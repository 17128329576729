import {
  Badge,
  Image,
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Select,
  Space,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import {
  AdEnum,
  AdSettingAdData,
  AdSettingAdPositionData,
  CategoryData,
  FontSizeType,
} from '../../types';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useVT } from 'virtualizedtableforantd4';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { dashboardRoute } from '../../constants/pathname';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { useAppDispatch } from '../../app/hooks';
import FourZeroThree from '../../components/FourZeroThree';
import FiveHundred from '../../components/FiveHundred';
import Container from '../../components/Container';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { compare, setFont } from '../../utils/colComponents';
import Table, { ColumnsType } from 'antd/lib/table';
import TableToolbar from '../../components/table/TableToolbar';
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { FALLBACK_IMG } from '../../constants/styles';
import { GREEN1, RED1 } from '../../constants/color';
import AdPositionAdModal from '../../components/advertisement/AdPositionAdModal';
import { tableScrollToTop } from '../../utils/helperFunction';

const AdPositionAdList = () => {
  //General Component
  const queryString = window.location.search;
  const [positionId] = useState(
    new URLSearchParams(queryString).get('position_id') ?? ''
  );
  const [catId] = useState(
    new URLSearchParams(queryString).get('cat_id') ?? ''
  );
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const isSubscribed = useRef(true);
  const dispatch = useAppDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [modalVisible, setModalVisible] = useState(false);
  //Table Component
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [total, setTotal] = useState();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Data Components
  const [adEnum, setAdEnum] = useState<AdEnum>();
  const [adData, setAdData] = useState<AdSettingAdData[]>([]);
  const [editAdData, setEditAdData] = useState<AdSettingAdData>();
  const [adPositionData, setAdPositionData] =
    useState<AdSettingAdPositionData>();
  const [categoryData, setCategoryData] = useState<CategoryData>();

  const columnKeys = [
    'adId',
    'positionId',
    'catId',
    'adName',
    'adImg',
    'adLink',
    'startTime|endTime',
    'clickCount',
    'platform',
    'isEnabled',
    'sortOrder',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<AdSettingAdData> = [
    {
      title: setFont(t('advertisement.adPositionAdColumnList.adId'), fontSize),
      dataIndex: 'adId',
      key: 'adId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: AdSettingAdData, b: AdSettingAdData) =>
        compare(a.adId, b.adId),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.adName'),
        fontSize
      ),
      dataIndex: 'adName',
      key: 'adName',
      width: 200,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('advertisement.adPositionAdColumnList.catId'), fontSize),
      dataIndex: 'catId',
      key: 'catId',
      width: 100,
      render: (value: any, record: AdSettingAdData) => (
        <Typography.Text>
          {record.category
            ? `${record.category.fullCatName} (${record.category.catId})`
            : ''}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('advertisement.adPositionAdColumnList.adImg'), fontSize),
      dataIndex: 'adImgPath',
      key: 'adImg',
      width: 100,
      render: (path: string) => (
        <Image
          width={50}
          src={path}
          preview={{ mask: <EyeOutlined /> }}
          fallback={FALLBACK_IMG}
        />
      ),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.adLink'),
        fontSize
      ),
      width: 300,
      dataIndex: 'adLink',
      key: 'adLink',
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.startTime'),
        fontSize
      ),
      key: 'startTime|endTime',
      width: 300,
      render: (value: any, record: AdSettingAdData) => (
        <Space direction="vertical" size={0}>
          <Typography.Text style={{ fontSize: fontSize }}>
            {`${t('advertisement.adPositionAdColumnList.startTime')}: ${
              record.startTime
            }`}
          </Typography.Text>
          <Typography.Text style={{ fontSize: fontSize }}>
            {`${t('advertisement.adPositionAdColumnList.endTime')}: ${
              record.endTime
            }`}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.clickCount'),
        fontSize
      ),
      width: 100,
      dataIndex: 'clickCount',
      key: 'clickCount',
      render: (value: number) => setFont(`${value}`, fontSize),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.platform'),
        fontSize
      ),
      width: 100,
      dataIndex: 'platform',
      key: 'platform',
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.isEnabled'),
        fontSize
      ),
      width: 100,
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(
        t('advertisement.adPositionAdColumnList.sortOrder'),
        fontSize
      ),
      width: 100,
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: AdSettingAdData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.adManage)
            }
            onClick={() => {
              setEditAdData(record);
              setModalVisible(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => {
              deleteAd(record.adId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.adManage)
            }
          >
            <Button
              danger
              type="link"
              size="small"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.advertisementGroup.adManage)
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Get Ad Enums
  const getAdEnum = useCallback(() => {
    getDataWithAuthToken('home_ad/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setAdEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  const getData = useCallback(async () => {
    if (isSubscribed.current) setLoading(true);
    try {
      const response = await getDataWithAuthToken(`ad/list`, {
        params: {
          page: page,
          size: pageSize,
          keyword: keyword ? keyword : undefined,
          positionId: positionId,
          catId: catId,
          platform:
            formRef.current && form.getFieldValue('platform')
              ? form.getFieldValue('platform')
              : undefined,
          isEnabled:
            formRef.current && form.getFieldValue('isEnabled') !== undefined
              ? form.getFieldValue('isEnabled') === 'all'
                ? undefined
                : form.getFieldValue('isEnabled')
              : undefined,
        },
      });
      if (response && response.goodStatus) {
        if (isSubscribed.current) {
          const adPosition: AdSettingAdPositionData =
            response.data.data.adPosition;
          const category: CategoryData = response.data.data.category;
          setAdData(response.data.list);
          setAdPositionData(adPosition);
          setCategoryData(category);
          setTotal(response.data.totalItem);

          // Scroll to top when data changes
          tableScrollToTop();

          document.title = `${t('advertisement.adPosition')} ${
            adPosition.positionName
          } ${category ? `| ${category.fullCatName}` : ''} - ${t(
            'advertisement.adPositionAd'
          )}`;
          dispatch(
            addPage({
              title: document.title,
              path: `${dashboardRoute.advertisement.adPosition.ad}?position_id=${positionId}&cat_id=${catId}`,
            })
          );
        }
      } else if (response && response.returnCode === 403) {
        if (isSubscribed.current) setFourZeroThree(true);
      } else {
        isSubscribed.current && setFiveHundred(true);
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
      if (isSubscribed.current) setLoading(false);
    } catch (error) {
      console.log(error);
      if (isSubscribed.current) setLoading(false);
    }
  }, [t, dispatch, positionId, catId, page, pageSize, keyword, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getAdEnum();
  }, [getAdEnum]);

  const deleteAd = async (id: number) => {
    if (isSubscribed.current) setLoading(true);
    try {
      const response = await postDataWithAuthToken('ad/delete', { adId: id });
      if (response && response.goodStatus) {
        alertMessage('success', t('advertisement.alerts.adDeleted'));
        getData();
      } else {
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
      if (isSubscribed.current) setLoading(false);
    } catch (error) {
      if (isSubscribed.current) setLoading(false);
      console.log(error);
    }
  };

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('general.advancedSearch')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all', isRecommend: 'all' }}
        >
          <Form.Item
            name="isEnabled"
            label={t('advertisement.adPositionAdColumnList.isEnabled')}
          >
            <Select
              style={{ width: 140 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              optionLabelProp="key"
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  isEnabled: value === 'all' ? '' : value.toString(),
                }));
              }}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="platform"
            label={t('advertisement.adPositionAdColumnList.platform')}
          >
            <Select
              style={{ width: 140 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              optionLabelProp="key"
              allowClear
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  platform: value ? value : undefined,
                }));
              }}
            >
              {adEnum?.platform.map((platform) => (
                <Select.Option key={platform.description} value={platform.code}>
                  {`${platform.description} [${platform.code}]`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('general.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('general.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>{t('general.advancedSearch')}</Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {`${t('advertisement.adPositionAd')} ${
                categoryData ? `| ${categoryData.fullCatName}` : ''
              }`}
            </Typography.Title>
            <TableToolbar
              leftElement={
                <Button
                  icon={<PlusOutlined />}
                  disabled={
                    !hasPermission(
                      actionPermissions.advertisementGroup.adManage
                    )
                  }
                  onClick={() => {
                    setEditAdData(undefined);
                    setModalVisible(true);
                  }}
                >
                  {t('advertisement.add/EditAd.addAdTitle')}
                </Button>
              }
              advancedSearch={advancedSearch}
              tableSize={tableSize}
              setTableSize={setTableSize}
              fontSize={fontSize}
              setFontSize={setFontSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchAdPositionAd')}
              rows={adData.map((adData) => ({
                ...adData,
              }))}
              exportConfig={{ fileName: 'AD_LIST' }}
            />
            <Table<AdSettingAdData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={adData}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              size={tableSize}
              loading={loading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                },
                current: page,
              }}
              rowKey={(ad) => ad.adId}
            />
          </Card>

          <AdPositionAdModal
            visible={modalVisible}
            setVisible={setModalVisible}
            adEnum={adEnum}
            selectedAdData={editAdData}
            adPositionData={adPositionData}
            categoryData={categoryData}
            refresh={() => {
              getData();
            }}
          />
        </>
      )}
    </Container>
  );
};

export default AdPositionAdList;
