import { FilterOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Grid,
  Popover,
  Space,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Form from 'antd/lib/form';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import AdminDropdown from '../../components/admin/AdminDropdown';
import EmployeeDropdown from '../../components/sortationSystem/common/EmployeeDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { EmployeeLogData, FontSizeType, OrderChangeLog } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const EmployeeLogList = () => {
  // General components
  const isSubscribed = useRef(true);
  const { t, i18n } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  // Data components
  const [keyword, setKeyword] = useState<string>('');
  const [employeeLogList, setEmployeeLogList] = useState<EmployeeLogData[]>([]);
  const [advance, setAdvance] = useState<{ [key: string]: string }>({});
  // Pagination components
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItems, setTotalItems] = useState<number>(0);
  // Table components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<EmployeeLogData[]>([]);
  const columnKeys = [
    'logId',
    'employee',
    'adminUser',
    'addTime',
    'action|',
    'remark|',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<EmployeeLogData> = [
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.logId'),
        fontSize
      ),
      key: 'logId',
      dataIndex: 'logId',
      width: 70,
      fixed: screens.lg ? 'left' : undefined,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.employee'),
        fontSize
      ),
      key: 'employee',
      dataIndex: 'employee',
      width: 150,
      render: (value) =>
        value &&
        setFont(`${value.nickname} (ID: ${value.employeeId})`, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.adminUser'),
        fontSize
      ),
      key: 'adminUser',
      dataIndex: 'adminUser',
      width: 120,
      render: (value) =>
        value &&
        setFont(`${value.adminUserName} (ID: ${value.adminId})`, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.addTime'),
        fontSize
      ),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('order.orderDetail.remark'),
        fontSize,
        t('order.orderDetail.remark')
      ),
      key: 'remark|',
      width: 250,
      render: (record: EmployeeLogData) =>
        record && record.logInfo && setFont(record.logInfo.remark, fontSize),
    },
    {
      title: setFont(
        t('order.orderDetail.action'),
        fontSize,
        t('order.orderDetail.action')
      ),
      key: 'action|',
      ellipsis: true,
      render: (record: EmployeeLogData) =>
        record && record.logInfo && setFont(record.logInfo.action, fontSize),
    },
  ];

  const level2Columns: ColumnsType<OrderChangeLog> = [
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.propertyName'),
        fontSize
      ),
      key: 'propertyName',
      dataIndex: 'propertyName',
      width: 80,
      render: (value: string) =>
        setFont(
          i18n.exists(`sortationSystem.employeeLogListColumns.${value}`)
            ? t(`sortationSystem.employeeLogListColumns.${value}`)
            : value,
          fontSize
        ),
    },
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.oldValue'),
        fontSize
      ),
      key: 'oldValue',
      dataIndex: 'oldValue',
      width: 80,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeLogListColumns.newValue'),
        fontSize
      ),
      key: 'newValue',
      dataIndex: 'newValue',
      width: 80,
      render: (value: string) => setFont(value, fontSize),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('employee/log/list', {
      params: {
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        adminId:
          formRef.current && form.getFieldValue('adminId')
            ? form.getFieldValue('adminId')
            : undefined,
        employeeId:
          formRef.current && form.getFieldValue('employeeId')
            ? form.getFieldValue('employeeId')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLoading(false);
          setEmployeeLogList(response.data.list);
          setTotalItems(response.data.totalItem);
          // Scroll to top when data changes
          tableScrollToTop();
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t, keyword, page, pageSize, form]);

  const batchDelete = () => {
    setIsLoading(true);
    postDataWithAuthToken('employee/delete_batch', {
      logIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage(
            'success',
            t('sortationSystem.alerts.employeeLogBatchDeleted')
          );
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('sortationSystem.employeeListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all' }}
        >
          <Form.Item
            name="adminId"
            label={t('goods.inventoryLogListColumns.advancedSearch.byAdminId')}
            style={{ marginBottom: 12 }}
          >
            <AdminDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  adminId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            name="employeeId"
            label={t(
              'sortationSystem.employeeLogListColumns.advancedSearch.employeeId'
            )}
            style={{ marginBottom: 12 }}
          >
            <EmployeeDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  employee: value,
                }));
              }}
            />
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                setKeyword('');
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t(
                'sortationSystem.employeeLogListColumns.advancedSearch.search'
              )}
            </Button>
            <Button
              disabled={Object.values(advance).every((value) => !value)}
              onClick={() => {
                form.resetFields();
                setAdvance({});
                if (page !== 1) setPage(1);
                else getData();
              }}
            >
              {t('sortationSystem.employeeLogListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('sortationSystem.employeeLogListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('sortationSystem.employeeLogList')}
            </Typography.Title>
            <TableToolbar
              setFontSize={setFontSize}
              fontSize={fontSize}
              totalItems={totalItems}
              refresh={() => getData()}
              tableSize={tableSize}
              setTableSize={setTableSize}
              rows={employeeLogList.map((log) => ({
                logId: log.logId ?? '',
                employee: log.employee
                  ? `${log.employee.nickname} (ID: ${log.employee.employeeId})`
                  : '',
                adminUser: log.adminUser
                  ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
                  : '',
                addTime: log.addTime ?? '',
                remark: log.logInfo ? log.logInfo.remark : '',
                action: log.logInfo ? log.logInfo.action : '',
              }))}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              advancedSearch={advancedSearch}
              exportConfig={{ fileName: 'EMPLOYEE_LOG_LIST' }}
            />
            <Table<EmployeeLogData>
              dataSource={employeeLogList}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              size={tableSize}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              rowKey={(item) => item.logId}
              rowSelection={{
                selectedRowKeys,
                onChange: (rowKeys: React.Key[], rows: EmployeeLogData[]) => {
                  setSelectedRowKeys(rowKeys);
                  setSelectedRows(rows);
                },
              }}
              loading={isLoading}
              pagination={{
                total: totalItems,
                pageSize: pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
              expandable={{
                rowExpandable: (record) =>
                  record.logInfo && record.logInfo.changeList ? true : false,
                expandedRowRender: (record) => (
                  <Table
                    size={tableSize}
                    dataSource={record.logInfo.changeList}
                    columns={level2Columns}
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    rowKey={(record) => record.propertyName}
                  />
                ),
                columnWidth: 30,
              }}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                funct={{
                  deleteFunc: hasPermission(
                    actionPermissions.sortationGroup.employeeLog
                  )
                    ? batchDelete
                    : undefined,
                  exportConfig: { fileName: 'EMPLOYEE_LOG_LIST' },
                }}
                selectedRows={selectedRows.map((log) => ({
                  logId: log.logId ?? '',
                  employee: log.employee
                    ? `${log.employee.nickname} (ID: ${log.employee.employeeId})`
                    : '',
                  adminUser: log.adminUser
                    ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
                    : '',
                  addTime: log.addTime ?? '',
                  remark: log.logInfo ? log.logInfo.remark : '',
                  action: log.logInfo ? log.logInfo.action : '',
                }))}
                setSelectedRowKeys={setSelectedRowKeys}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
              />
            )}
          </Card>
        </>
      )}
    </Container>
  );
};

export default EmployeeLogList;
