import React, { useEffect, useState, useCallback, useRef } from 'react';
import Container from '../../components/Container';
import { Card, Table, Space, Button, Popconfirm, Tag, Grid } from 'antd';
import { UserRankData, FontSizeType, BasicEnumInfoType } from '../../types';
import { ColumnsType } from 'antd/lib/table';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import UserRankModal from '../../components/users/UserRankModal';
import FourZeroThree from '../../components/FourZeroThree';
import TableToolbar from '../../components/table/TableToolbar';
import { alertMessage } from '../../utils/alertMessage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import { compare, setFont } from '../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import {
  BLUE1,
  BLUE2,
  GRAY4,
  GREEN1,
  HIGHLIGHT_YELLOW,
  ORANGE1,
  PURPLE1,
  PURPLE2,
  RED1,
  RED2,
} from '../../constants/color';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Tags for the different comments
 */
const commentTagColor: { [key: string]: string } = {
  0: GRAY4,
  1: RED1,
  2: HIGHLIGHT_YELLOW,
  3: GREEN1,
  4: BLUE1,
  5: PURPLE1,
  6: RED2,
  7: ORANGE1,
  8: PURPLE2,
  9: BLUE2,
};

/**
 * Display User Ranks
 * Can add, edit, or delete User Rank
 *
 */
const UserRank = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [rankData, setRankData] = useState<Array<UserRankData>>([]);
  const [selectedRank, setSelectedRank] = useState<UserRankData | undefined>();
  const [totalRank, setTotalRank] = useState();
  const [loading, setLoading] = useState(true);
  const [rankModalVisible, setRankModalVisible] = useState(false);
  const [specialCommentTypes, setSpecialCommentTypes] = useState<
    Array<BasicEnumInfoType>
  >([]);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [page, setPage] = useState(1);
  const isSubscribed = useRef(true);

  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<UserRankData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columnKeys = [
    'id',
    'name',
    'minPoints',
    'maxPoints',
    'discount',
    'maxDiscountAmount',
    'specialRank',
    'specialCommentInfo',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<UserRankData> = [
    {
      title: setFont(t('users.userRankColumns.id'), fontSize),
      dataIndex: 'rankId',
      key: 'id',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      render: (text: string, record: UserRankData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.userGroup.userRank)}
          onClick={async () => {
            setSelectedRank(record);
            setRankModalVisible(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: UserRankData, b: UserRankData) => compare(a.rankId, b.rankId),
    },
    {
      title: setFont(t('users.userRankColumns.rankName'), fontSize),
      dataIndex: 'rankName',
      key: 'name',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserRankData, b: UserRankData) =>
        compare(a.rankName, b.rankName),
    },
    {
      title: setFont(t('users.userRankColumns.minPts'), fontSize),
      dataIndex: 'minPoints',
      key: 'minPoints',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserRankData, b: UserRankData) =>
        compare(a.minPoints, b.minPoints),
    },
    {
      title: setFont(t('users.userRankColumns.maxPts'), fontSize),
      dataIndex: 'maxPoints',
      key: 'maxPoints',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserRankData, b: UserRankData) =>
        compare(a.maxPoints, b.maxPoints),
    },
    {
      title: setFont(t('users.userRankColumns.discount'), fontSize),
      dataIndex: 'discount',
      key: 'discount',
      width: 120,
      render: (text: string) => setFont(`${text}%`, fontSize),
      sorter: (a: UserRankData, b: UserRankData) =>
        compare(a.discount, b.discount),
    },
    {
      title: setFont(t('users.userRankColumns.maxDiscount'), fontSize),
      dataIndex: 'maxDiscountAmount',
      key: 'maxDiscountAmount',
      width: 120,
      render: (text: string) => setFont(`${text}`, fontSize),
      sorter: (a: UserRankData, b: UserRankData) =>
        compare(a.maxDiscountAmount, b.maxDiscountAmount),
    },
    {
      title: setFont(t('users.userRankColumns.specialRank'), fontSize),
      dataIndex: 'specialRank',
      key: 'specialRank',
      width: 120,
      render: (text: string, record: UserRankData) =>
        record.specialRank ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('users.userRankColumns.specialComment'), fontSize),
      dataIndex: 'specialCommentInfo',
      key: 'specialCommentInfo',
      filters: specialCommentTypes.map((comment) => {
        return { text: comment.description, value: comment.description };
      }),
      onFilter: (value, record) => record.specialCommentInfo === value,
      render: (text: string, record: UserRankData) => (
        <Tag
          color={
            commentTagColor[
              specialCommentTypes
                .findIndex(
                  (value) => value.description === record.specialCommentInfo
                )
                .toString()
            ]
          }
        >
          {setFont(record.specialCommentInfo, fontSize)}
        </Tag>
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : false,
      width: 120,
      render: (text: string, record: UserRankData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.userGroup.userRank)}
            onClick={async () => {
              setSelectedRank(record);
              setRankModalVisible(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            onConfirm={() => deleteRank(record.rankId)}
            disabled={!hasPermission(actionPermissions.userGroup.userRank)}
          >
            <Button
              danger
              type="link"
              disabled={!hasPermission(actionPermissions.userGroup.userRank)}
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Fetches user rank
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken('users/user_rank/list', {
      params: { page: page, size: pageSize },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setRankData(response.data.list);
            setTotalRank(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize]);

  // Fetches the Special Comments
  const getSpecialComments = useCallback(() => {
    getDataWithAuthToken('users/user_rank/special_comment')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setSpecialCommentTypes(response.data.list);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getData();
    if (hasPermission(actionPermissions.userGroup.userRank))
      getSpecialComments();
  }, [getData, getSpecialComments]);

  /**
   * @param rankId Id of the Rank you are going to delete
   */
  const deleteRank = (rankId: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('users/user_rank/delete', { rankId: rankId })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('users.alerts.rankDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <h1 style={{ fontSize: 24 }}>{t('users.userRank')}</h1>
          <TableToolbar
            fontSize={fontSize}
            setFontSize={setFontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={async () => {
                  setSelectedRank(undefined);
                  setRankModalVisible(true);
                }}
              >
                {t('users.add/editRank.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getData()}
            totalItems={totalRank}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            rows={rankData}
            exportConfig={{ fileName: 'USER_RANK' }}
          />
          <Table<UserRankData>
            dataSource={rankData}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={loading}
            pagination={{
              total: totalRank,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowKey={(userRank) => userRank.rankId}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: UserRankData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'USER_RANK' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <UserRankModal
        visible={rankModalVisible}
        setVisible={setRankModalVisible}
        rankProfile={selectedRank}
        callBack={() => getData()}
        specialComments={specialCommentTypes}
      />
    </Container>
  );
};

export default UserRank;
