import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { useState } from 'react';
import { GoodsTypeData } from '../../../types';
import { Select, Typography } from 'antd';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { DefaultOptionType } from 'antd/lib/select';

type SpecTypesDropdownProps = {
  labelInValue?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const SpecTypesDropdown = ({
  labelInValue = false,
  initialValue,
  onChange,
}: SpecTypesDropdownProps) => {
  const { t } = useTranslation();
  const [specs, setSpecs] = useState<GoodsTypeData[]>([]);
  const [specPage, setSpecPage] = useState(2);
  const [specTotalPage, setSpecTotalPage] = useState(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getSpecTypes = (value?: string) => {
    getDataWithAuthToken('goods/spec_type/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setSpecs(response.data.list);
          setSpecPage(2);
          setSpecTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const specTypeFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (specPage > specTotalPage) return;
      getDataWithAuthToken('goods/spec_type/list', {
        params: {
          keyword: searchText.specType || undefined,
          page: specPage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setSpecs((prev) => [...prev, ...response.data.list]);
            setSpecPage((prev) => prev + 1);
            setSpecTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      labelInValue={labelInValue}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      placeholder={t('searchPlaceholders.searchGoodsTypes')}
      showSearch
      onPopupScroll={specTypeFetchOnScroll}
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, specType: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => {
            getSpecTypes(value);
          }, EXTENDED_TIMEOUT)
        );
      }}
      allowClear
      onChange={handleOnChange}
      onFocus={() => {
        !specs.length && getSpecTypes();
      }}
      defaultValue={initialValue ? initialValue : undefined}
      filterOption={false}
      optionLabelProp="key"
    >
      {specs.map((type) => (
        <Select.Option
          key={`${type.typeName} (${type.goodsSpecTypeId})`}
          value={type.goodsSpecTypeId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('goods.goodsTypeListColumns.goodsSpecTypeId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('goods.goodsTypeListColumns.typeName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{type.goodsSpecTypeId}</Typography>
              <Typography>{type.typeName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SpecTypesDropdown;
