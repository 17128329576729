import { Col, Grid, Popover, Row, Space, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimGoodLists } from '../../../types';

import Table from 'antd/lib/table';
import OrderClaimOrderGoods from './ClaimOrderGoods';

type ClaimGoodsListProps = {
  claimGoodsList?: ClaimGoodLists[];
  loading: boolean;
  inSeparateWindow?: boolean;
  colsToShow?: string[];
};

/**
 * Displays Claim Goods List
 *
 */
const ClaimGoodsList = ({
  claimGoodsList,
  loading,
  colsToShow,
  inSeparateWindow = false,
}: ClaimGoodsListProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const screens = Grid.useBreakpoint();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);
  const columns = [
    {
      title: t('order.claimGoodsList.id'),
      width: 100,
      dataIndex: 'id',
      render: (text: string) => text,
    },
    {
      title: t('order.claimGoodsList.claimId'),
      width: 100,
      dataIndex: 'claimId',
      render: (text: string) => text,
    },
    {
      title: t('order.claimGoodsList.goodsInfo'),
      width: 350,
      dataIndex: 'goodsInfo',
      render: (text: string, record: ClaimGoodLists) =>
        record &&
        record.orderGoods && (
          <Popover
            mouseEnterDelay={0.5}
            overlayInnerStyle={{
              width: screens.lg ? 850 : screens.md ? 'auto' : undefined,
            }}
            placement="topLeft"
            content={<OrderClaimOrderGoods orderGood={record.orderGoods} />}
          >
            <div style={{ cursor: 'pointer', wordBreak: 'break-word' }}>
              {`${record.orderGoods.fullGoodsName}`}
            </div>
          </Popover>
        ),
    },
    {
      title: t('order.claimGoodsList.claimNumber'),
      width: 150,
      dataIndex: 'claimNumber',
      render: (text: string) => text,
    },
    {
      title: t('order.claimGoodsList.claimAmount'),
      dataIndex: 'claimAmount',
      width: 150,
      render: (text: string) => text,
    },
    {
      title: t('order.claimGoodsList.claimReason'),
      width: inSeparateWindow ? undefined : 200,
      dataIndex: 'claimReason',
      render: (text: string, record: ClaimGoodLists) =>
        record && (
          <Space direction="vertical">
            {text}
            {record.isSortationMistake && (
              <Space>
                <div>{`${t('order.orderDetail.isSortationMistake')}:`}</div>
                <div>{record.sortationReason}</div>
              </Space>
            )}
          </Space>
        ),
    },
    {
      title: t('order.claimGoodsList.feesInfo'),
      width: 150,
      dataIndex: 'feesInfo',
      render: (text: string, record: ClaimGoodLists) =>
        record &&
        record.orderGoods && (
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>{`${t('order.claimGoodsList.gstFee')}:`}</div>
              <div>{record.gstFee}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>{`${t('order.claimGoodsList.pstFee')}:`}</div>
              <div>{record.pstFee}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>{`${t('order.claimGoodsList.depositFee')}: `}</div>

              <div>{record.depositFee}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>{`${t('order.claimGoodsList.recycleFee')}: `}</div>

              <div>{record.recycleFee}</div>
            </div>
          </div>
        ),
    },
    {
      title: t('order.claimGoodsList.remark'),
      dataIndex: 'remark',
      width: 200,
      render: (text: string) => text,
    },
  ];

  return (
    <Row gutter={[16, 24]}>
      {!inSeparateWindow && (
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Text
            strong
            style={{
              fontSize: 16,
              marginBottom: 10,
            }}
          >
            {t('order.claimGoodsList.title')}
          </Typography.Text>
        </Col>
      )}
      <Col span={24}>
        <Table
          size="small"
          dataSource={claimGoodsList}
          columns={
            colsToShow
              ? columns.filter((colObj) =>
                  colsToShow.includes(colObj.dataIndex)
                )
              : columns.filter((colObj) => colObj.dataIndex !== 'claimId')
          }
          scroll={{ y: 600, x: 1200 }}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: 50,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
            hideOnSinglePage: true,
          }}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default ClaimGoodsList;
