import Container from '../../components/Container';
import { Card } from 'antd';
import UserIDTable from '../../components/users/userDetail/UserIDTable';

/**
 * Display Users Logs
 * /dashboard/admin/log
 */
const UserIDList = () => {
  return (
    <Container>
      <Card>
        <UserIDTable />
      </Card>
    </Container>
  );
};
export default UserIDList;
