import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Grid, Popconfirm, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import { S3Data, BasicEnumInfoType, FontSizeType } from '../../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import TableToolbar from '../../components/table/TableToolbar';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import S3Modal from '../../components/plugins/S3Modal';
import { compare, setFont } from '../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import { GREEN1, RED1 } from '../../constants/color';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const S3 = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [s3Data, setS3Data] = useState<S3Data[]>([]);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [showModal, setShowModal] = useState(false);
  const [regionList, setRegionList] = useState<BasicEnumInfoType[]>([]);
  const [bucketInfo, setBucketInfo] = useState<S3Data | undefined>();
  const columnKeys = [
    'id',
    'bucket',
    'regional',
    'isCName',
    'path',
    'isUse',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columns: ColumnsType<S3Data> = [
    {
      title: setFont(t('plugins.S3.S3Columns.id'), fontSize),
      dataIndex: 'id',
      key: 'id',
      fixed: screens.lg ? 'left' : false,
      width: 70,
      render: (text: string, record: S3Data) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={async () => {
            if (!regionList.length) await getRegionData();
            onEdit(record);
          }}
          disabled={!hasPermission(actionPermissions.pluginGroup.ossConfig)}
        >
          {text}
        </Button>
      ),
      sorter: (a: S3Data, b: S3Data) => compare(a.id, b.id),
    },
    {
      title: setFont(t('plugins.S3.S3Columns.bucket'), fontSize),
      dataIndex: 'bucket',
      key: 'bucket',
      fixed: screens.lg ? 'left' : false,
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: S3Data, b: S3Data) => compare(a.bucket, b.bucket),
    },
    {
      title: setFont(t('plugins.S3.S3Columns.region'), fontSize),
      dataIndex: 'regional',
      key: 'regional',
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: S3Data, b: S3Data) => compare(a.regional, b.regional),
    },
    {
      title: setFont(t('plugins.S3.S3Columns.isCName'), fontSize),
      dataIndex: 'isCname',
      key: 'isCName',
      width: 140,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('plugins.S3.S3Columns.path'), fontSize),
      dataIndex: 'path',
      key: 'path',
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: S3Data, b: S3Data) => compare(a.path, b.path),
    },
    {
      title: setFont(t('plugins.S3.S3Columns.inUse'), fontSize),
      dataIndex: 'isUse',
      key: 'isUse',
      width: 100,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : false,
      width: 120,
      render: (record: S3Data) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={async () => {
              if (!regionList.length) await getRegionData();
              onEdit(record);
            }}
            disabled={!hasPermission(actionPermissions.pluginGroup.ossConfig)}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              onDelete(record.id);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.pluginGroup.ossConfig)}
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={!hasPermission(actionPermissions.pluginGroup.ossConfig)}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getBucketData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('setting/s3/list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setS3Data(response.data.list);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t]);

  const getRegionData = async () => {
    getDataWithAuthToken('setting/s3/region_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setRegionList(response.data.list);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBucketData();
  }, [getBucketData]);

  const onEdit = (record: S3Data) => {
    if (isSubscribed.current) {
      setShowModal(true);
      setBucketInfo(record);
    }
  };

  const onAdd = () => {
    if (isSubscribed.current) {
      setShowModal(true);
      setBucketInfo(undefined);
    }
  };

  const onDelete = (id: number) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('setting/s3/delete', { id: id })
      .then((response) => {
        if (response && response.goodStatus) {
          getBucketData();
          alertMessage('success', t('plugins.S3.alerts.bucketDeleted'));
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Container>
      <Card>
        <Typography.Title level={3} style={{ fontWeight: 500 }}>
          {t('plugins.S3.title')}
        </Typography.Title>
        <TableToolbar
          setFontSize={setFontSize}
          fontSize={fontSize}
          leftElement={
            <Button
              disabled={!hasPermission(actionPermissions.pluginGroup.ossConfig)}
              icon={<PlusOutlined />}
              onClick={async () => {
                if (!regionList.length) await getRegionData();
                onAdd();
              }}
            >
              {t('plugins.S3.add/editBuckets.addTitle')}
            </Button>
          }
          tableSize={tableSize}
          setTableSize={setTableSize}
          totalItems={s3Data.length}
          refresh={getBucketData}
          columns={columns}
          columnKeys={columnKeys}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
        <Table<S3Data>
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          size={tableSize}
          loading={isLoading}
          dataSource={s3Data}
          components={vt}
          scroll={{ y: 600, x: 1200 }}
          rowKey={(record) => record.id}
          pagination={{
            total: s3Data.length,
            showQuickJumper: true,
            showSizeChanger: true,
            size: 'small',
            defaultPageSize: pageSize,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
            onChange: (page, pSize) => setPageSize(pSize || pageSize),
          }}
        />
      </Card>
      <S3Modal
        visible={showModal}
        setVisible={setShowModal}
        bucketInfo={bucketInfo}
        regionList={regionList}
        refresh={getBucketData}
      />
    </Container>
  );
};

export default S3;
