import { Col, Divider, Row, Typography } from 'antd';
import { QRCodeSVG } from 'qrcode.react';

const PrintCartLabel = () => {
  const renderComponent = (num: number) => {
    const label = `B`;
    return (
      <Row justify="center" align="middle">
        <Col span={12} style={{ textAlign: 'center', justifyItems: 'center' }}>
          <Typography.Text
            style={{ fontSize: 180 }}
          >{`${label}${num}`}</Typography.Text>
        </Col>
        <Col span={12}>
          <QRCodeSVG
            value={JSON.stringify({
              cartNumber: `${label}${num}`,
            })}
            size={280}
          />
        </Col>
        <Divider />
      </Row>
    );
  };

  const components = () => {
    let res: React.ReactNode[] = [];
    for (let i = 1; i <= 50; i++) {
      res.push(renderComponent(i));
    }
    return res;
  };

  return <div>{components()}</div>;
};

export default PrintCartLabel;
