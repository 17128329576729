import moment from 'moment';

export const getPercent = (old?: any, curr?: any) => {
  if (old === undefined || curr === undefined) {
    return '-1';
  } else if (old === '0' || old === 0 || old === '0.00') {
    return '';
  } else {
    let value = (((Number(curr) - Number(old)) / Number(old)) * 100).toFixed(3);
    return value === '0.000' ? '0' : value;
  }
};

export const addCommas = (num: any) => {
  return num.toLocaleString();
};

export const addCommasPrice = (num: any) => {
  if (num === '0') {
    return '0.00';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const generateKey = (apiLength: number) => {
  const charsPool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let API = '';
  for (let i = 0; i < apiLength; i++) {
    API += charsPool.charAt(Math.floor(Math.random() * charsPool.length));
  }
  return API;
};

/**
 * Moment function - convert string to dateTime Form
 * Catch Error, check if date is valid
 * @param dateTime
 * @param format
 * @param defaultValue
 * @returns
 */
export const momentDateTime = (
  dateTime?: string,
  format?: string,
  defaultValue?: any
) => {
  if (dateTime) {
    const date = moment(dateTime, true);
    if (!date.isValid()) {
      return defaultValue;
    }

    return format ? date.format(format) : date;
  }
  return defaultValue;
};

export const isIOS = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  // if (/windows phone/i.test(userAgent)) {
  //     return "Windows Phone";
  // }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return true;
  }

  return false;
};

export const isAndroid = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  if (/android/i.test(userAgent)) {
    return true;
  }

  return false;
};

export const isExcelUrl = (url: string) => {
  // remove QueryParams
  const urlObject = new URL(url);
  urlObject.search = '';

  // Define a regular expression for Excel file extensions
  const excelRegex = /\.xls[xm]?$/i; // Matches .xls, .xlsx, .xlsm, etc. case-insensitively

  // Test the URL against the regular expression
  return excelRegex.test(urlObject.href);
};

export const mergeParamsToString = (
  url: string,
  params: { [key: string]: any }
) => {
  for (let key in params) {
    if (params[key] !== undefined) {
      url += `&${key}=${params[key]}`;
    }
  }
  return url;
};

/**
 * Table scroll to top for some condition
 * - usually dataSource changed
 */
export const tableScrollToTop = () => {
  // Table always under ant design Tab - so only active when tab is active
  // Bugs: not working on multiple has class "ant-tabs-tabpane-active". e.g user detail
  const tableContainer = document.querySelector(
    `.ant-tabs-tabpane-active .ant-table-body`
  );
  if (tableContainer) {
    tableContainer.scrollTo(0, 0);
  }
};
