import { useEffect, useRef } from 'react';
import { Descriptions } from 'antd';
import { UserListData } from '../../../types';
import { useTranslation } from 'react-i18next';

type UserDescriptionProps = {
  userInfo: UserListData;
};

/**
 * Displays User Information
 *
 * @param userInfo User Information to display
 * @param loading  Whether the User Information is still loading
 */
const UserDescription = ({ userInfo }: UserDescriptionProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Descriptions>
      <Descriptions.Item label={t('users.userListColumns.username')}>
        {userInfo.userName}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.nickname')}>
        {userInfo.nickName}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.email')}>
        {userInfo.email}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.mobilePhone')}>
        {userInfo.mobilePhone}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.registerDate')}>
        {userInfo.regDate}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.birthday')}>
        {userInfo.birthday}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.userMoney')}>
        {userInfo.userMoney}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.frozenMoney')}>
        {userInfo.frozenMoney}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.payPoints')}>
        {userInfo.payPoints}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.rankPoints')}>
        {userInfo.rankPoints}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.rankName')}>
        {userInfo.userRank.rankName}
      </Descriptions.Item>
      <Descriptions.Item label={t('users.userListColumns.userMember')}>
        {userInfo.member?.rankName}
      </Descriptions.Item>
      {userInfo.salesPerson && (
        <Descriptions.Item label={t('salesperson.title')}>
          {`${userInfo.salesPerson.fullName} (ID: ${userInfo.salesPerson.salesPersonId})`}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={t('users.userListColumns.remark')}>
        {userInfo.remark}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserDescription;
