import { PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { PickUpPoint, PickUpPointModal, PickupData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableToolbar from '../../table/TableToolbar';

type PickupListModalProps = {
  pickPointData?: PickUpPoint;
  isSeller: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pickupData?: PickupData;
  refresh?: () => void;
};

const PickupListModal = ({
  pickPointData,
  isSeller,
  visible,
  setVisible,
  pickupData,
  refresh,
}: PickupListModalProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const isSubscribed = useRef(true);
  //Table Components
  const screens = useBreakpoint();
  //Data Components
  const [pickupPointList, setPickupPointList] = useState<PickUpPointModal[]>(
    []
  );
  //Text Components
  const [addresses, setAddresses] = useState<any>([]);
  const [advance, setAdvance] = useState<{
    pickupPointName?: string;
    mobile?: string;
    address?: string;
  }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      pickupData?.pickUpPoints
        ? setPickupPointList(pickupData.pickUpPoints)
        : setPickupPointList([]);
    }
  }, [form, visible, pickupData]);

  //Pickup Point Table
  const columns: ColumnsType<PickUpPointModal> = [
    {
      title: t('settings.pickupListColumns.pickUpPoints.pointName'),
      dataIndex: 'pointName',
      key: 'pointName',
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('settings.pickupListColumns.pickUpPoints.contactName'),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 120,
      render: (text: string) => text,
    },
    {
      title: t('settings.pickupListColumns.pickUpPoints.address'),
      key: 'address',
      width: 200,
      render: (record: PickUpPoint) => (
        <Space direction="vertical">
          <Typography.Text>{record.address}</Typography.Text>
          <Typography.Text>{record.mobile}</Typography.Text>
        </Space>
      ),
    },
    {
      title: t('actionsColumn.title'),
      key: 'action',
      width: 80,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: PickUpPointModal) => (
        <Popconfirm
          title={t('actionsColumn.deleteWarning')}
          onConfirm={() => {
            setPickupPointList((prev) =>
              prev.filter((pickupPointObj) => pickupPointObj !== record)
            );
          }}
          okText={t('actionsColumn.confirmation.yes')}
          cancelText={t('actionsColumn.confirmation.no')}
          placement="topRight"
        >
          <Button danger type="link" style={{ padding: 0 }}>
            {t('actionsColumn.delete')}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const onFinish = () => {
    if (!pickupPointList.length && !pickPointData) {
      if (typingTimeout) clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(
          () => alertMessage('warning', t('settings.alerts.emptyPickupPoint')),
          EXTENDED_TIMEOUT
        )
      );
    } else {
      form
        .validateFields()
        .then((values) => {
          //This will update the list of pickupPoints so the body will contain the pickupPoint with the updated info
          if (pickPointData && pickupData) {
            const index = pickupData.pickUpPoints.indexOf(pickPointData, 0);
            if (index > -1) {
              pickupData.pickUpPoints.splice(index, 1);
              let newPoint: PickUpPoint = {
                pointId: pickPointData.pointId,
                pointName: form.getFieldValue(['pointName']),
                contactName: form.getFieldValue(['contactName']),
                mobile: form.getFieldValue(['mobile']),
                address: form.getFieldValue(['address']),
              };
              pickupData.pickUpPoints.push(newPoint);
            }
          }
          if (isSubscribed.current) setIsLoading(true);
          postDataWithAuthToken(pickupData ? 'pick_up/edit' : 'pick_up/add', {
            isEnabled: pickupData?.isEnabled,
            pickUpAreaId: pickupData ? pickupData.pickUpAreaId : undefined,
            sellerId: pickupData ? undefined : values.sellerId,
            pickUpAreaName: values.pickUpAreaName
              ? values.pickUpAreaName
              : undefined,
            pickUpPoints: pickupPointList ? pickupPointList : undefined,
          })
            .then((response) => {
              if (response) {
                if (response.goodStatus) {
                  if (isSubscribed.current) setVisible(false);
                  if (refresh) refresh();
                  alertMessage(
                    'success',
                    pickupData
                      ? t('settings.alerts.pickupAreaEdited')
                      : t('settings.alerts.pickupAreaAdded')
                  );
                } else {
                  alertMessage(
                    'error',
                    response?.msg || t('general.noResponse'),
                    response?.data || undefined
                  );
                }
              }
              if (isSubscribed.current) setIsLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setIsLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    }
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  /**
   *  Makes a requests to Mapbox to autofill the address in the input box while the user is typing
   */
  const onSearchAddress = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    if (value && value.length > 5) {
      setTypingTimeout(
        setTimeout(() => {
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=ca&language=en&types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
            )
            .then((result) => {
              if (result && result.status === 200 && result.data) {
                if (isSubscribed.current) setAddresses(result.data.features);
              } else alertMessage('error', t('users.alerts.errorOccurred'));
            })
            .catch((err) => {
              console.log(err);
            });
        }, EXTENDED_TIMEOUT)
      );
    } else setAddresses([]);
  };

  return (
    <Modal
      title={
        pickPointData
          ? `${t('settings.add/editPickup.pickupPoint.editTitle')} ${
              pickPointData.pointName
            }`
          : t('settings.add/editPickup.pickupPoint.addTitle')
      }
      visible={visible}
      maskClosable={false}
      okText={t('settings.add/editPickup.ok')}
      cancelText={t('settings.add/editPickup.cancel')}
      onOk={onFinish}
      onCancel={onClose}
      width={576}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          initialValues={
            pickPointData
              ? { ...pickPointData }
              : pickupData
              ? { ...pickupData }
              : { sellerId: isSeller ? '' : 0 }
          }
        >
          {!pickupData && !pickPointData && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="sellerId"
                  label={t(
                    'settings.add/editPickup.advancedSearch.seller.title'
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.pleaseSelectOne'),
                    },
                  ]}
                >
                  <SellersDropdown />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pickUpAreaName"
                  label={t('settings.add/editPickup.pickUpAreaName')}
                  rules={[
                    {
                      required: pickupPointList
                        ? !pickupPointList.length
                        : true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      let target = e.target as HTMLInputElement;
                      setAdvance((prev) => ({
                        ...prev,
                        pickUpAreaName:
                          target.value !== '' ? target.value : undefined,
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {!pickPointData && !pickPointData && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="pointName"
                    label={t('settings.add/editPickup.pickupPoint.pointName')}
                    rules={[
                      {
                        required: pickupPointList
                          ? !pickupPointList.length
                          : true,
                        message: t('general.inputError.pleaseInputAmount'),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        let target = e.target as HTMLInputElement;
                        setAdvance((prev) => ({
                          ...prev,
                          pickupPointName:
                            target.value !== '' ? target.value : undefined,
                        }));
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contactName"
                    label={t('settings.add/editPickup.pickupPoint.contactName')}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="mobile"
                    label={t('settings.add/editPickup.pickupPoint.mobile')}
                    rules={[
                      {
                        required: pickupPointList
                          ? !pickupPointList.length
                          : true,
                        message: t('general.inputError.pleaseInputAmount'),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        let target = e.target as HTMLInputElement;
                        setAdvance((prev) => ({
                          ...prev,
                          mobile:
                            target.value !== '' ? target.value : undefined,
                        }));
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="address"
                    label={t('settings.add/editPickup.pickupPoint.address')}
                    rules={[
                      {
                        required: pickupPointList
                          ? !pickupPointList.length
                          : true,
                        message: t('general.inputError.pleaseInputAmount'),
                      },
                    ]}
                  >
                    <AutoComplete
                      placeholder={t('searchPlaceholders.searchAddress')}
                      autoFocus
                      style={{ width: '100%' }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      onChange={onSearchAddress}
                      onSelect={(value: string) => {
                        setAdvance((prev) => ({
                          ...prev,
                          address: value ? value : undefined,
                        }));
                      }}
                    >
                      {addresses.map((address: { place_name: string }) => (
                        <AutoComplete.Option
                          key={JSON.stringify(address)}
                          value={address.place_name}
                        >
                          {address.place_name}
                        </AutoComplete.Option>
                      ))}
                    </AutoComplete>
                  </Form.Item>
                </Col>
              </Row>
              <TableToolbar
                leftElement={
                  <Button
                    disabled={
                      advance.address === undefined ||
                      advance.mobile === undefined ||
                      advance.pickupPointName === undefined
                    }
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      let index = pickupPointList.findIndex((point) => {
                        if (
                          point.pointName === form.getFieldValue(['pointName'])
                        ) {
                          return true;
                        }
                        return false;
                      });
                      if (index === -1) {
                        setPickupPointList((prev) => {
                          return prev.concat({
                            pointName: form.getFieldValue(['pointName']),
                            contactName: form.getFieldValue(['contactName'])
                              ? form.getFieldValue(['contactName'])
                              : '',
                            mobile: form.getFieldValue(['mobile']),
                            address: form.getFieldValue(['address']),
                          });
                        });
                        form.resetFields([
                          'pointName',
                          'contactName',
                          'mobile',
                          'address',
                        ]);
                        setAdvance({});
                      } else {
                        if (typingTimeout) clearTimeout(typingTimeout);
                        setTypingTimeout(
                          setTimeout(
                            () =>
                              alertMessage(
                                'warning',
                                t('settings.alerts.pickupPointNameExists')
                              ),
                            EXTENDED_TIMEOUT
                          )
                        );
                      }
                    }}
                  >
                    {t('settings.add/editPickup.addTitle')}
                  </Button>
                }
              />
              <Table
                size="small"
                dataSource={pickupPointList}
                columns={columns}
                scroll={{ y: 200 }}
                pagination={{
                  pageSize: pickupPointList ? pickupPointList.length : 10,
                  hideOnSinglePage: true,
                }}
                rowKey={(record) => record.pointName}
              />
            </>
          )}
          {pickPointData && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="pointName"
                  label={t('settings.add/editPickup.pickupPoint.pointName')}
                  rules={[
                    {
                      required: pickPointData ? true : false,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      let target = e.target as HTMLInputElement;
                      setAdvance((prev) => ({
                        ...prev,
                        pickupPointName:
                          target.value !== '' ? target.value : undefined,
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contactName"
                  label={t('settings.add/editPickup.pickupPoint.contactName')}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mobile"
                  label={t('settings.add/editPickup.pickupPoint.mobile')}
                  rules={[
                    {
                      required: pickPointData ? true : false,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      let target = e.target as HTMLInputElement;
                      setAdvance((prev) => ({
                        ...prev,
                        mobile: target.value !== '' ? target.value : undefined,
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="address"
                  label={t('settings.add/editPickup.pickupPoint.address')}
                  rules={[
                    {
                      required: pickPointData ? true : false,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <AutoComplete
                    placeholder={t('searchPlaceholders.searchAddress')}
                    autoFocus
                    style={{ width: '100%' }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    onChange={onSearchAddress}
                  >
                    {addresses.map((address: { place_name: string }) => (
                      <AutoComplete.Option
                        key={JSON.stringify(address)}
                        value={address.place_name}
                      >
                        {address.place_name}
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default PickupListModal;
