import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Skeleton,
  Tabs,
  Typography,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroFour from '../../components/FourZeroFour';
import FourZeroThree from '../../components/FourZeroThree';
import UserModal from '../../components/users/UserModal';
import AccountLogTable from '../../components/users/userDetail/AccountLogTable';
import OrderClaimTable from '../../components/users/userDetail/OrderClaimTable';
import UserAddressTable from '../../components/users/userDetail/UserAddressTable';
import UserCouponTable from '../../components/users/userDetail/UserCouponTable';
import UserDescription from '../../components/users/userDetail/UserDescription';
import UserDeviceTable from '../../components/users/userDetail/UserDeviceTable';
import UserIDTable from '../../components/users/userDetail/UserIDTable';
import UserLogTable from '../../components/users/userDetail/UserLogTable';
import UserOrderTable from '../../components/users/userDetail/UserOrderTable';
import UserPhoto from '../../components/users/userDetail/UserPhoto';
import UserSMSLogTable from '../../components/users/userDetail/UserSMSLogTable';
import UserValueCardTable from '../../components/users/userDetail/UserValueCardTable';
import { actionPermissions } from '../../constants/actionPermissions';
import { dashboardRoute } from '../../constants/pathname';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { UserListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';

const { TabPane } = Tabs;

/**
 * Detail Page for an individual user
 * /dashboard/users/detail?user_id=:id
 */
const UserDetail = () => {
  const queryString = window.location.search;
  const [userId] = useState(
    new URLSearchParams(queryString).get('user_id') ?? ''
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [userInfo, setUserInfo] = useState<UserListData>();
  const [loading, setLoading] = useState(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const isSubscribed = useRef(true);
  const [fourZeroFour, setFourZeroFour] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`users/detail?userId=${userId}`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (response.data) {
              setUserInfo(response.data);
              document.title = `${t('users.userDetail')} ${
                response.data.nickName || response.data.userName
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('users.userDetail')} ${
                    response.data.nickName || response.data.userName
                  }`,
                  path: `${dashboardRoute.users.detail}?user_id=${userId}`,
                })
              );
            } else setFourZeroFour(true);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          if (isSubscribed.current) setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [userId, dispatch, t]);

  const handleShowEdit = () => {
    setShowEdit(true);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fourZeroFour ? (
        <Card>
          <FourZeroFour />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <div style={{ marginTop: 5 }}>
          <Row gutter={[5, 5]}>
            <Col span={24} lg={4}>
              <Card
                style={{ height: '100%' }}
                bodyStyle={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Skeleton active loading={loading}>
                  <div style={{ textAlign: 'center' }}>
                    <UserPhoto src={userInfo?.userPicture} />
                  </div>
                  <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>
                    <Title level={3}>
                      {userInfo?.nickName
                        ? userInfo?.nickName
                        : userInfo?.userName}
                    </Title>
                  </div>
                </Skeleton>
              </Card>
            </Col>
            <Col span={24} lg={20}>
              <Card style={{ height: '100%' }}>
                <Skeleton active loading={loading}>
                  {userInfo ? (
                    <div style={{ marginTop: -25 }}>
                      <Tabs
                        defaultActiveKey="overView"
                        tabBarExtraContent={
                          <Button
                            disabled={
                              !hasPermission(
                                actionPermissions.userGroup.userManage
                              )
                            }
                            type="text"
                            icon={<EditOutlined />}
                            onClick={async () => {
                              handleShowEdit();
                            }}
                            style={{
                              display: getDashboardStyle(
                                process.env.REACT_APP_TYPE
                              ).isSalesForceAppType
                                ? 'none'
                                : undefined,
                            }}
                          ></Button>
                        }
                      >
                        <TabPane
                          style={{ height: 'auto' }}
                          tab={t('users.userDetailPage.overviewTitle')}
                          key="overView"
                        >
                          <UserDescription userInfo={userInfo} />
                        </TabPane>
                      </Tabs>
                    </div>
                  ) : (
                    <Empty />
                  )}
                </Skeleton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Skeleton active loading={loading}>
                  {userInfo ? (
                    <Tabs defaultActiveKey={'accountLogs'}>
                      {hasPermission(
                        actionPermissions.userGroup.accountManage
                      ) && (
                        <TabPane
                          tab={t('users.userDetailPage.accountLogs')}
                          key="accountLogs"
                        >
                          {hasPermission(
                            actionPermissions.userGroup.accountManage
                          ) && (
                            <AccountLogTable
                              id={userId}
                              userInfo={userInfo}
                              refresh={getData}
                            />
                          )}
                        </TabPane>
                      )}
                      {hasPermission(actionPermissions.userGroup.userLog) && (
                        <TabPane
                          tab={t('users.userDetailPage.userLogs')}
                          key="userLogs"
                        >
                          <UserLogTable id={userId} />
                        </TabPane>
                      )}
                      {hasPermission(
                        actionPermissions.userGroup.userAddress
                      ) && (
                        <TabPane
                          tab={t('users.userDetailPage.address')}
                          key="address"
                        >
                          <UserAddressTable id={userId} />
                        </TabPane>
                      )}
                      {hasPermission(
                        actionPermissions.userGroup.userDevice
                      ) && (
                        <TabPane
                          tab={t('users.userDetailPage.userDevices')}
                          key="devices"
                        >
                          <UserDeviceTable id={userId} />
                        </TabPane>
                      )}
                      {hasPermission(
                        actionPermissions.userGroup.userRealManage
                      ) && (
                        <TabPane
                          tab={t('users.userDetailPage.userID')}
                          key="userID"
                        >
                          <UserIDTable id={userId} />
                        </TabPane>
                      )}
                      {hasPermission(
                        actionPermissions.activityGroup.couponManage
                      ) &&
                        !getDashboardStyle().isSalesForceAppType && (
                          <TabPane
                            tab={t('users.userDetailPage.userCoupon')}
                            key="userCoupon"
                          >
                            <UserCouponTable id1={userId} />
                          </TabPane>
                        )}
                      {hasPermission(
                        actionPermissions.activityGroup.valueCard
                      ) && (
                        <TabPane
                          tab={t('users.userDetailPage.valueCards')}
                          key="valueCardList"
                        >
                          <UserValueCardTable userId={userId} />
                        </TabPane>
                      )}
                      {hasPermission(
                        actionPermissions.orderGroup.orderManage
                      ) && (
                        <TabPane
                          tab={t('users.userDetailPage.orders')}
                          key="orders"
                        >
                          <UserOrderTable id={userId} />
                        </TabPane>
                      )}
                      {hasPermission(
                        actionPermissions.orderGroup.orderClaimView
                      ) &&
                        !getDashboardStyle().isSalesForceAppType && (
                          <TabPane
                            tab={t('users.userDetailPage.orderClaimList')}
                            key="claimList"
                          >
                            <OrderClaimTable id={userId} />
                          </TabPane>
                        )}
                      {hasPermission(actionPermissions.pluginGroup.smsLog) &&
                        !getDashboardStyle().isSalesForceAppType && (
                          <TabPane
                            tab={t('users.userDetailPage.smsLogs')}
                            key="messages"
                          >
                            <UserSMSLogTable id={userId} userInfo={userInfo} />
                          </TabPane>
                        )}
                    </Tabs>
                  ) : (
                    <Empty />
                  )}
                </Skeleton>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <UserModal
        visible={showEdit}
        setVisible={setShowEdit}
        userProfile={userInfo}
        callBack={() => getData()}
      />
    </Container>
  );
};

export default UserDetail;
