import { SendOutlined } from '@ant-design/icons';
import { Button, Dropdown, Grid, Menu, Table, Typography } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableToolbar from '../../../components/table/TableToolbar';
import SendNotificationModal from '../../../components/users/SendNotificationModal';
import { useTab } from '../../../hooks/useTab';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { DeviceData, FontSizeType } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import FourZeroThree from '../../../components/FourZeroThree';
import { dashboardRoute } from '../../../constants/pathname';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../../constants/systemConstants';
import { compare, setFont } from '../../../utils/colComponents';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../FiveHundred';
import { actionPermissions } from '../../../constants/actionPermissions';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { tableScrollToTop } from '../../../utils/helperFunction';

type UserDeviceTableProps = {
  id?: string;
};

const UserDeviceTable = ({ id }: UserDeviceTableProps) => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [devices, setDevices] = useState<DeviceData[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalDevices, setTotalDevices] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const columnKeys = [
    'deviceId',
    'userId',
    'deviceCode',
    'platform',
    'version',
    'ipAddress',
    'language',
    'loginExpiryTime',
    'addTime',
    'updateTime',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [sendAll, setSendAll] = useState(false);
  const [keyword, setKeyword] = useState('');
  const { addTab } = useTab();
  const [selectedRows, setSelectedRows] = useState<DeviceData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getDeviceData = useCallback(() => {
    if (isSubscribed.current) setTableLoading(true);
    getDataWithAuthToken('users/device/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        userId: id ? id : undefined,
      },
    })
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus) {
            setDevices(response.data.list);
            setTotalDevices(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response && response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          setTableLoading(false);
        }
      })
      .catch((err) => {
        setTableLoading(false);
        console.log(err);
      });
  }, [t, page, pageSize, keyword, id]);

  useEffect(() => {
    getDeviceData();
  }, [getDeviceData]);

  const onDeleteDevices = () => {
    if (isSubscribed.current) setTableLoading(true);
    postDataWithAuthToken('users/device/delete_batch', {
      deviceIds: selectedRowKeys,
    })
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus) {
            getDeviceData();
            alertMessage('success', t('users.alerts.deviceDeleted'));
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setTableLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setTableLoading(false);
      });
  };

  const columns: ColumnsType<DeviceData> = [
    {
      title: setFont(t('users.userDeviceColumns.deviceId'), fontSize),
      key: 'deviceId',
      dataIndex: 'deviceId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) => compare(a.deviceId, b.deviceId),
    },
    {
      title: setFont(t('users.userDeviceColumns.userId'), fontSize),
      key: 'userId',
      dataIndex: 'userId',
      width: 100,
      sorter: (a: DeviceData, b: DeviceData) => compare(a.userId, b.userId),
      render: (text: string, record: DeviceData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            record.userId <= 0 ||
            !hasPermission(actionPermissions.userGroup.userView)
          }
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.users.detail}?user_id=${record.userId}`
            )
          }
        >
          {record.userId}
        </Button>
      ),
    },
    {
      title: setFont(t('users.userDeviceColumns.deviceCode'), fontSize),
      key: 'deviceCode',
      dataIndex: 'deviceCode',
      width: 350,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userDeviceColumns.platform'), fontSize),
      key: 'platform',
      dataIndex: 'platform',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) => compare(a.platform, b.platform),
    },
    {
      title: setFont(t('users.userDeviceColumns.version'), fontSize),
      key: 'version',
      dataIndex: 'version',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) => compare(a.version, b.version),
    },
    {
      title: setFont(t('users.userDeviceColumns.ipAddress'), fontSize),
      key: 'ipAddress',
      dataIndex: 'ipAddress',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) =>
        compare(a.ipAddress, b.ipAddress),
    },
    {
      title: setFont(t('users.userDeviceColumns.language'), fontSize),
      key: 'language',
      dataIndex: 'lang',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) => compare(a.lang, b.lang),
    },
    {
      title: setFont(t('users.userDeviceColumns.loginExpiry'), fontSize),
      key: 'loginExpiryTime',
      dataIndex: 'loginExpireTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) =>
        compare(a.loginExpireTime, b.loginExpireTime),
    },
    {
      title: setFont(t('users.userDeviceColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) => compare(a.addTime, b.addTime),
    },
    {
      title: setFont(t('users.userDeviceColumns.updateTime'), fontSize),
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DeviceData, b: DeviceData) =>
        compare(a.updateTime, b.updateTime),
    },
  ];

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {id ? (
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('users.userDevice')}
        </Typography.Title>
      ) : (
        <h1 style={{ fontSize: 24 }}>{t('users.userDevice')}</h1>
      )}
      <TableToolbar
        setFontSize={setFontSize}
        fontSize={fontSize}
        leftElement={
          getDashboardStyle().isSalesForceAppType ? undefined : (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item
                    key="sendSelected"
                    disabled={selectedRowKeys.length === 0}
                    onClick={() => setShowNotificationModal(true)}
                  >
                    {t('users.userDeviceColumns.toSelectedDevices')}
                  </Menu.Item>
                  {!id && (
                    <Menu.Item
                      key="sendAll"
                      onClick={() => {
                        setShowNotificationModal(true);
                        setSendAll(true);
                      }}
                    >
                      {t('users.userDeviceColumns.toAllDevices')}
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button
                disabled={
                  !hasPermission(actionPermissions.userGroup.userDevice)
                }
                icon={<SendOutlined />}
              >
                {t('users.sendNotifications.title')}
              </Button>
            </Dropdown>
          )
        }
        tableSize={tableSize}
        setTableSize={setTableSize}
        totalItems={totalDevices}
        refresh={() => getDeviceData()}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        rows={devices}
        search={(keyword) => {
          setKeyword(keyword);
          setPage(1);
        }}
        searchPlaceholder={t('searchPlaceholders.searchUserDeviceKeyword')}
        exportConfig={{ fileName: id ? `USER_DEVICE_ID_${id}` : 'USER_DEVICE' }}
      />
      <Table
        size={tableSize}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        dataSource={devices}
        loading={tableLoading}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowKey={(device) => device.deviceId}
        rowSelection={{
          selectedRowKeys,
          onChange: (rowKeys: React.Key[], rows: DeviceData[]) => {
            setSelectedRowKeys(rowKeys);
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total: totalDevices,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
          current: page,
        }}
      />
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          funct={{
            exportConfig: {
              fileName: id ? `USER_DEVICE_ID_${id}` : 'USER_DEVICE',
            },
            deleteFunc:
              hasPermission(actionPermissions.userGroup.userDevice) &&
              !getDashboardStyle().isSalesForceAppType
                ? onDeleteDevices
                : undefined,
          }}
        />
      )}
      <SendNotificationModal
        visible={showNotificationModal}
        setVisible={setShowNotificationModal}
        selectedDeviceIds={selectedRowKeys}
        devices={devices}
        sendAll={sendAll}
        setSendAll={setSendAll}
      />
    </>
  );
};

export default UserDeviceTable;
