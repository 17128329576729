import React, { useEffect, useRef } from 'react';
import { Descriptions, Tag, Typography } from 'antd';
import { SellerDetailData } from '../../../types';
import { useTranslation } from 'react-i18next';
import {
  BOOLEAN_TO_STATUS,
  REVIEW_STATUS_FAILED,
} from '../../../constants/generalConstants';

type SellerAdditionalDescriptionProps = {
  sellerInfo: SellerDetailData;
  callBack?: () => void;
};

/**
 * Displays User Information
 *
 * @param userInfo User Information to display
 * @param loading  Whether the User Information is still loading
 */
const SellerAdditionalDescription = ({
  sellerInfo,
}: SellerAdditionalDescriptionProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Descriptions>
      {sellerInfo.config && (
        <Descriptions.Item
          label={t('sellers.sellerDetailVariables.businessType.title')}
        >
          {t(
            `sellers.sellerDetailVariables.businessType.${sellerInfo.config.businessType}`
          )}
        </Descriptions.Item>
      )}
      {sellerInfo.config && (
        <Descriptions.Item
          label={t('sellers.sellerDetailVariables.useSharePayment')}
        >
          {t(BOOLEAN_TO_STATUS[sellerInfo.config.useSharePayment.toString()])}
        </Descriptions.Item>
      )}
      {sellerInfo.config && (
        <Descriptions.Item
          label={t('sellers.sellerDetailVariables.shopOpenStatus')}
        >
          {t(BOOLEAN_TO_STATUS[sellerInfo.config.shopOpenStatus.toString()])}
        </Descriptions.Item>
      )}
      {sellerInfo.config && (
        <Descriptions.Item label={t('sellers.sellerDetailVariables.isSelfRun')}>
          {t(BOOLEAN_TO_STATUS[sellerInfo.config.isSelfRun.toString()])}
        </Descriptions.Item>
      )}

      {sellerInfo.config && (
        <Descriptions.Item
          label={t('sellers.sellerDetailVariables.settlement')}
        >
          {`${t(
            BOOLEAN_TO_STATUS[sellerInfo.config.isSettlePercent.toString()]
          )} ${
            sellerInfo.config.isSettlePercent
              ? `, ${sellerInfo.config.settlePercentValue}%`
              : ''
          } `}
        </Descriptions.Item>
      )}
      {sellerInfo.config && (
        <Descriptions.Item label={t('sellers.sellerDetailVariables.sortOrder')}>
          {sellerInfo.config.sortOrder}
        </Descriptions.Item>
      )}
      {sellerInfo.config && (
        <Descriptions.Item
          label={t('sellers.sellerDetailVariables.reviewGoodsStatus')}
        >
          {t(BOOLEAN_TO_STATUS[sellerInfo.config.reviewGoodsStatus.toString()])}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={t('sellers.sellerDetailVariables.longitude')}>
        {sellerInfo.longitude}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.latitude')}
        span={2}
      >
        {sellerInfo.latitude}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.showRegionId')}
        span={3}
      >
        <Typography.Paragraph
          style={{ maxHeight: 75, overflow: 'auto' }}
          ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
        >
          {sellerInfo.showRegionId}
        </Typography.Paragraph>
      </Descriptions.Item>
      {sellerInfo.config && (
        <Descriptions.Item label={t('general.reviewStatus.title')} span={1}>
          <Tag
            color={
              sellerInfo.config.reviewStatus
                ? sellerInfo.config.reviewStatus.tagColor
                : undefined
            }
          >
            {sellerInfo.config.reviewStatus
              ? sellerInfo.config.reviewStatus.description
              : ''}
          </Tag>
        </Descriptions.Item>
      )}
      {sellerInfo.config && (
        <Descriptions.Item
          label={t('sellers.sellerDetailVariables.reviewContent')}
          style={{
            display:
              sellerInfo.config.reviewStatus.code === REVIEW_STATUS_FAILED
                ? ''
                : 'none',
          }}
          span={2}
        >
          {sellerInfo.config.reviewContent}
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.moreInfo.remark')}
        span={2}
      >
        {sellerInfo.remark}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default SellerAdditionalDescription;
