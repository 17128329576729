import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Input,
  Popconfirm,
  Popover,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import FiveHundred from '../../components/FiveHundred';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { useVT } from 'virtualizedtableforantd4';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { alertMessage } from '../../utils/alertMessage';
import { BasicEnumInfoType, FontSizeType, SalespersonData } from '../../types';
import TableToolbar from '../../components/table/TableToolbar';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/lib/table';
import { compare, setFont } from '../../utils/colComponents';
import SalespersonModal from '../../components/salesperson/SalespersonModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import { tableScrollToTop } from '../../utils/helperFunction';

const SalespersonList = () => {
  // General Component
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [salespersonStatus, setSalespersonStatus] = useState<string>('ALL');
  // Data Component
  const [salespersons, setSalespersons] = useState<any[]>([]);
  const [salespersonEnum, setSalespersonEnum] = useState<{
    [key: string]: BasicEnumInfoType[];
  }>({});
  const [selectedSalesperson, setSelectedSalesperson] =
    useState<SalespersonData>();
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  // Table Component
  const [keyword, setKeyword] = useState<string>('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<SalespersonData[]>([]);
  const columnKeys: string[] = [
    'salesPersonId',
    'userName',
    'fullName',
    'mobile',
    'status',
    'remark',
    'addTime',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);

  const columns: ColumnsType<SalespersonData> = [
    {
      title: setFont(t('salesperson.salesPersonId'), fontSize),
      dataIndex: 'salesPersonId',
      key: 'salesPersonId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      render: (text: string, record: SalespersonData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setShowModal(true);
            setSelectedSalesperson(record);
          }}
          disabled={
            !hasPermission(actionPermissions.salespersonGroup.salespersonManage)
          }
        >
          {text}
        </Button>
      ),
      sorter: (a: SalespersonData, b: SalespersonData) =>
        compare(a.salesPersonId, b.salesPersonId),
    },
    {
      title: setFont(t('salesperson.userName'), fontSize),
      dataIndex: 'userName',
      key: 'userName',
      render: (text: string) => setFont(text, fontSize),
      width: 160,
    },
    {
      title: setFont(t('salesperson.fullName'), fontSize),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text: string) => setFont(text, fontSize),
      width: 160,
    },
    {
      title: setFont(t('salesperson.mobile'), fontSize),
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text: string) => setFont(text, fontSize),
      width: 160,
    },
    {
      title: setFont(t('salesperson.status'), fontSize),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag
          style={{ fontSize: fontSize }}
          color={
            status === 'ACTIVE'
              ? 'success'
              : status === 'FORBIDDEN'
              ? 'error'
              : undefined
          }
        >
          {salespersonEnum &&
          salespersonEnum.salesPersonStatus &&
          salespersonEnum.salesPersonStatus.length > 0 &&
          salespersonEnum.salesPersonStatus.findIndex(
            (salesperson) => salesperson.code === status
          ) > -1
            ? salespersonEnum.salesPersonStatus[
                salespersonEnum.salesPersonStatus.findIndex(
                  (salesperson) => salesperson.code === status
                )
              ].description
            : status}
        </Tag>
      ),
      width: 160,
    },
    {
      title: setFont(t('salesperson.remark'), fontSize),
      dataIndex: 'remark',
      key: 'remark',
      render: (text: string) => setFont(text, fontSize),
      // width: 120,
    },
    {
      title: setFont(t('salesperson.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      render: (text: string) => setFont(text, fontSize),
      width: 160,
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 120,
      render: (record: SalespersonData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setShowModal(true);
              setSelectedSalesperson(record);
            }}
            disabled={
              !hasPermission(
                actionPermissions.salespersonGroup.salespersonManage
              )
            }
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => deleteSalesperson(record.salesPersonId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(
                actionPermissions.salespersonGroup.salespersonManage
              )
            }
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(
                  actionPermissions.salespersonGroup.salespersonManage
                )
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deleteSalesperson = (salesPersonId: number) => {
    setIsLoading(true);
    postDataWithAuthToken('sales_person/delete', {
      salesPersonId: salesPersonId,
    })
      .then((response) => {
        setIsLoading(false);
        if (response && response.goodStatus) {
          alertMessage('success', t('salesperson.alerts.salespersonDeleted'));
          getSalespersonList();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getSalespersonList = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('sales_person/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        username:
          formRef.current && form.getFieldValue('userName')
            ? form.getFieldValue('userName')
            : undefined,
        fullName:
          formRef.current && form.getFieldValue('fullName')
            ? form.getFieldValue('fullName')
            : undefined,
        mobile:
          formRef.current && form.getFieldValue('mobile')
            ? form.getFieldValue('mobile')
            : undefined,
        status: salespersonStatus === 'ALL' ? undefined : salespersonStatus,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response) {
          if (response.goodStatus) {
            setSalespersons(response.data.list);
            setTotal(response.data.totalItem);
            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else setFiveHundred(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [page, pageSize, t, form, keyword, salespersonStatus]);

  const getSalespersonEnumData = useCallback(() => {
    getDataWithAuthToken('sales_person/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setSalespersonEnum(response.data);
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getSalespersonEnumData();
  }, [getSalespersonEnumData]);

  useEffect(() => {
    getSalespersonList();
  }, [getSalespersonList]);

  // Advance search pop up component
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('salesperson.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item name="userName" label={t('salesperson.userName')}>
            <Input
              allowClear
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  userName: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item name="fullName" label={t('salesperson.fullName')}>
            <Input
              allowClear
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  fullName: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item name="mobile" label={t('salesperson.mobile')}>
            <Input
              allowClear
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  mobile: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 12 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getSalespersonList(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('general.search')}
              </Button>
              <Button
                disabled={
                  !advance.userName && !advance.fullName && !advance.mobile
                }
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getSalespersonList();
                }}
              >
                {t('general.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      <Card>
        {fourZeroThree ? (
          <FourZeroThree />
        ) : fiveHundred ? (
          <FiveHundred />
        ) : (
          <>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('salesperson.salespersonList')}
            </Typography.Title>
            <Tabs
              onTabClick={(key) => {
                if (key !== salespersonStatus) {
                  setSelectedRowKeys([]);
                  setSelectedRows([]);
                  setSalespersonStatus(key);
                  setPage(1);
                }
              }}
            >
              <Tabs.TabPane key="ALL" tab={t('general.all')} />
              {salespersonEnum &&
                salespersonEnum.salesPersonStatus &&
                salespersonEnum.salesPersonStatus.length > 0 &&
                salespersonEnum.salesPersonStatus.map((status) => (
                  <Tabs.TabPane key={status.code} tab={status.description} />
                ))}
            </Tabs>
            <TableToolbar
              leftElement={
                <>
                  <Button
                    icon={<PlusOutlined />}
                    disabled={
                      !hasPermission(
                        actionPermissions.salespersonGroup.salespersonManage
                      )
                    }
                    onClick={() => {
                      setSelectedSalesperson(undefined);
                      setShowModal(true);
                    }}
                  >
                    {t('salesperson.addTitle')}
                  </Button>
                </>
              }
              setFontSize={setFontSize}
              fontSize={fontSize}
              tableSize={tableSize}
              setTableSize={setTableSize}
              refresh={() => getSalespersonList()}
              totalItems={total}
              searchPlaceholder={t(
                'searchPlaceholders.searchSalespersonKeyword'
              )}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              rows={salespersons}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              exportConfig={{ fileName: 'SALESPERSON_LIST' }}
              advancedSearch={advancedSearch}
            />
            <Table<SalespersonData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={salespersons}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              size={tableSize}
              loading={isLoading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
              rowKey={(saleperson) => saleperson.salesPersonId}
              rowSelection={{
                selectedRowKeys,
                hideSelectAll: true,
                onChange: (
                  selectedRowKeys: React.Key[],
                  selectedRows: SalespersonData[]
                ) => {
                  setSelectedRowKeys(selectedRowKeys);
                  setSelectedRows(selectedRows);
                },
              }}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                selectedRows={selectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
                funct={{ exportConfig: { fileName: 'SALESPERSON_LIST' } }}
              />
            )}
          </>
        )}
      </Card>
      <SalespersonModal
        visible={showModal}
        setVisible={setShowModal}
        salesperson={selectedSalesperson}
        callBack={getSalespersonList}
        salespersonEnum={salespersonEnum}
      />
    </Container>
  );
};

export default SalespersonList;
