import React from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnHeightOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  DEFAULT_FONT_SIZE,
  LARGE_FONT_SIZE,
  MEDIUM_FONT_SIZE,
} from '../../constants/systemConstants';
import { FontSizeType } from '../../types';

type TableSizeChangeProps = {
  fontSize?: FontSizeType;
  setFontSize?: React.Dispatch<React.SetStateAction<FontSizeType>>;
  tableSize: SizeType;
  setTableSize: React.Dispatch<React.SetStateAction<SizeType>>;
  mobile?: boolean;
};

/**
 * Button to change size of Table
 *
 * @param tableSize    Current Table Size
 * @param setTableSize Change Table Size
 * @param mobile       Display mobile version of button
 * @param fontSize     Current font size
 * @param setFontSize  Change font size
 */
const TableSizeChange = ({
  tableSize,
  setTableSize,
  mobile,
  fontSize,
  setFontSize,
}: TableSizeChangeProps) => {
  const { t } = useTranslation();

  // table size: small -> middle -> large
  // font size: 14 -> 17 -> 20
  const handleTableSizeChange = () => {
    if (tableSize === 'large') {
      setTableSize('small');
      if (setFontSize && fontSize && fontSize === LARGE_FONT_SIZE) {
        setFontSize(DEFAULT_FONT_SIZE);
      }
    } else if (tableSize === 'middle') {
      setTableSize('large');
      if (setFontSize && fontSize && fontSize === MEDIUM_FONT_SIZE) {
        setFontSize(LARGE_FONT_SIZE);
      }
    } else {
      setTableSize('middle');
      if (setFontSize && fontSize) {
        setFontSize(MEDIUM_FONT_SIZE);
      }
    }
  };

  return mobile ? (
    <Button
      type="text"
      block
      onClick={handleTableSizeChange}
      icon={<ColumnHeightOutlined />}
      style={{ textAlign: 'left' }}
    >
      {t('general.changeSize')}
    </Button>
  ) : (
    <Tooltip title={t('general.changeSize')}>
      <Button
        type="text"
        onClick={handleTableSizeChange}
        icon={<ColumnHeightOutlined style={{ fontSize: DEFAULT_FONT_SIZE }} />}
      />
    </Tooltip>
  );
};

export default TableSizeChange;
