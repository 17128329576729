import { Card, Col, Row, Skeleton } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import OrderFee from '../../components/order/orderDetail/OrderFee';
import OrderBasicInfo from '../../components/order/orderDetail/OrderBasicInfo';
import OrderAddressInfo from '../../components/order/orderDetail/OrderAddressInfo';
import OrderProgress from '../../components/order/orderDetail/OrderProgress';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import FourZeroFour from '../../components/FourZeroFour';
import { OrderData, OrderEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import OrderGoods from '../../components/order/orderDetail/OrderGoodsTable';
import OrderOtherInfo from '../../components/order/orderDetail/OrderOtherInfo';
import OrderFormInfo from '../../components/order/orderDetail/OrderFormInfo';
import OrderActionLog from '../../components/order/orderDetail/OrderActionLogTable';
import OrderCombineInfo from '../../components/order/orderDetail/OrderCombineInfo';
import OrderPayLogTable from '../../components/order/orderDetail/OrderPayLogTable';
import OrderClaimInfo from '../../components/order/orderDetail/OrderClaimInfo';
import FiveHundred from '../../components/FiveHundred';
import OrderMainGoodsFee from '../../components/order/orderDetail/OrderMainGoodsFee';
import { dashboardRoute } from '../../constants/pathname';
import OrderCreditFormInfo from '../../components/order/orderDetail/OrderCreditFormInfo';
import getDashboardStyle from '../../utils/getDashboardStyle';

const OrderDetail = () => {
  const queryString = window.location.search;
  const [orderId] = useState(new URLSearchParams(queryString).get('order_id'));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fourZeroFour, setFourZeroFour] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderInfo, setOrderInfo] = useState<OrderData>();
  const isSubscribed = useRef(true);
  const [enums, setEnums] = useState<OrderEnum>();
  const [isCurrentEditing, setIsCurrentEditing] = useState<boolean>(false);

  const getOrderDetail = useCallback(() => {
    const getEnums = async (sellerId: number) => {
      getDataWithAuthToken(`order/enum_list?sellerId=${sellerId}`)
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setEnums(response.data);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('order/detail', { params: { orderId: orderId } })
      .then(async (response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (response.data) {
              setOrderInfo(response.data);
              document.title = `${t('order.orderDetail.title')} ${
                response.data.orderSn
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('order.orderDetail.title')} ${
                    response.data.orderSn
                  }`,
                  path: `${dashboardRoute.order.detail}?order_id=${orderId}`,
                })
              );
              if (response.data.seller) {
                await getEnums(response.data.seller.sellerId);
              }
            } else setFourZeroFour(true);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          if (isSubscribed.current) setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [dispatch, t, orderId]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  return (
    <Container style={{ marginTop: 5 }}>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fourZeroFour ? (
        <Card>
          <FourZeroFour />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Row gutter={[5, 5]}>
          {orderInfo && (
            <Col span={24}>
              <Card>
                <Skeleton active loading={isLoading}>
                  <OrderProgress orderInfo={orderInfo} />
                </Skeleton>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <OrderBasicInfo
                  orderInfo={orderInfo}
                  callBack={getOrderDetail}
                  enums={enums}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <OrderAddressInfo
                  orderInfo={orderInfo}
                  callBack={getOrderDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <OrderOtherInfo
                  orderInfo={orderInfo}
                  callBack={getOrderDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          {orderInfo &&
            orderInfo.orderExtend &&
            orderInfo.orderExtend.formValue &&
            !!orderInfo.orderExtend.formValue.length && (
              <Col span={24}>
                <Card>
                  <Skeleton active loading={isLoading}>
                    <OrderFormInfo
                      orderInfo={orderInfo}
                      callBack={getOrderDetail}
                      isCurrentEditing={isCurrentEditing}
                      setIsCurrentEditing={setIsCurrentEditing}
                    />
                  </Skeleton>
                </Card>
              </Col>
            )}
          {orderInfo &&
            orderInfo.orderExtend &&
            orderInfo.orderExtend.creditFormValue &&
            !!orderInfo.orderExtend.creditFormValue.length && (
              <Col span={24}>
                <Card>
                  <Skeleton active loading={isLoading}>
                    <OrderCreditFormInfo orderInfo={orderInfo} />
                  </Skeleton>
                </Card>
              </Col>
            )}
          {orderInfo && orderInfo.orderGoodsList && (
            <Col span={24}>
              <Card>
                <Skeleton active loading={isLoading}>
                  <OrderGoods
                    orderInfo={orderInfo}
                    callBack={getOrderDetail}
                    isCurrentEditing={isCurrentEditing}
                    setIsCurrentEditing={setIsCurrentEditing}
                    isRegionSale={
                      orderInfo &&
                      orderInfo.seller &&
                      orderInfo.seller.isRegionSale
                    }
                    enums={enums}
                  />
                </Skeleton>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <OrderFee
                  orderInfo={orderInfo}
                  callBack={getOrderDetail}
                  enums={enums}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          {orderInfo && orderInfo.seller && orderInfo.seller.isRegionSale && (
            <Col span={24}>
              <Card>
                <Skeleton active loading={isLoading}>
                  <OrderMainGoodsFee orderInfo={orderInfo} />
                </Skeleton>
              </Card>
            </Col>
          )}
          {orderInfo &&
            ((orderInfo.combineOrderList &&
              orderInfo.combineOrderList.length > 0) ||
              orderInfo.mainOrder) && (
              <Col span={24}>
                <Card>
                  <Skeleton active loading={isLoading}>
                    <OrderCombineInfo orderInfo={orderInfo} />
                  </Skeleton>
                </Card>
              </Col>
            )}
          {orderInfo &&
            orderInfo.payLogList &&
            orderInfo.payLogList.length > 0 && (
              <Col span={24}>
                <Card>
                  <Skeleton active loading={isLoading}>
                    <OrderPayLogTable orderInfo={orderInfo} />
                  </Skeleton>
                </Card>
              </Col>
            )}
          {orderInfo &&
            orderInfo.orderClaimList &&
            orderInfo.orderClaimList.length > 0 && (
              <Col span={24}>
                <Card>
                  <Skeleton active loading={isLoading}>
                    <OrderClaimInfo
                      orderInfo={orderInfo}
                      loading={isLoading}
                      callBack={getOrderDetail}
                    />
                  </Skeleton>
                </Card>
              </Col>
            )}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <OrderActionLog
                  orderInfo={orderInfo}
                  callBack={getOrderDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                  enums={enums}
                />
              </Skeleton>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default OrderDetail;
