/**
 *
 * @param setFirstLoad
 * @param setContentImgModalVisible
 * @returns JSX component of all the icons and drop down in any Quill Editor. Remember, if you are adding font types
 * and/or font sizes, add corresponding styles in index.less
 */

import {
  DeleteFilled,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

type EditorToolbarProps = {
  setContentModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstLoad: React.Dispatch<React.SetStateAction<boolean>>;
  fullscreenMode: boolean;
  setFullscreenMode: React.Dispatch<React.SetStateAction<boolean>>;
  data?: string;
  setData: React.Dispatch<React.SetStateAction<string | undefined>>;
  setClear: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditorToolbar = ({
  setContentModalVisible,
  setFirstLoad,
  fullscreenMode,
  setFullscreenMode,
  data,
  setData,
  setClear,
}: EditorToolbarProps) => {
  const { t } = useTranslation();
  return (
    <>
      <select className="ql-header">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
      <select className="ql-font">
        <option value="arial">{'Arial'}</option>
        <option value="comic-sans">{'Comic Sans'}</option>
        <option value="georgia">{'Georgia'}</option>
        <option value="helvetica">{'Helvetica'}</option>
        <option value="lucida">{'Lucida'}</option>
      </select>
      <select className="ql-size">
        <option value="extra-small">{'13'}</option>
        <option value="small">{'14'}</option>
        <option value="medium">{'18'}</option>
        <option value="large">{'20'}</option>
      </select>
      <select className="ql-align">
        <option value=""></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
      <button className="ql-bold"></button>
      <select className="ql-color" />
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
      <button className="ql-formula" />
      <button className="ql-background" />
      <Tooltip title={t('editor.mediaToolTip')}>
        {
          <PaperClipOutlined
            style={{ padding: 5 }}
            onClick={() => {
              setFirstLoad(true);
              setContentModalVisible(true);
            }}
          />
        }
      </Tooltip>
      <Tooltip title={t('editor.FullScreenToolTip')}>
        {fullscreenMode ? (
          <FullscreenExitOutlined
            className="ql-fullscreen"
            style={{ fontSize: 15 }}
            onClick={() => {
              setFullscreenMode((prev) => !prev);
            }}
          />
        ) : (
          <FullscreenOutlined
            className="ql-fullscreenExit"
            style={{ fontSize: 15 }}
            onClick={() => {
              setFullscreenMode((prev) => !prev);
            }}
          />
        )}
      </Tooltip>
      <Tooltip title={t('editor.ClearToolTip')} /** Clear Editor*/>
        <Popconfirm
          disabled={data ? false : true}
          title={'editor.clearWarning'}
          onConfirm={() => {
            setData(undefined);
            setClear(true);
          }}
          okText={t('actionsColumn.confirmation.yes')}
          cancelText={t('actionsColumn.confirmation.no')}
          placement="topRight"
        >
          <DeleteFilled style={{ padding: 5 }} />
        </Popconfirm>
      </Tooltip>
    </>
  );
};

export default EditorToolbar;
