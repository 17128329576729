import { Form, Input, Modal, Select, Spin, Typography } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import {
  ShippingData,
  TPLConfigObj,
  TPLData,
  TransportData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { GetTPLConfigFormFields } from '../../settings/shipping/GetTPLConfigFormFields';
import RegionTreeSelect from '../common/RegionTreeSelect';

type AddEditTPLModalProps = {
  setViewType: React.Dispatch<React.SetStateAction<string>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  tplInfo?: TPLData;
  setCalled: React.Dispatch<React.SetStateAction<boolean>>;
  mainRegionIDData: number[];
  setMainRegionIDData: React.Dispatch<React.SetStateAction<any[]>>;
  transInfo?: TransportData;
};

const AddEditTPLModal = ({
  setViewType,
  visible,
  setVisible,
  tplInfo,
  setCalled,
  mainRegionIDData,
  setMainRegionIDData,
  transInfo,
}: AddEditTPLModalProps) => {
  //General components
  const [isEditTPLModalLoading, setIsEditTPLModalLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Data components
  const [selectedShippingId, setSelectedShippingId] = useState(-1);
  const [tplConfigData, setTplConfigData] = useState<TPLConfigObj[]>([]);
  const [shippingList, setShippingList] = useState<ShippingData[]>([]);

  /**
   * makes a request and grabs the tpl config info and loads it
   */
  const getTPLConfigData = useCallback(
    (shippingCode?: string) => {
      if (isSubscribed.current && shippingCode) setIsEditTPLModalLoading(true);
      shippingCode &&
        getDataWithAuthToken('shipping/transport/tpl/config', {
          params: { shippingCode: shippingCode },
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) {
                  setTplConfigData(response.data.list);
                }
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsEditTPLModalLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsEditTPLModalLoading(true);
            console.log(err);
          });
    },
    [t]
  );

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * makes a request and grabs the shippinglist with isEnabled(yes) + isDeliveryOnly(yes) and loads it
   */
  const getShippingData = (value?: string) => {
    if (isSubscribed.current) setIsEditTPLModalLoading(true);
    getDataWithAuthToken('shipping/list', {
      params: {
        keyword: value || undefined,
        isEnabled: true,
        isDeliveryOnly: true,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setShippingList(response.data.list);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        if (isSubscribed.current) setIsEditTPLModalLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsEditTPLModalLoading(true);
        console.log(err);
      });
  };

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      getTPLConfigData(tplInfo?.shipping.shippingCode);
    }
  }, [form, visible, tplInfo, getTPLConfigData]);

  const onClose = () => {
    if (isSubscribed.current) {
      setMainRegionIDData([]);
      setViewType('view');
      setSelectedShippingId(-1);
      setVisible(false);
    }
  };

  /**
   * @param values Form values
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isEditTPLModalLoading) {
          setIsEditTPLModalLoading(true);
        }
        let configData: any[] = [];
        Object.keys(values).forEach((key) => {
          values[key] &&
            key !== 'shippingId' &&
            key !== 'tplId' &&
            key !== 'tplName' &&
            key !== 'regionId' &&
            configData.push({
              name: key,
              value: String(
                typeof values[key] === 'boolean'
                  ? values[key]
                    ? 1
                    : 0
                  : values[key]
              ),
            });
        });

        postDataWithAuthToken(
          tplInfo
            ? 'shipping/transport/tpl/edit'
            : 'shipping/transport/tpl/add',
          {
            tplId: tplInfo ? tplInfo.tplId : undefined,
            tplName: form.getFieldValue('tplName')
              ? form.getFieldValue('tplName')
              : undefined,
            regionId: form.getFieldValue('regionId').join(','),
            configure: configData,
            transportId:
              !tplInfo && transInfo?.transportId
                ? transInfo.transportId
                : undefined,
            shippingId:
              !tplInfo && form.getFieldValue('shippingId')
                ? parseInt(form.getFieldValue('shippingId').split(', ')[0])
                : undefined,
          }
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              tplInfo
                ? t('settings.alerts.tplEdited')
                : t('settings.alerts.tplAdded')
            );
            setCalled(false);
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isEditTPLModalLoading) {
            setIsEditTPLModalLoading(false);
          }
        });
      })
      .catch((err: any) => {
        if (isEditTPLModalLoading) {
          setIsEditTPLModalLoading(false);
        }
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        getContainer={false}
        maskClosable={false}
        onOk={onFinish}
        onCancel={onClose}
        width={550}
        bodyStyle={{ height: 'auto' }}
        style={{ height: 500 }}
        title={
          tplInfo
            ? `${t('settings.add/edit/viewModal.editTPLTitle')} ${
                tplInfo.tplId
              }`
            : `${t('settings.add/edit/viewModal.addTPLTitle')}`
        }
        visible={visible}
      >
        {tplInfo ? (
          <Spin spinning={isEditTPLModalLoading}>
            <Form
              form={form}
              initialValues={
                tplInfo
                  ? {
                      ...tplInfo,
                    }
                  : {}
              }
              onFinish={onFinish}
            >
              <Form.Item /**For TPL name */
                name={'tplName'}
                label={t('settings.tpl.tplName')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                <Input style={{ width: 200 }} />
              </Form.Item>
              <Form.Item /**For Region ID */
                name="regionId"
                label={t('settings.add/edit/viewModal.regionId')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <RegionTreeSelect
                  initialValue={tplInfo.regionId.join()}
                  disabledCondition={(value) => {
                    return (
                      mainRegionIDData.includes(value) &&
                      !tplInfo?.regionId.includes(value)
                    );
                  }}
                />
              </Form.Item>
              {tplConfigData &&
                tplConfigData.map((config) => {
                  return GetTPLConfigFormFields(config, tplInfo.configure);
                })}
            </Form>
          </Spin>
        ) : (
          <Spin spinning={isEditTPLModalLoading}>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={
                tplInfo
                  ? {}
                  : {
                      shippingId:
                        selectedShippingId !== -1
                          ? selectedShippingId
                          : undefined,
                    }
              }
            >
              <Form.Item /**Displaying Transport ID*/
                name={'transportID'}
                label={t('settings.add/edit/viewModal.transportId')}
              >
                <Typography.Text>{transInfo?.transportId}</Typography.Text>
              </Form.Item>
              <Form.Item /**For Shipping ID  */
                name="shippingId"
                label={t('settings.add/edit/viewModal.shippingId')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 250 }}
                  value={String(selectedShippingId)}
                  placeholder={t('searchPlaceholders.searchShippingId')}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onFocus={() => {
                    if (!shippingList.length) getShippingData();
                  }}
                  onSearch={(value) => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getShippingData(value), EXTENDED_TIMEOUT)
                    );
                  }}
                  onSelect={(shippingObj: string) => {
                    setSelectedShippingId(parseInt(shippingObj.split(', ')[0]));
                    getTPLConfigData(shippingObj.split(', ')[1]);
                  }}
                  optionLabelProp="key"
                  filterOption={false}
                >
                  {shippingList.map((shippingInfo) => (
                    <Select.Option
                      key={shippingInfo.shippingId}
                      value={
                        shippingInfo.shippingId +
                        ', ' +
                        shippingInfo.shippingCode
                      }
                    >
                      <div>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography.Text type="secondary">
                            {t('settings.add/edit/viewModal.shippingId')}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            {t('settings.add/edit/viewModal.shippingName')}
                          </Typography.Text>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography>{shippingInfo.shippingId}</Typography>
                          <Typography>{shippingInfo.shippingName}</Typography>
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item /**For TPL name */
                name={'tplName'}
                label={t('settings.tpl.tplName')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input style={{ width: 200 }} />
              </Form.Item>
              <Form.Item /**For Region ID */
                name="regionId"
                label={t('settings.add/edit/viewModal.regionId')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <RegionTreeSelect
                  disabledCondition={(value) => {
                    return mainRegionIDData.includes(value);
                  }}
                />
              </Form.Item>
              {tplConfigData &&
                tplConfigData.map((config) => {
                  return GetTPLConfigFormFields(config);
                })}
            </Form>
          </Spin>
        )}
      </Modal>
    </>
  );
};

export default AddEditTPLModal;
