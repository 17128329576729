import { RightOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popover,
  Radio,
  Select,
  Switch,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_FAILED,
} from '../../constants/generalConstants';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import { BasicEnumInfoType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import BatchFailedModal from '../BatchFailedModal';
import { onExportToExcel } from '../table/TableExport';
import GoodsBatchModal from './GoodsBatchModal';
import CategoryDropdown from './common/CategoryDropdown';
import DepartmentDropdown from './common/DepartmentDropdown';
import SupplierDropdown from './common/SupplierDropdown';
import BrandDropdown from './common/BrandDropdown';

type BatchGoodsTableFooterToolbarProps = {
  mode: 'goods' | 'relatedGoods';
  goodInfo: { navType?: string };
  general: {
    advance?: any;
    setAdvance?: React.Dispatch<React.SetStateAction<any>>;
  };
  selectedRows: any[];
  columns?: ColumnsType<any>;
  funct: {
    refresh?: Function;
    deleteFunc?: () => void;
    exportConfig?: { fileName: string; url?: string };
  };
  form: FormInstance<any>;
  selectedRowKeys?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const BatchGoodsTableFooterToolbar = ({
  mode,
  form,
  selectedRows,
  selectedRowKeys,
  setSelectedRowKeys,
  columns,
  funct,
  general,
  goodInfo,
}: BatchGoodsTableFooterToolbarProps) => {
  //General Components
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const [addEditCatMode, setAddEditCatMode] = useState<
    | 'goods/edit/batch/add_extend_cat'
    | 'goods/edit/batch/cat'
    | 'goods/edit/batch/clear_extend_cat'
  >();
  const [isReviewEnabled, setIsReviewEnabled] = useState(false);
  //Visible/Statuses Components
  const [batchImportVis, setBatchImportVis] = useState(false);
  const [popOverDeleteVis, setPopOverDeleteVis] = useState(false);
  const [popOverRestoreVis, setPopOverRestoreVis] = useState(false);
  const [popOverStatus, setPopOverStatus] = useState<boolean>();
  const [promoType, setPromoType] = useState(0);
  const [batchFailedVis, setBatchFailedVis] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  const [batchFailObjs, setBatchFailObjs] = useState<{
    dataType?: 'GOODS' | 'ORDER' | 'TASK';
    data?: any[];
  }>({});
  const [goodsTags, setGoodsTags] = useState<BasicEnumInfoType[]>([]);

  // Fetch goods tag list
  const getGoodsTags = () => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setGoodsTags(response.data.goodsTag);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendGoodsBatchRequest = (
    batchRequest: string,
    body?: { [key: string]: any }
  ) => {
    setIsLoading(true);
    setBatchFailedVis(true);
    postDataWithAuthToken(
      batchRequest === 'goods/delete_batch' ? batchRequest : batchRequest,
      {
        goodsIds:
          batchRequest !== 'goods/related_goods/import_batch' && selectedRowKeys
            ? selectedRowKeys
            : undefined,
        goodsList:
          batchRequest === 'goods/related_goods/import_batch' && selectedRows
            ? selectedRows.map((obj) => {
                return {
                  goodsId: obj.goodsId,
                  goodsName: obj.goodsName,
                  goodsUnit: obj.goodsUnit,
                  shopPrice: obj.shopPrice,
                };
              })
            : undefined,
        isPurchaseLimit:
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? form.getFieldValue('isPurchaseLimit')
            : undefined,
        purchaseLimitNum:
          form.getFieldValue('purchaseLimitNum') &&
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? form.getFieldValue('purchaseLimitNum')
            : undefined,
        purchaseLimitStartDate:
          form.getFieldValue('purchaseLimitStartDate') &&
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? moment(form.getFieldValue('purchaseLimitStartDate')[0]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        purchaseLimitEndDate:
          form.getFieldValue('purchaseLimitStartDate') &&
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? moment(form.getFieldValue('purchaseLimitStartDate')[1]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        isPromote:
          batchRequest === 'goods/edit/batch/promote'
            ? form.getFieldValue('isPromote')
            : undefined,
        promotePercent:
          form.getFieldValue('promotePercent') &&
          batchRequest === 'goods/edit/batch/promote'
            ? form.getFieldValue('promotePercent')
            : undefined,
        promoteAmount:
          form.getFieldValue('promoteAmount') &&
          batchRequest === 'goods/edit/batch/promote'
            ? parseFloat(form.getFieldValue('promoteAmount'))
            : undefined,
        promoteStartDate:
          form.getFieldValue('promoteDate') &&
          batchRequest === 'goods/edit/batch/promote'
            ? moment(form.getFieldValue('promoteDate')[0]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        promoteEndDate:
          form.getFieldValue('promoteDate') &&
          batchRequest === 'goods/edit/batch/promote'
            ? moment(form.getFieldValue('promoteDate')[1]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        catId:
          form.getFieldValue('search_catId') &&
          (batchRequest === 'goods/edit/batch/cat' ||
            batchRequest === 'goods/edit/batch/add_extend_cat' ||
            batchRequest === 'goods/edit/batch/clear_extend_cat')
            ? form.getFieldValue('search_catId')[
                form.getFieldValue('search_catId').length - 1
              ]
            : undefined,
        brandId:
          batchRequest === 'goods/edit/batch/brand'
            ? form.getFieldValue('brandId')
            : undefined,
        supplierId:
          batchRequest === 'goods/edit/batch/supplier'
            ? form.getFieldValue('supplierId')
            : undefined,
        isOnSale:
          batchRequest === 'goods/edit/batch/sale_status'
            ? form.getFieldValue('isOnSale')
            : undefined,
        eventTag:
          form.getFieldValue('eventTag') &&
          batchRequest === 'goods/edit/batch/event'
            ? form.getFieldValue('eventTag')
            : undefined,
        eventTagStatus:
          batchRequest === 'goods/edit/batch/event'
            ? form.getFieldValue('eventTagStatus')
            : undefined,
        goodsTag:
          batchRequest === 'goods/edit/batch/goods_tag'
            ? form.getFieldValue('goodsTag')
            : undefined,
        departmentId:
          batchRequest === 'goods/edit/batch/department'
            ? form.getFieldValue('departmentId')
            : undefined,
        reviewStatus:
          batchRequest === 'goods/edit/batch/review'
            ? form.getFieldValue('reviewStatus')
            : undefined,
        reviewContent:
          batchRequest === 'goods/edit/batch/review' &&
          form.getFieldValue('reviewStatus') === REVIEW_STATUS_FAILED
            ? form.getFieldValue('reviewContent')
            : undefined,
        isUpdatePrice:
          batchRequest === 'goods/related_goods/sync' && body
            ? body.isUpdatePrice
            : undefined,
        gst:
          batchRequest === 'goods/edit/batch/gst'
            ? form.getFieldValue('gst')
            : undefined,
        pst:
          batchRequest === 'goods/edit/batch/pst'
            ? form.getFieldValue('pst')
            : undefined,
        keywords:
          batchRequest === 'goods/edit/batch/keywords'
            ? form.getFieldValue('keywords') || ''
            : undefined,
        shippingTransportId:
          batchRequest === 'goods/related_goods/import_batch'
            ? form.getFieldValue('shippingTransportId')
            : undefined,
      },
      { timeout: 0 }
    )
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setBatchFailedVis(false);
            if (setSelectedRowKeys) setSelectedRowKeys([]);
            if (funct.refresh) funct.refresh();
            if (batchRequest === 'goods/trash_batch')
              alertMessage('success', t('goods.alerts.batchArchived'));
            else if (batchRequest === 'goods/edit/batch/clear_extend_cat')
              alertMessage('success', t('goods.alerts.extendedCatCleared'));
            else if (batchRequest === 'goods/delete_batch')
              alertMessage('success', t('goods.alerts.batchDeleted'));
            else if (batchRequest === 'goods/restore_batch')
              alertMessage('success', t('goods.alerts.batchRestored'));
            else alertMessage('success', t('goods.alerts.batchEdited'));
          } else if (!response.goodStatus && !response.batchStatus) {
            alertMessage('error', t('general.fourFourFour'));
            setBatchFailObjs({
              dataType: response.data.errorType,
              data: response?.data.list,
            });
          } else {
            setBatchFailedVis(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setBatchFailedVis(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Check if review is enabled
  const getReviewEnabled = useCallback(() => {
    getDataWithAuthToken('goods/review_enabled')
      .then((response) => {
        if (response && response.goodStatus) {
          setIsReviewEnabled(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  }, [t]);

  useEffect(() => {
    if (mode === 'goods') {
      getReviewEnabled();
    }
  }, [mode, getReviewEnabled]);

  return (
    <>
      {mode === 'goods' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {hasPermission(actionPermissions.goodGroup.goodManage) && (
            <>
              <Popover /**Popover for department*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="departmentId"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseSelectOne'),
                        },
                      ]}
                      label={t('goods.goodsBatch.department')}
                    >
                      <DepartmentDropdown
                        onChange={(value) => {
                          general.setAdvance &&
                            value &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              departmentId: value,
                            }));
                          setPopOverStatus(true);
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={isLoading || !general.advance.departmentId}
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/department');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={!general.advance.departmentId}
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display:
                      goodInfo && goodInfo.navType === 'TRASH'
                        ? 'none'
                        : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editDepartment')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for brand*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="brandId"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseSelectOne'),
                        },
                      ]}
                      label={t('goods.goodsBatch.brand')}
                    >
                      <BrandDropdown
                        onChange={(value) => {
                          general.setAdvance &&
                            value &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              brandId: value,
                            }));
                          setPopOverStatus(true);
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={isLoading || !general.advance.brandId}
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/brand');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('general.ok')}
                      </Button>
                      <Button
                        disabled={!general.advance.brandId}
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('general.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display:
                      goodInfo && goodInfo.navType === 'TRASH'
                        ? 'none'
                        : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editBrand')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for supplier*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="supplierId"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseSelectOne'),
                        },
                      ]}
                      label={t('goods.add/editSupplier.title')}
                    >
                      <SupplierDropdown
                        onChange={(value) => {
                          general.setAdvance &&
                            value &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              supplierId: value,
                            }));
                          setPopOverStatus(true);
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={isLoading || !general.advance.supplierId}
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/supplier');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={!general.advance.supplierId}
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display:
                      goodInfo && goodInfo.navType === 'TRASH'
                        ? 'none'
                        : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editSupplier')}
                  <RightOutlined />
                </Button>
              </Popover>
              {isReviewEnabled /**Popover for review status*/ && (
                <Popover
                  trigger="click"
                  placement="rightTop"
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  onVisibleChange={() => {
                    form.resetFields();
                    if (general.setAdvance) general.setAdvance({});
                    setPopOverStatus(false);
                  }}
                  content={
                    <div
                      style={{
                        padding: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'max-content',
                      }}
                    >
                      <Form.Item
                        name="reviewStatus"
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.pleaseSelectOne'),
                          },
                        ]}
                        label={t('goods.goodsBatch.reviewStatus')}
                      >
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode as HTMLElement
                          }
                          placeholder={t('general.pleaseSelect')}
                          onChange={(value) => {
                            general.setAdvance &&
                              value &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                reviewStatus: value,
                              }));
                            setPopOverStatus(true);
                          }}
                        >
                          {Object.keys(REVIEW_STATUS).map(
                            (type: string, index) => (
                              <Select.Option key={index} value={type}>
                                {t(REVIEW_STATUS[type])}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      {general &&
                        general.advance &&
                        general.advance.reviewStatus ===
                          REVIEW_STATUS_FAILED && (
                          <Form.Item
                            name="reviewContent"
                            label={t('goods.goodsBatch.reviewContent')}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                            rules={[
                              {
                                required: general.advance.reviewStatus
                                  ? true
                                  : false,
                                message: t('general.inputError.empty'),
                              },
                            ]}
                          >
                            <Input.TextArea
                              onChange={(e) => {
                                general.setAdvance &&
                                  general.setAdvance((prev: any) => ({
                                    ...prev,
                                    reviewContent: e.target.value,
                                  }));
                              }}
                              autoSize={{ minRows: 2 }}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        )}

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                          loading={isLoading}
                          disabled={isLoading || !general.advance.reviewStatus}
                          type="primary"
                          size="small"
                          onClick={() => {
                            sendGoodsBatchRequest('goods/edit/batch/review');
                            setPopOverStatus(false);
                            form.resetFields();
                            if (general.setAdvance) general.setAdvance({});
                          }}
                        >
                          {t('goods.goodsBatch.ok')}
                        </Button>
                        <Button
                          disabled={!general.advance.reviewStatus}
                          size="small"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            setPopOverStatus(false);
                            form.resetFields();
                            if (general.setAdvance) general.setAdvance({});
                          }}
                        >
                          {t('admin.adminLogsColumns.advancedSearch.reset')}
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <Button
                    block
                    type="text"
                    style={{
                      display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {t('goods.goodsBatch.editReviewStatus')}
                    <RightOutlined />
                  </Button>
                </Popover>
              )}
              <Popover /**Popover for purchase limit */
                style={{ display: goodInfo.navType === 'TRASH' ? 'none' : '' }}
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields([
                    'isPurchaseLimit',
                    'purchaseLimitNum',
                    'purchaseLimitStartDate',
                  ]);
                  if (general.setAdvance)
                    general.setAdvance((prev: any) => ({
                      ...prev,
                      isPurchaseLimit: 'default',
                    }));
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Item
                        name="isPurchaseLimit"
                        valuePropName="checked"
                        label={t('goods.goodsBatch.isPurchaseLimit')}
                      >
                        <Select
                          placeholder={t('general.pleaseSelect')}
                          style={{ minWidth: 90 }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          filterOption={false}
                          optionLabelProp="key"
                          onChange={(value) => {
                            if (general.setAdvance)
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                isPurchaseLimit: value,
                              }));
                            setPopOverStatus(
                              value === 'default' ? false : value
                            );
                            value === false &&
                              form.setFieldsValue({
                                purchaseLimitNum: undefined,
                                purchaseLimitStartDate: undefined,
                              });
                          }}
                        >
                          <Select.Option
                            key={`${t('general.pleaseSelect')}`}
                            value={'default'}
                          >
                            {t('general.pleaseSelect')}
                          </Select.Option>
                          <Select.Option
                            key={`${t('general.booleanToStatus.true')}`}
                            value={true}
                          >
                            {t('general.booleanToStatus.true')}
                          </Select.Option>
                          <Select.Option
                            key={`${t('general.booleanToStatus.false')}`}
                            value={false}
                          >
                            {t('general.booleanToStatus.false')}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="purchaseLimitNum"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'goods.inputError.pleaseInputLimitValue'
                            ),
                          },
                        ]}
                        style={{
                          marginLeft: 10,
                          display: popOverStatus ? '' : 'none',
                        }}
                        label={t('goods.goodsBatch.purchaseLimitNum')}
                      >
                        <InputNumber
                          type="number"
                          min={1}
                          onChange={(values) => {
                            general.setAdvance &&
                              values &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                purchaseLimit: values,
                              }));
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name="purchaseLimitStartDate"
                        style={{
                          display: popOverStatus ? '' : 'none',
                        }}
                        label={t('goods.goodsBatch.purchaseLimitDate')}
                      >
                        <RangePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode as HTMLElement
                          }
                          style={{ maxWidth: 240 }}
                          showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [
                              moment('00:00:00', 'HH:mm:ss'),
                              moment('00:00:00', 'HH:mm:ss'),
                            ],
                          }}
                          format={DATE_TIME_FORMAT}
                          placeholder={[
                            t('goods.goodsBatch.startDate'),
                            t('goods.goodsBatch.endDate'),
                          ]}
                          onChange={(values) => {
                            general.setAdvance &&
                              values &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                startDate: moment(values[0]).format(
                                  DATE_TIME_FORMAT
                                ),
                                endDate: moment(values[1]).format(
                                  DATE_TIME_FORMAT
                                ),
                              }));
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={
                          isLoading ||
                          general.advance.isPurchaseLimit === 'default'
                        }
                        type="primary"
                        size="small"
                        htmlType="submit"
                        onClick={() => {
                          if (general.setAdvance)
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              isPurchaseLimit: 'default',
                            }));
                          sendGoodsBatchRequest(
                            'goods/edit/batch/purchase_limit'
                          );
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                          setPopOverStatus(false);
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        size="small"
                        style={{ marginLeft: 5 }}
                        disabled={general.advance.isPurchaseLimit === 'default'}
                        onClick={() => {
                          form.resetFields();
                          if (general.setAdvance)
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              isPurchaseLimit: 'default',
                            }));
                          setPopOverStatus(false);
                        }}
                      >
                        {t('goods.goodsBatch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editPurchaseLimit')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for promo info */
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                trigger="click"
                onVisibleChange={() => {
                  form.resetFields();
                  setPopOverStatus(false);
                  setPromoType(0);
                  if (general.setAdvance)
                    general.setAdvance((prev: any) => ({
                      ...prev,
                      isPromote: 'default',
                    }));
                }}
                placement="rightTop"
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Form.Item
                        name="isPromote"
                        valuePropName="checked"
                        label={t('goods.goodsBatch.isPromote')}
                      >
                        <Select
                          placeholder={t('general.pleaseSelect')}
                          style={{ minWidth: 120 }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          filterOption={false}
                          optionLabelProp="key"
                          onChange={(value) => {
                            if (general.setAdvance)
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                isPromote: value,
                              }));
                            setPopOverStatus(
                              value === 'default' ? false : value
                            );
                            value === false &&
                              form.setFieldsValue({
                                purchaseLimitNum: undefined,
                                purchaseLimitStartDate: undefined,
                              });
                          }}
                        >
                          <Select.Option
                            key={`${t('general.pleaseSelect')}`}
                            value={'default'}
                          >
                            {t('general.pleaseSelect')}
                          </Select.Option>
                          <Select.Option
                            key={`${t('general.booleanToStatus.true')}`}
                            value={true}
                          >
                            {t('general.booleanToStatus.true')}
                          </Select.Option>
                          <Select.Option
                            key={`${t('general.booleanToStatus.false')}`}
                            value={false}
                          >
                            {t('general.booleanToStatus.false')}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="promoType"
                        label={t('goods.goodsBatch.setPromoType')}
                        style={{
                          marginLeft: 10,
                          display: popOverStatus ? '' : 'none',
                        }}
                        extra={t('goods.goodsBatch.promoTooltip')}
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.pleaseSelectOne'),
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={(e) => setPromoType(e.target.value)}
                        >
                          <Radio value={1}>{t('%')}</Radio>
                          <Radio value={2}>{t('$')}</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name="promotePercent"
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.pleaseInputAmount'),
                          },
                        ]}
                        style={{
                          marginLeft: 10,
                          display:
                            promoType === 1 && popOverStatus ? '' : 'none',
                        }}
                        label={t('goods.goodsBatch.promotePercent')}
                      >
                        <InputNumber
                          type="number"
                          min={0}
                          max={100}
                          onChange={(values) => {
                            general.setAdvance &&
                              values &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                promoAmount: values,
                              }));
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="promoteAmount"
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.pleaseInputAmount'),
                          },
                        ]}
                        style={{
                          marginLeft: 10,
                          display:
                            promoType === 2 && popOverStatus ? '' : 'none',
                        }}
                        label={t('goods.goodsBatch.promoteAmount')}
                      >
                        <InputNumber<string>
                          stringMode
                          step="0.01"
                          min="0"
                          onChange={(values) => {
                            general.setAdvance &&
                              values &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                promoAmount: values,
                              }));
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name="promoteDate"
                        style={{ display: popOverStatus ? '' : 'none' }}
                        label={t('goods.goodsBatch.promoteDate')}
                      >
                        <RangePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode as HTMLElement
                          }
                          style={{ maxWidth: 240 }}
                          showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [
                              moment('00:00:00', 'HH:mm:ss'),
                              moment('00:00:00', 'HH:mm:ss'),
                            ],
                          }}
                          format={DATE_TIME_FORMAT}
                          placeholder={[
                            t('goods.goodsBatch.startDate'),
                            t('goods.goodsBatch.endDate'),
                          ]}
                          onChange={(values) => {
                            values &&
                              general.setAdvance &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                startDate: moment(values[0]).format(
                                  DATE_TIME_FORMAT
                                ),
                                endDate: moment(values[1]).format(
                                  DATE_TIME_FORMAT
                                ),
                              }));
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button // OK button
                        loading={isLoading}
                        disabled={
                          isLoading || general.advance.isPromote === 'default'
                        }
                        size="small"
                        type="primary"
                        onClick={() => {
                          if (general.setAdvance)
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              isPromote: 'default',
                            }));
                          sendGoodsBatchRequest('goods/edit/batch/promote');
                          form.resetFields();
                          setPopOverStatus(false);
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button // Reset button
                        style={{ marginLeft: 5 }}
                        size="small"
                        disabled={general.advance.isPromote === 'default'}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance)
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              isPromote: 'default',
                            }));
                        }}
                      >
                        {t('goods.goodsBatch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editPromoInfo')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for add/edit/delete goods category*/
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                trigger="click"
                placement="right"
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="search_catId"
                      rules={[
                        {
                          required:
                            addEditCatMode !==
                            'goods/edit/batch/clear_extend_cat',
                          message: t('general.inputError.pleaseSelectOne'),
                        },
                      ]}
                      label={t('goods.goodsBatch.catId')}
                    >
                      <CategoryDropdown
                        onFocusFetch={true}
                        onChange={(value) => {
                          if (general.setAdvance)
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              categoryID: value,
                            }));
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={
                          isLoading ||
                          (addEditCatMode !==
                            'goods/edit/batch/clear_extend_cat' &&
                            !general.advance.categoryID)
                        }
                        size="small"
                        type="primary"
                        onClick={() => {
                          addEditCatMode &&
                            sendGoodsBatchRequest(addEditCatMode);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                          setAddEditCatMode(undefined);
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        size="small"
                        disabled={!general.advance.categoryID}
                        onClick={() => {
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: 'max-content',
                  }}
                >
                  <Button
                    block
                    type="text"
                    style={{
                      display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() =>
                      setAddEditCatMode('goods/edit/batch/add_extend_cat')
                    }
                  >
                    {t('goods.goodsBatch.addExtendCategory')}
                    <RightOutlined />
                  </Button>
                  <Button
                    block
                    type="text"
                    danger
                    style={{
                      display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() =>
                      setAddEditCatMode('goods/edit/batch/clear_extend_cat')
                    }
                  >
                    {t('goods.goodsBatch.clearExtendCategory')}
                    <RightOutlined />
                  </Button>
                  <Button
                    block
                    type="text"
                    style={{
                      display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => setAddEditCatMode('goods/edit/batch/cat')}
                  >
                    {t('goods.goodsBatch.editGoodsCategory')}
                    <RightOutlined />
                  </Button>
                </div>
              </Popover>
              <Popover /**Popover for sale status*/
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                trigger="click"
                placement="rightTop"
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="isOnSale"
                      label={t('goods.goodsBatch.isOnSale')}
                      initialValue={false}
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) => {
                          checked &&
                            general.setAdvance &&
                            general.setAdvance &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              isOnSale: checked,
                            }));
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={isLoading}
                        size="small"
                        type="primary"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/sale_status');
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        size="small"
                        disabled={!general.advance.isOnSale}
                        onClick={() => {
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editSaleStatus')}
                  <RightOutlined />
                </Button>
              </Popover>
              {/**Batch Edit Price Modal*/}
              {goodInfo.navType === 'UNREVIEWED' &&
                getDashboardStyle().isSellersCoAppType && (
                  <Button
                    block
                    type="text"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => setBatchImportVis(true)}
                  >
                    {mode === 'goods'
                      ? t('goods.goodsBatch.editPrice')
                      : t('goods.goodsBatch.importMainGoods')}
                  </Button>
                )}
              <Popover /**Popover for GST*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="gst"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseInputAmount'),
                        },
                      ]}
                      label={t('goods.goodsBatch.gst')}
                    >
                      <InputNumber
                        type="number"
                        precision={2}
                        step={0.01}
                        min={0}
                        max={99}
                        style={{ minWidth: 120 }}
                        onChange={(value) => {
                          general.setAdvance &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              gst: value,
                            }));
                          setPopOverStatus(true);
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={
                          isLoading ||
                          general.advance.gst === undefined ||
                          general.advance.gst === null
                        }
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/gst');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={
                          general.advance.gst === undefined ||
                          general.advance.gst === null
                        }
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editGST')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for PST*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="pst"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseInputAmount'),
                        },
                      ]}
                      label={t('goods.goodsBatch.pst')}
                    >
                      <InputNumber
                        type="number"
                        precision={2}
                        step={0.01}
                        min={0}
                        max={99}
                        style={{ minWidth: 120 }}
                        onChange={(value) => {
                          general.setAdvance &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              pst: value,
                            }));
                          setPopOverStatus(true);
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={
                          isLoading ||
                          general.advance.pst === undefined ||
                          general.advance.pst === null
                        }
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/pst');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={
                          general.advance.pst === undefined ||
                          general.advance.pst === null
                        }
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editPST')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for keyword*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="keywords"
                      label={t('goods.goodsBatch.keywords')}
                    >
                      <Input
                        style={{ minWidth: 120 }}
                        onChange={(value) => {
                          general.setAdvance &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              keywords: value,
                            }));
                          setPopOverStatus(true);
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={isLoading}
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/keywords');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={
                          general.advance.keywords === undefined ||
                          general.advance.keywords === null
                        }
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editKeywords')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for goods tag*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <Form.Item
                      name="goodsTag"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseSelectOne'),
                        },
                      ]}
                      label={t('goods.goodsBatch.goodsTag.title')}
                    >
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        placeholder={t('general.pleaseSelect')}
                        onChange={(value) => {
                          value &&
                            general.setAdvance &&
                            general.setAdvance((prev: any) => ({
                              ...prev,
                              goodsTag: value,
                            }));
                          setPopOverStatus(true);
                        }}
                        onFocus={() => {
                          if (!goodsTags.length) getGoodsTags();
                        }}
                      >
                        {goodsTags.map((tag) => (
                          <Select.Option key={tag.code} value={tag.code}>
                            {tag.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={isLoading || !general.advance.goodsTag}
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/goods_tag');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={!general.advance.goodsTag}
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editGoodsTag')}
                  <RightOutlined />
                </Button>
              </Popover>
              <Popover /**Popover for event tag*/
                trigger="click"
                placement="rightTop"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  form.resetFields();
                  if (general.setAdvance) general.setAdvance({});
                  setPopOverStatus(false);
                }}
                content={
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'max-content',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Item
                        name="eventTag"
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.pleaseSelectOne'),
                          },
                        ]}
                        label={t('goods.goodsBatch.eventTag.title')}
                      >
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode as HTMLElement
                          }
                          placeholder={t('general.pleaseSelect')}
                          onChange={(value) => {
                            value &&
                              general.setAdvance &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                eventTag: value,
                              }));
                            setPopOverStatus(true);
                          }}
                        >
                          <Select.Option value="IS_NEW">
                            {t('goods.goodsBatch.eventTag.IS_NEW')}
                          </Select.Option>
                          <Select.Option value="IS_BEST">
                            {t('goods.goodsBatch.eventTag.IS_BEST')}
                          </Select.Option>
                          <Select.Option value="IS_HOT">
                            {t('goods.goodsBatch.eventTag.IS_HOT')}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="eventTagStatus"
                        valuePropName="checked"
                        rules={[{ required: true }]}
                        style={{
                          marginLeft: 10,
                          display: popOverStatus ? '' : 'none',
                        }}
                        label={t('goods.goodsBatch.eventTagStatus')}
                        initialValue={false}
                      >
                        <Switch
                          onChange={(checked) => {
                            checked &&
                              general.setAdvance &&
                              general.setAdvance((prev: any) => ({
                                ...prev,
                                eventTagStatus: checked,
                              }));
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        loading={isLoading}
                        disabled={
                          isLoading ||
                          (!general.advance.eventTag &&
                            !general.advance.eventTagStatus)
                        }
                        type="primary"
                        size="small"
                        onClick={() => {
                          sendGoodsBatchRequest('goods/edit/batch/event');
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('goods.goodsBatch.ok')}
                      </Button>
                      <Button
                        disabled={
                          !general.advance.eventTag &&
                          !general.advance.eventTagStatus
                        }
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setPopOverStatus(false);
                          form.resetFields();
                          if (general.setAdvance) general.setAdvance({});
                        }}
                      >
                        {t('admin.adminLogsColumns.advancedSearch.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display: goodInfo.navType === 'TRASH' ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {t('goods.goodsBatch.editEventTag')}
                  <RightOutlined />
                </Button>
              </Popover>
            </>
          )}
          <Popover /**Archive/Restore button */
            overlayStyle={{ width: 195 }}
            placement="rightTop"
            trigger="click"
            visible={popOverRestoreVis}
            onVisibleChange={(visible) => {
              !visible && setPopOverRestoreVis(false);
            }}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            content={
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography.Text style={{ fontWeight: 500 }}>
                  {goodInfo.navType === 'TRASH'
                    ? t('goods.alerts.groupRestoreWarning')
                    : t('goods.alerts.groupArchiveWarning')}
                </Typography.Text>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    size="small"
                    onClick={() => setPopOverRestoreVis(false)}
                  >
                    {t('actionsColumn.confirmation.no')}
                  </Button>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    type="primary"
                    size="small"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      setPopOverRestoreVis(false);
                      goodInfo.navType === 'TRASH'
                        ? sendGoodsBatchRequest('goods/restore_batch')
                        : sendGoodsBatchRequest('goods/trash_batch');
                    }}
                  >
                    {t('actionsColumn.confirmation.yes')}
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              block
              type="text"
              danger={true}
              onClick={() => {
                setPopOverRestoreVis(true);
              }}
              style={{ textAlign: 'left' }}
            >
              {goodInfo.navType === 'TRASH'
                ? t('goods.goodsBatch.restoreSelected')
                : t('goods.goodsBatch.archiveSelected')}
            </Button>
          </Popover>
          {goodInfo.navType === 'TRASH' && (
            <Popover /**Delete button */
              overlayStyle={{ width: 195 }}
              placement="rightTop"
              trigger="click"
              visible={popOverDeleteVis}
              onVisibleChange={(visible) => {
                !visible && setPopOverDeleteVis(false);
              }}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <div
                  style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography.Text style={{ fontWeight: 500 }}>
                    {goodInfo.navType === 'TRASH' &&
                      t('goods.alerts.groupDeleteWarning')}
                  </Typography.Text>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      onClick={() => setPopOverRestoreVis(false)}
                    >
                      {t('actionsColumn.confirmation.no')}
                    </Button>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      type="primary"
                      size="small"
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        setPopOverRestoreVis(false);
                        goodInfo.navType === 'TRASH' &&
                          sendGoodsBatchRequest('goods/delete_batch');
                      }}
                    >
                      {t('actionsColumn.confirmation.yes')}
                    </Button>
                  </div>
                </div>
              }
            >
              <Button
                block
                type="text"
                danger={true}
                onClick={() => {
                  setPopOverDeleteVis(true);
                }}
                style={{ textAlign: 'left' }}
              >
                {goodInfo.navType === 'TRASH' &&
                  t('goods.goodsBatch.deleteSelected')}
              </Button>
            </Popover>
          )}
          {selectedRows && columns && funct.exportConfig && (
            <Button
              block
              type="text"
              onClick={() =>
                onExportToExcel(columns, selectedRows, funct.exportConfig)
              }
              style={{ textAlign: 'left' }}
            >
              {t('actionsColumn.exportSelected')}
            </Button>
          )}
        </div>
      ) : mode === 'relatedGoods' ? (
        <>
          {hasPermission(actionPermissions.settingGroup.shipView) && (
            <Button
              block
              type="text"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => setBatchImportVis(true)}
            >
              {t('goods.goodsBatch.importMainGoods')}
            </Button>
          )}
          {selectedRows && columns && funct.exportConfig && (
            <Button
              block
              type="text"
              onClick={() =>
                onExportToExcel(columns, selectedRows, funct.exportConfig)
              }
              style={{ textAlign: 'left' }}
            >
              {t('actionsColumn.exportSelected')}
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
      <GoodsBatchModal
        visible={batchImportVis}
        setVisible={setBatchImportVis}
        mode={mode}
        selectedRows={selectedRows}
        refresh={funct.refresh}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <BatchFailedModal
        failedObjects={batchFailObjs}
        visible={batchFailedVis}
        setVisible={setBatchFailedVis}
        selectedRowKeys={selectedRowKeys}
        loading={isLoading}
      />
    </>
  );
};

export default BatchGoodsTableFooterToolbar;
