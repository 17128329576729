import { Form, FormInstance, Switch } from 'antd';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { UserListData, Viewport } from '../../../types';
import UsersDropdown from '../../users/common/UsersDropdown';

type UserFormItemProps = {
  userId: string | undefined;
  isAnonymousUser: boolean;
  setIsAnonymousUser?: React.Dispatch<SetStateAction<boolean>>;
  setSelectedUser: React.Dispatch<SetStateAction<UserListData | undefined>>;
  setViewport: React.Dispatch<SetStateAction<Viewport | undefined>>;
  form: FormInstance<any>;
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
};

const UserFormItem = ({
  userId,
  isAnonymousUser,
  setIsAnonymousUser,
  setSelectedUser,
  setViewport,
  form,
  formData,
  setFormData,
}: UserFormItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!userId && setIsAnonymousUser && (
        <Form.Item label={`${t('order.add/editOrder.anonymous')}: `}>
          <Switch
            checked={isAnonymousUser}
            onChange={(checked) => {
              setIsAnonymousUser(checked);
              setSelectedUser(undefined);
              if (checked) {
                setFormData((prev: any) => ({
                  ...prev,
                  userId: 0,
                  address: '',
                  addressType: '',
                  buzzerCode: '',
                  consignee: '',
                  email: '',
                  latitude: 0,
                  longitude: 0,
                  mobile: '',
                  postalCode: '',
                  tel: '',
                  unitNumber: '',
                }));
              } else {
                form.setFieldsValue({ userId: undefined });
              }
            }}
          />
        </Form.Item>
      )}
      {!isAnonymousUser && (
        <Form.Item
          label={`${t('order.add/editOrder.user')}: `}
          name="userId"
          rules={[
            {
              required: true,
              message: t('general.inputError.pleaseSelectOne'),
            },
          ]}
        >
          <UsersDropdown
            disabled={userId ? true : false}
            initialValue={
              userId
                ? Number(userId)
                : formData.userId
                ? formData.userId
                : undefined
            }
            onChange={(value, option: any) => {
              if (option.user) {
                setSelectedUser(option.user);
                setFormData((prev: any) => ({
                  ...prev,
                  userId: value,
                  address: '',
                  addressType: '',
                  buzzerCode: '',
                  consignee: '',
                  email: '',
                  latitude: 0,
                  longitude: 0,
                  mobile: '',
                  postalCode: '',
                  tel: '',
                  unitNumber: '',
                }));
                setViewport({
                  longitude: 0,
                  latitude: 0,
                  zoom: 15,
                });
              }
            }}
          />
        </Form.Item>
      )}
    </>
  );
};

export default UserFormItem;
