import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import { PrintType, PrintTypeMode } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import GeneralPrintContent from '../GeneralPrintContent';

type OrderPrintModalProps = {
  mode: PrintTypeMode;
  printoutTableData: PrintType;
  onCancel: Function;
  isFullVersion?: boolean;
};

const OrderPrintModal = ({
  mode,
  printoutTableData,
  onCancel,
  isFullVersion = true,
}: OrderPrintModalProps) => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const orderIds = printoutTableData.orderIds ?? [];
  const routeId = printoutTableData.routeId;
  const [modalLoading, setModalLoading] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    isSubscribed.current = false;
  }, []);

  const onPrintRequest = () => {
    setModalLoading(true);
    postDataWithAuthToken(
      routeId === undefined && orderIds.length === 1
        ? 'order/operate'
        : 'order/batch',
      {
        orderIds: !(routeId === undefined && orderIds.length === 1)
          ? orderIds
          : undefined,
        orderId:
          routeId === undefined && orderIds.length === 1
            ? orderIds[0]
            : undefined,
        operate: 'PRINT',
        routeId: routeId ? routeId : undefined,
      }
    )
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('info', t('order.alerts.printing'));
          console.log(
            `Order ID: ${orderIds}, Route ID: ${
              routeId ? routeId : 'N/A'
            }, Time of Print: ${moment().format(DATE_TIME_FORMAT)}`
          );
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setModalLoading(false);
      });
  };

  return (
    <GeneralPrintContent<PrintType>
      mode={mode}
      printoutTableData={
        {
          ordersData: printoutTableData.ordersData,
        } as PrintType
      }
      onCancel={onCancel}
      isFullVersion={isFullVersion}
      beforePrint={
        mode === 'order'
          ? {
              function: onPrintRequest,
              isLoading: modalLoading,
              setIsLoading: setModalLoading,
            }
          : undefined
      }
    />
  );
};

export default OrderPrintModal;
