import { Form, Modal, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { CreditCardData } from '../../types';

type CreditCardModalProps = {
  ccData?: CreditCardData;
  loading: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreditCardModal = ({
  ccData,
  loading,
  visible,
  setVisible,
}: CreditCardModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  //Data components
  //Text Editor Components

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      footer={null}
      onCancel={onClose}
      title={`${t('order.payLogListColumns.ccTitle')} ${
        ccData ? ccData.cardId : undefined
      }`}
      visible={visible}
    >
      <Spin spinning={loading}>
        {ccData && (
          <Form form={form}>
            <Form.Item /**CC Number*/
              label={t('order.payLogListColumns.cardNum')}
            >
              {ccData.cardNum}
            </Form.Item>
            <Form.Item /**CC Expire Month*/
              label={t('order.payLogListColumns.expireMonth')}
            >
              {ccData.expireMonth}
            </Form.Item>
            <Form.Item /**CC Expire Year*/
              label={t('order.payLogListColumns.expireYear')}
            >
              {ccData.expireYear}
            </Form.Item>
            <Form.Item /**CC Postal Code*/
              label={t('order.payLogListColumns.postalCode')}
            >
              {ccData.postalCode}
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default CreditCardModal;
