import Select, { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdSettingAdPositionData } from '../../../types';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { Typography } from 'antd';

type AdPositionDropdownProps = {
  labelInValue?: boolean;
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const AdPositionDropdown = ({
  labelInValue = false,
  disabled = false,
  initialValue,
  onChange,
}: AdPositionDropdownProps) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [adPosition, setAdPosition] = useState<AdSettingAdPositionData[]>([]);
  const [adPositionPage, setAdPositionPage] = useState(2);
  const [adPositionTotalPage, setAdPositionTotalPage] = useState(2);

  const getAdPosition = (value?: string) => {
    getDataWithAuthToken('ad_position/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setAdPosition(response.data.list);
          setAdPositionPage(2);
          setAdPositionTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const adPositionFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (adPositionPage > adPositionTotalPage) return;
      getDataWithAuthToken('ad_position/list', {
        params: {
          keyword: searchText.adPosition || undefined,
          page: adPositionPage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setAdPosition((prev) => [...prev, ...response.data.list]);
            setAdPositionPage((prev) => prev + 1);
            setAdPositionTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={
        !hasPermission(actionPermissions.advertisementGroup.adView) || disabled
      }
      labelInValue={labelInValue}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={adPositionFetchOnScroll}
      showSearch
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, adPosition: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getAdPosition(value), EXTENDED_TIMEOUT)
        );
      }}
      onFocus={() => {
        if (!adPosition.length) getAdPosition();
      }}
      filterOption={false}
      placeholder={t('searchPlaceholders.searchAdPosition')}
      defaultValue={initialValue ? initialValue : undefined}
      onChange={handleOnChange}
      optionLabelProp="key"
    >
      {adPosition.map((position) => (
        <Select.Option
          key={`${position.positionName} (${position.positionId})`}
          value={position.positionId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('advertisement.adPositionColumnList.positionId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('advertisement.adPositionColumnList.positionName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{position.positionId}</Typography>
              <Typography>{position.positionName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AdPositionDropdown;
