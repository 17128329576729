import React, { useCallback, useEffect, useRef, useState } from 'react';
import Container from '../components/Container';
import {
  Card,
  Avatar,
  Typography,
  Row,
  Col,
  Empty,
  Table,
  List,
  Button,
  Skeleton,
  Grid,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getDataWithAuthToken, getMyProfile } from '../utils/axiosRequest';
import { AdminListData, AdminLogData } from '../types';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import ChangeProfileModal from '../components/ChangeProfileModal';
import { alertMessage } from '../utils/alertMessage';
import { useVT } from 'virtualizedtableforantd4';
import getDashboardStyle from '../utils/getDashboardStyle';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

/**
 * View for User's own profile
 *
 * /dashboard/profile
 *
 * @param route Route information from
 */
const MyProfile = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const [adminData, setAdminData] = useState<AdminListData | undefined>();
  const [adminLogs, setAdminLogs] = useState<Array<AdminLogData>>([]);
  const [totalLogs, setTotalLogs] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [actionList, setActionList] = useState<Array<string>>([]);
  const [adminLoading, setAdminLoading] = useState(true);
  const isSubscribed = useRef(true);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getProfile = useCallback(() => {
    if (isSubscribed.current) setAdminLoading(true);
    getMyProfile()
      .then((data) => {
        if (data) {
          if (isSubscribed.current) {
            setAdminData(data);
            setActionList(data.actionList.split(','));
          }
        } else {
          alertMessage('error', t('general.noResponse'), undefined);
        }
        if (isSubscribed.current) setAdminLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setAdminLoading(false);
      });
  }, [t]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  /**
   * @param userId  We only want logs from the actual user
   * @param page    Page Number
   */
  const getLogs = useCallback(
    (userId: number, page: number = 1) => {
      if (isSubscribed.current) setTableLoading(true);
      getDataWithAuthToken(
        `admin/log/list?adminId=${userId}&page=${page}&size=15`
      )
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setAdminLogs(response.data.list);
              setTotalLogs(response.data.totalItem);
              setTableLoading(false);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setTableLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (isSubscribed.current) setTableLoading(false);
        });
    },
    [t]
  );

  useEffect(() => {
    if (adminData) {
      getLogs(adminData.adminId);
    }
  }, [adminData, getLogs]);

  const columns: ColumnsType<AdminLogData> = [
    {
      title: t('profile.profileColumns.logId'),
      dataIndex: 'logId',
      key: 'logId',
      width: 100,
    },
    {
      title: t('profile.profileColumns.logTime'),
      dataIndex: 'logTime',
      key: 'logTime',
      width: 160,
    },
    {
      title: t('profile.profileColumns.ipAddress'),
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      width: 160,
    },
    {
      title: t('profile.profileColumns.logInfo'),
      dataIndex: 'logInfo',
      key: 'logInfo',
      width: 400,
    },
  ];

  return (
    <>
      <Container>
        <div style={{ marginTop: 5 }}>
          <Row gutter={5}>
            <Col span={24} lg={{ span: 8 }}>
              <Card
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Skeleton active loading={adminLoading}>
                  {adminData ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          size={128}
                          icon={<UserOutlined />}
                          style={{
                            backgroundColor: getDashboardStyle(
                              process.env.REACT_APP_TYPE
                            ).mainColor,
                          }}
                        />
                        <Title level={2} style={{ margin: 0, marginTop: 14 }}>
                          {adminData.adminUserName}
                        </Title>
                        <Text style={{ marginTop: 14 }}>
                          {adminData.sellerName}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 24,
                        }}
                      >
                        <div>
                          <Text strong>
                            {t('profile.profileColumns.email')}:{' '}
                          </Text>
                          {adminData.email}
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <Text strong>
                            {t('profile.profileColumns.addTime')}:{' '}
                          </Text>
                          {adminData.addTime}
                        </div>
                        <Text strong style={{ marginTop: 8 }}>
                          {t('admin.add/editAdmin.actions')}:
                        </Text>
                        <List
                          size="small"
                          dataSource={actionList}
                          renderItem={(action) => (
                            <List.Item>
                              <Text>{action}</Text>
                            </List.Item>
                          )}
                          pagination={{
                            size: 'small',
                            pageSize: 7,
                            simple: true,
                            hideOnSinglePage: true,
                          }}
                          style={{ width: '100%', fontSize: '12' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: 24,
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => setProfileModalVisible(true)}
                        >
                          {t('profile.edit/ChangeProfile.editTitle')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Empty />
                  )}
                </Skeleton>
              </Card>
            </Col>
            <Col span={24} lg={{ span: 16 }}>
              <Card>
                {adminLogs.length > 0 && (
                  <Table<AdminLogData>
                    loading={tableLoading}
                    columns={columns}
                    dataSource={adminLogs}
                    size="small"
                    components={vt}
                    scroll={{ y: 600, x: 1200 }}
                    rowKey={(log) => log.logId}
                    pagination={{
                      simple: screens.xs,
                      total: totalLogs,
                      showQuickJumper: true,
                      showSizeChanger: false,
                      size: 'small',
                      defaultPageSize: 15,
                      showTotal: (total, range) =>
                        t('general.paginationTotal', {
                          start: range[0],
                          end: range[1],
                          total: total,
                        }),
                      onChange: (page, pSize) => {
                        if (adminData) {
                          getLogs(adminData.adminId, page);
                        }
                      },
                    }}
                    title={() => (
                      <h1 style={{ fontSize: 24 }}>
                        {adminData?.adminUserName}
                        {': '}
                        {t('admin.adminLogs').toLowerCase()} ({totalLogs})
                      </h1>
                    )}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </div>
        {adminData && (
          <ChangeProfileModal
            visible={profileModalVisible}
            setVisible={setProfileModalVisible}
            userEmail={adminData?.email}
            callBack={getProfile}
          />
        )}
      </Container>
    </>
  );
};

export default MyProfile;
