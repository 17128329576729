import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Table,
  Card,
  Button,
  Space,
  Popconfirm,
  Grid,
  Form,
  Typography,
  Image,
  Popover,
  Select,
  Badge,
} from 'antd';
import Container from '../../components/Container';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ArticleData, FontSizeType } from '../../types';
import { ColumnsType } from 'antd/lib/table';
import FourZeroThree from '../../components/FourZeroThree';
import TableToolbar from '../../components/table/TableToolbar';
import { alertMessage } from '../../utils/alertMessage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import { compare, setFont } from '../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import { hasPermission } from '../../utils/hasPermission';
import { FALLBACK_IMG } from '../../constants/styles';
import ArticleModal from '../../components/advertisement/ArticleModal';
import FiveHundred from '../../components/FiveHundred';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Article List Table
 * Can add, or delete article
 *
 * /dashboard/advertisement/articleList
 *
 */
const ArticleList = () => {
  //General Components
  const formRef = useRef(null);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const isSubscribed = useRef(true);
  const screens = useBreakpoint();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  //Data Components
  const [articleData, setArticleData] = useState<Array<ArticleData>>([]);
  const [articleDetail, setArticleDetail] = useState<ArticleData>();
  //Table Components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [total, setTotal] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'articleId',
    'articleCat',
    'thumbImg',
    'title',
    'keyword',
    'briefDesc',
    'externalLink',
    'isRecommend',
    'isEnabled',
    'sortOrder',
    'addTime',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter(
      (key) =>
        key !== 'thumbImg' &&
        key !== 'keyword' &&
        key !== 'briefDesc' &&
        key !== 'externalLink'
    )
  );

  const columns: ColumnsType<ArticleData> = [
    {
      title: setFont(t('advertisement.articleColumnList.articleId'), fontSize),
      dataIndex: 'articleId',
      key: 'articleId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: ArticleData, b: ArticleData) =>
        compare(a.articleId, b.articleId),
      render: (text: string, record: ArticleData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            !hasPermission(actionPermissions.advertisementGroup.articleManage)
          }
          onClick={() => {
            getArticleDetailData(record.articleId);
            setModalVisible(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('advertisement.articleColumnList.articleCat'), fontSize),
      dataIndex: 'articleCat',
      key: 'articleCat',
      width: 200,
      render: (text: string, record: ArticleData) =>
        setFont(
          record && record.articleCat ? record.articleCat.articleCatName : '',
          fontSize
        ),
    },
    {
      title: setFont(t('advertisement.articleColumnList.thumbImg'), fontSize),
      dataIndex: 'thumbImg',
      key: 'thumbImg',
      width: 100,
      render: (text: string) => (
        <Image
          width={70}
          src={text}
          preview={{ mask: <EyeOutlined /> }}
          fallback={FALLBACK_IMG}
        />
      ),
    },
    {
      title: setFont(t('advertisement.articleColumnList.title'), fontSize),
      dataIndex: 'title',
      key: 'title',
      width: 500,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('advertisement.articleColumnList.keyword'), fontSize),
      dataIndex: 'keyword',
      key: 'keyword',
      width: 180,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('advertisement.articleColumnList.briefDesc'), fontSize),
      dataIndex: 'briefDesc',
      key: 'briefDesc',
      width: 180,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('advertisement.articleColumnList.externalLink'),
        fontSize
      ),
      dataIndex: 'externalLink',
      key: 'externalLink',
      width: 180,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('advertisement.articleColumnList.isRecommend'),
        fontSize
      ),
      dataIndex: 'isRecommend',
      key: 'isRecommend',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('advertisement.articleColumnList.isEnabled'), fontSize),
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('advertisement.articleColumnList.sortOrder'), fontSize),
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: ArticleData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.articleManage)
            }
            onClick={() => {
              getArticleDetailData(record.articleId);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => deleteArticle(record.articleId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.articleManage)
            }
          >
            <Button
              danger
              type="link"
              size="small"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(
                  actionPermissions.advertisementGroup.articleManage
                )
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Fetches Articles
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`article/list`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword ? keyword : undefined,
        isEnabled:
          formRef.current &&
          typeof form.getFieldValue('isEnabled') === 'boolean'
            ? form.getFieldValue('isEnabled')
            : undefined,
        isRecommend:
          formRef.current &&
          typeof form.getFieldValue('isRecommend') === 'boolean'
            ? form.getFieldValue('isRecommend')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setArticleData(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, form]);

  /**
   * Fetches Articles
   */
  const getArticleDetailData = (articleId: number) => {
    if (isSubscribed.current) setLoadingModal(true);
    getDataWithAuthToken(`article/detail`, {
      params: {
        articleId: articleId,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setArticleDetail(response.data);
            setModalVisible(true);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoadingModal(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoadingModal(false);
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  /**
   * @param id Id of article to delete
   */
  const deleteArticle = (id: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('article/delete', { articleId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('advertisement.alerts.articleDeleted'));
          getData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('advertisement.articleColumnList.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all', isRecommend: 'all' }}
        >
          <Form.Item
            name="isEnabled"
            label={t(
              'advertisement.articleColumnList.advancedSearch.isEnabled'
            )}
          >
            <Select
              style={{ width: 140 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              optionLabelProp="key"
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  isEnabled: value === 'all' ? '' : value.toString(),
                }));
              }}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="isRecommend"
            label={t(
              'advertisement.articleColumnList.advancedSearch.isRecommend'
            )}
          >
            <Select
              style={{ width: 140 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              optionLabelProp="key"
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  isRecommend: value === 'all' ? '' : value.toString(),
                }));
              }}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('advertisement.articleColumnList.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('advertisement.articleColumnList.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('advertisement.articleColumnList.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('advertisement.articleList')}
            </Typography.Title>
            <TableToolbar
              leftElement={
                <Button
                  icon={<PlusOutlined />}
                  disabled={
                    !hasPermission(
                      actionPermissions.advertisementGroup.articleManage
                    )
                  }
                  onClick={() => {
                    setModalVisible(true);
                    setArticleDetail(undefined);
                    setLoadingModal(false);
                  }}
                >
                  {t('advertisement.add/EditArticle.addTitle')}
                </Button>
              }
              advancedSearch={advancedSearch}
              tableSize={tableSize}
              setTableSize={setTableSize}
              fontSize={fontSize}
              setFontSize={setFontSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchArticle')}
              rows={articleData.map((article) => ({
                ...article,
              }))}
              exportConfig={{ fileName: 'ARTICLE_LIST' }}
            />
            <Table<ArticleData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={articleData}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              size={tableSize}
              loading={loading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
              rowKey={(article) => article.articleId}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                setSelectedRowKeys={setSelectedRowKeys}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
                funct={{ exportConfig: { fileName: 'ARTICLE_LIST' } }}
                selectedRows={[]}
              />
            )}
          </Card>
          <ArticleModal
            loading={loadingModal}
            setLoading={setLoadingModal}
            visible={modalVisible}
            setVisible={setModalVisible}
            selectedArticle={articleDetail}
            refresh={() => getData()}
          />
        </>
      )}
    </Container>
  );
};

export default ArticleList;
