import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Grid,
  Typography,
  Table,
  Image,
  Switch,
  InputNumber,
  Button,
  Space,
  Popconfirm,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import BrandModal from '../../components/goods/BrandModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { FALLBACK_IMG } from '../../constants/styles';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BrandData, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { RED1 } from '../../constants/color';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

const BrandList = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [brands, setBrands] = useState<BrandData[]>([]);
  const [editBrand, setEditBrand] = useState<BrandData>();
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const columnKeys = [
    'brandId',
    'brandName',
    'brandNameEn',
    'brandFirstChar',
    'brandLogo',
    'brandBackgroundImg',
    'brandDesc',
    'siteUrl',
    'isShow',
    'isRecommend',
    'addTime',
    'sortOrder',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<BrandData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  const [editValidator, setEditValidator] = useState<{
    data: BrandData;
    rowIndex: any;
    colIndex: any;
  }>();

  const handleOk = (param: string, value: any, record: BrandData) => {
    postDataWithAuthToken('goods/brand/edit', {
      ...record,
      sortOrder: param === 'sortOrder' && value ? parseInt(value) : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.brandEdited'));
          getBrandData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setEditValidator(undefined);
  };

  const columns: ColumnsType<BrandData> = [
    {
      title: setFont(t('goods.brandListColumns.brandId'), fontSize),
      key: 'brandId',
      dataIndex: 'brandId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string, record: BrandData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.goodGroup.brandManage)}
          onClick={() => {
            setEditBrand(record);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: BrandData, b: BrandData) => compare(a.brandId, b.brandId),
    },
    {
      title: setFont(t('goods.brandListColumns.brandName'), fontSize),
      key: 'brandName',
      dataIndex: 'brandName',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: BrandData, b: BrandData) => compare(a.brandName, b.brandName),
    },
    {
      title: setFont(t('goods.brandListColumns.brandNameEn'), fontSize),
      key: 'brandNameEn',
      dataIndex: 'brandNameEn',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: BrandData, b: BrandData) =>
        compare(a.brandNameEn, b.brandNameEn),
    },
    {
      title: setFont(t('goods.brandListColumns.brandFirstChar'), fontSize),
      key: 'brandFirstChar',
      dataIndex: 'brandFirstChar',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: BrandData, b: BrandData) =>
        compare(a.brandFirstChar, b.brandFirstChar),
    },
    {
      title: setFont(t('goods.brandListColumns.brandLogo'), fontSize),
      key: 'brandLogo',
      dataIndex: 'brandLogoPath',
      width: 140,
      render: (path: string) => (
        <Image
          width={50}
          src={path}
          preview={{ mask: <EyeOutlined /> }}
          fallback={FALLBACK_IMG}
        />
      ),
    },
    {
      title: setFont(t('goods.brandListColumns.brandBackgroundImg'), fontSize),
      key: 'brandBackgroundImg',
      dataIndex: 'brandBackgroundImgPath',
      width: 140,
      render: (path: string) => (
        <Image
          width={50}
          src={path}
          preview={{ mask: <EyeOutlined /> }}
          fallback={FALLBACK_IMG}
        />
      ),
    },
    {
      title: setFont(t('goods.brandListColumns.brandDesc'), fontSize),
      key: 'brandDesc',
      dataIndex: 'brandDesc',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.brandListColumns.siteUrl'), fontSize),
      key: 'siteUrl',
      dataIndex: 'siteUrl',
      width: 200,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.brandListColumns.isShow'), fontSize),
      key: 'isShow',
      dataIndex: 'isShow',
      width: 100,
      render: (value: boolean, record: BrandData) => (
        <Switch
          checked={value}
          onChange={(checked) => updateBrand('isShow', checked, record)}
        />
      ),
    },
    {
      title: setFont(t('goods.brandListColumns.isRecommend'), fontSize),
      key: 'isRecommend',
      dataIndex: 'isRecommend',
      width: 100,
      render: (value: boolean, record: BrandData) => (
        <Switch
          checked={value}
          onChange={(checked) => updateBrand('isRecommend', checked, record)}
        />
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      sorter: true,
      render: (value: number, record: BrandData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'sortOrder' ? (
          <Space direction="vertical">
            <InputNumber
              autoFocus
              defaultValue={value}
              min={0}
              max={50000}
              placeholder="0-50000"
              style={{ width: 90, fontSize: fontSize }}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(input) =>
                !input ? setIsEmptyInput(true) : setIsEmptyInput(false)
              }
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                target.value && handleOk('sortOrder', target.value, record);
              }}
            ></InputNumber>
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'sortOrder',
              });
            }}
          >
            {value}
          </Button>
        ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: BrandData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditBrand(record);
              setShowModal(true);
            }}
            disabled={!hasPermission(actionPermissions.goodGroup.brandManage)}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeleteBrand(record.brandId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.goodGroup.brandManage)}
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={!hasPermission(actionPermissions.goodGroup.brandManage)}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Delete brand by ID
  const handleDeleteBrand = async (brandId: number) => {
    try {
      const response = await postDataWithAuthToken('goods/brand/delete', {
        brandId: brandId,
      });
      if (response && response.goodStatus) {
        getBrandData();
        alertMessage('success', t('goods.alerts.brandDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  const updateBrand = async (
    key: string,
    value: string | number | boolean,
    brand: BrandData
  ) => {
    postDataWithAuthToken('goods/brand/edit', {
      ...brand,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getBrandData();
            alertMessage('success', t('goods.alerts.brandEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBrandData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/brand/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setBrands(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, page, pageSize, keyword]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getBrandData();
  }, [getBrandData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.brandList')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditBrand(undefined);
                  setShowModal(true);
                }}
              >
                {t('goods.add/editBrand.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getBrandData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchBrandName')}
            rows={brands}
            exportConfig={{ fileName: 'BRANDS_LIST' }}
          />
          <Table<BrandData>
            dataSource={brands}
            rowKey={(brand) => brand.brandId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={isLoading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: BrandData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'BRANDS_LIST' } }}
            />
          )}
        </Card>
      )}
      <BrandModal
        visible={showModal}
        setVisible={setShowModal}
        brand={editBrand}
        refresh={() => getBrandData()}
      />
    </Container>
  );
};

export default BrandList;
