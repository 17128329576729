import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Space, Spin, Tooltip } from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import AdminRoleDropdown from './AdminRoleDropdown';
import PermissionList from './PermissionList';

type AdminModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // If Admin Profile Exists then we are editing an already existing profile.
  adminProfile?: AdminListData;
  callBack?: Function;
  adminActions: DataNode[];
};

/**
 * Modal used for creating and editing Admins
 *
 * @prop visible       If Modal should be shown
 * @prop setVisible    Setting whether Modal should be displayed or not
 * @prop adminProfile  Profile to edit if we are editing a profile
 * @prop callBack      What to call next after form is submitted (usually a table refresh function)
 * @prop roles         List of admin role
 * @prop adminActions  Array of reconstructed admin action
 * @prop setRoles      Setting a role to the current admin profile
 */
const AdminModal = ({
  visible,
  setVisible,
  adminProfile,
  callBack,
  adminActions,
}: AdminModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const [selectedActions, setSelectedActions] = useState<string[] | undefined>(
    []
  );
  const isSubscribed = useRef(true);
  const profile = JSON.parse(localStorage.getItem('profile') || '');

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.setFieldsValue(adminProfile);
      if (adminActions?.length && adminProfile?.actionList)
        if (isSubscribed.current)
          setSelectedActions(adminProfile.actionList.split(','));
    }
  }, [adminProfile, adminActions, form, visible]);

  /**
   * @param values Form Values
   * @returns axios response
   */
  const updateProfile = async (values: any) => {
    const response = await postDataWithAuthToken('admin/edit', {
      ...values,
      adminId: adminProfile ? adminProfile.adminId : undefined,
      actionList: selectedActions?.length ? selectedActions.join() : '',
      password: values.password ? values.password : undefined,
      roleId: values.roleId ? values.roleId : 0,
    });
    return response;
  };

  console.log(adminProfile);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setLoading(true);
        // if adminProfile -> Edit User
        if (adminProfile) {
          // Submits Edit Admin
          updateProfile(values).then((response) => {
            if (isSubscribed.current) setLoading(false);
            if (response && response.goodStatus) {
              alertMessage('success', t('admin.alerts.adminEdited'));
              if (callBack) {
                callBack();
              }
              handleCancel();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
        } else {
          // Submits Add new Admin
          postDataWithAuthToken('admin/add', {
            ...values,
            roleId: values.roleId ? values.roleId : undefined,
            actionList: selectedActions?.length ? selectedActions.join() : '',
          }).then((response) => {
            if (isSubscribed.current) setLoading(false);
            if (response && response.goodStatus) {
              alertMessage('success', t('admin.alerts.adminAdded'));
              if (callBack) {
                callBack();
              }
              handleCancel();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
        }
      })
      .catch((err) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  const handleCancel = () => {
    if (isSubscribed.current) {
      setFirstPassword('');
      setVisible(false);
    }
  };

  const onReset = () => {
    if (isSubscribed.current) setSelectedActions([]);
    form.resetFields();
  };

  const onSelect = () => {
    form.setFieldsValue({ roleId: 0 });
  };

  return (
    <Modal
      title={
        adminProfile
          ? `${t('admin.add/editAdmin.editTitle')} ${
              adminProfile.adminUserName
            }`
          : t('admin.add/editAdmin.addTitle')
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('admin.add/editAdmin.ok')}
      cancelText={t('admin.add/editAdmin.cancel')}
      afterClose={onReset}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="form_in_modal">
          {!adminProfile && (
            <Form.Item
              name="username"
              label={t('admin.add/editAdmin.username')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="email"
            label={t('admin.add/editAdmin.email')}
            rules={[
              {
                type: 'email',
                message: t('general.inputError.pleaseInputValidEmail'),
              },
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('admin.add/editAdmin.password')}
            rules={[
              {
                required: adminProfile ? false : true,
                message: t('general.inputError.empty'),
              },
            ]}
            hasFeedback={adminProfile && firstPassword.length ? true : false}
          >
            <Input.Password
              onChange={(e) => setFirstPassword(e.target.value)}
            />
          </Form.Item>
          {firstPassword && (
            <Form.Item
              name="confirmPassword"
              label={t('admin.add/editAdmin.confirmPassword')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseConfirmPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('general.inputError.passwordsDontMatch'))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item label={t('admin.add/editAdmin.assignRole')} name="roleId">
            <AdminRoleDropdown
              initialValue={adminProfile?.roleId}
              onChange={(value, option: any) => {
                if (option && option.actionList) {
                  const beforeFiltered = option.actionList.split(',');
                  const filtered = option.actionList
                    .split(',')
                    .filter(
                      (action: string) =>
                        profile.actionList &&
                        (profile.actionList === 'all' ||
                          profile.actionList.indexOf(action) !== -1)
                    );
                  if (beforeFiltered.length !== filtered.length)
                    alertMessage('info', t('admin.alerts.noteRemoveAction'));
                  setSelectedActions(filtered);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="actionList"
            label={
              <Space>
                {t('admin.add/editAdmin.actions')}
                <Tooltip title={t('admin.add/editAdmin.actionsRemark')}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            }
          >
            <PermissionList
              permissionList={adminActions}
              selectedPermissions={selectedActions}
              setSelectedPermissions={setSelectedActions}
              onSelectionChange={onSelect}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AdminModal;
