import {
  BorderlessTableOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Image,
  Input,
  InputNumber,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { FALLBACK_IMG } from '../../../constants/styles';
import { GoodData, OrderGoodsInsertData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import SelectGoodsModal from '../../goods/selectGoods/SelectGoodsModal';
import SelectGoodAttrModal from '../../goods/selectGoods/SelectGoodAttrModal';

type OrderGoodsFormProps = {
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
  currentOrderGoodsList?: OrderGoodsInsertData[];
};

const OrderGoodsForm = ({
  formData,
  setFormData,
  currentOrderGoodsList,
}: OrderGoodsFormProps) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [currentGoodIndex, setCurrentGoodIndex] = useState<number>();
  const [selectGoodModalVisible, setSelectGoodModalVisible] =
    useState<boolean>(false);
  const [selectGoodAttrModalVisible, setSelectGoodAttrModalVisible] =
    useState<boolean>(false);
  const [firstLoadCurrentOg, setFirstLoadCurrentOg] = useState<boolean>(false);

  const goodColumns: ColumnsType<GoodData> = [
    {
      title: t('order.orderDetail.goodsImage'),
      width: 100,
      render: (good: GoodData) => (
        <Image
          src={good.thumbPicPath}
          fallback={FALLBACK_IMG}
          width={50}
          preview={{ src: good.largePicPath, mask: <EyeOutlined /> }}
        />
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsName'),
      width: 160,
      dataIndex: 'goodsName',
      render: (value, record, index) => (
        <Input
          defaultValue={value}
          onBlur={(e) => {
            let target = e.target as HTMLInputElement;
            updateGoodsList('goodsName', target.value, index);
          }}
        />
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsSn'),
      width: 80,
      dataIndex: 'goodsSn',
    },
    {
      title: t('goods.goodsListColumns.goodsUnit'),
      width: 150,
      dataIndex: 'goodsUnit',
      render: (value, record, index) => (
        <Input
          defaultValue={value}
          onBlur={(e) => {
            let target = e.target as HTMLInputElement;
            updateGoodsList('goodsUnit', target.value, index);
          }}
        />
      ),
    },
    {
      title: t('order.orderDetail.goodsAttrName'),
      width: 100,
      dataIndex: 'goodsAttrName',
    },
    {
      title: t('order.orderDetail.amount'),
      width: 100,
      dataIndex: 'goodsNumber',
      render: (value, record: GoodData, index: number) => (
        <Button
          block
          disabled={!hasPermission(actionPermissions.orderGroup.orderManage)}
          style={{ textAlign: 'left' }}
          onClick={() => {
            if (hasPermission(actionPermissions.orderGroup.orderManage)) {
              setCurrentGoodIndex(pageSize * (page - 1) + index);
              setSelectGoodAttrModalVisible(true);
            }
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsWeight'),
      key: 'isWeightGoods|goodsWeight',
      width: 140,
      render: (text: string, record: GoodData, index) => (
        <InputNumber
          type="number"
          defaultValue={parseFloat(record.goodsWeight)}
          min={0}
          onBlur={(e) => {
            if (record.isWeightGoods && Number(e.target.value) <= 0) {
              alertMessage('warning', t('goods.alerts.missingWeightWarning'));
            } else {
              updateGoodsList('goodsWeight', e.target.value, index);
            }
          }}
        />
      ),
    },
    {
      title: t('goods.goodsListColumns.shopPrice'),
      width: 100,
      dataIndex: 'goodsPrice',
      render: (value, record: GoodData, index: number) =>
        getDashboardStyle().isSalesForceAppType ? (
          value
        ) : (
          <InputNumber
            type="number"
            min={0}
            precision={2}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateGoodsList('goodsPrice', target.value, index);
            }}
          />
        ),
    },
    {
      title: t('order.orderGoodsListColumns.goodsPriceBeforeDiscount'),
      width: 100,
      dataIndex: 'goodsPriceBeforeDiscount',
    },
    {
      title: t('order.orderGoodsListColumns.mainGoodsPrice'),
      width: 100,
      dataIndex: 'mainGoodsPrice',
      key: 'mainGoodsPrice',
      render: (value, record: GoodData, index: number) =>
        getDashboardStyle().isSalesForceAppType ? (
          value
        ) : (
          <InputNumber
            type="number"
            min={0}
            precision={2}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateGoodsList('mainGoodsPrice', target.value, index);
            }}
          />
        ),
    },
    {
      title: t('order.orderDetail.subTotal'),
      width: 80,
      render: (record: any) => record && record.totalPrice,
    },
    {
      title: t('order.orderDetail.action'),
      width: 80,
      render: (value, record: GoodData, index: number) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={() => deleteGood(pageSize * (page - 1) + index)}
        >
          {t('actionsColumn.delete')}
        </Button>
      ),
    },
  ];

  const calculateOrderGoodsTotal = useCallback(
    (orderGoodsList: OrderGoodsInsertData[]) => {
      setIsLoading(true);
      postDataWithAuthToken('order/preview_goods', {
        userId: formData.userId,
        sellerId: formData.sellerId,
        orderGoodsList: orderGoodsList.map((good: OrderGoodsInsertData) => ({
          goodsId: good.goodsId,
          goodsName: good.goodsName,
          goodsUnit: good.goodsUnit,
          goodsNumber: good.goodsNumber,
          goodsSkuAttrIds:
            (good.goodsAttrId && good.goodsAttrId.split(',')) || undefined,
          goodsPrice: good.goodsPrice,
          mainGoodsPrice: good.mainGoodsPrice,
          goodsWeight: good.goodsWeight,
        })),
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setIsLoading(false);
            setFormData((prev) => ({
              ...prev,
              orderGoodsList: response.data.orderGoodsList,
              orderGoodsListTotal: response.data.goodsAmount,
              shippingTransportId: response.data.transportId,
            }));
          } else {
            setIsLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
    [formData.sellerId, formData.userId, setFormData, t]
  );

  // currentOrderGoodsList from prev page -> fetch list into formData
  useEffect(() => {
    if (
      !firstLoadCurrentOg &&
      currentOrderGoodsList &&
      currentOrderGoodsList.length > 0 &&
      // 如果已有订单商品,则无需更新
      !(
        formData &&
        formData.orderGoodsList &&
        formData.orderGoodsList.length > 0
      )
    ) {
      setFormData((prev) => {
        if (!(prev.orderGoodsList && prev.orderGoodsList.length > 0)) {
          return { ...prev, orderGoodsList: currentOrderGoodsList };
        }
      });
      setFirstLoadCurrentOg(true);
      calculateOrderGoodsTotal(currentOrderGoodsList);
    }
  }, [
    firstLoadCurrentOg,
    currentOrderGoodsList,
    calculateOrderGoodsTotal,
    formData,
    setFormData,
  ]);

  const deleteGood = (index: number) => {
    let newList = (formData.orderGoodsList || []).filter(
      (good: GoodData, i: number) => index !== i
    );

    if (newList.length) {
      calculateOrderGoodsTotal(newList);
    } else {
      setFormData((prev) => ({
        ...prev,
        orderGoodsList: [],
        orderGoodsListTotal: '0.00',
        shippingTransportId: 0,
      }));
    }
  };

  const updateGoodsList = (
    key: string,
    value: string | number,
    index: number
  ) => {
    const { orderGoodsList } = formData;
    let newList = (orderGoodsList || []).slice();
    newList[pageSize * (page - 1) + index][key] = value;
    setFormData((prev) => ({
      ...prev,
      orderGoodsList: newList,
    }));
    if (
      key === 'goodsNumber' ||
      key === 'goodsPrice' ||
      key === 'mainGoodsPrice' ||
      key === 'goodsWeight'
    ) {
      calculateOrderGoodsTotal(newList);
    }
  };

  const renderTotalDisplay = () => {
    return (
      <div style={{ textAlign: 'right', fontSize: 18 }}>
        <Typography.Text>{`${t('order.orderDetail.total')}: `}</Typography.Text>
        <Typography.Text type="danger" strong>
          {(formData && formData.orderGoodsListTotal) || '0.00'}
        </Typography.Text>
      </div>
    );
  };

  return (
    <>
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.add/editOrder.goodList')}
        </Space>
      </Divider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            setSelectGoodModalVisible(true);
          }}
        >
          {t('goods.add/editGood.addTitle')}
        </Button>
        {renderTotalDisplay()}
      </div>
      <Table
        dataSource={formData.orderGoodsList}
        columns={
          formData.isRegionSale
            ? goodColumns
            : goodColumns.filter((col) => col.key !== 'mainGoodsPrice')
        }
        size="small"
        rowKey={(record) => `${record.goodsId}_${Math.random()}`}
        pagination={{
          pageSize: pageSize,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          hideOnSinglePage: true,
          onChange: (p, pSize) => {
            setPage(p);
            setPageSize(pSize);
          },
          current: page,
        }}
        loading={isLoading}
        scroll={{ x: 768 }}
      />

      {selectGoodModalVisible && (
        <SelectGoodsModal
          firstLoad={false}
          visible={selectGoodModalVisible}
          setVisible={setSelectGoodModalVisible}
          userId={formData.userId}
          sellerId={formData.sellerId}
          newGoodObjs={formData.orderGoodsList}
          setNewGoodObjs={setFormData}
          multiSelectFeature={false}
          shippingTransportId={formData.shippingTransportId || 0}
        />
      )}
      {selectGoodAttrModalVisible && (
        <SelectGoodAttrModal
          visible={selectGoodAttrModalVisible}
          setVisible={setSelectGoodAttrModalVisible}
          selectedGoods={formData.orderGoodsList}
          setSelectedGoods={setFormData}
          callBack={() => setCurrentGoodIndex(undefined)}
          userId={formData.userId || 0}
          currentGood={
            currentGoodIndex !== undefined &&
            formData.orderGoodsList[currentGoodIndex]
          }
          currentGoodIndex={currentGoodIndex}
        />
      )}
    </>
  );
};

export default OrderGoodsForm;
