import React from 'react';
import { useTranslation } from 'react-i18next';
import ShopConfigLayout from '../../layout/ShopConfigLayout';

/**
 * Displays SMS Settings
 * /dashboard/settings/sms
 *
 * @param route Route Information from react-router-config
 */
const SMSSettings = () => {
  const { t } = useTranslation();

  return (
    <ShopConfigLayout group="sms" title={t('plugins.SMS.settings.title')} />
  );
};

export default SMSSettings;
