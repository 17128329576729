import { actionPermissions } from '../constants/actionPermissions';

/**
  * How to use hideFromNav
  * Hiding only 1 or not a lot of items vvvvv
    Object.values(main group).filter((permission) =>
      [perms of navs you want to HIDE].includes(
        permission
      )
    )
  * Hiding only lots items vvvvv
    Object.values(actionPermissions.goodGroup).filter(
      (permission) =>
        ![
          perms of navs you want to SHOW
        ].includes(permission)
    )
*/
const getDashboardStyle = (
  type: string | undefined = process.env.REACT_APP_TYPE
) => {
  const appType = type
    ? type
    : process.env.REACT_APP_TYPE
    ? process.env.REACT_APP_TYPE
    : 'luniuMall';
  let webTitle = process.env.REACT_APP_TITLE
    ? process.env.REACT_APP_TITLE
    : undefined;
  let mainColor = process.env.REACT_APP_COLOR
    ? process.env.REACT_APP_COLOR
    : undefined;
  // wholesale - wholesale company
  if (appType?.includes('wholesale')) {
    const { userGroup, orderGroup, ...rest } = actionPermissions;
    return {
      type: appType,
      title: webTitle ? webTitle : appType,
      mainColor: mainColor
        ? mainColor
        : appType.includes('sellersCo')
        ? '#00b7ee'
        : appType.includes('salesForce')
        ? '#b298ff'
        : '#e5832f',
      hidePrintoutSections: [],
      baseURL:
        process.env.REACT_APP_BACKEND_API === undefined
          ? appType.includes('sellersCo')
            ? 'https://sellerapi.grand-import-ca.com/api/V1/'
            : appType.includes('salesForce')
            ? 'https://salesforceapi.grand-import-ca.com/api/V1/'
            : 'https://api2.grand-import-ca.com/api2/V1/'
          : process.env.REACT_APP_BACKEND_API,
      icon: require(`../assets/images/${appType}_logo.png`).default,
      isSellerSwitch: !appType.includes('sellersCo'),
      showReviewStatus: !appType.includes('sellersCo'),
      hideFromNav: appType.includes('sellersCo')
        ? Object.values(actionPermissions.activityGroup)
            .concat(
              Object.values(actionPermissions.goodGroup).filter(
                (permission) =>
                  ![
                    actionPermissions.goodGroup.goodView,
                    actionPermissions.goodGroup.goodComments,
                    actionPermissions.goodGroup.goodLib,
                    actionPermissions.goodGroup.relatedGoods,
                  ].includes(permission)
              )
            )
            .concat(Object.values(actionPermissions.userGroup))
            .concat(Object.values(actionPermissions.adminGroup))
            .concat(Object.values(actionPermissions.advertisementGroup))
            .concat(Object.values(actionPermissions.sortationGroup))
            .concat(Object.values(actionPermissions.cacheGroup))
            .concat(Object.values(actionPermissions.generalGroup))
            .concat(
              Object.values(actionPermissions.settingGroup).filter(
                (permission) =>
                  [actionPermissions.settingGroup.regionSetting].includes(
                    permission
                  )
              )
            )
            .concat(Object.values(actionPermissions.pluginGroup))
        : appType.includes('salesForce')
        ? Object.values(rest)
            .flatMap((group) => Object.values(group))
            .concat(
              Object.values(userGroup).filter(
                (permission) => ![userGroup.userView].includes(permission)
              )
            )
            .concat(
              Object.values(orderGroup).filter(
                (permission) => ![orderGroup.orderView].includes(permission)
              )
            )
        : Object.values(actionPermissions.activityGroup),
      // .concat(Object.values(actionPermissions.sellerGroup))
      // .concat(
      //   Object.values(actionPermissions.goodGroup).filter((permission) =>
      //     [actionPermissions.goodGroup.goodLib].includes(permission)
      //   ))
      isSellersCoAppType: appType.includes('sellersCo'),
      isWholeSaleAppType: true,
      isSalesForceAppType: appType.includes('salesForce'),
    };
  } // Seller - seller companies
  else if (appType?.includes('sellersCo')) {
    return {
      type: appType,
      title: webTitle ? webTitle : appType,
      mainColor: mainColor ? mainColor : '#00b7ee',
      icon: require(`../assets/images/${appType}_logo.png`).default,
      hidePrintoutSections: [],
      isSellerSwitch: false,
      showReviewStatus: false,
      baseURL:
        process.env.REACT_APP_BACKEND_API === undefined
          ? 'https://sellerapi.luniumall.com/api/V1/'
          : process.env.REACT_APP_BACKEND_API,
      hideFromNav: Object.values(actionPermissions.goodGroup)
        .filter(
          (permission) =>
            ![
              actionPermissions.goodGroup.goodView,
              actionPermissions.goodGroup.goodComments,
              actionPermissions.goodGroup.goodLib,
              actionPermissions.goodGroup.relatedGoods,
            ].includes(permission)
        )
        .concat(Object.values(actionPermissions.userGroup))
        .concat(Object.values(actionPermissions.adminGroup))
        .concat(Object.values(actionPermissions.advertisementGroup))
        .concat(Object.values(actionPermissions.sortationGroup))
        .concat(Object.values(actionPermissions.cacheGroup))
        .concat(Object.values(actionPermissions.generalGroup))
        .concat(
          Object.values(actionPermissions.settingGroup).filter((permission) =>
            [actionPermissions.settingGroup.regionSetting].includes(permission)
          )
        )
        .concat(Object.values(actionPermissions.pluginGroup)),
      isSellersCoAppType: true,
      isWholeSaleAppType: false,
      isSalesForceAppType: false,
    };
  } // Sales Force - salesperson dashboard
  else if (appType?.includes('salesForce')) {
    const { userGroup, ...rest } = actionPermissions;
    return {
      type: appType,
      title: webTitle ? webTitle : appType,
      mainColor: mainColor ? mainColor : '#b298ff',
      hidePrintoutSections: [],
      baseURL:
        process.env.REACT_APP_BACKEND_API === undefined
          ? 'https://salesforceapi.luniumall.com/api/V1/'
          : process.env.REACT_APP_BACKEND_API,
      icon: require(`../assets/images/${appType}_logo.png`).default,
      isSellerSwitch: false,
      showReviewStatus: false,
      hideFromNav: Object.values(rest)
        .flatMap((group) => Object.values(group))
        .concat(
          Object.values(userGroup).filter(
            (permission) => ![userGroup.userView].includes(permission)
          )
        ),
      isSellersCoAppType: false,
      isWholeSaleAppType: false,
      isSalesForceAppType: true,
    };
  }

  // Default type is admin dashboard
  return {
    type: appType,
    title: webTitle ? webTitle : 'luniuMall',
    mainColor: mainColor ? mainColor : '#2cae35',
    baseURL:
      process.env.REACT_APP_BACKEND_API === undefined
        ? 'https://api2.luniumall.com/api2/V1/'
        : process.env.REACT_APP_BACKEND_API,
    icon: require('../assets/images/luniu_logo.png').default,
    isSellerSwitch: true,
    hidePrintoutSections: ['orderGoods'],
    showReviewStatus: true,
    hideFromNav: Object.values(actionPermissions.goodGroup).filter(
      (permission) => [actionPermissions.goodGroup.goodLib].includes(permission)
    ),
    isSellersCoAppType: false,
    isWholeSaleAppType: false,
    isSalesForceAppType: false,
  };
};

export default getDashboardStyle;
