import { Col, Input, Row, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BLACK } from '../../../../constants/color';
import {
  STATEMENT_SUB_TITLE,
  STATEMENT_MAIN_BODY,
} from '../../../../constants/printoutConstants';
import { StatementPrintoutOrderData } from '../../../../types';

type StatementPrintoutBodyProps = {
  body: StatementPrintoutOrderData;
};

const StatementPrintoutBody = ({ body }: StatementPrintoutBodyProps) => {
  const dateRef = useRef<any>();
  const { t } = useTranslation();
  const [field, setField] = useState<{ [key: string]: string }>({});
  const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.focus();
    }

    const { billName, billAddress, billPhone } = body;
    setField({ billName, billAddress, billPhone });
  }, [body]);

  return (
    <div
      style={{
        marginBottom: 5,
      }}
    >
      <Row /**Bill To */
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Col /**Bill To */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography.Paragraph
            strong
            style={{
              fontSize: STATEMENT_SUB_TITLE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.billTo')}
          </Typography.Paragraph>

          {field &&
            Object.keys(field).length > 0 &&
            Object.keys(field).map((key) => (
              <div key={key}>
                {isEditing[key] ? (
                  <Input
                    autoFocus
                    defaultValue={field[key]}
                    onChange={(e) =>
                      setField((prev) => ({
                        ...prev,
                        [key]:
                          e.target.value ||
                          body[key as keyof typeof StatementPrintoutBody],
                      }))
                    }
                    onBlur={() => setIsEditing({ [key]: false })}
                  />
                ) : (
                  <Typography.Text
                    style={{ fontSize: STATEMENT_MAIN_BODY, color: BLACK }}
                    onClick={() => setIsEditing({ [key]: true })}
                  >
                    {field[key]}
                  </Typography.Text>
                )}
              </div>
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default StatementPrintoutBody;
