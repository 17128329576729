import {
  Button,
  Col,
  Empty,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { BLUE1, HIGHLIGHT_ORANGE, WHITE } from '../../../constants/color';
import { ORDER_BUTTON_PERMISSIONS } from '../../../constants/orderConstants';
import {
  BasicEnumInfoType,
  OrderActionLog,
  OrderActionNote,
  OrderChangeLog,
  OrderData,
  OrderEnum,
  OrderPrintOutContents,
  PrintType,
  PrintTypeMode,
  PrintoutSelectedOrderObj,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import PrintModal from '../../PrintModal';
import TaskModal from '../../sortationSystem/TaskModal';
import UserMessageModal from '../../users/UserMessageModal';
import BuildOrderData from '../ToPrintoutData';
import OrderActionModal from './OrderActionModal';

type OrderActionLogProps = {
  orderInfo?: OrderData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  enums?: OrderEnum;
};

const OrderActionLogTable = ({
  orderInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
  enums,
}: OrderActionLogProps) => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [invoicePrintModalVisible, setInvoicePrintModalVisible] =
    useState(false);
  const [packingSlipPrintModalVisible, setPackingSlipPrintModalVisible] =
    useState(false);
  const [isFullVersion, setIsFullVersion] = useState<boolean>();
  const [smsModalVisible, setSMSModalVisible] = useState<boolean>(false);
  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [allOrderPrintoutData, setAllOrderPrintoutData] = useState<
    PrintType | undefined
  >(undefined);

  const actionNoteColumns: ColumnsType<OrderChangeLog> = [
    {
      title: t('order.orderDetail.propertyName'),
      dataIndex: 'propertyName',
      key: 'propertyName',
      width: 100,
      render: (text: string) =>
        i18n.exists(`order.orderDetail.${text}`)
          ? t(`order.orderDetail.${text}`)
          : text,
    },
    {
      title: t('order.orderDetail.oldValue'),
      dataIndex: 'oldValue',
      key: 'oldValue',
      width: 100,
    },
    {
      title: t('order.orderDetail.newValue'),
      dataIndex: 'newValue',
      key: 'newValue',
      width: 100,
    },
  ];

  const columns: ColumnsType<OrderActionLog> = [
    {
      title: t('admin.title'),
      dataIndex: 'adminName',
      key: 'adminName',
      width: 100,
    },
    {
      title: t('order.orderDetail.logTime'),
      dataIndex: 'logTime',
      key: 'logTime',
      width: 160,
    },
    {
      title: t('order.orderListColumns.orderStatus'),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 100,
      render: (record: BasicEnumInfoType) =>
        record && <Tag color={record.tagColor}>{record.description}</Tag>,
    },
    {
      title: t('order.orderListColumns.payStatus'),
      dataIndex: 'payStatus',
      key: 'payStatus',
      width: 100,
      render: (value: BasicEnumInfoType) => value && value.description,
    },
    {
      title: t('order.orderListColumns.shippingStatus'),
      dataIndex: 'shippingStatus',
      key: 'shippingStatus',
      width: 140,
      render: (value: BasicEnumInfoType) => value && value.description,
    },
    {
      title: t('order.orderDetail.remark'),
      key: 'remark',
      width: 250,
      render: (record: OrderActionLog) =>
        record && record.actionNote && record.actionNote.remark,
    },
    {
      title: t('order.orderDetail.action'),
      dataIndex: 'actionNote',
      key: 'actionNote',
      ellipsis: true,
      render: (record: OrderActionNote) => record && record.action,
    },
  ];

  const getPrintoutContents = (
    printMode: PrintTypeMode,
    orderIds: number[]
  ) => {
    setModalLoading(true);
    getDataWithAuthToken('order/print', {
      params: {
        orderIds: orderIds.toString(),
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          let responseData: OrderPrintOutContents[] = response.data.list;
          if (responseData.length > 0) {
            //each traversing thru selected order and building it info sep objects.
            //each key in the obj will be used for a specific component
            const OrderDataObjects: PrintoutSelectedOrderObj = BuildOrderData(
              responseData,
              true
            );
            setAllOrderPrintoutData({ ...OrderDataObjects });
            setModalLoading(false);
            setPrintModalVisible(printMode === 'order');
            setInvoicePrintModalVisible(printMode === 'invoice');
            setPackingSlipPrintModalVisible(printMode === 'packingSlip');
          } else {
            setModalLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalLoading(false);
      });
  };

  const resetTask = () => {
    postDataWithAuthToken('task/reset_batch', {
      orderId: orderInfo ? orderInfo.orderId : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('sortationSystem.alerts.taskEdited'));
          callBack && callBack();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const invalidateBatch = () => {
    postDataWithAuthToken('task/invalidate_batch', {
      orderId: orderInfo ? orderInfo.orderId : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('sortationSystem.alerts.taskEdited'));
          callBack && callBack();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return orderInfo ? (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.actionLog')}
        </Typography.Text>
      </Col>
      {!getDashboardStyle().isSalesForceAppType && (
        <Col span={24}>
          <Space wrap>
            {orderInfo.buttonList &&
              Object.keys(orderInfo.buttonList)
                .filter(
                  (button) =>
                    button !== 'outOfStockEnabled' &&
                    button !== 'returnEnabled' &&
                    button !== 'refundEnabled' &&
                    button !== 'differenceEnabled' &&
                    button !== 'creditMemoEnabled'
                )
                .map(
                  (key) =>
                    orderInfo.buttonList[key] &&
                    (key === 'batchResetTaskEnabled' ||
                    key === 'batchInvalidateTaskEnabled' ? (
                      <Popconfirm
                        disabled={!hasPermission(ORDER_BUTTON_PERMISSIONS[key])}
                        key={key}
                        onConfirm={() => {
                          if (key === 'batchInvalidateTaskEnabled') {
                            invalidateBatch();
                          } else if (key === 'batchResetTaskEnabled') {
                            resetTask();
                          }
                        }}
                        title={
                          key === 'batchResetTaskEnabled'
                            ? t(`sortationSystem.alerts.resetTaskButton`)
                            : key === 'batchInvalidateTaskEnabled'
                            ? t(
                                `sortationSystem.alerts.batchInvalidateTaskButton`
                              )
                            : ''
                        }
                        placement="topLeft"
                      >
                        <Button
                          disabled={
                            !hasPermission(ORDER_BUTTON_PERMISSIONS[key])
                          }
                          size="small"
                          type="primary"
                          key={key}
                          danger
                        >
                          {t(`order.orderDetail.buttonList.${key}`)}
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        disabled={!hasPermission(ORDER_BUTTON_PERMISSIONS[key])}
                        size="small"
                        type="primary"
                        key={key}
                        danger={key === 'batchInvalidateTaskEnabled'}
                        onClick={() => {
                          if (key === 'unpaidNotifyEnabled') {
                            setSMSModalVisible(true);
                          } else if (key === 'addTaskEnabled') {
                            setTaskModalVisible(true);
                          } else {
                            setAction(key);
                            setShowModal(true);
                          }
                        }}
                        style={
                          key === 'unpaidNotifyEnabled'
                            ? { backgroundColor: BLUE1, borderColor: BLUE1 }
                            : undefined
                        }
                      >
                        {t(`order.orderDetail.buttonList.${key}`)}
                      </Button>
                    ))
                )}
            <Button
              disabled={
                !hasPermission(actionPermissions.orderGroup.orderRemark)
              }
              size="small"
              style={{
                backgroundColor: HIGHLIGHT_ORANGE,
                color: WHITE,
              }}
              onClick={() => {
                setAction('remark');
                setShowModal(true);
              }}
              type="link"
            >
              {t(`order.orderDetail.buttonList.remark`)}
            </Button>

            {orderInfo.printTime ? (
              <Popconfirm
                title={t('actionsColumn.printWarning')}
                onConfirm={() => {
                  setIsFullVersion(true);
                  getPrintoutContents('order', [orderInfo.orderId]);
                  setPrintModalVisible(true);
                }}
                okText={t('actionsColumn.confirmation.yes')}
                cancelText={t('actionsColumn.confirmation.no')}
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderPrint)
                }
                placement="topLeft"
              >
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderPrint)
                  }
                  size="small"
                  style={{
                    backgroundColor: HIGHLIGHT_ORANGE,
                    color: WHITE,
                  }}
                  type="link"
                >
                  {t('order.orderDetail.buttonList.print')}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderPrint)
                }
                type="link"
                size="small"
                style={{
                  backgroundColor: HIGHLIGHT_ORANGE,
                  color: WHITE,
                }}
                onClick={() => {
                  setIsFullVersion(true);
                  getPrintoutContents('order', [orderInfo.orderId]);
                  setPrintModalVisible(true);
                }}
              >
                {t('order.orderDetail.buttonList.print')}
              </Button>
            )}

            <Button
              disabled={!hasPermission(actionPermissions.orderGroup.orderPrint)}
              type="primary"
              size="small"
              onClick={() => {
                getPrintoutContents('invoice', [orderInfo.orderId]);
                setInvoicePrintModalVisible(true);
              }}
            >
              {t('order.orderDetail.buttonList.printInvoice')}
            </Button>

            <Button
              disabled={!hasPermission(actionPermissions.orderGroup.orderPrint)}
              type="primary"
              size="small"
              onClick={() => {
                getPrintoutContents('packingSlip', [orderInfo.orderId]);
                setPackingSlipPrintModalVisible(true);
              }}
            >
              {t('order.orderDetail.buttonList.printPackingSlip')}
            </Button>
          </Space>
        </Col>
      )}
      {orderInfo.orderActionList && (
        <Col span={24}>
          <Table
            dataSource={orderInfo.orderActionList}
            columns={columns}
            size="small"
            scroll={{ y: 600, x: 1200 }}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
            }}
            rowKey={(record) => record.actionId}
            expandable={{
              rowExpandable: (record) =>
                record.actionNote && record.actionNote.changeList
                  ? true
                  : false,
              expandedRowRender: (record) => (
                <Table
                  size="small"
                  dataSource={record.actionNote.changeList}
                  columns={actionNoteColumns}
                  pagination={{
                    pageSize: 10,
                    hideOnSinglePage: true,
                    showTotal: (total, range) =>
                      t('general.paginationTotal', {
                        start: range[0],
                        end: range[1],
                        total: total,
                      }),
                  }}
                  rowKey={(record) => record.propertyName}
                  scroll={{ y: 600, x: 1200 }}
                />
              ),
              columnWidth: 30,
            }}
          />
        </Col>
      )}
      <OrderActionModal
        visible={showModal}
        setVisible={setShowModal}
        action={action}
        orderInfo={orderInfo}
        callBack={() => {
          if (isCurrentEditing && setIsCurrentEditing)
            setIsCurrentEditing(false);
          if (callBack) callBack();
        }}
        enums={enums}
      />
      <PrintModal
        modalLoading={modalLoading}
        mode="order"
        isFullVersion={isFullVersion}
        setIsFullVersion={setIsFullVersion}
        data={allOrderPrintoutData}
        setData={setAllOrderPrintoutData}
        visible={printModalVisible}
        setVisible={setPrintModalVisible}
      />
      <PrintModal
        modalLoading={modalLoading}
        mode="invoice"
        data={allOrderPrintoutData}
        setData={setAllOrderPrintoutData}
        visible={invoicePrintModalVisible}
        setVisible={setInvoicePrintModalVisible}
      />
      <PrintModal
        modalLoading={modalLoading}
        mode="packingSlip"
        data={allOrderPrintoutData}
        setData={setAllOrderPrintoutData}
        visible={packingSlipPrintModalVisible}
        setVisible={setPackingSlipPrintModalVisible}
      />
      {smsModalVisible && (
        <UserMessageModal
          visible={smsModalVisible}
          setVisible={setSMSModalVisible}
          userInfo={orderInfo.user}
          contentTemp={[orderInfo.unpaidNotifyContent]}
        />
      )}
      {taskModalVisible && (
        <TaskModal
          visible={taskModalVisible}
          setVisible={setTaskModalVisible}
          order={orderInfo}
        />
      )}
    </Row>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.actionLog')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderActionLogTable;
