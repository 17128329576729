import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Form,
  Grid,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import FourZeroThree from '../../../components/FourZeroThree';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../../constants/color';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  CouponData,
  CouponEnum,
  CouponUserData,
  FontSizeType,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import FiveHundred from '../../FiveHundred';
import CouponUserModal from '../../activity/CouponUserModal';
import SellersDropdown from '../../sellers/SellersDropdown';
import UsersDropdown from '../common/UsersDropdown';
import { tableScrollToTop } from '../../../utils/helperFunction';

type UserCouponTableProps = {
  id1?: string; //id1 is used between UC List in and not in User Detail
};

/**
 * Display User Coupon List from the Sidebar
 * @param id1 is the id when you navigate from user detail
 * @param id2 is the id when you click View Usage in Coupon List
 */
const UserCouponTable = ({ id1 }: UserCouponTableProps) => {
  //General Components
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const queryString = window.location.search;
  const [id2] = useState(new URLSearchParams(queryString).get('coupon_id'));
  const [loading, setLoading] = useState(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { addTab } = useTab();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [modalVisible, setModalVisible] = useState(false);
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [isSeller, setIsSeller] = useState(false);
  //Data Components
  const [couponEnum, setCouponEnum] = useState<CouponEnum>();
  const [couponUserList, setCouponUserList] = useState<Array<CouponUserData>>(
    []
  );
  const [couponUserParentList, setCouponUserParentList] =
    useState<CouponData>();
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Table Components
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [keyword, setKeyword] = useState('');
  const columnKeys = [
    'ucId',
    'userId',
    'orderId',
    'ucSn',
    'couponMoney',
    'isUsed',
    'isUsedTime',
    'startTime',
    'expireTime',
    'couponId|couponName|couponType',
    'dummyCol',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRows, setSelectedRows] = useState<CouponUserData[]>([]);
  const [advance, setAdvance] = useState<{ [key: string]: any }>({});
  //Pagination Components
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '50');
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * This handles and updates the main table rendered on the page
   * It takes in the parameters if provided from the Advanced Search popover
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`coupon/user/list`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        isSeller: isSeller,
        userId: id1
          ? id1
          : formRef.current && form.getFieldValue('user_id')
          ? form.getFieldValue('user_id')
          : undefined,
        couponId: id2 && !id1 ? id2 : undefined,
        isUsed:
          formRef.current && typeof form.getFieldValue('isUsed') === 'boolean'
            ? form.getFieldValue('isUsed')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setCouponUserList(response.data.list);
            setTotal(response.data.totalItem);
            if (id2) setCouponUserParentList(response.data.data);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, keyword, page, pageSize, form, id1, id2, isSeller]);

  const getCouponEnumData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`coupon/enum_list`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setCouponEnum(response.data);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getCouponEnumData();
  }, [getCouponEnumData]);

  const columns: ColumnsType<CouponUserData> = [
    {
      title: setFont(t('activity.userCouponColumnList.ucId'), fontSize),
      dataIndex: 'ucId',
      key: 'ucId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: CouponUserData, b: CouponUserData) => compare(a.ucId, b.ucId),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.userCouponColumnList.userId'), fontSize),
      dataIndex: 'userId',
      key: 'userId',
      width: 100,
      render: (text: number) =>
        text > 0 ? (
          <Button
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
            onClick={() => {
              addTab(``, `${dashboardRoute.users.detail}?user_id=${text}`);
            }}
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
          >
            {text}
          </Button>
        ) : (
          <Typography.Text style={{ fontSize: fontSize }}>
            {text}
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('activity.userCouponColumnList.orderId'), fontSize),
      dataIndex: 'orderId',
      key: 'orderId',
      width: 100,
      render: (text: number) =>
        text > 0 ? (
          <Button
            disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
            onClick={() => {
              addTab(``, `${dashboardRoute.order.detail}?order_id=${text}`);
            }}
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
          >
            {text}
          </Button>
        ) : (
          <Typography.Text style={{ fontSize: fontSize }}>
            {text}
          </Typography.Text>
        ),
    },
    {
      title: setFont(
        t('activity.userCouponColumnList.couponInfo'),
        fontSize,
        `${t('activity.userCouponColumnList.couponId')}|${t(
          'activity.userCouponColumnList.couponName'
        )}|${t('activity.userCouponColumnList.couponType')}`
      ),
      dataIndex: 'coupon',
      key: 'couponId|couponName|couponType',
      width: 200,
      render: (text: string, record: CouponUserData) => (
        <Space direction="vertical">
          <Typography.Text>
            {`${record && record.coupon ? record.coupon.couponName : ''} (${
              record && record.coupon ? record.coupon.couponId : ''
            })`}
          </Typography.Text>
          <Typography.Text>
            {couponEnum?.couponType.map((type, index) => {
              if (
                record &&
                record.coupon &&
                type.code === record.coupon.couponType
              ) {
                return (
                  <Typography.Text style={{ fontSize: fontSize }} key={index}>
                    {type.description}
                  </Typography.Text>
                );
              }
              return <Typography.Text key={index}>{''}</Typography.Text>;
            })}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: setFont(t('activity.userCouponColumnList.ucSn'), fontSize),
      dataIndex: 'ucSn',
      key: 'ucSn',
      width: 150,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.userCouponColumnList.couponMoney'), fontSize),
      dataIndex: 'couponMoney',
      key: 'couponMoney',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.userCouponColumnList.startTime'), fontSize),
      dataIndex: 'startTime',
      key: 'startTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.userCouponColumnList.expireTime'), fontSize),
      dataIndex: 'expireTime',
      key: 'expireTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.userCouponColumnList.isUsed'), fontSize),
      dataIndex: 'isUsed',
      key: 'isUsed',
      width: 100,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('activity.userCouponColumnList.isUsedTime'), fontSize),
      dataIndex: 'isUsedTime',
      key: 'isUsedTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
  ];

  const batchDelete = () => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('coupon/user/delete_batch', {
      ucIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('activity.alerts.userCouponsBatchDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  /**
   * This popover displays, and handles the filters should the user need
   * It takes in inputs and formats it into parameters so that it can be used by getData
   */
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('activity.userCouponColumnList.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
          initialValues={{ isUsed: 'all' }}
        >
          {isSeller && (
            <Form.Item
              label={t('activity.couponColumnList.advancedSearch.sellerId')}
              name="sellerId"
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          {!id1 && (
            <Form.Item
              name="user_id"
              label={t('activity.userCouponColumnList.advancedSearch.userId')}
            >
              <UsersDropdown
                onChange={(value: any) => {
                  setAdvance((prev: any) => ({
                    ...prev,
                    user_id: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            name="isUsed"
            label={t('activity.userCouponColumnList.advancedSearch.isUsed')}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  isUsed: value === 'all' ? '' : value.toString(),
                }));
              }}
              filterOption={false}
              optionLabelProp="key"
              placeholder={`${t('general.pleaseSelect')}`}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('activity.userCouponColumnList.advancedSearch.search')}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('activity.userCouponColumnList.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('activity.userCouponColumnList.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Space>
        {id1 ? (
          <Typography.Title level={4} style={{ marginBottom: 12 }}>
            {t('users.userDetailPage.userCoupon')}
          </Typography.Title>
        ) : (
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('activity.userCouponList')}
          </Typography.Title>
        )}
        {id1 && getDashboardStyle().isSellerSwitch && (
          <Switch
            loading={loading}
            checkedChildren={t('activity.valueCardTypeColumns.seller')}
            unCheckedChildren={t('activity.valueCardTypeColumns.self')}
            style={{ marginBottom: 12 }}
            onChange={(checked) => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  setIsSeller(checked);
                  setPage(1);
                }, EXTENDED_TIMEOUT)
              );
              if (!checked) {
                formRef.current && form.resetFields(['sellerId']);
                setAdvance((prev: any) => ({
                  ...prev,
                  sellerId: undefined,
                }));
              }
            }}
          />
        )}
      </Space>
      <TableToolbar
        advancedSearch={advancedSearch}
        leftElement={
          <>
            {!id1 && (
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.activityGroup.couponManage)
                }
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                {t('activity.addCouponUser.addTitle')}
              </Button>
            )}
          </>
        }
        tableSize={tableSize}
        setTableSize={setTableSize}
        fontSize={fontSize}
        setFontSize={setFontSize}
        refresh={() => getData()}
        totalItems={total}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        search={(keyword) => {
          setKeyword(keyword);
          setPage(1);
        }}
        searchPlaceholder={t('searchPlaceholders.searchUserCoupon')}
        rows={couponUserList.map((userCoupon) => ({
          ...userCoupon,
          couponId:
            userCoupon && userCoupon.coupon ? userCoupon.coupon.couponId : '',
          couponName:
            userCoupon && userCoupon.coupon ? userCoupon.coupon.couponName : '',
          couponType:
            userCoupon && userCoupon.coupon ? userCoupon.coupon.couponType : '',
        }))}
        exportConfig={{ fileName: 'COUPON_USER_LIST' }}
      />
      <Table<CouponUserData>
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        dataSource={couponUserList}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        size={tableSize}
        loading={loading}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
          current: page,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: CouponUserData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        rowKey={(coupon) => coupon.ucId}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          selectedRowKeys={selectedRowKeys}
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          funct={{
            deleteFunc: batchDelete,
            exportConfig: { fileName: 'COUPON_USER_LIST' },
          }}
        />
      )}
      <CouponUserModal
        couponId={Number(id2) || undefined}
        visible={modalVisible}
        setVisible={setModalVisible}
        refresh={() => getData()}
        couponUserParent={couponUserParentList}
      />
    </>
  );
};
export default UserCouponTable;
