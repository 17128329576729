import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Card,
  Form,
  Grid,
  Input,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../../components/Container';
import FiveHundred from '../../../components/FiveHundred';
import FourZeroThree from '../../../components/FourZeroThree';
import SellersDropdown from '../../../components/sellers/SellersDropdown';
import PickupListModal from '../../../components/settings/shipping/PickupListModal';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import { RED1 } from '../../../constants/color';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  FontSizeType,
  PickUpPoint,
  PickupData,
  SellerData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import { tableScrollToTop } from '../../../utils/helperFunction';

const PickupList = () => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { addTab } = useTab();
  const [isSeller, setIsSeller] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //Data Components
  const [pickupList, setPickupList] = useState<PickupData[]>([]);
  const [editPickupData, setEditPickupData] = useState<PickupData>(); //for Pickup Modal
  const [editPickupPointData, setEditPickupPointData] = useState<PickUpPoint>(); //for Pickup Modal
  //Table Components
  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  const [editPickupDataValidator, setPickupDataEditValidator] = useState<{
    data: PickupData;
    rowIndex: any;
    colIndex: any;
  }>();
  const [editPickupPointValidator, setPickupPointEditValidator] = useState<{
    data: PickUpPoint;
    rowIndex: any;
    colIndex: any;
    addressType?: string;
  }>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys: string[] = [
    'pickUpAreaId',
    'pickUpAreaName',
    'seller',
    'isEnabled',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [advance, setAdvance] = useState<{ sellerId?: number }>({});
  //Text Components
  const [addresses, setAddresses] = useState<any>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);

  const updatePickupArea = (
    key: string,
    value: boolean | string | PickUpPoint[],
    record: PickupData
  ) => {
    postDataWithAuthToken('pick_up/edit', {
      pickUpAreaId: record.pickUpAreaId,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getPickupData();
            alertMessage('success', t('settings.alerts.pickupAreaEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setPickupDataEditValidator(undefined);
    setPickupPointEditValidator(undefined);
  };

  /**
   *  Makes a requests to Mapbox to autofill the address in the input box while the user is typing
   */
  const onSearchAddress = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    if (value && value.length > 5) {
      setTypingTimeout(
        setTimeout(() => {
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=ca&language=en&types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
            )
            .then((result) => {
              if (result && result.status === 200 && result.data) {
                if (isSubscribed.current) setAddresses(result.data.features);
              } else alertMessage('error', t('users.alerts.errorOccurred'));
            })
            .catch((err) => {
              console.log(err);
            });
        }, EXTENDED_TIMEOUT)
      );
    } else setAddresses([]);
  };

  //Primary Table
  const columns: ColumnsType<PickupData> = [
    {
      title: setFont(t('settings.pickupListColumns.pickUpAreaId'), fontSize),
      key: 'pickUpAreaId',
      dataIndex: 'pickUpAreaId',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      sorter: (a: PickupData, b: PickupData) =>
        compare(a.pickUpAreaName, b.pickUpAreaName),
      render: (text: string, record: PickupData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.settingGroup.pickupView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setEditPickupData(record);
            setEditPickupPointData(undefined);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('settings.pickupListColumns.pickUpAreaName'), fontSize),
      key: 'pickUpAreaName',
      dataIndex: 'pickUpAreaName',
      width: 500,
      sorter: (a: PickupData, b: PickupData) =>
        compare(a.pickUpAreaName, b.pickUpAreaName),
      render: (text: string, record: PickupData, index) =>
        editPickupDataValidator?.rowIndex === index &&
        editPickupDataValidator.colIndex === 'pickUpAreaName' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={text}
              onBlur={() => {
                setIsEmptyInput(false);
                setPickupDataEditValidator(undefined);
              }}
              onChange={(e) => {
                let target = e.target as HTMLInputElement;
                !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                target.value &&
                  updatePickupArea('pickUpAreaName', target.value, record);
              }}
            />
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Typography.Text
            style={{
              fontSize: fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              setPickupDataEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'pickUpAreaName',
              });
            }}
          >
            {text}
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('settings.pickupListColumns.seller'), fontSize),
      key: 'seller',
      dataIndex: 'seller',
      width: 100,
      render: (record: SellerData) =>
        record && (
          <Typography.Text
            style={{ fontSize: fontSize }}
            ellipsis={{ tooltip: record.shopName }}
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
              }
              style={{ padding: 0, fontSize: fontSize }}
              type="link"
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.sellers.detail}?seller_id=${record.sellerId}`
                )
              }
            >
              {record.shopName}
            </Button>
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('settings.pickupListColumns.isEnabled'), fontSize),
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      width: 120,
      render: (value: boolean, record: PickupData) => (
        <Switch
          checked={value}
          onChange={(checked) => updatePickupArea('isEnabled', checked, record)}
        />
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'action',
      width: 130,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: PickupData) => (
        <Space>
          <Button
            type="link"
            disabled={!hasPermission(actionPermissions.settingGroup.pickupView)}
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditPickupData(record);
              setEditPickupPointData(undefined);
              setShowModal(true);
            }}
          >
            {t('settings.add/editPickup.pickupPoint.addTitle')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeletePickupArea(record.pickUpAreaId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.settingGroup.pickupView)}
          >
            <Button
              danger
              type="link"
              disabled={
                !hasPermission(actionPermissions.settingGroup.pickupView)
              }
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  //Nested Table
  const nestedColumns: ColumnsType<PickUpPoint> = [
    {
      title: setFont(
        t('settings.pickupListColumns.pickUpPoints.pointId'),
        fontSize
      ),
      dataIndex: 'pointId',
      key: 'pointId',
      width: 120,
      sorter: (a: PickUpPoint, b: PickUpPoint) => compare(a.pointId, b.pointId),
      render: (id: number, record: PickUpPoint) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.settingGroup.pickupView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            pickupList.forEach((pickupObj) => {
              if (
                pickupObj.pickUpPoints.findIndex(
                  (pickPoint) => pickPoint === record
                ) !== -1
              ) {
                setEditPickupPointData(record);
                setEditPickupData(pickupObj);
                setShowModal(true);
              }
            });
          }}
        >
          {id.toString()}
        </Button>
      ),
    },
    {
      title: setFont(
        t('settings.pickupListColumns.pickUpPoints.pointName'),
        fontSize
      ),
      dataIndex: 'pointName',
      key: 'pointName',
      width: 120,
      sorter: (a: PickUpPoint, b: PickUpPoint) =>
        compare(a.pointName, b.pointName),
      render: (text: string, record: PickUpPoint, index) =>
        editPickupPointValidator &&
        editPickupPointValidator.data.pointId === record.pointId &&
        editPickupPointValidator.rowIndex === index &&
        editPickupPointValidator.colIndex === 'pointName' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={text}
              style={{ width: 120 }}
              onBlur={() => {
                setIsEmptyInput(false);
                setPickupPointEditValidator(undefined);
              }}
              onChange={(e) => {
                let target = e.target as HTMLInputElement;
                !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                pickupList.forEach((pickupObj, i) => {
                  if (
                    target.value &&
                    pickupObj.pickUpPoints.findIndex(
                      (pickPoint) => pickPoint === record
                    ) !== -1
                  ) {
                    record.pointName = target.value;
                    updatePickupArea(
                      'pickUpPoints',
                      pickupObj.pickUpPoints,
                      pickupObj
                    );
                  }
                });
              }}
            />
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Typography.Text
            style={{
              fontSize: fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              setPickupPointEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'pointName',
              });
            }}
          >
            {text}
          </Typography.Text>
        ),
    },
    {
      title: setFont(
        t('settings.pickupListColumns.pickUpPoints.contactName'),
        fontSize
      ),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 120,
      render: (text: string, record: PickUpPoint, index) =>
        editPickupPointValidator &&
        editPickupPointValidator.data.pointId === record.pointId &&
        editPickupPointValidator.rowIndex === index &&
        editPickupPointValidator.colIndex === 'contactName' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={text}
              style={{ width: 120 }}
              onBlur={() => {
                setIsEmptyInput(false);
                setPickupPointEditValidator(undefined);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                pickupList.forEach((pickupObj, i) => {
                  if (
                    target.value &&
                    pickupObj.pickUpPoints.findIndex(
                      (pickPoint) => pickPoint === record
                    ) !== -1
                  ) {
                    record.contactName = target.value;
                    updatePickupArea(
                      'pickUpPoints',
                      pickupObj.pickUpPoints,
                      pickupObj
                    );
                  }
                });
              }}
            />
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Button
            type="text"
            style={{
              textAlign: 'left',
              width: '100%',
              fontSize: fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              setPickupPointEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'contactName',
              });
            }}
          >
            {text ? text : ' '}
          </Button>
        ),
    },
    {
      title: setFont(
        t('settings.pickupListColumns.pickUpPoints.address'),
        fontSize
      ),
      dataIndex: 'address',
      key: 'address',
      width: 520,
      render: (text: string, record: PickUpPoint, index) =>
        editPickupPointValidator &&
        editPickupPointValidator.data.pointId === record.pointId &&
        editPickupPointValidator.rowIndex === index &&
        editPickupPointValidator.colIndex === 'address' ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            {editPickupPointValidator.addressType === 'address' && (
              <>
                <AutoComplete
                  placeholder={t('searchPlaceholders.searchAddress')}
                  autoFocus
                  style={{ width: '100%' }}
                  defaultValue={record.address}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  onBlur={() => {
                    setIsEmptyInput(false);
                    setPickupPointEditValidator(undefined);
                  }}
                  onChange={(value) => {
                    if (value) {
                      setIsEmptyInput(false);
                      onSearchAddress(value);
                    } else {
                      setIsEmptyInput(true);
                    }
                  }}
                  onSelect={(value: string) => {
                    pickupList.forEach((pickupObj, i) => {
                      if (
                        value &&
                        pickupObj.pickUpPoints.findIndex(
                          (pickPoint) => pickPoint === record
                        ) !== -1
                      ) {
                        record.address = value;
                        updatePickupArea(
                          'pickUpPoints',
                          pickupObj.pickUpPoints,
                          pickupObj
                        );
                      }
                    });
                  }}
                >
                  {addresses.map((address: { place_name: string }) => (
                    <AutoComplete.Option
                      key={JSON.stringify(address)}
                      value={address.place_name}
                    >
                      {address.place_name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
                <Typography.Text
                  style={{
                    fontSize: fontSize,
                    color: RED1,
                    display: isEmptyInput ? '' : 'none',
                  }}
                >
                  {t('general.inputError.empty')}
                </Typography.Text>
              </>
            )}
            {editPickupPointValidator.addressType === 'mobile' && (
              <>
                <Input
                  autoFocus
                  defaultValue={record.mobile}
                  onBlur={() => {
                    setIsEmptyInput(false);
                    setPickupPointEditValidator(undefined);
                  }}
                  onChange={(e) => {
                    let target = e.target as HTMLInputElement;
                    !target.value
                      ? setIsEmptyInput(true)
                      : setIsEmptyInput(false);
                  }}
                  onPressEnter={(e) => {
                    let target = e.target as HTMLInputElement;
                    pickupList.forEach((pickupObj, i) => {
                      if (
                        target.value &&
                        pickupObj.pickUpPoints.findIndex(
                          (pickPoint) => pickPoint === record
                        ) !== -1
                      ) {
                        record.mobile = target.value;
                        updatePickupArea(
                          'pickUpPoints',
                          pickupObj.pickUpPoints,
                          pickupObj
                        );
                      }
                    });
                  }}
                />
                <Typography.Text
                  style={{
                    fontSize: fontSize,
                    color: RED1,
                    display: isEmptyInput ? '' : 'none',
                  }}
                >
                  {t('general.inputError.empty')}
                </Typography.Text>
              </>
            )}
          </Space>
        ) : (
          <Space size={0} direction="vertical" style={{ width: '100%' }}>
            <Button
              type="text"
              style={{
                height: 'auto',
                padding: 0,
                whiteSpace: 'normal',
                textAlign: 'left',
              }}
              onClick={() => {
                setPickupPointEditValidator({
                  data: record,
                  rowIndex: index,
                  colIndex: 'address',
                  addressType: 'address',
                });
              }}
            >
              <div
                style={{
                  wordBreak: 'break-all',
                  textAlign: 'left',
                  overflow: 'hidden',
                  height: '60px',
                }}
              >
                {record.address ? record.address : ' '}
              </div>
            </Button>
            <Button
              type="text"
              style={{
                padding: 0,
                whiteSpace: 'normal',
                textAlign: 'left',
              }}
              onClick={() => {
                setPickupPointEditValidator({
                  data: record,
                  rowIndex: index,
                  colIndex: 'address',
                  addressType: 'mobile',
                });
              }}
            >
              <div
                style={{
                  wordBreak: 'break-all',
                  textAlign: 'left',
                  overflow: 'hidden',
                  height: '60px',
                }}
              >
                {record.mobile ? record.mobile : ' '}
              </div>
            </Button>
          </Space>
        ),
    },
    {
      title: t('actionsColumn.title'),
      key: 'action',
      width: 120,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: PickUpPoint) => (
        <Space>
          <Button
            type="link"
            disabled={!hasPermission(actionPermissions.settingGroup.pickupView)}
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              pickupList.forEach((pickupObj) => {
                if (
                  pickupObj.pickUpPoints.findIndex(
                    (pickPoint) => pickPoint === record
                  ) !== -1
                ) {
                  setEditPickupPointData(record);
                  setEditPickupData(pickupObj);
                  setShowModal(true);
                }
              });
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => {
              pickupList.forEach((pickupObj, i) => {
                if (
                  pickupObj.pickUpPoints.findIndex(
                    (pickPoint) => pickPoint === record
                  ) !== -1
                ) {
                  if (pickupObj.pickUpPoints.length <= 1) {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(
                        () =>
                          alertMessage(
                            'warning',
                            t('settings.alerts.emptyPickupPoint')
                          ),
                        EXTENDED_TIMEOUT
                      )
                    );
                  } else {
                    updatePickupArea(
                      'pickUpPoints',
                      pickupObj.pickUpPoints.filter(
                        (point) => point !== record
                      ),
                      pickupObj
                    );
                  }
                }
              });
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.settingGroup.pickupView)}
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.settingGroup.pickupView)
              }
              danger
              type="link"
              style={{ padding: 0 }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  /**
   * @param areaId Id of PickupArea to delete
   */
  const handleDeletePickupArea = (areaId: number) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('pick_up/delete', {
      pickUpAreaId: areaId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.transDeleted'));
          getPickupData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  /**
   * makes request call and loads the PickupData variable
   */
  const getPickupData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('pick_up/list', {
      params: {
        page: page,
        size: pageSize,
        isSeller: isSeller,
        keyword: keyword || undefined,
        sellerId:
          isSeller && formRef.current && form.getFieldValue('seller_Id')
            ? form.getFieldValue('seller_Id')
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setPickupList(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, page, pageSize, keyword, form, isSeller]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getPickupData();
  }, [getPickupData]);

  /**
   * This popover displays, and handles the filters*/
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('settings.transListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          <Form.Item
            name="seller_Id"
            label={t('settings.transListColumns.advancedSearch.sellerId')}
          >
            <SellersDropdown
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                disabled={!advance.sellerId}
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getPickupData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('settings.transListColumns.advancedSearch.search')}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setAdvance({
                    sellerId: -1,
                  });
                  if (page !== 1) setPage(1);
                  else getPickupData();
                }}
              >
                {t('settings.transListColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Button
        style={{ display: !isSeller ? 'none' : '' }}
        icon={<FilterOutlined />}
      >
        {t('settings.transListColumns.advancedSearch.title')}
      </Button>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('settings.pickupListColumns.title')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={isLoading}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                    }, EXTENDED_TIMEOUT)
                  );
                }}
                style={{ marginBottom: 12 }}
                checkedChildren={t('goods.goodsListColumns.seller')}
                unCheckedChildren={t('goods.goodsListColumns.self')}
              />
            )}
          </Space>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                disabled={
                  !hasPermission(actionPermissions.settingGroup.pickupView)
                }
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditPickupData(undefined);
                  setEditPickupPointData(undefined);
                  setShowModal(true);
                }}
              >
                {t('settings.add/editPickup.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getPickupData();
            }}
            totalItems={total}
            setSelectedRowKeys={setSelectedRowKeys}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchTransport')}
            advancedSearch={advancedSearch}
            rows={pickupList.map((pickupObj) => ({
              ...pickupObj,
              seller: pickupObj.seller.shopName,
            }))}
            exportConfig={{ fileName: 'PICKUP_LIST' }}
          />
          <Table
            dataSource={pickupList}
            rowKey={(pickupArea) => pickupArea.pickUpAreaId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            size={tableSize}
            loading={isLoading}
            pagination={{
              hideOnSinglePage: true,
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
              },
              current: page,
            }}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  size="small"
                  dataSource={record.pickUpPoints}
                  columns={nestedColumns}
                  pagination={{ pageSize: 10, hideOnSinglePage: true }}
                  rowKey={(record) => record.pointId}
                />
              ),
              columnWidth: 30,
            }}
            scroll={{ x: 1200, y: 600 }}
            components={vt}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'PICKUP_LIST' } }}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              selectedRows={[]}
            />
          )}
        </Card>
      )}
      <PickupListModal
        pickPointData={editPickupPointData ? editPickupPointData : undefined}
        isSeller={isSeller}
        visible={showModal}
        setVisible={setShowModal}
        pickupData={editPickupData}
        refresh={() => getPickupData()}
      />
    </Container>
  );
};

export default PickupList;
