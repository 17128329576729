import React, { useState, useEffect } from 'react';
import { ShopConfigData } from '../../../types';
import { Form, Button, FormInstance, Image, Space } from 'antd';
import PhotoGalleryModal from '../../PhotoGalleryModal';
import { useTranslation } from 'react-i18next';
import { EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { FALLBACK_IMG } from '../../../constants/styles';
import { MEDIA_TYPE } from '../../../constants/mediaConstants';

type PhotoSelectProps = {
  field: ShopConfigData;
  form: FormInstance<any>;
};

/**
 * Photo Select Field for ShopConfig Forms
 *
 * @param field Field information
 * @param form  Form Instance
 */
const PhotoSelect = ({ field, form }: PhotoSelectProps) => {
  const { t } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [media, setMedia] = useState({
    photo: '',
    photoPath: '',
  });

  useEffect(() => {
    form.setFieldsValue({ [field.code]: media.photo });
  }, [media, field, form]);

  useEffect(() => {
    setMedia({
      photo: field.value || '',
      photoPath: field.valueDetail ? field.valueDetail.filePath : '',
    });
  }, [field]);

  return (
    <Form.Item key={field.id} noStyle>
      <Form.Item
        name={field.code}
        initialValue={field.value}
        help={field.remark ? field.remark : undefined}
        style={{ marginBottom: field.remark ? 16 : 24 }}
        label={field.description}
      >
        <Space align="start">
          <Space direction="vertical" align="center">
            <Image
              width={100}
              src={media.photoPath}
              preview={{ mask: <EyeOutlined /> }}
              fallback={FALLBACK_IMG}
            />

            <Button
              onClick={() => {
                setFirstLoad(true);
                setModalVisible(true);
              }}
            >
              {t('settings.photoGallery')}
            </Button>
          </Space>
          {media.photoPath && (
            <MinusCircleOutlined
              style={{ color: ' #ff0f0f' }}
              onClick={() => setMedia({ photo: '', photoPath: '' })}
            />
          )}
        </Space>
      </Form.Item>
      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={modalVisible}
        setVisible={setModalVisible}
        setValue={setMedia}
        value={media}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
    </Form.Item>
  );
};

export default PhotoSelect;
