import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

interface PageHistoryState {
  activePage: string;
  value: Array<{ title: string; path: string; pinned?: boolean }>;
}

const initialState: PageHistoryState = {
  activePage: window.location.pathname,
  value: [],
};

export const pageHistorySlice = createSlice({
  name: 'pageHistory',
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload;
    },
    pinPage: (
      state,
      action: PayloadAction<{ path: string; pinned: boolean }>
    ) => {
      const pageIndex = state.value.findIndex(
        (_page) => _page.path === action.payload.path
      );
      let newArr = state.value.slice();
      if (pageIndex > -1) {
        newArr[pageIndex] = {
          ...newArr[pageIndex],
          pinned: action.payload.pinned,
        };
        state.value = newArr;
      }
    },
    addPage: (
      state,
      action: PayloadAction<{ title: string; path: string }>
    ) => {
      const pageIndex = state.value.findIndex(
        (_page) => _page.path === action.payload.path
      );
      let newArr = state.value.slice();
      if (pageIndex > -1) {
        newArr[pageIndex] = {
          ...action.payload,
          title: action.payload.title || state.value[pageIndex].title,
          path: action.payload.path || state.value[pageIndex].path,
          pinned: state.value[pageIndex].pinned,
        };
        state.value = newArr;
      } else {
        state.value = [...state.value, action.payload];
      }
    },
    removePagePath: (state, action: PayloadAction<string>) => {
      const pageIndex = state.value.findIndex(
        (_page) => _page.path === action.payload
      );
      if (pageIndex > -1) {
        state.value = [
          ...state.value.slice(0, pageIndex),
          ...state.value.slice(pageIndex + 1),
        ];
      }
    },
    removePageTitle: (state, action: PayloadAction<string>) => {
      const pageIndex = state.value.findIndex(
        (_page) => _page.title === action.payload
      );
      if (pageIndex > -1) {
        state.value = [
          ...state.value.slice(0, pageIndex),
          ...state.value.slice(pageIndex + 1),
        ];
      }
    },
    removeAllPages: (state) => {
      state.value = state.value.slice(0, 1);
    },
    removePageOnRefresh: (
      state,
      action: PayloadAction<{
        page: { title: string; path: string };
        refreshTitle: string;
      }>
    ) => {
      const pageIndex = state.value.findIndex(
        (_page) => _page.path === action.payload.page.path
      );
      if (pageIndex > -1) {
        state.value = [
          ...state.value.slice(0, pageIndex),
          {
            title: action.payload.refreshTitle,
            path: '',
          },
          ...state.value.slice(pageIndex + 1),
        ];
      }
    },
    insertPageOnRefresh: (
      state,
      action: PayloadAction<{
        page: { title: string; path: string };
        index: number;
      }>
    ) => {
      state.value = [
        ...state.value.slice(0, action.payload.index),
        action.payload.page,
        ...state.value.slice(action.payload.index + 1),
      ];
    },
  },
});

export const {
  setActivePage,
  pinPage,
  addPage,
  removePagePath,
  removePageTitle,
  removeAllPages,
  removePageOnRefresh,
  insertPageOnRefresh,
} = pageHistorySlice.actions;

// Other code such as selectors can use th imported `RootState` type
export const selectPageHistory = (state: RootState) => state.pageHistory.value;

export const activePage = (state: RootState) => state.pageHistory.activePage;

export default pageHistorySlice.reducer;
