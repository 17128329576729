import { Typography } from 'antd';
import { FontSizeType } from '../types';

export const compare = (
  a: string | number | null,
  b: string | number | null
) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b;
  return (a !== null ? a.toString() : '').localeCompare(
    b !== null ? b.toString() : ''
  );
};

export const setFont = (
  text: string,
  fontSize?: FontSizeType,
  exportColumns?: string
) => (
  <Typography.Text key={exportColumns} style={{ fontSize: fontSize }}>
    {text}
  </Typography.Text>
);
