import Container from '../../components/Container';
import { Card, Space, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { TaskEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';
import TaskGoodsDistribution from '../../components/analysis/task/TaskGoodsDistribution';
import TaskDistribution from '../../components/analysis/task/TaskDistribution';

const TaskAnalysis = () => {
  //General Components
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  const [taskEnum, setTaskEnum] = useState<TaskEnum>();
  //Text Components
  const { t } = useTranslation();

  const getTaskEnum = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('task/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [t]);

  useEffect(() => {
    getTaskEnum();
  }, [getTaskEnum]);

  return (
    <Container>
      <Card bodyStyle={{ minHeight: 250 }}>
        <Space>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('analysis.taskTitle')}
          </Typography.Title>
        </Space>
        <Tabs destroyInactiveTabPane defaultActiveKey="1">
          <Tabs.TabPane
            tab={t(`analysis.taskDistribution.tab1Title`)}
            disabled={!hasPermission(actionPermissions.analysisGroup.taskStat)}
            key={'1'}
          >
            <TaskDistribution
              taskEnum={taskEnum}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.taskGoodsDistribution.tab2Title`)}
            disabled={!hasPermission(actionPermissions.analysisGroup.taskStat)}
            key={'2'}
          >
            <TaskGoodsDistribution
              taskEnum={taskEnum}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Container>
  );
};

export default TaskAnalysis;
