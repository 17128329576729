import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardRoute, authRoute } from '../constants/pathname';
import { alertMessage } from '../utils/alertMessage';
import { useAuth } from '../utils/createAuthProvider';

/**
 * Landing page for Luniu Ecommerce Dashboard
 * Redirects User to Dashboard or Login depending on login status
 *
 * /
 */
const Landing = () => {
  const history = useHistory();
  const auth = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.isLogged) {
      history.push(dashboardRoute.home);
    } else {
      history.push(authRoute.login);
      alertMessage('error', t('login.alerts.loginRequired'));
    }
  }, [history, auth, t]);

  return <></>;
};

export default Landing;
