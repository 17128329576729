import { configureStore } from '@reduxjs/toolkit';
import appConfigReducer from '../features/appConfig/appConfigSlice';
import pageHistoryReducer from '../features/pageHistory/pageHistorySlice';
import selectedUserReducer from '../features/selectedUser/selectedUserSlice';

/**
 * Stores any details that need to be accessed from different parts of the app.
 */
export const store = configureStore({
  reducer: {
    pageHistory: pageHistoryReducer,
    selectedUser: selectedUserReducer,
    appConfig: appConfigReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type
export type AppDispatch = typeof store.dispatch;
