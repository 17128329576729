import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { FALLBACK_IMG } from '../../../constants/styles';
import { OrderData, OrderFormValue } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type OrderFormInfoProps = {
  orderInfo?: OrderData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderFormInfo = ({
  orderInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: OrderFormInfoProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnEdit = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          let updatedFormValues: OrderFormValue[] =
            orderInfo && orderInfo.orderExtend
              ? orderInfo.orderExtend.formValue
              : [];
          Object.keys(values).forEach((key) => {
            let index = updatedFormValues.findIndex(
              (item) => item.field_id.toString() === key.split('|')[0]
            );
            updatedFormValues[index] = {
              ...updatedFormValues[index],
              child: {
                ...updatedFormValues[index].child,
                [key.split('|')[1]]: {
                  field_name:
                    updatedFormValues[index].child[key.split('|')[1]]
                      .field_name,
                  value: values[key],
                },
              },
            };
          });

          postDataWithAuthToken('order/edit', {
            orderId: orderInfo?.orderId,
            formValue: updatedFormValues,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('order.alerts.orderEdited'));
                handleOnCancel();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setEditing(true);
        if (setIsCurrentEditing) setIsCurrentEditing(true);
      }
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    setEditing(false);
    if (setIsCurrentEditing) setIsCurrentEditing(false);
  };

  const renderField = (field: any) => {
    switch (field.fieldType) {
      case 'STRING':
        return <Input />;
      case 'TEXT':
        return <Input.TextArea autoSize={{ minRows: 1 }} />;
      case 'NUMBER':
        return (
          <InputNumber
            type="number"
            style={{ width: '100%' }}
            controls={false}
          />
        );
      case 'FILE':
      case 'P_FILE':
        return (
          <Image
            src={field.fullValue}
            fallback={FALLBACK_IMG}
            style={{ maxWidth: 200 }}
          />
        );
      default:
        return <></>;
    }
  };

  return orderInfo &&
    orderInfo.orderExtend &&
    orderInfo.orderExtend.formValue ? (
    <Spin spinning={isLoading}>
      <Form form={form} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.orderDetail.formInfo')}
            </Typography.Text>
            {editing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          <Col span={24}>
            <Row gutter={[0, 24]}>
              {orderInfo.orderExtend.formValue.map((item: OrderFormValue) => (
                <Col key={item.field_id} span={24}>
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <Typography.Text strong>
                        {item.field_name}
                      </Typography.Text>
                    </Col>
                    {Object.keys(item.child).map((key: any) => (
                      <Col key={key} span={24} md={12} lg={6}>
                        {
                          <Form.Item
                            label={`${item.child[key].field_name}: `}
                            style={{
                              marginBottom: 0,
                              display: item.child[key].isShow
                                ? undefined
                                : 'none',
                            }}
                            rules={[{ required: item.child[key].isRequired }]}
                            name={
                              editing ? `${item.field_id}|${key}` : undefined
                            }
                            initialValue={item.child[key].value}
                          >
                            {editing ? (
                              renderField(item.child[key])
                            ) : item.child[key].fieldType === 'FILE' ||
                              item.child[key].fieldType === 'P_FILE' ? (
                              <Image
                                src={item.child[key].fullValue}
                                fallback={FALLBACK_IMG}
                                style={{ maxWidth: 200 }}
                              />
                            ) : (
                              <Typography.Text>
                                {item.child[key].value}
                              </Typography.Text>
                            )}
                          </Form.Item>
                        }
                      </Col>
                    ))}
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.formInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderFormInfo;
