import { EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Image,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MEDIA_TYPE } from '../../../constants/mediaConstants';
import { FALLBACK_IMG } from '../../../constants/styles';
import { OrderFormValue } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import PhotoGalleryModal from '../../PhotoGalleryModal';

type OtherInfoFormProps = {
  form: FormInstance<any>;
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
};

type MediaData = {
  photo: string;
  photoPath: string;
  originalPic: string;
  originalPicPath: string;
  thumbPic: string;
  largePic: string;
  thumbPicPath: string;
  largePicPath: string;
  valueKey?: string;
};

const OtherInfoForm = ({ form, formData, setFormData }: OtherInfoFormProps) => {
  const { t } = useTranslation();
  const [fileRef, setFileRef] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [filePath, setFilePath] = useState<{
    [key: string]: { originalPic: ''; originalPicPath: '' };
  }>();
  const [selectedFile, setSelectedFile] = useState<MediaData | undefined>();

  const getFormList = useCallback(() => {
    getDataWithAuthToken('form_field/blank_list', {
      params: {
        sellerId: formData.sellerId,
        fieldCode: 'ORDER',
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setFormData((prev) => ({ ...prev, formValue: response.data.list }));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t, formData.sellerId, setFormData]);

  useEffect(() => {
    getFormList();
  }, [getFormList]);

  useEffect(() => {
    if (selectedFile && selectedFile.valueKey) {
      setFilePath((prev: any) => {
        return {
          ...prev,
          [`${selectedFile.valueKey}`]: {
            originalPic: selectedFile.originalPic,
            originalPicPath: selectedFile.originalPicPath,
          },
        };
      });
      const groupIndex = Number(selectedFile.valueKey.split('|')[0]);
      const fieldId = selectedFile.valueKey.split('|')[1];
      const fieldName = selectedFile.valueKey.split('|')[2];

      setFormData((prev) => {
        let updatedFormValues: OrderFormValue[] = prev.formValue;
        // find key in formValue and replace value
        if (updatedFormValues[groupIndex].child[fieldName]) {
          updatedFormValues[groupIndex].child[fieldName] = {
            ...updatedFormValues[groupIndex].child[fieldName],
            value: selectedFile.photoPath,
          };
        }

        const formFieldsValue = form.getFieldsValue();
        form.setFieldsValue({
          ...formFieldsValue,
          // using originalPicPath not originalPic, because it does not show image if go back from next page
          [`${fieldId}|${fieldName}`]: selectedFile.originalPicPath,
        });

        return { ...prev, formValue: updatedFormValues };
      });
    }
  }, [selectedFile, form, setFormData]);

  const renderField = (
    groupItem: OrderFormValue,
    fieldKey: string,
    groupIndex: number
  ) => {
    const field = groupItem.child[fieldKey];
    switch (field.fieldType) {
      case 'STRING':
        return <Input />;
      case 'TEXT':
        return <Input.TextArea autoSize={{ minRows: 1 }} />;
      case 'NUMBER':
        return (
          <InputNumber
            type="number"
            style={{ width: '100%' }}
            controls={false}
          />
        );
      case 'FILE':
      case 'P_FILE':
        const formFilePath = form.getFieldValue(
          `${groupItem.field_id}|${fieldKey}`
        );
        const valueKey = `${groupIndex}|${groupItem.field_id}|${fieldKey}`;
        return (
          <Space size="large" align="start">
            <Space align="start">
              <Image
                width={50}
                src={
                  filePath && filePath[valueKey]
                    ? filePath[valueKey].originalPicPath
                    : formFilePath
                    ? formFilePath
                    : undefined
                }
                preview={{ mask: <EyeOutlined /> }}
                fallback={FALLBACK_IMG}
              />
              {filePath &&
                filePath[valueKey] &&
                filePath[valueKey].originalPic && (
                  <MinusCircleOutlined
                    style={{ color: ' #ff0f0f' }}
                    onClick={() => {
                      // remove filePath with 'valueKey' path
                      setFilePath((prevFilePath) => {
                        const updatedFilePath = { ...prevFilePath };
                        delete updatedFilePath[valueKey];
                        return updatedFilePath;
                      });
                    }}
                  />
                )}
            </Space>
            <Button
              onClick={() => {
                setFileRef(valueKey);
                setFirstLoad(true);
                setFileModalVisible(true);
              }}
            >
              {t('goods.add/editCategory.photoGallery')}
            </Button>
          </Space>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item
          label={`${t('order.orderDetail.buyerRemark')}: `}
          name="buyerRemark"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={`${t('order.orderDetail.sellerRemark')}: `}
          name="sellerRemark"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={`${t('order.orderDetail.deliveryRemark')}: `}
          name="deliveryRemark"
        >
          <Input.TextArea />
        </Form.Item>
        <Row gutter={[0, 24]}>
          {formData &&
            formData.formValue &&
            formData.formValue.map(
              (item: OrderFormValue, groupIndex: number) =>
                item.isShow && (
                  <Col key={item.field_id} span={24}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Typography.Text strong>
                          {item.field_name}
                        </Typography.Text>
                      </Col>
                      {Object.keys(item.child).map((key: any) => (
                        <Col key={key} span={24} md={12} lg={6}>
                          <Form.Item
                            label={`${item.child[key].field_name}: `}
                            style={{
                              marginBottom: 0,
                              display: item.child[key].isShow
                                ? undefined
                                : 'none',
                            }}
                            rules={[
                              {
                                required: item.child[key].isRequired,
                                message: t('general.inputError.empty'),
                              },
                            ]}
                            name={`${item.field_id}|${key}`}
                            initialValue={item.child[key].value}
                          >
                            {renderField(item, key, groupIndex)}
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                )
            )}
        </Row>
      </Form>

      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={fileModalVisible}
        setVisible={setFileModalVisible}
        setValue={setSelectedFile}
        value={selectedFile}
        valueKey={fileRef}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
    </>
  );
};

export default OtherInfoForm;
