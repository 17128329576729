import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RED1 } from '../../constants/color';
import { GoodData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import TransportDropdown from '../settings/common/TransportDropdown';

type GoodsBatchModalProps = {
  mode: 'goods' | 'relatedGoods';
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows?: GoodData[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  refresh?: Function;
};

const GoodsBatchModal = ({
  mode,
  visible,
  setVisible,
  selectedRows,
  refresh,
  setSelectedRowKeys,
}: GoodsBatchModalProps) => {
  //General Components
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  //Data Components
  const [batchData, setBatchData] = useState<GoodData[]>([]);

  const [editValidator, setEditValidator] = useState<{
    data: GoodData;
    rowIndex: any;
    colIndex: any;
  }>();

  const onOk = () => {
    form.validateFields().then(() => {
      postDataWithAuthToken(
        mode === 'relatedGoods'
          ? 'goods/related_goods/import_batch'
          : 'goods/edit/batch/review_price',
        {
          goodsList:
            batchData.length !== 0
              ? batchData.map((obj) => {
                  return mode === 'relatedGoods'
                    ? {
                        goodsId: obj.goodsId,
                        goodsName: obj.goodsName,
                        goodsUnit: obj.goodsUnit,
                        shopPrice: obj.shopPrice,
                      }
                    : {
                        goodsId: obj.goodsId,
                        shopPrice: obj.shopPrice,
                      };
                })
              : undefined,

          shippingTransportId:
            mode === 'relatedGoods'
              ? form.getFieldValue('shippingTransportId')
              : undefined,
        }
      )
        .then((response) => {
          if (response) {
            if (response.goodStatus) {
              onCancel();
              if (setSelectedRowKeys) setSelectedRowKeys([]);
              if (refresh) refresh();
              alertMessage('success', t('goods.alerts.batchEdited'));
            } else if (!response.goodStatus && !response.batchStatus) {
              alertMessage('error', t('general.fourFourFour'));
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    if (visible) {
      if (batchData.length === 0 && selectedRows) setBatchData(selectedRows);
    }
  }, [visible, batchData, selectedRows]);

  const onCancel = () => {
    setIsLoading(false);
    setVisible(false);
    setBatchData([]);
  };

  const columns: ColumnsType<GoodData> = [
    {
      title: t('goods.goodsListColumns.goodsName'),
      key: 'goodsName',
      dataIndex: 'goodsName',
      width: 350,
      render: (value: string, record: GoodData, index) =>
        mode !== 'goods' &&
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'goodsName' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={record.goodsName}
              style={{ width: 300 }}
              onBlur={(e) => {
                let target = e.target as HTMLInputElement;
                if (target.value) {
                  batchData.forEach((goodObj) => {
                    if (goodObj.goodsId === record.goodsId) {
                      goodObj.goodsName = target.value;
                    }
                  });
                }
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(e) => {
                let target = e.target as HTMLInputElement;
                !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                if (target.value) {
                  batchData.forEach((goodObj) => {
                    if (goodObj.goodsId === record.goodsId) {
                      goodObj.goodsName = target.value;
                    }
                  });
                }
              }}
            />
            <Typography.Text
              style={{
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Typography.Text
            style={{
              cursor: mode !== 'goods' ? 'pointer' : undefined,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'goodsName',
              });
            }}
          >
            {record.goodsName}
          </Typography.Text>
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsUnit'),
      key: 'goodsUnit',
      dataIndex: 'goodsUnit',
      width: 140,
      render: (value: string, record: GoodData, index) =>
        mode !== 'goods' &&
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'goodsUnit' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={value}
              style={{ width: 120 }}
              onBlur={(e) => {
                let target = e.target as HTMLInputElement;
                if (target.value) {
                  batchData.forEach((goodObj) => {
                    if (goodObj.goodsId === record.goodsId) {
                      goodObj.goodsUnit = target.value;
                    }
                  });
                }
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(e) => {
                let target = e.target as HTMLInputElement;
                !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                if (target.value) {
                  batchData.forEach((goodObj) => {
                    if (goodObj.goodsId === record.goodsId) {
                      goodObj.goodsUnit = target.value;
                    }
                  });
                }
              }}
            />
            <Typography.Text
              style={{
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Typography.Text
            style={{
              cursor: mode !== 'goods' ? 'pointer' : undefined,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'goodsUnit',
              });
            }}
          >
            {value}
          </Typography.Text>
        ),
    },
    {
      title: t('goods.goodsListColumns.shopPrice'),
      key: 'shopPrice',
      dataIndex: 'shopPrice',
      width: mode === 'goods' ? 80 : undefined,
      render: (value: number, record: GoodData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'shopPrice' ? (
          <Space direction="vertical">
            <InputNumber
              autoFocus
              defaultValue={value}
              style={{ width: 65 }}
              onBlur={(e) => {
                let target = e.target as HTMLInputElement;
                if (target.value) {
                  batchData.forEach((goodObj) => {
                    if (goodObj.goodsId === record.goodsId) {
                      goodObj.shopPrice = target.value;
                    }
                  });
                }
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(input) =>
                !input ? setIsEmptyInput(true) : setIsEmptyInput(false)
              }
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                if (target.value) {
                  batchData.forEach((goodObj) => {
                    if (goodObj.goodsId === record.goodsId) {
                      goodObj.shopPrice = target.value;
                    }
                  });
                }
              }}
            />
            <Typography.Text
              style={{
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'shopPrice',
              });
            }}
          >
            {value}
          </Button>
        ),
    },
    {
      title: t('goods.goodsListColumns.mainShopPrice'),
      key: 'mainShopPrice',
      render: (record: GoodData) =>
        record && record.mainGoods ? record.mainGoods.shopPrice : undefined,
    },
  ];

  return (
    <Modal
      title={
        mode === 'goods'
          ? t('goods.goodsBatch.editPrice')
          : t('goods.goodsBatch.importMainGoods')
      }
      visible={visible}
      cancelText={t('order.print.cancel')}
      onCancel={onCancel}
      onOk={onOk}
      width={screens.md ? 768 : '90%'}
    >
      <Spin spinning={isLoading}>
        {mode === 'goods' ? (
          <Table<GoodData>
            style={{ paddingBottom: 15 }}
            size="small"
            dataSource={batchData}
            rowKey={(goodObj) => goodObj.goodsId}
            columns={columns}
            scroll={{
              x: 'max-content',
              y: selectedRows && selectedRows.length > 10 ? 350 : undefined,
            }}
            pagination={false}
          />
        ) : mode === 'relatedGoods' ? (
          <Form ref={formRef} form={form}>
            <Table<GoodData>
              style={{ paddingBottom: 15 }}
              size="small"
              dataSource={batchData}
              rowKey={(goodObj) => goodObj.goodsId}
              columns={columns.slice(0, -1)}
              scroll={{
                x: 'max-content',
                y: selectedRows && selectedRows.length > 10 ? 500 : undefined,
              }}
              pagination={false}
            />
            <Form.Item
              name="shippingTransportId"
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
              label={t('goods.goodsBatch.shippingTransportId')}
            >
              <TransportDropdown />
            </Form.Item>
          </Form>
        ) : (
          <></>
        )}
      </Spin>
    </Modal>
  );
};

export default GoodsBatchModal;
