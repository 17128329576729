import { Button, Empty, Modal, Space, Tooltip } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GalleryAlbum, GalleryMedia } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import PhotoGalleryGrid from '../../photoGallery/PhotoGalleryGrid';
import GalleryPagination from '../../photoGallery/GalleryPagination';
import GalleryHeader from '../../photoGallery/GalleryHeader';
import { isVideo } from '../../../utils/checkFileType';
import UploadImage from '../../photoGallery/UploadImage';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { LONGEST_TIMEOUT } from '../../../constants/systemConstants';
import {
  IMAGE_TYPE,
  MEDIA_TYPE,
  VIDEO_TYPE,
} from '../../../constants/mediaConstants';

type PhotoModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  album?: GalleryAlbum;
  refresh?: () => void;
};

const PhotoModal = ({
  album,
  visible,
  setVisible,
  refresh,
}: PhotoModalProps) => {
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState<GalleryMedia[]>([]);
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [mediaType, setMediaType] = useState<string>(MEDIA_TYPE.BOTH);
  const [selectedPhotos, setSelectedPhotos] = useState<GalleryMedia[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadList, setUploadList] = useState<any[]>([]);
  const [totalProgress, setTotalProgress] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      isSubscribed.current &&
      selectedPhotos.length &&
      photos.length &&
      selectedPhotos.length === photos.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedPhotos, photos]);

  const getData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('gallery/pic/list', {
      params: {
        albumId: album?.albumId,
        page: page,
        size: 28,
        mediaType: mediaType === MEDIA_TYPE.BOTH ? undefined : mediaType,
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setPhotos(response.data.list);
            setTotalPhotos(response.data.totalItem);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) {
          setSelectedPhotos([]);
          setSelectAll(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (isSubscribed.current) {
          setSelectedPhotos([]);
          setSelectAll(false);
          setIsLoading(false);
        }
        console.log(err);
      });
  }, [t, album, page, mediaType, keyword]);

  useEffect(() => {
    if (visible && album) getData();
  }, [getData, visible, album]);

  const onClose = () => {
    if (isSubscribed.current) {
      if (showUpload) {
        if (!uploading) {
          setUploadList([]);
          setTotalProgress(0);
          setUploading(false);
          setShowUpload(false);
        } else {
          alertMessage('warning', 'Please stay while uploading');
        }
      } else {
        setPhotos([]);
        setSelectAll(false);
        setMediaType(MEDIA_TYPE.BOTH);
        setKeyword('');
        setPage(1);
        setVisible(false);
      }
    }
  };

  const handleUpload = async () => {
    if (album) {
      if (isSubscribed.current) setUploading(true);
      for (let i = 0; i < uploadList.length; i++) {
        const data = new FormData();
        data.append('albumId', album.albumId.toString());
        if (isVideo(uploadList[i].name)) {
          data.append('videos', uploadList[i].originFileObj);
        } else {
          data.append('images', uploadList[i].originFileObj);
        }

        try {
          const response = await postDataWithAuthToken(
            'gallery/pic/upload',
            data,
            {
              onUploadProgress: (progressEvent: any) => {
                const progress = Math.floor(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                if (isSubscribed.current)
                  setUploadList((prev: any) => {
                    let list = [...prev];
                    list[i].percent = progress;
                    list[i].status = 'uploading';
                    return list;
                  });
              },
            }
          );

          if (response && response.goodStatus) {
            if (isSubscribed.current)
              setUploadList((prev: any) => {
                let list = [...prev];
                list[i].percent = 100;
                list[i].status = 'done';
                return list;
              });
            setTotalProgress(Math.floor(((i + 1) / uploadList.length) * 100));
          } else {
            setUploadList((prev: any) => {
              let list = [...prev];
              list[i].status = 'error';
              return list;
            });
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
            break;
          }
        } catch (err) {
          console.log(err);
          break;
        }
      }

      setTimeout(() => {
        onClose();
        getData();
        if (refresh) refresh();
      }, LONGEST_TIMEOUT);
    }
  };

  return (
    <Modal
      title={
        showUpload ? (
          <>
            {`${album?.albumName} > ${
              t('settings.photoGalleryActions.uploadMedia') + ' '
            }`}
            <Tooltip title={IMAGE_TYPE + ',' + VIDEO_TYPE}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        ) : (
          `${album?.albumName} > ${t('settings.photoGalleryActions.viewMedia')}`
        )
      }
      visible={visible}
      onCancel={onClose}
      width={850}
      footer={
        showUpload ? (
          <Space>
            <Button onClick={onClose}>
              {t('settings.photoGalleryActions.back')}
            </Button>
            <Button type="primary" onClick={handleUpload} loading={uploading}>
              {t('settings.photoGalleryActions.ok')}
            </Button>
          </Space>
        ) : null
      }
      bodyStyle={{ padding: showUpload ? undefined : 8 }}
      maskClosable={uploading ? false : true}
    >
      {!showUpload ? (
        <>
          <GalleryHeader
            album={album}
            mediaType={mediaType}
            setMediaType={setMediaType}
            setPage={setPage}
            callBack={(keyword: string) => {
              setKeyword(keyword);
              setPage(1);
            }}
            refresh={() => getData()}
            setShowUpload={setShowUpload}
            totalPhotos={totalPhotos}
          />
          {!!photos.length ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <PhotoGalleryGrid
                photos={photos}
                photosLoading={isLoading}
                value={selectedPhotos}
                setValue={setSelectedPhotos}
                isMultiple
              />
              <GalleryPagination
                totalPhotos={totalPhotos}
                pagePhotos={page}
                setPagePhotos={setPage}
                selectedPhotos={selectedPhotos}
                setSelectedPhotos={setSelectedPhotos}
                photos={photos}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                callBack={() => {
                  if (album) getData();
                }}
                refreshTable={() => {
                  if (refresh) refresh();
                }}
              />
            </div>
          ) : (
            <Empty />
          )}
        </>
      ) : (
        <UploadImage
          uploadList={uploadList}
          setUploadList={setUploadList}
          uploading={uploading}
          totalProgress={totalProgress}
        />
      )}
    </Modal>
  );
};

export default PhotoModal;
