import { ResponsivePie } from '@nivo/pie';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { addCommas } from '../../../utils/helperFunction';

import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GoodsAnalysisDataPoint, NivoPieChartData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { nivoToolTip } from '../../../utils/nivoGraphTools';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import SellersDropdown from '../../sellers/SellersDropdown';

type GoodsOverviewProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const GoodsOverview = ({
  isSeller,
  isLoading,
  setIsLoading,
}: GoodsOverviewProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const isSubscribed = useRef(true);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Data Components
  const [goodsAnalysisData, setGoodsAnalysisData] =
    useState<GoodsAnalysisDataPoint>();
  const [pieGraphData, setPieGraphData] = useState<NivoPieChartData[]>([]);

  /**
   * Grabs all the needed data for Goods Analysis
   */
  const getData = useCallback(() => {
    const getPieGraphData = (data: GoodsAnalysisDataPoint) => {
      let pieGraph: NivoPieChartData[] = [];

      data.goodsTagStat.forEach((obj) => {
        pieGraph.push({
          id: obj.name,
          value: parseFloat(obj.itemPercentage),
          label: obj.itemNum,
        });
      });

      return pieGraph;
    };
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('analysis/goods', {
      params: {
        isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
        sellerId:
          getDashboardStyle().isSellerSwitch &&
          isSeller &&
          formRef.current &&
          form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodsAnalysisData(response.data);
            setPieGraphData(getPieGraphData(response.data));
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [t, setFourZeroThree, setFiveHundred, isSeller, form, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form style={{ paddingBottom: 15 }} ref={formRef} form={form}>
            <Row gutter={[16, 0]}>
              {isSeller && (
                <Col span={24} md={24} lg={24} xl={8}>
                  <Form.Item
                    name="sellerId"
                    label={t('analysis.goodsSales.seller')}
                    style={{ marginBottom: 10 }}
                  >
                    <SellersDropdown />
                  </Form.Item>
                </Col>
              )}

              {isSeller && (
                <Col span={24}>
                  <Space align="start">
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        if (typingTimeout) clearTimeout(typingTimeout);
                        setTypingTimeout(
                          setTimeout(() => {
                            getData();
                          }, GENERAL_TIMEOUT)
                        );
                      }}
                    >
                      {t('analysis.goodsDistribution.ok')}
                    </Button>
                    <Button
                      onClick={() => {
                        form.resetFields();
                        if (typingTimeout) clearTimeout(typingTimeout);
                        setTypingTimeout(
                          setTimeout(() => {
                            form.resetFields();
                            form.setFieldsValue({
                              date: [
                                moment().startOf('isoWeek'),
                                moment().endOf('isoWeek'),
                              ],
                            });
                            getData();
                          }, GENERAL_TIMEOUT)
                        );
                      }}
                    >
                      {t('analysis.goodsDistribution.reset')}
                    </Button>
                  </Space>
                  <Tooltip title={t('general.refresh')}>
                    <Button
                      type="text"
                      onClick={() => {
                        if (typingTimeout) clearTimeout(typingTimeout);
                        setTypingTimeout(
                          setTimeout(() => {
                            getData();
                          }, GENERAL_TIMEOUT)
                        );
                      }}
                      icon={<ReloadOutlined />}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Form>
          {goodsAnalysisData && (
            <Row>
              {Object.keys(goodsAnalysisData).map((label: any, index) => {
                return (
                  label !== 'goodsTagStat' && (
                    <Col key={index} span={24} sm={12} md={8} lg={6}>
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {t(`analysis.goodsOverview.${label}`)}
                      </div>
                      <Typography.Title
                        level={3}
                        style={{
                          margin: 0,
                          textAlign: 'center',
                        }}
                      >
                        {`${addCommas(
                          goodsAnalysisData[
                            label as keyof typeof goodsAnalysisData
                          ]
                        )}${
                          [
                            'onSaleGoodsPercentage',
                            'presaleGoodsPercentage',
                            'promoteGoodsPercentage',
                            'purchaseLimitGoodsPercentage',
                          ].includes(label)
                            ? '%'
                            : ''
                        }`}
                      </Typography.Title>
                    </Col>
                  )
                );
              })}
              {pieGraphData.length > 0 && (
                <Col
                  span={24}
                  style={{
                    height: 300,
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    {t(`analysis.goodsOverview.goodsTagStat`)}
                  </div>
                  <ResponsivePie
                    colors={{ scheme: 'paired' }}
                    tooltip={(datum) =>
                      nivoToolTip({
                        ...datum,
                        tooltipType: 'Standard',
                      })
                    }
                    data={pieGraphData}
                    arcLinkLabelsStraightLength={10}
                    arcLinkLabelsDiagonalLength={10}
                    arcLabel={(d) => (d.value !== 0 ? `${d.value}%` : '')}
                    arcLinkLabel={(d) => (d.value !== 0 ? `${d.id}` : '')}
                    margin={{
                      top: 40,
                      left: 40,
                      right: 40,
                      bottom: 40,
                    }}
                    arcLinkLabelsSkipAngle={pieGraphData.length < 3 ? 0 : 5}
                    arcLabelsSkipAngle={pieGraphData.length < 3 ? 0 : 20}
                    theme={{
                      fontSize: 12,
                      axis: {
                        legend: { text: { fontSize: DEFAULT_FONT_SIZE } },
                      },
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
        </Spin>
      )}
    </>
  );
};

export default GoodsOverview;
