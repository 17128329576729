import { PlusOutlined } from '@ant-design/icons';
import { Card, Grid, Typography, Table, Button, Space, Popconfirm } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import DepartmentModal from '../../components/goods/DepartmentModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { DepartmentData, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

const DepartmentList = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [departments, setDepartments] = useState<DepartmentData[]>([]);
  const [editDepartment, setEditDepartment] = useState<DepartmentData>();
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const columnKeys = [
    'departmentId',
    'departmentName',
    'remark',
    'addTime',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<DepartmentData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columns: ColumnsType<DepartmentData> = [
    {
      title: setFont(t('goods.departmentListColumns.departmentId'), fontSize),
      key: 'departmentId',
      dataIndex: 'departmentId',
      fixed: screens.lg ? 'left' : undefined,
      width: 140,
      render: (text: string, record: DepartmentData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            !hasPermission(actionPermissions.goodGroup.departmentManage)
          }
          onClick={() => {
            setEditDepartment(record);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: DepartmentData, b: DepartmentData) =>
        compare(a.departmentId, b.departmentId),
    },
    {
      title: setFont(t('goods.departmentListColumns.departmentName'), fontSize),
      key: 'departmentName',
      dataIndex: 'departmentName',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: DepartmentData, b: DepartmentData) =>
        compare(a.departmentName, b.departmentName),
    },
    {
      title: setFont(t('goods.departmentListColumns.remark'), fontSize),
      key: 'remark',
      dataIndex: 'remark',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.departmentListColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'action',
      width: 120,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: DepartmentData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.goodGroup.departmentManage)
            }
            onClick={() => {
              setEditDepartment(record);
              setShowModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeleteDepartment(record.departmentId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.goodGroup.departmentManage)
            }
          >
            <Button
              danger
              type="link"
              disabled={
                !hasPermission(actionPermissions.goodGroup.departmentManage)
              }
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Delete department by ID
  const handleDeleteDepartment = async (departmentId: number) => {
    try {
      const response = await postDataWithAuthToken('goods/department/delete', {
        departmentId: departmentId,
      });
      if (response && response.goodStatus) {
        getDepartmentData();
        alertMessage('success', t('goods.alerts.departmentDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  const getDepartmentData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/department/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setDepartments(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, page, pageSize, keyword]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getDepartmentData();
  }, [getDepartmentData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.departmentList')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditDepartment(undefined);
                  setShowModal(true);
                }}
                disabled={
                  !hasPermission(actionPermissions.goodGroup.departmentManage)
                }
              >
                {t('goods.add/editDepartment.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getDepartmentData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchDepartments')}
            rows={departments}
            exportConfig={{ fileName: 'DEPARTMENT_LIST' }}
          />
          <Table<DepartmentData>
            dataSource={departments}
            rowKey={(department) => department.departmentId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={isLoading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: DepartmentData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'DEPARTMENT_LIST' } }}
            />
          )}
        </Card>
      )}
      <DepartmentModal
        visible={showModal}
        setVisible={setShowModal}
        department={editDepartment}
        refresh={() => getDepartmentData()}
      />
    </Container>
  );
};

export default DepartmentList;
