import {
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Spin,
  Switch,
  Upload,
  Image,
  Button,
  Tooltip,
  Space,
  Checkbox,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueCardType } from '../../types';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import {
  REVIEW_STATUS_FAILED,
  REVIEW_STATUS,
  REVIEW_STATUS_NO_NEED_REVIEW,
} from '../../constants/generalConstants';
import getDashboardStyle from '../../utils/getDashboardStyle';
import {
  CloseCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { IMAGE_TYPE } from '../../constants/mediaConstants';
import { RED1 } from '../../constants/color';
import SellersDropdown from '../sellers/SellersDropdown';

type ValueCardTypeModalProps = {
  visible: boolean;
  isSeller: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedValueCardType?: ValueCardType;
  refresh: Function;
};

const ValueCardTypeModal = ({
  visible,
  setVisible,
  isSeller,
  selectedValueCardType,
  refresh,
}: ValueCardTypeModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [reviewStatus, setReviewStatus] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [vcPic, setVCPic] = useState<any[]>([]);
  const [hasVCImageAltered, setHasVCImageAltered] = useState<boolean>(false);
  const [noLimit, setNoLimit] = useState<boolean>(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (selectedValueCardType && selectedValueCardType.vcImgPath) {
        setVCPic([
          {
            largePicPath: selectedValueCardType.vcImgPath,
          },
        ]);
      }
      if (selectedValueCardType && selectedValueCardType.vcExpireMonth === 0) {
        setNoLimit(true);
      }
    }
  }, [form, visible, selectedValueCardType]);

  const onClose = () => {
    if (isSubscribed.current) {
      setReviewStatus(undefined);
      setVCPic([]);
      setVisible(false);
      setNoLimit(false);
    }
  };

  const onFinish = () => {
    const data = new FormData();
    if (noLimit) {
      data.append('vcExpireMonth', '0');
    }
    if (!isSeller) {
      data.append('sellerId', '0');
    }
    form
      .validateFields()
      .then((values: any) => {
        selectedValueCardType &&
          data.append('vctId', selectedValueCardType.vctId.toString());
        Object.keys(values).forEach((label) => {
          if (label === 'vcImg' && hasVCImageAltered) {
            if (vcPic && vcPic.length === 1 && vcPic[0].originFileObj) {
              data.append('vcImg', vcPic[0].originFileObj);
              data.append('removeVcImg', 'false');
            } else {
              data.append('removeVcImg', 'true');
            }
          } else if (values[label as keyof typeof values] !== undefined) {
            data.append(label, values[label as keyof typeof values]);
          }
        });

        if (isSubscribed.current) setLoading(true);

        postDataWithAuthToken(
          selectedValueCardType
            ? 'value_card/type/edit'
            : 'value_card/type/add',
          data
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedValueCardType
                ? t('activity.alerts.valueCardTypeEdited')
                : t('activity.alerts.valueCardTypeAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedValueCardType
          ? `${t('activity.add/EditValueCardType.editTitle')} ${
              selectedValueCardType.vcName
            }`
          : `${t('activity.add/EditValueCardType.addVCTTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={
            selectedValueCardType
              ? {
                  ...selectedValueCardType,
                  isRecharge: selectedValueCardType.isRecharge,
                  reviewStatus: getDashboardStyle().showReviewStatus
                    ? selectedValueCardType.reviewStatus
                    : undefined,
                }
              : {
                  isRecharge: true,
                  reviewStatus: getDashboardStyle().showReviewStatus
                    ? REVIEW_STATUS_NO_NEED_REVIEW
                    : undefined,
                }
          }
          onFinish={onFinish}
        >
          {isSeller && !selectedValueCardType && (
            <Form.Item
              label={t('activity.add/EditValueCardType.seller')}
              name="sellerId"
              rules={[
                {
                  required: isSeller,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <SellersDropdown disabled={!isSeller} />
            </Form.Item>
          )}

          <Form.Item /**For VC Name */
            name="vcName"
            label={
              <Space>
                {t('activity.add/EditValueCardType.vcName')}
                <Tooltip
                  title={t('activity.add/EditValueCardType.vcNameTooltip')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            }
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input style={{ width: 200, marginRight: 10 }} />
          </Form.Item>
          <Form.Item /**For VC Desc */
            name="vcDesc"
            label={t('activity.add/EditValueCardType.vcDesc')}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
          <Form.Item /**For VC Prefix */
            name="vcPrefix"
            label={t('activity.add/EditValueCardType.vcPrefix')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
          <Form.Item /**For VC Image */
            name="vcImg"
            label={
              <Space>
                {t('activity.add/EditValueCardType.image')}
                <Tooltip
                  title={t('activity.add/EditValueCardType.vcImageTooltip')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            }
          >
            {vcPic && vcPic.length === 1 && vcPic[0].largePicPath ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Image height={100} src={vcPic[0].largePicPath} />
                <Tooltip
                  title={t('actionsColumn.delete')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    style={{ border: 0 }}
                    size="large"
                    icon={<CloseCircleOutlined style={{ color: RED1 }} />}
                    onClick={() => {
                      setHasVCImageAltered(true);
                      setVCPic([]);
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <Upload
                maxCount={1}
                listType="picture-card"
                accept={`${IMAGE_TYPE}`}
                showUploadList={{
                  showPreviewIcon: false,
                }}
                fileList={vcPic}
                onChange={({
                  file,
                  fileList,
                }: {
                  file: any;
                  fileList: any[];
                }) => {
                  setVCPic(fileList);
                  setHasVCImageAltered(true);
                }}
                onRemove={(file) => setVCPic([])}
                beforeUpload={() => {
                  return false;
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {t('settings.photoGalleryActions.selectFile')}
                  </div>
                </div>
              </Upload>
            )}
          </Form.Item>
          <Space align="start" wrap>
            <Form.Item
              name={noLimit ? undefined : 'vcExpireMonth'}
              label={t('activity.add/EditValueCardType.vcExpireMonth')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              {!noLimit && (
                <InputNumber min={0} precision={0} style={{ width: 200 }} />
              )}
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Checkbox
                checked={noLimit}
                onChange={(e) => {
                  setNoLimit(e.target.checked);
                }}
              >
                {t('activity.add/EditValueCardType.endTimeHelp')}
              </Checkbox>
            </Form.Item>
          </Space>
          <Form.Item /**For VC Value */
            name="vcValue"
            label={t('activity.add/EditValueCardType.vcValue')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <InputNumber<string>
              step="0.01"
              precision={2}
              style={{
                width: 200,
              }}
            />
          </Form.Item>
          <Form.Item /**For isRecharge */
            name="isRecharge"
            label={t('activity.add/EditValueCardType.isRecharge')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {getDashboardStyle().showReviewStatus && (
            <Form.Item /**For Review Status */
              label={t('activity.add/EditValueCardType.reviewStatus')}
              name="reviewStatus"
            >
              <Radio.Group
                onChange={(e) => {
                  form.resetFields(['reviewContent']);
                  setReviewStatus(e.target.value);
                }}
              >
                {Object.keys(REVIEW_STATUS).map((type) => {
                  return (
                    <Radio key={type} value={type}>
                      {t(REVIEW_STATUS[type])}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          )}
          {getDashboardStyle().showReviewStatus &&
            reviewStatus === REVIEW_STATUS_FAILED /**For Review Content */ && (
              <Form.Item
                name="reviewContent"
                label={t('activity.add/EditValueCardType.reviewContent')}
                rules={[
                  {
                    required: reviewStatus === REVIEW_STATUS_FAILED,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ValueCardTypeModal;
