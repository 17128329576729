import { Button, Col, DatePicker, Row, Typography } from 'antd';
import {
  DATE_WORDS_SHORTCUT_FORMAT,
  DEFAULT_FONT_SIZE,
  LARGE_FONT_SIZE,
} from '../../../constants/systemConstants';
import { CommonPrintoutHeaderType } from '../../../types';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import moment from 'moment';

type CommonPrintoutHeaderProps = {
  header: CommonPrintoutHeaderType;
  title: string;
  datePicker?: {
    startDate?: string;
    endDate?: string;
    setDate: Dispatch<SetStateAction<string | undefined>>;
  };
};

const CommonPrintoutHeader = ({
  header,
  title,
  datePicker,
}: CommonPrintoutHeaderProps) => {
  const dateRef = useRef<any>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  // Date Picker
  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.focus();
    }
    if (datePicker && datePicker.startDate && datePicker.endDate) {
      datePicker.startDate &&
        setStartDate(
          moment(datePicker.startDate).format(DATE_WORDS_SHORTCUT_FORMAT)
        );
      datePicker.endDate &&
        setEndDate(
          moment(datePicker.endDate).format(DATE_WORDS_SHORTCUT_FORMAT)
        );
      datePicker.setDate(
        `${moment(datePicker.startDate).format(
          DATE_WORDS_SHORTCUT_FORMAT
        )} - ${moment(datePicker.endDate).format(DATE_WORDS_SHORTCUT_FORMAT)}`
      );
    }
  }, [datePicker]);

  // Date Picker Component
  const datePickerComponent = () => {
    if (datePicker) {
      if (startDate === undefined && endDate === undefined) {
        return (
          <DatePicker.RangePicker
            ref={dateRef}
            style={{ width: 190, fontSize: DEFAULT_FONT_SIZE }}
            onChange={(e) => {
              if (e?.length === 2) {
                setStartDate(moment(e[0]).format(DATE_WORDS_SHORTCUT_FORMAT));
                setEndDate(moment(e[1]).format(DATE_WORDS_SHORTCUT_FORMAT));
                datePicker.setDate(
                  `${moment(e[0]).format(
                    DATE_WORDS_SHORTCUT_FORMAT
                  )} - ${moment(e[1]).format(DATE_WORDS_SHORTCUT_FORMAT)}`
                );
              }
            }}
            format={DATE_WORDS_SHORTCUT_FORMAT}
          />
        );
      } else {
        return (
          <Button
            onClick={() => {
              setStartDate(undefined);
              setEndDate(undefined);
              datePicker.setDate(undefined);
            }}
            style={{
              border: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              fontSize: DEFAULT_FONT_SIZE,
              padding: 0,
            }}
          >
            {`${startDate} - ${endDate}`}
          </Button>
        );
      }
    }
  };

  return (
    <div>
      <Row /**Logo, Business Address*/>
        <Col /** Logo */ span={5}>
          <img src={header.logo} alt="logo" width={140} />
        </Col>

        <Col /**Business Address */
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography.Text
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              fontWeight: 'bold',
            }}
          >
            {header.businessName}
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: DEFAULT_FONT_SIZE,
            }}
          >
            {header.businessAddress}
          </Typography.Text>
          <Typography.Text style={{ fontSize: DEFAULT_FONT_SIZE }}>
            {header.businessPhone}
          </Typography.Text>
        </Col>
        <Col /** Title and Number */
          span={7}
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          <Typography.Text
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: LARGE_FONT_SIZE,
              wordBreak: 'break-word',
            }}
          >
            {title}
          </Typography.Text>
          {header.serialNumber && (
            <Typography.Text
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: DEFAULT_FONT_SIZE,
              }}
            >
              {header.serialNumber}
            </Typography.Text>
          )}

          {header.addTime && (
            <Typography.Text
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                fontSize: DEFAULT_FONT_SIZE,
              }}
            >
              {header.addTime}
            </Typography.Text>
          )}
          {datePicker && datePickerComponent()}
        </Col>
      </Row>
    </div>
  );
};

export default CommonPrintoutHeader;
