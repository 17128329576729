import {
  Col,
  Form,
  Modal,
  Row,
  AutoComplete,
  Input,
  Radio,
  RadioChangeEvent,
  Spin,
  Switch,
  Grid,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css';
import { AddressData, BasicEnumInfoType, Viewport } from '../../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import {
  EXTENDED_TIMEOUT,
  SHORTEST_TIMEOUT,
} from '../../constants/systemConstants';
import { MAP_MARKER_ICON, MAP_MARKER_SIZE } from '../../constants/styles';

type UserAddressModalProp = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editAddress?: AddressData;
  refresh?: Function;
  userId?: string;
};

/**
 * Model to add and edit User Addresses
 *
 * @param visible     Should modal be shown
 * @param setVisible  Change visibility of modal
 * @param editAddress Address to be edited
 * @param refresh     Function to be called after adding/editing address
 * @param userId      Which user to attach the address to (For UserDetail page)
 */
const UserAddressModal = ({
  visible,
  setVisible,
  editAddress,
  refresh,
  userId,
}: UserAddressModalProp) => {
  const { t } = useTranslation();
  const [viewport, setViewport] = useState<Viewport>();
  const [addressTypeEnum, setAddressTypeEnum] = useState<BasicEnumInfoType[]>(
    []
  );
  const [addresses, setAddresses] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [loading, setLoading] = useState(false);
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const screens = Grid.useBreakpoint();

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getEnumList = useCallback(() => {
    getDataWithAuthToken('users/user_address/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setAddressTypeEnum(response.data.addressType);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getEnumList();
  }, [getEnumList]);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      setSelectedAddress(editAddress);
      setTimeout(() => {
        setViewport({
          longitude: Number(editAddress?.longitude) || 0,
          latitude: Number(editAddress?.latitude) || 0,
          zoom: 15,
        });
      }, SHORTEST_TIMEOUT);
    }
  }, [editAddress, visible, form]);

  /**
   * @param value The address that was selected by user
   */
  const onSelectAddress = (value: any) => {
    let addressObj: {
      context: any[];
      center: number[];
      address: string;
      text: string;
    } = JSON.parse(value);
    let postcode = '';
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let region = '';
    let country = '';
    if (addressObj.address) streetNumber = addressObj.address;
    if (addressObj.text) streetName = addressObj.text;
    if (addressObj.context && addressObj.context.length) {
      for (let e of addressObj.context) {
        if (e.id.includes('postcode')) postcode = e.text;
        else if (e.id.includes('place')) city = e.text;
        else if (e.id.includes('region')) region = e.short_code.slice(-2);
        else if (e.id.includes('country')) country = e.short_code.toUpperCase();
      }
    }
    if (visible && isSubscribed.current) {
      setSelectedAddress((prev: AddressData) => ({
        ...prev,
        postalCode: postcode,
        longitude: addressObj.center[0],
        latitude: addressObj.center[1],
      }));
      setViewport({
        longitude: addressObj.center[0],
        latitude: addressObj.center[1],
        zoom: 15,
      });
      form.setFieldsValue({
        address: `${streetNumber} ${streetName}, ${city}, ${region}, ${country}`,
        postalCode: postcode,
        longitude: addressObj.center[0],
        latitude: addressObj.center[1],
      });
    }
  };

  /**
   * @param value search value for address
   */
  const onSearchAddress = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    if (value && value.length > 5) {
      setTypingTimeout(
        setTimeout(() => {
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=ca&language=en&types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
            )
            .then((result) => {
              if (result && result.status === 200 && result.data) {
                if (isSubscribed.current) setAddresses(result.data.features);
              } else alertMessage('error', t('users.alerts.errorOccurred'));
            })
            .catch((err) => {
              console.log(err);
            });
        }, EXTENDED_TIMEOUT)
      );
    } else setAddresses([]);
  };

  const onReset = () => {
    if (isSubscribed.current) setSelectedAddress(undefined);
    form.resetFields();
  };

  /**
   * @param e Which address type user has picked (House, Townhouse, Apartment)
   */
  const onSelectAddressType = (e: RadioChangeEvent) => {
    if (isSubscribed.current) {
      setSelectedAddress((prev: AddressData) => ({
        ...prev,
        address_type: e.target.value,
      }));
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setLoading(true);
        // Editing previous address
        if (editAddress) {
          postDataWithAuthToken('users/user_address/edit', {
            ...values,
            addressId: editAddress.addressId,
            addressType: values.addressType,
          }).then((result) => {
            if (result && result.goodStatus) {
              if (isSubscribed.current) {
                setLoading(false);
                setVisible(false);
                if (refresh) refresh();
              }
              alertMessage('success', t('users.alerts.addressEdited'));
            } else {
              if (isSubscribed.current) setLoading(false);
              alertMessage(
                'error',
                result?.msg || t('general.noResponse'),
                result?.data || undefined
              );
            }
          });
          // Adding new address
        } else {
          postDataWithAuthToken('users/user_address/add', {
            ...values,
            buzzerCode: values.buzzerCode ? values.buzzerCode : '',
            unitNumber: values.unitNumber ? values.unitNumber : '',
            tel: values.tel ? values.tel : '',
            userId: userId ? parseInt(userId) : undefined,
            addressType: values.addressType,
          }).then((response) => {
            if (response && response.goodStatus) {
              if (isSubscribed.current) {
                setLoading(false);
                setVisible(false);
                if (refresh) refresh();
              }
              alertMessage('success', t('users.alerts.addressAdded'));
            } else {
              if (isSubscribed.current) setLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      visible={visible}
      title={t('users.add/editAddress.editTitle')}
      onOk={onFinish}
      onCancel={() => setVisible(false)}
      afterClose={onReset}
      width={screens.xl ? '50vw' : undefined}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
    >
      <Spin spinning={loading}>
        <Row>
          <Col xs={{ span: 24 }} xxl={{ span: 10, offset: 1 }}>
            <Form
              layout="vertical"
              form={form}
              initialValues={
                editAddress
                  ? {
                      ...editAddress,
                      addressType: editAddress.addressType.code,
                    }
                  : { addressType: 'HOUSE' }
              }
            >
              {editAddress && (
                <Form.Item label={t('users.add/editAddress.region')}>
                  {editAddress.deliveryRegion}
                </Form.Item>
              )}
              <Form.Item
                name="consignee"
                label={t('users.add/editAddress.consignee')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="mobile"
                label={t('users.add/editAddress.mobile')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address"
                label={t('users.add/editAddress.address')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <AutoComplete
                  allowClear
                  onChange={onSearchAddress}
                  onSelect={(value: string, option: any) =>
                    onSelectAddress(option.key)
                  }
                >
                  {addresses.map((address: { place_name: string }) => (
                    <AutoComplete.Option
                      key={JSON.stringify(address)}
                      value={address.place_name}
                    >
                      {address.place_name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <Form.Item
                name="postalCode"
                label={t('users.add/editAddress.postalCode')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="latitude"
                label={t('users.add/editAddress.latitude')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="longitude"
                label={t('users.add/editAddress.longitude')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="addressType"
                label={t('users.add/editAddress.addressType')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <Radio.Group onChange={onSelectAddressType}>
                  {addressTypeEnum.map((addressType) => (
                    <Radio value={addressType.code} key={addressType.code}>
                      {addressType.description}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="unitNumber"
                label={t('users.add/editAddress.unit')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="buzzerCode"
                label={t('users.add/editAddress.buzzer')}
              >
                <Input />
              </Form.Item>
              <Form.Item name="tel" label={t('users.add/editAddress.tel')}>
                <Input />
              </Form.Item>
              <Form.Item
                name="isDefaultAddress"
                valuePropName="checked"
                label={t('users.add/editAddress.defaultAddress')}
              >
                <Switch />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={{ span: 24 }} xxl={{ span: 10, offset: 2 }}>
            <ReactMapGL
              {...viewport}
              width="100%"
              height={400}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              onViewportChange={setViewport}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            >
              {!!(
                selectedAddress &&
                selectedAddress.latitude &&
                selectedAddress.longitude
              ) ? (
                <Marker
                  latitude={Number(selectedAddress.latitude)}
                  longitude={Number(selectedAddress.longitude)}
                >
                  <svg
                    height={MAP_MARKER_SIZE}
                    viewBox="0 0 24 24"
                    style={{
                      // cursor: 'pointer',
                      fill: '#d00',
                      stroke: 'none',
                      transform: `translate(${
                        -MAP_MARKER_SIZE / 2
                      }px,${-MAP_MARKER_SIZE}px)`,
                    }}
                  >
                    <path d={MAP_MARKER_ICON} />
                  </svg>
                </Marker>
              ) : (
                <></>
              )}
            </ReactMapGL>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default UserAddressModal;
