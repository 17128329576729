import { Button, Space, Spin, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HIGHLIGHT_ORANGE, PRIMARY } from '../../../constants/color';
import {
  GoodsPendingStockData,
  GoodsPendingStockSumData,
} from '../../../types';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';

type GoodPendingStockProps = {
  visible: boolean;
  goodsId: number;
};

const GoodPendingStock = ({ visible, goodsId }: GoodPendingStockProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { addTab } = useTab();
  const [stockData, setStockData] = useState<GoodsPendingStockData[]>([]);
  const [sumDetail, setSumDetail] = useState<
    { sum: number } & GoodsPendingStockSumData
  >({ sum: 0, unsortedSum: 0, finishedSum: 0, inProgressSum: 0, unshipSum: 0 });
  const [loading, setLoading] = useState<boolean>(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getPendingStockData = useCallback(async () => {
    try {
      if (isSubscribed.current) setLoading(true);
      const response = await getDataWithAuthToken('goods/pending_stock', {
        params: { goodsId: goodsId },
      });
      if (response && response.goodStatus) {
        if (isSubscribed.current) {
          setStockData(response.data.list);
          setSumDetail({ sum: response.data.totalItem, ...response.data.data });
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (isSubscribed.current) setLoading(false);
      console.log(error);
    }
  }, [goodsId]);

  useEffect(() => {
    setStockData([]);
    setSumDetail({
      sum: 0,
      finishedSum: 0,
      inProgressSum: 0,
      unsortedSum: 0,
      unshipSum: 0,
    });
    if (visible) {
      getPendingStockData();
    }
  }, [visible, getPendingStockData]);

  const sharedOnCell = (data: GoodsPendingStockData) => {
    if (data.store !== null) {
      return { colSpan: 0 };
    }

    return {};
  };

  const columns: ColumnsType<GoodsPendingStockData> = [
    {
      title: t('goods.goodsListColumns.goodsName'),
      dataIndex: 'goodsName',
      render: (value, record) =>
        record.store !== null ? (
          <Typography.Text strong>{record.store}</Typography.Text>
        ) : (
          <Typography.Text>{record.goodsName}</Typography.Text>
        ),
      onCell: (data) => ({ colSpan: data.store !== null ? 7 : 1 }),
    },
    {
      title: t('goods.goodsListColumns.goodsUnit'),
      dataIndex: 'goodsUnit',
      colSpan: 1,
      onCell: sharedOnCell,
    },
    {
      title: t('goods.goodsListColumns.goodsAttrName'),
      dataIndex: 'goodsAttr',
      colSpan: 1,
      onCell: sharedOnCell,
    },
    {
      title: t('goods.pendingStock.unshipStock'),
      dataIndex: 'unshipStock',
      colSpan: 1,
      onCell: sharedOnCell,
    },
    {
      title: t('goods.pendingStock.prepareStock'),
      dataIndex: 'prepareStock',
      colSpan: 1,
      onCell: sharedOnCell,
    },
    {
      title: t('goods.pendingStock.partialShipStock'),
      dataIndex: 'partialShipStock',
      colSpan: 1,
      onCell: sharedOnCell,
    },
    {
      title: t('goods.pendingStock.sum'),
      dataIndex: 'sum',
      colSpan: 1,
      onCell: sharedOnCell,
      render: (text: string, record: GoodsPendingStockData) => {
        return (
          <Space direction="vertical">
            <Typography.Text strong>
              {`${t('goods.pendingStock.unshipSum')}: ${
                record.sumDetail.unshipSum
              }`}
            </Typography.Text>
            <Typography.Text strong>
              {`${t('goods.pendingStock.unsortedSum')}: ${
                record.sumDetail.unsortedSum
              }`}
            </Typography.Text>
            <Typography.Text>
              {`${t('goods.pendingStock.inProgressSum')}: ${
                record.sumDetail.inProgressSum
              }`}
            </Typography.Text>
            <Typography.Text>
              {`${t('goods.pendingStock.finishedSum')}: ${
                record.sumDetail.finishedSum
              }`}
            </Typography.Text>

            <Button
              type="link"
              disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
              onClick={() => {
                // 跳转指定订单
                addTab(
                  '',
                  `${
                    dashboardRoute.order.list
                  }?order_ids=${record.orderIdsList.join(',')}&is_seller=${
                    record.isSeller
                  }`
                );
              }}
              style={{ padding: 0 }}
            >
              {`${t('goods.pendingStock.sum')}: ${record.sum}`}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      {stockData && sumDetail.sum > 0 ? (
        <>
          <Space size="middle" style={{ marginBottom: 10 }}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('goods.pendingStock.title')}
            </Typography.Text>
          </Space>
          <Table
            columns={columns}
            dataSource={stockData}
            bordered
            scroll={{ y: 200 }}
            pagination={false}
            size="small"
            rowKey={(record) => `${record.recId} - ${record.store}`}
            footer={() => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Space>
                  <Typography.Text strong style={{ color: PRIMARY }}>
                    {t('goods.pendingStock.unshipSum')}
                  </Typography.Text>
                  <Typography.Text strong style={{ color: HIGHLIGHT_ORANGE }}>
                    {sumDetail.unshipSum}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong style={{ color: PRIMARY }}>
                    {t('goods.pendingStock.unsortedSum')}
                  </Typography.Text>
                  <Typography.Text strong style={{ color: HIGHLIGHT_ORANGE }}>
                    {sumDetail.unsortedSum}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('goods.pendingStock.inProgressSum')}
                  </Typography.Text>
                  <Typography.Text strong style={{ color: HIGHLIGHT_ORANGE }}>
                    {sumDetail.inProgressSum}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('goods.pendingStock.finishedSum')}
                  </Typography.Text>
                  <Typography.Text strong style={{ color: HIGHLIGHT_ORANGE }}>
                    {sumDetail.finishedSum}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('goods.pendingStock.totalStock')}
                  </Typography.Text>
                  <Typography.Text strong style={{ color: HIGHLIGHT_ORANGE }}>
                    {sumDetail.sum}
                  </Typography.Text>
                </Space>
              </div>
            )}
          />
        </>
      ) : (
        <></>
      )}
    </Spin>
  );
};

export default GoodPendingStock;
