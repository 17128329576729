import { Empty, Grid, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderData } from '../../../types';

type OrderProgressProps = {
  orderInfo?: OrderData;
};

const OrderProgress = ({ orderInfo }: OrderProgressProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const currentStep = () => {
    if (orderInfo) {
      if (orderInfo.confirmDeliveredTime) {
        return 4;
      } else if (orderInfo.shippingTime) {
        return 3;
      } else if (orderInfo.payTime) {
        return 2;
      } else if (orderInfo.confirmTime) {
        return 1;
      } else if (orderInfo.addTime) {
        return 0;
      } else {
        return undefined;
      }
    }
    return undefined;
  };

  return orderInfo ? (
    <Steps
      direction={screens.md ? undefined : 'vertical'}
      labelPlacement="vertical"
      size="small"
      current={currentStep()}
    >
      <Steps.Step
        title={t('order.orderDetail.steps.createOrder')}
        description={orderInfo.addTime}
      />
      <Steps.Step
        title={t('order.orderDetail.steps.confirmOrder')}
        description={orderInfo.confirmTime}
      />
      <Steps.Step
        title={t('order.orderDetail.steps.payOrder')}
        description={orderInfo.payTime}
      />
      <Steps.Step
        title={t('order.orderDetail.steps.shipping')}
        description={orderInfo.shippingTime}
      />
      <Steps.Step
        title={t('order.orderDetail.steps.confirmDelivered')}
        description={orderInfo.confirmDeliveredTime}
      />
    </Steps>
  ) : (
    <Empty />
  );
};

export default OrderProgress;
