import { Progress, Typography, Upload } from 'antd';
import { DEFAULT_FONT_SIZE } from '../../constants/systemConstants';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  IMAGE_TYPE,
  MAX_UPLOAD_SIZE,
  VIDEO_TYPE,
} from '../../constants/mediaConstants';

type UploadImageProps = {
  uploading: boolean;
  uploadList: any[];
  setUploadList: React.Dispatch<React.SetStateAction<any[]>>;
  totalProgress?: number;
};

const UploadImage = ({
  uploading,
  uploadList,
  setUploadList,
  totalProgress,
}: UploadImageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography style={{ fontSize: DEFAULT_FONT_SIZE }}>
        {`${t(
          'settings.photoGalleryActions.maxFileSize'
        )}: ${MAX_UPLOAD_SIZE}MB`}
      </Typography>
      <Upload
        listType="picture-card"
        accept={`${IMAGE_TYPE},${VIDEO_TYPE}`}
        multiple
        showUploadList={{
          showPreviewIcon: false,
          showRemoveIcon: uploading ? false : true,
        }}
        fileList={uploadList}
        onChange={({ file, fileList }: { file: any; fileList: any[] }) => {
          let index = uploadList.findIndex((e) => e.name === file.name);
          if (index !== -1) return;
          setUploadList(fileList);
        }}
        onRemove={(file) =>
          !uploading &&
          setUploadList((prev) => {
            return prev.filter((e) => file.uid !== e.uid);
          })
        }
        beforeUpload={() => {
          return false;
        }}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>
            {t('settings.photoGalleryActions.selectFile')}
          </div>
        </div>
      </Upload>
      {uploading && totalProgress !== undefined && (
        <Progress percent={totalProgress} />
      )}
    </>
  );
};

export default UploadImage;
