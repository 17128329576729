import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { TaskData, TaskEnum } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type TaskBasicInfoProps = {
  taskInfo?: TaskData;
  callback?: () => void;
  enums?: TaskEnum;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TaskBasicInfo = ({
  taskInfo,
  callback,
  enums,
  isCurrentEditing,
  setIsCurrentEditing,
}: TaskBasicInfoProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleOnCancel = () => {
    form.resetFields();
    setIsEditing(false);
    setIsCurrentEditing && setIsCurrentEditing(false);
  };

  const handleOnEdit = () => {
    if (isEditing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('task/edit', {
            ...values,
            taskId: taskInfo ? taskInfo.taskId : undefined,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('sortationSystem.alerts.taskEdited'));
                handleOnCancel();
                callback && callback();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => setIsLoading(false));
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setIsEditing(true);
        setIsCurrentEditing && setIsCurrentEditing(true);
      }
    }
  };

  return taskInfo ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...taskInfo,
          taskType: taskInfo.taskType ? taskInfo.taskType.code : undefined,
          taskStage: taskInfo.taskStage ? taskInfo.taskStage.code : undefined,
          taskStatus: taskInfo.taskStatus
            ? taskInfo.taskStatus.code
            : undefined,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('sortationSystem.taskDetail.basicInfo')}
            </Typography.Text>
            {isEditing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.sortationGroup.taskManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskId')}:`}
            >
              <Typography.Text>{taskInfo.taskId}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskStage')}:`}
              name="taskStage"
              rules={[{ required: isEditing }]}
            >
              {isEditing ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {enums &&
                    enums.taskStage &&
                    enums.taskStage.length > 0 &&
                    enums.taskStage.map((item) => (
                      <Select.Option key={item.code} value={item.code}>
                        {item.description}
                      </Select.Option>
                    ))}
                </Select>
              ) : (
                taskInfo.taskStage && (
                  <Tag color={taskInfo.taskStage.tagColor}>
                    {taskInfo.taskStage.description}
                  </Tag>
                )
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskType')}:`}
              name="taskType"
              rules={[{ required: isEditing }]}
            >
              {isEditing ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {enums &&
                    enums.taskType &&
                    enums.taskType.length > 0 &&
                    enums.taskType.map((item) => (
                      <Select.Option key={item.code} value={item.code}>
                        {item.description}
                      </Select.Option>
                    ))}
                </Select>
              ) : (
                <Typography.Text>
                  {taskInfo.taskType && taskInfo.taskType.description}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskStatus')}:`}
              name="taskStatus"
              rules={[{ required: isEditing }]}
            >
              {isEditing ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {enums &&
                    enums.taskStatus &&
                    enums.taskStatus.length > 0 &&
                    enums.taskStatus.map((item) => (
                      <Select.Option key={item.code} value={item.code}>
                        {item.description}
                      </Select.Option>
                    ))}
                </Select>
              ) : (
                <Tag color={taskInfo.taskStatus.tagColor}>
                  {taskInfo.taskStatus && taskInfo.taskStatus.description}
                </Tag>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              name="taskLabel"
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskLabel')}:`}
            >
              {isEditing ? (
                <Input />
              ) : (
                <Typography.Text>{taskInfo.taskLabel}</Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              name="cartNumber"
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.cartNumber')}:`}
            >
              {isEditing ? (
                <Input />
              ) : (
                <Typography.Text>{taskInfo.cartNumber}</Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskGoodsNum')}:`}
            >
              <Typography.Text>{`${taskInfo.taskGoodsNum} (${t(
                'sortationSystem.taskDetail.finished'
              )}: ${taskInfo.completedTaskGoodsNum})`}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.taskDate')}:`}
            >
              <Typography.Text>{taskInfo.taskTime}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.startTime')}:`}
            >
              <Typography.Text>{taskInfo.startTime}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.finishTime')}:`}
            >
              <Typography.Text>{taskInfo.finishTime}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={4}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={`${t('sortationSystem.taskListColumns.addTime')}:`}
            >
              <Typography.Text>{taskInfo.addTime}</Typography.Text>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('sortationSystem.taskDetail.basicInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskBasicInfo;
