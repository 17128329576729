import {
  Button,
  DatePicker,
  Form,
  Grid,
  InputNumber,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import FourZeroThree from '../../FourZeroThree';
import FiveHundred from '../../FiveHundred';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DATE_TIME_FORMAT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { ReloadOutlined } from '@ant-design/icons';
import TableToolbar from '../../table/TableToolbar';
import { UserBalanceAnalysisData } from '../../../types';
import { ColumnsType } from 'antd/lib/table';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { setFont } from '../../../utils/colComponents';
import { useTab } from '../../../hooks/useTab';
import { dashboardRoute } from '../../../constants/pathname';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { mergeParamsToString } from '../../../utils/helperFunction';

const UserBalance = () => {
  const { t } = useTranslation();
  const { addTab } = useTab();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const screens = Grid.useBreakpoint();
  const [tabMode, setTabMode] = useState<string>('tab1');
  const [data, setData] = useState<UserBalanceAnalysisData[]>([]);
  const [lastLogData, setLastLogData] = useState<UserBalanceAnalysisData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<UserBalanceAnalysisData[]>(
    []
  );
  const columnKeys = [
    'userId|',
    'userName|',
    'nickName|',
    'mobilePhone|',
    'balanceAmount',
    'changeAmount',
  ];
  const columns: ColumnsType<UserBalanceAnalysisData> = [
    {
      title: setFont(
        t('analysis.userAccountBalance.userId'),
        undefined,
        t('analysis.userAccountBalance.userId')
      ),
      key: 'userId|',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (record: UserBalanceAnalysisData) =>
        record &&
        record.user && (
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              addTab(
                '',
                `${dashboardRoute.users.detail}?user_id=${record.user.userId}`
              );
            }}
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
          >
            {record.user.userId}
          </Button>
        ),
    },
    {
      title: setFont(
        t('analysis.userAccountBalance.userName'),
        undefined,
        t('analysis.userAccountBalance.userName')
      ),
      key: 'userName|',
      width: 100,
      render: (record: UserBalanceAnalysisData) =>
        record &&
        record.user && (
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              addTab(
                '',
                `${dashboardRoute.users.detail}?user_id=${record.user.userId}`
              );
            }}
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
          >
            {record.user.userName}
          </Button>
        ),
    },
    {
      title: setFont(
        t('analysis.userAccountBalance.nickname'),
        undefined,
        t('analysis.userAccountBalance.nickname')
      ),
      key: 'nickName|',
      width: 100,
      render: (record: UserBalanceAnalysisData) =>
        record.user && record.user.nickName,
    },
    {
      title: setFont(
        t('analysis.userAccountBalance.mobilePhone'),
        undefined,
        t('analysis.userAccountBalance.mobilePhone')
      ),
      key: 'mobilePhone|',
      width: 100,
      render: (record: UserBalanceAnalysisData) =>
        record.user && record.user.mobilePhone,
    },
    {
      title: setFont(
        t('analysis.userAccountBalance.balanceAmount'),
        undefined,
        t('analysis.userAccountBalance.balanceAmount')
      ),
      key: 'balanceAmount',
      dataIndex: 'balanceAmount',
      width: 100,
      render: (text: string) => text,
    },
    {
      title: setFont(
        t('analysis.userAccountBalance.changeAmount'),
        undefined,
        t('analysis.userAccountBalance.changeAmount')
      ),
      key: 'changeAmount',
      dataIndex: 'changeAmount',
      width: 100,
      render: (text: string) => text,
    },
  ];

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    const url =
      tabMode === 'tab1'
        ? 'analysis/user/export/balance?'
        : 'analysis/user/export/last_log?';

    return mergeParamsToString(url, params);
  };

  const getFormParams = useCallback(() => {
    return {
      page: page,
      size: pageSize,
      startTime:
        formRef.current && form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[0]).format(DATE_TIME_FORMAT)
          : undefined,
      endTime:
        formRef.current && form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[1]).format(DATE_TIME_FORMAT)
          : undefined,
      minAmount:
        formRef.current && form.getFieldValue('minAmount')
          ? form.getFieldValue('minAmount')
          : undefined,
    };
  }, [form, page, pageSize]);

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken(
      tabMode === 'tab1'
        ? 'analysis/user/account/balance'
        : 'analysis/user/account/last_log',
      {
        params: getFormParams(),
      }
    )
      .then((response) => {
        if (response && response.goodStatus) {
          setSelectedRowKeys([]);
          tabMode === 'tab1'
            ? setData(response.data.list)
            : setLastLogData(response.data.list);
          setTotal(response.data.totalItem);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [t, tabMode, getFormParams]);

  useEffect(() => {
    getData();
  }, [getData]);

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <Spin spinning={isLoading} style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }} /**Range picker, OK, Reset, Refresh Buttons */
      >
        <Form form={form} ref={formRef}>
          <Form.Item
            name="date"
            label={t('analysis.userAccountBalance.date')}
            initialValue={[moment().startOf('month'), moment().endOf('month')]}
            style={{ marginBottom: 12, paddingRight: 10 }}
          >
            <DatePicker.RangePicker
              format={DATE_TIME_FORMAT}
              showTime
              placeholder={[
                t('analysis.goodsDistribution.startDate'),
                t('analysis.goodsDistribution.endDate'),
              ]}
            />
          </Form.Item>
          {tabMode === 'tab1' && (
            <Form.Item
              name="minAmount"
              label={t('analysis.userAccountBalance.minAmount')}
              initialValue={100}
              style={{ marginBottom: 12, paddingRight: 10 }}
            >
              <InputNumber />
            </Form.Item>
          )}
        </Form>
        <Space align="start">
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  getData();
                }, GENERAL_TIMEOUT)
              );
            }}
          >
            {t('analysis.goodsDistribution.ok')}
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  form.setFieldsValue({
                    date: [moment().startOf('month'), moment().endOf('month')],
                  });
                  getData();
                }, GENERAL_TIMEOUT)
              );
            }}
          >
            {t('analysis.goodsDistribution.reset')}
          </Button>
        </Space>
        <Tooltip title={t('general.refresh')}>
          <Button
            type="text"
            onClick={() => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  getData();
                }, GENERAL_TIMEOUT)
              );
            }}
            icon={<ReloadOutlined />}
          />
        </Tooltip>
      </div>
      <Tabs
        defaultActiveKey="tab1"
        onTabClick={(key) => {
          setTabMode(key);
        }}
      >
        {['tab1', 'tab2'].map((tab) => (
          <Tabs.TabPane
            key={tab}
            tab={t(`analysis.userAccountBalance.${tab}Name`)}
          >
            <Typography.Title level={4} style={{ marginBottom: 12 }}>
              {t(`analysis.userAccountBalance.${tab}Title`)}
            </Typography.Title>
            <TableToolbar
              columns={
                tab === 'tab1'
                  ? columns.filter((col) => col.key !== 'changeAmount')
                  : columns
              }
              columnKeys={
                tab === 'tab1'
                  ? columnKeys.filter((key) => key !== 'changeAmount')
                  : columnKeys
              }
              rows={(tab === 'tab1' ? data : lastLogData).map((row) => ({
                ...row,
                userId: row.user ? row.user.userId : undefined,
                userName: row.user ? row.user.userName : undefined,
                nickName: row.user ? row.user.nickName : undefined,
                mobilePhone: row.user ? row.user.mobilePhone : undefined,
              }))}
              exportConfig={{
                fileName:
                  tab === 'tab1'
                    ? 'USER_BALANCE_ANALYSIS'
                    : 'USER_LAST_LOG_BALANCE_ANALYSIS',
                url: getExportUrl(getFormParams()),
              }}
            />
            <Table<UserBalanceAnalysisData>
              dataSource={tab === 'tab1' ? data : lastLogData}
              rowKey={(obj) => obj.user.userId}
              columns={
                tab === 'tab1'
                  ? columns.filter((col) => col.key !== 'changeAmount')
                  : columns
              }
              scroll={{
                y: 500,
                x: 500,
              }}
              loading={isLoading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                },
                current: page,
              }}
              rowSelection={{
                selectedRowKeys,
                onChange: (
                  selectedRowKeys: React.Key[],
                  selectedRows: UserBalanceAnalysisData[]
                ) => {
                  setSelectedRowKeys(selectedRowKeys);
                  setSelectedRows(selectedRows);
                },
              }}
              size="small"
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((row) => ({
            ...row,
            userId: row.user ? row.user.userId : undefined,
            userName: row.user ? row.user.userName : undefined,
            nickName: row.user ? row.user.nickName : undefined,
            mobilePhone: row.user ? row.user.mobilePhone : undefined,
          }))}
          funct={{
            refresh: () => getData(),
            exportConfig: hasPermission(actionPermissions.userGroup.userView)
              ? {
                  fileName:
                    tabMode === 'tab1'
                      ? 'USER_BALANCE_ANALYSIS'
                      : 'USER_LAST_LOG_BALANCE_ANALYSIS',
                }
              : undefined,
          }}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={
            tabMode === 'tab1'
              ? columns.filter((col) => col.key !== 'changeAmount')
              : columns
          }
        />
      )}
    </Spin>
  );
};

export default UserBalance;
