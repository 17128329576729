import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Spin,
  Select,
  Switch,
  Checkbox,
} from 'antd';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { BasicEnumInfoType, UserRankData } from '../../types';
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../utils/alertMessage';

type UserRankModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // If Rank Profile Exists then we are editing an already existing profile.
  rankProfile?: UserRankData;
  callBack?: Function;
  specialComments: BasicEnumInfoType[];
};

const UserRankModal = ({
  visible,
  setVisible,
  rankProfile,
  callBack,
  specialComments,
}: UserRankModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [specialCommentDict, setSpecialCommentDict] = useState<{
    [key: string]: string;
  }>({});
  const isSubscribe = useRef(true);
  const [isSpecialRank, setIsSpecialRank] = useState(false);
  const [noLimit, setNoLimit] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      isSubscribe.current = false;
    };
  }, []);

  // Need to reset the form so that the default values are correct in Edit
  useEffect(() => {
    if (visible) {
      form.resetFields();
      setNoLimit(
        rankProfile && rankProfile.maxDiscountAmount === 0 ? true : false
      );
      if (isSubscribe.current)
        setIsSpecialRank(rankProfile ? rankProfile.specialRank : false);
    }
  }, [visible, form, rankProfile]);

  // Finds which key matches with Special comment if editing an existing Rank
  useEffect(() => {
    let newSpecialCommentDict: { [key: string]: string } = {};
    specialComments.forEach((key) => {
      newSpecialCommentDict[key.description] = key.code;
    });
    setSpecialCommentDict(newSpecialCommentDict);
  }, [specialComments]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        try {
          if (isSubscribe.current) setLoading(true);
          postDataWithAuthToken(
            rankProfile ? 'users/user_rank/edit' : 'users/user_rank/add',
            {
              rankId: rankProfile ? rankProfile.rankId : undefined,
              rankName: values.rankName,
              minPoints: isSpecialRank ? undefined : values.minPoints,
              maxPoints: isSpecialRank ? undefined : values.maxPoints,
              discount: values.discount,
              maxDiscountAmount: noLimit ? 0 : values.maxDiscountAmount,
              specialRank: values.specialRank ? true : false,
              specialComment: values.specialCommentInfo,
            }
          ).then((response) => {
            if (response && response.goodStatus) {
              if (isSubscribe.current) setVisible(false);
              alertMessage(
                'success',
                rankProfile
                  ? t('users.alerts.rankEdited')
                  : t('users.alerts.rankAdded')
              );
              if (callBack) {
                callBack();
              }
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
          if (isSubscribe.current) setLoading(false);
        } catch (err) {
          console.log(err);
          if (isSubscribe.current) setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    if (isSubscribe.current) setVisible(false);
  };

  return (
    <Modal
      title={
        rankProfile
          ? `${t('users.add/editRank.editTitle')} ${rankProfile.rankName}`
          : t('users.add/editRank.addTitle')
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('users.add/editRank.ok')}
      cancelText={t('users.add/editRank.cancel')}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={
            rankProfile
              ? {
                  ...rankProfile,
                  specialCommentInfo:
                    specialCommentDict[rankProfile.specialCommentInfo],
                }
              : {}
          }
        >
          <Form.Item
            name="rankName"
            label={t('users.add/editRank.rankName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseInputAmount'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="discount"
            label={t('users.add/editRank.discount')}
            rules={[
              {
                required: rankProfile ? false : true,
                message: t('general.inputError.pleaseInputAmount'),
              },
            ]}
            hasFeedback
          >
            <InputNumber type="number" min={0} max={100} addonAfter="%" />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {!noLimit && (
              <Form.Item
                label={t('users.add/editRank.maxDiscountAmount')}
                name={noLimit ? undefined : 'maxDiscountAmount'}
                rules={[
                  {
                    required: !noLimit,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                {
                  <InputNumber
                    value={
                      rankProfile && !noLimit
                        ? rankProfile.maxDiscountAmount
                        : undefined
                    }
                    type="number"
                    min={0}
                    max={50000}
                  />
                }
              </Form.Item>
            )}
            <div style={{ width: 10 }} />
            <Form.Item
              label={noLimit ? t('users.add/editRank.maxDiscountAmount') : ' '}
            >
              <Checkbox
                checked={noLimit}
                onChange={(e) => {
                  setNoLimit(e.target.checked);
                }}
              >
                {t('users.add/editRank.noLimit')}
              </Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            name="specialRank"
            label={t('users.add/editRank.specialRank')}
            valuePropName="checked"
          >
            <Switch onChange={(checked) => setIsSpecialRank(checked)} />
          </Form.Item>
          {!isSpecialRank && (
            <Form.Item
              label={t('users.add/editRank.pointRange')}
              style={{ marginBottom: 0 }}
              required
            >
              <Form.Item
                name="minPoints"
                rules={[
                  {
                    required: isSpecialRank ? false : true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
                style={{ display: 'inline-block' }}
              >
                <InputNumber
                  type="number"
                  placeholder={t('users.add/editRank.min')}
                />
              </Form.Item>
              <Form.Item
                name="maxPoints"
                rules={[
                  {
                    required: isSpecialRank ? false : true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
                style={{
                  display: 'inline-block',
                  margin: '0 8px',
                }}
              >
                <InputNumber
                  type="number"
                  placeholder={t('users.add/editRank.max')}
                />
              </Form.Item>
            </Form.Item>
          )}
          <Form.Item
            name="specialCommentInfo"
            label={t('users.add/editRank.specialComment')}
          >
            <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
              {specialComments.map((key) => (
                <Select.Option key={key.code} value={key.code}>
                  {key.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UserRankModal;
