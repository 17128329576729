import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Input,
  Menu,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import SalespersonDropdown from '../../components/salesperson/SalespersonDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import UserModal from '../../components/users/UserModal';
import UserRankDropdown from '../../components/users/common/UserRankDropdown';
import { actionPermissions } from '../../constants/actionPermissions';
import { dashboardRoute } from '../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import {
  BasicEnumInfoType,
  FontSizeType,
  UserListData,
  UserRankData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import {
  mergeParamsToString,
  tableScrollToTop,
} from '../../utils/helperFunction';

/**
 * Display User List
 * Can add, edit, or delete User Rank
 *
 * /dashboard/users/list
 *
 * @prop route Route information from react-router-config
 */
const UserList = () => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const [navType, setNavType] = useState('ACTIVE_USER');
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  //Data Components
  const [selectedUser, setSelectedUser] = useState<UserListData | undefined>();
  const [totalUsers, setTotalUsers] = useState();
  const [userData, setUserData] = useState<Array<UserListData>>([]);
  const [userNavs, setUserNavs] = useState<BasicEnumInfoType[]>([]);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  //Table Components
  const [selectedRows, setSelectedRows] = useState<UserListData[]>([]);
  const { addTab } = useTab();
  const formRef = useRef(null);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys = [
    'id',
    'username',
    'nickname',
    'email',
    'mobilePhone',
    'regDate',
    'userMoney',
    'payPoints',
    'rankName',
    'member',
    'remark',
    'actions',
    'remark',
    'frozenMoney',
    'rankPoints',
    'birthday',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((col: string) => {
      return (
        col !== 'birthday' && col !== 'rankPoints' && col !== 'frozenMoney'
      );
    })
  );
  //Text Components
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('users/export/excel?', params);
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getFormParams = useCallback(() => {
    return {
      userNav: navType || undefined,
      page: page,
      size: pageSize,
      keyword: keyword || undefined,
      keywordName:
        (formRef.current && form.getFieldValue('keywordName')) || undefined,
      keywordUserName:
        (formRef.current && form.getFieldValue('keywordUserName')) || undefined,
      keywordMobile:
        (formRef.current && form.getFieldValue('keywordMobile')) || undefined,
      email: (formRef.current && form.getFieldValue('email')) || undefined,
      rankId:
        formRef.current &&
        form.getFieldValue('rankId') &&
        form.getFieldValue('rankId').length > 0
          ? form.getFieldValue('rankId').join()
          : undefined,
      memberId:
        formRef.current &&
        form.getFieldValue('memberId') &&
        form.getFieldValue('memberId').length > 0
          ? form.getFieldValue('memberId').join()
          : undefined,
      salesPersonId:
        (formRef.current && form.getFieldValue('salesPersonId')) || undefined,
    };
  }, [page, pageSize, keyword, form, navType]);
  /**
   * Fetches Users
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken('users/list', {
      params: getFormParams(),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setUserData(response.data.list);
            setTotalUsers(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, getFormParams]);

  const getUserEnum = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`users/enum_list`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setUserNavs(response.data.userNav);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getUserEnum();
  }, [getUserEnum]);

  /**
   * @param userId Id of user to delete
   */
  const deleteUser = (userId: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('users/delete', { userId: userId })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('users.alerts.userDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const archiveUser = (userId: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('users/trash', {
      userId: userId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('users.alerts.userArchived'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const restoreUser = (userId: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('users/restore', {
      userId: userId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('users.alerts.userRestored'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  /**
   * @param selectedRowKeys Array of keys that were selected
   */
  const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    if (isSubscribed.current) {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
  };

  const columns: ColumnsType<UserListData> = [
    {
      title: setFont(t('users.userListColumns.id'), fontSize),
      dataIndex: 'userId',
      key: 'id',
      fixed: screens.lg ? 'left' : false,
      width: 100,
      sorter: (a: UserListData, b: UserListData) => compare(a.userId, b.userId),
      render: (text: string, record: UserListData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            record.userId <= 0 ||
            !hasPermission(actionPermissions.userGroup.userView)
          }
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.users.detail}?user_id=${record.userId}`
            )
          }
        >
          {record.userId}
        </Button>
      ),
    },
    {
      title: setFont(t('users.userListColumns.username'), fontSize),
      dataIndex: 'userName',
      key: 'username',
      width: 160,
      render: (text: string, record: UserListData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            record.userId <= 0 ||
            !hasPermission(actionPermissions.userGroup.userView)
          }
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.users.detail}?user_id=${record.userId}`
            )
          }
        >
          {record.userName}
        </Button>
      ),
    },
    {
      title: setFont(t('users.userListColumns.nickname'), fontSize),
      dataIndex: 'nickName',
      key: 'nickname',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userListColumns.email'), fontSize),
      dataIndex: 'email',
      key: 'email',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userListColumns.mobilePhone'), fontSize),
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userListColumns.birthday'), fontSize),
      dataIndex: 'birthday',
      key: 'birthday',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserListData, b: UserListData) =>
        compare(a.birthday, b.birthday),
    },
    {
      title: setFont(t('users.userListColumns.userMoney'), fontSize),
      dataIndex: 'userMoney',
      key: 'userMoney',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserListData, b: UserListData) =>
        compare(a.userMoney, b.userMoney),
    },
    {
      title: setFont(t('users.userListColumns.frozenMoney'), fontSize),
      dataIndex: 'frozenMoney',
      key: 'frozenMoney',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserListData, b: UserListData) =>
        compare(a.frozenMoney, b.frozenMoney),
    },
    {
      title: setFont(t('users.userListColumns.payPoints'), fontSize),
      dataIndex: 'payPoints',
      key: 'payPoints',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserListData, b: UserListData) =>
        compare(a.payPoints, b.payPoints),
    },
    {
      title: setFont(t('users.userListColumns.rankPoints'), fontSize),
      dataIndex: 'rankPoints',
      key: 'rankPoints',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserListData, b: UserListData) =>
        compare(a.rankPoints, b.rankPoints),
    },
    {
      title: setFont(t('users.userListColumns.rankName'), fontSize),
      dataIndex: 'userRank',
      key: 'rankName',
      width: 120,
      render: (text: UserRankData) => text && setFont(text.rankName, fontSize),
    },
    {
      title: setFont(t('users.userListColumns.userMember'), fontSize),
      dataIndex: 'member',
      key: 'member',
      width: 120,
      render: (text: UserRankData) => text && setFont(text.rankName, fontSize),
    },
    {
      title: setFont(t('users.userListColumns.remark'), fontSize),
      dataIndex: 'remark',
      key: 'remark',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userListColumns.registerDate'), fontSize),
      dataIndex: 'regDate',
      key: 'regDate',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserListData, b: UserListData) =>
        compare(a.regDate, b.regDate),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : false,
      width: 120,
      render: (text: string, record: UserListData) => (
        <Space size="small">
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              record.userId <= 0 ||
              !hasPermission(actionPermissions.userGroup.userView)
            }
            onClick={() =>
              addTab(
                '',
                `${dashboardRoute.users.detail}?user_id=${record.userId}`
              )
            }
          >
            {t('actionsColumn.view')}
          </Button>
          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.userId])
                setMorePopoverVis({ [record.userId]: flag });
            }}
            visible={morePopoverVis[record.userId]}
            overlay={
              <Menu
                onClick={(e) => {
                  setMorePopoverVis({ [record.userId]: false });
                }}
              >
                {hasPermission(actionPermissions.userGroup.userDrop) &&
                  record.isDelete && (
                    <Menu.Item key="restore" style={{ fontSize: fontSize }}>
                      <Popconfirm
                        title={t('actionsColumn.restoreWarning')}
                        onConfirm={() => {
                          restoreUser(record.userId);
                        }}
                        okText={t('actionsColumn.confirmation.yes')}
                        cancelText={t('actionsColumn.confirmation.no')}
                        placement="leftBottom"
                      >
                        <Typography.Text>
                          {t('actionsColumn.restore')}
                        </Typography.Text>
                      </Popconfirm>
                    </Menu.Item>
                  )}
                {hasPermission(actionPermissions.userGroup.userDrop) && (
                  <Menu.Item key="deleteArchive" style={{ fontSize: fontSize }}>
                    <Popconfirm
                      title={
                        record.isDelete
                          ? t('actionsColumn.deleteWarning')
                          : t('actionsColumn.archiveWarning')
                      }
                      onConfirm={() => {
                        record.isDelete
                          ? deleteUser(record.userId)
                          : archiveUser(record.userId);
                      }}
                      okText={t('actionsColumn.confirmation.yes')}
                      cancelText={t('actionsColumn.confirmation.no')}
                      placement="leftBottom"
                    >
                      <Typography.Text type="danger">
                        {record.isDelete
                          ? t('goods.actionsColumn.delete')
                          : t('goods.actionsColumn.archive')}
                      </Typography.Text>
                    </Popconfirm>
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.userId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Advance search pop up component
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('users.userListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item
            name="keywordMobile"
            label={t('users.userListColumns.advancedSearch.mobile')}
          >
            <Input
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  keywordMobile: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            name="keywordUserName"
            label={`${t('users.userListColumns.advancedSearch.username')}`}
          >
            <Input
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  keywordUserName: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            name="keywordName"
            label={`${t('users.userListColumns.advancedSearch.username')}/${t(
              'users.userListColumns.advancedSearch.nickname'
            )}`}
          >
            <Input
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  keywordName: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            name="rankId"
            label={t('users.userListColumns.advancedSearch.userRank')}
          >
            <UserRankDropdown
              isMultiple={true}
              onChange={(values) => {
                setAdvance((prev) => ({
                  ...prev,
                  rankId: values && values.length ? values : undefined,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            name="memberId"
            label={t('users.userListColumns.advancedSearch.userMember')}
          >
            <UserRankDropdown
              isMember={true}
              isMultiple={true}
              onChange={(values) => {
                setAdvance((prev) => ({
                  ...prev,
                  memberId: values && values.length ? values : undefined,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('users.userListColumns.advancedSearch.email')}
          >
            <Input
              onChange={(e) => {
                setAdvance((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
            />
          </Form.Item>

          <Form.Item name="salesPersonId" label={t('salesperson.title')}>
            <SalespersonDropdown
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  salesPersonId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('users.userListColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('users.userListColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('users.userList')}
          </Typography.Title>

          <Tabs
            onTabClick={(key) => {
              if (key !== navType) {
                setSelectedRowKeys([]);
                setSelectedRows([]);
                setNavType(key);
                setPage(1);
              }
            }}
          >
            {userNavs.length > 0 &&
              userNavs
                .filter((nav) => {
                  if (
                    nav.code !== 'ACTIVE_USER' &&
                    getDashboardStyle().isSalesForceAppType
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((type) => (
                  <Tabs.TabPane key={type.code} tab={type.description} />
                ))}
          </Tabs>
          <TableToolbar
            fontSize={fontSize}
            setFontSize={setFontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedUser(undefined);
                  setUserModalVisible(true);
                }}
                disabled={
                  !hasPermission(actionPermissions.userGroup.userManage)
                }
              >
                {t('users.addUser.title')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getData()}
            totalItems={totalUsers}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchUserKeyword')}
            advancedSearch={advancedSearch}
            rows={userData.map((user) => ({
              ...user,
              userRank: user.userRank ? user.userRank.rankName : '',
              member: user.member ? user.member.rankName : '',
            }))}
            exportConfig={
              hasPermission(actionPermissions.userGroup.userExport)
                ? {
                    fileName: 'USER_LIST',
                    url: getExportUrl(getFormParams()),
                  }
                : undefined
            }
          />
          <Table<UserListData>
            dataSource={userData}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={loading}
            pagination={{
              total: totalUsers,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowSelection={rowSelection}
            rowKey={(user) => user.userId}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{
                exportConfig: hasPermission(
                  actionPermissions.userGroup.userExport
                )
                  ? { fileName: 'USER_LIST' }
                  : undefined,
              }}
              selectedRows={selectedRows.map((user) => ({
                ...user,
                userRank: user.userRank ? user.userRank.rankName : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}

      <UserModal
        visible={userModalVisible}
        setVisible={setUserModalVisible}
        userProfile={selectedUser}
        callBack={() => getData()}
      />
    </Container>
  );
};

export default UserList;
