import axios from 'axios';

const BASE_URL = process.env.REACT_APP_LUNIU_API_API
  ? process.env.REACT_APP_LUNIU_API_API
  : 'https://notification.luniumall.com/api/v1/';

/**
 * Handles all HTTP requests for app
 *
 * If requests requires authentication createAuthProvider will handle the tokens
 */
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Adding headers before each request
axiosInstance.interceptors.request.use(
  function (config) {
    // If language is set to English add Accept-Language header
    // We don't need to do this for Chinese since that is the default
    const language = localStorage.getItem('@storage_language');
    if (language === 'en') {
      config.headers = {
        ...config.headers,
        'Accept-Language': 'en_us',
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * @param endPoint URL path
 * @param options  Custom options for axios request
 * @returns        validateAxiosResponse
 */
export const postData = async (
  endPoint: string,
  body: object,
  options?: {}
) => {
  try {
    const response = await axiosInstance.post(endPoint, body, options);
    if (response.status === 200) {
      return validateAxiosResponse(response.data);
    } else console.error(response);
  } catch (err) {
    console.log('postData' + err + ' at endpoint: ' + endPoint);
  }
};

/**
 * @param response return_code  number   The return code number
 *                 data         JSON     The data received from the response
 *                 msg          string   The message received from the response
 *
 * @returns        goodStatus   boolean  If the return code is 200
 *                 return_code  number   The return code number
 *                 data         JSON     The data received from the response
 *                 msg          string   The message received from the response
 */
function validateAxiosResponse(response: {
  return_code?: number;
  data: any;
  msg?: string;
}) {
  if (response['return_code']) {
    switch (response['return_code']) {
      case 200:
        return {
          goodStatus: true,
          return_code: response.return_code,
          data: response.data,
          msg: response.msg,
        };
      default:
        return {
          goodStatus: false,
          return_code: response.return_code,
          data: response.data,
          msg: response.msg,
        };
    }
  } else {
    return {
      goodStatus: false,
      return_code: response.return_code,
      data: response.data,
    };
  }
}
