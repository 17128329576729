import {
  Button,
  Grid,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { FontSizeType, SellerAdminData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import TableToolbar from '../../table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../../constants/systemConstants';
import { PlusOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { hasPermission } from '../../../utils/hasPermission';
import SellerAdminModal from '../SellerAdminModal';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../../../constants/actionPermissions';

type UserAdminListTableProps = {
  id: string;
};

const UserAdminListTable = ({ id }: UserAdminListTableProps) => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [sellerAdmins, setSellerAdmins] = useState<SellerAdminData[]>([]);
  const [editSellerAdmins, setEditSellerAdmins] = useState<
    SellerAdminData | undefined
  >();
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const isSubscribed = useRef(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [selectedRows, setSelectedRows] = useState<SellerAdminData[]>([]);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getSellerAdminData = useCallback(() => {
    if (isSubscribed.current) setTableLoading(true);
    getDataWithAuthToken('seller/admin_user/list', {
      params: {
        page: page,
        size: pageSize,
        sellerId: id,
        keyword: keyword,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (response.data.list.length === 0) {
              notification['warning']({
                duration: 25,
                message: 'WARNING:',
                description: t('sellers.alerts.missingUserAdmin'),
              });
            }
            setSellerAdmins(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        console.log(err);
      });
  }, [id, t, keyword, page, pageSize]);

  useEffect(() => {
    getSellerAdminData();
  }, [getSellerAdminData]);

  const columns: ColumnsType<SellerAdminData> = [
    {
      title: setFont(t('sellers.adminListColumns.id'), fontSize),
      key: 'adminId',
      dataIndex: 'adminId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.adminId, b.adminId),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.sellerName'), fontSize),
      key: 'sellerName',
      dataIndex: 'sellerName',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.sellerName, b.sellerName),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.adminName'), fontSize),
      key: 'adminUserName',
      dataIndex: 'adminUserName',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.adminUserName, b.adminUserName),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.parentAdminInfo'), fontSize),
      key: 'adminUserName',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.adminUserName, b.adminUserName),
      render: (sellerAdmin: SellerAdminData) =>
        setFont(
          sellerAdmin.parentAdmin &&
            sellerAdmin.parentAdmin.adminUserName &&
            sellerAdmin.parentAdmin.adminId
            ? sellerAdmin.parentAdmin.adminUserName +
                '(ID: ' +
                sellerAdmin.parentAdmin.adminId +
                ')'
            : '',
          fontSize
        ),
    },
    {
      title: setFont(t('sellers.adminListColumns.email'), fontSize),
      key: 'email',
      dataIndex: 'email',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.addTime, b.addTime),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.lastLogin'), fontSize),
      key: 'lastLogin',
      dataIndex: 'lastLogin',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.lastLogin, b.lastLogin),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: SellerAdminData) => (
        <Space>
          <Button
            disabled={
              !hasPermission(actionPermissions.generalGroup.usersMerchantsPriv)
            }
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditSellerAdmins(record);
              setShowModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => handleDeleteSellerAdmin(record.adminId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.generalGroup.usersMerchantsPriv)
            }
          >
            <Button
              disabled={
                !hasPermission(
                  actionPermissions.generalGroup.usersMerchantsPriv
                )
              }
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Delete seller admin by ID
  const handleDeleteSellerAdmin = async (adminId: number) => {
    try {
      const response = await postDataWithAuthToken('seller/admin_user/delete', {
        adminId: adminId,
      });
      if (response && response.goodStatus) {
        getSellerAdminData();
        alertMessage('success', t('sellers.alerts.adminDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Typography.Title level={4} style={{ marginBottom: 12 }}>
        {t('sellers.sellersAdmins')}
      </Typography.Title>
      <TableToolbar
        tableSize={tableSize}
        setTableSize={setTableSize}
        fontSize={fontSize}
        setFontSize={setFontSize}
        refresh={() => getSellerAdminData()}
        totalItems={total}
        columns={columns}
        search={(keyword) => {
          setKeyword(keyword);
        }}
        leftElement={
          <Button
            disabled={
              !hasPermission(
                actionPermissions.generalGroup.usersMerchantsPriv
              ) || sellerAdmins.length > 0
            }
            icon={<PlusOutlined />}
            onClick={() => {
              setEditSellerAdmins(undefined);
              setShowModal(true);
            }}
          >
            {t('sellers.add/editAdmin.addTitle')}
          </Button>
        }
      />
      <Table<SellerAdminData>
        size="small"
        columns={columns}
        dataSource={sellerAdmins}
        loading={tableLoading}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowKey={(sellerAdmin) => sellerAdmin.adminId}
        rowSelection={{
          selectedRowKeys,
          onChange: (rowKeys: React.Key[], rows: SellerAdminData[]) => {
            setSelectedRowKeys(rowKeys);
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPageSize(pSize || pageSize);
            setPage(page);
          },
        }}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          funct={{}}
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns}
        />
      )}
      <SellerAdminModal
        visible={showModal}
        setVisible={setShowModal}
        adminProfile={editSellerAdmins}
        callBack={() => getSellerAdminData()}
        sellerId={id}
      />
    </>
  );
};

export default UserAdminListTable;
