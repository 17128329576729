//Printout Newline keyword
export const PRINTOUT_NEWLINE = 'divider';

/** FontSize for change font size in table cell */
export const PRINTOUT_TITLE_FONT_SIZE = 30;
export const PRINTOUT_TITLE_NAME_FONT_SIZE = 40;
export const PRINTOUT_SEQ_LABLE_FONT_SIZE = 50;
export const PRINTOUT_FOCUS_FONT_SIZE = 0;
export const PRINTOUT_SHIPPING_INFO_FONT_SIZE = 17;
export const PRINTOUT_QR_CODES_FONT_SIZE = 14;
export const PRINTOUT_SUBTITLE_FONT_SIZE = 20;
export const PRINTOUT_RULES_FONT_SIZE = 11;
export const PRINTOUT_DATA_FONT_SIZE = 13;
export const PRINTOUT_DATA_REMARK_FONT_SIZE = 20;
export const DRIVER_PRINTOUT_DATA_FONT_SIZE = 19;

/**Invoice  */
export const INVOICE_PRINT_DEFAULT = 14;
export const PRINTOUT_TERMS_FONT_SIZE = 11;
export const INVOICE_REMARK_FONTSIZE = 20;

/**Statement Printout FontSize */
export const STATEMENT_SMALLEST = 12;
export const STATEMENT_MAIN_BODY = 13;
export const STATEMENT_SUB_TITLE = 14;
export const STATEMENT_TITLE = 17;

/** Order Claim  */
export const CLAIM_PRINT_DEFAULT = 14;
export const CLAIM_FOOTER_FONTSIZE = 14;
