import { Image } from 'antd';

type ShopLogoProps = {
  src?: string;
};

/**
 * Displays photo of the shop's logo
 *
 * @param src URL of the photo
 */
const ShopLogo = ({ src }: ShopLogoProps) => {
  return (
    <Image width={"auto"} src={src} style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      
    }} />
  );
};

export default ShopLogo;
