import { Select, Space, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORANGE1 } from '../../../constants/color';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { UserListData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';

type UsersDropdownProps = {
  disabled?: boolean;
  defaultOptions?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const UsersDropdown = ({
  onChange,
  disabled = false,
  defaultOptions = false,
  initialValue,
}: UsersDropdownProps) => {
  const { t } = useTranslation();
  //Data components
  const [userList, setUserList] = useState<UserListData[]>([]);
  //Pagination/table Components
  const [userPage, setUserPage] = useState(2);
  const [userTotalPage, setUserTotalPage] = useState(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  /**
   * Sends a request and returns the User Data based on the typed keywords
   */
  const getUserData = (value?: string) => {
    getDataWithAuthToken('users/list', {
      params: {
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setUserList(response.data.list);
          setUserPage(2);
          setUserTotalPage(response.data.totalPage);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Makes a new request to get new list of users
   * When the user scrolls to the bottom of the dropdown box
   */
  const fetchOnScrollUserData = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (userPage > userTotalPage) return;
      getDataWithAuthToken('users/list', {
        params: {
          page: userPage,
          keyword: searchText.user || undefined,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setUserList((prev) => [...prev, ...response.data.list]);
          setUserPage((prev) => prev + 1);
          setUserTotalPage(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      filterOption={false}
      placeholder={t('searchPlaceholders.searchUserKeyword')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={fetchOnScrollUserData}
      onFocus={() => {
        if (!userList.length) getUserData();
      }}
      showSearch
      onSearch={(value: string) => {
        setSearchText((prev) => ({ ...prev, user: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getUserData(value), EXTENDED_TIMEOUT)
        );
      }}
      disabled={disabled}
      allowClear={!defaultOptions}
      defaultValue={initialValue ? initialValue : undefined}
      optionLabelProp="key"
      onChange={handleOnChange}
    >
      {defaultOptions && (
        <Select.Option key={0} value={0}>
          {t('actionsColumn.unassigned')}
        </Select.Option>
      )}
      {userList.map((user) => (
        <Select.Option key={user.userName} value={user.userId} user={user}>
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('users.userListColumns.username')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('users.userListColumns.mobilePhone')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Space>
                <Typography>
                  {user.userName}
                  {user.nickName ? ` (${user.nickName})` : ''}
                </Typography>
                {user.userRank && user.userRank.rankId > 0 && (
                  <Typography.Text style={{ color: ORANGE1 }}>
                    {` - ${user.userRank.rankName}`}
                  </Typography.Text>
                )}
              </Space>
              <Typography>{user.mobilePhone}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default UsersDropdown;
