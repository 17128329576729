import { Divider } from 'antd';
import { Dispatch, SetStateAction, forwardRef } from 'react';
import DriverPrintoutTable from '../../components/order/driverPrintoutSections/DriverPrintoutTable';
import CommonPrintoutHeader from '../../components/order/orderPrintoutSections/CommonPrintoutHeader';
import FormValuePrintout from '../../components/order/orderPrintoutSections/FormValuePrintout';
import InvoicePrintoutBody from '../../components/order/orderPrintoutSections/InvoicePrintSections/InvoicePrintoutBody';
import OrderPrintoutBody from '../../components/order/orderPrintoutSections/OrderPrintoutBody';
import OrderPrintoutFooter from '../../components/order/orderPrintoutSections/OrderPrintoutFooter';
import OrderPrintoutHeader from '../../components/order/orderPrintoutSections/OrderPrintoutHeader';
import OrderPrintoutTable from '../../components/order/orderPrintoutSections/OrderPrintoutTable';
import OrderClaimPrintoutBody from '../../components/order/orderPrintoutSections/orderClaimPrintSections/OrderClaimPrintoutBody';
import OrderClaimPrintoutTable from '../../components/order/orderPrintoutSections/orderClaimPrintSections/OrderClaimPrintoutTable';
import OrderGoodsPrintoutBody from '../../components/order/orderPrintoutSections/orderGoodsPrintSections/OrderGoodsPrintoutBody';
import StatementPrintoutBody from '../../components/order/orderPrintoutSections/statementPrintoutSections/StatementPrintoutBody';
import StatementPrintoutTable from '../../components/order/orderPrintoutSections/statementPrintoutSections/StatementPrintoutTable';
import PrintExcelTable from '../../components/settings/PrintExcelTable';
import PrintCartLabel from '../../components/sortationSystem/common/PrintCartLabel';
import { BLACK } from '../../constants/color';
import {
  CommonPrintoutHeaderType,
  ExpressOrderList,
  PrintType,
  PrintoutOrderBodyData,
  PrintoutOrderHeaderData,
  PrintoutSelectedOrderData,
  SpecialSummaryList,
} from '../../types';
import getDashboardStyle from '../../utils/getDashboardStyle';

/**
 * This is where the body of the page to be printed is constructed.
 * The body consists of 4 parts:
 * @alias OrderPrintoutHeaderInfo: This is usually unChanged. Includes the title of the printout, QR codes, and the numerated steps
 * @alias OrderPrintoutBody: This is list of order info such as consignee, address, expected time name...etc, as well as, the 3 sub sections such as
 *    Seller Preview, Notify Type, Order Note
 * @alias OrderTable: This is table section of the printout.
 * @alias OrderPrintoutFooter: This is primarily for subtotal and total cost info as well as other comments
 */
export const ComponentToPrint = forwardRef(
  (
    props: {
      isFullVersion?: boolean;
      setDate?: Dispatch<SetStateAction<string | undefined>>;
    } & PrintType,
    ref
  ) => {
    // 打印模板 - Invoice/Packing Slip
    const getGeneralInvoicePrintContent = (
      title: string,
      contentData: PrintoutSelectedOrderData,
      showPrice: boolean
    ) => {
      if (contentData) {
        return (
          <div
            key={contentData.body.orderSn}
            style={{ margin: 0, flexDirection: 'column', width: '100%' }}
          >
            <div
              id={title}
              style={{
                margin: 15,
              }}
            >
              <CommonPrintoutHeader
                header={
                  {
                    ...contentData.header,
                    serialNumber: contentData.header.invoiceSn,
                    addTime: !getDashboardStyle().isWholeSaleAppType
                      ? contentData.header.addTime
                        ? contentData.header.addTime.slice(0, -9)
                        : undefined
                      : undefined,
                  } as CommonPrintoutHeaderType
                }
                title={title.toUpperCase()}
              />
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  height: 2,
                  backgroundColor: BLACK,
                }}
              />
              <InvoicePrintoutBody
                header={contentData.header}
                body={contentData.body}
              />
              <div style={{ marginBottom: 15 }}></div>
              {contentData.table && (
                <OrderPrintoutTable
                  printoutTableData={contentData.table}
                  isFullVersion={true}
                  showPrice={showPrice}
                />
              )}
            </div>
          </div>
        );
      }
      return <></>;
    };

    // 司机打印
    const getDriverPrintContent = () => {
      const divide = (arr: any[], len: number) => {
        var chunks = [],
          i = 0,
          n = arr.length;
        while (i < n) {
          chunks.push(arr.slice(i, (i += len)));
        }
        return chunks;
      };
      const fillLowArrays = (arrayTotal: any[][], maxNumber: number) => {
        let x = arrayTotal.map((array) => {
          if (array.length < maxNumber) {
            return array.concat(
              Array.apply(null, Array(maxNumber - array.length)).map(
                (obj, index) => {
                  return {
                    key: `@${index}`,
                    sequenceLabel: '',
                    orderId: `@${index}`,
                    consignee: '',
                    mobile: '',
                    fullAddress: '',
                    totalAmount: '',
                    orderAmount: '',
                    tipsFee: '',
                    shippingFee: '',
                    sellerRemark: '',
                    buyerRemark: '',
                  };
                }
              )
            );
          } else {
            return array;
          }
        });
        return x;
      };
      if (props && props.driverData) {
        let dividedExpressData: ExpressOrderList[][] = [];
        let dividedSpecialData: SpecialSummaryList[][] = [];
        if (props.driverData) {
          dividedExpressData = divide(props.driverData.expressList, 11);
          dividedExpressData = fillLowArrays(dividedExpressData, 11);
          dividedSpecialData = divide(props.driverData.specialList, 12);
          dividedSpecialData = fillLowArrays(dividedSpecialData, 12);
        }

        return (
          <div
            key={0}
            style={{
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <DriverPrintoutTable
              routeName={props.driverData?.routeName}
              routeId={props.driverData?.routeId}
              expressData={dividedExpressData}
              specialData={dividedSpecialData}
              totalOrder={props.driverData?.totalOrder}
              sumTipsFee={props.driverData?.sumTipsFee}
              totalSpecialSummary={props.driverData?.totalSpecialSummary}
            />
          </div>
        );
      }
      return <></>;
    };

    // 订单打印样式
    const getOrderPrintContent = () => {
      if (props && props.orderData) {
        return (
          <div
            key={props.orderData.body.orderSn}
            style={{ margin: 0, flexDirection: 'column', width: '100%' }}
          >
            <OrderPrintoutHeader
              header={props.orderData.header as PrintoutOrderHeaderData}
            />
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <OrderPrintoutBody
              body={props.orderData.body as PrintoutOrderBodyData}
            />
            <div style={{ marginBottom: 15 }}></div>
            {props.orderData && props.orderData.table && (
              <>
                <FormValuePrintout printoutTableData={props.orderData.table} />
                <OrderPrintoutTable
                  isFullVersion={props.isFullVersion}
                  printoutTableData={props.orderData.table}
                />
                <div style={{ marginBottom: 15 }}></div>
              </>
            )}
            <OrderPrintoutFooter footer={props.orderData.footer} />
          </div>
        );
      }
      return <></>;
    };

    // 订单Invoice - 正规格式订单打印
    const getInvoicePrintContent = (contentData: PrintoutSelectedOrderData) => {
      return getGeneralInvoicePrintContent('invoice', contentData, true);
    };

    // 发货单
    const getPackingSlipPrintContent = (
      contentData: PrintoutSelectedOrderData
    ) => {
      return getGeneralInvoicePrintContent('packing slip', contentData, false);
    };

    // Statement
    const getStatementPrintContent = () => {
      if (props && props.statementData && props.setDate) {
        return (
          <div
            style={{
              margin: 0,
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              id="statement"
              style={{
                margin: 15,
              }}
            >
              <CommonPrintoutHeader
                header={
                  {
                    ...props.statementData,
                  } as CommonPrintoutHeaderType
                }
                title={'STATEMENT'}
                datePicker={{
                  startDate: props.statementData.startDate,
                  endDate: props.statementData.endDate,
                  setDate: props.setDate,
                }}
              />
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  height: 2,
                  backgroundColor: BLACK,
                }}
              />
              <StatementPrintoutBody body={props.statementData} />
              <StatementPrintoutTable data={props.statementData} />
            </div>
          </div>
        );
      }
      return <></>;
    };

    // OrderClaim
    const getOrderClaimPrintContent = () => {
      if (props && props.orderClaimData) {
        return (
          <div
            style={{
              margin: 0,
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              id="orderClaim"
              style={{
                margin: 15,
              }}
            >
              <CommonPrintoutHeader
                header={
                  {
                    ...props.orderClaimData,
                    serialNumber: props.orderClaimData.claimInvoiceSn,
                    addTime: !getDashboardStyle().isWholeSaleAppType
                      ? props.orderClaimData.addTime
                        ? props.orderClaimData.addTime.slice(0, -9)
                        : undefined
                      : undefined,
                  } as CommonPrintoutHeaderType
                }
                title={
                  props.orderClaimData.claimType.code === 'CREDIT_MEMO'
                    ? 'CREDIT MEMO'
                    : 'ORDER CLAIM'
                }
              />
              <div
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  height: 2,
                  backgroundColor: BLACK,
                }}
              />
              <OrderClaimPrintoutBody claim={props.orderClaimData} />
              <div style={{ marginBottom: 15 }}></div>
              <OrderClaimPrintoutTable claim={props.orderClaimData} />
            </div>
          </div>
        );
      }
      return <></>;
    };

    // OrderGoods
    const getOrderGoodsPrintContent = () => {
      if (props && props.orderGoodsData) {
        return <OrderGoodsPrintoutBody body={props.orderGoodsData} />;
      }
      return <></>;
    };

    if (props.orderData) {
      // 特殊处理
      return getDashboardStyle().isWholeSaleAppType
        ? getInvoicePrintContent(props.orderData)
        : getOrderPrintContent();
    } else if (props.invoiceData) {
      return getInvoicePrintContent(props.invoiceData);
    } else if (props.packingSlipData) {
      return getPackingSlipPrintContent(props.packingSlipData);
    } else if (props.driverData) {
      return getDriverPrintContent();
    } else if (props.statementData) {
      return getStatementPrintContent();
    } else if (props.orderClaimData) {
      return getOrderClaimPrintContent();
    } else if (props.orderGoodsData) {
      return getOrderGoodsPrintContent();
    } else if (props.printLabel) {
      return <PrintCartLabel />;
    } else if (props.excelData) {
      return props.excelData ? <PrintExcelTable {...props.excelData} /> : <></>;
    } else {
      return <></>;
    }
  }
);
