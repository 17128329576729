import { Button, Modal, Space } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MEDIA_TYPE } from '../../../constants/mediaConstants';
import { GoodPicData } from '../../../types';
import PhotoGalleryGrid from '../../photoGallery/PhotoGalleryGrid';

type GoodGalleryModalProps = {
  photos: GoodPicData[];
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  value?: any;
};

const GoodGalleryModal = ({
  photos,
  visible,
  setVisible,
  setValue,
  value,
}: GoodGalleryModalProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  const [selectedMedias, setSelectedMedias] = useState<any>([]);

  const handleOk = () => {
    if (isSubscribed.current) {
      setValue && setValue(selectedMedias);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    if (isSubscribed.current) setVisible(false);
  };

  return (
    <Modal
      title={<Space>{t('settings.photoGallery')}</Space>}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1100}
      okText={t('photoGallery.add/editAlbum.ok')}
      cancelText={t('photoGallery.add/editAlbum.cancel')}
      bodyStyle={{
        padding: 0,
        minHeight: 580,
      }}
      footer={
        <Space>
          <Button onClick={handleCancel}>
            {t('settings.photoGalleryActions.cancel')}
          </Button>
          <Button type="primary" onClick={handleOk}>
            {t('settings.photoGalleryActions.ok')}
          </Button>
        </Space>
      }
      maskClosable={true}
      destroyOnClose
    >
      <PhotoGalleryGrid
        photos={photos}
        photosLoading={false}
        setValue={setSelectedMedias}
        value={selectedMedias}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
    </Modal>
  );
};

export default GoodGalleryModal;
