import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplierData } from '../../../types';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { Select, Typography } from 'antd';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { DefaultOptionType } from 'antd/lib/select';

type SupplierDropdownProps = {
  labelInValue?: boolean;
  disabled?: boolean;
  initialValue?: any;
  defaultOptions?: boolean;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const SupplierDropdown = ({
  labelInValue = false,
  initialValue,
  onChange,
}: SupplierDropdownProps) => {
  const { t } = useTranslation();
  const [supplierPage, setSupplierPage] = useState(2);
  const [supplierTotalPage, setSupplierTotalPage] = useState(2);
  const [suppliers, setSuppliers] = useState<SupplierData[]>([]);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getSuppliers = (value?: string) => {
    getDataWithAuthToken('goods/supplier/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setSuppliers(response.data.list);
          setSupplierPage(2);
          setSupplierTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * Fetch when supplier list scrolls to the bottom
   * @param e UI event
   */
  const supplierFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (supplierPage > supplierTotalPage) return;
      getDataWithAuthToken('goods/supplier/list', {
        params: {
          keyword: searchText.supplier || undefined,
          page: supplierPage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setSuppliers((prev) => [...prev, ...response.data.list]);
            setSupplierPage((prev) => prev + 1);
            setSupplierTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };
  return (
    <Select
      style={{ width: '100%' }}
      placeholder={t('searchPlaceholders.searchSupplier')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={supplierFetchOnScroll}
      showSearch
      onSearch={(value) => {
        setSearchText((prev) => ({
          ...prev,
          supplierId: value,
        }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getSuppliers(value), EXTENDED_TIMEOUT)
        );
      }}
      onFocus={() => {
        if (!suppliers.length) getSuppliers();
      }}
      optionLabelProp="key"
      filterOption={false}
      allowClear
      labelInValue={labelInValue}
      defaultValue={initialValue ? initialValue : undefined}
      onChange={handleOnChange}
    >
      {suppliers.map((supplier) => (
        <Select.Option
          key={`${supplier.supplierName} (${supplier.supplierId})`}
          value={supplier.supplierId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('goods.supplierListColumns.supplierId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('goods.supplierListColumns.supplierName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{supplier.supplierId}</Typography>
              <Typography>{supplier.supplierName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SupplierDropdown;
