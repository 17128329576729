import { BarChartOutlined, ReloadOutlined } from '@ant-design/icons';
import { ResponsiveLine } from '@nivo/line';
import { Button, Spin, Tabs, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BLUE3, HIGHLIGHT_YELLOW, PRIMARY } from '../../constants/color';
import { PERIOD } from '../../constants/analysisConstants';
import {
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
  MEDIUM_FONT_SIZE,
} from '../../constants/systemConstants';
import { HomeAnalysisDataPoint, NivoLineChartData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import {
  getLineGraphBottomAxisLabel,
  nivoToolTip,
} from '../../utils/nivoGraphTools';
import FiveHundred from '../FiveHundred';
import FourZeroThree from '../FourZeroThree';

type OrderOverviewProps = {
  isSubscribed: React.MutableRefObject<boolean>;
};

const HomePeriodReport = ({ isSubscribed }: OrderOverviewProps) => {
  //General Components
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const viewTypeButtons = ['totalOrderNum', 'totalAmount'];
  const [viewMode, setViewMode] = useState<string>(
    t('statistics.homePeriodReport.totalOrderNum')
  );
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [tabMode, setTabMode] = useState<'month' | 'year'>('month');
  //Data Components
  const [monthLineGraphData, setMonthLineGraphData] = useState<
    NivoLineChartData[]
  >([]);
  const [yearLineGraphData, setYearLineGraphData] = useState<
    NivoLineChartData[]
  >([]);
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  /**
   * Grabs Data and Statistics for the Dashboard
   */
  const getData = useCallback(
    (period: string) => {
      const getLineGraphData = (data: HomeAnalysisDataPoint[]) => {
        let tempAnalysisData: NivoLineChartData[] = [];
        let tempTotalAmountData: { x: string; y: string | number }[] = [];
        let tempOrderNumData: { x: string; y: string | number }[] = [];
        // console.log(data.length);
        data.forEach((point) => {
          tempTotalAmountData.push({ x: point.label, y: point['totalAmount'] });
          tempOrderNumData.push({ x: point.label, y: point['totalOrderNum'] });
        });
        tempAnalysisData.push({
          id: `${t('statistics.homePeriodReport.totalAmount')}`,
          color: PRIMARY,
          data: tempTotalAmountData,
        });
        tempAnalysisData.push({
          id: `${t('statistics.homePeriodReport.totalOrderNum')}`,
          color: BLUE3,
          data: tempOrderNumData,
        });
        // console.log('tempAnalysisData is', tempAnalysisData);
        return tempAnalysisData;
      };

      if (isSubscribed.current) setLoading(true);
      getDataWithAuthToken(`analysis/home/period_report`, {
        params: {
          period: period,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              if (period === 'MONTH') {
                if (response.data.list.length === 0) {
                  setMonthLineGraphData([]);
                } else {
                  setMonthLineGraphData(getLineGraphData(response.data.list));
                }
              } else if (period === 'YEAR') {
                if (response.data.list.length === 0) {
                  setMonthLineGraphData([]);
                } else {
                  setYearLineGraphData(getLineGraphData(response.data.list));
                }
              }
            }
          } else if (response && response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (isSubscribed.current) setLoading(false);
        });
    },
    [t, isSubscribed]
  );

  useEffect(() => {
    getData(PERIOD['month']);
  }, [getData]);

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <Spin style={{ width: '100%' }} spinning={loading}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <BarChartOutlined
          style={{
            color: HIGHLIGHT_YELLOW,
            fontSize: 17,
            paddingRight: 5,
          }}
        />
        <Typography.Text style={{ fontSize: MEDIUM_FONT_SIZE }}>
          {t('statistics.homePeriodReport.title')}
        </Typography.Text>
      </div>
      <Tabs
        onTabClick={(key) => {
          if (key === '0') {
            if (monthLineGraphData.length === 0) getData('MONTH');
            setTabMode('month');
          } else {
            if (yearLineGraphData.length === 0) getData('YEAR');
            setTabMode('year');
          }
        }}
        defaultActiveKey="0"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {Object.keys(PERIOD).map((label, index) => {
          return (
            <Tabs.TabPane
              key={index.toString()}
              tab={t(`statistics.homePeriodReport.${label}`)}
            >
              {viewTypeButtons.map((buttonLabel, index) => {
                /**ViewType Buttons */
                return (
                  <Button
                    key={index}
                    type={
                      viewMode ===
                      t(`statistics.homePeriodReport.${buttonLabel}`)
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() =>
                      setViewMode(
                        t(`statistics.homePeriodReport.${buttonLabel}`)
                      )
                    }
                  >
                    {`${t(`statistics.homePeriodReport.${buttonLabel}`)} (${t(
                      buttonLabel === 'totalOrderNum'
                        ? `nivo.number`
                        : `nivo.dollar`
                    )})`}
                  </Button>
                );
              })}
              <Tooltip /**Buttons Selection, Refresh Buttons */
                title={t('general.refresh')}
              >
                <Button
                  type="text"
                  onClick={() => {
                    // console.log('range1Data is', range1Data);
                    // console.log('range2Data is', range2Data);
                    // console.log('growth is', growthData);
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        label === 'month' ? getData('MONTH') : getData('YEAR');
                      }, GENERAL_TIMEOUT)
                    );
                  }}
                  icon={<ReloadOutlined />}
                />
              </Tooltip>
              <div style={{ width: '100%', height: 650 }}>
                <div
                  style={{ textAlign: 'center', fontSize: MEDIUM_FONT_SIZE }}
                >
                  {viewMode}
                </div>
                {monthLineGraphData.length === 0 &&
                yearLineGraphData.length === 0 ? (
                  <></>
                ) : (
                  <ResponsiveLine
                    tooltip={(point) =>
                      nivoToolTip({
                        ...point,
                        tooltipType: 'HomeAnalysis',
                        xAxisLength:
                          tabMode === 'month'
                            ? monthLineGraphData[0].data.length
                            : yearLineGraphData[0].data.length,
                        specialForm: label,
                      })
                    }
                    axisBottom={getLineGraphBottomAxisLabel(
                      undefined,
                      'standard'
                    )}
                    theme={{
                      fontSize: DEFAULT_FONT_SIZE,
                      axis: {
                        legend: { text: { fontSize: DEFAULT_FONT_SIZE } },
                      },
                    }}
                    colors={(d) => d.color}
                    xScale={{
                      type: 'point',
                    }}
                    yScale={{
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: false,
                      reverse: false,
                    }}
                    useMesh
                    animate={true}
                    enableGridX={false}
                    data={
                      tabMode === 'month'
                        ? monthLineGraphData.filter(
                            (data) => data.id === viewMode
                          )
                        : yearLineGraphData.filter(
                            (data) => data.id === viewMode
                          )
                    }
                    axisLeft={{
                      legend:
                        viewMode ===
                        t('statistics.homePeriodReport.totalOrderNum')
                          ? t('nivo.number')
                          : t('nivo.dollar'),
                      legendOffset: -60,
                      legendPosition: 'middle',
                    }}
                    margin={{
                      top: 10,
                      right: 20,
                      bottom: 150,
                      left: 70,
                    }}
                  />
                )}
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </Spin>
  );
};

export default HomePeriodReport;
