import { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import PermissionList from './PermissionList';
import { AdminRoleData } from '../../types';
import { DataNode } from 'rc-tree-select/lib/interface';
import { alertMessage } from '../../utils/alertMessage';

type AdminRoleModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  editRole?: AdminRoleData;
  refresh?: Function;
  permissionList?: DataNode[];
};

const AdminRoleModal = ({
  showModal,
  setShowModal,
  editRole,
  refresh,
  permissionList,
}: AdminRoleModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState<
    string[] | undefined
  >([]);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(editRole ? 'admin/role/edit' : 'admin/role/add', {
          ...values,
          roleId: editRole ? editRole.roleId : undefined,
          actionList: selectedPermissions?.join(','),
        })
          .then((result) => {
            if (result && result.goodStatus) {
              if (isSubscribed.current) setShowModal(false);
              if (refresh) refresh();
              alertMessage('success', t('admin.alerts.roleAdded'));
            } else {
              alertMessage(
                'error',
                result?.msg || t('general.noResponse'),
                result?.data || undefined
              );
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCancel = () => {
    if (isSubscribed.current) setShowModal(false);
  };

  const onReset = () => {
    if (isSubscribed.current) setSelectedPermissions([]);
    form.resetFields();
  };

  useEffect(() => {
    if (isSubscribed.current && showModal) {
      form.setFieldsValue(editRole);
      if (permissionList?.length && editRole?.actionList)
        if (isSubscribed.current)
          setSelectedPermissions(editRole.actionList.split(','));
    }
  }, [editRole, permissionList, showModal, form]);

  return (
    <Modal
      title={
        editRole
          ? `${t('admin.add/editRole.editTitle')} ${editRole.roleName}`
          : `${t('admin.add/editRole.addTitle')}`
      }
      visible={showModal}
      onOk={onFinish}
      onCancel={onCancel}
      okText={t('admin.add/editRole.ok')}
      cancelText={t('admin.add/editRole.cancel')}
      afterClose={onReset}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="roleName"
            label={t('admin.add/editRole.name')}
            rules={[{ required: true, message: t('general.inputError.empty') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="roleDesc"
            label={t('admin.add/editRole.description')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="actionList"
            label={t('admin.add/editRole.action')}
            rules={[
              {
                required: selectedPermissions?.length ? false : true,
                message: t('general.inputError.empty'),
              },
            ]}
            required
          >
            <PermissionList
              permissionList={permissionList}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AdminRoleModal;
