import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Card,
  Grid,
  Typography,
  Space,
  Button,
  Popconfirm,
  Breadcrumb,
  Table,
  Dropdown,
  Menu,
  Form,
  Badge,
  Popover,
  Select,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { FontSizeType, ArticleCategoryData, ArticleCatEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { useVT } from 'virtualizedtableforantd4';
import ArticleCategoryModal from '../../components/advertisement/ArticleCategoryModal';
import FiveHundred from '../../components/FiveHundred';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, PRIMARY, RED1 } from '../../constants/color';
import { tableScrollToTop } from '../../utils/helperFunction';

const ArticleCatList = () => {
  //General Component
  const formRef = useRef(null);
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  //Table Component
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [form] = Form.useForm();
  const columnKeys = [
    'articleCatId',
    'articleCatName',
    'articleCatType',
    'keyword',
    'articleCatDesc',
    'isEnabled',
    'sortOrder',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((key) => key !== 'articleCatDesc')
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  //Data Component
  const [articleCatData, setArticleCatData] = useState<ArticleCategoryData[]>(
    []
  );
  const [editArticleCat, setEditArticleCat] = useState<ArticleCategoryData>();
  const [articleCatEnum, setArticleCatEnum] = useState<ArticleCatEnum>();
  //Text Component
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const { t } = useTranslation();
  //Breadcrumbs Component
  const [parentId, setParentId] = useState(0);
  const [parentArtCat, setParentArtCat] = useState<ArticleCategoryData>();
  const [breadCrumbItems, setBreadCrumbItems] = useState<React.ReactNode[]>([]);
  const [breadCrumbs, setBreadCrumbs] = useState<string[]>([
    t('advertisement.articleCatColumnList.topArticleCatPage'),
  ]);

  const columns: ColumnsType<ArticleCategoryData> = [
    {
      title: setFont(
        t('advertisement.articleCatColumnList.articleCatId'),
        fontSize
      ),
      key: 'articleCatId',
      dataIndex: 'articleCatId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string, record: ArticleCategoryData) => (
        <Button
          type="link"
          onClick={() => handleViewSubArticleCat(record)}
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            !hasPermission(actionPermissions.advertisementGroup.articleCat)
          }
        >
          {text}
        </Button>
      ),
      sorter: (a: ArticleCategoryData, b: ArticleCategoryData) =>
        compare(a.articleCatId, b.articleCatId),
    },
    {
      title: setFont(
        t('advertisement.articleCatColumnList.articleCatName'),
        fontSize
      ),
      key: 'articleCatName',
      dataIndex: 'articleCatName',
      width: 160,
      render: (text: string) => setFont(text === '' ? '' : text, fontSize),
    },
    {
      title: setFont(
        t('advertisement.articleCatColumnList.articleCatType'),
        fontSize
      ),
      key: 'articleCatType',
      dataIndex: 'articleCatType',
      width: 160,
      render: (text: string) =>
        articleCatEnum?.articleCatType.map((type, index) => {
          if (type.code === text) {
            return (
              <Typography.Text style={{ fontSize: fontSize }} key={index}>
                {type.description}
              </Typography.Text>
            );
          }
          return <Typography.Text key={index}>{''}</Typography.Text>;
        }),
    },
    {
      title: setFont(t('advertisement.articleCatColumnList.keyword'), fontSize),
      key: 'keyword',
      dataIndex: 'keyword',
      width: 160,
      render: (text: string) => setFont(text === '' ? '' : text, fontSize),
    },
    {
      title: setFont(
        t('advertisement.articleCatColumnList.articleCatDesc'),
        fontSize
      ),
      key: 'articleCatDesc',
      dataIndex: 'articleCatDesc',
      width: 180,
      render: (text: string) => setFont(text === '' ? '' : text, fontSize),
    },
    {
      title: setFont(
        t('advertisement.articleCatColumnList.isEnabled'),
        fontSize
      ),
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(
        t('advertisement.articleCatColumnList.sortOrder'),
        fontSize
      ),
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: t('actionsColumn.title'),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: ArticleCategoryData) => (
        <Space size="small">
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.articleCat)
            }
            onClick={() => {
              setEditArticleCat(record);
              setShowModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.articleCatId])
                setMorePopoverVis({ [record.articleCatId]: flag });
            }}
            visible={morePopoverVis[record.articleCatId]}
            overlay={
              <Menu>
                <Menu.Item
                  key="view"
                  onClick={() => handleViewSubArticleCat(record)}
                >
                  {setFont(t('actionsColumn.view'), fontSize)}
                </Menu.Item>
                <Menu.Item key="delete">
                  <Popconfirm
                    title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
                    onConfirm={() => {
                      deleteArtCat(record.articleCatId);
                    }}
                    okText={t('actionsColumn.confirmation.yes')}
                    cancelText={t('actionsColumn.confirmation.no')}
                    placement="leftBottom"
                  >
                    <Typography.Text
                      type="danger"
                      style={{ fontSize: fontSize }}
                    >
                      {t('actionsColumn.delete')}
                    </Typography.Text>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.articleCatId]: true })}
              disabled={
                !hasPermission(actionPermissions.advertisementGroup.articleCat)
              }
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  /**
   * @param id Id of ArtCat to delete
   */
  const deleteArtCat = (id: number) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('article/cat/delete', { articleCatId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('advertisement.alerts.articleCatDeleted'));
          getArticleCatData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  //Get Article Category data
  const getArticleCatData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('article/cat/list', {
      params: {
        page: page,
        size: pageSize,
        parentId: parentId,
        keyword: keyword || undefined,
        isEnabled:
          formRef.current && form.getFieldValue('isEnabled')
            ? form.getFieldValue('isEnabled') === 'all'
              ? undefined
              : form.getFieldValue('isEnabled')
            : undefined,
        articleCatType:
          formRef.current && form.getFieldValue('articleCatType')
            ? form.getFieldValue('articleCatType') === 'all'
              ? undefined
              : form.getFieldValue('articleCatType')
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setArticleCatData(response.data.list);
              setParentArtCat(response.data.data);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);

              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, parentId, keyword, form, page, pageSize]);

  // Get Article Category Enums
  const getArtCatEnum = useCallback(() => {
    getDataWithAuthToken('article/cat/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setArticleCatEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  // Click on breadcrumb
  const handleClickOnBreadcrumb = (e: React.MouseEvent) => {
    let target = e.target as HTMLAnchorElement;
    if (target.tabIndex !== breadCrumbItems.length - 1) {
      let temp = breadCrumbItems;
      temp.splice(target.tabIndex + 1);
      if (isSubscribed.current) {
        setBreadCrumbItems(temp);
        setParentId(parseInt(target.id));
        setBreadCrumbs((prev) => prev.slice(0, target.tabIndex + 2));
      }
    }
  };

  // View sub Ads
  const handleViewSubArticleCat = (artCat: ArticleCategoryData) => {
    let items = breadCrumbItems;
    items.push(
      <Breadcrumb.Item key={artCat.articleCatId}>
        <span
          id={artCat.articleCatId.toString()}
          tabIndex={breadCrumbItems.length}
          onClick={handleClickOnBreadcrumb}
          style={{ cursor: 'pointer', color: PRIMARY }}
        >
          {artCat.articleCatName ? artCat.articleCatName : ''}
        </span>
      </Breadcrumb.Item>
    );
    if (isSubscribed.current) {
      setBreadCrumbItems(items);
      setPage(1);
      setParentId(artCat.articleCatId);
      setBreadCrumbs((prev) => [...prev, artCat.articleCatName]);
    }
  };

  // Go back to upper level ad list
  const handleGoBack = (artCat: ArticleCategoryData) => {
    if (isSubscribed.current) {
      let temp = breadCrumbItems;
      temp.pop();
      setBreadCrumbItems(temp);
      setParentId(artCat.parentId || 0);
      setBreadCrumbs((prev) => prev.slice(0, prev.length - 1));
    }
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getArticleCatData();
  }, [getArticleCatData]);

  useEffect(() => {
    getArtCatEnum();
  }, [getArtCatEnum]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('advertisement.articleCatColumnList.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all', articleCatType: 'all' }}
        >
          <Form.Item
            name="isEnabled"
            label={t(
              'advertisement.articleCatColumnList.advancedSearch.isEnabled'
            )}
          >
            <Select
              style={{ width: 140 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              optionLabelProp="key"
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  isEnabled: value === 'all' ? '' : value.toString(),
                }));
              }}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t(
              'advertisement.articleCatColumnList.advancedSearch.articleCatType'
            )}
            name="articleCatType"
          >
            <Select
              placeholder={t('general.inputError.pleaseSelectOne')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  articleCatType: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t('general.all')}
              </Select.Option>
              {articleCatEnum?.articleCatType.map((adType) => (
                <Select.Option key={adType.description} value={adType.code}>
                  {adType.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getArticleCatData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('advertisement.articleCatColumnList.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getArticleCatData();
                }}
              >
                {t('advertisement.articleCatColumnList.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('advertisement.articleCatColumnList.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('advertisement.articleCatList')}
            </Typography.Title>
            {hasPermission(actionPermissions.advertisementGroup.articleCat) &&
              !!(breadCrumbItems && breadCrumbItems.length) && (
                <Breadcrumb separator=">" style={{ marginBottom: 8 }}>
                  <Breadcrumb.Item key="0">
                    <span
                      onClick={() => {
                        setBreadCrumbItems([]);
                        setParentId(0);
                        setBreadCrumbs([
                          t(
                            'advertisement.articleCatColumnList.topArticleCatPage'
                          ),
                        ]);
                      }}
                      style={{ cursor: 'pointer', color: PRIMARY }}
                    >
                      {t(
                        'advertisement.articleCatColumnList.topArticleCatPage'
                      )}
                    </span>
                  </Breadcrumb.Item>
                  {breadCrumbItems}
                </Breadcrumb>
              )}
            <TableToolbar
              advancedSearch={advancedSearch}
              setFontSize={setFontSize}
              fontSize={fontSize}
              leftElement={
                <Space>
                  {parentArtCat && JSON.stringify(parentArtCat) !== '{}' && (
                    <Button
                      icon={<RollbackOutlined />}
                      onClick={() => handleGoBack(parentArtCat)}
                      disabled={
                        !hasPermission(
                          actionPermissions.advertisementGroup.articleCat
                        )
                      }
                    >
                      {t('advertisement.articleCatColumnList.back')}
                    </Button>
                  )}
                  {
                    <Button
                      disabled={
                        !hasPermission(
                          actionPermissions.advertisementGroup.articleCat
                        )
                      }
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setEditArticleCat(undefined);
                        setShowModal(true);
                      }}
                    >
                      {t('advertisement.add/EditArticleCat.addTitle')}
                    </Button>
                  }
                </Space>
              }
              tableSize={tableSize}
              setTableSize={setTableSize}
              refresh={() => {
                getArticleCatData();
              }}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchArticleCat')}
              rows={articleCatData.map((articleCat) => ({
                ...articleCat,
              }))}
              exportConfig={{
                fileName: 'ARTICLE_CATEGORY_LIST',
              }}
            />
            <Table<ArticleCategoryData>
              dataSource={articleCatData}
              rowKey={(articleCat) => articleCat.articleCatId}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              size={tableSize}
              loading={isLoading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                selectedRows={[]}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
                funct={{ exportConfig: { fileName: 'ARTICLE_CATEGORY_LIST' } }}
              />
            )}
          </Card>
          <ArticleCategoryModal
            selectedArticleCat={editArticleCat}
            visible={showModal}
            setVisible={setShowModal}
            refresh={() => getArticleCatData()}
            artCatEnum={articleCatEnum}
            parent={parentArtCat}
            breadCrumbs={breadCrumbs}
          />
        </>
      )}
    </Container>
  );
};

export default ArticleCatList;
