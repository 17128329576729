import { useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdminListData,
  FontSizeType,
  ValueCard,
  ValueRecordCard,
} from '../../types';
import { Typography, Button, Modal, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import { setFont, compare } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { DEFAULT_FONT_SIZE } from '../../constants/systemConstants';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useVT } from 'virtualizedtableforantd4';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { useTab } from '../../hooks/useTab';
import { PlusOutlined } from '@ant-design/icons';
import TableToolbar from '../table/TableToolbar';
import TableFooterToolbar from '../table/TableFooterToolbar';
import ValueRecordCardModal from './ValueRecordCardModal';
import FiveHundred from '../FiveHundred';
import FourZeroThree from '../FourZeroThree';

type ValueCardRecordListModalProps = {
  selectedVCData: ValueCard | undefined;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: Function;
};

const ValueCardRecordListModal = ({
  selectedVCData,
  visible,
  setVisible,
  refresh,
}: ValueCardRecordListModalProps) => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(true);
  const { addTab } = useTab();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [adjustModalVisible, setAdjustModalVisible] = useState(false);
  //Data Components
  const [valueRecordCardData, setValueRecordCardData] = useState<
    ValueRecordCard[]
  >([]);
  const [parentRecordCardData, setParentRecordCardData] = useState<ValueCard>();
  //Table Components
  const columnKeys = [
    'orderId',
    'adminUser',
    'addVal|useVal',
    'currentVal',
    'recordDesc',
    'recordTime',
  ];
  const screens = useBreakpoint();
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  //Text Components
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const columns: ColumnsType<ValueRecordCard> = [
    {
      title: setFont(t('activity.valueCardRecordColumns.recordTime'), fontSize),
      dataIndex: 'recordTime',
      key: 'recordTime',
      fixed: screens.lg ? 'left' : undefined,
      width: 200,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardRecordColumns.vcrId'), fontSize),
      dataIndex: 'vcrId',
      key: 'vcrId',
      width: 150,
      sorter: (a: ValueRecordCard, b: ValueRecordCard) =>
        compare(a.vcrId, b.vcrId),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('activity.valueCardRecordColumns.valueChange'),
        fontSize,
        `${t('activity.valueCardRecordColumns.addVal')}|${t(
          'activity.valueCardRecordColumns.useVal'
        )}`
      ),
      key: 'addVal|useVal',
      width: 150,
      render: (text: string, record: ValueRecordCard) =>
        Number(record.addVal) > 0 ? (
          <Typography.Text
            strong
            style={{
              color: GREEN1,
              fontSize: fontSize,
            }}
          >
            {record.addVal}
          </Typography.Text>
        ) : Number(record.useVal) > 0 ? (
          <Typography.Text
            strong
            style={{
              color: RED1,
              fontSize: fontSize,
            }}
          >
            {`-${record.useVal}`}
          </Typography.Text>
        ) : (
          <Typography.Text
            strong
            style={{
              color: GREEN1,
              fontSize: fontSize,
            }}
          >
            {'0.00'}
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('activity.valueCardRecordColumns.currentVal'), fontSize),
      dataIndex: 'currentVal',
      key: 'currentVal',
      width: 150,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardRecordColumns.recordDesc'), fontSize),
      dataIndex: 'recordDesc',
      key: 'recordDesc',
      width: 250,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardRecordColumns.orderId'), fontSize),
      dataIndex: 'orderId',
      key: 'orderId',
      width: 100,
      render: (text: number) =>
        text === 0 ? (
          <Typography.Text style={{ fontSize: fontSize }}>
            {text}
          </Typography.Text>
        ) : (
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() =>
              addTab('', `${dashboardRoute.order.detail}?order_id=${text}`)
            }
            disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
          >
            {text}
          </Button>
        ),
    },
    {
      title: setFont(t('activity.valueCardRecordColumns.adminId'), fontSize),
      key: 'adminUser',
      dataIndex: 'adminUser',
      render: (text: AdminListData, record: ValueRecordCard) =>
        record.adminUser
          ? setFont(record.adminUser.adminUserName, fontSize)
          : '',
    },
  ];

  /**
   * Fetches Value Record Card List
   */
  const getVCRecordData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`value_card/record/list`, {
      params: {
        vcId: selectedVCData ? selectedVCData.vcId : undefined,
        page: page,
        size: pageSize,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setValueRecordCardData(response.data.list);
            setParentRecordCardData(response.data.data);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, selectedVCData]);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      getVCRecordData();
    }
  }, [visible, getVCRecordData]);

  const onClose = () => {
    if (isSubscribed.current) {
      setValueRecordCardData([]);
      setVisible(false);
    }
  };
  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <Modal
      getContainer={false}
      footer={null}
      onCancel={onClose}
      width={screens.md ? 1200 : '90%'}
      title={`${t('activity.valueCardRecordList')}: ${
        selectedVCData ? selectedVCData.vcId : ''
      }`}
      visible={visible}
    >
      <Spin spinning={loading}>
        <TableToolbar
          leftElement={
            <Button
              icon={<PlusOutlined />}
              disabled={
                !hasPermission(actionPermissions.activityGroup.valueCard)
              }
              onClick={() => {
                setAdjustModalVisible(true);
              }}
            >
              {t('activity.addValueRecordCard.addTitle')}
            </Button>
          }
          fontSize={fontSize}
          setFontSize={setFontSize}
          refresh={() => getVCRecordData()}
          totalItems={total}
          columns={columns}
          columnKeys={columnKeys}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          searchPlaceholder={t('searchPlaceholders.searchValueCardType')}
          rows={valueRecordCardData.map((valueRecordCard) => ({
            ...valueRecordCard,
            adminUser:
              valueRecordCard && valueRecordCard.adminUser
                ? valueRecordCard.adminUser.adminId
                : undefined,
            addVal: valueRecordCard.addVal,
            currentVal: valueRecordCard.currentVal,
            useVal: valueRecordCard.useVal,
          }))}
          exportConfig={{ fileName: 'VALUE_RECORD_CARD' }}
        />
        <Table<ValueRecordCard>
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          dataSource={valueRecordCardData}
          components={vt}
          scroll={{ y: 600 }}
          pagination={{
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
            size: 'small',
            defaultPageSize: pageSize,
            onChange: (page, pSize) => {
              setPage(page);
              setPageSize(pSize || pageSize);
              setSelectedRowKeys([]);
            },
            current: page,
          }}
          rowKey={(vcr) => vcr.vcrId}
        />
        {!!selectedRowKeys.length && (
          <TableFooterToolbar
            selectedRowKeys={selectedRowKeys}
            selectedRows={[]}
            setSelectedRowKeys={setSelectedRowKeys}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            funct={{ exportConfig: { fileName: 'VALUE_RECORD_CARD' } }}
          />
        )}
        <ValueRecordCardModal
          selectedVCData={parentRecordCardData}
          visible={adjustModalVisible}
          setVisible={setAdjustModalVisible}
          refreshVCRecordList={getVCRecordData}
          refreshVCList={refresh}
        />
      </Spin>
    </Modal>
  );
};

export default ValueCardRecordListModal;
