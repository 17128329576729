import {
  BorderlessTableOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  Image,
  Input,
  InputNumber,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import videoLogo from '../../assets/images/video-logo.png';
import { HIGHLIGHT_YELLOW, PURPLE1, RED1, WHITE } from '../../constants/color';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import { FALLBACK_IMG } from '../../constants/styles';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../constants/systemConstants';
import {
  BasicEnumInfoType,
  GalleryMedia,
  GoodData,
  GoodEnums,
  GoodsSkuAttr,
  GoodsSkuProductData,
  GoodsTypeAttributeData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { isVideo } from '../../utils/checkFileType';
import { momentDateTime } from '../../utils/helperFunction';
import PhotoGalleryModal from '../PhotoGalleryModal';
import VideoPreviewModal from '../photoGallery/VideoPreviewModal';
import SellersDropdown from '../sellers/SellersDropdown';
import TransportDropdown from '../settings/common/TransportDropdown';
import BrandDropdown from './common/BrandDropdown';
import CategoryDropdown from './common/CategoryDropdown';
import DepartmentDropdown from './common/DepartmentDropdown';
import SpecTypesDropdown from './common/SpecTypeDropdown';
import SupplierDropdown from './common/SupplierDropdown';
import GoodCategoryModal from './goodDetail/GoodCategoryModal';
import GoodSpecModal from './goodDetail/GoodSpecModal';
import SelectGoodsModal from './selectGoods/SelectGoodsModal';

type GoodModalProps = {
  goodEnums?: GoodEnums;
  isSeller?: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  copyGood?: GoodData;
  setCopyGood?: React.Dispatch<React.SetStateAction<GoodData | undefined>>;
  callBack?: () => void;
};

const GoodModal = ({
  visible,
  setVisible,
  callBack,
  isSeller,
  goodEnums,
  copyGood,
  setCopyGood,
}: GoodModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [showPhotoGallery, setShowPhotoGallery] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState({
    photo: '',
    photoPath: '',
    largePic: '',
    thumbPic: '',
  });
  const [firstLoad, setFirstLoad] = useState(false);
  const [isWeightGood, setIsWeightGood] = useState(false);
  const [isPromote, setIsPromote] = useState(false);
  const [isPurchaseLimit, setIsPurchaseLimit] = useState(false);
  const [memberPriceEmpty, setMemberPriceEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState<any[]>([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [video, setVideo] = useState<GalleryMedia>();
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [shippingMethod, setShippingMethod] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [extendCatList, setExtendCatList] = useState<any[]>([]);
  const [goodDetail, setGoodDetail] = useState<GoodData>();
  const [ruleSign, setRuleSign] = useState<string>();
  const screens = Grid.useBreakpoint();
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const tagColor: { [key: string]: string } = {
    isBest: HIGHLIGHT_YELLOW,
    isHot: RED1,
    isNew: PURPLE1,
  };
  const weekdays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const [specAttr, setSpecAttr] = useState<GoodsTypeAttributeData[]>([]);
  const [showAddAttribute, setShowAddAttribute] = useState<{
    [key: string]: boolean;
  }>({});
  const [attributeInput, setAttributeInput] = useState('');
  const [selectedSpec, setSelectedSpec] = useState(
    new Map<string, { [key: string]: string }[]>()
  );
  const [goodSkuProducts, setGoodSkuProducts] = useState<GoodsSkuProductData[]>(
    []
  );
  const [specNameList, setSpecNameList] = useState<{
    [key: string]: string;
  }>({});
  const [specAttrList, setSpecAttrList] = useState<{
    [key: string]: GoodsSkuAttr;
  }>({});
  const [showGoodSpecModal, setShowGoodSpecModal] = useState(false);
  const [editingAttr, setEditingAttr] = useState<
    GoodsSkuAttr & { specTypeName: string; specTypeId: string }
  >();
  const [goodTags, setGoodTags] = useState<BasicEnumInfoType[]>([]);
  const [mainGoodsId, setMainGoodsId] = useState<GoodData>();
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData>();
  const [selectGoodsVisible, setSelectGoodsVisible] = useState<boolean>(false);

  const getGoodDetail = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/detail', {
      params: { goodsId: copyGood && copyGood.goodsId },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          let goodInfo: GoodData = response.data;
          let tags: { [key: string]: boolean } = {
            isBest: goodInfo.isBest || false,
            isHot: goodInfo.isHot || false,
            isNew: goodInfo.isNew || false,
          };
          let keys = [];
          for (let key of Object.keys(tags)) {
            if (!tags[key]) continue;
            keys.push(key);
          }
          let pics: any[] = [];
          // Uncomment below if copying photos is requested
          // for (let pic of goodInfo.pics) {
          //   if (pic.originalPic === goodInfo.originalPic) continue;
          //   pics.push({
          //     picId: pic.imgId,
          //     originalPic: pic.originalPic,
          //     largePic: pic.largePic,
          //     thumbPic: pic.thumbPic,
          //     originalPicPath: pic.originalPicPath,
          //     largePicPath: pic.largePicPath,
          //     thumbPicPath: pic.thumbPicPath,
          //   });
          // }
          if (isSubscribed.current) {
            setGoodDetail(goodInfo);
            setShippingMethod(goodInfo.shippingMethod);
            setExtendCatList(copyGood ? [] : goodInfo.extendCatList || []);
            setSelectedPhoto({
              photo: goodInfo.originalPic,
              photoPath: goodInfo.thumbPicPath,
              largePic: goodInfo.largePic,
              thumbPic: goodInfo.thumbPic,
            });
            setSelectedPhotos(pics);
            setIsWeightGood(goodInfo.isWeightGoods);
            const {
              goodsSn,
              barcode,
              supplierSku,
              goodsId,
              promote,
              purchaseLimit,
              isMember,
              memberPrice,
              brand,
              department,
              category,
              seller,
              shippingTransport,
              sku,
              thumbPicPath,
              largePicPath,
              originalPicPath,
              extendCatList,
              desktopDesc,
              mobileDesc,
              reviewContent,
              reviewStatus,
              salesVolume,
              salesVolumeBase,
              lastUpdate,
              addTime,
              isOnSale,
              isDelete,
              expireDate,
              estimateShopPrice,
              depositFee,
              recycleFee,
              goodsWeight,
              goodsNumber,
              supplier,
              sellerNote,
              ...rest
            } = goodInfo;
            setFormData({
              ...rest,
              catId: goodInfo.category ? goodInfo.category.allCatIds : [],
              saleDay: goodInfo.saleDay ? goodInfo.saleDay.split(',') : [],
              goodsTag: goodInfo.goodsTag
                ? {
                    label: goodInfo.goodsTag.description,
                    value: goodInfo.goodsTag.code,
                  }
                : undefined,
              eventTags: keys,
              brandId: goodInfo.brand
                ? {
                    value: goodInfo.brand.brandId,
                    label: goodInfo.brand.brandName,
                  }
                : undefined,
              departmentId: goodInfo.department
                ? {
                    value: goodInfo.department.departmentId,
                    label: goodInfo.department.departmentName,
                  }
                : undefined,
              supplierId:
                goodInfo.supplier && goodInfo.supplier.supplierId !== 0
                  ? {
                      value: goodInfo.supplier.supplierId,
                      label: goodInfo.supplier.supplierName,
                    }
                  : undefined,
              shippingTransportId: goodInfo.shippingTransport
                ? {
                    value: goodInfo.shippingTransport.transportId,
                    label: goodInfo.shippingTransport.transportName,
                  }
                : undefined,
              goodsNumberRuleSign:
                goodInfo && goodInfo.goodsNumberRule !== null
                  ? goodInfo.goodsNumberRule.sign
                  : undefined,
            });

            setRuleSign(goodInfo?.goodsNumberRule?.sign);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [copyGood, t]);

  /**
   * When the changes have been made in the SelectedGoodObjs, it will update mainGoodsId. The user only sees mainGoodsId.
   * The merging be done here
   */
  useEffect(() => {
    if (selectedGoodObjs) {
      setMainGoodsId(selectedGoodObjs);
      setSelectedGoodObjs(undefined);
    }
  }, [selectedGoodObjs]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        originalPic: selectedPhoto.photo,
      });
    }
  }, [visible, form, selectedPhoto]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onCancel = () => {
    if (isSubscribed.current) {
      setCategoryOptions([]);
      setRuleSign(undefined);
      setShippingMethod('');
      setCurrentStep(0);
      setIsPromote(false);
      setIsPurchaseLimit(false);
      setMemberPriceEmpty(true);
      setIsWeightGood(false);
      setFormData({});
      setExtendCatList([]);
      setSelectedPhotos([]);
      setMainGoodsId(undefined);
      setSelectedPhoto({
        photo: '',
        photoPath: '',
        largePic: '',
        thumbPic: '',
      });
      setSpecAttr([]);
      setGoodSkuProducts([]);
      setSpecNameList({});
      setSpecAttrList({});
      setSelectedSpec(new Map());
      setVisible(false);
      if (setCopyGood) setCopyGood(undefined);
    }
  };

  useEffect(() => {
    if (copyGood && visible) {
      getGoodDetail();
    }
  }, [copyGood, getGoodDetail, visible]);

  const getSpecTypeAttrs = (value?: number) => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/spec_type/attr/list', {
      params: { goodsSpecTypeId: value },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            let selectedSpecName: { [key: string]: string } = {};
            let selectedSpec = new Map<string, { [key: string]: string }[]>();
            response.data.list.forEach((data: GoodsTypeAttributeData) => {
              selectedSpecName[`${data.specAttrId}`] = data.specAttrName;
              selectedSpec.set(`${data.specAttrId}`, []);
            });
            setSelectedSpec(selectedSpec);
            setSpecNameList((prev) => ({ ...prev, ...selectedSpecName }));
            setSpecAttr(response.data.list);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  const getGoodTags = () => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setGoodTags(response.data.goodsTag);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SortableAttrItem = SortableElement(
    ({ item, specId }: { item: any; specId: string }) => (
      <div style={{ cursor: 'grab', marginRight: 8, zIndex: 10000 }}>
        <Tag style={{ margin: 0 }}>
          <Space>
            {item && !!Object.values(item).length && Object.values(item)[0]}
            <Tooltip title={t('goods.add/editGood.uploadImage')}>
              <Button
                type="text"
                size="small"
                icon={<UploadOutlined />}
                onClick={() => {
                  let keys = Object.keys(item);
                  if (keys.length && keys[0]) {
                    setEditingAttr({
                      ...specAttrList[keys[0]],
                      skuAttrValue: specAttrList[keys[0]]
                        ? specAttrList[keys[0]].skuAttrValue
                        : item[keys[0]],
                      specTypeId: specId,
                      specTypeName: specNameList[specId],
                    });
                  } else {
                    setEditingAttr(undefined);
                  }
                  setShowGoodSpecModal(true);
                }}
              />
            </Tooltip>
          </Space>
        </Tag>
      </div>
    )
  );

  const SortableAttrList = SortableContainer(
    ({ items, specId }: { items: any; specId: string }) => (
      <div style={{ display: 'flex' }}>
        {items.map((item: string, index: number) => (
          <SortableAttrItem
            item={item}
            index={index}
            key={index}
            specId={specId}
          />
        ))}
      </div>
    )
  );

  const onSubmit = () => {
    let {
      goodsNumberRule,
      promoteDate,
      purchaseLimitDate,
      eventTags,
      goodsSpecTypeId,
      isRelatedGoods,
      mainGoods,
      ...rest
    } = formData;
    let modifiedSpecAttr: any = {};
    let attrIndex: any = {};
    selectedSpec.forEach((value, key) => {
      modifiedSpecAttr['p' + key] = value.map((obj, index) => {
        attrIndex['p' + Object.keys(obj)[0]] = { ['p' + key]: index };
        let attrId = Object.keys(obj)[0];
        let modified: any = {
          goodsSkuAttrId: attrId,
          skuAttrValue: obj[attrId],
          attrThumbPic:
            specAttrList[attrId] && specAttrList[attrId].attrThumbPic
              ? specAttrList[attrId].attrThumbPic
              : '',
          attrLargePic:
            specAttrList[attrId] && specAttrList[attrId].attrLargePic
              ? specAttrList[attrId].attrLargePic
              : '',
          attrOriginalPic:
            specAttrList[attrId] && specAttrList[attrId].attrOriginalPic
              ? specAttrList[attrId].attrOriginalPic
              : '',
          isDefaultAttr:
            specAttrList[attrId] && specAttrList[attrId].isDefaultAttr
              ? specAttrList[attrId].isDefaultAttr
              : false,
          sortOrder: index,
        };

        if (isNaN(modified.goodsSkuAttrId)) {
          delete modified.goodsSkuAttrId;
        } else {
          modified.goodsSkuAttrId = parseInt(modified.goodsSkuAttrId);
        }
        return modified;
      });
    });

    let modifiedSkuProduct = goodSkuProducts.map((product) => {
      let aIndex: any = {};
      let { goodsId, goodsSkuAttrIds, ...rest } = product;
      Object.keys(goodsSkuAttrIds).forEach((id) => {
        aIndex = { ...aIndex, ...attrIndex[id] };
      });
      return {
        ...rest,
        attrIndex: aIndex,
        productPrice: rest.productPrice || 0,
        productNumber: rest.productNumber || 0,
        productWarnNumber: rest.productWarnNumber || 1,
        oldProductNumber: rest.productId ? rest.productNumber : undefined,
      };
    });
    let goodsNumberRuleObj = {
      sign:
        formData.goodsNumberRuleSign === undefined
          ? null
          : formData.goodsNumberRuleSign,
      number:
        formData.goodsNumberRuleValue === undefined
          ? null
          : formData.goodsNumberRuleValue,
    };
    let modifiedValues = {
      ...rest,
      goodsNumberRule: goodsNumberRuleObj ? goodsNumberRuleObj : null,
      sellerId: formData.sellerId,
      catId: formData.catId.at(-1),
      extendCatList: extendCatList.map((c) => c.catId),
      saleDay: formData.saleDay ? formData.saleDay.join() : '',
      isBest: formData.eventTags
        ? formData.eventTags.includes('isBest')
        : false,
      isNew: formData.eventTags ? formData.eventTags.includes('isNew') : false,
      isHot: formData.eventTags ? formData.eventTags.includes('isHot') : false,
      brandId: formData.brandId ? formData.brandId.value : undefined,
      isOnSale: formData.isOnSale,
      departmentId: formData.departmentId
        ? formData.departmentId.value
        : undefined,
      supplierId: formData.supplierId ? formData.supplierId.value : 0,
      shippingMethod: formData.shippingMethod,
      shippingTransportId: formData.shippingTransportId
        ? formData.shippingTransportId.value
        : 0,
      originalPic: formData.originalPic,
      largePic: selectedPhoto.largePic,
      thumbPic: selectedPhoto.thumbPic,
      pics: [
        {
          originalPic: selectedPhoto.photo,
          largePic: selectedPhoto.largePic,
          thumbPic: selectedPhoto.thumbPic,
        },
        ...selectedPhotos.map((photo) => ({
          originalPic: photo.originalPic,
          largePic: photo.largePic,
          thumbPic: photo.thumbPic,
        })),
      ],
      promoteStartDate:
        formData.promoteDate && formData.promoteDate[0]
          ? formData.promoteDate[0].format(DATE_TIME_FORMAT)
          : undefined,
      promoteEndDate:
        formData.promoteDate && formData.promoteDate[1]
          ? formData.promoteDate[1].format(DATE_TIME_FORMAT)
          : undefined,
      purchaseLimitStartDate:
        formData.purchaseLimitDate && formData.purchaseLimitDate[0]
          ? formData.purchaseLimitDate[0].format(DATE_TIME_FORMAT)
          : undefined,
      purchaseLimitEndDate:
        formData.purchaseLimitDate && formData.purchaseLimitDate[1]
          ? formData.purchaseLimitDate[1].format(DATE_TIME_FORMAT)
          : undefined,
      mainGoodsId: mainGoodsId ? mainGoodsId.goodsId : undefined,
      sku: {
        goodsSpecTypeId: formData.goodsSpecTypeId && formData.goodsSpecTypeId,
        specTypeAttr: modifiedSpecAttr,
        skuProduct: modifiedSkuProduct,
      },
      goodsNumber: formData.goodsNumber,
      goodsTag: formData.goodsTag ? formData.goodsTag.value : undefined,
      expireDate: momentDateTime(formData.expireDate, DATE_FORMAT, ''),
      depositFee: formData.depositFee || 0,
      recycleFee: formData.recycleFee || 0,
    };

    let filteredValues: any = {};
    Object.keys(modifiedValues).forEach((key) => {
      if (modifiedValues[key] !== undefined) {
        filteredValues[key] = modifiedValues[key];
      }
    });

    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('goods/add', filteredValues)
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.goodsAdded'));
          if (callBack) callBack();
          onCancel();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  };

  const SortableBar = SortableHandle(() => (
    <DragOutlined style={{ cursor: 'grab' }} />
  ));

  const SortableItem = SortableElement(({ photo }: { photo: GalleryMedia }) => (
    <Card
      style={{ zIndex: 10000, width: 110 }}
      hoverable
      bodyStyle={{ padding: 5 }}
      cover={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 5,
          }}
        >
          <Image
            preview={{
              visible: false,
              mask: (
                <Space>
                  <Tooltip
                    title={t('settings.photoGalleryActions.preview')}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode as HTMLElement
                    }
                  >
                    <Button
                      size="small"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        if (isVideo(photo.originalPicPath)) {
                          setVideo(photo);
                          setShowVideoModal(true);
                        } else {
                          setPreviewIndex(
                            selectedPhotos.findIndex(
                              (item) => item.originalPic === photo.originalPic
                            )
                          );
                          setShowPreview(true);
                        }
                      }}
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        left: 6,
                        bottom: 6,
                      }}
                    />
                  </Tooltip>
                </Space>
              ),
            }}
            src={
              isVideo(photo.originalPicPath) ? videoLogo : photo.thumbPicPath
            }
            width="auto"
            height={100}
            fallback={FALLBACK_IMG}
          />
          <Tooltip
            title={t('actionsColumn.delete')}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Button
              size="small"
              icon={<DeleteOutlined style={{ color: RED1 }} />}
              onClick={() => {
                setSelectedPhotos((prev) =>
                  prev.filter((p) => p.picId !== photo.picId)
                );
              }}
              style={{
                position: 'absolute',
                zIndex: 1,
                right: 10,
                bottom: 38,
              }}
            />
          </Tooltip>
        </div>
      }
    >
      <Card.Meta
        title={
          <div style={{ textAlign: 'center' }}>
            <SortableBar />
          </div>
        }
      />
    </Card>
  ));

  const SortableList = SortableContainer(
    ({ items }: { items: GalleryMedia[] }) => (
      <Row gutter={[16, 16]}>
        <Col key="mainImg">
          <Card
            style={{ zIndex: 10000, width: 110 }}
            hoverable
            bodyStyle={{ padding: 5 }}
            cover={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 5,
                }}
              >
                <Image
                  src={selectedPhoto.photoPath}
                  width="auto"
                  height={100}
                  fallback={FALLBACK_IMG}
                />
              </div>
            }
          >
            <Card.Meta
              title={
                <div style={{ textAlign: 'center' }}>
                  {t('goods.add/editGood.mainImage')}
                </div>
              }
            />
          </Card>
        </Col>
        {items.map((photo, index) => (
          <Col key={index}>
            <SortableItem photo={photo} index={index} />
          </Col>
        ))}
        <Col key="addBtn">
          <Button
            onClick={() => {
              setFirstLoad(true);
              setMultiSelect(true);
              setShowPhotoGallery(true);
            }}
            style={{ height: 138, width: 110 }}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>
                {t('settings.photoGalleryActions.selectFile')}
              </div>
            </div>
          </Button>
        </Col>
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup
            preview={{
              visible: showPreview,
              onVisibleChange: (value) => setShowPreview(value),
              current: previewIndex,
            }}
          >
            {items &&
              items.map((photo, index) => (
                <Image src={photo.largePicPath} key={index} />
              ))}
          </Image.PreviewGroup>
        </div>
      </Row>
    )
  );

  /*
   * Cartesian Product (笛卡儿积)
   */
  const cartesianProduct = (arrays: { [key: string]: string }[][]) => {
    let array: any[] = [];
    let filteredArray = arrays.filter((arr) => arr.length > 0);
    if (filteredArray.length === 1) {
      array = filteredArray[0].map((par) => ({
        ['p' + Object.keys(par)[0]]: Object.values(par)[0],
      }));
    } else if (filteredArray.length > 1) {
      array = filteredArray.reduce((lastArray, currentArray) => {
        let a: any[] = [];
        lastArray.forEach((par1) => {
          currentArray.forEach((par2) =>
            a.push({
              ['p' + Object.keys(par1)[0]]: Object.values(par1)[0],
              ['p' + Object.keys(par2)[0]]: Object.values(par2)[0],
            })
          );
        });
        return a;
      });
    }
    return array;
  };

  const columns: ColumnsType<GoodsSkuProductData> = [
    {
      title: t('goods.add/editGood.specTypeAttr'),
      width: 120,
      render: (record: GoodsSkuProductData) =>
        record.goodsSkuAttrIds
          ? Object.values(record.goodsSkuAttrIds).join(', ')
          : '',
    },
    {
      title: (
        <Space>
          {t('goods.goodsListColumns.shopPrice')}
          <Tooltip title="商品价格 + 属性价格 = 最终价格">
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'productPrice',
      width: 120,
      render: (value: number, record: GoodsSkuProductData, index: number) => (
        <InputNumber
          type="number"
          min={0}
          defaultValue={value || 0}
          onChange={(value) => {
            setGoodSkuProducts((prev) => {
              let products = prev.slice();
              products[index] = {
                ...products[index],
                productPrice: value || 0,
              };
              return products;
            });
          }}
        />
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsNumber'),
      dataIndex: 'productNumber',
      width: 120,
      render: (value: number, record: GoodsSkuProductData, index: number) => (
        <InputNumber
          type="number"
          min={0}
          defaultValue={value || 0}
          onChange={(value) => {
            setGoodSkuProducts((prev) => {
              let products = prev.slice();
              products[index] = {
                ...products[index],
                productNumber: value || 0,
              };
              return products;
            });
          }}
        />
      ),
    },
    {
      title: t('goods.add/editGood.warnNumber'),
      dataIndex: 'productWarnNumber',
      width: 120,
      render: (value: number, record: GoodsSkuProductData, index: number) => (
        <InputNumber
          type="number"
          min={0}
          defaultValue={value || 1}
          onChange={(value) => {
            setGoodSkuProducts((prev) => {
              let products = prev.slice();
              products[index] = {
                ...products[index],
                productWarnNumber: value || 1,
              };
              return products;
            });
          }}
        />
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsSn'),
      dataIndex: 'productSn',
      width: 120,
      render: (value: string, record: GoodsSkuProductData, index: number) => (
        <Input
          defaultValue={value}
          onChange={(e) => {
            setGoodSkuProducts((prev) => {
              let products = prev.slice();
              products[index] = {
                ...products[index],
                productSn: e.target.value,
              };
              return products;
            });
          }}
        />
      ),
    },
    {
      title: t('goods.goodsListColumns.barcode'),
      dataIndex: 'barCode',
      width: 120,
      render: (value: string, record: GoodsSkuProductData, index: number) => (
        <Input
          defaultValue={value}
          onChange={(e) => {
            setGoodSkuProducts((prev) => {
              let products = prev.slice();
              products[index] = {
                ...products[index],
                barCode: e.target.value,
              };
              return products;
            });
          }}
        />
      ),
    },
  ];

  const steps = [
    {
      // Seller info
      title: t('goods.add/editGood.stepOne'),
      content: (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ sellerId: isSeller ? undefined : 0 }}
        >
          <Divider orientation="left" style={{ marginTop: 0 }}>
            <Space>
              <BorderlessTableOutlined />
              {t('goods.add/editGood.sellerInfo')}
            </Space>
          </Divider>
          <Form.Item
            label={t('goods.goodsListColumns.seller')}
            name="sellerId"
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <SellersDropdown
              disabled={!isSeller}
              initialValue={formData.sellerId}
              onChange={(value) => {
                if (goodDetail && goodDetail.seller) {
                  if (goodDetail.seller.sellerId === value) {
                    setFormData((prev: any) => ({
                      ...prev,
                      shippingTransportId: goodDetail.shippingTransport
                        ? {
                            value: goodDetail.shippingTransport.transportId,
                            label: goodDetail.shippingTransport.transportName,
                          }
                        : undefined,
                    }));
                  } else {
                    setFormData((prev: any) => ({
                      ...prev,
                      shippingTransportId: undefined,
                    }));
                  }
                }
              }}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      // Good info
      title: t('goods.add/editGood.stepTwo'),
      content: (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            eventTags: [],
            catId: [],
            saleDay: [],
            isOnSale: true,
            isAloneSale: true,
            isPresale: false,
            isPromote: false,
            isReal: true,
            isPurchaseLimit: false,
            isCountForPrint: false,
            isStaffPickupOnly: false,
            isDiscountEnabled: true,
            promoteDate: [moment().startOf('day'), moment().endOf('day')],
            purchaseLimitDate: [moment().startOf('day'), moment().endOf('day')],
            sortOrder: 100,
            warnNumber: 3,
            expireDate: '',
            sellerNote: '',
          }}
        >
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                <Space>
                  <BorderlessTableOutlined />
                  {t('goods.add/editGood.basicInfo')}
                </Space>
              </Divider>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="goodsName"
                label={t('goods.goodsListColumns.goodsName')}
                rules={[
                  { required: true, message: t('general.inputError.empty') },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="goodsSn"
                label={t('goods.goodsListColumns.goodsSn')}
                tooltip={t('goods.add/editGood.toolTip.goodsSn')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="goodsUnit"
                label={t('goods.goodsListColumns.goodsUnit')}
                rules={[
                  { required: true, message: t('general.inputError.empty') },
                ]}
                tooltip={t('goods.add/editGood.toolTip.goodsUnit')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="barcode"
                label={t('goods.goodsListColumns.barcode')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="supplierSku"
                label={t('goods.goodsListColumns.supplierSku')}
              >
                <Input />
              </Form.Item>
            </Col>
            {isSeller && (
              <Col span={24} md={12}>
                <Form.Item
                  name="mainGoodsId"
                  label={t('goods.add/editGood.mainGoodsId')}
                >
                  <Space direction="horizontal">
                    <Typography.Text>{mainGoodsId?.goodsId}</Typography.Text>
                    <Button
                      icon={mainGoodsId ? <EditOutlined /> : <PlusOutlined />}
                      onClick={() => setSelectGoodsVisible((prev) => !prev)}
                      style={{ padding: 0 }}
                      type="text"
                    ></Button>
                    {mainGoodsId ? (
                      <DeleteOutlined
                        style={{ color: RED1 }}
                        onClick={() => {
                          setMainGoodsId(undefined);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
            <Col span={24} md={12}>
              <Form.Item
                name="keywords"
                label={t('goods.goodsListColumns.keywords')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="goodsBrief"
                label={t('goods.goodsListColumns.goodsBrief')}
              >
                <Input.TextArea autoSize={{ minRows: 1 }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="originalPic"
                label={t('goods.add/editGood.goodImage')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Image
                    width={100}
                    src={selectedPhoto ? selectedPhoto.photoPath : ''}
                    preview={{ mask: <EyeOutlined /> }}
                    fallback={FALLBACK_IMG}
                  />
                  <Button
                    size="small"
                    style={{ width: 100, marginTop: 8 }}
                    onClick={() => {
                      setFirstLoad(true);
                      setMultiSelect(false);
                      setShowPhotoGallery(true);
                    }}
                  >
                    {t('goods.add/editCategory.photoGallery')}
                  </Button>
                </div>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('goods.add/editGood.isSync')}
                name="isSync"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                <Space>
                  <BorderlessTableOutlined />
                  <div>
                    {t('goods.goodsListColumns.shopPrice')}/
                    {t('goods.goodsListColumns.goodsNumber')}
                  </div>
                </Space>
              </Divider>
            </Col>
            <Col span={24} sm={12} md={8}>
              <Form.Item
                name="shopPrice"
                label={t('goods.goodsListColumns.shopPrice')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        value &&
                        value.toString().indexOf('.') !== -1 &&
                        value
                          .toString()
                          .substring(value.toString().indexOf('.') + 1).length >
                          2
                      ) {
                        return Promise.reject(
                          new Error(t('users.inputError.twoDecimalsOnly'))
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={8}>
              <Form.Item
                name="gst"
                label="GST/HST"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
                tooltip={t('goods.add/editGood.toolTip.gst')}
              >
                <InputNumber type="number" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={8}>
              <Form.Item
                name="pst"
                label="PST"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                <InputNumber type="number" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={8}>
              <Form.Item
                name="depositFee"
                label={t('goods.goodsListColumns.depositFee')}
              >
                <InputNumber type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={8}>
              <Form.Item
                name="recycleFee"
                label={t('goods.goodsListColumns.recycleFee')}
              >
                <InputNumber type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Space>
                <Form.Item
                  label={t('goods.goodsListColumns.goodsNumberRule')}
                  name={'goodsNumberRuleSign'}
                >
                  <Select
                    style={{ width: 150 }}
                    allowClear
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    showSearch={false}
                    filterOption={false}
                    onChange={(value) => {
                      if (value === undefined) {
                        form.setFieldsValue({
                          goodsNumberRuleValue: undefined,
                        });
                      }
                      setRuleSign(value);
                    }}
                  >
                    {goodEnums &&
                      goodEnums.goodsNumberRuleSign.length > 0 &&
                      goodEnums.goodsNumberRuleSign.map((rule) => (
                        <Select.Option key={rule.code} value={rule.code}>
                          {rule.description}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('goods.goodsListColumns.value')}
                  name={'goodsNumberRuleValue'}
                  rules={[
                    {
                      required: ruleSign !== undefined,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <InputNumber
                    disabled={ruleSign === undefined}
                    style={{ width: 80 }}
                    type="number"
                    min={1}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                name="goodsNumber"
                label={t('goods.goodsListColumns.goodsNumber')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                <InputNumber type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                name="warnNumber"
                label={t('goods.add/editGood.warnNumber')}
              >
                <InputNumber type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.goodsListColumns.isWeightGoods')}
                name="isWeightGoods"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => setIsWeightGood(checked)}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                name={isWeightGood ? 'goodsWeight' : undefined}
                label={t('goods.add/editGood.goodsWeight')}
                rules={[
                  {
                    required: isWeightGood,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
              >
                <InputNumber type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('goods.add/editGood.sellerNote')}
                name="sellerNote"
              >
                <Input.TextArea autoSize={{ minRows: 1 }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                <Space>
                  <BorderlessTableOutlined />
                  {t('goods.add/editGood.classification')}
                </Space>
              </Divider>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('goods.add/editGood.goodsTag')}
                name="goodsTag"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <Select
                  labelInValue
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={t('general.pleaseSelect')}
                  showSearch={false}
                  onFocus={() => {
                    if (!goodTags.length) getGoodTags();
                  }}
                >
                  {goodTags.map((tag) => (
                    <Select.Option key={tag.code} value={tag.code}>
                      {tag.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('goods.goodsListColumns.eventTag')}
                name="eventTags"
              >
                <Select
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={t('general.pleaseSelect')}
                  showSearch={false}
                  tagRender={(props) => (
                    <Tag color={tagColor[props.value]}>
                      {t(`goods.goodsListColumns.${props.value}`)}
                    </Tag>
                  )}
                >
                  <Select.Option key="isBest" value="isBest">
                    {t(`goods.goodsListColumns.isBest`)}
                  </Select.Option>
                  <Select.Option key="isHot" value="isHot">
                    {t(`goods.goodsListColumns.isHot`)}
                  </Select.Option>
                  <Select.Option key="isNew" value="isNew">
                    {t(`goods.goodsListColumns.isNew`)}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={
                  <>
                    {t('goods.goodsListColumns.category')}
                    <Button
                      size="small"
                      type="link"
                      onClick={() => setShowCategoryModal(true)}
                    >
                      {t('goods.add/editGood.extendCatList')}
                    </Button>
                  </>
                }
                name="catId"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <CategoryDropdown
                  categoryOptions={categoryOptions}
                  setCategoryOptions={setCategoryOptions}
                  initialValue={formData.catId}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('goods.goodsListColumns.brand')}
                name="brandId"
              >
                <BrandDropdown
                  labelInValue={true}
                  initialValue={formData.brandId}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('goods.add/editGood.department')}
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <DepartmentDropdown
                  labelInValue={true}
                  initialValue={formData.departmentId}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('goods.add/editSupplier.title')}
                name="supplierId"
              >
                <SupplierDropdown
                  labelInValue={true}
                  initialValue={formData.supplierId}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={t('goods.add/editGood.saleDay')} name="saleDay">
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  mode="multiple"
                  showSearch={false}
                  placeholder={t('general.pleaseSelect')}
                >
                  {weekdays.map((day) => (
                    <Select.Option key={day} value={day}>
                      {t(`goods.add/editGood.${day}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label={t('goods.add/editGood.shippingMethod.title')}
                name="shippingMethod"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={t('general.pleaseSelect')}
                  showSearch={false}
                  onChange={(value) => setShippingMethod(value)}
                >
                  <Select.Option key={1} value="PICKUP">
                    {t('goods.add/editGood.shippingMethod.PICKUP')}
                  </Select.Option>
                  <Select.Option key={2} value="SHIPPING">
                    {t('goods.add/editGood.shippingMethod.SHIPPING')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {shippingMethod === 'SHIPPING' && (
              <Col span={24} md={6}>
                <Form.Item
                  label={t(
                    'goods.add/editGood.shippingMethod.shippingTemplate'
                  )}
                  name="shippingTransportId"
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.pleaseSelectOne'),
                    },
                  ]}
                >
                  <TransportDropdown
                    sellerId={formData.sellerId}
                    labelInValue={true}
                    initialValue={formData.shippingTransportId}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                <Space>
                  <BorderlessTableOutlined />
                  {t('goods.add/editGood.specialInfo')}
                </Space>
              </Divider>
            </Col>
            <Col span={24} sm={isPromote ? 12 : 24} md={isPromote ? 8 : 24}>
              <Form.Item
                label={t('goods.add/editGood.isPromote')}
                name="isPromote"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    setIsPromote(checked);
                  }}
                />
              </Form.Item>
            </Col>
            {isPromote && (
              <Col span={24} sm={12} md={8}>
                <Form.Item
                  name="promotePrice"
                  label={t('goods.add/editGood.promotePrice')}
                  rules={[{ required: true }]}
                >
                  <InputNumber type="number" min={0} />
                </Form.Item>
              </Col>
            )}
            {isPromote && (
              <Col span={24} md={8}>
                <Form.Item
                  name="promoteDate"
                  label={t('goods.add/editGood.promoteDate')}
                  rules={[{ required: true }]}
                >
                  <DatePicker.RangePicker
                    showTime
                    ranges={{
                      [t('goods.add/editGood.today')]: [
                        moment().startOf('day'),
                        moment().endOf('day'),
                      ],
                    }}
                    placeholder={[
                      t('goods.add/editGood.startDate'),
                      t('goods.add/editGood.endDate'),
                    ]}
                  />
                </Form.Item>
              </Col>
            )}
            <Col
              span={24}
              sm={isPurchaseLimit ? 12 : 24}
              md={isPurchaseLimit ? 8 : 24}
            >
              <Form.Item
                label={t('goods.add/editGood.isPurchaseLimit')}
                name="isPurchaseLimit"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(checked) => {
                    setIsPurchaseLimit(checked);
                  }}
                />
              </Form.Item>
            </Col>
            {isPurchaseLimit && (
              <Col span={24} sm={12} md={8}>
                <Form.Item
                  name="purchaseLimitNum"
                  label={t('goods.add/editGood.purchaseLimitNumber')}
                  rules={[{ required: true }]}
                >
                  <InputNumber type="number" min={0} />
                </Form.Item>
              </Col>
            )}
            {isPurchaseLimit && (
              <Col span={24} md={8}>
                <Form.Item
                  name="purchaseLimitDate"
                  label={t('goods.add/editGood.purchaseLimitDate')}
                  rules={[{ required: true }]}
                >
                  <DatePicker.RangePicker
                    showTime
                    ranges={{
                      [t('goods.add/editGood.today')]: [
                        moment().startOf('day'),
                        moment().endOf('day'),
                      ],
                    }}
                    placeholder={[
                      t('goods.add/editGood.startDate'),
                      t('goods.add/editGood.endDate'),
                    ]}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12} sm={12} md={8}>
              <Form.Item
                label={t('goods.goodsListColumns.isMember')}
                name="isMember"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={16}>
              <Form.Item
                name={'memberPrice'}
                label={t('goods.goodsListColumns.memberPrice')}
              >
                <Space>
                  {!memberPriceEmpty && <InputNumber type="number" min={0} />}
                  <Checkbox
                    defaultChecked={memberPriceEmpty}
                    onChange={(e) => {
                      console.log(`checked = ${e.target.checked}`);

                      if (!e.target.checked) {
                        setMemberPriceEmpty(false);
                      } else {
                        setMemberPriceEmpty(true);
                      }
                    }}
                  >
                    {t('goods.add/editGood.memberPriceEmpty')}
                  </Checkbox>
                </Space>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider orientation="left" style={{ marginTop: 0 }}>
                <Space>
                  <BorderlessTableOutlined />
                  {t('goods.add/editGood.otherInfo')}
                </Space>
              </Divider>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.goodsListColumns.isPresale')}
                name="isPresale"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.goodsListColumns.isOnSale')}
                name="isOnSale"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.add/editGood.isAloneSale')}
                name="isAloneSale"
                valuePropName="checked"
                tooltip={t('goods.add/editGood.toolTip.isAloneSale')}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.add/editGood.isCountForPrint')}
                name="isCountForPrint"
                valuePropName="checked"
                tooltip={t('goods.add/editGood.toolTip.isCountForPrint')}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col> */}
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.add/editGood.isStaffPickupOnly')}
                name="isStaffPickupOnly"
                valuePropName="checked"
                tooltip={t('goods.add/editGood.toolTip.isStaffPickupOnly')}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.add/editGood.isDiscountEnabled')}
                name="isDiscountEnabled"
                valuePropName="checked"
                tooltip={t('goods.add/editGood.toolTip.isDiscountEnabled')}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.goodsListColumns.isReal')}
                name="isReal"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.goodsListColumns.sortOrder')}
                name="sortOrder"
              >
                <InputNumber type="number" min={0} max={50000} />
              </Form.Item>
            </Col>
            <Col span={24} sm={12} md={6}>
              <Form.Item
                label={t('goods.goodsListColumns.expireDate')}
                name="expireDate"
              >
                <DatePicker
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      // Good gallery
      title: t('goods.add/editGood.goodsGallery'),
      content: (
        <>
          <Divider orientation="left" style={{ marginTop: 0 }}>
            <Space>
              <BorderlessTableOutlined />
              {t('goods.add/editGood.goodsGallery')}
            </Space>
          </Divider>
          <div style={{ marginBottom: 16 }}>
            {t('goods.add/editGood.toolTip.photoGallery')}
          </div>
          <SortableList
            axis="xy"
            useDragHandle
            items={selectedPhotos}
            onSortEnd={({ oldIndex, newIndex }) => {
              setSelectedPhotos((prev) =>
                arrayMoveImmutable(prev, oldIndex, newIndex)
              );
            }}
          />
        </>
      ),
    },
    {
      // Good attribute
      title: t('goods.add/editGood.attrInfo'),
      content: (
        <Form form={form}>
          <Row gutter={[24, 8]}>
            <Col span={16}>
              <Typography.Text strong style={{ fontSize: 16 }}>
                {t('goods.add/editGood.spec')}
              </Typography.Text>
            </Col>

            <Col span={24}>
              <Form.Item
                name="goodsSpecTypeId"
                label={t('goods.add/editGood.specType')}
                style={{ marginBottom: 0 }}
              >
                <SpecTypesDropdown
                  initialValue={formData.goodsSpecTypeId}
                  onChange={(value) => {
                    setSelectedSpec(new Map());
                    setGoodSkuProducts([]);
                    if (!value) {
                      setSpecAttr([]);
                      return;
                    }
                    getSpecTypeAttrs(value);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={t('goods.add/editGood.specTypeAttr')}
                style={{ marginBottom: 0 }}
              >
                {specAttr.map((item, itemIndex) => (
                  <Row key={itemIndex} style={{ marginTop: 5 }}>
                    <Col span={24}>
                      <Typography.Text>{item.specAttrName}:</Typography.Text>
                    </Col>
                    <Col>
                      <Space wrap>
                        {item.isInputAttrFromList ? (
                          item.inputAttrValues &&
                          !!item.inputAttrValues.length &&
                          item.inputAttrValues.map((value, index) => (
                            <Tag key={index} style={{ margin: 0 }}>
                              <Space>
                                <Checkbox
                                  checked={
                                    selectedSpec.has(`${item.specAttrId}`) &&
                                    (
                                      selectedSpec.get(`${item.specAttrId}`) ||
                                      []
                                    ).find((a) => Object.values(a)[0] === value)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let attr = new Map<
                                      string,
                                      { [key: string]: string }[]
                                    >();
                                    if (e.target.checked) {
                                      let attrId = `${item.specAttrId}_${value}`;
                                      if (item.goodsSkuAttr) {
                                        let foundAttr = item.goodsSkuAttr.find(
                                          (a) => a.skuAttrValue === value
                                        );
                                        if (foundAttr) {
                                          attrId = `${foundAttr.goodsSkuAttrId}`;
                                        }
                                      }

                                      attr = new Map(selectedSpec).set(
                                        `${item.specAttrId}`,
                                        [
                                          ...(selectedSpec.get(
                                            `${item.specAttrId}`
                                          ) || []),
                                          { [attrId]: value },
                                        ]
                                      );
                                    } else {
                                      attr = new Map(selectedSpec).set(
                                        `${item.specAttrId}`,
                                        (
                                          selectedSpec.get(
                                            `${item.specAttrId}`
                                          ) || []
                                        ).filter(
                                          (a) => Object.values(a)[0] !== value
                                        )
                                      );
                                    }

                                    let products = cartesianProduct(
                                      Array.from(attr.values())
                                    ).map((a: any) => ({ goodsSkuAttrIds: a }));
                                    setSelectedSpec(attr);
                                    setGoodSkuProducts(products);
                                  }}
                                />
                                {value}
                              </Space>
                            </Tag>
                          ))
                        ) : (
                          <>
                            {item.goodsSkuAttr &&
                              !!item.goodsSkuAttr.length &&
                              item.goodsSkuAttr.map((goodAttr, index) => (
                                <Tag
                                  style={{ margin: 0 }}
                                  key={index}
                                  visible
                                  onClose={() => {
                                    setSpecAttr((prev) => {
                                      let attrs = prev.slice();
                                      attrs[itemIndex] = {
                                        ...item,
                                        goodsSkuAttr: [
                                          ...item.goodsSkuAttr.slice(0, index),
                                          ...item.goodsSkuAttr.slice(index + 1),
                                        ],
                                      };
                                      return attrs;
                                    });

                                    let selectedAttr = new Map(
                                      selectedSpec
                                    ).set(
                                      `${item.specAttrId}`,
                                      (
                                        selectedSpec.get(
                                          `${item.specAttrId}`
                                        ) || []
                                      ).filter(
                                        (a) =>
                                          Object.keys(a)[0] !==
                                          `${goodAttr.goodsSkuAttrId}`
                                      )
                                    );

                                    let products = cartesianProduct(
                                      Array.from(selectedAttr.values())
                                    ).map((a: any) => ({ goodsSkuAttrIds: a }));
                                    setSelectedSpec(selectedAttr);
                                    setGoodSkuProducts(products);
                                  }}
                                  closable={!item.isInputAttrFromList}
                                >
                                  <Space>
                                    <Checkbox
                                      checked={
                                        selectedSpec.has(
                                          `${item.specAttrId}`
                                        ) &&
                                        (
                                          selectedSpec.get(
                                            `${item.specAttrId}`
                                          ) || []
                                        ).find(
                                          (a) =>
                                            Object.keys(a)[0] ===
                                            `${goodAttr.goodsSkuAttrId}`
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        let attr = new Map<
                                          string,
                                          { [key: string]: string }[]
                                        >();
                                        if (e.target.checked) {
                                          attr = new Map(selectedSpec).set(
                                            `${item.specAttrId}`,
                                            [
                                              ...(selectedSpec.get(
                                                `${item.specAttrId}`
                                              ) || []),
                                              {
                                                [`${goodAttr.goodsSkuAttrId}`]:
                                                  goodAttr.skuAttrValue,
                                              },
                                            ]
                                          );
                                        } else {
                                          attr = new Map(selectedSpec).set(
                                            `${item.specAttrId}`,
                                            (
                                              selectedSpec.get(
                                                `${item.specAttrId}`
                                              ) || []
                                            ).filter(
                                              (a) =>
                                                Object.keys(a)[0] !==
                                                `${goodAttr.goodsSkuAttrId}`
                                            )
                                          );
                                        }

                                        let products = cartesianProduct(
                                          Array.from(attr.values())
                                        ).map((a: any) => ({
                                          goodsSkuAttrIds: a,
                                        }));
                                        setSelectedSpec(attr);
                                        setGoodSkuProducts(products);
                                      }}
                                    />
                                    {goodAttr.skuAttrValue}
                                  </Space>
                                </Tag>
                              ))}
                            <Tag
                              style={{
                                borderStyle: 'dashed',
                                background: WHITE,
                                margin: 0,
                              }}
                              onClick={() =>
                                setShowAddAttribute({
                                  [`${item.specAttrId}`]: true,
                                })
                              }
                            >
                              {showAddAttribute[`${item.specAttrId}`] ? (
                                <Input
                                  size="small"
                                  style={{ width: 60, height: 18 }}
                                  autoFocus
                                  onChange={(e) => {
                                    setAttributeInput(e.target.value);
                                  }}
                                  onBlur={() => {
                                    if (attributeInput) {
                                      setSpecAttr((prev) => {
                                        let attrs = prev.slice();
                                        let goodsSkuAttr: GoodsSkuAttr[] = [];

                                        goodsSkuAttr = [
                                          {
                                            goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                            skuAttrValue: attributeInput,
                                          },
                                        ];
                                        setSpecAttrList((prev) => ({
                                          ...prev,
                                          [`${item.specAttrId}_${attributeInput}`]:
                                            {
                                              goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                              skuAttrValue: attributeInput,
                                            },
                                        }));

                                        attrs[itemIndex] = {
                                          ...item,
                                          goodsSkuAttr: goodsSkuAttr,
                                        };
                                        return attrs;
                                      });
                                    }
                                    setAttributeInput('');
                                    setShowAddAttribute({
                                      [`${item.specAttrId}`]: false,
                                    });
                                  }}
                                />
                              ) : (
                                <Space>
                                  <PlusOutlined />
                                  {t('goods.add/editGood.newAttribute')}
                                </Space>
                              )}
                            </Tag>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Table
                size="small"
                dataSource={goodSkuProducts}
                columns={columns}
                scroll={{ y: 600, x: 1200 }}
                rowKey={(record) =>
                  Object.keys(record.goodsSkuAttrIds).join('|')
                }
                pagination={{
                  pageSize: 10,
                  hideOnSinglePage: true,
                  showTotal: (total, range) =>
                    t('general.paginationTotal', {
                      start: range[0],
                      end: range[1],
                      total: total,
                    }),
                }}
              />
            </Col>
            {!!Array.from(selectedSpec.values()).length &&
              Array.from(selectedSpec.values()).some(
                (value) => value.length
              ) && (
                <Col span={24}>
                  <Form.Item
                    label={t('goods.goodsListColumns.sortOrder')}
                    style={{ marginBottom: 0 }}
                  >
                    <Space direction="vertical" style={{ marginTop: 5 }}>
                      {Array.from(selectedSpec.keys()).map(
                        (specId, index) =>
                          !!(selectedSpec.get(`${specId}`) || []).length && (
                            <Row key={index}>
                              <Col span={24}>
                                <Typography.Text>
                                  {specNameList[`${specId}`]}:
                                </Typography.Text>
                              </Col>
                              <Col span={24}>
                                <SortableAttrList
                                  specId={`${specId}`}
                                  items={selectedSpec.get(`${specId}`) || []}
                                  distance={1}
                                  axis="xy"
                                  onSortEnd={({ oldIndex, newIndex }) => {
                                    setSelectedSpec((prev) => {
                                      let attr = new Map(prev);
                                      if (attr.has(`${specId}`))
                                        attr.set(
                                          `${specId}`,
                                          arrayMoveImmutable(
                                            attr.get(`${specId}`) || [],
                                            oldIndex,
                                            newIndex
                                          )
                                        );
                                      return attr;
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                          )
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              )}
          </Row>
        </Form>
      ),
    },
    {
      // Confirmation
      title: t('goods.add/editGood.finalStep'),
      content: (
        <Spin spinning={isLoading}>
          <Result
            status="success"
            title={t('goods.add/editGood.finish')}
            subTitle={t('goods.add/editGood.addGoodConfirmation')}
          />
        </Spin>
      ),
    },
  ];

  return (
    <Modal
      title={
        isSeller
          ? `${t('goods.add/editGood.addSellerGoodTitle')}`
          : `${t('goods.add/editGood.addTitle')}`
      }
      visible={visible}
      footer={null}
      maskClosable={false}
      width={screens.lg ? 992 : screens.md ? 768 : undefined}
      onCancel={onCancel}
      bodyStyle={{ padding: '24px 12px' }}
    >
      <Steps
        labelPlacement={screens.md ? 'vertical' : undefined}
        responsive={false}
        size="small"
        current={currentStep}
        style={{ marginBottom: 24, padding: '0 12px' }}
      >
        {steps.map((item) => (
          <Steps.Step key={item.title} title={screens.md ? item.title : ''} />
        ))}
      </Steps>
      <div
        style={{
          height: '50vh',
          overflow: 'auto',
          padding: '0 12px',
        }}
      >
        {steps[currentStep].content}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}
      >
        {currentStep > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              setCurrentStep((prev) => prev - 1);
              form.setFieldsValue({ ...formData });
            }}
            disabled={isLoading}
          >
            {t('goods.add/editGood.previous')}
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    ...values,
                  }));
                  setCurrentStep((prev) => prev + 1);
                  form.resetFields();
                  form.setFieldsValue({ ...formData });
                })
                .catch((err) => console.log(err));
            }}
          >
            {t('goods.add/editGood.next')}
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            {t('goods.add/editGood.ok')}
          </Button>
        )}
      </div>
      <PhotoGalleryModal
        visible={showPhotoGallery}
        setVisible={setShowPhotoGallery}
        setValue={multiSelect ? setSelectedPhotos : setSelectedPhoto}
        value={multiSelect ? selectedPhotos : selectedPhoto}
        supportedMediaType={multiSelect ? undefined : MEDIA_TYPE.IMAGE}
        firstLoad={firstLoad}
        isMultiple={multiSelect}
      />
      <VideoPreviewModal
        visible={showVideoModal}
        setVisible={setShowVideoModal}
        videoPath={video?.originalPicPath}
        title={video?.picName}
      />
      <GoodCategoryModal
        visible={showCategoryModal}
        setVisible={setShowCategoryModal}
        categoryList={extendCatList}
        setCategoryList={setExtendCatList}
        allCategories={categoryOptions}
      />
      <GoodSpecModal
        visible={showGoodSpecModal}
        setVisible={setShowGoodSpecModal}
        goodInfo={formData}
        attrInfo={editingAttr}
        updateAttrInfo={(value: {
          [key: string]: GoodsSkuAttr & {
            specTypeName: string;
            specTypeId: string;
          };
        }) => {
          setSpecAttrList((prev) => ({ ...prev, ...value }));
        }}
      />
      {/**Main Goods Modal - This Select Goods component is in Good Modal step 2. When activated from here, the sellerId will be 0 */}
      <SelectGoodsModal
        firstLoad={false}
        sellerId={0}
        newGoodObjs={selectedGoodObjs}
        setNewGoodObjs={setSelectedGoodObjs}
        visible={selectGoodsVisible}
        setVisible={setSelectGoodsVisible}
        multiSelectFeature={false}
      />
    </Modal>
  );
};
export default GoodModal;
