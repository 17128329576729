import {
  Button,
  Col,
  Empty,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import i18n from '../../../i18n';
import { OrderChangeLog, TaskData, TaskLog } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type TaskLogProps = {
  taskInfo?: TaskData;
  callback?: () => void;
};

const TaskLogTable = ({ taskInfo, callback }: TaskLogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetTask = () => {
    setIsLoading(true);
    postDataWithAuthToken('task/reset', {
      taskId: taskInfo ? taskInfo.taskId : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLoading(false);
          alertMessage('success', t('sortationSystem.alerts.taskEdited'));
          callback && callback();
        } else {
          setIsLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const actionNoteColumns: ColumnsType<OrderChangeLog> = [
    {
      title: t('order.orderDetail.propertyName'),
      dataIndex: 'propertyName',
      key: 'propertyName',
      width: 100,
      render: (text: string) =>
        i18n.exists(`order.orderDetail.${text}`)
          ? t(`order.orderDetail.${text}`)
          : text,
    },
    {
      title: t('order.orderDetail.oldValue'),
      dataIndex: 'oldValue',
      key: 'oldValue',
      width: 100,
    },
    {
      title: t('order.orderDetail.newValue'),
      dataIndex: 'newValue',
      key: 'newValue',
      width: 100,
    },
  ];

  const columns: ColumnsType<TaskLog> = [
    {
      title: t('admin.title'),
      key: 'username',
      width: 200,
      render: (record: TaskLog) =>
        record &&
        `${record.username} ${
          record.employeeId
            ? `(ID: ${record.employeeId})`
            : `(ID: ${record.adminId})`
        }`,
    },
    {
      title: t('order.orderDetail.logTime'),
      dataIndex: 'addTime',
      key: 'addTime',
      width: 160,
    },
    {
      title: t('sortationSystem.taskListColumns.taskStatus'),
      dataIndex: 'taskStatus',
      key: 'taskStatus',
      width: 100,
      render: (value) =>
        value && <Tag color={value.tagColor}>{value.description}</Tag>,
    },
    {
      title: t('sortationSystem.taskListColumns.taskStage'),
      dataIndex: 'taskStage',
      key: 'taskStage',
      width: 100,
      render: (value) =>
        value && <Tag color={value.tagColor}>{value.description}</Tag>,
    },
    {
      title: t('sortationSystem.taskListColumns.taskType'),
      dataIndex: 'taskType',
      key: 'taskType',
      width: 100,
      render: (value) => value && value.description,
    },
    {
      title: t('order.orderDetail.remark'),
      key: 'remark',
      dataIndex: 'logInfo',
      width: 250,
      render: (value) => value && value.remark,
    },
    {
      title: t('order.orderDetail.action'),
      key: 'actionNote',
      dataIndex: 'logInfo',
      ellipsis: true,
      render: (value) => value && value.action,
    },
  ];

  return taskInfo ? (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {t('order.orderDetail.actionLog')}
          </Typography.Text>
        </Col>
        {taskInfo.buttonList &&
          Object.values(taskInfo.buttonList).some((value) => value) && (
            <Col span={24}>
              <Space wrap>
                {Object.keys(taskInfo.buttonList).map(
                  (key) =>
                    taskInfo.buttonList[key] && (
                      <Popconfirm
                        key={key}
                        onConfirm={resetTask}
                        title={t(`sortationSystem.alerts.${key}`)}
                        placement="topLeft"
                        disabled={
                          isLoading ||
                          !hasPermission(
                            actionPermissions.sortationGroup.taskManage
                          )
                        }
                      >
                        <Button
                          disabled={
                            isLoading ||
                            !hasPermission(
                              actionPermissions.sortationGroup.taskManage
                            )
                          }
                          size="small"
                          type="primary"
                          key={key}
                          danger
                        >
                          {t(`sortationSystem.taskDetail.buttonList.${key}`)}
                        </Button>
                      </Popconfirm>
                    )
                )}
              </Space>
            </Col>
          )}
        <Col span={24}>
          {taskInfo.taskLogList && (
            <Table
              dataSource={taskInfo.taskLogList}
              columns={columns}
              size="small"
              scroll={{ y: 600, x: 1200 }}
              pagination={{
                pageSize: 10,
                hideOnSinglePage: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
              }}
              rowKey={(record) => record.logId}
              expandable={{
                rowExpandable: (record) =>
                  record.logInfo && record.logInfo.changeList ? true : false,
                expandedRowRender: (record) => (
                  <Table
                    size="small"
                    dataSource={record.logInfo.changeList}
                    columns={actionNoteColumns}
                    pagination={{
                      pageSize: 10,
                      hideOnSinglePage: true,
                      showTotal: (total, range) =>
                        t('general.paginationTotal', {
                          start: range[0],
                          end: range[1],
                          total: total,
                        }),
                    }}
                    rowKey={(record) => record.propertyName}
                    scroll={{ y: 600, x: 1200 }}
                  />
                ),
                columnWidth: 30,
              }}
            />
          )}
        </Col>
      </Row>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('sortationSystem.taskDetail.taskLog')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskLogTable;
