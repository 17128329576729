import React, { useEffect } from 'react';
import FooterOnlyLayout from './layout/FooterOnlyLayout';
import { Form, Input, Button, Checkbox } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { postData } from '../utils/axiosRequest';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LanguageSubMenu from '../components/LanguageSubMenu';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { login, useAuth } from '../utils/createAuthProvider';
import { alertMessage } from '../utils/alertMessage';
import { dashboardRoute } from '../constants/pathname';
import { GENERAL_TIMEOUT } from '../constants/systemConstants';
import getDashboardStyle from '../utils/getDashboardStyle';

/**
 * Login Page
 *
 * /auth/login
 *
 * @param route Route information that includes info like title
 */
const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useDocumentTitle(t('general.login'));
  const auth = useAuth();

  /**
   * @param values.username Username entered
   * @param values.password Password entered
   * @param values.remember TODO: Should the user be remember after session is over
   */
  const loginHandler = (values: {
    username: string;
    password: string;
    remember: boolean;
  }) => {
    postData(
      'auth/login',
      getDashboardStyle().isSalesForceAppType
        ? {
            mobile: values.username,
            password: values.password,
          }
        : {
            username: values.username,
            password: values.password,
          }
    ).then((response) => {
      if (response && response.goodStatus) {
        const accessToken = response.data.accessToken;
        if (accessToken) {
          login(accessToken);
          setTimeout(() => history.push(dashboardRoute.home), GENERAL_TIMEOUT);
        }
      } else {
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    });
  };

  /**
   * @param errorInfo Information on the failed Login
   */
  const loginFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (auth.isLogged) {
      history.push(dashboardRoute.home);
    }
    let state = window.history.state;
    if (state && state.returnCode === 401) {
      alertMessage('error', t('login.alerts.loginRequired'));
    }
  }, [history, auth, t]);

  return (
    <FooterOnlyLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 16px',
          height: 48,
        }}
      >
        <div style={{ flex: 1 }}></div>
        <LanguageSubMenu />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ marginTop: 70 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={getDashboardStyle().icon}
              alt="logo"
              style={{ height: 42, width: 42, marginRight: 20 }}
            />
            <h1 style={{ margin: 0, fontSize: 38, fontWeight: 640 }}>
              {t(getDashboardStyle().title)}
            </h1>
          </div>
          <div style={{ marginTop: 30 }}>
            <Form
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={loginHandler}
              onFinishFailed={loginFailed}
              style={{ width: 320 }}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input
                  placeholder={
                    getDashboardStyle().isSalesForceAppType
                      ? t('users.userListColumns.mobilePhone')
                      : t('login.username')
                  }
                  size="large"
                  prefix={
                    <UserOutlined
                      style={{
                        color: getDashboardStyle().mainColor,
                      }}
                    />
                  }
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('login.password')}
                  size="large"
                  prefix={
                    <LockOutlined
                      style={{
                        color: getDashboardStyle().mainColor,
                      }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  span: 16,
                }}
              >
                <Checkbox>{t('login.rememberMe')}</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block size="large">
                  {t('login.title')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </FooterOnlyLayout>
  );
};

export default Login;
