import { Image, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { TaskGoodData } from '../types';

type ImagesViewerProps = {
  images: any[];
  setImages: React.Dispatch<React.SetStateAction<TaskGoodData | undefined>>;
  visible: boolean;
  loading: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImagesViewer = ({
  images,
  setImages,
  visible,
  loading,
  setVisible,
}: ImagesViewerProps) => {
  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
    setImages(undefined);
  };

  return (
    <Modal
      title={t('general.images')}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Image.PreviewGroup>
          {images.length > 0 ? (
            images.map((image, index) => {
              return (
                <Image
                  style={{ paddingRight: 10 }}
                  key={index}
                  width={200}
                  src={image.imgUrl ? image.imgUrl : image.largePicPath}
                />
              );
            })
          ) : (
            <></>
          )}
        </Image.PreviewGroup>
      </Spin>
    </Modal>
  );
};

export default ImagesViewer;
