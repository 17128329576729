import { Card } from 'antd';
import Container from '../../../components/Container';
import OrderClaimTable from '../../../components/users/userDetail/OrderClaimTable';

const OrderClaimList = () => {
  return (
    <Container>
      <Card>
        <OrderClaimTable />
      </Card>
    </Container>
  );
};

export default OrderClaimList;
