import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Grid,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../../../constants/actionPermissions';
import { addCommas, addCommasPrice } from '../../../utils/helperFunction';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useTab } from '../../../hooks/useTab';
import { UserOrderRankAnalysisDataPoint } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import { hasPermission } from '../../../utils/hasPermission';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import TableToolbar from '../../table/TableToolbar';
const UserOrderRanks = () => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const { addTab } = useTab();
  const { RangePicker } = DatePicker;
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { t } = useTranslation();
  //Data Components
  const [userOrderRankData, setUserOrderRankData] = useState<
    UserOrderRankAnalysisDataPoint[]
  >([]);
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Table Components
  const [sortValue, setSortValue] = useState<'SALES' | 'ORDER_NUM'>('SALES');
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const columnKeys = ['userId|', 'orderNum', 'sales'];

  const columns: ColumnsType<UserOrderRankAnalysisDataPoint> = [
    {
      title: setFont(
        `${t('analysis.userOrderRank.userInfo')}`,
        DEFAULT_FONT_SIZE,
        `${t('analysis.userOrderRank.userId')}`
      ),
      key: 'userId|',
      fixed: screens.lg ? 'left' : undefined,
      width: 200,
      render: (text: string, record: UserOrderRankAnalysisDataPoint) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          disabled={!hasPermission(actionPermissions.userGroup.userView)}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.users.detail}?user_id=${
                record.user ? record.user.userId : ''
              }`
            )
          }
        >
          {record.user ? record.user.userName : ''}
        </Button>
      ),
    },
    {
      title: t('analysis.userOrderRank.orderNum'),
      key: 'orderNum',
      width: 150,
      dataIndex: 'orderNum',
      sorter: (
        a: UserOrderRankAnalysisDataPoint,
        b: UserOrderRankAnalysisDataPoint
      ) =>
        compare(
          Number(a.orderNum) !== undefined ? Number(a.orderNum) : null,
          Number(b.orderNum) !== undefined ? Number(b.orderNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.userOrderRank.sales'),
      key: 'sales',
      dataIndex: 'sales',
      sorter: (
        a: UserOrderRankAnalysisDataPoint,
        b: UserOrderRankAnalysisDataPoint
      ) =>
        compare(
          Number(a.sales) !== undefined ? Number(a.sales) : null,
          Number(b.sales) !== undefined ? Number(b.sales) : null
        ),
      render: (text: string) => addCommasPrice(text),
    },
  ];

  /**
   * @param pagination Current pagination state
   * @param filters    Current filter state
   * @param sorter     Current sorter state
   * @param extra      Current data source and action
   */
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<UserOrderRankAnalysisDataPoint>
      | SorterResult<UserOrderRankAnalysisDataPoint>[],
    extra: {
      currentDataSource: UserOrderRankAnalysisDataPoint[];
      action: 'paginate' | 'sort' | 'filter';
    }
  ) => {
    if (extra.action === 'sort' && !Array.isArray(sorter)) {
      if (sorter.field === 'orderNum') {
        setSortValue('ORDER_NUM');
      } else if (sorter.field === 'sales') {
        setSortValue('SALES');
      }
    }
  };

  /**
   * Grabs all the needed data for User Order Rank
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken('analysis/user/order_rank', {
      params: {
        startDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
            : undefined,
        endDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
            : undefined,

        sortValue: sortValue,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setUserOrderRankData(response.data.list);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, setFourZeroThree, setFiveHundred, form, sortValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={loading}>
          <Form form={form} ref={formRef}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                paddingBottom: 20,
                flexWrap: 'wrap',
              }}
            >
              <Form.Item
                style={{ marginBottom: 12, paddingRight: 10 }}
                name={'date'}
                label={t('analysis.userOrderRank.date')}
                initialValue={[
                  moment().subtract(8, 'days'),
                  moment().subtract(1, 'days'),
                ]}
              >
                <RangePicker
                  format={DATE_FORMAT}
                  placeholder={[
                    t('analysis.userOrderRank.startDate'),
                    t('analysis.userOrderRank.endDate'),
                  ]}
                  ranges={{
                    [`${t('analysis.userOrderRank.month')}`]: [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    [`${t('analysis.userOrderRank.threeMonths')}`]: [
                      moment().startOf('month'),
                      moment().endOf('month').add(3, 'months'),
                    ],
                    [`${t('analysis.userOrderRank.sixMonths')}`]: [
                      moment().startOf('month'),
                      moment().startOf('month').add(6, 'months'),
                    ],
                    [`${t('analysis.userOrderRank.year')}`]: [
                      moment().startOf('year'),
                      moment().endOf('year'),
                    ],
                    [`${t('analysis.userOrderRank.lastYear')}`]: [
                      moment().startOf('year').subtract(1, 'years'),
                      moment().endOf('year').subtract(1, 'years'),
                    ],
                  }}
                  onChange={(value) => {
                    getData();
                  }}
                />
              </Form.Item>
              <Space align="start">
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        if (
                          form.getFieldValue('date') &&
                          form.getFieldValue('date').length === 2
                        ) {
                          getData();
                        }
                      }, GENERAL_TIMEOUT)
                    );
                  }}
                >
                  {t('analysis.orderDistribution.ok')}
                </Button>
                <Button
                  onClick={() => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        form.resetFields();
                        getData();
                      }, GENERAL_TIMEOUT)
                    );
                  }}
                >
                  {t('analysis.orderDistribution.reset')}
                </Button>
              </Space>
              <Tooltip title={t('general.refresh')}>
                <Button
                  type="text"
                  onClick={() => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }}
                  icon={<ReloadOutlined />}
                />
              </Tooltip>
            </div>
          </Form>
          <div style={{ width: '100%' }}>
            <TableToolbar
              columns={columns}
              columnKeys={columnKeys}
              rows={userOrderRankData.map((userRank) => ({
                ...userRank,
                userId: userRank.user ? userRank.user.userId : '',
              }))}
              exportConfig={{
                fileName: 'USER_RANK_ANALYSIS',
              }}
            />
            <Table<UserOrderRankAnalysisDataPoint>
              onChange={onTableChange}
              dataSource={userOrderRankData}
              rowKey={(order) => order.user.userId}
              columns={columns}
              components={vt}
              scroll={{
                y: 500,
                x: 500,
              }}
              loading={loading}
              pagination={false}
              rowSelection={undefined}
            />
          </div>
        </Spin>
      )}
    </>
  );
};

export default UserOrderRanks;
