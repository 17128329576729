import { Form, Input, Modal, Radio, Select, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CouponData, CouponEnum } from '../../types';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { GetCouponFormFields } from './GetCouponFormFields';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_DEFAULT,
  REVIEW_STATUS_FAILED,
} from '../../constants/generalConstants';
import getDashboardStyle from '../../utils/getDashboardStyle';
import SellersDropdown from '../sellers/SellersDropdown';

type CouponModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCoupon?: CouponData;
  couponEnum?: CouponEnum;
  refresh: Function;
  isSeller: boolean;
};

const CouponModal = ({
  visible,
  setVisible,
  selectedCoupon,
  couponEnum,
  refresh,
  isSeller,
}: CouponModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [couponData, setCouponData] = useState<string[]>([]);
  const [reviewStatus, setReviewStatus] = useState<string>();
  const [loading, setLoading] = useState(false);
  //Data components
  const [couponRemark, setCouponRemark] = useState<string>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  //  on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      if (couponEnum && couponEnum.couponType.length > 0 && selectedCoupon) {
        couponEnum.couponType.forEach((type) => {
          if (type.code === selectedCoupon.couponType) {
            let x: string[] = [];
            type.data.forEach((value) => {
              if (
                ![
                  'couponEndTime',
                  'activityEndTime',
                  'couponLowerBound',
                ].includes(value)
              ) {
                x.push(value);
              }
            });
            setCouponData(x);
            setCouponRemark(type.remark);
          }
        });
      }
      if (selectedCoupon?.reviewStatus.code === REVIEW_STATUS_FAILED)
        setReviewStatus(REVIEW_STATUS_FAILED);
    }
  }, [form, visible, couponEnum, selectedCoupon]);

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
      setCouponData([]);
      setReviewStatus(undefined);
      setCouponRemark(undefined);
    }
  };

  /**
   * @param values Form values
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        const {
          couponValidUser,
          couponDesc,
          couponDate,
          activityDate,
          reviewContent,
          sellerId,
          ...rest
        } = values;
        postDataWithAuthToken(selectedCoupon ? 'coupon/edit' : 'coupon/add', {
          ...rest,
          sellerId: !selectedCoupon
            ? isSeller
              ? values.sellerId
              : 0
            : undefined,
          couponId: selectedCoupon ? selectedCoupon.couponId : undefined,
          couponValidUser: values.couponValidUser
            ? values.couponValidUser.join(',')
            : undefined,
          activityStartTime: values.activityDate
            ? moment(values.activityDate[0]).format(DATE_TIME_FORMAT)
            : undefined,
          activityEndTime: values.activityDate
            ? moment(values.activityDate[1]).format(DATE_TIME_FORMAT)
            : undefined,
          couponStartTime: values.couponDate
            ? moment(values.couponDate[0]).format(DATE_TIME_FORMAT)
            : undefined,
          couponEndTime: values.couponDate
            ? moment(values.couponDate[1]).format(DATE_TIME_FORMAT)
            : undefined,
          couponDesc: values.couponDesc ? values.couponDesc : '',
          reviewContent: getDashboardStyle().showReviewStatus
            ? values.reviewStatus &&
              values.reviewStatus === REVIEW_STATUS_FAILED
              ? values.reviewContent
              : ''
            : undefined,
          couponUpperBound:
            values.couponType === 'COUPON_ORDER'
              ? values.couponUpperBound
                ? values.couponUpperBound
                : 0
              : undefined,
          couponUsageLimit: values.couponUsageLimit
            ? values.couponUsageLimit
            : 0,
          couponExpireDay: values.couponExpireDay ? values.couponExpireDay : 0,
        }).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedCoupon
                ? t('activity.alerts.couponEdited')
                : t('activity.alerts.couponAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedCoupon
          ? `${t('activity.add/EditCoupon.editTitle')} ${
              selectedCoupon.couponName
                ? selectedCoupon.couponName
                : selectedCoupon.couponTitle
            }`
          : `${t('activity.add/EditCoupon.addTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={
            selectedCoupon
              ? {
                  ...selectedCoupon,
                  couponValidUser: selectedCoupon.couponValidUser.split(','),
                  reviewStatus: getDashboardStyle().showReviewStatus
                    ? selectedCoupon.reviewStatus.code
                    : undefined,
                }
              : {
                  reviewStatus: getDashboardStyle().showReviewStatus
                    ? REVIEW_STATUS_DEFAULT
                    : undefined,
                  couponValidUser: [],
                }
          }
          onFinish={onFinish}
        >
          {isSeller && !selectedCoupon && (
            <Form.Item
              label={t('activity.add/EditCoupon.seller')}
              name="sellerId"
              rules={[
                {
                  required: isSeller,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <SellersDropdown disabled={!isSeller} />
            </Form.Item>
          )}
          <Form.Item /**For Coupon Name */
            name="couponName"
            label={t('activity.add/EditCoupon.couponName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
          <Form.Item /**For Coupon Title */
            name="couponTitle"
            label={t('activity.add/EditCoupon.couponTitle')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input style={{ width: 200 }} />
          </Form.Item>
          <Form.Item /**For Coupon Type */
            name="couponType"
            label={t('activity.add/EditCoupon.couponType')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
            style={{ marginBottom: couponRemark ? 16 : 24 }}
            extra={couponRemark ? couponRemark : undefined}
          >
            <Select
              style={{ width: 200 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onSelect={(value: string) => {
                setCouponData([]);
                couponEnum &&
                  couponEnum.couponType.forEach((type) => {
                    if (type.code === value) {
                      let x: string[] = [];
                      type.data.forEach((value) => {
                        if (
                          ![
                            'couponEndTime',
                            'activityEndTime',
                            'couponLowerBound',
                          ].includes(value)
                        ) {
                          x.push(value);
                        }
                      });
                      setCouponData(x);
                      setCouponRemark(type.remark);
                    }
                  });
              }}
            >
              {couponEnum &&
                couponEnum.couponType.map((type) => (
                  <Select.Option key={type.description} value={type.code}>
                    {type.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {couponData &&
            couponData.map((data, index) => {
              if (['couponStartTime', 'activityStartTime'].includes(data)) {
                return (
                  <GetCouponFormFields
                    key={index}
                    type="DATE"
                    data={data}
                    selectedCoupon={selectedCoupon}
                  />
                );
              } else if (data === 'couponUpperBound') {
                return (
                  <GetCouponFormFields
                    key={index}
                    type="MAX_MIN"
                    data={data}
                    selectedCoupon={selectedCoupon}
                  />
                );
              } else if (
                ['couponTotalNum', 'couponUserNum', 'couponExpireDay'].includes(
                  data
                )
              ) {
                return (
                  <GetCouponFormFields
                    key={index}
                    type="INTEGER"
                    data={data}
                    selectedCoupon={selectedCoupon}
                  />
                ); //NUM
              } else if (['couponUsageLimit', 'couponMoney'].includes(data)) {
                return (
                  <GetCouponFormFields
                    key={index}
                    type="DECIMAL"
                    data={data}
                    selectedCoupon={selectedCoupon}
                  />
                );
              } else if (data === 'couponValidUser') {
                return (
                  <GetCouponFormFields
                    key={index}
                    type="MULTI_SELECT"
                    data={data}
                    selectedCoupon={selectedCoupon}
                    couponEnum={couponEnum}
                  />
                );
              } else {
                return (
                  <GetCouponFormFields
                    key={index}
                    type="STRING"
                    data={data}
                    selectedCoupon={selectedCoupon}
                  />
                );
              }
            })}
          <Form.Item /**For Coupon Desc */
            name="couponDesc"
            label={t('activity.add/EditCoupon.couponDesc')}
          >
            <TextArea rows={3} />
          </Form.Item>
          {getDashboardStyle().showReviewStatus && (
            <Form.Item
              label={t('activity.add/EditCoupon.reviewStatus')}
              name="reviewStatus"
            >
              <Radio.Group
                onChange={(e) => {
                  form.resetFields(['reviewContent']);
                  setReviewStatus(e.target.value);
                }}
              >
                {Object.keys(REVIEW_STATUS).map((type) => {
                  return (
                    <Radio key={type} value={type}>
                      {t(REVIEW_STATUS[type])}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          )}
          {getDashboardStyle().showReviewStatus &&
            reviewStatus === REVIEW_STATUS_FAILED && (
              <Form.Item
                name="reviewContent"
                label={t('activity.add/EditCoupon.reviewContent')}
                rules={[
                  {
                    required:
                      reviewStatus === REVIEW_STATUS_FAILED ? true : false,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default CouponModal;
