import {
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  DatePicker,
  Form,
  Grid,
  Image,
  Input,
  InputNumber,
  Layout,
  List,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { DataNode } from 'rc-tree-select/lib/interface';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  DragStart,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import PhotoGalleryModal from '../../components/PhotoGalleryModal';
import AdLinkTypeModal from '../../components/advertisement/AdLinkTypeModal';
import AdPageModal from '../../components/advertisement/AdPageModal';
import BrandDropdown from '../../components/goods/common/BrandDropdown';
import CategoryDropdown from '../../components/goods/common/CategoryDropdown';
import SelectGoodsModal from '../../components/goods/selectGoods/SelectGoodsModal';
import RegionTreeSelect from '../../components/settings/common/RegionTreeSelect';
import { PRIMARY, RED1, WHITE } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import { FALLBACK_IMG } from '../../constants/styles';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import {
  addPage,
  removePagePath,
} from '../../features/pageHistory/pageHistorySlice';
import { useTab } from '../../hooks/useTab';
import {
  HomeAdConfig,
  AdData,
  AdEnum,
  HomeAdPositionAdData,
  HomeAdPositionData,
  BasicEnumInfoType,
  GoodData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';

const AdDetail = () => {
  const queryString = window.location.search;
  const [adPageId] = useState(
    new URLSearchParams(queryString).get('ad_page_id')
  );
  const [action] = useState(new URLSearchParams(queryString).get('action'));
  const [isSeller] = useState(
    new URLSearchParams(queryString).get('is_seller')
  );
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [isLeftDrawerLoading, setIsLeftDrawerLoading] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adComponentContainer, setAdComponentContainer] = useState<
    BasicEnumInfoType[]
  >([]);
  const [adMainContainer, setAdMainContainer] = useState<HomeAdPositionData[]>(
    []
  );
  const [adPageDetail, setAdPageDetail] = useState<AdData>();
  const [isLeftDrawerCollapsed, setIsLeftDrawerCollapsed] =
    useState<boolean>(true);
  const [isRightDrawerCollapsed, setIsRightDrawerCollapsed] =
    useState<boolean>(true);
  const screens = Grid.useBreakpoint();
  const dispatch = useAppDispatch();
  const [currentComponentIndex, setCurrentComponentIndex] = useState<number>();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [activeKey, setActiveKey] = useState<string>();
  const [enumList, setEnumList] = useState<AdEnum>();
  const [photoGalleryVisible, setPhotoGalleryVisible] =
    useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [regionOptions, setRegionOptions] = useState<DataNode[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [file, setFile] = useState<{ [key: string]: string }>();
  const [currentAdIndex, setCurrentAdIndex] = useState<number>();
  const [isAdMainContainerChanged, setIsMainContainerChange] =
    useState<boolean>(false);
  const [adPageModalVisible, setAdPageModalVisible] = useState<boolean>(false);
  const [allRegionIds, setAllRegionIds] = useState<string[]>([]);
  const [currentLinkType, setCurrentLinkType] = useState<any>({});
  const [adLinkTypeVisible, setAdLinkTypeVisible] = useState<boolean>(false);
  const [goodsVisible, setGoodsVisible] = useState<boolean>(false);
  const [selectedGood, setSelectedGood] = useState<GoodData>();
  const [filterForm] = Form.useForm();
  const { addTab } = useTab();

  const getEnumList = useCallback(() => {
    setIsLeftDrawerLoading(true);
    getDataWithAuthToken('home_ad/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLeftDrawerLoading(false);
          setEnumList(response.data);
          setAdComponentContainer(response.data.adPositionType);
        } else {
          setIsLeftDrawerLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        setIsLeftDrawerLoading(false);
        console.log(err);
      });
  }, [t]);

  const getAdPageDetail = useCallback(() => {
    setIsLoading(true);
    if (action === 'add') {
      const title =
        isSeller === 'true'
          ? t('advertisement.add/EditAdPage.addTitleSeller')
          : t('advertisement.add/EditAdPage.addTitle');
      document.title = `${title} - ${t(getDashboardStyle().title)}`;
      dispatch(
        addPage({
          title: title,
          path: `${dashboardRoute.advertisement.detail}?action=add&is_seller=${isSeller}`,
        })
      );
      setIsLoading(false);
      setAdPageDetail({
        adPageId: 0,
        adPageName:
          isSeller === 'true'
            ? t('advertisement.adDetail.newAdPageSeller')
            : t('advertisement.adDetail.newAdPage'),
        adPageTitle: '',
        seller: {
          sellerId: 0,
          shopName: t(getDashboardStyle().title),
        },
        adPositionList: [],
      });
    } else if (adPageId) {
      getDataWithAuthToken('home_ad/detail', { params: { adPageId: adPageId } })
        .then((response) => {
          if (response && response.data) {
            document.title = `${t('advertisement.adDetail.adPage')} ${
              response.data.adPageName
            } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
            dispatch(
              addPage({
                title: `${t('advertisement.adDetail.adPage')} ${
                  response.data.adPageName
                }`,
                path: `${dashboardRoute.advertisement.detail}?ad_page_id=${adPageId}`,
              })
            );
            setIsLoading(false);
            setAdPageDetail(response.data);
            setAdMainContainer(response.data.adPositionList || []);
            setIsLeftDrawerCollapsed(true);
            setIsRightDrawerCollapsed(true);
            setCurrentComponentIndex(undefined);
            setIsMainContainerChange(false);
          } else if (response && response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            setIsLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [t, adPageId, action, isSeller, dispatch]);

  const onSave = () => {
    let adPage = { ...adPageDetail };
    adPage.parentId = adPage.parentAdPage ? adPage.parentAdPage.adPageId : 0;
    delete adPage.parentAdPage;
    setIsLoading(true);

    postDataWithAuthToken(
      adPage && adPage.adPageId ? 'home_ad/edit' : 'home_ad/add',
      {
        ...adPage,
        showRegionId: adPage && adPage.showRegionId ? adPage.showRegionId : '',
        adPageId: adPage && adPage.adPageId ? adPage.adPageId : undefined,
        adPositionList: adMainContainer.map((item) => ({
          ...item,
          adPositionStyle: {
            ...item.adPositionStyle,
            threeAdsMethod:
              item.adPositionType === 'THREE_ADS'
                ? (item.adPositionStyle &&
                    item.adPositionStyle.threeAdsMethod) ||
                  'TWO_ONE'
                : undefined,
          },
          adList: item.adList.map((ad) => {
            let newAd = {
              ...ad,
              adConfig: {
                ...ad.adConfig,
                catId: ad.adConfig && ad.adConfig.catId ? ad.adConfig.catId : 0,
                goodsBrandId:
                  ad.adConfig && ad.adConfig.goodsBrandId
                    ? ad.adConfig.goodsBrandId
                    : 0,
                goodsType:
                  ad.adConfig && ad.adConfig.goodsType
                    ? ad.adConfig.goodsType
                    : 'ALL',
                titleColor:
                  ad.adConfig && ad.adConfig.titleColor
                    ? ad.adConfig.titleColor
                    : '',
                goodsItemNumber:
                  ad.adConfig && ad.adConfig.goodsItemNumber
                    ? ad.adConfig.goodsItemNumber
                    : 0,
                brandItemNumber:
                  ad.adConfig && ad.adConfig.brandItemNumber
                    ? ad.adConfig.brandItemNumber
                    : 0,
              },
            };
            if (typeof newAd.adId === 'string') {
              delete newAd.adId;
            }
            delete newAd.adStatus;
            delete newAd.isFiltered;
            return newAd;
          }),
        })),
        seller: undefined,
        sellerId:
          adPage && !adPage.adPageId && adPage.seller
            ? adPage.seller.sellerId
            : undefined,
      }
    )
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLoading(false);
          if (!adPage.adPageId) {
            alertMessage('success', t('advertisement.alerts.adAdded'));
            addTab('advertisement.homeAd', dashboardRoute.advertisement.homeAd);
            dispatch(
              removePagePath(
                `${dashboardRoute.advertisement.detail}?action=add`
              )
            );
          } else {
            getAdPageDetail();
            alertMessage('success', t('advertisement.alerts.adEdited'));
          }
        } else {
          setIsLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((values) => {
        let newList = adMainContainer.map((adSpace) => {
          if (adSpace.adList && adSpace.adList.length) {
            return {
              ...adSpace,
              adList: adSpace.adList.map((ad) => {
                if (
                  values.platform &&
                  ad.platform &&
                  ad.platform !== values.platform
                ) {
                  return { ...ad, isFiltered: true };
                }
                if (
                  values.regions &&
                  values.regions.length &&
                  ad.showRegionId &&
                  ad.showRegionId !== 'all'
                ) {
                  let regions = ad.showRegionId
                    ? ad.showRegionId.split(',')
                    : [];
                  let isFiltered = false;
                  for (let region of values.regions) {
                    if (!regions.includes(String(region))) {
                      isFiltered = true;
                      break;
                    }
                  }
                  if (isFiltered) return { ...ad, isFiltered: true };
                }
                if (
                  values.startTime &&
                  ad.startTime &&
                  ad.endTime &&
                  (values.startTime < moment(ad.startTime) ||
                    values.startTime > moment(ad.endTime))
                ) {
                  return { ...ad, isFiltered: true };
                }
                if (values.isEnabled && !ad.isEnabled) {
                  return { ...ad, isFiltered: true };
                }
                return { ...ad, isFiltered: false };
              }),
            };
          } else {
            return adSpace;
          }
        });
        setAdMainContainer(newList);
      })
      .catch((err) => console.log(err));
  };

  const removeFromList = (list: any[], index: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list: any[], index: number, element: any) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const onDragStart = (initial: DragStart, provided: ResponderProvided) => {
    if (initial.source.droppableId === 'mainContainer') {
      setIsLeftDrawerCollapsed(true);
    } else if (initial.source.droppableId === 'rightContainer') {
      setActiveKey(undefined);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (
      result.source &&
      result.source.droppableId !== result.destination.droppableId
    ) {
      if (result.destination.droppableId === 'mainContainer') {
        setAdMainContainer(
          addToList(adMainContainer, result.destination.index, {
            adPositionName:
              adComponentContainer[result.source.index].description,
            adPositionType: adComponentContainer[result.source.index].code,
            adList: [],
          })
        );
        setIsMainContainerChange(true);
        setIsRightDrawerCollapsed(true);
        setCurrentComponentIndex(result.destination.index);
      }
    } else {
      if (result.destination.droppableId === 'mainContainer') {
        const [removedElement, newSourceList] = removeFromList(
          adMainContainer,
          result.source.index
        );
        setAdMainContainer(
          addToList(newSourceList, result.destination.index, removedElement)
        );
        setIsMainContainerChange(true);
        setIsRightDrawerCollapsed(true);
        setCurrentComponentIndex(result.destination.index);
      }
    }
  };

  const onDragEndRightDrawer = (result: DropResult) => {
    if (!result.destination || currentComponentIndex === undefined) {
      return;
    }

    let source = { ...adMainContainer[currentComponentIndex] };
    const [removedElement, newSourceList] = removeFromList(
      source.adList,
      result.source.index
    );

    let newList = adMainContainer.slice();
    newList[currentComponentIndex].adList = addToList(
      newSourceList,
      result.destination.index,
      removedElement
    );

    setAdMainContainer(newList);
    setIsMainContainerChange(true);
  };

  const renderDefaultAdSpace = (adComp: HomeAdPositionData) => {
    return (
      <Image
        src={
          (enumList &&
            enumList.adPositionType &&
            enumList.adPositionType.length &&
            enumList.adPositionType.findIndex(
              (type) => type.code === adComp.adPositionType
            ) !== -1 &&
            enumList.adPositionType[
              enumList.adPositionType.findIndex(
                (type) => type.code === adComp.adPositionType
              )
            ].defaultImg) ||
          undefined
        }
        fallback={FALLBACK_IMG}
        preview={false}
      />
    );
  };

  const renderAds = (ad: HomeAdPositionAdData, index: number) => {
    let fieldList: string[] = [];
    if (enumList && enumList.adMediaType) {
      let found = enumList.adMediaType.find(
        (type) => type.code === ad.adMediaType
      );
      if (found) {
        fieldList = found.data;
      }
    }

    return fieldList.map((field) => {
      switch (field) {
        case 'file':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <Tooltip
                title={t('goods.add/editGood.edit')}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                  onClick={() => {
                    setCurrentAdIndex(index);
                    setPhotoGalleryVisible(true);
                    setFirstLoad(true);
                  }}
                />
              </Tooltip>
              <Image
                src={(ad.adConfig && ad.adConfig.filePath) || undefined}
                fallback={FALLBACK_IMG}
              />
            </Form.Item>
          );
        case 'showGoodsListTitle':
        case 'isRecommendBrand':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
              valuePropName="checked"
            >
              <Switch
                onChange={(checked) => {
                  if (currentComponentIndex !== undefined) {
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig[
                      field
                    ] = checked;
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
              />
            </Form.Item>
          );
        case 'goodsItemNumber':
        case 'brandItemNumber':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <InputNumber
                type="number"
                min={0}
                precision={0}
                style={{ width: '100%' }}
                onChange={(value) => {
                  if (currentComponentIndex !== undefined) {
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig[
                      field
                    ] = value;
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
              />
            </Form.Item>
          );
        case 'goodsType':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <Select
                onChange={(value) => {
                  if (currentComponentIndex !== undefined) {
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig[
                      field
                    ] = value;
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
              >
                {enumList &&
                  enumList.adGoodsType &&
                  enumList.adGoodsType.map((type) => (
                    <Select.Option key={type.code}>
                      {type.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );
        case 'catId':
        case 'brandCatId':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <CategoryDropdown
                categoryOptions={categoryOptions}
                setCategoryOptions={setCategoryOptions}
                initialValue={
                  field === 'catId'
                    ? ad.adConfig.catId
                    : field === 'brandCatId'
                    ? ad.adConfig.brandCatId
                    : undefined
                }
                onChange={(values, selectedOptions) => {
                  if (currentComponentIndex !== undefined) {
                    const selectedValue =
                      selectedOptions && selectedOptions.length
                        ? selectedOptions.at(-1)
                        : undefined;
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig[
                      field
                    ] = selectedValue ? selectedValue.value : 0;
                    if (field === 'catId') {
                      // update goodsList titleName
                      form.setFieldsValue({
                        [`${
                          newList[currentComponentIndex].adList[index].adId ||
                          `p${currentAdIndex}`
                        }|titleName`]: selectedValue
                          ? selectedValue.label
                          : undefined,
                      });
                      newList[currentComponentIndex].adList[
                        index
                      ].adConfig.titleName = selectedValue
                        ? selectedValue.label
                        : undefined;
                    }
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
              />
            </Form.Item>
          );
        case 'goodsBrandId':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <BrandDropdown
                initialValue={
                  ad.adConfig && ad.adConfig.goodsBrandId
                    ? ad.adConfig.goodsBrandId
                    : 0
                }
                onChange={(value) => {
                  if (currentComponentIndex !== undefined) {
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig[
                      field
                    ] = value;
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
              />
            </Form.Item>
          );
        case 'link':
          return (
            <div key={`${ad.adId || `p${index}`}|${field}`}>
              <Form.Item
                label={t(`advertisement.adDetail.${field}`)}
                name={`${ad.adId || `p${index}`}|${field}`}
                style={{ marginBottom: 10 }}
              >
                <Input
                  onChange={(e) => {
                    if (currentComponentIndex !== undefined) {
                      let newList = adMainContainer.slice();
                      newList[currentComponentIndex].adList[index].adConfig = {
                        ...newList[currentComponentIndex].adList[index]
                          .adConfig,
                        link: e.target.value,
                      };
                      setAdMainContainer(newList);
                      setIsMainContainerChange(true);
                    }
                  }}
                />
              </Form.Item>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder={t('general.pleaseSelect')}
                style={{ width: '100%' }}
                onSelect={(value: string, option: any) => {
                  if (option.key === 'GOODS') {
                    setCurrentAdIndex(index);
                    setGoodsVisible(true);
                  } else if (
                    option.key === 'TOPIC' ||
                    option.key === 'CATEGORY' ||
                    option.key === 'BRAND' ||
                    option.key === 'ARTICLE'
                  ) {
                    setCurrentAdIndex(index);
                    setCurrentLinkType(option);
                    setAdLinkTypeVisible(true);
                  } else {
                    if (currentComponentIndex !== undefined) {
                      let newList = adMainContainer.slice();
                      newList[currentComponentIndex].adList[index].adConfig = {
                        ...newList[currentComponentIndex].adList[index]
                          .adConfig,
                        link: value,
                      };
                      form.setFieldsValue({
                        [`${
                          newList[currentComponentIndex].adList[index].adId ||
                          `p${index}`
                        }|link`]: value,
                      });
                      setAdMainContainer(newList);
                      setIsMainContainerChange(true);
                    }
                  }
                }}
              >
                {enumList &&
                  enumList.adLinkType &&
                  enumList.adLinkType.map((type) => (
                    <Select.Option key={type.code} value={type.data}>
                      {type.description}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          );
        case 'titleColor':
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <Input
                onChange={(e) => {
                  if (currentComponentIndex !== undefined) {
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig = {
                      ...newList[currentComponentIndex].adList[index].adConfig,
                      [field]: e.target.value,
                    };
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
                placeholder="#XXXXXX"
                suffix={
                  <Avatar
                    shape="square"
                    size={20}
                    style={{
                      backgroundColor:
                        form.getFieldValue(
                          `${ad.adId || `p${index}`}|${field}`
                        ) || WHITE,
                    }}
                  />
                }
              />
            </Form.Item>
          );
        default:
          return (
            <Form.Item
              label={t(`advertisement.adDetail.${field}`)}
              name={`${ad.adId || `p${index}`}|${field}`}
              key={`${ad.adId || `p${index}`}|${field}`}
            >
              <Input
                onChange={(e) => {
                  if (currentComponentIndex !== undefined) {
                    let newList = adMainContainer.slice();
                    newList[currentComponentIndex].adList[index].adConfig = {
                      ...newList[currentComponentIndex].adList[index].adConfig,
                      [field]: e.target.value,
                    };
                    setAdMainContainer(newList);
                    setIsMainContainerChange(true);
                  }
                }}
              />
            </Form.Item>
          );
      }
    });
  };

  const renderAdSpaces = (adComp: HomeAdPositionData) => {
    switch (adComp.adPositionType) {
      case 'HOME_BANNER':
        return adComp.adList &&
          !!adComp.adList.filter((ad) => !ad.isFiltered).length ? (
          <Carousel autoplay dots={false}>
            {adComp.adList
              .filter((ad) => !ad.isFiltered)
              .map((ad, index) => (
                <Image
                  key={index}
                  src={(ad.adConfig && ad.adConfig.filePath) || ''}
                  fallback={FALLBACK_IMG}
                />
              ))}
          </Carousel>
        ) : (
          renderDefaultAdSpace(adComp)
        );
      case 'NAV':
        return adComp.adList &&
          !!adComp.adList.filter((ad) => !ad.isFiltered).length ? (
          <Row gutter={[8, 8]}>
            {adComp.adList
              .filter((ad) => !ad.isFiltered)
              .map((ad, index) => (
                <Col key={index}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar src={ad.adConfig && ad.adConfig.filePath} />
                    <Typography.Text style={{ fontSize: 12 }}>
                      {ad.adConfig && ad.adConfig.titleName}
                    </Typography.Text>
                  </div>
                </Col>
              ))}
          </Row>
        ) : (
          renderDefaultAdSpace(adComp)
        );
      case 'POPUP':
      case 'ONE_ADS':
        return adComp.adList &&
          !!adComp.adList.filter((ad) => !ad.isFiltered).length ? (
          <Image
            src={
              (adComp.adList.filter((ad) => !ad.isFiltered)[0].adConfig &&
                adComp.adList.filter((ad) => !ad.isFiltered)[0].adConfig
                  .filePath) ||
              ''
            }
            fallback={FALLBACK_IMG}
          />
        ) : (
          renderDefaultAdSpace(adComp)
        );
      case 'TWO_ADS':
        return adComp.adList &&
          !!adComp.adList.filter((ad) => !ad.isFiltered).length ? (
          <Row gutter={[8, 8]}>
            {adComp.adList
              .filter((ad) => !ad.isFiltered)
              .map(
                (ad, index) =>
                  index <= 1 && (
                    <Col span={12} key={index}>
                      <Image
                        src={(ad.adConfig && ad.adConfig.filePath) || ''}
                        fallback={FALLBACK_IMG}
                      />
                    </Col>
                  )
              )}
          </Row>
        ) : (
          renderDefaultAdSpace(adComp)
        );
      case 'THREE_ADS':
        return adComp.adList &&
          !!adComp.adList.filter((ad) => !ad.isFiltered).length ? (
          adComp.adPositionStyle &&
          adComp.adPositionStyle.threeAdsMethod === 'TWO_ONE' ? (
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Row gutter={[8, 8]}>
                  {adComp.adList
                    .filter((ad) => !ad.isFiltered)
                    .map(
                      (ad, index) =>
                        index <= 1 && (
                          <Col span={24} key={index}>
                            <Image
                              src={(ad.adConfig && ad.adConfig.filePath) || ''}
                              fallback={FALLBACK_IMG}
                            />
                          </Col>
                        )
                    )}
                </Row>
              </Col>
              <Col span={12}>
                {adComp.adList.filter((ad) => !ad.isFiltered).length >= 3 && (
                  <Image
                    src={
                      (adComp.adList.filter((ad) => !ad.isFiltered)[2]
                        .adConfig &&
                        adComp.adList.filter((ad) => !ad.isFiltered)[2].adConfig
                          .filePath) ||
                      ''
                    }
                    fallback={FALLBACK_IMG}
                  />
                )}
              </Col>
            </Row>
          ) : adComp.adPositionStyle &&
            adComp.adPositionStyle.threeAdsMethod === 'ONE_TWO' ? (
            <Row gutter={[8, 8]}>
              <Col span={12}>
                {!!adComp.adList.filter((ad) => !ad.isFiltered).length && (
                  <Image
                    src={
                      (adComp.adList.filter((ad) => !ad.isFiltered)[0]
                        .adConfig &&
                        adComp.adList.filter((ad) => !ad.isFiltered)[0].adConfig
                          .filePath) ||
                      ''
                    }
                    fallback={FALLBACK_IMG}
                  />
                )}
              </Col>
              <Col span={12}>
                <Row gutter={[8, 8]}>
                  {adComp.adList
                    .filter((ad) => !ad.isFiltered)
                    .map(
                      (ad, index) =>
                        index >= 1 &&
                        index <= 2 && (
                          <Col span={24} key={index}>
                            <Image
                              src={(ad.adConfig && ad.adConfig.filePath) || ''}
                              fallback={FALLBACK_IMG}
                            />
                          </Col>
                        )
                    )}
                </Row>
              </Col>
            </Row>
          ) : (
            <Row gutter={[8, 8]}>
              {adComp.adList
                .filter((ad) => !ad.isFiltered)
                .map(
                  (ad, index) =>
                    index <= 2 && (
                      <Col span={8} key={index}>
                        <Image
                          src={(ad.adConfig && ad.adConfig.filePath) || ''}
                          fallback={FALLBACK_IMG}
                        />
                      </Col>
                    )
                )}
            </Row>
          )
        ) : (
          renderDefaultAdSpace(adComp)
        );
      default:
        return renderDefaultAdSpace(adComp);
    }
  };

  useEffect(() => {
    if (
      currentComponentIndex !== undefined &&
      currentAdIndex !== undefined &&
      file
    ) {
      let newList = adMainContainer.slice();
      newList[currentComponentIndex].adList[currentAdIndex].adConfig.file =
        file.photo;
      newList[currentComponentIndex].adList[currentAdIndex].adConfig.filePath =
        file.photoPath;
      setAdMainContainer(newList);
      setIsMainContainerChange(true);
      setFile(undefined);
      setCurrentAdIndex(undefined);
    }
  }, [file, currentComponentIndex, currentAdIndex, adMainContainer]);

  useEffect(() => {
    if (
      currentComponentIndex !== undefined &&
      currentAdIndex !== undefined &&
      selectedGood
    ) {
      let newList = adMainContainer.slice();
      newList[currentComponentIndex].adList[currentAdIndex].adConfig = {
        ...newList[currentComponentIndex].adList[currentAdIndex].adConfig,
        link: 'goods?id=' + selectedGood.goodsId,
      };
      form.setFieldsValue({
        [`${
          newList[currentComponentIndex].adList[currentAdIndex].adId ||
          `p${currentAdIndex}`
        }|link`]: 'goods?id=' + selectedGood.goodsId,
      });
      setAdMainContainer(newList);
      setIsMainContainerChange(true);
      setSelectedGood(undefined);
      setCurrentLinkType(undefined);
    }
  }, [
    selectedGood,
    currentComponentIndex,
    currentAdIndex,
    adMainContainer,
    form,
  ]);

  useEffect(() => {
    getAdPageDetail();
    getEnumList();
  }, [getAdPageDetail, getEnumList]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space align="start" wrap>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {adPageDetail && adPageDetail.adPageName}
            </Typography.Title>
            <Tooltip title={t('advertisement.adDetail.adPageConfig')}>
              <Button
                type="text"
                size="large"
                icon={<EditOutlined />}
                onClick={() => {
                  setAdPageModalVisible(true);
                }}
              />
            </Tooltip>
          </Space>
          <Spin spinning={isLoading}>
            <Row gutter={[8, 12]} style={{ marginBottom: 12 }}>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {/** Left Drawer Button */}
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setIsLeftDrawerCollapsed(!isLeftDrawerCollapsed);
                  }}
                >
                  {t('advertisement.adDetail.addAdSpaceTitle')}
                </Button>
                {/** Save & Restore Buttons */}
                <Space>
                  <Button type="primary" onClick={onSave}>
                    {t('advertisement.adDetail.save')}
                  </Button>
                  {adPageDetail && !!adPageDetail.adPageId && (
                    <Button
                      disabled={!isAdMainContainerChanged}
                      onClick={() => {
                        filterForm.resetFields();
                        getAdPageDetail();
                      }}
                    >
                      {t('advertisement.adDetail.restore')}
                    </Button>
                  )}
                </Space>
              </Col>
              {/** Ad Filter */}
              <Col span={24}>
                <Form
                  layout="vertical"
                  form={filterForm}
                  initialValues={{ platform: null }}
                >
                  <Row gutter={[16, 12]}>
                    <Col span={24} md={12} xl={6}>
                      <Form.Item
                        label={t('advertisement.adDetail.platform')}
                        style={{ marginBottom: 0 }}
                        name="platform"
                      >
                        <Select onChange={(value) => {}}>
                          <Select.Option value={null}>
                            {t('goods.goodsListColumns.all')}
                          </Select.Option>
                          {enumList &&
                            enumList.platform &&
                            enumList.platform.map((platform) => (
                              <Select.Option
                                key={platform.code}
                                value={platform.code}
                              >
                                {platform.description}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12} xl={6}>
                      <Form.Item
                        label={t('advertisement.adDetail.showRegionId')}
                        style={{ marginBottom: 0 }}
                        name="regions"
                      >
                        <RegionTreeSelect
                          parentRegionOptions={regionOptions}
                          setParentRegionOptions={setRegionOptions}
                          allRegionIds={allRegionIds}
                          setAllRegionIds={setAllRegionIds}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12} xl={6}>
                      <Form.Item
                        label={t('goods.add/editGood.startDate')}
                        style={{ marginBottom: 0 }}
                        name="startTime"
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          showTime
                          placeholder={t('goods.add/editGood.startDate')}
                          onChange={(values) => {}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} md={6} xl={3}>
                      <Form.Item
                        label={t('advertisement.adDetail.showEnabledOny')}
                        style={{ marginBottom: 0 }}
                        name="isEnabled"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      md={6}
                      xl={3}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Space>
                        <Button type="primary" onClick={onFilter}>
                          {t('advertisement.adDetail.filter')}
                        </Button>
                        <Button
                          onClick={() => {
                            filterForm.resetFields();
                            onFilter();
                          }}
                        >
                          {t('advertisement.adDetail.reset')}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Layout style={{ border: '1px #F1F1F1 solid', height: '70vh' }}>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                {/** Left Drawer */}
                <Layout.Sider
                  theme="light"
                  style={{
                    overflow: 'overlay',
                    display: isLeftDrawerCollapsed ? 'none' : undefined,
                    zIndex: 3,
                  }}
                  width={250}
                  collapsible
                  collapsed={isLeftDrawerCollapsed}
                  trigger={null}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 16,
                    }}
                  >
                    {t('advertisement.adDetail.addAdSpaceTitle')}
                    <CloseOutlined
                      onClick={() => setIsLeftDrawerCollapsed(true)}
                    />
                  </div>
                  <Spin spinning={isLeftDrawerLoading}>
                    <Droppable droppableId="leftDrawer">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <List
                            style={{ padding: '0 16px' }}
                            itemLayout="vertical"
                          >
                            {adComponentContainer.map((adComp, index) => (
                              <Draggable
                                draggableId={`leftDrawer_${adComp.code}_${index}`}
                                index={index}
                                key={`leftDrawer_${adComp.code}_${index}`}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <List.Item>
                                      <Card
                                        hoverable
                                        size="small"
                                        title={adComp.description}
                                        cover={
                                          <Image
                                            src={adComp.defaultImg}
                                            preview={false}
                                            fallback={FALLBACK_IMG}
                                          />
                                        }
                                      />
                                    </List.Item>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </List>
                        </div>
                      )}
                    </Droppable>
                  </Spin>
                </Layout.Sider>
                {/** Main Container */}
                <Layout.Content
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 16,
                  }}
                >
                  <Droppable droppableId="mainContainer">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <List
                          bordered
                          style={{
                            minWidth: screens.xs ? '100%' : 350,
                            minHeight: 350,
                            padding: '12px 0',
                            overflow: 'auto',
                            height: '100%',
                            background: WHITE,
                          }}
                          itemLayout="vertical"
                        >
                          {adMainContainer.map(
                            (adComp, index) =>
                              adComp.adList &&
                              (!adComp.adList.length ||
                                (!!adComp.adList.length &&
                                  !adComp.adList.every(
                                    (ad) => ad.isFiltered === true
                                  ))) && (
                                <Draggable
                                  key={`mainContainer_${adComp.adPositionName}_${index}`}
                                  draggableId={`mainContainer_${adComp.adPositionName}_${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <List.Item>
                                        <Card
                                          hoverable
                                          size="small"
                                          title={adComp.adPositionName}
                                          actions={[
                                            <EditOutlined
                                              onClick={() => {
                                                setActiveKey(undefined);
                                                setCurrentComponentIndex(index);
                                                setIsRightDrawerCollapsed(
                                                  false
                                                );
                                                form.resetFields();
                                                formRef.current &&
                                                  form.setFieldsValue({
                                                    adPositionName:
                                                      adComp.adPositionName,
                                                    ...adComp.adPositionStyle,
                                                    threeAdsMethod:
                                                      (adComp.adPositionStyle &&
                                                        adComp.adPositionStyle
                                                          .threeAdsMethod) ||
                                                      'TWO_ONE',
                                                  });
                                                formRef.current &&
                                                  adComp.adList.forEach(
                                                    (ad) => {
                                                      const {
                                                        adMediaType,
                                                        adStatus,
                                                        adId,
                                                        startTime,
                                                        endTime,
                                                        ...rest
                                                      } = ad;
                                                      Object.keys(rest).forEach(
                                                        (key) => {
                                                          if (
                                                            key === 'adConfig'
                                                          ) {
                                                            Object.keys(
                                                              rest[key]
                                                            ).forEach((key) => {
                                                              if (
                                                                key ===
                                                                  'catId' ||
                                                                key ===
                                                                  'brandCatId'
                                                              ) {
                                                                form.setFieldsValue(
                                                                  {
                                                                    [`${ad.adId}|${key}`]:
                                                                      ad
                                                                        .adConfig[
                                                                        key
                                                                      ]
                                                                        ? [
                                                                            Number(
                                                                              ad
                                                                                .adConfig[
                                                                                key
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        : [],
                                                                  }
                                                                );
                                                              } else if (
                                                                key ===
                                                                'goodsBrandId'
                                                              ) {
                                                                form.setFieldsValue(
                                                                  {
                                                                    [`${ad.adId}|${key}`]:
                                                                      ad
                                                                        .adConfig[
                                                                        key
                                                                      ]
                                                                        ? Number(
                                                                            ad
                                                                              .adConfig[
                                                                              key
                                                                            ]
                                                                          )
                                                                        : 0,
                                                                  }
                                                                );
                                                              } else if (
                                                                key ===
                                                                'goodsType'
                                                              ) {
                                                                form.setFieldsValue(
                                                                  {
                                                                    [`${ad.adId}|${key}`]:
                                                                      ad
                                                                        .adConfig[
                                                                        key
                                                                      ]
                                                                        ? ad
                                                                            .adConfig[
                                                                            key
                                                                          ]
                                                                        : 'ALL',
                                                                  }
                                                                );
                                                              } else {
                                                                form.setFieldsValue(
                                                                  {
                                                                    [`${ad.adId}|${key}`]:
                                                                      ad
                                                                        .adConfig[
                                                                        key as keyof HomeAdConfig
                                                                      ],
                                                                  }
                                                                );
                                                              }
                                                            });
                                                          } else if (
                                                            key ===
                                                            'showRegionId'
                                                          ) {
                                                            form.setFieldsValue(
                                                              {
                                                                [`${ad.adId}|${key}`]:
                                                                  ad.showRegionId &&
                                                                  regionOptions.length
                                                                    ? ad.showRegionId ===
                                                                      'all'
                                                                      ? allRegionIds
                                                                      : ad.showRegionId.split(
                                                                          ','
                                                                        )
                                                                    : [],
                                                              }
                                                            );
                                                          } else {
                                                            form.setFieldsValue(
                                                              {
                                                                [`${ad.adId}|${key}`]:
                                                                  ad[
                                                                    key as keyof HomeAdPositionAdData
                                                                  ],
                                                              }
                                                            );
                                                          }

                                                          if (
                                                            startTime &&
                                                            endTime
                                                          ) {
                                                            form.setFieldsValue(
                                                              {
                                                                [`${ad.adId}|date`]:
                                                                  [
                                                                    moment(
                                                                      startTime
                                                                    ),
                                                                    moment(
                                                                      endTime
                                                                    ),
                                                                  ],
                                                              }
                                                            );
                                                          }
                                                        }
                                                      );
                                                    }
                                                  );
                                              }}
                                            />,
                                            <DragOutlined
                                              {...provided.dragHandleProps}
                                            />,
                                            <Popconfirm
                                              getPopupContainer={(
                                                triggerNode
                                              ) =>
                                                triggerNode.parentNode as HTMLElement
                                              }
                                              title={t(
                                                'actionsColumn.deleteWarning'
                                              )}
                                              onConfirm={() => {
                                                setIsRightDrawerCollapsed(true);
                                                setCurrentComponentIndex(
                                                  undefined
                                                );
                                                const newSourceList =
                                                  Array.from(adMainContainer);
                                                newSourceList.splice(index, 1);
                                                setAdMainContainer(
                                                  newSourceList
                                                );
                                                setIsMainContainerChange(true);
                                              }}
                                              okText={t(
                                                'actionsColumn.confirmation.yes'
                                              )}
                                              cancelText={t(
                                                'actionsColumn.confirmation.no'
                                              )}
                                              placement="left"
                                            >
                                              <DeleteOutlined
                                                style={{ color: RED1 }}
                                              />
                                            </Popconfirm>,
                                          ]}
                                          style={{
                                            border:
                                              currentComponentIndex === index
                                                ? `solid ${PRIMARY} 2px`
                                                : '',
                                            maxWidth: screens.sm ? 300 : 190,
                                          }}
                                          bodyStyle={{ textAlign: 'center' }}
                                        >
                                          {renderAdSpaces(adComp)}
                                        </Card>
                                      </List.Item>
                                    </div>
                                  )}
                                </Draggable>
                              )
                          )}
                          {provided.placeholder}
                        </List>
                      </div>
                    )}
                  </Droppable>
                </Layout.Content>
              </DragDropContext>
              <DragDropContext
                onDragEnd={onDragEndRightDrawer}
                onDragStart={onDragStart}
              >
                {/** Right Drawer */}
                <Layout.Sider
                  theme="light"
                  style={{
                    overflow: 'overlay',
                    display: isRightDrawerCollapsed ? 'none' : undefined,
                    zIndex: 3,
                  }}
                  width={screens.md ? 400 : 300}
                  collapsible
                  collapsed={isRightDrawerCollapsed}
                  trigger={null}
                  onCollapse={() => {
                    setCurrentComponentIndex(undefined);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 16,
                    }}
                  >
                    {t('advertisement.adDetail.editAdSpaceTitle')}
                    <CloseOutlined
                      onClick={() => {
                        setCurrentComponentIndex(undefined);
                        setIsRightDrawerCollapsed(true);
                      }}
                    />
                  </div>
                  <Form
                    ref={formRef}
                    layout="vertical"
                    form={form}
                    style={{ padding: 16 }}
                  >
                    <Tabs>
                      <Tabs.TabPane
                        tab={t('advertisement.adDetail.adSpaceConfig')}
                        key="1"
                      >
                        <Form.Item
                          label={t('advertisement.adDetail.adSpaceId')}
                        >
                          <Typography.Text>
                            {currentComponentIndex !== undefined &&
                              adMainContainer[currentComponentIndex] &&
                              adMainContainer[currentComponentIndex]
                                .adPositionId}
                          </Typography.Text>
                        </Form.Item>
                        <Form.Item
                          label={t('advertisement.adDetail.adSpaceType')}
                        >
                          <Typography.Text>
                            {currentComponentIndex !== undefined &&
                              enumList &&
                              enumList.adPositionType &&
                              enumList.adPositionType.findIndex(
                                (type) =>
                                  type.code ===
                                  adMainContainer[currentComponentIndex]
                                    .adPositionType
                              ) !== -1 &&
                              enumList.adPositionType[
                                enumList.adPositionType.findIndex(
                                  (type) =>
                                    type.code ===
                                    adMainContainer[currentComponentIndex]
                                      .adPositionType
                                )
                              ].description}
                          </Typography.Text>
                        </Form.Item>
                        <Form.Item
                          label={t('advertisement.adDetail.adPositionName')}
                          name="adPositionName"
                        >
                          <Input
                            onChange={(e) => {
                              if (currentComponentIndex !== undefined) {
                                let newList = adMainContainer.slice();
                                newList[currentComponentIndex].adPositionName =
                                  e.target.value;
                                setAdMainContainer(newList);
                                setIsMainContainerChange(true);
                              }
                            }}
                          />
                        </Form.Item>
                        {currentComponentIndex !== undefined &&
                          adMainContainer[currentComponentIndex] &&
                          adMainContainer[currentComponentIndex]
                            .adPositionType === 'THREE_ADS' && (
                            <Form.Item
                              label={t('advertisement.adDetail.threeAdsMethod')}
                              name="threeAdsMethod"
                            >
                              <Select
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                onChange={(value) => {
                                  if (currentComponentIndex !== undefined) {
                                    let newList = adMainContainer.slice();
                                    newList[
                                      currentComponentIndex
                                    ].adPositionStyle = {
                                      ...newList[currentComponentIndex]
                                        .adPositionStyle,
                                      threeAdsMethod: value,
                                    };
                                    setAdMainContainer(newList);
                                    setIsMainContainerChange(true);
                                  }
                                }}
                              >
                                {enumList &&
                                  enumList.threeAdsMethod &&
                                  enumList.threeAdsMethod.map((option) => (
                                    <Select.Option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          )}
                        <Form.Item
                          label={t('advertisement.adDetail.bottomMargin')}
                          name="bottomMargin"
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            style={{ width: '100%' }}
                            onChange={(value) => {
                              if (currentComponentIndex !== undefined) {
                                let newList = adMainContainer.slice();
                                newList[
                                  currentComponentIndex
                                ].adPositionStyle.bottomMargin = value || 0;
                                setAdMainContainer(newList);
                                setIsMainContainerChange(true);
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t('advertisement.adDetail.lrMargin')}
                          name="lrMargin"
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            style={{ width: '100%' }}
                            onChange={(value) => {
                              if (currentComponentIndex !== undefined) {
                                let newList = adMainContainer.slice();
                                newList[
                                  currentComponentIndex
                                ].adPositionStyle.lrMargin = value || 0;
                                setAdMainContainer(newList);
                                setIsMainContainerChange(true);
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t('advertisement.adDetail.backgroundColor')}
                          name="backgroundColor"
                        >
                          <Input
                            suffix={
                              <Avatar
                                shape="square"
                                size={20}
                                style={{
                                  backgroundColor:
                                    currentComponentIndex !== undefined &&
                                    adMainContainer[currentComponentIndex] &&
                                    adMainContainer[currentComponentIndex]
                                      .adPositionStyle
                                      ? adMainContainer[currentComponentIndex]
                                          .adPositionStyle.backgroundColor ||
                                        WHITE
                                      : WHITE,
                                }}
                              />
                            }
                            onChange={(e) => {
                              if (currentComponentIndex !== undefined) {
                                let newList = adMainContainer.slice();
                                newList[currentComponentIndex] = {
                                  ...newList[currentComponentIndex],
                                  adPositionStyle: {
                                    ...newList[currentComponentIndex]
                                      .adPositionStyle,
                                    backgroundColor: e.target.value,
                                  },
                                };
                                setAdMainContainer(newList);
                                setIsMainContainerChange(true);
                              }
                            }}
                            placeholder="#XXXXXX"
                          />
                        </Form.Item>
                        <Form.Item
                          label={t('advertisement.adDetail.height')}
                          name="height"
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            style={{ width: '100%' }}
                            onChange={(value) => {
                              if (currentComponentIndex !== undefined) {
                                let newList = adMainContainer.slice();
                                newList[
                                  currentComponentIndex
                                ].adPositionStyle.height = value || 0;
                                setAdMainContainer(newList);
                                setIsMainContainerChange(true);
                              }
                            }}
                          />
                        </Form.Item>
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={t('advertisement.adDetail.adConfig')}
                        key="2"
                      >
                        <Button
                          type="dashed"
                          block
                          icon={<PlusOutlined />}
                          style={{ marginBottom: 10, height: 46 }}
                          onClick={() => {
                            if (currentComponentIndex !== undefined) {
                              let newList = adMainContainer.slice();

                              let currentTime =
                                moment().format(DATE_TIME_FORMAT);
                              let mediaType =
                                enumList &&
                                enumList.adPositionType &&
                                enumList.adPositionType.find(
                                  (type) =>
                                    type.code ===
                                    newList[currentComponentIndex]
                                      .adPositionType
                                );
                              newList[currentComponentIndex].adList.splice(
                                0,
                                0,
                                {
                                  adId: currentTime,
                                  adName: 'New',
                                  adMediaType:
                                    mediaType && mediaType.adMediaType
                                      ? mediaType.adMediaType
                                      : null,
                                  platform: null,
                                  showRegionId: null,
                                  adConfig: {
                                    titleName: '',
                                    file: '',
                                    filePath: '',
                                    link: '',
                                    catId: null,
                                    goodsType: null,
                                    titleColor: null,
                                    showGoodsListTitle: false,
                                    goodsItemNumber: null,
                                    goodsBrandId: null,
                                    isRecommendBrand: false,
                                    brandItemNumber: null,
                                    brandCatId: null,
                                  },
                                  startTime: '',
                                  endTime: '',
                                  isEnabled: false,
                                  isFiltered: false,
                                }
                              );
                              setAdMainContainer(newList);
                              setIsMainContainerChange(true);
                              setActiveKey('0');
                            }
                          }}
                        />
                        <Droppable droppableId="rightContainer">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {currentComponentIndex !== undefined &&
                                adMainContainer[currentComponentIndex] &&
                                !!adMainContainer[currentComponentIndex].adList
                                  .length &&
                                adMainContainer[
                                  currentComponentIndex
                                ].adList.map((ad, index) => (
                                  <Draggable
                                    key={`rightContainer_${index}`}
                                    draggableId={`rightContainer_${index}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <Collapse
                                          accordion
                                          expandIconPosition="end"
                                          activeKey={activeKey}
                                          onChange={(key) => {
                                            if (typeof key === 'string') {
                                              setActiveKey(key);
                                            } else if (Array.isArray(key)) {
                                              setActiveKey(key[0]);
                                            } else {
                                              setActiveKey(undefined);
                                            }
                                          }}
                                        >
                                          <Collapse.Panel
                                            header={
                                              <Space>
                                                <DragOutlined
                                                  {...provided.dragHandleProps}
                                                />
                                                {ad.adName}
                                              </Space>
                                            }
                                            key={index.toString()}
                                            style={{
                                              marginBottom: 10,
                                            }}
                                            extra={
                                              <Space>
                                                {!ad.isFiltered && (
                                                  <EyeOutlined />
                                                )}
                                                <Popconfirm
                                                  getPopupContainer={(
                                                    triggerNode
                                                  ) =>
                                                    triggerNode.parentNode as HTMLElement
                                                  }
                                                  title={t(
                                                    'actionsColumn.deleteWarning'
                                                  )}
                                                  onConfirm={() => {
                                                    let source = {
                                                      ...adMainContainer[
                                                        currentComponentIndex
                                                      ],
                                                    };
                                                    const newSourceList =
                                                      Array.from(source.adList);
                                                    newSourceList.splice(
                                                      index,
                                                      1
                                                    );
                                                    let newList =
                                                      adMainContainer.slice();
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList = newSourceList;
                                                    setAdMainContainer(newList);
                                                    setIsMainContainerChange(
                                                      true
                                                    );
                                                  }}
                                                  okText={t(
                                                    'actionsColumn.confirmation.yes'
                                                  )}
                                                  cancelText={t(
                                                    'actionsColumn.confirmation.no'
                                                  )}
                                                  placement="left"
                                                >
                                                  <DeleteOutlined
                                                    style={{
                                                      color: RED1,
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                    }}
                                                  />
                                                </Popconfirm>
                                              </Space>
                                            }
                                          >
                                            <Form.Item
                                              label={t(
                                                'advertisement.adDetail.adId'
                                              )}
                                            >
                                              <Typography.Text>
                                                {ad.adId}
                                              </Typography.Text>
                                            </Form.Item>
                                            <Form.Item
                                              label={t(
                                                'advertisement.adDetail.adName'
                                              )}
                                              name={`${ad.adId}|adName`}
                                              rules={[{ required: true }]}
                                            >
                                              <Input
                                                onChange={(e) => {
                                                  if (
                                                    currentComponentIndex !==
                                                    undefined
                                                  ) {
                                                    let newList =
                                                      adMainContainer.slice();
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList[index].adName =
                                                      e.target.value;
                                                    setAdMainContainer(newList);
                                                    setIsMainContainerChange(
                                                      true
                                                    );
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              label={t(
                                                'advertisement.adDetail.showRegionId'
                                              )}
                                              name={`${ad.adId}|showRegionId`}
                                            >
                                              <RegionTreeSelect
                                                parentRegionOptions={
                                                  regionOptions
                                                }
                                                setParentRegionOptions={
                                                  setRegionOptions
                                                }
                                                allRegionIds={allRegionIds}
                                                setAllRegionIds={
                                                  setAllRegionIds
                                                }
                                                initialValue={ad.showRegionId}
                                                onChange={(values) => {
                                                  if (
                                                    currentComponentIndex !==
                                                    undefined
                                                  ) {
                                                    let newList =
                                                      adMainContainer.slice();
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList[
                                                      index
                                                    ].showRegionId =
                                                      values.length
                                                        ? values.length ===
                                                          allRegionIds.length
                                                          ? 'all'
                                                          : values.join(',')
                                                        : '';
                                                    setAdMainContainer(newList);
                                                    setIsMainContainerChange(
                                                      true
                                                    );
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              label={t(
                                                'advertisement.adDetail.platform'
                                              )}
                                              name={`${ad.adId}|platform`}
                                            >
                                              <Select
                                                onChange={(value) => {
                                                  if (
                                                    currentComponentIndex !==
                                                    undefined
                                                  ) {
                                                    let newList =
                                                      adMainContainer.slice();
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList[index].platform =
                                                      value;
                                                    setAdMainContainer(newList);
                                                    setIsMainContainerChange(
                                                      true
                                                    );
                                                  }
                                                }}
                                              >
                                                <Select.Option value={null}>
                                                  {t(
                                                    'goods.goodsListColumns.all'
                                                  )}
                                                </Select.Option>
                                                {enumList &&
                                                  enumList.platform &&
                                                  enumList.platform.map(
                                                    (platform) => (
                                                      <Select.Option
                                                        key={platform.code}
                                                        value={platform.code}
                                                      >
                                                        {platform.description}
                                                      </Select.Option>
                                                    )
                                                  )}
                                              </Select>
                                            </Form.Item>
                                            <Form.Item
                                              label={t(
                                                'advertisement.adDetail.date'
                                              )}
                                              name={`${ad.adId}|date`}
                                              rules={[{ required: true }]}
                                            >
                                              <DatePicker.RangePicker
                                                showTime
                                                ranges={{
                                                  [t(
                                                    'goods.add/editGood.today'
                                                  )]: [
                                                    moment().startOf('day'),
                                                    moment().endOf('day'),
                                                  ],
                                                }}
                                                placeholder={[
                                                  t(
                                                    'goods.add/editGood.startDate'
                                                  ),
                                                  t(
                                                    'goods.add/editGood.endDate'
                                                  ),
                                                ]}
                                                onChange={(values) => {
                                                  if (
                                                    currentComponentIndex !==
                                                      undefined &&
                                                    values &&
                                                    values.length
                                                  ) {
                                                    let newList =
                                                      adMainContainer.slice();
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList[index].startTime =
                                                      moment(values[0]).format(
                                                        DATE_TIME_FORMAT
                                                      );
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList[index].endTime =
                                                      moment(values[1]).format(
                                                        DATE_TIME_FORMAT
                                                      );
                                                    setAdMainContainer(newList);
                                                    setIsMainContainerChange(
                                                      true
                                                    );
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              label={t(
                                                'advertisement.adDetail.isEnabled'
                                              )}
                                              name={`${ad.adId}|isEnabled`}
                                              valuePropName="checked"
                                            >
                                              <Switch
                                                onChange={(checked) => {
                                                  if (
                                                    currentComponentIndex !==
                                                    undefined
                                                  ) {
                                                    let newList =
                                                      adMainContainer.slice();
                                                    newList[
                                                      currentComponentIndex
                                                    ].adList[index].isEnabled =
                                                      checked;
                                                    setAdMainContainer(newList);
                                                    setIsMainContainerChange(
                                                      true
                                                    );
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                            {renderAds(ad, index)}
                                          </Collapse.Panel>
                                        </Collapse>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </Tabs.TabPane>
                    </Tabs>
                  </Form>
                </Layout.Sider>
              </DragDropContext>
            </Layout>
          </Spin>
        </Card>
      )}
      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={photoGalleryVisible}
        setVisible={setPhotoGalleryVisible}
        value={file}
        setValue={setFile}
      />
      <AdPageModal
        visible={adPageModalVisible}
        setVisible={setAdPageModalVisible}
        isChanged={isAdMainContainerChanged}
        adPage={
          adPageDetail && { ...adPageDetail, adPositionList: adMainContainer }
        }
        enums={enumList}
        regions={regionOptions}
        allRegionIds={allRegionIds}
        isSeller={isSeller === 'true'}
        callBack={
          adPageDetail && adPageDetail.adPageId
            ? getAdPageDetail
            : (detail?: AdData) => setAdPageDetail(detail)
        }
      />
      <AdLinkTypeModal
        visible={adLinkTypeVisible}
        setVisible={setAdLinkTypeVisible}
        linkType={currentLinkType}
        categories={categoryOptions}
        callBack={(value) => {
          if (
            currentComponentIndex !== undefined &&
            currentAdIndex !== undefined
          ) {
            let newList = adMainContainer.slice();
            newList[currentComponentIndex].adList[currentAdIndex].adConfig = {
              ...newList[currentComponentIndex].adList[currentAdIndex].adConfig,
              link: value,
            };
            form.setFieldsValue({
              [`${
                newList[currentComponentIndex].adList[currentAdIndex].adId ||
                `p${currentAdIndex}`
              }|link`]: value,
            });
            setAdMainContainer(newList);
            setIsMainContainerChange(true);
            setCurrentAdIndex(undefined);
            setCurrentLinkType(undefined);
          }
        }}
      />
      <SelectGoodsModal
        visible={goodsVisible}
        setVisible={setGoodsVisible}
        setNewGoodObjs={setSelectedGood}
        multiSelectFeature={false}
        firstLoad={firstLoad}
        sellerId={
          adPageDetail && adPageDetail.seller ? adPageDetail.seller.sellerId : 0
        }
      />
    </Container>
  );
};

export default AdDetail;
