import React, { useState } from 'react';
import { Input, Space, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type TableSearchProps = {
  placeholder?: string;
  searchFunction: (keyword: string) => void;
  size?: 'small' | 'large';
  mobile?: boolean;
};

/**
 * Handles Search for tables
 *
 * @param placeholder    Text that will appear in the input box if its empty
 * @param searchFunction Function that will be called with the keyword as an argument
 * @param size           Determines size of the search box
 * @param mobile         Should show mobile version
 */
const TableSearch = ({
  placeholder,
  searchFunction,
  size,
  mobile,
}: TableSearchProps) => {
  const [keyword, setKeyword] = useState('');

  const handleSearch = () => {
    searchFunction(keyword);
  };

  return (
    <Space wrap>
      <Tooltip trigger={['hover']} title={placeholder} placement="topLeft">
        <Input
          style={{ width: size === 'large' ? 250 : 180 }}
          prefix={<SearchOutlined />}
          placeholder={placeholder}
          onChange={(e) => {
            e.target.value === '' && searchFunction('');
            setKeyword(e.target.value);
          }}
          allowClear
          onPressEnter={handleSearch}
          value={keyword}
        />
      </Tooltip>
    </Space>
  );
};

export default TableSearch;
