import { VIDEO_TYPE } from '../constants/mediaConstants';

/**
 * @param filename Name of file
 */
export const isVideo = (filename: string) => {
  const extension = filename.slice(filename.lastIndexOf('.'));
  if (extension) {
    return VIDEO_TYPE.indexOf(extension.toLowerCase()) !== -1;
  }
  return false;
};
