export const rootRoute = { path: '/' };

export const authRoute = {
  path: '/auth',
  login: '/auth/login',
};

export const dashboardRoute = {
  path: '/dashboard',
  home: '/dashboard/home',
  users: {
    path: '/dashboard/users',
    list: '/dashboard/users/list',
    idList: '/dashboard/users/id',
    log: '/dashboard/users/log',
    rank: '/dashboard/users/rank',
    device: '/dashboard/users/device',
    address: '/dashboard/users/address',
    detail: '/dashboard/users/detail',
  },
  salesperson: {
    path: '/dashboard/salesperson',
    list: '/dashboard/salesperson/list',
  },
  admins: {
    path: '/dashboard/admin',
    list: '/dashboard/admin/list',
    log: '/dashboard/admin/log',
    role: '/dashboard/admin/role',
  },
  goods: {
    path: '/dashboard/goods',
    settings: '/dashboard/goods/settings',
    log: '/dashboard/goods/log',
    logSidebar: '/dashboard/goods/logs', //This is for Goods Logs in the sidebar
    categories: '/dashboard/goods/categories',
    supplierList: '/dashboard/goods/supplier',
    brands: '/dashboard/goods/brands',
    departments: '/dashboard/goods/departments',
    commentsSidebar: '/dashboard/goods/comments',
    comments: '/dashboard/goods/comments',
    list: '/dashboard/goods/list',
    mainGoodsList: '/dashboard/goods/main_goods',
    detail: '/dashboard/goods/detail',
    inventoryLogsSidebar: '/dashboard/goods/inventory_logs', //This is for Inventory Logs in the sidebar
    inventoryLogsGoodsList: '/dashboard/goods/inventory_log', //This is for Inventory Logs in the Good List Actions Column
    goodsType: '/dashboard/goods/goodsType',
    mismatch: '/dashboard/goods/mismatch',
  },
  activity: {
    path: '/dashboard/activity',
    favActivityList: '/dashboard/activity/favourable_activity_list',
    couponList: '/dashboard/activity/coupon_list',
    userCouponList: '/dashboard/activity/user_coupon',
    valueCardTypeList: '/dashboard/activity/value_card_type_list',
    valueCardList: '/dashboard/activity/value_card_list',
    userValueCardList: '/dashboard/activity/user_value_card_list',
  },
  advertisement: {
    path: '/dashboard/advertisement',
    article: {
      path: '/dashboard/advertisement/article',
      articleList: '/dashboard/advertisement/article/article_list',
      articleCatList: '/dashboard/advertisement/article/article_category_list',
    },
    homeAd: '/dashboard/advertisement/home_ad',
    detail: '/dashboard/advertisement/detail',
    adPosition: {
      path: '/dashboard/advertisement/ad_position',
      ad: '/dashboard/advertisement/ad',
    },
  },
  sellers: {
    path: '/dashboard/sellers',
    settings: '/dashboard/sellers/settings',
    grades: '/dashboard/sellers/grades',
    list: '/dashboard/sellers/list',
    detail: '/dashboard/sellers/detail',
    admins: '/dashboard/sellers/admins',
  },
  sortationSystem: {
    path: '/dashboard/sortationSystem',
    employee: '/dashboard/sortationSystem/employee',
    employeeLogs: '/dashboard/sortationSystem/employee_logs',
    tasks: '/dashboard/sortationSystem/tasks',
    taskOrder: '/dashboard/sortationSystem/task', // task based on order
    taskDetail: '/dashboard/sortationSystem/task_detail',
  },
  order: {
    path: '/dashboard/order',
    list: '/dashboard/order/list',
    detail: '/dashboard/order/detail',
    orderClaimList: '/dashboard/order/orderClaim/list',
    orderClaimDetail: '/dashboard/order/orderClaim/detail',
  },
  analysis: {
    path: '/dashboard/analysis',
    user: '/dashboard/analysis/user',
    sales: '/dashboard/analysis/sales',
    order: '/dashboard/analysis/order',
    goods: '/dashboard/analysis/goods',
    orderClaim: '/dashboard/analysis/order_claim',
    employee: '/dashboard/analysis/employee',
    task: '/dashboard/analysis/task',
  },
  plugins: {
    path: '/dashboard/plugins',
    sms: {
      path: '/dashboard/plugins/sms',
      settings: '/dashboard/plugins/sms/settings',
      smsTemp: '/dashboard/plugins/sms/sms_template',
      smsLogs: '/dashboard/plugins/sms/sms_log',
    },
    email: {
      path: '/dashboard/plugins/email',
      settings: '/dashboard/plugins/email/settings',
    },
    s3: '/dashboard/plugins/s3',
  },
  settings: {
    path: '/dashboard/settings',
    basic: '/dashboard/settings/basic',
    region: '/dashboard/settings/region',
    gallery: '/dashboard/settings/gallery',
    fileManager: '/dashboard/settings/file_manager',
    shipping: {
      path: '/dashboard/settings/shipping',
      shipList: '/dashboard/settings/shipping/list',
      transportList: '/dashboard/settings/shipping/transport_list',
      pickupList: '/dashboard/settings/shipping/pickup_list',
    },
    formField: '/dashboard/settings/form_field',
    payment: '/dashboard/settings/payment',
    openApi: '/dashboard/settings/open_api',
  },
  profile: '/dashboard/profile',
  cache: '/dashboard/cache',
};
