import { Form, Input, InputNumber, Radio } from 'antd';
import { TPLConfigObj } from '../../../types';

const GetTPLConfigFormFields: (
  configField: TPLConfigObj,
  tplInfoConfigureList?: any
) => any = (configField, tplInfoConfigureList) => {
  switch (configField.type) {
    case 'DECIMAL':
      return (
        <Form.Item
          key={configField.code}
          name={configField.code}
          label={configField.description}
          rules={[
            {
              required: configField.isRequired,
              message: 'Please give an input',
            },
          ]}
          initialValue={
            tplInfoConfigureList
              ? tplInfoConfigureList[configField.code]
              : undefined
          }
          help={configField.remark ? configField.remark : undefined}
          style={{ marginBottom: configField.remark ? 16 : 24 }}
        >
          {<InputNumber stringMode min="0" step="0.01" />}
        </Form.Item>
      );
    case 'SELECT':
      return (
        <Form.Item
          key={configField.code}
          name={configField.code}
          label={configField.description}
          rules={[
            { required: configField.isRequired, message: 'Please select one' },
          ]}
          initialValue={
            tplInfoConfigureList
              ? tplInfoConfigureList[configField.code]
              : undefined
          }
          help={configField.remark ? configField.remark : undefined}
          style={{ marginBottom: configField.remark ? 16 : 24 }}
        >
          {
            <Radio.Group>
              {Object.keys(configField.storeRange).map((type) => {
                return (
                  <Radio key={type} value={type}>
                    {
                      configField.storeRange[
                        type as unknown as keyof typeof configField.storeRange
                      ]
                    }
                  </Radio>
                );
              })}
            </Radio.Group>
          }
        </Form.Item>
      );
    case 'INTEGER':
      return (
        <Form.Item
          key={configField.code}
          name={configField.code}
          label={configField.description}
          rules={[
            {
              required: configField.isRequired,
              message: 'Please give an input',
            },
          ]}
          initialValue={
            tplInfoConfigureList
              ? tplInfoConfigureList[configField.code]
              : undefined
          }
          help={configField.remark ? configField.remark : undefined}
          style={{ marginBottom: configField.remark ? 16 : 24 }}
        >
          {<Input type={'number'} min={0} />}
        </Form.Item>
      );
    case 'TEXT':
      return (
        <Form.Item
          name={configField.code}
          key={configField.code}
          label={configField.description}
          rules={[
            {
              required: configField.isRequired,
              message: 'Please give an input',
            },
          ]}
          initialValue={
            tplInfoConfigureList
              ? tplInfoConfigureList[configField.code]
              : undefined
          }
          help={configField.remark ? configField.remark : undefined}
          style={{ marginBottom: configField.remark ? 16 : 24 }}
        >
          {<Input />}
        </Form.Item>
      );
    default:
      return '';
  }
};

export { GetTPLConfigFormFields };
