import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { TaskData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type TaskOtherInfoProps = {
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  taskInfo?: TaskData;
  callback?: () => void;
};

const TaskOtherInfo = ({
  isCurrentEditing,
  setIsCurrentEditing,
  taskInfo,
  callback,
}: TaskOtherInfoProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleOnCancel = () => {
    form.resetFields();
    setIsEditing(false);
    setIsCurrentEditing && setIsCurrentEditing(false);
  };

  const handleOnEdit = () => {
    if (isEditing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('task/edit', {
            other: {
              ...values,
            },
            taskId: taskInfo ? taskInfo.taskId : undefined,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('sortationSystem.alerts.taskEdited'));
                handleOnCancel();
                callback && callback();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => setIsLoading(false));
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setIsEditing(true);
        setIsCurrentEditing && setIsCurrentEditing(true);
      }
    }
  };

  const getFormItemContent = (key: string, value: any) => {
    let props: FormItemProps = {};
    if (typeof value === 'boolean') props = { valuePropName: 'checked' };
    return (
      <Form.Item
        key={key}
        name={key}
        style={{ marginBottom: 0 }}
        label={`${t(`sortationSystem.taskListColumns.other.${key}`)}:`}
        {...props}
      >
        {getInputContainer(value)}
      </Form.Item>
    );
  };

  const getInputContainer = (value: any) => {
    switch (typeof value) {
      case 'number':
        return isEditing ? (
          <InputNumber type="number" min={0} />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        );
      case 'boolean':
        return (
          <Switch
            disabled={!isEditing}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        );
      default:
        return isEditing ? (
          <Input />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        );
    }
  };

  return taskInfo && taskInfo.other ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...taskInfo.other,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('sortationSystem.taskDetail.otherInfo')}
            </Typography.Text>
            {isEditing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.sortationGroup.taskManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          {Object.entries(taskInfo.other).map(([key, value], index) => (
            <Col key={key} span={24} md={12} lg={8} xl={6}>
              {getFormItemContent(key, value)}
            </Col>
          ))}
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('sortationSystem.taskDetail.otherInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskOtherInfo;
