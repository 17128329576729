import { useEffect, useRef } from 'react';
import { Descriptions } from 'antd';
import { SellerDetailData } from '../../../types';
import { useTranslation } from 'react-i18next';

type SellerDescriptionProps = {
  sellerInfo: SellerDetailData;
  callBack?: () => void;
};

const SellerDescription = ({ sellerInfo }: SellerDescriptionProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Descriptions>
      <Descriptions.Item label={t('sellers.sellerDetailVariables.sellerId')}>
        {sellerInfo.sellerId}
      </Descriptions.Item>
      <Descriptions.Item label={t('sellers.sellerDetailVariables.shopName')}>
        {sellerInfo.shopName}
      </Descriptions.Item>
      <Descriptions.Item label={t('sellers.sellerDetailVariables.shopTitle')}>
        {sellerInfo.shopTitle}
      </Descriptions.Item>
      <Descriptions.Item label={t('sellers.sellerDetailVariables.wechat')}>
        {sellerInfo.wechat}
      </Descriptions.Item>
      <Descriptions.Item label={t('sellers.sellerDetailVariables.tel')}>
        {sellerInfo.tel}
      </Descriptions.Item>
      <Descriptions.Item label={t('sellers.sellerDetailVariables.email')}>
        {sellerInfo.email}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.moreInfo.shopDesc')}
        span={3}
      >
        {sellerInfo.shopDesc}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.shopKeyword')}
        span={3}
      >
        {sellerInfo.shopKeyword}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.shopAddress')}
        span={3}
      >
        {sellerInfo.shopAddress}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default SellerDescription;
