import { FilterOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Grid,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { useAppDispatch } from '../../app/hooks';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import AdminDropdown from '../../components/admin/AdminDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { BLACK, GREEN1, RED1 } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import {
  DATE_TIME_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import {
  AdminListData,
  BasicEnumInfoType,
  FontSizeType,
  GoodsInfo,
  GoodsInventoryLogData,
  UserLogData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Users Logs
 * /dashboard/admin/log
 *
 * @prop route Route information from react-router-config
 */
const InventoryLogs = () => {
  const queryString = window.location.search;
  const [goodId] = useState(new URLSearchParams(queryString).get('good_id'));
  const ellipsis = useState(true);
  const { addTab } = useTab();
  const dispatch = useAppDispatch();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [logData, setLogData] = useState<Array<GoodsInventoryLogData>>([]);
  const [changeStatus, setChangeStatus] = useState<Array<BasicEnumInfoType>>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalLogs, setTotalLogs] = useState();
  const [page, setPage] = useState(1);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'id',
    'orderId',
    'inventoryChangeStatus|remark',
    'number|',
    'addTime',
    'goodsName|',
    'adminUser',
    'product|',
    'shopName|',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRows, setSelectedRows] = useState<UserLogData[]>([]);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`goods/inventory_log/list`, {
      params: {
        goodsId: goodId || undefined,
        page: page,
        size: pageSize,
        startTime:
          formRef.current && form.getFieldValue('search_byDate')
            ? moment(form.getFieldValue('search_byDate')[0]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        endTime:
          formRef.current && form.getFieldValue('search_byDate')
            ? moment(form.getFieldValue('search_byDate')[1]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        inventoryChangeStatus:
          formRef.current && form.getFieldValue('search_byStatus')
            ? form.getFieldValue('search_byStatus')
            : undefined,
        adminId:
          formRef.current && form.getFieldValue('adminId')
            ? form.getFieldValue('adminId')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setLogData(response.data.list);
            setTotalLogs(response.data.totalItem);
            setSelectedRowKeys([]);
            if (goodId) {
              document.title = `${t('goods.inventoryLogs')} ${
                response.data.data ? response.data.data.fullGoodsName : ''
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('goods.inventoryLogs')} ${
                    response.data.data ? response.data.data.fullGoodsName : ''
                  }`,
                  path: `${dashboardRoute.goods.inventoryLogsGoodsList}?good_id=${goodId}`,
                })
              );
            }

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, form, goodId, dispatch]);

  const columns: ColumnsType<GoodsInventoryLogData> = [
    {
      title: setFont(t('goods.inventoryLogListColumns.id'), fontSize),
      dataIndex: 'id',
      key: 'id',
      fixed: screens.lg ? 'left' : undefined,
      width: 80,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: GoodsInventoryLogData, b: GoodsInventoryLogData) =>
        compare(a.id, b.id),
    },
    {
      title: setFont(
        t('goods.inventoryLogListColumns.goods.title'),
        fontSize,
        `${t('goods.inventoryLogListColumns.goods.title')}`
      ),
      dataIndex: 'goods',
      key: 'goodsName|',
      width: 120,
      render: (record: GoodsInfo) =>
        record && (
          <Typography.Text
            style={ellipsis ? { width: 155, fontSize: fontSize } : undefined}
            ellipsis={ellipsis ? { tooltip: record.fullGoodsName } : false}
          >
            <Button
              style={{ padding: 0, fontSize: fontSize }}
              type="link"
              disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
                )
              }
            >
              {record.fullGoodsName}
            </Button>
          </Typography.Text>
        ),
    },
    {
      title: setFont(
        t('goods.inventoryLogListColumns.product'),
        fontSize,
        `${t('goods.inventoryLogListColumns.product')}`
      ),
      dataIndex: 'product',
      key: 'product|',
      width: 140,
      render: (value: GoodsInventoryLogData, record: GoodsInventoryLogData) =>
        record &&
        record.product && (
          <Typography.Text
            style={ellipsis ? { width: 135, fontSize: fontSize } : undefined}
            ellipsis={
              ellipsis
                ? {
                    tooltip: record.product.goodsSkuAttrName,
                  }
                : false
            }
          >
            {record.product.goodsSkuAttrName}
          </Typography.Text>
        ),
    },
    {
      title: setFont(
        t('goods.inventoryLogListColumns.goods.seller.title'),
        fontSize,
        `${t('goods.inventoryLogListColumns.goods.seller.title')}`
      ),
      dataIndex: 'goods',
      key: 'shopName|',
      width: 120,
      render: (record: GoodsInfo) =>
        record &&
        record.seller && (
          <Typography.Text
            style={ellipsis ? { width: 155, fontSize: fontSize } : undefined}
            ellipsis={ellipsis ? { tooltip: record.seller.shopName } : false}
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
              }
              style={{ padding: 0 }}
              type="link"
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.sellers.detail}?seller_id=${record.seller.sellerId}`
                )
              }
            >
              {record.seller.shopName}
            </Button>
          </Typography.Text>
        ),
    },
    {
      title: setFont(
        t('goods.inventoryLogListColumns.number'),
        fontSize,
        `${t('goods.inventoryLogListColumns.number')}`
      ),
      key: 'number|',
      width: 75,
      render: (record: GoodsInventoryLogData) =>
        record && (
          <Typography.Text
            style={{
              fontSize: fontSize,
              color:
                record.number[1] === '0'
                  ? BLACK
                  : record.number[0] === '+'
                  ? GREEN1
                  : RED1,
            }}
          >
            {record.number}
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('goods.inventoryLogListColumns.orderId'), fontSize),
      dataIndex: 'orderId',
      key: 'orderId',
      width: 100,
      render: (text: number) =>
        text === 0 ? (
          <Typography.Text style={{ fontSize: fontSize }}>
            {text}
          </Typography.Text>
        ) : (
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() =>
              addTab('', `${dashboardRoute.order.detail}?order_id=${text}`)
            }
            disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
          >
            {text}
          </Button>
        ),
      sorter: (a: GoodsInventoryLogData, b: GoodsInventoryLogData) =>
        compare(a.orderId, b.orderId),
    },

    {
      title: setFont(
        `${t(
          'goods.inventoryLogListColumns.inventoryChangeStatus.title'
        )} & ${t('goods.inventoryLogListColumns.remark')}`,
        fontSize,
        `${t('goods.inventoryLogListColumns.inventoryChangeStatus.title')}|${t(
          'goods.inventoryLogListColumns.remark'
        )}`
      ),
      key: 'inventoryChangeStatus|remark',
      width: 185,
      render: (record: GoodsInventoryLogData) =>
        record &&
        record.inventoryChangeStatus && (
          <Space direction="vertical">
            <Tag
              color={
                record.inventoryChangeStatus
                  ? record.inventoryChangeStatus.tagColor
                  : undefined
              }
            >
              {record.inventoryChangeStatus
                ? record.inventoryChangeStatus.description
                : undefined}
            </Tag>
            <Typography.Text style={{ fontSize: fontSize }}>
              {record.remark}
            </Typography.Text>
          </Space>
        ),
    },
    {
      title: setFont(
        t('goods.inventoryLogListColumns.adminUser.title'),
        fontSize
      ),
      dataIndex: 'adminUser',
      key: 'adminUser',
      width: 120,
      render: (text: AdminListData) =>
        text && setFont(text.adminUserName, fontSize),
    },
    {
      title: setFont(t('goods.inventoryLogListColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * @param selectedRowKeys Array of keys that were selected
   */
  const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    if (isSubscribed.current) {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  /**
   * Sends a request and sets changeStatus
   */
  const getChangeStatusData = () => {
    getDataWithAuthToken('goods/inventory_log/status_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setChangeStatus(response.data);
          }
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  /**
   * This popover displays, and handles the filters should the user need
   * It takes in inputs and formats it into parameters so that it can be used by getData
   */
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('goods.inventoryLogListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item
            name="search_byDate"
            label={t('goods.inventoryLogListColumns.advancedSearch.byDate')}
            style={{ marginBottom: 12 }}
          >
            {
              <RangePicker
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('00:00:00', 'HH:mm:ss'),
                  ],
                }}
                format={DATE_TIME_FORMAT}
                placeholder={[
                  t('goods.inventoryLogListColumns.advancedSearch.startDate'),
                  t('goods.inventoryLogListColumns.advancedSearch.endDate'),
                ]}
                onChange={(values) => {
                  values
                    ? setAdvance((prev) => ({
                        ...prev,
                        startDate: moment(values[0]).format(DATE_TIME_FORMAT),
                        endDate: moment(values[1]).format(DATE_TIME_FORMAT),
                      }))
                    : setAdvance((prev) => ({
                        ...prev,
                        startDate: '',
                        endDate: '',
                      }));
                }}
              />
            }
          </Form.Item>
          <Form.Item
            name="search_byStatus"
            label={t(
              'goods.inventoryLogListColumns.advancedSearch.changeStatus'
            )}
            style={{ marginBottom: 12 }}
          >
            <Select
              allowClear
              placeholder={t('searchPlaceholders.selectInventoryChangeStatus')}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  invChangeStatus: value !== undefined ? String(value) : '',
                }));
              }}
              onFocus={() => {
                if (
                  !changeStatus.length &&
                  hasPermission(actionPermissions.goodGroup.goodView)
                )
                  getChangeStatusData();
              }}
            >
              {changeStatus.map((status) => (
                <Select.Option value={status.code} key={status.code}>
                  {`${status.description}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="adminId"
            label={t('goods.inventoryLogListColumns.advancedSearch.byAdminId')}
            style={{ marginBottom: 12 }}
          >
            <AdminDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  adminId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('users.userLogsColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('users.userLogsColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('goods.inventoryLogListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <FourZeroThree />
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.inventoryLogs')}
          </Typography.Title>
          <TableToolbar
            fontSize={fontSize}
            setFontSize={setFontSize}
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getData()}
            totalItems={totalLogs}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            advancedSearch={advancedSearch}
            rows={logData.map((log) => ({
              ...log,

              number: log.number ? log.number : '',
              shopName:
                log.goods && log.goods.seller ? log.goods.seller.shopName : '',
              goodsName: log.goods ? log.goods.fullGoodsName : '',
              adminUser: log.adminUser ? `${log.adminUser.adminUserName}` : '',
              product: log.product ? log.product.goodsSkuAttrName : '',
              inventoryChangeStatus: log.inventoryChangeStatus
                ? log.inventoryChangeStatus.description
                : '',
              remark: log.remark ? log.remark : '',
            }))}
            exportConfig={{ fileName: 'GOODS_INVENTORY_LOGS' }}
          />
          <Table<GoodsInventoryLogData>
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            dataSource={logData}
            size={tableSize}
            loading={loading}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowSelection={rowSelection}
            rowKey={(log) => log.id}
            pagination={{
              total: totalLogs,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'GOODS_INVENTORY_LOGS' } }}
              selectedRows={selectedRows.map((log) => ({
                ...log,
                adminUser: log.adminUser
                  ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
                  : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
    </Container>
  );
};
export default InventoryLogs;
