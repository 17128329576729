import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Menu,
  Popconfirm,
  Popover,
  Select,
  Space,
  Typography,
} from 'antd';
import FourZeroThree from '../../components/FourZeroThree';
import FiveHundred from '../../components/FiveHundred';
import TableToolbar from '../../components/table/TableToolbar';
import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { AdEnum, AdSettingAdPositionData, FontSizeType } from '../../types';
import Table, { ColumnsType } from 'antd/lib/table';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { compare, setFont } from '../../utils/colComponents';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { useVT } from 'virtualizedtableforantd4';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { alertMessage } from '../../utils/alertMessage';
import AdPositionModal from '../../components/advertisement/AdPositionModal';
import { useTab } from '../../hooks/useTab';
import { dashboardRoute } from '../../constants/pathname';
import { tableScrollToTop } from '../../utils/helperFunction';

const AdPositionList = () => {
  //General Components
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { addTab } = useTab();
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [loading, setLoading] = useState(true);
  const [adPositionModalVisible, setAdPositionModalVisible] = useState(false);
  //Data Components
  const [adEnum, setAdEnum] = useState<AdEnum>();
  const [adPositionData, setAdPositionData] = useState<
    AdSettingAdPositionData[]
  >([]);
  const [editAdPosition, setEditAdPosition] =
    useState<AdSettingAdPositionData>();
  //Table Components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [total, setTotal] = useState();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const columnKeys = [
    'positionId',
    'positionName',
    'positionLocation',
    'positionDesc',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<AdSettingAdPositionData> = [
    {
      title: setFont(
        t('advertisement.adPositionColumnList.positionId'),
        fontSize
      ),
      dataIndex: 'positionId',
      key: 'positionId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: AdSettingAdPositionData, b: AdSettingAdPositionData) =>
        compare(a.positionId, b.positionId),
      render: (text: string, record: AdSettingAdPositionData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.advertisementGroup.adView)}
          onClick={() => {
            addTab(
              ``,
              `${dashboardRoute.advertisement.adPosition.ad}?position_id=${
                record.positionId ? record.positionId : ''
              }&cat_id=0`
            );
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(
        t('advertisement.adPositionColumnList.positionName'),
        fontSize
      ),
      dataIndex: 'positionName',
      key: 'positionName',
      width: 200,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(
        t('advertisement.adPositionColumnList.positionLocation'),
        fontSize
      ),
      dataIndex: 'positionLocation',
      key: 'positionLocation',
      width: 200,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(
        t('advertisement.adPositionColumnList.positionDesc'),
        fontSize
      ),
      dataIndex: 'positionDesc',
      key: 'positionDesc',
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: AdSettingAdPositionData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.adView)
            }
            onClick={() => {
              addTab(
                ``,
                `${dashboardRoute.advertisement.adPosition.ad}?position_id=${
                  record.positionId ? record.positionId : ''
                }&cat_id=0`
              );
            }}
          >
            {t('advertisement.adPositionColumnList.viewAd')}
          </Button>
          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.positionId])
                setMorePopoverVis({ [record.positionId]: flag });
            }}
            visible={morePopoverVis[record.positionId]}
            overlay={
              <Menu>
                {hasPermission(
                  actionPermissions.advertisementGroup.adManage
                ) && (
                  <Menu.Item
                    key="edit"
                    style={{ fontSize: fontSize }}
                    onClick={() => {
                      setEditAdPosition(record);
                      setAdPositionModalVisible(true);
                    }}
                  >
                    {t('actionsColumn.edit')}
                  </Menu.Item>
                )}

                {hasPermission(
                  actionPermissions.advertisementGroup.adManage
                ) && (
                  <Menu.Item key="delete" style={{ fontSize: fontSize }}>
                    <Popconfirm
                      title={t('actionsColumn.deleteWarning')}
                      onConfirm={() => {
                        deleteAdPosition(record.positionId);
                      }}
                      okText={t('actionsColumn.confirmation.yes')}
                      cancelText={t('actionsColumn.confirmation.no')}
                      placement="leftBottom"
                    >
                      <Typography.Text type="danger">
                        {t('actionsColumn.delete')}
                      </Typography.Text>
                    </Popconfirm>
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.positionId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Get Ad Enums
  const getAdEnum = useCallback(() => {
    getDataWithAuthToken('home_ad/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setAdEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  /**
   * Fetches Ad Position Ad
   */
  const getData = useCallback(async () => {
    if (isSubscribed.current) setLoading(true);
    try {
      const response = await getDataWithAuthToken(`ad_position/list`, {
        params: {
          page: page,
          size: pageSize,
          keyword: keyword ? keyword : undefined,
          positionLocation:
            formRef.current && form.getFieldValue('positionLocation')
              ? form.getFieldValue('positionLocation')
              : undefined,
        },
      });
      if (response && response.goodStatus) {
        setAdPositionData(response.data.list);
        setTotal(response.data.totalItem);

        // Scroll to top when data changes
        tableScrollToTop();
      } else if (response && response.returnCode === 403) {
        if (isSubscribed.current) setFourZeroThree(true);
      } else {
        isSubscribed.current && setFiveHundred(true);
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
      if (isSubscribed.current) setLoading(false);
    } catch (error) {
      console.log(error);
      if (isSubscribed.current) setLoading(false);
    }
  }, [t, page, pageSize, keyword, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getAdEnum();
  }, [getAdEnum]);

  const deleteAdPosition = async (id: number) => {
    if (isSubscribed.current) setLoading(true);
    try {
      const response = await postDataWithAuthToken('ad_position/delete', {
        positionId: id,
      });

      if (response && response.goodStatus) {
        alertMessage('success', t('advertisement.alerts.adPositionDeleted'));
        getData();
      } else {
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
      if (isSubscribed.current) setLoading(false);
    } catch (error) {
      if (isSubscribed.current) setLoading(false);
      console.log(error);
    }
  };

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('general.advancedSearch')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all', isRecommend: 'all' }}
        >
          <Form.Item
            name="positionLocation"
            label={t(
              'advertisement.adPositionColumnList.advancedSearch.positionLocation'
            )}
          >
            <Select
              allowClear
              placeholder={t('general.inputError.pleaseSelectOne')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  adPageType: value,
                }))
              }
            >
              {adEnum?.positionLocation.map((positionLocation) => (
                <Select.Option
                  key={positionLocation.description}
                  value={positionLocation.code}
                >
                  {`${positionLocation.description} [${positionLocation.code}]`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('general.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('general.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>{t('general.advancedSearch')}</Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('advertisement.adPosition')}
            </Typography.Title>
            <TableToolbar
              leftElement={
                <Button
                  icon={<PlusOutlined />}
                  disabled={
                    !hasPermission(
                      actionPermissions.advertisementGroup.adManage
                    )
                  }
                  onClick={() => {
                    setEditAdPosition(undefined);
                    setAdPositionModalVisible(true);
                  }}
                >
                  {t('advertisement.add/EditAd.addPositionTitle')}
                </Button>
              }
              advancedSearch={advancedSearch}
              tableSize={tableSize}
              setTableSize={setTableSize}
              fontSize={fontSize}
              setFontSize={setFontSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchAdPosition')}
              rows={adPositionData.map((adPositionData) => ({
                ...adPositionData,
              }))}
              exportConfig={{ fileName: 'AD_POSITION_LIST' }}
            />
            <Table<AdSettingAdPositionData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={adPositionData}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              size={tableSize}
              loading={loading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                },
                current: page,
              }}
              rowKey={(adPositionData) => adPositionData.positionId}
            />
            <AdPositionModal
              visible={adPositionModalVisible}
              setVisible={setAdPositionModalVisible}
              adEnum={adEnum}
              selectedPositionData={editAdPosition}
              refresh={() => {
                getData();
              }}
            />
          </Card>
        </>
      )}
    </Container>
  );
};

export default AdPositionList;
