import React from 'react';
import { ShopConfigData } from '../../../types';
import { FormInstance, Typography } from 'antd';
import { getFormField } from '../../../utils/getFormField';

const { Title } = Typography;

type GroupFieldsProps = {
  field: ShopConfigData;
  formInfo: Array<ShopConfigData>;
  formInstance: FormInstance<any>;
};

/**
 * Fields that combine a group
 * 
 * @param field        The Group Field
 * @param formInfo     Form Information
 * @param formInstance Form Instance
 */
const GroupFields = ({ field, formInfo, formInstance }: GroupFieldsProps) => {
  return (
    <>
      <Title level={4}>{field.description}</Title>
      {field.children.map((field) =>
        getFormField(field, formInfo, formInstance)
      )}
    </>
  );
};

export default GroupFields;
