import { Button, Col, Row, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimAttachmentLists, OrderClaimData } from '../../../types';

import Table, { ColumnsType } from 'antd/lib/table';

type ClaimAttachmentListProps = {
  orderClaimDetail: OrderClaimData;
  loading: boolean;
};

/**
 * Displays Claim Attachment List
 *
 */
const ClaimAttachmentList = ({
  orderClaimDetail,
  loading,
}: ClaimAttachmentListProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);
  const columns: ColumnsType<ClaimAttachmentLists> = [
    {
      title: t('order.claimAttachmentListColumns.id'),
      width: 50,
      dataIndex: 'id',
      render: (text: string) => text,
    },
    {
      title: t('order.claimAttachmentListColumns.fileType'),
      width: 60,
      dataIndex: 'fileType',
      render: (text: string, record: ClaimAttachmentLists) => (
        <div
          style={{
            wordBreak: 'break-word',
          }}
        >
          {record && record.fileType ? record.fileType.description : ''}
        </div>
      ),
    },
    {
      title: t('order.claimAttachmentListColumns.filePath'),
      width: 300,
      dataIndex: 'filePath',
      render: (text: string) => (
        <Button
          style={{ wordBreak: 'break-word', padding: 0 }}
          type="link"
          onClick={() => window.open(orderClaimDetail.udeskLink)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: t('order.claimAttachmentListColumns.sortOrder'),
      dataIndex: 'sortOrder',
      width: 80,
      render: (text: string) => text,
    },
    {
      title: t('order.claimAttachmentListColumns.remark'),
      width: 180,
      dataIndex: 'remark',
      render: (text: string) => text,
    },
  ];

  return (
    <Row gutter={[16, 24]}>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Text
          strong
          style={{
            fontSize: 16,
            marginBottom: 10,
          }}
        >
          {t('order.claimAttachmentListColumns.title')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Table
          size="small"
          dataSource={orderClaimDetail.claimAttachmentList}
          columns={columns}
          scroll={{ y: 600, x: 1200 }}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: 50,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
            hideOnSinglePage: true,
          }}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default ClaimAttachmentList;
