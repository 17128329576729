import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Image, Modal, Radio, Space, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FALLBACK_IMG } from '../../../constants/styles';
import { GoodData, GoodsSkuAttr } from '../../../types';
import GoodGalleryModal from './GoodGalleryModal';

type GoodSpecImageProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  goodInfo?: GoodData;
  attrInfo?: GoodsSkuAttr & { specTypeName: string; specTypeId: string };
  updateAttrInfo?: (value: {
    [key: string]: GoodsSkuAttr & { specTypeName: string; specTypeId: string };
  }) => void;
};
const GoodSpecImageModal = ({
  visible,
  setVisible,
  goodInfo,
  attrInfo,
  updateAttrInfo,
}: GoodSpecImageProps) => {
  const { t } = useTranslation();
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [photo, setPhoto] = useState<any>({});
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (visible && attrInfo) {
      form.resetFields();
      if (isSubscribed.current) {
        setPhoto({
          photo: attrInfo.attrOriginalPic || '',
          photoPath: attrInfo.attrThumbPicPath || '',
          thumbPic: attrInfo.attrThumbPic || '',
          originalPicPath: attrInfo.attrOriginalPicPath || '',
          largePic: attrInfo.attrLargePic || '',
          largePicPath: attrInfo.attrLargePicPath || '',
        });
      }
    }
  }, [visible, form, attrInfo]);

  useEffect(() => {
    if (visible && isSubscribed.current) {
      form.setFieldsValue({
        attrOriginalPic: photo.photo,
      });
    }
  }, [visible, form, photo]);

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (updateAttrInfo && attrInfo) {
          let goodsSkuAttrId = attrInfo.goodsSkuAttrId
            ? attrInfo.goodsSkuAttrId
            : `${attrInfo?.specTypeId}_${attrInfo?.skuAttrValue}`;
          let { specTypeId, specTypeName, ...rest } = attrInfo;

          updateAttrInfo({
            [goodsSkuAttrId]: {
              ...rest,
              ...values,
              attrThumbPic: photo.thumbPic,
              attrLargePic: photo.largePic,
              attrOriginalPic: photo.photo,
              attrThumbPicPath: photo.photoPath,
              attrLargePicPath: photo.largePicPath,
              attrOriginalPicPath: photo.originalPicPath,
              goodsSkuAttrId: goodsSkuAttrId,
            },
          });

          if (isSubscribed.current) setVisible(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      visible={visible}
      okText={t('goods.add/editGood.ok')}
      cancelText={t('goods.add/editGood.cancel')}
      onOk={onOk}
      onCancel={() => setVisible(false)}
    >
      <Form
        form={form}
        initialValues={
          attrInfo
            ? { isDefaultAttr: attrInfo.isDefaultAttr || false }
            : { isDefaultAttr: false }
        }
      >
        <Form.Item label={t('goods.goodsListColumns.goodsName')}>
          <Typography.Text>{goodInfo && goodInfo.goodsName}</Typography.Text>
        </Form.Item>
        <Form.Item label={t('goods.add/editGood.specType')}>
          <Typography.Text>{attrInfo && attrInfo.specTypeName}</Typography.Text>
        </Form.Item>
        <Form.Item label={t('goods.add/editGood.specTypeAttr')}>
          <Typography.Text>{attrInfo && attrInfo.skuAttrValue}</Typography.Text>
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.attrImage')}
          name="attrOriginalPic"
          rules={[{ required: true }]}
        >
          <Space align="start">
            <Space direction="vertical">
              <Image
                width={100}
                src={photo.photoPath}
                preview={{ src: photo.attrLargePicPath }}
                fallback={FALLBACK_IMG}
              />
              <Button
                block
                size="small"
                onClick={() => {
                  if (goodInfo?.pics) {
                    setShowGalleryModal(true);
                  } else {
                    alert('Add Photos');
                  }
                }}
              >
                {t('goods.add/editGood.goodGallery')}
              </Button>
            </Space>
            {photo.photoPath && (
              <MinusCircleOutlined
                style={{ color: ' #ff0f0f' }}
                onClick={() =>
                  setPhoto({
                    photo: '',
                    photoPath: '',
                    originalPic: '',
                    originalPicPath: '',
                    largePic: '',
                    largePicPath: '',
                  })
                }
              />
            )}
          </Space>
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.defaultAttr')}
          name="isDefaultAttr"
        >
          <Radio.Group>
            <Radio value={false}>{t('general.booleanToStatus.false')}</Radio>
            <Radio value={true}>{t('general.booleanToStatus.true')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      {goodInfo?.pics && (
        <GoodGalleryModal
          photos={goodInfo.pics}
          visible={showGalleryModal}
          setVisible={setShowGalleryModal}
          value={photo}
          setValue={setPhoto}
        />
      )}
    </Modal>
  );
};

export default GoodSpecImageModal;
