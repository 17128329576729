import { ReloadOutlined } from '@ant-design/icons';
import { ResponsiveLine } from '@nivo/line';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  BLACK,
  BLUE1,
  BLUE2,
  GREEN1,
  PURPLE1,
  PURPLE2,
  RED1,
} from '../../../constants/color';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
  MEDIUM_FONT_SIZE,
  SMALL_FONT_SIZE,
} from '../../../constants/systemConstants';
import { NivoLineChartData, OrderAnalysisDataPoint } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import {
  addCommas,
  addCommasPrice,
  getPercent,
} from '../../../utils/helperFunction';
import {
  getLineGraphBottomAxisLabel,
  nivoToolTip,
} from '../../../utils/nivoGraphTools';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableToolbar from '../../table/TableToolbar';

type OrderDetailTable = {
  label1?: string;
  totalAmount1: string;
  totalOrderNum1: string;
  label2?: string;
  totalAmount2: string;
  totalOrderNum2: string;
  totalOrderNumGrowth: string;
  totalAmountGrowth: string;
};

type OrderDetailProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const OrderDetail = ({
  isSeller,
  isLoading,
  setIsLoading,
}: OrderDetailProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const { RangePicker } = DatePicker;
  const isSubscribed = useRef(true);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [numOfDays, setNumOfDays] = useState<Number>(7);
  const [viewMode, setViewMode] = useState<string>(
    t('analysis.orderDetail.totalOrderNum')
  );
  const viewTypeButtons = ['totalOrderNum', 'totalAmount'];

  //This Year range
  const [range1Data, setRange1Data] = useState<OrderAnalysisDataPoint[]>([]);
  //Last Year range
  const [range2Data, setRange2Data] = useState<OrderAnalysisDataPoint[]>([]);
  const [lineGraphData, setLineGraphData] = useState<NivoLineChartData[]>([]);
  const [orderDetailTable, setOrderDetailTable] = useState<OrderDetailTable[]>(
    []
  );
  //Text/Visuals Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'date2',
    'totalOrderNum2',
    'totalAmount2',
    'date1',
    'totalAmount1',
    'totalOrderNum1',
    'totalOrderNumGrowth',
    'totalAmountGrowth',
  ];

  const getGrowthAndLineData = useCallback(() => {
    let orderDetailTable: OrderDetailTable[] = [];
    let lineGraph: NivoLineChartData[] = [];
    let totalOrderNumData1: { x?: string; y: string | number }[] = [];
    let totalAmountData1: { x?: string; y: string | number }[] = [];
    let totalOrderNumData2: { x?: string; y: string | number }[] = [];
    let totalAmountData2: { x?: string; y: string | number }[] = [];
    if (
      range1Data &&
      range2Data &&
      range1Data.length === range2Data.length &&
      range1Data.length > 0 &&
      range2Data.length > 0
    ) {
      for (let i = 0; i < range1Data.length; i++) {
        // console.log('curr is', range1Data[i]);
        // console.log('old is', range2Data[i]);
        totalOrderNumData1.push({
          x: range1Data[i].label,
          y: range1Data[i].totalOrderNum,
        });
        totalAmountData1.push({
          x: range1Data[i].label,
          y: range1Data[i].totalAmount,
        });
        totalOrderNumData2.push({
          x: range1Data[i].label,
          y: range2Data[i].totalOrderNum,
        });
        totalAmountData2.push({
          x: range1Data[i].label,
          y: range2Data[i].totalAmount,
        });
        orderDetailTable.push({
          label1: range1Data[i].label,
          totalAmount1: range1Data[i].totalAmount,
          totalOrderNum1: range1Data[i].totalOrderNum,
          label2: range2Data[i].label,
          totalAmount2: range2Data[i].totalAmount,
          totalOrderNum2: range2Data[i].totalOrderNum,
          totalOrderNumGrowth: getPercent(
            range2Data[i].totalOrderNum,
            range1Data[i].totalOrderNum
          ),
          totalAmountGrowth: getPercent(
            range2Data[i].totalAmount,
            range1Data[i].totalAmount
          ),
        });
      }
      lineGraph.push({
        id: `${t('analysis.orderDetail.totalOrderNum')}`,
        color: BLUE1,
        data: totalOrderNumData1,
      });
      lineGraph.push({
        id: `${t('analysis.orderDetail.totalAmount')}`,
        color: PURPLE1,
        data: totalAmountData1,
      });
      lineGraph.push({
        id: `${t('analysis.orderDetail.totalOrderNum')} (${t(
          'analysis.orderDetail.lastYear'
        )})`,
        color: BLUE2,
        data: totalOrderNumData2,
      });
      lineGraph.push({
        id: `${t('analysis.orderDetail.totalAmount')} (${t(
          'analysis.orderDetail.lastYear'
        )})`,
        color: PURPLE2,
        data: totalAmountData2,
      });
      setLineGraphData(lineGraph);
      setOrderDetailTable(orderDetailTable);
    }
  }, [range1Data, range2Data, t]);

  useEffect(() => {
    getGrowthAndLineData();
  }, [getGrowthAndLineData]);

  /**
   * Grabs all the needed data for Order Detail
   */
  const getData = useCallback(
    (date?: { key: string; start: string; end: string }) => {
      if (isSubscribed.current) setIsLoading(true);
      getDataWithAuthToken('analysis/order/detail', {
        params: {
          isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
          sellerId:
            getDashboardStyle().isSellerSwitch &&
            isSeller &&
            formRef.current &&
            form.getFieldValue('sellerId')
              ? form.getFieldValue('sellerId')
              : undefined,
          startDate: date ? date.start : undefined,
          endDate: date ? date.end : undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              if (date) {
                if (date.key === 'range1') {
                  setRange1Data(response.data.list);
                } else if (date.key === 'range2') {
                  setRange2Data(response.data.list);
                }
              }
            }
          } else if (response && response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (isSubscribed.current) setIsLoading(false);
        });
    },
    [t, setFourZeroThree, setFiveHundred, isSeller, form, setIsLoading]
  );

  useEffect(() => {
    let start = moment().subtract(8, 'days');
    let end = moment().subtract(1, 'days');
    getData({
      key: 'range1',
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
    });
    getData({
      key: 'range2',
      start: start.subtract(1, 'years').format(DATE_FORMAT),
      end: end.subtract(1, 'years').format(DATE_FORMAT),
    });
  }, [getData]);

  const columns: ColumnsType<OrderDetailTable> = [
    {
      title: `${t('analysis.orderDetail.date')} (${t(
        'analysis.orderDetail.lastYear'
      )})`,
      fixed: screens.lg ? 'left' : undefined,
      key: 'label2',
      dataIndex: 'label2',
      width: 120,
      render: (text: string) => text,
    },
    {
      title: t('analysis.orderDetail.totalOrderNum'),
      dataIndex: 'totalOrderNum2',
      key: 'totalOrderNum2',
      width: 150,
      sorter: (a: OrderDetailTable, b: OrderDetailTable) =>
        compare(
          Number(a.totalOrderNum2) !== undefined
            ? Number(a.totalOrderNum2)
            : null,
          Number(b.totalOrderNum2) !== undefined
            ? Number(b.totalOrderNum2)
            : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.orderDetail.totalAmount'),
      dataIndex: 'totalAmount2',
      key: 'totalAmount2',
      width: 150,
      sorter: (a: OrderDetailTable, b: OrderDetailTable) =>
        compare(
          Number(a.totalAmount2) !== undefined ? Number(a.totalAmount2) : null,
          Number(b.totalAmount2) !== undefined ? Number(b.totalAmount2) : null
        ),
      render: (text: string) => addCommasPrice(text),
    },
    {
      title: t('analysis.orderDetail.date'),
      dataIndex: 'label1',
      key: 'label1',
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('analysis.orderDetail.totalOrderNum'),
      dataIndex: 'totalOrderNum1',
      key: 'totalOrderNum1',
      width: 150,
      sorter: (a: OrderDetailTable, b: OrderDetailTable) =>
        compare(
          Number(a.totalOrderNum1) !== undefined
            ? Number(a.totalOrderNum1)
            : null,
          Number(b.totalOrderNum1) !== undefined
            ? Number(b.totalOrderNum1)
            : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.orderDetail.totalAmount'),
      dataIndex: 'totalAmount1',
      key: 'totalAmount1',
      width: 150,
      sorter: (a: OrderDetailTable, b: OrderDetailTable) =>
        compare(
          Number(a.totalAmount1) !== undefined ? Number(a.totalAmount1) : null,
          Number(b.totalAmount1) !== undefined ? Number(b.totalAmount1) : null
        ),
      render: (text: string) => addCommasPrice(text),
    },
    {
      title: t('analysis.orderDetail.totalOrderNumGrowth'),
      dataIndex: 'totalOrderNumGrowth',
      key: 'totalOrderNumGrowth',
      width: 200,
      render: (text: string) => (
        <div
          style={{
            color: text[0] === '-' ? RED1 : text !== '' ? GREEN1 : BLACK,
          }}
        >
          {text ? `${text}%` : '0%'}
        </div>
      ),
    },

    {
      title: t('analysis.orderDetail.totalAmountGrowth'),
      dataIndex: 'totalAmountGrowth',
      key: 'totalAmountGrowth',
      render: (text: string) => (
        <div
          style={{
            color: text[0] === '-' ? RED1 : text !== '' ? GREEN1 : BLACK,
          }}
        >
          {text ? `${addCommas(text)}%` : '0%'}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form form={form} ref={formRef} style={{ paddingBottom: 20 }}>
            <Row gutter={[16, 0]}>
              <Col span={24} md={24} lg={12} xl={8}>
                <Form.Item
                  name={'date'}
                  label={t('nivo.date')}
                  initialValue={[
                    moment().subtract(8, 'days'),
                    moment().subtract(1, 'days'),
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    placeholder={[t('nivo.startDate'), t('nivo.endDate')]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                    onChange={(value) => {
                      if (value && value[0] && value[1]) {
                        setNumOfDays(value[1].diff(value[0], 'days') + 1);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {isSeller && (
                <Col span={24} md={24} lg={12} xl={8}>
                  <Form.Item
                    name="sellerId"
                    label={t('analysis.goodsSales.seller')}
                  >
                    <SellersDropdown />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      setRange1Data([]);
                      setRange2Data([]);
                      setOrderDetailTable([]);
                      if (typingTimeout) clearTimeout(typingTimeout);
                      form.getFieldValue('date') &&
                        setTypingTimeout(
                          setTimeout(() => {
                            let start = moment(form.getFieldValue('date')[0]);
                            let end = moment(form.getFieldValue('date')[1]);
                            getData({
                              key: 'range1',
                              start: start.format(DATE_FORMAT),
                              end: end.format(DATE_FORMAT),
                            });
                            getData({
                              key: 'range2',
                              start: start
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                              end: end.subtract(1, 'years').format(DATE_FORMAT),
                            });
                          }, GENERAL_TIMEOUT)
                        );
                    }}
                  >
                    {t('analysis.orderDistribution.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      setRange1Data([]);
                      setRange2Data([]);
                      form.resetFields();
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          let start = moment().subtract(8, 'days');
                          let end = moment().subtract(1, 'days');
                          getData({
                            key: 'range1',
                            start: start.format(DATE_FORMAT),
                            end: end.format(DATE_FORMAT),
                          });
                          getData({
                            key: 'range2',
                            start: start
                              .subtract(1, 'years')
                              .format(DATE_FORMAT),
                            end: end.subtract(1, 'years').format(DATE_FORMAT),
                          });
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderDistribution.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (form.getFieldValue('date')) {
                        setTypingTimeout(
                          setTimeout(() => {
                            let start = moment(form.getFieldValue('date')[0]);
                            let end = moment(form.getFieldValue('date')[1]);
                            getData({
                              key: 'range1',
                              start: start.format(DATE_FORMAT),
                              end: end.format(DATE_FORMAT),
                            });
                            getData({
                              key: 'range2',
                              start: start
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                              end: end.subtract(1, 'years').format(DATE_FORMAT),
                            });
                          }, GENERAL_TIMEOUT)
                        );
                      }
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
          {lineGraphData.length > 0 && (
            <Col span={24} style={{ paddingBottom: 20 }}>
              {viewTypeButtons.map((buttonLabel, index) => {
                return (
                  <Button
                    key={index}
                    type={
                      viewMode === t(`analysis.orderDetail.${buttonLabel}`)
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      setViewMode(t(`analysis.orderDetail.${buttonLabel}`));
                    }}
                  >
                    {buttonLabel === 'totalAmount'
                      ? `${t(`analysis.orderDetail.${buttonLabel}`)} (${t(
                          `nivo.dollar`
                        )})`
                      : `${t(`analysis.orderDetail.${buttonLabel}`)} (${t(
                          `nivo.number`
                        )})`}
                  </Button>
                );
              })}
              <div /**Line Graph Title */
                style={{ textAlign: 'center', fontSize: MEDIUM_FONT_SIZE }}
              >
                {viewMode === t('analysis.orderDetail.totalOrderNum')
                  ? t('analysis.orderDetail.totalOrderNum')
                  : t('analysis.orderDetail.totalAmount')}
              </div>
              {/**Line Legend */}
              {viewMode === t('analysis.orderDetail.totalOrderNum') ? (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      paddingRight: 10,
                      backgroundColor: BLUE1,
                      fontSize: SMALL_FONT_SIZE,
                    }}
                  />
                  <div>{`${t('analysis.orderDetail.totalOrderNum')}`}</div>
                  <div
                    style={{
                      width: 20,
                    }}
                  />
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      paddingRight: 10,
                      backgroundColor: BLUE2,
                      fontSize: SMALL_FONT_SIZE,
                    }}
                  />
                  <div>{`${t('analysis.orderDetail.totalOrderNum')} (${t(
                    'analysis.orderDetail.lastYear'
                  )})`}</div>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      paddingRight: 10,
                      backgroundColor: PURPLE1,
                      fontSize: SMALL_FONT_SIZE,
                    }}
                  />
                  <div>{`${t('analysis.orderDetail.totalAmount')}`}</div>
                  <div
                    style={{
                      width: 20,
                    }}
                  />
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      paddingRight: 10,
                      backgroundColor: PURPLE2,
                      fontSize: SMALL_FONT_SIZE,
                    }}
                  />
                  <div>{`${t('analysis.orderDetail.totalAmount')} (${t(
                    'analysis.orderDetail.lastYear'
                  )})`}</div>
                </div>
              )}
              <div style={{ width: '100%', height: 550 }}>
                {
                  <ResponsiveLine
                    theme={{
                      fontSize: DEFAULT_FONT_SIZE,
                      axis: {
                        legend: { text: { fontSize: DEFAULT_FONT_SIZE } },
                      },
                    }}
                    colors={(d) => d.color}
                    tooltip={(point) =>
                      nivoToolTip({
                        ...point,
                        xAxisLength: lineGraphData[0].data.length,
                        tooltipType: 'Standard',
                      })
                    }
                    xScale={{
                      type: 'time',
                      format: '%Y-%m-%d',
                      useUTC: false,
                    }}
                    yScale={{
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: false,
                      reverse: false,
                    }}
                    useMesh
                    animate={true}
                    enableGridX={false}
                    data={lineGraphData.filter((data) =>
                      viewMode === t('analysis.orderDetail.totalOrderNum')
                        ? [
                            `${t('analysis.orderDetail.totalOrderNum')}`,
                            `${t('analysis.orderDetail.totalOrderNum')} (${t(
                              'analysis.orderDetail.lastYear'
                            )})`,
                          ].includes(data.id.toString())
                        : [
                            `${t('analysis.orderDetail.totalAmount')}`,
                            `${t('analysis.orderDetail.totalAmount')} (${t(
                              'analysis.orderDetail.lastYear'
                            )})`,
                          ].includes(data.id.toString())
                    )}
                    axisBottom={getLineGraphBottomAxisLabel(numOfDays)}
                    axisLeft={{
                      legend:
                        viewMode === t('analysis.orderDetail.totalOrderNum')
                          ? t('nivo.number')
                          : t('nivo.dollar'),
                      legendOffset: -60,
                      legendPosition: 'middle',
                    }}
                    margin={{
                      top: 10,
                      right: 20,
                      bottom: 60,
                      left: 70,
                    }}
                  />
                }
              </div>
            </Col>
          )}
          {orderDetailTable && orderDetailTable.length > 0 && (
            <Col span={24}>
              <TableToolbar
                columns={columns}
                columnKeys={columnKeys}
                rows={orderDetailTable.map((orderDetail) => ({
                  ...orderDetail,
                  totalOrderNumGrowth:
                    orderDetail.totalOrderNumGrowth &&
                    orderDetail.totalOrderNumGrowth !== '0'
                      ? `${orderDetail.totalOrderNumGrowth}%`
                      : '0%',
                  totalAmountGrowth:
                    orderDetail.totalAmountGrowth &&
                    orderDetail.totalAmountGrowth !== '0'
                      ? `${orderDetail.totalAmountGrowth}%`
                      : '0%',
                }))}
                exportConfig={{
                  fileName: 'ORDER_DETAIL_ANALYSIS',
                }}
              />
              <Table<OrderDetailTable>
                size="small"
                dataSource={orderDetailTable}
                columns={columns}
                scroll={{ y: 500, x: 1200 }}
                rowKey={(record) => (record.label1 ? record.label1 : '')}
                pagination={{
                  pageSize: 50,
                  showTotal: (total, range) =>
                    t('general.paginationTotal', {
                      start: range[0],
                      end: range[1],
                      total: total,
                    }),
                  hideOnSinglePage: true,
                }}
              />
            </Col>
          )}
        </Spin>
      )}
    </>
  );
};

export default OrderDetail;
