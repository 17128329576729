import { Spin, TreeSelect } from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';

type RegionTreeSelectProps = {
  initialValue?: any | any[];
  onChange?: ((value: any) => void) | undefined;
  disabledCondition?: (value: number) => boolean; // disable function which disable tree node in tree select
  allRegionIds?: string[]; // region Ids from parent component
  setAllRegionIds?: React.Dispatch<React.SetStateAction<string[]>>;
  parentRegionOptions?: DataNode[]; // region options from parent component
  setParentRegionOptions?: React.Dispatch<React.SetStateAction<DataNode[]>>;
};

const RegionTreeSelect = ({
  initialValue,
  onChange,
  disabledCondition,
  allRegionIds,
  setAllRegionIds,
  parentRegionOptions,
  setParentRegionOptions,
}: RegionTreeSelectProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [regionOptions, setRegionOptions] = useState<DataNode[]>([]);
  const [defaultKeys, setDefaultKeys] = useState<string[]>();
  const [isLoading, setIsLoading] = useState(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // const disabledConditionCallback = useCallback(
  //   (value) => {
  //     return disabledCondition && disabledCondition(value);
  //   },
  //   [disabledCondition]
  // );

  const getRegionData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    let regionIds: string[] = [];
    /**
     * Reconstruct the data to be used in TreeSelect
     *
     * @param  data        Array of all the regions
     * @return DataNode[]  A reformated version of regions
     */
    const makeTreeData = (data: RegionData[] = []) => {
      let treeData: DataNode[] = [];
      for (let node of data) {
        let treeNode: DataNode = {
          title: node.regionName,
          value: node.regionId.toString(),
          key: node.regionId.toString(),
          isLeaf: !(node.child && node.child.length),
        };
        treeNode.title = node.regionName;
        treeNode.value = node.regionId.toString();
        treeNode.key = node.regionId.toString();

        if (!treeNode.isLeaf) {
          treeNode.children = makeTreeData(node.child);
          treeNode.disabled = treeNode.children.every(
            (v) => v.disabled === true
          );
        } else {
          regionIds.push(node.regionId.toString());
          // Hide checkbox when condition meet
          if (disabledCondition) {
            treeNode.disabled = disabledCondition(node.regionId);
          }
        }
        treeData.push(treeNode);
      }
      return treeData;
    };

    const getInitialValue = (regionIds?: string[]) => {
      return initialValue
        ? initialValue === 'all'
          ? regionIds
            ? regionIds
            : []
          : Array.isArray(initialValue)
          ? initialValue
          : initialValue.split(',')
        : [];
    };
    if (parentRegionOptions && parentRegionOptions.length > 0) {
      setRegionOptions(parentRegionOptions);
      allRegionIds && setAllRegionIds && setAllRegionIds(allRegionIds);
      if (allRegionIds) {
        setAllRegionIds && setAllRegionIds(allRegionIds);
        setDefaultKeys(getInitialValue(allRegionIds));
      }
      setIsLoading(false);
    } else {
      getDataWithAuthToken('setting/region/all')
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              const treeData = makeTreeData(response.data.list);
              setRegionOptions(treeData);
              setDefaultKeys(getInitialValue(regionIds));

              setAllRegionIds && setAllRegionIds(regionIds);
              setParentRegionOptions && setParentRegionOptions(treeData);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [
    t,
    initialValue,
    allRegionIds,
    disabledCondition,
    parentRegionOptions,
    setAllRegionIds,
    setParentRegionOptions,
  ]);

  useEffect(() => {
    if (isSubscribed.current) {
      if (!regionOptions.length) {
        getRegionData();
      }
    }
  }, [regionOptions, getRegionData]);

  const handleOnChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Spin spinning={isLoading}>
      {defaultKeys && (
        <TreeSelect
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          treeData={regionOptions}
          defaultValue={defaultKeys}
          treeDefaultExpandedKeys={defaultKeys}
          treeCheckable={true}
          placeholder={t('general.pleaseSelect')}
          allowClear={true}
          showSearch={false}
          maxTagCount={10}
          onChange={handleOnChange}
          onFocus={() => {
            if (!regionOptions.length) getRegionData();
          }}
        />
      )}
    </Spin>
  );
};

export default RegionTreeSelect;
