import { Card } from 'antd';
import Container from '../../components/Container';
import UserDeviceTable from '../../components/users/userDetail/UserDeviceTable';

const UserDevice = () => {
  return (
    <Container>
      <Card>
        <UserDeviceTable />
      </Card>
    </Container>
  );
};

export default UserDevice;
