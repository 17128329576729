import React, { useEffect, useState, useCallback } from 'react';
import { Menu, Button, Skeleton, Space, Input, Tooltip, Drawer } from 'antd';
import { GalleryAlbum } from '../../types';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AlbumModal from '../settings/photoGallery/AlbumModal';
import { SetStateAction } from 'react-router/node_modules/@types/react';
// import AlbumModal from './AlbumModal';

const { SubMenu } = Menu;

type AlbumSidebarProps = {
  handleAlbumSelect: (album: GalleryAlbum) => void;
  getAlbums: (keyword?: string) => void;
  albums: Array<GalleryAlbum>;
  loading: boolean;
  setAlbumPage: React.Dispatch<SetStateAction<number>>;
  hasMore: boolean;
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  isMobile?: boolean;
  setAlbumKeyword: React.Dispatch<SetStateAction<string>>;
  setIsNewSearch: React.Dispatch<SetStateAction<boolean>>;
};

/**
 * The sidebar containing all the albums for Photo Gallery
 *
 * @param setTitle          Function to set title as album name
 * @param handleAlbumSelect Function called after album has been selected
 */
const AlbumSidebar = ({
  handleAlbumSelect,
  getAlbums,
  albums,
  loading,
  setAlbumPage,
  hasMore,
  visible,
  setVisible,
  isMobile,
  setAlbumKeyword,
  setIsNewSearch,
}: AlbumSidebarProps) => {
  const { t } = useTranslation();

  const [subAlbums, setSubAlbums] = useState<{
    [key: string]: {
      albums: Array<GalleryAlbum>;
      page: number;
      hasNextPage: boolean;
    };
  }>({});
  const [rootSubmenuKeys, setRootSubMenuKeys] = useState<Array<string>>([]);
  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
  const [albumModalVisible, setAlbumModalVisible] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  /**
   * @param parentId Album to get the subAlbums from
   */
  const getSubAlbums = useCallback((parentId: string, page: number = 1) => {
    getDataWithAuthToken(
      `gallery/list?page=${page}&size=15&parentId=${parentId}`
    ).then((response) => {
      if (response && response.goodStatus) {
        setSubAlbums((prev) => ({
          ...prev,
          [parentId]: prev[parentId]
            ? {
                albums: [...prev[parentId].albums, ...response.data.list],
                page: page,
                hasNextPage: response.data.totalPage > page,
              }
            : {
                albums: response.data.list,
                page: 1,
                hasNextPage: response.data.totalPage > 1,
              },
        }));
      }
    });
  }, []);

  useEffect(() => {
    setRootSubMenuKeys(albums.map((album) => album.albumId.toString()));

    if (firstLoad) {
      // Check if an album was previously selected
      const previousSelectedKey = localStorage.getItem('albumId');
      if (previousSelectedKey) {
        const filteredAlbumList = albums.filter(
          (album) => album.albumId.toString() === previousSelectedKey
        );
        if (filteredAlbumList.length > 0) {
          const startingAlbum = filteredAlbumList[0];
          if (startingAlbum.childCount > 0) {
            setOpenKeys([previousSelectedKey]);
          } else {
            setSelectedKeys([previousSelectedKey]);
          }
          getSubAlbums(previousSelectedKey);
          handleAlbumSelect(startingAlbum);
        }
      }
      setFirstLoad(false);
    }
  }, [albums, handleAlbumSelect, firstLoad, getSubAlbums]);

  /**
   * @param keys First Level Album that was selected
   */
  const onOpenChange = (keys: React.Key[]) => {
    setSelectedKeys([]);
    const latestOpenKey = keys.find(
      (key) => openKeys.indexOf(key.toString()) === -1
    );
    if (
      latestOpenKey &&
      rootSubmenuKeys.indexOf(latestOpenKey.toString()) === -1
    ) {
      setOpenKeys(keys.map((key) => key.toString()));
      localStorage.setItem('albumId', keys[0].toString());
    } else {
      setOpenKeys(
        latestOpenKey
          ? [latestOpenKey.toString()]
          : keys.map((key) => key.toString())
      );
      latestOpenKey &&
        localStorage.setItem('albumId', latestOpenKey.toString());
    }
  };

  const renderMenuItem = (albums: Array<GalleryAlbum>) =>
    albums.map((album) =>
      album.childCount > 0 ? (
        <SubMenu
          key={album.albumId.toString()}
          title={
            <>
              {album.albumName}
              {!!album.photoCount && (
                <span style={{ fontSize: 12, marginLeft: 5 }}>
                  ({album.photoCount})
                </span>
              )}
            </>
          }
          onTitleClick={() => {
            !subAlbums[album.albumId.toString()] &&
              getSubAlbums(album.albumId.toString());
            handleAlbumSelect(album);
          }}
        >
          {subAlbums[album.albumId.toString()] ? (
            <>
              {renderMenuItem(subAlbums[album.albumId.toString()].albums)}
              {subAlbums[album.albumId.toString()].hasNextPage && (
                <Menu.Item
                  key={`${subAlbums[album.albumId.toString()]}_${
                    subAlbums[album.albumId.toString()].page
                  }`}
                >
                  <Button
                    block
                    shape="round"
                    onClick={() =>
                      getSubAlbums(
                        album.albumId.toString(),
                        subAlbums[album.albumId.toString()].page + 1
                      )
                    }
                  >
                    {t('settings.photoGalleryActions.loadMore')}
                  </Button>
                </Menu.Item>
              )}
            </>
          ) : (
            <Menu.Item
              key={`${album.albumId.toString()}_loading`}
              style={{ textAlign: 'center' }}
            >
              <Button type="text" block icon={<LoadingOutlined />} />
            </Menu.Item>
          )}
        </SubMenu>
      ) : (
        <Menu.Item
          key={album.albumId.toString()}
          onClick={() => {
            handleAlbumSelect(album);
            setSelectedKeys([album.albumId.toString()]);
            if (rootSubmenuKeys.includes(album.albumId.toString()))
              setOpenKeys([album.albumId.toString()]);
            localStorage.setItem('albumId', album.albumId.toString());
          }}
        >
          <>
            {album.albumName}
            {!!album.photoCount && (
              <span style={{ fontSize: 12, marginLeft: 5 }}>
                ({album.photoCount})
              </span>
            )}
          </>
        </Menu.Item>
      )
    );

  return (
    <>
      {!isMobile ? (
        <div style={{ maxHeight: 580 }}>
          <Space style={{ padding: 8 }}>
            <Tooltip title={t('settings.add/editAlbum.addTitle')}>
              <Button
                icon={<PlusOutlined />}
                onClick={() => setAlbumModalVisible(true)}
              />
            </Tooltip>
            <Input
              placeholder={t('searchPlaceholders.searchPhotoGalleryKeyword')}
              onPressEnter={(e) => {
                setIsNewSearch(true);
                setAlbumKeyword(e.currentTarget.value);
                setAlbumPage(1);
              }}
            />
          </Space>
          <div
            style={{
              paddingLeft: loading ? 16 : 0,
              paddingRight: loading ? 16 : 0,
            }}
          >
            <Skeleton
              loading={loading}
              paragraph={{ rows: 14 }}
              title={{ width: 170 }}
            >
              <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                style={{
                  borderRightWidth: 0,
                }}
                selectedKeys={selectedKeys}
              >
                {renderMenuItem(albums)}
              </Menu>
              {hasMore && (
                <Button
                  block
                  shape="round"
                  onClick={() => {
                    setIsNewSearch(false);
                    setAlbumPage((prev) => prev + 1);
                  }}
                >
                  {t('settings.photoGalleryActions.loadMore')}
                </Button>
              )}
            </Skeleton>
          </div>
        </div>
      ) : (
        <Drawer
          closable={false}
          placement="left"
          visible={visible}
          onClose={() => setVisible(false)}
          style={{ position: 'fixed' }}
          bodyStyle={{ overflowX: 'hidden' }}
          width={200}
        >
          <Space style={{ padding: 8 }}>
            <Tooltip title={t('settings.add/editAlbum.addTitle')}>
              <Button
                icon={<PlusOutlined />}
                onClick={() => setAlbumModalVisible(true)}
              />
            </Tooltip>
            <Input
              placeholder={t('searchPlaceholders.searchPhotoGalleryKeyword')}
              onPressEnter={(e) => {
                setIsNewSearch(true);
                setAlbumKeyword(e.currentTarget.value);
                setAlbumPage(1);
              }}
            />
          </Space>
          <div
            style={{
              paddingLeft: loading ? 16 : 0,
              paddingRight: loading ? 16 : 0,
            }}
          >
            <Skeleton
              loading={loading}
              paragraph={{ rows: 14 }}
              title={{ width: 170 }}
            >
              <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                style={{
                  borderRightWidth: 0,
                }}
                selectedKeys={selectedKeys}
              >
                {renderMenuItem(albums)}
              </Menu>
              {hasMore && (
                <Button
                  block
                  shape="round"
                  onClick={() => {
                    setIsNewSearch(false);
                    setAlbumPage((prev) => prev + 1);
                  }}
                >
                  {t('settings.photoGalleryActions.loadMore')}
                </Button>
              )}
            </Skeleton>
          </div>
        </Drawer>
      )}
      <AlbumModal
        visible={albumModalVisible}
        setVisible={setAlbumModalVisible}
        callBack={getAlbums}
      />
    </>
  );
};

export default AlbumSidebar;
