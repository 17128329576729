import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Spin,
  Switch,
  Image,
  Typography,
  Tooltip,
  Tag,
  Cascader,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleCategoryData, ArticleData, GoodData } from '../../types';
import TextArea from 'antd/lib/input/TextArea';
import 'react-quill/dist/quill.snow.css';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import PhotoGalleryModal from '../PhotoGalleryModal';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import {
  EyeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { FALLBACK_IMG } from '../../constants/styles';
import SelectGoodsModal from '../goods/selectGoods/SelectGoodsModal';
import TextEditor from '../textEditor/TextEditor';
import { MEDIA_TYPE } from '../../constants/mediaConstants';

type ArticleModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArticle?: ArticleData;
  refresh: Function;
};

const ArticleModal = ({
  visible,
  setVisible,
  loading,
  setLoading,
  selectedArticle,
  refresh,
}: ArticleModalProps) => {
  //General components
  const quillRef = useRef<any>(null);
  const [selectedGoodVisible, setSelectedGoodVisible] =
    useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [emptyGiftList, setEmptyGiftList] = useState<boolean>(false);
  const [thumbImgModalVisible, setThumbImgModalVisible] = useState(false);
  const [fullscreenMode, setFullScreenMode] = useState(false);
  const [thumbImg, setThumbImg] = useState({
    photoPath: '',
  });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [options, setOptions] = useState<
    {
      value: number | string;
      label: string;
      isLeaf: boolean;
      disabled?: boolean;
    }[]
  >([]);
  //Data components
  const [goodTags, setGoodTags] = useState<GoodData[]>([]);
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData[]>([]);
  //Text Editor Components
  const [clear, setClear] = useState(false);
  const [HTMLText, setHTMLText] = useState<string>();

  // Sets isSubscribed, quillRef to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
      quillRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (clear) {
      form.resetFields(['content']);
      setClear(false);
    }
  }, [clear, form]);

  /**
   * When the changes have been made in the SelectedGoodObjs, it will update goodTags. The user only sees goodTags.
   * The merging and checking will be done here
   */
  useEffect(() => {
    if (selectedGoodObjs.length) {
      let origList: GoodData[] = goodTags.slice();
      let origIDList: number[] = goodTags
        .slice()
        .map((origObj) => origObj.goodsId);
      selectedGoodObjs.forEach((selectedObj) => {
        if (!origIDList.includes(selectedObj.goodsId)) {
          origList.push(selectedObj);
        }
      });
      setGoodTags(origList);
      setSelectedGoodObjs([]);
    }
  }, [selectedGoodObjs, goodTags]);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      setHTMLText(undefined);
      setThumbImg({
        photoPath: selectedArticle ? selectedArticle.thumbImgPath : '',
      });
      if (selectedArticle) setHTMLText(selectedArticle.content);
    }
  }, [form, visible, selectedArticle, t]);

  useEffect(() => {
    if (visible && !emptyGiftList) {
      if (selectedArticle && selectedArticle.articleGoods) {
        let tempList: GoodData[] = [];
        selectedArticle.articleGoods.forEach((artGood) => {
          tempList = tempList.concat(artGood.goods);
        });
        setGoodTags(tempList);
      }
    }
  }, [visible, selectedArticle, emptyGiftList]);

  const onCloseGiftTab = (good: GoodData) => {
    let tempArray = goodTags.slice();
    const index = tempArray.indexOf(good, 0);
    if (goodTags.length === 1) {
      setEmptyGiftList(true);
    }
    if (index > -1) {
      tempArray.splice(index, 1);
    }
    setGoodTags(tempArray);
  };

  const onClose = () => {
    setGoodTags([]);
    setThumbImg({ photoPath: '' });
    setHTMLText(undefined);
    setGoodTags([]);
    setEmptyGiftList(false);
    setVisible(false);
  };

  const onFinish = () => {
    const goodsList: number[] = [];
    if (goodTags.length) {
      goodTags.forEach((gift) => {
        goodsList.push(gift.goodsId);
      });
    }
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        const {
          articleCatId,
          articleGoods,
          articleGoodsIds,
          content,
          ...rest
        } = values;
        if (
          values.articleCatId.length === 0 &&
          selectedArticle &&
          selectedArticle.articleCat === null
        ) {
          alertMessage(
            'warning',
            t('advertisement.alerts.pleaseSelectArticleCat')
          );
          setLoading(false);
        } else {
          postDataWithAuthToken(
            selectedArticle ? 'article/edit' : 'article/add',
            {
              ...rest,
              articleCatId: selectedArticle
                ? values.articleCatId.length > 0
                  ? values.articleCatId[values.articleCatId.length - 1]
                  : selectedArticle.articleCat.articleCatId
                : values.articleCatId[values.articleCatId.length - 1],
              content: HTMLText ? HTMLText : undefined,
              articleGoodsIds: goodsList.length > 0 ? goodsList : [],
              thumbImg: thumbImg.photoPath,
              articleId: selectedArticle
                ? selectedArticle.articleId
                : undefined,
            }
          ).then((response) => {
            if (response && response.goodStatus) {
              if (isSubscribed.current) setVisible(false);
              alertMessage(
                'success',
                selectedArticle
                  ? t('advertisement.alerts.articleEdited')
                  : t('advertisement.alerts.articleAdded')
              );
              if (refresh) refresh();
              onClose();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
          if (isSubscribed.current) setLoading(false);
        }
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  const getArtCat = () => {
    getDataWithAuthToken('article/cat/list', {
      params: { parentId: 0 },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setOptions(
            response.data.list.map((artCat: ArticleCategoryData) => {
              return {
                value: artCat.articleCatId,
                label: artCat.articleCatName,
                isLeaf: artCat.childCount === 0,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const loadData = (selectedOptions?: any[]) => {
    if (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      setTimeout(() => {
        getDataWithAuthToken('article/cat/list', {
          params: { parentId: targetOption.value },
        })
          .then((response) => {
            if (response && response.goodStatus) {
              targetOption.children = response.data.list.map(
                (artCat: ArticleCategoryData) => {
                  return {
                    value: artCat.articleCatId,
                    label: artCat.articleCatName,
                    isLeaf: artCat.childCount === 0,
                  };
                }
              );
              targetOption.loading = false;
              options && setOptions && setOptions([...options]);
            }
          })
          .catch((err) => console.log(err));
      }, EXTENDED_TIMEOUT);
    }
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={fullscreenMode ? '100%' : 700}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedArticle
          ? `${t('advertisement.add/EditArticle.editTitle')} ${
              selectedArticle.title ? selectedArticle.title : ''
            }`
          : `${t('advertisement.add/EditArticle.addTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={
            selectedArticle
              ? {
                  ...selectedArticle,
                  articleCatId: [],
                }
              : {
                  articleCatId: [],
                  sortOrder: 100,
                  isEnabled: true,
                  isRecommend: false,
                }
          }
          onFinish={onFinish}
        >
          <Form.Item /**For Title*/
            name="title"
            label={t('advertisement.add/EditArticle.title')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input style={{ maxWidth: 250 }} />
          </Form.Item>
          <Form.Item /**For Article Good Cat */
            name="articleCatId"
            label={t('advertisement.add/EditArticle.articleCat')}
            rules={[
              {
                required: selectedArticle ? false : true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Cascader
              style={{ maxWidth: 350 }}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              onFocus={() => {
                if (getArtCat && options.length <= 1) getArtCat();
              }}
              placeholder={`${
                selectedArticle && selectedArticle.articleCat
                  ? selectedArticle.articleCat.articleCatName
                  : ''
              }`}
              displayRender={(label) => label.join(' > ')}
              options={options}
              loadData={loadData}
              changeOnSelect
            />
          </Form.Item>
          <Form.Item /**For thumbImg */
            name="thumbImg"
            label={t('advertisement.add/EditArticle.thumbImg')}
          >
            <Space size="large" align="start">
              <Space align="start">
                <Image
                  width={50}
                  src={thumbImg.photoPath}
                  preview={{ mask: <EyeOutlined /> }}
                  fallback={FALLBACK_IMG}
                />
                {thumbImg.photoPath && (
                  <MinusCircleOutlined
                    style={{ color: ' #ff0f0f' }}
                    onClick={() => setThumbImg({ photoPath: '' })}
                  />
                )}
              </Space>
              <Button
                onClick={() => {
                  setFirstLoad(true);
                  setThumbImgModalVisible(true);
                }}
              >
                {t('goods.add/editCategory.photoGallery')}
              </Button>
            </Space>
          </Form.Item>
          <Form.Item /**For Keyword */
            name="keyword"
            label={t('advertisement.add/EditArticle.keyword')}
          >
            <TextArea style={{ maxWidth: 450 }} rows={2} />
          </Form.Item>
          <Form.Item /**For Brief Description */
            name="briefDesc"
            label={t('advertisement.add/EditArticle.briefDesc')}
          >
            <TextArea style={{ maxWidth: 450 }} rows={3} />
          </Form.Item>
          <Form.Item /**For External Link */
            name="externalLink"
            label={t('advertisement.add/EditArticle.externalLink')}
          >
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>
          <Form.Item /**For content */
            name="content"
            label={t('advertisement.add/EditArticle.content')}
          >
            <TextEditor
              fullscreenMode={fullscreenMode}
              setFullscreenMode={setFullScreenMode}
              setClear={setClear}
              data={HTMLText}
              setData={setHTMLText}
            />
          </Form.Item>
          <Form.Item /**For Article Goods */
            name="articleGoods"
            label={t('advertisement.add/EditArticle.articleGoodsIds')}
          >
            {goodTags.length ? (
              <Space direction="vertical">
                {goodTags &&
                  goodTags.map(
                    (tag) =>
                      tag &&
                      tag.goodsId && (
                        <Tag
                          onClose={() => onCloseGiftTab(tag)}
                          closable={true}
                          key={tag.goodsId}
                        >
                          <Space>
                            <Tooltip
                              key={tag.goodsId}
                              title={
                                tag
                                  ? tag.fullGoodsName
                                    ? tag.fullGoodsName
                                    : ''
                                  : ''
                              }
                            >
                              <Typography.Text>
                                {tag
                                  ? tag.fullGoodsName
                                    ? tag.fullGoodsName
                                    : ''
                                  : ''}
                              </Typography.Text>
                            </Tooltip>
                          </Space>
                        </Tag>
                      )
                  )}
                <Tooltip
                  title={t('advertisement.add/EditArticle.addGoodsTitle')}
                >
                  <Tag
                    onClick={() => setSelectedGoodVisible((prev) => !prev)}
                    style={{
                      display: goodTags.length > 0 ? '' : 'none',
                    }}
                  >
                    <PlusOutlined />
                  </Tag>
                </Tooltip>
              </Space>
            ) : (
              <Tooltip title={t('advertisement.add/EditArticle.addGoodsTitle')}>
                <Tag
                  style={{
                    display: goodTags.length === 0 ? '' : 'none',
                  }}
                  onClick={() => setSelectedGoodVisible((prev) => !prev)}
                >
                  <PlusOutlined />
                </Tag>
              </Tooltip>
            )}
          </Form.Item>
          <Form.Item /**For isEnabled */
            name="isEnabled"
            label={t('advertisement.add/EditArticle.isEnabled')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item /**For isRecommend */
            name="isRecommend"
            label={t('advertisement.add/EditArticle.isRecommend')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item /**For sortOrder */
            name="sortOrder"
            label={t('advertisement.add/EditArticle.sortOrder')}
          >
            <InputNumber min={0} max={50000} />
          </Form.Item>
        </Form>
      </Spin>
      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={thumbImgModalVisible}
        setVisible={setThumbImgModalVisible}
        setValue={setThumbImg}
        value={thumbImg}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
      <SelectGoodsModal
        newGoodObjs={selectedGoodObjs}
        setNewGoodObjs={setSelectedGoodObjs}
        visible={selectedGoodVisible}
        setVisible={setSelectedGoodVisible}
        multiSelectFeature={true}
        sellerId={0}
        firstLoad={false}
      />
    </Modal>
  );
};

export default ArticleModal;
