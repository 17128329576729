import { EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import { dashboardRoute } from '../../constants/pathname';
import { FALLBACK_IMG } from '../../constants/styles';
import { useTab } from '../../hooks/useTab';
import { BasicEnumInfoType, CategoryData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import PhotoGalleryModal from '../PhotoGalleryModal';
import RegionTreeSelect from '../settings/common/RegionTreeSelect';
import CategoryDropdown from './common/CategoryDropdown';

type CategoryModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  category?: CategoryData;
  refresh?: () => void;
  parent?: CategoryData;
  categoryTopics?: BasicEnumInfoType[];
  breadCrumbs?: number[];
};

const CategoryModal = ({
  visible,
  setVisible,
  category,
  refresh,
  parent,
  categoryTopics,
  breadCrumbs,
}: CategoryModalProps) => {
  const { addTab } = useTab();
  const [firstLoad, setFirstLoad] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [mobileIcon, setMobileIcon] = useState({ photo: '', photoPath: '' });
  const [allRegionIds, setAllRegionIds] = useState<string[]>([]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      setMobileIcon({
        photo: (category && category.mobileIcon) ?? '',
        photoPath: (category && category.mobileIconPath) ?? '',
      });
    }
  }, [category, form, visible, t]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        mobileIcon: mobileIcon.photo,
      });
    }
  }, [mobileIcon, form, visible]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(
          category ? 'goods/category/edit' : 'goods/category/add',
          {
            ...values,
            catId: category ? category.catId : undefined,
            parentId: values.parentId.length
              ? values.parentId[values.parentId.length - 1]
              : parent
              ? parent.catId
              : 0,
            categoryTopic: values.categoryTopic.join(),
            mobileIcon: values.mobileIcon,
            isShow: values.isShow ? true : false,
            showInNav: values.showInNav ? true : false,
            sortOrder: isNaN(values.sortOrder) ? 100 : values.sortOrder,
            showRegionId:
              allRegionIds && values.showRegionId.length === allRegionIds.length
                ? 'all'
                : values.showRegionId.join(),
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  category
                    ? t('goods.alerts.categoryEdited')
                    : t('goods.alerts.categoryAdded')
                );
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  return (
    <Modal
      title={
        category
          ? `${t('goods.add/editCategory.editTitle')} ${category.catName}`
          : t('goods.add/editCategory.addTitle')
      }
      visible={visible}
      okText={t('goods.add/editCategory.ok')}
      cancelText={t('goods.add/editCategory.cancel')}
      onOk={onFinish}
      onCancel={onClose}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            category
              ? {
                  ...category,
                  parentId: breadCrumbs?.at(-1),
                  categoryTopic: category.categoryTopic
                    ? category.categoryTopic.split(',')
                    : [],
                  showRegionId:
                    category && category.showRegionId
                      ? category.showRegionId === 'all'
                        ? allRegionIds
                        : category.showRegionId.split(',')
                      : [],
                }
              : {
                  parentId: [0],
                  categoryTopic: [],
                  showRegionId: [],
                  sortOrder: 100,
                }
          }
        >
          <Form.Item
            name="catName"
            label={t('goods.add/editCategory.catName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="catNameEn"
            label={t('goods.add/editCategory.catNameEn')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="parentId"
            label={t('goods.add/editCategory.parentCategory')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <CategoryDropdown
              initialValue={breadCrumbs}
              hasTopTree={true}
              currentCat={category}
              columnWidth={250}
            />
          </Form.Item>
          <Form.Item
            name="mobileIcon"
            label={t('goods.add/editCategory.mobileIcon')}
          >
            <Space size="large" align="start">
              <Space align="start">
                <Image
                  width={50}
                  src={mobileIcon.photoPath}
                  preview={{ mask: <EyeOutlined /> }}
                  fallback={FALLBACK_IMG}
                />
                {mobileIcon.photoPath && (
                  <MinusCircleOutlined
                    style={{ color: ' #ff0f0f' }}
                    onClick={() => setMobileIcon({ photo: '', photoPath: '' })}
                  />
                )}
              </Space>
              <Button
                onClick={() => {
                  setFirstLoad(true);
                  setModalVisible(true);
                }}
              >
                {t('goods.add/editCategory.photoGallery')}
              </Button>
            </Space>
          </Form.Item>
          <Form.Item
            name="categoryTopic"
            label={t('goods.add/editCategory.categoryTopic')}
          >
            <Select
              mode="multiple"
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {categoryTopics &&
                !!categoryTopics.length &&
                categoryTopics.map((topic) => (
                  <Select.Option key={topic.code} value={topic.code}>
                    {topic.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="catDesc" label={t('goods.add/editCategory.catDesc')}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
          <Form.Item
            name="isShow"
            valuePropName="checked"
            label={t('goods.add/editCategory.isShow')}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="showInNav"
            valuePropName="checked"
            label={t('goods.add/editCategory.showInNav')}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="showRegionId"
            label={t('goods.add/editCategory.showRegionId')}
          >
            <RegionTreeSelect
              initialValue={category?.showRegionId}
              setAllRegionIds={setAllRegionIds}
            />
          </Form.Item>
          <Form.Item
            name="sortOrder"
            label={t('goods.add/editCategory.sortOrder')}
          >
            <InputNumber
              type="number"
              min={0}
              max={50000}
              placeholder="0-50000"
            />
          </Form.Item>
          <Space>
            <Button
              onClick={() => {
                addTab(
                  ``,
                  `${dashboardRoute.advertisement.adPosition.ad}?position_id=0&cat_id=${category?.catId}`
                );
                onClose();
              }}
            >
              {t('goods.add/editCategory.catBannerSetting')}
            </Button>
          </Space>
        </Form>
      </Spin>
      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={modalVisible}
        setVisible={setModalVisible}
        setValue={setMobileIcon}
        value={mobileIcon}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
    </Modal>
  );
};

export default CategoryModal;
