import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Grid,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';

import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { useAppDispatch } from '../../app/hooks';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import AdminDropdown from '../../components/admin/AdminDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import {
  DATE_TIME_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import {
  AdminListData,
  ChangeLogList,
  FontSizeType,
  GoodsLogData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Admin Logs
 * /dashboard/admin/log
 *
 */
const GoodsLog = () => {
  const queryString = window.location.search;
  const [goodId] = useState(new URLSearchParams(queryString).get('good_id'));
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const { RangePicker } = DatePicker;
  const dispatch = useAppDispatch();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [logData, setLogData] = useState<Array<GoodsLogData>>([]);
  const [totalLogs, setTotalLogs] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const { addTab } = useTab();
  const columnKeys = [
    'logId',
    'goodsId',
    'logTime',
    'goodsName',
    'goodsUnit',
    'shopPrice',
    'isOnSale',
    'addTime',
    'shippingFee',
    'promotePrice',
    'goodsWeight',
    'isActive',
    'adminUser',
  ];
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRows, setSelectedRows] = useState<GoodsLogData[]>([]);
  const [page, setPage] = useState(1);
  const isSubscribed = useRef(true);
  const [keyword, setKeyword] = useState('');
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const columns: ColumnsType<GoodsLogData> = [
    {
      title: setFont(t('goods.goodsLogColumns.logId'), fontSize),
      dataIndex: 'logId',
      key: 'logId',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: GoodsLogData, b: GoodsLogData) => compare(a.logId, b.logId),
    },
    {
      title: setFont(t('goods.goodsLogColumns.goodsId'), fontSize),
      dataIndex: 'goodsId',
      key: 'goodsId',
      width: 120,
      render: (text: string) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab('', `${dashboardRoute.goods.detail}?good_id=${text}`)
          }
        >
          {text}
        </Button>
      ),
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.goodsId, b.goodsId),
    },
    {
      title: setFont(t('goods.goodsLogColumns.goodsName'), fontSize),
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 120,
      render: (text: string) => (
        <Typography.Text
          style={{ width: 155, fontSize: fontSize }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Typography.Text>
      ),
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.goodsName, b.goodsName),
    },
    {
      title: setFont(t('goods.goodsLogColumns.goodsUnit'), fontSize),
      dataIndex: 'goodsUnit',
      key: 'goodsUnit',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.goodsUnit, b.goodsUnit),
    },
    {
      title: setFont(t('goods.goodsLogColumns.shopPrice'), fontSize),
      dataIndex: 'shopPrice',
      key: 'shopPrice',
      render: (text: string) => setFont(text, fontSize),
      width: 120,
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.shopPrice, b.shopPrice),
    },
    {
      title: setFont(t('goods.goodsLogColumns.isOnSale'), fontSize),
      dataIndex: 'isOnSale',
      key: 'isOnSale',
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
      width: 120,
    },
    {
      title: setFont(t('goods.goodsLogColumns.shippingFee'), fontSize),
      dataIndex: 'shippingFee',
      key: 'shippingFee',
      render: (text: string) => setFont(text, fontSize),
      width: 120,
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.shippingFee, b.shippingFee),
    },
    {
      title: setFont(t('goods.goodsLogColumns.promotePrice'), fontSize),
      dataIndex: 'promotePrice',
      key: 'promotePrice',
      render: (text: string) => setFont(text, fontSize),
      width: 120,
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.promotePrice, b.promotePrice),
    },
    {
      title: setFont(t('goods.goodsLogColumns.goodsWeight'), fontSize),
      dataIndex: 'goodsWeight',
      key: 'goodsWeight',
      render: (text: string) => setFont(text, fontSize),
      width: 120,
      sorter: (a: GoodsLogData, b: GoodsLogData) =>
        compare(a.goodsWeight, b.goodsWeight),
    },
    {
      title: setFont(t('goods.goodsLogColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      render: (value: string) => setFont(value, fontSize),
      width: 120,
    },
    {
      title: setFont(t('goods.goodsLogColumns.adminUser.title'), fontSize),
      dataIndex: 'adminUser',
      key: 'adminUser',
      render: (record: AdminListData) =>
        record &&
        setFont(`${record.adminUserName} (ID: ${record.adminId})`, fontSize),
      width: 120,
    },
  ];

  const changeLogListColumns: ColumnsType<ChangeLogList> = [
    {
      title: setFont(
        t('goods.goodsLogColumns.changeLogListColumns.propertyName'),
        fontSize
      ),
      dataIndex: 'propertyName',
      key: 'propertyName',
      width: 120,
      render: (text: string) =>
        setFont(t(`goods.add/editGood.${text}`), fontSize),
    },
    {
      title: setFont(
        t('goods.goodsLogColumns.changeLogListColumns.oldValue'),
        fontSize
      ),
      dataIndex: 'oldValue',
      key: 'oldValue',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(
        t('goods.goodsLogColumns.changeLogListColumns.newValue'),
        fontSize
      ),
      dataIndex: 'newValue',
      key: 'newValue',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
  ];

  /**
   * @param selectedRowKeys Array of keys that were selected
   */
  const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    if (isSubscribed.current) {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  // Row selection Handler
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDelete = () => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('goods/log/delete_batch', {
      logIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.deletedLogs'));
          getData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  /**
   * Fetches Logs
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);

    getDataWithAuthToken('goods/log/list', {
      params: {
        goodsId: goodId || undefined,
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        adminId:
          formRef.current && form.getFieldValue('adminId')
            ? form.getFieldValue('adminId')
            : undefined,

        isOnSale: formRef.current
          ? form.getFieldValue('isOnSale') !== 'all'
            ? form.getFieldValue('isOnSale')
            : undefined
          : undefined,

        startTime:
          formRef.current && form.getFieldValue('byDate')
            ? moment(form.getFieldValue('byDate')[0]).format(DATE_TIME_FORMAT)
            : undefined,
        endTime:
          formRef.current && form.getFieldValue('byDate')
            ? moment(form.getFieldValue('byDate')[1]).format(DATE_TIME_FORMAT)
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setLogData(response.data.list);
            setTotalLogs(response.data.totalItem);
            setSelectedRowKeys([]);
            if (goodId) {
              document.title = `${t('goods.goodsLog')} ${
                response.data.data ? response.data.data.fullGoodsName : ''
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('goods.goodsLog')} ${
                    response.data.data ? response.data.data.fullGoodsName : ''
                  }`,
                  path: `${dashboardRoute.goods.log}?good_id=${goodId}`,
                })
              );
            }

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, goodId, dispatch, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  /**
   * This popover displays, and handles the filters should the user need
   * It takes in inputs and formats it into parameters so that it can be used by getData
   */
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('goods.goodsLogColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isOnSale: 'all' }}
        >
          <Form.Item
            name="byDate"
            label={t('goods.goodsLogColumns.advancedSearch.byDate')}
            style={{ marginBottom: 12 }}
          >
            <RangePicker
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('00:00:00', 'HH:mm:ss'),
                ],
              }}
              format={DATE_TIME_FORMAT}
              placeholder={[
                t('goods.goodsLogColumns.advancedSearch.startDate'),
                t('goods.goodsLogColumns.advancedSearch.endDate'),
              ]}
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      startDate: moment(values[0]).format(DATE_TIME_FORMAT),
                      endDate: moment(values[1]).format(DATE_TIME_FORMAT),
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      startDate: '',
                      endDate: '',
                    }));
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('goods.goodsLogColumns.isOnSale')}
            name="isOnSale"
            style={{ marginBottom: 12 }}
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  isOnSale:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }));
              }}
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="adminId"
            label={t('goods.goodsLogColumns.advancedSearch.adminId')}
            style={{ marginBottom: 12 }}
          >
            <AdminDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  adminId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('goods.goodsLogColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('goods.goodsLogColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('goods.goodsLogColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <h1 style={{ fontSize: 24 }}>{t('goods.goodsLog')}</h1>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getData()}
            totalItems={totalLogs}
            columns={columns}
            columnKeys={columnKeys}
            advancedSearch={advancedSearch}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchGoodsLogKeyword')}
            rows={logData.map((log) => ({
              ...log,
              adminUser: log.adminUser ? log.adminUser.adminUserName : '',
            }))}
            exportConfig={{ fileName: 'GOODS_LOG' }}
            selectedRows={selectedRows}
            setSelectedRowKeys={setSelectedRowKeys}
          />
          <Table<GoodsLogData>
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            dataSource={logData}
            size={tableSize}
            pagination={{
              total: totalLogs,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            expandable={{
              rowExpandable: (record) => (record.changeLogList ? true : false),
              expandedRowRender: (record, index) => (
                <Table
                  key={index}
                  size={tableSize}
                  dataSource={record.changeLogList}
                  columns={changeLogListColumns}
                  pagination={{
                    pageSize: 10,
                    hideOnSinglePage: true,
                    showTotal: (total, range) =>
                      t('general.paginationTotal', {
                        start: range[0],
                        end: range[1],
                        total: total,
                      }),
                  }}
                  rowKey={(record) => record.propertyName}
                />
              ),
              columnWidth: 30,
            }}
            loading={loading}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowSelection={rowSelection}
            rowKey={(log) => log.logId}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows.map((log) => ({
                ...log,
                adminUser: log.adminUser ? log.adminUser.adminUserName : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{
                deleteFunc: hasPermission(
                  actionPermissions.goodGroup.goodManage
                )
                  ? handleDelete
                  : undefined,
                exportConfig: { fileName: 'GOODS_LOG' },
              }}
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default GoodsLog;
