import { Typography } from 'antd';

import {
  PRINTOUT_RULES_FONT_SIZE,
  PRINTOUT_SUBTITLE_FONT_SIZE,
} from '../../../constants/printoutConstants';
import { PrintoutOrderFooterData } from '../../../types';

type OrderPrintoutFooterProps = {
  footer: PrintoutOrderFooterData;
};

const OrderPrintoutFooter = ({ footer }: OrderPrintoutFooterProps) => {
  //General Components

  return (
    <div
      style={{
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Typography.Text
        style={{ fontWeight: 'bold', fontSize: PRINTOUT_SUBTITLE_FONT_SIZE }}
      >{`${footer.bottomTitle}`}</Typography.Text>
      {footer.bottomContents.map((content, index) => {
        return (
          <Typography.Paragraph
            key={index}
            style={{ margin: 0, fontSize: PRINTOUT_RULES_FONT_SIZE }}
          >{`${content}`}</Typography.Paragraph>
        );
      })}
    </div>
  );
};

export default OrderPrintoutFooter;
