import { Card } from 'antd';
import Container from '../../components/Container';
import TaskListTable from '../../components/sortationSystem/TaskListTable';

const TaskList = () => {
  return (
    <Container>
      <Card>
        <TaskListTable />
      </Card>
    </Container>
  );
};

export default TaskList;
