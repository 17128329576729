import {
  Switch,
    Form,
    Input,
    Modal,
    Spin,
  } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
  import { useEffect, useRef, useState } from 'react';
  import { useTranslation } from 'react-i18next';
  import { ShippingData } from '../../../types';
  import { alertMessage } from '../../../utils/alertMessage';
  import { postDataWithAuthToken } from '../../../utils/axiosRequest';
  
  type ShippingModalProps = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    shippingInfo?: ShippingData;
    refresh?: () => void;
  };
  
  const ShippingModal = ({
    visible,
    setVisible,
    shippingInfo,
    refresh,
  }: ShippingModalProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const isSubscribed = useRef(true);

    // Sets isSubscribed to false if component becomes unmounted
    useEffect(() => {
      return () => {
        isSubscribed.current = false;
      };
    }, []);
  
    useEffect(() => {
      if (isSubscribed.current && visible) {
        form.resetFields();
     
      }
    }, [form, visible, shippingInfo]);
  
    const onFinish = () => {
      form
        .validateFields()
        .then((values) => {
          if (isSubscribed.current) setIsLoading(true);
          postDataWithAuthToken('shipping/edit', {
            ...values,
            shippingId: shippingInfo ? shippingInfo.shippingId : undefined
          })
            .then((response) => {
              if (response) {
                if (response.goodStatus) {
                  if (isSubscribed.current) setVisible(false);
                  if (refresh) refresh();
                  alertMessage(
                    'success',
                    t('settings.alerts.shippingEdited')
                  );
                } else {
                  alertMessage(
                    'error',
                    response?.msg || t('general.noResponse'),
                    response?.data || undefined
                  );
                }
              }
              if (isSubscribed.current) setIsLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setIsLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    };
  
    const onClose = () => {
      if (isSubscribed.current) {
        setVisible(false);
      }
    };
  
    return (
      <Modal
        title={
          `${t('settings.add/editShipping.editTitle')} ${shippingInfo && shippingInfo.shippingId}`
        }
        visible={visible}
        okText={t('settings.add/editShipping.ok')}
        cancelText={t('settings.add/editShipping.cancel')}
        onOk={onFinish}
        onCancel={onClose}
      >
        <Spin spinning={isLoading}>
          <Form
            form={form}
            layout="vertical"
            initialValues={shippingInfo ? { ...shippingInfo } : {}}
          >
            <Form.Item name= "shippingName"
              label={t('settings.add/editShipping.shippingName')}
            >
              <Input />
            </Form.Item>
            <Form.Item name="insureFee"
              label={t('settings.add/editShipping.insureFee')}
            >
              <Input />
            </Form.Item>
            <Form.Item name="isEnabled"
              valuePropName="checked"
              label={t('settings.add/editShipping.isEnabled')}
            >
              <Switch />
            </Form.Item>
            <Form.Item name="shippingDesc"
              label={t('settings.add/editShipping.shippingDesc')}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  };
  
  export default ShippingModal;
  
  