import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Grid,
  Popover,
  Progress,
  Space,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import TableToolbar from '../../components/table/TableToolbar';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { FileData, ProgressData, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import { FilterOutlined } from '@ant-design/icons';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import moment from 'moment';
import { compare, setFont } from '../../utils/colComponents';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import ExcelModal from '../../components/settings/ExcelModal';
import { isExcelUrl, tableScrollToTop } from '../../utils/helperFunction';

const FileManager = () => {
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<FileData[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItems, setTotalItems] = useState(0);
  const isSubscribed = useRef(true);
  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const screens = useBreakpoint();
  const columnKeys = [
    'fileId',
    'userId',
    'fileName',
    'progress',
    'statusMessage',
    'addTime',
    'actions',
  ];
  const [keyword, setKeyword] = useState('');
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [inProgressList, setInProgressList] = useState<ProgressData[]>([]);
  const [selectedRows, setSelectedRows] = useState<FileData[]>([]);
  const [excelModalVisible, setExcelModalVisible] = useState<boolean>(false);
  const [selectedExcel, setSelectedExcel] = useState<
    { path: string; name: string; addTime?: string } | undefined
  >(undefined);
  const { RangePicker } = DatePicker;
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columns: ColumnsType<FileData> = [
    {
      title: setFont(t('fileManager.fileManagerColumns.id'), fontSize),
      dataIndex: 'fileId',
      key: 'fileId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: FileData, b: FileData) => compare(a.fileId, b.fileId),
    },
    {
      title: setFont(t('fileManager.fileManagerColumns.adminId'), fontSize),
      dataIndex: 'userId',
      key: 'userId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: FileData, b: FileData) => compare(a.userId, b.userId),
    },
    {
      title: setFont(t('fileManager.fileManagerColumns.fileName'), fontSize),
      dataIndex: 'fileName',
      key: 'fileName',
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: FileData, b: FileData) => compare(a.fileName, b.fileName),
    },
    {
      title: setFont(t('fileManager.fileManagerColumns.progress'), fontSize),
      key: 'progress',
      width: 200,
      render: (record: FileData) => {
        let file = inProgressList.find((e) => record.fileId === e.fileId);
        return (
          <Progress
            percent={
              file && !file.isFinished
                ? file.progressValue || undefined
                : record.status === 2
                ? undefined
                : 100
            }
            status={
              file && file.errorMessage
                ? 'exception'
                : file && !file.isFinished
                ? 'active'
                : record.status === 2
                ? 'exception'
                : undefined
            }
            style={{ paddingRight: 10, fontSize: fontSize }}
          />
        );
      },
    },
    {
      title: setFont(
        t('fileManager.fileManagerColumns.statusMessage'),
        fontSize
      ),
      dataIndex: 'statusMessage',
      key: 'statusMessage',
      width: 200,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('fileManager.fileManagerColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: FileData, b: FileData) => compare(a.addTime, b.addTime),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      width: 120,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: FileData) => (
        <Space size="small" wrap>
          {record.status === 1 && (
            <Space>
              <Button
                disabled={
                  !hasPermission(actionPermissions.settingGroup.fileManager)
                }
                type="link"
                style={{ padding: 0, fontSize: fontSize }}
                href={record.filePath}
                target="_blank"
                rel="noreferrer"
                download
              >
                {t('actionsColumn.download')}
              </Button>
              {isExcelUrl(record.filePath) && (
                <Button
                  disabled={
                    !hasPermission(actionPermissions.settingGroup.fileManager)
                  }
                  type="link"
                  style={{ padding: 0, fontSize: fontSize }}
                  onClick={() => {
                    setExcelModalVisible(true);
                    setSelectedExcel({
                      path: record.filePath,
                      name: record.fileName,
                      addTime: record.addTime,
                    });
                  }}
                >
                  {t('actionsColumn.view')}
                </Button>
              )}
            </Space>
          )}
        </Space>
      ),
    },
  ];

  const [advance, setAdvance] = useState<{
    startDate?: string;
    endDate?: string;
  }>({ startDate: '', endDate: '' });

  // Delete files
  const onDeleteFiles = async () => {
    try {
      const result = await postDataWithAuthToken('file_manager/delete_batch', {
        fileIds: selectedRowKeys,
      });
      if (result && result.goodStatus) {
        getFileList();
        alertMessage('success', t('fileManager.alerts.fileDeleted'));
      } else
        alertMessage(
          'error',
          result?.msg || t('general.noResponse'),
          result?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Check file status
   * @param fileIds number[] Array of file IDs
   */
  const checkStatus = useCallback(
    async (fileIds: number[]) => {
      getDataWithAuthToken('file_manager/status', {
        params: { fileIds: fileIds.join() },
      })
        .then((response) => {
          if (response) {
            if (response.goodStatus) {
              if (isSubscribed.current) setInProgressList(response.data);
            } else {
              alertMessage(
                'error',
                t('fileManager.alerts.errorOccurred'),
                response?.msg || t('general.noResponse')
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [t]
  );

  // Advance search pop up component
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('fileManager.fileManagerColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item
            name="date"
            label={t('fileManager.fileManagerColumns.advancedSearch.date')}
          >
            <RangePicker
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      startDate: moment(values[0]).format(DATE_FORMAT),
                      endDate: moment(values[1]).format(DATE_FORMAT),
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      startDate: '',
                      endDate: '',
                    }));
              }}
              placeholder={[
                t('fileManager.fileManagerColumns.advancedSearch.startDate'),
                t('fileManager.fileManagerColumns.advancedSearch.endDate'),
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getFileList(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('fileManager.fileManagerColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={!advance.startDate && !advance.endDate}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getFileList();
                }}
              >
                {t('fileManager.fileManagerColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('fileManager.fileManagerColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  // Get file list
  const getFileList = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('file_manager/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        startDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
            : undefined,
        endDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
            : undefined,
      },
    })
      .then(async (response) => {
        if (response) {
          if (response.goodStatus && response.data) {
            if (isSubscribed.current) {
              setFileList(response.data.list);
              setTotalItems(response.data.totalItem);
              setSelectedRowKeys([]);
              let fileIds: number[] = [];
              for (let e of response.data.list) {
                if (e.status === 0) fileIds.push(e.fileId);
              }
              if (fileIds.length) {
                await checkStatus(fileIds);
              }
              setIsLoading(false);

              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            if (isSubscribed.current) {
              setFiveHundred(true);
              setIsLoading(false);
            }
            alertMessage(
              'error',
              t('fileManager.alerts.errorOccurred'),
              response?.msg || t('general.noResponse')
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [page, pageSize, keyword, form, t, checkStatus]);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getFileList();
  }, [getFileList]);

  useEffect(() => {
    // Fetch file status every 5 seconds
    const timer = setInterval(async () => {
      if (inProgressList.length) {
        if (inProgressList.find((e) => !e.isFinished && !e.errorMessage)) {
          let fileIds = [];
          for (let e of inProgressList) {
            fileIds.push(e.fileId);
          }
          await checkStatus(fileIds);
        } else {
          if (isSubscribed.current) setInProgressList([]);
          getFileList();
        }
      }
      clearInterval(timer);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [checkStatus, getFileList, inProgressList]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('fileManager.title')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            totalItems={totalItems}
            refresh={() => {
              getFileList();
            }}
            tableSize={tableSize}
            setTableSize={setTableSize}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            advancedSearch={advancedSearch}
          />
          <Table<FileData>
            dataSource={fileList}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(file) => file.fileId}
            rowSelection={{
              selectedRowKeys,
              onChange: (rowKeys: React.Key[], rows: FileData[]) => {
                setSelectedRowKeys(rowKeys);
                setSelectedRows(rows);
              },
            }}
            loading={isLoading}
            pagination={{
              total: totalItems,
              pageSize: pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ deleteFunc: () => onDeleteFiles() }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <ExcelModal
        visible={excelModalVisible}
        setVisible={setExcelModalVisible}
        setSelectedExcel={setSelectedExcel}
        selectedExcel={selectedExcel}
      />
    </Container>
  );
};

export default FileManager;
