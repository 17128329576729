import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Menu,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import FormFieldModal from '../../components/settings/FormFieldModal';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { FIELD_TYPE_GROUP } from '../../constants/settingsConstants';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { FontSizeType, FormFieldData, SellerData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const FormField = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [searchAdvance, setSearchAdvance] = useState<{
    [key: string]: string | number;
  }>({});
  const [formFields, setFormFields] = useState<FormFieldData[]>([]);
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const screens = Grid.useBreakpoint();
  const [editingField, setEditingField] = useState<FormFieldData>();
  const [action, setAction] = useState<'add' | 'edit'>('add');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const columnKeys: string[] = [
    'fieldId',
    'chineseFieldName',
    'englishFieldName',
    'fieldType',
    'fieldCode',
    'isRequired',
    'isShow',
    'seller',
    'sortOrder',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);

  const columns: ColumnsType<FormFieldData> = [
    {
      title: setFont(t('settings.formFieldColumns.fieldId'), fontSize),
      key: 'fieldId',
      dataIndex: 'fieldId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: number, record: FormFieldData) => (
        <Button
          type="link"
          size="small"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setAction('edit');
            setEditingField(record);
            setShowModal(true);
          }}
          disabled={
            !hasPermission(actionPermissions.settingGroup.formFieldConfig)
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('settings.formFieldColumns.englishFieldName'), fontSize),
      key: 'englishFieldName',
      dataIndex: 'englishFieldName',
      render: (text: string) => setFont(`${text}`, fontSize),
    },
    {
      title: setFont(t('settings.formFieldColumns.chineseFieldName'), fontSize),
      key: 'chineseFieldName',
      dataIndex: 'chineseFieldName',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.formFieldColumns.fieldType'), fontSize),
      key: 'fieldType',
      dataIndex: 'fieldType',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.formFieldColumns.fieldCode'), fontSize),
      key: 'fieldCode',
      dataIndex: 'fieldCode',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.formFieldColumns.isRequired'), fontSize),
      key: 'isRequired',
      dataIndex: 'isRequired',
      width: 100,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('settings.formFieldColumns.isShow'), fontSize),
      key: 'isShow',
      dataIndex: 'isShow',
      width: 100,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('settings.formFieldColumns.seller'), fontSize),
      key: 'seller',
      dataIndex: 'seller',
      width: 100,
      render: (seller: SellerData) =>
        seller && setFont(`${seller.shopName}`, fontSize),
    },
    {
      title: setFont(t('settings.formFieldColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      render: (text: number) => setFont(`${text}`, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 120,
      render: (record: FormFieldData) => (
        <Space>
          <Button
            type="link"
            size="small"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setAction('edit');
              setEditingField(record);
              setShowModal(true);
            }}
            disabled={
              !hasPermission(actionPermissions.settingGroup.formFieldConfig)
            }
          >
            {t('actionsColumn.edit')}
          </Button>
          {record.children ? (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item
                    key="addField"
                    style={{ fontSize: fontSize }}
                    onClick={() => {
                      setAction('add');
                      setEditingField(record);
                      setShowModal(true);
                    }}
                  >
                    {t('actionsColumn.addField')}
                  </Menu.Item>
                  <Menu.Item key="deleteField" style={{ fontSize: fontSize }}>
                    <Popconfirm
                      title={t('actionsColumn.deleteWarning')}
                      okText={t('actionsColumn.confirmation.yes')}
                      cancelText={t('actionsColumn.confirmation.no')}
                      placement="leftTop"
                      onConfirm={() => onDelete(record)}
                    >
                      <Typography.Text type="danger">
                        {t('actionsColumn.delete')}
                      </Typography.Text>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                disabled={
                  !hasPermission(actionPermissions.settingGroup.formFieldConfig)
                }
                type="link"
                style={{ padding: 0, fontSize: fontSize }}
              >
                {t('actionsColumn.more')}
                <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Popconfirm
              title={t('actionsColumn.deleteWarning')}
              okText={t('actionsColumn.confirmation.yes')}
              cancelText={t('actionsColumn.confirmation.no')}
              placement="leftTop"
              onConfirm={() => onDelete(record)}
              disabled={
                !hasPermission(actionPermissions.settingGroup.formFieldConfig)
              }
            >
              <Button
                type="link"
                style={{ padding: 0, fontSize: fontSize }}
                disabled={
                  !hasPermission(actionPermissions.settingGroup.formFieldConfig)
                }
              >
                <Typography.Text type="danger">
                  {t('actionsColumn.delete')}
                </Typography.Text>
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const onDelete = (field: FormFieldData) => {
    setIsLoading(true);
    postDataWithAuthToken('form_field/delete', { fieldId: field.fieldId })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            field.fieldType === FIELD_TYPE_GROUP
              ? alertMessage('success', t('settings.alerts.formDeleted'))
              : alertMessage('success', t('settings.alerts.fieldDeleted'));
            getFormFields();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getFormFields = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('form_field/list', {
      params: {
        isSeller: isSeller,
        sellerId: formRef.current && form.getFieldValue('sellerId'),
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setFormFields(
              response.data.list.map((field: FormFieldData) => ({
                ...field,
                key: field.fieldId,
              }))
            );
            setTotalItems(response.data.list.length);

            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else setFiveHundred(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [t, isSeller, form]);

  useEffect(() => {
    getFormFields();
  }, [getFormFields]);

  const advancedSearch = (
    <Popover
      title={t('goods.goodsListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          <Form.Item
            label={t('goods.goodsListColumns.advancedSearch.sellerId')}
            name="sellerId"
            style={{ marginBottom: 12 }}
          >
            <SellersDropdown
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            />
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => getFormFields(), GENERAL_TIMEOUT)
                );
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(searchAdvance).every(
                (value) => value !== undefined && !value
              )}
              onClick={() => {
                form.resetFields();
                setSearchAdvance({});
                getFormFields();
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Button icon={<FilterOutlined />}>
        {t('goods.goodsListColumns.advancedSearch.title')}
      </Button>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('settings.formFields')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                disabled={
                  !hasPermission(actionPermissions.settingGroup.formFieldConfig)
                }
                loading={isLoading}
                checkedChildren={t('goods.goodsListColumns.seller')}
                unCheckedChildren={t('goods.goodsListColumns.self')}
                style={{ marginBottom: 12 }}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                    }, EXTENDED_TIMEOUT)
                  );
                }}
              />
            )}
          </Space>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setAction('add');
                  setEditingField(undefined);
                  setShowModal(true);
                }}
                disabled={
                  !hasPermission(actionPermissions.settingGroup.formFieldConfig)
                }
              >
                {t('settings.add/editFormField.addTitle')}
              </Button>
            }
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            tableSize={tableSize}
            setTableSize={setTableSize}
            totalItems={totalItems}
            refresh={() => getFormFields()}
            advancedSearch={isSeller ? advancedSearch : undefined}
          />
          <Table
            dataSource={formFields}
            loading={isLoading}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(record) => record.fieldId}
            size={tableSize}
            pagination={false}
          />
        </Card>
      )}
      <FormFieldModal
        visible={showModal}
        setVisible={setShowModal}
        action={action}
        fieldInfo={editingField}
        refresh={() => getFormFields()}
        isSeller={isSeller}
      />
    </Container>
  );
};

export default FormField;
