import Table from 'antd/lib/table';
import moment from 'moment';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderBatchData,
  OrderBatchExcelHeaderProps,
  OrderBatchGoodsData,
} from '../../../types';
import { DATE_FORMAT } from '../../../constants/systemConstants';

type SelectExcelDataProps = {
  excelData: { [key: string]: any }[];
  batchOrderData: OrderBatchData[];
  setBatchOrderData: React.Dispatch<SetStateAction<OrderBatchData[]>>;
  selectedBatchOrderData: OrderBatchData | undefined;
  setSelectedBatchOrderData: React.Dispatch<
    SetStateAction<OrderBatchData | undefined>
  >;
};

const SelectExcelData = ({
  excelData,
  batchOrderData,
  setBatchOrderData,
  selectedBatchOrderData,
  setSelectedBatchOrderData,
}: SelectExcelDataProps) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    selectedBatchOrderData ? [selectedBatchOrderData.storeId] : []
  );

  const groupDataByStore = useCallback(() => {
    // group excel by StoreID
    const formattedExcel = excelData.reduce((acc, row) => {
      const storeID = row['StoreID'];

      if (!isNaN(storeID) && storeID !== '') {
        if (!acc[storeID]) {
          acc[storeID] = [];
        }
        acc[storeID].push(row);
      }
      return acc;
    }, {});

    // Transform the grouped data into the OrderBatchData format
    const updatedBatchOrderData: OrderBatchData[] = Object.keys(
      formattedExcel
    ).map((storeID) => {
      const storeData: OrderBatchExcelHeaderProps[] = formattedExcel[storeID];

      const goods: OrderBatchGoodsData[] = [];
      storeData.forEach((row: OrderBatchExcelHeaderProps) => {
        if (row.ItemID && Number(row['Order(Cs)'])) {
          goods.push({
            goodsSn: row.ItemID,
            goodsName: row.Chinese ? `${row.Chinese} ${row.Description}` : '',
            goodsUnit: row.Pack ? `${row.Pack} ${row.Size}` : '',
            goodsNumber: Number(row['Order(Cs)']),
            price: row['Price/Cs'].replace('$', '').replace(/\s/g, ''),
          });
        }
      });

      const deliveryDate = storeData[0]['DeliveryDate']
        ? moment(new Date(storeData[0]['DeliveryDate']))
        : undefined;
      return {
        storeId: storeID,
        storeName: storeData[0]['Store'] ? storeData[0]['Store'] : '',
        deliveryDate: deliveryDate,
        goods,
        totalNumber: goods.length,
      };
    });

    setBatchOrderData(updatedBatchOrderData);
    // 如果没有设置的话，默认选择第一个
    if (
      !selectedBatchOrderData &&
      updatedBatchOrderData &&
      updatedBatchOrderData.length > 0
    ) {
      setSelectedBatchOrderData(updatedBatchOrderData[0]);
      setSelectedRowKeys([updatedBatchOrderData[0].storeId]);
    }
  }, [
    excelData,
    setBatchOrderData,
    selectedBatchOrderData,
    setSelectedBatchOrderData,
    setSelectedRowKeys,
  ]);

  useEffect(() => {
    if (excelData.length > 0) {
      groupDataByStore();
    }
  }, [excelData, groupDataByStore]);

  /*** Store/User Table */
  const storeTableColumns = [
    {
      title: t('order.add/editOrder.batchOrder.column.storeId'),
      width: 40,
      dataIndex: 'storeId',
      render: (text: string) => text,
    },
    {
      title: t('order.add/editOrder.batchOrder.column.storeName'),
      width: 100,
      dataIndex: 'storeName',
      render: (text: string) => text,
    },
    {
      title: t('order.add/editOrder.batchOrder.column.deliveryDate'),
      width: 100,
      dataIndex: 'deliveryDate',
      render: (text: string, record: OrderBatchData) =>
        record.deliveryDate
          ? record.deliveryDate.format(DATE_FORMAT)
          : undefined,
    },
    {
      title: t('order.add/editOrder.batchOrder.column.totalNumber'),
      width: 100,
      dataIndex: 'totalNumber',
      render: (text: string) => text,
    },
  ];

  const expandedRowRender = (goods: OrderBatchGoodsData[]) => {
    const columns = [
      {
        title: t('goods.goodsListColumns.goodsSn'),
        width: 40,
        dataIndex: 'goodsSn',
        render: (text: string) => text,
      },
      {
        title: t('order.orderGoodsListColumns.goodsName'),
        width: 100,
        dataIndex: 'goodsName',
        render: (text: string) => text,
      },
      {
        title: t('order.orderGoodsListColumns.goodsUnit'),
        width: 100,
        dataIndex: 'goodsUnit',
        render: (text: string) => text,
      },
      {
        title: t('order.orderGoodsListColumns.goodsNumber'),
        width: 100,
        dataIndex: 'goodsNumber',
        render: (text: string) => text,
      },
      {
        title: t('order.orderGoodsListColumns.goodsPrice'),
        width: 100,
        dataIndex: 'price',
        render: (text: string) => text,
      },
    ];

    return <Table columns={columns} dataSource={goods} pagination={false} />;
  };

  return (
    <Table
      dataSource={batchOrderData}
      columns={storeTableColumns}
      scroll={{ x: 'max-content', y: 600 }}
      rowKey={(record) => record.storeId}
      rowSelection={{
        type: 'radio',
        defaultSelectedRowKeys: selectedRowKeys,
        selectedRowKeys,
        onChange: (
          selectedRowKeys: React.Key[],
          selectedRows: OrderBatchData[]
        ) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedBatchOrderData(selectedRows[0]);
        },
      }}
      expandable={{
        rowExpandable: (record) =>
          record.totalNumber > 0 && record.goods ? true : false,
        expandedRowRender: (record) => {
          return expandedRowRender(record.goods);
        },
      }}
      pagination={false}
    />
  );
};

export default SelectExcelData;
