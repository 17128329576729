import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Table, Modal, Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../constants/actionPermissions';
import { GREEN1, HIGHLIGHT_ORANGE } from '../constants/color';
import { hasPermission } from '../utils/hasPermission';
import { useTab } from '../hooks/useTab';
import { dashboardRoute } from '../constants/pathname';

type BatchFailedModalProps = {
  failedObjects: {
    dataType?: 'GOODS' | 'ORDER' | 'TASK';
    data?: any[];
    status?: string;
  };
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys?: any[];
  loading?: boolean;
  callback?: () => void;
};

const BatchFailedModal = ({
  failedObjects,
  visible,
  setVisible,
  selectedRowKeys,
  loading,
  callback,
}: BatchFailedModalProps) => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const { addTab } = useTab();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const closeModal = () => {
    callback && callback();
    setVisible(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: t(`general.batchFailedTableColumns.id`),
      key: 'id',
      width: 80,
      render: (record: any) => (
        <Button
          type="link"
          disabled={
            failedObjects.dataType === 'GOODS'
              ? !hasPermission(actionPermissions.goodGroup.goodView)
              : !hasPermission(actionPermissions.orderGroup.orderView)
          }
          onClick={() => {
            addTab(
              '',
              failedObjects.dataType === 'GOODS'
                ? `${dashboardRoute.goods.detail}?good_id=${record.id}`
                : `${dashboardRoute.order.detail}?order_id=${record.id}`
            );
          }}
          style={{ padding: 0 }}
        >
          {record.id}
        </Button>
      ),
    },
    {
      title: t(`general.batchFailedTableColumns.basicInfo`),
      key: 'basicInfo',
      width: 200,
      render: (record: any) =>
        failedObjects.dataType === 'ORDER'
          ? record.data.orderSn
          : failedObjects.dataType === 'GOODS'
          ? record.data.fullGoodsName
          : '',
    },
    {
      title: t(`general.batchFailedTableColumns.reason`),
      key: 'reason',
      render: (record: any) => record.errorMsg,
    },
  ];

  return (
    <Modal
      title={
        loading ? null : failedObjects && failedObjects.status === 'success' ? (
          <Space>
            <CheckCircleOutlined style={{ color: GREEN1 }} />
            <Typography.Text>{`${t(
              'general.batchFailedActions.successTitle'
            )} (${t(
              `general.batchFailedTableColumns.title.${failedObjects.dataType}`
            )})`}</Typography.Text>
          </Space>
        ) : (
          failedObjects.dataType && (
            <Space>
              <WarningOutlined style={{ color: HIGHLIGHT_ORANGE }} />
              <Typography.Text>{`${t('general.batchFailedActions.title')} (${t(
                `general.batchFailedTableColumns.title.${failedObjects.dataType}`
              )})`}</Typography.Text>
            </Space>
          )
        )
      }
      visible={visible}
      footer={null}
      width={loading ? 250 : 768}
      centered={loading}
      closable={!loading}
      maskClosable={!loading}
      onCancel={closeModal}
    >
      {loading ? (
        <Space
          align="center"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <LoadingOutlined />
          <Typography.Text>{t('general.batchLoading')}</Typography.Text>
        </Space>
      ) : (
        <Table
          components={vt}
          scroll={{ y: 600 }}
          dataSource={failedObjects.data}
          columns={columns}
          size="small"
          pagination={false}
          rowKey={(record) => record.id}
          footer={() =>
            failedObjects &&
            failedObjects.data && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {`${t('general.batchFailedActions.total')} ${
                  selectedRowKeys && selectedRowKeys.length
                } ${t('general.items')} (${t(
                  'general.batchFailedActions.success'
                )}: ${
                  selectedRowKeys &&
                  selectedRowKeys.length - failedObjects.data.length
                } | ${t('general.batchFailedActions.fail')}: ${
                  failedObjects.data.length
                })`}
              </div>
            )
          }
        />
      )}
    </Modal>
  );
};

export default BatchFailedModal;
