import { useEffect, useRef } from 'react';
import { Descriptions, Typography } from 'antd';
import { SellerDetailData } from '../../../types';
import { useTranslation } from 'react-i18next';

type GradeDetailsProps = {
  sellerInfo: SellerDetailData;
  callBack?: () => void;
};

const GradeDetails = ({ sellerInfo }: GradeDetailsProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Descriptions>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.sellerShopGrade.gradeId')}
      >
        {sellerInfo.config.sellerShopGrade
          ? sellerInfo.config.sellerShopGrade.gradeId!
            ? sellerInfo.config.sellerShopGrade.gradeId!
            : ''
          : ''}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.sellerShopGrade.gradeName')}
        span={2}
      >
        {sellerInfo.config.sellerShopGrade
          ? sellerInfo.config.sellerShopGrade.gradeName!
            ? sellerInfo.config.sellerShopGrade.gradeName!
            : ''
          : ''}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.sellerShopGrade.gradeDesc')}
        span={3}
      >
        {sellerInfo.config.sellerShopGrade
          ? sellerInfo.config.sellerShopGrade.gradeDesc!
            ? sellerInfo.config.sellerShopGrade.gradeDesc!.toString
            : ''
          : ''}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('sellers.sellerDetailVariables.sellerShopGrade.actionList')}
        span={3}
      >
        <Typography.Paragraph
          style={{ maxHeight: 75, overflow: 'auto' }}
          ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
        >
          {sellerInfo.config.sellerShopGrade
            ? sellerInfo.config.sellerShopGrade.actionList!
              ? sellerInfo.config.sellerShopGrade.actionList!
              : ''
            : ''}
        </Typography.Paragraph>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default GradeDetails;
