import { Form, Input, Modal, Spin, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CouponData } from '../../types';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import UsersDropdown from '../users/common/UsersDropdown';

type CouponUserModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  couponId?: number;
  refresh: Function;
  couponUserParent?: CouponData;
};

const CouponUserModal = ({
  couponId,
  couponUserParent,
  visible,
  setVisible,
  refresh,
}: CouponUserModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  /**
   * @param values Form values
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        const { couponMoney, ...rest } = values;
        postDataWithAuthToken('coupon/user/add', {
          ...rest,
          couponId: couponId,
          couponMoney: couponUserParent?.isFlexCouponMoney
            ? values.couponMoney
            : undefined,
        }).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage('success', t('activity.alerts.userCouponAdded'));
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }

          if (isSubscribed.current) setLoading(false);
        });
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={`${t('activity.addCouponUser.addTitle')} ${
        couponUserParent && couponUserParent.couponName
      }`}
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          initialValues={{
            couponMoney:
              couponUserParent && couponUserParent.isFlexCouponMoney
                ? couponUserParent.couponMoney
                : '',
          }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
            name="userId"
            label={t('activity.addCouponUser.userId')}
          >
            <UsersDropdown />
          </Form.Item>
          <Form.Item
            name="couponMoney"
            label={t('activity.addCouponUser.couponMoney')}
            rules={[
              {
                required:
                  couponUserParent && couponUserParent.isFlexCouponMoney,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            {couponUserParent && couponUserParent.isFlexCouponMoney ? (
              <Input></Input>
            ) : (
              <Typography.Text>
                {couponUserParent && couponUserParent.couponMoney}
              </Typography.Text>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CouponUserModal;
