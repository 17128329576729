import { PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Typography,
  Table,
  Grid,
  Space,
  Button,
  Popconfirm,
  Switch,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import SellerGradeModal from '../../components/sellers/SellerGradeModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { FontSizeType, SellerGradeData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const { useBreakpoint } = Grid;

const SellerGrade = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = useBreakpoint();
  const [showModal, setShowModal] = useState(false);
  const [editGrade, setEditGrade] = useState<SellerGradeData>();
  const [isLoading, setIsLoading] = useState(true);
  const [grades, setGrades] = useState<SellerGradeData[]>([]);
  const [keyword, setKeyword] = useState('');
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const isSubscribed = useRef(true);
  const columnKeys = [
    'gradeId',
    'gradeName',
    'maxGoodsNumber',
    'gradeDesc',
    'isEnabled',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SellerGradeData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columns: ColumnsType<SellerGradeData> = [
    {
      title: setFont(t('sellers.gradeListColumns.id'), fontSize),
      key: 'gradeId',
      dataIndex: 'gradeId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: (a: SellerGradeData, b: SellerGradeData) =>
        compare(a.gradeId, b.gradeId),
      render: (text: string, record: SellerGradeData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.sellerGroup.sellerGrade)}
          onClick={() => {
            setEditGrade(record);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('sellers.gradeListColumns.gradeName'), fontSize),
      key: 'gradeName',
      dataIndex: 'gradeName',
      width: 120,
      sorter: (a: SellerGradeData, b: SellerGradeData) =>
        compare(a.gradeName, b.gradeName),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.gradeListColumns.maxGoods'), fontSize),
      key: 'maxGoodsNumber',
      dataIndex: 'maxGoodsNumber',
      width: 100,
      sorter: (a: SellerGradeData, b: SellerGradeData) =>
        compare(a.maxGoodsNumber, b.maxGoodsNumber),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.gradeListColumns.gradeDesc'), fontSize),
      key: 'gradeDesc',
      dataIndex: 'gradeDesc',
      width: 180,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.gradeListColumns.isEnabled'), fontSize),
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      width: 100,
      render: (value: boolean, record: SellerGradeData) => (
        <Switch
          disabled={!hasPermission(actionPermissions.sellerGroup.sellerGrade)}
          checked={value}
          onChange={(checked) => updateGrade('isEnabled', checked, record)}
        />
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: SellerGradeData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditGrade(record);
              setShowModal(true);
            }}
            disabled={!hasPermission(actionPermissions.sellerGroup.sellerGrade)}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => handleDeleteGrade(record.gradeId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.sellerGroup.sellerGrade)}
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerGrade)
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const updateGrade = (
    key: string,
    value: string | number | boolean,
    grade: SellerGradeData
  ) => {
    postDataWithAuthToken('seller/grade/edit', {
      ...grade,
      actionList: grade.actionList || null,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getGradeData();
            alertMessage('success', t('sellers.alerts.gradeEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Delete seller grade by ID
  const handleDeleteGrade = async (gradeId: number) => {
    try {
      const response = await postDataWithAuthToken('seller/grade/delete', {
        gradeId: gradeId,
      });
      if (response && response.goodStatus) {
        getGradeData();
        alertMessage('success', t('sellers.alerts.gradeDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  const getGradeData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('seller/grade/list', {
      params: { keyword: keyword || undefined },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setGrades(response.data.list);
              setTotal(response.data.list.length);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, keyword]);

  useEffect(() => {
    getGradeData();
  }, [getGradeData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('sellers.sellersGrades')}
          </Typography.Title>
          <TableToolbar
            searchPlaceholder={t('searchPlaceholders.searchSellerGrade')}
            leftElement={
              <Button
                disabled={
                  !hasPermission(actionPermissions.sellerGroup.sellerGrade)
                }
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditGrade(undefined);
                  setShowModal(true);
                }}
              >
                {t('sellers.add/editGrade.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            fontSize={fontSize}
            setFontSize={setFontSize}
            refresh={() => getGradeData()}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
            }}
          />
          <Table<SellerGradeData>
            dataSource={grades}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            loading={isLoading}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(grade) => grade.gradeId}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: SellerGradeData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',

              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'SELLER_GRADE_LIST' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <SellerGradeModal
        visible={showModal}
        setVisible={setShowModal}
        grade={editGrade}
        refresh={() => getGradeData()}
      />
    </Container>
  );
};

export default SellerGrade;
