import { PlusOutlined } from '@ant-design/icons';
import {
  Switch,
  Form,
  Input,
  Modal,
  Spin,
  Space,
  Typography,
  Button,
  Table,
  Popconfirm,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import {
  FontSizeType,
  GoodsTypeAttributeData,
  GoodsTypeData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import TableFooterToolbar from '../table/TableFooterToolbar';
import TableToolbar from '../table/TableToolbar';
import GoodsTypeAttrModal from './GoodsTypeAttrModal';

type GoodsTypeModalProps = {
  viewType: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  goodsTypeInfo?: GoodsTypeData;
  refresh?: () => void;
};

const GoodsTypeModal = ({
  viewType,
  visible,
  setVisible,
  goodsTypeInfo,
  refresh,
}: GoodsTypeModalProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showTypeAttrModal, setShowTypeAttrModal] = useState(false);
  const [called, setCalled] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  //Data Components
  const [goodsTypeAttrData, setGoodsTypeAttrData] = useState<
    GoodsTypeAttributeData[]
  >([]);
  const [goodsTypeAttrInfo, setGoodsTypeAttrInfo] =
    useState<GoodsTypeAttributeData>();
  //Table Components
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const screens = useBreakpoint();
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<GoodsTypeAttributeData[]>(
    []
  );

  const columns: ColumnsType<GoodsTypeAttributeData> = [
    {
      title: setFont(t('goods.goodsTypeAttributeList.specAttrId'), fontSize),
      key: 'specAttrId',
      dataIndex: 'specAttrId',
      width: 80,
      render: (text: string, record: GoodsTypeAttributeData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setGoodsTypeAttrInfo(record);
            setVisible(false);
            setShowTypeAttrModal(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsTypeAttributeList.specAttrName'), fontSize),
      key: 'specAttrName',
      dataIndex: 'specAttrName',
      width: 80,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsTypeAttributeList.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 90,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 40,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: GoodsTypeAttributeData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setGoodsTypeAttrInfo(record);
              setVisible(false);
              setShowTypeAttrModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => handleDeleteGoodsTypeAttr(record.specAttrId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
          >
            <Button
              danger
              type="link"
              size="small"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Delete goodsType Attr by ID
  const handleDeleteGoodsTypeAttr = async (id: number) => {
    try {
      const response = await postDataWithAuthToken(
        'goods/spec_type/attr/delete_batch',
        {
          specAttrIds: [id],
        }
      );
      if (response && response.goodStatus) {
        if (hasPermission(actionPermissions.goodGroup.goodsAttrView))
          getGoodsTypeAttrData();
        alertMessage('success', t('goods.alerts.typeAttrDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  const getGoodsTypeAttrData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/spec_type/attr/list', {
      params: {
        goodsSpecTypeId: goodsTypeInfo && goodsTypeInfo.goodsSpecTypeId,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setGoodsTypeAttrData(response.data.list);
              setCalled(true);
              setTotal(response.data.totalItem);
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          }
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, goodsTypeInfo]);

  /**
   * delete multiple Type Attributes through batch delete
   */
  const deleteTypeAttr = () => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('goods/spec_type/attr/delete_batch', {
      specAttrIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.typeAttrDeleted'));
          if (hasPermission(actionPermissions.goodGroup.goodsAttrView))
            getGoodsTypeAttrData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      viewType !== 'add' &&
        !called &&
        hasPermission(actionPermissions.goodGroup.goodsAttrView) &&
        getGoodsTypeAttrData();
    }
  }, [form, visible, getGoodsTypeAttrData, viewType, called]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(
          goodsTypeInfo ? 'goods/spec_type/edit' : 'goods/spec_type/add',
          {
            ...values,
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  goodsTypeInfo
                    ? t('goods.alerts.typeEdited')
                    : t('goods.alerts.typeAdded')
                );
                onClose();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setSelectedRowKeys([]);
      setVisible(false);
      setCalled(false);
    }
  };

  return (
    <>
      <Modal
        title={
          goodsTypeInfo
            ? `${t('goods.add/view/editTypeList.viewTitle')} ${
                goodsTypeInfo.typeName
              }`
            : t('goods.add/view/editTypeList.addTitle')
        }
        footer={goodsTypeInfo && []}
        visible={visible}
        okText={t('goods.add/view/editTypeList.ok')}
        cancelText={t('goods.add/view/editTypeList.cancel')}
        onOk={onFinish}
        onCancel={onClose}
      >
        <Spin spinning={isLoading}>
          {goodsTypeInfo /**For View Goods Type */ ? (
            <Form
              form={form}
              initialValues={goodsTypeInfo ? { ...goodsTypeInfo } : {}}
            >
              <Space size={30}>
                <Form.Item
                  name="goodsSpecTypeId"
                  label={t('goods.add/view/editTypeList.goodsSpecTypeId')}
                >
                  <Typography.Text>
                    {goodsTypeInfo.goodsSpecTypeId}
                  </Typography.Text>
                </Form.Item>
                <Form.Item
                  name="sellerId"
                  label={t('goods.add/view/editTypeList.sellerId')}
                >
                  <Typography.Text>{goodsTypeInfo.sellerId}</Typography.Text>
                </Form.Item>
                <Form.Item
                  name="typeName"
                  label={t('goods.add/view/editTypeList.typeName')}
                >
                  <Typography.Text>{goodsTypeInfo.typeName}</Typography.Text>
                </Form.Item>
              </Space>
              <TableToolbar
                fontSize={fontSize}
                setFontSize={setFontSize}
                leftElement={
                  <Button
                    icon={<PlusOutlined />}
                    disabled={
                      !hasPermission(actionPermissions.goodGroup.attrManage)
                    }
                    onClick={() => {
                      setGoodsTypeAttrInfo(undefined);
                      setVisible(false);
                      setShowTypeAttrModal(true);
                    }}
                  >
                    {t('goods.add/editGoodsTypeAttribute.addTitle')}
                  </Button>
                }
                tableSize={tableSize}
                setTableSize={setTableSize}
                refresh={() => {
                  if (hasPermission(actionPermissions.goodGroup.goodsAttrView))
                    getGoodsTypeAttrData();
                }}
                totalItems={total}
                columns={columns}
              />
              <Table<GoodsTypeAttributeData>
                dataSource={goodsTypeAttrData}
                rowKey={(specTypeAttr) => specTypeAttr.specAttrId}
                columns={columns}
                scroll={{
                  x: 'max-content',
                  y:
                    goodsTypeAttrData && goodsTypeAttrData.length > 10
                      ? 500
                      : undefined,
                }}
                size={tableSize}
                loading={isLoading}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (
                    selectedRowKeys: React.Key[],
                    selectedRows: GoodsTypeAttributeData[]
                  ) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setSelectedRows(selectedRows);
                  },
                }}
              />
              {!!selectedRowKeys.length && (
                <TableFooterToolbar
                  funct={{
                    exportConfig: { fileName: 'GOODS_TYPE_ATTRIBUTE_LIST' },
                    deleteFunc: deleteTypeAttr,
                  }}
                  selectedRows={selectedRows}
                  setSelectedRowKeys={setSelectedRowKeys}
                />
              )}
            </Form>
          ) : (
            /**For Add Goods Type */
            <Form
              form={form}
              layout="vertical"
              initialValues={goodsTypeInfo ? {} : { isEnabled: true }}
            >
              <Form.Item
                name="typeName"
                label={t('goods.add/view/editTypeList.typeName')}
                rules={[
                  { required: true, message: t('general.inputError.empty') },
                ]}
              >
                <Input style={{ width: 200 }} />
              </Form.Item>
              <Form.Item
                name="isEnabled"
                label={t('goods.add/view/editTypeList.isEnabled')}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <Switch defaultChecked={true} />
              </Form.Item>
            </Form>
          )}
        </Spin>
      </Modal>
      <GoodsTypeAttrModal
        setCalled={setCalled}
        visible={showTypeAttrModal}
        setVisible={setShowTypeAttrModal}
        setGoodsTypeVisible={setVisible}
        goodsTypeAttrInfo={goodsTypeAttrInfo}
        goodsTypeInfo={goodsTypeInfo}
      />
    </>
  );
};

export default GoodsTypeModal;
