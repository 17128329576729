import { EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Image,
  Button,
  Spin,
  Switch,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FALLBACK_IMG } from '../../constants/styles';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import { BrandData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import PhotoGalleryModal from '../PhotoGalleryModal';

type BrandModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  brand?: BrandData;
  refresh?: () => void;
};

const BrandModal = ({
  visible,
  setVisible,
  brand,
  refresh,
}: BrandModalProps) => {
  const { t } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [logo, setLogo] = useState({ photo: '', photoPath: '' });
  const [backgroundImg, setBackgroundImg] = useState({
    photo: '',
    photoPath: '',
  });
  const [photoRef, setPhotoRef] = useState('');

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      setLogo({
        photo: brand ? brand.brandLogo : '',
        photoPath: brand ? brand.brandLogoPath : '',
      });
      setBackgroundImg({
        photo: brand ? brand.brandBackgroundImg : '',
        photoPath: brand ? brand.brandBackgroundImgPath : '',
      });
    }
  }, [form, visible, brand]);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.setFieldsValue({
        brandLogo: logo.photo,
        brandBackgroundImg: backgroundImg.photo,
      });
    }
  }, [logo, backgroundImg, visible, form]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(brand ? 'goods/brand/edit' : 'goods/brand/add', {
          ...values,
          brandId: brand ? brand.brandId : undefined,
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  brand
                    ? t('goods.alerts.brandEdited')
                    : t('goods.alerts.brandAdded')
                );
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  return (
    <Modal
      title={
        brand
          ? `${t('goods.add/editBrand.editTitle')} ${brand.brandName}`
          : t('goods.add/editBrand.addTitle')
      }
      visible={visible}
      okText={t('goods.add/editBrand.ok')}
      cancelText={t('goods.add/editBrand.cancel')}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={brand ? { ...brand } : { sortOrder: 100 }}
        >
          <Form.Item
            name="brandName"
            label={t('goods.add/editBrand.brandName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="brandNameEn"
            label={t('goods.add/editBrand.brandNameEn')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="brandFirstChar"
            label={t('goods.add/editBrand.brandFirstChar')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="brandLogo"
            label={t('goods.add/editBrand.brandLogo')}
          >
            <Space size="large" align="start">
              <Space align="start">
                <Image
                  width={50}
                  src={logo.photoPath}
                  preview={{ mask: <EyeOutlined /> }}
                  fallback={FALLBACK_IMG}
                />
                {logo.photoPath && (
                  <MinusCircleOutlined
                    style={{ color: ' #ff0f0f' }}
                    onClick={() => setLogo({ photo: '', photoPath: '' })}
                  />
                )}
              </Space>
              <Button
                onClick={() => {
                  setPhotoRef('logo');
                  setModalVisible(true);
                  setFirstLoad(true);
                }}
              >
                {t('goods.add/editBrand.photoGallery')}
              </Button>
            </Space>
          </Form.Item>
          <Form.Item
            name="brandBackgroundImg"
            label={t('goods.add/editBrand.brandBackgroundImg')}
          >
            <Space size="large" align="start">
              <Space align="start">
                <Image
                  width={50}
                  src={backgroundImg.photoPath}
                  preview={{ mask: <EyeOutlined /> }}
                  fallback={FALLBACK_IMG}
                />
                {backgroundImg.photoPath && (
                  <MinusCircleOutlined
                    style={{ color: ' #ff0f0f' }}
                    onClick={() =>
                      setBackgroundImg({ photo: '', photoPath: '' })
                    }
                  />
                )}
              </Space>
              <Button
                onClick={() => {
                  setPhotoRef('backgroundImg');
                  setModalVisible(true);
                  setFirstLoad(true);
                }}
              >
                {t('goods.add/editBrand.photoGallery')}
              </Button>
            </Space>
          </Form.Item>
          <Form.Item
            name="brandDesc"
            label={t('goods.add/editBrand.brandDesc')}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
          <Form.Item name="siteUrl" label={t('goods.add/editBrand.siteUrl')}>
            <Input />
          </Form.Item>
          <Form.Item
            name="isShow"
            valuePropName="checked"
            label={t('goods.add/editBrand.isShow')}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="isRecommend"
            valuePropName="checked"
            label={t('goods.add/editBrand.isRecommend')}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="sortOrder"
            label={t('goods.add/editBrand.sortOrder')}
          >
            <InputNumber
              type="number"
              min={0}
              max={50000}
              placeholder="0-50000"
            />
          </Form.Item>
        </Form>
      </Spin>
      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={modalVisible}
        setVisible={setModalVisible}
        setValue={photoRef === 'logo' ? setLogo : setBackgroundImg}
        value={photoRef === 'logo' ? logo : backgroundImg}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
    </Modal>
  );
};

export default BrandModal;
