import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Space,
  Table,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { DataNode } from 'rc-tree-select/lib/interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import AdminModal from '../../components/admin/AdminModal';
import AdminRoleDropdown from '../../components/admin/AdminRoleDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { AdminAction, AdminListData, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Admin List Table
 * Can add, edit, or delete Admins
 *
 * /dashboard/admin/list
 *
 *
 */
const AdminList = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [adminData, setAdminData] = useState<Array<AdminListData>>([]);
  const [totalAdmins, setTotalAdmins] = useState();
  const [loading, setLoading] = useState(true);
  const [adminModalVisible, setAdminModalVisible] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<
    AdminListData | undefined
  >();
  const [advance, setAdvance] = useState<{
    roleId?: string;
  }>({});
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [adminActions, setAdminActions] = useState<Array<DataNode>>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [keyword, setKeyword] = useState('');
  const isSubscribed = useRef(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<AdminListData[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const profile = JSON.parse(localStorage.getItem('profile') || '');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys = [
    'id',
    'username',
    'parentName',
    'sellerName',
    'email',
    'addTime',
    'lastLogin',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<AdminListData> = [
    {
      title: setFont(t('admin.adminListColumns.id'), fontSize),
      dataIndex: 'adminId',
      key: 'id',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      render: (text: string, record: AdminListData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            if (!adminActions.length) getActionList();
            setAdminModalVisible(true);
            setSelectedAdmin(record);
          }}
          disabled={!hasPermission(actionPermissions.adminGroup.adminManage)}
        >
          {text}
        </Button>
      ),
      sorter: (a: AdminListData, b: AdminListData) =>
        compare(a.adminId, b.adminId),
    },
    {
      title: setFont(t('admin.adminListColumns.username'), fontSize),
      dataIndex: 'adminUserName',
      key: 'username',
      render: (text: string) => setFont(text, fontSize),
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: AdminListData, b: AdminListData) =>
        compare(a.adminUserName, b.adminUserName),
      width: 120,
    },
    {
      title: setFont(t('admin.adminListColumns.parentName'), fontSize),
      dataIndex: 'parentAdmin',
      key: 'parentName',
      render: (value: AdminListData) =>
        setFont(value ? value.adminUserName : '', fontSize),
      sorter: (a: AdminListData, b: AdminListData) =>
        compare(
          a.parentAdmin ? a.parentAdmin.adminUserName : '',
          b.parentAdmin ? b.parentAdmin.adminUserName : ''
        ),
      width: 120,
    },
    {
      title: setFont(t('admin.adminListColumns.sellerName'), fontSize),
      dataIndex: 'sellerName',
      key: 'sellerName',
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminListData, b: AdminListData) =>
        compare(a.sellerName, b.sellerName),
      width: 120,
    },
    {
      title: setFont(t('admin.adminListColumns.email'), fontSize),
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminListData, b: AdminListData) => compare(a.email, b.email),
      width: 160,
    },
    {
      title: setFont(t('admin.adminListColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminListData, b: AdminListData) =>
        compare(a.addTime, b.addTime),
      width: 160,
    },
    {
      title: setFont(t('admin.adminListColumns.lastLogin'), fontSize),
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminListData, b: AdminListData) =>
        compare(a.lastLogin, b.lastLogin),
      width: 160,
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 120,
      render: (record: AdminListData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              if (!adminActions.length) getActionList();
              setAdminModalVisible(true);
              setSelectedAdmin(record);
            }}
            disabled={!hasPermission(actionPermissions.adminGroup.adminManage)}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => deleteAdmin(record.adminId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.adminGroup.adminDrop)}
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={!hasPermission(actionPermissions.adminGroup.adminDrop)}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Reconstruct the data to be used in TreeSelect
   *
   * @param  data        Array of all the Admin Actions
   * @return DataNode[]  A reformated Version of Admin Actions
   */
  const makeTreeData = (data: AdminAction[] = []) => {
    let treeData: DataNode[] = [];
    for (let node of data) {
      let treeNode: DataNode = {};
      treeNode.title = node.description;
      treeNode.value = node.actionCode;
      treeNode.key = node.actionCode;
      if (node.adminAction && node.adminAction.length) {
        treeNode.children = makeTreeData(node.adminAction);
        treeNode.disabled = treeNode.children.every(
          (child) => child.disabled === true
        );
      } else {
        treeNode.isLeaf = true;
        treeNode.disabled = profile.actionList
          ? profile.actionList === 'all' ||
            profile.actionList.indexOf(node.actionCode) !== -1
            ? false
            : true
          : true;
      }
      treeData.push(treeNode);
    }
    return treeData;
  };

  // Fetches action list
  const getActionList = () => {
    getDataWithAuthToken('admin/action/list')
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus)
            setAdminActions(makeTreeData(response.data.list));
          else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Fetches Admins
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`admin/list`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword ? keyword : undefined,
        roleId:
          formRef.current && form.getFieldValue('roleId')
            ? form.getFieldValue('roleId')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setAdminData(response.data.list);
            setTotalAdmins(response.data.totalItem);
            setSelectedRowKeys([]);

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  /**
   * @param adminId Id of admin to delete
   */
  const deleteAdmin = (adminId: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('admin/delete', { adminId: adminId })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('admin.alerts.adminDeleted'));
          getData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('admin.adminLogsColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item
            name="roleId"
            label={t('admin.adminListColumns.advancedSearch.adminRole')}
          >
            <AdminRoleDropdown
              onChange={(value: any) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  roleId: value,
                }))
              }
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('admin.adminListColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('admin.adminListColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('admin.adminListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <h1 style={{ fontSize: 24 }}>{t('admin.adminList')}</h1>
          <TableToolbar
            advancedSearch={advancedSearch}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  if (!adminActions.length) getActionList();
                  setAdminModalVisible(true);
                  setSelectedAdmin(undefined);
                }}
                disabled={
                  !hasPermission(actionPermissions.adminGroup.adminManage)
                }
              >
                {t('admin.add/editAdmin.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            fontSize={fontSize}
            setFontSize={setFontSize}
            refresh={() => getData()}
            totalItems={totalAdmins}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchUsernameEmail')}
            rows={adminData.map((admin) => ({
              ...admin,
              parentAdmin: admin.parentAdmin
                ? admin.parentAdmin.adminUserName
                : '',
            }))}
            exportConfig={{ fileName: 'ADMIN' }}
          />
          <Table<AdminListData>
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            dataSource={adminData}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={loading}
            pagination={{
              total: totalAdmins,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowKey={(admin) => admin.adminId}
            rowSelection={{
              selectedRowKeys,
              hideSelectAll: true,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: AdminListData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows.map((admin) => ({
                ...admin,
                parentAdmin: admin.parentAdmin
                  ? admin.parentAdmin.adminUserName
                  : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'ADMIN' } }}
            />
          )}
        </Card>
      )}
      <AdminModal
        visible={adminModalVisible}
        setVisible={setAdminModalVisible}
        adminProfile={selectedAdmin}
        callBack={() => {
          getData();
        }}
        adminActions={adminActions}
      />
    </Container>
  );
};

export default AdminList;
