import Select, { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandData } from '../../../types';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { Typography } from 'antd';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';

type BrandDropdownProps = {
  labelInValue?: boolean;
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};
const BrandDropdown = ({
  labelInValue = false,
  disabled = false,
  initialValue,
  onChange,
}: BrandDropdownProps) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [brands, setBrands] = useState<BrandData[]>([]);
  const [brandPage, setBrandPage] = useState(2);
  const [brandTotalPage, setBrandTotalPage] = useState(2);

  const getBrands = (value?: string) => {
    getDataWithAuthToken('goods/brand/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setBrands(response.data.list);
          setBrandPage(2);
          setBrandTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const brandFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (brandPage > brandTotalPage) return;
      getDataWithAuthToken('goods/brand/list', {
        params: {
          keyword: searchText.brand || undefined,
          page: brandPage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setBrands((prev) => [...prev, ...response.data.list]);
            setBrandPage((prev) => prev + 1);
            setBrandTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={
        !hasPermission(actionPermissions.goodGroup.brandView) || disabled
      }
      labelInValue={labelInValue}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={brandFetchOnScroll}
      showSearch
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, brand: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => getBrands(value), EXTENDED_TIMEOUT));
      }}
      onFocus={() => {
        if (!brands.length) getBrands();
      }}
      allowClear
      filterOption={false}
      placeholder={t('searchPlaceholders.searchBrandName')}
      defaultValue={initialValue ? initialValue : undefined}
      onChange={handleOnChange}
      optionLabelProp="key"
    >
      {brands.map((brand) => (
        <Select.Option
          key={`${brand.brandName} (${brand.brandId})`}
          value={brand.brandId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('goods.brandListColumns.brandId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('goods.brandListColumns.brandName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{brand.brandId}</Typography>
              <Typography>{brand.brandName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default BrandDropdown;
