import Container from '../../components/Container';

import { Card, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import NewUsers from '../../components/analysis/user/NewUsers';
import UserOrderRanks from '../../components/analysis/user/UserOrderRanks';
import UserOverview from '../../components/analysis/user/UserOverview';
import UserStatement from '../../components/analysis/user/UserStatement';
import UserBalance from '../../components/analysis/user/UserBalance';

const UserAnalysis = () => {
  //General Components
  //Data Components
  //Text Components
  const { t } = useTranslation();

  return (
    <Container>
      <Card>
        <Typography.Title level={3} style={{ fontWeight: 500 }}>
          {t('analysis.userTitle')}
        </Typography.Title>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={t(`analysis.userOverview.tab1Title`)} key={'1'}>
            <UserOverview />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t(`analysis.newUsers.tab2Title`)} key={'2'}>
            <NewUsers />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t(`analysis.userOrderRank.tab3Title`)} key={'3'}>
            <UserOrderRanks />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.userOrderStatement.tab4Title`)}
            key={'4'}
          >
            <UserStatement />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.userAccountBalance.tab5Title`)}
            key={'5'}
          >
            <UserBalance />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Container>
  );
};

export default UserAnalysis;
