import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GENERAL_TIMEOUT } from '../../constants/systemConstants';

const { Text } = Typography;

type TableRefreshProps = {
  onRefresh: () => void;
  total?: number | string;
  recordRemark?: string;
  mobile?: boolean;
};

/**
 * Button that refreshes a table
 *
 * @param onRefresh What will execute when refresh button is pressed
 * @param total     The total number of Objects that are in the table
 * @param mobile    Show mobile version of button
 * @param recordRemark The total number of main orders
 */
const TableRefresh = ({
  onRefresh,
  total,
  mobile,
  recordRemark,
}: TableRefreshProps) => {
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const { t } = useTranslation();

  return mobile ? (
    <Button
      type="text"
      block
      onClick={() => {
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => onRefresh(), GENERAL_TIMEOUT));
      }}
      icon={<ReloadOutlined />}
      style={{ textAlign: 'left' }}
    >
      {t('general.refresh')}
    </Button>
  ) : (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      <Button
        type="text"
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(setTimeout(() => onRefresh(), GENERAL_TIMEOUT));
        }}
        icon={<ReloadOutlined style={{ fontSize: 14 }} />}
      />
      {total ? (
        <Text>{`${t('general.refresh')} - ${total} ${t('general.items')}${
          recordRemark ? recordRemark : ''
        }`}</Text>
      ) : (
        <Text>{`${t('general.refresh')}`}</Text>
      )}
    </div>
  );
};

export default TableRefresh;
