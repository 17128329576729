import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Empty,
  Image,
  Row,
  Spin,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BLUE3,
  ORANGE1,
  PRIMARY,
  PURPLE2,
  RED1,
  WHITE,
} from '../../../constants/color';
import { FALLBACK_IMG } from '../../../constants/styles';
import {
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';

type SelectGoodsGalleryGridProps = {
  goodData: GoodData[];
  loading: boolean;
  showSelected: boolean;
  selectedGoodObjs: GoodData[];
  setSelectedGoodObjs: React.Dispatch<React.SetStateAction<GoodData[]>>;
  multiSelectFunct: boolean;
  shippingTransportId?: number;
  hideSKU?: boolean;
};

const SelectGoodsGalleryGrid = ({
  goodData,
  loading,
  showSelected,
  selectedGoodObjs,
  setSelectedGoodObjs,
  multiSelectFunct,
  shippingTransportId,
  hideSKU,
}: SelectGoodsGalleryGridProps) => {
  const { t } = useTranslation();
  // const isSubscribed = useRef(true);
  const ellipsis = useState(true);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const badgePosition = (type: string, goods: GoodData) => {
    let top = 10;
    const positionGap = 30;
    let position: any = {};

    if (goods.isDelete) position.isDelete = true;
    if (goods.hasGoodsSkuAttr) position.hasGoodsSkuAttr = true;
    if (goods.isWeightGoods) position.isWeightGoods = true;
    if (goods.goodsNumberRule) position.goodsNumberRule = true;

    Object.keys(position).forEach((key, index, thisArg) => {
      if (thisArg && key === type) {
        top += index * positionGap;
      }
    });

    return top;
  };

  return (
    <Spin spinning={loading}>
      {!showSelected && !goodData.length && <Empty></Empty>}
      <Row
        gutter={[16, 8]}
        style={{
          height: goodData.length && 475,
          overflow: 'auto',
        }}
      >
        {showSelected
          ? selectedGoodObjs.map((goodObj, index) => {
              return (
                <Col key={index} span={12} lg={4}>
                  <Badge.Ribbon
                    color={PURPLE2}
                    style={{ display: goodObj.hasGoodsSkuAttr ? '' : 'none' }}
                    text={t('goods.goodsListColumns.sku')}
                  >
                    <Card
                      style={{
                        height: 225,
                        padding: 0,
                      }}
                      bodyStyle={{ padding: 0 }}
                      cover={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 5,
                          }}
                        >
                          <Image
                            preview={{
                              src: goodObj.largePicPath
                                ? goodObj.largePicPath
                                : goodObj.largePic,
                            }}
                            src={
                              goodObj.thumbPicPath
                                ? goodObj.thumbPicPath
                                : goodObj.thumbPic
                            }
                            width="auto"
                            height={100}
                            fallback={FALLBACK_IMG}
                          />
                        </div>
                      }
                    >
                      <Card.Meta
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        title={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography.Text
                              style={
                                ellipsis
                                  ? {
                                      padding: 5,
                                      fontSize: DEFAULT_FONT_SIZE,
                                      textAlign: 'center',
                                    }
                                  : undefined
                              }
                              ellipsis={
                                ellipsis
                                  ? { tooltip: goodObj.goodsName }
                                  : false
                              }
                            >
                              {goodObj.goodsName}
                            </Typography.Text>
                          </div>
                        }
                        description={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography.Text
                              style={
                                ellipsis
                                  ? {
                                      padding: 5,
                                      fontSize: DEFAULT_FONT_SIZE,
                                      textAlign: 'center',
                                    }
                                  : undefined
                              }
                              ellipsis={
                                ellipsis
                                  ? {
                                      tooltip: `$${goodObj.shopPrice} ${goodObj.goodsUnit}`,
                                    }
                                  : false
                              }
                            >
                              {`$${goodObj.shopPrice} ${goodObj.goodsUnit}`}
                            </Typography.Text>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                              }}
                            >
                              {selectedGoodObjs.indexOf(goodObj) > -1 ? (
                                <Button
                                  onClick={() => {
                                    setSelectedGoodObjs(
                                      selectedGoodObjs.filter(
                                        (x) => x !== goodObj
                                      )
                                    );
                                  }}
                                  size="small"
                                  icon={<MinusOutlined />}
                                  type="text"
                                  style={{
                                    color: WHITE,
                                    backgroundColor: PRIMARY,
                                  }}
                                >
                                  {t('general.selectedLabel')}
                                </Button>
                              ) : (
                                <Button
                                  disabled={
                                    hideSKU && goodObj.hasGoodsSkuAttr
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    if (multiSelectFunct) {
                                      setSelectedGoodObjs((prev) =>
                                        prev.concat(goodObj)
                                      );
                                    } else {
                                      if (
                                        selectedGoodObjs.length &&
                                        selectedGoodObjs[0] !== goodObj
                                      ) {
                                        if (typingTimeout)
                                          clearTimeout(typingTimeout);
                                        setTypingTimeout(
                                          setTimeout(
                                            () =>
                                              alertMessage(
                                                'warning',
                                                t(
                                                  'goods.alerts.canOnlySelectOneGood'
                                                )
                                              ),
                                            GENERAL_TIMEOUT
                                          )
                                        );
                                      } else {
                                        selectedGoodObjs.length < 1 &&
                                          setSelectedGoodObjs((prev) =>
                                            prev.concat(goodObj)
                                          );
                                      }
                                    }
                                  }}
                                  size="small"
                                  icon={<PlusOutlined />}
                                  type="text"
                                  style={{
                                    color: 'white',
                                    backgroundColor: PRIMARY,
                                  }}
                                >
                                  {t('general.selectLabel')}
                                </Button>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </Card>
                  </Badge.Ribbon>
                </Col>
              );
            })
          : goodData.map((goodObj, index) => {
              return (
                <Col key={index} span={12} lg={4}>
                  <Badge.Ribbon
                    style={{
                      position: 'absolute',
                      top: badgePosition('isWeightGoods', goodObj),
                      zIndex: 5,

                      display: goodObj.isWeightGoods ? undefined : 'none',
                    }}
                    color={BLUE3}
                    text={t('goods.goodsListColumns.isWeighted')}
                  />
                  {goodObj.goodsNumberRule && (
                    <Badge.Ribbon
                      style={{
                        position: 'absolute',
                        top: badgePosition('goodsNumberRule', goodObj),
                        zIndex: 5,
                        display: goodObj.goodsNumberRule ? undefined : 'none',
                      }}
                      color={ORANGE1}
                      text={`${goodObj.goodsNumberRule.description}${goodObj.goodsNumberRule.number}`}
                    />
                  )}
                  <Badge.Ribbon
                    color={goodObj.isDelete ? RED1 : PURPLE2}
                    style={{
                      display: goodObj.isDelete
                        ? undefined
                        : goodObj.hasGoodsSkuAttr
                        ? undefined
                        : 'none',
                    }}
                    text={
                      goodObj.isDelete
                        ? t('goods.add/editGood.isDelete')
                        : t('goods.goodsListColumns.sku')
                    }
                  >
                    <Card
                      style={{
                        height: 225,
                        padding: 0,
                      }}
                      bodyStyle={{ padding: 0 }}
                      cover={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 5,
                          }}
                        >
                          <Image
                            preview={{
                              src: goodObj.largePicPath
                                ? goodObj.largePicPath
                                : goodObj.largePic,
                            }}
                            src={
                              goodObj.thumbPicPath
                                ? goodObj.thumbPicPath
                                : goodObj.thumbPic
                            }
                            width="auto"
                            height={100}
                            fallback={FALLBACK_IMG}
                          />
                        </div>
                      }
                    >
                      <Card.Meta
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        title={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography.Text
                              style={
                                ellipsis
                                  ? {
                                      padding: 5,
                                      fontSize: DEFAULT_FONT_SIZE,
                                      textAlign: 'center',
                                    }
                                  : undefined
                              }
                              ellipsis={
                                ellipsis
                                  ? { tooltip: goodObj.goodsName }
                                  : false
                              }
                            >
                              {goodObj.goodsName}
                            </Typography.Text>
                          </div>
                        }
                        description={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography.Text
                              style={
                                ellipsis
                                  ? {
                                      padding: 5,
                                      fontSize: DEFAULT_FONT_SIZE,
                                      textAlign: 'center',
                                    }
                                  : undefined
                              }
                              ellipsis={
                                ellipsis
                                  ? {
                                      tooltip: `$${goodObj.shopPrice} ${goodObj.goodsUnit}`,
                                    }
                                  : false
                              }
                            >
                              {`$${goodObj.shopPrice} ${goodObj.goodsUnit}`}
                            </Typography.Text>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                              }}
                            >
                              {selectedGoodObjs.findIndex(
                                (obj) => obj.goodsId === goodObj.goodsId
                              ) !== -1 ? (
                                <Button
                                  onClick={() => {
                                    let tempList: GoodData[] = [];
                                    tempList = selectedGoodObjs.slice();
                                    let index = tempList.findIndex(
                                      (obj) => obj.goodsId === goodObj.goodsId
                                    );
                                    if (index !== -1) tempList.splice(index, 1);
                                    setSelectedGoodObjs(tempList);
                                  }}
                                  size="small"
                                  icon={<MinusOutlined />}
                                  style={{
                                    color: WHITE,
                                    backgroundColor: PRIMARY,
                                  }}
                                >
                                  {t('general.selectedLabel')}
                                </Button>
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    if (multiSelectFunct) {
                                      setSelectedGoodObjs((prev) =>
                                        prev.concat(goodObj)
                                      );
                                    } else {
                                      if (shippingTransportId) {
                                        if (
                                          goodObj.shippingMethod ===
                                            'SHIPPING' &&
                                          shippingTransportId !==
                                            goodObj.shippingTransportId
                                        ) {
                                          if (typingTimeout)
                                            clearTimeout(typingTimeout);
                                          setTypingTimeout(
                                            setTimeout(
                                              () =>
                                                alertMessage(
                                                  'warning',
                                                  t(
                                                    'order.alerts.shippingMismatchWarning'
                                                  )
                                                ),
                                              GENERAL_TIMEOUT
                                            )
                                          );
                                        } else {
                                          if (selectedGoodObjs.length) {
                                            if (typingTimeout)
                                              clearTimeout(typingTimeout);
                                            setTypingTimeout(
                                              setTimeout(
                                                () =>
                                                  alertMessage(
                                                    'warning',
                                                    t(
                                                      'goods.alerts.canOnlySelectOneGood'
                                                    )
                                                  ),
                                                GENERAL_TIMEOUT
                                              )
                                            );
                                          } else {
                                            setSelectedGoodObjs((prev) =>
                                              prev.concat(goodObj)
                                            );
                                          }
                                        }
                                      } else {
                                        if (
                                          selectedGoodObjs.length &&
                                          selectedGoodObjs[0] !== goodObj
                                        ) {
                                          if (typingTimeout)
                                            clearTimeout(typingTimeout);
                                          setTypingTimeout(
                                            setTimeout(
                                              () =>
                                                alertMessage(
                                                  'warning',
                                                  t(
                                                    'goods.alerts.canOnlySelectOneGood'
                                                  )
                                                ),
                                              GENERAL_TIMEOUT
                                            )
                                          );
                                        } else {
                                          selectedGoodObjs.length < 1 &&
                                            setSelectedGoodObjs((prev) =>
                                              prev.concat(goodObj)
                                            );
                                        }
                                      }
                                    }
                                  }}
                                  size="small"
                                  icon={<PlusOutlined />}
                                  disabled={
                                    goodObj.isDelete ||
                                    (hideSKU && goodObj.hasGoodsSkuAttr)
                                      ? true
                                      : false
                                  }
                                >
                                  {t('general.selectLabel')}
                                </Button>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </Card>
                  </Badge.Ribbon>
                </Col>
              );
            })}
      </Row>
    </Spin>
  );
};

export default SelectGoodsGalleryGrid;
