import { useEffect, useState, useCallback, useRef } from 'react';
import Container from '../../components/Container';
import {
  Card,
  Tabs,
  Row,
  Col,
  Empty,
  Typography,
  Button,
  Skeleton,
} from 'antd';
import { SellerDetailData } from '../../types';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import FourZeroThree from '../../components/FourZeroThree';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { hasPermission } from '../../utils/hasPermission';
import FourZeroFour from '../../components/FourZeroFour';
import { alertMessage } from '../../utils/alertMessage';
import SellerDescription from '../../components/sellers/sellerDetail/SellerDescription';
import ShopLogo from '../../components/sellers/sellerDetail/ShopLogo';
import SellerAdditionalDescription from '../../components/sellers/sellerDetail/SellerAdditionalDescription';
import { EditOutlined } from '@ant-design/icons';
import SellerModal from '../../components/sellers/SellerModal';
import GradeDetails from '../../components/sellers/sellerDetail/GradeDetails';
import UserAdminListTable from '../../components/sellers/sellerDetail/UserAdminListTable';
import FiveHundred from '../../components/FiveHundred';
import { dashboardRoute } from '../../constants/pathname';
import { actionPermissions } from '../../constants/actionPermissions';
import getDashboardStyle from '../../utils/getDashboardStyle';

const { TabPane } = Tabs;

/**
 * Detail Page for an individual seller
 * /dashboard/sellers/detail?seller_id=:sellerId
 */
const SellerDetail = () => {
  const { Title } = Typography;
  const queryString = window.location.search;
  const [sellerId] = useState(
    new URLSearchParams(queryString).get('seller_id')
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [sellerInfo, setSellerInfo] = useState<SellerDetailData>();
  const [loading, setLoading] = useState(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const isSubscribed = useRef(true);
  const [fourZeroFour, setFourZeroFour] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Makes a request and set the seller information via seller id so that
   * it can be used throughout the page as well as for the modal
   * Sets the title of the page title with the seller information
   *
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`seller/detail?sellerId=${sellerId}`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (response.data) {
              setSellerInfo(response.data);
              document.title = `${t('sellers.sellerDetail')} ${
                response.data.shopName
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('sellers.sellerDetail')} ${
                    response.data.shopName
                  }`,
                  path: `${dashboardRoute.sellers.detail}?seller_id=${sellerId}`,
                })
              );
            } else setFourZeroFour(true);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          if (isSubscribed.current) setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [sellerId, dispatch, t]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fourZeroFour ? (
        <Card>
          <FourZeroFour />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <div style={{ marginTop: 5 }}>
          <Row gutter={[5, 5]}>
            <Col span={24} lg={{ span: 4 }}>
              <Card
                style={{ height: '100%' }}
                bodyStyle={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Skeleton active loading={loading}>
                  <div style={{ textAlign: 'center' }}>
                    <ShopLogo src={sellerInfo?.shopLogo} />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Title level={3}>{sellerInfo?.shopTitle}</Title>
                  </div>
                </Skeleton>
              </Card>
            </Col>
            <Col span={24} lg={{ span: 20 }}>
              <Card style={{ height: '100%' }}>
                <Skeleton active loading={loading}>
                  {sellerInfo ? (
                    <div style={{ marginTop: -25 }}>
                      <Tabs
                        defaultActiveKey="overView"
                        tabBarExtraContent={
                          <Button
                            disabled={
                              !hasPermission(
                                actionPermissions.sellerGroup.sellerMerchants
                              )
                            }
                            style={{ border: 0 }}
                            icon={<EditOutlined />}
                            onClick={() => {
                              setShowEdit(true);
                            }}
                          ></Button>
                        }
                      >
                        {hasPermission(
                          actionPermissions.sellerGroup.sellerMerchants
                        ) && (
                          <TabPane
                            style={{ height: 'auto' }}
                            tab={t(
                              'sellers.sellerDetailVariables.overviewTitle'
                            )}
                            key="overView"
                          >
                            <SellerDescription sellerInfo={sellerInfo} />
                          </TabPane>
                        )}
                        {hasPermission(
                          actionPermissions.sellerGroup.sellerMerchants
                        ) && (
                          <TabPane
                            style={{ height: 'auto' }}
                            tab={t('sellers.sellerDetailVariables.gradeTitle')}
                            key="gradeDetails"
                          >
                            <GradeDetails
                              sellerInfo={sellerInfo}
                              callBack={getData}
                            />
                          </TabPane>
                        )}
                        {hasPermission(
                          actionPermissions.sellerGroup.sellerMerchantDrop
                        ) && (
                          <TabPane
                            style={{ height: 'auto' }}
                            tab={t(
                              'sellers.sellerDetailVariables.moreInfo.title'
                            )}
                            key="moreInfo"
                          >
                            <SellerAdditionalDescription
                              sellerInfo={sellerInfo}
                              callBack={getData}
                            />
                          </TabPane>
                        )}
                      </Tabs>
                    </div>
                  ) : (
                    <Empty />
                  )}
                </Skeleton>
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: 5 }}>
            <Card>
              <Skeleton active loading={loading}>
                {sellerInfo ? (
                  <UserAdminListTable id={sellerId || ''} />
                ) : (
                  <Empty />
                )}
              </Skeleton>
            </Card>
          </div>
        </div>
      )}
      <SellerModal
        visible={showEdit}
        setVisible={setShowEdit}
        seller={sellerInfo}
        refresh={() => getData()}
      />
    </Container>
  );
};

export default SellerDetail;
