import { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Card, Space, Button, Popconfirm, Grid } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Container from '../../components/Container';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import AdminRoleModal from '../../components/admin/AdminRoleModal';
import { AdminAction, AdminRoleData, FontSizeType } from '../../types';
import { DataNode } from 'rc-tree-select/lib/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import FourZeroThree from '../../components/FourZeroThree';
import TableToolbar from '../../components/table/TableToolbar';
import { alertMessage } from '../../utils/alertMessage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import { compare, setFont } from '../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Admin Roles
 * Can add, edit, or delete Admin Roles
 *
 *
 */
const AdminRole = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState<DataNode[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editRole, setEditRole] = useState<AdminRoleData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const columnKeys = ['id', 'name', 'description', 'action'];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isSubscribed = useRef(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<AdminRoleData[]>([]);
  const profile = JSON.parse(localStorage.getItem('profile') || '');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * @param record The Selected Admin Role
   */
  const handleShowModal = (record: AdminRoleData) => {
    if (isSubscribed.current) {
      setEditRole(record);
      setShowModal(true);
    }
  };

  /**
   * Reconstruct the data to be used in TreeSelect
   *
   * @param data         Array of all the Admin Actions
   * @return DataNode[]  A reformated Version of Admin Actions
   */
  const makeTreeData = (data: AdminAction[] = []) => {
    let treeData: DataNode[] = [];
    for (let node of data) {
      let treeNode: DataNode = {};
      treeNode.title = node.description;
      treeNode.value = node.actionCode;
      treeNode.key = node.actionCode;
      if (node.adminAction && node.adminAction.length) {
        treeNode.children = makeTreeData(node.adminAction);
        treeNode.checkable = treeNode.children.every(
          (child) => child.disableCheckbox === true
        )
          ? false
          : true;
      } else {
        treeNode.isLeaf = true;
        treeNode.disableCheckbox = profile.actionList
          ? profile.actionList === 'all' ||
            profile.actionList.indexOf(node.actionCode) !== -1
            ? false
            : true
          : true;
      }
      treeData.push(treeNode);
    }
    return treeData;
  };

  /**
   * @param roleId ID to be deleted
   */
  const deleteRole = async (roleId: number) => {
    try {
      const result = await postDataWithAuthToken('admin/role/delete', {
        roleId: roleId,
      });
      if (result && result.goodStatus) {
        getRoleData();
        alertMessage('success', t('admin.alerts.roleDeleted'));
      } else
        alertMessage(
          'error',
          result?.msg || t('general.noResponse'),
          result?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  // Fetches Role Data
  const getRoleData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('admin/role/list', {
      params: {
        page: currentPage,
        size: pageSize,
      },
    })
      .then((resultRoleList) => {
        if (
          resultRoleList &&
          resultRoleList.goodStatus &&
          resultRoleList.data
        ) {
          if (isSubscribed.current) {
            setRoleList(resultRoleList.data.list);
            setTotalItems(resultRoleList.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (resultRoleList && resultRoleList.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            resultRoleList?.msg || t('general.noResponse'),
            resultRoleList?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, currentPage, pageSize]);

  // Fetch role list, refresh the list if any role gets updated
  useEffect(() => {
    getRoleData();
  }, [getRoleData]);

  // Fetch permission list
  const getPermissionData = async () => {
    getDataWithAuthToken('admin/action/list')
      .then((resultPermissionList) => {
        if (isSubscribed.current) {
          if (
            resultPermissionList &&
            resultPermissionList.goodStatus &&
            resultPermissionList.data
          ) {
            if (isSubscribed.current)
              setPermissionList(makeTreeData(resultPermissionList.data.list));
          } else
            alertMessage(
              'error',
              resultPermissionList?.msg || t('general.noResponse'),
              resultPermissionList?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: ColumnsType<AdminRoleData> = [
    {
      title: setFont(t('admin.adminRoleColumns.id'), fontSize),
      dataIndex: 'roleId',
      key: 'id',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string, record: AdminRoleData) => (
        <Button
          disabled={
            !hasPermission(actionPermissions.adminGroup.adminRoleManage)
          }
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={async () => {
            if (!permissionList.length) await getPermissionData();
            handleShowModal(record);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: AdminRoleData, b: AdminRoleData) =>
        compare(a.roleId, b.roleId),
    },
    {
      title: setFont(t('admin.adminRoleColumns.roleName'), fontSize),
      dataIndex: 'roleName',
      key: 'name',
      width: 160,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminRoleData, b: AdminRoleData) =>
        compare(a.roleName, b.roleName),
    },
    {
      title: setFont(t('admin.adminRoleColumns.roleDescription'), fontSize),
      dataIndex: 'roleDesc',
      key: 'description',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={async () => {
              if (!permissionList.length) await getPermissionData();
              handleShowModal(record);
            }}
            disabled={
              !hasPermission(actionPermissions.adminGroup.adminRoleManage)
            }
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={async () => await deleteRole(record.roleId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.adminGroup.adminRoleManage)
            }
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.adminGroup.adminRoleManage)
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <h1 style={{ fontSize: 24 }}>{t('admin.adminRole')}</h1>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.adminGroup.adminRoleManage)
                }
                onClick={async () => {
                  if (!permissionList.length) await getPermissionData();
                  setEditRole(undefined);
                  setShowModal(true);
                }}
              >
                {t('admin.add/editRole.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getRoleData()}
            totalItems={totalItems}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            rows={roleList}
            exportConfig={{ fileName: 'ADMIN_ROLE' }}
          />
          <Table<AdminRoleData>
            dataSource={roleList}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            loading={isLoading}
            rowKey={(role) => role.roleId}
            size={tableSize}
            pagination={{
              total: totalItems,
              pageSize: pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              onChange: (page, pSize) => {
                setCurrentPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: currentPage,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: AdminRoleData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'ADMIN_ROLE' } }}
            />
          )}
        </Card>
      )}
      <AdminRoleModal
        showModal={showModal}
        setShowModal={setShowModal}
        editRole={editRole}
        refresh={() => getRoleData()}
        permissionList={permissionList}
      />
    </Container>
  );
};

export default AdminRole;
