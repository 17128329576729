import { useState } from 'react';

/**
 * Hook to access localStorage
 * Can be used like useState
 * 
 * source: https://usehooks.com/useLocalStorage/
 * 
 * @param key                        Key to store value
 * @param initialValue               Initial Value if there's nothing in localStorage
 * @returns [storedValue, setValue]  The stored value plus the function to change it (similar to useState)
 */
const useLocalStorage = (key: string, initialValue: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export { useLocalStorage };
