import React from 'react';
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config';

/**
 * This components holds all the routes attributed to authenticating users
 * 
 * @prop  RouteConfigComponentProps route Information on the route such as child routes
 */
const Auth = ({ route }: RouteConfigComponentProps) => {
  return <div>{renderRoutes(route?.routes)}</div>;
};

export default Auth;
