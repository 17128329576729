import { useCallback, useEffect, useRef, useState } from 'react';
import FourZeroThree from '../../FourZeroThree';
import FiveHundred from '../../FiveHundred';
import {
  Badge,
  Button,
  DatePicker,
  Drawer,
  Form,
  Grid,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { alertMessage } from '../../../utils/alertMessage';
import {
  FontSizeType,
  OrderEnum,
  PrintType,
  UserStatementData,
} from '../../../types';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import TableToolbar from '../../table/TableToolbar';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FilterOutlined } from '@ant-design/icons';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';
import { ColumnsType } from 'antd/lib/table';
import { setFont } from '../../../utils/colComponents';
import { useTab } from '../../../hooks/useTab';
import { useVT } from 'virtualizedtableforantd4';
import { dashboardRoute } from '../../../constants/pathname';
import { addCommas, addCommasPrice } from '../../../utils/helperFunction';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import PrintModal from '../../PrintModal';
import SellersDropdown from '../../sellers/SellersDropdown';

const UserStatement = () => {
  // General Components
  const { t } = useTranslation();
  const { addTab } = useTab();
  const screens = Grid.useBreakpoint();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [form] = Form.useForm();
  const formRef = useRef<any>(null);
  // Status components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [statementModalVisible, setStatementModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState(false);
  // Data components
  const [userStatements, setUserStatements] = useState<UserStatementData[]>([]);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [orderEnumObj, setOrderEnumObj] = useState<OrderEnum>();
  const [statementPrintoutData, setStatementPrintoutData] =
    useState<PrintType>();
  // Pagination components
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState<number>(0);
  // Table components
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<UserStatementData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys: string[] = [
    'userId|',
    'userName|',
    'nickName|',
    'totalOrderNum|',
    'totalStatementAmount|',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);

  const getStatementPrintoutContents = (userId: number) => {
    setIsModalLoading(true);
    const startTime =
      formRef.current && form.getFieldValue('addTimeInterval')
        ? moment(form.getFieldValue('addTimeInterval')[0]).format(
            DATE_TIME_FORMAT
          )
        : undefined;
    const endTime =
      formRef.current && form.getFieldValue('addTimeInterval')
        ? moment(form.getFieldValue('addTimeInterval')[1]).format(
            DATE_TIME_FORMAT
          )
        : undefined;
    getDataWithAuthToken('order/print_statement', {
      params: {
        userId: userId,
        isSeller: isSeller,
        sellerId:
          isSeller && formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        startExpectShippingTime:
          formRef.current && form.getFieldValue('expectShippingTimeInterval')
            ? moment(
                form.getFieldValue('expectShippingTimeInterval')[0]
              ).format(DATE_FORMAT)
            : undefined,
        endExpectShippingTime:
          formRef.current && form.getFieldValue('expectShippingTimeInterval')
            ? moment(
                form.getFieldValue('expectShippingTimeInterval')[1]
              ).format(DATE_FORMAT)
            : undefined,
        startAddTime: startTime,
        endAddTime: endTime,
        orderStatus:
          formRef.current && form.getFieldValue('orderStatus')
            ? form.getFieldValue('orderStatus').join()
            : undefined,
        shippingStatus:
          formRef.current && form.getFieldValue('shippingStatus')
            ? form.getFieldValue('shippingStatus').join()
            : undefined,
        payStatus:
          formRef.current && form.getFieldValue('payStatus')
            ? form.getFieldValue('payStatus').join()
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setStatementPrintoutData({
            statementData: {
              ...response.data,
              startDate: startTime,
              endDate: endTime,
            },
          });
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsModalLoading(false);
      });
  };

  //Makes a request and set the order enum list
  const getOrderEnumList = useCallback(() => {
    getDataWithAuthToken('order/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setOrderEnumObj(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  const getUserStatements = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('analysis/user/statement', {
      params: {
        page: page,
        size: pageSize,
        isSeller: isSeller,
        sellerId:
          isSeller && formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        startExpectShippingTime:
          formRef.current && form.getFieldValue('expectShippingTimeInterval')
            ? moment(
                form.getFieldValue('expectShippingTimeInterval')[0]
              ).format(DATE_FORMAT)
            : undefined,
        endExpectShippingTime:
          formRef.current && form.getFieldValue('expectShippingTimeInterval')
            ? moment(
                form.getFieldValue('expectShippingTimeInterval')[1]
              ).format(DATE_FORMAT)
            : undefined,
        startAddTime:
          formRef.current && form.getFieldValue('addTimeInterval')
            ? moment(form.getFieldValue('addTimeInterval')[0]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        endAddTime:
          formRef.current && form.getFieldValue('addTimeInterval')
            ? moment(form.getFieldValue('addTimeInterval')[1]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        orderStatus:
          formRef.current && form.getFieldValue('orderStatus')
            ? form.getFieldValue('orderStatus').join()
            : undefined,
        shippingStatus:
          formRef.current && form.getFieldValue('shippingStatus')
            ? form.getFieldValue('shippingStatus').join()
            : undefined,
        payStatus:
          formRef.current && form.getFieldValue('payStatus')
            ? form.getFieldValue('payStatus').join()
            : undefined,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response && response.goodStatus) {
          setUserStatements(response.data.list);
          setTotal(response.data.totalItem);
          setSelectedRowKeys([]);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [form, isSeller, page, pageSize, t]);

  useEffect(() => {
    hasPermission(actionPermissions.orderGroup.orderView) && getOrderEnumList();
  }, [getOrderEnumList]);

  useEffect(() => {
    getUserStatements();
  }, [getUserStatements]);

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  const advancedSearch = (
    <Drawer
      visible={showDrawer}
      closable={true}
      onClose={() => setShowDrawer(false)}
      placement="right"
      headerStyle={{ padding: '10px 3px 10px 3px' }}
      width={screens.sm ? 600 : 350}
      bodyStyle={{ padding: 10 }}
      title={t('order.orderListColumns.advancedSearch.title')}
    >
      <Form
        layout="vertical"
        form={form}
        ref={formRef}
        initialValues={{
          expectShippingTimeIntervalType: 'all',
        }}
      >
        {isSeller && (
          <Form.Item
            name="sellerId"
            label={t('order.orderListColumns.advancedSearch.sellerId')}
            style={{ marginBottom: 12 }}
          >
            <SellersDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  sellerId: value,
                }));
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          name="shippingStatus"
          label={t('order.orderListColumns.advancedSearch.shippingStatus')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.selectStatus')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: string[]) => {
              setAdvance((prev) => ({
                ...prev,
                shippingStatus: value,
              }));
            }}
          >
            {orderEnumObj &&
              orderEnumObj['shippingStatus'].map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="payStatus"
          label={t('order.orderListColumns.advancedSearch.payStatus')}
          style={{ marginBottom: 12 }}
        >
          <Select
            allowClear={true}
            placeholder={t('searchPlaceholders.selectStatus')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: string[]) => {
              setAdvance((prev) => ({
                ...prev,
                payStatus: value,
              }));
            }}
          >
            {orderEnumObj &&
              orderEnumObj['payStatus'].map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="expectShippingTimeInterval"
          label={t(
            'order.orderListColumns.advancedSearch.expectShippingTimeInterval'
          )}
          style={{ marginBottom: 12 }}
        >
          <DatePicker.RangePicker
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            onChange={(values) => {
              values
                ? setAdvance((prev) => ({
                    ...prev,
                    startExpectShippingTime: moment(values[0]).format(
                      DATE_TIME_FORMAT
                    ),
                    endExpectShippingTime: moment(values[1]).format(
                      DATE_TIME_FORMAT
                    ),
                  }))
                : setAdvance((prev) => ({
                    ...prev,
                    startExpectShippingTime: undefined,
                    endExpectShippingTime: undefined,
                  }));
            }}
            placeholder={[
              t('order.orderListColumns.advancedSearch.startDate'),
              t('order.orderListColumns.advancedSearch.endDate'),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="addTimeInterval"
          label={t('order.orderListColumns.advancedSearch.addTimeInterval')}
          style={{ marginBottom: 12 }}
        >
          <DatePicker.RangePicker
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            style={{ width: '100%' }}
            showTime={{
              format: 'HH:mm:ss',
              hideDisabledOptions: true,
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('00:00:00', 'HH:mm:ss'),
              ],
            }}
            format={DATE_TIME_FORMAT}
            onChange={(values) => {
              values
                ? setAdvance((prev) => ({
                    ...prev,
                    startAddTime: moment(values[0]).format(DATE_TIME_FORMAT),
                    endAddTime: moment(values[1]).format(DATE_TIME_FORMAT),
                  }))
                : setAdvance((prev) => ({
                    ...prev,
                    startAddTime: undefined,
                    endAddTime: undefined,
                  }));
            }}
            placeholder={[
              t('order.orderListColumns.advancedSearch.startDate'),
              t('order.orderListColumns.advancedSearch.endDate'),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="orderStatus"
          label={t('order.orderListColumns.advancedSearch.orderStatus')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.selectStatus')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: string[]) => {
              setAdvance((prev) => ({
                ...prev,
                orderStatus: value,
              }));
            }}
          >
            {orderEnumObj &&
              orderEnumObj['orderStatus'].map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getUserStatements(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.orderListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(advance).every((value) => !value)}
              onClick={() => {
                form.resetFields();
                setAdvance({});
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getUserStatements(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.orderListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );

  const columns: ColumnsType<UserStatementData> = [
    {
      title: setFont(
        t('analysis.userOrderStatement.userId'),
        fontSize,
        t('analysis.userOrderStatement.userId')
      ),
      key: 'userId|',
      fixed: screens.lg ? 'left' : undefined,
      width: 90,
      render: (record: UserStatementData) =>
        record &&
        record.user && (
          <Button
            style={{ padding: 0, fontSize: fontSize }}
            type="link"
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
            onClick={() => {
              addTab(
                '',
                `${dashboardRoute.users.detail}?user_id=${record.user.userId}`
              );
            }}
          >
            {record.user.userId}
          </Button>
        ),
    },
    {
      title: setFont(
        t('analysis.userOrderStatement.userName'),
        fontSize,
        t('analysis.userOrderStatement.userName')
      ),
      key: 'userName|',
      width: 120,
      render: (record: UserStatementData) =>
        record && record.user && setFont(record.user.userName, fontSize),
    },
    {
      title: setFont(
        t('analysis.userOrderStatement.nickname'),
        fontSize,
        t('analysis.userOrderStatement.nickname')
      ),
      key: 'nickName|',
      width: 120,
      render: (record: UserStatementData) =>
        record && record.user && setFont(record.user.nickName, fontSize),
    },
    {
      title: setFont(
        t('analysis.userOrderStatement.totalOrderNum'),
        fontSize,
        t('analysis.userOrderStatement.totalOrderNum')
      ),
      key: 'totalOrderNum|',
      width: 120,
      render: (record: UserStatementData) =>
        record && setFont(addCommas(record.totalOrderNum), fontSize),
    },
    {
      title: setFont(
        t('analysis.userOrderStatement.totalStatementAmount'),
        fontSize,
        t('analysis.userOrderStatement.totalStatementAmount')
      ),
      key: 'totalStatementAmount|',
      width: 120,
      render: (record: UserStatementData) =>
        record &&
        setFont(addCommasPrice(record.totalStatementAmount), fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: UserStatementData) =>
        record &&
        record.user && (
          <Button
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              getStatementPrintoutContents(record.user.userId);
              setStatementModalVisible(true);
            }}
          >
            {t('actionsColumn.view')}
          </Button>
        ),
    },
  ];

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Space>
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('analysis.userOrderStatement.tab4Title')}
        </Typography.Title>
        {getDashboardStyle().isSellerSwitch && (
          <Switch
            loading={isLoading}
            onChange={(checked) => {
              setAdvance((prev) => ({ ...prev, sellerId: undefined }));
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  setIsSeller(checked);
                  setPage(1);
                }, EXTENDED_TIMEOUT)
              );
            }}
            style={{ marginBottom: 12 }}
            checkedChildren={t('goods.goodsListColumns.seller')}
            unCheckedChildren={t('goods.goodsListColumns.self')}
          />
        )}
      </Space>
      <TableToolbar
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={getUserStatements}
        totalItems={total}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        rows={userStatements.map((statement) => ({
          ...statement,
          userId: statement.user ? statement.user.userId : '',
          userName: statement.user ? statement.user.userName : '',
          nickName: statement.user ? statement.user.nickName : '',
        }))}
        exportConfig={
          hasPermission(actionPermissions.orderGroup.orderView)
            ? { fileName: 'ORDER_STATEMENT' }
            : undefined
        }
        advancedSearch={
          <Badge
            count={Object.keys(advance).reduce((accumulator, obj) => {
              if (advance[obj as keyof typeof advance]) {
                return accumulator + 1;
              }

              return accumulator;
            }, 0)}
          >
            <Button
              icon={<FilterOutlined />}
              onClick={() => setShowDrawer((prev) => !prev)}
            >
              {t('order.orderListColumns.advancedSearch.title')}
            </Button>
            {advancedSearch}
          </Badge>
        }
      />
      <Table
        dataSource={userStatements}
        rowKey={(statement) => statement.user.userId}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        size={tableSize}
        loading={isLoading}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
          },
          current: page,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: UserStatementData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((statement) => ({
            ...statement,
            userId: statement.user ? statement.user.userId : '',
            userName: statement.user ? statement.user.userName : '',
            nickName: statement.user ? statement.user.nickName : '',
          }))}
          funct={{
            refresh: () => getUserStatements(),
            exportConfig: hasPermission(actionPermissions.orderGroup.orderView)
              ? { fileName: 'ORDER_STATEMENT' }
              : undefined,
          }}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
        />
      )}
      <PrintModal<PrintType>
        modalLoading={isModalLoading}
        mode="statement"
        data={statementPrintoutData}
        setData={setStatementPrintoutData}
        visible={statementModalVisible}
        setVisible={setStatementModalVisible}
      />
    </>
  );
};

export default UserStatement;
