/**
 * Component to be shown based on permission
 * @param   action  string    user permission
 * @returns         boolean   whether the component should be displayed or not
 */
export const hasPermission = (action: string) => {
  let user: string = localStorage.getItem('profile') || '{}';
  if (user && JSON.parse(user).actionList) {
    if (JSON.parse(user).actionList === 'all') {
      return true;
    }

    if (JSON.parse(user).actionList.split(',').includes(action)) {
      return true;
    }
  }
  return false;
};
