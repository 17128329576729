import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Menu,
  Popconfirm,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import ValueCardTypeModal from '../../components/activity/ValueCardTypeModal';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { REVIEW_STATUS } from '../../constants/generalConstants';
import { dashboardRoute } from '../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import { FontSizeType, ValueCardType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Value Card Type List Table
 * Can add, edit, or delete value card types
 *
 */
const ValueCardTypeList = () => {
  //General Components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { addTab } = useTab();
  const formRef = useRef(null);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const isSubscribed = useRef(true);
  const screens = useBreakpoint();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  //Data Components
  const [valueCardTypeData, setValueCardTypeData] = useState<ValueCardType[]>(
    []
  );
  const [selectedValueCardType, setSelectedValueCardType] =
    useState<ValueCardType>();
  //Table Components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState();
  const [keyword, setKeyword] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'vctId',
    'seller',
    'vcName',
    'vcDesc',
    'vcValue',
    'vcPrefix',
    'vcExpireMonth',
    'isRecharge',
    'reviewStatus',
    'reviewContent',
    'addTime',
    'action',
  ];
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<ValueCardType> = [
    {
      title: setFont(t('activity.valueCardTypeColumns.vctId'), fontSize),
      dataIndex: 'vctId',
      key: 'vctId',
      width: 120,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: ValueCardType, b: ValueCardType) => compare(a.vctId, b.vctId),
      render: (text: ValueCardType, record: ValueCardType) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.activityGroup.valueCard)}
          onClick={() => {
            setSelectedValueCardType(record);
            setModalVisible(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.seller'), fontSize),
      dataIndex: 'seller',
      key: 'seller',
      width: 120,
      render: (text: string, record: ValueCardType) =>
        record.seller ? (
          <Button
            disabled={
              !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
            }
            style={{ padding: 0, fontSize: fontSize }}
            type="link"
            onClick={() =>
              addTab(
                '',
                `${dashboardRoute.sellers.detail}?seller_id=${record.seller.sellerId}`
              )
            }
          >
            {record.seller.shopName}
          </Button>
        ) : (
          <></>
        ),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.actName'), fontSize),
      dataIndex: 'actName',
      key: 'actName',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.vcName'), fontSize),
      dataIndex: 'vcName',
      key: 'vcName',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.vcValue'), fontSize),
      dataIndex: 'vcValue',
      key: 'vcValue',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.vcPrefix'), fontSize),
      dataIndex: 'vcPrefix',
      key: 'vcPrefix',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('activity.valueCardTypeColumns.vcExpireMonth'),
        fontSize
      ),
      dataIndex: 'vcExpireMonth',
      key: 'vcExpireMonth',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.isRecharge'), fontSize),
      dataIndex: 'isRecharge',
      key: 'isRecharge',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1 }} />
        ) : (
          <CloseOutlined style={{ color: RED1 }} />
        ),
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.reviewStatus'), fontSize),
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      width: 120,
      render: (text: string) =>
        Object.keys(REVIEW_STATUS).map((status, index) => {
          if (status === text) {
            return (
              <div key={index} style={{ fontSize: fontSize }}>
                {t(REVIEW_STATUS[status])}
              </div>
            );
          }
          return <div key={index}></div>;
        }),
    },
    {
      title: setFont(
        t('activity.valueCardTypeColumns.reviewContent'),
        fontSize
      ),
      dataIndex: 'reviewContent',
      key: 'reviewContent',
      width: 200,
      render: (text: string) => text,
    },
    {
      title: setFont(t('activity.valueCardTypeColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'action',
      width: 120,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: ValueCardType) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.activityGroup.valueCard)}
            onClick={() => {
              setSelectedValueCardType(record);
              setModalVisible(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>

          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.vctId])
                setMorePopoverVis({ [record.vctId]: flag });
            }}
            visible={morePopoverVis[record.vctId]}
            overlay={
              <Menu>
                <Menu.Item
                  key="viewUsage"
                  style={{ fontSize: fontSize }}
                  disabled={
                    !hasPermission(actionPermissions.activityGroup.couponManage)
                  }
                  onClick={() => {
                    setMorePopoverVis({ [record.vctId]: false });
                    addTab(
                      `${t(`activity.valueCardList`)} ${record.vcName}`,
                      `${dashboardRoute.activity.valueCardList}?vct_id=${record.vctId}`
                    );
                  }}
                >
                  {t('actionsColumn.view')}
                </Menu.Item>
                <Menu.Item key="delete">
                  <Popconfirm
                    title={t('actionsColumn.deleteWarning')}
                    onConfirm={() => {
                      deleteValueCardType(record.vctId);
                    }}
                    okText={t('actionsColumn.confirmation.yes')}
                    cancelText={t('actionsColumn.confirmation.no')}
                    placement="leftBottom"
                  >
                    <Button
                      danger
                      type="link"
                      disabled={
                        !hasPermission(
                          actionPermissions.activityGroup.favourable
                        )
                      }
                      style={{ padding: 0, fontSize: fontSize }}
                    >
                      {t('actionsColumn.delete')}
                    </Button>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.vctId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const deleteValueCardType = (id: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('value_card/type/delete', {
      vctId: id,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('activity.alerts.valueCardTypeDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Fetches Value Card Types
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`value_card/type/list`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword ? keyword : undefined,
        isSeller: isSeller,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        reviewStatus:
          formRef.current && form.getFieldValue('reviewStatus')
            ? form.getFieldValue('reviewStatus') === 'all'
              ? undefined
              : form.getFieldValue('reviewStatus')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setValueCardTypeData(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, form, isSeller]);

  useEffect(() => {
    getData();
  }, [getData]);

  const advancedSearch = (
    <Popover
      // overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('activity.valueCardTypeColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ actType: 'all', reviewStatus: 'all' }}
        >
          {isSeller && (
            <Form.Item
              label={t('activity.valueCardTypeColumns.advancedSearch.sellerId')}
              name="sellerId"
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          <Form.Item
            label={t(
              'activity.valueCardTypeColumns.advancedSearch.reviewStatus'
            )}
            name="reviewStatus"
          >
            <Select
              style={{ width: 250 }}
              placeholder={t('general.inputError.pleaseSelectOne')}
              showSearch={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  reviewStatus: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t('general.all')}
              </Select.Option>
              {Object.keys(REVIEW_STATUS).map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {t(REVIEW_STATUS[type])}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('activity.valueCardTypeColumns.advancedSearch.search')}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('activity.valueCardTypeColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('activity.valueCardTypeColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Space>
              <Typography.Title level={3} style={{ fontWeight: 500 }}>
                {t('activity.valueCardTypeList')}
              </Typography.Title>
              {getDashboardStyle().isSellerSwitch && (
                <Switch
                  loading={loading}
                  checkedChildren={t('activity.valueCardTypeColumns.seller')}
                  unCheckedChildren={t('activity.valueCardTypeColumns.self')}
                  style={{ marginBottom: 12 }}
                  onChange={(checked) => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        setIsSeller(checked);
                        setPage(1);
                      }, EXTENDED_TIMEOUT)
                    );
                    if (!checked) {
                      formRef.current && form.resetFields(['sellerId']);
                      setAdvance((prev: any) => ({
                        ...prev,
                        sellerId: undefined,
                      }));
                    }
                  }}
                />
              )}
            </Space>
            <TableToolbar
              advancedSearch={advancedSearch}
              leftElement={
                <Button
                  icon={<PlusOutlined />}
                  disabled={
                    !hasPermission(actionPermissions.activityGroup.valueCard)
                  }
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedValueCardType(undefined);
                  }}
                >
                  {t('activity.add/EditValueCardType.addVCTTitle')}
                </Button>
              }
              fontSize={fontSize}
              setFontSize={setFontSize}
              tableSize={tableSize}
              setTableSize={setTableSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchValueCardType')}
              rows={valueCardTypeData.map((valueCard) => ({
                ...valueCard,
                seller:
                  valueCard && valueCard.seller
                    ? valueCard.seller.sellerId
                    : '',
              }))}
              exportConfig={{ fileName: 'VALUE_CARD_TYPE_LIST' }}
            />
            <Table<ValueCardType>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={valueCardTypeData}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              loading={loading}
              size={tableSize}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
              rowKey={(vc) => vc.vctId}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                selectedRowKeys={selectedRowKeys}
                selectedRows={[]}
                setSelectedRowKeys={setSelectedRowKeys}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
                funct={{ exportConfig: { fileName: 'VALUE_CARD_TYPE_LIST' } }}
              />
            )}
          </Card>
          <ValueCardTypeModal
            visible={modalVisible}
            isSeller={isSeller}
            setVisible={setModalVisible}
            selectedValueCardType={selectedValueCardType}
            refresh={() => getData()}
          />
        </>
      )}
    </Container>
  );
};

export default ValueCardTypeList;
