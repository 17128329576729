import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DATE_FORMAT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { UserAnalysisDataPoint } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';

import {
  addCommas,
  addCommasPrice,
  getPercent,
} from '../../../utils/helperFunction';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import { GREEN1, RED1 } from '../../../constants/color';

const UserOverview = () => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { RangePicker } = DatePicker;
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { t } = useTranslation();
  //Data Components
  //This Year range OR Total(sent request with no date range)
  const [range1Data, setRange1Data] = useState<UserAnalysisDataPoint>();
  //Last Year range
  const [range2Data, setRange2Data] = useState<UserAnalysisDataPoint>();
  const [userAnalysisData, setUserAnalysisData] =
    useState<UserAnalysisDataPoint>();
  const [growthAmount, setGrowthAmount] = useState<UserAnalysisDataPoint>();
  const rowOneLabels = [
    'totalUserNum',
    'activeUserNum',
    'repeatUserNum',
    'firstOrderUserNum',
    'averageOrderPerUser',
    'purchaseAmountPerUser',
    'perCustomerTransaction',
  ];
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getGrowth = useCallback(() => {
    if (range1Data && range2Data) {
      let percentageObj: UserAnalysisDataPoint = {
        totalUserNum: '',
        activeUserNum: '',
        repeatUserNum: '',
        activeUserRate: '',
        repeatPurchaseRate: '',
        firstOrderUserNum: '',
        firstOrderUserRate: '',
        averageOrderPerUser: '',
        purchaseAmountPerUser: '',
        perCustomerTransaction: '',
      };
      Object.keys(range2Data).forEach((key) => {
        if (Number(range1Data[key as keyof typeof range1Data]) !== 0) {
          let percent: string = getPercent(
            range2Data[key as keyof typeof range2Data],
            range1Data[key as keyof typeof range1Data]
          );
          percentageObj[key as keyof typeof percentageObj] = percent;
        }
      });
      setGrowthAmount(percentageObj);
    }
  }, [range1Data, range2Data]);

  useEffect(() => {
    getGrowth();
  }, [getGrowth]);

  /**
   * Grabs all the needed data for User Overview
   */
  const getData = useCallback(
    (date?: { key: string; start: string; end: string }) => {
      if (isSubscribed.current) setLoading(true);
      getDataWithAuthToken('analysis/user', {
        params: {
          startDate: date ? date.start : undefined,
          endDate: date ? date.end : undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              if (date) {
                if (date.key === 'range1') {
                  setRange1Data(response.data);
                } else if (date.key === 'range2') {
                  setRange2Data(response.data);
                }
              } else {
                setUserAnalysisData(response.data);
              }
            }
          } else if (response && response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (isSubscribed.current) setLoading(false);
        });
    },
    [t, setFourZeroThree, setFiveHundred]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <Spin style={{ width: '100%' }} spinning={loading}>
      {userAnalysisData && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Row>
            <Col span={24}>
              <Form
                ref={formRef}
                form={form}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: 5,
                  flexWrap: 'wrap',
                }}
              >
                <Form.Item
                  style={{ marginBottom: 12, paddingRight: 10 }}
                  name={'date'}
                  label={t('analysis.userOverview.date')}
                  initialValue={['', moment()]}
                >
                  <RangePicker
                    format={DATE_FORMAT}
                    placeholder={[
                      t('analysis.userOverview.startDate'),
                      t('analysis.userOverview.endDate'),
                    ]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </Form.Item>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);

                      setTypingTimeout(
                        setTimeout(() => {
                          if (
                            form.getFieldValue('date') &&
                            form.getFieldValue('date').length === 2
                          ) {
                            setRange1Data(undefined);
                            setRange2Data(undefined);
                            let range1 = {
                              key: 'range1',
                              start: moment(form.getFieldValue('date')[0])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                              end: moment(form.getFieldValue('date')[1])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                            };
                            getData(range1);
                            let range2 = {
                              key: 'range2',
                              start: moment(
                                form.getFieldValue('date')[0]
                              ).format(DATE_FORMAT),
                              end: moment(form.getFieldValue('date')[1]).format(
                                DATE_FORMAT
                              ),
                            };
                            getData(range2);
                          }
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.userOverview.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      form.setFieldsValue({ date: ['', moment()] });
                      setGrowthAmount(undefined);
                      getData();
                    }}
                  >
                    {t('analysis.userOverview.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          if (form.getFieldValue(['date'])[0]) {
                            getData({
                              key: 'range1',
                              start: moment(
                                form.getFieldValue(['date'])[0]
                              ).format(DATE_FORMAT),
                              end: moment(
                                form.getFieldValue(['date'])[1]
                              ).format(DATE_FORMAT),
                            });
                            getData({
                              key: 'range2',
                              start: moment(form.getFieldValue(['date'])[0])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                              end: moment(form.getFieldValue(['date'])[1])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                            });
                          }
                        })
                      );
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Form>
            </Col>
            {rowOneLabels.map((label, index) => {
              let value =
                userAnalysisData[label as keyof typeof userAnalysisData];

              return (
                <Col
                  style={{ paddingTop: 7, paddingBottom: 7 }}
                  key={index}
                  span={24}
                  md={12}
                  lg={6}
                  xl={4}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      {t(`analysis.userOverview.${label}`)}
                    </div>
                    <Typography.Title
                      level={3}
                      style={{
                        margin: 0,
                        textAlign: 'center',
                      }}
                    >
                      {[
                        'perCustomerTransaction',
                        'purchaseAmountPerUser',
                      ].includes(label)
                        ? `$${addCommasPrice(value)}`
                        : value}
                    </Typography.Title>
                    {growthAmount &&
                      growthAmount[label as keyof typeof growthAmount] !== '' &&
                      growthAmount[label as keyof typeof growthAmount] !==
                        '0' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Form.Item
                            label={t(`analysis.userOverview.lastYear`)}
                            style={{ marginBottom: 0 }}
                          >
                            {growthAmount[
                              label as keyof typeof growthAmount
                            ][0] === '-' ? (
                              <ArrowDownOutlined style={{ color: RED1 }} />
                            ) : (
                              <ArrowUpOutlined style={{ color: GREEN1 }} />
                            )}
                            {`${addCommas(
                              growthAmount[label as keyof typeof growthAmount]
                            )}%`}
                          </Form.Item>
                        </div>
                      )}
                  </div>
                </Col>
              );
            })}
            <Col /**firstOrderUserRate*/
              style={{ paddingTop: 7, paddingBottom: 7 }}
              span={24}
              md={12}
              lg={6}
              xl={4}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography.Text style={{ textAlign: 'center' }}>
                  {t(`analysis.userOverview.firstOrderUserRate`)}
                </Typography.Text>
                <Typography.Title
                  level={3}
                  style={{ margin: 0, textAlign: 'center' }}
                >
                  {`${addCommas(userAnalysisData.firstOrderUserRate)}%`}
                </Typography.Title>
                {growthAmount &&
                  growthAmount.firstOrderUserRate !== '' &&
                  growthAmount.firstOrderUserRate !== '0' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        label={t(`analysis.userOverview.lastYear`)}
                      >
                        {growthAmount.firstOrderUserRate[0] === '-' ? (
                          <ArrowDownOutlined style={{ color: RED1 }} />
                        ) : (
                          <ArrowUpOutlined style={{ color: GREEN1 }} />
                        )}
                        {`${growthAmount.firstOrderUserRate}%`}
                      </Form.Item>
                    </div>
                  )}
              </div>
            </Col>
            <Col /**activeUserRate */
              style={{ paddingTop: 7, paddingBottom: 7 }}
              span={24}
              md={12}
              lg={6}
              xl={4}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography.Text style={{ textAlign: 'center' }}>
                  {t(`analysis.userOverview.activeUserRate`)}
                </Typography.Text>
                <Typography.Title
                  level={3}
                  style={{ margin: 0, textAlign: 'center' }}
                >
                  {`${addCommas(userAnalysisData.activeUserRate)}%`}
                </Typography.Title>
                {growthAmount &&
                  growthAmount.activeUserRate !== '' &&
                  growthAmount.activeUserRate !== '0' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        label={t(`analysis.userOverview.lastYear`)}
                      >
                        {growthAmount.activeUserRate[0] === '-' ? (
                          <ArrowDownOutlined style={{ color: RED1 }} />
                        ) : (
                          <ArrowUpOutlined style={{ color: GREEN1 }} />
                        )}
                        {`${growthAmount.activeUserRate}%`}
                      </Form.Item>
                    </div>
                  )}
              </div>
            </Col>
            <Col /**repeatPurchaseRate */
              style={{ paddingTop: 7, paddingBottom: 7 }}
              span={24}
              md={12}
              lg={6}
              xl={4}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  {t(`analysis.userOverview.repeatPurchaseRate`)}
                </div>
                <Typography.Title
                  level={3}
                  style={{ margin: 0, textAlign: 'center' }}
                >
                  {`${userAnalysisData.repeatPurchaseRate}%`}
                </Typography.Title>
                {growthAmount &&
                  growthAmount.repeatPurchaseRate !== '' &&
                  growthAmount.repeatPurchaseRate !== '0' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        label={t(`analysis.userOverview.lastYear`)}
                      >
                        {growthAmount.repeatPurchaseRate[0] === '-' ? (
                          <ArrowDownOutlined style={{ color: RED1 }} />
                        ) : (
                          <ArrowUpOutlined style={{ color: GREEN1 }} />
                        )}
                        {`${growthAmount.repeatPurchaseRate}%`}
                      </Form.Item>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Spin>
  );
};

export default UserOverview;
