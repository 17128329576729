import React, { useEffect, useRef, useState } from 'react';
import { Menu, Dropdown, Typography, Button } from 'antd';
import { UserOutlined, LogoutOutlined, ClearOutlined } from '@ant-design/icons';
import { AdminListData } from '../types';
import { getMyProfile } from '../utils/axiosRequest';
import { useHistory } from 'react-router-dom';
import { logout } from '../utils/createAuthProvider';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../app/hooks';
import { removeAllPages } from '../features/pageHistory/pageHistorySlice';
import { dashboardRoute, authRoute } from '../constants/pathname';
import { useTab } from '../hooks/useTab';
import { hasPermission } from '../utils/hasPermission';
import { actionPermissions } from '../constants/actionPermissions';
import getDashboardStyle from '../utils/getDashboardStyle';

const { Text } = Typography;

/**
 * User Dropdown for Header
 */
const UserHeaderDropdown = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const dispatch = useAppDispatch();
  const { addTab } = useTab();

  const [profile, setProfile] = useState<AdminListData>();

  useEffect(() => {
    getMyProfile()
      .then((profile) => {
        if (profile) {
          if (isSubscribed.current) setProfile(profile);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * @param key User action to take
   */
  const handleUserAction = ({ key }: { key: string }) => {
    if (key === 'profile') {
      document.title = `${t('profile.profile')} - ${t(
        getDashboardStyle().title
      )} ${t('dashboard.title')}`;
      addTab('profile.title', dashboardRoute.profile);
    } else if (key === 'cache') {
      addTab('cache.title', dashboardRoute.cache);
    } else if (key === 'logout') {
      logout();
      dispatch(removeAllPages());
      history.push(authRoute.login);
    }
  };

  const menu = (
    <Menu onClick={handleUserAction}>
      {!getDashboardStyle().isSalesForceAppType && (
        <Menu.Item key="profile">
          <UserOutlined style={{ marginRight: 8 }} />
          <Text>{t('profile.title')}</Text>
        </Menu.Item>
      )}
      {hasPermission(actionPermissions.cacheGroup.cacheManage) &&
        !getDashboardStyle().isSalesForceAppType && (
          <Menu.Item key="cache">
            <ClearOutlined style={{ marginRight: 8 }} />
            <Text>{t('cache.title')}</Text>
          </Menu.Item>
        )}
      <Menu.Item key="logout">
        <LogoutOutlined style={{ marginRight: 8 }} />
        <Text>{t('login.logout')}</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown
        overlay={menu}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      >
        <Button
          type="text"
          icon={<UserOutlined />}
          style={{
            color: getDashboardStyle().mainColor,
            padding: 4,
          }}
        >
          {profile?.adminUserName ?? 'User'}
        </Button>
      </Dropdown>
    </div>
  );
};

export default UserHeaderDropdown;
