import { BorderlessTableOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import axios from 'axios';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMapGL, { Marker } from 'react-map-gl';
import { MAP_MARKER_ICON, MAP_MARKER_SIZE } from '../../../constants/styles';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { AddressData, OrderEnum, Viewport } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import UserAddressDropdown from '../../users/common/UserAddressDropdown';

type AddressFormProps = {
  form: FormInstance<any>;
  enums?: OrderEnum;
  viewport: Viewport | undefined;
  setViewport: React.Dispatch<SetStateAction<Viewport | undefined>>;
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
};
const AddressForm = ({
  form,
  enums,
  viewport,
  setViewport,
  formData,
  setFormData,
}: AddressFormProps) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const [addressListData, setAddressListData] = useState<AddressData[]>([]);

  const [addresses, setAddresses] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>();

  const onSelectAddressFromList = useCallback(
    (address: any) => {
      address.longitude = Number(address.longitude);
      address.latitude = Number(address.latitude);

      form.setFieldsValue({
        ...address,
        addressType: address.addressType.code,
      });
      setSelectedAddress({
        ...address,
        addressType: address.addressType.code,
      });
      setFormData((prev) => ({
        ...prev,
        ...address,
        addressType: address.addressType.code,
      }));
      setViewport({
        longitude: address.longitude,
        latitude: address.latitude,
        zoom: 15,
      });
    },
    [form, setFormData, setViewport]
  );

  // Get first page of address list - auto select the first
  const getUserAddressData = useCallback(() => {
    getDataWithAuthToken('users/user_address/list', {
      params: { userId: formData.userId || undefined },
    })
      .then((result) => {
        if (result && result.goodStatus) {
          const addressList = result.data.list;
          setAddressListData(addressList);

          if (addressList.length > 0) {
            onSelectAddressFromList(addressList[0]);
          }
        } else {
          alertMessage(
            'error',
            result?.msg || t('general.noResponse'),
            result?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t, formData.userId, onSelectAddressFromList]);

  useEffect(() => {
    getUserAddressData();
  }, [getUserAddressData]);

  /**
   * @param value The address that was selected by user
   */
  const onSelectAddress = (value: any) => {
    let addressObj: {
      context: any[];
      center: number[];
      address: string;
      text: string;
    } = JSON.parse(value);
    let postcode = '';
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let region = '';
    let country = '';
    if (addressObj.address) streetNumber = addressObj.address;
    if (addressObj.text) streetName = addressObj.text;
    if (addressObj.context && addressObj.context.length) {
      for (let e of addressObj.context) {
        if (e.id.includes('postcode')) postcode = e.text;
        else if (e.id.includes('place')) city = e.text;
        else if (e.id.includes('region')) region = e.short_code.slice(-2);
        else if (e.id.includes('country')) country = e.short_code.toUpperCase();
      }
    }

    setSelectedAddress((prev: AddressData) => ({
      ...prev,
      postalCode: postcode,
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
    }));
    setViewport({
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
      zoom: 15,
    });
    form.setFieldsValue({
      address: `${streetNumber} ${streetName}, ${city}, ${region}, ${country}`,
      postalCode: postcode,
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
    });
    setFormData((prev) => ({
      ...prev,
      address: `${streetNumber} ${streetName}, ${city}, ${region}, ${country}`,
      postalCode: postcode,
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
    }));
  };

  /**
   * @param value search value for address
   */
  const onSearchAddress = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    if (value && value.length > 5) {
      setTypingTimeout(
        setTimeout(() => {
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=ca&language=en&types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
            )
            .then((result) => {
              if (result && result.status === 200 && result.data) {
                setAddresses(result.data.features);
              } else alertMessage('error', t('users.alerts.errorOccurred'));
            })
            .catch((err) => {
              console.log(err);
            });
        }, EXTENDED_TIMEOUT)
      );
    } else setAddresses([]);
  };

  return (
    <Form form={form} layout="vertical">
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.add/editOrder.addressInfo')}
        </Space>
      </Divider>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          {!!formData.userId && (
            <Form.Item
              label={`${t('order.orderDetail.selectFromSavedAddressList')}: `}
              style={{ marginBottom: 0 }}
            >
              <UserAddressDropdown
                userId={formData.userId}
                firstAddressData={addressListData}
                onChange={(value, option: any) => {
                  onSelectAddressFromList(option.address);
                }}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.consignee')}: `}
            style={{ marginBottom: 0 }}
            name="consignee"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  consignee: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.consigneeMobile')}: `}
            style={{ marginBottom: 0 }}
            name="mobile"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input
              type="tel"
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, mobile: e.target.value }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.email')}: `}
            style={{ marginBottom: 0 }}
            name="email"
          >
            <Input
              type="email"
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.tel')}: `}
            style={{ marginBottom: 0 }}
            name="tel"
          >
            <Input
              type="tel"
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, tel: e.target.value }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.address')}: `}
            style={{ marginBottom: 0 }}
            name="address"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <AutoComplete
              allowClear
              onChange={onSearchAddress}
              onSelect={(value: string, option: any) =>
                onSelectAddress(option.key)
              }
            >
              {addresses.map((address: { place_name: string }) => (
                <AutoComplete.Option
                  key={JSON.stringify(address)}
                  value={address.place_name}
                >
                  {address.place_name}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.postalCode')}: `}
            style={{ marginBottom: 0 }}
            name="postalCode"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  postalCode: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.unitNumber')}: `}
            style={{ marginBottom: 0 }}
            name="unitNumber"
          >
            <Input
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  unitNumber: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.buzzerCode')}: `}
            style={{ marginBottom: 0 }}
            name="buzzerCode"
          >
            <Input
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  buzzerCode: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.addressType')}: `}
            style={{ marginBottom: 0 }}
            name="addressType"
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              onChange={(value) =>
                setFormData((prev) => ({
                  ...prev,
                  addressType: value,
                }))
              }
            >
              {enums?.addressType.map((addressType) => (
                <Select.Option value={addressType.code} key={addressType.code}>
                  {addressType.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.latitude')}: `}
            style={{ marginBottom: 0 }}
            name="latitude"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  latitude: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.longitude')}: `}
            style={{ marginBottom: 0 }}
            name="longitude"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  longitude: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <ReactMapGL
            {...viewport}
            width="100%"
            height={400}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={setViewport}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          >
            {!!(
              selectedAddress &&
              selectedAddress.latitude &&
              selectedAddress.longitude
            ) ? (
              <Marker
                latitude={selectedAddress.latitude}
                longitude={selectedAddress.longitude}
              >
                <svg
                  height={MAP_MARKER_SIZE}
                  viewBox="0 0 24 24"
                  style={{
                    fill: '#d00',
                    stroke: 'none',
                    transform: `translate(${
                      -MAP_MARKER_SIZE / 2
                    }px,${-MAP_MARKER_SIZE}px)`,
                  }}
                >
                  <path d={MAP_MARKER_ICON} />
                </svg>
              </Marker>
            ) : (
              <></>
            )}
          </ReactMapGL>
        </Col>
      </Row>
    </Form>
  );
};

export default AddressForm;
