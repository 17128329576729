import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  useRef,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import {
  NivoPieChartData,
  TaskDistributionData,
  TaskDistributionStatusData,
  TaskEnum,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import TableToolbar from '../../table/TableToolbar';
import {
  addCommas,
  addCommasPrice,
  getPercent,
} from '../../../utils/helperFunction';
import { ResponsivePie } from '@nivo/pie';
import { nivoToolTip } from '../../../utils/nivoGraphTools';
import { RED1, GREEN1 } from '../../../constants/color';

type TaskDistributionProps = {
  taskEnum?: TaskEnum;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const TaskDistribution = ({
  taskEnum,
  isLoading,
  setIsLoading,
}: TaskDistributionProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { RangePicker } = DatePicker;
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const isSubscribed = useRef(true);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  //Data Components
  //This Year range
  const [range1Data, setRange1Data] = useState<TaskDistributionData>();
  //Last Year range
  const [range2Data, setRange2Data] = useState<TaskDistributionData>();
  const [growthData, setGrowthData] = useState<{
    totalTaskNum: '';
    totalTaskGoodsNum: '';
    totalWorkload: '';
    avgTaskWorkload: '';
    avgTaskGoodsWorkload: '';
  }>();
  const [taskDistriPieGraphs, setTaskDistriPieGraphs] =
    useState<NivoPieChartData[][]>();
  const [taskDistriTableData, setTaskDistriTableData] = useState<
    TaskDistributionStatusData[]
  >([]);

  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Pagination Components

  const columnKeys = [
    'id',
    'taskGoodsNum',
    'taskGoodsNumPercentage',
    'taskNum',
    'taskNumPercentage',
  ];

  const columns: ColumnsType<TaskDistributionStatusData> = [
    {
      title: t('analysis.taskDistribution.id'),
      key: 'id',
      dataIndex: 'id',
      width: 100,
      render: (text: number) => text,
    },
    {
      title: t('analysis.taskDistribution.taskGoodsNum'),
      key: 'taskGoodsNum',
      dataIndex: 'taskGoodsNum',
      width: 100,
      render: (text: number) => text,
    },
    {
      title: t('analysis.taskDistribution.taskGoodsNumPercentage'),
      key: 'taskGoodsNumPercentage',
      dataIndex: 'taskGoodsNumPercentage',
      width: 100,
      render: (text: number) => text,
    },
    {
      title: t('analysis.taskDistribution.taskNum'),
      key: 'taskNum',
      dataIndex: 'taskNum',
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('analysis.taskDistribution.taskNumPercentage'),
      key: 'taskNumPercentage',
      dataIndex: 'taskNumPercentage',
      width: 100,
      render: (text: string) => text,
    },
  ];

  const getGrowth = useCallback(() => {
    let growthObj: any = {
      totalTaskNum: '',
      totalTaskGoodsNum: '',
      totalWorkload: '',
      avgTaskWorkload: '',
      avgTaskGoodsWorkload: '',
    };
    if (range1Data && range2Data) {
      Object.keys(range1Data).forEach((label) => {
        if (label !== 'taskStatusData') {
          growthObj[label] = getPercent(
            range2Data[label as keyof typeof range2Data],
            range1Data[label as keyof typeof range1Data]
          );
        }
      });
      setGrowthData(growthObj);
    }
  }, [range1Data, range2Data]);

  useEffect(() => {
    getGrowth();
  }, [getGrowth]);

  /**
   * Grabs all the needed data for Task Distribution
   */
  const getData = useCallback(
    (date?: { key: string; start: string; end: string }) => {
      const getTableData = (
        taskStatusData: TaskDistributionStatusData,
        taskEnum: TaskEnum
      ) => {
        let tableData: TaskDistributionStatusData[] = [];
        Object.keys(taskStatusData).forEach((key: string) => {
          let valueObj: any =
            taskStatusData[key as keyof typeof taskStatusData];
          let translatedKey = '';
          Object.values(taskEnum.taskStatus).forEach((item) => {
            if (item.code === key) {
              translatedKey = item.description;
            }
          });
          tableData.push({
            ...valueObj,
            id: translatedKey,
          });
        });

        return tableData;
      };
      const getPieGraphData = (
        taskStatusData: TaskDistributionStatusData,
        taskEnum: TaskEnum
      ) => {
        let taskNumPieGraph: NivoPieChartData[] = [];
        let taskGoodsNumPieGraph: NivoPieChartData[] = [];

        Object.keys(taskStatusData).forEach((key) => {
          let translatedKey = '';
          Object.values(taskEnum.taskStatus).forEach((item) => {
            if (item.code === key) {
              translatedKey = item.description;
            }
          });

          let obj: any = taskStatusData[key as keyof typeof taskStatusData];
          taskNumPieGraph.push({
            id: translatedKey,
            value: parseFloat(obj.taskNumPercentage),
            label: obj.taskNum,
          });
          taskGoodsNumPieGraph.push({
            id: translatedKey,
            value: parseFloat(obj.taskGoodsNumPercentage),
            label: obj.taskGoodsNum,
          });
        });

        return [taskNumPieGraph, taskGoodsNumPieGraph];
      };
      if (isSubscribed.current) setIsLoading(true);
      getDataWithAuthToken('analysis/task', {
        params: {
          startDate: date
            ? date.start
            : form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
            : undefined,
          endDate: date
            ? date.end
            : form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
            : undefined,
          taskStage: form.getFieldValue('taskStage')
            ? form.getFieldValue('taskStage')
            : undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              if (date) {
                if (date.key === 'range1') {
                  setRange1Data(response.data);
                } else if (date.key === 'range2') {
                  setRange2Data(response.data);
                }
              } else {
                setRange1Data(response.data);
              }
              if (response.data && response.data.taskStatusData && taskEnum) {
                setTaskDistriTableData(
                  getTableData(response.data.taskStatusData, taskEnum)
                );
                setTaskDistriPieGraphs(
                  getPieGraphData(response.data.taskStatusData, taskEnum)
                );
              }
            }
          } else if (response && response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (isSubscribed.current) setIsLoading(false);
        });
    },
    [t, form, setFourZeroThree, setFiveHundred, setIsLoading, taskEnum]
  );

  useEffect(() => {
    if (taskEnum) {
      getData({
        key: 'range1',
        start: moment().subtract(8, 'days').format(DATE_FORMAT),
        end: moment().subtract(1, 'days').format(DATE_FORMAT),
      });
      getData({
        key: 'range2',
        start: moment()
          .subtract(8, 'days')
          .subtract(1, 'years')
          .format(DATE_FORMAT),
        end: moment()
          .subtract(1, 'days')
          .subtract(1, 'years')
          .format(DATE_FORMAT),
      });
    }
  }, [getData, taskEnum]);

  const refreshData = () => {
    if (form.getFieldValue('date') && form.getFieldValue('date').length === 2) {
      setRange1Data(undefined);
      setRange2Data(undefined);
      let start = moment(form.getFieldValue('date')[0]);
      let end = moment(form.getFieldValue('date')[1]);
      let range1 = {
        key: 'range1',
        start: start.format(DATE_FORMAT),
        end: end.format(DATE_FORMAT),
      };
      getData(range1);
      let range2 = {
        key: 'range2',
        start: start.subtract(1, 'years').format(DATE_FORMAT),
        end: end.subtract(1, 'years').format(DATE_FORMAT),
      };
      getData(range2);
    } else {
      setRange1Data(undefined);
      setRange2Data(undefined);
      setGrowthData(undefined);
      getData();
    }
  };

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <Spin style={{ width: '100%' }} spinning={isLoading}>
      <Form /**Range picker, Task Stage, OK, Reset, Refresh Buttons */
        form={form}
        ref={formRef}
        initialValues={{}}
      >
        <Row gutter={[16, 0]}>
          <Col span={24} md={24} lg={8} xl={8}>
            <Form.Item
              name={'date'}
              label={t('analysis.orderDistribution.date')}
              initialValue={[
                moment().subtract(8, 'days'),
                moment().subtract(1, 'days'),
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                placeholder={[
                  t('analysis.orderDistribution.startDate'),
                  t('analysis.orderDistribution.endDate'),
                ]}
                ranges={{
                  [`${t('nivo.week')}`]: [
                    moment().startOf('isoWeek'),
                    moment().endOf('isoWeek'),
                  ],
                  [`${t('nivo.month')}`]: [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  [`${t('nivo.firstHalfYear')}`]: [
                    moment().startOf('year'),
                    moment().startOf('year').add(6, 'months'),
                  ],
                  [`${t('nivo.secondHalfYear')}`]: [
                    moment().startOf('year').add(6, 'months'),
                    moment().endOf('year'),
                  ],
                  [`${t('nivo.year')}`]: [
                    moment().startOf('year'),
                    moment().endOf('year'),
                  ],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={24} lg={8} xl={8}>
            <Form.Item
              name="taskStage"
              label={t('analysis.taskDistribution.taskStage')}
            >
              <Select
                allowClear
                filterOption={false}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                optionLabelProp="key"
              >
                {taskEnum &&
                  taskEnum.taskStage.map((taskStage) => (
                    <Select.Option
                      key={taskStage.description}
                      value={taskStage.code}
                    >
                      {taskStage.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Space align="start">
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      refreshData();
                    }, GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('analysis.taskDistribution.ok')}
              </Button>
              <Button
                onClick={() => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setGrowthData(undefined);
                      setRange1Data(undefined);
                      setRange2Data(undefined);
                      form.resetFields();
                      getData({
                        key: 'range1',
                        start: moment().subtract(8, 'days').format(DATE_FORMAT),
                        end: moment().subtract(1, 'days').format(DATE_FORMAT),
                      });
                      getData({
                        key: 'range2',
                        start: moment()
                          .subtract(8, 'days')
                          .subtract(1, 'years')
                          .format(DATE_FORMAT),
                        end: moment()
                          .subtract(1, 'days')
                          .subtract(1, 'years')
                          .format(DATE_FORMAT),
                      });
                    }, GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('analysis.taskDistribution.reset')}
              </Button>
            </Space>
            <Tooltip title={t('general.refresh')}>
              <Button
                type="text"
                onClick={() => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      refreshData();
                    }, GENERAL_TIMEOUT)
                  );
                }}
                icon={<ReloadOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form>
      {range1Data /**Overview data */ && (
        <Row style={{ width: '100%', paddingTop: 15 }}>
          {Object.keys(range1Data).map((label: any, index) => {
            let growthValue = growthData
              ? growthData[label as keyof typeof growthData]
              : '';
            return (
              label !== 'taskStatusData' && (
                <Col key={index} span={24} sm={12} md={8} lg={6}>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {t(`analysis.taskDistribution.${label}`)}
                  </div>
                  <Typography.Title
                    level={3}
                    style={{
                      margin: 0,
                      textAlign: 'center',
                    }}
                  >
                    {addCommasPrice(
                      range1Data[label as keyof typeof range1Data]
                    )}
                  </Typography.Title>
                  {growthData &&
                    !Object.keys(growthData).every(
                      (label: any) =>
                        growthData[label as keyof typeof growthData] === ''
                    ) && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Form.Item
                          style={{
                            textAlign: 'center',
                            marginBottom: 0,
                          }}
                          label={t(`analysis.orderOverview.lastYear`)}
                        >
                          {growthValue.at(0) === '-' ? (
                            <ArrowDownOutlined style={{ color: RED1 }} />
                          ) : (
                            <ArrowUpOutlined style={{ color: GREEN1 }} />
                          )}

                          {`${addCommas(growthValue)}%`}
                        </Form.Item>
                      </div>
                    )}
                </Col>
              )
            );
          })}
        </Row>
      )}
      {taskDistriPieGraphs &&
        taskDistriPieGraphs.length > 0 /**Piegraphs data */ && (
          <Row style={{ width: '100%', paddingTop: 15 }}>
            {taskDistriPieGraphs.map((pieData, i) => {
              return (
                <Col
                  key={i}
                  span={12}
                  style={{
                    height: 300,
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    {t(
                      `analysis.taskDistribution.${
                        i === 0 ? 'taskNumPercentage' : 'taskGoodsNumPercentage'
                      }`
                    )}
                  </div>
                  <ResponsivePie
                    colors={{ scheme: 'paired' }}
                    tooltip={(datum) =>
                      nivoToolTip({
                        ...datum,
                        tooltipType: 'Standard',
                      })
                    }
                    data={pieData}
                    arcLinkLabelsStraightLength={10}
                    arcLinkLabelsDiagonalLength={10}
                    arcLabel={(d) => (d.value !== 0 ? `${d.value}%` : '')}
                    arcLinkLabel={(d) => (d.value !== 0 ? `${d.id}` : '')}
                    margin={{
                      top: 20,
                      left: 40,
                      right: 40,
                      bottom: 40,
                    }}
                    arcLinkLabelsSkipAngle={pieData.length < 3 ? 0 : 5}
                    arcLabelsSkipAngle={pieData.length < 3 ? 0 : 20}
                    theme={{
                      fontSize: 12,
                      axis: {
                        legend: { text: { fontSize: DEFAULT_FONT_SIZE } },
                      },
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      <div style={{ width: '100%', paddingTop: 10 }} /**Table data */>
        <TableToolbar
          columns={columns}
          columnKeys={columnKeys}
          rows={taskDistriTableData.map((data) => ({
            ...data,
          }))}
          exportConfig={{
            fileName: 'TASK_DISTRIBUTION_ANALYSIS',
          }}
        />
        <Table<TaskDistributionStatusData>
          dataSource={taskDistriTableData}
          rowKey={(obj) => obj.id}
          columns={columns}
          components={vt}
          scroll={{
            y: 500,
            x: 500,
          }}
          loading={isLoading}
          pagination={false}
          rowSelection={undefined}
        />
      </div>
    </Spin>
  );
};

export default TaskDistribution;
