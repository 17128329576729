import { Button, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValueCard } from '../../../types';
import { useState } from 'react';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { GRAY4 } from '../../../constants/color';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';
import ValueCardModal from '../ValueCardModal';
import { DefaultOptionType } from 'antd/lib/select';

type ValueCardDropdownProps = {
  userId?: number;
  sellerId?: number;
  isEdit?: boolean;
  defaultOptions?: boolean;
  labelInValue?: boolean;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
  onClear?: Function;
  initialValue?: any;
};

const ValueCardDropdown = ({
  userId,
  sellerId,
  isEdit = true,
  defaultOptions = false,
  labelInValue = false,
  initialValue,
  onChange,
  onClear,
}: ValueCardDropdownProps) => {
  const { t } = useTranslation();
  const [valueCards, setValueCards] = useState<ValueCard[]>([]);
  const [pageValueCard, setPageValueCard] = useState<number>(2);
  const [totalPageValueCard, setTotalPageValueCard] = useState<number>(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [modalVisible, setModalVisible] = useState<{
    visible: boolean;
    type: 'EDIT' | 'ASSIGN_USER' | 'ADD_MULTIPLE' | 'ADD_SINGLE';
  }>();

  const getValueCards = (value?: string) => {
    if (sellerId !== undefined) {
      getDataWithAuthToken('value_card/list', {
        params: {
          keyword: value || undefined,
          userId: userId,
          isValid: true,
          sellerId: sellerId,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setValueCards(response.data.list);
            setPageValueCard(2);
            setTotalPageValueCard(response.data.totalPage);
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchOnScrollValueCard = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (pageValueCard > totalPageValueCard) return;
      getDataWithAuthToken('value_card/list', {
        params: {
          page: pageValueCard,
          keyword: searchText.valueCard,
          userId: userId,
          isValid: true,
          sellerId: sellerId,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setValueCards((prev) => [...prev, ...response.data.list]);
          setPageValueCard((prev) => prev + 1);
          setTotalPageValueCard(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  const handleOnClear = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <>
      <Select
        placeholder={t('searchPlaceholders.searchValueCard')}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onPopupScroll={fetchOnScrollValueCard}
        onFocus={() => {
          if (!valueCards.length) getValueCards();
        }}
        onSearch={(value) => {
          setSearchText((prev) => ({ ...prev, valueCard: value }));
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(
            setTimeout(() => getValueCards(value), EXTENDED_TIMEOUT)
          );
        }}
        onChange={handleOnChange}
        onClear={handleOnClear}
        allowClear={!defaultOptions}
        showSearch
        defaultValue={initialValue ? initialValue : undefined}
        optionLabelProp="key"
        filterOption={false}
        labelInValue={labelInValue}
        showArrow={isEdit}
        bordered={isEdit}
        style={{ pointerEvents: isEdit ? undefined : 'none' }}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Space
                style={{
                  width: '100%',
                  borderTopStyle: 'solid',
                  borderTopColor: GRAY4,
                  borderTopWidth: 1,
                }}
              >
                <Button
                  type="link"
                  disabled={
                    sellerId === undefined ||
                    !userId ||
                    !hasPermission(actionPermissions.activityGroup.valueCard)
                  }
                  onClick={() => {
                    setModalVisible({ visible: true, type: 'ADD_SINGLE' });
                  }}
                >
                  {t('activity.add/EditValueCardType.addVCTitle')}
                </Button>
              </Space>
            </>
          );
        }}
      >
        {defaultOptions && (
          <Select.Option
            key={'0$0.00'}
            value={0}
            valueCard={{ vcId: 0, cardMoney: 0 }}
          >
            {t('order.orderDetail.noValueCard')}
          </Select.Option>
        )}
        {valueCards.map((card) => (
          <Select.Option
            key={`${card.vcId} $${card.cardMoney}`}
            value={card.vcId}
            valueCard={{ vcId: card.vcId, cardMoney: card.cardMoney }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {card.valueCardType && (
                <Space wrap>
                  <Typography.Text type="secondary">
                    {t('activity.valueCardColumns.vctId')}:
                  </Typography.Text>
                  <Typography>{card.valueCardType.vcName}</Typography>
                </Space>
              )}
              <Space wrap>
                <Typography.Text type="secondary">
                  {t('activity.valueCardColumns.valueCardSn')}:
                </Typography.Text>
                <Typography>{card.valueCardSn}</Typography>
              </Space>
              <Space wrap>
                <Typography.Text type="secondary">
                  {t('activity.valueCardColumns.cardMoney')}:
                </Typography.Text>
                <Typography>{card.cardMoney}</Typography>
              </Space>
            </div>
          </Select.Option>
        ))}
      </Select>
      <ValueCardModal
        vctID={null}
        visible={modalVisible}
        setVisible={setModalVisible}
        sellerId={sellerId}
        userId={userId}
        refresh={() => {
          getValueCards();
        }}
      />
    </>
  );
};

export default ValueCardDropdown;
