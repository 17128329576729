import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Typography,
  Table,
  Select,
  Space,
  Button,
  Popover,
  Form,
  Badge,
} from 'antd';
import {
  AccountLogData,
  BasicEnumInfoType,
  UserListData,
  FontSizeType,
  AdminListData,
} from '../../../types';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { alertMessage } from '../../../utils/alertMessage';
import TableToolbar from '../../table/TableToolbar';
import { FilterOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import AdjustAccountModal from '../AdjustAccountModal';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import { hasPermission } from '../../../utils/hasPermission';
import { useVT } from 'virtualizedtableforantd4';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { compare, setFont } from '../../../utils/colComponents';
import { actionPermissions } from '../../../constants/actionPermissions';
import { BLACK, GREEN1, RED1 } from '../../../constants/color';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import {
  mergeParamsToString,
  tableScrollToTop,
} from '../../../utils/helperFunction';

const { Title } = Typography;
const { Option } = Select;

type AccountLogTableProps = {
  id: string;
  userInfo?: UserListData;
  refresh?: () => void;
};

/**
 * Account Log Table for an individual user
 *
 * @param id       string         we're displaying the log data of
 * @param userInfo UserListData   current user info
 * @param refresh  void           callback to refresh user details
 */
const AccountLogTable = ({ id, userInfo, refresh }: AccountLogTableProps) => {
  const { t } = useTranslation();
  const [userLogs, setUserLogs] = useState<Array<AccountLogData>>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalLogs, setTotalLogs] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [accountTypes, setAccountTypes] = useState<Array<BasicEnumInfoType>>(
    []
  );
  const isSubscribed = useRef(true);
  const [showAdjustModal, setShowAdjustModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<AccountLogData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const columnKeys = [
    'changeTime',
    'changeDesc',
    'currentBalance',
    'userMoney',
    'frozenMoney',
    'rankPoints',
    'payPoints',
    'admin',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('users/account_log/export/excel?', params);
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Get Params from form (get/export list params)
  const getFormParams = useCallback(() => {
    return {
      userId: id,
      accountType:
        formRef.current && form.getFieldValue('accountType')
          ? form.getFieldValue('accountType')
          : 'ALL',
      page: page,
      size: pageSize,
    };
  }, [id, page, pageSize, form]);

  /**
   * Fetches Logs
   *
   * @param page     Page to fetch
   * @param pageSize Page Size
   */
  const getLogData = useCallback(() => {
    if (isSubscribed.current) setTableLoading(true);
    getDataWithAuthToken('users/account_log/list', {
      params: getFormParams(),
    })
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus) {
            setUserLogs(response.data.list);
            setTotalLogs(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          setTableLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setTableLoading(false);
      });
  }, [t, getFormParams]);

  const getAccountTypes = useCallback(() => {
    getDataWithAuthToken('users/account_log/account_type')
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus)
            setAccountTypes(response.data.list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAccountTypes();
    getLogData();
  }, [getLogData, getAccountTypes]);

  /**
   * @param value The type of account the user wants to see logs of
   */
  const handleAccountChange = (value: string) => {
    setAdvance((prev) => ({
      ...prev,
      accountType: value === 'ALL' ? '' : value,
    }));
  };

  const columns: ColumnsType<AccountLogData> = [
    {
      title: setFont(t('users.accountLogsColumns.changeTime'), fontSize),
      dataIndex: 'changeTime',
      key: 'changeTime',
      width: 160,
      sorter: (a: AccountLogData, b: AccountLogData) =>
        compare(a.changeTime, b.changeTime),
      render: (text) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.accountLogsColumns.changeDesc'), fontSize),
      dataIndex: 'changeDesc',
      key: 'changeDesc',
      width: 400,
      render: (text) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.accountLogsColumns.currentAmount'), fontSize),
      dataIndex: 'currentBalance',
      key: 'currentBalance',
      width: 100,
      render: (record: number) => (
        <Typography.Text strong style={{ fontSize: fontSize }}>
          {record}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('users.accountLogsColumns.userMoney'), fontSize),
      dataIndex: 'userMoney',
      key: 'userMoney',
      width: 100,
      render: (record: number) => (
        <Typography.Text
          strong
          style={{
            color:
              Number(record) > 0 ? GREEN1 : Number(record) === 0 ? BLACK : RED1,
            fontSize: fontSize,
          }}
        >
          {record}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('users.accountLogsColumns.frozenMoney'), fontSize),
      dataIndex: 'frozenMoney',
      key: 'frozenMoney',
      width: 100,
      render: (record: number) => (
        <Typography.Text
          strong
          style={{
            color:
              Number(record) > 0 ? GREEN1 : Number(record) === 0 ? BLACK : RED1,
            fontSize: fontSize,
          }}
        >
          {record}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('users.accountLogsColumns.rankPoints'), fontSize),
      dataIndex: 'rankPoints',
      key: 'rankPoints',
      width: 100,
      render: (record: number) => (
        <Typography.Text
          strong
          style={{
            color:
              Number(record) > 0 ? GREEN1 : Number(record) === 0 ? BLACK : RED1,
            fontSize: fontSize,
          }}
        >
          {record}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('users.accountLogsColumns.payPoints'), fontSize),
      dataIndex: 'payPoints',
      key: 'payPoints',
      width: 100,
      render: (record: number) => (
        <Typography.Text
          strong
          style={{
            color:
              Number(record) > 0 ? GREEN1 : Number(record) === 0 ? BLACK : RED1,
            fontSize: fontSize,
          }}
        >
          {record}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('users.accountLogsColumns.admin'), fontSize),
      dataIndex: 'adminUser',
      key: 'admin',
      width: 160,
      render: (record: AdminListData) =>
        record && (
          <Typography style={{ fontSize: fontSize }}>
            {record.adminUserName} (ID: {record.adminId})
          </Typography>
        ),
    },
  ];

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('users.userAddressColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          initialValues={{ accountType: 'ALL' }}
          ref={formRef}
          style={{ width: 200 }}
        >
          <Form.Item
            label={t('users.adjustAccountLogs.accountType')}
            name="accountType"
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={handleAccountChange}
            >
              {accountTypes.map((account) => (
                <Option key={account.code} value={account.code}>
                  {account.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space wrap>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getLogData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('users.userAddressColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.keys(advance).every((key) => !advance[key])}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getLogData();
                }}
              >
                {t('users.userAddressColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userAddressColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <>
      <Title level={4} style={{ marginBottom: 12 }}>
        {t('users.accountLogsColumns.title')}
      </Title>
      <TableToolbar
        leftElement={
          getDashboardStyle().isSalesForceAppType ? undefined : (
            <Button
              icon={<MoneyCollectOutlined />}
              onClick={() => setShowAdjustModal(true)}
              disabled={
                !hasPermission(actionPermissions.userGroup.surplusManage)
              }
            >
              {t('users.adjustAccountLogs.title')}
            </Button>
          )
        }
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getLogData()}
        totalItems={totalLogs}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        advancedSearch={advancedSearch}
        rows={userLogs.map((log) => ({
          ...log,
          adminUser: log.adminUser
            ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
            : '',
        }))}
        exportConfig={
          hasPermission(actionPermissions.userGroup.accountManage)
            ? {
                fileName: `ACCOUNT_LOG_ID_${id}`,
                url: getExportUrl(getFormParams()),
              }
            : undefined
        }
      />

      <Table<AccountLogData>
        style={{ marginTop: 14 }}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        dataSource={userLogs}
        size={tableSize}
        loading={tableLoading}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        pagination={{
          total: totalLogs,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
          current: page,
        }}
        rowKey={(log) => log.logId}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: AccountLogData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((log) => ({
            ...log,
            adminUser: log.adminUser
              ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
              : '',
          }))}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns}
          funct={{
            exportConfig: hasPermission(
              actionPermissions.userGroup.accountManage
            )
              ? { fileName: `ACCOUNT_LOG_ID_${id}` }
              : undefined,
          }}
        />
      )}
      <AdjustAccountModal
        id={id}
        visible={showAdjustModal}
        setVisible={setShowAdjustModal}
        accountTypes={accountTypes}
        userInfo={userInfo}
        refresh={() => {
          if (refresh) refresh();
          getLogData();
        }}
      />
    </>
  );
};

export default AccountLogTable;
