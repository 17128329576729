import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Pagination,
  Popconfirm,
  Popover,
  Space,
  TreeSelect,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { GalleryAlbum, GalleryMedia } from '../../types';
import { LegacyDataNode } from 'rc-tree-select/lib/interface';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';

type GalleryPaginationProps = {
  totalPhotos: number;
  pagePhotos: number;
  setPagePhotos: React.Dispatch<React.SetStateAction<number>>;
  selectedPhotos?: GalleryMedia[];
  setSelectedPhotos?: React.Dispatch<React.SetStateAction<GalleryMedia[]>>;
  callBack?: () => void;
  photos?: GalleryMedia[];
  selectAll?: boolean;
  setSelectAll?: React.Dispatch<React.SetStateAction<boolean>>;
  refreshTable?: () => void;
};

/**
 * Pagination System for Photo Gallery
 *
 * @param totalPhotos       Number of photos in Album
 * @param pagePhotos        Current photo page
 * @param setPagePhotos     Set new page
 * @param selectedPhotos    Selected photo IDs
 * @param setSelectedPhotos Set selected photo IDs
 * @param selectAll         Value of whether select all or not
 * @param setSelectAll      Set value of select all
 * @param callBack          A callback usually a refresh function
 * @param refreshTable      A function to refresh the table
 */
const GalleryPagination = ({
  totalPhotos,
  pagePhotos,
  setPagePhotos,
  selectedPhotos,
  setSelectedPhotos,
  callBack,
  photos,
  selectAll,
  setSelectAll,
  refreshTable,
}: GalleryPaginationProps) => {
  const selectedPhotosData: number[] = [];
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [moveToAlbumId, setMoveToAlbumId] = useState<any>();
  const [treeData, setTreeData] = useState<
    {
      id: number | string;
      value: number | string;
      title: string;
      isLeaf: boolean;
      pId?: number;
      selectable?: boolean;
    }[]
  >([]);
  const [showTransferPic, setShowTransferPic] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const onDelete = () => {
    selectedPhotos?.forEach((value) => selectedPhotosData.push(value.picId));
    postDataWithAuthToken('gallery/pic/delete_batch', {
      picIds: selectedPhotosData,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.photoDeleted'));
          if (callBack) callBack();
          if (refreshTable) refreshTable();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectAll = (checked: boolean) => {
    let temp = [];
    if (checked) {
      if (photos) {
        for (let photo of photos) {
          temp.push(photo);
        }
      }
    }
    if (isSubscribed.current && setSelectedPhotos && setSelectAll) {
      setSelectAll(checked);
      setSelectedPhotos(temp);
    }
  };

  const makeTreeData = (albums: GalleryAlbum[]) => {
    let temp: any[] = [];
    for (let album of albums) {
      temp.push({
        id: album.albumId,
        pId: album.parentId,
        value: album.albumId,
        title: album.albumName,
        isLeaf: album.childCount ? false : true,
      });
    }
    return temp;
  };

  const getTreeData = (page: number, pId: number) => {
    getDataWithAuthToken('gallery/list', {
      params: {
        parentId: pId,
        page: page,
        size: 15,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          let temp = treeData.slice();
          temp = temp.concat(makeTreeData(response.data.list));
          if (response.data.totalPage > page) {
            if (page > 1) {
              temp = temp.filter((dataNode) => {
                return dataNode.id.toString() !== `${pId}-${page}`;
              });
            }
            temp = temp.concat([
              {
                id: `${pId}-${page + 1}`,
                value: `${pId}-${page + 1}`,
                pId: pId,
                title: t('settings.photoGalleryActions.loadMore'),
                isLeaf: false,
                selectable: false,
              },
            ]);
          } else {
            temp = temp.filter((dataNode) => {
              return dataNode.id.toString() !== `${pId}-${page}`;
            });
          }
          if (isSubscribed.current) setTreeData(temp);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onLoadData = (dataNode: LegacyDataNode) =>
    new Promise<void>((resolve) => {
      const { id, isLeaf } = dataNode;
      if (isLeaf) {
        resolve();
        return;
      }

      if (id.toString().includes('-')) {
        setTimeout(() => {
          getTreeData(parseInt(id.split('-')[1]), parseInt(id.split('-')[0]));
          resolve();
        }, EXTENDED_TIMEOUT);
      } else {
        setTimeout(() => {
          getTreeData(1, id);
          resolve();
        }, EXTENDED_TIMEOUT);
      }
    });

  const onMove = () => {
    postDataWithAuthToken('gallery/pic/transfer', {
      picIds: selectedPhotos && selectedPhotos.map((p) => p.picId),
      albumId: moveToAlbumId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.photoMoved'));
          if (isSubscribed.current) setShowTransferPic(false);
          if (callBack) callBack();
          if (refreshTable) refreshTable();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Space
      wrap
      style={{
        display: 'flex',
        justifyContent:
          setSelectedPhotos && setSelectAll ? 'space-between' : 'flex-end',
        alignItems: 'center',
        padding: 16,
      }}
    >
      {setSelectAll && setSelectedPhotos && (
        <Space>
          <Checkbox
            checked={selectAll}
            onChange={(e) => onSelectAll(e.target.checked)}
          >
            {t('settings.photoGalleryActions.selectAll')}
          </Checkbox>
          <Popover
            visible={showTransferPic}
            onVisibleChange={(visible) =>
              !visible && setShowTransferPic(visible)
            }
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            trigger="click"
            content={
              <Space direction="vertical">
                <TreeSelect
                  onFocus={() => getTreeData(1, 0)}
                  loadData={onLoadData}
                  treeData={treeData}
                  treeDataSimpleMode
                  style={{ minWidth: 200, zIndex: 2 }}
                  onSelect={setMoveToAlbumId}
                  placeholder={t('general.pleaseSelect')}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
                <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    size="small"
                    onClick={() => setShowTransferPic(false)}
                  >
                    {t('settings.add/editAlbum.cancel')}
                  </Button>
                  <Button type="primary" size="small" onClick={onMove}>
                    {t('settings.add/editAlbum.ok')}
                  </Button>
                </Space>
              </Space>
            }
          >
            <Button
              disabled={selectedPhotos && selectedPhotos.length ? false : true}
              onClick={() => setShowTransferPic(true)}
            >
              {t('settings.photoGalleryActions.move')}
            </Button>
          </Popover>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            onConfirm={onDelete}
            disabled={!(selectedPhotos && selectedPhotos.length)}
          >
            <Button
              danger
              disabled={!(selectedPhotos && selectedPhotos.length)}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      )}
      <Pagination
        defaultPageSize={28}
        current={pagePhotos}
        total={totalPhotos}
        onChange={(page, pageSize) => setPagePhotos(page)}
        showSizeChanger={false}
      />
    </Space>
  );
};

export default GalleryPagination;
