import React from 'react';
import { Space, Grid } from 'antd';
import TableSizeChange from '../../components/table/TableSizeChange';
import TableRefresh from '../../components/table/TableRefresh';
import TableSearch from '../../components/table/TableSearch';
import ColumnSelector from '../../components/ColumnSelector';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import TableExport from './TableExport';
import TableToolbarMobile from './TableToolbarMobile';
import { FontSizeType } from '../../types';

const { useBreakpoint } = Grid;

type TableToolbarProps = {
  leftElement?: JSX.Element;
  tableSize?: SizeType;
  fontSize?: FontSizeType;
  setFontSize?: React.Dispatch<React.SetStateAction<FontSizeType>>;
  setTableSize?: React.Dispatch<React.SetStateAction<SizeType>>;
  refresh?: () => void;
  search?: (keyword: string) => void;
  searchPlaceholder?: string;
  advancedSearch?: JSX.Element;
  totalItems?: number | string;
  columns?: ColumnsType<any>;
  columnKeys?: Array<string>;
  selectedColumns?: Array<string>;
  setSelectedColumns?: React.Dispatch<React.SetStateAction<Array<string>>>;
  rows?: Array<any>;
  exportConfig?: {
    fileName: string;
    url?: string;
    otherExports?: { title: string; url: string }[];
  };
  selectedRows?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  recordRemark?: string;
};

/**
 * Table Toolbar that provides basic functionality for tables
 *
 * @param leftElement        React Component that we be the leftmost button
 * @param tableSize          Size of the table
 * @param setTableSize       Changes size of table
 * @param refresh            Refresh Function
 * @param totalItems         Total Items in table
 * @param columns            List of Column Types
 * @param columnKeys         List of all Column Keys
 * @param selectedColumns    List of Selected Column Keys
 * @param setSelectedColumns Sets all the Selected Columns
 * @param rows               List of row data
 * @param advancedSearch     React Component of advanced search
 * @param selectedRows       Data of selected rows
 * @param setSelectedRowKeys Sets all selected row keys
 * @param setFontSize        Sets the font size in the table
 * @param recordRemark       If you want to display additional info after X records
 * Dependencies: The toolbar will only render components that have all their needed dependencies
 *
 * TableSizeChange -> tableSize, setTableSize
 * TableRefresh    -> refresh
 * TableSearch     -> search
 * ColumnSelector  -> columns, columnKeys, selectedColumns, setSelectedColumns
 * TableExport     -> columns, rows
 * SelectedRowsBar -> selectedRows
 */
const TableToolbar = ({
  leftElement,
  fontSize,
  tableSize,
  setTableSize,
  setFontSize,
  refresh,
  search,
  searchPlaceholder,
  advancedSearch,
  totalItems,
  columns,
  columnKeys,
  selectedColumns,
  setSelectedColumns,
  rows,
  exportConfig,
  selectedRows,
  setSelectedRowKeys,
  recordRemark,
}: TableToolbarProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <>
      {screens.xs ? (
        <TableToolbarMobile
          leftElement={leftElement}
          fontSize={fontSize}
          setFontSize={setFontSize}
          tableSize={tableSize}
          setTableSize={setTableSize}
          refresh={refresh}
          search={search}
          searchPlaceholder={searchPlaceholder}
          columns={columns}
          columnKeys={columnKeys}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          rows={rows}
          exportConfig={exportConfig}
          advancedSearch={advancedSearch}
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          recordRemark={recordRemark}
          totalItems={totalItems}
        />
      ) : (
        <>
          <Space
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 20,
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Space wrap>
              {leftElement}
              {columns && rows && (
                <TableExport
                  columns={
                    selectedColumns
                      ? columns.filter((x) =>
                          selectedColumns.includes(x.key?.toString() ?? '')
                        )
                      : columns
                  }
                  rows={rows}
                  exportConfig={exportConfig}
                />
              )}
              <Space wrap>
                {tableSize && setTableSize && (
                  <TableSizeChange
                    tableSize={tableSize}
                    setTableSize={setTableSize}
                    fontSize={fontSize}
                    setFontSize={setFontSize}
                  />
                )}
                {refresh && (
                  <TableRefresh
                    recordRemark={recordRemark ? recordRemark : undefined}
                    onRefresh={refresh}
                    total={totalItems}
                  />
                )}
              </Space>
            </Space>
            <Space wrap>
              {search && (
                <TableSearch
                  searchFunction={search}
                  placeholder={
                    searchPlaceholder ? searchPlaceholder : t('general.search')
                  }
                />
              )}
              {advancedSearch}
              {columns &&
                columnKeys &&
                selectedColumns &&
                setSelectedColumns && (
                  <ColumnSelector
                    columns={columns}
                    columnKeys={columnKeys}
                    selectedColumns={selectedColumns}
                    setSelectedColumns={setSelectedColumns}
                  />
                )}
            </Space>
          </Space>
          {/* Alternative option for showing selected rows */}
          {/* {selectedRows && !!selectedRows.length && (
            <TableSelectedRows
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns}
              exportConfig={exportConfig}
            />
          )} */}
        </>
      )}
    </>
  );
};

export default TableToolbar;
