import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { REVIEW_STATUS_FAILED } from '../../../constants/generalConstants';
import { TaskData, TaskEnum } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type TaskReviewProps = {
  taskInfo?: TaskData;
  callback?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  enums?: TaskEnum;
};

const TaskReview = ({
  taskInfo,
  isCurrentEditing,
  setIsCurrentEditing,
  callback,
  enums,
}: TaskReviewProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reviewStatus, setReviewStatus] = useState<string>('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (taskInfo && taskInfo.reviewStatus) {
      setReviewStatus(taskInfo.reviewStatus.code);
    }
  }, [taskInfo]);

  const handleOnCancel = () => {
    form.resetFields();
    if (taskInfo && taskInfo.reviewStatus) {
      setReviewStatus(taskInfo.reviewStatus.code);
    }
    setIsLoading(false);
    setIsEditing(false);
    setIsCurrentEditing && setIsCurrentEditing(false);
  };

  const handleOnEdit = () => {
    if (isEditing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('task/review', {
            ...values,
            taskId: taskInfo ? taskInfo.taskId : undefined,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('sortationSystem.alerts.taskEdited'));
                handleOnCancel();
                callback && callback();
              } else {
                setIsLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              setIsLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setIsEditing(true);
        setIsCurrentEditing && setIsCurrentEditing(true);
      }
    }
  };

  return taskInfo ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...taskInfo,
          reviewStatus: taskInfo.reviewStatus
            ? taskInfo.reviewStatus.code
            : undefined,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('sortationSystem.taskListColumns.reviewInfo')}
            </Typography.Text>
            {isEditing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.sortationGroup.taskManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          <Col span={24} md={12} xl={6}>
            <Form.Item
              label={`${t('sortationSystem.taskListColumns.reviewStatus')}:`}
              name="reviewStatus"
              style={{ marginBottom: 0 }}
            >
              {isEditing ? (
                <Radio.Group
                  onChange={(e) => {
                    setReviewStatus(e.target.value);
                  }}
                >
                  {enums &&
                    enums.taskReviewStatus &&
                    enums.taskReviewStatus.length > 0 &&
                    enums.taskReviewStatus.map((item) => (
                      <Radio key={item.code} value={item.code}>
                        {item.description}
                      </Radio>
                    ))}
                </Radio.Group>
              ) : (
                taskInfo.reviewStatus && (
                  <Tag color={taskInfo.reviewStatus.tagColor}>
                    {taskInfo.reviewStatus.description}
                  </Tag>
                )
              )}
            </Form.Item>
          </Col>
          {reviewStatus === REVIEW_STATUS_FAILED && (
            <Col span={24} md={12} xl={6}>
              <Form.Item
                label={`${t('sortationSystem.taskListColumns.reviewContent')}:`}
                name="reviewContent"
                rules={[{ required: isEditing }]}
                style={{ marginBottom: 0 }}
              >
                {isEditing ? (
                  <Input.TextArea />
                ) : (
                  <Typography.Text>{taskInfo.reviewContent}</Typography.Text>
                )}
              </Form.Item>
            </Col>
          )}
          {taskInfo.reviewTime && (
            <Col span={24} md={12} xl={6}>
              <Form.Item
                label={`${t('sortationSystem.taskListColumns.reviewTime')}:`}
                style={{ marginBottom: 0 }}
              >
                <Typography.Text>{taskInfo.reviewTime}</Typography.Text>
              </Form.Item>
            </Col>
          )}
          {!!taskInfo.reviewAdminId && (
            <Col span={24} md={12} xl={6}>
              <Form.Item
                label={`${t('sortationSystem.taskListColumns.reviewAdminId')}:`}
                style={{ marginBottom: 0 }}
              >
                <Typography.Text>{taskInfo.reviewAdminId}</Typography.Text>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('sortationSystem.taskListColumns.reviewInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskReview;
