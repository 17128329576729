import { Button, Card, Empty, Row, Skeleton, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Container from '../../../components/Container';
import FiveHundred from '../../../components/FiveHundred';
import FourZeroFour from '../../../components/FourZeroFour';
import FourZeroThree from '../../../components/FourZeroThree';
import ClaimModal from '../../../components/order/ClaimModal';
import ClaimAttachmentList from '../../../components/order/orderClaimDetail/ClaimAttachmentList';
import ClaimBasicInfo from '../../../components/order/orderClaimDetail/ClaimBasicInfo';
import ClaimGoodsList from '../../../components/order/orderClaimDetail/ClaimGoodsList';
import ClaimOrderInfo from '../../../components/order/orderClaimDetail/ClaimOrderInfo';
import ClaimRefundLogList from '../../../components/order/orderClaimDetail/ClaimRefundLogList';
import ClaimReviewInfo from '../../../components/order/orderClaimDetail/ClaimReviewInfo';
import PayLogList from '../../../components/order/orderClaimDetail/PayLogList';
import OrderClaimTable from '../../../components/users/userDetail/OrderClaimTable';
import { actionPermissions } from '../../../constants/actionPermissions';
import { WHITE } from '../../../constants/color';
import { REVIEW_STATUS_DEFAULT } from '../../../constants/generalConstants';
import { dashboardRoute } from '../../../constants/pathname';
import { addPage } from '../../../features/pageHistory/pageHistorySlice';
import { OrderClaimData, OrderEnum } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';

/**
 * Detail Page for an individual claim
 * /dashboard/order/orderClaimDetail
 */
const OrderClaimDetail = () => {
  //General Component
  const queryString = window.location.search;
  const [claimId] = useState(new URLSearchParams(queryString).get('claim_id'));
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const isSubscribed = useRef(true);
  const [fourZeroFour, setFourZeroFour] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const collapsed = useAppSelector((state) => state.appConfig.sidebarCollapsed);
  const screens = useBreakpoint();
  const [modalVisible, setModalVisible] = useState(false);
  //Text Component
  const { t } = useTranslation();
  //Table Component
  //Data Component
  const [orderClaimDetail, setOrderClaimDetail] = useState<OrderClaimData>();
  const [enums, setEnums] = useState<OrderEnum>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getData = useCallback(() => {
    const getEnums = async (sellerId: number) => {
      getDataWithAuthToken(`order/enum_list?sellerId=${sellerId}`)
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setEnums(response.data);
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`claim/detail?claimId=${claimId}`)
      .then(async (response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (response.data) {
              setOrderClaimDetail(response.data);
              document.title = `${t('order.orderClaimDetail')} ${
                response.data.claimSn
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('order.orderClaimDetail')} ${
                    response.data.claimSn
                  }`,
                  path: `${dashboardRoute.order.orderClaimDetail}?claim_id=${claimId}`,
                })
              );
              await getEnums(response.data.seller.sellerId);
            } else setFourZeroFour(true);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          if (isSubscribed.current) setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [claimId, dispatch, t]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container style={{ marginTop: 5 }}>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fourZeroFour ? (
        <Card>
          <FourZeroFour />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Row gutter={[5, 5]}>
            <Card bordered={false} style={{ width: '100%', height: '100%' }}>
              <Skeleton active loading={loading}>
                {orderClaimDetail ? (
                  <ClaimBasicInfo
                    orderClaimDetail={orderClaimDetail}
                    onRefresh={getData}
                  />
                ) : (
                  <Empty />
                )}
              </Skeleton>
            </Card>
            <Card bordered={false} style={{ width: '100%', height: '100%' }}>
              <Skeleton active loading={loading}>
                {orderClaimDetail ? (
                  <ClaimReviewInfo orderClaimDetail={orderClaimDetail} />
                ) : (
                  <Empty />
                )}
              </Skeleton>
            </Card>
            {orderClaimDetail && orderClaimDetail.orderInfo && (
              <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                <Skeleton active loading={loading}>
                  <ClaimOrderInfo orderClaimDetail={orderClaimDetail} />
                </Skeleton>
              </Card>
            )}
            {orderClaimDetail && orderClaimDetail.claimGoodsList && (
              <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                <Skeleton active loading={loading}>
                  <ClaimGoodsList
                    claimGoodsList={orderClaimDetail.claimGoodsList}
                    loading={loading}
                  />
                </Skeleton>
              </Card>
            )}
            {orderClaimDetail && orderClaimDetail.claimAttachmentList && (
              <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                <Skeleton active loading={loading}>
                  <ClaimAttachmentList
                    loading={loading}
                    orderClaimDetail={orderClaimDetail}
                  />
                </Skeleton>
              </Card>
            )}
            {orderClaimDetail && orderClaimDetail.claimRefundLogList && (
              <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                <Skeleton active loading={loading}>
                  <ClaimRefundLogList
                    refresh={() => getData()}
                    loading={loading}
                    orderClaimDetail={orderClaimDetail}
                  />
                </Skeleton>
              </Card>
            )}
            {orderClaimDetail && orderClaimDetail.payLogList && (
              <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                <Skeleton active loading={loading}>
                  <PayLogList
                    loading={loading}
                    orderClaimDetail={orderClaimDetail}
                  />
                </Skeleton>
              </Card>
            )}
            {orderClaimDetail && orderClaimDetail.orderClaimList && (
              <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                <Skeleton active loading={loading}>
                  <OrderClaimTable
                    simplified={{
                      simplifiedForm: true,
                      orderClaimList: orderClaimDetail.orderClaimList,
                      loading: loading,
                      callBack: getData,
                    }}
                  />
                </Skeleton>
              </Card>
            )}
          </Row>
          {orderClaimDetail?.reviewStatus.code === REVIEW_STATUS_DEFAULT && (
            <Space
              style={{
                position: 'fixed',
                bottom: 0,
                left: screens.sm ? (collapsed ? 55 : 200) : 0,
                right: 0,
                backgroundColor: WHITE,
                padding: '8px 24px 8px 24px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                zIndex: 3,
                boxShadow:
                  '0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%)',
              }}
            >
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.claimManage)
                }
                style={{ fontWeight: 'bold', padding: 0 }}
                onClick={() => setModalVisible(true)}
                type="link"
              >
                {t('actionsColumn.review')}
              </Button>
            </Space>
          )}
          <ClaimModal
            selectedRefund={orderClaimDetail}
            visible={modalVisible}
            setVisible={setModalVisible}
            refresh={() => getData()}
            enums={enums}
          />
        </>
      )}
    </Container>
  );
};

export default OrderClaimDetail;
