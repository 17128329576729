import { Card } from 'antd';
import Container from '../../components/Container';
import UserCouponTable from '../../components/users/userDetail/UserCouponTable';

const UserCouponList = () => {
  return (
    <Container>
      <Card>
        <UserCouponTable />
      </Card>
    </Container>
  );
};

export default UserCouponList;
