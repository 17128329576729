import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

/**
 * Stores the app config
 */

interface AppConfigState {
  sidebarCollapsed: boolean;
}

const initialState: AppConfigState = {
  sidebarCollapsed: false,
};

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    /**
     * @param state  Current SelectedUser
     * @param action The new SelectedUser
     */
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload;
    },
  },
});

export const { setSidebarCollapsed } = appConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const sidebarCollapsed = (state: RootState) =>
  state.appConfig.sidebarCollapsed;

export default appConfigSlice.reducer;
