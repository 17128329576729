import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { routes } from './constants/routes';
import ScrollToTop from './components/ScrollToTop';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BackTop, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';

function App() {
  const { i18n } = useTranslation();
  return (
    <Provider store={store}>
      <ConfigProvider
        locale={i18n.language.includes('zh') ? zhCN : enUS}
        autoInsertSpaceInButton={false}
      >
        <Router>
          <ScrollToTop />
          {renderRoutes(routes)}
          <BackTop />
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
