import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { postDataWithAuthToken } from '../utils/axiosRequest';
import { alertMessage } from '../utils/alertMessage';

type AdminModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  callBack?: Function;
  userEmail: string;
};

/**
 * Modal used for changing user password
 *
 * @prop visible     If Modal should be shown
 * @prop setVisible  Setting whether Modal should be displayed or not
 * @prop callBack    What to call next after form is submitted (usually a table refresh function)
 * @prop userEmail   Profile to get user's email
 */
const ChangeProfileModal = ({
  visible,
  setVisible,
  callBack,
  userEmail,
}: AdminModalProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const isSubscribed = useRef(true);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        try {
          if (isSubscribed.current) setLoading(true);
          postDataWithAuthToken('admin/edit_profile', {
            email: values.email,
            password:
              values.password && values.password.length > 0
                ? values.password
                : undefined,
            confirmPassword:
              values.confirmPassword && values.confirmPassword.length > 0
                ? values.confirmPassword
                : undefined,
          }).then((response) => {
            if (response && response.goodStatus) {
              if (isSubscribed.current) setVisible(false);
              alertMessage('success', 'Profile Changed');
              if (callBack) {
                callBack();
              }
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
            if (isSubscribed.current) setLoading(false);
          });
        } catch (err) {
          console.log(err);
          if (isSubscribed.current) setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    if (isSubscribed.current) {
      setFirstPassword('');
      setVisible(false);
    }
  };

  return (
    <Modal
      title={t('profile.edit/ChangeProfile.changeTitle')}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('profile.edit/ChangeProfile.ok')}
      cancelText={t('profile.edit/ChangeProfile.cancel')}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="email"
            label={t('profile.edit/ChangeProfile.email')}
            initialValue={userEmail}
            rules={[
              {
                type: 'email',
                message: t('general.inputError.pleaseInputValidEmail'),
              },
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('profile.edit/ChangeProfile.password')}
          >
            <Input.Password
              onChange={(e) => setFirstPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={t('profile.edit/ChangeProfile.confirmPassword')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: firstPassword.length > 0,
                message: t('general.inputError.pleaseConfirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(t('general.inputError.passwordsDontMatch'))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ChangeProfileModal;
