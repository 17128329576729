import { Col, Empty, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderData } from '../../../types';
import OrderClaimTable from '../../users/userDetail/OrderClaimTable';

type OrderClaimInfoProps = {
  orderInfo?: OrderData;
  loading: boolean;
  callBack: Function;
};

const OrderClaimInfo = ({
  orderInfo,
  loading,
  callBack,
}: OrderClaimInfoProps) => {
  const { t } = useTranslation();

  return orderInfo && orderInfo.orderClaimList ? (
    <OrderClaimTable
      simplified={{
        simplifiedForm: true,
        orderClaimList: orderInfo.orderClaimList,
        loading: loading,
        callBack: callBack,
      }}
    />
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderClaimList')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderClaimInfo;
