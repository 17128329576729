import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Grid,
  Space,
  Typography,
  Tooltip,
  Popover,
  Select,
  Badge,
  Dropdown,
  Menu,
  Tag,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Table, { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import FourZeroThree from '../../FourZeroThree';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import TableToolbar from '../../table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  UserIDListData,
  FontSizeType,
  BasicEnumInfoType,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import { hasPermission } from '../../../utils/hasPermission';
import { dashboardRoute } from '../../../constants/pathname';
import UserIDModal from '../UserIDModal';
import FiveHundred from '../../FiveHundred';
import { REVIEW_STATUS } from '../../../constants/generalConstants';
import { actionPermissions } from '../../../constants/actionPermissions';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import UsersDropdown from '../common/UsersDropdown';
import { tableScrollToTop } from '../../../utils/helperFunction';

type UserIDTableProps = {
  id?: string;
};

const UserIDTable = ({ id }: UserIDTableProps) => {
  //General Component
  const isSubscribed = useRef(true);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const { addTab } = useTab();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  //Data Component
  const [userIDListData, setUserIDListData] = useState<Array<UserIDListData>>(
    []
  );
  const [userID, setUserID] = useState<UserIDListData>();
  const [identificationType, setIdentificationType] = useState<
    Array<BasicEnumInfoType>
  >([]);
  //Table Component
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<UserIDListData[]>([]);
  const columnKeys: string[] = [
    'identificationId',
    'userId',
    'orderId',
    'address',
    'identificationType',
    'realName',
    'bankCard',
    'reviewStatus',
    'addTime',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);

  const columns: ColumnsType<UserIDListData> = [
    {
      title: setFont(t('users.userIDColumns.identificationId'), fontSize),
      key: 'identificationId',
      dataIndex: 'identificationId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: (a: UserIDListData, b: UserIDListData) =>
        compare(a.identificationId, b.identificationId),
      render: (text: string, record: UserIDListData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.userGroup.userRealManage)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setUserID(record);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(`${t('users.userIDColumns.userId')}`, fontSize),
      key: 'userId',
      dataIndex: 'userId',
      width: 100,
      render: (value: string) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.userGroup.userView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab('', `${dashboardRoute.users.detail}?user_id=${value}`)
          }
        >
          {value}
        </Button>
      ),
    },
    {
      title: setFont(`${t('users.userIDColumns.orderId')}`, fontSize),
      key: 'orderId',
      dataIndex: 'orderId',
      width: 100,
      render: (value: number) => (
        <Button
          type="link"
          disabled={
            !hasPermission(actionPermissions.orderGroup.orderView) || !value
          }
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab('', `${dashboardRoute.order.detail}?order_id=${value}`)
          }
        >
          {value}
        </Button>
      ),
    },
    {
      title: setFont(
        `${t('users.userIDColumns.identificationType')}`,
        fontSize
      ),
      key: 'identificationType',
      dataIndex: 'identificationType',
      width: 200,
      render: (value: string) =>
        identificationType.map((type, index) => {
          if (type.code === value) {
            return (
              <Typography.Text key={index}>{type.description}</Typography.Text>
            );
          }
          return <Typography.Text key={index}>{''}</Typography.Text>;
        }),
    },
    {
      title: setFont(`${t('users.userIDColumns.realName')}`, fontSize),
      key: 'realName',
      dataIndex: 'realName',
      width: 200,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(`${t('users.userIDColumns.bankCard')}`, fontSize),
      key: 'bankCard',
      dataIndex: 'bankCard',
      width: 200,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(`${t('users.userIDColumns.address')}`, fontSize),
      key: 'address',
      dataIndex: 'address',
      width: 200,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(`${t('users.userIDColumns.reviewStatus')}`, fontSize),
      key: 'reviewStatus',
      dataIndex: 'reviewStatus',
      width: 160,
      render: (value: string, record: UserIDListData) =>
        record.reviewStatus && (
          <Tag
            style={{ fontSize: fontSize }}
            color={record.reviewStatus.tagColor}
          >
            {record.reviewStatus.description}
          </Tag>
        ),
    },
    {
      title: setFont(t('users.userIDColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 80,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: UserIDListData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.userGroup.userRealManage)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setUserID(record);
            setShowModal(true);
          }}
        >
          {t('actionsColumn.view')}
        </Button>
      ),
    },
  ];

  const getUserIDData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('users/identification/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        userId: id
          ? id
          : formRef.current && form.getFieldValue('user_id')
          ? form.getFieldValue('user_id')
          : undefined,
        identificationType:
          formRef.current && form.getFieldValue('identificationType')
            ? form.getFieldValue('identificationType')
            : undefined,

        reviewStatus:
          formRef.current && form.getFieldValue('reviewStatus')
            ? form.getFieldValue('reviewStatus')
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setUserIDListData(response.data.list);
            setTotal(response.data.totalItem);
            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else setFiveHundred(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [page, pageSize, t, form, keyword, id]);

  const getUserIDEnumData = useCallback(() => {
    getDataWithAuthToken('users/identification/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setIdentificationType(response.data.identificationType);
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getUserIDData();
  }, [getUserIDData]);

  useEffect(() => {
    if (hasPermission(actionPermissions.userGroup.userRealManage))
      getUserIDEnumData();
  }, [getUserIDEnumData]);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const advancedSearch = (
    <Popover
      trigger={['click']}
      placement="bottomRight"
      content={
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          style={{ width: 250 }}
        >
          <Form.Item
            label={t('users.userIDColumns.identificationType')}
            name="identificationType"
            style={{ marginBottom: 12 }}
          >
            <Select
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch={false}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            >
              {identificationType.map((type) => (
                <Select.Option key={type.description} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!id && (
            <Form.Item
              name="user_id"
              label={t('users.userIDColumns.advancedSearch.userId')}
            >
              <UsersDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    user_id: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label={t('users.userIDColumns.reviewStatus')}
            name="reviewStatus"
          >
            <Select
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch={false}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  reviewStatus: value,
                }))
              }
              optionLabelProp="value"
            >
              {Object.keys(REVIEW_STATUS).map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {t(REVIEW_STATUS[type])}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getUserIDData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('users.userIDColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(advance).every(
                (value) => typeof value !== 'number' && !value
              )}
              onClick={() => {
                form.resetFields();
                setAdvance({});
                if (page !== 1) setPage(1);
                else getUserIDData();
              }}
            >
              {t('users.userIDColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userIDColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  const batchDel = () => {
    postDataWithAuthToken('users/identification/delete_batch', {
      identificationIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getUserIDData();
          alertMessage('success', t('users.alerts.batchUserIDDel'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAppLink = (x: string) => {
    if (x === 'send') {
      setIsLoading(true);
      postDataWithAuthToken('users/identification/send', {
        userId: id ? id : undefined,
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              alertMessage('success', t('users.alerts.appLinkSent'));
              setIsLoading(false);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err);
        });
    } else if (x === 'copy') {
      setIsLoading(true);
      getDataWithAuthToken('users/identification/link', {
        params: { userId: id ? id : undefined },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              navigator.clipboard.writeText(response.data);
              alertMessage('success', t('users.alerts.appLinkCopied'));
              setIsLoading(false);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err);
        });
    }
  };

  const menu = (
    <Menu
      onClick={(item) => {
        if (hasPermission(actionPermissions.userGroup.userRealManage))
          handleAppLink(item.key);
      }}
    >
      <Menu.Item key={'copy'}>
        {t('users.userIDColumns.appLink.copy')}
      </Menu.Item>
      <Menu.Item key={'send'}>
        {t('users.userIDColumns.appLink.send')}
      </Menu.Item>
    </Menu>
  );

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {id ? (
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('users.userIDList')}
        </Typography.Title>
      ) : (
        <Typography.Title level={3} style={{ fontWeight: 500 }}>
          {t('users.userIDList')}
        </Typography.Title>
      )}
      <TableToolbar
        leftElement={
          <>
            {id ? (
              hasPermission(actionPermissions.userGroup.userRealManage) && (
                <Dropdown overlay={menu} placement="bottomCenter">
                  <Tooltip title={t(`users.alerts.appLinkToolTip`)}>
                    <Button loading={isLoading}>
                      {t('users.userIDColumns.appLink.title')}
                    </Button>
                  </Tooltip>
                </Dropdown>
              )
            ) : (
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.userGroup.userRealManage)
                }
                onClick={() => {
                  setUserID(undefined);
                  setShowModal(true);
                }}
              >
                {t('users.viewUserIDModal.addTitle')}
              </Button>
            )}
          </>
        }
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getUserIDData()}
        totalItems={total}
        searchPlaceholder={t('searchPlaceholders.searchUserID')}
        search={(keyword) => {
          setKeyword(keyword);
          setPage(1);
        }}
        rows={userIDListData.map((userID) => ({
          ...userID,
        }))}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        exportConfig={{ fileName: 'USER_ID_LIST' }}
        advancedSearch={advancedSearch}
      />
      <Table
        dataSource={userIDListData}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        loading={isLoading}
        size={tableSize}
        components={vt}
        rowKey={(good) => good.identificationId}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: UserIDListData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
          total: total,
          current: page,
        }}
        scroll={{ y: 600, x: 800 }}
      />
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          typeSpecificActions="users"
          selectedRowKeys={selectedRowKeys}
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          funct={{
            deleteFunc: getDashboardStyle().isSalesForceAppType
              ? undefined
              : () => batchDel(),
            refresh: () => getUserIDData(),
            exportConfig: { fileName: 'USER_ID_LIST' },
          }}
        />
      )}
      <UserIDModal
        userID={userID}
        visible={showModal}
        setVisible={setShowModal}
        refresh={() => getUserIDData()}
        identificationTypes={identificationType}
      />
    </>
  );
};

export default UserIDTable;
