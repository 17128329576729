import Container from '../../components/Container';
import { Card, Typography, Tabs, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import OrderClaimOverview from '../../components/analysis/order/OrderClaimOverview';
import OrderClaimDistribution from '../../components/analysis/order/OrderClaimDistribution';
import OrderClaimDetail from '../../components/analysis/order/OrderClaimDetail';
import { useState } from 'react';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import getDashboardStyle from '../../utils/getDashboardStyle';
const OrderClaimAnalysis = () => {
  //General Components
  const [isSeller, setIsSeller] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  //Text Components
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  return (
    <Container>
      <Card bodyStyle={{ minHeight: 250 }}>
        <Space>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('analysis.orderClaimTitle')}
          </Typography.Title>
          {getDashboardStyle().isSellerSwitch && (
            <Switch
              loading={isLoading}
              onChange={(checked) => {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => {
                    setIsSeller(checked);
                  }, EXTENDED_TIMEOUT)
                );
              }}
              style={{ marginBottom: 12 }}
              checkedChildren={t('goods.goodsListColumns.seller')}
              unCheckedChildren={t('goods.goodsListColumns.self')}
            />
          )}
        </Space>
        <Tabs destroyInactiveTabPane defaultActiveKey="1">
          <Tabs.TabPane
            tab={t(`analysis.orderClaimOverview.tab1Title`)}
            key={'1'}
          >
            <OrderClaimOverview
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.orderClaimDistribution.tab2Title`)}
            key={'2'}
          >
            <OrderClaimDistribution
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.orderClaimDetail.tab3Title`)}
            key={'3'}
          >
            <OrderClaimDetail
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Container>
  );
};

export default OrderClaimAnalysis;
