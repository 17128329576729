import { useTranslation } from 'react-i18next';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { useState } from 'react';
import { EmployeeData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { Typography } from 'antd';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';

type EmployeeDropdownProps = {
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const EmployeeDropdown = ({
  disabled = false,
  initialValue,
  onChange,
}: EmployeeDropdownProps) => {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState<Array<EmployeeData>>([]);
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  //Pagination Components
  const [currentPageEmployee, setCurrentPageEmployee] = useState(2);
  const [totalPageEmployee, setTotalPageEmployee] = useState(2);

  const getEmployeeData = (value?: string) => {
    getDataWithAuthToken('employee/list', {
      params: {
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setEmployeeList(response.data.list);
          setCurrentPageEmployee(2);
          setTotalPageEmployee(response.data.totalPage);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOnScrollEmployee = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (currentPageEmployee > totalPageEmployee) return;
      getDataWithAuthToken('employee/list', {
        params: {
          page: currentPageEmployee,
          keyword: searchText.employee || undefined,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setEmployeeList((prev) => [...prev, ...response.data.list]);
          setCurrentPageEmployee((prev) => prev + 1);
          setTotalPageEmployee(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={
        !hasPermission(actionPermissions.sortationGroup.employeeView) ||
        disabled
      }
      optionLabelProp="key"
      showSearch
      placeholder={t('searchPlaceholders.searchEmployee')}
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      onPopupScroll={fetchOnScrollEmployee}
      onFocus={() => {
        if (!employeeList.length) getEmployeeData();
      }}
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, employee: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getEmployeeData(value), EXTENDED_TIMEOUT)
        );
      }}
      defaultValue={initialValue ? initialValue : undefined}
      allowClear
      filterOption={false}
      onChange={handleOnChange}
    >
      {employeeList.map((employee) => (
        <Select.Option
          key={`${employee.nickname} (${employee.employeeId})`}
          value={employee.employeeId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('sortationSystem.employeeListColumns.employeeId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('sortationSystem.employeeListColumns.nickname')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{employee.employeeId}</Typography>
              <Typography>{employee.nickname}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default EmployeeDropdown;
