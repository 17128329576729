import Select, { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminRoleData } from '../../types';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';
import { Typography } from 'antd';

type AdminRoleDropdownProps = {
  disabled?: boolean;
  initialValue?: number;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const AdminRoleDropdown = ({
  disabled,
  initialValue,
  onChange,
}: AdminRoleDropdownProps) => {
  const { t } = useTranslation();
  const [roleList, setRoleList] = useState<AdminRoleData[]>([]);
  const [currentPage, setCurrentPage] = useState(2);
  const [totalPage, setTotalPage] = useState(2);

  // Fetches role data so user can choose in a select list
  const getRoleData = () => {
    getDataWithAuthToken('admin/role/list')
      .then((response) => {
        if (response && response.goodStatus) {
          setRoleList(response.data.list);
          setCurrentPage(2);
          setTotalPage(response.data.totalPage);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (currentPage > totalPage) return;

      getDataWithAuthToken('admin/role/list', {
        params: {
          page: currentPage,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setRoleList((prev) => [...prev, ...response.data.list]);
          setCurrentPage((prev) => prev + 1);
          setTotalPage(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={
        !hasPermission(actionPermissions.adminGroup.adminManage) || disabled
      }
      defaultValue={initialValue ? initialValue : undefined}
      placeholder={t('searchPlaceholders.selectRoleId')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={fetchOnScroll}
      onFocus={() => {
        if (!roleList.length) getRoleData();
      }}
      allowClear
      onChange={handleOnChange}
      optionLabelProp="key"
      filterOption={false}
      // labelInValue
    >
      {roleList.map((role) => (
        <Select.Option
          key={`${role.roleName} (${role.roleId})`}
          value={role.roleId}
          actionList={role.actionList}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('admin.adminRoleColumns.id')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('admin.adminRoleColumns.roleName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{role.roleId}</Typography>
              <Typography>{role.roleName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AdminRoleDropdown;
