import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { DepartmentData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { Select, Typography } from 'antd';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { DefaultOptionType } from 'antd/lib/select';
import { actionPermissions } from '../../../constants/actionPermissions';
import { hasPermission } from '../../../utils/hasPermission';

type DepartmentDropdownProps = {
  labelInValue?: boolean;
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const DepartmentDropdown = ({
  labelInValue = false,
  disabled = false,
  initialValue,
  onChange,
}: DepartmentDropdownProps) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [departments, setDepartments] = useState<DepartmentData[]>([]);
  const [departmentPage, setDepartmentPage] = useState(2);
  const [departmentTotalPage, setDepartmentTotalPage] = useState(2);

  const getDepartments = (value?: string) => {
    getDataWithAuthToken('goods/department/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setDepartments(response.data.list);
          setDepartmentPage(2);
          setDepartmentTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * Fetch when department list scrolls to the bottom
   * @param e UI event
   */
  const departmentFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (departmentPage > departmentTotalPage) return;
      getDataWithAuthToken('goods/department/list', {
        params: {
          keyword: searchText.department || undefined,
          page: departmentPage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setDepartments((prev) => [...prev, ...response.data.list]);
            setDepartmentPage((prev) => prev + 1);
            setDepartmentTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={departmentFetchOnScroll}
      showSearch
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, department: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getDepartments(value), EXTENDED_TIMEOUT)
        );
      }}
      onFocus={() => {
        if (!departments.length) getDepartments();
      }}
      filterOption={false}
      placeholder={t('searchPlaceholders.searchDepartments')}
      optionLabelProp="key"
      disabled={
        !hasPermission(actionPermissions.goodGroup.departmentView) || disabled
      }
      allowClear
      labelInValue={labelInValue}
      defaultValue={initialValue ? initialValue : undefined}
      onChange={handleOnChange}
    >
      {departments.map((department) => (
        <Select.Option
          key={`${department.departmentName} (${department.departmentId})`}
          value={department.departmentId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('goods.departmentListColumns.departmentId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('goods.departmentListColumns.departmentName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{department.departmentId}</Typography>
              <Typography>{department.departmentName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default DepartmentDropdown;
