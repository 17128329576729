import { Form, InputNumber, Modal, Radio, Select, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';

type OrderSelectAttrModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGoods?: any;
  setSelectedGoods?: React.Dispatch<React.SetStateAction<any>>;
  callBack?: () => void;
  userId: number;
  currentGood?: GoodData;
  currentGoodIndex?: number;
  orderId?: number;
};
const SelectGoodAttrModal = ({
  visible,
  setVisible,
  selectedGoods,
  callBack,
  setSelectedGoods,
  userId,
  currentGood,
  currentGoodIndex,
  orderId,
}: OrderSelectAttrModalProps) => {
  const { t } = useTranslation();
  const [goodDetail, setGoodDetail] = useState<GoodData>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customPrice, setCustomPrice] = useState<number>();

  const getGoodDetail = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('goods/detail', {
      params: {
        goodsId: currentGood && currentGood.goodsId,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setGoodDetail(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [t, currentGood]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getGoodDetail();
    }
  }, [visible, getGoodDetail, form]);

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const { goodsNumber, shopPrice, goodsWeight, ...rest } = values;
        postDataWithAuthToken('order/preview_goods', {
          userId: userId,
          sellerId:
            goodDetail && goodDetail.seller ? goodDetail.seller.sellerId : 0,
          orderId: orderId,
          orderGoodsList: [
            ...(selectedGoods || []).map((good: any, index: number) => ({
              goodsId: good.goodsId,
              goodsName: good.goodsName,
              goodsWeight: good.goodsWeight,
              goodsNumber:
                currentGoodIndex !== undefined && index === currentGoodIndex
                  ? goodsNumber
                  : good.goodsNumber,
              goodsSkuAttrIds:
                (good.goodsAttrId && good.goodsAttrId.split(',')) || [],
              recId: good.recId || undefined,
              goodsPrice: good.goodsPrice,
              mainGoodsPrice: good.mainGoodsPrice,
            })),
            ...(currentGoodIndex !== undefined
              ? []
              : [
                  {
                    goodsId: (goodDetail && goodDetail.goodsId) || undefined,
                    goodsName:
                      (goodDetail && goodDetail.goodsName) || undefined,
                    goodsNumber: goodsNumber,
                    goodsSkuAttrIds: Object.values(rest),
                    goodsPrice: values.shopPrice
                      ? values.shopPrice === 1
                        ? goodDetail && goodDetail.promotePrice
                        : values.shopPrice === 2
                        ? goodDetail && goodDetail.memberPrice
                        : customPrice
                      : undefined,
                    mainGoodsPrice:
                      goodDetail && goodDetail.mainGoods
                        ? goodDetail.mainGoods.shopPrice
                        : undefined,
                    goodsWeight: goodsWeight,
                  },
                ]),
          ],
        })
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              setSelectedGoods &&
                setSelectedGoods((prev: any) => ({
                  ...prev,
                  orderGoodsList: response.data.orderGoodsList,
                  orderGoodsListTotal: response.data.goodsAmount,
                  shippingTransportId: response.data.transportId,
                }));
              onClose();
              callBack && callBack();
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onClose = () => {
    setGoodDetail(undefined);
    setVisible(false);
  };

  return (
    <Modal
      title={t('order.add/editOrder.selectAttr')}
      visible={visible}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t('order.add/editOrder.ok')}
      cancelText={t('order.add/editOrder.cancel')}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={{
            goodsNumber:
              currentGoodIndex !== undefined && currentGood
                ? currentGood.goodsNumber
                : 1,
            goodsWeight: currentGood ? currentGood.goodsWeight : '',
            shopPrice: 0,
          }}
        >
          {currentGoodIndex === undefined && goodDetail && (
            <>
              {goodDetail.sku &&
                goodDetail.sku.specTypeAttr &&
                goodDetail.sku.specTypeAttr.map(
                  (attr) =>
                    attr.goodsSkuAttr &&
                    !!attr.goodsSkuAttr.length && (
                      <Form.Item
                        name={attr.specAttrName}
                        label={attr.specAttrName}
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.pleaseSelectOne'),
                          },
                        ]}
                        key={attr.specAttrId}
                      >
                        <Select style={{ width: '100%' }}>
                          {attr.goodsSkuAttr.map((a) => (
                            <Select.Option
                              value={a.goodsSkuAttrId}
                              key={a.goodsSkuAttrId}
                            >
                              {a.skuAttrValue}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )
                )}
              <Form.Item
                name="shopPrice"
                label={t('goods.add/editGood.shopPrice')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                  () => ({
                    validator(rule, value) {
                      if (
                        value === 3 &&
                        (customPrice === null || customPrice === undefined)
                      ) {
                        return Promise.reject(
                          new Error(t('general.inputError.empty'))
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>
                    {`${t('goods.add/editGood.normalPrice')}${
                      goodDetail.sku ? '' : ` ${goodDetail.shopPrice}`
                    }`}
                  </Radio>
                  {goodDetail.promotePrice && (
                    <Radio value={1}>
                      {`${t('goods.add/editGood.promotePrice')} ${
                        goodDetail.promotePrice
                      }`}
                    </Radio>
                  )}
                  {goodDetail.memberPrice && goodDetail.memberPrice > 0 && (
                    <Radio value={2}>
                      {`${t('goods.goodsListColumns.memberPrice')} ${
                        goodDetail.memberPrice
                      }`}
                    </Radio>
                  )}
                  {!getDashboardStyle().isSalesForceAppType && (
                    <Radio value={3}>
                      <InputNumber
                        type="number"
                        min={0}
                        placeholder={t('goods.add/editGood.custom')}
                        onChange={(value) => {
                          setCustomPrice(value !== null ? value : undefined);
                        }}
                      />
                    </Radio>
                  )}
                </Radio.Group>
              </Form.Item>
            </>
          )}
          <Space size={16}>
            {currentGood && currentGood.depositFee && (
              <Form.Item label={t('order.orderDetail.depositFee')}>
                {currentGood?.depositFee}
              </Form.Item>
            )}
            {currentGood && currentGood.recycleFee && (
              <Form.Item label={t('order.orderDetail.recycleFee')}>
                {currentGood?.recycleFee}
              </Form.Item>
            )}
          </Space>

          <Form.Item
            name="goodsNumber"
            label={t('order.orderDetail.amount')}
            rules={[{ required: true, message: t('general.inputError.empty') }]}
          >
            <InputNumber type="number" min={1} />
          </Form.Item>
          {currentGood?.isWeightGoods && (
            <Form.Item
              name="goodsWeight"
              label={t('goods.goodsListColumns.goodsWeight')}
              rules={[
                {
                  required: currentGood?.isWeightGoods,
                  message: t('general.inputError.pleaseInputAmount'),
                },
                () => ({
                  validator(rule, value) {
                    if (value <= 0) {
                      return Promise.reject(
                        new Error(t('order.inputError.outOfRange'))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber type="number" min={0} />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default SelectGoodAttrModal;
