import { ControlOutlined, FilterOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Badge,
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Grid,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SortOrder, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import PrintModal from '../../components/PrintModal';
import TaskSortOrderModal from '../../components/sortationSystem/TaskSortOrderModal';
import EmployeeDropdown from '../../components/sortationSystem/common/EmployeeDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { WHITE } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import {
  FontSizeType,
  OrderRouteData,
  PrintType,
  TaskData,
  TaskEnum,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';
import { makeTaskGoodsData } from '../../views/sortationSystem/TaskDetail';
import TaskOrderGoodsTable from './taskDetail/TaskOrderGoodsTable';

type TaskListTableProps = {
  taskListDetail?: TaskData;
};

const TaskListTable = ({ taskListDetail }: TaskListTableProps) => {
  // General components
  const queryString = window.location.search;
  const [orderId] = useState(new URLSearchParams(queryString).get('order_id'));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { addTab } = useTab();
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTaskGoodsLoading, setIsTaskGoodsLoading] = useState<boolean>(true);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false);
  const [isPrintModalVisible, setIsPrintModalVisible] =
    useState<boolean>(false);
  // Data components
  const [taskNav, setTaskNav] = useState<string>('ALL');
  const [taskEnum, setTaskEnum] = useState<TaskEnum>();
  const [tasks, setTasks] = useState<TaskData[]>([]);
  const [advance, setAdvance] = useState<{ [key: string]: any }>({});
  const [routeData, setRouteData] = useState<OrderRouteData[]>([]);
  const [taskLabelData, setTaskLabelData] = useState<{ value: string }[]>([]);
  const [cartNumberData, setCartNumberData] = useState<{ value: string }[]>([]);
  const [orderCount, setOrderCount] = useState<number>();
  const [taskInfo, setTaskInfo] = useState<TaskData>();
  // Table components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<TaskData[]>([]);
  // Pagination components
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(null);
  const [sortValue, setSortValue] = useState<React.Key>('');
  const columnKeys = [
    'taskId',
    'taskStage|taskType',
    'taskGoodsNum',
    'taskStatus',
    'employeeId|nickname|mobile',
    'taskTime|startTime|finishTime|addTime',
    'orderSn|consignee|sequenceLabel|orderShippingLabel',
    'reviewAdminId|reviewStatus|reviewContent|reviewTime',
    'taskLabel|cartNumber',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getOrderCount = useCallback(() => {
    getDataWithAuthToken('task/order_count', {
      params: {
        taskNav: taskNav ?? undefined,
        taskType:
          formRef.current && form.getFieldValue('taskType')
            ? form.getFieldValue('taskType')
            : undefined,
        taskStage:
          formRef.current && form.getFieldValue('taskStage')
            ? form.getFieldValue('taskStage')
            : undefined,
        taskStatus:
          formRef.current && form.getFieldValue('taskStatus')
            ? form.getFieldValue('taskStatus')
            : undefined,
        employeeId:
          formRef.current && form.getFieldValue('employeeId') !== undefined
            ? form.getFieldValue('employeeId')
            : undefined,
        orderSn:
          formRef.current && form.getFieldValue('orderSn')
            ? form.getFieldValue('orderSn')
            : undefined,
        startTaskTime: formRef.current
          ? form.getFieldValue('taskDate')
            ? moment(form.getFieldValue('taskDate')[0]).format(DATE_FORMAT)
            : undefined
          : orderId
          ? undefined
          : moment().format(DATE_FORMAT),
        endTaskTime: formRef.current
          ? form.getFieldValue('taskDate')
            ? moment(form.getFieldValue('taskDate')[1]).format(DATE_FORMAT)
            : undefined
          : orderId
          ? undefined
          : moment().format(DATE_FORMAT),
        startAddTime:
          formRef.current && form.getFieldValue('addTime')
            ? moment(form.getFieldValue('addTime')[0]).format(DATE_TIME_FORMAT)
            : undefined,
        endAddTime:
          formRef.current && form.getFieldValue('addTime')
            ? moment(form.getFieldValue('addTime')[1]).format(DATE_TIME_FORMAT)
            : undefined,
        orderId: orderId || undefined,
        taskLabel:
          formRef.current && form.getFieldValue('taskLabel')
            ? form.getFieldValue('taskLabel')
            : undefined,
        cartNumber:
          formRef.current && form.getFieldValue('cartNumber')
            ? form.getFieldValue('cartNumber')
            : undefined,
        routeId:
          formRef.current && form.getFieldValue('routeId')
            ? form.getFieldValue('routeId')
            : undefined,
        goodsName:
          formRef.current && form.getFieldValue('goodsName')
            ? form.getFieldValue('goodsName')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setOrderCount(response.data);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t, taskNav, form, orderId]);

  const getTasks = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('task/list', {
      params: {
        page: page,
        size: pageSize,
        taskNav: taskNav ?? undefined,
        taskType:
          formRef.current && form.getFieldValue('taskType')
            ? form.getFieldValue('taskType')
            : undefined,
        taskStage:
          formRef.current && form.getFieldValue('taskStage')
            ? form.getFieldValue('taskStage')
            : undefined,
        taskStatus:
          formRef.current && form.getFieldValue('taskStatus')
            ? form.getFieldValue('taskStatus')
            : undefined,
        employeeId:
          formRef.current && form.getFieldValue('employeeId') !== undefined
            ? form.getFieldValue('employeeId')
            : undefined,
        orderSn:
          formRef.current && form.getFieldValue('orderSn')
            ? form.getFieldValue('orderSn')
            : undefined,
        startTaskTime: formRef.current
          ? form.getFieldValue('taskDate')
            ? moment(form.getFieldValue('taskDate')[0]).format(DATE_FORMAT)
            : undefined
          : orderId
          ? undefined
          : moment().format(DATE_FORMAT),
        endTaskTime: formRef.current
          ? form.getFieldValue('taskDate')
            ? moment(form.getFieldValue('taskDate')[1]).format(DATE_FORMAT)
            : undefined
          : orderId
          ? undefined
          : moment().format(DATE_FORMAT),
        startAddTime:
          formRef.current && form.getFieldValue('addTime')
            ? moment(form.getFieldValue('addTime')[0]).format(DATE_TIME_FORMAT)
            : undefined,
        endAddTime:
          formRef.current && form.getFieldValue('addTime')
            ? moment(form.getFieldValue('addTime')[1]).format(DATE_TIME_FORMAT)
            : undefined,
        orderId: orderId || undefined,
        taskLabel:
          formRef.current && form.getFieldValue('taskLabel')
            ? form.getFieldValue('taskLabel')
            : undefined,
        cartNumber:
          formRef.current && form.getFieldValue('cartNumber')
            ? form.getFieldValue('cartNumber')
            : undefined,
        routeId:
          formRef.current && form.getFieldValue('routeId')
            ? form.getFieldValue('routeId')
            : undefined,
        sortOrder:
          sortOrder === 'ascend'
            ? 'ASC'
            : sortOrder === 'descend'
            ? 'DESC'
            : undefined,
        sortValue: (sortOrder && sortValue) || undefined,
        goodsName:
          formRef.current && form.getFieldValue('goodsName')
            ? form.getFieldValue('goodsName')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setTasks(response.data.list);
            setTotalItems(response.data.totalItem);
            setOrderCount(undefined);
            setSelectedRowKeys([]);
            if (orderId) {
              document.title = `${t('sortationSystem.taskList')} ${
                response.data.list &&
                response.data.list.length > 0 &&
                response.data.list[0].order
                  ? response.data.list[0].order.orderSn
                  : orderId
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('sortationSystem.taskList')} ${
                    response.data.list &&
                    response.data.list.length > 0 &&
                    response.data.list[0].order
                      ? response.data.list[0].order.orderSn
                      : orderId
                  }`,
                  path: `${dashboardRoute.sortationSystem.taskOrder}?order_id=${orderId}`,
                })
              );
            }

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [
    t,
    page,
    pageSize,
    taskNav,
    form,
    orderId,
    sortOrder,
    sortValue,
    dispatch,
  ]);

  const getTaskEnum = useCallback(() => {
    getDataWithAuthToken('task/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setTaskEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [t]);

  const getRouteData = (date: string) => {
    getDataWithAuthToken('order/route_list', {
      params: {
        shippingDate: date,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setRouteData(response.data.list);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskLabelList = () => {
    getDataWithAuthToken('task/task_label')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current)
            setTaskLabelData(
              response.data.map((taskLabel: string) => ({ value: taskLabel }))
            );
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCartNumberList = () => {
    getDataWithAuthToken('task/cart_number')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current)
            setCartNumberData(
              response.data.map((cartNumber: string) => ({ value: cartNumber }))
            );
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskDetailData = (taskId: number) => {
    if (isSubscribed.current) setIsTaskGoodsLoading(true);
    getDataWithAuthToken('task/detail', {
      params: {
        taskId: taskId,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setTaskInfo(response.data);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsTaskGoodsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsTaskGoodsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (!taskListDetail) {
      getTaskEnum();
    }
  }, [taskListDetail, getTaskEnum]);

  useEffect(() => {
    if (!taskListDetail) {
      if (!formRef.current && !orderId) {
        setAdvance((prev) => ({
          ...prev,
          taskDate: [
            moment().format(DATE_FORMAT),
            moment().format(DATE_FORMAT),
          ],
        }));
      }
      getTasks();
    }
  }, [taskListDetail, getTasks, orderId]);

  useEffect(() => {
    if (
      !taskListDetail &&
      tasks.length > 0 &&
      orderCount === undefined &&
      hasPermission(actionPermissions.sortationGroup.taskView)
    ) {
      getOrderCount();
    }
  }, [taskListDetail, tasks, orderCount, getOrderCount]);

  const advancedSearch = (
    <>
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />} onClick={() => setIsDrawerOpen(true)}>
          {t('sortationSystem.employeeLogListColumns.advancedSearch.title')}
        </Button>
      </Badge>
      <Drawer
        title={t('sortationSystem.employeeListColumns.advancedSearch.title')}
        visible={isDrawerOpen}
        closable={true}
        onClose={() => setIsDrawerOpen(false)}
        placement="right"
        headerStyle={{ padding: '10px 3px 10px 3px' }}
        width={screens.sm ? 600 : 350}
        bodyStyle={{ padding: 10 }}
      >
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{
            taskDate: orderId ? undefined : [moment(), moment()],
          }}
        >
          {!orderId && (
            <Form.Item
              label={t('order.orderListColumns.orderSn')}
              name="orderSn"
              style={{ marginBottom: 12 }}
            >
              <Input
                allowClear
                onChange={(e) => {
                  setAdvance((prev) => ({
                    ...prev,
                    orderSn: e.target.value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskLabel')}
            name="taskLabel"
            style={{ marginBottom: 12 }}
          >
            <AutoComplete
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
              options={taskLabelData}
              allowClear
              filterOption
              onFocus={() => {
                getTaskLabelList();
              }}
              onChange={(value) => {
                setAdvance((prev) => ({ ...prev, taskLabel: value }));
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.cartNumber')}
            name="cartNumber"
            style={{ marginBottom: 12 }}
          >
            <AutoComplete
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
              options={cartNumberData}
              allowClear
              filterOption
              onFocus={() => {
                getCartNumberList();
              }}
              onChange={(value) => {
                setAdvance((prev) => ({ ...prev, cartNumber: value }));
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('order.orderListColumns.advancedSearch.goodsName')}
            name="goodsName"
            style={{ marginBottom: 12 }}
          >
            <Input
              allowClear
              onChange={(e) => {
                setAdvance((prev) => ({
                  ...prev,
                  goodsName: e.target.value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            name="employeeId"
            label={t(
              'sortationSystem.employeeLogListColumns.advancedSearch.employeeId'
            )}
            style={{ marginBottom: 12 }}
          >
            <EmployeeDropdown
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  employeeId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskStatus')}
            name="taskStatus"
            style={{ marginBottom: 12 }}
          >
            <Select
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  taskStatus: value,
                }));
              }}
            >
              {taskEnum &&
                taskEnum.taskStatus &&
                taskEnum.taskStatus.length > 0 &&
                taskEnum.taskStatus.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskType')}
            name="taskType"
            style={{ marginBottom: 12 }}
          >
            <Select
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  taskType: value,
                }));
              }}
            >
              {taskEnum &&
                taskEnum.taskType &&
                taskEnum.taskType.length > 0 &&
                taskEnum.taskType.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskStage')}
            name="taskStage"
            style={{ marginBottom: 12 }}
          >
            <Select
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  taskStage: value,
                }));
              }}
            >
              {taskEnum &&
                taskEnum.taskStage &&
                taskEnum.taskStage.length > 0 &&
                taskEnum.taskStage.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="taskDate"
            label={t('sortationSystem.taskListColumns.taskDate')}
            style={{ marginBottom: 12 }}
          >
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              ranges={{
                [t('goods.add/editGood.today')]: [moment(), moment()],
              }}
              format={DATE_FORMAT}
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      taskDate: [
                        moment(values[0]).format(DATE_FORMAT),
                        moment(values[1]).format(DATE_FORMAT),
                      ],
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      taskDate: undefined,
                    }));
              }}
              placeholder={[
                t('order.orderListColumns.advancedSearch.startDate'),
                t('order.orderListColumns.advancedSearch.endDate'),
              ]}
            />
          </Form.Item>
          <Form.Item
            name="addTime"
            label={t('sortationSystem.taskListColumns.addTime')}
            style={{ marginBottom: 12 }}
          >
            <DatePicker.RangePicker
              placement="topRight"
              style={{ width: '100%' }}
              ranges={{
                [t('goods.add/editGood.today')]: [
                  moment().set({ hour: 0, minute: 0, second: 0 }),
                  moment().set({ hour: 23, minute: 59, second: 59 }),
                ],
              }}
              showTime={{ format: 'HH:mm:ss' }}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              format={DATE_TIME_FORMAT}
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      addTime: [
                        moment(values[0]).format(DATE_TIME_FORMAT),
                        moment(values[1]).format(DATE_TIME_FORMAT),
                      ],
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      addTime: undefined,
                    }));
              }}
              placeholder={[
                t('order.orderListColumns.advancedSearch.startDate'),
                t('order.orderListColumns.advancedSearch.endDate'),
              ]}
            />
          </Form.Item>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                name="routeDate"
                label={t('order.orderListColumns.advancedSearch.route')}
                style={{ marginBottom: 12 }}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  placeholder={t('searchPlaceholders.selectShippingDate')}
                  onChange={(date, dateString) => {
                    setAdvance((prev) => ({
                      ...prev,
                      routeDate: date ? dateString : undefined,
                    }));
                    if (dateString === '') {
                      setAdvance((prev) => ({
                        routeId: undefined,
                        routeDate: undefined,
                      }));
                      form.resetFields(['routeId']);
                      setRouteData([]);
                    }
                    dateString &&
                      hasPermission(actionPermissions.orderGroup.orderView) &&
                      getRouteData(dateString);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {routeData && routeData.length > 0 && (
                <Form.Item
                  name="routeId"
                  label=" "
                  style={{ marginBottom: 12 }}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('searchPlaceholders.searchRouteId')}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onSelect={(value: number, option: any) => {
                      setAdvance((prev) => ({
                        ...prev,
                        routeId: value,
                        routeName: option.key.split('@')[0],
                      }));
                    }}
                  >
                    {routeData.map((route, i) => (
                      <Select.Option
                        key={`${route.routeName}@${i}`}
                        value={route.id}
                      >
                        {route.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getTasks(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t(
                  'sortationSystem.employeeLogListColumns.advancedSearch.search'
                )}
              </Button>
              <Button
                disabled={Object.values(advance).every(
                  (item) => !item || !Array.isArray(item)
                )}
                onClick={() => {
                  form.resetFields();
                  if (orderId) {
                    setAdvance({});
                  } else {
                    form.setFieldsValue({ taskDate: [moment(), moment()] });
                    setAdvance(() => ({
                      taskDate: [
                        moment().format(DATE_FORMAT),
                        moment().format(DATE_FORMAT),
                      ],
                    }));
                  }
                  if (page !== 1) setPage(1);
                  else getTasks();
                }}
              >
                {t(
                  'sortationSystem.employeeLogListColumns.advancedSearch.reset'
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );

  /**
   * @param pagination Current pagination state
   * @param filters    Current filter state
   * @param sorter     Current sorter state
   * @param extra      Current data source and action
   */
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TaskData> | SorterResult<TaskData>[],
    extra: {
      currentDataSource: TaskData[];
      action: 'paginate' | 'sort' | 'filter';
    }
  ) => {
    if (extra.action === 'sort' && !Array.isArray(sorter)) {
      setSortOrder(
        sorter.order === 'ascend'
          ? 'ascend'
          : sorter.order === 'descend'
          ? 'descend'
          : undefined
      );
      setSortValue(
        sorter.columnKey === 'taskTime|startTime|finishTime|addTime'
          ? 'TASK_TIME'
          : sorter.columnKey === 'taskId'
          ? 'TASK_ID'
          : sorter.columnKey ===
            'orderSn|consignee|sequenceLabel|orderShippingLabel'
          ? 'ORDER_ID'
          : ''
      );
    }
  };

  const columns: ColumnsType<TaskData> = [
    {
      title: setFont(t('sortationSystem.taskListColumns.taskId'), fontSize),
      key: 'taskId',
      dataIndex: 'taskId',
      width: 80,
      fixed: screens.lg ? 'left' : undefined,
      sorter: true,
      render: (value, record: TaskData) => (
        <Popover
          mouseEnterDelay={0.5}
          overlayInnerStyle={{
            width: screens.lg ? 800 : screens.md ? 'auto' : undefined,
          }}
          // getPopupContainer={(triggerNode) =>
          //   triggerNode.parentNode as HTMLElement
          // }
          placement="right"
          onVisibleChange={(visible: boolean) => {
            if (
              visible &&
              hasPermission(actionPermissions.sortationGroup.taskView)
            ) {
              getTaskDetailData(record.taskId);
            }
          }}
          content={
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <TaskOrderGoodsTable
                  title={t('sortationSystem.taskDetail.unusualTaskGoodsList')}
                  taskInfo={taskInfo}
                  taskGoodsList={taskInfo?.unusualTaskGoods}
                  isLoading={isTaskGoodsLoading}
                  isEditable={false}
                  enums={taskEnum}
                />
              </Col>
              <Col span={24}>
                <TaskOrderGoodsTable
                  title={t('sortationSystem.taskDetail.taskGoodsList')}
                  taskInfo={taskInfo}
                  taskGoodsList={
                    taskInfo ? makeTaskGoodsData(taskInfo?.taskGoodsMap) : []
                  }
                  isLoading={isTaskGoodsLoading}
                  isEditable={false}
                  enums={taskEnum}
                />
              </Col>
            </Row>
          }
        >
          <Button
            type="link"
            size="small"
            disabled={!hasPermission(actionPermissions.sortationGroup.taskView)}
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              addTab(
                '',
                `${dashboardRoute.sortationSystem.taskDetail}?task_id=${value}`
              );
            }}
          >
            {value}
          </Button>
        </Popover>
      ),
    },
    {
      title: setFont(
        t('sortationSystem.taskListColumns.orderInfo'),
        fontSize,
        `${t('order.orderListColumns.orderSn')}|${t(
          'order.orderListColumns.orderAddress.consignee'
        )}|${t('order.orderListColumns.sequenceLabel')}|${t(
          'sortationSystem.taskListColumns.orderShippingLabel'
        )}`
      ),
      key: 'orderSn|consignee|sequenceLabel|orderShippingLabel',
      width: 240,
      sorter: true,
      render: (record: TaskData) =>
        record &&
        record.order && (
          <Space direction="vertical" size={0}>
            <Space>
              {setFont(`${t('order.orderListColumns.orderSn')}:`, fontSize)}
              <Button
                type="link"
                size="small"
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderView)
                }
                style={{ padding: 0, fontSize: fontSize }}
                onClick={() => {
                  addTab(
                    '',
                    `${dashboardRoute.order.detail}?order_id=${record.order.orderId}`
                  );
                }}
              >
                {record.order.orderSn}
              </Button>
            </Space>
            <Space>
              {setFont(
                `${t('order.orderListColumns.orderAddress.consignee')}:`,
                fontSize
              )}
              {setFont(record.order.consignee, fontSize)}
            </Space>
            {(record.order.sequenceLabel ||
              record.order.orderShippingLabel) && (
              <Space>
                {setFont(
                  `${t('order.orderListColumns.sequenceLabel')}:`,
                  fontSize
                )}
                {record.order.sequenceLabel && (
                  <Tag color="#800020" style={{ fontSize: fontSize }}>
                    {record.order.sequenceLabel}
                  </Tag>
                )}
                {record.order.orderShippingLabel && (
                  <Tag style={{ fontSize: fontSize }}>
                    {record.order.orderShippingLabel}
                  </Tag>
                )}
              </Space>
            )}
          </Space>
        ),
    },
    {
      title: setFont(
        t('sortationSystem.taskListColumns.taskInfo'),
        fontSize,
        `${t('sortationSystem.taskListColumns.taskStage')}|${t(
          'sortationSystem.taskListColumns.taskType'
        )}`
      ),
      key: 'taskStage|taskType',
      width: 200,
      render: (record: TaskData) => (
        <Space direction="vertical" size={0}>
          <Space>
            {setFont(
              `${t('sortationSystem.taskListColumns.taskStage')}:`,
              fontSize
            )}
            {record.taskStage && (
              <Tag
                color={record.taskStage.tagColor}
                style={{ fontSize: fontSize, color: WHITE }}
              >
                {record.taskStage.description}
              </Tag>
            )}
          </Space>
          <Space>
            {setFont(
              `${t('sortationSystem.taskListColumns.taskType')}:`,
              fontSize
            )}
            {record.taskType && setFont(record.taskType.description, fontSize)}
          </Space>
        </Space>
      ),
    },
    {
      title: setFont(
        t('sortationSystem.taskListColumns.taskLabel'),
        fontSize,
        `${t('sortationSystem.taskListColumns.taskLabel')}|${t(
          'sortationSystem.taskListColumns.cartNumber'
        )}`
      ),
      key: 'taskLabel|cartNumber',
      width: 140,
      render: (record: TaskData) => (
        <Space direction="vertical" size={0}>
          {record && record.taskLabel && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.taskLabel')}:`,
                fontSize
              )}
              {record && setFont(record.taskLabel, fontSize)}
            </Space>
          )}
          {record && record.cartNumber && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.cartNumber')}:`,
                fontSize
              )}
              {setFont(record.cartNumber, fontSize)}
            </Space>
          )}
        </Space>
      ),
    },
    {
      title: setFont(
        t('sortationSystem.taskDetail.employeeInfo'),
        fontSize,
        `${t('sortationSystem.employeeListColumns.employeeId')}|${t(
          'sortationSystem.employeeListColumns.nickname'
        )}|${t('sortationSystem.employeeListColumns.mobile')}`
      ),
      key: 'employeeId|nickname|mobile',
      width: 160,
      render: (record: TaskData) =>
        record &&
        record.employee && (
          <Space direction="vertical" size={0}>
            {record.employee.employeeId && (
              <Space>
                {setFont(
                  `${t('sortationSystem.employeeListColumns.employeeId')}:`,
                  fontSize
                )}
                {setFont(record.employee.employeeId.toString(), fontSize)}
              </Space>
            )}
            {record.employee.nickname && (
              <Space>
                {setFont(
                  `${t('sortationSystem.employeeListColumns.nickname')}:`,
                  fontSize
                )}
                {setFont(record.employee.nickname, fontSize)}
              </Space>
            )}
            {record.employee.mobile && (
              <Space>
                {setFont(
                  `${t('sortationSystem.employeeListColumns.mobile')}:`,
                  fontSize
                )}
                {setFont(record.employee.mobile, fontSize)}
              </Space>
            )}
          </Space>
        ),
    },
    {
      title: setFont(
        t('sortationSystem.taskListColumns.taskGoodsNum'),
        fontSize
      ),
      key: 'taskGoodsNum',
      dataIndex: 'taskGoodsNum',
      width: 100,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('sortationSystem.taskListColumns.taskStatus'), fontSize),
      key: 'taskStatus',
      dataIndex: 'taskStatus',
      width: 100,
      render: (value) =>
        value && (
          <Tag
            color={value.tagColor}
            style={{ fontSize: fontSize, color: WHITE }}
          >
            {value.description}
          </Tag>
        ),
    },
    {
      title: setFont(
        t('sortationSystem.taskListColumns.taskTime'),
        fontSize,
        `${t('sortationSystem.taskListColumns.taskDate')}|${t(
          'sortationSystem.taskListColumns.startTime'
        )}|${t('sortationSystem.taskListColumns.finishTime')}|${t(
          'sortationSystem.taskListColumns.addTime'
        )}`
      ),
      key: 'taskTime|startTime|finishTime|addTime',
      sorter: true,
      width: 220,
      render: (record: TaskData) => (
        <Space direction="vertical" size={0}>
          {record && record.taskTime && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.taskDate')}:`,
                fontSize
              )}
              {record && setFont(record.taskTime, fontSize)}
            </Space>
          )}
          {record && record.startTime && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.startTime')}:`,
                fontSize
              )}
              {setFont(record.startTime, fontSize)}
            </Space>
          )}
          {record && record.finishTime && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.finishTime')}:`,
                fontSize
              )}
              {record && setFont(record.finishTime, fontSize)}
            </Space>
          )}
          {record && record.addTime && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.addTime')}:`,
                fontSize
              )}
              {record && setFont(record.addTime, fontSize)}
            </Space>
          )}
        </Space>
      ),
    },
    {
      title: setFont(
        t('sortationSystem.taskListColumns.reviewInfo'),
        fontSize,
        `${t('sortationSystem.taskListColumns.reviewAdminId')}|${t(
          'sortationSystem.taskListColumns.reviewStatus'
        )}|${t('sortationSystem.taskListColumns.reviewContent')}|${t(
          'sortationSystem.taskListColumns.reviewTime'
        )}`
      ),
      key: 'reviewAdminId|reviewStatus|reviewContent|reviewTime',
      width: 220,
      render: (record: TaskData) => (
        <Space direction="vertical" size={0}>
          {record && !!record.reviewAdminId && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.reviewAdminId')}:`,
                fontSize
              )}
              {setFont(record.reviewAdminId.toString(), fontSize)}
            </Space>
          )}
          {record && record.reviewStatus && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.reviewStatus')}:`,
                fontSize
              )}
              <Tag
                color={record.reviewStatus.tagColor}
                style={{ fontSize: fontSize }}
              >
                {record.reviewStatus.description}
              </Tag>
            </Space>
          )}
          {record && record.reviewContent && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.reviewContent')}:`,
                fontSize
              )}
              {setFont(record.reviewContent, fontSize)}
            </Space>
          )}
          {record && record.reviewTime && (
            <Space>
              {setFont(
                `${t('sortationSystem.taskListColumns.reviewTime')}:`,
                fontSize
              )}
              {setFont(record.reviewTime, fontSize)}
            </Space>
          )}
        </Space>
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 80,
      render: (record: TaskData) => (
        <Button
          type="link"
          size="small"
          disabled={!hasPermission(actionPermissions.sortationGroup.taskView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            addTab(
              '',
              `${dashboardRoute.sortationSystem.taskDetail}?task_id=${record.taskId}`
            );
          }}
        >
          {t('actionsColumn.view')}
        </Button>
      ),
    },
  ];

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {taskListDetail ? (
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('sortationSystem.taskList')}
        </Typography.Title>
      ) : (
        <Typography.Title level={3} style={{ fontWeight: 500 }}>
          {t('sortationSystem.taskList')}
        </Typography.Title>
      )}

      {!taskListDetail && (
        <>
          <Tabs
            onTabClick={(key) => {
              if (key !== taskNav) {
                setSelectedRowKeys([]);
                setSelectedRows([]);
                setTaskNav(key);
                setPage(1);
              }
            }}
          >
            {taskEnum &&
              taskEnum.taskNav &&
              taskEnum.taskNav.length > 0 &&
              taskEnum.taskNav.map((type) => (
                <Tabs.TabPane key={type.code} tab={type.description} />
              ))}
          </Tabs>
          <TableToolbar
            leftElement={
              <>
                {taskNav === 'PENDING' && (
                  <Button
                    icon={<ControlOutlined />}
                    onClick={() => {
                      setIsSortModalVisible(true);
                    }}
                    disabled={
                      isLoading ||
                      !hasPermission(
                        actionPermissions.sortationGroup.taskManage
                      )
                    }
                  >
                    {t('sortationSystem.add/EditTask.editTaskSortOrder')}
                  </Button>
                )}
                {/* <Button
                  onClick={() => {
                    setIsPrintModalVisible(true);
                  }}
                >{`打印推车号`}</Button> */}
              </>
            }
            setFontSize={setFontSize}
            fontSize={fontSize}
            totalItems={totalItems}
            refresh={() => getTasks()}
            tableSize={tableSize}
            setTableSize={setTableSize}
            recordRemark={` (${orderCount} ${t(
              'sortationSystem.taskListColumns.orderCount'
            )})`}
            rows={tasks.map((task) => ({
              ...task,
              orderId: task.order ? task.order.orderId : '',
              orderSn: task.order ? task.order.orderSn : '',
              consignee: task.order ? task.order.consignee : '',
              sequenceLabel: task.order ? task.order.sequenceLabel : '',
              orderShippingLabel: task.order
                ? task.order.orderShippingLabel
                : '',
              taskStatus: task.taskStatus ? task.taskStatus.description : '',
              taskStage: task.taskStage ? task.taskStage.description : '',
              taskType: task.taskType ? task.taskType.description : '',
              reviewStatus: task.reviewStatus
                ? task.reviewStatus.description
                : '',
              employeeId: task.employee ? task.employee.employeeId : '',
              nickname: task.employee ? task.employee.nickname : '',
              mobile: task.employee ? task.employee.mobile : '',
            }))}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            searchPlaceholder={t('searchPlaceholders.searchEmployee')}
            advancedSearch={advancedSearch}
            exportConfig={{ fileName: 'TASK_LIST' }}
          />
        </>
      )}

      <Table<TaskData>
        dataSource={taskListDetail ? taskListDetail.taskList : tasks}
        columns={
          taskListDetail
            ? [
                ...columns.slice(0, 1),
                ...columns.slice(2, 5),
                ...columns.slice(6),
              ].filter((x) => selectedColumns.includes(x.key?.toString() ?? ''))
            : columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )
        }
        size={tableSize}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowKey={(item) => item.taskId}
        rowSelection={
          !taskListDetail
            ? {
                selectedRowKeys,
                onChange: (rowKeys: React.Key[], rows: TaskData[]) => {
                  setSelectedRowKeys(rowKeys);
                  setSelectedRows(rows);
                },
              }
            : undefined
        }
        loading={isLoading}
        pagination={
          taskListDetail
            ? {
                pageSize: 10,
                size: 'small',
              }
            : {
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
                total: totalItems,
              }
        }
        onChange={onTableChange}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          funct={{
            refresh: () => getTasks(),
            exportConfig: { fileName: 'TASK_LIST' },
          }}
          typeSpecificActions="task"
          selectedRows={selectedRows.map((task) => ({
            ...task,
            orderId: task.order ? task.order.orderId : '',
            orderSn: task.order ? task.order.orderSn : '',
            consignee: task.order ? task.order.consignee : '',
            sequenceLabel: task.order ? task.order.sequenceLabel : '',
            orderShippingLabel: task.order ? task.order.orderShippingLabel : '',
            taskStatus: task.taskStatus ? task.taskStatus.description : '',
            taskStage: task.taskStage ? task.taskStage.description : '',
            taskType: task.taskType ? task.taskType.description : '',
            reviewStatus: task.reviewStatus
              ? task.reviewStatus.description
              : '',
            employeeId: task.employee ? task.employee.employeeId : '',
            nickname: task.employee ? task.employee.nickname : '',
            mobile: task.employee ? task.employee.mobile : '',
          }))}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          general={
            hasPermission(actionPermissions.sortationGroup.taskView)
              ? { advance: advance, setAdvance: setAdvance }
              : undefined
          }
        />
      )}

      <TaskSortOrderModal
        taskEnum={taskEnum}
        visible={isSortModalVisible}
        setVisible={setIsSortModalVisible}
        refresh={() => {
          getTasks();
        }}
      />

      <PrintModal<PrintType>
        modalLoading={isLoading}
        mode="printLabel"
        data={{ printLabel: true }}
        visible={isPrintModalVisible}
        setVisible={setIsPrintModalVisible}
      />
    </>
  );
};

export default TaskListTable;
