import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DollarCircleOutlined,
  FileTextOutlined,
  ReloadOutlined,
  ShoppingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row, Spin, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BLUE1, GREEN1, ORANGE1, PURPLE1 } from '../../constants/color';
import {
  MEDIUM_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { StatisticsData } from '../../types';
import { hasPermission } from '../../utils/hasPermission';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import HomePeriodReport from './HomePeriodReport';
import { actionPermissions } from '../../constants/actionPermissions';
import { addCommas, addCommasPrice } from '../../utils/helperFunction';
import getDashboardStyle from '../../utils/getDashboardStyle';

const HomeAnalysis = () => {
  //General Components
  const [statLoading, setStatLoading] = useState(false);
  const isSubscribed = useRef(true);
  const { t } = useTranslation();
  //Data Components
  const [statisticsData, setStatisticsData] = useState<StatisticsData>();
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  /**
   * Grabs Data and Statistics for the Dashboard
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setStatLoading(true);
    getDataWithAuthToken(`analysis/home`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setStatisticsData(response.data);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setStatLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setStatLoading(false);
      });
  }, [t]);

  useEffect(() => {
    !getDashboardStyle().isSalesForceAppType && getData();
  }, [getData]);

  return (
    <>
      <Spin style={{ width: '100%' }} spinning={statLoading}>
        {statisticsData && (
          <>
            {/**Dead Space between Title and data */}
            <div style={{ height: 5 }} />
            {/** Num of Users and Num of Goods*/}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {statisticsData.userStat &&
                hasPermission(actionPermissions.analysisGroup.homeUser) && (
                  <Card /**Card User: Num of User, Num of Good and Num of Order  */
                    style={{
                      width: '49.8%',
                    }}
                    bodyStyle={{
                      padding: 10,
                    }}
                  >
                    <Row>
                      <Col span={24} /**Icon, User Title*/>
                        <UserOutlined
                          style={{
                            color: BLUE1,
                            fontSize: MEDIUM_FONT_SIZE,
                            paddingRight: 5,
                          }}
                        />
                        <Typography.Text style={{ fontSize: MEDIUM_FONT_SIZE }}>
                          {t('statistics.userStat.title')}
                        </Typography.Text>

                        <Tooltip title={t('general.refresh')}>
                          <Button
                            type="text"
                            onClick={() => {
                              if (typingTimeout) clearTimeout(typingTimeout);
                              setTypingTimeout(
                                setTimeout(() => getData(), GENERAL_TIMEOUT)
                              );
                            }}
                            icon={<ReloadOutlined />}
                          />
                        </Tooltip>
                      </Col>
                      <Col span={24} md={12} lg={4} /**Today user number*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(statisticsData.userStat.todayUserNum)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.userStat.todayUserNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} md={12} lg={4} /**Yesterday user number*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.userStat.yesterdayUserNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.userStat.yesterdayUserNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} md={12} lg={4} /**Month user number*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.userStat.thisMonthUserNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.userStat.thisMonthUserNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} md={12} lg={4} /**Total user number*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(statisticsData.userStat.totalUserNum)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.userStat.totalUserNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} md={12} lg={4} /**Total deleted users*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {statisticsData.userStat.totalDeletedUserNum}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.userStat.totalDeletedUserNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              {statisticsData.goodStat &&
                hasPermission(actionPermissions.analysisGroup.homeGoods) && (
                  <Card /**Card Goods: Total, OnSale, SellerTotal, SellerUnreviewed */
                    bodyStyle={{
                      padding: 10,
                    }}
                    style={{
                      width: '49.8%',
                    }}
                  >
                    <Row>
                      <Col
                        span={24}
                        /**Icon, Good Title*/
                      >
                        <ShoppingOutlined
                          style={{
                            color: ORANGE1,
                            fontSize: 17,
                            paddingRight: 5,
                          }}
                        />
                        <Typography.Text style={{ fontSize: MEDIUM_FONT_SIZE }}>
                          {t('statistics.goodStat.title')}
                        </Typography.Text>
                      </Col>
                      <Col span={24} md={12} lg={6} /**Total Goods*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(statisticsData.goodStat.totalGoodsNum)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.goodStat.totalGoodsNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} md={12} lg={6} /**On Sale Goods*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(statisticsData.goodStat.onSaleGoodsNum)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.goodStat.onSaleGoodsNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} md={12} lg={6} /**Seller Total Goods*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.goodStat.sellerTotalGoodsNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.goodStat.sellerTotalGoodsNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col
                        span={24}
                        md={12}
                        lg={6} /**Seller Unreviewed Goods*/
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.goodStat.sellerUnreviewedGoodsNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.goodStat.sellerUnreviewedGoodsNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
            </div>
            {/**Dead Space between cards */}
            <div style={{ height: 5 }} />
            {/** Num of Order and Sales*/}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {statisticsData.orderStat &&
                hasPermission(actionPermissions.analysisGroup.homeOrder) && (
                  <Card /**Card Order: Today, yesterday, unconfirmed, unpaid, unshipped, unreviewedOrderClaim  */
                    style={{
                      width: '64.8%',
                    }}
                    bodyStyle={{
                      padding: 10,
                    }}
                  >
                    <Row>
                      <Col /**Icon, Order Title*/
                        span={24}
                        style={{
                          marginBottom: 25,
                        }}
                      >
                        <FileTextOutlined
                          style={{
                            color: GREEN1,
                            fontSize: MEDIUM_FONT_SIZE,
                            paddingRight: 5,
                          }}
                        />
                        <Typography.Text style={{ fontSize: MEDIUM_FONT_SIZE }}>
                          {t('statistics.orderStat.title')}
                        </Typography.Text>
                      </Col>
                      <Col /**Today order number*/
                        span={24}
                        sm={12}
                        md={8}
                        lg={4}
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(statisticsData.orderStat.todayOrderNum)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.orderStat.todayOrderNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col /**Yesterday order number*/
                        span={24}
                        sm={12}
                        md={8}
                        lg={4}
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.orderStat.yesterdayOrderNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.orderStat.yesterdayOrderNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col /**Unconfirmed order number*/
                        span={24}
                        sm={12}
                        md={8}
                        lg={4}
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.orderStat.unconfirmedOrderNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.orderStat.unconfirmedOrderNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col /**Unpaid order number*/
                        span={24}
                        sm={12}
                        md={8}
                        lg={4}
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(statisticsData.orderStat.unpaidOrderNum)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.orderStat.unpaidOrderNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col /**Unshipped order number*/
                        span={24}
                        sm={12}
                        md={8}
                        lg={4}
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.orderStat.unshippedOrderNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.orderStat.unshippedOrderNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col /**Unreviewed Order Claim number*/
                        span={24}
                        md={8}
                        lg={4}
                      >
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommas(
                              statisticsData.orderStat.unreviewedOrderClaimNum
                            )}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.orderStat.unreviewedOrderClaimNum')}
                          </Typography.Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              {statisticsData.saleStat &&
                hasPermission(actionPermissions.analysisGroup.homeOrder) && (
                  <Card /**Card Sales */
                    style={{
                      width: '34.8%',
                    }}
                    bodyStyle={{
                      padding: 10,
                    }}
                  >
                    <Row>
                      <Col
                        span={24} /**Icon, Sale Title, unit*/
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 25,
                        }}
                      >
                        <div /**Title and Icon */>
                          <DollarCircleOutlined
                            style={{
                              color: PURPLE1,
                              fontSize: MEDIUM_FONT_SIZE,
                              paddingRight: 5,
                            }}
                          />
                          <Typography.Text
                            style={{ fontSize: MEDIUM_FONT_SIZE }}
                          >
                            {t('statistics.saleStat.title')}
                          </Typography.Text>
                        </div>
                      </Col>
                      <Col span={24} /**Today sales*/>
                        <div
                          style={{
                            height: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography.Title
                            level={3}
                            style={{ margin: 0, textAlign: 'center' }}
                          >
                            {addCommasPrice(statisticsData.saleStat.todaySales)}
                          </Typography.Title>
                          <Typography.Text style={{ textAlign: 'center' }}>
                            {t('statistics.saleStat.todaySales')}
                          </Typography.Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
            </div>
          </>
        )}
      </Spin>
      {/**Dead Space */}
      {!getDashboardStyle().isSalesForceAppType &&
        [
          actionPermissions.analysisGroup.homeOrder,
          actionPermissions.analysisGroup.homeSales,
        ].every((perm) => hasPermission(perm)) && (
          <>
            <div style={{ height: 5 }} />
            <Card bodyStyle={{ padding: 10 }}>
              <HomePeriodReport isSubscribed={isSubscribed} />
            </Card>
          </>
        )}
    </>
  );
};

export default HomeAnalysis;
