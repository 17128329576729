import { Image } from 'antd';

type UserPhotoProps = {
  src?: string;
};

/**
 * Displays photo of user in a card
 *
 * @param src URL of the photo
 */
const UserPhoto = ({ src }: UserPhotoProps) => {
  return (
    <Image
      width="auto"
      src={src}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    />
  );
};

export default UserPhoto;
