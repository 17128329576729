import { Form, Input, Modal, Radio, Spin } from 'antd';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_DEFAULT,
  REVIEW_STATUS_FAILED,
} from '../../constants/generalConstants';
import { GoodData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';

type GoodReviewModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  goodInfo?: GoodData;
  refresh: Function;
};

const GoodReviewModal = ({
  visible,
  setVisible,
  goodInfo,
  refresh,
}: GoodReviewModalProps) => {
  const isSubscribed = useRef(true);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [reviewStatus, setReviewStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (visible && goodInfo) {
      form.resetFields();
      if (isSubscribed.current) setReviewStatus(goodInfo.reviewStatus.code);
    }
  }, [visible, goodInfo, form]);

  const onCancel = () => {
    if (isSubscribed.current) {
      setReviewStatus('');
      setVisible(false);
    }
  };

  /**
   *  Makes a request whether it is an edit or add request after clicking Ok
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken('goods/edit', {
          ...values,
          goodsId: goodInfo?.goodsId,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              if (isSubscribed.current) setVisible(false);
              setIsLoading(false);
              setVisible(false);
              if (refresh) refresh();
              alertMessage('success', t('goods.alerts.goodsEdited'));
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      visible={visible}
      onOk={onFinish}
      onCancel={onCancel}
      title={`${t('goods.add/editGood.reviewGood')}`}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={{
            reviewStatus: goodInfo?.reviewStatus.data || REVIEW_STATUS_DEFAULT,
            reviewContent: goodInfo?.reviewContent || undefined,
          }}
        >
          <Form.Item label={t('goods.goodsListColumns.goodsName')}>
            {goodInfo?.goodsName}
          </Form.Item>
          {getDashboardStyle().showReviewStatus && (
            <Form.Item
              label={t('goods.add/editGood.reviewGood')}
              name="reviewStatus"
            >
              <Radio.Group
                onChange={(e) => {
                  form.resetFields(['reviewContent']);
                  setReviewStatus(e.target.value);
                }}
              >
                {Object.keys(REVIEW_STATUS).map((type) => {
                  return (
                    <Radio key={type} value={type}>
                      {t(REVIEW_STATUS[type])}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          )}
          {getDashboardStyle().showReviewStatus &&
            reviewStatus === REVIEW_STATUS_FAILED && (
              <Form.Item
                name="reviewContent"
                rules={[
                  {
                    required: reviewStatus ? true : false,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default GoodReviewModal;
