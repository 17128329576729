import { ReloadOutlined } from '@ant-design/icons';
import { ResponsivePie } from '@nivo/pie';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { GOODS_DISTRI_TYPE } from '../../../constants/analysisConstants';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { GoodsDistributionData, NivoPieChartData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { addCommas } from '../../../utils/helperFunction';
import { nivoToolTip } from '../../../utils/nivoGraphTools';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import CategoryDropdown from '../../goods/common/CategoryDropdown';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableToolbar from '../../table/TableToolbar';

type GoodsDistributionProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const GoodsDistribution = ({
  isSeller,
  isLoading,
  setIsLoading,
}: GoodsDistributionProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const { RangePicker } = DatePicker;
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const isSubscribed = useRef(true);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [tabMode, setTabMode] = useState<string>(GOODS_DISTRI_TYPE.goodsTag);
  //Data Components
  const [tabsData, setTabsData] = useState<{
    [key: string]: {
      pieGraphs: NivoPieChartData[];
      tableData: GoodsDistributionData[];
    };
  }>({});

  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = ['name', 'itemNum', 'totalNum', 'itemPercentage'];
  const columns: ColumnsType<GoodsDistributionData> = [
    {
      title: t('analysis.goodsDistribution.name'),
      key: 'name',
      dataIndex: 'name',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('analysis.goodsDistribution.itemNum'),
      key: 'itemNum',
      dataIndex: 'itemNum',
      width: 90,
      sorter: (a: GoodsDistributionData, b: GoodsDistributionData) =>
        compare(
          Number(a.itemNum) !== undefined ? Number(a.itemNum) : null,
          Number(b.itemNum) !== undefined ? Number(b.itemNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.goodsDistribution.totalNum'),
      key: 'totalNum',
      dataIndex: 'totalNum',
      width: 90,
      sorter: (a: GoodsDistributionData, b: GoodsDistributionData) =>
        compare(
          Number(a.totalNum) !== undefined ? Number(a.totalNum) : null,
          Number(b.totalNum) !== undefined ? Number(b.totalNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.goodsDistribution.itemPercentage'),
      key: 'itemPercentage',
      dataIndex: 'itemPercentage',
      width: 90,
      render: (text: string) => addCommas(text),
    },
  ];

  /**
   * Grabs all the needed data for Goods Analysis
   */
  const getData = useCallback(() => {
    const getGraphData = (data: GoodsDistributionData[]) => {
      let itemNumPie: NivoPieChartData[] = [];
      let tableData: GoodsDistributionData[] = [];
      if (data.length > 0) {
        data.forEach((point) => {
          itemNumPie.push({
            id: point.name,
            value: parseFloat(point.itemPercentage),
            label: parseInt(point.itemNum),
          });
          tableData.push({
            name: point.name,
            itemNum: point.itemNum,
            totalNum: point.totalNum,
            itemPercentage: point.itemPercentage,
          });
        });
        setTabsData((prev) => ({
          ...prev,
          [tabMode]: {
            pieGraphs: itemNumPie,
            tableData: tableData,
          },
        }));
      }
    };
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('analysis/goods/distr', {
      params: {
        isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
        sellerId:
          getDashboardStyle().isSellerSwitch &&
          isSeller &&
          formRef.current &&
          form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        distrType: tabMode,
        catId:
          formRef.current && form.getFieldValue('catId')
            ? form.getFieldValue('catId').at(-1)
            : undefined,
        startDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
            : undefined,
        endDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            getGraphData(response.data.list);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [
    t,
    form,
    setFourZeroThree,
    setFiveHundred,
    isSeller,
    setIsLoading,
    tabMode,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form /**Range picker, OK, Reset, Refresh Buttons */
            form={form}
            ref={formRef}
          >
            <Row gutter={[16, 0]}>
              <Col span={24} md={24} lg={12} xl={8}>
                <Form.Item
                  name={'date'}
                  label={t('analysis.goodsDistribution.date')}
                  initialValue={[
                    moment().subtract(8, 'days'),
                    moment().subtract(1, 'days'),
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    placeholder={[
                      t('analysis.goodsDistribution.startDate'),
                      t('analysis.goodsDistribution.endDate'),
                    ]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              {isSeller && (
                <Col span={24} md={24} lg={12} xl={8}>
                  <Form.Item
                    name="sellerId"
                    label={t('analysis.goodsSales.seller')}
                  >
                    <SellersDropdown />
                  </Form.Item>
                </Col>
              )}
              <Col span={24} md={24} lg={12} xl={8}>
                {tabMode === 'CATEGORY' && (
                  <Form.Item
                    label={t('analysis.goodsDistribution.category')}
                    name="catId"
                  >
                    <CategoryDropdown onFocusFetch={true} />
                  </Form.Item>
                )}
              </Col>
              <Col span={24}>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          setTabsData({});
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.goodsDistribution.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      form.resetFields();
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          form.resetFields();
                          form.setFieldsValue({
                            date: [
                              moment().startOf('isoWeek'),
                              moment().endOf('isoWeek'),
                            ],
                          });
                          setTabsData({});
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.goodsDistribution.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
          <Tabs
            defaultActiveKey="CLAIM_TYPE"
            onTabClick={(key) => {
              setTabMode(key);
            }}
          >
            {Object.keys(GOODS_DISTRI_TYPE).map((label) => {
              return (
                <Tabs.TabPane
                  tab={t(`analysis.goodsDistribution.${label}`)}
                  key={
                    GOODS_DISTRI_TYPE[label as keyof typeof GOODS_DISTRI_TYPE]
                  }
                >
                  {tabsData[tabMode] &&
                    tabsData[tabMode].pieGraphs.length > 0 && (
                      <div style={{ height: 300 }}>
                        <ResponsivePie /**Pie Graphs */
                          colors={{ scheme: 'paired' }}
                          tooltip={(datum) =>
                            nivoToolTip({
                              ...datum,
                              tooltipType: 'Standard',
                            })
                          }
                          arcLinkLabelsStraightLength={10}
                          arcLinkLabelsDiagonalLength={10}
                          arcLabel={(d) => (d.value !== 0 ? `${d.value}%` : '')}
                          arcLinkLabel={(d) => (d.value !== 0 ? `${d.id}` : '')}
                          margin={{
                            top: 40,
                            left: 40,
                            right: 40,
                            bottom: 40,
                          }}
                          arcLinkLabelsSkipAngle={
                            tabsData[tabMode].pieGraphs.length < 3 ? 0 : 5
                          }
                          arcLabelsSkipAngle={
                            tabsData[tabMode].pieGraphs.length < 3 ? 0 : 20
                          }
                          data={tabsData[tabMode].pieGraphs}
                          theme={{
                            fontSize: 12,
                            axis: {
                              legend: {
                                text: { fontSize: DEFAULT_FONT_SIZE },
                              },
                            },
                          }}
                        />
                      </div>
                    )}

                  {tabsData[tabMode] &&
                    tabsData[tabMode].tableData.length >
                      0 /**Export Table */ && (
                      <div style={{ width: '100%' }}>
                        <TableToolbar
                          columns={columns}
                          columnKeys={columnKeys}
                          rows={tabsData[tabMode].tableData.map((data) => ({
                            ...data,
                          }))}
                          exportConfig={{
                            fileName: 'GOODS_DISTRIBUTION_ANALYSIS',
                          }}
                        />
                        <Table<GoodsDistributionData>
                          dataSource={tabsData[tabMode].tableData}
                          rowKey={(obj) => obj.name}
                          columns={columns}
                          components={vt}
                          scroll={{
                            y: 500,
                            x: 500,
                          }}
                          loading={isLoading}
                          pagination={false}
                          rowSelection={undefined}
                          size="small"
                        />
                      </div>
                    )}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Spin>
      )}
    </>
  );
};

export default GoodsDistribution;
