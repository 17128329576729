import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import FiveHundred from '../components/FiveHundred';
import FourZeroThree from '../components/FourZeroThree';
import { CacheData } from '../types';
import { alertMessage } from '../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../utils/axiosRequest';

const Cache = () => {
  const { t } = useTranslation();
  const [cacheList, setCacheList] = useState<CacheData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCaches, setSelectedCaches] = useState<{
    [key: string]: string[];
  }>({});
  const [checkAll, setCheckAll] = useState<{ [key: string]: boolean }>({});
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);

  const getCacheData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('cache/list')
      .then((response) => {
        if (response && response.goodStatus) {
          setCacheList(response.data.list);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t]);

  useEffect(() => {
    getCacheData();
  }, [getCacheData]);

  const onConfirm = () => {
    setIsLoading(true);
    postDataWithAuthToken('cache/clear', {
      cacheNameList: Object.values(selectedCaches).flat(1),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('cache.cacheCleared'));
          setSelectedCaches({});
          setCheckAll({});
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('cache.title')}
          </Typography.Title>
          <Spin spinning={isLoading}>
            <Row gutter={[0, 24]}>
              {cacheList.map((cache, index) => (
                <Col span={24} key={index}>
                  <Space style={{ marginTop: 12 }}>
                    <Checkbox
                      checked={checkAll[cache.code]}
                      onChange={(e) => {
                        setSelectedCaches((prev) => ({
                          ...prev,
                          [cache.code]: e.target.checked
                            ? cache && cache.child
                              ? cache.child.map((c) => c.code)
                              : []
                            : [],
                        }));

                        setCheckAll((prev) => ({
                          ...prev,
                          [cache.code]: e.target.checked,
                        }));
                      }}
                    />
                    <Typography.Text strong style={{ fontSize: 16 }}>
                      {cache.description}
                    </Typography.Text>
                  </Space>
                  <Divider style={{ marginTop: 6, marginBottom: 12 }} />
                  <Row gutter={[0, 8]}>
                    {cache.child &&
                      cache.child.map((c: CacheData) => (
                        <Col span={24} lg={6} md={8} sm={12} key={c.code}>
                          <Checkbox
                            value={c.code}
                            checked={
                              selectedCaches[cache.code]
                                ? selectedCaches[cache.code].includes(c.code)
                                : false
                            }
                            onChange={(e) => {
                              setSelectedCaches((prev) =>
                                e.target.checked
                                  ? {
                                      ...prev,
                                      [cache.code]: prev[cache.code]
                                        ? prev[cache.code].concat(c.code)
                                        : [c.code],
                                    }
                                  : {
                                      ...prev,
                                      [cache.code]: prev[cache.code]
                                        ? prev[cache.code].filter(
                                            (ca) => ca !== c.code
                                          )
                                        : [],
                                    }
                              );

                              setCheckAll((prev) => ({
                                ...prev,
                                [cache.code]:
                                  cache &&
                                  cache.child &&
                                  selectedCaches[cache.code]
                                    ? cache.child.length ===
                                      (e.target.checked
                                        ? selectedCaches[cache.code].length + 1
                                        : selectedCaches[cache.code].length - 1)
                                    : false,
                              }));
                            }}
                          >
                            {c.description}
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Col>
              ))}
              <Col span={24}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="primary"
                    onClick={onConfirm}
                    disabled={
                      !cacheList.length ||
                      Object.values(selectedCaches).every((c) => !c.length)
                        ? true
                        : false
                    }
                  >
                    {t('cache.title')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Spin>
        </Card>
      )}
    </Container>
  );
};

export default Cache;
