import { Form, Input, Modal, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TPLData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import SellersDropdown from '../../sellers/SellersDropdown';

type AddTransportModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  tplInfo?: TPLData;
  refresh?: () => void;
  setViewType: React.Dispatch<React.SetStateAction<string>>;
  setCalled: React.Dispatch<React.SetStateAction<boolean>>;
  setMainRegionIDData: React.Dispatch<React.SetStateAction<any[]>>;
  setTransId: React.Dispatch<React.SetStateAction<number>>;
  isSeller: boolean;
};
const AddTransportModal = ({
  visible,
  setVisible,
  setCalled,
  setViewType,
  setMainRegionIDData,
  setTransId,
  refresh,
  isSeller,
}: AddTransportModalProps) => {
  //General Components
  const { t } = useTranslation();
  const [isAddTransModalLoading, setIsAddTransModalLoading] = useState(false);
  const [addTransForm] = Form.useForm();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      addTransForm.resetFields();
    }
  }, [addTransForm, visible]);

  /**
   * @param values Form values
   */
  const onFinish = () => {
    addTransForm
      .validateFields()
      .then((values: any) => {
        if (isAddTransModalLoading) {
          setIsAddTransModalLoading(true);
        }
        postDataWithAuthToken('shipping/transport/add', {
          sellerId: isSeller ? values.sellerId : 0,
          transportName: values.transportName,
          transportTitle: values.transportTitle,
        }).then((response) => {
          if (response && response.goodStatus) {
            setTransId(response.data.transportId);
            setMainRegionIDData([]);
            refresh && refresh();
            setViewType('view');
            alertMessage('success', t('settings.alerts.transAdded'));
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isAddTransModalLoading) {
            setIsAddTransModalLoading(false);
          }
        });
      })
      .catch((err: any) => {
        if (isAddTransModalLoading) {
          setIsAddTransModalLoading(false);
        }
        console.log(err);
      });
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
      setMainRegionIDData([]);
      setCalled(false);
    }
  };

  return (
    <>
      <Modal
        forceRender
        maskClosable={false}
        onOk={onFinish}
        width={400}
        onCancel={onClose}
        title={`${t('settings.add/edit/viewModal.addTransTitle')}`}
        visible={visible}
      >
        <Spin spinning={isAddTransModalLoading}>
          <Form form={addTransForm} onFinish={onFinish}>
            <Form.Item
              style={{ display: isSeller ? '' : 'none' }}
              name="sellerId"
              label={t('settings.add/edit/viewModal.sellerId')}
              rules={[
                {
                  required: isSeller ? true : false,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <SellersDropdown />
            </Form.Item>
            <Form.Item
              name="transportName"
              label={t('settings.add/edit/viewModal.transportName')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <Input style={{ maxWidth: 152 }} />
            </Form.Item>
            <Form.Item
              name="transportTitle"
              label={t('settings.add/edit/viewModal.transportTitle')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <Input style={{ maxWidth: 163 }} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddTransportModal;
