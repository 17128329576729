import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
  Switch,
  TreeSelect,
} from 'antd';
import { AdminAction, SellerGradeData } from '../../types';
import { useTranslation } from 'react-i18next';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { DataNode } from 'rc-tree-select/lib/interface';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';

type GradeModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  grade?: SellerGradeData;
  refresh?: () => void;
};

const GradeModal = ({
  visible,
  setVisible,
  grade,
  refresh,
}: GradeModalProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const profile = JSON.parse(localStorage.getItem('profile') || '');
  const [adminActions, setAdminActions] = useState<DataNode[]>([]);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Fetches action list
  const getActionList = useCallback(() => {
    /**
     * Reconstruct the data to be used in TreeSelect
     *
     * @param  data        Array of all the Admin Actions
     * @return DataNode[]  A reformated Version of Admin Actions
     */
    const makeTreeData = (data: AdminAction[] = []) => {
      let treeData: DataNode[] = [];
      for (let node of data) {
        let treeNode: DataNode = {};
        treeNode.title = node.description;
        treeNode.value = node.actionCode;
        treeNode.key = node.actionCode;
        if (node.adminAction && node.adminAction.length) {
          treeNode.children = makeTreeData(node.adminAction);
          treeNode.checkable = treeNode.children.every(
            (child) => child.disableCheckbox === true
          )
            ? false
            : true;
        } else {
          treeNode.isLeaf = true;
          treeNode.disableCheckbox = profile.actionList
            ? profile.actionList === 'all' ||
              profile.actionList.indexOf(node.actionCode) !== -1
              ? false
              : true
            : true;
        }
        treeData.push(treeNode);
      }
      return treeData;
    };

    getDataWithAuthToken('admin/action/list', { params: { isSeller: true } })
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus)
            setAdminActions(makeTreeData(response.data.list));
          else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t, profile]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (!adminActions.length) getActionList();
    }
  }, [visible, form, getActionList, adminActions]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(
          grade ? 'seller/grade/edit' : 'seller/grade/add',
          {
            ...values,
            gradeId: grade ? grade.gradeId : undefined,
            actionList: values.actionList ? values.actionList.join() : '',
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  grade
                    ? t('sellers.alerts.gradeEdited')
                    : t('sellers.alerts.gradeAdded')
                );
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  return (
    <Modal
      title={
        grade
          ? `${t('sellers.add/editGrade.editTitle')} ${grade.gradeName}`
          : t('sellers.add/editGrade.addTitle')
      }
      visible={visible}
      okText={t('sellers.add/editGrade.ok')}
      cancelText={t('sellers.add/editGrade.cancel')}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            grade
              ? {
                  ...grade,
                  actionList:
                    adminActions.length && grade.actionList
                      ? grade.actionList.split(',')
                      : [],
                }
              : {}
          }
        >
          <Form.Item
            name="gradeName"
            label={t('sellers.add/editGrade.gradeName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="gradeDesc"
            label={t('sellers.add/editGrade.gradeDesc')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="maxGoodsNumber"
            label={t('sellers.add/editGrade.maxGoods')}
          >
            <InputNumber type="number" />
          </Form.Item>
          <Form.Item
            name="isEnabled"
            valuePropName="checked"
            label={t('sellers.add/editGrade.isEnabled')}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="actionList"
            label={t('sellers.add/editGrade.actions')}
          >
            <TreeSelect
              treeData={adminActions}
              treeCheckable
              placeholder={t('general.pleaseSelect')}
              allowClear
              showSearch={false}
              maxTagCount={10}
              treeDefaultExpandedKeys={
                grade && grade.actionList ? grade.actionList.split(',') : []
              }
              disabled={
                !hasPermission(actionPermissions.generalGroup.allotPriv)
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default GradeModal;
