/** Settings */
export const FIELD_TYPE_GROUP = 'GROUP';
export const FIELD_TYPE: {
  [key: string]: { code: string; description: string };
} = {
  STRING: {
    code: 'STRING',
    description: 'settings.add/editFormField.fieldType.STRING',
  },
  TEXT: {
    code: 'TEXT',
    description: 'settings.add/editFormField.fieldType.TEXT',
  },
  NUMBER: {
    code: 'NUMBER',
    description: 'settings.add/editFormField.fieldType.NUMBER',
  },
};
export const PAYMENT_METHOD_STATUS: { [key: string]: string } = {
  ENABLED: 'settings.add/editPayment.payStatus.ENABLED',
  DISABLED: 'settings.add/editPayment.payStatus.DISABLED',
  ONLY_PLATFORM: 'settings.add/editPayment.payStatus.ONLY_PLATFORM',
};
