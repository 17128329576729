import { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import PhotoGalleryModal from '../PhotoGalleryModal';
import EditorToolbar from './EditorToolbar';
import { useTranslation } from 'react-i18next';
import htmlEditButton from 'quill-html-edit-button';
import { EDITOR_FORMAT } from '../../constants/styles';

type TextEditorProps = {
  setClear: React.Dispatch<React.SetStateAction<boolean>>;
  data?: string;
  setData: React.Dispatch<React.SetStateAction<string | undefined>>;
  fullscreenMode: boolean;
  setFullscreenMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const TextEditor = ({
  setClear,
  data,
  setData,
  fullscreenMode,
  setFullscreenMode,
}: TextEditorProps) => {
  //General Component
  const { t } = useTranslation();
  const quillRef = useRef<any>(null);
  const isSubscribed = useRef(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [contentModalVisible, setContentModalVisible] = useState(false);
  const [content, setContent] = useState<
    {
      originalPicPath: string;
      mediaType: string;
      originalMediaPath: string;
    }[]
  >([]);
  //Text Editor Component
  Quill.debug(false);
  Quill.debug('error');
  const Font = Quill.import('formats/font');
  const DirectionAttribute = Quill.import('attributors/attribute/direction');
  Quill.register(DirectionAttribute, true, false);

  const AlignClass = Quill.import('attributors/class/align');
  Quill.register(AlignClass, true, false);

  const BackgroundClass = Quill.import('attributors/class/background');
  Quill.register(BackgroundClass, true, false);

  const ColorClass = Quill.import('attributors/class/color');
  Quill.register(ColorClass, true, false);

  const DirectionClass = Quill.import('attributors/class/direction');
  Quill.register(DirectionClass, true, false);

  const FontClass = Quill.import('attributors/class/font');
  Quill.register(FontClass, true, false);

  const SizeClass = Quill.import('attributors/class/size');
  Quill.register(SizeClass, true, false);

  const AlignStyle = Quill.import('attributors/style/align');
  Quill.register(AlignStyle, true, false);

  const BackgroundStyle = Quill.import('attributors/style/background');
  Quill.register(BackgroundStyle, true, false);

  const ColorStyle = Quill.import('attributors/style/color');
  Quill.register(ColorStyle, true, false);

  const DirectionStyle = Quill.import('attributors/style/direction');
  Quill.register(DirectionStyle, true, false);

  const FontStyle = Quill.import('attributors/style/font');
  Quill.register(FontStyle, true, false);

  Font.whitelist = [
    'arial',
    'comic-sans',
    'courier-new',
    'georgia',
    'helvetica',
    'lucida',
  ];
  Quill.register('modules/htmlEditButton', htmlEditButton, false);
  Quill.register(Font, true, false);
  const modules = {
    toolbar: {
      container: '#toolBar',
    },
    htmlEditButton: {
      buttonTitle: t('editor.HTMLToolTip'),
    },
  };

  // Sets isSubscribed, quillRef to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
      quillRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (quillRef.current && content && content.length) {
      content.forEach((media) => {
        const editor = quillRef.current.getEditor();
        let range = editor.getSelection(true);
        if (media.mediaType === MEDIA_TYPE.IMAGE && media.originalPicPath) {
          editor.insertEmbed(range, 'image', media.originalPicPath);
        } else if (
          media.mediaType === MEDIA_TYPE.VIDEO &&
          media.originalMediaPath
        ) {
          editor.insertEmbed(range, 'video', media.originalMediaPath);
        }
        editor.setSelection(range.index + 1);
      });
      setContent([]);
    }
  }, [content]);

  return (
    <>
      <div
        style={{
          width: fullscreenMode ? '100%' : undefined,
        }}
        id="toolBar"
      >
        <span /** Add Pic/Video*/>
          <EditorToolbar
            data={data}
            setData={setData}
            setClear={setClear}
            fullscreenMode={fullscreenMode}
            setFullscreenMode={setFullscreenMode}
            setFirstLoad={setFirstLoad}
            setContentModalVisible={setContentModalVisible}
          />
        </span>
      </div>
      <ReactQuill
        style={{
          height: fullscreenMode ? 750 : 250,
          width: fullscreenMode ? '100%' : undefined,
        }}
        defaultValue={data || undefined}
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={EDITOR_FORMAT}
        onChange={(content: string) => {
          if (content !== '') {
            setData(content);
          } else {
            setData(undefined);
          }
        }}
      />

      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={contentModalVisible}
        setVisible={setContentModalVisible}
        setValue={setContent}
        value={content}
        isMultiple
        supportedMediaType={MEDIA_TYPE.BOTH}
      />
    </>
  );
};
export default TextEditor;
