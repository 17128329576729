import { Card, Col, Row, Skeleton } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroFour from '../../components/FourZeroFour';
import FourZeroThree from '../../components/FourZeroThree';
import TaskEmployeeInfo from '../../components/sortationSystem/taskDetail/TaskEmployeeInfo';
import TaskOrderInfo from '../../components/sortationSystem/taskDetail/TaskOrderInfo';
import TaskBasicInfo from '../../components/sortationSystem/taskDetail/TaskBasicInfo';
import TaskLogTable from '../../components/sortationSystem/taskDetail/TaskLogTable';
import { dashboardRoute } from '../../constants/pathname';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { OrderClaimData, TaskData, TaskEnum, TaskGoodData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import TaskOrderGoodsTable from '../../components/sortationSystem/taskDetail/TaskOrderGoodsTable';
import TaskReview from '../../components/sortationSystem/taskDetail/TaskReview';
import TaskOtherInfo from '../../components/sortationSystem/taskDetail/TaskOtherInfo';
import TaskListTable from '../../components/sortationSystem/TaskListTable';
import OrderClaimTable from '../../components/users/userDetail/OrderClaimTable';

const TaskDetail = () => {
  // General components
  const queryString = window.location.search;
  const [taskId] = useState(new URLSearchParams(queryString).get('task_id'));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fourZeroFour, setFourZeroFour] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOrderClaimLoading, setIsOrderClaimLoading] =
    useState<boolean>(false);
  const [taskInfo, setTaskInfo] = useState<TaskData>();
  const [enums, setEnums] = useState<TaskEnum>();
  const [isCurrentEditing, setIsCurrentEditing] = useState<boolean>(false);

  const [taskGoods, setTaskGoods] = useState<TaskGoodData[]>([]);
  const [orderClaims, setOrderClaims] = useState<OrderClaimData[]>([]);

  const getTaskEnum = useCallback(() => {
    getDataWithAuthToken('task/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setEnums(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [t]);

  const getOrderClaimList = useCallback(
    async (orderId: number) => {
      setIsOrderClaimLoading(true);
      try {
        const response = await getDataWithAuthToken('claim/list', {
          params: { orderId: orderId, size: 100 },
        });
        setIsOrderClaimLoading(false);
        if (response && response.goodStatus) {
          setOrderClaims(response.data.list);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      } catch (err) {
        setIsOrderClaimLoading(false);
        alertMessage('error', t('general.noResponse'));
      }
    },
    [t]
  );

  const getTaskDetail = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('task/detail', { params: { taskId: taskId } })
      .then((response) => {
        if (response && response.goodStatus) {
          if (response.data) {
            setTaskInfo(response.data);
            document.title = `${t('sortationSystem.taskDetail.title')} ${
              response.data.order ? response.data.order.orderSn : taskId
            } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
            dispatch(
              addPage({
                title: `${t('sortationSystem.taskDetail.title')} ${
                  response.data.order ? response.data.order.orderSn : taskId
                }`,
                path: `${dashboardRoute.sortationSystem.taskDetail}?task_id=${taskId}`,
              })
            );
          } else {
            setFourZeroFour(true);
          }
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [t, dispatch, taskId]);

  const makeTaskGoodsDataCallback = useCallback(
    (taskGoodsMap: { [key: string]: { [key: string]: TaskGoodData[] } }) => {
      return makeTaskGoodsData(taskGoodsMap);
    },
    []
  );

  useEffect(() => {
    if (taskInfo) {
      taskInfo.taskGoodsMap &&
        setTaskGoods(makeTaskGoodsDataCallback(taskInfo.taskGoodsMap));
      taskInfo.order && getOrderClaimList(taskInfo.order.orderId);
    }
  }, [taskInfo, makeTaskGoodsDataCallback, getOrderClaimList]);

  useEffect(() => {
    getTaskEnum();
  }, [getTaskEnum]);

  useEffect(() => {
    getTaskDetail();
  }, [getTaskDetail]);

  return (
    <Container style={{ marginTop: 5 }}>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fourZeroFour ? (
        <Card>
          <FourZeroFour />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Row gutter={[5, 5]}>
          {/** Basic Info */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskBasicInfo
                  taskInfo={taskInfo}
                  enums={enums}
                  callback={getTaskDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>

          {/** Other Info */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskOtherInfo
                  taskInfo={taskInfo}
                  callback={getTaskDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          {/** Order Info */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskOrderInfo taskInfo={taskInfo} />
              </Skeleton>
            </Card>
          </Col>
          {/** Employee Info */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskEmployeeInfo taskInfo={taskInfo} />
              </Skeleton>
            </Card>
          </Col>
          {/** Order Claim List */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading && isOrderClaimLoading}>
                <OrderClaimTable
                  simplified={{
                    simplifiedForm: true,
                    orderClaimList: orderClaims,
                    loading: isOrderClaimLoading,
                    callBack: getOrderClaimList,
                  }}
                />
              </Skeleton>
            </Card>
          </Col>
          {/** Review Info */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskReview
                  taskInfo={taskInfo}
                  callback={getTaskDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                  enums={enums}
                />
              </Skeleton>
            </Card>
          </Col>
          {/** Unusual Order Goods Table */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskOrderGoodsTable
                  title={t('sortationSystem.taskDetail.unusualTaskGoodsList')}
                  taskInfo={taskInfo}
                  taskGoodsList={taskInfo?.unusualTaskGoods}
                  callback={getTaskDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                  enums={enums}
                />
              </Skeleton>
            </Card>
          </Col>
          {/** Order Goods Table */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskOrderGoodsTable
                  title={t('sortationSystem.taskDetail.taskGoodsList')}
                  taskInfo={taskInfo}
                  taskGoodsList={taskGoods}
                  callback={getTaskDetail}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                  enums={enums}
                />
              </Skeleton>
            </Card>
          </Col>
          {/** Other Task List */}
          <Col span={24}>
            <Card bordered={false} style={{ width: '100%', height: '100%' }}>
              <Skeleton active loading={isLoading}>
                {taskInfo && <TaskListTable taskListDetail={taskInfo} />}
              </Skeleton>
            </Card>
          </Col>
          {/** Task Log */}
          <Col span={24}>
            <Card>
              <Skeleton active loading={isLoading}>
                <TaskLogTable taskInfo={taskInfo} callback={getTaskDetail} />
              </Skeleton>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default TaskDetail;

export const makeTaskGoodsData = (taskGoodsMap: {
  [key: string]: { [key: string]: TaskGoodData[] };
}) => {
  let newData: TaskGoodData[] = [];
  for (let mainCat in taskGoodsMap) {
    for (let subCat in taskGoodsMap[mainCat]) {
      newData = [...newData, ...taskGoodsMap[mainCat][subCat]];
    }
  }
  return newData;
};
