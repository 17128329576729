/**Order NavType */
export const ORDER_NAVTYPE: { [key: string]: string } = {
  all: 'ALL',
  fastDelivery: 'FAST_DELIVERY',
  unconfirmed: 'UNCONFIRMED',
  unpaid: 'UNPAID',
  unshipped: 'UNSHIPPED',
  completed: 'COMPLETED',
  invalidCancelled: 'INVALID_CANCELED',
};

/** Form Field Code */
export const FIELD_CODE: { [key: string]: string } = {
  ORDER: 'ORDER',
  GOODS: 'GOODS',
};

/** Shipping Code */
export const SHIPPING_CODE: { [key: string]: string } = {
  flat: 'flat',
  cac: 'cac',
};

/**Order Status */
export const CANCELED_ORDER = 'CANCELED';
export const INVALID_ORDER = 'INVALID';
/**Order Status */
export const RESULT_CODE_FAILED = 'FAILED';
export const RESULT_CODE_CREATE_FAILED = 'CREATE_FAILED';

/** Order Detail Button */
export const ORDER_BUTTON: { [key: string]: string } = {
  payEnabled: 'PAY',
  cancelPayEnabled: 'CANCEL_PAY',
  prepareEnabled: 'PREPARE',
  cancelPrepareEnabled: 'CANCEL_PREPARE',
  confirmEnabled: 'CONFIRM',
  cancelEnabled: 'CANCEL',
  invalidateEnabled: 'INVALIDATE',
  shipEnabled: 'SHIP',
  cancelShipEnabled: 'CANCEL_SHIP',
  receiveEnabled: 'RECEIVE',
  cancelReceiveEnabled: 'CANCEL_RECEIVE',
  refundEnabled: 'REFUND',
  returnEnabled: 'RETURN',
  outOfStockEnabled: 'OUT_OF_STOCK',
  partialShipEnabled: 'PARTIAL_SHIP',
  differenceEnabled: 'DIFFERENCE',
  creditMemoEnabled: 'CREDIT_MEMO',
};

export const ORDER_BUTTON_PERMISSIONS: { [key: string]: string } = {
  payEnabled: 'order_pay',
  cancelPayEnabled: 'order_cancel_pay',
  prepareEnabled: 'order_prepare',
  cancelPrepareEnabled: 'order_cancel_prepare',
  confirmEnabled: 'order_confirm',
  cancelEnabled: 'order_cancel',
  invalidateEnabled: 'order_invalidate',
  shipEnabled: 'order_ship',
  cancelShipEnabled: 'order_cancel_ship',
  receiveEnabled: 'order_receive',
  cancelReceiveEnabled: 'order_cancel_receive',
  refundEnabled: 'order_refund',
  returnEnabled: 'order_return',
  outOfStockEnabled: 'order_out_of_stock',
  unpaidNotifyEnabled: 'sms_send',
  partialShipEnabled: 'order_partial_ship',
  addTaskEnabled: 'task_manage',
  batchResetTaskEnabled: 'task_manage',
  batchInvalidateTaskEnabled: 'task_manage',
  batchUpdateSpecialTgEnabled: 'task_manage',
};

export const BATCH_ORDER_BUTTON_PERMISSIONS: { [key: string]: string } = {
  PREPARE: 'order_batch_prepare',
  CANCEL_PREPARE: 'order_batch_cancel_prepare',
  EXPECT_SHIPPING_TIME: 'order_batch_expect_shipping_time',
  SHIP: 'order_batch_ship',
  RECEIVE: 'order_receive',
  CANCEL_RECEIVE: 'order_cancel_receive',
  IMPORT: 'order_export_to_ds',
  CONFIRM: 'order_batch_confirm',
  PAY: 'order_batch_pay',
};

/** Refund Method */
export const REFUND_METHOD: { [key: string]: string } = {
  SURPLUS: 'SURPLUS',
  ORIGINAL: 'ORIGINAL',
  OFFLINE: 'OFFLINE',
  COUPON: 'COUPON',
  VALUE_CARD: 'VALUE_CARD',
};

/** Claim Type */
export const CLAIM_TYPE_OUT_OF_STOCK = 'OUT_OF_STOCK';
export const CLAIM_TYPE_RETURN = 'RETURN';

/** Claim Code */
export const CLAIM_CODE_SUCCESS = 'SUCCESS';
export const CLAIM_CODE_FINISH = 'FINISHED';

/** Coupon Type */
export const COUPON_TYPE_MANUAL = 'COUPON_MANUAL';
