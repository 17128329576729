import { useTranslation } from 'react-i18next';
import { AxisProps } from '@nivo/axes';
import { Form } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../constants/systemConstants';
import { TooltipWrapper } from '@nivo/tooltip';
import { WHITE } from '../constants/color';
import { addCommas } from './helperFunction';
type TProps = {
  value: string[];
  addParan?: boolean;
};
const T = ({ value, addParan }: TProps) => {
  const { t } = useTranslation();
  let resultStr = '';
  for (let i = 0; i < value.length; i++) {
    resultStr += addParan && i === 1 ? `(${t(value[1])})` : t(value[i]) + ' ';
  }
  // console.log(t(resultStr));
  return <>{resultStr}</>;
};

const everyXDays = (num: number, iterator: number) => {
  return num % iterator === 0 ? true : false;
};

export const getLineGraphBottomAxisLabel = (days?: Number, custom?: string) => {
  if (days) {
    switch (true) {
      case days <= 7 /** Less than a week */:
        return {
          format: '%d',
          tickRotation: -45,
          tickValues: 'every day',
          legend: <T value={['nivo.date']} />,
          legendOffset: 50,
          legendPosition: 'middle',
        } as AxisProps;
      case 8 <= days && days <= 31 /** Between a week and a month*/:
        return {
          format: '%d/%m',
          tickRotation: -45,
          tickValues: 'every 3 days',
          legend: <T value={['nivo.date', 'nivo.day/month']} addParan />,
          legendOffset: 50,
          legendPosition: 'middle',
        } as AxisProps;
      case 32 <= days && days <= 93 /** Between a month and 2 months*/:
        return {
          format: '%d/%m',
          tickRotation: -45,
          tickValues: 'every 7 days',
          legend: <T value={['nivo.date', 'nivo.day/month']} addParan />,
          legendOffset: 50,
          legendPosition: 'middle',
        } as AxisProps;
      case 94 <= days && days <= 186 /** Between 2 months and half year*/:
        return {
          format: '%d/%m',
          tickRotation: -45,
          tickValues: 'every 11 days',
          legend: <T value={['nivo.date', 'nivo.day/month']} addParan />,
          legendOffset: 50,
          legendPosition: 'middle',
        } as AxisProps;
      case 187 <= days && days <= 365 /** Between half year and 1 year*/:
        return {
          format: '%m/%Y',
          tickRotation: -45,
          tickValues: 'every 20 days',
          legend: <T value={['nivo.date', 'nivo.month/year']} addParan />,
          tickSize: 10,
          tickPadding: 5,
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      case days > 365 /** Beyond a year*/:
        return {
          format: '%m/%Y',
          tickRotation: -45,
          tickValues: 'every 28 days',
          legend: <T value={['nivo.date', 'nivo.month/year']} addParan />,
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      default:
    }
  } else if (custom) {
    if (custom === 'time') {
      return {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        format: (tick) => tick,
        legend: <T value={['nivo.time', 'nivo.24Hrs']} addParan />,
        legendOffset: 80,
        legendPosition: 'middle',
      } as AxisProps;
    } else if (custom === 'week') {
      return {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        format: (tick) => tick,
        legend: <T value={['nivo.week']} />,
        legendOffset: 80,
        legendPosition: 'middle',
      } as AxisProps;
    } else if (custom === 'standard') {
      return {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        format: (tick) => tick,
        legend: <T value={['nivo.date']} />,
        legendOffset: 80,
        legendPosition: 'middle',
      } as AxisProps;
    }
  }
};

export const getBarGraphBottomAxisLabel = (days?: Number, custom?: string) => {
  if (days) {
    switch (true) {
      case days <= 7 /** Less than a week */:
        return {
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -45,
          legend: <T value={['nivo.date']} />,
          legendPosition: 'middle',
          format: (tick) => (tick ? `${tick.split('-')[2]}` : ''),
          legendOffset: 50,
        } as AxisProps;
      case 8 <= days && days <= 31 /** Between a week and a month*/:
        return {
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -45,
          format: (tick) =>
            tick ? `${tick.split('-')[2]}/${tick.split('-')[1]}` : '',
          legend: <T value={['nivo.date', 'nivo.day/month']} addParan />,
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      case 32 <= days && days <= 62 /** Between a month and 2 months*/:
        return {
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -45,
          legend: <T value={['nivo.date', 'nivo.day/month']} addParan />,
          format: (tick) => {
            if (tick && everyXDays(parseInt(tick.split('-')[2]), 2)) {
              return `${tick.split('-')[2]}/${tick.split('-')[1]}`;
            } else {
              return '';
            }
          },
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      case 63 <= days && days <= 186 /** Between 2 months and half year*/:
        return {
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -45,
          legend: <T value={['nivo.date', 'nivo.day/month']} addParan />,
          format: (tick) => {
            if (tick && everyXDays(parseInt(tick.split('-')[2]), 5)) {
              return `${tick.split('-')[2]}/${tick.split('-')[1]}`;
            } else {
              return '';
            }
          },
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      case 187 <= days && days <= 365 /** Between half year and 1 year*/:
        return {
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -45,
          legend: <T value={['nivo.date', 'nivo.month/year']} addParan />,
          format: (tick) => {
            if (
              (tick && parseInt(tick.split('-')[2]) === 1) ||
              parseInt(tick.split('-')[2]) === 15
            ) {
              return `${tick.split('-')[1]}/${tick.split('-')[0]}`;
            } else {
              return '';
            }
          },
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      case days > 365 /** Beyond a year*/:
        return {
          tickSize: 10,
          tickPadding: 5,
          tickRotation: -45,
          legend: <T value={['nivo.date', 'nivo.month/year']} addParan />,
          format: (tick) => {
            if (tick && everyXDays(parseInt(tick.split('-')[2]), 15)) {
              return `${tick.split('-')[1]}/${tick.split('-')[0]}`;
            } else {
              return '';
            }
          },
          legendOffset: 80,
          legendPosition: 'middle',
        } as AxisProps;
      default:
    }
  } else if (custom) {
    if (custom === 'time') {
      return {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        format: (tick) => tick,
        legend: <T value={['nivo.time', 'nivo.24Hrs']} addParan />,
        legendOffset: 80,
        legendPosition: 'middle',
      } as AxisProps;
    } else if (custom === 'week') {
      return {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        format: (tick) => tick,
        legend: <T value={['nivo.week']} />,
        legendOffset: 80,
        legendPosition: 'middle',
      } as AxisProps;
    } else if (custom === 'standard') {
      return {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        format: (tick) => tick,
        legend: <T value={['nivo.date']} />,
        legendOffset: 80,
        legendPosition: 'middle',
      } as AxisProps;
    }
  }
};

/**
 * @param id (Bar Graph) get the graph name
 * @param indexValue (Bar Graph) get the x-axis value
 * @param index (Bar Graph) get the x-axis index
 * @param value (Bar Graph) get the y-axis value
 * @param translationSection (Bar Graph) section of the translation eg. 'analysis.newUsers'
 * @param point (Line Graph) point object
 * @param datum (Pie Graph) pie section object
 * @param tooltipType what type of tooltip do you need? Standard or specific one?
 * @param specialForm is a condition when tooltipType is NOT Standard
 * @param xAxisLength is the number points on the x axis
 * @returns
 */

export const nivoToolTip: React.FunctionComponent<any> = ({
  color,
  id,
  value,
  indexValue,
  index,
  point,
  datum,
  tooltipType,
  translationSection,
  specialForm,
  xAxisLength,
}) => {
  switch (true) {
    default:
    case tooltipType === 'Standard':
      return (
        <div style={{ width: 400 }}>
          <TooltipWrapper
            anchor={
              index !== undefined
                ? index < xAxisLength / 2
                  ? 'right'
                  : index > xAxisLength / 2
                  ? 'left'
                  : 'top'
                : point !== undefined
                ? point.id.split('.')[1] < xAxisLength / 2
                  ? 'right'
                  : point.id.split('.')[1] > xAxisLength / 2
                  ? 'left'
                  : 'top'
                : 'top'
            }
            position={[0, 0]}
          >
            <Form
              style={{
                border: '1.5px solid black',
                borderRadius: 15,
                backgroundColor: WHITE,
                padding: 3,
              }}
            >
              <div /**Color, graph title*/
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div /**Graph color */
                  style={{
                    height: 15,
                    width: 15,
                    paddingRight: 10,
                    backgroundColor: datum
                      ? datum.color
                      : point
                      ? point.color
                      : color,
                  }}
                />
                <div /**Graph Title */
                  style={{
                    paddingLeft: 5,
                    fontWeight: 'bolder',
                  }}
                >
                  {datum ? (
                    `(${datum.data.id})`
                  ) : point ? (
                    `(${point.serieId})`
                  ) : (
                    <T value={['', `${translationSection}.${id}`]} addParan />
                  )}
                </div>
              </div>
              <div /**Label 1 & 2*/>
                <Form.Item /**Label 1 */
                  label={
                    <div style={{ fontWeight: 'bolder' }}>
                      {
                        <T
                          value={[
                            `nivo.${
                              specialForm === 'Time'
                                ? 'time'
                                : datum
                                ? 'value'
                                : 'date'
                            }`,
                          ]}
                        />
                      }
                    </div>
                  }
                  style={{ marginBottom: 0 }}
                >
                  {point
                    ? moment(point.data.x).format(DATE_FORMAT)
                    : datum
                    ? datum.label
                    : indexValue}
                </Form.Item>
                <Form.Item /**Label 2 */
                  style={{ marginBottom: 0 }}
                  label={
                    <div style={{ fontWeight: 'bolder' }}>
                      {<T value={[`nivo.${datum ? 'percent' : 'value'}`]} />}
                    </div>
                  }
                >
                  {datum
                    ? datum.value
                    : point
                    ? addCommas(point.data.y)
                    : addCommas(value)}
                </Form.Item>
              </div>
            </Form>
          </TooltipWrapper>
        </div>
      );
    case tooltipType === 'HomeAnalysis':
      return (
        <div style={{ width: 400 }}>
          <TooltipWrapper
            anchor={
              point.id.split('.')[1] < xAxisLength / 2
                ? 'right'
                : point.id.split('.')[1] > xAxisLength / 2
                ? 'left'
                : 'top'
            }
            position={[0, 0]}
          >
            <Form
              style={{
                border: '1.5px solid black',
                borderRadius: 15,
                backgroundColor: WHITE,
                padding: 3,
              }}
            >
              <div /**Color, graph title*/
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div /**Graph color */
                  style={{
                    height: 15,
                    width: 15,
                    paddingRight: 10,
                    backgroundColor: point.color,
                  }}
                />
                <div /**Graph Title */
                  style={{
                    paddingLeft: 5,
                    fontWeight: 'bolder',
                  }}
                >
                  {`(${point.serieId})`}
                </div>
              </div>
              <div /**Label 1 & 2*/>
                <Form.Item /**Label 1*/
                  label={
                    <div
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      {<T value={[`nivo.${'date'}`]} />}
                    </div>
                  }
                  style={{ marginBottom: 0 }}
                >
                  {specialForm === 'month'
                    ? moment(point.data.x).format(DATE_FORMAT)
                    : specialForm === 'year'
                    ? point.data.x
                    : ''}
                </Form.Item>
                <Form.Item /**Label 2 */
                  style={{ marginBottom: 0 }}
                  label={
                    <div style={{ fontWeight: 'bolder' }}>
                      {<T value={[`nivo.${'value'}`]} />}
                    </div>
                  }
                >
                  {addCommas(point.data.y)}
                </Form.Item>
              </div>
            </Form>
          </TooltipWrapper>
        </div>
      );
  }
};
