import { useTranslation } from 'react-i18next';
import { ClaimGoodLists, OrderClaimPrintoutData } from '../../../../types';
import { Col, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CLAIM_FOOTER_FONTSIZE,
  CLAIM_PRINT_DEFAULT,
} from '../../../../constants/printoutConstants';

type OrderClaimPrintoutTableProps = {
  claim: OrderClaimPrintoutData;
};

const OrderClaimPrintoutTable = ({ claim }: OrderClaimPrintoutTableProps) => {
  //General Components
  const { t } = useTranslation();

  const columns: ColumnsType<ClaimGoodLists> = [
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.claimGoodsList.id')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'id',
      key: 'id',
      width: 30,
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.id}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.printoutColumns.goodsName')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'fullGoodsName',
      key: 'fullGoodsName',
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.orderGoods.fullGoodsName}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.claimGoodsList.claimReason')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'claimReason',
      key: 'claimReason',
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.claimReason}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.printoutColumns.qty')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'claimNumber',
      key: 'claimNumber',
      width: 50,
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.claimNumber}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.claimGoodsList.depositFee')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'depositFee',
      key: 'depositFee',
      width: 80,
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.depositFee}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.claimGoodsList.recycleFee')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'recycleFee',
      key: 'recycleFee',
      width: 110,
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.recycleFee}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.claimGoodsList.gstFee')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'gstFee',
      key: 'gstFee',
      width: 60,
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.gstFee}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.claimGoodsList.pstFee')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'pstFee',
      key: 'pstFee',
      width: 50,
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.pstFee}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.printoutColumns.goodsPrice')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'goodsPrice',
      key: 'goodsPrice',
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.orderGoods ? record.orderGoods.goodsPrice : ''}
        </div>
      ),
    },
    {
      title: (
        <Space
          size={0}
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography.Text style={{ fontSize: CLAIM_PRINT_DEFAULT }}>
            {t('order.printoutColumns.amount')}
          </Typography.Text>
        </Space>
      ),
      dataIndex: 'claimAmount',
      key: 'claimAmount',
      render: (text: string, record: ClaimGoodLists) => (
        <div
          style={{
            textAlign: 'center',
            fontSize: CLAIM_PRINT_DEFAULT,
          }}
        >
          {record.claimAmount}
        </div>
      ),
    },
  ];

  return (
    <>
      {claim.claimGoodsList && claim.claimGoodsList.length > 0 && (
        <Table<ClaimGoodLists>
          className="tablePrintout"
          dataSource={claim.claimGoodsList}
          rowKey={(data, index) => `${index}`}
          columns={columns}
          pagination={false}
          size="small"
          bordered={true}
          style={{
            marginBottom: 15,
          }}
        />
      )}
      <div>
        {claim.summary &&
          Object.keys(claim.summary).map((key) => {
            let amount = claim.summary[key as keyof typeof claim.summary];
            return (
              amount &&
              amount !== '0.00' &&
              amount !== '0' && (
                <Row justify="end" align="middle">
                  <Col span={4} offset={15} style={{ textAlign: 'right' }}>
                    <Typography.Text
                      style={{ fontSize: CLAIM_FOOTER_FONTSIZE }}
                    >
                      {t(`order.printoutColumns.summary.${key}`)}
                    </Typography.Text>
                  </Col>
                  <Col span={3} offset={1} style={{ textAlign: 'right' }}>
                    <Typography.Text
                      style={{ fontSize: CLAIM_FOOTER_FONTSIZE }}
                    >
                      {amount}
                    </Typography.Text>
                  </Col>
                </Row>
              )
            );
          })}

        <Row justify="end" align="middle">
          <Col span={4} offset={15} style={{ textAlign: 'right' }}>
            <Typography.Text
              strong
              style={{ fontSize: CLAIM_FOOTER_FONTSIZE + 2 }}
            >
              {t(`order.printoutColumns.finalAmount`)}
            </Typography.Text>
          </Col>
          <Col span={3} offset={1} style={{ textAlign: 'right' }}>
            <Typography.Text style={{ fontSize: CLAIM_FOOTER_FONTSIZE + 2 }}>
              {claim.finalAmount}
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrderClaimPrintoutTable;
