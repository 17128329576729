import { Col, Empty, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderData } from '../../../types';
import PayLogList from '../orderClaimDetail/PayLogList';

type OrderPayLogTableProps = {
  orderInfo?: OrderData;
};

const OrderPayLogTable = ({ orderInfo }: OrderPayLogTableProps) => {
  const { t } = useTranslation();

  return orderInfo && orderInfo.payLogList ? (
    <PayLogList orderDetail={orderInfo} />
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.payLogListColumns.title')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderPayLogTable;
