import Select, { DefaultOptionType } from 'antd/lib/select';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { useState } from 'react';
import { SellerData } from '../../types';
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../utils/alertMessage';
import { Typography } from 'antd';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';

type SellersDropdownProps = {
  labelInValue?: boolean;
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const SellersDropdown = ({
  labelInValue = false,
  disabled = false,
  initialValue,
  onChange,
}: SellersDropdownProps) => {
  //General components
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Data components
  const [sellers, setSellers] = useState<SellerData[]>([]);
  //Pagination/table Components
  const [sellerPage, setSellerPage] = useState(2);
  const [sellerTotalPage, setSellerTotalPage] = useState(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});

  const getSellers = (value?: string) => {
    getDataWithAuthToken('seller/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setSellers(response.data.list);
          setSellerPage(2);
          setSellerTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * Fetch when seller list scrolls to the bottom
   * @param e UI event
   */
  const sellerFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (sellerPage > sellerTotalPage) return;
      getDataWithAuthToken('seller/list', {
        params: {
          page: sellerPage,
          keyword: searchText.seller || undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setSellers((prev) => [...prev, ...response.data.list]);
            setSellerPage((prev) => prev + 1);
            setSellerTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      disabled={disabled}
      onPopupScroll={sellerFetchOnScroll}
      showSearch
      allowClear
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, seller: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => getSellers(value), EXTENDED_TIMEOUT));
      }}
      onFocus={() => {
        if (!sellers.length) getSellers();
      }}
      onChange={handleOnChange}
      filterOption={false}
      placeholder={t('searchPlaceholders.searchSeller')}
      labelInValue={labelInValue}
      defaultValue={initialValue ? initialValue : undefined}
      optionLabelProp="key"
    >
      {sellers.map((seller) => (
        <Select.Option
          key={`${seller.shopName ? seller.shopName : seller.sellerId} (${
            seller.sellerId
          })`}
          value={seller.sellerId}
          seller={seller}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('sellers.sellerListColumns.sellerId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('sellers.sellerListColumns.shopName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{seller.sellerId}</Typography>
              <Typography>{seller.shopName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SellersDropdown;
