import { Descriptions } from 'antd';
import { PrintoutOrderTableData } from '../../../types';
import { PRINTOUT_DATA_FONT_SIZE } from '../../../constants/printoutConstants';
import { BLACK } from '../../../constants/color';

type FormValuePrintoutProps = {
  printoutTableData: PrintoutOrderTableData;
};

const FormValuePrintout = ({ printoutTableData }: FormValuePrintoutProps) => {
  return (
    <>
      {printoutTableData.formValue &&
        printoutTableData.formValue.length > 0 &&
        printoutTableData.formValue.map((tableData, i) => {
          return (
            <>
              <Descriptions
                className="tablePrintout"
                title={
                  <div
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: 2,
                      fontSize: PRINTOUT_DATA_FONT_SIZE,
                    }}
                  >
                    {`${tableData.field_name}: `}
                  </div>
                }
                bordered
                style={{
                  marginBottom: 15,
                  border: 'solid',
                  borderWidth: 1,
                  borderColor: BLACK,
                }}
                labelStyle={{
                  fontSize: PRINTOUT_DATA_FONT_SIZE,
                  padding: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: '#fff',
                }}
                contentStyle={{
                  fontSize: PRINTOUT_DATA_FONT_SIZE,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {Object.keys(tableData.child).map((field, j) => {
                  return (
                    tableData.child[field] && (
                      <Descriptions.Item
                        label={`${tableData.child[field].field_name}`}
                        labelStyle={{ fontSize: 13 }}
                      >
                        {tableData.child[field].value}
                      </Descriptions.Item>
                    )
                  );
                })}
              </Descriptions>
            </>
          );
        })}
    </>
  );
};

export default FormValuePrintout;
