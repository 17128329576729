import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type VideoPreviewModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  videoPath?: string;
  title?: React.ReactNode;
};

const VideoPreviewModal = ({
  visible,
  setVisible,
  videoPath,
  title,
}: VideoPreviewModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title || t('settings.photoGalleryColumns.preview')}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <video
          src={videoPath}
          controls
          width="100%"
          style={{ maxWidth: 640, maxHeight: 360 }}
        />
      </div>
    </Modal>
  );
};

export default VideoPreviewModal;
