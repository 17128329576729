import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import {
  DATE_FORMAT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { EmployeeTaskGoodsDistributionData, TaskEnum } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import EmployeeDropdown from '../../sortationSystem/common/EmployeeDropdown';
import TableToolbar from '../../table/TableToolbar';

type EmployeeTaskGoodsDistributionProps = {
  taskEnum?: TaskEnum;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const EmployeeTaskGoodsDistribution = ({
  taskEnum,
  isLoading,
  setIsLoading,
}: EmployeeTaskGoodsDistributionProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const { RangePicker } = DatePicker;
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const isSubscribed = useRef(true);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  //Data Components
  const [employeeTaskGoodsDistriData, setEmployeeTaskGoodsDistriData] =
    useState<EmployeeTaskGoodsDistributionData[]>([]);
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const columnKeys = ['employee', 'totalNum', 'correctNum', 'correctRate'];

  const columns: ColumnsType<EmployeeTaskGoodsDistributionData> = [
    {
      title: t('analysis.employeeTaskGoodsDistribution.employee'),
      key: 'employee',
      dataIndex: 'employee',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (employee) => (employee ? employee.nickname : ''),
    },
    {
      title: t('analysis.employeeTaskGoodsDistribution.correctNum'),
      key: 'correctNum',
      dataIndex: 'correctNum',
      width: 100,
      render: (text: number) => text,
    },
    {
      title: t('analysis.employeeTaskGoodsDistribution.totalNum'),
      key: 'totalNum',
      dataIndex: 'totalNum',
      width: 100,
      render: (text: number) => text,
    },
    {
      title: t('analysis.employeeTaskGoodsDistribution.correctRate'),
      key: 'correctRate',
      dataIndex: 'correctRate',
      width: 100,
      render: (text: string) => text,
    },
  ];

  /**
   * Grabs all the needed data for Employee Task Goods Distribution
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('analysis/employee/task_goods', {
      params: {
        startDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
          : undefined,
        endDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
          : undefined,
        taskStage: form.getFieldValue('taskStage')
          ? form.getFieldValue('taskStage')
          : undefined,
        employeeId: form.getFieldValue('employeeId')
          ? form.getFieldValue('employeeId')
          : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setEmployeeTaskGoodsDistriData(response.data);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [t, form, setFourZeroThree, setFiveHundred, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form /**Range picker, Task Stage, OK, Reset, Refresh Buttons */
            form={form}
            ref={formRef}
            initialValues={{}}
          >
            <Row gutter={[16, 0]}>
              <Col span={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name={'date'}
                  label={t('analysis.orderDistribution.date')}
                  initialValue={[
                    moment().subtract(8, 'days'),
                    moment().subtract(1, 'days'),
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    placeholder={[
                      t('analysis.orderDistribution.startDate'),
                      t('analysis.orderDistribution.endDate'),
                    ]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name="taskStage"
                  label={t('analysis.employeeTaskDistribution.taskStage')}
                >
                  <Select
                    filterOption={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    optionLabelProp="key"
                  >
                    {taskEnum &&
                      taskEnum.taskStage.map((taskStage) => (
                        <Select.Option
                          key={taskStage.description}
                          value={taskStage.code}
                        >
                          {taskStage.description}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name="employeeId"
                  label={t(
                    'sortationSystem.employeeLogListColumns.advancedSearch.employeeId'
                  )}
                >
                  <EmployeeDropdown />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          setEmployeeTaskGoodsDistriData([]);
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.employeeTaskDistribution.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          form.resetFields();
                          setEmployeeTaskGoodsDistriData([]);
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.employeeTaskDistribution.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
          <div style={{ width: '100%', paddingTop: 15 }}>
            <TableToolbar
              columns={columns}
              columnKeys={columnKeys}
              rows={employeeTaskGoodsDistriData.map((data) => ({
                ...data,
                employee:
                  data.employee && data.employee.nickname
                    ? data.employee.nickname
                    : data.employee.username,
              }))}
              exportConfig={{
                fileName: 'EMPLOYEE_TASK_GOODS_DISTRIBUTION_ANALYSIS',
              }}
            />
            <Table<EmployeeTaskGoodsDistributionData>
              dataSource={employeeTaskGoodsDistriData}
              rowKey={(obj) => obj.employee.employeeId}
              columns={columns}
              components={vt}
              scroll={{
                y: 500,
                x: 500,
              }}
              loading={isLoading}
              pagination={false}
              rowSelection={undefined}
            />
          </div>
        </Spin>
      )}
    </>
  );
};

export default EmployeeTaskGoodsDistribution;
