import { Button, Divider, Form, Modal, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategoryDropdown from '../common/CategoryDropdown';

type GoodCategoryModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  categoryList?: any[];
  setCategoryList?: React.Dispatch<React.SetStateAction<any[]>>;
  allCategories?: any[];
};

const GoodCategoryModal = ({
  visible,
  setVisible,
  categoryList,
  setCategoryList,
  allCategories,
}: GoodCategoryModalProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [currentOptions, setCurrentOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (visible && categoryList) {
      setSelectedOptions(categoryList);
    }
  }, [visible, categoryList]);

  const onCancel = () => {
    form.resetFields();
    if (isSubscribed.current) {
      setSelectedOptions(categoryList);
      setVisible(false);
    }
  };

  const onOk = () => {
    form.resetFields();
    if (isSubscribed.current) {
      setCategoryList && setCategoryList(selectedOptions);
      setVisible(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('goods.add/editGood.ok')}
      cancelText={t('goods.add/editGood.cancel')}
      title={t('goods.add/editGood.extendCatList')}
    >
      <div style={{ display: 'flex' }}>
        <Form
          form={form}
          layout="vertical"
          style={{ flex: 1, marginRight: 10 }}
        >
          <Form.Item name="extendCatList" style={{ marginBottom: 0 }}>
            <CategoryDropdown
              categoryOptions={allCategories}
              onFocusFetch={true}
              onChange={(value, options) => {
                setCurrentOptions(options);
              }}
            />
          </Form.Item>
        </Form>
        <Button
          onClick={() => {
            if (
              currentOptions &&
              currentOptions.length &&
              selectedOptions.findIndex(
                (option: any) => option.catId === currentOptions.at(-1).value
              ) === -1
            ) {
              setSelectedOptions((prev: any) => [
                ...prev,
                {
                  catId: currentOptions.at(-1).value,
                  category: {
                    catId: currentOptions.at(-1).value,
                    fullCatName: currentOptions
                      .map((c: any) => c.label)
                      .join(' > '),
                  },
                },
              ]);
            }
          }}
        >
          {t('goods.add/editGood.add')}
        </Button>
      </div>
      {!!selectedOptions.length && <Divider dashed />}
      {selectedOptions.map((c: any, index: number) => (
        <Tag
          key={index}
          visible
          closable
          onClose={() =>
            setSelectedOptions((prev: any[]) => [
              ...prev.slice(0, index),
              ...prev.slice(index + 1),
            ])
          }
          style={{ marginBottom: 8 }}
        >
          {c.category && c.category.fullCatName}
        </Tag>
      ))}
    </Modal>
  );
};

export default GoodCategoryModal;
