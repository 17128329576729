import { useDispatch } from 'react-redux';
import {
  addPage,
  removePagePath,
  setActivePage,
} from '../features/pageHistory/pageHistorySlice';
import { useAppSelector } from '../app/hooks';
import getDashboardStyle from '../utils/getDashboardStyle';
import { useTranslation } from 'react-i18next';

export const useTab = () => {
  const pageHistory = useAppSelector((state) => state.pageHistory.value);
  const activePage = useAppSelector((state) => state.pageHistory.activePage);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addTab = (title: string, path: string) => {
    window.history.pushState({}, '', path);
    dispatch(
      addPage({
        title: title,
        path: path,
      })
    );
    dispatch(setActivePage(path));
  };

  const removeTab = (path: string) => {
    if (pageHistory.length) {
      if (path === activePage) {
        let activeTab =
          pageHistory[pageHistory.findIndex((page) => page.path === path) - 1];
        window.history.pushState({}, '', activeTab.path);
        document.title = `${t(activeTab.title)} - ${t(
          getDashboardStyle().title
        )}`;
        dispatch(setActivePage(activeTab.path));
      }
      dispatch(removePagePath(path));
    }
  };

  return { addTab, removeTab };
};
