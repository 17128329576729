import React, { useRef } from 'react';
import { Button, Radio, RadioChangeEvent, Grid, Space, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GalleryAlbum } from '../../types';
import TableSearch from '../table/TableSearch';
import TableRefresh from '../table/TableRefresh';
import { MEDIA_TYPE } from '../../constants/mediaConstants';

const { Title } = Typography;

type GalleryHeaderProps = {
  album?: GalleryAlbum;
  mediaType: string;
  setMediaType: React.Dispatch<React.SetStateAction<string>>;
  callBack?: Function;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setShowUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  totalPhotos?: number;
  refresh?: () => void;
};

/**
 * Photo Gallery Header
 *
 * @param album Selected Album
 */
const GalleryHeader = ({
  refresh,
  album,
  mediaType,
  setMediaType,
  callBack,
  setPage,
  setShowUpload,
  totalPhotos,
}: GalleryHeaderProps) => {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isSubscribed = useRef(true);

  /**
   * @param e Media Type option
   */
  const onChange = (e: RadioChangeEvent) => {
    if (isSubscribed.current) {
      setPage && setPage(1);
      setMediaType(e.target.value);
    }
  };

  return (
    <Space
      wrap
      style={{
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Space direction="vertical">
        {album && (
          <Space>
            <Title level={5} style={{ margin: 0 }}>
              {album?.albumName}
            </Title>
            {refresh && (
              <TableRefresh onRefresh={refresh} total={totalPhotos} />
            )}
          </Space>
        )}
        <Button
          icon={<UploadOutlined />}
          disabled={album === undefined}
          onClick={() => setShowUpload && setShowUpload(true)}
        >
          {t('settings.photoGalleryActions.uploadMedia')}
        </Button>
      </Space>
      <Space direction="vertical">
        <TableSearch
          placeholder={t('searchPlaceholders.searchPhoto')}
          searchFunction={(keyword) => {
            if (callBack) callBack(keyword);
          }}
          mobile={!screens.md}
        />
        <Radio.Group onChange={onChange} value={mediaType}>
          {Object.keys(MEDIA_TYPE).map((type, index) => {
            return (
              <Radio value={MEDIA_TYPE[type]} key={index}>
                {t(
                  `settings.photoGalleryColumns.mediaType.${MEDIA_TYPE[
                    type
                  ].toLowerCase()}`
                )}
              </Radio>
            );
          })}
        </Radio.Group>
      </Space>
    </Space>
  );
};

export default GalleryHeader;
