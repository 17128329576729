import { ReloadOutlined } from '@ant-design/icons';
import { ResponsivePie } from '@nivo/pie';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { ORDER_CLAIM_DISTRI_TYPE } from '../../../constants/analysisConstants';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
  MEDIUM_FONT_SIZE,
} from '../../../constants/systemConstants';
import { NivoPieChartData, OrderDistributionData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { addCommas, addCommasPrice } from '../../../utils/helperFunction';
import { nivoToolTip } from '../../../utils/nivoGraphTools';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableToolbar from '../../table/TableToolbar';

type OrderClaimDistributionProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const OrderClaimDistribution = ({
  isSeller,
  isLoading,
  setIsLoading,
}: OrderClaimDistributionProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const { RangePicker } = DatePicker;
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const isSubscribed = useRef(true);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [tabMode, setTabMode] = useState<string>(
    ORDER_CLAIM_DISTRI_TYPE.claimType
  );
  //Data Components
  const [tabsData, setTabsData] = useState<{
    [key: string]: {
      pieGraphs: NivoPieChartData[][];
      barGraph: {
        label: string;
        totalOrderNum?: number;
        totalAmount?: string;
      }[];
      tableData: OrderDistributionData[];
    };
  }>({});

  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'label',
    'totalOrderNum',
    'totalAmount',
    'orderNumPercentage',
    'amountPercentage',
  ];
  const columns: ColumnsType<OrderDistributionData> = [
    {
      title: t('analysis.orderClaimDistribution.id'),
      key: 'label',
      dataIndex: 'label',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('analysis.orderClaimDistribution.totalClaimNum'),
      key: 'totalClaimNum',
      dataIndex: 'totalClaimNum',
      width: 90,
      sorter: (a: OrderDistributionData, b: OrderDistributionData) =>
        compare(
          Number(a.totalOrderNum) !== undefined
            ? Number(a.totalOrderNum)
            : null,
          Number(b.totalOrderNum) !== undefined ? Number(b.totalOrderNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.orderClaimDistribution.totalAmount'),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      sorter: (a: OrderDistributionData, b: OrderDistributionData) =>
        compare(
          Number(a.totalAmount) !== undefined ? Number(a.totalAmount) : null,
          Number(b.totalAmount) !== undefined ? Number(b.totalAmount) : null
        ),
      width: 90,
      render: (text: string) => addCommasPrice(text),
    },
    {
      title: t('analysis.orderClaimDistribution.numPercentage'),
      key: 'numPercentage',
      dataIndex: 'numPercentage',
      width: 90,
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.orderClaimDistribution.amountPercentage'),
      key: 'amountPercentage',
      dataIndex: 'amountPercentage',
      width: 90,
      render: (text: string) => addCommas(text),
    },
  ];

  /**
   * Grabs all the needed data for Order Claim Distribution
   */
  const getData = useCallback(() => {
    const getGraphData = (data: OrderDistributionData[]) => {
      let barGraphData: {
        label: string;
        totalOrderNum?: number;
        totalAmount?: string;
      }[] = [];
      let pieGraphs: NivoPieChartData[][] = [];
      let totalClaimNumPie: NivoPieChartData[] = [];
      let totalAmountPie: NivoPieChartData[] = [];
      let tableData: OrderDistributionData[] = [];
      if (data.length > 0) {
        data.forEach((point) => {
          point.totalClaimNum !== undefined &&
            point.numPercentage !== undefined &&
            totalClaimNumPie.push({
              id: point.label,
              value: parseFloat(point.numPercentage),
              label: point.totalClaimNum,
            });
          point.totalAmount !== undefined &&
            point.amountPercentage !== undefined &&
            totalAmountPie.push({
              id: point.label,
              value: parseFloat(point.amountPercentage),
              label: point.totalAmount,
            });
          point.totalAmount &&
            tableData.push({
              label: point.label,
              totalAmount: point.totalAmount,
              totalClaimNum: point.totalClaimNum,
              numPercentage: point.numPercentage,
              amountPercentage: point.amountPercentage,
            });
        });
        if (totalClaimNumPie.length > 0 && totalAmountPie.length > 0) {
          pieGraphs.push(totalClaimNumPie);
          pieGraphs.push(totalAmountPie);
        }
        if (barGraphData.length > 0) {
          setTabsData((prev) => ({
            ...prev,
            [tabMode]: {
              barGraph: barGraphData,
              tableData: tableData,
              pieGraphs: [],
            },
          }));
        } else if (
          pieGraphs.length > 0 &&
          totalClaimNumPie.length > 0 &&
          totalAmountPie.length > 0
        ) {
          setTabsData((prev) => ({
            ...prev,
            [tabMode]: {
              pieGraphs: pieGraphs,
              tableData: tableData,
              barGraph: [],
            },
          }));
        }
      }
    };
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('analysis/order/claim_distr', {
      params: {
        isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
        sellerId:
          getDashboardStyle().isSellerSwitch &&
          isSeller &&
          formRef.current &&
          form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        distrType: tabMode,
        startDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
          : undefined,
        endDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
          : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            getGraphData(response.data.list);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [
    t,
    form,
    setFourZeroThree,
    setFiveHundred,
    tabMode,
    isSeller,
    setIsLoading,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form /**Range picker, OK, Reset, Refresh Buttons */
            form={form}
            ref={formRef}
            initialValues={{}}
          >
            <Row gutter={[16, 0]}>
              <Col span={24} md={24} lg={24} xl={8}>
                <Form.Item
                  name={'date'}
                  label={t('analysis.orderClaimDistribution.date')}
                  initialValue={[
                    moment().subtract(8, 'days'),
                    moment().subtract(1, 'days'),
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    placeholder={[
                      t('analysis.orderClaimDistribution.startDate'),
                      t('analysis.orderClaimDistribution.endDate'),
                    ]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              {isSeller && (
                <Col span={24} md={24} lg={24} xl={8}>
                  <Form.Item
                    name="sellerId"
                    label={t('analysis.goodsSales.seller')}
                  >
                    <SellersDropdown />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderClaimDistribution.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      form.resetFields();
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          form.resetFields();
                          setTabsData({});
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderClaimDistribution.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
          <Tabs
            defaultActiveKey={ORDER_CLAIM_DISTRI_TYPE.claimType}
            onTabClick={(key) => {
              setTabMode(key);
            }}
          >
            {Object.keys(ORDER_CLAIM_DISTRI_TYPE).map((label) => {
              return (
                <Tabs.TabPane
                  tab={t(`analysis.orderClaimDistribution.${label}`)}
                  key={
                    ORDER_CLAIM_DISTRI_TYPE[
                      label as keyof typeof ORDER_CLAIM_DISTRI_TYPE
                    ]
                  }
                >
                  {
                    /**Pie Graphs */
                    tabsData[tabMode] &&
                      tabsData[tabMode].pieGraphs.length > 0 && (
                        <Row style={{ marginBottom: 40 }} gutter={[0, 24]}>
                          {tabsData[tabMode].pieGraphs.map(
                            (pieGraph, index) => {
                              return (
                                <Col
                                  key={index}
                                  span={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={12}
                                  style={{ height: 300 }}
                                >
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      fontSize: MEDIUM_FONT_SIZE,
                                      paddingBottom: 5,
                                    }}
                                  >
                                    {t(
                                      `analysis.orderClaimDistribution.${
                                        index === 0
                                          ? 'totalClaimNum'
                                          : 'totalAmount'
                                      }`
                                    )}
                                  </div>
                                  <ResponsivePie
                                    colors={{ scheme: 'paired' }}
                                    tooltip={(datum) =>
                                      nivoToolTip({
                                        ...datum,
                                        tooltipType: 'Standard',
                                      })
                                    }
                                    arcLinkLabelsStraightLength={10}
                                    arcLinkLabelsDiagonalLength={10}
                                    arcLinkLabelsSkipAngle={
                                      pieGraph.length < 3 ? 0 : 5
                                    }
                                    arcLabelsSkipAngle={
                                      pieGraph.length < 3 ? 0 : 20
                                    }
                                    data={pieGraph}
                                    arcLabel={(d) =>
                                      d.value !== 0 ? `${d.value}%` : ''
                                    }
                                    arcLinkLabel={(d) =>
                                      d.value !== 0 ? `${d.id}` : ''
                                    }
                                    theme={{
                                      fontSize: 12,
                                      axis: {
                                        legend: {
                                          text: { fontSize: DEFAULT_FONT_SIZE },
                                        },
                                      },
                                    }}
                                    margin={{
                                      top: 40,
                                      left: 40,
                                      right: 40,
                                      bottom: 40,
                                    }}
                                  />
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      )
                  }
                  {tabsData[tabMode] &&
                    tabsData[tabMode].tableData.length >
                      0 /**Export Table */ && (
                      <div style={{ width: '100%' }}>
                        <TableToolbar
                          columns={columns}
                          columnKeys={columnKeys}
                          rows={tabsData[tabMode].tableData.map((data) => ({
                            ...data,
                          }))}
                          exportConfig={{
                            fileName: 'ORDER_CLAIM_DISTRIBUTION_ANALYSIS',
                          }}
                        />
                        <Table<OrderDistributionData>
                          dataSource={tabsData[tabMode].tableData}
                          rowKey={(obj) => obj.label}
                          columns={columns}
                          components={vt}
                          scroll={{
                            y: 500,
                            x: 500,
                          }}
                          loading={isLoading}
                          pagination={false}
                          rowSelection={undefined}
                          size="small"
                        />
                      </div>
                    )}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Spin>
      )}
    </>
  );
};

export default OrderClaimDistribution;
