import { Modal, Spin, Input, Button, Col, Row, Select } from 'antd';
import Form from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_DEFAULT_STATUS } from '../../constants/generalConstants';
import { EmployeeData, EmployeeEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { generateKey } from '../../utils/helperFunction';

type EmployeeProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  info?: EmployeeData;
  callBack?: () => void;
  employeeEnum?: EmployeeEnum;
};

const EmployeeModal = ({
  employeeEnum,
  visible,
  setVisible,
  info,
  callBack,
}: EmployeeProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [employeeTypeList, setEmployeeTypeList] = useState<string[]>([]);

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken(info ? 'employee/edit' : 'employee/add', {
          ...values,
          employeeId: info ? info.employeeId : undefined,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              alertMessage(
                'success',
                info
                  ? t('sortationSystem.alerts.employeeEdited')
                  : t('sortationSystem.alerts.employeeAdded')
              );
              callBack && callBack();
              onClose();
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setEmployeeTypeList(
        info && info.employeeTypeList
          ? info.employeeTypeList.map((type) => type.code)
          : []
      );
    }
  }, [visible, form, info]);

  return (
    <Modal
      visible={visible}
      okText={t('general.ok')}
      cancelText={t('general.close')}
      okButtonProps={{ loading: isLoading }}
      onOk={onConfirm}
      onCancel={onClose}
      title={
        info
          ? `${t('sortationSystem.add/EditEmployee.editTitle')} ${
              info.employeeId
            }`
          : t('sortationSystem.add/EditEmployee.addTitle')
      }
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={
            info
              ? {
                  ...info,
                  nickname: info.nickname ? info.nickname : '',
                  mobile: info.mobile ? info.mobile : '',
                  activeCode: info.activeCode ? info.activeCode : '',
                  status: info.status ? info.status.code : '',
                  currentEmployeeType: info.currentEmployeeType
                    ? info.currentEmployeeType.code
                    : '',
                  employeeTypeList: info.employeeTypeList
                    ? info.employeeTypeList.map((type) => type.code)
                    : [],
                  remark: info.remark ? info.remark : '',
                }
              : { status: EMPLOYEE_DEFAULT_STATUS }
          }
          layout="vertical"
        >
          <Form.Item
            label={t('sortationSystem.add/EditEmployee.nickname')}
            name="nickname"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.add/EditEmployee.mobile')}
            name="mobile"
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row align="middle" gutter={[0, 12]}>
            <Col>
              <Form.Item
                label={t('sortationSystem.add/EditEmployee.activeCode')}
                name="activeCode"
                rules={[
                  {
                    required: true,
                    len: 6,
                    message: t('general.inputError.empty'),
                  },
                  {
                    pattern: /^[\w]*$/,
                    message: t('sortationSystem.inputError.apiCode'),
                  },
                ]}
                style={{ paddingRight: 10 }}
              >
                <Input style={{ width: 100 }} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={' '}
                // style={{ marginBottom: 0 }}
              >
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      activeCode: generateKey(6),
                    });
                  }}
                >
                  {t('sortationSystem.add/EditEmployee.generateCode')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="status"
            label={t(
              'sortationSystem.employeeListColumns.advancedSearch.employeeStatus'
            )}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              style={{ minWidth: 120 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {employeeEnum &&
                employeeEnum.employeeStatus &&
                employeeEnum.employeeStatus.map((status) => (
                  <Select.Option key={status.code} value={status.code}>
                    {status.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="employeeTypeList"
            label={t('sortationSystem.add/EditEmployee.employeeTypeList')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              mode="multiple"
              onChange={(value) => {
                setEmployeeTypeList(value);
              }}
            >
              {employeeEnum &&
                employeeEnum.employeeType &&
                employeeEnum.employeeType.map((type) => (
                  <Select.Option key={type.code} value={type.code}>
                    {type.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {employeeTypeList.length > 0 && (
            <Form.Item
              name="currentEmployeeType"
              label={t('sortationSystem.add/EditEmployee.currentEmployeeType')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <Select
                style={{ minWidth: 120 }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {employeeEnum &&
                  employeeEnum.employeeType &&
                  employeeEnum.employeeType
                    .filter((type) => employeeTypeList.includes(type.code))
                    .map((type) => (
                      <Select.Option key={type.code} value={type.code}>
                        {type.description}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label={t('sortationSystem.add/EditEmployee.remark')}
            name="remark"
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EmployeeModal;
