import React from 'react';
import { useTranslation } from 'react-i18next';
import ShopConfigLayout from '../layout/ShopConfigLayout';

/**
 * Displays Basic Settings
 * /dashboard/settings/basic
 *
 * @param route Route Information from react-router-config
 */
const BasicSettings = () => {
  const { t } = useTranslation();

  return <ShopConfigLayout group="basic" title={t('settings.basicSettings.title')} />;
};

export default BasicSettings;
