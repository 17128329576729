import { Cascader } from 'antd';
import { DefaultOptionType } from 'rc-cascader';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { CategoryData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type CategoryDropdownProps = {
  onFocusFetch?: boolean;
  hasTopTree?: boolean; // used in CategoryModal which for parent category
  currentCat?: CategoryData;
  columnWidth?: number; // set column width
  initialValue?: any | any[];
  categoryOptions?: any[];
  setCategoryOptions?: React.Dispatch<React.SetStateAction<any[]>>;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const CategoryDropdown = ({
  hasTopTree = false,
  onFocusFetch = false,
  columnWidth = undefined,
  currentCat,
  initialValue,
  categoryOptions,
  setCategoryOptions,
  onChange,
}: CategoryDropdownProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getCategories = useCallback(() => {
    const makeTreeData = (data: CategoryData[]) => {
      let treeData: DefaultOptionType[] = [];
      for (let node of data) {
        const treeNode: DefaultOptionType = {
          value: node.catId,
          label: node.catName,
          disabled: currentCat && currentCat.catId === node.catId,
          children:
            node.child && node.child.length
              ? makeTreeData(node.child)
              : undefined,
        };
        treeData.push(treeNode);
      }
      return treeData;
    };

    // Get category options from parent components
    if (categoryOptions && categoryOptions.length > 0) {
      setOptions(categoryOptions);
    } else {
      getDataWithAuthToken('goods/category/all')
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              let treeData = [];
              if (hasTopTree) {
                treeData = [
                  {
                    value: 0,
                    label: t('goods.categoryListColumns.topCategory'),
                    children: makeTreeData(response.data.list),
                  },
                ];
              } else {
                treeData = makeTreeData(response.data.list);
              }
              setOptions(treeData);
              setCategoryOptions && setCategoryOptions(treeData);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  }, [t, hasTopTree, currentCat, categoryOptions, setCategoryOptions]);

  useEffect(() => {
    // if onFocusFetch, not render category when component displayed
    if (!onFocusFetch) {
      getCategories();
    }
  }, [onFocusFetch, getCategories]);

  /** Search Box Filter */
  const filter = (searchText: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) > -1
    );

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Cascader
      style={{ width: '100%' }}
      dropdownMatchSelectWidth={!!columnWidth}
      dropdownMenuColumnStyle={columnWidth ? { width: columnWidth } : undefined}
      disabled={!hasPermission(actionPermissions.goodGroup.categoryView)}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      defaultValue={initialValue}
      options={options}
      placeholder={t('searchPlaceholders.searchCategoryId')}
      changeOnSelect
      displayRender={(label) => label.join(' > ')}
      showSearch={{ filter }}
      onChange={handleOnChange}
      onFocus={() => {
        if (onFocusFetch) {
          if (!options.length) getCategories();
        }
      }}
    />
  );
};

export default CategoryDropdown;
