import {
  DragOutlined,
  MenuOutlined,
  RetweetOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Grid,
  Modal,
  Radio,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { useVT } from 'virtualizedtableforantd4';
import { WHITE } from '../../constants/color';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { TASK_SORT_ORDER_TYPE } from '../../constants/taskConstants';
import { TaskData, TaskEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';

type TaskSortOrderModalProps = {
  taskEnum?: TaskEnum;
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  refresh?: Function;
};

const TaskSortOrderModal = ({
  taskEnum,
  visible,
  setVisible,
  refresh,
}: TaskSortOrderModalProps) => {
  const screens = Grid.useBreakpoint();
  const isSubscribed = useRef(true);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // General
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [isLoading, setIsLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  // Data
  const [selectedSortType, setSelectedSortType] = useState<string>(
    TASK_SORT_ORDER_TYPE.labelSort
  );
  // 任务日期
  const taskTimeOptions = [
    moment().format(DATE_FORMAT), // 今日
    moment().add(1, 'days').format(DATE_FORMAT), //明日
  ];
  const [selectedTaskTime, setSelectedTaskTime] = useState<string>(
    taskTimeOptions[0]
  );
  const [taskLabelData, setTaskLabelData] = useState<string[]>([]);
  const [oldTaskLabelData, setOldTaskLabelData] = useState<string[]>([]);
  const [selectedTaskLabel, setSelectedTaskLabel] = useState<string>();
  const [taskData, setTaskData] = useState<TaskData[]>([]);
  const [oldTaskData, setOldTaskData] = useState<TaskData[]>([]);
  const [taskDataSize, setTaskDataSize] = useState<number>(0);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /*** 获取未分配的普品拣单列表 */
  const getPendingTaskList = useCallback(
    async (label: string) => {
      if (isSubscribed.current) setIsLoading(true);
      try {
        const response = await getDataWithAuthToken('task/list', {
          params: {
            page: 1,
            size: 10000, // TODO Backend did not provide task list without pagination
            taskNav: 'PENDING',
            taskStage: 'NORMAL_SORT',
            taskLabel: label,
            startTaskTime: selectedTaskTime,
            endTaskTime: selectedTaskTime,
          },
        });

        if (response && response.goodStatus) {
          setTaskData(response.data.list);
          setOldTaskData([...response.data.list]);
          setTaskDataSize(response.data.totalItem);
          if (isSubscribed.current) setIsLoading(false);
        } else {
          if (isSubscribed.current) setIsLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      } catch (error) {
        if (isSubscribed.current) setIsLoading(false);
        console.log(error);
      }
    },
    [t, selectedTaskTime]
  );

  const getTaskLabelList = useCallback(async () => {
    if (isSubscribed.current) setIsLoading(true);
    try {
      const response = await getDataWithAuthToken('task/task_label', {
        params: {
          startTaskTime: selectedTaskTime,
          endTaskTime: selectedTaskTime,
        },
      });

      if (response && response.goodStatus) {
        setTaskLabelData(response.data);
        setOldTaskLabelData([...response.data]);
        // 根据首个标签自动获取任务列表
        if (
          selectedSortType === TASK_SORT_ORDER_TYPE.taskSort &&
          response.data &&
          response.data.length > 0
        ) {
          const targetLabel = response.data[0];
          setSelectedTaskLabel(targetLabel);
          getPendingTaskList(targetLabel);
        }
        if (isSubscribed.current) setIsLoading(false);
      } else {
        if (isSubscribed.current) setIsLoading(false);
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    } catch (err) {
      if (isSubscribed.current) setIsLoading(false);
      console.log(err);
    }
  }, [t, selectedTaskTime, selectedSortType, getPendingTaskList]);

  const checkIfDataChanged = (type: string = TASK_SORT_ORDER_TYPE.taskSort) => {
    if (type === TASK_SORT_ORDER_TYPE.labelSort) {
      return !oldTaskLabelData.every(function (oldLabel, index) {
        return oldLabel === taskLabelData[index];
      });
    } else if (type === TASK_SORT_ORDER_TYPE.taskSort) {
      return !oldTaskData.every(function (oldTask, index) {
        return oldTask.taskId === taskData[index].taskId;
      });
    }

    return false;
  };

  /**
   * 首次打开 - 加载标签列表
   * 切换日期/排序类型之后需要更新任务标签，任务列表
   *  */
  useEffect(() => {
    if (visible && isSubscribed.current) {
      setSelectedTaskLabel(undefined);
      setTaskData([]);
      setOldTaskData([]);
      getTaskLabelList();
      setTaskDataSize(0);
    }
  }, [visible, selectedTaskTime, selectedSortType, getTaskLabelList]);

  const onCancel = () => {
    if (isSubscribed.current) {
      setVisible(false);
      form.resetFields();
      setTaskLabelData([]);
      setOldTaskLabelData([]);
      setSelectedTaskLabel(undefined);
      setTaskData([]);
      setOldTaskData([]);
      setTaskDataSize(0);
      setSelectedTaskTime(taskTimeOptions[0]);
      setSelectedSortType(TASK_SORT_ORDER_TYPE.labelSort);
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (isSubscribed.current) setIsLoading(true);
        try {
          const response = await postDataWithAuthToken('task/sort_batch', {
            ...values,
            taskLabelList: taskLabelData,
            taskIds: taskData.map((task) => task.taskId),
          });
          if (response && response.goodStatus) {
            if (isSubscribed.current) setIsLoading(false);
            alertMessage(
              'success',
              t('sortationSystem.alerts.taskSortOrderEdited')
            );
            onCancel();
            refresh && refresh();
          } else {
            if (isSubscribed.current) setIsLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } catch (error) {
          if (isSubscribed.current) setIsLoading(false);
          console.log(error);
        }
      })
      .catch((error) => console.log(error));
  };

  // 任务标签排序
  const TaskLabelSortableItem = SortableElement(
    ({ index, label }: { index: number; label: string }) => (
      <div key={index} style={{ zIndex: 10000, cursor: 'grab' }}>
        <Tag
          icon={<DragOutlined />}
          key={index}
          style={{
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: DEFAULT_FONT_SIZE,
          }}
        >
          {label}
        </Tag>
      </div>
    )
  );
  const TaskLabelSortableList = SortableContainer(() => (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
      {taskLabelData &&
        taskLabelData.map((label: string, index: number) => (
          <TaskLabelSortableItem index={index} label={label} key={index} />
        ))}
    </div>
  ));
  const renderTaskSortComponent = () => {
    return (
      <Form.Item
        label={t('sortationSystem.taskListColumns.taskLabel')}
        name="taskLabelList"
      >
        <TaskLabelSortableList
          axis="xy"
          onSortEnd={({ oldIndex, newIndex }) => {
            setTaskLabelData((prev) => {
              return arrayMoveImmutable(prev, oldIndex, newIndex);
            });
          }}
        />
      </Form.Item>
    );
  };

  // 任务列表排序
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));
  const TableSortableItem = SortableElement(
    (props: React.HTMLAttributes<HTMLTableRowElement>) => (
      <tr style={{ zIndex: 10000 }} {...props} />
    )
  );
  const TableSortableBody = SortableContainer(
    (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
      <tbody style={{ zIndex: 10000 }} {...props} />
    )
  );
  const DraggableContainer = (props: SortableContainerProps) => (
    <TableSortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={({ oldIndex, newIndex }) => {
        setTaskData((prev) => {
          return arrayMoveImmutable(prev, oldIndex, newIndex);
        });
      }}
      {...props}
    />
  );
  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = taskData.findIndex(
      (task) => task.taskId === restProps['data-row-key']
    );
    return <TableSortableItem index={index} {...restProps} />;
  };
  const columns: ColumnsType<TaskData> = [
    {
      title: t('sortationSystem.taskListColumns.sortOrder'),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      className: 'drag-visible',
      width: 50,
      render: () => <DragHandle />,
    },
    {
      title: t('sortationSystem.taskListColumns.taskId'),
      key: 'taskId',
      dataIndex: 'taskId',
      className: 'drag-visible',
      width: 50,
    },
    {
      title: t('sortationSystem.taskListColumns.orderInfo'),
      key: 'orderSn',
      width: 140,
      className: 'drag-visible',
      render: (record: TaskData) =>
        record &&
        record.order && (
          <Space direction="vertical" size={0}>
            <Space>{record.order.orderSn}</Space>
            <Space>{record.order.consignee}</Space>
            {(record.order.sequenceLabel ||
              record.order.orderShippingLabel) && (
              <Space>
                {`${t('order.orderListColumns.sequenceLabel')}:`}
                {record.order.sequenceLabel && (
                  <Tag color="#800020">{record.order.sequenceLabel}</Tag>
                )}
                {record.order.orderShippingLabel && (
                  <Tag>{record.order.orderShippingLabel}</Tag>
                )}
              </Space>
            )}
          </Space>
        ),
    },
    {
      title: t('sortationSystem.taskListColumns.taskInfo'),
      key: 'taskStage',
      width: 140,
      render: (record: TaskData) => (
        <Space direction="vertical" size={0}>
          <Space>
            {`${t('sortationSystem.taskListColumns.taskStage')}:`}
            {record.taskStage && (
              <Tag color={record.taskStage.tagColor} style={{ color: WHITE }}>
                {record.taskStage.description}
              </Tag>
            )}
          </Space>
          <Space>
            {`${t('sortationSystem.taskListColumns.taskType')}:`}
            {record.taskType && record.taskType.description}
          </Space>
        </Space>
      ),
    },
    {
      title: t('sortationSystem.taskListColumns.taskLabel'),
      key: 'taskLabel',
      dataIndex: 'taskLabel',
      width: 80,
    },
    {
      title: t('sortationSystem.taskListColumns.taskStatus'),
      key: 'taskStatus',
      dataIndex: 'taskStatus',
      width: 80,
      render: (value) =>
        value && (
          <Tag color={value.tagColor} style={{ color: WHITE }}>
            {value.description}
          </Tag>
        ),
    },
  ];
  const renderLabelSortComponent = () => {
    return (
      <>
        <Form.Item
          label={t('sortationSystem.taskListColumns.taskLabel')}
          name="taskLabelOptions"
        >
          <Space wrap size={[8, 16]}>
            {taskLabelData &&
              taskLabelData.map((label) => (
                <CheckableTag
                  key={label}
                  checked={selectedTaskLabel === label}
                  onChange={async () => {
                    setSelectedTaskLabel(label);
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(
                        async () => await getPendingTaskList(label),
                        GENERAL_TIMEOUT
                      )
                    );
                  }}
                  style={{
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontSize: DEFAULT_FONT_SIZE,
                    border: '1px solid #f0f0f0',
                  }}
                >
                  {label}
                </CheckableTag>
              ))}
          </Space>
        </Form.Item>

        <Table
          dataSource={taskData}
          columns={columns}
          loading={isLoading}
          rowKey={(record) => record.taskId}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
              ...vt,
            },
          }}
          scroll={{ y: 600, x: 1200 }}
          pagination={false}
          title={() => (
            <Space>
              <Button
                icon={<RetweetOutlined />}
                disabled={taskData.length <= 1}
                onClick={() => {
                  const reverseTaskList = taskData.reverse();
                  setTaskData([...reverseTaskList]);
                }}
              >
                {t('sortationSystem.add/EditTask.reverseTaskSortOrder')}
              </Button>
              <Button
                icon={<RollbackOutlined />}
                disabled={!checkIfDataChanged()}
                onClick={() => {
                  setTaskData([...oldTaskData]);
                }}
              >
                {t('sortationSystem.add/EditTask.restore')}
              </Button>
            </Space>
          )}
          footer={() => {
            return (
              <Typography style={{ textAlign: 'right' }}>{`${t(
                'sortationSystem.taskListColumns.taskNumber'
              )}: ${taskDataSize}`}</Typography>
            );
          }}
        />
      </>
    );
  };

  return (
    <Modal
      width={screens.lg ? 992 : screens.md ? 768 : undefined}
      visible={visible}
      onOk={onFinish}
      onCancel={onCancel}
      okButtonProps={{
        disabled:
          // 任务标签排序
          selectedSortType === TASK_SORT_ORDER_TYPE.labelSort
            ? taskLabelData && taskLabelData.length > 1
              ? !checkIfDataChanged(TASK_SORT_ORDER_TYPE.labelSort)
              : true
            : taskData && taskData.length > 1
            ? !checkIfDataChanged()
            : true,
      }}
      title={`${t('sortationSystem.add/EditTask.editTaskSortOrder')}`}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={{
            taskSortOrderType: TASK_SORT_ORDER_TYPE.labelSort,
            taskTime: taskTimeOptions[0],
          }}
        >
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskSortOrderType')}
            name="taskSortOrderType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {taskEnum && taskEnum.taskSortOrderType && (
              <Radio.Group
                onChange={(e) => {
                  setSelectedSortType(e.target.value);
                }}
              >
                {taskEnum.taskSortOrderType.map((type, index) => (
                  <Radio value={type.code} key={index}>
                    {type.description}
                  </Radio>
                ))}
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskTime')}
            name="taskTime"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                setSelectedTaskTime(e.target.value);
              }}
            >
              {taskTimeOptions.map((tag, index) => (
                <Radio.Button value={tag} key={index}>
                  {tag}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>

          {selectedSortType === TASK_SORT_ORDER_TYPE.labelSort &&
            renderTaskSortComponent()}

          {selectedSortType === TASK_SORT_ORDER_TYPE.taskSort &&
            renderLabelSortComponent()}
        </Form>
      </Spin>
    </Modal>
  );
};

export default TaskSortOrderModal;
