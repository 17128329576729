import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

/**
 * Stores the nickname of a user whos been viewed on the detail page
 * Used to get PageHistoryTabs titles for different UserDetail pages
 */

interface SelectedUserState {
  value: string;
}

const initialState: SelectedUserState = {
  value: '',
};

export const selectedUserSlice = createSlice({
  name: 'selectedUser',
  initialState,
  reducers: {
    /**
     * @param state  Current SelectedUser
     * @param action The new SelectedUser
     */
    setSelectedUser: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setSelectedUser } = selectedUserSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSelectedUser = (state: RootState) => state.selectedUser.value;

export default selectedUserSlice.reducer;
