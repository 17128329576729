import { Card } from 'antd';
import Container from '../../../components/Container';
import UserSMSLogTable from '../../../components/users/userDetail/UserSMSLogTable';

const SMSLogList = () => {
  return (
    <Container>
      <Card>
        <UserSMSLogTable />
      </Card>
    </Container>
  );
};

export default SMSLogList;
