import { Cascader, Input, Modal, Select, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import { AdData, ArticleData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import BrandDropdown from '../goods/common/BrandDropdown';
import CategoryDropdown from '../goods/common/CategoryDropdown';

type AdLinkTypeModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  linkType: any;
  callBack?: (value: string) => void;
  categories?: any[];
};

const AdLinkTypeModal = ({
  visible,
  setVisible,
  linkType,
  callBack,
  categories,
}: AdLinkTypeModalProps) => {
  const { t } = useTranslation();
  const [link, setLink] = useState<string>('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [articles, setArticles] = useState<ArticleData[]>([]);
  const [articlePage, setArticlePage] = useState(2);
  const [articleTotalPage, setArticleTotalPage] = useState(2);
  const [options, setOptions] = useState<
    {
      value: number | string;
      label: string;
      isLeaf: boolean;
      disabled?: boolean;
    }[]
  >([]);

  const getArticles = (value?: string) => {
    getDataWithAuthToken('article/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setArticles(response.data.list);
          setArticlePage(2);
          setArticleTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const articleFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (articlePage > articleTotalPage) return;
      getDataWithAuthToken('article/list', {
        params: {
          keyword: searchText.article || undefined,
          page: articlePage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setArticles((prev) => [...prev, ...response.data.list]);
            setArticlePage((prev) => prev + 1);
            setArticleTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onConfirm = () => {
    callBack && callBack(link || linkType.value);
    onClose();
  };

  const onClose = () => {
    setLink('');
    setVisible(false);
  };

  const getAdPages = () => {
    getDataWithAuthToken('home_ad/list', {
      params: { parentId: 0 },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setOptions(
              response.data.list.map((ad: AdData) => {
                return {
                  value: ad.adPageId,
                  label: ad.adPageName,
                  isLeaf: ad.childCount === 0,
                };
              })
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const loadData = (selectedOptions?: any[]) => {
    if (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      setTimeout(() => {
        getDataWithAuthToken('home_ad/list', {
          params: { parentId: targetOption.value },
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                targetOption.children = response.data.list.map((ad: AdData) => {
                  return {
                    value: ad.adPageId,
                    label: ad.adPageName,
                    isLeaf: ad.childCount === 0,
                  };
                });
                targetOption.loading = false;
                setOptions([...options]);
              }
            }
          })
          .catch((err) => console.log(err));
      }, EXTENDED_TIMEOUT);
    }
  };

  const renderAdLinkType = () => {
    switch (linkType.key) {
      case 'CATEGORY':
        return (
          <CategoryDropdown
            categoryOptions={categories}
            onChange={(value) => {
              if (value && value.length) {
                setLink(linkType.value + value.at(-1));
              }
            }}
          />
        );
      case 'BRAND':
        return (
          <BrandDropdown
            onChange={(value) => {
              setLink(linkType.value + value);
            }}
          />
        );
      case 'TOPIC':
        return (
          <Cascader
            style={{ width: '100%' }}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            options={options}
            loadData={loadData}
            placeholder={t('general.pleaseSelect')}
            changeOnSelect
            displayRender={(label) => label.join(' > ')}
            onChange={(value) => {
              if (value && value.length) {
                setLink(linkType.value + value.at(-1));
              }
            }}
            onFocus={() => {
              if (!options.length) getAdPages();
            }}
          />
        );
      case 'ARTICLE':
        return (
          <Select
            style={{ width: '100%' }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onPopupScroll={articleFetchOnScroll}
            showSearch
            allowClear
            onSearch={(value) => {
              setSearchText((prev) => ({ ...prev, article: value }));
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => getArticles(value), EXTENDED_TIMEOUT)
              );
            }}
            onFocus={() => {
              if (!articles.length) getArticles();
            }}
            filterOption={false}
            placeholder={t('searchPlaceholders.searchArticle')}
            onChange={(value) => {
              setLink(linkType.value + value);
            }}
          >
            {articles.map((article) => (
              <Select.Option key={article.articleId} value={article.articleId}>
                <Space direction="vertical" size={0}>
                  <Typography.Text>{article.title}</Typography.Text>
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {article.briefDesc}
                  </Typography.Text>
                </Space>
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return <Input defaultValue={linkType.value} readOnly />;
    }
  };

  return (
    <Modal
      title={linkType ? linkType.children : ''}
      visible={visible}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t('goods.add/editBrand.ok')}
      cancelText={t('goods.add/editBrand.cancel')}
      destroyOnClose
    >
      {visible && renderAdLinkType()}
    </Modal>
  );
};

export default AdLinkTypeModal;
