import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getDashboardStyle from '../utils/getDashboardStyle';

// @param string title Title to be added into document
const useDocumentTitle = (title: string) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t(title)} - ${t(getDashboardStyle().title.toString())}`;
  }, [title, t]);
};

export { useDocumentTitle };
