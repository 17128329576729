import Select, { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { AdminListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { Typography } from 'antd';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';

type AdminDropdownProps = {
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const AdminDropdown = ({ disabled = false, onChange }: AdminDropdownProps) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [adminList, setAdminList] = useState<Array<AdminListData>>([]);
  const [currentPageAdminID, setCurrentPageAdminID] = useState(2);
  const [totalPageAdminID, setTotalPageAdminID] = useState(2);
  /**
   * Sends a request and returns the AdminData based on the typed keywords
   */
  const getAdminData = (value?: string) => {
    getDataWithAuthToken('admin/list', {
      params: {
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setAdminList(response.data.list);
          setCurrentPageAdminID(2);
          setTotalPageAdminID(response.data.totalPage);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Makes a new request to get new list of admins
   * When the user scrolls to the bottom of the dropdown box
   */
  const fetchOnScrollAdminID = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (currentPageAdminID > totalPageAdminID) return;
      getDataWithAuthToken('admin/list', {
        params: {
          page: currentPageAdminID,
          keyword: searchText.adminId || undefined,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setAdminList((prev) => [...prev, ...response.data.list]);
          setCurrentPageAdminID((prev) => prev + 1);
          setTotalPageAdminID(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={
        !hasPermission(actionPermissions.adminGroup.adminManage) || disabled
      }
      placeholder={t('searchPlaceholders.searchUsernameEmail')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={fetchOnScrollAdminID}
      onFocus={() => {
        if (!adminList.length) getAdminData();
      }}
      showSearch
      allowClear
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, adminId: '' }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getAdminData(value), EXTENDED_TIMEOUT)
        );
      }}
      onChange={handleOnChange}
      optionLabelProp="key"
      filterOption={false}
    >
      {adminList.map((admin) => (
        <Select.Option
          key={`${admin.adminUserName} (${admin.adminId})`}
          value={String(admin.adminId)}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('users.userLogsColumns.advancedSearch.adminId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('users.userLogsColumns.advancedSearch.username')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{admin.adminId}</Typography>
              <Typography>{admin.adminUserName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AdminDropdown;
