export const actionPermissions = {
  userGroup: {
    userView: 'users_view', //User list, enum List and User Detail
    userManage: 'users_manage', //User Add/Edit
    userDrop: 'users_drop', //Archiving and Deleting users
    userLog: 'user_log', //View, Batch Delete User Logs List, Change Type List
    userExport: 'export_user_list', //Export User List
    userRank: 'user_rank', //User Rank List, User Rank All, Special Comment, Add/edit/delete
    userDevice: 'user_device', //User Device List and Batch delete devices
    userAddress: 'user_address', //User Address List, add/edit/batch delete
    userRealManage: 'users_real_manage', //User ID List/Detail/list link, enum list, edit/batch delete/sent ID Application
    surplusManage: 'surplus_manage', // Adjust Account in Account Logs
    accountManage: 'account_manage', //account log list, account types, add/export
  },
  salespersonGroup: {
    salespersonView: 'sales_person_view',
    salespersonManage: 'sales_person_manage',
  },
  adminGroup: {
    adminManage: 'admin_manage', //Admin List, admin action list, add/edit/
    adminDrop: 'admin_drop', //Admin delete
    adminLogsManage: 'logs_manage', //Admin Log List
    adminRoleManage: 'role_manage', //Admin Role List, add/edit/delete
    logsDrop: 'logs_drop', //Batch delete admin logs
  },
  goodGroup: {
    goodView: 'goods_view', //Enum list, goods list, goods detail, export excel, inventory list, inventory status list, goods log
    goodManage: 'goods_manage', //add/edit goods + batch:(clear extend cat + add extend cat + event + promote + purchase limit + sale status + department + supplier goods tag + review + gst + pst) + sync related goods + batch delete goods log
    relatedGoods: 'related_goods_manage', //Mismatch list, Sync
    goodDropRestore: 'remove_back', //Delete/Batch delete/Trash/Batch restore
    shopConfig: 'shop_config', // Goods Settings
    categoryView: 'cat_view', //Category List/All
    categoryManage: 'cat_manage', //Category Add/Edit/Export
    catDrop: 'cat_drop', //Transfer/Delete Category
    brandView: 'brand_view', //Brand List
    brandManage: 'brand_manage', //Add/Edit/Delete Brands
    departmentView: 'department_view', //Department List
    departmentManage: 'department_manage', //Department List/Add/Edit/Delete
    goodComments: 'comment_priv', //Good Comments List, Add/Edit/Delete
    goodsTypeView: 'goods_type_view', //Goods Spec Type List
    goodsTypeManage: 'goods_type', //Add/Edit/Delete Goods Spec Type
    goodsAttrView: 'attr_view', //Goods Attr List
    attrManage: 'attr_manage', //Add/Edit?Batch Delete Goods Attr
    suppliersManage: 'suppliers_manage', //Supplier List, Add/Edit/Delete
    goodLib: 'goods_lib', // Main Goods List and Import Batch
  },
  orderGroup: {
    orderView: 'order_view', //enum list, route list, order list, main count, order detail, order goods list, order driver
    orderManage: 'order_edit', //order preview, order preview goods, order preview out of stock, order preview return, order add/edit
    orderClaimView: 'claim_view', //Order Claim List, export, detail page
    claimManage: 'claim_manage', //Order review/retry
    orderBatchConfirm: 'order_batch_confirm', //Batch Order Confirm
    orderPrint: 'order_print', //Order Print
    orderPrintExpress: 'order_print_express', //Order Print Express
    orderRemark: 'order_remark', // Order Remark
    orderRefund: 'order_refund', //Order Refund
    orderOutOfStock: 'order_out_of_stock', //Order Out of Stock
    orderReturn: 'order_return', //Order Return
    orderCreditMemo: 'order_credit_memo', // Order Credit Memo
  },
  activityGroup: {
    couponManage: 'coupons_manage', //Coupon List, enum list, add/edit/delete coupon, coupon user list, add/batch delete coupon user
    favourable: 'favourable', //Fav Activity List, enum list, add/edit/delete
    valueCardView: 'value_card_view', //Value Card Type List
    valueCard: 'value_card', //add/edit/delete value card types
  },
  advertisementGroup: {
    homeAd: 'touch_dashboard', //Home Ad List/Detail, enum list, add/edit/delete
    articleManage: 'article_manage', //Article List, enum list, add/edit/delete
    articleCat: 'article_cat', //Article Cat List, enum list, add/edit/delete
    adView: 'ad_view', //Ad View
    adManage: 'ad_manage', //Ad Manage
  },
  sellerGroup: {
    sellerMerchants: 'users_merchants', //Seller Settings/List/Detail/Business Type, add/edit, Seller Action list
    sellerPrivateMerchants: 'users_merchants_priv', //Seller admin user list, add/edit/delete admin user
    sellerMerchantDrop: 'users_merchants_drop', //Delete Seller
    sellerGrade: 'seller_grade', //Seller Grade list, add/edit/delete seller grade
  },
  sortationGroup: {
    employeeView: 'employee_view', //Employee and Enum List
    employeeManage: 'employee_manage', //Add/edit/delete employee
    employeeLog: 'employee_log', //Employee log list, batch delete
    taskView: 'task_view', //Employee task list
    taskManage: 'task_manage', //Add/edit/delete task
  },
  analysisGroup: {
    //HOME analysis REQUIRES ALL 4 BELOW PERMISSIONS
    //HOME period report REQUIRES: ORDER + SALES analysis
    homeOrder: 'index_order_stats', //Home Analysis: Order Section
    homeGoods: 'index_goods_view', //Home Analysis: Goods Section
    homeUser: 'index_member_info', //Home Analysis: User Section
    homeSales: 'index_sales_stats', //Home Analysis: Sales Section
    userStat: 'user_stat', //User Analysis, new user analysis, order rank user analysis
    goodStat: 'goods_stat', //Goods Analysis, goods detail analysis, goods distri
    orderStat: 'order_stat', //Order analysis, order detail analysis, order distri, order claim analysis, order claim detail, order claim distribution
    salesStat: 'sales_stat', //Sales Goods, category, department, goods export
    employeeStat: 'employee_stat', //Employee Tasks, Employee Task Goods
    taskStat: 'task_stat', //Tasks, Task Goods
  },
  pluginGroup: {
    ossConfig: 'oss_configure', //S3 List, S3 region list, add/edit/delete S3
    shopConfig: 'shop_config', //All Settings Page
    smsLog: 'sms_log', //SMS Log list, Settings, enum list, batch delete
    smsSend: 'sms_send', //SMS Send
    smsTemp: 'sms_setting', //SMS Temp list, enum list, add/edit/delete SMS Temp
  },
  settingGroup: {
    basicSetting: 'shop_config', //Basic Settings
    regionSetting: 'area_list', //Region List, Region All, add/edit/delete
    galleryAlbum: 'gallery_album', //Photo Gallery, add/edit/delete, list/upload/transfer/batch delete pic
    fileManager: 'file_manager', //File Manager, batch delete, status
    pickupView: 'shiparea_manage', //Pickup List, add/edit/delete
    shipView: 'ship_view', //Shipping/Trans/TPL List, detail, tpl config
    shipManage: 'ship_manage', //Shipping/Trans/TPL add/Edit/delete,
    openAPI: 'api', //OpenAPI
    paymentSettings: 'payment_view', //Payment List, enabled list, config
    paymentConfig: 'payment_config', //Add/edit/delete payment
    formFieldConfig: 'form_field_config', //Form Field list, enum list, blank list, add/edit/delete
  },
  cacheGroup: {
    cacheManage: 'cache_manage', //User Dropdown Cache
  },
  generalGroup: {
    allotPriv: 'allot_priv',
    usersMerchantsPriv: 'users_merchants_priv',
  },
};
