import { Form, Input, Modal, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DepartmentData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';

type DepartmentModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  department?: DepartmentData;
  refresh?: () => void;
};

const DepartmentModal = ({
  visible,
  setVisible,
  department,
  refresh,
}: DepartmentModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
    }
  }, [form, visible, department]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(
          department ? 'goods/department/edit' : 'goods/department/add',
          {
            ...values,
            departmentId: department ? department.departmentId : undefined,
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  department
                    ? t('goods.alerts.departmentEdited')
                    : t('goods.alerts.departmentAdded')
                );
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  return (
    <Modal
      title={
        department
          ? `${t('goods.add/editDepartment.editTitle')} ${
              department.departmentName
            }`
          : t('goods.add/editDepartment.addTitle')
      }
      visible={visible}
      okText={t('goods.add/editDepartment.ok')}
      cancelText={t('goods.add/editDepartment.cancel')}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={department ? { ...department } : {}}
        >
          <Form.Item
            name="departmentName"
            label={t('goods.add/editDepartment.departmentName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="remark"
            label={t('goods.add/editDepartment.remark')}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DepartmentModal;
