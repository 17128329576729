import React, { useEffect, useRef } from 'react';
import { Col, Form, Row, Typography } from 'antd';
import { OrderGood } from '../../../types';
import { useTranslation } from 'react-i18next';

type ClaimOrderGoodsProps = {
  orderGood: OrderGood;
};

/**
 * Displays Claim Order Goods Popover
 *
 */
const ClaimOrderGoods = ({ orderGood }: ClaimOrderGoodsProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Form layout="vertical">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {
            <Typography.Text
              style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}
            >
              {t('order.orderGoodsListColumns.title')}
            </Typography.Text>
          }
        </Col>

        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.goodsSn')}: `}
              </div>
            }
          >
            <div
              style={{
                wordBreak: 'break-word',
              }}
            >
              {orderGood ? orderGood.goodsSn : ''}
            </div>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.goodsFullName')}: `}
              </div>
            }
          >
            <div
              style={{
                wordBreak: 'break-word',
              }}
            >
              {`${orderGood ? orderGood.goodsName : ''} ${
                orderGood ? orderGood.goodsUnit : ''
              }`}
            </div>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.goodsNumber')}: `}
              </div>
            }
          >
            <div
              style={{
                wordBreak: 'break-word',
              }}
            >
              {orderGood ? orderGood.goodsNumber : ''}
            </div>
          </Form.Item>
        </Col>
        {orderGood && orderGood.goodsAttrName && (
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={
                <div
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${t('order.orderGoodsListColumns.goodsAttrName')}: `}
                </div>
              }
            >
              <div
                style={{
                  wordBreak: 'break-word',
                }}
              >
                {orderGood.goodsAttrName}
              </div>
            </Form.Item>
          </Col>
        )}
        <Col span={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.sendNumber')}: `}
              </div>
            }
          >
            <div
              style={{
                wordBreak: 'break-word',
              }}
            >
              {orderGood ? orderGood.sendNumber : ''}
            </div>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.goodsPrice')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.goodsPrice : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.totalPrice')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.totalPrice : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.gst')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.gst : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.pst')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.pst : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.depositFee')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.depositFee : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={18}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.recycleFee')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.recycleFee : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.mainGoodsPrice')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.mainGoodsPrice : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.totalMainPrice')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.totalMainPrice : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.mainGst')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.mainGst : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.mainPst')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.mainPst : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.mainDepositFee')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.mainDepositFee : ''}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={18}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {`${t('order.orderGoodsListColumns.mainRecycleFee')}: `}
              </div>
            }
          >
            {orderGood ? orderGood.mainRecycleFee : ''}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClaimOrderGoods;
