import { Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdEnum, GoodData } from '../../../types';
import SelectGoodsModal from '../../goods/selectGoods/SelectGoodsModal';
import AdLinkTypeModal from '../AdLinkTypeModal';

type AdLinkTypeDropdownProps = {
  setAdLinkType: React.Dispatch<React.SetStateAction<string>>;
  sellerId?: number;
  adEnum?: AdEnum;
};

const AdLinkTypeDropdown = ({
  setAdLinkType,
  adEnum,
  sellerId,
}: AdLinkTypeDropdownProps) => {
  //General components
  const { t } = useTranslation();
  const [goodsVisible, setGoodsVisible] = useState<boolean>(false);
  const [selectedGood, setSelectedGood] = useState<GoodData>();
  const [adLinkTypeVisible, setAdLinkTypeVisible] = useState<boolean>(false);
  const [currentLinkType, setCurrentLinkType] = useState<any>({});

  return (
    <>
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        placeholder={t('general.pleaseSelect')}
        style={{ width: '100%' }}
        onSelect={(value: string, option: any) => {
          setCurrentLinkType(option);

          switch (option.key) {
            case 'TOPIC':
            case 'CATEGORY':
            case 'BRAND':
            case 'ARTICLE':
              setAdLinkTypeVisible(true);
              break;
            case 'GOODS':
              setGoodsVisible(true);
              break;
            default:
              setAdLinkType(option.value);
              break;
          }
        }}
      >
        {adEnum &&
          adEnum.adLinkType &&
          adEnum.adLinkType.map((type) => (
            <Select.Option key={type.code} value={type.data}>
              {type.description}
            </Select.Option>
          ))}
      </Select>

      <AdLinkTypeModal
        visible={adLinkTypeVisible}
        setVisible={setAdLinkTypeVisible}
        linkType={currentLinkType}
        callBack={(value) => {
          setAdLinkType(value);
        }}
      />

      <SelectGoodsModal
        visible={goodsVisible}
        setVisible={setGoodsVisible}
        newGoodObjs={selectedGood}
        setNewGoodObjs={setSelectedGood}
        multiSelectFeature={false}
        firstLoad={true}
        sellerId={sellerId ? sellerId : 0}
        callback={(selectedGoodObj) => {
          if (selectedGoodObj && selectedGoodObj.length > 0) {
            // get single selected good
            const good = selectedGoodObj[0];
            setAdLinkType(`${currentLinkType.value}${good.goodsId}`);
          }
        }}
      />
    </>
  );
};

export default AdLinkTypeDropdown;
