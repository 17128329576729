import {
  Button,
  Col,
  Empty,
  Grid,
  Image,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { GoodsInfo, OrderData, OrderEnum, OrderGood } from '../../../types';
import { FALLBACK_IMG } from '../../../constants/styles';
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import OrderActionModal from './OrderActionModal';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import SelectGoodsModal from '../../goods/selectGoods/SelectGoodsModal';
import { ORDER_BUTTON } from '../../../constants/orderConstants';
import { hasPermission } from '../../../utils/hasPermission';
import ClaimGoodsList from '../orderClaimDetail/ClaimGoodsList';
import { actionPermissions } from '../../../constants/actionPermissions';
import { RED1 } from '../../../constants/color';
import { DEFAULT_FONT_SIZE } from '../../../constants/systemConstants';
import getDashboardStyle from '../../../utils/getDashboardStyle';

type OrderGoodsProps = {
  orderInfo?: OrderData;
  orderGoodsData?: OrderGood[];
  callBack?: () => void;
  isLoading?: boolean;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  isRegionSale?: boolean;
  enums?: OrderEnum;
};
const OrderGoods = ({
  orderInfo,
  callBack,
  isLoading,
  orderGoodsData,
  isCurrentEditing,
  setIsCurrentEditing,
  isRegionSale,
  enums,
}: OrderGoodsProps) => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<any>();
  const [newOrderGoodsData, setNewOrderGoodsData] = useState<{
    orderGoodsList: any[];
    orderGoodsListTotal?: string | number;
  }>();
  const [selectGoodModalVisible, setSelectGoodModalVisible] =
    useState<boolean>(false);
  const { addTab } = useTab();
  const [validationObject, setValidationObject] = useState<OrderGood>();
  const [selectedRows, setSelectedRows] = useState<OrderGood[]>([]);
  const [action, setAction] = useState<string>('');
  const [claimAmount, setClaimAmount] = useState<string>('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  /**
   * When the changes have been made in the SelectedGoodObjs, it will update newOrderGoodsData. The user only sees newOrderGoodsData.
   * NOTE: The SelectedGoodsObjs is NOT GoodData TYPE, it is CUSTOM TYPE that is why the type is ANY
   * NOTE: No additional changes were made here because the selectedGoodObjs will always be the most updated value since it is being filtered in SelectGoodAtterModal
   */
  useEffect(() => {
    if (selectedGoodObjs) {
      setNewOrderGoodsData(selectedGoodObjs);
      setSelectedGoodObjs(undefined);
    }
  }, [selectedGoodObjs]);

  useEffect(() => {
    if (orderInfo) {
      setNewOrderGoodsData({
        orderGoodsList: orderInfo.orderGoodsList,
        orderGoodsListTotal: orderInfo.orderAmount
          ? orderInfo.orderAmount.goodsAmount
          : '0.00',
      });
    }
  }, [orderInfo]);

  const calculateOrderGoodsTotal = (orderGoodsList: any[]) => {
    setLoading(true);
    postDataWithAuthToken('order/preview_goods', {
      userId: orderInfo && orderInfo.user ? orderInfo.user.userId : undefined,
      sellerId:
        orderInfo && orderInfo.seller ? orderInfo.seller.sellerId : undefined,
      orderId: orderInfo ? orderInfo.orderId : undefined,
      orderGoodsList: orderGoodsList.map((good: any) => ({
        goodsId: good.goodsId,
        goodsName: good.goodsName,
        goodsNumber: good.goodsNumber,
        goodsSkuAttrIds:
          (good.goodsAttrId && good.goodsAttrId.split(',')) || [],
        goodsPrice: good.goodsPrice,
        recId: good.recId || undefined,
        mainGoodsPrice: isRegionSale ? good.mainGoodsPrice : undefined,
        productId: good.productId || undefined,
        goodsWeight: good.goodsWeight || undefined,
      })),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setNewOrderGoodsData((prev) => ({
            ...prev,
            orderGoodsList: response.data.orderGoodsList,
            orderGoodsListTotal: response.data.goodsAmount,
          }));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateGoodsList = (
    key: string,
    value: string | number,
    index: number
  ) => {
    let newList =
      (newOrderGoodsData &&
        JSON.parse(JSON.stringify(newOrderGoodsData.orderGoodsList))) ||
      [];
    newList[pageSize * (page - 1) + index][key] = value;

    setNewOrderGoodsData((prev) => ({
      ...prev,
      orderGoodsList: newList,
    }));
    if (
      key === 'goodsNumber' ||
      key === 'goodsPrice' ||
      key === 'mainGoodsPrice' ||
      key === 'goodsWeight'
    ) {
      calculateOrderGoodsTotal(newList);
    }
  };

  const handleOutOfStock = () => {
    setLoading(true);
    postDataWithAuthToken('order/preview_out_of_stock', {
      remark: `System: ${t('order.orderDetail.buttonList.outOfStockEnabled')}`,
      operate: ORDER_BUTTON.outOfStockEnabled,
      orderId:
        selectedRows && selectedRows.length
          ? selectedRows[0].orderId
          : undefined,
      claimGoodsList:
        selectedRows && selectedRows.length
          ? selectedRows.map((good) => ({ recId: good.recId }))
          : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setLoading(false);
          setClaimAmount(response.data);
          setAction('outOfStockEnabled');
          setShowModal(true);
        } else {
          setLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleOnEdit = () => {
    let hasNewGood = false;
    if (editing) {
      setLoading(true);
      postDataWithAuthToken('order/edit', {
        orderId: orderInfo && orderInfo.orderId,
        shippingFee:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.shippingFee) ||
          undefined,
        payFee:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.payFee) ||
          undefined,
        tipsFee:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.tipsFee) ||
          undefined,
        discountAmount:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.discountAmount) ||
          undefined,
        couponsAmount:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.couponsAmount) ||
          undefined,
        bonusAmount:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.bonusAmount) ||
          undefined,
        integral:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.integral) ||
          undefined,
        surplusAmount:
          (orderInfo &&
            orderInfo.orderAmount &&
            orderInfo.orderAmount.surplusAmount) ||
          undefined,
        orderGoodsList:
          newOrderGoodsData && newOrderGoodsData.orderGoodsList
            ? newOrderGoodsData.orderGoodsList.map((good: any) => {
                if (!good.recId) {
                  hasNewGood = true;
                }
                return {
                  recId: good.recId || undefined,
                  goodsId: good.goodsId,
                  goodsNumber: good.goodsNumber,
                  goodsName: good.goodsName,
                  goodsPrice: good.goodsPrice,
                  goodsSkuAttrIds:
                    (good.goodsAttrId && good.goodsAttrId.split(',')) ||
                    undefined,
                  productId: good.productId || undefined,
                  mainGoodsPrice: isRegionSale
                    ? good.mainGoodsPrice
                    : undefined,
                  goodsWeight: good.goodsWeight,
                };
              })
            : undefined,
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setLoading(false);
            alertMessage('success', t('order.alerts.orderEdited'));
            Modal.warning({
              title: hasNewGood
                ? t('order.orderDetail.buttonList.addTaskEnabled')
                : t('order.orderDetail.buttonList.batchUpdateSpecialTgEnabled'),
              content: `${
                hasNewGood
                  ? t('order.orderDetail.alerts.newGood', {
                      button: t('order.orderDetail.buttonList.addTaskEnabled'),
                    })
                  : ''
              } ${t('order.orderDetail.alerts.specialTask', {
                button: t(
                  'order.orderDetail.buttonList.batchUpdateSpecialTgEnabled'
                ),
              })}`,
              okText: t('general.ok'),
              maskClosable: false,
            });
            handleOnCancel();
            if (callBack) callBack();
          } else {
            setLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setEditing(true);
        if (setIsCurrentEditing) setIsCurrentEditing(true);
      }
    }
  };

  const handleOnCancel = () => {
    if (orderInfo) {
      setNewOrderGoodsData({
        orderGoodsList: orderInfo.orderGoodsList,
        orderGoodsListTotal: orderInfo.orderAmount
          ? orderInfo.orderAmount.goodsAmount
          : '0.00',
      });
    }
    setPage(1);
    setSelectedRows([]);
    setClaimAmount('');
    setEditing(false);
    if (setIsCurrentEditing) setIsCurrentEditing(false);
  };

  const columns: ColumnsType<OrderGood> = [
    {
      title: t('order.orderDetail.goodsImage'),
      width: 100,
      dataIndex: 'goods',
      render: (good: GoodsInfo) =>
        good && (
          <Image
            src={good.largePic}
            fallback={FALLBACK_IMG}
            width={50}
            preview={{ mask: <EyeOutlined /> }}
          />
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsName'),
      width: 160,
      dataIndex: 'goodsName',
      render: (value, record, index) =>
        editing ? (
          <Input
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateGoodsList('goodsName', target.value, index);
            }}
          />
        ) : (
          <Space>
            {record.giftSpecial && (
              <Tag color={record.giftSpecial.tagColor}>
                {record.giftSpecial.description}
              </Tag>
            )}
            <Typography.Text
              style={{
                padding: 0,
                textAlign: 'left',
                whiteSpace: 'normal',
                height: 'auto',
                cursor: getDashboardStyle().isSalesForceAppType
                  ? 'auto'
                  : 'pointer',
              }}
              disabled={
                getDashboardStyle().isSalesForceAppType ||
                !hasPermission(actionPermissions.goodGroup.goodView)
              }
              onClick={() => {
                addTab(
                  '',
                  `${dashboardRoute.goods.detail}?good_id=${
                    record.goods ? record.goods.goodsId : ''
                  }`
                );
              }}
            >
              {value}
            </Typography.Text>
          </Space>
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsUnit'),
      width: 100,
      dataIndex: 'goodsUnit',
    },
    {
      title: t('order.orderDetail.goodsAttrName'),
      width: 100,
      dataIndex: 'goodsAttrName',
    },
    {
      title: t('order.orderDetail.amount'),
      width: 100,
      dataIndex: 'goodsNumber',
      render: (value, record, index: number) =>
        editing ? (
          <InputNumber
            type="number"
            min={0}
            precision={0}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateGoodsList('goodsNumber', target.value, index);
            }}
          />
        ) : (
          <Typography>{record.goodsNumber}</Typography>
        ),
    },
    {
      title: t('goods.goodsListColumns.shopPrice'),
      width: 100,
      dataIndex: 'goodsPrice',
      render: (value, record, index: number) =>
        !getDashboardStyle().isSalesForceAppType && editing ? (
          <InputNumber
            type="number"
            min={0}
            precision={2}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateGoodsList('goodsPrice', target.value, index);
            }}
          />
        ) : (
          <Typography>{record.goodsPrice}</Typography>
        ),
    },
    {
      title: t('order.orderGoodsListColumns.goodsPriceBeforeDiscount'),
      width: 100,
      dataIndex: 'goodsPriceBeforeDiscount',
    },
    {
      title: t('goods.goodsListColumns.goodsWeight'),
      width: 120,
      dataIndex: 'goodsWeight',
      render: (value, record, index: number) =>
        editing && record.isWeightGoods ? (
          <div>
            <InputNumber
              type="number"
              min={0}
              defaultValue={value}
              onBlur={(e) => {
                let target = e.target as HTMLInputElement;
                if (record.isWeightGoods && Number(target.value) <= 0) {
                  setValidationObject(record);
                } else {
                  updateGoodsList('goodsWeight', target.value, index);
                  setValidationObject(undefined);
                }
              }}
            />
            {validationObject && validationObject.recId === record.recId && (
              <div style={{ fontSize: DEFAULT_FONT_SIZE, color: RED1 }}>
                {t('goods.alerts.missingWeightWarning')}
              </div>
            )}
          </div>
        ) : (
          <Typography>{record.goodsWeight}</Typography>
        ),
    },
    {
      title: t('order.orderGoodsListColumns.mainGoodsPrice'),
      width: 100,
      dataIndex: 'mainGoodsPrice',
      key: 'mainGoodsPrice',
      render: (value, record, index: number) =>
        !getDashboardStyle().isSellersCoAppType && editing ? (
          <InputNumber
            type="number"
            min={0}
            precision={2}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateGoodsList('mainGoodsPrice', target.value, index);
            }}
          />
        ) : (
          <Typography>{record.mainGoodsPrice}</Typography>
        ),
    },
    {
      title: t('order.orderGoodsListColumns.settlement'),
      width: 100,
      dataIndex: 'settlePercentValue',
      key: 'settlePercentValue',
      render: (value, record) =>
        isRegionSale ? <Typography>{`${value}%`}</Typography> : <></>,
    },
    {
      title: t('order.orderGoodsListColumns.feesInfo'),
      width: 180,
      render: (record: OrderGood) => (
        <div
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>{`${t('order.orderGoodsListColumns.gst')}:`}</div>
            <div>{record.gst}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>{`${t('order.orderGoodsListColumns.pst')}:`}</div>
            <div>{record.pst}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>{`${t('order.orderGoodsListColumns.depositFee')}: `}</div>

            <div>{record.depositFee}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>{`${t('order.orderGoodsListColumns.recycleFee')}: `}</div>

            <div>{record.recycleFee}</div>
          </div>
        </div>
      ),
    },
    {
      title: t('order.orderDetail.sendNumber'),
      width: 80,
      dataIndex: 'sendNumber',
    },
    {
      title: t('order.orderDetail.claimAmount'),
      width: 100,
      render: (record: OrderGood) =>
        record.claimGoodsList &&
        record.claimGoodsList.length > 0 && (
          <Popover
            mouseEnterDelay={0.5}
            overlayInnerStyle={{
              width: screens.lg ? 800 : screens.md ? 'auto' : undefined,
            }}
            // getPopupContainer={(triggerNode) =>
            //   triggerNode.parentNode as HTMLElement
            // }
            placement="rightTop"
            content={
              <ClaimGoodsList
                claimGoodsList={record.claimGoodsList}
                loading={false}
                colsToShow={[
                  'claimId',
                  'claimNumber',
                  'claimAmount',
                  'claimReason',
                ]}
                inSeparateWindow={true}
              />
            }
          >
            <div style={{ color: RED1, cursor: 'default' }}>
              {record.claimAmount}
            </div>
          </Popover>
        ),
    },
    {
      title: t('order.orderDetail.subTotal'),
      width: 150,
      render: (record: OrderGood) => (
        <Space>
          <Typography>{record.totalPrice}</Typography>
          {parseFloat(record.totalDiscountAmount) > 0 && (
            <Typography.Text style={{ color: RED1 }}>{`(${t(
              'order.orderGoodsListColumns.totalDiscountAmount'
            )} ${record.totalDiscountAmount})`}</Typography.Text>
          )}
        </Space>
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsSn'),
      width: 120,
      dataIndex: 'goodsSn',
    },

    {
      title: t('order.orderDetail.action'),
      width: 100,
      render: (text, record, index) => (
        <Button
          type="link"
          style={{ padding: 0, color: RED1 }}
          onClick={() => {
            let newList =
              (newOrderGoodsData &&
                newOrderGoodsData.orderGoodsList &&
                newOrderGoodsData.orderGoodsList.filter(
                  (good, i) => i !== pageSize * (page - 1) + index
                )) ||
              [];
            if (newList.length) {
              calculateOrderGoodsTotal(newList);
            } else {
              setNewOrderGoodsData((prev) => ({
                ...prev,
                orderGoodsList: [],
                orderGoodsListTotal: '0.00',
              }));
            }
          }}
        >
          {t('goods.add/editGood.delete')}
        </Button>
      ),
    },
  ];

  return orderGoodsData || (orderInfo && orderInfo.orderGoodsList) ? (
    <Spin spinning={loading}>
      <Row gutter={[16, 24]}>
        {orderInfo && orderInfo.orderGoodsList && (
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.orderDetail.orderGoods')}
            </Typography.Text>
            {editing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage) ||
                    validationObject !== undefined
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
        )}

        {editing && (
          <Col span={24}>
            <Space>
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectGoodModalVisible(true);
                }}
              >
                {t('goods.add/editGood.addTitle')}
              </Button>
              {orderInfo &&
                orderInfo.buttonList &&
                orderInfo.buttonList.outOfStockEnabled &&
                selectedRows &&
                !!selectedRows.length && (
                  <Button
                    disabled={
                      !hasPermission(
                        actionPermissions.orderGroup.orderOutOfStock
                      )
                    }
                    type="primary"
                    size="small"
                    onClick={handleOutOfStock}
                  >
                    {t('order.orderDetail.buttonList.outOfStockEnabled')}
                  </Button>
                )}
              {orderInfo &&
                orderInfo.buttonList &&
                orderInfo.buttonList.returnEnabled &&
                selectedRows &&
                !!selectedRows.length && (
                  <Button
                    disabled={
                      !hasPermission(actionPermissions.orderGroup.orderReturn)
                    }
                    type="primary"
                    size="small"
                    onClick={() => {
                      setAction('returnEnabled');
                      setShowModal(true);
                    }}
                  >
                    {t('order.orderDetail.buttonList.returnEnabled')}
                  </Button>
                )}
              {orderInfo &&
                orderInfo.buttonList &&
                orderInfo.buttonList.creditMemoEnabled &&
                selectedRows &&
                !!selectedRows.length &&
                getDashboardStyle().isWholeSaleAppType && (
                  <Button
                    disabled={
                      !hasPermission(
                        actionPermissions.orderGroup.orderCreditMemo
                      )
                    }
                    type="primary"
                    size="small"
                    onClick={() => {
                      setAction('creditMemoEnabled');
                      setShowModal(true);
                    }}
                  >
                    {t('order.orderDetail.buttonList.creditMemoEnabled')}
                  </Button>
                )}
            </Space>
          </Col>
        )}

        <Col span={24}>
          <Table
            // style={{ zIndex: 99999 }}
            size="small"
            dataSource={
              orderInfo
                ? newOrderGoodsData
                  ? newOrderGoodsData.orderGoodsList
                  : orderInfo.orderGoodsList
                : orderGoodsData
            }
            columns={
              orderGoodsData || !editing
                ? isRegionSale
                  ? columns.slice(0, columns.length - 1)
                  : columns
                      .filter((col) => col.key !== 'mainGoodsPrice')
                      .slice(
                        0,
                        columns.filter((col) => col.key !== 'mainGoodsPrice')
                          .length - 1
                      )
                : isRegionSale
                ? columns
                : columns.filter((col) => col.key !== 'mainGoodsPrice')
            }
            scroll={{ y: 600, x: 1200 }}
            rowKey={(record, index) =>
              record.recId ? record.recId : `${index}|${record.goodsId}`
            }
            pagination={{
              pageSize: pageSize,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              showSizeChanger: true,
              onChange: (p, pSize) => {
                setPage(p);
                setPageSize(pSize);
              },
              current: page,
            }}
            loading={isLoading}
            footer={() =>
              orderInfo && (
                <div style={{ textAlign: 'right' }}>
                  <Typography.Text>
                    {`${t('order.orderDetail.total')}: `}
                  </Typography.Text>
                  <Typography.Text type="danger" strong>
                    {newOrderGoodsData
                      ? newOrderGoodsData.orderGoodsListTotal
                      : orderInfo && orderInfo.orderAmount
                      ? orderInfo.orderAmount.goodsAmount
                      : '0.00'}
                  </Typography.Text>
                </div>
              )
            }
            rowSelection={
              !getDashboardStyle().isSalesForceAppType && editing
                ? {
                    onChange: (
                      selectedRowKeys: React.Key[],
                      selectedRows: OrderGood[]
                    ) => {
                      setSelectedRows(selectedRows);
                    },
                    getCheckboxProps: (record: OrderGood) => ({
                      disabled: !record.recId,
                    }),
                  }
                : undefined
            }
          />
        </Col>
        <OrderActionModal
          visible={showModal}
          setVisible={setShowModal}
          action={action}
          claimAmount={claimAmount}
          orderInfo={orderInfo}
          orderGoods={selectedRows}
          callBack={() => {
            handleOnCancel();
            if (callBack) callBack();
          }}
          enums={enums}
        />
        {selectGoodModalVisible && (
          <SelectGoodsModal
            firstLoad={false}
            visible={selectGoodModalVisible}
            setVisible={setSelectGoodModalVisible}
            userId={(orderInfo && orderInfo.user && orderInfo.user.userId) || 0}
            sellerId={
              (orderInfo && orderInfo.seller && orderInfo.seller.sellerId) || 0
            }
            orderId={orderInfo ? orderInfo.orderId : undefined}
            newGoodObjs={newOrderGoodsData?.orderGoodsList}
            setNewGoodObjs={setSelectedGoodObjs}
            multiSelectFeature={false}
            shippingTransportId={
              (orderInfo &&
                orderInfo.orderShipping &&
                orderInfo.orderShipping.transportId) ||
              0
            }
          />
        )}
      </Row>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.orderGoods')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderGoods;
