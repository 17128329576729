import { Button, Col, Form, Row, Tag, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { OrderClaimData } from '../../../types';
import { hasPermission } from '../../../utils/hasPermission';

type ClaimOrderInfoProps = {
  orderClaimDetail: OrderClaimData;
};

/**
 * Displays Order Information
 *
 */
const ClaimOrderInfo = ({ orderClaimDetail }: ClaimOrderInfoProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { addTab } = useTab();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Form layout="vertical">
      <Row gutter={[16, 24]}>
        <Col span={24}>
          {
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.claimListColumns.orderInfoTitle')}
            </Typography.Text>
          }
        </Col>
        {orderClaimDetail.orderInfo && (
          <>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.orderId')}: `}
                >
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    disabled={
                      !hasPermission(actionPermissions.orderGroup.orderView)
                    }
                    onClick={() => {
                      addTab(
                        '',
                        `${dashboardRoute.order.detail}?order_id=${orderClaimDetail.orderInfo.orderId}`
                      );
                    }}
                  >
                    {orderClaimDetail.orderInfo.orderId}
                  </Button>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.payName')}: `}
                >
                  <div
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {orderClaimDetail.orderInfo.payName}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.orderSn')}: `}
                >
                  <div
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {orderClaimDetail.orderInfo.orderSn}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.addTime')}: `}
                >
                  <div
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {orderClaimDetail.orderInfo.addTime}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.totalAmount')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.orderInfo.orderAmount.totalAmount}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.orderStatus')}: `}
                >
                  <Tag
                    color={
                      orderClaimDetail &&
                      orderClaimDetail.orderInfo &&
                      orderClaimDetail.orderInfo.orderStatus
                        ? orderClaimDetail.orderInfo.orderStatus.tagColor
                        : undefined
                    }
                  >
                    {orderClaimDetail &&
                    orderClaimDetail.orderInfo &&
                    orderClaimDetail.orderInfo.orderStatus
                      ? orderClaimDetail.orderInfo.orderStatus.description
                      : undefined}
                  </Tag>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.shippingStatus')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.orderInfo.shippingStatus.description}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.payStatus')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.orderInfo.payStatus.description}
                  </div>
                </Form.Item>
              }
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default ClaimOrderInfo;
