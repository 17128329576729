/** Media Type */
export const MEDIA_TYPE: { [key: string]: string } = {
  IMAGE: `IMAGE`,
  VIDEO: `VIDEO`,
  BOTH: `BOTH`,
};
/** Supported Image/Video Format*/
export const IMAGE_TYPE = '.jpg, .jpeg, .gif, .png, .webp';
export const VIDEO_TYPE = ' .mov, .flv, .mp4, .avi, .wmv';
/** Max Upload File Size */
export const MAX_UPLOAD_SIZE = 50;
