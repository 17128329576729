import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BgColorsOutlined,
  BoldOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
} from '@ant-design/icons';
import {
  Col,
  Empty,
  Row,
  Typography,
  Image,
  Space,
  Button,
  Tooltip,
  Spin,
  Input,
  Popover,
  Dropdown,
  Menu,
} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { FALLBACK_IMG } from '../../../constants/styles';
import { GoodData, MobileDescData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import VideoPreviewModal from '../../photoGallery/VideoPreviewModal';
import PhotoGalleryModal from '../../PhotoGalleryModal';
import videoLogo from '../../../assets/images/video-logo.png';
import { GithubPicker } from 'react-color';
import { BLACK, RED1, WHITE } from '../../../constants/color';
import { actionPermissions } from '../../../constants/actionPermissions';
import { hasPermission } from '../../../utils/hasPermission';

type GoodDescriptionEditorProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const GoodDescriptionEditor = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodDescriptionEditorProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [items, setItems] = useState<MobileDescData[]>(
    (goodInfo && goodInfo.mobileDesc) || []
  );
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [video, setVideo] = useState<any>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const handleCancelEditing = () => {
    if (isSubscribed.current) {
      if (goodInfo) setItems(goodInfo.mobileDesc || []);
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  const handleEditing = () => {
    if (editing) {
      if (isSubscribed.current) setLoading(true);
      postDataWithAuthToken('goods/edit', {
        goodsId: goodInfo && goodInfo.goodsId,
        mobileDesc: items.map((item) =>
          item.type === 'TXT'
            ? { type: item.type, value: item.value, style: item.style }
            : {
                type: item.type,
                originalMedia: item.originalMedia,
                thumbMedia: item.thumbMedia,
                largeMedia: item.largeMedia,
              }
        ),
      })
        .then((response) => {
          if (response && response.goodStatus) {
            alertMessage('success', t('goods.alerts.goodsEdited'));
            handleCancelEditing();
            if (callBack) callBack();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setLoading(false);
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const SortableActions = ({
    item,
    id,
  }: {
    item: MobileDescData;
    id: number;
  }) => (
    <div style={{ display: 'flex' }}>
      <SortableBar />
      <Tooltip
        title={t('goods.add/editGood.copy')}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
      >
        <Button
          type="text"
          size="small"
          icon={<CopyOutlined />}
          onClick={() => {
            if (item.type === 'TXT') {
              setItems((prev) => [
                ...prev.slice(0, id + 1),
                item,
                ...prev.slice(id + 1),
              ]);
            } else {
              setItems((prev) => [
                ...prev.slice(0, id + 1),
                item,
                ...prev.slice(id + 1),
              ]);
            }
          }}
        />
      </Tooltip>
      <Tooltip
        title={t('goods.add/editGood.delete')}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
      >
        <Button
          type="text"
          size="small"
          icon={<DeleteOutlined style={{ color: RED1 }} />}
          onClick={() =>
            setItems((prev) => prev.filter((_, index) => index !== id))
          }
        />
      </Tooltip>
    </div>
  );

  const TextEditorBar = ({
    item,
    id,
  }: {
    item: MobileDescData;
    id: number;
  }) => {
    const colors = [
      '#000000',
      '#FFFFFF',
      '#B80000',
      '#DB3E00',
      '#FCCB00',
      '#008B02',
      '#006B76',
      '#1273DE',
      '#004DCF',
      '#5300EB',
      '#EB9694',
      '#FAD0C3',
      '#FEF3BD',
      '#C1E1C5',
      '#BEDADC',
      '#C4DEF6',
      '#BED3F3',
      '#D4C4FB',
    ];

    const fontSizeOptions = () => {
      let options = [];
      for (let i = 9; i < 51; i++) {
        options.push(<Menu.Item key={i + 'px'}>{i}</Menu.Item>);
      }
      return (
        <Menu
          style={{ maxHeight: 140, overflow: 'auto' }}
          onClick={({ key }) => {
            setItems((prev) => [
              ...prev.slice(0, id),
              { ...item, style: { ...item.style, fontSize: key } },
              ...prev.slice(id + 1),
            ]);
          }}
        >
          {options}
        </Menu>
      );
    };

    const textAlignOptions = (
      <Menu
        style={{ maxHeight: 140, overflow: 'auto' }}
        onClick={({ key }) => {
          setItems((prev) => [
            ...prev.slice(0, id),
            { ...item, style: { ...item.style, textAlign: key } },
            ...prev.slice(id + 1),
          ]);
        }}
      >
        <Menu.Item key={'left'}>
          <AlignLeftOutlined />
        </Menu.Item>
        <Menu.Item key={'center'}>
          <AlignCenterOutlined />
        </Menu.Item>
        <Menu.Item key={'right'}>
          <AlignRightOutlined />
        </Menu.Item>
      </Menu>
    );

    return (
      <div
        style={{
          display: 'flex',
          borderLeft: '1px solid #d9d9d9',
          borderRight: '1px solid #d9d9d9',
          borderTop: '1px solid #d9d9d9',
        }}
      >
        <Dropdown overlay={fontSizeOptions} trigger={['click']}>
          <Tooltip title={t('goods.add/editGood.fontSize')}>
            <Button type="text" size="small" icon={<FontSizeOutlined />} />
          </Tooltip>
        </Dropdown>
        <Tooltip title={t('goods.add/editGood.bold')}>
          <Button
            type={
              item.style && item.style.fontWeight === 'bold'
                ? 'primary'
                : 'text'
            }
            size="small"
            icon={<BoldOutlined />}
            onClick={() => {
              setItems((prev) => [
                ...prev.slice(0, id),
                {
                  ...item,
                  style: {
                    ...item.style,
                    fontWeight:
                      item.style && item.style.fontWeight === 'bold'
                        ? 'normal'
                        : 'bold',
                  },
                },
                ...prev.slice(id + 1),
              ]);
            }}
          />
        </Tooltip>
        <Popover
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
          }}
          trigger="click"
          content={
            <GithubPicker
              colors={colors}
              color={item.style ? item.style.fontColor : BLACK}
              width="188px"
              triangle="hide"
              onChangeComplete={(color) => {
                setItems((prev) => [
                  ...prev.slice(0, id),
                  { ...item, style: { ...item.style, fontColor: color.hex } },
                  ...prev.slice(id + 1),
                ]);
              }}
            />
          }
          placement="bottomLeft"
        >
          <Tooltip title={t('goods.add/editGood.fontColor')}>
            <Button type="text" size="small" icon={<FontColorsOutlined />} />
          </Tooltip>
        </Popover>
        <Popover
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          overlayInnerStyle={{
            display: 'flex',
            justifyContent: 'center',
          }}
          trigger="click"
          content={
            <GithubPicker
              colors={colors}
              color={item.style ? item.style.backgroundColor : WHITE}
              width="188px"
              triangle="hide"
              onChangeComplete={(color) => {
                setItems((prev) => [
                  ...prev.slice(0, id),
                  {
                    ...item,
                    style: { ...item.style, backgroundColor: color.hex },
                  },
                  ...prev.slice(id + 1),
                ]);
              }}
            />
          }
          placement="bottomLeft"
        >
          <Tooltip title={t('goods.add/editGood.backgroundColor')}>
            <Button type="text" size="small" icon={<BgColorsOutlined />} />
          </Tooltip>
        </Popover>
        <Dropdown overlay={textAlignOptions} trigger={['click']}>
          <Tooltip title={t('goods.add/editGood.textAlign')}>
            <Button
              type="text"
              size="small"
              icon={
                item.style ? (
                  item.style.textAlign === 'left' ? (
                    <AlignLeftOutlined />
                  ) : item.style.textAlign === 'center' ? (
                    <AlignCenterOutlined />
                  ) : item.style.textAlign === 'right' ? (
                    <AlignRightOutlined />
                  ) : (
                    <AlignLeftOutlined />
                  )
                ) : (
                  <AlignLeftOutlined />
                )
              }
            />
          </Tooltip>
        </Dropdown>
      </div>
    );
  };

  const SortableBar = SortableHandle(() => (
    <Button
      type="text"
      size="small"
      icon={<DragOutlined style={{ cursor: 'grab' }} />}
    />
  ));

  const SortableItem = SortableElement(
    ({ item, id }: { item: MobileDescData; id: number }) =>
      item.type === 'TXT' ? (
        <div style={{ padding: 5 }}>
          <SortableActions item={item} id={id} />
          <TextEditorBar item={item} id={id} />
          <Input.TextArea
            defaultValue={item.value}
            onBlur={(e) => {
              setItems((prev) => [
                ...prev.slice(0, id),
                { ...item, value: e.currentTarget.value },
                ...prev.slice(id + 1),
              ]);
            }}
            style={{ resize: 'vertical', minHeight: 50, borderRadius: 0 }}
          />
        </div>
      ) : item.type === 'IMG' || item.type === 'VIDEO' ? (
        <div style={{ padding: 5 }}>
          <SortableActions item={item} id={id} />
          <Image
            src={item.type === 'IMG' ? item.originalMediaPath : videoLogo}
            preview={item.type === 'IMG'}
            width={100}
            fallback={FALLBACK_IMG}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (item.type === 'VIDEO') {
                setVideo({ value: item.originalMediaPath });
                setShowVideoModal(true);
              }
            }}
          />
        </div>
      ) : (
        <></>
      )
  );

  const SortableList = SortableContainer(
    ({ items }: { items: MobileDescData[] }) => (
      <Row
        style={{
          minWidth: 250,
          maxWidth: 400,
          minHeight: 250,
          padding: 5,
          border: '1px solid #d9d9d9',
        }}
      >
        {items.map((item, index) => (
          <Col span={24} key={index}>
            <SortableItem index={index} item={item} id={index} />
          </Col>
        ))}
      </Row>
    )
  );

  return (
    <Spin spinning={loading}>
      <Row gutter={[0, 16]}>
        <Col span={16}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {t('goods.add/editGood.detailDescription')}
          </Typography.Text>
        </Col>
        <Col span={8}>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {editing ? (
              <Space>
                <Button onClick={() => handleCancelEditing()}>
                  {t('goods.add/editGood.cancel')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (hasPermission(actionPermissions.goodGroup.goodManage))
                      handleEditing();
                  }}
                >
                  {t('goods.add/editGood.ok')}
                </Button>
              </Space>
            ) : (
              <Tooltip
                title={t('goods.add/editGood.edit')}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <Button
                  type="text"
                  onClick={() => {
                    if (hasPermission(actionPermissions.goodGroup.goodManage))
                      handleEditing();
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        </Col>
        {editing && (
          <Col span={24}>{t('goods.add/editGood.toolTip.description')}</Col>
        )}
        {editing && (
          <Col span={24}>
            <Space>
              <Button
                size="small"
                onClick={() => {
                  setItems((prev) => [
                    ...prev,
                    {
                      type: 'TXT',
                      value: '',
                      style: {
                        fontSize: '14px',
                        fontWeight: 'normal',
                        fontColor: BLACK,
                        backgroundColor: WHITE,
                        textAlign: 'left',
                      },
                    },
                  ]);
                }}
              >
                {t('goods.add/editGood.addText')}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setFirstLoad(true);
                  setShowModal(true);
                }}
              >
                {t('goods.add/editGood.addMedia')}
              </Button>
            </Space>
          </Col>
        )}
        <Col span={24}>
          {!!items.length ? (
            <Space align="start" size="large" wrap>
              {editing && (
                <Space direction="vertical">
                  {items && !!items.length && (
                    <Typography.Text strong>
                      {t('goods.add/editGood.edit')}:
                    </Typography.Text>
                  )}
                  {items && !!items.length && (
                    <SortableList
                      useDragHandle
                      items={items}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        setItems((prev) =>
                          arrayMoveImmutable(prev, oldIndex, newIndex)
                        );
                      }}
                    />
                  )}
                </Space>
              )}
              <Space direction="vertical">
                {editing && items && !!items.length && (
                  <Typography.Text strong>
                    {t('goods.add/editGood.preview')}:
                  </Typography.Text>
                )}
                {!!items.length && (
                  <Space
                    direction="vertical"
                    style={{
                      maxHeight: editing ? undefined : 400,
                      maxWidth: 400,
                      minWidth: 250,
                      minHeight: 250,
                      overflow: 'auto',
                      border: '1px solid #d9d9d9',
                      padding: 10,
                    }}
                  >
                    {items.map((item, index) =>
                      item.type === 'TXT' ? (
                        <div
                          key={index}
                          style={{
                            whiteSpace: 'pre-wrap',
                            fontSize: item.style
                              ? item.style.fontSize
                              : undefined,
                            color: item.style
                              ? item.style.fontColor
                              : undefined,
                            backgroundColor: item.style
                              ? item.style.backgroundColor
                              : undefined,
                            fontWeight: item.style
                              ? item.style.fontWeight
                              : undefined,
                            textAlign: item.style
                              ? item.style.textAlign
                              : undefined,
                          }}
                        >
                          {item.value}
                        </div>
                      ) : item.type === 'IMG' ? (
                        <Image
                          key={index}
                          src={item.originalMediaPath}
                          fallback={FALLBACK_IMG}
                          preview={false}
                        />
                      ) : item.type === 'VIDEO' ? (
                        <video
                          key={index}
                          src={item.originalMediaPath}
                          controls
                          width="100%"
                          height={250}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </Space>
                )}
              </Space>
            </Space>
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
      <PhotoGalleryModal
        visible={showModal}
        setVisible={setShowModal}
        setValue={setItems}
        value={items}
        firstLoad={firstLoad}
        isMultiple
      />
      <VideoPreviewModal
        visible={showVideoModal}
        setVisible={setShowVideoModal}
        videoPath={video?.value}
        title={video?.picName}
      />
    </Spin>
  );
};

export default GoodDescriptionEditor;
