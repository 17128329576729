import { Col, Empty, Form, InputNumber, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderData } from '../../../types';

type OrderMainGoodsFeeProps = {
  orderInfo?: OrderData;
};
const OrderMainGoodsFee = ({ orderInfo }: OrderMainGoodsFeeProps) => {
  const { t } = useTranslation();

  return orderInfo && orderInfo.orderAmount ? (
    <Form layout="vertical" initialValues={{ ...orderInfo.orderAmount }}>
      <Row gutter={[8, 24]}>
        <Col span={24}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {t('order.orderDetail.mainGoodsFee')}
          </Typography.Text>
        </Col>
        <Col span={24} sm={12} md={4}>
          <Form.Item
            label={`${t('order.orderDetail.mainGoodsAmount')}:`}
            name="mainGoodsAmount"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={4}>
          <Form.Item
            label={`${t('order.orderDetail.gstFee')}:`}
            name="mainGstFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={4}>
          <Form.Item
            label={`${t('order.orderDetail.pstFee')}:`}
            name="mainPstFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={4}>
          <Form.Item
            label={`${t('order.orderDetail.mainDepositFee')}:`}
            name="mainDepositFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={4}>
          <Form.Item
            label={`${t('order.orderDetail.mainRecycleFee')}:`}
            name="mainRecycleFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={4}>
          <Form.Item
            label={`${t(
              'order.orderListColumns.orderAmount.mainSettleAmount'
            )}:`}
            name="mainSettleAmount"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.orderFee')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderMainGoodsFee;
