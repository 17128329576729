import { Divider, Table } from 'antd';
import CommonPrintoutHeader from '../order/orderPrintoutSections/CommonPrintoutHeader';
import { useEffect, useState } from 'react';
import { CommonPrintoutHeaderType, ExcelPrintData } from '../../types';
import { getBusinessInfo } from '../../utils/axiosRequest';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/systemConstants';

const PrintExcelTable = ({
  title,
  tableData,
  tableColumn,
  header,
}: ExcelPrintData) => {
  const [businessInfo, setBusinessInfo] = useState<CommonPrintoutHeaderType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBusinessInfo();
        setBusinessInfo(response as CommonPrintoutHeaderType);
      } catch (error) {
        // Handle errors if needed
        console.error('Error updating business info:', error);
      }
    };
    if (title) {
      fetchData();
    }
  }, [title]);

  return (
    <>
      {title && businessInfo && (
        <>
          <CommonPrintoutHeader
            header={{
              ...businessInfo,
              // Print Date
              addTime: header?.addTime
                ? `Effective Date: ${header?.addTime.slice(0, -9)}`
                : `Print Date: ${moment().format(DATE_FORMAT)}`,
            }}
            title={title}
          />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </>
      )}
      <Table
        className="tablePrintout excelPrintout"
        dataSource={tableData}
        columns={tableColumn}
        pagination={false}
        size="small"
        bordered={true}
        scroll={{ x: 'max-content' }}
        style={{
          marginBottom: 15,
        }}
      />
    </>
  );
};

export default PrintExcelTable;
