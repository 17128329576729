import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

const FourZeroFour = () => {
  const { t } = useTranslation();

  return (
    <Result status="404" title="404" subTitle={t('general.fourZeroFour')} />
  );
};

export default FourZeroFour;
