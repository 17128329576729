/** Task Goods Status */
export const TASK_GOODS_STATUS = {
  outOfStock: 'OUT_OF_STOCK',
  success: 'SUCCESS',
  difference: 'DIFFERENCE',
  exchange: 'EXCHANGE',
  orderInvalid: 'ORDER_INVALID',
  notStart: 'NOT_START',
};

export const TASK_STATUS = {
  inProgress: 'IN_PROGRESS',
  finished: 'FINISHED',
  split: 'SPLIT',
  suspension: 'SUSPENSION',
  orderInvalid: 'ORDER_INVALID',
  notStarted: 'NOT_STARTED',
};

export const TASK_SORT_ORDER_TYPE = {
  labelSort: 'LABEL_SORT',
  taskSort: 'TASK_SORT',
};
