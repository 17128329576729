import React from 'react';
import { useTranslation } from 'react-i18next';
import ShopConfigLayout from '../layout/ShopConfigLayout';

/**
 * Displays Seller Settings
 * /dashboard/settings/seller
 *
 * @param route Route Information from react-router-config
 */
const SellerSettings = () => {
  const { t } = useTranslation();

  return <ShopConfigLayout group="seller" title={t('sellers.settings.title')} />;
};

export default SellerSettings;
