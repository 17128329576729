import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GREEN1, RED1 } from '../../../constants/color';
import {
  DATE_FORMAT,
  GENERAL_TIMEOUT,
  MEDIUM_FONT_SIZE,
} from '../../../constants/systemConstants';
import {
  OrderAnalysisDataPoint,
  OrderOverviewAnalysisData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import {
  addCommas,
  addCommasPrice,
  getPercent,
} from '../../../utils/helperFunction';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import SellersDropdown from '../../sellers/SellersDropdown';

type OrderOverviewProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const OrderOverview = ({
  isSeller,
  isLoading,
  setIsLoading,
}: OrderOverviewProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { RangePicker } = DatePicker;
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { t } = useTranslation();
  //Data Components
  //This Year range OR Total(sent request with no date range)
  const [range1Data, setRange1Data] = useState<OrderOverviewAnalysisData>();
  //Last Year range
  const [range2Data, setRange2Data] = useState<OrderOverviewAnalysisData>();
  const [growthData, setGrowthData] = useState<OrderOverviewAnalysisData>();
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getGrowth = useCallback(() => {
    if (range1Data && range2Data) {
      let totalData: OrderAnalysisDataPoint = {
        totalAmount: '',
        totalOrderNum: '',
        totalClaimNum: '',
      };
      let paidData: OrderAnalysisDataPoint = {
        totalAmount: '',
        totalOrderNum: '',
        totalClaimNum: '',
      };
      let invalidData: OrderAnalysisDataPoint = {
        totalClaimNum: '',
        totalAmount: '',
        totalOrderNum: '',
      };
      let prepareData: OrderAnalysisDataPoint = {
        totalClaimNum: '',
        totalAmount: '',
        totalOrderNum: '',
      };
      Object.keys(range1Data).forEach((label) => {
        Object.keys(
          range1Data[label as keyof OrderOverviewAnalysisData]
        ).forEach((label2) => {
          switch (label) {
            case 'totalData': {
              totalData[label2 as keyof OrderAnalysisDataPoint] = getPercent(
                range2Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ],
                range1Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ]
              );
              break;
            }
            case 'invalidData': {
              invalidData[label2 as keyof OrderAnalysisDataPoint] = getPercent(
                range2Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ],
                range1Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ]
              );
              break;
            }
            case 'prepareData': {
              prepareData[label2 as keyof OrderAnalysisDataPoint] = getPercent(
                range2Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ],
                range1Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ]
              );
              break;
            }
            case 'paidData': {
              paidData[label2 as keyof OrderAnalysisDataPoint] = getPercent(
                range2Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ],
                range1Data[label as keyof OrderOverviewAnalysisData][
                  label2 as keyof OrderAnalysisDataPoint
                ]
              );
              // break;
            }
          }
        });
      });
      setGrowthData({
        totalData: totalData,
        paidData: paidData,
        invalidData: invalidData,
        prepareData: prepareData,
      });
    }
  }, [range1Data, range2Data]);

  useEffect(() => {
    getGrowth();
  }, [getGrowth]);

  /**
   * Grabs all the needed data for Order overview
   */
  const getData = useCallback(
    (date?: { key: string; start: string; end: string }) => {
      if (isSubscribed.current) {
        setIsLoading(true);
      }
      getDataWithAuthToken('analysis/order', {
        params: {
          isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
          sellerId:
            getDashboardStyle().isSellerSwitch &&
            isSeller &&
            formRef.current &&
            form.getFieldValue('sellerId')
              ? form.getFieldValue('sellerId')
              : undefined,
          startDate: date ? date.start : undefined,
          endDate: date ? date.end : undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              if (date) {
                if (date.key === 'range1') {
                  setRange1Data(response.data);
                } else if (date.key === 'range2') {
                  setRange2Data(response.data);
                }
              } else {
                setRange1Data(response.data);
              }
            }
          } else if (response && response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (isSubscribed.current) {
            setIsLoading(false);
          }
        });
    },
    [t, setFourZeroThree, setFiveHundred, isSeller, setIsLoading, form]
  );

  useEffect(() => {
    getData({
      key: 'range1',
      start: moment().subtract(8, 'days').format(DATE_FORMAT),
      end: moment().subtract(1, 'days').format(DATE_FORMAT),
    });
    getData({
      key: 'range2',
      start: moment()
        .subtract(8, 'days')
        .subtract(1, 'years')
        .format(DATE_FORMAT),
      end: moment()
        .subtract(1, 'days')
        .subtract(1, 'years')
        .format(DATE_FORMAT),
    });
  }, [getData]);

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form style={{ paddingBottom: 15 }} ref={formRef} form={form}>
            <Row gutter={[16, 0]}>
              <Col span={24} md={24} lg={24} xl={8}>
                <Form.Item
                  name={'date'}
                  style={{ width: '100%' }}
                  label={t('analysis.orderOverview.date')}
                  initialValue={[
                    moment().subtract(8, 'days'),
                    moment().subtract(1, 'days'),
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    placeholder={[
                      t('analysis.orderOverview.startDate'),
                      t('analysis.orderOverview.endDate'),
                    ]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              {isSeller && (
                <Col span={24} md={24} lg={24} xl={8}>
                  <Form.Item
                    name="sellerId"
                    label={t('analysis.goodsSales.seller')}
                    style={{ marginBottom: 10 }}
                  >
                    <SellersDropdown />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          if (
                            form.getFieldValue('date') &&
                            form.getFieldValue('date').length === 2
                          ) {
                            setRange1Data(undefined);
                            setRange2Data(undefined);
                            let range1 = {
                              key: 'range1',
                              start: moment(
                                form.getFieldValue('date')[0]
                              ).format(DATE_FORMAT),
                              end: moment(form.getFieldValue('date')[1]).format(
                                DATE_FORMAT
                              ),
                            };
                            getData(range1);
                            let range2 = {
                              key: 'range2',
                              start: moment(form.getFieldValue('date')[0])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                              end: moment(form.getFieldValue('date')[1])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                            };
                            getData(range2);
                          } else {
                            setGrowthData(undefined);
                            getData();
                          }
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderOverview.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          setGrowthData(undefined);
                          setRange1Data(undefined);
                          setRange2Data(undefined);
                          form.resetFields();
                          getData({
                            key: 'range1',
                            start: moment()
                              .subtract(8, 'days')
                              .format(DATE_FORMAT),
                            end: moment()
                              .subtract(1, 'days')
                              .format(DATE_FORMAT),
                          });
                          getData({
                            key: 'range2',
                            start: moment()
                              .subtract(8, 'days')
                              .subtract(1, 'years')
                              .format(DATE_FORMAT),
                            end: moment()
                              .subtract(1, 'days')
                              .subtract(1, 'years')
                              .format(DATE_FORMAT),
                          });
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderOverview.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          if (form.getFieldValue(['date'])) {
                            getData({
                              key: 'range1',
                              start: moment(
                                form.getFieldValue(['date'])[0]
                              ).format(DATE_FORMAT),
                              end: moment(
                                form.getFieldValue(['date'])[1]
                              ).format(DATE_FORMAT),
                            });
                            getData({
                              key: 'range2',
                              start: moment(form.getFieldValue(['date'])[0])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                              end: moment(form.getFieldValue(['date'])[1])
                                .subtract(1, 'years')
                                .format(DATE_FORMAT),
                            });
                          }
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
          {range1Data && (
            <div
              style={{
                flexDirection: 'column',
              }}
            >
              {Object.keys(range1Data).map((label, index) => {
                return (
                  <Row key={index}>
                    <Col span={24}>
                      <Divider orientation="left">
                        <Typography.Text
                          strong
                          style={{ fontSize: MEDIUM_FONT_SIZE }}
                        >
                          {t(`analysis.orderOverview.${label}`)}
                        </Typography.Text>
                      </Divider>
                    </Col>
                    {Object.keys(
                      range1Data[label as keyof OrderOverviewAnalysisData]
                    ).map((dataLabel, index1) => {
                      let value =
                        range1Data[label as keyof OrderOverviewAnalysisData][
                          dataLabel as keyof OrderAnalysisDataPoint
                        ];

                      let growthValue =
                        growthData &&
                        growthData[label as keyof OrderOverviewAnalysisData][
                          dataLabel as keyof OrderAnalysisDataPoint
                        ] !== '' &&
                        growthData[label as keyof OrderOverviewAnalysisData][
                          dataLabel as keyof OrderAnalysisDataPoint
                        ] !== '0'
                          ? growthData[
                              label as keyof OrderOverviewAnalysisData
                            ][dataLabel as keyof OrderAnalysisDataPoint]
                          : false;
                      return (
                        <Col
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            paddingTop: 7,
                            paddingBottom: 7,
                          }}
                          key={index1}
                          span={24}
                          sm={12}
                          md={8}
                          lg={6}
                          xl={6}
                        >
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {t(`analysis.orderOverview.${dataLabel}`)}
                          </div>
                          <Typography.Title
                            level={3}
                            style={{
                              margin: 0,
                              textAlign: 'center',
                            }}
                          >
                            {`${
                              ['totalAmount', 'averageAmount'].includes(
                                dataLabel
                              )
                                ? '$'
                                : ''
                            }${
                              ['totalAmount', 'averageAmount'].includes(
                                dataLabel
                              )
                                ? addCommasPrice(value)
                                : value
                            }${
                              [
                                'orderNumPercentage',
                                'amountPercentage',
                              ].includes(dataLabel)
                                ? '%'
                                : ''
                            }`}
                          </Typography.Title>

                          {growthValue && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Form.Item
                                style={{
                                  textAlign: 'center',
                                  marginBottom: 0,
                                }}
                                label={t(`analysis.orderOverview.lastYear`)}
                              >
                                {growthValue.at(0) === '-' ? (
                                  <ArrowDownOutlined style={{ color: RED1 }} />
                                ) : (
                                  <ArrowUpOutlined style={{ color: GREEN1 }} />
                                )}

                                {`${addCommas(growthValue)}%`}
                              </Form.Item>
                            </div>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                );
              })}
            </div>
          )}
        </Spin>
      )}
    </>
  );
};

export default OrderOverview;
