import React from 'react';
import { useTranslation } from 'react-i18next';
import ShopConfigLayout from '../layout/ShopConfigLayout';

/**
 * Displays Mail Settings
 * /dashboard/settings/mail
 *
 * @param route Route Information from react-router-config
 */
const MailSettings = () => {
  const { t } = useTranslation();

  return <ShopConfigLayout group="mail" title={t('plugins.Mail.settings.title')} />;
};

export default MailSettings;
