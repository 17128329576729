import { Row, Typography, Image, Col } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { RED1 } from '../../../constants/color';
import {
  PRINTOUT_QR_CODES_FONT_SIZE,
  PRINTOUT_RULES_FONT_SIZE,
  PRINTOUT_SEQ_LABLE_FONT_SIZE,
  PRINTOUT_TITLE_FONT_SIZE,
  PRINTOUT_TITLE_NAME_FONT_SIZE,
} from '../../../constants/printoutConstants';
import { MEDIUM_FONT_SIZE } from '../../../constants/systemConstants';
import { PrintoutOrderHeaderData } from '../../../types';
import getDashboardStyle from '../../../utils/getDashboardStyle';

type OrderPrintoutHeaderInfoProps = {
  header: PrintoutOrderHeaderData;
};

const OrderPrintoutHeader = ({ header }: OrderPrintoutHeaderInfoProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Row /**Title Pic, Task QR Code, Title, Route Name and Seq Label */>
        <Col /**Title Pic and Task QR Code */
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Image src={header.logo} alt="logo" width={150} />
          {header.taskQrCodeUrl && (
            <QRCodeSVG
              value={header.taskQrCodeUrl}
              size={80}
              imageSettings={{
                src: header.logo,
                height: 10,
                width: 10,
                excavate: true,
              }}
            />
          )}
        </Col>
        <Col /**Title Text */
          span={8}
          style={{
            textAlign: 'center',
            width: '100%',
            fontWeight: 'bold',
            alignSelf: 'center',
            fontSize: PRINTOUT_TITLE_FONT_SIZE,
          }}
        >
          {getDashboardStyle().isWholeSaleAppType
            ? 'INVOICE'
            : t('order.printoutComponents.pageTitle')}
        </Col>
        <Col /**Route Name and Seq Label */
          span={8}
          style={{
            height: 70,
            overflow: 'visible',
            zIndex: 3,
            textAlign: 'right',
            width: '100%',
            fontWeight: 'bold',
            fontSize: header.route
              ? PRINTOUT_SEQ_LABLE_FONT_SIZE
              : PRINTOUT_TITLE_NAME_FONT_SIZE,
            wordBreak: 'break-word',
          }}
        >
          <div /**Displaying routeName & consignee */
            style={{
              lineHeight: header.route ? 0.8 : 1,
            }}
          >
            {header.route ? header.route.routeName : header.consignee}
          </div>
          {header.route /**Displaying seq label */ ? (
            <div
              style={{
                lineHeight: 0.8,
                color: RED1,
              }}
            >
              {header.route.sequenceLabel}
            </div>
          ) : (
            header.orderShippingLabel /**Displaying orderShipping label */ && (
              <div
                style={{
                  lineHeight: 1.2,
                }}
              >
                {header.orderShippingLabel}
              </div>
            )
          )}
        </Col>
      </Row>
      <Row /**Rules and QR Codes */>
        <Col /**Rules */
          span={15}
          style={{
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Typography.Text
            style={{ fontWeight: 'bold', fontSize: MEDIUM_FONT_SIZE }}
          >
            {t('order.printoutComponents.shippingTermsTitle') + ':'}
          </Typography.Text>
          {header.shippingTerms.map((term, index) => {
            return (
              <Typography.Paragraph
                key={index}
                style={{
                  margin: 0,
                  fontSize: PRINTOUT_RULES_FONT_SIZE,
                }}
              >{`${index + 1}. ${term}`}</Typography.Paragraph>
            );
          })}
        </Col>
        <Col span={2}></Col>
        {!getDashboardStyle().isWholeSaleAppType && (
          <Col /**QR Code 1 and 2 */
            span={7}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <div /**QR Code 1 */
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: PRINTOUT_QR_CODES_FONT_SIZE,
              }}
            >
              {t('order.printoutComponents.qrCode1Title')}
              <Image style={{ width: 100 }} src={header.qrCode1} />
            </div>
            <div /**QR Code 2 */
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: PRINTOUT_QR_CODES_FONT_SIZE,
              }}
            >
              {t('order.printoutComponents.qrCode2Title')}
              <Image style={{ width: 100 }} src={header.qrCode2} />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default OrderPrintoutHeader;
