import Container from '../components/Container';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import HomeAnalysis from '../components/dashboard/HomeAnalysis';
import getDashboardStyle from '../utils/getDashboardStyle';
import { hasPermission } from '../utils/hasPermission';
import { actionPermissions } from '../constants/actionPermissions';

const { Title } = Typography;

const DashboardHome = () => {
  //General Components
  const { t } = useTranslation();
  //Data Components
  //Text Components
  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Card>
          <Title>{t(getDashboardStyle().title)}</Title>
        </Card>
      </div>
      {(hasPermission(actionPermissions.analysisGroup.homeOrder) ||
        hasPermission(actionPermissions.analysisGroup.homeGoods) ||
        hasPermission(actionPermissions.analysisGroup.homeUser) ||
        hasPermission(actionPermissions.analysisGroup.homeSales)) && (
        <HomeAnalysis />
      )}
    </Container>
  );
};

export default DashboardHome;
