import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Empty, Button, Grid, Space, Col, Row, Typography } from 'antd';
import { GalleryAlbum, GalleryMedia } from '../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../utils/axiosRequest';
import AlbumSidebar from './photoGallery/AlbumSidebar';
import GalleryHeader from './photoGallery/GalleryHeader';
import GalleryPagination from './photoGallery/GalleryPagination';
import PhotoGalleryGrid from './photoGallery/PhotoGalleryGrid';
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../utils/alertMessage';
import { isVideo } from '../utils/checkFileType';
import UploadImage from './photoGallery/UploadImage';
import { MenuOutlined } from '@ant-design/icons';
import { LONGEST_TIMEOUT } from '../constants/systemConstants';
import { MEDIA_TYPE } from '../constants/mediaConstants';

const { useBreakpoint } = Grid;

type PhotoGalleryModalProps = {
  firstLoad?: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  value?: any;
  valueKey?: string;
  supportedMediaType?: string;
  isMultiple?: boolean;
};

/**
 * Gallery to show available photos/videos plus the ability to add new ones
 * Organized in albums
 *
 * @param visible    Should Modal be visible
 * @param setVisible Set Modal to be visible or not visible
 * @param setValue   Set Value of picture being selected
 * @param value      Currently Selected Photo
 * @param valueKey   Value Key for chosen photoGalleryModel - know images for which form item
 * @param supportedMediaType Media type that is supported
 * @param isMultiple         enable multiple selection
 */
const PhotoGalleryModal = ({
  firstLoad,
  visible,
  setVisible,
  setValue,
  value,
  valueKey,
  supportedMediaType,
  isMultiple,
}: PhotoGalleryModalProps) => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState<Array<GalleryMedia>>([]);
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [selectedAlbum, setSelectedAlbum] = useState<GalleryAlbum>();
  const [pagePhotos, setPagePhotos] = useState(1);
  const [photosLoading, setPhotosLoading] = useState(false);
  const [albumsLoading, setAlbumsLoading] = useState(true);
  const [albums, setAlbums] = useState<Array<GalleryAlbum>>([]);
  const [mediaType, setMediaType] = useState<string>(MEDIA_TYPE.BOTH);
  const [albumPage, setAlbumPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadList, setUploadList] = useState<any[]>([]);
  const [totalProgress, setTotalProgress] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  const isSubscribed = useRef(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const screens = useBreakpoint();
  const [selectedMedias, setSelectedMedias] = useState<any>([]);
  const [albumKeyword, setAlbumKeyword] = useState<string>('');
  const [isNewSearch, setIsNewSearch] = useState<boolean>(false);

  /**
   * @param keyword Search term for albums
   */
  const getAlbums = useCallback(() => {
    getDataWithAuthToken(
      `gallery/list?page=${albumPage}&size=15${
        albumKeyword ? `&keyword=${albumKeyword}` : ''
      }`
    )
      .then((response) => {
        if (response && response.goodStatus) {
          if (isNewSearch) {
            setAlbums(response.data.list);
          } else {
            setAlbums((prev) => prev.concat(response.data.list));
          }
          if (albumPage >= response.data.totalPage) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
        setAlbumsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [albumPage, albumKeyword, isNewSearch]);

  /**
   * @param albumId What album to get the photos from
   * @param page    Page number
   */
  const getPhotos = useCallback(() => {
    if (isSubscribed.current) setPhotosLoading(true);
    getDataWithAuthToken(
      `gallery/pic/list?albumId=${
        selectedAlbum?.albumId
      }&page=${pagePhotos}&size=28${
        mediaType !== MEDIA_TYPE.BOTH ? `&mediaType=${mediaType}` : ''
      }${keyword ? `&keyword=${keyword}` : ''}`
    )
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setPhotos(response.data.list);
            setTotalPhotos(response.data.totalItem);
          }
        }
        if (isSubscribed.current) setPhotosLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedAlbum, pagePhotos, mediaType, keyword]);

  useEffect(() => {
    if (selectedAlbum) {
      getPhotos();
    }
  }, [selectedAlbum, getPhotos]);

  useEffect(() => {
    firstLoad && getAlbums();
  }, [getAlbums, firstLoad]);

  useEffect(() => {
    if (visible && isSubscribed.current) {
      value && setSelectedMedias(value);
    }
  }, [visible, value]);

  const handleOk = () => {
    if (showUpload) {
      if (isSubscribed.current) handleUpload();
    } else {
      if (isSubscribed.current) {
        if (valueKey) {
          selectedMedias.valueKey = valueKey;
        }
        setValue && setValue(selectedMedias);
        setVisible(false);
      }
    }
  };

  const handleCancel = () => {
    if (showUpload) {
      if (!uploading) {
        if (isSubscribed.current) {
          setUploadList([]);
          setTotalProgress(0);
          setUploading(false);
          setShowUpload(false);
        }
      } else {
        alertMessage('warning', 'Please stay while uploading');
      }
    } else {
      if (isSubscribed.current) setVisible(false);
    }
  };

  /**
   * @param album Selected Album
   */
  const handleAlbumSelect = useCallback((album: GalleryAlbum) => {
    if (isSubscribed.current) {
      setPagePhotos(1);
      setSelectedAlbum(album);
    }
  }, []);

  const handleUpload = async () => {
    if (selectedAlbum) {
      if (isSubscribed.current) setUploading(true);
      for (let i = 0; i < uploadList.length; i++) {
        const data = new FormData();
        data.append('albumId', selectedAlbum.albumId.toString());
        if (isVideo(uploadList[i].name)) {
          data.append('videos', uploadList[i].originFileObj);
        } else {
          data.append('images', uploadList[i].originFileObj);
        }

        try {
          const response = await postDataWithAuthToken(
            'gallery/pic/upload',
            data,
            {
              onUploadProgress: (progressEvent: any) => {
                const progress = Math.floor(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                if (isSubscribed.current)
                  setUploadList((prev: any) => {
                    let list = [...prev];
                    list[i].percent = progress;
                    list[i].status = 'uploading';
                    return list;
                  });
              },
            }
          );

          if (response && response.goodStatus) {
            if (isSubscribed.current)
              setUploadList((prev: any) => {
                let list = [...prev];
                list[i].percent = 100;
                list[i].status = 'done';
                return list;
              });
            setTotalProgress(Math.floor(((i + 1) / uploadList.length) * 100));
          } else {
            setUploadList((prev: any) => {
              let list = [...prev];
              list[i].status = 'error';
              return list;
            });
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
            break;
          }
        } catch (err) {
          console.log(err);
          break;
        }
      }

      setTimeout(() => {
        handleCancel();
        getPhotos();
      }, LONGEST_TIMEOUT);
    }
  };

  return (
    <Modal
      title={
        <Space>
          {t('settings.photoGallery')}
          {!screens.md && !showUpload && (
            <Button
              onClick={() => setShowSidebar(true)}
              icon={<MenuOutlined />}
              shape="circle"
            />
          )}
        </Space>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1100}
      okText={t('photoGallery.add/editAlbum.ok')}
      cancelText={t('photoGallery.add/editAlbum.cancel')}
      bodyStyle={{
        padding: showUpload ? '16px 24px' : 0,
        minHeight: 580,
      }}
      footer={
        <Space>
          <Button onClick={handleCancel}>
            {showUpload
              ? t('settings.photoGalleryActions.back')
              : t('settings.photoGalleryActions.cancel')}
          </Button>
          <Button type="primary" onClick={handleOk} loading={uploading}>
            {t('settings.photoGalleryActions.ok')}
          </Button>
        </Space>
      }
      maskClosable={uploading ? false : true}
      destroyOnClose
    >
      <Row>
        <Col
          span={screens.md ? (showUpload ? 0 : 5) : 0.1}
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            minHeight: 580,
            pointerEvents: showUpload ? 'none' : 'auto',
          }}
        >
          <AlbumSidebar
            handleAlbumSelect={handleAlbumSelect}
            getAlbums={getAlbums}
            albums={albums}
            loading={albumsLoading}
            setAlbumPage={setAlbumPage}
            hasMore={hasMore}
            visible={showSidebar}
            setVisible={setShowSidebar}
            isMobile={!screens.md}
            setAlbumKeyword={setAlbumKeyword}
            setIsNewSearch={setIsNewSearch}
          />
        </Col>
        {!showUpload ? (
          <Col
            span={screens.md ? 19 : 'auto'}
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            <GalleryHeader
              album={selectedAlbum}
              mediaType={mediaType}
              setMediaType={setMediaType}
              setPage={setPagePhotos}
              setShowUpload={setShowUpload}
              callBack={(keyword: string) => {
                if (selectedAlbum) {
                  setKeyword(keyword);
                  if (pagePhotos !== 1) setPagePhotos(1);
                }
              }}
              refresh={getPhotos}
            />
            {photos.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: 490,
                }}
              >
                <PhotoGalleryGrid
                  photos={photos}
                  photosLoading={photosLoading}
                  setValue={setSelectedMedias}
                  value={selectedMedias}
                  supportedMediaType={supportedMediaType}
                  isMultiple={isMultiple}
                />
                <GalleryPagination
                  totalPhotos={totalPhotos}
                  pagePhotos={pagePhotos}
                  setPagePhotos={setPagePhotos}
                />
              </div>
            ) : (
              <Empty />
            )}
          </Col>
        ) : (
          <Col>
            <Typography.Title
              level={5}
              style={{ marginTop: 4, marginBottom: 12 }}
            >
              {`${selectedAlbum?.albumName} > ${t(
                'settings.photoGalleryActions.uploadMedia'
              )}`}
            </Typography.Title>
            <UploadImage
              uploadList={uploadList}
              setUploadList={setUploadList}
              uploading={uploading}
              totalProgress={totalProgress}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default PhotoGalleryModal;
