import {
  Avatar,
  Button,
  Cascader,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Spin,
  TreeSelect,
} from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WHITE } from '../../constants/color';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import { AdData, AdEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import SellersDropdown from '../sellers/SellersDropdown';

type AdPageModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isChanged?: boolean;
  callBack?: (adPageDetail?: AdData) => void;
  adPage?: AdData;
  enums?: AdEnum;
  regions: DataNode[];
  allRegionIds: string[];
  isSeller?: boolean;
};

const AdPageModal = ({
  visible,
  setVisible,
  isChanged,
  callBack,
  adPage,
  enums,
  regions,
  allRegionIds,
  isSeller = false,
}: AdPageModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [titleColor, setTitleColor] = useState<string>('');
  const [parentAdPageName, setParentAdPageName] = useState<string>('');
  const [options, setOptions] = useState<
    {
      value: number | string;
      label: string;
      isLeaf: boolean;
      disabled?: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setOptions([
        {
          value: 0,
          label: t('advertisement.adPageColumnList.topAdPage'),
          isLeaf: false,
        },
      ]);
      if (adPage && adPage.adPageStyle && adPage.adPageStyle.titleColor) {
        setTitleColor(adPage.adPageStyle.titleColor || WHITE);
      } else {
        setTitleColor(WHITE);
      }
    }
  }, [visible, form, allRegionIds, adPage, t]);

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        if (adPage) {
          if (adPage.adPageId) {
            setIsLoading(true);
            postDataWithAuthToken('home_ad/edit', {
              adPageId: adPage.adPageId,
              parentId: values.parentId
                ? values.parentId.at(-1)
                : (adPage.parentAdPage && adPage.parentAdPage.adPageId) || 0,
              adPageName: values.adPageName || '',
              adPageTitle: values.adPageTitle || '',
              adPageType: values.adPageType || '',
              keyword: values.keyword || '',
              showRegionId:
                values.showRegionId.length === allRegionIds.length
                  ? 'all'
                  : values.showRegionId.join(),
              adPageStyle: {
                titleColor: values.titleColor || '',
              },
            })
              .then((response) => {
                if (response && response.goodStatus) {
                  setIsLoading(false);
                  alertMessage('success', t('advertisement.alerts.adEdited'));
                  callBack && callBack();
                  setVisible(false);
                } else {
                  setIsLoading(false);
                  alertMessage(
                    'error',
                    response?.msg || t('general.noResponse'),
                    response?.data || undefined
                  );
                }
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err);
              });
          } else {
            // Add Page
            const params = {
              ...adPage,
              seller: values.sellerId
                ? {
                    sellerId: values.sellerId.value,
                    shopName: values.sellerId.label,
                  }
                : { sellerId: 0, shopName: '' },
              parentAdPage: values.parentId
                ? {
                    adPageId: values.parentId.at(-1),
                    adPageName: parentAdPageName,
                  }
                : adPage.parentAdPage
                ? adPage.parentAdPage
                : undefined,
              adPageName: values.adPageName || '',
              adPageTitle: values.adPageTitle || '',
              adPageType: values.adPageType || '',
              keyword: values.keyword || '',
              showRegionId:
                values.showRegionId.length === allRegionIds.length
                  ? 'all'
                  : values.showRegionId.join(),
              adPageStyle: {
                titleColor: values.titleColor || '',
              },
            };
            callBack && callBack(params);
            setVisible(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    setVisible(false);
  };

  const loadData = (selectedOptions?: any[]) => {
    if (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      setTimeout(() => {
        getDataWithAuthToken('home_ad/list', {
          params: { parentId: targetOption.value },
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                targetOption.children = response.data.list.map((ad: AdData) => {
                  return {
                    value: ad.adPageId,
                    label: ad.adPageName,
                    isLeaf: ad.childCount === 0,
                    disabled: ad && adPage && ad.adPageId === adPage.adPageId,
                  };
                });
                targetOption.loading = false;
                setOptions([...options]);
              }
            }
          })
          .catch((err) => console.log(err));
      }, EXTENDED_TIMEOUT);
    }
  };

  return (
    <Modal
      title={t('advertisement.adDetail.adPageConfig')}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('advertisement.adDetail.cancel')}
        </Button>,
        adPage && adPage.adPageId && isChanged ? (
          <Popconfirm
            key="submit"
            title={t('advertisement.alerts.changeWarning')}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            onConfirm={onConfirm}
          >
            <Button type="primary">
              {t('advertisement.adDetail.confirm')}
            </Button>
          </Popconfirm>
        ) : (
          <Button key="submit" type="primary" onClick={onConfirm}>
            {t('advertisement.adDetail.confirm')}
          </Button>
        ),
      ]}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            adPage
              ? {
                  ...adPage,
                  titleColor: adPage.adPageStyle
                    ? adPage.adPageStyle.titleColor
                    : undefined,
                  showRegionId:
                    adPage.showRegionId && regions.length
                      ? adPage.showRegionId === 'all'
                        ? allRegionIds
                        : adPage.showRegionId.split(',')
                      : [],
                  parentId:
                    adPage.parentId !== undefined
                      ? [adPage.parentId]
                      : undefined,
                }
              : {}
          }
        >
          <Form.Item
            name="adPageName"
            label={t('advertisement.adPageColumnList.adPageName')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="adPageTitle"
            label={t('advertisement.adPageColumnList.adPageTitle')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {isSeller && adPage && !adPage.adPageId && (
            <Form.Item
              label={t('advertisement.adPageColumnList.seller')}
              name="sellerId"
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <SellersDropdown
                labelInValue={true}
                initialValue={
                  adPage.seller && adPage.seller?.sellerId
                    ? {
                        value: adPage.seller.sellerId,
                        label: adPage.seller.shopName,
                      }
                    : undefined
                }
              />
            </Form.Item>
          )}
          <Form.Item
            name="parentId"
            label={t('advertisement.adDetail.parentAdPage')}
            rules={[
              {
                required: adPage && adPage.adPageId ? false : true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Cascader
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              options={options}
              loadData={loadData}
              placeholder={
                adPage
                  ? adPage.parentAdPage
                    ? adPage.parentAdPage.adPageName
                    : adPage.adPageId
                    ? t('advertisement.adPageColumnList.topAdPage')
                    : t('general.pleaseSelect')
                  : t('general.pleaseSelect')
              }
              changeOnSelect
              displayRender={(label) => label.join(' > ')}
              onChange={(value, selectedOptions: any) => {
                if (selectedOptions) {
                  setParentAdPageName(selectedOptions.at(-1).label);
                } else {
                  setParentAdPageName('');
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="adPageType"
            label={t('advertisement.adPageColumnList.adPageType')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select>
              {enums &&
                enums.adPageType &&
                enums.adPageType.map((type) => (
                  <Select.Option key={type.code} value={type.code}>
                    {type.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('advertisement.adDetail.showRegionId')}
            name="showRegionId"
          >
            <TreeSelect
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              treeData={regions}
              treeDefaultExpandedKeys={
                adPage && adPage.showRegionId
                  ? adPage.showRegionId === 'all'
                    ? allRegionIds
                    : adPage.showRegionId.split(',')
                  : []
              }
              treeCheckable
              placeholder={t('general.pleaseSelect')}
              allowClear
              showSearch={false}
              maxTagCount={10}
            />
          </Form.Item>
          <Form.Item
            name="keyword"
            label={t('advertisement.adPageColumnList.keyword')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="titleColor"
            label={t('advertisement.adDetail.titleColor')}
          >
            <Input
              suffix={
                <Avatar
                  shape="square"
                  size={20}
                  style={{
                    backgroundColor: titleColor,
                  }}
                />
              }
              onChange={(e) => {
                setTitleColor(e.target.value ? e.target.value : WHITE);
              }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AdPageModal;
