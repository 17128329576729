import Cascader, { DefaultOptionType } from 'antd/lib/cascader';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';

type RegionDropdownProps = {
  isMultiple?: boolean;
  onFocusFetch?: boolean;
  endTreeLevel?: number;
  columnWidth?: number; // set column width
  initialValue?: any | any[];
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const RegionDropdown = ({
  isMultiple = false,
  onFocusFetch = false,
  endTreeLevel = undefined,
  columnWidth = undefined,
  initialValue,
  onChange,
}: RegionDropdownProps) => {
  const { t } = useTranslation();
  const [regionOptions, setRegionOptions] = useState<DefaultOptionType[]>([]);

  const getRegionData = useCallback(() => {
    /**
     * Reconstruct the data to be used in Cascader
     *
     */
    const makeTreeData = (data: RegionData[] = []) => {
      let treeData: DefaultOptionType[] = [];
      for (let node of data) {
        let treeNode: DefaultOptionType = {
          value: node.regionId,
          label: node.regionName,
          children:
            node.child && node.child.length
              ? endTreeLevel && node.regionTypeId >= endTreeLevel - 1
                ? undefined
                : makeTreeData(node.child)
              : undefined,
        };
        treeData.push(treeNode);
      }
      return treeData;
    };

    getDataWithAuthToken('setting/region/all')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setRegionOptions(makeTreeData(response.data.list));
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => console.log(err));
  }, [t, endTreeLevel]);

  useEffect(() => {
    // if onFocusFetch, not render region when component displayed
    if (!onFocusFetch) {
      getRegionData();
    }
  }, [onFocusFetch, getRegionData]);

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  const cProps = !isMultiple
    ? {
        displayRender: (label: any[]) => label.join(' > '),
      }
    : {};

  return (
    <Cascader
      style={{ width: '100%' }}
      multiple={isMultiple}
      maxTagCount="responsive"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      dropdownMatchSelectWidth={!!columnWidth}
      dropdownMenuColumnStyle={columnWidth ? { width: columnWidth } : undefined}
      defaultValue={initialValue}
      allowClear
      options={regionOptions}
      changeOnSelect
      placeholder={t('general.pleaseSelect')}
      onChange={handleOnChange}
      onFocus={() => {
        if (onFocusFetch) {
          if (!regionOptions.length) getRegionData();
        }
      }}
      {...cProps}
    />
  );
};

export default RegionDropdown;
