import { Button, Col, DatePicker, Form, Grid, Row, Space, Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { GREEN1, RED1 } from '../../../constants/color';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../../constants/systemConstants';
import {
  DepartmentData,
  DepartmentYearSalesAnalysisData,
  FontSizeType,
  SalesAnalysisData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { addCommas, addCommasPrice } from '../../../utils/helperFunction';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import DepartmentDropdown from '../../goods/common/DepartmentDropdown';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableToolbar from '../../table/TableToolbar';

type DepartmentSalesProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const DepartmentYearSales = ({
  isSeller,
  isLoading,
  setIsLoading,
}: DepartmentSalesProps) => {
  //General Components
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  //Data Components
  const [data, setData] = useState<any[]>([]);
  //Text Components
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const screens = Grid.useBreakpoint();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableHeaders, setTableHeaders] = useState<string[]>([]);
  const columnKeys = [
    'month',
    'totalGoodsNum',
    'totalAmount',
    'lastYearTotalGoodsNum',
    'lastYearTotalAmount',
    'totalGoodsNumGrowth',
    'totalAmountGrowth',
    ...tableHeaders.flatMap((header: any) => [
      `${header.split('@')[0]}@totalGoodsNum`,
      `${header.split('@')[0]}@goodsNumPercentage`,
      `${header.split('@')[0]}@totalAmount`,
      `${header.split('@')[0]}@amountPercentage`,
      `${header.split('@')[0]}@totalGoodsNumberGrowth`,
      `${header.split('@')[0]}@totalAmountGrowth`,
      `${header.split('@')[0]}@lastYearTotalGoodsNum`,
      `${header.split('@')[0]}@lastYearTotalAmount`,
      `${header.split('@')[0]}@lastYearGoodsNumPercentage`,
      `${header.split('@')[0]}@lastYearAmountPercentage`,
    ]),
  ];

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('analysis/sales/department_year', {
      params: {
        isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
        year:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')).year()
            : moment().year(),
        sellerId:
          getDashboardStyle().isSellerSwitch &&
          isSeller &&
          formRef.current &&
          form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        departmentId:
          formRef.current && form.getFieldValue('departmentId')
            ? form.getFieldValue('departmentId')
            : undefined,
      },
    })
      .then(async (response) => {
        if (response && response.goodStatus) {
          let headers: any[] = [];
          let modifiedData = response.data.departmentSalesMonthList.map(
            (item: DepartmentYearSalesAnalysisData, index: number) => {
              const { departmentSalesStatList, ...rest } = item;
              let modifiedItem: any = rest;
              if (departmentSalesStatList.length) {
                for (let dept of departmentSalesStatList) {
                  index === 0 &&
                    headers.push(`${dept.departmentId}@${dept.departmentName}`);
                  for (let key in dept) {
                    modifiedItem[`${dept.departmentId}@${key}`] =
                      dept[key as keyof SalesAnalysisData];
                  }
                }
              }
              return modifiedItem;
            }
          );
          // get data of previous year
          const response2 = await getDataWithAuthToken(
            'analysis/sales/department_year',
            {
              params: {
                isSeller: getDashboardStyle().isSellerSwitch
                  ? isSeller
                  : undefined,
                year:
                  formRef.current && form.getFieldValue('date')
                    ? moment(form.getFieldValue('date'))
                        .subtract(1, 'years')
                        .year()
                    : moment().subtract(1, 'years').year(),
                sellerId:
                  getDashboardStyle().isSellerSwitch &&
                  isSeller &&
                  formRef.current &&
                  form.getFieldValue('sellerId')
                    ? form.getFieldValue('sellerId')
                    : undefined,
                departmentId:
                  formRef.current && form.getFieldValue('departmentId')
                    ? form.getFieldValue('departmentId')
                    : undefined,
              },
            }
          );
          if (response2 && response2.goodStatus) {
            for (let item of response2.data.departmentSalesMonthList) {
              const {
                departmentSalesStatList,
                month,
                totalAmount,
                totalGoodsNum,
              } = item;
              modifiedData[month - 1].lastYearTotalGoodsNum = totalGoodsNum;
              modifiedData[month - 1].lastYearTotalAmount = totalAmount;
              modifiedData[month - 1].totalGoodsNumGrowth = totalGoodsNum
                ? (
                    ((modifiedData[month - 1].totalGoodsNum - totalGoodsNum) /
                      totalGoodsNum) *
                    100
                  ).toFixed(2)
                : '0.00';
              modifiedData[month - 1].totalAmountGrowth = Number(totalAmount)
                ? (
                    (Number(
                      modifiedData[month - 1].totalAmount - Number(totalAmount)
                    ) /
                      Number(totalAmount)) *
                    100
                  ).toFixed(2)
                : '0.00';

              if (departmentSalesStatList.length) {
                departmentSalesStatList.forEach((dept: SalesAnalysisData) => {
                  modifiedData[month - 1][
                    `${dept.departmentId}@lastYearTotalGoodsNum`
                  ] = dept.totalGoodsNum;

                  modifiedData[month - 1][
                    `${dept.departmentId}@lastYearTotalAmount`
                  ] = dept.totalAmount;

                  modifiedData[month - 1][
                    `${dept.departmentId}@lastYearGoodsNumPercentage`
                  ] = dept.goodsNumPercentage;

                  modifiedData[month - 1][
                    `${dept.departmentId}@lastYearAmountPercentage`
                  ] = dept.amountPercentage;

                  modifiedData[month - 1][
                    `${dept.departmentId}@totalGoodsNumGrowth`
                  ] = dept.totalGoodsNum
                    ? (
                        ((modifiedData[month - 1][
                          `${dept.departmentId}@totalGoodsNum`
                        ] -
                          dept.totalGoodsNum) /
                          dept.totalGoodsNum) *
                        100
                      ).toFixed(2)
                    : '0.00';

                  modifiedData[month - 1][
                    `${dept.departmentId}@totalAmountGrowth`
                  ] = Number(dept.totalAmount)
                    ? (
                        (Number(
                          modifiedData[month - 1][
                            `${dept.departmentId}@totalAmount`
                          ] - Number(dept.totalAmount)
                        ) /
                          Number(dept.totalAmount)) *
                        100
                      ).toFixed(2)
                    : '0.00';
                });
              }
            }
          }
          setData(modifiedData);
          setTableHeaders(
            response.data.departmentList.map(
              (dept: DepartmentData) =>
                `${dept.departmentId}@${dept.departmentName}`
            )
          );
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [t, form, setIsLoading, isSeller]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<DepartmentYearSalesAnalysisData> = [
    {
      title: setFont(t('analysis.departmentYearSales.month'), fontSize),
      fixed: screens.lg ? 'left' : undefined,
      key: 'month',
      dataIndex: 'month',
      width: 100,
      render: (value) =>
        setFont(
          formRef.current && form.getFieldValue('date')
            ? `${moment(form.getFieldValue('date')).year()}-${value}`
            : `${moment().year()}-${value}`,
          fontSize
        ),
    },
    ...tableHeaders.map((header) => ({
      title: setFont(header.split('@')[1], fontSize),
      children: [
        {
          title: setFont(
            t('analysis.departmentYearSales.totalGoodsNum'),
            fontSize
          ),
          key: `${header.split('@')[0]}@totalGoodsNum`,
          dataIndex: `${header.split('@')[0]}@totalGoodsNum`,
          width: 100,
          render: (value: string) =>
            value && setFont(addCommas(value), fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.lastYearTotalGoodsNum'),
            fontSize
          ),
          key: `${header.split('@')[0]}@lastYearTotalGoodsNum`,
          dataIndex: `${header.split('@')[0]}@lastYearTotalGoodsNum`,
          width: 120,
          render: (value: string) =>
            value && setFont(addCommas(value), fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.goodsNumPercentage'),
            fontSize
          ),
          key: `${header.split('@')[0]}@goodsNumPercentage`,
          dataIndex: `${header.split('@')[0]}@goodsNumPercentage`,
          width: 120,
          render: (value: string) =>
            value && setFont(addCommas(value) + '%', fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.lastYearGoodsNumPercentage'),
            fontSize
          ),
          key: `${header.split('@')[0]}@lastYearGoodsNumPercentage`,
          dataIndex: `${header.split('@')[0]}@lastYearGoodsNumPercentage`,
          width: 140,
          render: (value: string) =>
            value && setFont(addCommas(value) + '%', fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.totalGoodsNumGrowth'),
            fontSize
          ),
          key: `${header.split('@')[0]}@totalGoodsNumGrowth`,
          dataIndex: `${header.split('@')[0]}@totalGoodsNumGrowth`,
          width: 150,
          render: (value: string) =>
            value && (
              <div
                style={{
                  color:
                    Number(value) > 0
                      ? GREEN1
                      : Number(value) === 0
                      ? undefined
                      : RED1,
                }}
              >
                {addCommas(value) + '%'}
              </div>
            ),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.totalAmount'),
            fontSize
          ),
          key: `${header.split('@')[0]}@totalAmount`,
          dataIndex: `${header.split('@')[0]}@totalAmount`,
          width: 100,
          render: (value: string) =>
            value && setFont(addCommasPrice(value), fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.lastYearTotalAmount'),
            fontSize
          ),
          key: `${header.split('@')[0]}@lastYearTotalAmount`,
          dataIndex: `${header.split('@')[0]}@lastYearTotalAmount`,
          width: 120,
          render: (value: string) =>
            value && setFont(addCommasPrice(value), fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.amountPercentage'),
            fontSize
          ),
          key: `${header.split('@')[0]}@amountPercentage`,
          dataIndex: `${header.split('@')[0]}@amountPercentage`,
          width: 120,
          render: (value: string) =>
            value && setFont(addCommas(value) + '%', fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.lastYearAmountPercentage'),
            fontSize
          ),
          key: `${header.split('@')[0]}@lastYearAmountPercentage`,
          dataIndex: `${header.split('@')[0]}@lastYearAmountPercentage`,
          width: 140,
          render: (value: string) =>
            value && setFont(addCommas(value) + '%', fontSize),
        },
        {
          title: setFont(
            t('analysis.departmentYearSales.totalAmountGrowth'),
            fontSize
          ),
          key: `${header.split('@')[0]}@totalAmountGrowth`,
          dataIndex: `${header.split('@')[0]}@totalAmountGrowth`,
          width: 150,
          render: (value: string) =>
            value && (
              <div
                style={{
                  fontSize: fontSize,
                  color:
                    Number(value) > 0
                      ? GREEN1
                      : Number(value) === 0
                      ? undefined
                      : RED1,
                }}
              >
                {addCommas(value) + '%'}
              </div>
            ),
        },
      ],
    })),
    {
      title: setFont(t('analysis.departmentYearSales.totalGoodsNum'), fontSize),
      key: 'totalGoodsNum',
      dataIndex: 'totalGoodsNum',
      width: 100,
      render: (value) => value && setFont(addCommas(value), fontSize),
    },
    {
      title: setFont(
        t('analysis.departmentYearSales.lastYearTotalGoodsNum'),
        fontSize
      ),
      key: 'lastYearTotalGoodsNum',
      dataIndex: 'lastYearTotalGoodsNum',
      width: 120,
      render: (value) => value && setFont(addCommas(value), fontSize),
    },
    {
      title: setFont(
        t('analysis.departmentYearSales.totalGoodsNumGrowth'),
        fontSize
      ),
      key: 'totalGoodsNumGrowth',
      dataIndex: 'totalGoodsNumGrowth',
      width: 150,
      render: (value) =>
        value && (
          <div
            style={{
              fontSize: fontSize,
              color:
                Number(value) > 0
                  ? GREEN1
                  : Number(value) === 0
                  ? undefined
                  : RED1,
            }}
          >
            {addCommas(value) + '%'}
          </div>
        ),
    },
    {
      title: setFont(t('analysis.departmentYearSales.totalAmount'), fontSize),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 100,
      render: (value) => value && setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(
        t('analysis.departmentYearSales.lastYearTotalAmount'),
        fontSize
      ),
      key: 'lastYearTotalAmount',
      dataIndex: 'lastYearTotalAmount',
      width: 120,
      render: (value) => value && setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(
        t('analysis.departmentYearSales.totalAmountGrowth'),
        fontSize
      ),
      key: 'totalAmountGrowth',
      dataIndex: 'totalAmountGrowth',
      width: 150,
      render: (value) =>
        value && (
          <div
            style={{
              fontSize: fontSize,
              color:
                Number(value) > 0
                  ? GREEN1
                  : Number(value) === 0
                  ? undefined
                  : RED1,
            }}
          >
            {addCommas(value) + '%'}
          </div>
        ),
    },
  ];

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Form layout="vertical" form={form} ref={formRef}>
        <Row gutter={[16, 0]}>
          <Col span={24} md={12} lg={12} xl={8}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="date"
              label={t('analysis.departmentYearSales.year')}
              initialValue={moment()}
            >
              <DatePicker
                allowClear={false}
                picker="year"
                style={{ width: '100%' }}
                placeholder={t('analysis.departmentYearSales.year')}
              />
            </Form.Item>
          </Col>
          {isSeller && (
            <Col span={24} md={12} lg={12} xl={8}>
              <Form.Item
                name="sellerId"
                label={t('analysis.goodsSales.seller')}
                style={{ marginBottom: 12 }}
              >
                <SellersDropdown />
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12} lg={12} xl={8}>
            <Form.Item
              label={t('analysis.departmentSales.department')}
              name="departmentId"
              style={{ marginBottom: 12 }}
            >
              <DepartmentDropdown />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ marginBottom: 12 }}>
              <Space>
                <Button type="primary" onClick={() => getData()}>
                  {t('general.search')}
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    getData();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TableToolbar
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        columns={columns}
        columnKeys={columnKeys}
        rows={data}
        exportConfig={{
          fileName: 'DEPARTMENT_YEAR_SALES',
        }}
      />
      <Table
        dataSource={data}
        loading={isLoading}
        size={tableSize}
        columns={columns}
        components={vt}
        scroll={{ y: 600 }}
        rowKey={(sales) => `${sales.month}`}
        pagination={false}
        bordered
      />
    </>
  );
};

export default DepartmentYearSales;
