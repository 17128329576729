import { EditOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMapGL, { Marker } from 'react-map-gl';
import { actionPermissions } from '../../../constants/actionPermissions';
import { MAP_MARKER_ICON, MAP_MARKER_SIZE } from '../../../constants/styles';
import {
  EXTENDED_TIMEOUT,
  SHORTEST_TIMEOUT,
} from '../../../constants/systemConstants';
import {
  AddressData,
  BasicEnumInfoType,
  OrderData,
  Viewport,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import UserAddressDropdown from '../../users/common/UserAddressDropdown';

type OrderAddressInfoProps = {
  orderInfo?: OrderData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderAddressInfo = ({
  orderInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: OrderAddressInfoProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewport, setViewport] = useState<Viewport>();
  const [addressTypeEnum, setAddressTypeEnum] = useState<BasicEnumInfoType[]>(
    []
  );
  const [addresses, setAddresses] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const handleOnEdit = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('order/edit', {
            orderId: orderInfo?.orderId,
            ...values,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('order.alerts.orderEdited'));
                handleOnCancel();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setEditing(true);
        if (setIsCurrentEditing) setIsCurrentEditing(true);
      }
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    if (orderInfo) {
      setSelectedAddress(orderInfo.orderAddress || {});
      setTimeout(() => {
        setViewport({
          longitude: Number(orderInfo.orderAddress.longitude) || 0,
          latitude: Number(orderInfo.orderAddress.latitude) || 0,
          zoom: 15,
        });
      }, SHORTEST_TIMEOUT);
    }
    setEditing(false);
    if (setIsCurrentEditing) setIsCurrentEditing(false);
  };

  const getEnumList = useCallback(() => {
    getDataWithAuthToken('users/user_address/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setAddressTypeEnum(response.data.addressType);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getEnumList();
  }, [getEnumList]);

  useEffect(() => {
    if (orderInfo) {
      setSelectedAddress(orderInfo.orderAddress || {});
      setTimeout(() => {
        setViewport({
          longitude: Number(orderInfo.orderAddress.longitude) || 0,
          latitude: Number(orderInfo.orderAddress.latitude) || 0,
          zoom: 15,
        });
      }, SHORTEST_TIMEOUT);
    }
  }, [orderInfo]);

  /**
   * @param value The address that was selected by user
   */
  const onSelectAddress = (value: any) => {
    let addressObj: {
      context: any[];
      center: number[];
      address: string;
      text: string;
    } = JSON.parse(value);
    let postcode = '';
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let region = '';
    let country = '';
    if (addressObj.address) streetNumber = addressObj.address;
    if (addressObj.text) streetName = addressObj.text;
    if (addressObj.context && addressObj.context.length) {
      for (let e of addressObj.context) {
        if (e.id.includes('postcode')) postcode = e.text;
        else if (e.id.includes('place')) city = e.text;
        else if (e.id.includes('region')) region = e.short_code.slice(-2);
        else if (e.id.includes('country')) country = e.short_code.toUpperCase();
      }
    }
    setSelectedAddress((prev: AddressData) => ({
      ...prev,
      postalCode: postcode,
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
    }));
    setViewport({
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
      zoom: 15,
    });
    form.setFieldsValue({
      address: `${streetNumber} ${streetName}, ${city}, ${region}, ${country}`,
      postalCode: postcode,
      longitude: addressObj.center[0],
      latitude: addressObj.center[1],
    });
  };

  /**
   * @param value search value for address
   */
  const onSearchAddress = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    if (value && value.length > 5) {
      setTypingTimeout(
        setTimeout(() => {
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=ca&language=en&types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
            )
            .then((result) => {
              if (result && result.status === 200 && result.data) {
                setAddresses(result.data.features);
              } else alertMessage('error', t('users.alerts.errorOccurred'));
            })
            .catch((err) => {
              console.log(err);
            });
        }, EXTENDED_TIMEOUT)
      );
    } else setAddresses([]);
  };

  return orderInfo && orderInfo.orderAddress ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...orderInfo.orderAddress,
          addressType: orderInfo.orderAddress.addressType
            ? orderInfo.orderAddress.addressType.code
            : undefined,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.orderDetail.addressInfo')}
            </Typography.Text>
            {editing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          {editing && (
            <Col span={24}>
              <Form.Item
                label={`${t('order.orderDetail.selectFromSavedAddressList')}: `}
                style={{ marginBottom: 0 }}
              >
                <UserAddressDropdown
                  userId={
                    orderInfo.user.userId
                      ? orderInfo.user.userId.toString()
                      : undefined
                  }
                  onChange={(value, option: any) => {
                    let address = option.address;
                    address.longitude = Number(address.longitude);
                    address.latitude = Number(address.latitude);

                    form.setFieldsValue({
                      ...address,
                      addressType: address.addressType.code,
                    });
                    setSelectedAddress((prev: any) => ({
                      ...prev,
                      ...address,
                      addressType: address.addressType.code,
                    }));
                    setViewport({
                      longitude: address.longitude,
                      latitude: address.latitude,
                      zoom: 15,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.consignee')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'consignee' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Input />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.consignee}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.consigneeMobile')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'mobile' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Input type="tel" />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.mobile}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.email')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'email' : undefined}
            >
              {editing ? (
                <Input type="email" />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.email}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.tel')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'tel' : undefined}
            >
              {editing ? (
                <Input type="tel" />
              ) : (
                <Typography.Text>{orderInfo.orderAddress.tel}</Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.address')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'address' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <AutoComplete
                  allowClear
                  onChange={onSearchAddress}
                  onSelect={(value: string, option: any) =>
                    onSelectAddress(option.key)
                  }
                >
                  {addresses.map((address: { place_name: string }) => (
                    <AutoComplete.Option
                      key={JSON.stringify(address)}
                      value={address.place_name}
                    >
                      {address.place_name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.address}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.postalCode')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'postalCode' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Input />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.postalCode}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.unitNumber')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'unitNumber' : undefined}
            >
              {editing ? (
                <Input />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.unitNumber}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.buzzerCode')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'buzzerCode' : undefined}
            >
              {editing ? (
                <Input />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.buzzerCode}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.addressType')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'addressType' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Select>
                  {addressTypeEnum.map((addressType) => (
                    <Select.Option
                      value={addressType.code}
                      key={addressType.code}
                    >
                      {addressType.description}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.addressType
                    ? orderInfo.orderAddress.addressType.description
                    : ''}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.latitude')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'latitude' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Input />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.latitude}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.longitude')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'longitude' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Input />
              ) : (
                <Typography.Text>
                  {orderInfo.orderAddress.longitude}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          {editing && (
            <Col span={24}>
              <ReactMapGL
                {...viewport}
                width="100%"
                height={400}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onViewportChange={setViewport}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              >
                {!!(
                  selectedAddress &&
                  selectedAddress.latitude &&
                  selectedAddress.longitude
                ) ? (
                  <Marker
                    latitude={Number(selectedAddress.latitude)}
                    longitude={Number(selectedAddress.longitude)}
                  >
                    <svg
                      height={MAP_MARKER_SIZE}
                      viewBox="0 0 24 24"
                      style={{
                        // cursor: 'pointer',
                        fill: '#d00',
                        stroke: 'none',
                        transform: `translate(${
                          -MAP_MARKER_SIZE / 2
                        }px,${-MAP_MARKER_SIZE}px)`,
                      }}
                    >
                      <path d={MAP_MARKER_ICON} />
                    </svg>
                  </Marker>
                ) : (
                  <></>
                )}
              </ReactMapGL>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.addressInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderAddressInfo;
