import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FavActivityData,
  FullEnumInfoType,
  GoodData,
  UserRankData,
} from '../../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import moment from 'moment';
import {
  CloseOutlined,
  DragOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import SelectGoodsModal from '../goods/selectGoods/SelectGoodsModal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  REVIEW_STATUS_FAILED,
  REVIEW_STATUS_DEFAULT,
  REVIEW_STATUS,
} from '../../constants/generalConstants';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import SellersDropdown from '../sellers/SellersDropdown';

type FavActivityModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFavActivity?: FavActivityData;
  favActivityTypes?: {
    code: string;
    description: string;
    remark: string;
  }[];
  refresh: Function;
  isSeller: boolean;
};

const FavActivityModal = ({
  visible,
  setVisible,
  selectedFavActivity,
  favActivityTypes,
  refresh,
  isSeller,
}: FavActivityModalProps) => {
  //General components
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [reviewStatus, setReviewStatus] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [selectedGoodGiftsVisible, setSelectedGoodGiftsVisible] =
    useState<boolean>(false);
  const [emptyGiftList, setEmptyGiftList] = useState<boolean>(false);
  const [warningNotification, setWarningNotification] = useState<
    'large' | 'small'
  >();
  const [undo, setUndo] = useState<{ [orig: string]: any; called: boolean }>({
    called: false,
  });
  const [selectedSeller, setSelectedSeller] = useState<number>(0);
  const [noUpperLimit, setNoUpperLimit] = useState<boolean>(false);
  //Data components
  const [filteredRankIds, setFilteredRankIds] = useState<Array<number>>([]);
  const [selectedGoodGifts, setSelectedGoodGifts] = useState<Array<GoodData>>(
    []
  );
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData[]>([]);
  const [selectedAtvType, setSelectedAtvType] = useState<FullEnumInfoType>();
  const [userRanks, setUserRanks] = useState<UserRankData[]>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getUserRanks = useCallback(() => {
    getDataWithAuthToken('users/user_rank/all', { params: { isMember: false } })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setUserRanks(response.data.list);
            if (!filteredRankIds.length) {
              let newIds: Array<number> = [];
              let oldIds: Array<number> | undefined =
                selectedFavActivity &&
                selectedFavActivity.userRankIds
                  .split(',')
                  .map((i) => parseInt(i));
              oldIds?.forEach((id) => {
                if (response.data.list.map((i: any) => i.rankId).includes(id))
                  newIds.push(id);
              });
              setFilteredRankIds(newIds);
              form.setFieldsValue({ userRankIds: newIds });
            }
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  }, [t, selectedFavActivity, filteredRankIds, form]);

  // Things to do on first render
  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (selectedFavActivity && isSubscribed.current) {
        setNoUpperLimit(!Boolean(Number(selectedFavActivity.maxAmount)));
      }
    }
  }, [form, visible, selectedFavActivity]);

  // First render, pre-load the activity type object from selected Fav Activity Obj
  useEffect(() => {
    if (
      visible &&
      !selectedAtvType &&
      selectedFavActivity &&
      favActivityTypes
    ) {
      favActivityTypes?.forEach((type) => {
        if (type.code === selectedFavActivity.actType) {
          setSelectedAtvType(type);
        }
      });
    }
  }, [visible, selectedFavActivity, selectedAtvType, favActivityTypes]);

  // First render, pre-load the sellerId ID from selected Fav Activity Obj
  useEffect(() => {
    if (
      visible &&
      selectedFavActivity &&
      selectedFavActivity.seller &&
      !selectedSeller
    ) {
      setSelectedSeller(selectedFavActivity.seller.sellerId);
    }
  }, [visible, selectedFavActivity, selectedSeller]);

  // First render, pre-load the Gifts list from selected Fav Activity Obj
  useEffect(() => {
    if (visible && !emptyGiftList) {
      if (
        selectedFavActivity &&
        selectedFavActivity.gift &&
        !selectedGoodGifts.length
      ) {
        let tempList: GoodData[] = [];
        tempList = tempList.concat(selectedFavActivity.gift);
        setSelectedGoodGifts(tempList);
      }
    }
  }, [visible, selectedFavActivity, selectedGoodGifts, emptyGiftList]);

  // First render, pre-load user rank list
  useEffect(() => {
    if (visible && userRanks === undefined) {
      hasPermission(actionPermissions.userGroup.userRank) && getUserRanks();
    }
  }, [visible, userRanks, getUserRanks]);

  const onClose = () => {
    if (isSubscribed.current) {
      setWarningNotification(undefined);
      setUserRanks(undefined);
      setReviewStatus(undefined);
      setSelectedAtvType(undefined);
      setFilteredRankIds([]);
      setSelectedGoodGifts([]);
      setUndo({ called: false });
      setEmptyGiftList(false);
      setNoUpperLimit(false);
      setVisible(false);
    }
  };
  const onCloseGiftTab = (good: GoodData) => {
    let tempArray = selectedGoodGifts.slice();
    const index = tempArray.indexOf(good, 0);
    if (selectedGoodGifts.length === 1) {
      setEmptyGiftList(true);
    }
    if (index > -1) {
      tempArray.splice(index, 1);
    }
    setSelectedGoodGifts(tempArray);
  };

  /**
   * @param values Form values
   */
  const onFinish = () => {
    const giftList: { goodsId: number; shopPrice?: string; number?: number }[] =
      [];
    if (selectedGoodGifts.length) {
      selectedGoodGifts.forEach((gift) => {
        giftList.push({
          goodsId: gift.goodsId,
          shopPrice: gift.shopPrice ? gift.shopPrice : undefined,
          number: gift.number ? gift.number : undefined,
        });
      });
    }
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        const { date, userRankIds, sellerId, gift, ...rest } = values;
        postDataWithAuthToken(
          selectedFavActivity ? 'fav_activity/edit' : 'fav_activity/add',
          {
            ...rest,
            sellerId: !selectedFavActivity
              ? isSeller
                ? values.sellerId
                : 0
              : undefined,
            gift: giftList.length ? giftList : undefined,
            actId: selectedFavActivity ? selectedFavActivity.actId : undefined,
            userRankIds: values.userRankIds.join(','),
            startTime: moment(values.date[0]).format(DATE_TIME_FORMAT),
            endTime: moment(values.date[1]).format(DATE_TIME_FORMAT),
            reviewContent:
              values.reviewStatus &&
              values.reviewStatus === REVIEW_STATUS_FAILED
                ? values.reviewContent
                : '',
            maxAmount: values.maxAmount ? values.maxAmount : 0,
          }
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedFavActivity
                ? t('activity.alerts.favActivityEdited')
                : t('activity.alerts.favActivityAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  /**
   * When the changes have been made in the newGoodObjs, it will update selectedGoodGifts. The user only sees selectedGoodGifts.
   * The merging, checking for dups will be done here
   */
  useEffect(() => {
    if (selectedGoodObjs.length) {
      let origList: GoodData[] = selectedGoodGifts.slice();
      let origIDList: number[] = selectedGoodGifts
        .slice()
        .map((origObj) => origObj.goodsId);
      selectedGoodObjs.forEach((selectedObj) => {
        if (!origIDList.includes(selectedObj.goodsId)) {
          origList.push(selectedObj);
        }
      });
      setSelectedGoodGifts(origList);
      setSelectedGoodObjs([]);
    }
  }, [selectedGoodObjs, selectedGoodGifts]);

  const SortableBar = SortableHandle(() => (
    <DragOutlined style={{ fontSize: '18px', cursor: 'grab' }} />
  ));

  const SortableItem = SortableElement(({ goodObj }: { goodObj: GoodData }) => (
    <div
      style={{
        backgroundColor: '#fafafa',
        padding: 3,
        zIndex: 9999,
        border: '1px #d9d9d9 solid',
      }}
      key={goodObj.goodsId}
    >
      <Space align="center" style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Tooltip
          key={goodObj.goodsId}
          title={
            goodObj ? (goodObj.fullGoodsName ? goodObj.fullGoodsName : '') : ''
          }
        >
          <Typography.Text>
            {goodObj
              ? goodObj.fullGoodsName
                ? goodObj.fullGoodsName
                : ''
              : ''}
          </Typography.Text>
        </Tooltip>
        <InputNumber<string>
          placeholder={t('activity.add/EditFavActivity.price')}
          precision={2}
          style={{ width: 100 }}
          min="0"
          defaultValue={
            goodObj ? (goodObj.shopPrice ? goodObj.shopPrice : '') : ''
          }
          onBlur={(e) => {
            let target = e.target as HTMLInputElement;
            selectedGoodGifts.forEach((gift) => {
              if (gift.goodsId === goodObj.goodsId) {
                gift.shopPrice = target.value;
              }
            });
          }}
        />
        <InputNumber
          placeholder={t('activity.add/EditFavActivity.number')}
          style={{ width: 125 }}
          min={1}
          max={50}
          defaultValue={goodObj && goodObj.number ? goodObj.number : 1}
          onBlur={(e) => {
            let target = e.target as HTMLInputElement;
            selectedGoodGifts.forEach((gift) => {
              if (gift.goodsId === goodObj.goodsId) {
                gift.number = parseInt(target.value);
              }
            });
          }}
        />
        <Tooltip title={t('activity.add/EditFavActivity.addGiftTitle')}>
          <Tag
            style={{
              display:
                (isSeller &&
                  selectedSeller &&
                  selectedGoodGifts.length === 0) ||
                selectedGoodGifts.length === 0
                  ? ''
                  : 'none',
            }}
            onClick={() => setSelectedGoodGiftsVisible((prev) => !prev)}
          >
            <PlusOutlined />
          </Tag>
        </Tooltip>
        <div style={{ textAlign: 'center' }}>{<SortableBar />}</div>
        <CloseOutlined onClick={() => onCloseGiftTab(goodObj)} />
      </Space>
    </div>
  ));

  const SortableList = SortableContainer(({ items }: { items: GoodData[] }) => (
    <Row gutter={[16, 16]}>
      {items.map((goodObj, index) => (
        <Col key={index}>
          <SortableItem goodObj={goodObj} index={index} />
        </Col>
      ))}
    </Row>
  ));

  return (
    <>
      <Modal
        maskClosable={false}
        onOk={onFinish}
        onCancel={onClose}
        width={550}
        bodyStyle={{ height: 'auto' }}
        title={
          selectedFavActivity
            ? `${t('activity.add/EditFavActivity.editTitle')} ${
                selectedFavActivity.actName
              }`
            : `${t('activity.add/EditFavActivity.addTitle')}`
        }
        visible={visible}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            initialValues={
              selectedFavActivity
                ? {
                    ...selectedFavActivity,
                    gift: selectedFavActivity.gift,
                    userRankIds: filteredRankIds,
                    reviewStatus: getDashboardStyle().showReviewStatus
                      ? selectedFavActivity.reviewStatus.code
                      : undefined,
                  }
                : {
                    reviewStatus: getDashboardStyle().showReviewStatus
                      ? REVIEW_STATUS_DEFAULT
                      : undefined,
                    sortOrder: 100,
                    userRankIds: [],
                  }
            }
            onFinish={onFinish}
          >
            {isSeller && !selectedFavActivity && (
              <Form.Item
                label={t('activity.add/EditFavActivity.seller')}
                name="sellerId"
                rules={[
                  {
                    required: isSeller,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <SellersDropdown
                  disabled={!isSeller}
                  onChange={(value) => {
                    setSelectedSeller(parseInt(value));
                    setSelectedGoodGifts([]);
                  }}
                />
              </Form.Item>
            )}
            <Form.Item /**For Fav Activity Name */
              name="actName"
              label={t('activity.add/EditFavActivity.actName')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <Input style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label={t('activity.favActivityColumnList.actType.title')}
              style={{
                marginBottom:
                  selectedAtvType && selectedAtvType.remark ? 16 : 0,
              }}
              required
            >
              <Space align="start">
                <Form.Item /**For Fav Activity Type */
                  name="actType"
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.pleaseSelectOne'),
                    },
                  ]}
                  help={selectedAtvType ? selectedAtvType.remark : ''}
                >
                  <Select
                    style={{ minWidth: 200 }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    filterOption={false}
                    onSelect={(value: string) => {
                      favActivityTypes?.forEach((type) => {
                        if (type.code === value) {
                          setSelectedAtvType(type);
                        }
                      });
                      form.resetFields(['actTypeExt']);
                    }}
                  >
                    {favActivityTypes &&
                      favActivityTypes.map((type) => (
                        <Select.Option key={type.description} value={type.code}>
                          {type.description}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                {(selectedAtvType || selectedFavActivity) && (
                  <Form.Item /**For Fav Activity Ext */
                    name="actTypeExt"
                    // label={t('activity.add/EditFavActivity.actTypeExt')}
                    rules={[
                      {
                        required: true,
                        message: t('general.inputError.pleaseSelectOne'),
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      precision={
                        (selectedAtvType || selectedFavActivity) &&
                        (selectedAtvType?.code === 'PRICE' ||
                          selectedFavActivity?.actType === 'PRICE')
                          ? 2
                          : 0
                      }
                      max={
                        (selectedAtvType || selectedFavActivity) &&
                        (selectedAtvType?.code === 'DISCOUNT' ||
                          selectedFavActivity?.actType === 'DISCOUNT')
                          ? 99
                          : undefined
                      }
                      min={
                        (selectedAtvType || selectedFavActivity) &&
                        (selectedAtvType?.code === 'DISCOUNT' ||
                          selectedFavActivity?.actType === 'DISCOUNT')
                          ? 1
                          : undefined
                      }
                    />
                  </Form.Item>
                )}
              </Space>
            </Form.Item>
            <Form.Item
              label={t('activity.add/EditFavActivity.userRankIds')}
              style={{ marginBottom: 0 }}
              required
            >
              <Space
                align="start" /**For UserRank Ids & Select All/Undo Button*/
              >
                <Form.Item
                  name="userRankIds"
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <Select
                    style={{ width: screens.lg ? 250 : 150 }}
                    mode="multiple"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder={t('searchPlaceholders.selectRankName')}
                    onBlur={() => {
                      setFilteredRankIds(
                        form.getFieldsValue(['userRankIds']).userRankIds
                      );
                    }}
                  >
                    {userRanks &&
                      userRanks.map((rank) => (
                        <Select.Option key={rank.rankName} value={rank.rankId}>
                          {rank.rankName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    disabled={
                      userRanks &&
                      userRanks
                        .map((i) => i.rankId)
                        .every((id) => filteredRankIds.includes(id))
                    }
                    onClick={() => {
                      if (!undo.called) {
                        setUndo({
                          data: form.getFieldValue('userRankIds'),
                          called: true,
                        });
                        form.setFieldsValue({
                          userRankIds:
                            userRanks && userRanks.map((i) => i.rankId),
                        });
                      } else if (undo.called) {
                        form.setFieldsValue({ userRankIds: undo.data });
                        setUndo({ called: false });
                      }
                    }}
                  >
                    {undo.called ? t('general.undo') : t('general.selectAll')}
                  </Button>
                </Form.Item>
              </Space>
            </Form.Item>
            <Space align="start" wrap /**For Min-Max Amount */>
              <Form.Item /**For Min Amount */
                label={t('activity.add/EditFavActivity.minAmount')}
                name="minAmount"
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseInputAmount'),
                  },
                ]}
                style={{
                  marginBottom:
                    warningNotification && warningNotification === 'large'
                      ? 16
                      : 0,
                }}
                extra={
                  <Typography.Text type="danger">
                    {warningNotification &&
                      warningNotification === 'large' &&
                      t('activity.inputError.largeWarning')}
                  </Typography.Text>
                }
              >
                <InputNumber
                  onBlur={(e) => {
                    let target = e.target as HTMLInputElement;
                    if (
                      form.getFieldValue(['maxAmount']) &&
                      parseInt(form.getFieldValue(['maxAmount'])) !== 0 &&
                      parseInt(target.value) >
                        parseInt(form.getFieldValue(['maxAmount']))
                    ) {
                      setWarningNotification('large');
                    } else {
                      setWarningNotification(undefined);
                    }
                  }}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label={t('activity.add/EditFavActivity.maxAmount')}
                required
                style={{
                  marginBottom:
                    warningNotification && warningNotification === 'small'
                      ? 16
                      : 0,
                }}
                extra={
                  <Typography.Text type="danger">
                    {warningNotification &&
                      warningNotification === 'small' &&
                      t('activity.inputError.minMaxWarning')}
                  </Typography.Text>
                }
              >
                <Space align="start" wrap>
                  {!noUpperLimit && (
                    <Form.Item /**For Max Amount */
                      name="maxAmount"
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.pleaseInputAmount'),
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber
                        onBlur={(e) => {
                          let target = e.target as HTMLInputElement;
                          if (
                            form.getFieldValue(['minAmount']) &&
                            parseInt(target.value) !== 0 &&
                            parseInt(target.value) <
                              parseInt(form.getFieldValue(['minAmount']))
                          ) {
                            setWarningNotification('small');
                          } else {
                            setWarningNotification(undefined);
                          }
                        }}
                        min={0}
                      />
                    </Form.Item>
                  )}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Checkbox
                      checked={noUpperLimit}
                      onChange={(e) => {
                        setNoUpperLimit(e.target.checked);
                        setWarningNotification(undefined);
                      }}
                    >
                      {t('activity.add/EditFavActivity.maxAmountRemark')}
                    </Checkbox>
                  </Form.Item>
                </Space>
              </Form.Item>
            </Space>
            <Form.Item /**For Date */
              name="date"
              label={`${t('activity.add/EditFavActivity.startTime')}-${t(
                'activity.add/EditFavActivity.endTime'
              )}`}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
              initialValue={
                selectedFavActivity &&
                selectedFavActivity.startTime &&
                selectedFavActivity.endTime
                  ? [
                      moment(selectedFavActivity.startTime),
                      moment(selectedFavActivity.endTime),
                    ]
                  : []
              }
            >
              <RangePicker
                style={{ width: '100%' }}
                showTime={{
                  format: 'HH:mm:ss',
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('00:00:00', 'HH:mm:ss'),
                  ],
                }}
                format={DATE_TIME_FORMAT}
                placeholder={[
                  t('activity.add/EditFavActivity.startDate'),
                  t('activity.add/EditFavActivity.endDate'),
                ]}
              />
            </Form.Item>
            {selectedAtvType && selectedAtvType.code === 'GOODS' && (
              <Form.Item /**For Gifts */
                name="gift"
                label={
                  <>
                    <Typography.Text>
                      {t('activity.add/EditFavActivity.gift')}
                      <Tooltip
                        title={t('activity.add/EditFavActivity.giftRemark')}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Typography.Text>
                  </>
                }
                rules={[
                  {
                    required: selectedGoodGifts.length > 0 ? false : true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                {selectedGoodGifts.length > 0 ? (
                  <div>
                    <div style={{ paddingBottom: 15 }}>
                      <SortableList
                        axis="y"
                        useDragHandle
                        items={selectedGoodGifts}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          setSelectedGoodGifts((prev) =>
                            arrayMoveImmutable(prev, oldIndex, newIndex)
                          );
                        }}
                      />
                    </div>
                    <div>
                      <Tooltip
                        title={t('activity.add/EditFavActivity.addGiftTitle')}
                      >
                        <Tag
                          onClick={() =>
                            setSelectedGoodGiftsVisible((prev) => !prev)
                          }
                          style={{
                            display:
                              (isSeller &&
                                selectedSeller &&
                                selectedGoodGifts.length > 0) ||
                              selectedGoodGifts.length > 0
                                ? ''
                                : 'none',
                          }}
                        >
                          <PlusOutlined />
                        </Tag>
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  <Tooltip
                    title={t('activity.add/EditFavActivity.addGiftTitle')}
                  >
                    <Tag
                      style={{
                        display:
                          (isSeller &&
                            selectedSeller &&
                            selectedGoodGifts.length === 0) ||
                          selectedGoodGifts.length === 0
                            ? ''
                            : 'none',
                      }}
                      onClick={() =>
                        setSelectedGoodGiftsVisible((prev) => !prev)
                      }
                    >
                      <PlusOutlined />
                    </Tag>
                  </Tooltip>
                )}
              </Form.Item>
            )}
            <Form.Item /**For Sort Order */
              name="sortOrder"
              label={t('activity.add/EditFavActivity.sortOrder')}
            >
              <InputNumber min={0} max={50000} />
            </Form.Item>
            {getDashboardStyle().showReviewStatus && (
              <Form.Item /**For Review Status */
                label={t('activity.add/EditFavActivity.reviewStatus')}
                name="reviewStatus"
              >
                <Radio.Group
                  onChange={(e) => {
                    form.resetFields(['reviewContent']);
                    setReviewStatus(e.target.value);
                  }}
                >
                  {Object.keys(REVIEW_STATUS).map((type) => {
                    return (
                      <Radio key={type} value={type}>
                        {t(REVIEW_STATUS[type])}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            )}
            {getDashboardStyle().showReviewStatus &&
              reviewStatus ===
                REVIEW_STATUS_FAILED /**For Review Content */ && (
                <Form.Item
                  name="reviewContent"
                  label={t('activity.add/EditFavActivity.reviewContent')}
                  rules={[
                    {
                      required: reviewStatus === REVIEW_STATUS_FAILED,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 4 }} />
                </Form.Item>
              )}
          </Form>
        </Spin>
      </Modal>
      <SelectGoodsModal
        firstLoad
        newGoodObjs={selectedGoodObjs}
        setNewGoodObjs={setSelectedGoodObjs}
        visible={selectedGoodGiftsVisible}
        setVisible={setSelectedGoodGiftsVisible}
        sellerId={
          selectedFavActivity
            ? selectedFavActivity.seller.sellerId
            : selectedSeller
        }
        hideSKU={true}
        multiSelectFeature={true}
      />
    </>
  );
};

export default FavActivityModal;
