import { useState } from 'react';
import { SalespersonData } from '../../types';
import { useTranslation } from 'react-i18next';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { Select, Typography } from 'antd';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import { DefaultOptionType } from 'antd/lib/select';

type SalespersonDropdownProps = {
  disabled?: boolean;
  labelInValue?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const SalespersonDropdown = ({
  disabled = false,
  labelInValue = false,
  initialValue,
  onChange,
}: SalespersonDropdownProps) => {
  const { t } = useTranslation();
  const [salespersons, setSalespersons] = useState<SalespersonData[]>([]);
  const [pageSalesperson, setPageSalesperson] = useState<number>(2);
  const [totalPageSalesperson, setTotalPageSalesperson] = useState<number>(2);

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});

  const getSalespersons = async (value?: string) => {
    getDataWithAuthToken('sales_person/list', {
      params: { keyword: value || undefined },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setSalespersons(response.data.list);
          setPageSalesperson(2);
          setTotalPageSalesperson(response.data.totalPage);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchSalespersonOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (pageSalesperson > totalPageSalesperson) return;
      getDataWithAuthToken('sales_person/list', {
        params: {
          page: pageSalesperson,
          keyword: searchText.salesperson || undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setSalespersons((prev) => [...prev, ...response.data.list]);
            setPageSalesperson((prev) => prev + 1);
            setTotalPageSalesperson(response.data.totalPage);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={getDashboardStyle().isSalesForceAppType || disabled}
      allowClear
      showSearch
      placeholder={t('searchPlaceholders.searchSalespersonKeyword')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onFocus={() => {
        !salespersons.length && getSalespersons();
      }}
      onPopupScroll={fetchSalespersonOnScroll}
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, salesperson: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getSalespersons(value), EXTENDED_TIMEOUT)
        );
      }}
      filterOption={false}
      labelInValue={labelInValue}
      defaultValue={initialValue ? initialValue : undefined}
      onChange={handleOnChange}
      optionLabelProp="key"
    >
      {salespersons &&
        salespersons.length > 0 &&
        salespersons.map((salesperson) => (
          <Select.Option
            key={`${salesperson.fullName} (${salesperson.salesPersonId})`}
            value={salesperson.salesPersonId}
          >
            <div>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Text type="secondary">
                  {t('salesperson.salesPersonId')}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {t('salesperson.fullName')}
                </Typography.Text>
              </span>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{salesperson.salesPersonId}</Typography>
                <Typography>{salesperson.fullName}</Typography>
              </span>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
};

export default SalespersonDropdown;
