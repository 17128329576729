import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Menu,
  Popconfirm,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import CouponModal from '../../components/activity/CouponModal';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { REVIEW_STATUS } from '../../constants/generalConstants';
import { dashboardRoute } from '../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import { CouponData, CouponEnum, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Coupons List Table
 * Can add, or delete coupons
 *
 * /dashboard/coupon/list
 *
 */
const CouponList = () => {
  //General Components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { addTab } = useTab();
  const formRef = useRef(null);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const isSubscribed = useRef(true);
  const screens = useBreakpoint();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  //Data Components
  const [couponData, setCouponData] = useState<Array<CouponData>>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<CouponData>();
  const [couponEnum, setCouponEnum] = useState<CouponEnum>();
  //Table Components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [total, setTotal] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'couponId',
    'seller',
    'couponType',
    'couponName',
    'couponCode',
    'couponTitle',
    'couponUsageLimit',
    'couponMoney',
    'couponDesc',
    'couponStatus',
    'reviewStatus',
    'reviewContent',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter(
      (key) =>
        key !== 'couponTitle' && key !== 'couponDesc' && key !== 'reviewContent'
    )
  );

  const columns: ColumnsType<CouponData> = [
    {
      title: setFont(t('activity.couponColumnList.couponId'), fontSize),
      dataIndex: 'couponId',
      key: 'couponId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: CouponData, b: CouponData) => compare(a.couponId, b.couponId),
      render: (text: string, record: CouponData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            !hasPermission(actionPermissions.activityGroup.couponManage)
          }
          onClick={() => {
            setSelectedCoupon(record);
            setModalVisible(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('activity.couponColumnList.seller'), fontSize),
      dataIndex: 'seller',
      key: 'seller',
      width: 120,
      render: (text: string, record: CouponData) => (
        <Button
          type="link"
          style={{
            padding: 0,
          }}
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          onClick={() => {
            addTab(
              ``,
              `${dashboardRoute.sellers.detail}?seller_id=${record.seller.sellerId}`
            );
          }}
        >
          {record.seller && record.seller.shopName
            ? record.seller.shopName
            : record.seller.sellerId}
        </Button>
      ),
    },
    {
      title: setFont(t('activity.couponColumnList.couponType'), fontSize),
      dataIndex: 'couponType',
      key: 'couponType',
      width: 120,
      render: (text: string) =>
        couponEnum?.couponType.map((type, index) => {
          if (type.code === text) {
            return (
              <Typography.Text style={{ fontSize: fontSize }} key={index}>
                {type.description}
              </Typography.Text>
            );
          }
          return <Typography.Text key={index}>{''}</Typography.Text>;
        }),
    },
    {
      title: setFont(t('activity.couponColumnList.couponName'), fontSize),
      dataIndex: 'couponName',
      key: 'couponName',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.couponColumnList.couponTitle'), fontSize),
      dataIndex: 'couponTitle',
      key: 'couponTitle',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('activity.couponColumnList.couponCode'), fontSize),
      dataIndex: 'couponCode',
      key: 'couponCode',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.couponColumnList.couponUsageLimit'), fontSize),
      dataIndex: 'couponUsageLimit',
      key: 'couponUsageLimit',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.couponColumnList.couponMoney'), fontSize),
      dataIndex: 'couponMoney',
      key: 'couponMoney',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.couponColumnList.couponStatus'), fontSize),
      dataIndex: 'couponStatus',
      key: 'couponStatus',
      width: 120,
      render: (text: string) =>
        couponEnum?.activityStatus.map((type, index) => {
          if (type.code === text) {
            return (
              <Typography.Text style={{ fontSize: fontSize }} key={index}>
                {type.description}
              </Typography.Text>
            );
          }
          return <Typography.Text key={index}>{''}</Typography.Text>;
        }),
    },
    {
      title: setFont(t('activity.couponColumnList.couponDesc'), fontSize),
      dataIndex: 'couponDesc',
      key: 'couponDesc',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('activity.couponColumnList.reviewStatus'), fontSize),
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      width: 120,
      render: (text: string, record: CouponData) =>
        record.reviewStatus && (
          <Tag
            style={{ fontSize: fontSize }}
            color={record.reviewStatus.tagColor}
          >
            {record.reviewStatus.description}
          </Tag>
        ),
    },
    {
      title: setFont(t('activity.couponColumnList.reviewContent'), fontSize),
      dataIndex: 'reviewContent',
      key: 'reviewContent',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: CouponData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.activityGroup.couponManage)
            }
            onClick={() => {
              setSelectedCoupon(record);
              setModalVisible(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.couponId])
                setMorePopoverVis({ [record.couponId]: flag });
            }}
            visible={morePopoverVis[record.couponId]}
            overlay={
              <Menu>
                <Menu.Item
                  key="viewUsage"
                  style={{ fontSize: fontSize }}
                  disabled={
                    !hasPermission(actionPermissions.activityGroup.couponManage)
                  }
                  onClick={() => {
                    setMorePopoverVis({ [record.couponId]: false });
                    addTab(
                      `${t(`activity.userCouponList`)} ${record.couponName}`,
                      `${dashboardRoute.activity.userCouponList}?coupon_id=${record.couponId}`
                    );
                  }}
                >
                  {t('actionsColumn.viewUsage')}
                </Menu.Item>
                {actionPermissions.activityGroup.couponManage && (
                  <Menu.Item key="delete">
                    <Popconfirm
                      title={t('actionsColumn.deleteWarning')}
                      onConfirm={() => {
                        deleteCoupon(record.couponId);
                        setMorePopoverVis({ [record.couponId]: false });
                      }}
                      okText={t('actionsColumn.confirmation.yes')}
                      cancelText={t('actionsColumn.confirmation.no')}
                      placement="leftBottom"
                    >
                      <Typography.Text type="danger">
                        {t('actionsColumn.delete')}
                      </Typography.Text>
                    </Popconfirm>
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.couponId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Fetches coupons
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`coupon/list`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword ? keyword : undefined,
        isSeller: isSeller,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        couponType:
          formRef.current && form.getFieldValue('couponType')
            ? form.getFieldValue('couponType') === 'all'
              ? undefined
              : form.getFieldValue('couponType')
            : undefined,
        reviewStatus:
          formRef.current && form.getFieldValue('reviewStatus')
            ? form.getFieldValue('reviewStatus') === 'all'
              ? undefined
              : form.getFieldValue('reviewStatus')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setCouponData(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, form, isSeller]);

  const getCouponEnumData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`coupon/enum_list`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setCouponEnum(response.data);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getCouponEnumData();
  }, [getCouponEnumData]);

  /**
   * @param id Id of coupon to delete
   */
  const deleteCoupon = (id: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('coupon/delete', { couponId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('activity.alerts.couponDeleted'));
          getData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('activity.userCouponColumnList.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ couponType: 'all', reviewStatus: 'all' }}
        >
          {isSeller && (
            <Form.Item
              label={t('activity.couponColumnList.advancedSearch.sellerId')}
              name="sellerId"
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          <Form.Item
            label={t('activity.couponColumnList.advancedSearch.couponType')}
            name="couponType"
          >
            <Select
              placeholder={t('general.inputError.pleaseSelectOne')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  couponType: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t('general.all')}
              </Select.Option>
              {couponEnum?.couponType.map((couponType) => (
                <Select.Option
                  key={couponType.description}
                  value={couponType.code}
                >
                  {couponType.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('activity.couponColumnList.advancedSearch.reviewStatus')}
            name="reviewStatus"
          >
            <Select
              placeholder={t('general.inputError.pleaseSelectOne')}
              showSearch={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  reviewStatus: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t('general.all')}
              </Select.Option>
              {Object.keys(REVIEW_STATUS).map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {t(REVIEW_STATUS[type])}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('activity.couponColumnList.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('activity.couponColumnList.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('activity.userCouponColumnList.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('activity.couponList')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={loading}
                checkedChildren={t('activity.couponColumnList.seller')}
                unCheckedChildren={t('activity.couponColumnList.self')}
                style={{ marginBottom: 12 }}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                      setPage(1);
                    }, EXTENDED_TIMEOUT)
                  );
                  if (!checked) {
                    formRef.current && form.resetFields(['sellerId']);
                    setAdvance((prev: any) => ({
                      ...prev,
                      sellerId: undefined,
                    }));
                  }
                }}
              />
            )}
          </Space>
          <TableToolbar
            advancedSearch={advancedSearch}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.activityGroup.couponManage)
                }
                onClick={() => {
                  setModalVisible(true);
                  setSelectedCoupon(undefined);
                }}
              >
                {t('activity.add/EditCoupon.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            fontSize={fontSize}
            setFontSize={setFontSize}
            refresh={() => getData()}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchCoupon')}
            rows={couponData.map((coupon) => ({
              ...coupon,

              seller: coupon.seller ? coupon.seller.shopName : '',
            }))}
            exportConfig={{ fileName: 'COUPON_LIST' }}
          />
          <Table<CouponData>
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            dataSource={couponData}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={loading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowKey={(coupon) => coupon.couponId}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'COUPON_LIST' } }}
              selectedRows={[]}
            />
          )}
        </Card>
      )}
      <CouponModal
        visible={modalVisible}
        setVisible={setModalVisible}
        couponEnum={couponEnum}
        selectedCoupon={selectedCoupon}
        refresh={() => getData()}
        isSeller={isSeller}
      />
    </Container>
  );
};

export default CouponList;
