import { useState } from 'react';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { useTranslation } from 'react-i18next';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { Typography } from 'antd';

type TransportDropdownProps = {
  sellerId?: string | number;
  labelInValue?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const TransportDropdown = ({
  sellerId,
  labelInValue = false,
  initialValue,
  onChange,
}: TransportDropdownProps) => {
  const { t } = useTranslation();
  const [transportListData, setTransportListData] = useState<any[]>([]);
  const [transportPage, setTransportPage] = useState(2);
  const [transportTotalPage, setTransportTotalPage] = useState(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getTransportList = (value?: string) => {
    getDataWithAuthToken('shipping/transport/list', {
      params: {
        sellerId: sellerId,
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setTransportListData(response.data.list);
          setTransportPage(2);
          setTransportTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * Fetch when transport list scrolls to the bottom
   * @param e UI event
   */
  const transportListFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (transportPage > transportTotalPage) return;
      getDataWithAuthToken('shipping/transport/list', {
        params: {
          sellerId: sellerId,
          keyword: searchText.transport || undefined,
          page: transportPage,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setTransportListData((prev) => [...prev, ...response.data.list]);
            setTransportPage((prev) => prev + 1);
            setTransportTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      labelInValue={labelInValue}
      defaultValue={initialValue ? initialValue : undefined}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      placeholder={t('searchPlaceholders.searchTransport')}
      showSearch
      allowClear
      onPopupScroll={transportListFetchOnScroll}
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, transport: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getTransportList(value), EXTENDED_TIMEOUT)
        );
      }}
      onFocus={() => {
        if (!transportListData.length) getTransportList();
      }}
      filterOption={false}
      optionLabelProp="key"
      onChange={handleOnChange}
    >
      {transportListData.map((transportData) => (
        <Select.Option
          key={`${transportData.transportName} (${transportData.transportId})`}
          value={transportData.transportId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('settings.transListColumns.transportId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('settings.transListColumns.transportName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{transportData.transportId}</Typography>
              <Typography>{transportData.transportName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default TransportDropdown;
