import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import PaymentMethodModal from '../../components/settings/PaymentMethodModal';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import {
  BasicEnumInfoType,
  FontSizeType,
  PaymentEnum,
  PaymentMethod,
  SellerData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const PaymentSetting = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [paymentEnum, setPaymentEnum] = useState<PaymentEnum>();
  const [editingPaymentMethod, setEditingPaymentMethod] =
    useState<PaymentMethod>();
  const [searchAdvance, setSearchAdvance] = useState<{
    [key: string]: string | number;
  }>({});
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const screens = Grid.useBreakpoint();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [keyword, setKeyword] = useState<string>('');
  const columnKeys: string[] = [
    'payId',
    'payName',
    'payDesc',
    'payFee',
    'payStatus',
    'isPayOnDelivery',
    'isOnline',
    'sortOrder',
    'actions',
    'seller',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);

  const columns: ColumnsType<PaymentMethod> = [
    {
      title: setFont('ID', fontSize),
      key: 'payId',
      dataIndex: 'payId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: number, record: PaymentMethod) => (
        <Button
          type="link"
          disabled={
            !hasPermission(actionPermissions.settingGroup.paymentConfig)
          }
          size="small"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setShowModal(true);
            setEditingPaymentMethod(record);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('settings.paymentColumns.seller'), fontSize),
      key: 'seller',
      dataIndex: 'seller',
      width: 100,
      render: (record: SellerData) =>
        record && record.shopName ? setFont(record.shopName, fontSize) : '',
    },
    {
      title: setFont(t('settings.paymentColumns.payName'), fontSize),
      key: 'payName',
      dataIndex: 'payName',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.paymentColumns.payDesc'), fontSize),
      key: 'payDesc',
      dataIndex: 'payDesc',
      render: (text: string) => (
        <div style={{ overflow: 'auto', maxHeight: 100 }}>
          {setFont(text, fontSize)}
        </div>
      ),
    },
    {
      title: setFont(t('settings.paymentColumns.payFee'), fontSize),
      key: 'payFee',
      dataIndex: 'payFee',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.paymentColumns.payStatus'), fontSize),
      key: 'payStatus',
      dataIndex: 'status',
      width: 120,
      render: (value: BasicEnumInfoType) =>
        value && setFont(value.description, fontSize),
    },
    {
      title: setFont(t('settings.paymentColumns.isPayOnDelivery'), fontSize),
      key: 'isPayOnDelivery',
      dataIndex: 'isPayOnDelivery',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('settings.paymentColumns.isOnline'), fontSize),
      key: 'isOnline',
      dataIndex: 'isOnline',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('settings.paymentColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      render: (text: number) => setFont(text.toString(), fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 80,
      render: (record: PaymentMethod) => (
        <Space>
          <Button
            type="link"
            size="small"
            disabled={
              !hasPermission(actionPermissions.settingGroup.paymentConfig)
            }
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setShowModal(true);
              setEditingPaymentMethod(record);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="leftTop"
            onConfirm={() => onDelete(record)}
            disabled={
              !hasPermission(actionPermissions.settingGroup.paymentConfig)
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.settingGroup.paymentConfig)
              }
            >
              <Typography.Text type="danger">
                {t('actionsColumn.delete')}
              </Typography.Text>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onDelete = (paymentMethod: PaymentMethod) => {
    setIsLoading(true);
    postDataWithAuthToken('payment/delete', { payId: paymentMethod.payId })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            alertMessage('success', t('settings.alerts.paymentMethodDeleted'));
            getPaymentMethods();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getPaymentEnum = useCallback(() => {
    getDataWithAuthToken('payment/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setPaymentEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => console.log(err));
  }, [t]);

  const getPaymentMethods = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('payment/list', {
      params: {
        isSeller: isSeller,
        sellerId: formRef.current && form.getFieldValue('sellerId'),
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setPaymentMethods(response.data.list);
            setTotalItems(response.data.list.length);
            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else setFiveHundred(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [t, isSeller, form, keyword]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    getPaymentEnum();
  }, [getPaymentEnum]);

  const advancedSearch = (
    <Popover
      title={t('goods.goodsListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          <Form.Item
            label={t('goods.goodsListColumns.advancedSearch.sellerId')}
            name="sellerId"
            style={{ marginBottom: 12 }}
          >
            <SellersDropdown
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            />
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => getPaymentMethods(), GENERAL_TIMEOUT)
                );
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(searchAdvance).every(
                (value) => value !== undefined && !value
              )}
              onClick={() => {
                form.resetFields();
                setSearchAdvance({});
                getPaymentMethods();
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Button icon={<FilterOutlined />}>
        {t('goods.goodsListColumns.advancedSearch.title')}
      </Button>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('settings.paymentSettings')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                disabled={
                  !hasPermission(actionPermissions.settingGroup.paymentConfig)
                }
                loading={isLoading}
                checkedChildren={t('goods.goodsListColumns.seller')}
                unCheckedChildren={t('goods.goodsListColumns.self')}
                style={{ marginBottom: 12 }}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                    }, EXTENDED_TIMEOUT)
                  );
                }}
              />
            )}
          </Space>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                disabled={
                  !hasPermission(actionPermissions.settingGroup.paymentConfig)
                }
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditingPaymentMethod(undefined);
                  setShowModal(true);
                }}
              >
                {t('settings.add/editPayment.addTitle')}
              </Button>
            }
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            tableSize={tableSize}
            setTableSize={setTableSize}
            totalItems={totalItems}
            refresh={() => getPaymentMethods()}
            advancedSearch={isSeller ? advancedSearch : undefined}
            search={(text) => setKeyword(text)}
          />
          <Table
            loading={isLoading}
            dataSource={paymentMethods}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(record) => record.payId}
            size={tableSize}
            pagination={false}
          />
        </Card>
      )}
      <PaymentMethodModal
        visible={showModal}
        setVisible={setShowModal}
        paymentInfo={editingPaymentMethod}
        isSeller={isSeller}
        callBack={() => getPaymentMethods()}
        paymentEnum={paymentEnum}
      />
    </Container>
  );
};

export default PaymentSetting;
