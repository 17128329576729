import React, { useEffect, useState, useCallback, useRef } from 'react';
import Container from '../../components/Container';
import { Card, Tabs, Spin, Empty } from 'antd';
import ShopConfigForm from '../../components/settings/ShopConfigForm';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { ShopConfigData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { useTranslation } from 'react-i18next';
import FourZeroThree from '../../components/FourZeroThree';
import FiveHundred from '../../components/FiveHundred';

const { TabPane } = Tabs;

type ShopConfigLayoutProps = {
  group: string;
  title?: string;
};

/**
 * Default Layout for Shop Config
 *
 * @param group Group of the Shop Config we want
 * @param title Title of page
 */
const ShopConfigLayout = ({ group, title }: ShopConfigLayoutProps) => {
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [shopConfigData, setShopConfigData] = useState<Array<ShopConfigData>>(
    []
  );
  const [loading, setLoading] = useState(false);
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`setting/shop_config/list?group=${group}`)
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus) {
            setShopConfigData(response.data.shopConfig);
          } else if (response && response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  }, [t, group]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          {title && <h1 style={{ fontSize: 24 }}>{title}</h1>}
          <Spin spinning={loading}>
            {shopConfigData.length > 0 || loading ? (
              <Tabs defaultActiveKey="shop_info">
                {shopConfigData.map((group) => (
                  <TabPane tab={group.description} key={group.code}>
                    <ShopConfigForm
                      formInfo={group.children}
                      callBack={() => getData()}
                      setLoading={setLoading}
                    />
                  </TabPane>
                ))}
              </Tabs>
            ) : (
              <Empty />
            )}
          </Spin>
        </Card>
      )}
    </Container>
  );
};

export default ShopConfigLayout;
