import { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Spin, Checkbox, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';

type AddRegionModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  refresh?: Function;
  parentId: number;
};

const AddRegionModal = ({
  showModal,
  setShowModal,
  refresh,
  parentId,
}: AddRegionModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken('setting/region/add', {
          regionName: values.regionName,
          parentId: parentId,
          postalCode: values.postalCode || '',
          sortOrder: isNaN(values.sortOrder) ? 100 : values.sortOrder,
          isShow: values.isShow || false,
        })
          .then((result) => {
            if (result && result.goodStatus) {
              if (isSubscribed.current) {
                setIsLoading(false);
                setShowModal(false);
              }
              if (refresh) refresh();

              alertMessage('success', t('settings.alerts.regionAdded'));
            } else {
              if (isSubscribed.current) setIsLoading(false);
              alertMessage(
                'error',
                result?.msg || t('general.noResponse'),
                result?.data || undefined
              );
            }
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCancel = () => {
    if (isSubscribed.current) setShowModal(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={t('settings.add/editRegion.addTitle')}
      visible={showModal}
      confirmLoading={isLoading}
      afterClose={onReset}
      okType="primary"
      okText={t('settings.add/editRegion.ok')}
      onOk={onFinish}
      onCancel={onCancel}
      cancelText={t('settings.add/editRegion.cancel')}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="regionName"
            label={t('settings.add/editRegion.regionName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="sortOrder"
            label={t('settings.add/editRegion.sortOrder')}
            initialValue={100}
          >
            <InputNumber
              type="number"
              min={0}
              max={50000}
              placeholder="0-50000"
            />
          </Form.Item>
          <Form.Item name="isShow" valuePropName="checked">
            <Checkbox>{`${t('settings.add/editRegion.isShow')}?`}</Checkbox>
          </Form.Item>
          {parentId === 4 && (
            <Form.Item
              name="postalCode"
              label={t('settings.add/editRegion.postalCode')}
            >
              <Input maxLength={3} />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddRegionModal;
