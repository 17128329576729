import { Form, Input, InputNumber, Modal, Select, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_CODE } from '../../constants/orderConstants';
import {
  FIELD_TYPE,
  FIELD_TYPE_GROUP,
} from '../../constants/settingsConstants';
import { FormFieldData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import SellersDropdown from '../sellers/SellersDropdown';

type FormFieldModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  fieldInfo?: FormFieldData;
  action: 'add' | 'edit';
  refresh?: () => void;
  isSeller?: boolean;
};

const FormFieldModal = ({
  visible,
  setVisible,
  fieldInfo,
  action,
  refresh,
  isSeller,
}: FormFieldModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible, form]);

  const onClose = () => {
    setVisible(false);
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken(
          action === 'add' ? 'form_field/add' : 'form_field/edit',
          {
            ...values,
            sellerId:
              fieldInfo && fieldInfo.fieldType === FIELD_TYPE_GROUP
                ? fieldInfo.sellerId
                : values.sellerId || 0,
            parentId: fieldInfo
              ? fieldInfo.fieldType === FIELD_TYPE_GROUP
                ? fieldInfo.fieldId
                : fieldInfo.parentId
              : 0,
            fieldType: values.fieldType || FIELD_TYPE_GROUP,
            fieldId:
              fieldInfo && action === 'edit' ? fieldInfo.fieldId : undefined,
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                action === 'add'
                  ? values.fieldType
                    ? alertMessage('success', t('settings.alerts.formAdded'))
                    : alertMessage('success', t('settings.alerts.fieldAdded'))
                  : fieldInfo && fieldInfo.fieldType === FIELD_TYPE_GROUP
                  ? alertMessage('success', t('settings.alerts.formEdited'))
                  : alertMessage('success', t('settings.alerts.fieldEdited'));
                refresh && refresh();
                onClose();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t('settings.add/editFormField.ok')}
      cancelText={t('settings.add/editFormField.cancel')}
      title={
        fieldInfo
          ? fieldInfo.fieldType === FIELD_TYPE_GROUP
            ? action === 'add'
              ? t('settings.add/editFormField.addFieldTitle')
              : t('settings.add/editFormField.editTitle')
            : t('settings.add/editFormField.editFieldTitle')
          : t('settings.add/editFormField.addTitle')
      }
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            fieldInfo && action !== 'add'
              ? {
                  ...fieldInfo,
                  isRequired: fieldInfo.isRequired || false,
                  isShow: fieldInfo.isShow || false,
                  sortOrder: fieldInfo.sortOrder || 1,
                }
              : { isRequired: false, isShow: false, sortOrder: 1 }
          }
        >
          {!fieldInfo && isSeller && action === 'add' && (
            <Form.Item
              label={t('goods.goodsListColumns.advancedSearch.sellerId')}
              name="sellerId"
              rules={[{ required: true }]}
            >
              <SellersDropdown />
            </Form.Item>
          )}
          <Form.Item
            label={
              fieldInfo &&
              (fieldInfo.fieldType !== FIELD_TYPE_GROUP ||
                (fieldInfo.fieldType === FIELD_TYPE_GROUP && action === 'add'))
                ? t('settings.formFieldColumns.englishFieldName')
                : t('settings.formFieldColumns.englishFormName')
            }
            name="englishFieldName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              fieldInfo &&
              (fieldInfo.fieldType !== FIELD_TYPE_GROUP ||
                (fieldInfo.fieldType === FIELD_TYPE_GROUP && action === 'add'))
                ? t('settings.formFieldColumns.chineseFieldName')
                : t('settings.formFieldColumns.chineseFormName')
            }
            name="chineseFieldName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {fieldInfo &&
            (fieldInfo.fieldType !== FIELD_TYPE_GROUP ||
              (fieldInfo.fieldType === FIELD_TYPE_GROUP &&
                action === 'add')) && (
              <Form.Item
                label={t('settings.formFieldColumns.fieldType')}
                name="fieldType"
                rules={[{ required: true }]}
              >
                <Select>
                  {Object.keys(FIELD_TYPE).map((key) => (
                    <Select.Option
                      value={FIELD_TYPE[key].code}
                      key={FIELD_TYPE[key].code}
                    >
                      {`${FIELD_TYPE[key].code} (${t(
                        FIELD_TYPE[key].description
                      )})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          {!fieldInfo ||
          (fieldInfo &&
            fieldInfo.fieldType === FIELD_TYPE_GROUP &&
            action === 'edit') ? (
            <Form.Item
              label={t('settings.formFieldColumns.formCode')}
              name="fieldCode"
              rules={[{ required: true }]}
            >
              <Select>
                {Object.keys(FIELD_CODE).map((key) => (
                  <Select.Option value={FIELD_CODE[key]} key={FIELD_CODE[key]}>
                    {FIELD_CODE[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label={t('settings.formFieldColumns.fieldCode')}
              name="fieldCode"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label={t('settings.formFieldColumns.isRequired')}
            valuePropName="checked"
            name="isRequired"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t('settings.formFieldColumns.isShow')}
            valuePropName="checked"
            name="isShow"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t('settings.formFieldColumns.sortOrder')}
            name="sortOrder"
          >
            <InputNumber
              type="number"
              min={0}
              max={50000}
              placeholder="0 - 50000"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default FormFieldModal;
