import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import Container from '../../components/Container';
import {
  Badge,
  Button,
  Card,
  Grid,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Form from 'antd/lib/form';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import EmployeeModal from '../../components/sortationSystem/EmployeeModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  DEFAULT_SIZE_TYPE,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
  BasicEnumInfoType,
  EmployeeData,
  EmployeeEnum,
  FontSizeType,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const EmployeeList = () => {
  // General components

  const isSubscribed = useRef(true);
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  // Data components
  const [keyword, setKeyword] = useState<string>('');
  const [employeeList, setEmployeeList] = useState<EmployeeData[]>([]);
  const [employeeEnum, setEmployeeEnum] = useState<EmployeeEnum>();
  const [editingItem, setEditingItem] = useState<EmployeeData>();
  const [advance, setAdvance] = useState<{ [key: string]: string }>({});
  // Pagination components
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItems, setTotalItems] = useState<number>(0);
  // Table components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<EmployeeData[]>([]);
  const columnKeys = [
    'employeeId',
    'nickname',
    'username',
    'mobile',
    'activeCode',
    'currentEmployeeType',
    'status',
    'token',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<EmployeeData> = [
    {
      title: setFont(
        t('sortationSystem.employeeListColumns.employeeId'),
        fontSize
      ),
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 70,
      fixed: screens.lg ? 'left' : undefined,
      render: (value: string, record: EmployeeData) => (
        <Button
          type="link"
          size="small"
          disabled={
            !hasPermission(actionPermissions.sortationGroup.employeeManage)
          }
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setEditingItem(record);
            setShowModal(true);
          }}
        >
          {value}
        </Button>
      ),
    },
    {
      title: setFont(
        t('sortationSystem.employeeListColumns.nickname'),
        fontSize
      ),
      key: 'nickname',
      dataIndex: 'nickname',
      width: 100,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeListColumns.username'),
        fontSize
      ),
      key: 'username',
      dataIndex: 'username',
      width: 150,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('sortationSystem.employeeListColumns.mobile'), fontSize),
      key: 'mobile',
      dataIndex: 'mobile',
      width: 100,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeListColumns.activeCode'),
        fontSize
      ),
      key: 'activeCode',
      dataIndex: 'activeCode',
      width: 100,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(
        t('sortationSystem.employeeListColumns.employeeType'),
        fontSize
      ),
      key: 'currentEmployeeType',
      dataIndex: 'currentEmployeeType',
      width: 300,
      render: (value: BasicEnumInfoType, record) => (
        <Space wrap size={0}>
          {record.employeeTypeList.length > 0 &&
            record.employeeTypeList.map((type, index) => {
              return value.code === type.code ? (
                <Tag
                  key={index}
                  style={{ fontSize: fontSize, fontWeight: 500 }}
                >
                  {type.description}
                </Tag>
              ) : (
                <Typography.Text
                  key={index}
                  style={{
                    fontSize: fontSize,
                    marginRight: 8,
                  }}
                >
                  {type.description}
                </Typography.Text>
              );
            })}
        </Space>
      ),
    },
    {
      title: setFont(t('sortationSystem.employeeListColumns.status'), fontSize),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      render: (value) => value && setFont(value.description, fontSize),
    },
    {
      title: setFont(t('sortationSystem.employeeListColumns.token'), fontSize),
      key: 'token',
      dataIndex: 'token',
      width: 350,
      render: (text: string, record) => (
        <Space>
          {record.deviceToken && (
            <Typography.Text
              style={{
                fontSize: fontSize,
              }}
            >
              {`${t('sortationSystem.employeeListColumns.deviceToken')}: ${
                record.deviceToken
              }`}
            </Typography.Text>
          )}
          {record.expoToken && (
            <Typography.Text
              style={{
                fontSize: fontSize,
              }}
            >
              {`${t('sortationSystem.employeeListColumns.expoToken')}: ${
                record.expoToken
              }`}
            </Typography.Text>
          )}
        </Space>
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 80,
      render: (record: EmployeeData) => (
        <Space>
          <Button
            type="link"
            size="small"
            disabled={
              !hasPermission(actionPermissions.sortationGroup.employeeManage)
            }
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditingItem(record);
              setShowModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="leftTop"
            onConfirm={() => onDelete(record.employeeId)}
            disabled={
              !hasPermission(actionPermissions.sortationGroup.employeeManage)
            }
          >
            <Button
              type="link"
              disabled={
                !hasPermission(actionPermissions.sortationGroup.employeeManage)
              }
              style={{ padding: 0, fontSize: fontSize }}
            >
              <Typography.Text type="danger">
                {t('actionsColumn.delete')}
              </Typography.Text>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);
  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('employee/list', {
      params: {
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        username:
          formRef.current && form.getFieldValue('username')
            ? form.getFieldValue('username')
            : undefined,
        mobile:
          formRef.current && form.getFieldValue('mobile')
            ? form.getFieldValue('mobile')
            : undefined,
        employeeStatus:
          formRef.current && form.getFieldValue('employeeStatus')
            ? form.getFieldValue('employeeStatus')
            : undefined,
        employeeType:
          formRef.current && form.getFieldValue('employeeType')
            ? form.getFieldValue('employeeType')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLoading(false);
          setEmployeeList(response.data.list);
          setTotalItems(response.data.totalItem);

          // Scroll to top when data changes
          tableScrollToTop();
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t, keyword, page, pageSize, form]);

  const getEnum = useCallback(() => {
    getDataWithAuthToken('employee/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setEmployeeEnum(response.data);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  const onDelete = (id: number) => {
    setIsLoading(true);
    postDataWithAuthToken('employee/delete', {
      employeeId: id,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('sortationSystem.alerts.employeeDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getEnum();
  }, [getEnum]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('sortationSystem.employeeListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all' }}
        >
          <Form.Item
            name="username"
            label={t(
              'sortationSystem.employeeListColumns.advancedSearch.username'
            )}
            style={{ marginBottom: 12 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={t(
              'sortationSystem.employeeListColumns.advancedSearch.mobile'
            )}
            style={{ marginBottom: 12 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="employeeStatus"
            label={t(
              'sortationSystem.employeeListColumns.advancedSearch.employeeStatus'
            )}
            style={{ marginBottom: 12 }}
          >
            <Select
              style={{ width: '100%' }}
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  employeeStatus: value,
                }));
              }}
            >
              {employeeEnum &&
                employeeEnum.employeeStatus &&
                employeeEnum.employeeStatus.map((status) => (
                  <Select.Option key={status.code} value={status.code}>
                    {status.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="employeeType"
            label={t(
              'sortationSystem.employeeListColumns.advancedSearch.employeeType'
            )}
            style={{ marginBottom: 12 }}
          >
            <Select
              style={{ width: '100%' }}
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  employeeType: value,
                }));
              }}
            >
              {employeeEnum &&
                employeeEnum.employeeType &&
                employeeEnum.employeeType.map((status) => (
                  <Select.Option key={status.code} value={status.code}>
                    {status.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                setKeyword('');
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('sortationSystem.employeeListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(advance).every((value) => !value)}
              onClick={() => {
                form.resetFields();
                setAdvance({});
                if (page !== 1) setPage(1);
                else getData();
              }}
            >
              {t('sortationSystem.employeeListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('sortationSystem.employeeListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('sortationSystem.employeeListColumns.title')}
          </Typography.Title>
          <TableToolbar
            leftElement={
              <Button
                disabled={
                  !hasPermission(
                    actionPermissions.sortationGroup.employeeManage
                  )
                }
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditingItem(undefined);
                  setShowModal(true);
                }}
              >
                {t('sortationSystem.add/EditEmployee.addTitle')}
              </Button>
            }
            setFontSize={setFontSize}
            fontSize={fontSize}
            totalItems={totalItems}
            refresh={() => getData()}
            tableSize={tableSize}
            setTableSize={setTableSize}
            rows={employeeList.map((employee) => ({
              ...employee,
              status: employee.status ? employee.status.description : '',
              currentEmployeeType: employee.currentEmployeeType
                ? employee.currentEmployeeType.description
                : '',
            }))}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchEmployee')}
            advancedSearch={advancedSearch}
            exportConfig={{ fileName: 'EMPLOYEE_LIST' }}
          />
          <Table<EmployeeData>
            dataSource={employeeList}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(item) => item.employeeId}
            rowSelection={{
              selectedRowKeys,
              onChange: (rowKeys: React.Key[], rows: EmployeeData[]) => {
                setSelectedRowKeys(rowKeys);
                setSelectedRows(rows);
              },
            }}
            loading={isLoading}
            pagination={{
              total: totalItems,
              pageSize: pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'EMPLOYEE_LIST' } }}
              selectedRows={selectedRows.map((employee) => ({
                ...employee,
                status: employee.status ? employee.status.description : '',
                currentEmployeeType: employee.currentEmployeeType
                  ? employee.currentEmployeeType.description
                  : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <EmployeeModal
        visible={showModal}
        setVisible={setShowModal}
        info={editingItem}
        callBack={getData}
        employeeEnum={employeeEnum}
      />
    </Container>
  );
};

export default EmployeeList;
