import { Form } from 'antd';
import { useEffect, useRef } from 'react';
import { ShopConfigData } from '../../../types';
import RegionDropdown from '../common/RegionDropdown';

type RegionSelectProps = {
  field: ShopConfigData;
  country: string;
  province: string;
  city: string;
};

/**
 * Handles select for Country, Province, and Cities
 *
 * @param field Info on the form field
 * @param parentId Id of the parent Country or Province (0 if no parent)
 */
const RegionSelect = ({
  field,
  country,
  province,
  city,
}: RegionSelectProps) => {
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Form.Item
      key={field.id}
      label={field.description}
      name="regionSelect"
      help={field.remark ? field.remark : undefined}
      initialValue={[Number(country), Number(province), Number(city)]}
    >
      <RegionDropdown
        initialValue={[Number(country), Number(province), Number(city)]}
        endTreeLevel={3}
      />
    </Form.Item>
  );
};

export default RegionSelect;
