import Container from '../../components/Container';
import { Card, Space, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { TaskEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import EmployeeTaskDistribution from '../../components/analysis/employee/EmployeeTaskDistribution';
import EmployeeTaskGoodsDistribution from '../../components/analysis/employee/EmployeeTaskGoodsDistribution';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';

const EmployeeAnalysis = () => {
  //General Components
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  const [taskEnum, setTaskEnum] = useState<TaskEnum>();
  //Text Components
  const { t } = useTranslation();

  const getTaskEnum = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('task/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  }, [t]);

  useEffect(() => {
    getTaskEnum();
  }, [getTaskEnum]);

  return (
    <Container>
      <Card bodyStyle={{ minHeight: 250 }}>
        <Space>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('analysis.employeeTitle')}
          </Typography.Title>
        </Space>
        <Tabs destroyInactiveTabPane defaultActiveKey="1">
          <Tabs.TabPane
            tab={t(`analysis.employeeTaskDistribution.tab1Title`)}
            disabled={
              !hasPermission(actionPermissions.analysisGroup.employeeStat)
            }
            key={'1'}
          >
            <EmployeeTaskDistribution
              taskEnum={taskEnum}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.employeeTaskGoodsDistribution.tab2Title`)}
            disabled={
              !hasPermission(actionPermissions.analysisGroup.employeeStat)
            }
            key={'2'}
          >
            <EmployeeTaskGoodsDistribution
              taskEnum={taskEnum}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Container>
  );
};

export default EmployeeAnalysis;
