import { RightOutlined } from '@ant-design/icons';
import { TASK_STATUS } from '../../constants/taskConstants';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  FormInstance,
  Popconfirm,
  Popover,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { hasPermission } from '../../utils/hasPermission';
import BatchFailedModal from '../BatchFailedModal';
import { onExportToExcel } from '../table/TableExport';
import EmployeeDropdown from './common/EmployeeDropdown';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/systemConstants';

type BatchTaskTableFooterToolbarProps = {
  form: FormInstance<any>;
  columns?: ColumnsType<any>;
  selectedRows: any[];
  selectedRowKeys?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  funct: {
    refresh?: Function;
    deleteFunc?: () => void;
    exportConfig?: { fileName: string; url?: string };
  };
};

const BatchTaskTableFooterToolbar = ({
  form,
  columns,
  selectedRows,
  selectedRowKeys,
  setSelectedRowKeys,
  funct,
}: BatchTaskTableFooterToolbarProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [batchFailedVis, setBatchFailedVis] = useState(false);
  //Data Components
  const [taskLabelData, setTaskLabelData] = useState<{ value: string }[]>([]);
  const [batchFailObjs, setBatchFailObjs] = useState<{
    dataType?: 'GOODS' | 'ORDER' | 'TASK';
    data?: any[];
    status?: string;
  }>({});
  const taskLabel = Form.useWatch('taskLabel', form);
  const employeeId = Form.useWatch('employeeId', form);
  const taskDate = Form.useWatch('taskDate', form);

  const sendTasksBatchRequest = async (
    operate:
      | 'assignTask'
      | 'editTaskLabel'
      | 'finishTask'
      | 'editTaskTime'
      | 'editTaskStatus',
    needRefresh: boolean = true, // multiple batch request together; eg. assignTask, and then editTaskStatus with same button click
    taskStatus?: keyof typeof TASK_STATUS
  ) => {
    let resStatus = false;
    setIsLoading(true);
    setBatchFailedVis(false);
    await postDataWithAuthToken(
      operate === 'assignTask'
        ? 'task/edit_employee_batch'
        : operate === 'editTaskLabel'
        ? 'task/edit_task_label_batch'
        : operate === 'finishTask'
        ? 'task/finish_batch'
        : operate === 'editTaskTime'
        ? 'task/edit_task_time_batch'
        : operate === 'editTaskStatus'
        ? 'task/edit_task_status_batch'
        : '',
      {
        taskIds: selectedRowKeys || undefined,
        employeeId: form.getFieldValue('employeeId')
          ? form.getFieldValue('employeeId')
          : undefined,
        taskLabel: form.getFieldValue('taskLabel')
          ? form.getFieldValue('taskLabel')
          : undefined,
        taskDate: form.getFieldValue('taskDate')
          ? moment(form.getFieldValue('taskDate')).format(DATE_FORMAT)
          : undefined,
        // editTaskStatus
        taskStatus: taskStatus ? taskStatus : undefined,
      },
      { timeout: 0 }
    )
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (needRefresh) {
              setSelectedRowKeys && setSelectedRowKeys([]);
              funct.refresh && funct.refresh();
            }
            resStatus = true;
            alertMessage('success', t('sortationSystem.alerts.batchEdited'));
          } else if (!response.goodStatus && !response.batchStatus) {
            // Batch action error
            setBatchFailedVis(true);
            alertMessage('error', t('general.fourFourFour'));
            setBatchFailObjs({
              dataType: response.data.errorType,
              data: response?.data.list,
            });
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setBatchFailedVis(false);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return resStatus;
  };

  const getTaskLabelList = () => {
    getDataWithAuthToken('task/task_label')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskLabelData(
            response.data.map((taskLabel: string) => ({ value: taskLabel }))
          );
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Popover
          trigger="click"
          placement="rightBottom"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          onVisibleChange={(visible) => {
            visible && form.resetFields();
          }}
          content={
            <div
              style={{
                width: 350,
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Form.Item
                name="employeeId"
                label={t(
                  'sortationSystem.employeeLogListColumns.advancedSearch.employeeId'
                )}
              >
                <EmployeeDropdown />
              </Form.Item>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Popconfirm
                  getPopupContainer={(trigger) =>
                    trigger.parentNode as HTMLElement
                  }
                  showArrow={false}
                  overlayInnerStyle={{ padding: 12 }}
                  placement="topLeft"
                  title={t(
                    'sortationSystem.taskListColumns.batch.editInProgressTaskStatusWarning'
                  )}
                  disabled={
                    isLoading ||
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  okText={t('actionsColumn.confirmation.yes')}
                  cancelText={t('actionsColumn.confirmation.no')}
                  okButtonProps={{
                    disabled: !hasPermission(
                      actionPermissions.sortationGroup.taskManage
                    ),
                  }}
                  cancelButtonProps={{
                    disabled: !hasPermission(
                      actionPermissions.sortationGroup.taskManage
                    ),
                  }}
                  onConfirm={() => {
                    sendTasksBatchRequest('assignTask', false).then((res) => {
                      res &&
                        sendTasksBatchRequest(
                          'editTaskStatus',
                          true,
                          TASK_STATUS.inProgress as keyof typeof TASK_STATUS
                        );
                    });
                  }}
                  onCancel={() => {
                    sendTasksBatchRequest('assignTask');
                  }}
                >
                  <Button
                    loading={isLoading}
                    disabled={
                      isLoading ||
                      !employeeId ||
                      !hasPermission(
                        actionPermissions.sortationGroup.taskManage
                      )
                    }
                    type="primary"
                    size="small"
                  >
                    {t('general.ok')}
                  </Button>
                </Popconfirm>

                <Button
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </div>
            </div>
          }
        >
          <Button
            block
            type="text"
            style={{
              display: hasPermission(
                actionPermissions.sortationGroup.taskManage
              )
                ? 'flex'
                : 'none',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {t(`sortationSystem.taskListColumns.batch.assignTask`)}
            <RightOutlined />
          </Button>
        </Popover>
        <Popover
          trigger="click"
          placement="rightBottom"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          onVisibleChange={(visible) => {
            visible && form.resetFields();
          }}
          content={
            <div
              style={{
                width: 350,
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Form.Item
                label={t('sortationSystem.taskListColumns.taskLabel')}
                name="taskLabel"
              >
                <AutoComplete
                  options={taskLabelData}
                  allowClear
                  filterOption
                  onFocus={() => {
                    getTaskLabelList();
                  }}
                />
              </Form.Item>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    !taskLabel ||
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  type="primary"
                  size="small"
                  onClick={() => {
                    sendTasksBatchRequest('editTaskLabel');
                  }}
                >
                  {t('general.ok')}
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </div>
            </div>
          }
        >
          <Button
            block
            type="text"
            style={{
              display: hasPermission(
                actionPermissions.sortationGroup.taskManage
              )
                ? 'flex'
                : 'none',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {t(`sortationSystem.taskListColumns.batch.editTaskLabel`)}
            <RightOutlined />
          </Button>
        </Popover>
        <Popover
          trigger="click"
          placement="rightBottom"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          onVisibleChange={(visible) => {
            visible && form.resetFields();
          }}
          content={
            <div
              style={{
                width: 200,
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Form.Item
                label={t('sortationSystem.taskListColumns.taskTime')}
                name="taskDate"
              >
                <DatePicker />
              </Form.Item>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    !taskDate ||
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  type="primary"
                  size="small"
                  onClick={() => {
                    sendTasksBatchRequest('editTaskTime');
                  }}
                >
                  {t('general.ok')}
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </div>
            </div>
          }
        >
          <Button
            block
            type="text"
            style={{
              display: hasPermission(
                actionPermissions.sortationGroup.taskManage
              )
                ? 'flex'
                : 'none',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {t(`sortationSystem.taskListColumns.batch.editTaskTime`)}
            <RightOutlined />
          </Button>
        </Popover>
        <Button
          block
          type="text"
          disabled={!hasPermission(actionPermissions.sortationGroup.taskManage)}
          onClick={() => {
            sendTasksBatchRequest('finishTask');
          }}
          style={{ textAlign: 'left' }}
        >
          {t('sortationSystem.taskListColumns.batch.finishTask')}
        </Button>
        {selectedRows && columns && funct.exportConfig && (
          <Button
            block
            type="text"
            onClick={() => {
              onExportToExcel(columns, selectedRows, funct.exportConfig);
            }}
            style={{ textAlign: 'left' }}
          >
            {t('actionsColumn.exportSelected')}
          </Button>
        )}
      </div>
      <BatchFailedModal
        failedObjects={batchFailObjs}
        visible={batchFailedVis}
        setVisible={setBatchFailedVis}
        selectedRowKeys={selectedRowKeys}
        loading={isLoading}
      />
    </>
  );
};

export default BatchTaskTableFooterToolbar;
