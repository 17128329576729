import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import getDashboardStyle from '../utils/getDashboardStyle';

/**
 * @param keyLanguage to be used in app
 */
const handleLanguageSelect = ({ key }: { key: string }) => {
  localStorage.setItem('@storage_language', key);
  window.location.reload();
};

/**
 * Menu on header for user to select language
 */
const LanguageSubMenu = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  // Gets current language
  useEffect(() => {
    const language = localStorage.getItem('@storage_language');
    if (language) {
      setCurrentLanguage(language);
    }
  }, []);

  const menu = (
    <Menu selectedKeys={[currentLanguage]} onClick={handleLanguageSelect}>
      <Menu.Item key="zh">
        <span role="img" style={{ marginRight: 8, fontSize: 10 }}>
          CN
        </span>
        简体中文
      </Menu.Item>
      <Menu.Item key="en">
        <span role="img" style={{ marginRight: 8, fontSize: 10 }}>
          US
        </span>
        English
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown
        key="translation"
        overlay={menu}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      >
        <Button
          type="text"
          size="small"
          icon={<GlobalOutlined />}
          style={{
            color: getDashboardStyle().mainColor,
          }}
        />
      </Dropdown>
    </div>
  );
};

export default LanguageSubMenu;
