import React, { useState } from 'react';
import { Button, Space, Tooltip, Dropdown, Row, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { SettingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { GRAY3, WHITE } from '../constants/color';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DEFAULT_FONT_SIZE } from '../constants/systemConstants';

type ColumnSelectorProps = {
  columns: ColumnsType<any>;
  columnKeys: Array<string>;
  selectedColumns: Array<string>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  mobile?: boolean;
};

/**
 * User picks what columns they want seen on the table
 *
 * @param columns            The table columns
 * @param columnKeys         The keys of all columns
 * @param selectedColumns    Columns that are currently selected to be shown
 * @param setSelectedColumns Function to set shown columns
 * @param mobile             Should show mobile version
 */
const ColumnSelector = ({
  columns,
  columnKeys,
  selectedColumns,
  setSelectedColumns,
  mobile,
}: ColumnSelectorProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [indeterminate, setIndeterminate] = useState(
    !!selectedColumns.length && selectedColumns.length === columnKeys.length
      ? false
      : true
  );
  const [checkAll, setCheckAll] = useState(
    !!selectedColumns.length && selectedColumns.length === columnKeys.length
      ? true
      : false
  );

  /**
   * @param list List of selected values in checkbox
   */
  const onDropdownChange = (list: CheckboxValueType[]) => {
    setSelectedColumns(list.map((x) => x.toString()));
    setIndeterminate(!!list.length && list.length < columnKeys.length);
    setCheckAll(list.length === columnKeys.length);
  };

  /**
   * @param e Event information of the "All" checkbox
   */
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedColumns(e.target.checked ? columnKeys : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const columnSelector = (
    <div
      style={{
        backgroundColor: WHITE,
        padding: 12,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Space size="small" direction="vertical">
        <div
          style={{
            borderBottomWidth: 1,
            borderBottomColor: GRAY3,
            borderBottomStyle: 'solid',
            paddingBottom: 6,
          }}
        >
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            {t('general.all')}
          </Checkbox>
        </div>
        <Checkbox.Group value={selectedColumns} onChange={onDropdownChange}>
          <Space size="small" direction="vertical">
            {columns.map((column) => (
              <Row key={column.key}>
                <Checkbox value={column.key}>{column.title}</Checkbox>
              </Row>
            ))}
          </Space>
        </Checkbox.Group>
      </Space>
    </div>
  );

  return (
    <Dropdown
      overlay={columnSelector}
      placement="bottomRight"
      arrow
      trigger={['click']}
      onVisibleChange={(visible) => setVisible(visible)}
      visible={visible}
      overlayClassName="dropdown-scroll-style"
    >
      {mobile ? (
        <Button icon={<SettingOutlined />}>
          {t('general.displaySettings')}
        </Button>
      ) : (
        <Tooltip title={t('general.displaySettings')}>
          <Button
            type="text"
            icon={<SettingOutlined style={{ fontSize: DEFAULT_FONT_SIZE }} />}
          />
        </Tooltip>
      )}
    </Dropdown>
  );
};

export default ColumnSelector;
