import { useTranslation } from 'react-i18next';
import { OrderGood, OrderGoodsListByOrderData } from '../../../../types';
import { Space, Table, Typography } from 'antd';
import { PRINTOUT_DATA_FONT_SIZE } from '../../../../constants/printoutConstants';

type OrderGoodsPrintoutBodyProps = {
  body: OrderGoodsListByOrderData[];
};

const TABLE_FONT_SIZE = 20;

const OrderGoodsPrintoutBody = ({ body }: OrderGoodsPrintoutBodyProps) => {
  //General Components
  const { t } = useTranslation();

  const titleView = (title: string) => {
    return (
      <Space
        size={0}
        style={{
          paddingLeft: 5,
          paddingRight: 5,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontSize: PRINTOUT_DATA_FONT_SIZE }}>{title}</div>
      </Space>
    );
  };

  const tableCellView = (
    text: string,
    isBold: boolean = false,
    fontSize: number = TABLE_FONT_SIZE
  ) => {
    return (
      <Space
        size={0}
        style={{
          paddingLeft: 5,
          paddingRight: 5,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Typography.Text
          style={{
            fontSize: fontSize,
            fontWeight: isBold ? 'bold' : undefined,
          }}
        >
          {text}
        </Typography.Text>
      </Space>
    );
  };

  const columns = [
    {
      title: titleView(t('order.printoutColumns.goodsId')),
      dataIndex: 'recId',
      key: 'recId',
      width: 70,
      render: (text: string) => tableCellView(text, false, TABLE_FONT_SIZE - 5),
    },
    {
      title: titleView(t('order.printoutColumns.goodsName')),
      dataIndex: 'goodsName',
      key: 'goodsName',
      render: (text: string, record: OrderGood) => (
        <div
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            flexDirection: 'column',
          }}
        >
          <div>
            {record.giftSpecial && (
              <Typography.Text
                style={{
                  fontSize: TABLE_FONT_SIZE,
                  color: record.giftSpecial
                    ? record.giftSpecial.tagColor
                    : undefined,
                  wordBreak: 'break-word',
                  fontWeight: 'bold',
                }}
              >
                {`${
                  record.giftSpecial
                    ? `【${record.giftSpecial.description}】`
                    : ''
                }`}
              </Typography.Text>
            )}
            <Typography.Text
              style={{
                fontSize: TABLE_FONT_SIZE,
                wordBreak: 'break-word',
              }}
            >
              {`${record.goodsName ? record.goodsName : ''}`}
            </Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: titleView(t('order.printoutColumns.goodsUnit')),
      dataIndex: 'goodsUnit',
      key: 'goodsUnit',
      width: 140,
      render: (text: string) => tableCellView(text),
    },
    {
      title: titleView(t('order.printoutColumns.goodsAttrName')),
      dataIndex: 'goodsAttrName',
      key: 'goodsAttrName',
      width: 140,
      render: (text: string) => tableCellView(text),
    },
    {
      title: titleView(t('order.printoutColumns.goodsWeight')),
      dataIndex: 'goodsWeight',
      key: 'goodsWeight',
      width: 80,
      render: (text: string) => tableCellView(text === '0.000' ? '' : text),
    },
    {
      title: titleView(t('order.printoutColumns.goodsNumber')),
      dataIndex: 'goodsNumber',
      key: 'goodsNumber',
      width: 40,
      render: (text: string) => tableCellView(text, true),
    },
  ];

  const orderTableView = () => {
    // Max number of order goods per page
    const maxNumPerPage = 18;
    const maxOrderNumPerPage = 3;

    const secondaryNumPerPage = 12;
    const secondaryOrderNumPerPage = 2;

    let currentOgNumber = 0;
    let currentOrderNumber = 0;
    return body.map((orderData: OrderGoodsListByOrderData, index: number) => {
      if (orderData.orderGoodsCount > 0) {
        let pageBreak = false;

        currentOgNumber += orderData.orderGoodsCount;
        currentOrderNumber = currentOrderNumber + 1;

        // get next orderGoods Count
        const nextOgNumber =
          index + 1 !== body.length ? body[index + 1].orderGoodsCount : 0;

        // exceed max og number or order number per page, then new page
        if (
          currentOgNumber + nextOgNumber > maxNumPerPage ||
          currentOrderNumber === maxOrderNumPerPage ||
          // meet secondary req
          (currentOrderNumber === secondaryOrderNumPerPage &&
            currentOgNumber + nextOgNumber > secondaryNumPerPage)
        ) {
          pageBreak = true;
          currentOgNumber = 0;
          currentOrderNumber = 0;
        }

        return (
          <div key={index}>
            <Table
              className="tablePrintout"
              columns={columns}
              dataSource={orderData.orderGoods}
              pagination={false}
              size="small"
              bordered={true}
              style={{
                marginBottom: 100,
              }}
              title={() => {
                return (
                  <Space>
                    <Typography.Paragraph
                      style={{
                        fontWeight: 'bold',
                        fontSize: 25,
                        marginBottom: 0,
                      }}
                    >
                      {orderData.consignee}
                    </Typography.Paragraph>
                    <Typography.Text
                      style={{
                        fontSize: 20,
                        marginBottom: 0,
                      }}
                    >
                      {`${orderData.orderSn.substring(
                        0,
                        4
                      )}***${orderData.orderSn.slice(-5)}`}
                    </Typography.Text>
                  </Space>
                );
              }}
            />
            {pageBreak && index + 1 !== body.length && (
              <div className="page-break" />
            )}
          </div>
        );
      } else {
        return <></>;
      }
    });
  };

  return body && body.length > 0 ? (
    <div style={{ marginTop: 10, marginBottom: 10 }}>{orderTableView()}</div>
  ) : (
    <></>
  );
};

export default OrderGoodsPrintoutBody;
