import { Card, Typography, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BLACK } from '../../../constants/color';
import {
  PRINTOUT_DATA_FONT_SIZE,
  PRINTOUT_DATA_REMARK_FONT_SIZE,
  PRINTOUT_SHIPPING_INFO_FONT_SIZE,
} from '../../../constants/printoutConstants';
import { PrintoutOrderBodyData } from '../../../types';

type OrderPrintoutBodyProps = {
  body: PrintoutOrderBodyData;
};

const OrderPrintoutBody = ({ body }: OrderPrintoutBodyProps) => {
  //General Components
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div /**OrderShipping Info */
        style={{
          width:
            !body.orderRemark &&
            !body.buyerRemark &&
            !body.sellerRemark &&
            !body.specialUserIcon
              ? '100%'
              : '45%',
          flexDirection: 'column',
        }}
      >
        <Typography.Paragraph
          style={{
            margin: 0,
            fontWeight: 'bold',
            fontSize: PRINTOUT_SHIPPING_INFO_FONT_SIZE,
          }}
        >
          {`${t('order.print.consignee')} : ${body.consignee}`}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{
            margin: 0,
            fontWeight: 'bold',
            fontSize: PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {`${t('order.print.address')} : ${body.fullAddress} ${
            body.buzzerCode
              ? `(${t('order.print.buzzerCode')} : ${body.buzzerCode})`
              : ''
          }`}
        </Typography.Paragraph>
        {body.shippingName && (
          <Typography.Paragraph
            style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
          >
            {`${t('order.print.shippingName')} : ${body.shippingName}`}
          </Typography.Paragraph>
        )}
        {body.expectShippingTimeName && (
          <Typography.Paragraph
            style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
          >
            {`${t('order.print.expectShippingTimeName')} : ${
              body.expectShippingTimeName
            }`}
          </Typography.Paragraph>
        )}
        <Typography.Paragraph
          style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
        >
          {`${t('order.print.mobile')} : ${body.mobile}`}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
        >
          {`${t('order.print.userMobile')} : ${body.userMobile}`}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
        >
          {`${t('order.print.orderSn')} : ${body.orderSn}`}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
        >
          {`${t('order.print.addTime')} : ${body.addTime}`}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{ margin: 0, fontSize: PRINTOUT_DATA_FONT_SIZE }}
        >
          {`${t('order.print.payName')} : ${body.payName}`}
        </Typography.Paragraph>
      </div>
      <div /**Buyer/Seller/Order Remark and Special User Icon */
        style={{
          width: '45%',
          justifyContent: 'center',
        }}
      >
        {body.buyerRemark && (
          <Card
            bordered={true}
            bodyStyle={{
              padding: 5,
              wordBreak: 'break-all',
              fontSize: PRINTOUT_DATA_REMARK_FONT_SIZE,
              lineHeight: 1,
            }}
            style={{
              width: '100%',
              borderWidth: 3,
              borderColor: BLACK,
              marginBottom: 2,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {t('order.printoutComponents.buyerRemarkTitle')}
            </span>
            {`: ${body.buyerRemark}`}
          </Card>
        )}
        {body.sellerRemark && (
          <Card
            bordered={true}
            bodyStyle={{
              padding: 5,
              wordBreak: 'break-all',
              fontSize: PRINTOUT_DATA_REMARK_FONT_SIZE,
              lineHeight: 1,
            }}
            style={{
              width: '100%',
              borderWidth: 3,
              borderColor: BLACK,
              marginBottom: 2,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {t('order.printoutComponents.sellerRemarkTitle')}
            </span>
            {`: ${body.sellerRemark}`}
          </Card>
        )}
        {body.orderRemark && (
          <Card
            bordered={true}
            bodyStyle={{
              padding: 5,
              wordBreak: 'break-all',
              fontSize: PRINTOUT_DATA_REMARK_FONT_SIZE,
              lineHeight: 1,
            }}
            style={{
              width: '100%',
              borderWidth: 3,
              borderColor: BLACK,
              marginBottom: 2,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {t('order.printoutComponents.orderRemarkTitle')}
            </span>
            {`: ${body.orderRemark}`}
          </Card>
        )}
        {body.specialUserIcon && (
          <Image style={{ width: 100 }} src={body.specialUserIcon} />
        )}
      </div>
    </div>
  );
};

export default OrderPrintoutBody;
