import { Button, Col, Empty, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { OrderData } from '../../../types';
import { hasPermission } from '../../../utils/hasPermission';

type OrderCombineInfoProps = {
  orderInfo?: OrderData;
};

const OrderCombineInfo = ({ orderInfo }: OrderCombineInfoProps) => {
  const { t } = useTranslation();
  const { addTab } = useTab();

  const mainOrderColumns: ColumnsType<OrderData> = [
    {
      title: t('order.orderDetail.orderSn'),
      key: 'orderSn',
      dataIndex: 'orderSn',
      width: 100,
      render: (text: string, record: OrderData) => (
        <Button
          style={{ padding: 0 }}
          type="link"
          disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
          onClick={() => {
            addTab(
              '',
              `${dashboardRoute.order.detail}?order_id=${record.orderId}`
            );
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: t('order.orderDetail.totalAmount'),
      key: 'orderAmount',
      dataIndex: 'orderAmount',
      width: 100,
      render: (value) => value && value.totalAmount,
    },
    {
      title: t('order.orderDetail.payName'),
      key: 'payName',
      dataIndex: 'payName',
      width: 100,
    },
    {
      title: t('order.orderDetail.addTime'),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 100,
    },
  ];

  const combineOrderColumns: ColumnsType<OrderData> = [
    {
      title: t('order.orderDetail.orderSn'),
      key: 'orderSn',
      dataIndex: 'orderSn',
      width: 100,
      render: (text: string, record: OrderData) => (
        <Button
          style={{ padding: 0 }}
          type="link"
          disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
          onClick={() => {
            addTab(
              '',
              `${dashboardRoute.order.detail}?order_id=${record.orderId}`
            );
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: t('order.orderDetail.totalAmount'),
      key: 'orderAmount',
      dataIndex: 'orderAmount',
      width: 100,
      render: (value) => value && value.totalAmount,
    },
    {
      title: t('order.orderDetail.payName'),
      key: 'payName',
      dataIndex: 'payName',
      width: 100,
    },
    {
      title: t('order.orderDetail.addTime'),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 100,
    },
  ];

  return orderInfo && (orderInfo.mainOrder || orderInfo.combineOrderList) ? (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.main/combineOrder')}
        </Typography.Text>
      </Col>
      {orderInfo.mainOrder && (
        <Col span={24}>
          <Table
            title={() => (
              <Typography.Text strong>
                {t('order.orderDetail.mainOrder')}
              </Typography.Text>
            )}
            dataSource={[orderInfo.mainOrder]}
            columns={mainOrderColumns}
            size="small"
            rowKey={(record) => record.orderId}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
            }}
            scroll={{ y: 600, x: 1200 }}
          />
        </Col>
      )}
      {orderInfo.combineOrderList && (
        <Col span={24}>
          <Table
            title={() => (
              <Typography.Text strong>
                {t('order.orderDetail.combineOrder')}
              </Typography.Text>
            )}
            dataSource={orderInfo.combineOrderList}
            columns={combineOrderColumns}
            size="small"
            rowKey={(record) => record.orderId}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
            }}
            scroll={{ y: 600, x: 1200 }}
          />
        </Col>
      )}
    </Row>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.main/combineOrder')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderCombineInfo;
