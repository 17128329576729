import React from 'react';
import { Layout, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DASHBOARD_VERSION } from '../constants/systemConstants';
import getDashboardStyle from '../utils/getDashboardStyle';

const { Footer } = Layout;

const AntDesignFooter = () => {
  const { t } = useTranslation();

  return (
    <Footer style={{ textAlign: 'center' }}>
      <Space direction="vertical">
        <Typography>
          {t(getDashboardStyle().title)} ©{new Date().getFullYear()}
        </Typography>
        <Typography>
          {t('general.pageVersion')} {DASHBOARD_VERSION}
        </Typography>
      </Space>
    </Footer>
  );
};

export default AntDesignFooter;
