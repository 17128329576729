import { BorderlessTableOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Grid,
  Modal,
  Result,
  Space,
  Spin,
  Steps,
} from 'antd';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SHORTEST_TIMEOUT } from '../../constants/systemConstants';
import { OrderEnum, PaymentMethod, UserListData, Viewport } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { onNextStepClick, onOrderSubmit } from '../../utils/orderHelper';
import AddressForm from './addOrderSections/AddressForm';
import OrderGoodsForm from './addOrderSections/OrderGoodsForm';
import OtherInfoForm from './addOrderSections/OtherInfoForm';
import SellerForm from './addOrderSections/SellerForm';
import ShippingPaymentForm from './addOrderSections/ShippingPaymentForm';
import TotalFeeForm, {
  calculateTotalFeeComponent,
} from './addOrderSections/TotalFeeForm';
import UserFormItem from './addOrderSections/UserFormItem';

type OrderModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  isSeller: boolean;
  enums?: OrderEnum;
  callBack?: () => void;
  userId?: string;
};

const OrderModal = ({
  visible,
  setVisible,
  isSeller,
  enums,
  callBack,
  userId,
}: OrderModalProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAnonymousUser, setIsAnonymousUser] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [viewport, setViewport] = useState<Viewport>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserListData>();
  const [previewStatus, setPreviewStatus] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  // 获取用户详情
  const getUserDetail = useCallback(() => {
    if (userId) {
      getDataWithAuthToken('users/detail', {
        params: { userId: userId },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setSelectedUser(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  }, [userId, t]);

  useEffect(() => {
    if (visible) {
      getUserDetail();
    }
  }, [visible, getUserDetail]);

  const onClose = () => {
    setCurrentStep(0);
    setFormData({});
    setIsAnonymousUser(false);
    setSelectedUser(undefined);
    setPaymentMethods([]);
    setTimeout(() => {
      setViewport({
        longitude: 0,
        latitude: 0,
        zoom: 15,
      });
    }, SHORTEST_TIMEOUT);
    setVisible(false);
  };

  const calculateTotalFee = () => {
    calculateTotalFeeComponent(form, formData, t, setPreviewStatus);
  };

  const steps = [
    {
      // Seller info
      title: t('order.add/editOrder.stepOne'),
      content: (
        // 选择店铺
        <SellerForm
          form={form}
          isSeller={isSeller}
          formData={formData}
          setFormData={setFormData}
          setPaymentMethods={setPaymentMethods}
        />
      ),
    },
    {
      // User info
      title: t('order.add/editOrder.stepTwo'),
      content: (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ userId: userId ? Number(userId) : undefined }}
        >
          <Divider orientation="left" style={{ marginTop: 0 }}>
            <Space>
              <BorderlessTableOutlined />
              {t('order.add/editOrder.userInfo')}
            </Space>
          </Divider>
          <UserFormItem
            userId={userId}
            isAnonymousUser={isAnonymousUser}
            setIsAnonymousUser={setIsAnonymousUser}
            setSelectedUser={setSelectedUser}
            setViewport={setViewport}
            form={form}
            formData={formData}
            setFormData={setFormData}
          />
        </Form>
      ),
    },
    {
      // Order good list
      title: t('order.add/editOrder.stepThree'),
      content: <OrderGoodsForm formData={formData} setFormData={setFormData} />,
    },
    {
      // Shipping address info
      title: t('order.add/editOrder.stepFour'),
      content: (
        <AddressForm
          form={form}
          enums={enums}
          viewport={viewport}
          setViewport={setViewport}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      // Shipping method and payment method
      title: t('order.add/editOrder.shipping/payment'),
      content: (
        <ShippingPaymentForm
          form={form}
          enums={enums}
          formData={formData}
          setFormData={setFormData}
          setPaymentMethods={setPaymentMethods}
          paymentMethods={paymentMethods}
        />
      ),
    },
    {
      // Other info
      title: t('order.add/editOrder.otherInfo'),
      content: (
        <OtherInfoForm
          form={form}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      // Total fee
      title: t('order.add/editOrder.previewTotal'),
      content: (
        <TotalFeeForm
          form={form}
          enums={enums}
          formData={formData}
          isAnonymousUser={isAnonymousUser}
          selectedUser={selectedUser}
          setPreviewStatus={setPreviewStatus}
        />
      ),
    },
    {
      // Final confirmation
      title: t('order.add/editOrder.finalStep'),
      content: (
        <Spin spinning={isLoading}>
          <Result
            status="success"
            title={t('order.add/editOrder.finish')}
            subTitle={t('order.add/editOrder.addOrderConfirmation')}
          />
        </Spin>
      ),
    },
  ];

  return (
    <Modal
      title={
        isSeller
          ? t('order.add/editOrder.addSellerOrderTitle')
          : t('order.add/editOrder.addTitle')
      }
      visible={visible}
      footer={null}
      maskClosable={false}
      width={screens.lg ? 992 : screens.md ? 768 : undefined}
      onCancel={onClose}
      bodyStyle={{ padding: '24px 12px' }}
    >
      <Steps
        labelPlacement={screens.lg ? 'vertical' : undefined}
        responsive={false}
        size="small"
        current={currentStep}
        style={{ marginBottom: 24 }}
      >
        {steps.map((item) => (
          <Steps.Step key={item.title} title={screens.lg ? item.title : ''} />
        ))}
      </Steps>
      <div
        style={{
          height: '57vh',
          overflow: 'auto',
          padding: '0 12px',
        }}
      >
        {steps[currentStep].content}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}
      >
        {currentStep > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              setCurrentStep((prev) => prev - 1);
              form.setFieldsValue({ ...formData });
            }}
            disabled={isLoading}
          >
            {t('order.add/editOrder.previous')}
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button
            disabled={
              (currentStep === 2 &&
                (!formData.orderGoodsList ||
                  (formData.orderGoodsList &&
                    !formData.orderGoodsList.length))) ||
              (currentStep === 6 && !previewStatus)
            }
            type="primary"
            onClick={() => {
              onNextStepClick(
                form,
                formData,
                setFormData,
                currentStep,
                setCurrentStep,
                5,
                calculateTotalFee
              );
            }}
          >
            {t('order.add/editOrder.next')}
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() =>
              onOrderSubmit(formData, t, setIsLoading, onClose, callBack)
            }
            loading={isLoading}
          >
            {t('order.add/editOrder.ok')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default OrderModal;
