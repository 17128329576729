import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Select,
  Space,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Table, { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import OpenAPIModal from '../../components/settings/OpenAPIModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { FontSizeType, OpenApiData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const OpenApi = () => {
  // General components
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  // Data components
  const [keyword, setKeyword] = useState<string>('');
  const [openApiList, setOpenApiList] = useState<OpenApiData[]>([]);
  const [editingItem, setEditingItem] = useState<OpenApiData>();
  const [advance, setAdvance] = useState<{ [key: string]: string }>({});
  // Pagination components
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItems, setTotalItems] = useState<number>(0);
  // Table components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<OpenApiData[]>([]);
  const columnKeys = [
    'id',
    'apiName',
    'apiCode',
    'apiKey',
    'actionCode',
    'isEnabled',
    'addTime',
    'actions',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('open_api/list', {
      params: {
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        isEnabled:
          formRef.current && form.getFieldValue('isEnabled') !== undefined
            ? form.getFieldValue('isEnabled') === 'all'
              ? undefined
              : form.getFieldValue('isEnabled')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLoading(false);
          setOpenApiList(response.data.list);
          setTotalItems(response.data.totalItem);
          // Scroll to top when data changes
          tableScrollToTop();
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t, keyword, page, pageSize, form]);

  const onDelete = (id: number) => {
    setIsLoading(true);
    postDataWithAuthToken('open_api/delete', {
      id: id,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('settings.alerts.openApiDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('settings.openApi.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ isEnabled: 'all' }}
        >
          <Form.Item name="isEnabled" label={t('settings.openApi.isEnabled')}>
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) => {
                setAdvance((prev: any) => ({
                  ...prev,
                  isEnabled:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }));
              }}
            >
              <Select.Option key="all" value="all">
                {t(`general.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('settings.openApi.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('settings.openApi.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('fileManager.fileManagerColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  const columns: ColumnsType<OpenApiData> = [
    {
      title: setFont('ID', fontSize),
      key: 'id',
      dataIndex: 'id',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('settings.openApi.apiName'), fontSize),
      key: 'apiName',
      dataIndex: 'apiName',
      width: 120,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('settings.openApi.apiCode'), fontSize),
      key: 'apiCode',
      dataIndex: 'apiCode',
      width: 120,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('settings.openApi.apiKey'), fontSize),
      key: 'apiKey',
      dataIndex: 'apiKey',
      width: 120,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('settings.openApi.actionCode'), fontSize),
      key: 'actionCode',
      dataIndex: 'actionCode',
      width: 160,
      render: (value) => (
        <div style={{ maxHeight: 100, overflow: 'auto' }}>
          {setFont(value, fontSize)}
        </div>
      ),
    },
    {
      title: setFont(t('settings.openApi.isEnabled'), fontSize),
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      width: 120,
      render: (value) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('settings.openApi.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'actions',
      fixed: screens.lg ? 'right' : undefined,
      width: 80,
      render: (record: OpenApiData) => (
        <Space>
          <Button
            type="link"
            size="small"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditingItem(record);
              setShowModal(true);
            }}
            disabled={!hasPermission(actionPermissions.settingGroup.openAPI)}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="leftTop"
            onConfirm={() => onDelete(record.id)}
            disabled={!hasPermission(actionPermissions.settingGroup.openAPI)}
          >
            <Button
              disabled={!hasPermission(actionPermissions.settingGroup.openAPI)}
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              <Typography.Text type="danger">
                {t('actionsColumn.delete')}
              </Typography.Text>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('settings.openApi.title')}
          </Typography.Title>
          <TableToolbar
            leftElement={
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.settingGroup.openAPI)
                }
                onClick={() => {
                  setEditingItem(undefined);
                  setShowModal(true);
                }}
              >
                {t('settings.add/editOpenApi.addTitle')}
              </Button>
            }
            setFontSize={setFontSize}
            fontSize={fontSize}
            totalItems={totalItems}
            refresh={() => getData()}
            tableSize={tableSize}
            setTableSize={setTableSize}
            rows={openApiList}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchApiKeyword')}
            advancedSearch={advancedSearch}
            exportConfig={{ fileName: 'OPEN_API_LIST' }}
          />
          <Table<OpenApiData>
            dataSource={openApiList}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(item) => item.id}
            rowSelection={{
              selectedRowKeys,
              onChange: (rowKeys: React.Key[], rows: OpenApiData[]) => {
                setSelectedRowKeys(rowKeys);
                setSelectedRows(rows);
              },
            }}
            loading={isLoading}
            pagination={{
              total: totalItems,
              pageSize: pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'OPEN_API_LIST' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <OpenAPIModal
        visible={showModal}
        setVisible={setShowModal}
        info={editingItem}
        callBack={getData}
      />
    </Container>
  );
};

export default OpenApi;
