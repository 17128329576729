import { Form, Modal } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import CategoryDropdown from './common/CategoryDropdown';

type TransferGoodsModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  category?: CategoryData;
  refresh?: () => void;
};

const TransferGoodsModal = ({
  visible,
  setVisible,
  category,
  refresh,
}: TransferGoodsModalProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (visible && isSubscribed.current) {
      form.resetFields();
    }
  }, [visible, form, t]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        postDataWithAuthToken('goods/category/transfer_goods', {
          catId: category?.catId,
          destinationCatId:
            values.destinationCatId[values.destinationCatId.length - 1],
        })
          .then((response) => {
            if (response && response.goodStatus) {
              alertMessage('success', t('goods.alerts.goodsTransferred'));
              onClose();
              if (refresh) refresh();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  return (
    <Modal
      title={`${t('goods.actionsColumn.transfer')} ${category?.catName}`}
      visible={visible}
      okText={t('goods.categoryListColumns.ok')}
      cancelText={t('goods.categoryListColumns.cancel')}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('goods.actionsColumn.transferTo')}
          name="destinationCatId"
          rules={[
            {
              required: true,
              message: t('general.inputError.pleaseSelectOne'),
            },
          ]}
        >
          <CategoryDropdown onFocusFetch={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TransferGoodsModal;
