import React from 'react';
import { Dropdown, Menu, Button, Space } from 'antd';
import TableSizeChange from '../../components/table/TableSizeChange';
import TableRefresh from '../../components/table/TableRefresh';
import TableSearch from '../../components/table/TableSearch';
import ColumnSelector from '../../components/ColumnSelector';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import TableExport from './TableExport';
import { ExportOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { DEFAULT_FONT_SIZE } from '../../constants/systemConstants';
import { FontSizeType } from '../../types';

// import TableSelectedRows from './TableSelectedRows';

type TableToolbarMobileProps = {
  leftElement?: JSX.Element;
  fontSize?: FontSizeType;
  setFontSize?: React.Dispatch<React.SetStateAction<FontSizeType>>;
  tableSize?: SizeType;
  setTableSize?: React.Dispatch<React.SetStateAction<SizeType>>;
  refresh?: () => void;
  search?: (keyword: string) => void;
  searchPlaceholder?: string;
  advancedSearch?: JSX.Element;
  totalItems?: number | string;
  columns?: ColumnsType<any>;
  columnKeys?: Array<string>;
  selectedColumns?: Array<string>;
  setSelectedColumns?: React.Dispatch<React.SetStateAction<Array<string>>>;
  rows?: Array<any>;
  exportConfig?: {
    fileName: string;
    url?: string;
    otherExports?: { title: string; url: string }[];
  };
  selectedRows?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  recordRemark?: string;
};

/**
 * Mobile Table Toolbar that provides basic functionality for tables
 *
 * @param leftElement        React Component that we be the leftmost button
 * @param tableSize          Size of the table
 * @param setTableSize       Changes size of table
 * @param refresh            Refresh Function
 * @param totalItems         Total Items in table
 * @param columns            List of Column Types
 * @param columnKeys         List of all Column Keys
 * @param selectedColumns    List of Selected Column Keys
 * @param setSelectedColumns Sets all the Selected Columns
 * @param rows               List of row data
 * @param advancedSearch      React Component of advanced search
 * @param selectedRows       Data of selected rows
 * @param setSelectedRowKeys Sets all selected row keys
 * @param fontSize           The font size in table
 * @param setFontSize        Sets the font size in the table
 * @param recordRemark       If you want to display additional info after X records
 * Dependencies: The toolbar will only render components that have all their needed dependencies
 *
 * TableSizeChange -> tableSize, setTableSize
 * TableRefresh    -> refresh
 * TableSearch     -> search
 * ColumnSelector  -> columns, columnKeys, selectedColumns, setSelectedColumns
 * TableExport     -> columns, rows
 * SelectedRowsBar -> selectedRows
 */
const TableToolbarMobile = ({
  leftElement,
  fontSize,
  setFontSize,
  tableSize,
  setTableSize,
  refresh,
  search,
  searchPlaceholder,
  advancedSearch,
  totalItems,
  columns,
  columnKeys,
  selectedColumns,
  setSelectedColumns,
  rows,
  exportConfig,
  selectedRows,
  setSelectedRowKeys,
  recordRemark,
}: TableToolbarMobileProps) => {
  const { t } = useTranslation();

  const menu =
    (columns && rows) || (tableSize && setTableSize) || refresh ? (
      <Menu>
        {columns && rows && (
          <Menu.SubMenu
            key="export"
            title={t('actionsColumn.export')}
            icon={<ExportOutlined style={{ fontSize: DEFAULT_FONT_SIZE }} />}
            style={{ paddingLeft: 5 }}
          >
            <TableExport
              columns={
                selectedColumns
                  ? columns.filter((x) =>
                      selectedColumns.includes(x.key?.toString() ?? '')
                    )
                  : columns
              }
              rows={rows}
              exportConfig={exportConfig}
              mobile
            />
          </Menu.SubMenu>
        )}
        {tableSize && setTableSize && (
          <Menu.Item key="changeSize" style={{ padding: 0 }}>
            <TableSizeChange
              tableSize={tableSize}
              setTableSize={setTableSize}
              fontSize={fontSize}
              setFontSize={setFontSize}
              mobile
            />
          </Menu.Item>
        )}
        {refresh && (
          <Menu.Item key="refresh" style={{ padding: 0 }}>
            <TableRefresh
              recordRemark={recordRemark ? recordRemark : undefined}
              total={totalItems}
              onRefresh={refresh}
              key="refresh"
              mobile
            />
          </Menu.Item>
        )}
      </Menu>
    ) : undefined;

  return (
    <>
      <Space direction="vertical" style={{ marginBottom: 14 }}>
        {leftElement}
        {search && (
          <TableSearch
            searchFunction={search}
            placeholder={
              searchPlaceholder ? searchPlaceholder : t('general.search')
            }
            mobile
          />
        )}
        {advancedSearch}
        {columns && columnKeys && selectedColumns && setSelectedColumns && (
          <ColumnSelector
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            mobile
          />
        )}
        {menu && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button icon={<UnorderedListOutlined />}>
              {t('general.tableSettings')}
            </Button>
          </Dropdown>
        )}
        {totalItems &&
          `${totalItems} ${t('general.items')}${
            recordRemark ? recordRemark : ''
          }`}
      </Space>
      {/* Alternative option for showing selected rows */}
      {/* {selectedRows && !!selectedRows.length && (
        <TableSelectedRows
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns}
          exportConfig={exportConfig}
          mobile
        />
      )} */}
    </>
  );
};

export default TableToolbarMobile;
