import React, { useEffect } from 'react';
import { Grid, Layout } from 'antd';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { matchRoutes, RouteConfigComponentProps } from 'react-router-config';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import { useTranslation } from 'react-i18next';
import PageHistoryTabs from '../../components/PageHistoryTabs';
import { dashboardRoute } from '../../constants/pathname';
import { useTab } from '../../hooks/useTab';
import getDashboardStyle from '../../utils/getDashboardStyle';

const { Content } = Layout;

/**
 * The standard dashboard layout with permanent sidebar header and footer
 *
 * @props RouteConfigComponentProps route Route information that includes info like title
 */
const StandardLayout = (props: RouteConfigComponentProps) => {
  const { route } = props;
  const isCollapsed = useAppSelector(
    (state) => state.appConfig.sidebarCollapsed
  );
  const pageHistory = useAppSelector((state) => state.pageHistory.value);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { addTab } = useTab();

  /**
   * On location change, add page into pageHistory
   */
  useEffect(() => {
    if (
      window.location.pathname.includes(dashboardRoute.path) &&
      route &&
      route.routes &&
      !pageHistory.length
    ) {
      if (window.location.pathname === dashboardRoute.home) {
        document.title = `${t('dashboard.title')} - ${t(
          getDashboardStyle().title
        )}`;
        addTab('dashboard.title', dashboardRoute.home);
      } else {
        dispatch(
          addPage({
            title: 'dashboard.title',
            path: dashboardRoute.home,
          })
        );
        const matchedRoutes = matchRoutes(
          route.routes,
          window.location.pathname + window.location.search
        ).filter((x) => x.match.isExact);
        if (matchedRoutes.length > 0) {
          document.title = `${t(matchedRoutes[0].route.title)} - ${t(
            getDashboardStyle().title
          )}`;
          addTab(
            matchedRoutes[0].route.title,
            window.location.pathname + window.location.search
          );
        } else {
          document.title = `Error 404 - ${t(getDashboardStyle().title)}`;
          addTab('404', window.location.pathname);
        }
      }
    }
  }, [dispatch, t, route, pageHistory, addTab]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar route={route} isMobile={screens.sm ? false : true} />
      <Layout
        style={{
          marginLeft: screens.sm ? (isCollapsed ? 55 : 200) : undefined,
        }}
      >
        <Content>
          <PageHistoryTabs {...props} />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default StandardLayout;
