import { FilterOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Form,
  Grid,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import FourZeroThree from '../../../components/FourZeroThree';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  AdminListData,
  BasicEnumInfoType,
  ChangeLogList,
  FontSizeType,
  UserLogData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import FiveHundred from '../../FiveHundred';
import AdminDropdown from '../../admin/AdminDropdown';
import UsersDropdown from '../common/UsersDropdown';
import { tableScrollToTop } from '../../../utils/helperFunction';

const { Title } = Typography;

type UserLogTableProps = {
  id?: string;
};

/**
 * Display Users Logs
 * /dashboard/admin/log
 */
const UserLogTable = ({ id }: UserLogTableProps) => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [logData, setLogData] = useState<Array<UserLogData>>([]);
  const [changeTypes, setChangeTypes] = useState<Array<BasicEnumInfoType>>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalLogs, setTotalLogs] = useState();
  const [page, setPage] = useState(1);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [keyword, setKeyword] = useState('');
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '50');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { addTab } = useTab();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'logId',
    'userId',
    'admin',
    'changeTime',
    'changeType',
    'ipAddress',
    'changeCity',
    'logonService',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRows, setSelectedRows] = useState<UserLogData[]>([]);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * This handles and updates the main table rendered on the page
   * It takes in the parameters if provided from the Advanced Search popover
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`users/log/list?`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        userId: id
          ? id
          : formRef.current && form.getFieldValue('userId')
          ? form.getFieldValue('userId')
          : undefined,
        changeType:
          formRef.current && form.getFieldValue('changeType')
            ? form.getFieldValue('changeType')
            : undefined,
        adminId:
          formRef.current && form.getFieldValue('adminId')
            ? form.getFieldValue('adminId')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setLogData(response.data.list);
            setTotalLogs(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, keyword, page, pageSize, form, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDelete = () => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('users/log/delete_batch', {
      logIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('users.alerts.logsDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const columns: ColumnsType<UserLogData> = [
    {
      title: setFont(t('users.userLogsColumns.logId'), fontSize),
      dataIndex: 'logId',
      key: 'logId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserLogData, b: UserLogData) => compare(a.logId, b.logId),
    },
    {
      title: setFont(t('users.userLogsColumns.userId'), fontSize),
      dataIndex: 'userId',
      key: 'userId',
      width: 100,
      sorter: (a: UserLogData, b: UserLogData) => compare(a.userId, b.userId),
      render: (text: string, record: UserLogData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            record.userId <= 0 ||
            !hasPermission(actionPermissions.userGroup.userView)
          }
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.users.detail}?user_id=${record.userId}`
            )
          }
        >
          {record.userId}
        </Button>
      ),
    },
    {
      title: setFont(t('users.userLogsColumns.admin'), fontSize),
      dataIndex: 'adminUser',
      key: 'admin',
      width: 160,
      render: (text: AdminListData) =>
        text &&
        setFont(text.adminUserName + ' (ID: ' + text.adminId + ')', fontSize),
    },

    {
      title: setFont(t('users.userLogsColumns.changeTime'), fontSize),
      dataIndex: 'changeTime',
      key: 'changeTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserLogData, b: UserLogData) =>
        compare(a.changeTime, b.changeTime),
    },
    {
      title: setFont(t('users.userLogsColumns.changeType'), fontSize),
      dataIndex: 'changeType',
      key: 'changeType',
      width: 200,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userLogsColumns.ipAddress'), fontSize),
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserLogData, b: UserLogData) =>
        compare(a.ipAddress, b.ipAddress),
    },
    {
      title: setFont(t('users.userLogsColumns.changeCity'), fontSize),
      dataIndex: 'ipAddressLocation',
      key: 'changeCity',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: UserLogData, b: UserLogData) =>
        compare(a.ipAddressLocation, b.ipAddressLocation),
    },
    {
      title: setFont(t('users.userLogsColumns.browser'), fontSize),
      dataIndex: 'browser',
      key: 'logonService',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
  ];

  const changeLogListColumns: ColumnsType<ChangeLogList> = [
    {
      title: setFont(
        t('users.userLogsColumns.changeLogListColumns.propertyName'),
        fontSize
      ),
      dataIndex: 'propertyName',
      key: 'propertyName',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('users.userLogsColumns.changeLogListColumns.oldValue'),
        fontSize
      ),
      dataIndex: 'oldValue',
      key: 'oldValue',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
    {
      title: setFont(
        t('users.userLogsColumns.changeLogListColumns.newValue'),
        fontSize
      ),
      dataIndex: 'newValue',
      key: 'newValue',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
    },
  ];

  /**
   * @param selectedRowKeys Array of keys that were selected
   */
  const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    if (isSubscribed.current) {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  /**
   * Sends a request and returns the ChangeType data
   */
  const getChangeTypeData = () => {
    getDataWithAuthToken('users/log/change_type')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setChangeTypes(response.data.list);
          }
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This popover displays, and handles the filters should the user need
   * It takes in inputs and formats it into parameters so that it can be used by getData
   */
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('users.userLogsColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          {!id && (
            <Form.Item
              name="userId"
              label={t('users.userLogsColumns.advancedSearch.byUserId')}
            >
              <UsersDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    userId: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            name="changeType"
            label={t('users.userLogsColumns.advancedSearch.changeType')}
          >
            <Select
              allowClear
              disabled={!hasPermission(actionPermissions.userGroup.userManage)}
              placeholder={t('searchPlaceholders.selectChangeType')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => {
                setAdvance((prev) => ({
                  ...prev,
                  changeType: value,
                }));
              }}
              onFocus={() => {
                if (
                  !changeTypes.length &&
                  hasPermission(actionPermissions.userGroup.userLog)
                )
                  getChangeTypeData();
              }}
              optionLabelProp="key"
            >
              {changeTypes.map((changeType) => (
                <Select.Option
                  key={`${changeType.description} (${changeType.code})`}
                  value={changeType.code}
                >
                  {changeType.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="adminId"
            label={t('users.userLogsColumns.advancedSearch.byAdminId')}
          >
            <AdminDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  adminId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('users.userLogsColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('users.userLogsColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userLogsColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {id ? (
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('users.userLogs')}
        </Typography.Title>
      ) : (
        <Title level={3} style={{ fontWeight: 500 }}>
          {t('users.userLogs')}
        </Title>
      )}
      <TableToolbar
        fontSize={fontSize}
        setFontSize={setFontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        totalItems={totalLogs}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        advancedSearch={advancedSearch}
        rows={logData.map((log) => ({
          ...log,
          adminUser: log.adminUser
            ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
            : '',
        }))}
        exportConfig={{ fileName: 'USER_LOG' }}
      />
      <Table<UserLogData>
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        dataSource={logData}
        size={tableSize}
        loading={loading}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowSelection={rowSelection}
        rowKey={(log) => log.logId}
        expandable={{
          rowExpandable: (record) => (record.logInfo ? true : false),
          expandedRowRender: (record, index) => (
            <Table
              key={index}
              size={tableSize}
              dataSource={record.logInfo}
              columns={changeLogListColumns}
              pagination={{
                pageSize: 10,
                hideOnSinglePage: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
              }}
              rowKey={(record) => record.propertyName}
            />
          ),
          columnWidth: 30,
        }}
        pagination={{
          total: totalLogs,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
          current: page,
        }}
      />
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((log) => ({
            ...log,
            adminUser: log.adminUser
              ? `${log.adminUser.adminUserName} (ID: ${log.adminUser.adminId})`
              : '',
          }))}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          funct={{
            deleteFunc:
              hasPermission(actionPermissions.userGroup.userLog) &&
              !getDashboardStyle().isSalesForceAppType
                ? handleDelete
                : undefined,
            exportConfig: { fileName: 'USER_LOG' },
          }}
        />
      )}
    </>
  );
};
export default UserLogTable;
