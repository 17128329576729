import {
  Switch,
  Form,
  Input,
  Modal,
  Spin,
  Typography,
  InputNumber,
  Tag,
  Row,
  Col,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodsTypeAttributeData, GoodsTypeData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { CloseOutlined } from '@ant-design/icons';
import {
  DEFAULT_FONT_SIZE,
  MEDIUM_FONT_SIZE,
} from '../../constants/systemConstants';

type GoodsTypeAttrModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCalled?: React.Dispatch<React.SetStateAction<boolean>>;
  setGoodsTypeVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  goodsTypeAttrInfo?: GoodsTypeAttributeData;
  goodsTypeInfo?: GoodsTypeData;
  refresh?: Function;
};

const GoodsTypeAttrModal = ({
  visible,
  setVisible,
  setGoodsTypeVisible,
  goodsTypeAttrInfo,
  goodsTypeInfo,
  setCalled,
  refresh,
}: GoodsTypeAttrModalProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [inputAttr, setInputAttr] = useState(true);
  const [attrInputVals, setAtterInputVals] = useState<string[]>([]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      goodsTypeAttrInfo && setInputAttr(goodsTypeAttrInfo.isInputAttrFromList);
      goodsTypeAttrInfo &&
        setAtterInputVals(
          goodsTypeAttrInfo.inputAttrValues
            ? goodsTypeAttrInfo.inputAttrValues
            : []
        );
    }
  }, [form, visible, goodsTypeAttrInfo]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(
          goodsTypeAttrInfo
            ? 'goods/spec_type/attr/edit'
            : 'goods/spec_type/attr/add',
          {
            ...values,
            specAttrId: goodsTypeAttrInfo
              ? goodsTypeAttrInfo.specAttrId
              : undefined,
            goodsSpecTypeId: goodsTypeInfo
              ? goodsTypeInfo.goodsSpecTypeId
              : undefined,
            inputAttrValues: attrInputVals ? attrInputVals : [],
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  goodsTypeAttrInfo
                    ? t('goods.alerts.typeAttrEdited')
                    : t('goods.alerts.typeAttrAdded')
                );
                setCalled && setCalled(false);
                onClose();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
      setGoodsTypeVisible && setGoodsTypeVisible(true);
      setInputAttr(true);
      setCalled && setCalled(true);
      setAtterInputVals([]);
    }
  };

  const SortableItem = SortableElement(
    ({ name, i }: { name: string; i: number }) => (
      <div style={{ zIndex: 9999 }}>
        <Tag
          key={i}
          style={{ fontSize: DEFAULT_FONT_SIZE, cursor: 'grab' }}
          closable
          closeIcon={<CloseOutlined size={MEDIUM_FONT_SIZE} />}
          onClose={() =>
            setAtterInputVals((prev) =>
              prev.filter((value, index) => {
                return i !== index;
              })
            )
          }
        >
          {name}
        </Tag>
      </div>
    )
  );

  const SortableList = SortableContainer(({ items }: { items: string[] }) => (
    <Row gutter={[8, 8]}>
      {items.map((item, index) => (
        <Col key={index}>
          <SortableItem name={item} index={index} i={index} />
        </Col>
      ))}
    </Row>
  ));

  return (
    <Modal
      title={
        goodsTypeAttrInfo
          ? `${t('goods.add/editGoodsTypeAttribute.editTitle')} ${
              goodsTypeAttrInfo.specAttrName
            }`
          : t('goods.add/editGoodsTypeAttribute.addTitle')
      }
      visible={visible}
      okText={t('goods.add/editGoodsTypeAttribute.ok')}
      cancelText={t('goods.add/editGoodsTypeAttribute.cancel')}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Spin style={{ padding: 0 }} spinning={isLoading}>
        <Form
          form={form}
          initialValues={
            goodsTypeAttrInfo
              ? { ...goodsTypeAttrInfo }
              : { isInputAttrFromList: true, sortOrder: 100 }
          }
        >
          <Form.Item
            name="specAttrId"
            label={t('goods.add/editGoodsTypeAttribute.goodsSpecTypeId')}
            style={{ display: goodsTypeAttrInfo ? undefined : 'none' }}
          >
            <Typography.Text>{goodsTypeAttrInfo?.specAttrId}</Typography.Text>
          </Form.Item>
          <Form.Item
            name="specAttrName"
            label={t('goods.add/editGoodsTypeAttribute.specAttrName')}
            rules={[{ required: true, message: t('general.inputError.empty') }]}
          >
            <Input style={{ width: 120 }} />
          </Form.Item>
          <Form.Item
            name="isInputAttrFromList"
            valuePropName="checked"
            label={t('goods.add/editGoodsTypeAttribute.isInputAttrFromList')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Switch
              defaultChecked={true}
              onChange={(checked) => {
                checked === false && setAtterInputVals([]);
                setInputAttr(checked);
              }}
            />
          </Form.Item>
          <Form.Item
            name="AttrValues"
            label={t('goods.add/editGoodsTypeAttribute.inputAttrValues')}
            style={{ display: inputAttr ? undefined : 'none' }}
            rules={[
              {
                required: inputAttr
                  ? !attrInputVals.length
                    ? true
                    : false
                  : false,
              },
            ]}
            help={t('goods.goodsTypeAttributeList.pressEnterToAdd')}
          >
            <Input
              style={{ width: 180 }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                if (!attrInputVals.includes(target.value))
                  setAtterInputVals(attrInputVals.concat(String(target.value)));
                form.resetFields(['AttrValues']);
              }}
            />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            style={{ display: attrInputVals.length ? undefined : 'none' }}
          >
            {attrInputVals && (
              <SortableList
                distance={1}
                lockToContainerEdges
                axis="xy"
                items={attrInputVals}
                onSortEnd={({ oldIndex, newIndex }) => {
                  setAtterInputVals((prev) =>
                    arrayMoveImmutable(prev, oldIndex, newIndex)
                  );
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            name="sortOrder"
            label={t('goods.add/editGoodsTypeAttribute.sortOrder')}
          >
            <InputNumber
              type="number"
              min={0}
              max={50000}
              placeholder="0-50000"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default GoodsTypeAttrModal;
