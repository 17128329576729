import { Card, Space, Switch, Tabs, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CategorySales from '../../components/analysis/sales/CategorySales';
import DepartmentSales from '../../components/analysis/sales/DepartmentSales';
import DepartmentYearSales from '../../components/analysis/sales/DepartmentYearSales';
import GoodsSales from '../../components/analysis/sales/GoodsSales';
import Container from '../../components/Container';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import getDashboardStyle from '../../utils/getDashboardStyle';

const SalesAnalysis = () => {
  const { t } = useTranslation();
  const [isSeller, setIsSeller] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Container>
      <Card>
        <Space>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('analysis.salesTitle')}
          </Typography.Title>
          {getDashboardStyle().isSellerSwitch && (
            <Switch
              loading={isLoading}
              onChange={(checked) => {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => {
                    setIsSeller(checked);
                  }, EXTENDED_TIMEOUT)
                );
              }}
              style={{ marginBottom: 12 }}
              checkedChildren={t('goods.goodsListColumns.seller')}
              unCheckedChildren={t('goods.goodsListColumns.self')}
            />
          )}
        </Space>
        <Tabs destroyInactiveTabPane defaultActiveKey="1">
          <Tabs.TabPane tab={t('analysis.goodsSales.tab1Title')} key="1">
            <GoodsSales
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('analysis.categorySales.tab2Title')} key="2">
            <CategorySales
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('analysis.departmentSales.tab3Title')} key="3">
            <DepartmentSales
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('analysis.departmentYearSales.tab4Title')}
            key="4"
          >
            <DepartmentYearSales
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Container>
  );
};

export default SalesAnalysis;
