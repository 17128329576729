import { Modal, Form, Input, Spin, TreeSelect, InputNumber } from 'antd';
import { LegacyDataNode } from 'rc-tree-select/lib/interface';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { GalleryAlbum } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';

type AlbumModalProps = {
  parent?: GalleryAlbum;
  album?: GalleryAlbum;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  callBack?: () => void;
};

const AlbumModal = ({
  parent,
  album,
  visible,
  setVisible,
  callBack,
}: AlbumModalProps) => {
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState<
    {
      id: number | string;
      value: number | string;
      title: string;
      isLeaf: boolean;
      pId?: number;
      selectable?: boolean;
    }[]
  >([
    {
      id: 0,
      value: 0,
      title: t('settings.photoGalleryColumns.topAlbum'),
      isLeaf: false,
    },
  ]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      if (album)
        form.setFieldsValue({
          ...album,
          parentId: undefined,
          sortOrder: album.sortOrder || 100,
        });
    }
  }, [form, visible, album]);

  const onClose = () => {
    if (isSubscribed.current) {
      setTreeData([
        {
          id: 0,
          value: 0,
          title: t('settings.photoGalleryColumns.topAlbum'),
          isLeaf: false,
        },
      ]);
      setVisible(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(album ? 'gallery/edit' : 'gallery/add', {
          albumId: album ? album.albumId : undefined,
          albumName: values.albumName,
          albumDesc: values.albumDesc || '',
          parentId:
            values.parentId !== undefined
              ? values.parentId
              : parent
              ? parent.albumId
              : 0,
          sortOrder: isNaN(values.sortOrder) ? 100 : values.sortOrder,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              onClose();
              if (callBack) callBack();
              alertMessage(
                'success',
                album
                  ? t('settings.alerts.albumEdited')
                  : t('settings.alerts.albumAdded')
              );
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const makeTreeData = (albums: GalleryAlbum[]) => {
    let temp: any[] = [];
    for (let e of albums) {
      if (album && album.albumId === e.albumId) continue;
      temp.push({
        id: e.albumId,
        pId: e.parentId,
        value: e.albumId,
        title: e.albumName,
        isLeaf: e.childCount ? false : true,
      });
    }
    return temp;
  };

  const getTreeData = (pId: number, page: number) => {
    getDataWithAuthToken('gallery/list', {
      params: {
        parentId: pId,
        page: page,
        size: 15,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          let temp = treeData.slice();
          temp = temp.concat(makeTreeData(response.data.list));
          if (response.data.totalPage > page) {
            if (page > 1) {
              temp = temp.filter((dataNode) => {
                return dataNode.id.toString() !== `${pId}-${page}`;
              });
            }
            temp = temp.concat([
              {
                id: `${pId}-${page + 1}`,
                value: `${pId}-${page + 1}`,
                pId: pId,
                title: t('settings.photoGalleryActions.loadMore'),
                isLeaf: false,
                selectable: false,
              },
            ]);
          } else {
            temp = temp.filter((dataNode) => {
              return dataNode.id.toString() !== `${pId}-${page}`;
            });
          }
          if (isSubscribed.current) setTreeData(temp);
        } else {
          alertMessage(
            'error',
            response?.msg || t('noRespgeneral.noResponseonse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return false;
  };

  const onLoadData = (dataNode: LegacyDataNode) =>
    new Promise<void>((resolve) => {
      const { id, isLeaf } = dataNode;
      if (isLeaf) {
        resolve();
        return;
      }

      if (id.toString().includes('-')) {
        setTimeout(() => {
          getTreeData(parseInt(id.split('-')[0]), parseInt(id.split('-')[1]));
          resolve();
        }, EXTENDED_TIMEOUT);
      } else {
        setTimeout(() => {
          getTreeData(id, 1);
          resolve();
        }, EXTENDED_TIMEOUT);
      }
    });

  return (
    <Modal
      title={
        album
          ? `${t('settings.add/editAlbum.editTitle')} ${album?.albumName || ''}`
          : t('settings.add/editAlbum.addTitle')
      }
      visible={visible}
      okText={t('settings.add/editAlbum.ok')}
      cancelText={t('settings.add/editAlbum.cancel')}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="albumName"
            label={t('settings.add/editAlbum.albumName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="parentId"
            label={t('settings.add/editAlbum.parentAlbum')}
          >
            <TreeSelect
              loadData={onLoadData}
              treeData={treeData}
              treeDataSimpleMode
              placeholder={
                parent
                  ? parent.albumName
                  : t('settings.photoGalleryColumns.topAlbum')
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
          <Form.Item
            name="albumDesc"
            label={t('settings.add/editAlbum.albumDesc')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="sortOrder"
            label={t('settings.add/editAlbum.sortOrder')}
          >
            <InputNumber
              type="number"
              min={0}
              max={50000}
              placeholder="0-50000"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AlbumModal;
