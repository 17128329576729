import { Col, Empty, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { TaskData } from '../../../types';

type TaskEmployeeInfoProps = {
  taskInfo?: TaskData;
};

const TaskEmployeeInfo = ({ taskInfo }: TaskEmployeeInfoProps) => {
  const { t } = useTranslation();

  return taskInfo && taskInfo.employee ? (
    <Form layout="vertical">
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {t('sortationSystem.taskDetail.employeeInfo')}
          </Typography.Text>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={`${t('sortationSystem.employeeListColumns.employeeId')}:`}
          >
            <Typography.Text>{taskInfo.employee.employeeId}</Typography.Text>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={`${t('sortationSystem.employeeListColumns.username')}:`}
          >
            <Typography.Text>{taskInfo.employee.username}</Typography.Text>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={`${t('sortationSystem.employeeListColumns.nickname')}:`}
          >
            <Typography.Text>{taskInfo.employee.nickname}</Typography.Text>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={`${t('sortationSystem.employeeListColumns.mobile')}:`}
          >
            <Typography.Text>{taskInfo.employee.mobile}</Typography.Text>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('sortationSystem.taskDetail.employeeInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskEmployeeInfo;
