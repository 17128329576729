import { Space, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

type ImportExcelPreviewProps = {
  excelHeader: string[];
  excelData: { [key: string]: any }[];
};

const ImportExcelPreview = ({
  excelHeader,
  excelData,
}: ImportExcelPreviewProps) => {
  const { t } = useTranslation();

  const columns: ColumnType<{ [key: string]: any }>[] =
    excelHeader.length > 0
      ? Object.values(excelHeader).map((key, index) => {
          return {
            title: (
              <Space
                size={0}
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Typography.Text>{key}</Typography.Text>
              </Space>
            ),
            dataIndex: key,
            key: `${key}-${index}`,
            render: (value, data) => {
              return (
                <div
                  style={
                    typeof value === 'number'
                      ? {
                          maxWidth: 150,
                          minWidth:
                            // set min width if columns size is less than 6
                            columns.length < 6 ? 80 : undefined,
                          textAlign: 'center',
                        }
                      : { maxWidth: 550, paddingLeft: 5, paddingRight: 5 }
                  }
                >
                  <Typography.Text style={{ wordBreak: 'break-word' }}>
                    {value}
                  </Typography.Text>
                </div>
              );
            },
          };
        })
      : [];

  return (
    <div style={{ marginTop: 20 }}>
      <Typography.Text strong>{t('general.preview')}</Typography.Text>
      <div style={{ marginTop: 10 }}>
        <Table
          dataSource={excelData}
          columns={columns}
          scroll={{ x: 'max-content', y: 600 }}
          pagination={{
            total: excelData.length,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
          }}
        />
      </div>
    </div>
  );
};

export default ImportExcelPreview;
