import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { OrderData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';

type OrderOtherInfoProps = {
  orderInfo?: OrderData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderOtherInfo = ({
  orderInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: OrderOtherInfoProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnEdit = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('order/edit', {
            orderId: orderInfo?.orderId,
            ...values,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('order.alerts.orderEdited'));
                handleOnCancel();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setEditing(true);
        if (setIsCurrentEditing) setIsCurrentEditing(true);
      }
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    setEditing(false);
    if (setIsCurrentEditing) setIsCurrentEditing(false);
  };

  return orderInfo && orderInfo.orderExtend ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          buyerRemark: orderInfo.orderExtend.buyerRemark,
          sellerRemark: orderInfo.orderExtend.sellerRemark,
          deliveryRemark: orderInfo.orderExtend.deliveryRemark,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.orderDetail.otherInfo')}
            </Typography.Text>
            {editing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={`${t('order.orderDetail.buyerRemark')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'buyerRemark' : undefined}
            >
              {editing ? (
                <Input.TextArea />
              ) : (
                <Typography.Text>
                  {orderInfo.orderExtend.buyerRemark}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={`${t('order.orderDetail.sellerRemark')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'sellerRemark' : undefined}
            >
              {editing ? (
                <Input.TextArea />
              ) : (
                <Typography.Text>
                  {orderInfo.orderExtend.sellerRemark}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={`${t('order.orderDetail.deliveryRemark')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'deliveryRemark' : undefined}
            >
              {editing ? (
                <Input.TextArea />
              ) : (
                <Typography.Text>
                  {orderInfo.orderExtend.deliveryRemark}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.otherInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderOtherInfo;
