import React from 'react';
import { Layout } from 'antd';
import Footer from '../../components/Footer';

interface MyProps {}

const { Content } = Layout;

const FooterOnlyLayout = (props: React.PropsWithChildren<MyProps>) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>{props.children}</Content>
      <Footer />
    </Layout>
  );
};

export default FooterOnlyLayout;
