import React, { useEffect, useState } from 'react';
import { ShopConfigData } from '../../types';
import { Form, Button } from 'antd';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { useTranslation } from 'react-i18next';
import { getFormField } from '../../utils/getFormField';
import { GENERAL_TIMEOUT } from '../../constants/systemConstants';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

type ShopConfigFormProps = {
  formInfo: Array<ShopConfigData>;
  callBack?: Function;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Form used for ShopConfig
 *
 * @param formInfo   Information for the form to display
 * @param callBack   Function to call after submit
 * @param setLoading Change whether or not form is still loading
 */
const ShopConfigForm = ({
  formInfo,
  callBack,
  setLoading,
}: ShopConfigFormProps) => {
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.getFieldsValue(true);
  }, [form]);

  /**
   * @param values Form values
   */
  const submitForm = (values: any) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (setLoading) {
          setLoading(true);
        }

        // Separate region Select as its done through a cascader
        if (values.regionSelect) {
          const locationArray = values.regionSelect;
          const length = locationArray.length;
          values.shop_city = length >= 3 ? locationArray[2] : '0';
          values.shop_province = length >= 2 ? locationArray[1] : '0';
          values.shop_country = length >= 1 ? locationArray[0] : '0';
          values.regionSelect = undefined;
        }

        Object.keys(values).forEach((key) => {
          if (typeof values[key] === 'boolean') {
            if (values[key]) {
              values[key] = '1';
            } else {
              values[key] = '0';
            }
          }
        });

        postDataWithAuthToken('setting/shop_config/edit', values)
          .then((response) => {
            if (response && response.goodStatus) {
              alertMessage('success', t('shopConfig.alerts.shopConfigEdited'));
              if (callBack) {
                callBack();
              }
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
            if (setLoading) {
              setLoading(false);
            }
          })
          .catch((err) => {
            if (setLoading) {
              setLoading(false);
            }
            console.log(err);
          });
      }, GENERAL_TIMEOUT)
    );
  };

  return (
    <Form
      form={form}
      {...formItemLayout}
      style={{ marginTop: 24 }}
      onFinish={submitForm}
    >
      {formInfo.map((field) => getFormField(field, formInfo, form))}
      <Form.Item
        wrapperCol={{
          offset: 11,
        }}
      >
        <Button type="primary" htmlType="submit">
          {t('shopConfig.shopConfigActions.ok')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ShopConfigForm;
