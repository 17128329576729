import {
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD_STATUS } from '../../constants/settingsConstants';
import { PaymentConfigField, PaymentEnum, PaymentMethod } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import SellersDropdown from '../sellers/SellersDropdown';

type PaymentMethodModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  paymentInfo?: PaymentMethod;
  callBack?: () => void;
  isSeller?: boolean;
  paymentEnum?: PaymentEnum;
};

const PaymentMethodModal = ({
  visible,
  setVisible,
  paymentInfo,
  callBack,
  isSeller,
  paymentEnum,
}: PaymentMethodModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payConfigFields, setPayConfigFields] = useState<PaymentConfigField[]>(
    []
  );
  const [isPercentage, setIsPercentage] = useState<boolean>(false);

  const onClose = () => {
    setPayConfigFields([]);
    setVisible(false);
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        let payConfig: { [key: string]: string } = {};
        payConfigFields.forEach((field) => {
          payConfig[field.code] = values[field.code];
          delete values[field.code];
        });
        postDataWithAuthToken(paymentInfo ? 'payment/edit' : 'payment/add', {
          ...values,
          payConfig,
          payId: paymentInfo ? paymentInfo.payId : undefined,
          sellerId: paymentInfo ? paymentInfo.sellerId : values.sellerId || 0,
          payFee: values.payFee
            ? isPercentage
              ? `${values.payFee}%`
              : `${values.payFee}`
            : '0',
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                paymentInfo
                  ? alertMessage(
                      'success',
                      t('settings.alerts.paymentMethodEdited')
                    )
                  : alertMessage(
                      'success',
                      t('settings.alerts.paymentMethodAdded')
                    );
                callBack && callBack();
                onClose();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err) => console.log(err));
  };

  const getPaymentConfig = useCallback(
    (payCode: string) => {
      getDataWithAuthToken('payment/config', {
        params: {
          payCode:
            paymentInfo && paymentInfo.payCode
              ? paymentInfo.payCode.code
              : payCode,
        },
      })
        .then((response) => {
          if (response) {
            if (response.goodStatus) {
              setPayConfigFields(response.data.list || []);
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [t, paymentInfo]
  );

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (paymentInfo) {
        if (paymentInfo.payCode) {
          getPaymentConfig(paymentInfo.payCode.code);
        }
        if (paymentInfo.payFee) {
          setIsPercentage(paymentInfo.payFee.indexOf('%') !== -1);
        }
      } else {
        setIsPercentage(false);
      }
    }
  }, [visible, form, paymentInfo, getPaymentConfig]);

  return (
    <Spin spinning={isLoading}>
      <Modal
        title={
          paymentInfo
            ? t('settings.add/editPayment.editTitle')
            : t('settings.add/editPayment.addTitle')
        }
        visible={visible}
        onOk={onConfirm}
        onCancel={onClose}
        okText={t('settings.add/editPayment.ok')}
        cancelText={t('settings.add/editPayment.cancel')}
        bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
      >
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          initialValues={
            paymentInfo
              ? {
                  ...paymentInfo,
                  ...paymentInfo.payConfig,
                  status: paymentInfo.status
                    ? paymentInfo.status.code
                    : undefined,
                  payFee:
                    paymentInfo.payFee && paymentInfo.payFee.indexOf('%') === -1
                      ? Number(paymentInfo.payFee)
                      : Number(paymentInfo.payFee.replace('%', '')),
                }
              : { sortOrder: 1, payFee: 0, status: 'ENABLED' }
          }
        >
          {paymentInfo ? (
            <Form.Item label={t('settings.paymentColumns.seller')}>
              <>{paymentInfo.seller && paymentInfo.seller.shopName}</>
            </Form.Item>
          ) : isSeller ? (
            <Form.Item
              label={t('settings.paymentColumns.seller')}
              name="sellerId"
              rules={[{ required: true }]}
            >
              <SellersDropdown />
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item
            label={t('settings.paymentColumns.payName')}
            name="payName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Space size="large" align="end">
            <Form.Item
              label={t('settings.paymentColumns.payFee')}
              name="payFee"
            >
              <InputNumber
                min={0}
                type="number"
                addonAfter={isPercentage ? '%' : undefined}
                style={{ width: 100 }}
              />
            </Form.Item>
            <Form.Item>
              <Radio.Group
                value={isPercentage}
                onChange={(e) => setIsPercentage(e.target.value)}
              >
                <Radio value={false}>
                  {t('settings.add/editPayment.fixed')}
                </Radio>
                <Radio value={true}>
                  {t('settings.add/editPayment.percentage')}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Space>
          <Form.Item
            label={t('settings.paymentColumns.payDesc')}
            name="payDesc"
          >
            <Input.TextArea autoSize={{ maxRows: 4 }} />
          </Form.Item>
          {!paymentInfo && (
            <Form.Item
              label={t('settings.paymentColumns.payCode')}
              name="payCode"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(value) => {
                  getPaymentConfig(value);
                }}
              >
                {paymentEnum &&
                  paymentEnum.payCodeList.map((payCode) => (
                    <Select.Option value={payCode.code} key={payCode.code}>
                      {payCode.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          {payConfigFields.length > 0 &&
            payConfigFields.map((field) => (
              <Form.Item
                key={field.code}
                label={field.description}
                name={field.code}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            ))}
          <Form.Item
            label={t('settings.paymentColumns.payStatus')}
            name="status"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {Object.keys(PAYMENT_METHOD_STATUS).map((key) => (
                <Radio value={key} key={key}>
                  {t(PAYMENT_METHOD_STATUS[key])}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={t('settings.paymentColumns.sortOrder')}
            name="sortOrder"
          >
            <InputNumber type="number" min={0} max={50000} />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default PaymentMethodModal;
