import { EditOutlined, ProfileOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { HIGHLIGHT_YELLOW, PURPLE1, RED1 } from '../../../constants/color';
import { BasicEnumInfoType, GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import TransportDropdown from '../../settings/common/TransportDropdown';
import BrandDropdown from '../common/BrandDropdown';
import CategoryDropdown from '../common/CategoryDropdown';
import DepartmentDropdown from '../common/DepartmentDropdown';
import SupplierDropdown from '../common/SupplierDropdown';
import GoodCategoryModal from './GoodCategoryModal';
import MainGoodsOverview from './MainGoodsOverview';

type GoodDescriptionProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Displays User Information
 *
 * @param userInfo User Information to display
 */
const GoodClassification = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodDescriptionProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [eventTags, setEventTags] = useState<string[]>([]);
  const [shippingMethod, setShippingMethod] = useState('');
  const formRef = useRef(null);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [extendCatList, setExtendCatList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [goodTags, setGoodTags] = useState<BasicEnumInfoType[]>([]);
  const tagColor: { [key: string]: string } = {
    isBest: HIGHLIGHT_YELLOW,
    isHot: RED1,
    isNew: PURPLE1,
  };
  const weekdays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const handleEditing = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          if (isSubscribed.current) setLoading(true);
          postDataWithAuthToken('goods/edit', {
            goodsId: goodInfo && goodInfo.goodsId,
            catId: values.catId.at(-1),
            extendCatList: extendCatList.map((c) => c.catId),
            saleDay: values.saleDay.join(),
            isBest: values.eventTags.includes('isBest'),
            isNew: values.eventTags.includes('isNew'),
            isHot: values.eventTags.includes('isHot'),
            brandId: values.brandId ? values.brandId.value : 0,
            departmentId: values.departmentId
              ? values.departmentId.value
              : undefined,
            supplierId: values.supplierId ? values.supplierId.value : 0,
            shippingMethod: values.shippingMethod,
            shippingTransportId:
              values.shippingMethod === 'SHIPPING'
                ? values.shippingTransportId.value
                : undefined,
            goodsTag: values.goodsTag ? values.goodsTag.value : undefined,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('goods.alerts.goodsEdited'));
                handleCancelEditing();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              if (isSubscribed.current) setLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    if (goodInfo) {
      form.resetFields();
      if (isSubscribed.current) setExtendCatList(goodInfo.extendCatList || []);
    }
    if (isSubscribed.current) {
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  const getGoodTags = () => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setGoodTags(response.data.goodsTag);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (goodInfo) {
      let tags: { [key: string]: boolean } = {
        isBest: goodInfo.isBest || false,
        isHot: goodInfo.isHot || false,
        isNew: goodInfo.isNew || false,
      };

      let keys = [];
      for (let key of Object.keys(tags)) {
        if (!tags[key]) continue;
        keys.push(key);
      }
      form.setFieldsValue({ eventTags: keys });
      if (isSubscribed.current) {
        setEventTags(keys);
        setShippingMethod(goodInfo.shippingMethod);
        setExtendCatList(goodInfo.extendCatList || []);
      }
    }
  }, [goodInfo, t, form]);

  return goodInfo ? (
    <Spin spinning={loading}>
      <Form
        form={form}
        ref={formRef}
        initialValues={{
          ...goodInfo,
          eventTags: eventTags,
          goodsTag: goodInfo.goodsTag
            ? {
                label: goodInfo.goodsTag.description,
                value: goodInfo.goodsTag.code,
              }
            : undefined,
          departmentId: goodInfo.department
            ? {
                value: goodInfo.department.departmentId,
                label: goodInfo.department.departmentName,
              }
            : undefined,
          supplierId: goodInfo.supplier
            ? {
                value: goodInfo.supplier.supplierId,
                label: goodInfo.supplier.supplierName,
              }
            : undefined,
          brandId: goodInfo.brand
            ? { value: goodInfo.brand.brandId, label: goodInfo.brand.brandName }
            : undefined,
          catId: goodInfo.category ? goodInfo.category.allCatIds : [],
          saleDay: goodInfo.saleDay ? goodInfo.saleDay.split(',') : [],
          shippingMethod: goodInfo.shippingMethod || undefined,
          shippingTransportId: goodInfo.shippingTransport
            ? {
                value: goodInfo.shippingTransport.transportId,
                label: goodInfo.shippingTransport.transportName,
              }
            : undefined,
        }}
      >
        <Row gutter={[24, 8]}>
          <Col span={16}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('goods.add/editGood.classification')}
            </Typography.Text>
            {goodInfo && goodInfo.mainGoods && (
              <Popover
                trigger={'click'}
                placement="right"
                content={<MainGoodsOverview goodInfo={goodInfo} />}
              >
                <ProfileOutlined style={{ padding: 5, fontSize: '16px' }} />
              </Popover>
            )}
          </Col>
          <Col span={8}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editing ? (
                <Space>
                  <Button onClick={() => handleCancelEditing()}>
                    {t('goods.add/editGood.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                  >
                    {t('goods.add/editGood.ok')}
                  </Button>
                </Space>
              ) : (
                <Tooltip
                  title={t('goods.add/editGood.edit')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        handleEditing();
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.add/editGood.goodsTag')}
              name={editing ? 'goodsTag' : undefined}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              {editing ? (
                <Select
                  labelInValue
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={t('general.pleaseSelect')}
                  showSearch={false}
                  onFocus={() => {
                    if (!goodTags.length) getGoodTags();
                  }}
                >
                  {goodTags.map((tag) => (
                    <Select.Option key={tag.code} value={tag.code}>
                      {tag.description}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <>{goodInfo.goodsTag && goodInfo.goodsTag.description}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.goodsListColumns.eventTag')}
              name={editing ? 'eventTags' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Select
                  mode="multiple"
                  placeholder={t('general.pleaseSelect')}
                  showSearch={false}
                  tagRender={(props) => (
                    <Tag color={tagColor[props.value]}>
                      {t(`goods.goodsListColumns.${props.value}`)}
                    </Tag>
                  )}
                >
                  <Select.Option key="isBest" value="isBest">
                    {t(`goods.goodsListColumns.isBest`)}
                  </Select.Option>
                  <Select.Option key="isHot" value="isHot">
                    {t(`goods.goodsListColumns.isHot`)}
                  </Select.Option>
                  <Select.Option key="isNew" value="isNew">
                    {t(`goods.goodsListColumns.isNew`)}
                  </Select.Option>
                </Select>
              ) : (
                eventTags.map((tag) => (
                  <Tag color={tagColor[tag]} key={tag}>
                    {t(`goods.goodsListColumns.${tag}`)}
                  </Tag>
                ))
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.goodsListColumns.category')}
              name={editing ? 'catId' : undefined}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              {editing ? (
                <CategoryDropdown
                  categoryOptions={categoryOptions}
                  setCategoryOptions={setCategoryOptions}
                  initialValue={
                    goodInfo.category ? goodInfo.category.allCatIds : []
                  }
                />
              ) : (
                <>{goodInfo.category && goodInfo.category.fullCatName}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.add/editGood.extendCatList')}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Button
                  type="link"
                  onClick={() => setShowCategoryModal(true)}
                  style={{ padding: 0 }}
                >
                  {t('goods.add/editGood.edit')}
                </Button>
              ) : (
                <div
                  style={{
                    height: 'auto',
                    paddingTop: 6,
                    maxHeight: 120,
                    overflowY: 'auto',
                  }}
                >
                  {goodInfo.extendCatList &&
                    goodInfo.extendCatList.map(
                      (cat, index) =>
                        cat.category && (
                          <Tag key={index} style={{ marginBottom: 8 }}>
                            {cat.category.fullCatName}
                          </Tag>
                        )
                    )}
                </div>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.add/editGood.department')}
              name={editing ? 'departmentId' : undefined}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              {editing ? (
                <DepartmentDropdown
                  labelInValue={true}
                  initialValue={
                    goodInfo.department
                      ? {
                          value: goodInfo.department.departmentId,
                          label: goodInfo.department.departmentName,
                        }
                      : undefined
                  }
                />
              ) : (
                <>{goodInfo.department && goodInfo.department.departmentName}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label={t('goods.add/editSupplier.title')}
              name={editing ? 'supplierId' : undefined}
            >
              {editing ? (
                <SupplierDropdown
                  labelInValue={true}
                  initialValue={
                    goodInfo.supplier
                      ? {
                          value: goodInfo.supplier.supplierId,
                          label: goodInfo.supplier.supplierName,
                        }
                      : undefined
                  }
                />
              ) : (
                <>{goodInfo.supplier && goodInfo.supplier.supplierName}</>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.goodsListColumns.brand')}
              name={editing ? 'brandId' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <BrandDropdown
                  labelInValue={true}
                  initialValue={
                    goodInfo.brand
                      ? {
                          value: goodInfo.brand.brandId,
                          label: goodInfo.brand.brandName,
                        }
                      : undefined
                  }
                />
              ) : (
                goodInfo.brand && goodInfo.brand.brandName
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label={t('goods.add/editGood.shippingMethod.title')}
              name={editing ? 'shippingMethod' : undefined}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              {editing ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={t('general.pleaseSelect')}
                  showSearch={false}
                  onChange={(value) => setShippingMethod(value)}
                >
                  <Select.Option key={1} value="PICKUP">
                    {t('goods.add/editGood.shippingMethod.PICKUP')}
                  </Select.Option>
                  <Select.Option key={2} value="SHIPPING">
                    {t('goods.add/editGood.shippingMethod.SHIPPING')}
                  </Select.Option>
                </Select>
              ) : (
                <>
                  {t(
                    `goods.add/editGood.shippingMethod.${goodInfo.shippingMethod}`
                  )}
                </>
              )}
            </Form.Item>
          </Col>
          {shippingMethod === 'SHIPPING' && (
            <Col span={24} lg={12}>
              <Form.Item
                label={t('goods.add/editGood.shippingMethod.shippingTemplate')}
                name={editing ? 'shippingTransportId' : undefined}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                {editing ? (
                  <TransportDropdown
                    sellerId={goodInfo.seller.sellerId}
                    labelInValue={true}
                    initialValue={
                      goodInfo.shippingTransport
                        ? {
                            value: goodInfo.shippingTransport.transportId,
                            label: goodInfo.shippingTransport.transportName,
                          }
                        : undefined
                    }
                  />
                ) : (
                  <>
                    {goodInfo.shippingTransport
                      ? goodInfo.shippingTransport.transportName
                      : ''}
                  </>
                )}
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label={t('goods.add/editGood.saleDay')}
              name={editing ? 'saleDay' : undefined}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Select mode="multiple" showSearch={false}>
                  {weekdays.map((day) => (
                    <Select.Option key={day} value={day}>
                      {t(`goods.add/editGood.${day}`)}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                goodInfo.saleDay &&
                goodInfo.saleDay
                  .split(',')
                  .map((day) => `${t(`goods.add/editGood.${day}`)} `)
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <GoodCategoryModal
        visible={showCategoryModal}
        setVisible={setShowCategoryModal}
        categoryList={extendCatList}
        setCategoryList={setExtendCatList}
        allCategories={categoryOptions}
      />
    </Spin>
  ) : (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('goods.add/editGood.classification')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default GoodClassification;
