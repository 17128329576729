import { Form, Input, Modal, Select, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SMSTempData, SMSTempEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';

type SMSTemplateModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  callBack?: () => void;
  smsTempEnums?: SMSTempEnum;
  selectedSMSTemp?: SMSTempData;
};

const SMSTemplateModal = ({
  visible,
  setVisible,
  callBack,
  smsTempEnums,
  selectedSMSTemp,
}: SMSTemplateModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTempCode, setSelectedTempCode] = useState<{
    code: string;
    tempId: string;
    variables: string;
    content: string;
  }>();
  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (selectedSMSTemp) {
        form.setFieldsValue({ tempCode: selectedSMSTemp.tempCode });
        smsTempEnums &&
          smsTempEnums.tempCodeList.forEach((tempCode) => {
            if (tempCode.code === selectedSMSTemp.tempCode) {
              setSelectedTempCode(tempCode);
              form.resetFields(['tempId', 'variables', 'content']);
              // form.setFieldsValue({
              //   tempCode: selectedSMSTemp.tempCode,
              //   tempId: selectedSMSTemp.tempId,
              //   tempContent: selectedSMSTemp.tempContent,
              // });
            }
          });
      }
    }
  }, [visible, form, selectedSMSTemp, smsTempEnums]);

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken(
          selectedSMSTemp ? 'sms_temp/edit' : 'sms_temp/add',
          { ...values, id: selectedSMSTemp ? selectedSMSTemp.id : undefined }
        )
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              alertMessage(
                'success',
                selectedSMSTemp
                  ? t('settings.alerts.smsTempEdited')
                  : t('settings.alerts.smsTempAdded')
              );
              callBack && callBack();
              onClose();
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    setSelectedTempCode(undefined);
    setVisible(false);
  };

  return (
    <Modal
      title={
        selectedSMSTemp
          ? `${t(`settings.add/editSMSTemp.editTitle`)} ${
              selectedSMSTemp.tempCode
            }`
          : `${t(`settings.add/editSMSTemp.addTitle`)}`
      }
      onOk={onConfirm}
      visible={visible}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            selectedSMSTemp
              ? {
                  ...selectedSMSTemp,
                  tempCode: selectedSMSTemp.tempCode,
                  tempId: selectedSMSTemp.tempId,
                  smsSignature: selectedSMSTemp.smsSignature,
                  tempContent: selectedSMSTemp.tempContent,
                }
              : {}
          }
        >
          <Form.Item
            label={t('settings.add/editSMSTemp.tempCode')}
            name="tempCode"
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              placeholder={t('general.inputError.pleaseSelectOne')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onChange={(value) => {
                smsTempEnums &&
                  smsTempEnums.tempCodeList.forEach((tempCode) => {
                    if (tempCode.code === value) {
                      form.resetFields(['tempId', 'variables', 'content']);
                      form.setFieldsValue({
                        tempId: tempCode.tempId,
                        tempContent: tempCode.content,
                      });
                      setSelectedTempCode(tempCode);
                    }
                  });
              }}
            >
              {smsTempEnums?.tempCodeList.map((tempCode) => (
                <Select.Option key={tempCode.code} value={tempCode.code}>
                  {tempCode.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {selectedTempCode && (
            <Form.Item
              label={t('settings.add/editSMSTemp.tempId')}
              name="tempId"
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            name={'smsSignature'}
            label={t('settings.add/editSMSTemp.smsSignature')}
          >
            <Input />
          </Form.Item>
          {selectedTempCode && (
            <Form.Item
              label={t('settings.add/editSMSTemp.tempContent')}
              name="tempContent"
            >
              <TextArea />
            </Form.Item>
          )}
          {selectedTempCode && (
            <Form.Item
              label={t('settings.add/editSMSTemp.variables')}
              name="variables"
            >
              <Typography.Text>{selectedTempCode.variables}</Typography.Text>
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default SMSTemplateModal;
