import {
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Image,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import videoLogo from '../../../assets/images/video-logo.png';
import { actionPermissions } from '../../../constants/actionPermissions';
import { RED1 } from '../../../constants/color';
import { FALLBACK_IMG } from '../../../constants/styles';
import { GalleryMedia, GoodData, GoodPicData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { isVideo } from '../../../utils/checkFileType';
import { hasPermission } from '../../../utils/hasPermission';
import PhotoGalleryModal from '../../PhotoGalleryModal';
import VideoPreviewModal from '../../photoGallery/VideoPreviewModal';

type PhotoGalleryGridProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Grid to display photos/videos
 *
 * @param photos Array of photos to display
 */
const GoodGallery = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: PhotoGalleryGridProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [video, setVideo] = useState<GalleryMedia>();
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState<GoodPicData[]>(
    (goodInfo && goodInfo.pics) || []
  );
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const handleEditing = () => {
    if (editing) {
      if (isSubscribed.current) setLoading(true);
      postDataWithAuthToken('goods/edit', {
        goodsId: goodInfo && goodInfo.goodsId,
        pics: items.map((item, index) => {
          if (item.imgId) {
            return {
              imgId: item.imgId,
              largePic: item.largePic,
              thumbPic: item.thumbPic,
              originalPic: item.originalPic,
              sortOrder: index + 1,
            };
          } else {
            return {
              largePic: item.largePic,
              thumbPic: item.thumbPic,
              originalPic: item.originalPic,
              sortOrder: index + 1,
            };
          }
        }),
      })
        .then((response) => {
          if (response && response.goodStatus) {
            alertMessage('success', t('goods.alerts.goodsEdited'));
            handleCancelEditing();
            if (callBack) callBack();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setLoading(false);
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    if (isSubscribed.current) {
      if (goodInfo && goodInfo.pics) setItems(goodInfo.pics);
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  const SortableBar = SortableHandle(() => (
    <DragOutlined style={{ fontSize: '18px', cursor: 'grab' }} />
  ));

  const SortableItem = SortableElement(({ photo }: { photo: GoodPicData }) => (
    <Card
      style={{ height: 138, width: 110 }}
      hoverable={editing}
      bodyStyle={{ padding: 5 }}
      cover={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 5,
          }}
        >
          <Image
            preview={{
              visible: false,
              mask: (
                <Tooltip
                  title={t('settings.photoGalleryActions.preview')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    size="small"
                    icon={<EyeOutlined />}
                    onClick={() => {
                      if (isVideo(photo.originalPicPath)) {
                        setVideo(photo);
                        setShowVideoModal(true);
                      } else {
                        setPreviewIndex(
                          items.findIndex(
                            (item) => item.originalPic === photo.originalPic
                          )
                        );
                        setShowPreview(true);
                      }
                    }}
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      left: 6,
                      bottom: 6,
                    }}
                  />
                </Tooltip>
              ),
            }}
            src={
              isVideo(photo.originalPicPath) ? videoLogo : photo.thumbPicPath
            }
            width="auto"
            height={100}
            fallback={FALLBACK_IMG}
          />
          <Space>
            {editing && (
              <Tooltip
                title={t('actionsColumn.delete')}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <Button
                  disabled={editing ? false : true}
                  size="small"
                  icon={<DeleteOutlined style={{ color: RED1 }} />}
                  onClick={() => {
                    setItems((prev) =>
                      prev.filter((p) =>
                        p.imgId
                          ? p.imgId !== photo.imgId
                          : p.picId !== photo.picId
                      )
                    );
                  }}
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    right: 10,
                    bottom: 38,
                  }}
                />
              </Tooltip>
            )}
          </Space>
        </div>
      }
    >
      <Card.Meta />
      <div style={{ textAlign: 'center' }}>
        {editing && <SortableBar />}
        {photo.sortOrder
          ? ` ${t('goods.add/editGood.sortOrder')}: ${photo.sortOrder}`
          : 'New'}
      </div>
    </Card>
  ));

  const SortableList = SortableContainer(
    ({ items }: { items: GoodPicData[] }) => (
      <Row gutter={[16, 16]}>
        {items.map((photo, index) => (
          <Col key={index}>
            <SortableItem photo={photo} index={index} />
          </Col>
        ))}
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup
            preview={{
              visible: showPreview,
              onVisibleChange: (value) => setShowPreview(value),
              current: previewIndex,
            }}
          >
            {items &&
              items.map((photo, index) => (
                <Image src={photo.largePicPath} key={index} />
              ))}
          </Image.PreviewGroup>
        </div>
      </Row>
    )
  );

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Space>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('goods.add/editGood.goodsGallery')}
            </Typography.Text>
            {editing && (
              <Tooltip
                title={t('goods.add/editGood.addMedia')}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    setFirstLoad(true);
                    setShowModal(true);
                  }}
                  icon={<PlusOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        </Col>
        <Col span={8}>
          <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {editing ? (
              <Space>
                <Button onClick={() => handleCancelEditing()}>
                  {t('goods.add/editGood.cancel')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (hasPermission(actionPermissions.goodGroup.goodManage))
                      handleEditing();
                  }}
                >
                  {t('goods.add/editGood.ok')}
                </Button>
              </Space>
            ) : (
              <Tooltip
                title={t('goods.add/editGood.edit')}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <Button
                  type="text"
                  onClick={() => {
                    if (hasPermission(actionPermissions.goodGroup.goodManage))
                      handleEditing();
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        </Col>
        {editing && (
          <Col span={24}>{t('goods.add/editGood.toolTip.photoGallery')}</Col>
        )}
        <Col span={24}>
          {items && !!items.length ? (
            <SortableList
              axis="xy"
              useDragHandle
              items={items}
              onSortEnd={({ oldIndex, newIndex }) => {
                setItems((prev) =>
                  arrayMoveImmutable(prev, oldIndex, newIndex)
                );
              }}
            />
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
      <PhotoGalleryModal
        visible={showModal}
        setVisible={setShowModal}
        setValue={setItems}
        value={items}
        firstLoad={firstLoad}
        isMultiple
      />
      <VideoPreviewModal
        visible={showVideoModal}
        setVisible={setShowVideoModal}
        videoPath={video?.originalPicPath}
        title={video?.picName}
      />
    </Spin>
  );
};

export default GoodGallery;
