import { useTranslation } from 'react-i18next';
import { AdEnum, AdSettingAdPositionData } from '../../types';
import { Form, Input, Modal, Select, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';

type AdPositionModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  adEnum?: AdEnum;
  selectedPositionData?: AdSettingAdPositionData;
  refresh: Function;
};

const AdPositionModal = ({
  visible,
  setVisible,
  adEnum,
  selectedPositionData,
  refresh,
}: AdPositionModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
    }
  }, [t, form, visible]);

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setLoading(true);
        postDataWithAuthToken(
          selectedPositionData ? 'ad_position/edit' : 'ad_position/add',
          {
            ...values,
            positionId: selectedPositionData
              ? selectedPositionData.positionId
              : undefined,
          }
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedPositionData
                ? t('advertisement.alerts.adPositionEdited')
                : t('advertisement.alerts.adPositionAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }

          if (isSubscribed.current) setLoading(false);
        });
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedPositionData
          ? `${t('advertisement.add/EditAd.editPositionTitle')} ${
              selectedPositionData.positionName
            }`
          : `${t('advertisement.add/EditAd.addPositionTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          initialValues={
            selectedPositionData ? { ...selectedPositionData } : {}
          }
          form={form}
          onFinish={onFinish}
        >
          <Form.Item /**For Position Name */
            name="positionName"
            label={t('advertisement.adPositionColumnList.positionName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item /**For Position Location */
            name="positionLocation"
            label={t('advertisement.adPositionColumnList.positionLocation')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
            >
              {adEnum &&
                adEnum.positionLocation.map((location) => (
                  <Select.Option
                    key={location.description}
                    value={location.code}
                  >
                    {location.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item /**For Position Description */
            name="positionDesc"
            label={t('advertisement.adPositionColumnList.positionDesc')}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AdPositionModal;
