import Auth from '../pages/Auth';
import Dashboard from '../pages/Dashboard';
import Admin from '../pages/Admin';
import Users from '../pages/Users';
import AdminList from '../views/admin/AdminList';
import AdminRole from '../views/admin/AdminRole';
import AdminLog from '../views/admin/AdminLog';
import FourZeroFour from '../components/FourZeroFour';
import Settings from '../pages/Settings';
import Region from '../views/settings/Region';
import UserRank from '../views/user/UserRank';
import UserList from '../views/user/UserList';
import FourZeroThree from '../components/FourZeroThree';
import Login from '../views/Login';
import MyProfile from '../views/MyProfile';
import UserDetail from '../views/user/UserDetail';
import UserLogs from '../views/user/UserLogs';
import UserAddress from '../views/user/UserAddress';
import BasicSettings from '../views/settings/BasicSettings';
import GoodsSettings from '../views/goods/GoodsSettings';
import SMSSettings from '../views/plugins/sms/SMSSettings';
import MailSettings from '../views/plugins/MailSettings';
import SellerSettings from '../views/sellers/SellerSettings';
import Landing from '../pages/Landing';
import UserDevice from '../views/user/UserDevice';
import Goods from '../pages/Goods';
import Sellers from '../pages/Sellers';
import Plugins from '../pages/Plugins';
import SMS from '../pages/SMS';
import Mail from '../pages/Mail';
import S3 from '../views/plugins/S3';
import PhotoGallery from '../views/settings/PhotoGallery';
import DashboardHome from '../views/DashboardHome';
import { dashboardRoute, authRoute, rootRoute } from './pathname';
import FileManager from '../views/settings/FileManager';
import CategoryList from '../views/goods/CategoryList';
import BrandList from '../views/goods/BrandList';
import Department from '../views/goods/DepartmentList';
import {
  AlertOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  FileTextOutlined,
  LockOutlined,
  MailOutlined,
  MessageOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  StarOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import SellerGrade from '../views/sellers/SellerGrade';
import SellerList from '../views/sellers/SellerList';
import SellerDetail from '../views/sellers/SellerDetail';
import SellerAdminList from '../views/sellers/SellerAdminList';
import InventoryLogs from '../views/goods/InventoryLogList';
import Shipping from '../pages/Shipping';
import ShippingList from '../views/settings/shipping/ShippingList';
import TransportList from '../views/settings/shipping/TransportList';
import GoodsTypeList from '../views/goods/GoodsTypeList';
import GoodsLog from '../views/goods/GoodsLog';
import Order from '../pages/Order';
import OrderList from '../views/order/OrderList';
import GoodsList from '../views/goods/GoodList';
import GoodDetail from '../views/goods/GoodDetail';
import OrderDetail from '../views/order/OrderDetail';
import PickupList from '../views/settings/shipping/PickUpList';
import FormField from '../views/settings/FormField';
import PaymentSetting from '../views/settings/PaymentSetting';
import MismatchList from '../views/goods/MismatchList';
import Cache from '../views/Cache';
import Activity from '../pages/Activity';
import CouponList from '../views/activity/CouponList';
import UserIDList from '../views/user/UserIDList';
import FavActivityList from '../views/activity/FavActivityList';
import UserCouponList from '../views/activity/UserCouponList';
import AdDetail from '../views/advertisement/AdDetail';
import HomeAdList from '../views/advertisement/HomeAdList';
import Advertisement from '../pages/Advertisement';
import OrderClaimList from '../views/order/orderClaim/OrderClaimList';
import ArticleList from '../views/advertisement/ArticleList';
import ArticleCatList from '../views/advertisement/ArticleCatList';
import Article from '../pages/Article';
import OrderClaimDetail from '../views/order/orderClaim/OrderClaimDetail';
import SMSTemplateList from '../views/plugins/sms/SMSTemplateList';
import GoodCommentsList from '../views/goods/GoodCommentsList';
import UserAnalysis from '../views/analysis/UserAnalysis';
import Analysis from '../pages/Analysis';
import OrderAnalysis from '../views/analysis/OrderAnalysis';
import GoodsAnalysis from '../views/analysis/GoodsAnalysis';
import SMSLogList from '../views/plugins/sms/SMSLogList';
import SalesAnalysis from '../views/analysis/SalesAnalysis';
import OrderClaimAnalysis from '../views/analysis/OrderClaimAnalysis';
import { actionPermissions } from './actionPermissions';
import OpenApi from '../views/settings/OpenApi';
import EmployeeList from '../views/sortationSystem/EmployeeList';
import SortationSystem from '../pages/SortationSystem';
import EmployeeLogList from '../views/sortationSystem/EmployeeLogList';
import SupplierList from '../views/goods/SupplierList';
import MainGoodsList from '../views/goods/MainGoodsList';
import TaskList from '../views/sortationSystem/TaskList';
import TaskDetail from '../views/sortationSystem/TaskDetail';
import Salesperson from '../pages/Salesperson';
import SalespersonList from '../views/salesperson/SalespersonList';
import ValueCardTypeList from '../views/activity/ValueCardTypeList';
import ValueCardList from '../views/activity/ValueCardList';
import EmployeeAnalysis from '../views/analysis/EmployeeAnalysis';
import TaskAnalysis from '../views/analysis/TaskAnalysis';
import AdPositionList from '../views/advertisement/AdPositionList';
import AdPositionAdList from '../views/advertisement/AdPositionAdList';

// title:     Title of the page (What would show up in tab and document title)
// path:      URL the page will be on
// exact:     Requires the exact path to be entered
// component: The component that will be rendered when path is entered
// routes:    Child routes of the path
// actions:   Permissions to access the route
// icon:      Icon of the menu item
// hide:      Hide the menu item
export const routes = [
  {
    title: 'Luniu Mall',
    path: rootRoute.path,
    exact: true,
    component: Landing,
  },
  {
    title: 'dashboard.title',
    path: dashboardRoute.path,
    component: Dashboard,
    routes: [
      {
        title: 'dashboard.title',
        path: dashboardRoute.home,
        component: DashboardHome,
      },
      {
        title: 'users.title',
        path: dashboardRoute.users.path,
        component: Users,
        actions: Object.values(actionPermissions.userGroup),
        icon: UserOutlined,
        routes: [
          {
            title: 'users.userList',
            path: dashboardRoute.users.list,
            component: UserList,
            parent: dashboardRoute.users.path,
            actions: [actionPermissions.userGroup.userView],
          },
          {
            title: 'users.userLogs',
            path: dashboardRoute.users.log,
            component: UserLogs,
            parent: dashboardRoute.users.path,
            actions: [actionPermissions.userGroup.userLog],
          },
          {
            title: 'users.userRank',
            path: dashboardRoute.users.rank,
            component: UserRank,
            parent: dashboardRoute.users.path,
            actions: [actionPermissions.userGroup.userRank],
          },
          {
            title: 'users.userDevice',
            path: dashboardRoute.users.device,
            component: UserDevice,
            parent: dashboardRoute.users.path,
            actions: [actionPermissions.userGroup.userDevice],
          },
          {
            title: 'users.userIDList',
            path: dashboardRoute.users.idList,
            component: UserIDList,
            parent: dashboardRoute.users.path,
            actions: [actionPermissions.userGroup.userRealManage],
          },
          {
            title: 'users.userAddress',
            path: dashboardRoute.users.address,
            component: UserAddress,
            parent: dashboardRoute.users.path,
            actions: [actionPermissions.userGroup.userAddress],
          },

          {
            title: 'users.userDetail',
            path: `${dashboardRoute.users.detail}?user_id=:userId`,
            component: UserDetail,
            hide: true,
          },
        ],
      },
      {
        title: 'salesperson.title',
        path: dashboardRoute.salesperson.path,
        component: Salesperson,
        actions: Object.values(actionPermissions.salespersonGroup),
        icon: SolutionOutlined,
        routes: [
          {
            title: 'salesperson.salespersonList',
            path: dashboardRoute.salesperson.list,
            component: SalespersonList,
            actions: [actionPermissions.salespersonGroup.salespersonView],
          },
        ],
      },
      {
        title: 'admin.title',
        path: dashboardRoute.admins.path,
        component: Admin,
        actions: Object.values(actionPermissions.adminGroup),
        icon: LockOutlined,
        routes: [
          {
            title: 'admin.adminList',
            path: dashboardRoute.admins.list,
            component: AdminList,
            parent: dashboardRoute.admins.path,
            actions: [actionPermissions.adminGroup.adminManage],
          },
          {
            title: 'admin.adminLogs',
            path: dashboardRoute.admins.log,
            component: AdminLog,
            parent: dashboardRoute.admins.path,
            actions: [actionPermissions.adminGroup.adminLogsManage],
          },
          {
            title: 'admin.adminRole',
            path: dashboardRoute.admins.role,
            component: AdminRole,
            parent: dashboardRoute.admins.path,
            actions: [actionPermissions.adminGroup.adminRoleManage],
          },
        ],
      },
      {
        title: 'goods.goods',
        path: dashboardRoute.goods.path,
        component: Goods,
        actions: Object.values(actionPermissions.goodGroup),
        icon: ShoppingOutlined,
        routes: [
          {
            title: 'goods.goodsSetting',
            path: dashboardRoute.goods.settings,
            component: GoodsSettings,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.shopConfig],
          },
          {
            title: 'goods.goodsList',
            path: dashboardRoute.goods.list,
            component: GoodsList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.goodView],
          },
          {
            title: 'goods.goodsCommentsList',
            path: dashboardRoute.goods.commentsSidebar,
            component: GoodCommentsList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.goodComments],
          },
          {
            title: 'goods.goodsCommentsList',
            path: `${dashboardRoute.goods.comments}?good_id=:goodId`,
            component: GoodCommentsList,
            hide: true,
          },
          {
            title: 'goods.goodsLog',
            path: `${dashboardRoute.goods.logSidebar}`,
            component: GoodsLog,
            actions: [actionPermissions.goodGroup.goodView],
          },
          {
            title: 'goods.goodsLog',
            path: `${dashboardRoute.goods.log}?good_id=:goodId`,
            component: GoodsLog,
            hide: true,
          },
          {
            title: 'goods.inventoryLogs',
            path: `${dashboardRoute.goods.inventoryLogsSidebar}`,
            component: InventoryLogs,
            actions: [actionPermissions.goodGroup.goodView],
          },
          {
            title: 'goods.inventoryLogs',
            path: `${dashboardRoute.goods.inventoryLogsGoodsList}?good_id=:goodId`,
            component: InventoryLogs,
            hide: true,
          },
          {
            title: 'goods.categoryList',
            path: dashboardRoute.goods.categories,
            component: CategoryList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.categoryView],
          },
          {
            title: 'goods.brandList',
            path: dashboardRoute.goods.brands,
            component: BrandList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.brandView],
          },
          {
            title: 'goods.supplierList',
            path: dashboardRoute.goods.supplierList,
            component: SupplierList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.suppliersManage],
          },
          {
            title: 'goods.departmentList',
            path: dashboardRoute.goods.departments,
            component: Department,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.departmentView],
          },
          {
            title: 'goods.goodsDetail',
            path: `${dashboardRoute.goods.detail}?good_id=:goodId`,
            component: GoodDetail,
            hide: true,
            actions: [actionPermissions.goodGroup.goodView],
          },
          {
            title: 'goods.goodsTypeList',
            path: dashboardRoute.goods.goodsType,
            component: GoodsTypeList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.goodsTypeView],
          },
          {
            title: 'goods.mainGoodsLib',
            path: dashboardRoute.goods.mainGoodsList,
            component: MainGoodsList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.goodLib],
          },
          {
            title: 'goods.mismatchList',
            path: dashboardRoute.goods.mismatch,
            component: MismatchList,
            parent: dashboardRoute.goods.path,
            actions: [actionPermissions.goodGroup.relatedGoods],
          },
        ],
      },
      {
        title: 'order.title',
        path: dashboardRoute.order.path,
        component: Order,
        actions: Object.values(actionPermissions.orderGroup),
        icon: FileTextOutlined,
        routes: [
          {
            title: 'order.orderList',
            path: dashboardRoute.order.list,
            component: OrderList,
            parent: dashboardRoute.order.path,
            actions: [actionPermissions.orderGroup.orderView],
          },
          {
            title: 'order.orderList',
            path: `${dashboardRoute.order.list}?order_ids=:orderIds&is_seller=:isSeller`,
            component: OrderList,
            hide: true,
          },
          {
            title: 'order.orderDetail.title',
            path: `${dashboardRoute.order.detail}?order_id=:orderId`,
            component: OrderDetail,
            hide: true,
            actions: [actionPermissions.orderGroup.orderView],
          },
          {
            title: 'order.orderClaimList',
            path: dashboardRoute.order.orderClaimList,
            component: OrderClaimList,
            parent: dashboardRoute.order.path,
            actions: [actionPermissions.orderGroup.orderClaimView],
          },
          {
            title: 'order.orderClaimDetail',
            path: `${dashboardRoute.order.orderClaimDetail}?claim_id=:claimId`,
            component: OrderClaimDetail,
            hide: true,
            actions: [actionPermissions.orderGroup.orderClaimView],
          },
        ],
      },
      {
        title: 'activity.title',
        path: dashboardRoute.activity.path,
        component: Activity,
        actions: Object.values(actionPermissions.activityGroup),
        icon: StarOutlined,
        routes: [
          {
            title: 'activity.couponList',
            path: dashboardRoute.activity.couponList,
            component: CouponList,
            parent: dashboardRoute.activity.path,
            actions: [actionPermissions.activityGroup.couponManage],
          },
          {
            title: 'activity.userCouponList',
            path: `${dashboardRoute.activity.userCouponList}?coupon_id=:couponId`,
            component: UserCouponList,
            hide: true,
            actions: [actionPermissions.activityGroup.couponManage],
          },
          {
            title: 'activity.favActivityList',
            path: dashboardRoute.activity.favActivityList,
            component: FavActivityList,
            parent: dashboardRoute.activity.path,
            actions: [actionPermissions.activityGroup.favourable],
          },
          {
            title: 'activity.valueCardList',
            path: `${dashboardRoute.activity.valueCardList}?vct_id=:vctId`,
            component: ValueCardList,
            hide: true,
            parent: dashboardRoute.activity.path,
            actions: [actionPermissions.activityGroup.valueCard],
          },
          {
            title: 'activity.valueCardTypeList',
            path: dashboardRoute.activity.valueCardTypeList,
            component: ValueCardTypeList,
            parent: dashboardRoute.activity.path,
            actions: [actionPermissions.activityGroup.valueCardView],
          },
        ],
      },
      {
        title: 'advertisement.title',
        path: dashboardRoute.advertisement.path,
        component: Advertisement,
        icon: AlertOutlined,
        actions: Object.values(actionPermissions.advertisementGroup),
        routes: [
          {
            title: 'advertisement.homeAd',
            path: dashboardRoute.advertisement.homeAd,
            component: HomeAdList,
            actions: [actionPermissions.advertisementGroup.homeAd],
            parent: dashboardRoute.advertisement.path,
          },
          {
            title: 'advertisement.adPosition',
            path: dashboardRoute.advertisement.adPosition.path,
            component: AdPositionList,
            actions: [actionPermissions.advertisementGroup.adView],
            parent: dashboardRoute.advertisement.path,
          },
          {
            title: 'advertisement.article',
            path: dashboardRoute.advertisement.article.path,
            component: Article,
            actions: [
              actionPermissions.advertisementGroup.articleCat,
              actionPermissions.advertisementGroup.articleManage,
            ],
            routes: [
              {
                title: 'advertisement.articleList',
                path: dashboardRoute.advertisement.article.articleList,
                component: ArticleList,
                actions: [actionPermissions.advertisementGroup.articleManage],
                parent: `${dashboardRoute.advertisement.path}-${dashboardRoute.advertisement.article.path}`,
              },
              {
                title: 'advertisement.articleCatList',
                path: dashboardRoute.advertisement.article.articleCatList,
                component: ArticleCatList,
                actions: [actionPermissions.advertisementGroup.articleCat],
                parent: `${dashboardRoute.advertisement.path}-${dashboardRoute.advertisement.article.path}`,
              },
            ],
          },
          {
            title: 'advertisement.adDetail.adPage',
            path: `${dashboardRoute.advertisement.detail}?ad_page_id=:adPageId`,
            component: AdDetail,
            hide: true,
          },
          {
            title: 'advertisement.adDetail.adPage',
            path: `${dashboardRoute.advertisement.detail}?action=:action`,
            component: AdDetail,
            hide: true,
          },
          {
            title: 'advertisement.adPositionAd',
            path: `${dashboardRoute.advertisement.adPosition.ad}?position_id=:positionId&cat_id=:catId`,
            component: AdPositionAdList,
            hide: true,
          },
        ],
      },
      {
        title: 'sellers.title',
        path: dashboardRoute.sellers.path,
        component: Sellers,
        actions: Object.values(actionPermissions.sellerGroup),
        icon: ShopOutlined,
        routes: [
          {
            title: 'sellers.settings.title',
            path: dashboardRoute.sellers.settings,
            component: SellerSettings,
            parent: dashboardRoute.sellers.path,
            actions: [actionPermissions.sellerGroup.sellerMerchants],
          },
          {
            title: 'sellers.sellerList',
            path: dashboardRoute.sellers.list,
            component: SellerList,
            parent: dashboardRoute.sellers.path,
            actions: [actionPermissions.sellerGroup.sellerMerchants],
          },
          {
            title: 'sellers.sellerDetail',
            path: `${dashboardRoute.sellers.detail}?seller_id=:sellerId`,
            component: SellerDetail,
            hide: true,
            actions: [actionPermissions.sellerGroup.sellerMerchants],
          },
          {
            title: 'sellers.sellersGrades',
            path: dashboardRoute.sellers.grades,
            component: SellerGrade,
            parent: dashboardRoute.sellers.path,
            actions: [actionPermissions.sellerGroup.sellerGrade],
          },
          {
            title: 'sellers.sellersAdmins',
            path: dashboardRoute.sellers.admins,
            component: SellerAdminList,
            parent: dashboardRoute.sellers.path,
            actions: [actionPermissions.sellerGroup.sellerPrivateMerchants],
          },
        ],
      },
      {
        title: 'sortationSystem.title',
        path: dashboardRoute.sortationSystem.path,
        component: SortationSystem,
        actions: Object.values(actionPermissions.sortationGroup),
        icon: ShoppingCartOutlined,
        routes: [
          {
            title: 'sortationSystem.employeeList',
            path: dashboardRoute.sortationSystem.employee,
            component: EmployeeList,
            parent: dashboardRoute.sortationSystem.path,
            actions: [actionPermissions.sortationGroup.employeeView],
          },
          {
            title: 'sortationSystem.employeeLogList',
            path: dashboardRoute.sortationSystem.employeeLogs,
            component: EmployeeLogList,
            parent: dashboardRoute.sortationSystem.path,
            actions: [actionPermissions.sortationGroup.employeeLog],
          },
          {
            title: 'sortationSystem.taskList',
            path: dashboardRoute.sortationSystem.tasks,
            component: TaskList,
            parent: dashboardRoute.sortationSystem.path,
            actions: [actionPermissions.sortationGroup.taskView],
          },
          {
            title: 'sortationSystem.taskList',
            path: `${dashboardRoute.sortationSystem.taskOrder}?order_id=:orderId`,
            component: TaskList,
            hide: true,
            actions: [actionPermissions.sortationGroup.taskView],
          },
          {
            title: 'sortationSystem.taskDetail.title',
            path: `${dashboardRoute.sortationSystem.taskDetail}?task_id=:taskId`,
            component: TaskDetail,
            hide: true,
            actions: [actionPermissions.sortationGroup.taskView],
          },
        ],
      },
      {
        title: 'analysis.title',
        path: dashboardRoute.analysis.path,
        component: Analysis,
        actions: Object.values(actionPermissions.analysisGroup),
        icon: AreaChartOutlined,
        routes: [
          {
            title: 'analysis.userTitle',
            path: dashboardRoute.analysis.user,
            component: UserAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.userStat],
          },
          {
            title: 'analysis.salesTitle',
            path: dashboardRoute.analysis.sales,
            component: SalesAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.salesStat],
          },
          {
            title: 'analysis.goodsTitle',
            path: dashboardRoute.analysis.goods,
            component: GoodsAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.goodStat],
          },
          {
            title: 'analysis.orderTitle',
            path: dashboardRoute.analysis.order,
            component: OrderAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.orderStat],
          },
          {
            title: 'analysis.orderClaimTitle',
            path: dashboardRoute.analysis.orderClaim,
            component: OrderClaimAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.orderStat],
          },
          {
            title: 'analysis.employeeTitle',
            path: dashboardRoute.analysis.employee,
            component: EmployeeAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.employeeStat],
          },
          {
            title: 'analysis.taskTitle',
            path: dashboardRoute.analysis.task,
            component: TaskAnalysis,
            parent: dashboardRoute.analysis.path,
            actions: [actionPermissions.analysisGroup.taskStat],
          },
        ],
      },
      {
        title: 'plugins.title',
        path: dashboardRoute.plugins.path,
        component: Plugins,
        actions: Object.values(actionPermissions.pluginGroup),
        icon: AppstoreOutlined,
        routes: [
          {
            title: 'plugins.SMS.title',
            path: dashboardRoute.plugins.sms.path,
            component: SMS,
            actions: [
              actionPermissions.pluginGroup.shopConfig,
              actionPermissions.pluginGroup.smsLog,
              actionPermissions.pluginGroup.smsTemp,
            ],
            icon: MessageOutlined,
            routes: [
              {
                title: 'users.userDetailPage.smsLogs',
                path: dashboardRoute.plugins.sms.smsLogs,
                component: SMSLogList,
                parent: `${dashboardRoute.plugins.path}-${dashboardRoute.plugins.sms.path}`,
                actions: [actionPermissions.pluginGroup.smsLog],
              },
              {
                title: 'settings.smsTemp',
                path: dashboardRoute.plugins.sms.smsTemp,
                component: SMSTemplateList,
                parent: dashboardRoute.plugins.sms.path,
                actions: [actionPermissions.pluginGroup.smsTemp],
              },
              {
                title: 'plugins.SMS.settings.title',
                path: dashboardRoute.plugins.sms.settings,
                component: SMSSettings,
                parent: `${dashboardRoute.plugins.path}-${dashboardRoute.plugins.sms.path}`,
                actions: [actionPermissions.pluginGroup.shopConfig],
              },
            ],
          },
          {
            title: 'plugins.Mail.title',
            path: dashboardRoute.plugins.email.path,
            component: Mail,
            actions: [actionPermissions.pluginGroup.shopConfig],
            icon: MailOutlined,
            routes: [
              {
                title: 'plugins.Mail.settings.title',
                path: dashboardRoute.plugins.email.settings,
                component: MailSettings,
                parent: `${dashboardRoute.plugins.path}-${dashboardRoute.plugins.email.path}`,
                actions: [actionPermissions.pluginGroup.shopConfig],
              },
            ],
          },
          {
            title: 'plugins.S3.title',
            path: dashboardRoute.plugins.s3,
            component: S3,
            parent: 'plugins',
            actions: [actionPermissions.pluginGroup.ossConfig],
          },
        ],
      },
      {
        title: 'settings.title',
        path: dashboardRoute.settings.path,
        component: Settings,
        actions: Object.values(actionPermissions.settingGroup),
        icon: SettingOutlined,
        routes: [
          {
            title: 'settings.basicSettings.title',
            path: dashboardRoute.settings.basic,
            component: BasicSettings,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.basicSetting],
          },
          {
            title: 'settings.regionSettings',
            path: dashboardRoute.settings.region,
            component: Region,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.regionSetting],
          },
          {
            title: 'settings.paymentSettings',
            path: dashboardRoute.settings.payment,
            component: PaymentSetting,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.paymentSettings],
          },
          {
            title: 'settings.formFields',
            path: dashboardRoute.settings.formField,
            component: FormField,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.formFieldConfig],
          },
          {
            title: 'settings.photoGallery',
            path: dashboardRoute.settings.gallery,
            component: PhotoGallery,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.galleryAlbum],
          },
          {
            title: 'settings.fileManager',
            path: dashboardRoute.settings.fileManager,
            component: FileManager,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.fileManager],
          },
          {
            title: 'settings.shipping',
            path: dashboardRoute.settings.shipping.path,
            component: Shipping,
            actions: [
              actionPermissions.settingGroup.pickupView,
              actionPermissions.settingGroup.shipView,
              actionPermissions.settingGroup.shipManage,
            ],
            routes: [
              {
                title: 'settings.shippingListColumns.title',
                path: dashboardRoute.settings.shipping.shipList,
                component: ShippingList,
                parent: `${dashboardRoute.settings.path}-${dashboardRoute.settings.shipping.path}`,
                actions: [actionPermissions.settingGroup.shipView],
              },
              {
                title: 'settings.transListColumns.title',
                path: dashboardRoute.settings.shipping.transportList,
                component: TransportList,
                parent: `${dashboardRoute.settings.path}-${dashboardRoute.settings.shipping.path}`,
                actions: [actionPermissions.settingGroup.shipView],
              },
              {
                title: 'settings.pickupListColumns.title',
                path: dashboardRoute.settings.shipping.pickupList,
                component: PickupList,
                parent: `${dashboardRoute.settings.path}-${dashboardRoute.settings.shipping.path}`,
                actions: [actionPermissions.settingGroup.pickupView],
              },
            ],
          },
          {
            title: 'settings.openApi.title',
            path: dashboardRoute.settings.openApi,
            component: OpenApi,
            parent: dashboardRoute.settings.path,
            actions: [actionPermissions.settingGroup.openAPI],
          },
        ],
      },
      {
        title: 'profile.title',
        path: dashboardRoute.profile,
        component: MyProfile,
        icon: TeamOutlined,
      },
      {
        title: 'cache.title',
        path: dashboardRoute.cache,
        component: Cache,
        hide: true,
      },
    ],
  },
  {
    title: 'Auth',
    path: authRoute.path,
    component: Auth,
    routes: [
      {
        title: 'login.title',
        path: authRoute.login,
        component: Login,
      },
    ],
  },
  {
    title: '403',
    path: '/403',
    component: FourZeroThree,
  },
  {
    title: '404',
    path: '*',
    component: FourZeroFour,
  },
];
