import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Image,
  Input,
  InputNumber,
  Popover,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import CategoryDropdown from '../../components/goods/common/CategoryDropdown';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import { FALLBACK_IMG } from '../../constants/styles';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import { FontSizeType, GoodsInfo, MismatchGood, SellerData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const MismatchList = () => {
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mismatchList, setMismatchList] = useState<MismatchGood[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<MismatchGood[]>([]);
  const screens = Grid.useBreakpoint();
  const { addTab } = useTab();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const columnKeys: string[] = [
    'goodsId',
    'goodsImage',
    'shopName',
    'fullGoodsName',
    'shopPrice',
    'isPriceDiff',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnKeys);
  const [searchAdvance, setSearchAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [advance, setAdvance] = useState<{ [key: string]: any }>({});
  const [currentCell, setCurrentCell] = useState<{
    [key: string]: string;
  }>({});

  const updateGood = (
    key: string,
    value: string | number | boolean,
    good: MismatchGood
  ) => {
    postDataWithAuthToken('goods/edit', {
      goodsId: good.goodsId,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setCurrentCell({});
            getMismatchList();
            alertMessage('success', t('goods.alerts.goodsEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMismatchList = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('goods/related_goods/mismatch_list', {
      params: {
        page: page,
        size: pageSize,
        mainGoodsKeyword:
          formRef.current && form.getFieldValue('mainGoodsKeyword')
            ? form.getFieldValue('mainGoodsKeyword')
            : undefined,
        relatedGoodsKeyword:
          formRef.current && form.getFieldValue('relatedGoodsKeyword')
            ? form.getFieldValue('relatedGoodsKeyword')
            : undefined,
        sellerId:
          (formRef.current && form.getFieldValue('sellerId')) || undefined,
        mainGoodsCatId: formRef.current
          ? form.getFieldValue('mainGoodsCatId')
            ? form.getFieldValue('mainGoodsCatId').at(-1)
            : undefined
          : undefined,
        mainGoodsIsOnSale: formRef.current
          ? form.getFieldValue('mainGoodsIsOnSale') !== 'all'
            ? form.getFieldValue('mainGoodsIsOnSale')
            : undefined
          : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setMismatchList(response.data.list);
            setTotal(response.data.totalItem);

            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response.returnCode === 403) {
            setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else setFiveHundred(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [page, pageSize, t, form]);

  useEffect(() => {
    getMismatchList();
  }, [getMismatchList]);

  const columns: ColumnsType<MismatchGood> = [
    {
      title: setFont(t('goods.goodsListColumns.goodsId'), fontSize),
      key: 'goodsId',
      dataIndex: 'goodsId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: (a: MismatchGood, b: MismatchGood) =>
        compare(a.goodsId, b.goodsId),
      render: (text: string, record: MismatchGood) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
            )
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsImage'), fontSize),
      key: 'goodsImage',
      dataIndex: 'thumbPic',
      width: 100,
      render: (text: string) => (
        <Image src={text} fallback={FALLBACK_IMG} width={90} />
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsName'), fontSize),
      key: 'fullGoodsName',
      dataIndex: 'fullGoodsName',
      render: (text: string) => (
        <Typography.Text
          style={{ fontSize: fontSize }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.seller'), fontSize),
      key: 'shopName',
      dataIndex: 'seller',
      width: 100,
      render: (seller: SellerData) =>
        seller && (
          <Typography.Text
            style={{ fontSize: fontSize }}
            ellipsis={{ tooltip: seller.shopName }}
          >
            {seller.shopName}
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('goods.goodsListColumns.shopPrice'), fontSize),
      key: 'shopPrice',
      dataIndex: 'shopPrice',
      width: 100,
      render: (text: number, record: MismatchGood) =>
        currentCell[record.goodsId] === 'shopPrice' ? (
          <InputNumber
            disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
            type="number"
            style={{ fontSize: fontSize }}
            value={text}
            min={0}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              if (hasPermission(actionPermissions.goodGroup.goodManage))
                updateGood('shopPrice', target.value, record);
            }}
            onBlur={() => setCurrentCell({})}
            autoFocus
          />
        ) : (
          <Button
            type="text"
            style={{ fontSize: fontSize }}
            onClick={() => setCurrentCell({ [record.goodsId]: 'shopPrice' })}
          >
            {text}
          </Button>
        ),
    },
    {
      title: (
        <Space>
          {setFont(t('goods.goodsListColumns.isPriceDiff'), fontSize)}
          <Tooltip title={t('goods.goodsListColumns.isPriceDiffTooltip')}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      key: 'isPriceDiff',
      dataIndex: 'isPriceDiff',
      width: 100,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 80,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: MismatchGood) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
            )
          }
        >
          {t('actionsColumn.view')}
        </Button>
      ),
    },
  ];

  const mainGoodColumns: ColumnsType<GoodsInfo> = [
    {
      title: setFont(t('goods.goodsListColumns.mainGoodsId'), fontSize),
      key: 'goodsId',
      dataIndex: 'goodsId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string, record: GoodsInfo) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
            )
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.mainGoodsName'), fontSize),
      key: 'fullGoodsName',
      dataIndex: 'fullGoodsName',
      width: 300,
      render: (text: string) => (
        <Typography.Text
          style={{ fontSize: fontSize }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.shopPrice'), fontSize),
      key: 'shopPrice',
      dataIndex: 'shopPrice',
      width: 100,
      render: (text: number) => (
        <Typography.Text
          style={{ fontSize: fontSize }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Typography.Text>
      ),
    },
  ];

  const advancedSearch = (
    <Popover
      trigger={['click']}
      placement="bottomRight"
      content={
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          style={{ width: 250 }}
          initialValues={{ mainGoodsCatId: [], mainGoodsIsOnSale: 'all' }}
        >
          <Form.Item
            label={t('goods.goodsListColumns.advancedSearch.sellerId')}
            name="sellerId"
            style={{ marginBottom: 12 }}
          >
            <SellersDropdown
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.relatedGoodKeyword')}
            name="relatedGoodsKeyword"
            style={{ marginBottom: 12 }}
          >
            <Input
              onChange={(e) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  relatedGoodsKeyword: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.mainGoodKeyword')}
            name="mainGoodsKeyword"
            style={{ marginBottom: 12 }}
          >
            <Input
              onChange={(e) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  mainGoodsKeyword: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.mainGoodCategory')}
            name="mainGoodsCatId"
            style={{ marginBottom: 12 }}
          >
            <CategoryDropdown
              onFocusFetch={true}
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  mainGoodsCatId: value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.mainGoodIsOnSale')}
            name="mainGoodsIsOnSale"
            style={{ marginBottom: 12 }}
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  mainGoodsIsOnSale:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getMismatchList(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(searchAdvance).every(
                (value) => typeof value !== 'number' && !value
              )}
              onClick={() => {
                form.resetFields();
                setSearchAdvance({});
                if (page !== 1) setPage(1);
                else getMismatchList();
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Badge
        count={Object.keys(searchAdvance).reduce((accumulator, obj) => {
          if (searchAdvance[obj as keyof typeof searchAdvance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('goods.goodsListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.mismatchList')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getMismatchList()}
            totalItems={total}
            rows={mismatchList}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            exportConfig={{ fileName: 'GOODS_MISMATCH_LIST' }}
            advancedSearch={advancedSearch}
          />
          <Table
            dataSource={mismatchList}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            loading={isLoading}
            size={tableSize}
            components={vt}
            rowKey={(good) => good.goodsId}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: MismatchGood[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            expandable={{
              expandedRowRender: (record) => (
                <Table
                  size={tableSize}
                  dataSource={[record.mainGoods]}
                  columns={mainGoodColumns}
                  pagination={{ pageSize: 10, hideOnSinglePage: true }}
                  rowKey={(record) => record.goodsId}
                />
              ),
              columnWidth: 30,
            }}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              total: total,
              current: page,
            }}
            scroll={{ y: 600, x: 1200 }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRowKeys={selectedRowKeys}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              typeSpecificActions="goodsSync"
              general={
                hasPermission(actionPermissions.goodGroup.goodManage)
                  ? { advance: advance, setAdvance: setAdvance }
                  : undefined
              }
              funct={{
                refresh: () => getMismatchList(),
                exportConfig: { fileName: 'GOODS_MISMATCH_LIST' },
              }}
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default MismatchList;
