import { Form, Input, Modal, Space } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { ValueCard } from '../../types';

type ValueRecordCardModalProps = {
  selectedVCData: ValueCard | undefined;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refreshVCRecordList: Function;
  refreshVCList: Function;
};

const ValueRecordCardModal = ({
  selectedVCData,
  visible,
  setVisible,
  refreshVCRecordList,
  refreshVCList,
}: ValueRecordCardModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  //Data components
  //Pagination/table Components
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        postDataWithAuthToken('value_card/record/add', {
          ...values,
          vcId: selectedVCData ? selectedVCData.vcId : undefined,
        }).then((response) => {
          if (response && response.goodStatus) {
            alertMessage('success', t('activity.alerts.valueCardRecordAdded'));
            if (refreshVCList) refreshVCList();
            if (refreshVCRecordList) refreshVCRecordList();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={`${t('activity.addValueRecordCard.addTitle')}: ${
        selectedVCData ? selectedVCData.vcId : ''
      }`}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item /**For Record Desc */
          label={t('activity.addValueRecordCard.recordDesc')}
          name="recordDesc"
          rules={[
            {
              required: true,
              message: t('general.inputError.empty'),
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
        </Form.Item>
        <Form.Item /**For Change Value */
          label={t('activity.addValueRecordCard.changeVal')}
          name="changeVal"
          rules={[
            {
              required: true,
              message: t('general.inputError.pleaseInputAmount'),
            },
            () => ({
              validator(_, value) {
                if (parseInt(value) === 0) {
                  return Promise.reject(
                    new Error(t('activity.inputError.changeValueWarning'))
                  );
                } else if (
                  selectedVCData &&
                  parseFloat(value) * -1 > parseFloat(selectedVCData.cardMoney)
                ) {
                  return Promise.reject(
                    new Error(t('activity.inputError.changeValueMax'))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space>
            <Input type="number" />
            <>{`(${t('users.adjustAccountLogs.currAmount')}: ${
              selectedVCData?.cardMoney
            })`}</>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ValueRecordCardModal;
