import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import {
  OrderData,
  OrderClaimData,
  RefundPayLogLists,
  CreditCardData,
} from '../../../types';
import { useTranslation } from 'react-i18next';

import Table, { ColumnsType } from 'antd/lib/table';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import CreditCardModal from '../CreditCardModal';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../../constants/color';

type RefundPayLogListProps = {
  orderClaimDetail?: OrderClaimData;
  loading?: boolean;
  orderDetail?: OrderData;
};

/**
 * Displays Order Claim Pay Log List
 *
 */
const RefundPayLogList = ({
  orderClaimDetail,
  loading,
  orderDetail,
}: RefundPayLogListProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [creditCardData, setCreditCardData] = useState<CreditCardData>();
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [ccLoading, setCCLoading] = useState(false);

  /**
   * Gets Credit Card information
   */
  const getCreditCardData = (id: number) => {
    setCCLoading(true);
    getDataWithAuthToken('users/cc_detail', {
      params: {
        cardId: id,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setCreditCardData(response.data);
            setCreditCardModal(true);
            setCCLoading(false);
          }
        } else {
          setCCLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setCCLoading(false);
      });
  };
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);
  const columns: ColumnsType<RefundPayLogLists> = [
    {
      title: t('order.payLogListColumns.logId'),
      width: 100,
      dataIndex: 'logId',
      render: (text: string) => text,
    },
    {
      title: t('order.payLogListColumns.responseTransId'),
      width: 150,
      dataIndex: 'responseTransId',
      render: (text: string) => text,
    },
    {
      title: t('order.payLogListColumns.orderId'),
      width: 100,
      dataIndex: 'orderId',
      render: (text: string) => text,
    },
    {
      title: t('order.payLogListColumns.orderAmount'),
      width: 120,
      dataIndex: 'orderAmount',
      render: (text: string) => text,
    },
    {
      title: t('order.payLogListColumns.transId'),
      width: 300,
      dataIndex: 'transId',
      render: (text: string) => text,
    },
    {
      title: t('order.orderDetail.payFee'),
      width: 120,
      dataIndex: 'payFee',
      render: (text: string) => text,
    },
    {
      title: t('order.payLogListColumns.payType'),
      width: 200,
      dataIndex: 'payType',
      render: (text: string, record: RefundPayLogLists) =>
        record.payType ? record.payType.description : '',
    },
    {
      title: t('order.payLogListColumns.payCode'),
      width: 120,
      dataIndex: 'payCode',
      render: (text: string, record: RefundPayLogLists) =>
        record.payCode ? record.payCode.description : '',
    },
    {
      title: t('order.payLogListColumns.isPaid'),
      dataIndex: 'isPaid',
      width: 120,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1 }} />
        ) : (
          <CloseOutlined style={{ color: RED1 }} />
        ),
    },
    {
      title: t('order.payLogListColumns.cardId'),
      dataIndex: 'cardId',
      width: 200,
      render: (text: string) =>
        parseInt(text) !== 0 && (
          <Button
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
            onClick={() => {
              getCreditCardData(parseInt(text));
            }}
            type="link"
            style={{ padding: 0 }}
          >
            {text}
          </Button>
        ),
    },
  ];

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Text
            strong
            style={{
              fontSize: 16,
              marginBottom: 10,
            }}
          >
            {t('order.payLogListColumns.title')}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            dataSource={
              orderClaimDetail
                ? orderClaimDetail.payLogList
                : orderDetail
                ? orderDetail.payLogList
                : undefined
            }
            columns={columns}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(record) => record.logId}
            pagination={{
              pageSize: 50,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              hideOnSinglePage: true,
            }}
            loading={loading}
          />
        </Col>
      </Row>
      <CreditCardModal
        loading={ccLoading}
        ccData={creditCardData}
        visible={creditCardModal}
        setVisible={setCreditCardModal}
      />
    </>
  );
};

export default RefundPayLogList;
