import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../../constants/systemConstants';
import {
  FontSizeType,
  ShippingData,
  TPLData,
  TransportData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { setFont } from '../../../utils/colComponents';
import { hasPermission } from '../../../utils/hasPermission';
import TableToolbar from '../../table/TableToolbar';
import AddEditTPLModal from './AddEditTPLModal';
import AddTransportModal from './AddTransportModal';

type TransModalProps = {
  viewType: string;
  setViewType: React.Dispatch<React.SetStateAction<string>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  transInfo?: TransportData;
  refresh?: () => void;
  isSeller: boolean;
};

const TransModal = ({
  viewType,
  setViewType,
  visible,
  setVisible,
  transInfo,
  refresh,
  isSeller,
}: TransModalProps) => {
  //General components
  const { t } = useTranslation();
  const [isViewModalLoading, setIsViewModalLoading] = useState(false);
  const [isEditTPLModalVisible, setIsEditTPLModalVisible] = useState(false);
  const [ViewForm] = Form.useForm();
  const isSubscribed = useRef(true);
  //Data components
  const [transId, setTransId] = useState<number>(0);
  const [addTransInfo, setAddTransInfo] = useState<TransportData>(); //transInfo is from TransList row and addTransInfo is from addTransport Modal
  const [tplData, setTPLData] = useState<TPLData[]>();
  const [tplInfo, setTPLInfo] = useState<TPLData>();
  const [called, setCalled] = useState(false);
  const [mainRegionIDData, setMainRegionIDData] = useState<any[]>([]);
  //Table components
  const screens = useBreakpoint();
  const [total, setTotal] = useState(0);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  //Displaying text components
  const [editMode, setEditMode] = useState('');
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const columns: ColumnsType<TPLData> = [
    {
      title: setFont(t('settings.tpl.tplId'), fontSize),
      key: 'tplId',
      dataIndex: 'tplId',
      width: 120,
      render: (text: string, record: TPLData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.settingGroup.shipView)}
          onClick={() => {
            tplData?.map((tplObj) =>
              mainRegionIDData.push({
                id: tplObj.tplId,
                value: tplObj.regionId,
              })
            );
            setMainRegionIDData(
              Object.keys(mainRegionIDData)
                .map((key) => mainRegionIDData[parseInt(key)].value)
                .flat(3)
            );
            setTPLInfo(record);
            setViewType('editTPL');
            setIsEditTPLModalVisible(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('settings.tpl.tplName'), fontSize),
      key: 'tplName',
      dataIndex: 'tplName',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.tpl.regionId'), fontSize),
      key: 'regionId',
      dataIndex: 'regionId',
      width: 80,
      render: (text: number[]) => (
        <Typography.Text
          style={{ width: 90, fontSize: fontSize }}
          ellipsis={{
            tooltip: (
              <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                {text.join(', ')}
              </div>
            ),
          }}
        >
          {text.join(', ')}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('settings.tpl.shipping.title'), fontSize),
      key: 'shipping',
      dataIndex: 'shipping',
      render: (record: ShippingData) =>
        record &&
        setFont(
          record.shippingName + ' (ID: ' + record.shippingId + ')',
          fontSize
        ),
    },
    {
      title: setFont(t('settings.tpl.adminId'), fontSize),
      key: 'adminId',
      dataIndex: 'adminId',
      width: 100,
      render: (text: number) => setFont(String(text), fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: TPLData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.settingGroup.shipView)}
            onClick={() => {
              tplData?.map((tplObj) =>
                mainRegionIDData.push({
                  id: tplObj.tplId,
                  value: tplObj.regionId,
                })
              );
              setMainRegionIDData(
                Object.keys(mainRegionIDData)
                  .map((key) => mainRegionIDData[parseInt(key)].value)
                  .flat(3)
              );
              setTPLInfo(record);
              setViewType('editTPL');
              setIsEditTPLModalVisible(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              deleteTPL(record.tplId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.settingGroup.shipManage)}
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.settingGroup.shipManage)
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  /**
   * makes a request and grabs the trans detail info and loads it
   */
  const getTransDetailData = useCallback(
    (id?: number) => {
      if (isSubscribed.current) setIsViewModalLoading(true);
      getDataWithAuthToken('shipping/transport/detail', {
        params: {
          transportId: id,
        },
      })
        .then((response) => {
          if (response) {
            if (response.goodStatus) {
              if (isSubscribed.current) {
                setAddTransInfo(response.data);
                setTPLData(response.data.tplList);
                setTotal(response.data.totalItem);
                setCalled(true);
              }
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          }
          if (isSubscribed.current) setIsViewModalLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setIsViewModalLoading(true);
          console.log(err);
        });
    },
    [t]
  );

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      !called &&
        viewType === 'view' &&
        getTransDetailData(transInfo ? transInfo.transportId : transId);
    }
  }, [visible, viewType, called, getTransDetailData, transInfo, transId]);

  const onClose = () => {
    if (isSubscribed.current) {
      viewType === 'editTPL' ? setViewType('view') : setVisible(false);
      setCalled(false);
      setMainRegionIDData([]);
    }
  };

  /**
   * @param id Id of TPL to delete
   */
  const deleteTPL = (id: number) => {
    if (isSubscribed.current) setIsViewModalLoading(true);
    postDataWithAuthToken('shipping/transport/tpl/delete', { tplId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.tplDeleted'));
          getTransDetailData(transInfo?.transportId);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsViewModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsViewModalLoading(false);
      });
  };

  /**
   *
   */
  const editTransport = () => {
    ViewForm.validateFields().then((values) => {
      postDataWithAuthToken('shipping/transport/edit', {
        ...values,
        transportId: transInfo
          ? transInfo.transportId
          : addTransInfo?.transportId,
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              alertMessage('success', t('settings.alerts.transEdited'));
              refresh && refresh();
              getTransDetailData(
                transInfo ? transInfo.transportId : addTransInfo?.transportId
              );
            }
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return viewType === 'view' ? (
    <Modal
      getContainer={false}
      footer={null}
      onCancel={onClose}
      width={screens.md ? 992 : '90%'}
      title={`${t('settings.add/edit/viewModal.viewTransportTitle')} ${
        transInfo ? transInfo.transportId : addTransInfo?.transportId
      }`}
      visible={visible}
    >
      <Spin spinning={isViewModalLoading}>
        <Form
          form={ViewForm}
          initialValues={
            transInfo
              ? {
                  ...transInfo,
                  tplList: transInfo.tplList,
                  transportName: transInfo.transportName,
                  transportTitle: transInfo.transportTitle,
                }
              : {
                  transportName: addTransInfo?.transportName,
                  transportTitle: addTransInfo?.transportTitle,
                }
          }
        >
          <Row style={{ width: 'auto' }}>
            <Col className="gutter-row" span={5} /*SellerID Text*/>
              <Form.Item
                name="sellerId"
                label={t('settings.add/edit/viewModal.sellerId')}
              >
                {<Typography.Text>{addTransInfo?.sellerId}</Typography.Text>}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6} /*Trans ID Text*/>
              <Form.Item
                name="transportId"
                label={t('settings.add/edit/viewModal.transportId')}
              >
                {<Typography.Text>{addTransInfo?.transportId}</Typography.Text>}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={11} /*Trans Name Text*/>
              <Form.Item
                name="transportName"
                label={t('settings.add/edit/viewModal.transportName')}
              >
                {editMode === 'transName' ? (
                  <Input
                    suffix={
                      <CloseCircleOutlined onClick={() => setEditMode('')} />
                    }
                    placeholder={transInfo?.transportTitle}
                    onPressEnter={(e: React.KeyboardEvent) => {
                      let target = e.target as HTMLInputElement;
                      if (!target.value) {
                        return alertMessage(
                          'error',
                          t('general.inputError.empty')
                        );
                      }
                      editTransport();
                      setEditMode('');
                    }}
                  />
                ) : (
                  <Button
                    type={'text'}
                    onClick={() => setEditMode('transName')}
                  >
                    {transInfo
                      ? transInfo.transportName
                      : addTransInfo?.transportName}
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={13} /*Trans Title Text*/>
              <Form.Item
                name="transportTitle"
                label={t('settings.add/edit/viewModal.transportTitle')}
              >
                {editMode === 'transTitle' ? (
                  <Input
                    suffix={
                      <CloseCircleOutlined onClick={() => setEditMode('')} />
                    }
                    placeholder={transInfo?.transportTitle}
                    onPressEnter={(e: React.KeyboardEvent) => {
                      let target = e.target as HTMLInputElement;
                      if (!target.value) {
                        return alertMessage(
                          'error',
                          t('general.inputError.empty')
                        );
                      }
                      editTransport();
                      setEditMode('');
                    }}
                  />
                ) : (
                  <Button
                    type={'text'}
                    onClick={() => setEditMode('transTitle')}
                  >
                    {transInfo
                      ? transInfo.transportTitle
                      : addTransInfo?.transportTitle}
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={11} /*Update Time Text*/>
              <Form.Item
                name="updateTime"
                label={t('settings.add/edit/viewModal.updateTime')}
              >
                {
                  <Typography.Text>
                    {transInfo
                      ? transInfo.updateTime
                      : addTransInfo?.updateTime}
                  </Typography.Text>
                }
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24} /*TPL Data Table*/>
              <TableToolbar
                fontSize={fontSize}
                setFontSize={setFontSize}
                leftElement={
                  <Button
                    icon={<PlusOutlined />}
                    disabled={
                      !hasPermission(actionPermissions.settingGroup.shipManage)
                    }
                    onClick={() => {
                      tplData?.map((tplObj) =>
                        mainRegionIDData.push({
                          id: tplObj.tplId,
                          value: tplObj.regionId,
                        })
                      );
                      setMainRegionIDData(
                        Object.keys(mainRegionIDData)
                          .map((key) => mainRegionIDData[parseInt(key)].value)
                          .flat(2)
                      );
                      setViewType('addTPL');
                      setTPLInfo(undefined);
                      setIsEditTPLModalVisible(true);
                    }}
                  >
                    {t('settings.add/edit/viewModal.addTPLTitle')}
                  </Button>
                }
                tableSize={tableSize}
                setTableSize={setTableSize}
                refresh={() => {
                  getTransDetailData(transInfo?.transportId);
                  if (refresh) refresh();
                }}
                totalItems={total}
                columns={columns}
              />
              <Table<TPLData>
                size="small"
                dataSource={tplData}
                rowKey={(tpl) => tpl.tplId}
                columns={columns}
                scroll={{
                  x: 'max-content',
                  y: tplData && tplData.length > 10 ? 500 : undefined,
                }}
                loading={isViewModalLoading}
                pagination={false}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  ) : viewType === 'editTPL' || viewType === 'addTPL' ? (
    <AddEditTPLModal
      setCalled={setCalled}
      mainRegionIDData={mainRegionIDData}
      setMainRegionIDData={setMainRegionIDData}
      setViewType={setViewType}
      visible={isEditTPLModalVisible}
      setVisible={setIsEditTPLModalVisible}
      tplInfo={tplInfo}
      transInfo={transInfo ? transInfo : addTransInfo}
    />
  ) : (
    <AddTransportModal
      isSeller={isSeller}
      setTransId={setTransId}
      visible={visible}
      setVisible={setVisible}
      setCalled={setCalled}
      setViewType={setViewType}
      setMainRegionIDData={setMainRegionIDData}
      refresh={() => refresh && refresh()}
    />
  );
};

export default TransModal;
