import {
  Cascader,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Switch,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleCategoryData, ArticleCatEnum } from '../../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import TextArea from 'antd/lib/input/TextArea';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';

type ArticleCategoryModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: Function;
  selectedArticleCat?: ArticleCategoryData;
  parent?: ArticleCategoryData;
  artCatEnum?: ArticleCatEnum;
  breadCrumbs?: string[];
};

const ArticleCategoryModal = ({
  selectedArticleCat,
  artCatEnum,
  parent,
  visible,
  setVisible,
  refresh,
  breadCrumbs,
}: ArticleCategoryModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  // const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<
    {
      value: number | string;
      label: string;
      isLeaf: boolean;
      disabled?: boolean;
    }[]
  >([]);
  //Data components
  //Pagination/table Components

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();

      setOptions([
        {
          value: 0,
          label: t('goods.categoryListColumns.topCategory'),
          isLeaf: false,
        },
      ]);
    }
  }, [t, form, visible]);

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  const loadData = (selectedOptions?: any[]) => {
    if (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      setTimeout(() => {
        getDataWithAuthToken('article/cat/list', {
          params: { parentId: targetOption.value },
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                targetOption.children = response.data.list.map(
                  (artCat: ArticleCategoryData) => {
                    return {
                      value: artCat.articleCatId,
                      label: artCat.articleCatName,
                      isLeaf: artCat.childCount === 0,
                      disabled:
                        selectedArticleCat &&
                        selectedArticleCat.articleCatId === artCat.articleCatId,
                    };
                  }
                );
                targetOption.loading = false;
                setOptions([...options]);
              }
            }
          })
          .catch((err) => console.log(err));
      }, EXTENDED_TIMEOUT);
    }
  };

  /**
   * @param values Form values
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        postDataWithAuthToken(
          selectedArticleCat ? 'article/cat/edit' : 'article/cat/add',
          {
            ...values,
            articleCatId: selectedArticleCat
              ? selectedArticleCat.articleCatId
              : undefined,
            parentId: values.parentId.length
              ? values.parentId[values.parentId.length - 1]
              : parent
              ? parent.articleCatId
              : 0,
          }
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedArticleCat
                ? t('advertisement.alerts.articleCatEdited')
                : t('advertisement.alerts.articleCatAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }

          if (isSubscribed.current) setLoading(false);
        });
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedArticleCat
          ? `${t('advertisement.add/EditArticleCat.editTitle')} ${
              selectedArticleCat.articleCatName
            }`
          : `${t('advertisement.add/EditArticleCat.addTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          initialValues={
            selectedArticleCat
              ? { ...selectedArticleCat, parentId: [] }
              : {
                  parentId: [],
                  isEnabled: true,
                  sortOrder: 100,
                }
          }
          form={form}
          onFinish={onFinish}
        >
          <Form.Item /**For Article Category Name */
            name="articleCatName"
            label={t('advertisement.add/EditArticleCat.articleCatName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item /**For Article Category Type */
            name="articleCatType"
            label={t('advertisement.add/EditArticleCat.articleCatType')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
            >
              {artCatEnum &&
                artCatEnum.articleCatType.map((type) => (
                  <Select.Option key={type.description} value={type.code}>
                    {type.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item /**For Article Category Parent */
            name="parentId"
            label={t('advertisement.add/EditArticleCat.parentCategory')}
          >
            <Cascader
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              options={options}
              loadData={loadData}
              placeholder={
                breadCrumbs
                  ? breadCrumbs.join(' > ')
                  : t('general.pleaseSelect')
              }
              changeOnSelect
              displayRender={(label) => label.join(' > ')}
            />
          </Form.Item>
          <Form.Item /**For Article Category Keyword */
            name="keyword"
            label={t('advertisement.add/EditArticleCat.keyword')}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item /**For Article Category Description */
            name="articleCatDesc"
            label={t('advertisement.add/EditArticleCat.articleCatDesc')}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item /**For Article Category isEnabled */
            name="isEnabled"
            label={t('advertisement.add/EditArticleCat.isEnabled')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item /**For Article Category sortOrder */
            name="sortOrder"
            label={t('advertisement.add/EditArticleCat.sortOrder')}
          >
            <InputNumber min={0} max={50000} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ArticleCategoryModal;
