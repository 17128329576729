import { createTokenProvider } from './createTokenProvider';
import { useState, useEffect } from 'react';

/**
 * Handles tokens for logging in, logging off, and checking whether authenticated
 */
const createAuthProvider = () => {
  const tokenProvider = createTokenProvider();

  /**
   * @param newToken Token received when user logs in
   */
  const login: typeof tokenProvider.setToken = (newToken) => {
    localStorage.removeItem('profile');
    tokenProvider.setToken(newToken);
  };

  /**
   * TODO: send something to backend after logging out?
   */
  const logout = () => {
    localStorage.removeItem('profile');
    tokenProvider.setToken(null);
  };

  /**
   * @returns string User token for authentication 
   */
  const getToken = () => {
    return tokenProvider.getToken();
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());
    useEffect(() => {
      const listener = (newIsLogged: boolean) => {
        setIsLogged(newIsLogged);
      };
      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, []);
    return { isLogged } as { isLogged: typeof isLogged };
  };

  return {
    useAuth,
    getToken,
    login,
    logout,
  };
};

export const {
  useAuth,
  getToken,
  login,
  logout,
} = createAuthProvider();
