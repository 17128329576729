import { Form, Input, Modal, Spin, TreeSelect, Typography } from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import { AdminAction, SellerAdminData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { hasPermission } from '../../utils/hasPermission';
import SellersDropdown from './SellersDropdown';

type SellerAdminModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // If Admin Profile Exists then we are editing an already existing profile.
  adminProfile?: SellerAdminData;
  callBack?: () => void;
  sellerId?: string;
};

/**
 * Modal used for creating and editing Seller Admins
 *
 * @prop visible       If Modal should be shown
 * @prop setVisible    Setting whether Modal should be displayed or not
 * @prop adminProfile  Profile to edit if we are editing a profile
 * @prop sellerId      Seller ID
 * @prop callBack      What to call next after form is submitted (usually a table refresh function)
 */
const SellerAdminModal = ({
  visible,
  setVisible,
  adminProfile,
  callBack,
  sellerId,
}: SellerAdminModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const isSubscribed = useRef(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [adminActionList, setAdminActionList] = useState<DataNode[]>([]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Fetches action list
  const getAdminActionList = useCallback(
    (sellerActionList: string[]) => {
      const makeTreeData = (data: AdminAction[] = []) => {
        let treeData: DataNode[] = [];
        for (let node of data) {
          let treeNode: DataNode = {};
          treeNode.title = node.description;
          treeNode.value = node.actionCode;
          treeNode.key = node.actionCode;
          if (node.adminAction && node.adminAction.length) {
            treeNode.children = makeTreeData(node.adminAction);
            treeNode.disabled = treeNode.children.every((v) => v.disabled);
          } else {
            treeNode.disabled =
              sellerActionList.indexOf(node.actionCode) === -1;
          }
          treeData.push(treeNode);
        }
        return treeData;
      };

      getDataWithAuthToken('admin/action/list', { params: { isSeller: true } })
        .then(async (response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setAdminActionList(makeTreeData(response.data.list));
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [t]
  );

  /**
   * Filter the admin action list to be the seller admin action list
   * Workflow:
   * 1. Get all the admin actions (getAdminActionList, returns a AdminData[])
   * 2. Get available admin action codes based on seller ID and seller admin ID
   * 3. When reformatting the tree data, only enable those available admin actions, disable the rest
   * 先拿到全部的店铺权限信息列表, 然后再拿可以选择的权限代码列, 然后生成数据的时候把没有在可选择权限代码列表里面的权限禁用掉
   */
  const getSellerActions = useCallback(() => {
    getDataWithAuthToken('seller/action/list', {
      params: {
        sellerId:
          adminProfile &&
          adminProfile.sellerShopInfo &&
          adminProfile.sellerShopInfo.sellerId,
        adminId: adminProfile && adminProfile.adminId,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (!adminActionList.length) getAdminActionList(response.data.list);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        setFirstLoad(false);
      })
      .catch((err) => {
        setFirstLoad(false);
        console.log(err);
      });
  }, [t, adminProfile, adminActionList, getAdminActionList]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      adminProfile && firstLoad && getSellerActions();
    }
  }, [visible, form, adminProfile, firstLoad, getSellerActions]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(
          adminProfile ? 'seller/admin_user/edit' : 'seller/admin_user/add',
          {
            ...values,
            adminId: adminProfile ? adminProfile.adminId : undefined,
            actionList: values.actionList ? values.actionList.join() : '',
            password: values.password ? values.password : undefined,
          }
        )
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                handleCancel();
                if (callBack) callBack();
                alertMessage(
                  'success',
                  adminProfile
                    ? t('sellers.alerts.gradeEdited')
                    : t('sellers.alerts.adminAdded')
                );
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const handleCancel = () => {
    form.resetFields();
    if (isSubscribed.current) {
      setFirstLoad(true);
      setFirstPassword('');
      setVisible(false);
    }
  };

  return (
    <Modal
      title={
        adminProfile
          ? `${t('sellers.add/editAdmin.editTitle')} ${adminProfile.sellerName}`
          : t('sellers.add/editAdmin.addTitle')
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('sellers.add/editAdmin.ok')}
      cancelText={t('sellers.add/editAdmin.cancel')}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            adminProfile
              ? {
                  ...adminProfile,
                  actionList: adminProfile.actionList
                    ? adminProfile.actionList.split(',')
                    : undefined,
                }
              : { sellerId: sellerId }
          }
        >
          <Form.Item
            name="sellerId"
            label={t('sellers.add/editAdmin.sellerId')}
            rules={[
              {
                required: !adminProfile && true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            {!adminProfile ? (
              <SellersDropdown />
            ) : (
              <Typography.Text>
                {adminProfile.sellerShopInfo.sellerId}
              </Typography.Text>
            )}
          </Form.Item>
          {!adminProfile && (
            <Form.Item
              name="username"
              label={t('sellers.add/editAdmin.username')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="email"
            label={t('sellers.add/editAdmin.email')}
            rules={[
              {
                type: 'email',
                message: t('general.inputError.pleaseInputValidEmail'),
              },
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('sellers.add/editAdmin.password')}
            rules={[
              {
                required: adminProfile ? false : true,
                message: t('general.inputError.empty'),
              },
            ]}
            hasFeedback={adminProfile && firstPassword.length ? true : false}
          >
            <Input.Password
              onChange={(e) => setFirstPassword(e.target.value)}
            />
          </Form.Item>
          {firstPassword && (
            <Form.Item
              name="confirmPassword"
              label={t('sellers.add/editAdmin.confirmPassword')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseConfirmPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('general.inputError.passwordsDontMatch'))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="actionList"
            label={t('sellers.add/editAdmin.actions')}
            style={{ display: adminProfile ? undefined : 'none' }}
          >
            <TreeSelect
              treeData={adminActionList}
              treeCheckable
              placeholder={t('sellers.add/editAdmin.actionPermission')}
              allowClear
              showSearch={false}
              maxTagCount={10}
              treeDefaultExpandedKeys={
                adminProfile && adminProfile.actionList
                  ? adminProfile.actionList.split(',')
                  : []
              }
              disabled={
                !hasPermission(actionPermissions.generalGroup.allotPriv)
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SellerAdminModal;
