import { FilterOutlined, SendOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Form,
  Grid,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  FontSizeType,
  SMSLogData,
  SMSLogEnum,
  UserListData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { setFont } from '../../../utils/colComponents';
import { hasPermission } from '../../../utils/hasPermission';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import TableToolbar from '../../table/TableToolbar';
import UserMessageModal from '../UserMessageModal';
import UsersDropdown from '../common/UsersDropdown';
import { tableScrollToTop } from '../../../utils/helperFunction';

type UserMessageTableProps = {
  id?: string;
  userInfo?: UserListData;
};

const UserSMSLogTable = ({ id, userInfo }: UserMessageTableProps) => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [keyword, setKeyword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalLogs, setTotalLogs] = useState(0);
  const [advance, setAdvance] = useState<{ [key: string]: string }>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [smsLogs, setSmsLogs] = useState<SMSLogData[]>([]);
  const [smsEnum, setSmsEnum] = useState<SMSLogEnum>();
  const screens = Grid.useBreakpoint();
  const { addTab } = useTab();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SMSLogData[]>([]);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'logId',
    'userId',
    'addTime',
    'logType',
    'sid',
    'content',
    'error',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const getEnum = useCallback(() => {
    getDataWithAuthToken('sms/log/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setSmsEnum(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('sms/log/list', {
      params: {
        userId: id ? id : form.getFieldValue('userId'),
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        logType:
          formRef.current && form.getFieldValue('logType')
            ? form.getFieldValue('logType') === 'all'
              ? undefined
              : form.getFieldValue('logType')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setIsLoading(false);
          setSmsLogs(response.data.list);
          setTotalLogs(response.data.totalItem);
          // Scroll to top when data changes
          tableScrollToTop();
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t, id, keyword, page, pageSize, form]);

  useEffect(() => {
    getEnum();
  }, [getEnum]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onDelete = () => {
    setIsLoading(true);
    postDataWithAuthToken('sms/log/delete_batch', {
      logIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('users.alerts.smsLogDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const columns: ColumnsType<SMSLogData> = [
    {
      title: setFont(t('users.smsLogsColumns.logId'), fontSize),
      key: 'logId',
      dataIndex: 'logId',
      fixed: screens.lg ? 'left' : undefined,
      width: 80,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('users.smsLogsColumns.logType'), fontSize),
      key: 'logType',
      dataIndex: 'logType',
      width: 120,
      render: (value) => value && setFont(value.description || '', fontSize),
    },
    {
      title: setFont(t('users.smsLogsColumns.user'), fontSize),
      key: 'userId',
      dataIndex: 'userId',
      width: 160,
      render: (value, record) =>
        value ? (
          <>
            {setFont(`${t('users.smsLogsColumns.userId')}: `, fontSize)}
            <Button
              style={{ padding: 0, fontSize: fontSize }}
              type="link"
              onClick={() => {
                addTab('', `${dashboardRoute.users.detail}?user_id=${value}`);
              }}
              disabled={
                !hasPermission(actionPermissions.userGroup.userManage) || !value
              }
            >
              {value}
            </Button>
          </>
        ) : (
          record &&
          setFont(
            `${t('users.smsLogsColumns.mobile')}: ${record.mobile}`,
            fontSize
          )
        ),
    },
    {
      title: setFont('SID', fontSize),
      key: 'sid',
      dataIndex: 'sid',
      width: 160,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('users.smsLogsColumns.content'), fontSize),
      key: 'content',
      dataIndex: 'content',
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('users.smsLogsColumns.error'), fontSize),
      key: 'error',
      dataIndex: 'error',
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('users.smsLogsColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (value) => value && setFont(value, fontSize),
    },
  ];

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('users.userLogsColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
          initialValues={{ logType: 'all' }}
        >
          {!id && (
            <Form.Item
              name="userId"
              label={t('users.userLogsColumns.advancedSearch.byUserId')}
            >
              <UsersDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    userId: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item label={t('users.smsLogsColumns.logType')} name="logType">
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  logType: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t(`general.all`)}
              </Select.Option>
              {smsEnum &&
                smsEnum.logType &&
                smsEnum.logType.map((tag) => (
                  <Select.Option key={tag.code} value={tag.code}>
                    {tag.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('users.userLogsColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('users.userLogsColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userLogsColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {id ? (
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('users.userDetailPage.smsLogs')}
        </Typography.Title>
      ) : (
        <Typography.Title level={3} style={{ fontWeight: 500 }}>
          {t('users.userDetailPage.smsLogs')}
        </Typography.Title>
      )}
      <TableToolbar
        leftElement={
          <Button
            disabled={!hasPermission(actionPermissions.pluginGroup.smsSend)}
            icon={<SendOutlined />}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            {t('users.smsLogsColumns.title')}
          </Button>
        }
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        totalItems={totalLogs}
        columns={id ? columns.filter((col) => col.key !== 'userId') : columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        rows={smsLogs.map((row) => ({
          ...row,
          logType: row.logType ? row.logType.description : '',
        }))}
        searchPlaceholder={t('searchPlaceholders.searchUserKeyword')}
        search={
          id
            ? undefined
            : (keyword) => {
                setKeyword(keyword);
                setPage(1);
              }
        }
        advancedSearch={advancedSearch}
        exportConfig={{
          fileName: id ? `USER_SMS_LOG_ID_${id}` : 'USER_SMS_LOG',
        }}
      />
      <Table<SMSLogData>
        size={tableSize}
        columns={id ? columns.filter((col) => col.key !== 'userId') : columns}
        dataSource={smsLogs}
        loading={isLoading}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowKey={(log) => log.logId}
        pagination={{
          current: page,
          total: totalLogs,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (p, pSize) => {
            setPage(p);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: SMSLogData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((row) => ({
            ...row,
            logType: row.logType ? row.logType.description : '',
          }))}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={id ? columns.filter((col) => col.key !== 'userId') : columns}
          funct={{
            exportConfig: {
              fileName: id ? `USER_SMS_LOG_ID_${id}` : 'USER_SMS_LOG',
            },
            deleteFunc: onDelete,
          }}
        />
      )}
      {modalVisible && (
        <UserMessageModal
          visible={modalVisible}
          setVisible={setModalVisible}
          userInfo={userInfo}
          contentTemp={(smsEnum && smsEnum.contentTemp) || []}
        />
      )}
    </>
  );
};

export default UserSMSLogTable;
