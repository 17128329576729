import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Table,
  Tooltip,
  Upload,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSV_TEMPLATE } from '../../constants/styles';
import { UserListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';

type UserMessageModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo?: UserListData;
  contentTemp?: string[];
  callBack?: () => void;
};

const UserMessageModal = ({
  visible,
  setVisible,
  userInfo,
  contentTemp,
  callBack,
}: UserMessageModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [phones, setPhones] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onClose = () => {
    setIsValid(false);
    setPhones([]);
    setVisible(false);
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken('sms/send', {
          ...values,
          userId: userInfo ? userInfo.userId : undefined,
          phones: userInfo ? [userInfo.mobilePhone] : phones,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              alertMessage('success', t('users.alerts.messageSent'));
              callBack && callBack();
              onClose();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: ColumnType<{ index: number; mobile: string }>[] = [
    {
      title: t('users.smsLogsColumns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 80,
    },
    {
      title: t('users.smsLogsColumns.mobile'),
      dataIndex: 'mobile',
      key: 'mobile',
    },
  ];

  return (
    <Modal
      visible={visible}
      okButtonProps={{ disabled: !userInfo && !isValid, loading: isLoading }}
      onOk={onOk}
      onCancel={onClose}
      title={t('users.smsLogsColumns.title')}
      okText={t('general.ok')}
      cancelText={t('general.close')}
      maskClosable={false}
      bodyStyle={{ overflow: 'auto', maxHeight: 610 }}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          {userInfo ? (
            <Form.Item label={`${t('users.smsLogsColumns.mobile')}:`}>
              {userInfo.mobilePhone}
            </Form.Item>
          ) : (
            <Form.Item
              label={
                <Space>
                  {`${t('users.smsLogsColumns.importCsv')}:`}
                  <Tooltip title={t('users.smsLogsColumns.toolTip')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    href={`data:text/csv;charset=utf-8,${CSV_TEMPLATE}`}
                    download="phone-template.csv"
                  >
                    {t('general.downloadTemplate')}
                  </Button>
                </Space>
              }
              name="phones"
              rules={[{ required: true }]}
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
            >
              <Upload.Dragger
                multiple={false}
                accept=".csv"
                maxCount={1}
                showUploadList={isValid}
                beforeUpload={(file) => {
                  const fileReader = new FileReader();
                  if (file && file.type === 'text/csv') {
                    fileReader.onload = (e) => {
                      if (
                        e.target &&
                        e.target.result &&
                        typeof e.target.result === 'string'
                      ) {
                        // Filter out non-digit characters except new row and new line
                        let nums =
                          e.target.result
                            .replace(/[^\d\r\n]/g, '')
                            .split('\r\n')
                            .filter((num) => num !== '') || [];
                        if (nums.length > 0) {
                          // Check each string if it consists of non-digit characters or length is less than 10
                          let index = nums.findIndex(
                            (str: string) =>
                              /^\d+$/.test(str) === false || str.length < 10
                          );
                          if (index === -1) {
                            setIsValid(true);
                            setPhones(nums);
                          } else {
                            setIsValid(false);
                            setPhones([]);
                            alertMessage(
                              'error',
                              t('users.alerts.csvError'),
                              `${index + 1}: ${
                                e.target.result.split('\r\n')[index]
                              }`
                            );
                          }
                        } else {
                          setIsValid(false);
                          setPhones([]);
                          alertMessage('error', t('users.alerts.csvError'));
                        }
                      }
                    };
                    fileReader.readAsText(file);
                  } else {
                    setIsValid(false);
                    setPhones([]);
                    alertMessage('error', t('users.alerts.fileTypeError'));
                  }
                  return false;
                }}
              >
                <Space>
                  <InboxOutlined />
                  {t('general.dragToUpload')}
                </Space>
              </Upload.Dragger>
            </Form.Item>
          )}
          {isValid && (
            <Form.Item>
              <Table
                dataSource={phones.map((phone: string, index: number) => ({
                  index: index + 1,
                  mobile: phone,
                }))}
                columns={columns}
                rowKey={(item) => item.index}
                size="small"
                pagination={{
                  total: phones.length,
                  showTotal: (total, range) =>
                    t('general.paginationTotal', {
                      start: range[0],
                      end: range[1],
                      total: total,
                    }),
                  size: 'small',
                  pageSize: 8,
                  hideOnSinglePage: true,
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label={`${t('users.smsLogsColumns.content')}:`}
            name="content"
            rules={[{ required: true }]}
          >
            <Input.TextArea value={form.getFieldValue('content')} />
          </Form.Item>
          <Form.Item>
            <Space direction="vertical">
              {contentTemp &&
                contentTemp.length > 0 &&
                contentTemp.map((temp, index) => (
                  <Button
                    key={index}
                    style={{
                      textAlign: 'left',
                      whiteSpace: 'normal',
                      height: 'auto',
                      padding: 3,
                    }}
                    onClick={() => {
                      form.setFieldsValue({ content: temp });
                    }}
                  >
                    {temp}
                  </Button>
                ))}
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UserMessageModal;
