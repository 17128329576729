import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Image, Spin, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { ORANGE1 } from '../../../constants/color';
import { MEDIA_TYPE } from '../../../constants/mediaConstants';
import { FALLBACK_IMG } from '../../../constants/styles';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import PhotoGalleryModal from '../../PhotoGalleryModal';
import GoodGalleryModal from './GoodGalleryModal';

type GoodPhotoProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Displays photo of good in a card
 *
 * @param src URL of the photo
 */
const GoodPhoto = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodPhotoProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const isSubscribed = useRef(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<any>({});
  const [selectFromGoodGallery, setSelectFromGoodGallery] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (goodInfo && isSubscribed.current) {
      setSelectedPhoto({
        photo: '',
        photoPath: goodInfo.thumbPicPath || '',
        largePic: '',
        thumbPic: '',
        largePicPath: goodInfo.largePicPath || '',
        originalPicPath: goodInfo.originalPicPath || '',
      });
    }
  }, [goodInfo]);

  const handleCancelEditing = () => {
    if (isSubscribed.current) {
      setSelectedPhoto({
        photo: '',
        photoPath: goodInfo ? goodInfo.thumbPicPath : '',
        largePic: '',
        thumbPic: '',
        largePicPath: goodInfo ? goodInfo.largePicPath : '',
        originalPicPath: goodInfo ? goodInfo.originalPicPath : '',
      });
      setEditing(false);
      setSelectFromGoodGallery(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  const handleEditing = () => {
    if (editing) {
      if (isSubscribed.current) setLoading(true);
      postDataWithAuthToken('goods/edit', {
        goodsId: goodInfo && goodInfo.goodsId,
        thumbPic: selectedPhoto.thumbPic,
        largePic: selectedPhoto.largePic,
        originalPic: selectedPhoto.photo,
        pics:
          goodInfo &&
          (selectFromGoodGallery
            ? // If pics selected from current good gallery, do not need to add additional pics
              [...(goodInfo.pics || [])]
            : [
                {
                  thumbPic: selectedPhoto.thumbPic,
                  largePic: selectedPhoto.largePic,
                  originalPic: selectedPhoto.photo,
                },
                ...(goodInfo.pics || []),
              ]),
      })
        .then((response) => {
          if (response && response.goodStatus) {
            alertMessage('success', t('goods.alerts.goodsEdited'));
            handleCancelEditing();
            if (callBack) callBack();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setLoading(false);
          console.log(err);
        });
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  return (
    <Spin
      spinning={loading}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {editing ? (
        <Tooltip
          title={t('goods.add/editGood.cancel')}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <Button
            size="small"
            onClick={handleCancelEditing}
            icon={<CloseOutlined />}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={t('goods.add/editGood.edit')}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <Button
            size="small"
            icon={<EditOutlined />}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}
            onClick={() => {
              if (hasPermission(actionPermissions.goodGroup.goodManage))
                handleEditing();
            }}
          />
        </Tooltip>
      )}
      <Image
        src={selectedPhoto.photoPath}
        fallback={FALLBACK_IMG}
        style={{ maxWidth: 160 }}
        preview={{ src: selectedPhoto.largePicPath }}
      />
      {editing && (
        <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
          <Button
            size="small"
            onClick={() => {
              setFirstLoad(true);
              setShowModal(true);
              setSelectFromGoodGallery(false);
            }}
          >
            {t('goods.add/editGood.photoGallery')}
          </Button>
          <Button
            size="small"
            style={{ marginTop: 5, color: ORANGE1 }}
            onClick={() => {
              setShowGalleryModal(true);
              setSelectFromGoodGallery(true);
            }}
          >
            {t('goods.add/editGood.goodGallery')}
          </Button>
          {selectedPhoto.photo && (
            <Button
              size="small"
              type="primary"
              style={{ marginTop: 5 }}
              onClick={() => {
                if (hasPermission(actionPermissions.goodGroup.goodManage))
                  handleEditing();
              }}
            >
              {t('goods.add/editGood.ok')}
            </Button>
          )}
        </div>
      )}
      <PhotoGalleryModal
        visible={showModal}
        setVisible={setShowModal}
        setValue={setSelectedPhoto}
        value={selectedPhoto}
        supportedMediaType={MEDIA_TYPE.IMAGE}
        firstLoad={firstLoad}
      />
      {goodInfo?.pics && (
        <GoodGalleryModal
          photos={goodInfo?.pics}
          visible={showGalleryModal}
          setVisible={setShowGalleryModal}
          setValue={setSelectedPhoto}
          value={selectedPhoto}
        />
      )}
    </Spin>
  );
};

export default GoodPhoto;
