import { Card } from 'antd';
import Container from '../../components/Container';
import UserAddressTable from '../../components/users/userDetail/UserAddressTable';

/**
 * Displays all User Addresses
 * /dashboard/users/address
 */
const UserAddress = () => {
  return (
    <Container>
      <Card>
        <UserAddressTable />
      </Card>
    </Container>
  );
};

export default UserAddress;
