import React from 'react';
import { ShopConfigData } from '../types';
import {
  Form,
  Input,
  Radio,
  Select,
  FormInstance,
  InputNumber,
  Switch,
  Button,
  Row,
  Col,
} from 'antd';
import RegionSelect from '../components/settings/shopConfigFields/RegionSelect';
import GroupFields from '../components/settings/shopConfigFields/GroupFields';
import PhotoSelect from '../components/settings/shopConfigFields/PhotoSelect';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

/**
 * Return properly formatted information
 *
 * @param field        Specific Field Information
 * @param formInfo     List of Fields
 * @param formInstance Form Instance
 * @returns            Formatted Form Field
 */
const getFormField: (
  field: ShopConfigData,
  formInfo: Array<ShopConfigData>,
  formInstance: FormInstance<any>
) => JSX.Element = (field, formInfo, formInstance) => {
  switch (field.type) {
    default:
    case 'TEXT':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={field.value}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <Input />
        </Form.Item>
      );
    case 'MANUAL':
      if (field.code === 'shop_country') {
        return (
          <RegionSelect
            key={field.id}
            field={field}
            country={field.value}
            province={getFieldValue(formInfo, 'shop_province')}
            city={getFieldValue(formInfo, 'shop_city')}
          />
        );
      }
      break;
    case 'TEXTAREA':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={field.value}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <Input.TextArea />
        </Form.Item>
      );
    case 'SELECT':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={
            Object.keys(field.storeRange).length > 2
              ? field.value
              : Number(field.value)
              ? true
              : false
          }
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
          valuePropName={
            Object.keys(field.storeRange).length > 2 ? undefined : 'checked'
          }
        >
          {Object.keys(field.storeRange).length > 2 ? (
            <Radio.Group>
              {Object.keys(field.storeRange).map((option) => (
                <Radio key={option} value={option}>
                  {field.storeRange[option]}
                </Radio>
              ))}
            </Radio.Group>
          ) : (
            <Switch />
          )}
        </Form.Item>
      );
    case 'OPTIONS':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          help={field.remark ? field.remark : undefined}
          initialValue={field.value}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
            {Object.keys(field.storeRange).map((size) => (
              <Option key={size} value={size}>
                {field.storeRange[size]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    case 'HIDDEN':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={field.value}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <Input disabled />
        </Form.Item>
      );
    case 'GROUP':
      return (
        <GroupFields
          key={field.id}
          field={field}
          formInfo={formInfo}
          formInstance={formInstance}
        />
      );
    case 'PASSWORD':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={field.value}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <Input.Password />
        </Form.Item>
      );
    case 'FILE':
      return <PhotoSelect key={field.id} field={field} form={formInstance} />;
    case 'INTEGER':
    case 'LONG':
    case 'DOUBLE':
    case 'BIG_DECIMAL':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={field.value}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <InputNumber />
        </Form.Item>
      );
    case 'BOOLEAN':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          name={field.code}
          initialValue={field.value}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      );
    case 'LIST':
      return (
        <Form.Item
          key={field.id}
          label={field.description}
          help={field.remark ? field.remark : undefined}
          style={{ marginBottom: field.remark ? 16 : 24 }}
        >
          <Form.List
            name={field.code}
            initialValue={(field.valueDetail && field.valueDetail.list) || []}
          >
            {(items, { add, remove }) => (
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Row gutter={[0, 8]}>
                    {items.map((item, index) => (
                      <Col span={24} key={index}>
                        <Row gutter={[8, 8]}>
                          <Col span={22}>
                            <Form.Item {...item} style={{ marginBottom: 0 }}>
                              <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 4 }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <MinusCircleOutlined
                              onClick={() => remove(item.name)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col span={22}>
                  <Button
                    type="dashed"
                    onClick={() => add('')}
                    block
                    icon={<PlusOutlined />}
                  />
                </Col>
              </Row>
            )}
          </Form.List>
        </Form.Item>
      );
  }
  return <div key={field.id}></div>;
};

export { getFormField };

/**
 * @param formInfo  Form Information
 * @param fieldName The Field we want the value of
 * @returns any     The value inside that field name
 */
const getFieldValue: (
  formInfo: Array<ShopConfigData>,
  fieldName: string
) => any = (formInfo, fieldName) => {
  const filteredArray = formInfo.filter((x) => x.code === fieldName);
  if (filteredArray.length === 1) {
    const field = filteredArray[0];
    return field.value;
  }
  return '';
};
