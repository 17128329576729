import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueCard, ValueCardEnum, ValueCardType } from '../../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import {
  DATE_TIME_FORMAT,
  EXTENDED_TIMEOUT,
} from '../../constants/systemConstants';
import moment from 'moment-timezone';
import { generateKey } from '../../utils/helperFunction';
import UsersDropdown from '../users/common/UsersDropdown';

type ValueCardModalProps = {
  vctID: string | null;
  sellerId?: number;
  userId?: number;
  visible:
    | {
        visible: boolean;
        type: 'EDIT' | 'ASSIGN_USER' | 'ADD_MULTIPLE' | 'ADD_SINGLE';
      }
    | undefined;
  setVisible: React.Dispatch<
    React.SetStateAction<
      | {
          visible: boolean;
          type: 'EDIT' | 'ASSIGN_USER' | 'ADD_MULTIPLE' | 'ADD_SINGLE';
        }
      | undefined
    >
  >;
  selectedValueCard?: ValueCard;
  vcEnum?: ValueCardEnum;
  refresh: Function;
};

type componentShowProps = {
  vctEnable: boolean;
  vcPasswordEnable: boolean;
  userIdEnable: boolean;
  vcStatusEnable: boolean;
  endTimeEnable: boolean;

  addMultiNum: boolean;
};

const ValueCardModal = ({
  vctID,
  sellerId,
  userId,
  visible,
  setVisible,
  selectedValueCard,
  vcEnum,
  refresh,
}: ValueCardModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState<componentShowProps>({
    vctEnable: false,
    vcPasswordEnable: false,
    userIdEnable: false,
    vcStatusEnable: false,
    endTimeEnable: false,
    addMultiNum: false,
  });
  //Data components
  const [valueCardTypeList, setValueCardTypeList] = useState<ValueCardType[]>(
    []
  );
  //Pagination/table Components
  const [noLimit, setNoLimit] = useState<boolean>(false);
  const [currentPageVCT, setCurrentPageVCT] = useState(2);
  const [totalPageVCT, setTotalPageVCT] = useState(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Display each component with condition
  const getComponentShow = useCallback(() => {
    const displayComponent = {
      vctEnable: false,
      vcPasswordEnable: false,
      userIdEnable: false,
      vcStatusEnable: false,
      endTimeEnable: false,
      addMultiNum: false,
    };

    // Add VC By search Value Card Type
    if (!vctID && !selectedValueCard && sellerId !== undefined) {
      displayComponent.vctEnable = true;
    }

    // Edit VC
    if (visible?.type === 'EDIT' && selectedValueCard) {
      displayComponent.vcPasswordEnable = true;
      if (selectedValueCard && selectedValueCard?.userId !== 0) {
        // 如果提供UserId的话，无需输入
        if (!userId) displayComponent.userIdEnable = true;
        displayComponent.vcStatusEnable = true;
        displayComponent.endTimeEnable = true;
      }
    }

    // Add Single VC with userId
    if (visible?.type === 'ADD_SINGLE' || visible?.type === 'ASSIGN_USER') {
      if (!userId) displayComponent.userIdEnable = true;
    }

    // Add Multiple VC
    if (visible?.type === 'ADD_MULTIPLE') {
      displayComponent.addMultiNum = true;
    }

    setDisplay(displayComponent);
  }, [selectedValueCard, sellerId, userId, vctID, visible]);

  // Things to do on first render
  useEffect(() => {
    if (visible) {
      form.resetFields();
      getComponentShow();
      if (selectedValueCard && selectedValueCard.endTime === null)
        setNoLimit(true);
    }
  }, [form, visible, selectedValueCard, getComponentShow]);

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(undefined);
      setNoLimit(false);
      setValueCardTypeList([]);
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        postDataWithAuthToken(
          (visible?.type === 'EDIT' || visible?.type === 'ASSIGN_USER') &&
            selectedValueCard
            ? 'value_card/edit'
            : visible?.type === 'ADD_SINGLE'
            ? 'value_card/add'
            : 'value_card/add_batch',
          visible?.type === 'ASSIGN_USER'
            ? {
                vcId: selectedValueCard ? selectedValueCard.vcId : undefined,
                userId: userId ? userId : values.userId,
              }
            : selectedValueCard?.userId === 0
            ? {
                vcId: selectedValueCard ? selectedValueCard.vcId : undefined,
                valueCardPassword: values.valueCardPassword,
              }
            : {
                ...values,
                vctId: vctID ? Number(vctID) : values.vctId,
                vcId: selectedValueCard ? selectedValueCard.vcId : undefined,
                userId: userId ? userId : values.userId,
                endTime: noLimit
                  ? ''
                  : moment(values.endTime).format(DATE_TIME_FORMAT),
              }
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(visible);
            alertMessage(
              'success',
              visible?.type === 'EDIT'
                ? t('activity.alerts.valueCardEdited')
                : visible?.type === 'ASSIGN_USER'
                ? t('activity.alerts.valueCardUserAssigned')
                : t('activity.alerts.valueCardAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  /**
   * Sends a request and returns the Value Card Type Data based on the typed keywords
   */
  const getValueCardTypeData = (value?: string) => {
    if (sellerId !== undefined) {
      getDataWithAuthToken('value_card/type/list', {
        params: {
          sellerId: sellerId,
          keyword: value || undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setValueCardTypeList(response.data.list);
              setCurrentPageVCT(2);
              setTotalPageVCT(response.data.totalPage);
            }
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  /**
   * Makes a new request to get new list of Value Card Type
   * When the user scrolls to the bottom of the dropdown box
   */
  const fetchOnScrollValueCardType = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (currentPageVCT > totalPageVCT) return;
      getDataWithAuthToken('value_card/type/list', {
        params: {
          sellerId: sellerId,
          page: currentPageVCT,
          keyword: searchText.vct || undefined,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setValueCardTypeList((prev) => [...prev, ...response.data.list]);
            setCurrentPageVCT((prev) => prev + 1);
            setTotalPageVCT(response.data.totalPage);
          }
        }
      });
    }
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={550}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedValueCard
          ? visible?.type === 'ASSIGN_USER'
            ? `${t('activity.add/EditValueCardType.assignUserVCTitle')} ${
                selectedValueCard.vcId
              }`
            : `${t('activity.add/EditValueCardType.editVCTitle')} ${
                selectedValueCard.vcId
              }`
          : `${t('activity.add/EditValueCardType.addVCTitle')}`
      }
      visible={visible ? visible.visible : false}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={
            selectedValueCard
              ? {
                  ...selectedValueCard,
                  endTime: selectedValueCard.endTime
                    ? moment(selectedValueCard.endTime)
                    : undefined,
                  userId:
                    visible?.type === 'ASSIGN_USER'
                      ? ''
                      : selectedValueCard.userId,
                }
              : {}
          }
        >
          {/**For VCT - Choose ValueCard*/}
          {display.vctEnable && (
            <Form.Item
              label={t('activity.add/EditValueCardType.vctId')}
              name="vctId"
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onPopupScroll={fetchOnScrollValueCardType}
                showSearch
                allowClear
                onSearch={(value) => {
                  setSearchText((prev) => ({ ...prev, sellerId: value }));
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(
                      () => getValueCardTypeData(value),
                      EXTENDED_TIMEOUT
                    )
                  );
                }}
                onFocus={() => {
                  if (!valueCardTypeList.length) getValueCardTypeData();
                }}
                filterOption={false}
                placeholder={t('searchPlaceholders.searchValueCardType')}
                optionLabelProp="key"
              >
                {valueCardTypeList.map((vct) => (
                  <Select.Option key={vct.vctId} value={vct.vctId}>
                    <div>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography.Text type="secondary">
                          {t('activity.add/EditValueCardType.vctId')}
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          {t('activity.add/EditValueCardType.vcName')}
                        </Typography.Text>
                      </span>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography>{vct.vctId}</Typography>
                        <Typography>{vct.vcName}</Typography>
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {/**For VC Password */}
          {display.vcPasswordEnable && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Form.Item
                label={t('activity.add/EditValueCardType.valueCardPassword')}
                name="valueCardPassword"
                rules={[
                  {
                    required: true,
                    len: 8,
                    message: t('activity.inputError.passwordRange'),
                  },
                  {
                    pattern: /^[\w]*$/,
                    message: t('activity.inputError.noSpecialChars'),
                  },
                ]}
                style={{ paddingRight: 10 }}
              >
                <Input style={{ width: 100 }} />
              </Form.Item>
              <Form.Item label={' '}>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      valueCardPassword: generateKey(8),
                    });
                  }}
                >
                  {t('activity.add/EditValueCardType.generatePassword')}
                </Button>
              </Form.Item>
            </div>
          )}

          {/**For VC UserId */}
          {display.userIdEnable && (
            <Form.Item
              label={t('activity.add/EditValueCardType.userId')}
              name="userId"
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <UsersDropdown
                defaultOptions={
                  selectedValueCard && visible?.type !== 'ASSIGN_USER'
                }
                initialValue={
                  visible?.type === 'EDIT'
                    ? selectedValueCard?.userId
                    : undefined
                }
              />
            </Form.Item>
          )}

          {/**For VC Status */}
          {display.vcStatusEnable && (
            <Form.Item
              label={t('activity.add/EditValueCardType.vcStatus')}
              name="vcStatus"
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                allowClear
                filterOption={false}
                // optionLabelProp="key"
              >
                {vcEnum &&
                  vcEnum.vcStatus.length > 0 &&
                  vcEnum.vcStatus.map((status) => (
                    <Select.Option key={status.code} value={status.code}>
                      <Typography>{status.description}</Typography>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          {/**For VC End Time */}
          {display.endTimeEnable && (
            <Space align="start" wrap>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={!noLimit ? 'endTime' : undefined}
                label={t('activity.add/EditValueCardType.endTime')}
              >
                {!noLimit && <DatePicker showTime />}
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Checkbox
                  checked={noLimit}
                  onChange={(e) => {
                    setNoLimit(e.target.checked);
                  }}
                >
                  {t('activity.add/EditValueCardType.endTimeHelp')}
                </Checkbox>
              </Form.Item>
            </Space>
          )}

          {/**For Number Input */}
          {display.addMultiNum && (
            <Form.Item
              label={t('activity.add/EditValueCardType.number')}
              name="number"
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ValueCardModal;
