import { getNewToken } from './axiosRequest';

/**
 * Manages the tokens for createAuthProvider
 */
export const createTokenProvider = () => {
  /**
   * @param jwtToken JSON Token
   * @returns Expiry time or null if token doesn't exist
   */
  const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) return null;
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  /**
   * @param exp Expiry Date
   * @returns boolean Whether expiry date has passed or not
   */
  const isExpired = (exp?: number | null) => {
    if (!exp) return false;
    return Date.now() > exp;
  };

  /**
   * @returns string Token stored by token provider
   */
  const getToken = async () => {
    let _accessToken: string | null =
      JSON.parse(localStorage.getItem('AUTH_TOKEN') || 'null') || null;
    if (!_accessToken) {
      setToken(null);
    } else {
      if (isExpired(getExpirationDate(_accessToken))) {
        const response = await getNewToken();
        if (response && response.goodStatus) {
          const { accessToken: newToken } = response.data;
          setToken(newToken);
          _accessToken = newToken;
        } else {
          setToken(null);
        }
      }
    }

    return _accessToken;
  };

  const isLoggedIn = () => {
    let _accessToken: string | null =
      JSON.parse(localStorage.getItem('AUTH_TOKEN') || 'null') || null;
    return !!_accessToken;
  };

  let observers: Array<(isLogged: boolean) => void> = [];

  const subscribe = (observer: (isLogged: boolean) => void) => {
    observers.push(observer);
  };

  const unsubscribe = (observer: (isLogged: boolean) => void) => {
    observers = observers.filter((_observer) => _observer !== observer);
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    observers.forEach((observer) => observer(isLogged));
  };

  /**
   * @param token Access token that will be used by createTokenProvider
   */
  const setToken = (token: string | null) => {
    if (token) {
      localStorage.setItem('AUTH_TOKEN', JSON.stringify(token));
    } else {
      localStorage.removeItem('AUTH_TOKEN');
    }
    notify();
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};
