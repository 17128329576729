import { DatePicker, Form, Input, Modal, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../constants/systemConstants';
import { UserListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import SalespersonDropdown from '../salesperson/SalespersonDropdown';
import UserRankDropdown from './common/UserRankDropdown';

const { Option } = Select;

type UserModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // If userProfile Exists then we are editing an already existing profile.
  userProfile?: UserListData;
  callBack?: Function;
};

/**
 * Modal to create and edit users
 *
 * @prop visible      Whether Modal should be visible
 * @prop setVisible   Setting Modal to be visible or not
 * @prop userProfile  Profile to edit
 * @prop callBack     Function to call after submitting form. Usually a refresh function
 */
const UserModal = ({
  visible,
  setVisible,
  userProfile,
  callBack,
}: UserModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const isSubscribed = useRef(true);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Need to reset the form so that the default values are correct in Edit
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    if (userProfile) {
      setFormData({
        ...userProfile,
        birthday: moment(userProfile.birthday),
        rankId: userProfile.userRank
          ? {
              label: `${userProfile.userRank.rankName} (${userProfile.userRank.rankId})`,
              value: userProfile.userRank.rankId,
            }
          : undefined,
        memberId: userProfile.member
          ? {
              label: `${userProfile.member.rankName} (${userProfile.member.rankId})`,
              value: userProfile.member.rankId,
            }
          : undefined,
        salesPerson: userProfile.salesPerson
          ? {
              label: `${userProfile.salesPerson.fullName} (${userProfile.salesPerson.salesPersonId})`,
              value: userProfile.salesPerson.salesPersonId,
            }
          : undefined,
      });
    } else {
      const profile = localStorage.getItem('profile')
        ? JSON.parse(localStorage.getItem('profile') || '{}')
        : undefined;

      setFormData({
        salesPerson: getDashboardStyle().isSalesForceAppType
          ? profile
            ? {
                label: `${profile.adminUserName} (${profile.adminId})`,
                value: profile.adminId,
              }
            : undefined
          : undefined,
      });
    }
  }, [userProfile, setFormData]);

  /**
   * @param   values Form values
   * @param   year   Birth year
   * @param   month  Birth month
   * @param   day    Birth day
   * @returns        response from edit profile request
   */
  const updateProfile = async (values: any) => {
    const response = await postDataWithAuthToken('users/edit_profile', {
      userId: userProfile?.userId,
      nickName: values.nickName,
      email: values.email,
      mobilePhone: values.mobilePhone,
      rankId: values.rankId ? values.rankId.value : 0,
      memberId: values.memberId ? values.memberId.value : 0,
      password: values.password ? values.password : undefined,
      confirmPassword: values.confirmPassword
        ? values.confirmPassword
        : undefined,
      sex: values.sex,
      birthday: values.birthday
        ? values.birthday.format(DATE_FORMAT)
        : undefined,
      remark: values.remark,
      salesPersonId: values.salesPerson ? values.salesPerson.value : 0,
    });
    return response;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        try {
          if (isSubscribed.current) setLoading(true);
          if (userProfile) {
            // Editing User Profile
            updateProfile(values).then((response) => {
              if (response && response.goodStatus) {
                if (isSubscribed.current) setLoading(false);
                if (isSubscribed.current) setVisible(false);
                alertMessage('success', t('users.alerts.userEdited'));
                if (callBack) {
                  callBack();
                }
              } else {
                if (isSubscribed.current) setLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            });
          } else {
            // Creating new User Profile
            postDataWithAuthToken('users/add', {
              nickName: values.nickName,
              email: values.email || '',
              mobilePhone: values.mobilePhone,
              password: values.password,
              confirmPassword: values.confirmPassword,
              rankId: values.rankId ? values.rankId.value : 0,
              memberId: values.memberId ? values.memberId.value : 0,
              sex: values.sex,
              birthday: values.birthday
                ? values.birthday.format(DATE_FORMAT)
                : undefined,
              remark: values.remark,
              salesPersonId: values.salesPerson ? values.salesPerson.value : 0,
            }).then((response) => {
              if (response && response.goodStatus) {
                if (isSubscribed.current) setLoading(false);
                if (isSubscribed.current) setVisible(false);
                alertMessage('success', t('users.alerts.userAdded'));
                if (callBack) {
                  callBack();
                }
              } else {
                if (isSubscribed.current) setLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            });
          }
        } catch (err) {
          console.log(err);
          if (isSubscribed.current) setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    if (isSubscribed.current) {
      setFirstPassword('');
      setVisible(false);
      setFormData({});
    }
  };

  return (
    <Modal
      title={
        userProfile
          ? `${t('actionsColumn.edit')} ${userProfile.nickName}`
          : t('users.addUser.title')
      }
      visible={visible}
      onOk={submitForm}
      onCancel={handleCancel}
      okText={t('users.addUser.ok')}
      cancelText={t('users.addUser.cancel')}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={formData}>
          <Form.Item name="nickName" label={t('users.addUser.nickname')}>
            <Input />
          </Form.Item>
          <Form.Item
            name="mobilePhone"
            label={t('users.addUser.mobilePhone')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('users.addUser.password')}
            rules={[
              {
                required: userProfile ? false : true,
                message: t('general.inputError.empty'),
              },
            ]}
            hasFeedback={userProfile && firstPassword.length ? true : false}
          >
            <Input.Password
              onChange={(e) => setFirstPassword(e.target.value)}
            />
          </Form.Item>
          {firstPassword && (
            <Form.Item
              name="confirmPassword"
              label={t('users.addUser.confirmPassword')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseConfirmPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('general.inputError.passwordsDontMatch'))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="email"
            label={t('users.addUser.email')}
            rules={[
              {
                type: 'email',
                message: t('general.inputError.pleaseInputValidEmail'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="rankId" label={t('users.addUser.userRank')}>
            <UserRankDropdown
              labelInValue={true}
              initialValue={formData.rankId}
            />
          </Form.Item>
          <Form.Item
            name="memberId"
            label={t('users.userListColumns.userMember')}
          >
            <UserRankDropdown
              isMember={true}
              labelInValue={true}
              initialValue={formData.memberId}
            />
          </Form.Item>
          <Form.Item name="sex" label={t('users.addUser.sex.title')}>
            <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
              <Option value={0}>{t('users.addUser.sex.female')}</Option>
              <Option value={1}>{t('users.addUser.sex.male')}</Option>
              <Option value={2}>{t('users.addUser.sex.other')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="birthday" label={t('users.addUser.birthday')}>
            <DatePicker placeholder={t('users.addUser.selectDate')} />
          </Form.Item>
          <Form.Item name="salesPerson" label={t('salesperson.title')}>
            <SalespersonDropdown
              labelInValue={true}
              initialValue={formData.salesPerson}
            />
          </Form.Item>
          <Form.Item name="remark" label={t('users.addUser.remark')}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UserModal;
