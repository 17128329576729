import { useEffect, useRef } from 'react';
import { Col, Form, Row, Tag, Typography } from 'antd';
import { OrderClaimData } from '../../../types';
import { useTranslation } from 'react-i18next';

type ClaimReviewInfoProps = {
  orderClaimDetail: OrderClaimData;
};

/**
 * Displays Review Information
 *
 */
const ClaimReviewInfo = ({ orderClaimDetail }: ClaimReviewInfoProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <>
      <Form layout="vertical">
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {
              <Typography.Text strong style={{ fontSize: 16 }}>
                {t('order.claimListColumns.reviewInfoTitle')}
              </Typography.Text>
            }
          </Col>
          {orderClaimDetail.reviewAdminUser &&
            orderClaimDetail.reviewAdminUser.adminUserName && (
              <Col span={24} md={12} lg={8} xl={6}>
                {
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label={`${t('order.claimListColumns.reviewAdminUser')}: `}
                  >
                    {orderClaimDetail.reviewAdminUser.adminUserName}
                  </Form.Item>
                }
              </Col>
            )}
          {orderClaimDetail.reviewStatus && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{
                    marginBottom: 0,
                  }}
                  label={`${t('order.claimListColumns.reviewStatus')}: `}
                >
                  <Tag
                    color={
                      orderClaimDetail.reviewStatus
                        ? orderClaimDetail.reviewStatus.tagColor
                        : undefined
                    }
                  >
                    {orderClaimDetail.reviewStatus
                      ? orderClaimDetail.reviewStatus.description
                      : undefined}
                  </Tag>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.reviewTime && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.reviewTime')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.reviewTime}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.reviewContent && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.reviewContent')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.reviewContent}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default ClaimReviewInfo;
