import { TreeSelect } from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import { hasPermission } from '../../utils/hasPermission';

type PermissionListProps = {
  permissionList?: DataNode[];
  selectedPermissions?: string[];
  setSelectedPermissions: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  onSelectionChange?: Function;
};

const PermissionList = ({
  permissionList,
  setSelectedPermissions,
  selectedPermissions,
  onSelectionChange,
}: PermissionListProps) => {
  const { t } = useTranslation();
  const onChange = (value: string[]) => {
    onSelectionChange && onSelectionChange();
    setSelectedPermissions(value);
  };

  return (
    <TreeSelect
      treeData={permissionList}
      value={
        selectedPermissions
          ? selectedPermissions.includes('all')
            ? permissionList &&
              permissionList.map((permission) => permission.value as string)
            : selectedPermissions
          : undefined
      }
      onChange={onChange}
      treeCheckable
      placeholder={t('admin.add/editRole.actionPermission')}
      allowClear
      showSearch={false}
      maxTagCount={10}
      treeDefaultExpandedKeys={
        selectedPermissions
          ? selectedPermissions.includes('all')
            ? permissionList &&
              permissionList.map((permission) => permission.value as string)
            : selectedPermissions
          : undefined
      }
      disabled={!hasPermission(actionPermissions.generalGroup.allotPriv)}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    />
  );
};

export default PermissionList;
