import React from 'react';
import { useTranslation } from 'react-i18next';
import ShopConfigLayout from '../layout/ShopConfigLayout';

/**
 * Displays Goods Settings
 * /dashboard/settings/goods
 *
 * @param route Route Information from react-router-config
 */
const GoodsSettings = () => {
  const { t } = useTranslation();

  return <ShopConfigLayout group="goods" title={t('goods.goodsSetting')} />;
};

export default GoodsSettings;
