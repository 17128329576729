import { BorderlessTableOutlined } from '@ant-design/icons';
import { DatePicker, Divider, Form, FormInstance, Select, Space } from 'antd';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SHIPPING_CODE } from '../../../constants/orderConstants';
import { OrderEnum, PaymentMethod, ShippingData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';

type ShippingPaymentFormProps = {
  defaultSelected?: boolean;
  form: FormInstance<any>;
  enums?: OrderEnum;
  paymentMethods: PaymentMethod[];
  setPaymentMethods: React.Dispatch<SetStateAction<PaymentMethod[]>>;
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
};

const ShippingPaymentForm = ({
  defaultSelected = false,
  form,
  enums,
  paymentMethods,
  setPaymentMethods,
  formData,
  setFormData,
}: ShippingPaymentFormProps) => {
  const { t } = useTranslation();
  const [shippingList, setShippingList] = useState<ShippingData[]>([]);

  const getShippingList = useCallback(() => {
    getDataWithAuthToken('shipping/list')
      .then((response) => {
        if (response && response.goodStatus) {
          const shippingMethods: ShippingData[] = response.data.list;
          setShippingList(shippingMethods);
          if (
            defaultSelected &&
            !formData.shippingId &&
            !formData.shippingCode &&
            shippingMethods.length > 0
          ) {
            console.log(formData.shippingCode);
            setFormData((prev) => ({
              ...prev,
              shippingId: shippingMethods[0].shippingId,
              shippingCode: shippingMethods[0].shippingCode,
              shippingName: shippingMethods[0].shippingName,
            }));
            form.setFieldsValue({
              shippingId: shippingMethods[0].shippingId,
              shippingCode: shippingMethods[0].shippingCode,
              shippingName: shippingMethods[0].shippingName,
            });
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    form,
    t,
    setShippingList,
    setFormData,
    defaultSelected,
    formData.shippingId,
    formData.shippingCode,
  ]);

  const getPaymentMethodList = useCallback(() => {
    getDataWithAuthToken('payment/enabled_list', {
      params: { isSeller: !!formData.sellerId, sellerId: formData.sellerId },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          const paymentList: PaymentMethod[] = response.data.list;
          setPaymentMethods(paymentList);
          if (defaultSelected && !formData.payId && paymentList.length > 0) {
            setFormData((prev) => ({
              ...prev,
              payId: paymentList[0].payId,
              payName: paymentList[0].payName,
            }));
            form.setFieldsValue({
              payId: paymentList[0].payId,
              payName: paymentList[0].payName,
            });
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    form,
    formData.sellerId,
    formData.payId,
    defaultSelected,
    t,
    setPaymentMethods,
    setFormData,
  ]);

  // 默认选择第一个选项
  useEffect(() => {
    if (defaultSelected) {
      if (!shippingList || !shippingList.length) {
        getShippingList();
      }
      if (!paymentMethods || !paymentMethods.length) {
        getPaymentMethodList();
      }
    }
  }, [
    defaultSelected,
    shippingList,
    paymentMethods,
    getShippingList,
    getPaymentMethodList,
  ]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        shippingId: formData.shippingId,
        expectShippingTime: formData.expectShippingTime,
        expectShippingTimeInterval: formData.expectShippingTimeInterval,
        payId: formData.payId,
      }}
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.add/editOrder.shippingInfo')}
        </Space>
      </Divider>
      <Form.Item
        label={`${t('order.orderDetail.shippingName')}: `}
        name="shippingId"
        rules={[
          {
            required: true,
            message: t('general.inputError.pleaseSelectOne'),
          },
        ]}
      >
        <Select
          placeholder={t('general.pleaseSelect')}
          onChange={(value, option: any) => {
            setFormData((prev) => ({
              ...prev,
              shippingId: value,
              shippingCode: option.key,
              shippingName: option.children,
            }));
          }}
          onFocus={() => {
            if (!shippingList.length) getShippingList();
          }}
        >
          {shippingList.map((shipping) => (
            <Select.Option
              key={shipping.shippingCode}
              value={shipping.shippingId}
            >
              {shipping.shippingName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Space align="end" wrap>
        <Form.Item
          label={
            formData.shippingCode === SHIPPING_CODE['flat']
              ? `${t('order.orderDetail.expectedShippingTime')}: `
              : `${t('order.orderDetail.expectedPickupTime')}: `
          }
          style={{ minWidth: 149 }}
          name="expectShippingTime"
        >
          <DatePicker
            onChange={(value) => {
              if (!value) {
                setFormData((prev) => ({
                  ...prev,
                  expectShippingTime: value,
                  expectShippingTimeInterval: 0,
                }));
              }
            }}
          />
        </Form.Item>
        {formData.shippingCode === SHIPPING_CODE['flat'] && (
          <Form.Item
            style={{ minWidth: 149 }}
            name="expectShippingTimeInterval"
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              allowClear
              onChange={(value) => {
                if (!value)
                  setFormData((prev) => ({
                    ...prev,
                    expectShippingTimeInterval: value,
                  }));
              }}
            >
              {enums &&
                enums.timeInterval.map((interval) => (
                  <Select.Option value={interval.code} key={interval.code}>
                    {interval.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
      </Space>
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.add/editOrder.paymentInfo')}
        </Space>
      </Divider>
      <Form.Item
        label={`${t('order.add/editOrder.paymentMethod')}: `}
        name="payId"
        rules={[
          {
            required: true,
            message: t('general.inputError.pleaseSelectOne'),
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Select
          onChange={(value, option: any) => {
            setFormData((prev) => ({
              ...prev,
              payId: value,
              payName: option.children,
            }));
          }}
          placeholder={t('general.pleaseSelect')}
          onFocus={() => {
            if (!paymentMethods.length) getPaymentMethodList();
          }}
        >
          {paymentMethods.map((paymentMethod) => (
            <Select.Option
              key={paymentMethod.payId}
              value={paymentMethod.payId}
            >
              {paymentMethod.payName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default ShippingPaymentForm;
