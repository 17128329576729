export const REVIEW_STATUS_DEFAULT = 'UNREVIEWED';
export const REVIEW_STATUS_FAILED = 'FAIL_REVIEW';
export const REVIEW_STATUS_PASSED = 'PASS_REVIEW';
export const REVIEW_STATUS_NO_NEED_REVIEW = 'NO_NEED_REVIEW';
export const REVIEW_STATUS: { [key: string]: string } = {
  UNREVIEWED: 'general.reviewStatus.UNREVIEWED', //Not Reviewed
  FAIL_REVIEW: 'general.reviewStatus.FAIL_REVIEW', //Failed
  PASS_REVIEW: 'general.reviewStatus.PASS_REVIEW', //Passed
  NO_NEED_REVIEW: 'general.reviewStatus.NO_NEED_REVIEW', //Not Required
};

/** Boolean to Yes-No Status */
export const BOOLEAN_TO_STATUS: { [key: string]: string } = {
  true: `general.booleanToStatus.true`, //Yes
  false: `general.booleanToStatus.false`, //No
};

/**Employee Status */
export const EMPLOYEE_DEFAULT_STATUS = 'OFF_DUTY';

/*** Ad position location 广告位位置 */
export const Ad_POSITION_LOCATION_CATEGORY = 'CATEGORY_LIST';
