import React, { useEffect, useState, useCallback, useRef } from 'react';
import Container from '../../components/Container';
import { Card, Typography, Table, Button, Grid, Space, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FontSizeType, SupplierData } from '../../types';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useTranslation } from 'react-i18next';
import TableToolbar from '../../components/table/TableToolbar';
import FourZeroThree from '../../components/FourZeroThree';
import { alertMessage } from '../../utils/alertMessage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { hasPermission } from '../../utils/hasPermission';

import { compare, setFont } from '../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { actionPermissions } from '../../constants/actionPermissions';
import SupplierModal from '../../components/goods/SupplierModal';
import { PlusOutlined } from '@ant-design/icons';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Supplier List
 * /dashboard/goods/supplier
 *
 * @prop route Route information from react-router-config
 */
const SupplierList = () => {
  //General Components
  const [loading, setLoading] = useState(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const isSubscribed = useRef(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [supplierModalVis, setSupplierModalVis] = useState(false);
  //Data Components
  const [selectedSupplierObj, setSelectedSupplierObj] =
    useState<SupplierData>();
  const [supplierData, setSupplierData] = useState<SupplierData[]>([]);
  //Text Components
  const [keyword, setKeyword] = useState('');
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  //Table Components
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const columnKeys = [
    'supplierId',
    'supplierName',
    'supplierDesc',
    'addTime',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`goods/supplier/list?`, {
      params: {
        keyword: keyword ? keyword : undefined,
        page: page,
        size: pageSize,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setSupplierData(response.data.list);
            setTotal(response.data.totalItem);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword]);

  const deleteSupplier = (supplierId: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('goods/supplier/delete', {
      supplierId: supplierId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.supplierDeleted'));
          getData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const columns: ColumnsType<SupplierData> = [
    {
      title: setFont(t('goods.supplierListColumns.supplierId'), fontSize),
      dataIndex: 'supplierId',
      key: 'supplierId',
      fixed: screens.lg ? 'left' : undefined,
      width: 60,
      render: (text: string, record: SupplierData) => (
        <Button
          disabled={!hasPermission(actionPermissions.goodGroup.suppliersManage)}
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setSelectedSupplierObj(record);
            setSupplierModalVis(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: SupplierData, b: SupplierData) =>
        compare(a.supplierId, b.supplierId),
    },
    {
      title: setFont(t('goods.supplierListColumns.supplierName'), fontSize),
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 80,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: SupplierData, b: SupplierData) =>
        compare(a.supplierName, b.supplierName),
    },
    {
      title: setFont(t('goods.supplierListColumns.supplierDesc'), fontSize),
      dataIndex: 'supplierDesc',
      key: 'supplierDesc',
      width: 200,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.supplierListColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      width: 150,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: SupplierData) => (
        <Space>
          <Button
            disabled={
              !hasPermission(actionPermissions.goodGroup.suppliersManage)
            }
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setSelectedSupplierObj(record);
              setSupplierModalVis(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              deleteSupplier(record.supplierId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.goodGroup.suppliersManage)
            }
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.goodGroup.suppliersManage)
              }
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('goods.supplierList')}
            </Typography.Title>
            <TableToolbar
              fontSize={fontSize}
              setFontSize={setFontSize}
              leftElement={
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setSelectedSupplierObj(undefined);
                    setSupplierModalVis(true);
                  }}
                  disabled={
                    !hasPermission(actionPermissions.goodGroup.suppliersManage)
                  }
                >
                  {t('goods.add/editSupplier.addTitle')}
                </Button>
              }
              tableSize={tableSize}
              setTableSize={setTableSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchSupplier')}
              rows={supplierData.map((supplier) => ({
                ...supplier,
              }))}
              exportConfig={{ fileName: 'SUPPLIER_LIST' }}
            />
            <Table<SupplierData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={supplierData}
              size={tableSize}
              loading={loading}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              rowKey={(supplier) => supplier.supplierId}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                },
                current: page,
              }}
            />
          </Card>
          <SupplierModal
            visible={supplierModalVis}
            setVisible={setSupplierModalVis}
            selectedSupplier={selectedSupplierObj}
            refresh={() => getData()}
          />
        </>
      )}
    </Container>
  );
};
export default SupplierList;
