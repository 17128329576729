import { Modal, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PrintType, PrintTypeMode } from '../types';
import OrderPrintModal from './order/OrderPrintModal';
import GeneralPrintContent from './GeneralPrintContent';
import { actionPermissions } from '../constants/actionPermissions';

type PrintModalProps<T extends PrintType> = {
  mode: PrintTypeMode;
  visible: boolean;
  data: T | undefined;
  setData?: React.Dispatch<React.SetStateAction<T | undefined>>;
  modalLoading?: boolean;
  isFullVersion?: boolean;
  setIsFullVersion?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrintModal = <T extends PrintType>({
  mode,
  modalLoading,
  isFullVersion,
  setIsFullVersion,
  visible,
  setVisible,
  data,
  setData,
}: PrintModalProps<T>) => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Permission action for mode
  const actionPermission =
    mode === 'printLabel'
      ? actionPermissions.goodGroup.goodView
      : mode === 'excel'
      ? actionPermissions.settingGroup.fileManager
      : actionPermissions.orderGroup.orderPrint;

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    isSubscribed.current = false;
  }, []);

  const onCancel = () => {
    setIsFullVersion && setIsFullVersion(undefined);
    setData && setData(undefined);
    setVisible(false);
  };

  const getPrintModalContent = () => {
    let content = <></>;
    if (data) {
      switch (mode) {
        case 'order':
        case 'invoice':
        case 'packingSlip':
          if (data.ordersData) {
            content = (
              <OrderPrintModal
                mode={mode}
                isFullVersion={mode === 'order' ? isFullVersion : undefined}
                onCancel={() => onCancel}
                printoutTableData={data}
              />
            );
          }
          break;
        case 'driver':
          content = (
            <GeneralPrintContent<PrintType>
              onCancel={() => onCancel}
              printoutTableData={data}
              mode={mode}
              isLandscape={true}
              isZoom={true}
              actionPermission={actionPermission}
            />
          );
          break;
        default:
          content = (
            <GeneralPrintContent<PrintType>
              onCancel={() => onCancel}
              printoutTableData={data}
              mode={mode}
              actionPermission={actionPermission}
            />
          );
          break;
      }
    }
    return content;
  };

  return (
    <Modal
      title={t('order.print.title')}
      visible={visible}
      width={mode === 'driver' ? 1200 : 860}
      cancelText={t('order.print.cancel')}
      onCancel={onCancel}
      footer={null}
    >
      <Spin spinning={modalLoading}>{getPrintModalContent()}</Spin>
    </Modal>
  );
};

export default PrintModal;
