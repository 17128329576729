import { Button, Space, Typography } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GRAY4, ORANGE1 } from '../../../constants/color';
import { AddressData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import UserAddressModal from '../UserAddressModal';

type UserAddressDropdownProps = {
  userId?: string;
  firstAddressData?: AddressData[];
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const UserAddressDropdown = ({
  userId,
  firstAddressData,
  onChange,
}: UserAddressDropdownProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const [addressData, setAddressData] = useState<AddressData[]>([]);
  const [addressPage, setAddressPage] = useState<number>(2);
  const [addressTotalPage, setAddressTotalPage] = useState<number>(2);

  const getUserAddressData = () => {
    getDataWithAuthToken('users/user_address/list', {
      params: { userId: userId || undefined },
    })
      .then((result) => {
        if (result && result.goodStatus) {
          setAddressData(result.data.list);
          setAddressPage(2);
          setAddressTotalPage(result.data.totalPage);
        } else {
          alertMessage(
            'error',
            result?.msg || t('general.noResponse'),
            result?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addressFetchOnScroll = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (addressPage > addressTotalPage) return;
      getDataWithAuthToken('users/user_address/list', {
        params: {
          page: addressPage,
          userId: userId || undefined,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setAddressData((prev) => [...prev, ...response.data.list]);
            setAddressPage((prev) => prev + 1);
            setAddressTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <>
      <Select
        style={{ width: '100%' }}
        placeholder={t('general.pleaseSelect')}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onPopupScroll={addressFetchOnScroll}
        onChange={handleOnChange}
        onFocus={() => {
          if (!addressData.length) {
            // load from parent components
            if (firstAddressData && firstAddressData.length > 0) {
              setAddressData(firstAddressData);
              setAddressPage(2);
            } else {
              getUserAddressData();
            }
          }
        }}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Space
                style={{
                  width: '100%',
                  borderTopStyle: 'solid',
                  borderTopColor: GRAY4,
                  borderTopWidth: 1,
                }}
              >
                <Button
                  type="link"
                  disabled={
                    !userId ||
                    !hasPermission(actionPermissions.userGroup.userAddress)
                  }
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t('users.add/editAddress.addTitle')}
                </Button>
              </Space>
            </>
          );
        }}
      >
        {addressData.map((address) => (
          <Select.Option
            key={address.addressId}
            value={address.addressId}
            address={address}
          >
            {/* <div>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Text type="secondary">
                  {t('users.userAddressColumns.consignee')}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {t('users.userAddressColumns.address')}
                </Typography.Text>
              </span>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Space>
                  <Typography>{address.consignee}</Typography>
                  {address.isDefaultAddress && (
                    <Typography.Text style={{ color: ORANGE1 }}>
                      {` - ${t('users.userAddressColumns.default')}`}
                    </Typography.Text>
                  )}
                </Space>
                <Typography>{address.address}</Typography>
              </span>
            </div> */}
            <Space direction="horizontal">
              <Space>
                <Typography>[{address.consignee}]</Typography>
                {address.isDefaultAddress && (
                  <Typography.Text style={{ color: ORANGE1 }}>
                    {` - ${t('users.userAddressColumns.default')}`}
                  </Typography.Text>
                )}
              </Space>
              <Typography>{address.address}</Typography>
            </Space>
          </Select.Option>
        ))}
      </Select>
      <UserAddressModal
        visible={showModal}
        setVisible={setShowModal}
        refresh={() => getUserAddressData()}
        userId={userId}
      />
    </>
  );
};

export default UserAddressDropdown;
