/**Order Analysis */
export const DEFAULT_REGION_ID = [1, 2, 3];
/** Canada/BC/Greater Vancouver, Kai want me to set this if you are reading this Nico */

/**Order Distribution  */
export const ORDER_DISTRI_TYPE = {
  payment: `PAYMENT`,
  district: `DISTRICT`,
  platform: 'PLATFORM',
  tipsFee: 'TIPS_FEE',
  shipping: 'SHIPPING',
  payStatus: 'PAY_STATUS',
  amount: 'AMOUNT',
  hours: 'HOURS',
  days: 'DAYS',
};

/**Order Claim Distribution  */
export const ORDER_CLAIM_DISTRI_TYPE = {
  claimType: `CLAIM_TYPE`,
  refundMethod: `REFUND_METHOD`,
};

/**Goods Distribution */
export const GOODS_DISTRI_TYPE = {
  goodsTag: `GOODS_TAG`,
  category: `CATEGORY`,
  department: 'DEPARTMENT',
};

/**Home Analysis Period */
export const PERIOD = {
  month: `MONTH`,
  year: `YEAR`,
};
