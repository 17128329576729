import {
  Button,
  Col,
  Empty,
  Form,
  Image,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { TaskData } from '../../../types';
import { hasPermission } from '../../../utils/hasPermission';

type TaskOrderInfoProps = {
  taskInfo?: TaskData;
};

const TaskOrderInfo = ({ taskInfo }: TaskOrderInfoProps) => {
  const { t } = useTranslation();
  const { addTab } = useTab();

  return taskInfo && taskInfo.order ? (
    <Form layout="vertical">
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {t('sortationSystem.taskDetail.orderInfo')}
          </Typography.Text>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={`${t('order.orderDetail.orderSn')}: `}
          >
            <Button
              style={{ padding: 0 }}
              type="link"
              size="small"
              disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
              onClick={() => {
                addTab(
                  '',
                  `${dashboardRoute.order.detail}?order_id=${taskInfo.order.orderId}`
                );
              }}
            >
              {taskInfo.order.orderSn}
            </Button>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.seller')}: `}
            style={{ marginBottom: 0 }}
          >
            <Button
              type="link"
              style={{ padding: 0 }}
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
              }
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.sellers.detail}?seller_id=${
                    taskInfo.order.seller ? taskInfo.order.seller.sellerId : ''
                  }`
                )
              }
            >
              {taskInfo.order.seller ? taskInfo.order.seller.shopName : ''}
            </Button>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.user')}: `}
            style={{ marginBottom: 0 }}
          >
            <Button
              type="link"
              style={{ padding: 0 }}
              disabled={!hasPermission(actionPermissions.userGroup.userView)}
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.users.detail}?user_id=${
                    taskInfo.order.user ? taskInfo.order.user.userId : ''
                  }`
                )
              }
            >
              {taskInfo.order.user ? taskInfo.order.user.userName : ''}
            </Button>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('sortationSystem.taskDetail.mobilePhone')}: `}
            style={{ marginBottom: 0 }}
          >
            {taskInfo.order.user && (
              <Typography.Text>
                {taskInfo.order.user.mobilePhone}
              </Typography.Text>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            style={{ marginBottom: 0 }}
            label={`${t(
              'sortationSystem.taskListColumns.orderShippingLabel'
            )}:`}
          >
            <Space>
              {taskInfo.order.route && taskInfo.order.route.routeName && (
                <Typography.Text>
                  {taskInfo.order.route.routeName}
                </Typography.Text>
              )}
              {taskInfo.order.route && taskInfo.order.route.sequenceLabel && (
                <Typography.Text>
                  {taskInfo.order.route.sequenceLabel}
                </Typography.Text>
              )}
              {taskInfo.order.orderShippingLabel && (
                <Tag>{taskInfo.order.orderShippingLabel}</Tag>
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.consignee')}: `}
            style={{ marginBottom: 0 }}
          >
            {taskInfo.order.orderAddress && (
              <Typography.Text>
                {taskInfo.order.orderAddress.consignee}
              </Typography.Text>
            )}
            {taskInfo.order.user && taskInfo.order.user.specialComment && (
              <Tag
                color={taskInfo.order.user.specialComment.tagColor}
                style={{ marginLeft: 10 }}
              >
                {taskInfo.order.user.specialComment.description}
              </Tag>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.consigneeMobile')}: `}
            style={{ marginBottom: 0 }}
          >
            {taskInfo.order.orderAddress && (
              <Typography.Text>
                {taskInfo.order.orderAddress.mobile}
              </Typography.Text>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item
            label={`${t('order.orderDetail.address')}: `}
            style={{ marginBottom: 0 }}
          >
            {taskInfo.order.orderAddress && (
              <Typography.Text>
                {taskInfo.order.orderAddress.address}
              </Typography.Text>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8} xl={6}>
          <Form.Item noStyle>
            {taskInfo.order.specialUserIcon && (
              <Image src={taskInfo.order.specialUserIcon} width={70} />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('sortationSystem.taskDetail.orderInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskOrderInfo;
