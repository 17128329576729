import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Switch,
  TreeSelect,
} from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminAction, OpenApiData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { generateKey } from '../../utils/helperFunction';

type OpenApiModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  info?: OpenApiData;
  callBack?: () => void;
};

const OpenAPIModal = ({
  visible,
  setVisible,
  info,
  callBack,
}: OpenApiModalProps) => {
  const { t } = useTranslation();
  const profile = JSON.parse(localStorage.getItem('profile') || '');
  const [adminActions, setAdminActions] = useState<DataNode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  // Fetches action list
  const getActionList = useCallback(() => {
    /**
     * Reconstruct the data to be used in TreeSelect
     *
     * @param  data        Array of all the Admin Actions
     * @return DataNode[]  A reformated Version of Admin Actions
     */
    const makeTreeData = (data: AdminAction[] = []) => {
      let treeData: DataNode[] = [];
      for (let node of data) {
        let treeNode: DataNode = {};
        treeNode.title = node.description;
        treeNode.value = node.actionCode;
        treeNode.key = node.actionCode;
        if (node.adminAction && node.adminAction.length) {
          treeNode.children = makeTreeData(node.adminAction);

          treeNode.checkable = treeNode.children.every(
            (child) => child.disableCheckbox === true
          )
            ? false
            : true;
        } else {
          treeNode.isLeaf = true;
          treeNode.disableCheckbox = profile.actionList
            ? profile.actionList === 'all' ||
              profile.actionList.indexOf(node.actionCode) !== -1
              ? false
              : true
            : true;
        }
        treeData.push(treeNode);
      }
      return treeData;
    };

    getDataWithAuthToken('admin/action/list')
      .then((response) => {
        if (response && response.goodStatus) {
          setAdminActions(makeTreeData(response.data.list));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t, profile]);

  const onConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        setIsLoading(true);
        postDataWithAuthToken(info ? 'open_api/edit' : 'open_api/add', {
          ...values,
          isEnabled: values.isEnabled || false,
          actionCode: values.actionCode ? values.actionCode.join(',') : '',
          id: info ? info.id : undefined,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              alertMessage(
                'success',
                info
                  ? t('settings.alerts.openApiEdited')
                  : t('settings.alerts.openApiAdded')
              );
              callBack && callBack();
              onClose();
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (!adminActions.length) getActionList();
    }
  }, [visible, form, getActionList, adminActions]);

  return (
    <Modal
      visible={visible}
      okText={t('general.ok')}
      cancelText={t('general.close')}
      okButtonProps={{ loading: isLoading }}
      onOk={onConfirm}
      onCancel={onClose}
      title={
        info
          ? `${t('settings.add/editOpenApi.editTitle')} ${info.apiName}`
          : t('settings.add/editOpenApi.addTitle')
      }
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          initialValues={
            info
              ? {
                  ...info,
                  actionCode:
                    info.actionCode === 'all'
                      ? adminActions.map((item) => item.key)
                      : info.actionCode
                      ? info.actionCode.split(',')
                      : [],
                }
              : {}
          }
          layout="vertical"
        >
          <Form.Item
            label={t('settings.openApi.apiName')}
            name="apiName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('settings.openApi.apiCode')}
            name="apiCode"
            rules={[
              { required: true, min: 3, max: 25 },
              {
                pattern: /^[\w]*$/,
                message: 'Only accept letters, numbers and underscore "_"',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row
            align="bottom"
            justify="space-between"
            gutter={[0, 12]}
            style={{ marginBottom: 24 }}
          >
            <Col span={24} sm={19}>
              <Form.Item
                label={t('settings.openApi.apiKey')}
                name="apiKey"
                rules={[
                  { required: true, min: 10, max: 50 },
                  {
                    pattern: /^[A-Za-z\d]*$/,
                    message: 'Only accept letters and numbers',
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      apiKey: generateKey(32),
                    });
                  }}
                >
                  {t('settings.add/editOpenApi.generateApiKey')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('settings.openApi.isEnabled')}
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item name="actionCode" label={t('admin.add/editAdmin.actions')}>
            <TreeSelect
              treeData={adminActions}
              treeCheckable
              placeholder={t('admin.add/editRole.actionPermission')}
              allowClear
              showSearch={false}
              maxTagCount={10}
              treeDefaultExpandedKeys={
                info && info.actionCode.split(',')
                  ? info.actionCode.split(',').includes('all')
                    ? adminActions.map((action) => action.value as string)
                    : info.actionCode.split(',')
                  : undefined
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OpenAPIModal;
