import React from 'react';

interface MyProps {
  style?: React.CSSProperties;
}

const Container = (props: React.PropsWithChildren<MyProps>) => {
  return (
    <div style={{ padding: '0 24px 24px', ...props.style }}>
      {props.children}
    </div>
  );
};

export default Container;
