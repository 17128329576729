import { BorderlessTableOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Row,
  Space,
  Typography,
} from 'antd';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { UserListData, Viewport } from '../../../types';
import UserFormItem from '../addOrderSections/UserFormItem';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';

type UsersFormProps = {
  form: FormInstance<any>;
  selectedUser?: UserListData;
  setSelectedUser: React.Dispatch<SetStateAction<UserListData | undefined>>;
  setViewport: React.Dispatch<SetStateAction<Viewport | undefined>>;
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
};

const UsersForm = ({
  form,
  selectedUser,
  setSelectedUser,
  setViewport,
  formData,
  setFormData,
}: UsersFormProps) => {
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      initialValues={{
        userId: selectedUser ? selectedUser.userId : undefined,
      }}
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.add/editOrder.userInfo')}
        </Space>
      </Divider>
      {/**用户信息 */}
      {selectedUser ? (
        <>
          <Row gutter={[24, 8]}>
            <Col span={12} md={12} lg={6}>
              <Form.Item
                label={`${t('users.userListColumns.id')}: `}
                style={{ marginBottom: 0 }}
              >
                <Space>
                  <Typography.Text>{selectedUser.userId}</Typography.Text>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12} md={12} lg={6}>
              <Form.Item
                label={`${t('users.userListColumns.username')}: `}
                style={{ marginBottom: 0 }}
              >
                <Space>
                  <Button
                    disabled={
                      !hasPermission(actionPermissions.userGroup.userView)
                    }
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                      // open user detail in new tab
                      window.open(
                        `${window.location.origin}${dashboardRoute.users.detail}?user_id=${selectedUser.userId}`
                      );
                    }}
                  >
                    {selectedUser.userName}
                  </Button>
                  {/* <Typography.Text>{selectedUser.userName}</Typography.Text> */}
                </Space>
              </Form.Item>
            </Col>
            <Col span={12} md={12} lg={6}>
              <Form.Item
                label={`${t('users.userListColumns.nickname')}: `}
                style={{ marginBottom: 0 }}
              >
                <Space>
                  <Typography.Text>{selectedUser.nickName}</Typography.Text>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12} md={12} lg={6}>
              <Form.Item
                label={`${t('users.userListColumns.mobilePhone')}: `}
                style={{ marginBottom: 0 }}
              >
                <Space>
                  <Typography.Text>{selectedUser.mobilePhone}</Typography.Text>
                </Space>
              </Form.Item>
            </Col>
            <Col span={6} offset={18}>
              <Button
                onClick={() => {
                  setSelectedUser(undefined);
                }}
              >
                {t('order.add/editOrder.changeUser')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <UserFormItem
          userId={undefined}
          isAnonymousUser={false}
          setSelectedUser={setSelectedUser}
          setViewport={setViewport}
          form={form}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </Form>
  );
};

export default UsersForm;
