import { PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Grid,
  Typography,
  Table,
  Button,
  Space,
  Popconfirm,
  Switch,
  Tag,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import SellerModal from '../../components/sellers/SellerModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { dashboardRoute } from '../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import { SellerDetailData, FontSizeType, SellerData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

const SellerList = () => {
  const { addTab } = useTab();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [sellerData, setSellerData] = useState<SellerData[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<
    SellerDetailData | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys = [
    'sellerId',
    'shopName',
    'shopTitle',
    'shopKeyword',
    'shopOpenStatus',
    'reviewGoodsStatus',
    'isSelfRun',
    'reviewStatus',
    'reviewContent',
    'sortOrder',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SellerData[]>([]);
  const columns: ColumnsType<SellerData> = [
    {
      title: setFont(t('sellers.sellerListColumns.sellerId'), fontSize),
      key: 'sellerId',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      sorter: (a: SellerData, b: SellerData) => compare(a.sellerId, b.sellerId),
      render: (record: SellerData) => (
        <Button
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            addTab(
              ``,
              `${dashboardRoute.sellers.detail}?seller_id=${record.sellerId}`
            );
          }}
        >
          {record.sellerId}
        </Button>
      ),
    },
    {
      title: setFont(t('sellers.sellerListColumns.shopName'), fontSize),
      key: 'shopName',
      dataIndex: 'shopName',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
      sorter: (a: SellerData, b: SellerData) =>
        compare(a.shopName ? a.shopName : '', b.shopName ? b.shopName : ''),
    },
    {
      title: setFont(t('sellers.sellerListColumns.shopTitle'), fontSize),
      key: 'shopTitle',
      dataIndex: 'shopTitle',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
      sorter: (a: SellerData, b: SellerData) =>
        compare(a.shopTitle ? a.shopTitle : '', b.shopTitle ? b.shopTitle : ''),
    },
    {
      title: setFont(t('sellers.sellerListColumns.shopKeyword'), fontSize),
      key: 'shopKeyword',
      dataIndex: 'shopKeyword',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
      sorter: (a: SellerData, b: SellerData) =>
        compare(
          a.shopKeyword ? a.shopKeyword : '',
          b.shopKeyword ? b.shopKeyword : ''
        ),
    },
    {
      title: setFont(t('sellers.sellerListColumns.shopOpenStatus'), fontSize),
      key: 'shopOpenStatus',
      dataIndex: 'shopOpenStatus',
      width: 120,
      render: (value: boolean, record: SellerData) => (
        <Switch
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          checked={value}
          onChange={(checked) =>
            updateSellerList('shopOpenStatus', checked, record)
          }
        />
      ),
    },
    {
      title: setFont(
        t('sellers.sellerListColumns.reviewGoodsStatus'),
        fontSize
      ),
      key: 'reviewGoodsStatus',
      dataIndex: 'reviewGoodsStatus',
      width: 120,
      render: (value: boolean, record: SellerData) => (
        <Switch
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          checked={value}
          onChange={(checked) =>
            updateSellerList('reviewGoodsStatus', checked, record)
          }
        />
      ),
    },
    {
      title: setFont(t('sellers.sellerListColumns.isSelfRun'), fontSize),
      key: 'isSelfRun',
      dataIndex: 'isSelfRun',
      width: 120,
      render: (value: boolean, record: SellerData) => (
        <Switch
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          checked={value}
          onChange={(checked) => updateSellerList('isSelfRun', checked, record)}
        />
      ),
    },
    {
      title: setFont(
        t('sellers.sellerListColumns.reviewStatus.title'),
        fontSize
      ),
      key: 'reviewStatus',
      width: 140,
      dataIndex: 'reviewStatus',
      render: (status: string, record: SellerData) =>
        record.reviewStatus && (
          <Tag
            style={{ fontSize: fontSize }}
            color={record.reviewStatus.tagColor}
          >
            {record.reviewStatus.description}
          </Tag>
        ),
    },
    {
      title: setFont(t('sellers.sellerListColumns.reviewContent'), fontSize),
      key: 'reviewContent',
      dataIndex: 'reviewContent',
      width: 120,
      render: (text: string) => setFont(text ? text : '', fontSize),
      sorter: (a: SellerData, b: SellerData) =>
        compare(
          a.reviewContent ? a.reviewContent : '',
          b.reviewContent ? b.reviewContent : ''
        ),
    },
    {
      title: setFont(t('sellers.sellerListColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      render: (text: string) => setFont(text ? text : '', fontSize),
      sorter: (a: SellerData, b: SellerData) =>
        compare(a.sortOrder ? a.sortOrder : '', b.sortOrder ? b.sortOrder : ''),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: SellerData) => (
        <Space>
          <Button
            disabled={
              !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
            }
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() =>
              addTab(
                ``,
                `${dashboardRoute.sellers.detail}?seller_id=${record.sellerId}`
              )
            }
          >
            {t('actionsColumn.view')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeleteSeller(record.sellerId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.sellerGroup.sellerMerchantDrop)
            }
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerMerchantDrop)
              }
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const updateSellerList = async (
    key: string,
    value: boolean,
    status: SellerData
  ) => {
    postDataWithAuthToken('seller/edit', {
      sellerId: status.sellerId,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getSellerData();
            alertMessage('success', t('sellers.alerts.sellerEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Delete seller by ID
  const handleDeleteSeller = async (sellerId: number) => {
    try {
      const response = await postDataWithAuthToken('seller/delete', {
        sellerId: sellerId,
      });
      if (response && response.goodStatus) {
        getSellerData();
        alertMessage('success', t('sellers.alerts.sellerDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  //Return and set the seller list data for the seller list table
  const getSellerData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('seller/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setSellerData(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, page, pageSize, keyword]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getSellerData();
  }, [getSellerData]);

  return (
    <Container>
      {fourZeroThree ? (
        <FourZeroThree />
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('sellers.sellerList')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedSeller(undefined);
                  setShowModal(true);
                }}
                disabled={
                  !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
                }
              >
                {t('sellers.add/editSeller.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getSellerData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchSeller')}
            rows={sellerData}
            exportConfig={{ fileName: 'SELLER_LIST' }}
          />
          <Table<SellerData>
            dataSource={sellerData}
            rowKey={(seller) => seller.sellerId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={isLoading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: SellerData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'SELLER_LIST' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <SellerModal
        visible={showModal}
        setVisible={setShowModal}
        seller={selectedSeller}
        refresh={() => getSellerData()}
      />
    </Container>
  );
};

export default SellerList;
