import { Typography } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { SellerAdminData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';

type SellerAdminDropdownProps = {
  disabled?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const SellerAdminDropdown = ({
  disabled,
  initialValue,
  onChange,
}: SellerAdminDropdownProps) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [sellerAdminList, setSellerAdminList] = useState<
    Array<SellerAdminData>
  >([]);
  const [currentPageSellerAdminID, setCurrentPageSellerAdminID] = useState(2);
  const [totalPageSellerAdminID, setTotalPageSellerAdminID] = useState(2);
  /**
   * Sends a request and returns the AdminData based on the typed keywords
   */
  const getSellerAdminData = (value?: string) => {
    getDataWithAuthToken('seller/admin_user/list', {
      params: {
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setSellerAdminList(response.data.list);
          setCurrentPageSellerAdminID(2);
          setTotalPageSellerAdminID(response.data.totalPage);
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Makes a new request to get new list of admins
   * When the user scrolls to the bottom of the dropdown box
   */
  const fetchOnScrollSellerAdminID = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (currentPageSellerAdminID > totalPageSellerAdminID) return;
      getDataWithAuthToken('seller/admin_user/list', {
        params: {
          page: currentPageSellerAdminID,
          keyword: searchText.adminId || undefined,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setSellerAdminList((prev) => [...prev, ...response.data.list]);
          setCurrentPageSellerAdminID((prev) => prev + 1);
          setTotalPageSellerAdminID(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      disabled={
        !hasPermission(actionPermissions.sellerGroup.sellerPrivateMerchants) ||
        disabled
      }
      placeholder={t('searchPlaceholders.searchUsernameEmail')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={fetchOnScrollSellerAdminID}
      onFocus={() => {
        if (!sellerAdminList.length) getSellerAdminData();
      }}
      showSearch
      allowClear
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, adminId: '' }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => getSellerAdminData(value), EXTENDED_TIMEOUT)
        );
      }}
      onChange={handleOnChange}
      optionLabelProp="key"
      filterOption={false}
    >
      {sellerAdminList.map((admin) => (
        <Select.Option
          key={`${admin.adminUserName} (${admin.adminId})`}
          value={String(admin.adminId)}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('sellers.adminListColumns.id')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('sellers.adminListColumns.adminName')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('sellers.adminListColumns.sellerName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{admin.adminId}</Typography>
              <Typography>{admin.adminUserName}</Typography>
              <Typography>{admin.sellerName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SellerAdminDropdown;
