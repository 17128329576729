import {
  EyeOutlined,
  MinusCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Descriptions,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Typography,
} from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMapGL, { Marker } from 'react-map-gl';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_DEFAULT,
  REVIEW_STATUS_FAILED,
} from '../../constants/generalConstants';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import {
  FALLBACK_IMG,
  MAP_MARKER_ICON,
  MAP_MARKER_SIZE,
} from '../../constants/styles';
import {
  EXTENDED_TIMEOUT,
  MEDIUM_FONT_SIZE,
  SHORTEST_TIMEOUT,
} from '../../constants/systemConstants';
import {
  BasicEnumInfoType,
  SellerDetailData,
  SellerGradeData,
  Viewport,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import PhotoGalleryModal from '../PhotoGalleryModal';
import RegionTreeSelect from '../settings/common/RegionTreeSelect';
import UsersDropdown from '../users/common/UsersDropdown';

type SellerModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  seller?: SellerDetailData;
  refresh?: Function;
};

const SellerModal = ({
  visible,
  setVisible,
  seller,
  refresh,
}: SellerModalProps) => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isSettled, setIsSettled] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [addresses, setAddresses] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [viewport, setViewport] = useState<Viewport>();
  const [shopLogo, setShopLogo] = useState({
    photo: '',
    photoPath: '',
  });
  const [thumbLogo, setThumbLogo] = useState({
    photo: '',
    photoPath: '',
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [photoRef, setPhotoRef] = useState('');
  const [grades, setGrades] = useState<SellerGradeData[]>([]);
  const [businessTypes, setBusinessTypes] = useState<BasicEnumInfoType[]>([]);
  const [secondStatus, setSecondStatus] = useState<boolean>();
  const [allRegionIds, setAllRegions] = useState<string[]>([]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   *  Makes a requests to get the list of business types
   */
  const getBusinessTypes = useCallback(() => {
    getDataWithAuthToken('seller/business_type')
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus)
            setBusinessTypes(response.data.list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /* On first render, grab and load the current state of the variables */
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      setSelectedAddress(seller);
      setTimeout(() => {
        setViewport({
          longitude: Number(seller?.longitude) || 0,
          latitude: Number(seller?.latitude) || 0,
          zoom: 15,
        });
      }, SHORTEST_TIMEOUT);
      setShopLogo({
        photo: seller ? seller.shopLogo : '',
        photoPath: seller ? seller.shopLogoPath : '',
      });
      setThumbLogo({
        photo: seller ? seller.thumbLogo : '',
        photoPath: seller ? seller.thumbLogoPath : '',
      });
      setSecondStatus(
        seller?.config.reviewStatus.code === REVIEW_STATUS_FAILED
      );
      getBusinessTypes();
      setIsSettled(
        seller && seller.config ? seller.config.isSettlePercent : false
      );
    }
  }, [form, visible, seller, getBusinessTypes]);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.setFieldsValue({
        brandLogo: shopLogo.photo,
        brandBackgroundImg: thumbLogo.photo,
      });
    }
  }, [shopLogo, thumbLogo, visible, form]);

  /**
   *  Makes a request whether it is an edit or add request after clicking Ok
   */
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);
        postDataWithAuthToken(seller ? 'seller/edit' : 'seller/add', {
          ...values,
          shopLogo: shopLogo
            ? shopLogo.photoPath
              ? shopLogo.photoPath
              : undefined
            : undefined,
          shopLogoPath: shopLogo
            ? shopLogo.photoPath
              ? shopLogo.photoPath
              : undefined
            : undefined,
          thumbLogo: thumbLogo
            ? thumbLogo.photoPath
              ? thumbLogo.photoPath
              : undefined
            : undefined,
          thumbLogoPath: thumbLogo
            ? thumbLogo.photoPath
              ? thumbLogo.photoPath
              : undefined
            : undefined,
          sellerId: seller ? seller.sellerId : undefined,
          latitude: selectedAddress
            ? selectedAddress.latitude
              ? selectedAddress.latitude
              : 0
            : 0,
          longitude: selectedAddress
            ? selectedAddress.longitude
              ? selectedAddress.longitude
              : 0
            : 0,
          showRegionId: values.showRegionId
            ? allRegionIds && values.showRegionId.length === allRegionIds.length
              ? 'all'
              : values.showRegionId.join()
              ? values.showRegionId.join()
              : undefined
            : undefined,
        })
          .then((response) => {
            if (response) {
              if (response.goodStatus) {
                if (isSubscribed.current) setVisible(false);
                setIsLoading(false);
                setVisible(false);
                if (refresh) refresh();
                alertMessage(
                  'success',
                  seller
                    ? t('sellers.alerts.sellerEdited')
                    : t('sellers.alerts.sellerAdded')
                );
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) {
      setVisible(false);
    }
  };

  /**
   *  Makes a requests to Mapbox to autofill the address in the input box while the user is typing
   */
  const onSearchAddress = (value: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);
    if (value && value.length > 5) {
      setTypingTimeout(
        setTimeout(() => {
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=ca&language=en&types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
            )
            .then((result) => {
              if (result && result.status === 200 && result.data) {
                if (isSubscribed.current) setAddresses(result.data.features);
              } else alertMessage('error', t('users.alerts.errorOccurred'));
            })
            .catch((err) => {
              console.log(err);
            });
        }, EXTENDED_TIMEOUT)
      );
    } else setAddresses([]);
  };

  /**
   * @param value The address that was selected by user
   */
  const onSelectAddress = (value: any) => {
    let addressObj: { center: number[] } = JSON.parse(value);

    if (visible && isSubscribed.current) {
      setSelectedAddress((prev: SellerDetailData) => ({
        ...prev,
        longitude: addressObj.center[0],
        latitude: addressObj.center[1],
      }));
      setViewport({
        longitude: addressObj.center[0],
        latitude: addressObj.center[1],
        zoom: 15,
      });
    }
  };

  /**
   *  Makes a requests to get the list of grade objects
   */
  const getGradeListData = () => {
    getDataWithAuthToken('seller/grade/list')
      .then((response) => {
        if (isSubscribed.current) {
          if (response && response.goodStatus) setGrades(response.data.list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      title={
        seller
          ? `${t('sellers.add/editSeller.editTitle')} ${seller.sellerId}`
          : t('sellers.add/editSeller.addTitle')
      }
      visible={visible}
      okText={t('sellers.add/editSeller.ok')}
      cancelText={t('sellers.add/editSeller.cancel')}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={
            seller
              ? seller.config && {
                  ...seller,
                  sortOrder: seller.config.sortOrder
                    ? seller.config.sortOrder
                    : 100,
                  businessType: seller.config.businessType
                    ? seller.config.businessType
                    : `NORMAL`,
                  useSharePayment: seller.config.useSharePayment
                    ? seller.config.useSharePayment
                    : undefined,
                  shopOpenStatus: seller.config.shopOpenStatus
                    ? seller.config.shopOpenStatus
                    : undefined,
                  isSelfRun: seller.config.isSelfRun
                    ? seller.config.isSelfRun
                    : undefined,
                  sellerShopGradeId:
                    seller.config && seller.config.sellerShopGrade
                      ? seller.config.sellerShopGrade.gradeId
                      : undefined,
                  reviewStatus: getDashboardStyle().showReviewStatus
                    ? seller.config.reviewStatus
                      ? seller.config.reviewStatus.code
                      : REVIEW_STATUS_DEFAULT
                    : undefined,
                  reviewContent: getDashboardStyle().showReviewStatus
                    ? seller.config.reviewContent
                      ? seller.config.reviewContent
                      : undefined
                    : undefined,
                  reviewGoodsStatus: seller.config.reviewGoodsStatus
                    ? seller.config.reviewGoodsStatus
                    : undefined,
                  showRegionId: seller.showRegionId
                    ? seller.showRegionId === 'all'
                      ? allRegionIds
                      : seller.showRegionId.split(',')
                    : [],
                  tel: seller.tel ? seller.tel : '',
                  isSettlePercent: seller.config.isSettlePercent,
                  settlePercentValue:
                    seller.config.isSettlePercent &&
                    seller.config.settlePercentValue
                      ? seller.config.settlePercentValue
                      : 100,
                }
              : {
                  reviewStatus: REVIEW_STATUS_DEFAULT,
                  businessType: `NORMAL`,
                  showRegionId: [],
                  sortOrder: 100,
                  isSettlePercent: false,
                  settlePercentValue: 100,
                }
          }
        >
          <Tabs defaultActiveKey="basic">
            <TabPane
              style={{ height: 'auto' }}
              tab={t('sellers.add/editSeller.basic')}
              key="basic"
            >
              <Form.Item
                name="userId"
                label={t('sellers.add/editSeller.userId')}
                rules={[
                  {
                    required: seller ? false : true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
                style={{ display: seller ? 'none' : '' }}
              >
                <UsersDropdown />
              </Form.Item>
              <Form.Item
                name="shopName"
                label={t('sellers.add/editSeller.shopName')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="shopTitle"
                label={t('sellers.add/editSeller.shopTitle')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessType"
                label={t('sellers.add/editSeller.businessType.title')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Radio.Group>
                  {!!businessTypes.length &&
                    businessTypes.map((type) => {
                      return (
                        <Radio key={type.code} value={type.code}>
                          {type.description}
                        </Radio>
                      );
                    })}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="email"
                label={t('sellers.add/editSeller.email')}
                rules={[
                  {
                    type: 'email',
                    message: t('general.inputError.pleaseInputValidEmail'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="wechat"
                label={t('sellers.add/editSeller.wechat')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="tel"
                label={t('sellers.add/editSeller.tel')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="shopDesc"
                label={t('sellers.add/editSeller.shopDesc')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="remark"
                label={t('sellers.add/editSeller.remark')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="shopLogo"
                label={t('sellers.add/editSeller.shopLogo')}
              >
                <Space size="large" align="start">
                  <Space align="start">
                    <Image
                      width={50}
                      src={shopLogo.photoPath}
                      preview={{ mask: <EyeOutlined /> }}
                      fallback={FALLBACK_IMG}
                    />
                    {shopLogo.photoPath && (
                      <MinusCircleOutlined
                        style={{ color: ' #ff0f0f' }}
                        onClick={() =>
                          setShopLogo({ photo: '', photoPath: '' })
                        }
                      />
                    )}
                  </Space>
                  <Button
                    onClick={() => {
                      setPhotoRef('logo');
                      setModalVisible(true);
                      setFirstLoad(true);
                    }}
                  >
                    {t('sellers.add/editSeller.photoGallery')}
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item
                name="thumbLogo"
                label={t('sellers.add/editSeller.thumbLogo')}
              >
                <Space size="large" align="start">
                  <Space align="start">
                    <Image
                      width={50}
                      src={thumbLogo.photoPath}
                      preview={{ mask: <EyeOutlined /> }}
                      fallback={FALLBACK_IMG}
                    />
                    {thumbLogo.photoPath && (
                      <MinusCircleOutlined
                        style={{ color: ' #ff0f0f' }}
                        onClick={() =>
                          setThumbLogo({ photo: '', photoPath: '' })
                        }
                      />
                    )}
                  </Space>
                  <Button
                    onClick={() => {
                      setFirstLoad(true);
                      setPhotoRef('backgroundImg');
                      setModalVisible(true);
                    }}
                  >
                    {t('sellers.add/editSeller.photoGallery')}
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item
                name="showRegionId"
                label={t('sellers.add/editSeller.showRegionId')}
              >
                <RegionTreeSelect
                  initialValue={seller?.showRegionId}
                  setAllRegionIds={setAllRegions}
                />
              </Form.Item>
              <Form.Item
                name="shopAddress"
                label={t('sellers.add/editSeller.shopAddress')}
              >
                <AutoComplete
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  onChange={onSearchAddress}
                  onSelect={(value: string, option: any) =>
                    onSelectAddress(option.key)
                  }
                >
                  {addresses.map((address: { place_name: string }) => (
                    <AutoComplete.Option
                      key={JSON.stringify(address)}
                      value={address.place_name}
                    >
                      {address.place_name}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <ReactMapGL
                {...viewport}
                width="100%"
                height={400}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onViewportChange={setViewport}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              >
                {!!(
                  selectedAddress &&
                  selectedAddress.latitude &&
                  selectedAddress.longitude
                ) ? (
                  <Marker
                    latitude={Number(selectedAddress.latitude)}
                    longitude={Number(selectedAddress.longitude)}
                  >
                    <svg
                      height={MAP_MARKER_SIZE}
                      viewBox="0 0 24 24"
                      style={{
                        fill: '#d00',
                        stroke: 'none',
                        transform: `translate(${
                          -MAP_MARKER_SIZE / 2
                        }px,${-MAP_MARKER_SIZE}px)`,
                      }}
                    >
                      <path d={MAP_MARKER_ICON} />
                    </svg>
                  </Marker>
                ) : (
                  <></>
                )}
              </ReactMapGL>
            </TabPane>
            <TabPane
              style={{ height: 'auto' }}
              tab={t('sellers.add/editSeller.advancedConfig')}
              key="config"
            >
              <Form.Item
                name="sellerShopGradeId"
                label={t('sellers.add/editSeller.sellerShopGrade')}
              >
                <Select
                  optionLabelProp="key"
                  onClick={() => getGradeListData()}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder={t('searchPlaceholders.searchSellerGrade')}
                >
                  <Select.Option key={'0'} value={'0'}>
                    {t('general.pleaseSelect')}
                  </Select.Option>

                  {grades.map((grade) => (
                    <Select.Option
                      key={`${grade.gradeName} (${grade.gradeId})`}
                      value={String(grade.gradeId)}
                    >
                      <div>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography.Text type="secondary">
                            {t('sellers.add/editSeller.gradeId')}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            {t('sellers.add/editSeller.gradeName')}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            {t('sellers.add/editSeller.more')}
                          </Typography.Text>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography>{grade.gradeId}</Typography>
                          <Typography>{grade.gradeName}</Typography>
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <Popover
                              getPopupContainer={(trigger) =>
                                trigger.parentElement!
                              }
                              placement="left"
                              content={
                                <Descriptions style={{ width: 225 }}>
                                  <Descriptions.Item
                                    label={t(
                                      'sellers.add/editSeller.isEnabled'
                                    )}
                                    span={3}
                                  >
                                    {grade.isEnabled}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={t(
                                      'sellers.add/editSeller.maxGoodsNumber'
                                    )}
                                    span={3}
                                  >
                                    {grade.maxGoodsNumber}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={t(
                                      'sellers.add/editSeller.gradeDesc'
                                    )}
                                    span={3}
                                  >
                                    {grade.gradeDesc}
                                  </Descriptions.Item>
                                </Descriptions>
                              }
                              trigger={'hover'}
                            >
                              <MoreOutlined
                                style={{ fontSize: MEDIUM_FONT_SIZE }}
                              />
                            </Popover>
                          </span>
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="shopKeyword"
                label={t('sellers.add/editSeller.shopKeyword')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="useSharePayment"
                label={t('sellers.add/editSeller.useSharePayment')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="shopOpenStatus"
                label={t('sellers.add/editSeller.shopOpenStatus')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="reviewGoodsStatus"
                label={t('sellers.add/editSeller.reviewGoodsStatus')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="isSelfRun"
                label={t('sellers.add/editSeller.isSelfRun')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Item
                  style={{ paddingRight: 20 }}
                  name="isSettlePercent"
                  label={t('sellers.add/editSeller.isSettlePercent')}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(e) => {
                      setIsSettled(e);
                    }}
                  />
                </Form.Item>
                {isSettled && (
                  <Form.Item
                    name="settlePercentValue"
                    label={t('sellers.add/editSeller.settlePercentValue')}
                    rules={[
                      {
                        required: true,
                        message: t('general.inputError.pleaseInputAmount'),
                      },
                    ]}
                  >
                    <InputNumber min={1} max={100} />
                  </Form.Item>
                )}
              </div>
              {getDashboardStyle().showReviewStatus && (
                <Form.Item
                  name="reviewStatus"
                  label={t('general.reviewStatus.title')}
                >
                  <Radio.Group
                    onChange={(e) => {
                      e.target.value === REVIEW_STATUS_FAILED
                        ? setSecondStatus(true)
                        : setSecondStatus(false);
                    }}
                  >
                    {Object.keys(REVIEW_STATUS).map((type) => {
                      return (
                        <Radio key={type} value={type}>
                          {t(REVIEW_STATUS[type])}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
              )}
              {getDashboardStyle().showReviewStatus && (
                <Form.Item
                  name="reviewContent"
                  label={t('sellers.add/editSeller.reviewContent')}
                  style={{ display: secondStatus === true ? '' : 'none' }}
                  rules={[
                    {
                      required: secondStatus ? true : false,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                name="sortOrder"
                label={t('sellers.add/editSeller.sortOrder')}
              >
                <InputNumber
                  type="number"
                  min={0}
                  max={50000}
                  placeholder="0-50000"
                />
              </Form.Item>
            </TabPane>
          </Tabs>
        </Form>
      </Spin>
      {firstLoad && (
        <PhotoGalleryModal
          firstLoad={firstLoad}
          visible={modalVisible}
          setVisible={setModalVisible}
          setValue={photoRef === 'logo' ? setShopLogo : setThumbLogo}
          value={photoRef === 'logo' ? shopLogo : thumbLogo}
          supportedMediaType={MEDIA_TYPE.IMAGE}
        />
      )}
    </Modal>
  );
};

export default SellerModal;
