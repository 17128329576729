import { Button, Grid, Modal, Space, Table, Typography } from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import TableToolbar from '../table/TableToolbar';
import PrintModal from '../PrintModal';
import { PrintType } from '../../types';
import { ColumnType } from 'antd/lib/table';

type ExcelModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedExcel: { path: string; name: string; addTime?: string } | undefined;
  setSelectedExcel: React.Dispatch<
    React.SetStateAction<
      { path: string; name: string; addTime?: string } | undefined
    >
  >;
};

const ExcelModal = ({
  visible,
  setVisible,
  selectedExcel,
  setSelectedExcel,
}: ExcelModalProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const isSubscribed = useRef(true);

  const [isLoading, setIsLoading] = useState(false);
  const [excelHeader, setExcelHeader] = useState<string[]>([]);
  const [excelData, setExcelData] = useState<{ [key: string]: any }[]>([]);
  const [columnKeys, setColumnKeys] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const [exportModalVisible, setExportModalVisible] = useState(false);

  const onCancel = () => {
    if (isSubscribed.current) {
      setVisible(false);
      setSelectedExcel(undefined);
      setExcelData([]);
      setColumnKeys([]);
      setSearchKeyword('');
    }
  };

  /**
   * 打开Excel链接 - 转换Json格式，用于表格显示
   */
  const convertExcelToJson = useCallback(async (link: string) => {
    if (isSubscribed.current) setIsLoading(true);
    try {
      const response = await axios.get(link, {
        responseType: 'arraybuffer',
      });

      if (response.status === 200) {
        const data = response.data;
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet is the one you want to read
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const excelDataJson: { [key: string]: any }[] =
          XLSX.utils.sheet_to_json(sheet);

        // Get the header (column names)
        const excelHeader: string[] = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          {
            header: 1,
          }
        )[0] as string[];

        // header with key
        const header = Object.values(excelHeader).map(
          (key, index) => `${key}-${index}`
        );

        setColumnKeys(header);
        setSelectedColumns(header);
        setExcelData(excelDataJson);
        setExcelHeader(excelHeader);
      } else {
        console.error('Error fetching or parsing Excel data');
      }

      if (isSubscribed.current) setIsLoading(false);
    } catch (error) {
      if (isSubscribed.current) setIsLoading(false);
      console.error('Error fetching or parsing Excel data:', error);
    }
  }, []);

  /**
   * 根据搜索关键词，查询Excel Cell内相关关键词
   */
  const filteredData = excelData.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === 'string' &&
        value.toLowerCase().includes(searchKeyword.toLowerCase())
    )
  );

  useEffect(() => {
    if (isSubscribed.current && visible && selectedExcel) {
      convertExcelToJson(selectedExcel.path);
    }
  }, [visible, selectedExcel, convertExcelToJson]);

  // Define columns based on the keys of the first data row
  const columns: ColumnType<{ [key: string]: any }>[] =
    excelHeader.length > 0
      ? Object.values(excelHeader).map((key, index) => {
          return {
            title: (
              <Space
                size={0}
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Typography.Text>{key}</Typography.Text>
              </Space>
            ),
            dataIndex: key,
            // key: `${key}-${index}-${Object.entries(excelData[0])[0]}`,
            key: `${key}-${index}`,
            // width: 200,
            render: (value, record) =>
              Object.keys(record).length === 1 && index === 0 ? (
                // Category Title
                <Typography.Text strong style={{ paddingLeft: 10 }}>
                  {value}
                </Typography.Text>
              ) : (
                // Other table Rows
                <div
                  style={
                    typeof value === 'number'
                      ? {
                          maxWidth: 150,
                          minWidth:
                            // set min width if columns size is less than 6
                            selectedColumns.length < 6 ? 80 : undefined,
                          textAlign: 'center',
                        }
                      : { maxWidth: 550, paddingLeft: 5, paddingRight: 5 }
                  }
                >
                  <Typography.Text style={{ wordBreak: 'break-word' }}>
                    {value}
                  </Typography.Text>
                </div>
              ),
            onCell: (record: any) => {
              // Row - 只有首列有值，First Col 扩大cell，其余Col的colSpan需要为0
              return {
                colSpan:
                  Object.keys(record).length === 1 && index === 0
                    ? excelHeader.length + 1
                    : Object.keys(record).length === 1
                    ? 0
                    : 1,
              };
            },
          };
        })
      : [];

  return (
    <>
      <Modal
        title={t('fileManager.viewFile')}
        maskClosable={false}
        visible={visible}
        footer={null}
        width={screens.lg ? 992 : screens.md ? 768 : undefined}
        onCancel={onCancel}
        bodyStyle={{ padding: '24px 12px' }}
      >
        <TableToolbar
          leftElement={
            <Button
              onClick={() => {
                setExportModalVisible(true);
              }}
            >
              {t('actionsColumn.exportPdf')}
            </Button>
          }
          search={(keyword) => {
            setSearchKeyword(keyword);
          }}
          searchPlaceholder={t('searchPlaceholders.searchKeyword')}
          columns={columns}
          columnKeys={columnKeys}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
        <Table
          loading={isLoading}
          dataSource={filteredData}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          scroll={{ x: 'max-content', y: 600 }}
          pagination={{
            total: filteredData.length,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
          }}
        />
      </Modal>
      <PrintModal<PrintType>
        modalLoading={isLoading}
        mode="excel"
        data={
          {
            excelData: {
              tableData: filteredData,
              tableColumn: columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              ),
              title: selectedExcel?.name,
              header: { addTime: selectedExcel?.addTime },
            },
          } as PrintType
        }
        visible={exportModalVisible}
        setVisible={setExportModalVisible}
      />
    </>
  );
};

export default ExcelModal;
