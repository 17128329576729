import { PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Grid,
  Typography,
  Table,
  Button,
  Space,
  Popconfirm,
  Switch,
  Input,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import GoodsTypeAttrModal from '../../components/goods/GoodsTypeAttrModal';
import GoodsTypeModal from '../../components/goods/GoodsTypeModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
  FontSizeType,
  GoodsTypeAttributeData,
  GoodsTypeData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { useVT } from 'virtualizedtableforantd4';
import { RED1 } from '../../constants/color';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

const GoodsTypeList = () => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [showTypeAttrModal, setShowTypeAttrModal] = useState(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewType, setViewType] = useState<string>('');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  const [editValidator, setEditValidator] = useState<{
    data: GoodsTypeData;
    rowIndex: any;
    colIndex: any;
  }>();
  //Text Components
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  //Data Components
  const [goodsTypeData, setGoodsTypeData] = useState<GoodsTypeData[]>([]);
  const [goodsTypeInfo, setGoodsTypeInfo] = useState<GoodsTypeData>();
  const [goodsTypeAttrInfo, setGoodsTypeAttrInfo] =
    useState<GoodsTypeAttributeData>();
  //Table Components
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys = [
    'goodsSpecTypeId',
    'sellerId',
    'typeName',
    'isEnabled',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((col: string) => {
      return col !== 'sellerId';
    })
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<GoodsTypeData[]>([]);

  const setParentGoodSpecObj = (record: GoodsTypeAttributeData) => {
    goodsTypeData.map((goodSpecObj) => {
      let refArray: GoodsTypeAttributeData[] = goodSpecObj.specTypeAttrList;
      if (
        refArray.findIndex(
          (element: GoodsTypeAttributeData) =>
            element.specAttrId === record.specAttrId
        ) !== -1
      ) {
        setGoodsTypeInfo(goodSpecObj);
      }
      return goodSpecObj;
    });
  };

  const columns: ColumnsType<GoodsTypeData> = [
    {
      title: setFont(t('goods.goodsTypeListColumns.goodsSpecTypeId'), fontSize),
      key: 'goodsSpecTypeId',
      dataIndex: 'goodsSpecTypeId',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      render: (text: string, record: GoodsTypeData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.goodsTypeManage)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setViewType('view');
            setGoodsTypeInfo(record);

            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: GoodsTypeData, b: GoodsTypeData) =>
        compare(a.goodsSpecTypeId, b.goodsSpecTypeId),
    },
    {
      title: setFont(t('goods.goodsTypeListColumns.typeName'), fontSize),
      key: 'typeName',
      dataIndex: 'typeName',
      sorter: (a: GoodsTypeData, b: GoodsTypeData) =>
        compare(a.typeName, b.typeName),
      render: (text: string, record: GoodsTypeData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'typeName' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={text}
              style={{ width: 120 }}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(e) => {
                let target = e.target as HTMLInputElement;
                !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                if (
                  hasPermission(actionPermissions.goodGroup.goodsTypeManage) &&
                  target.value
                )
                  handleOk('typeName', target.value, record);
              }}
            />
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Typography.Text
            style={{
              fontSize: fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'typeName',
              });
            }}
          >
            {text}
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('goods.goodsTypeListColumns.isEnabled'), fontSize),
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      width: 120,
      render: (value: boolean, record: GoodsTypeData) => (
        <Switch
          checked={value}
          onChange={(checked) => {
            if (hasPermission(actionPermissions.goodGroup.goodsTypeManage))
              handleOk('isEnabled', checked, record);
          }}
        />
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 160,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: GoodsTypeData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.goodGroup.attrManage)}
            onClick={() => {
              setGoodsTypeInfo(record);
              setGoodsTypeAttrInfo(undefined);
              setShowTypeAttrModal(true);
            }}
          >
            {t('goods.add/editGoodsTypeAttribute.addTitle')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeleteGoodsType(record.goodsSpecTypeId, 'goodSpec');
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.goodGroup.goodsTypeManage)
            }
          >
            <Button
              danger
              disabled={
                !hasPermission(actionPermissions.goodGroup.goodsTypeManage)
              }
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const innerColumns: ColumnsType<GoodsTypeAttributeData> = [
    {
      title: setFont(t('goods.goodsTypeAttributeList.specAttrId'), fontSize),
      key: 'specAttrId',
      dataIndex: 'specAttrId',
      width: 140,
      render: (text: string, record: GoodsTypeAttributeData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.attrManage)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setParentGoodSpecObj(record);
            setGoodsTypeAttrInfo(record);
            setShowTypeAttrModal(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsTypeAttributeList.specAttrName'), fontSize),
      key: 'specAttrName',
      dataIndex: 'specAttrName',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsTypeAttributeList.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      render: (record: GoodsTypeAttributeData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.goodGroup.attrManage)}
            onClick={() => {
              setParentGoodSpecObj(record);
              setGoodsTypeAttrInfo(record);
              setShowTypeAttrModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() =>
              handleDeleteGoodsType([record.specAttrId], 'goodTypeAttr')
            }
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.goodGroup.goodsTypeManage)
            }
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.goodGroup.goodsTypeManage)
              }
              danger
              type="link"
              size="small"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleOk = (param: string, value: any, record: GoodsTypeData) => {
    postDataWithAuthToken('goods/spec_type/edit', {
      goodsSpecTypeId: record.goodsSpecTypeId,
      typeName: param === 'typeName' && value ? value : undefined,
      isEnabled: param === 'isEnabled' ? value : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.typeEdited'));
          getGoodsTypeData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setEditValidator(undefined);
  };

  const handleDeleteGoodsType = (id: any, type: string) => {
    postDataWithAuthToken(
      type === 'goodSpec'
        ? 'goods/spec_type/delete'
        : 'goods/spec_type/attr/delete_batch',
      {
        goodsSpecTypeId: typeof id === 'number' ? id : undefined,
        specAttrIds: Array.isArray(id) ? id : undefined,
      }
    )
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage(
            'success',
            type === 'goodSpec'
              ? t('goods.alerts.typeDeleted')
              : t('goods.alerts.typeAttrDeleted')
          );
          getGoodsTypeData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setEditValidator(undefined);
  };

  const getGoodsTypeData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/spec_type/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setGoodsTypeData(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, page, pageSize, keyword]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getGoodsTypeData();
  }, [getGoodsTypeData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.goodsTypeList')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.goodGroup.goodsTypeManage)
                }
                onClick={() => {
                  setViewType('add');
                  setGoodsTypeInfo(undefined);
                  setShowModal(true);
                }}
              >
                {t('goods.add/view/editTypeList.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getGoodsTypeData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchGoodsTypes')}
            rows={goodsTypeData}
            exportConfig={{ fileName: 'GOODS_TYPE_LIST' }}
          />
          <Table<GoodsTypeData>
            dataSource={goodsTypeData}
            rowKey={(type) => type.goodsSpecTypeId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            expandable={{
              rowExpandable: (goodsType) =>
                goodsType.specTypeAttrList.length ? true : false,
              expandedRowRender: (goodsType) =>
                hasPermission('attr_view') ? (
                  <Table
                    size={tableSize}
                    dataSource={goodsType.specTypeAttrList}
                    columns={innerColumns}
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    rowKey={(goodsType) => goodsType.specAttrId}
                    style={{ margin: 0 }}
                  />
                ) : (
                  <></>
                ),
              columnWidth: 30,
            }}
            scroll={{ x: 1200, y: 600 }}
            size={tableSize}
            components={vt}
            loading={isLoading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
              },
              current: page,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: GoodsTypeData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'GOODS_TYPE_LIST' } }}
            />
          )}
        </Card>
      )}
      <GoodsTypeModal
        viewType={viewType}
        visible={showModal}
        setVisible={setShowModal}
        goodsTypeInfo={goodsTypeInfo}
        refresh={() => getGoodsTypeData()}
      />
      <GoodsTypeAttrModal
        visible={showTypeAttrModal}
        setVisible={setShowTypeAttrModal}
        goodsTypeAttrInfo={goodsTypeAttrInfo}
        goodsTypeInfo={goodsTypeInfo}
        refresh={() => getGoodsTypeData()}
      />
    </Container>
  );
};

export default GoodsTypeList;
