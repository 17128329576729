import { Form, InputNumber, Modal, Spin, Input, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderClaimData, OrderEnum } from '../../types';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';

import 'react-quill/dist/quill.snow.css';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { REFUND_METHOD } from '../../constants/orderConstants';
import ValueCardDropdown from '../activity/common/ValueCardDropdown';
import CouponDropdown from '../activity/common/CouponDropdown';

type ClaimModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRefund?: OrderClaimData;
  refresh: Function;
  enums?: OrderEnum;
};

const ClaimModal = ({
  visible,
  setVisible,
  selectedRefund,
  refresh,
  enums,
}: ClaimModalProps) => {
  //General components
  const quillRef = useRef<any>(null);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  //Data components
  const [refundMethod, setRefundMethod] = useState<string>('');
  //Text Editor Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  // Sets isSubscribed, quillRef to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
      quillRef.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      form.setFieldsValue({
        finalAmount: selectedRefund?.finalAmount,
        reviewContent: t('order.claimReview.reviewPassed'),
      });
      if (selectedRefund?.refundMethod) {
        setRefundMethod(selectedRefund?.refundMethod.code);
      }
    }
  }, [form, selectedRefund, t, visible]);

  const onClose = () => {
    setRefundMethod('');
    setVisible(false);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(
          setTimeout(() => {
            postDataWithAuthToken('claim/review', {
              ...values,
              claimId: selectedRefund?.id,
            }).then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('order.alerts.claimReviewed'));
                if (refresh) refresh();
                onClose();
              } else {
                if (isSubscribed.current) setLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            });
          }, EXTENDED_TIMEOUT)
        );
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      onOk={onFinish}
      onCancel={onClose}
      bodyStyle={{ height: 'auto' }}
      title={t('order.claimReview.title')}
      visible={visible}
      width={550}
      okButtonProps={{ loading: loading }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={{
            ...selectedRefund,
            finalAmount: selectedRefund?.finalAmount,
            refundMethod: selectedRefund?.refundMethod?.code,
            refundTargetId: selectedRefund?.refundTargetId,
            isPassed: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item /**Final Claim Value*/
            name="finalAmount"
            label={t('order.claimReview.actualAmount')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <InputNumber<string>
              style={{ width: 250 }}
              min="0"
              step="0.01"
              stringMode
            />
          </Form.Item>
          <Form.Item /**Refund Methods*/
            name="refundMethod"
            label={t('order.orderDetail.refundMethod.title')}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 250 }}
              onChange={(value) => {
                setRefundMethod(value);
                form.setFieldsValue({ refundTargetId: undefined });
              }}
            >
              {enums &&
                enums.refundMethod.map((method) => (
                  <Select.Option key={method.code} value={method.code}>
                    {method.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {refundMethod === REFUND_METHOD.COUPON && (
            <Form.Item
              name="refundTargetId"
              label={t('order.orderDetail.coupon')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <CouponDropdown
                sellerId={selectedRefund?.seller?.sellerId}
                initialValue={selectedRefund?.refundTargetId}
              />
            </Form.Item>
          )}
          {refundMethod === REFUND_METHOD.VALUE_CARD && (
            <Form.Item
              name="refundTargetId"
              label={t('order.orderDetail.valueCard')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.empty'),
                },
              ]}
            >
              <ValueCardDropdown
                userId={selectedRefund?.user?.userId}
                sellerId={selectedRefund?.seller.sellerId}
                initialValue={selectedRefund?.refundTargetId}
              />
            </Form.Item>
          )}
          <Form.Item /**isPassed */
            name="isPassed"
            label={t('order.claimReview.isPassed')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
            valuePropName="checked"
          >
            <Switch
              onChange={(value: boolean) => {
                if (value) {
                  form.setFieldsValue({
                    finalAmount: selectedRefund?.finalAmount,
                    reviewContent: t('order.claimReview.reviewPassed'),
                  });
                } else {
                  form.setFieldsValue({
                    finalAmount: '0',
                    reviewContent: '',
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item /**reviewContent */
            name="reviewContent"
            label={t('order.claimReview.reviewContent')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            {<Input style={{ width: 250 }} />}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ClaimModal;
