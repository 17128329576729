import { Button, FormInstance, Popover, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import { REVIEW_STATUS_FAILED } from '../../constants/generalConstants';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { hasPermission } from '../../utils/hasPermission';
import BatchFailedModal from '../BatchFailedModal';
import { onExportToExcel } from '../table/TableExport';

type MismatchGoodsTableFooterToolbarProps = {
  funct: {
    refresh?: Function;
    deleteFunc?: () => void;
    exportConfig?: { fileName: string; url?: string };
  };
  selectedRowKeys?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  form: FormInstance<any>;
  selectedRows: any[];
  columns?: ColumnsType<any>;
};

const MismatchGoodsTableFooterToolbar = ({
  form,
  funct,
  selectedRowKeys,
  setSelectedRowKeys,
  selectedRows,
  columns,
}: MismatchGoodsTableFooterToolbarProps) => {
  //General Components
  const { t } = useTranslation();
  //Visible/Statuses Components
  const [popOverSyncWithPriceVis, setPopOverSyncWithPriceVis] = useState(false);
  const [popOverSyncVis, setPopOverSyncVis] = useState(false);
  const [batchFailedVis, setBatchFailedVis] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  const [batchFailObjs, setBatchFailObjs] = useState<{
    dataType?: 'GOODS' | 'ORDER' | 'TASK';
    data?: any[];
  }>({});

  const sendGoodsBatchRequest = (
    batchRequest: string,
    body?: { [key: string]: any }
  ) => {
    setIsLoading(true);
    setBatchFailedVis(false);
    postDataWithAuthToken(
      batchRequest === 'goods/delete_batch' ? batchRequest : batchRequest,
      {
        goodsIds: selectedRowKeys ? selectedRowKeys : undefined,

        isPurchaseLimit:
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? form.getFieldValue('isPurchaseLimit')
            : undefined,
        purchaseLimitNum:
          form.getFieldValue('purchaseLimitNum') &&
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? form.getFieldValue('purchaseLimitNum')
            : undefined,
        purchaseLimitStartDate:
          form.getFieldValue('purchaseLimitStartDate') &&
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? moment(form.getFieldValue('purchaseLimitStartDate')[0]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        purchaseLimitEndDate:
          form.getFieldValue('purchaseLimitStartDate') &&
          batchRequest === 'goods/edit/batch/purchase_limit'
            ? moment(form.getFieldValue('purchaseLimitStartDate')[1]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        isPromote:
          batchRequest === 'goods/edit/batch/promote'
            ? form.getFieldValue('isPromote')
            : undefined,
        promotePercent:
          form.getFieldValue('promotePercent') &&
          batchRequest === 'goods/edit/batch/promote'
            ? form.getFieldValue('promotePercent')
            : undefined,
        promoteAmount:
          form.getFieldValue('promoteAmount') &&
          batchRequest === 'goods/edit/batch/promote'
            ? parseFloat(form.getFieldValue('promoteAmount'))
            : undefined,
        promoteStartDate:
          form.getFieldValue('promoteDate') &&
          batchRequest === 'goods/edit/batch/promote'
            ? moment(form.getFieldValue('promoteDate')[0]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        promoteEndDate:
          form.getFieldValue('promoteDate') &&
          batchRequest === 'goods/edit/batch/promote'
            ? moment(form.getFieldValue('promoteDate')[1]).format(
                DATE_TIME_FORMAT
              )
            : undefined,
        catId:
          form.getFieldValue('search_catId') &&
          (batchRequest === 'goods/edit/batch/cat' ||
            batchRequest === 'goods/edit/batch/add_extend_cat')
            ? form.getFieldValue('search_catId')[
                form.getFieldValue('search_catId').length - 1
              ]
            : undefined,
        isOnSale:
          batchRequest === 'goods/edit/batch/sale_status'
            ? form.getFieldValue('isOnSale')
            : undefined,
        eventTag:
          form.getFieldValue('eventTag') &&
          batchRequest === 'goods/edit/batch/event'
            ? form.getFieldValue('eventTag')
            : undefined,
        eventTagStatus:
          batchRequest === 'goods/edit/batch/event'
            ? form.getFieldValue('eventTagStatus')
            : undefined,
        goodsTag:
          batchRequest === 'goods/edit/batch/goods_tag'
            ? form.getFieldValue('goodsTag')
            : undefined,
        departmentId:
          batchRequest === 'goods/edit/batch/department'
            ? form.getFieldValue('departmentId')
            : undefined,
        reviewStatus:
          batchRequest === 'goods/edit/batch/review'
            ? form.getFieldValue('reviewStatus')
            : undefined,
        reviewContent:
          batchRequest === 'goods/edit/batch/review' &&
          form.getFieldValue('reviewStatus') === REVIEW_STATUS_FAILED
            ? form.getFieldValue('reviewContent')
            : undefined,
        isUpdatePrice:
          batchRequest === 'goods/related_goods/sync' && body
            ? body.isUpdatePrice
            : undefined,
      },
      { timeout: 0 }
    )
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (setSelectedRowKeys) setSelectedRowKeys([]);
            if (funct.refresh) funct.refresh();
            if (batchRequest === 'goods/trash_batch')
              alertMessage('success', t('goods.alerts.batchArchived'));
            else if (batchRequest === 'goods/edit/batch/clear_extend_cat')
              alertMessage('success', t('goods.alerts.extendedCatCleared'));
            else if (batchRequest === 'goods/delete_batch')
              alertMessage('success', t('goods.alerts.batchDeleted'));
            else if (batchRequest === 'goods/restore_batch')
              alertMessage('success', t('goods.alerts.batchRestored'));
            else alertMessage('success', t('goods.alerts.batchEdited'));
          } else if (!response.goodStatus && !response.batchStatus) {
            setBatchFailedVis(true);
            alertMessage('error', t('general.fourFourFour'));
            setBatchFailObjs({
              dataType: response.data.errorType,
              data: response?.data.list,
            });
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popover
        overlayStyle={{ width: 195 }}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        trigger={['click']}
        visible={popOverSyncVis}
        onVisibleChange={(visible) => setPopOverSyncVis(visible)}
        placement="rightTop"
        content={
          <div
            style={{
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Text style={{ fontWeight: 500 }}>
              {t('goods.goodsBatch.syncWithMainGoodWarning')}
            </Typography.Text>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" onClick={() => setPopOverSyncVis(false)}>
                {t('actionsColumn.confirmation.no')}
              </Button>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                size="small"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setPopOverSyncVis(false);
                  sendGoodsBatchRequest('goods/related_goods/sync');
                }}
              >
                {t('actionsColumn.confirmation.yes')}
              </Button>
            </div>
          </div>
        }
      >
        <Button
          disabled={!hasPermission(actionPermissions.goodGroup.relatedGoods)}
          block
          type="text"
          style={{ textAlign: 'left' }}
        >
          {t('goods.goodsBatch.syncWithMainGood')}
        </Button>
      </Popover>
      <Popover
        overlayStyle={{ width: 195 }}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        trigger={['click']}
        visible={popOverSyncWithPriceVis}
        onVisibleChange={(visible) => setPopOverSyncWithPriceVis(visible)}
        placement="rightTop"
        content={
          <div
            style={{
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Text style={{ fontWeight: 500 }}>
              {t('goods.goodsBatch.syncWithMainGoodIncludingPriceWarning')}
            </Typography.Text>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size="small"
                onClick={() => setPopOverSyncWithPriceVis(false)}
              >
                {t('actionsColumn.confirmation.no')}
              </Button>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                size="small"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setPopOverSyncWithPriceVis(false);
                  sendGoodsBatchRequest('goods/related_goods/sync', {
                    isUpdatePrice: true,
                  });
                }}
              >
                {t('actionsColumn.confirmation.yes')}
              </Button>
            </div>
          </div>
        }
      >
        <Button
          disabled={!hasPermission(actionPermissions.goodGroup.relatedGoods)}
          block
          type="text"
          style={{ textAlign: 'left' }}
        >
          {t('goods.goodsBatch.syncWithMainGoodIncludingPrice')}
        </Button>
      </Popover>
      {selectedRows && columns && funct.exportConfig && (
        <Button
          block
          type="text"
          onClick={() =>
            onExportToExcel(columns, selectedRows, funct.exportConfig)
          }
          style={{ textAlign: 'left' }}
        >
          {t('actionsColumn.exportSelected')}
        </Button>
      )}
      <BatchFailedModal
        failedObjects={batchFailObjs}
        visible={batchFailedVis}
        setVisible={setBatchFailedVis}
        selectedRowKeys={selectedRowKeys}
        loading={isLoading}
      />
    </>
  );
};

export default MismatchGoodsTableFooterToolbar;
