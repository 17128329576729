import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import { CouponData, CouponEnum } from '../../types';

type GetCouponFormFieldsProps = {
  type: string;
  data: string;
  selectedCoupon?: CouponData;
  couponEnum?: CouponEnum;
};

const GetCouponFormFields = ({
  type,
  data,
  selectedCoupon,
  couponEnum,
}: GetCouponFormFieldsProps) => {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const [noCouponUpperBound, setCouponUpperBound] = useState<boolean>(false);
  const [noCouponUsageLimit, setCouponUsageLimit] = useState<boolean>(false);
  const [noCouponExpiryDayLimit, setCouponExpiryDayLimit] =
    useState<boolean>(false);

  useEffect(() => {
    if (selectedCoupon) {
      if (data === 'couponUpperBound') {
        setCouponUpperBound(!Boolean(Number(selectedCoupon.couponUpperBound)));
      } else if (data === 'couponUsageLimit') {
        setCouponUsageLimit(!Boolean(Number(selectedCoupon.couponUsageLimit)));
      } else if (data === 'couponExpireDay') {
        setCouponExpiryDayLimit(
          !Boolean(Number(selectedCoupon.couponExpireDay))
        );
      }
    }
  }, [data, selectedCoupon]);

  switch (type) {
    case 'DATE':
      return (
        <Form.Item
          name={data === 'activityStartTime' ? 'activityDate' : 'couponDate'}
          label={t(`activity.add/EditCoupon.${data}`)}
          rules={[
            {
              required: true,
              message: t('general.inputError.pleaseSelectOne'),
            },
          ]}
          initialValue={
            selectedCoupon
              ? data === 'activityStartTime'
                ? selectedCoupon.activityStartTime &&
                  selectedCoupon.activityEndTime
                  ? [
                      moment(selectedCoupon.activityStartTime),
                      moment(selectedCoupon.activityEndTime),
                    ]
                  : []
                : selectedCoupon.couponStartTime && selectedCoupon.couponEndTime
                ? [
                    moment(selectedCoupon.couponStartTime),
                    moment(selectedCoupon.couponEndTime),
                  ]
                : []
              : []
          }
        >
          <RangePicker
            style={{ width: '100%' }}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('00:00:00', 'HH:mm:ss'),
              ],
            }}
            format={DATE_TIME_FORMAT}
            placeholder={[
              t('activity.add/EditCoupon.startDate'),
              t('activity.add/EditCoupon.endDate'),
            ]}
          />
        </Form.Item>
      );
    case 'MAX_MIN':
      return (
        <Form.Item>
          <Form.Item
            name="couponLowerBound"
            label={t('activity.add/EditCoupon.couponLowerBound')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
            extra={
              data === 'couponUpperBound'
                ? t('activity.add/EditCoupon.couponLowerBoundToolTip')
                : undefined
            }
            style={{
              marginBottom: data === 'couponUpperBound' ? 16 : undefined,
            }}
          >
            {
              <InputNumber<string>
                style={{ width: 200 }}
                min="0"
                step="0.01"
                precision={2}
                stringMode
              />
            }
          </Form.Item>
          <Form.Item
            label={t('activity.add/EditCoupon.couponUpperBound')}
            required
            style={{ marginBottom: 0 }}
          >
            <Space align="start" wrap>
              {!noCouponUpperBound && (
                <Form.Item
                  name="couponUpperBound"
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  {
                    <InputNumber<string>
                      style={{ width: 200 }}
                      min="0"
                      step="0.01"
                      precision={2}
                      stringMode
                    />
                  }
                </Form.Item>
              )}
              <Form.Item style={{ marginBottom: 0 }}>
                <Checkbox
                  checked={noCouponUpperBound}
                  onChange={(e) => {
                    setCouponUpperBound(e.target.checked);
                  }}
                >
                  {t('activity.add/EditFavActivity.maxAmountRemark')}
                </Checkbox>
              </Form.Item>
            </Space>
          </Form.Item>
        </Form.Item>
      );
    case 'INTEGER':
      return (
        <Form.Item
          // name={data}
          label={t(`activity.add/EditCoupon.${data}`)}
          required
        >
          <Space align="start" wrap>
            {!noCouponExpiryDayLimit && (
              <Form.Item
                name={data}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                {<InputNumber precision={0} style={{ width: 200 }} min={0} />}
              </Form.Item>
            )}
            {data === 'couponExpireDay' && (
              <Form.Item style={{ marginBottom: 0 }}>
                <Checkbox
                  checked={noCouponExpiryDayLimit}
                  onChange={(e) => {
                    setCouponExpiryDayLimit(e.target.checked);
                  }}
                >
                  {t('activity.add/EditCoupon.couponExpireDayToolTip')}
                </Checkbox>
              </Form.Item>
            )}
          </Space>
        </Form.Item>
      );
    case 'DECIMAL':
      return (
        <Form.Item
          label={t(`activity.add/EditCoupon.${data}`)}
          style={{ marginBottom: data === 'couponUsageLimit' ? 16 : 24 }}
          required
        >
          <Space align="start" wrap>
            {!noCouponUsageLimit && (
              <Form.Item
                name={data}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.empty'),
                  },
                ]}
                extra={
                  data === 'couponUsageLimit'
                    ? t('activity.add/EditCoupon.couponUsageLimitToolTip')
                    : undefined
                }
                style={{ marginBottom: 0 }}
              >
                {
                  <InputNumber<string>
                    precision={2}
                    style={{ width: 200 }}
                    min="0"
                    step="0.01"
                    stringMode
                  />
                }
              </Form.Item>
            )}
            {data === 'couponUsageLimit' && (
              <Form.Item style={{ marginBottom: 0 }}>
                <Checkbox
                  checked={noCouponUsageLimit}
                  onChange={(e) => {
                    setCouponUsageLimit(e.target.checked);
                  }}
                >
                  {t('activity.add/EditCoupon.noCouponUsageLimit')}
                </Checkbox>
              </Form.Item>
            )}
          </Space>
        </Form.Item>
      );
    case 'STRING':
      return (
        <Form.Item
          name={data}
          label={t(`activity.add/EditCoupon.${data}`)}
          rules={[
            {
              required: true,
              message: t('general.inputError.empty'),
            },
          ]}
        >
          {<Input style={{ width: 200 }} />}
        </Form.Item>
      );
    case 'MULTI_SELECT':
      return (
        <Form.Item
          name={data}
          label={t(`activity.add/EditCoupon.${data}`)}
          rules={[
            {
              required: true,
              message: t('general.inputError.empty'),
            },
          ]}
        >
          {
            <Select
              mode="multiple"
              style={{ width: 200 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {couponEnum &&
                couponEnum.couponValidUser.map((user) => (
                  <Select.Option key={user.id} value={user.id.toString()}>
                    {user.description}
                  </Select.Option>
                ))}
            </Select>
          }
        </Form.Item>
      );
    default:
      return <></>;
  }
};

export { GetCouponFormFields };
