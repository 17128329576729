import { Button, Form, Input, Modal, Popconfirm, Select } from 'antd';
import { useEffect, useRef, Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postData } from '../../utils/luniuRNRequest';

type SendNotificationModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDeviceIds: Key[];
  devices: DeviceData[];
  sendAll?: boolean;
  setSendAll?: React.Dispatch<React.SetStateAction<boolean>>;
};

const SendNotificationModal = ({
  visible,
  setVisible,
  selectedDeviceIds,
  devices,
  sendAll,
  setSendAll,
}: SendNotificationModalProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setLoading(true);

        let pushTokens = [];
        if (!sendAll) {
          for (let id of selectedDeviceIds) {
            let found = devices.find((device) => device.deviceId === id);
            if (found) pushTokens.push(found.deviceCode);
          }

          postData('device/send', {
            title: values.title,
            content: values.content,
            data: {
              type: values.type,
              id: Number(values.id),
            },
            pushTokens: pushTokens,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                onClose();
                alertMessage('success', t('users.alerts.notificationSent'));
              } else {
                if (isSubscribed.current) setLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        } else {
          postData('device/send/all', {
            title: values.title,
            content: values.content,
            data: {
              type: values.type,
              id: Number(values.id),
            },
          })
            .then((response) => {
              if (response && response.goodStatus) {
                onClose();
                alertMessage('success', t('users.alerts.notificationSent'));
              } else {
                if (isSubscribed.current) setLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClose = () => {
    if (isSubscribed.current) {
      if (setSendAll) setSendAll(false);
      setLoading(false);
      setVisible(false);
    }
  };

  return (
    <Modal
      title={t('users.sendNotifications.title')}
      visible={visible}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>
            {t('users.sendNotifications.cancel')}
          </Button>
          {sendAll ? (
            <Popconfirm
              title={t('users.alerts.sendAllWarning')}
              okText={t('actionsColumn.confirmation.yes')}
              cancelText={t('actionsColumn.confirmation.no')}
              onConfirm={onSubmit}
            >
              <Button type="primary" loading={loading}>
                {t('users.sendNotifications.ok')}
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" loading={loading} onClick={onSubmit}>
              {t('users.sendNotifications.ok')}
            </Button>
          )}
        </>
      }
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="title"
          label={t('users.sendNotifications.notificationTitle')}
          rules={[{ required: true, message: t('general.inputError.empty') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="content"
          label={t('users.sendNotifications.content')}
          rules={[
            {
              required: true,
              message: t('general.inputError.empty'),
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="type"
          label={t('users.sendNotifications.contentType')}
          rules={[
            {
              required: true,
              message: t('general.inputError.pleaseSelectOne'),
            },
          ]}
        >
          <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
            <Select.Option value="category">Category</Select.Option>
            <Select.Option value="goods">Goods</Select.Option>
            <Select.Option value="webview">Webview</Select.Option>
            <Select.Option value="topic">Topic</Select.Option>
            <Select.Option value="order">Order</Select.Option>
            <Select.Option value="article">Article</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="id"
          label={t('users.sendNotifications.pageId')}
          rules={[{ required: true, message: t('general.inputError.empty') }]}
        >
          <Input />
        </Form.Item>
        {!sendAll && (
          <Form.Item label={t('users.sendNotifications.deviceId')}>
            {selectedDeviceIds.join(', ')}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default SendNotificationModal;
