import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Image,
  Popover,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import videoLogo from '../../assets/images/video-logo.png';
import { PRIMARY } from '../../constants/color';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import { FALLBACK_IMG } from '../../constants/styles';
import { GalleryMedia, GoodPicData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import VideoPreviewModal from './VideoPreviewModal';

type PhotoGalleryGridProps = {
  photos: Array<GalleryMedia | GoodPicData>;
  photosLoading: boolean;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
  value?: any;
  supportedMediaType?: string;
  isMultiple?: boolean;
};

/**
 * Grid to display photos/videos
 *
 * @param photos             Array of photos to display
 * @param photosLoading      Whether Photos are still being loaded
 * @param setValue           Set value of picture being selected
 * @param value              Currently selected photo
 * @param supportedMediaType Media type that is supported
 * @param isMultiple         enable multiple selection
 */
const PhotoGalleryGrid = ({
  photos,
  photosLoading,
  setValue,
  value,
  supportedMediaType,
  isMultiple,
}: PhotoGalleryGridProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [video, setVideo] = useState<GalleryMedia>();
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * @param photo Photo Information
   */
  const popoverContent = (photo: GalleryMedia) => (
    <div>
      <Typography.Text style={{ fontSize: 12 }}>{`${t(
        'settings.photoGalleryColumns.size'
      )}: ${
        Math.floor((photo.picSize / 1000) * 100) / 100
      }kB`}</Typography.Text>
      <br />
      <Typography.Text style={{ fontSize: 12 }}>{`${t(
        'settings.photoGalleryColumns.spec'
      )}: ${
        photo.mediaType === MEDIA_TYPE.IMAGE ? photo.picSpec : MEDIA_TYPE.VIDEO
      }`}</Typography.Text>
      <br />
      <Typography.Text ellipsis={true}>{`${t(
        'settings.photoGalleryColumns.addTime'
      )}: ${photo.addTime}`}</Typography.Text>
    </div>
  );

  const handleOnChange = (checked: boolean, item: GalleryMedia) => {
    if (isSubscribed.current) {
      if (checked) {
        isMultiple &&
          setValue &&
          setValue((prev: any[]) => [
            ...prev,
            {
              ...item,
              type: item.mediaType === MEDIA_TYPE.IMAGE ? 'IMG' : 'VIDEO',
              originalMedia: item.originalPic,
              originalMediaPath: item.originalPicPath,
              thumbMedia: item.thumbPic,
              thumbMediaPath: item.thumbPicPath,
              largeMedia: item.largePic,
              largeMediaPath: item.largePicPath,
            },
          ]);
      } else {
        isMultiple &&
          setValue &&
          setValue((prev: any[]) =>
            prev.filter((photo) => photo.picId !== item.picId)
          );
      }
    }
  };

  return (
    <Spin spinning={photosLoading}>
      <Row
        gutter={[16, 16]}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          maxHeight: 490,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {photos.map((photo, index) => (
          <Col key={index}>
            {isMultiple && value && setValue && (
              <Checkbox
                checked={
                  value.findIndex(
                    (p: GalleryMedia) => photo.originalPic === p.originalPic
                  ) !== -1 || photo.originalPic === value?.photo
                }
                onChange={(e) => handleOnChange(e.target.checked, photo)}
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  left: 14,
                  top: 3,
                }}
              />
            )}
            <Popover
              content={popoverContent(photo)}
              title={photo.picName}
              placement="top"
            >
              <Image
                src={
                  photo.mediaType === MEDIA_TYPE.IMAGE
                    ? photo.thumbPicPath
                    : videoLogo
                }
                alt={photo.picName}
                preview={
                  !supportedMediaType ||
                  (supportedMediaType && supportedMediaType === photo.mediaType)
                    ? {
                        visible: false,
                        mask: (
                          <Space>
                            <Tooltip
                              title={t('settings.photoGalleryActions.preview')}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode as HTMLElement
                              }
                            >
                              <Button
                                size="small"
                                icon={<EyeOutlined />}
                                onClick={() => {
                                  if (photo.mediaType === MEDIA_TYPE.VIDEO) {
                                    setVideo(photo);
                                    setShowVideoModal(true);
                                  } else {
                                    setPreviewIndex(index);
                                    setShowPreview(true);
                                  }
                                }}
                                style={{
                                  position: 'absolute',
                                  zIndex: 1,
                                  left: 6,
                                  bottom: 6,
                                }}
                              />
                            </Tooltip>
                          </Space>
                        ),
                      }
                    : false
                }
                width={100}
                height={100}
                fallback={FALLBACK_IMG}
                style={{
                  border:
                    photo.originalPic === value?.photo
                      ? `4px solid ${PRIMARY}`
                      : undefined,
                }}
                onClick={
                  !isMultiple
                    ? () => {
                        if (
                          !supportedMediaType ||
                          (supportedMediaType &&
                            supportedMediaType === photo.mediaType)
                        ) {
                          setValue &&
                            setValue((prev: any) => ({
                              ...prev,
                              photo: photo.originalPic,
                              photoPath: photo.thumbPicPath,
                              largePic: photo.largePic,
                              thumbPic: photo.thumbPic,
                              largePicPath: photo.largePicPath,
                              originalPic: photo.originalPic,
                              originalPicPath: photo.originalPicPath,
                            }));
                        } else {
                          alertMessage(
                            'warning',
                            'Media type is not supported'
                          );
                        }
                      }
                    : undefined
                }
              />
            </Popover>
            <Tooltip
              title={t('settings.photoGalleryActions.copy')}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
            >
              <Button
                size="small"
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(photo.originalPicPath);
                  alertMessage('success', 'Copy to clipboard');
                }}
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  right: 13,
                  bottom: 6,
                }}
              />
            </Tooltip>
          </Col>
        ))}
      </Row>
      <VideoPreviewModal
        visible={showVideoModal}
        setVisible={setShowVideoModal}
        videoPath={video?.originalPicPath}
        title={video?.picName}
      />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: showPreview,
            onVisibleChange: (value) => setShowPreview(value),
            current: previewIndex,
          }}
        >
          {photos.map((photo, index) => (
            <Image src={photo.largePicPath} key={index} />
          ))}
        </Image.PreviewGroup>
      </div>
    </Spin>
  );
};

export default PhotoGalleryGrid;
