import { EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Space,
  Spin,
  Switch,
} from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Ad_POSITION_LOCATION_CATEGORY } from '../../constants/generalConstants';
import { MEDIA_TYPE } from '../../constants/mediaConstants';
import { FALLBACK_IMG } from '../../constants/styles';
import { DATE_TIME_FORMAT } from '../../constants/systemConstants';
import {
  AdEnum,
  AdSettingAdData,
  AdSettingAdPositionData,
  CategoryData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import PhotoGalleryModal from '../PhotoGalleryModal';
import CategoryDropdown from '../goods/common/CategoryDropdown';
import AdLinkTypeDropdown from './common/AdLinkTypeDropdown';
import AdPositionDropdown from './common/AdPositionDropdown';

type AdPositionAdModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  adEnum?: AdEnum;
  selectedAdData?: AdSettingAdData;
  adPositionData?: AdSettingAdPositionData;
  categoryData?: CategoryData;
  refresh: Function;
};

const AdPositionAdModal = ({
  visible,
  setVisible,
  adEnum,
  selectedAdData,
  adPositionData,
  categoryData,
  refresh,
}: AdPositionAdModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgModalVisible, setImgModalVisible] = useState(false);
  const [adImg, setAdImg] = useState({ photo: '', photoPath: '' });
  const [adLink, setAdLink] = useState('');

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
      setAdImg({
        photo: selectedAdData ? selectedAdData.adImg : '',
        photoPath: selectedAdData ? selectedAdData.adImgPath : '',
      });
      setAdLink(selectedAdData ? selectedAdData.adLink : '');
    }
  }, [t, form, visible, selectedAdData]);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.setFieldsValue({
        adImg: adImg.photo,
        adLink: adLink,
      });
    }
  }, [adImg, adLink, visible, form]);

  const onClose = () => {
    if (isSubscribed.current) {
      form.resetFields();
      setVisible(false);
      setFirstLoad(false);
      setAdImg({ photo: '', photoPath: '' });
      setAdLink('');
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setLoading(true);
        const finalPositionId = values.positionId.value
          ? values.positionId.value
          : values.positionId;
        postDataWithAuthToken(selectedAdData ? 'ad/edit' : 'ad/add', {
          ...values,
          positionId: finalPositionId,
          adId: selectedAdData ? selectedAdData.adId : undefined,
          catId:
            adPositionData?.positionLocation === Ad_POSITION_LOCATION_CATEGORY
              ? values.catId
                ? values.catId.at(-1)
                : undefined
              : 0,
          startTime: values.date
            ? moment(values.date[0]).format(DATE_TIME_FORMAT)
            : undefined,
          endTime: values.date
            ? moment(values.date[1]).format(DATE_TIME_FORMAT)
            : undefined,
        }).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedAdData
                ? t('advertisement.alerts.adEdited')
                : t('advertisement.alerts.adAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }

          if (isSubscribed.current) setLoading(false);
        });
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      maskClosable={false}
      onOk={onFinish}
      onCancel={onClose}
      width={800}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedAdData
          ? `${t('advertisement.add/EditAd.editAdTitle')} ${
              selectedAdData.adName
            }`
          : `${t('advertisement.add/EditAd.addAdTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          initialValues={
            selectedAdData
              ? {
                  ...selectedAdData,
                  catId: selectedAdData.category
                    ? selectedAdData.category.allCatIds
                    : [],
                }
              : {
                  positionId: adPositionData
                    ? adPositionData.positionId
                    : undefined,
                  catId: categoryData ? categoryData.allCatIds : [],
                  sortOrder: 100,
                  isEnabled: true,
                }
          }
          form={form}
          onFinish={onFinish}
        >
          {/**For ad Name */}
          <Form.Item
            name="adName"
            label={t('advertisement.adPositionAdColumnList.adName')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="positionId"
            label={t('advertisement.adPositionColumnList.positionId')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <AdPositionDropdown
              labelInValue={true}
              initialValue={
                adPositionData
                  ? {
                      value: adPositionData.positionId,
                      label: `${adPositionData.positionName} (${adPositionData.positionId})`,
                    }
                  : undefined
              }
            />
          </Form.Item>

          {adPositionData &&
            adPositionData.positionLocation ===
              Ad_POSITION_LOCATION_CATEGORY && (
              <Form.Item
                name="catId"
                label={t('advertisement.adPositionAdColumnList.catId')}
                rules={[
                  {
                    required: true,
                    message: t('general.inputError.pleaseSelectOne'),
                  },
                ]}
              >
                <CategoryDropdown
                  initialValue={
                    selectedAdData
                      ? selectedAdData.category?.allCatIds
                      : categoryData?.allCatIds
                  }
                />
              </Form.Item>
            )}

          {/**For adImg */}
          <Form.Item
            name="adImg"
            label={t('advertisement.adPositionAdColumnList.adImg')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Space size="large" align="start">
              <Space align="start">
                <Image
                  width={50}
                  src={adImg.photoPath}
                  preview={{ mask: <EyeOutlined /> }}
                  fallback={FALLBACK_IMG}
                />
                {adImg.photoPath && (
                  <MinusCircleOutlined
                    style={{ color: ' #ff0f0f' }}
                    onClick={() => {
                      setAdImg({ photo: '', photoPath: '' });
                    }}
                  />
                )}
              </Space>
              <Button
                onClick={() => {
                  setFirstLoad(true);
                  setImgModalVisible(true);
                }}
              >
                {t('settings.photoGallery')}
              </Button>
            </Space>
          </Form.Item>

          {/**For adLink */}
          <div className="ant-form-item">
            <Form.Item
              name="adLink"
              label={t('advertisement.adPositionAdColumnList.adLink')}
            >
              <Input />
            </Form.Item>
            <AdLinkTypeDropdown adEnum={adEnum} setAdLinkType={setAdLink} />
          </div>

          {/**For Date */}
          <Form.Item
            name="date"
            label={`${t('advertisement.adPositionAdColumnList.startTime')}-${t(
              'advertisement.adPositionAdColumnList.endTime'
            )}`}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
            initialValue={
              selectedAdData &&
              selectedAdData.startTime &&
              selectedAdData.endTime
                ? [
                    moment(selectedAdData.startTime),
                    moment(selectedAdData.endTime),
                  ]
                : []
            }
          >
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              showTime={{
                format: 'HH:mm:ss',
                hideDisabledOptions: true,
                defaultValue: [
                  moment('00:00:00', 'HH:mm:ss'),
                  moment('00:00:00', 'HH:mm:ss'),
                ],
              }}
              format={DATE_TIME_FORMAT}
              placeholder={[
                t('advertisement.adPositionAdColumnList.startTime'),
                t('advertisement.adPositionAdColumnList.endTime'),
              ]}
            />
          </Form.Item>

          {/**For isEnabled */}
          <Form.Item
            name="isEnabled"
            label={t('advertisement.adPositionAdColumnList.isEnabled')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {/**For Sort Order */}
          <Form.Item
            name="sortOrder"
            label={t('advertisement.adPositionAdColumnList.sortOrder')}
          >
            <InputNumber min={1} max={50000} />
          </Form.Item>
        </Form>
      </Spin>

      <PhotoGalleryModal
        firstLoad={firstLoad}
        visible={imgModalVisible}
        setVisible={setImgModalVisible}
        setValue={setAdImg}
        value={adImg}
        supportedMediaType={MEDIA_TYPE.IMAGE}
      />
    </Modal>
  );
};

export default AdPositionAdModal;
