import React, { useEffect } from 'react';
import StandardLayout from '../views/layout/StandardLayout';
import { RouteConfigComponentProps } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../utils/createAuthProvider';
import { alertMessage } from '../utils/alertMessage';
import { useTranslation } from 'react-i18next';
import { authRoute } from '../constants/pathname';
/**
 * Contains all routes in the dashboard
 *
 * @props RouteConfigComponentProps route Route information that includes info like title
 */
const Dashboard = (props: RouteConfigComponentProps) => {
  const history = useHistory();
  const auth = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (!auth.isLogged) {
      history.push(authRoute.login);
      alertMessage('error', t('login.alerts.loginRequired'));
    }
  }, [history, auth, t]);

  return <StandardLayout {...props} />;
};

export default Dashboard;
