import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { useState } from 'react';
import { UserRankData } from '../../../types';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { Typography } from 'antd';

type UserRankDropdownProps = {
  isMember?: boolean;
  labelInValue?: boolean;
  isMultiple?: boolean;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};

const UserRankDropdown = ({
  isMember = false,
  labelInValue = false,
  isMultiple = false,
  initialValue,
  onChange,
}: UserRankDropdownProps) => {
  const { t } = useTranslation();
  const [userRankData, setUserRankData] = useState<Array<UserRankData>>([]);
  const [userRankPage, setUserRankPage] = useState<number>(2);
  const [userRankTotalPage, setUserRankTotalPage] = useState<number>(2);

  //Makes a request and set the user rank list data
  const getUserRankData = () => {
    getDataWithAuthToken('users/user_rank/list', {
      params: { isMember: isMember },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setUserRankData(response.data.list);
          setUserRankPage(2);
          setUserRankTotalPage(response.data.totalPage);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Makes a new request to get new list of user rank
   * When the user scrolls to the bottom of the dropdown box
   */
  const fetchOnScrollUserRank = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (userRankPage > userRankTotalPage) return;
      getDataWithAuthToken('users/user_rank/list', {
        params: { page: userRankPage, isMember: isMember },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setUserRankData((prev) => [...prev, ...response.data.list]);
            setUserRankPage((prev) => prev + 1);
            setUserRankTotalPage(response.data.totalPage);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      filterOption={false}
      placeholder={t('searchPlaceholders.selectUserRank')}
      onPopupScroll={fetchOnScrollUserRank}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      mode={isMultiple ? 'multiple' : undefined}
      maxTagCount="responsive"
      onFocus={() => {
        if (!userRankData.length) getUserRankData();
      }}
      onChange={handleOnChange}
      allowClear
      labelInValue={labelInValue}
      defaultValue={initialValue ? initialValue : undefined}
      optionLabelProp="key"
    >
      {userRankData.map((rank) => (
        <Select.Option
          key={`${rank.rankName} (${rank.rankId})`}
          value={rank.rankId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('users.userRankColumns.id')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('users.userRankColumns.rankName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{rank.rankId}</Typography>
              <Typography>{rank.rankName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
export default UserRankDropdown;
