import { ExportOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import XLSX from 'xlsx';
import { useTab } from '../../hooks/useTab';
import { dashboardRoute } from '../../constants/pathname';
import { useState } from 'react';
import { LONG_TIMEOUT } from '../../constants/systemConstants';

type TableExportProp = {
  columns: ColumnsType<any>;
  rows: any[];
  exportConfig?: {
    fileName: string;
    url?: string;
    otherExports?: { title: string; url: string }[];
  };
  mobile?: boolean;
};

const TableExport = ({
  columns,
  rows,
  exportConfig,
  mobile,
}: TableExportProp) => {
  const { addTab } = useTab();
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  // const onExportToCSV = (columns: any[], rows: any[]) => {
  //   let csvStr = '';
  //   let columnKeys = [];

  //   for (let column of columns) {
  //     if (column.dataIndex) {
  //       csvStr += `${column.title},`;
  //       columnKeys.push(column.dataIndex);
  //     }
  //   }
  //   csvStr = csvStr.slice(0, -1) + '\n';

  //   for (let row of rows) {
  //     for (let key of columnKeys) {
  //       if (row[key]) {
  //         if (row[key].toString().includes(',')) {
  //           csvStr += `"${row[key]}",`;
  //         } else {
  //           csvStr += `${row[key]},`;
  //         }
  //       } else {
  //         csvStr += ',';
  //       }
  //     }
  //     csvStr = csvStr.slice(0, -1) + '\n';
  //   }

  //   csvStr = `data:text/csv;charset=utf-8,${csvStr}`;
  //   let link = document.createElement('a');
  //   link.setAttribute('href', encodeURI(csvStr));
  //   link.setAttribute(
  //     'download',
  //     `${exportConfig?.fileName || 'export'}_${date}.csv`
  //   );
  //   link.click();
  // };

  const exportAll = (url: string) => {
    if (!url) return;
    getDataWithAuthToken(url)
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            alertMessage(
              'success',
              t('fileManager.alerts.requestReceived'),
              <Space direction="vertical">
                <Typography>ID: {response.data.id}</Typography>
                <Typography>
                  {t('fileManager.fileManagerColumns.fileName')}:{' '}
                  {response.data.fileName}
                </Typography>
                <Button
                  type="link"
                  onClick={() =>
                    addTab(
                      t('fileManager.title'),
                      dashboardRoute.settings.fileManager
                    )
                  }
                  style={{ padding: 0 }}
                >
                  {t('fileManager.fileManagerActions.go')}
                </Button>
              </Space>
            );
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || ''
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const menuItem = (title: string, url: string, index: number) => {
    return (
      <Menu.Item
        key={`${index}`}
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(
            setTimeout(() => exportAll(url || ''), LONG_TIMEOUT)
          );
        }}
      >
        {title}
      </Menu.Item>
    );
  };

  // Mobile - buttons
  const buttonListItem = (title: string, url: string) => {
    return (
      <Button
        type="text"
        block
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(
            setTimeout(() => exportAll(url || ''), LONG_TIMEOUT)
          );
        }}
        style={{ textAlign: 'left' }}
      >
        {title}
      </Button>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(
            setTimeout(
              () => onExportToExcel(columns, rows, exportConfig),
              LONG_TIMEOUT
            )
          );
        }}
      >
        {t('actionsColumn.exportCurrent')}
      </Menu.Item>
      {/** Export All */}
      {exportConfig?.url &&
        menuItem(t('actionsColumn.exportAll'), exportConfig?.url, 1)}

      {/** Other Export Url - array of object */}
      {exportConfig?.otherExports &&
        exportConfig?.otherExports.map((value, index) => {
          return menuItem(value.title, value.url, index + 2);
        })}
    </Menu>
  );

  return mobile ? (
    <>
      <Button
        type="text"
        block
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(
            setTimeout(
              () => onExportToExcel(columns, rows, exportConfig),
              LONG_TIMEOUT
            )
          );
        }}
        style={{ textAlign: 'left' }}
      >
        {t('actionsColumn.exportCurrent')}
      </Button>
      {/** Export All */}
      {exportConfig?.url &&
        buttonListItem(t('actionsColumn.exportAll'), exportConfig?.url)}

      {/** Other Export Url - array of object */}
      {exportConfig?.otherExports &&
        exportConfig?.otherExports.map((value, index) =>
          buttonListItem(value.title, value.url)
        )}
    </>
  ) : (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        <ExportOutlined />
        {t('actionsColumn.export')}
      </Button>
    </Dropdown>
  );
};

export default TableExport;
export const onExportToExcel = (
  columns: any[],
  rows: any[],
  exportConfig?: { fileName: string; url?: string }
  // t?: TFunction<'translation'>
) => {
  let date = new Date().toISOString().replace(/[^0-9]/g, '');
  let columnKeys = [];
  let excelData: any[][] = [];
  let temp: any[] = [];
  for (let column of columns) {
    /**
     * Here we check if we need to add additional columns since the data in the list's columns are congregated,
     * instead of individual.
     * The key differentiating part between a regular column and a special one is column.title.key.
     * If column.title.key exists that means it contains the title for the columns to be printed in Excel
     * KEY NOTE: TITLE OF COLUMN MUST BE A COMPONENT, NOT STRING
     */
    if (column.title.key && column.key.indexOf('|') !== -1) {
      column.title.key.split('|').forEach((title: string, index: number) => {
        if (temp.indexOf(title) === -1) temp.push(title);
        columnKeys.push(column.key.split('|')[index]);
      });
    } else if (column.dataIndex) {
      temp.push(
        typeof column.title === 'string'
          ? column.title
          : String(column.title.props.children)
      );
      columnKeys.push(column.dataIndex);
    } else if (column.children && column.children.length) {
      column.children.forEach((col: any) => {
        temp.push(
          typeof column.title === 'string'
            ? typeof col.title === 'string'
              ? `${column.title} ${col.title}`
              : `${column.title} ${String(col.title.props.children)}`
            : typeof col.title === 'string'
            ? `${String(column.title.props.children)} ${col.title}`
            : `${String(column.title.props.children)} ${String(
                col.title.props.children
              )}`
        );
        columnKeys.push(col.dataIndex);
      });
    }
  }
  excelData.push(temp);
  for (let row of rows) {
    let tempColData = [];
    for (let key of columnKeys) {
      if (row[key] !== undefined) {
        tempColData.push(row[key]);
      } else {
        tempColData.push('');
      }
    }
    excelData.push(tempColData);
  }

  let ws = XLSX.utils.aoa_to_sheet(excelData);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `${date.slice(0, 8)}`);
  XLSX.writeFile(
    wb,
    `${exportConfig?.fileName || 'EXPORT'}_${date.slice(0, 8)}.xls`
  );
};
