import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Space,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import SellerAdminModal from '../../components/sellers/SellerAdminModal';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { FontSizeType, SellerAdminData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const { useBreakpoint } = Grid;

const SellerAdminList = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = useBreakpoint();
  const [showModal, setShowModal] = useState(false);
  const [editSellerAdmin, setEditSellerAdmin] = useState<SellerAdminData>();
  const [isLoading, setIsLoading] = useState(true);
  const [sellerAdmins, setSellerAdmins] = useState<SellerAdminData[]>([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const isSubscribed = useRef(true);
  const columnKeys = [
    'adminId',
    'adminUserName',
    'sellerName',
    'email',
    'addTime',
    'lastLogin',
    'adminUserImg',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SellerAdminData[]>([]);
  const [form] = Form.useForm();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [advance, setAdvance] = useState<{ sellerId?: string }>({});
  const formRef = useRef(null);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columns: ColumnsType<SellerAdminData> = [
    {
      title: setFont(t('sellers.adminListColumns.id'), fontSize),
      key: 'adminId',
      dataIndex: 'adminId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.adminId, b.adminId),
      render: (text: string, record: SellerAdminData) => (
        <Button
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerPrivateMerchants)
          }
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            if (record.sellerShopInfo) {
              setEditSellerAdmin(record);
              setShowModal(true);
            } else {
              alertMessage('warning', t('sellers.alerts.missingSellerInfo'));
            }
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('sellers.adminListColumns.sellerName'), fontSize),
      key: 'sellerName',
      dataIndex: 'sellerName',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.sellerName, b.sellerName),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.adminName'), fontSize),
      key: 'adminUserName',
      dataIndex: 'adminUserName',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.adminUserName, b.adminUserName),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.parentAdminInfo'), fontSize),
      key: 'adminUserName',
      width: 120,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.adminUserName, b.adminUserName),
      render: (sellerAdmin: SellerAdminData) =>
        setFont(
          sellerAdmin.parentAdmin &&
            sellerAdmin.parentAdmin.adminUserName &&
            sellerAdmin.parentAdmin.adminId
            ? sellerAdmin.parentAdmin.adminUserName +
                '(' +
                sellerAdmin.parentAdmin.adminId +
                ')'
            : '',
          fontSize
        ),
    },
    {
      title: setFont(t('sellers.adminListColumns.email'), fontSize),
      key: 'email',
      dataIndex: 'email',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.addTime, b.addTime),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('sellers.adminListColumns.lastLogin'), fontSize),
      key: 'lastLogin',
      dataIndex: 'lastLogin',
      width: 160,
      sorter: (a: SellerAdminData, b: SellerAdminData) =>
        compare(a.lastLogin, b.lastLogin),
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: SellerAdminData) => (
        <Space>
          <Button
            disabled={
              !hasPermission(
                actionPermissions.sellerGroup.sellerPrivateMerchants
              )
            }
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              if (record.sellerShopInfo) {
                setEditSellerAdmin(record);
                setShowModal(true);
              } else {
                alertMessage('warning', t('sellers.alerts.missingSellerInfo'));
              }
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => handleDeleteSellerAdmin(record.adminId)}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(
                actionPermissions.sellerGroup.sellerPrivateMerchants
              )
            }
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(
                  actionPermissions.sellerGroup.sellerPrivateMerchants
                )
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Delete seller admin by ID
  const handleDeleteSellerAdmin = async (adminId: number) => {
    try {
      const response = await postDataWithAuthToken('seller/admin_user/delete', {
        adminId: adminId,
      });
      if (response && response.goodStatus) {
        getSellerAdminData();
        alertMessage('success', t('sellers.alerts.adminDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch seller admin List
  const getSellerAdminData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('seller/admin_user/list', {
      params: {
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? Number(form.getFieldValue('sellerId'))
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setSellerAdmins(response.data.list);
              setTotal(response.data.totalItem);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, keyword, page, pageSize, form]);

  // Advanced search component
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('fileManager.fileManagerColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          <Form.Item
            name="sellerId"
            label={t('sellers.adminListColumns.advancedSearch.sellerId')}
          >
            <SellersDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  sellerId: value,
                }));
              }}
            />
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                setKeyword('');
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getSellerAdminData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('sellers.adminListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={!advance.sellerId}
              onClick={() => {
                form.resetFields();
                setAdvance({});
                if (page !== 1) setPage(1);
                else getSellerAdminData();
              }}
            >
              {t('sellers.adminListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('fileManager.fileManagerColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  useEffect(() => {
    getSellerAdminData();
  }, [getSellerAdminData]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('sellers.sellersAdmins')}
          </Typography.Title>
          <TableToolbar
            searchPlaceholder={t('searchPlaceholders.searchUserKeyword')}
            tableSize={tableSize}
            setTableSize={setTableSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditSellerAdmin(undefined);
                  setShowModal(true);
                }}
                disabled={
                  !hasPermission(
                    actionPermissions.sellerGroup.sellerPrivateMerchants
                  )
                }
              >
                {t('sellers.add/editAdmin.addTitle')}
              </Button>
            }
            setFontSize={setFontSize}
            refresh={() => getSellerAdminData()}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
            }}
            advancedSearch={advancedSearch}
          />
          <Table<SellerAdminData>
            dataSource={sellerAdmins}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            loading={isLoading}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(sellerAdmin) => sellerAdmin.adminId}
            rowSelection={{
              hideSelectAll: true,
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: SellerAdminData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'SELLER_ADMIN_LIST' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <SellerAdminModal
        visible={showModal}
        setVisible={setShowModal}
        adminProfile={editSellerAdmin}
        callBack={() => getSellerAdminData()}
      />
    </Container>
  );
};

export default SellerAdminList;
