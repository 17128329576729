import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Users should be redirected to this component if receives a 403 response
 */
const FourZeroThree = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle={t('general.fourZeroThree')}
    />
  );
};

export default FourZeroThree;