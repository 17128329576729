import { Button, Col, DatePicker, Form, Grid, Row, Space, Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { FontSizeType, SalesAnalysisData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { addCommas, addCommasPrice } from '../../../utils/helperFunction';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import CategoryDropdown from '../../goods/common/CategoryDropdown';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import TableToolbar from '../../table/TableToolbar';

type CategorySalesProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const CategorySales = ({
  isSeller,
  isLoading,
  setIsLoading,
}: CategorySalesProps) => {
  //General Components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const formRef = useRef(null);
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  //Data Components
  const [data, setData] = useState<SalesAnalysisData[]>([]);
  // Table Components
  const screens = Grid.useBreakpoint();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItem, setTotalItem] = useState(0);
  const [sortValue, setSortValue] = useState<'SALES' | 'GOODS_NUM'>('SALES');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SalesAnalysisData[]>([]);
  const columnKeys = [
    'minGoodsPrice',
    'maxGoodsPrice',
    'averageGoodsPrice',
    'totalGoodsNum',
    'totalAmount',
    'catId',
    'catName',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((key) => key !== 'catId')
  );

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('analysis/sales/category', {
      params: {
        isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
        sortValue: sortValue,
        sellerId:
          getDashboardStyle().isSellerSwitch &&
          isSeller &&
          formRef.current &&
          form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        catId:
          formRef.current && form.getFieldValue('catId')
            ? form.getFieldValue('catId').at(-1)
            : undefined,
        startDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
            : undefined,
        endDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setData(response.data.list);
          setTotalItem(response.data.totalItem);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t, sortValue, form, setIsLoading, isSeller]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<SalesAnalysisData> = [
    {
      title: setFont(t('analysis.categorySales.id'), fontSize),
      key: 'catId',
      dataIndex: 'catId',
      fixed: screens.lg ? 'left' : undefined,
      width: 80,
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('analysis.categorySales.catName'), fontSize),
      key: 'catName',
      dataIndex: 'catName',
      render: (value) => setFont(value, fontSize),
    },
    {
      title: setFont(t('analysis.categorySales.minGoodsPrice'), fontSize),
      key: 'minGoodsPrice',
      dataIndex: 'minGoodsPrice',
      width: 100,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(t('analysis.categorySales.maxGoodsPrice'), fontSize),
      key: 'maxGoodsPrice',
      dataIndex: 'maxGoodsPrice',
      width: 100,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(t('analysis.categorySales.averageGoodsPrice'), fontSize),
      key: 'averageGoodsPrice',
      dataIndex: 'averageGoodsPrice',
      width: 100,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(t('analysis.categorySales.totalGoodsNum'), fontSize),
      key: 'totalGoodsNum',
      dataIndex: 'totalGoodsNum',
      width: 160,
      sorter: (a: SalesAnalysisData, b: SalesAnalysisData) =>
        compare(
          Number(a.totalGoodsNum) !== undefined
            ? Number(a.totalGoodsNum)
            : null,
          Number(b.totalGoodsNum) !== undefined ? Number(b.totalGoodsNum) : null
        ),
      render: (value) => setFont(addCommas(value), fontSize),
    },
    {
      title: setFont(t('analysis.categorySales.totalAmount'), fontSize),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 160,
      sorter: (a: SalesAnalysisData, b: SalesAnalysisData) =>
        compare(
          Number(a.totalAmount) !== undefined ? Number(a.totalAmount) : null,
          Number(b.totalAmount) !== undefined ? Number(b.totalAmount) : null
        ),
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
  ];

  /**
   * @param pagination Current pagination state
   * @param filters    Current filter state
   * @param sorter     Current sorter state
   * @param extra      Current data source and action
   */
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SalesAnalysisData> | SorterResult<SalesAnalysisData>[],
    extra: {
      currentDataSource: SalesAnalysisData[];
      action: 'paginate' | 'sort' | 'filter';
    }
  ) => {
    if (extra.action === 'sort' && !Array.isArray(sorter)) {
      if (sorter.field === 'totalGoodsNum') {
        setSortValue('GOODS_NUM');
      } else if (sorter.field === 'totalAmount') {
        setSortValue('SALES');
      }
    }
  };

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Form layout="vertical" form={form} ref={formRef}>
        <Row gutter={[16, 0]}>
          <Col span={24} md={12} lg={12} xl={8}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name={'date'}
              label={t('analysis.categorySales.date')}
              initialValue={[
                moment().subtract(8, 'days'),
                moment().subtract(1, 'days'),
              ]}
            >
              <RangePicker
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                placeholder={[
                  t('analysis.categorySales.startDate'),
                  t('analysis.categorySales.endDate'),
                ]}
                ranges={{
                  [`${t('nivo.week')}`]: [
                    moment().startOf('isoWeek'),
                    moment().endOf('isoWeek'),
                  ],
                  [`${t('nivo.month')}`]: [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  [`${t('nivo.firstHalfYear')}`]: [
                    moment().startOf('year'),
                    moment().startOf('year').add(6, 'months'),
                  ],
                  [`${t('nivo.secondHalfYear')}`]: [
                    moment().startOf('year').add(6, 'months'),
                    moment().endOf('year'),
                  ],
                  [`${t('nivo.year')}`]: [
                    moment().startOf('year'),
                    moment().endOf('year'),
                  ],
                }}
              />
            </Form.Item>
          </Col>
          {isSeller && (
            <Col span={24} md={12} lg={12} xl={8}>
              <Form.Item
                name="sellerId"
                label={t('analysis.goodsSales.seller')}
                style={{ marginBottom: 12 }}
              >
                <SellersDropdown />
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12} lg={12} xl={8}>
            <Form.Item
              label={t('analysis.categorySales.category')}
              name="catId"
              style={{ marginBottom: 12 }}
            >
              <CategoryDropdown onFocusFetch={true} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ marginBottom: 12 }}>
              <Space>
                <Button type="primary" onClick={() => getData()}>
                  {t('general.search')}
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    getData();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TableToolbar
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        totalItems={totalItem}
        columns={columns}
        columnKeys={columnKeys}
        rows={data}
        exportConfig={{
          fileName: 'CATEGORY_SALES',
        }}
      />
      <Table<SalesAnalysisData>
        onChange={onTableChange}
        dataSource={data}
        loading={isLoading}
        size={tableSize}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowKey={(sales) => `${sales.catId}`}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: SalesAnalysisData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        pagination={{
          current: page,
          total: totalItem,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (p, pSize) => {
            setPage(p);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
        }}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns}
          funct={{
            exportConfig: {
              fileName: 'CATEGORY_SALES',
            },
          }}
        />
      )}
    </>
  );
};

export default CategorySales;
