import { Form, Input, InputNumber, Modal, Spin, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';

type OrderShippingInfoModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  orderInfo?: OrderData;
};

const OrderShippingInfoModal = ({
  visible,
  setVisible,
  orderInfo,
}: OrderShippingInfoModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDriverInfo = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('order/driver', {
      params: { orderSn: orderInfo && orderInfo.orderSn },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          form.setFieldsValue({
            ...response.data,
            status: response.data.status
              ? response.data.status.description
              : '',
            routeName: response.data.route ? response.data.route.routeName : '',
            shippingDate: response.data.route
              ? response.data.route.shippingDate
              : '',
            startAt: response.data.route ? response.data.route.startAt : '',
            driverName:
              response.data.route && response.data.route.driver
                ? response.data.route.driver.driverName
                : '',
            driverPhone:
              response.data.route && response.data.route.driver
                ? response.data.route.driver.phone
                : '',
          });
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [t, orderInfo, form]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getDriverInfo();
    }
  }, [visible, getDriverInfo, form]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={t('order.orderDetail.shippingInfo.title')}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <Form form={form} labelCol={{ span: 11 }}>
          <Form.Item
            name="status"
            label={t('order.orderDetail.shippingInfo.status')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="etc"
            label={t('order.orderDetail.shippingInfo.etc')}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              bordered={false}
              readOnly
              formatter={(value) => `${value} ${value ? 'min' : ''}`}
            />
          </Form.Item>
          <Form.Item
            name="etcStartTime"
            label={t('order.orderDetail.shippingInfo.etcStartTime')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="etcEndTime"
            label={t('order.orderDetail.shippingInfo.etcEndTime')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="distance"
            label={t('order.orderDetail.shippingInfo.distance')}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              bordered={false}
              readOnly
              formatter={(value) => `${value} ${value ? 'km' : ''}`}
            />
          </Form.Item>
          <Form.Item
            name="arrival"
            label={t('order.orderDetail.shippingInfo.arrival')}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              bordered={false}
              readOnly
              formatter={(value) => `${value} ${value ? 'min' : ''}`}
            />
          </Form.Item>
          <Form.Item
            name="sequenceIndex"
            label={t('order.orderDetail.shippingInfo.sequenceIndex')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="sequenceLabel"
            label={t('order.orderDetail.shippingInfo.sequenceLabel')}
            style={{ marginBottom: 0 }}
          >
            <Tag color="#800020" style={{ marginLeft: 11 }}>
              {visible && form.getFieldValue('sequenceLabel')}
            </Tag>
          </Form.Item>
          <Form.Item
            name="countSequence"
            label={t('order.orderDetail.shippingInfo.countSequence')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="routeName"
            label={t('order.orderDetail.shippingInfo.routeName')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="shippingDate"
            label={t('order.orderDetail.shippingInfo.shippingDate')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="startAt"
            label={t('order.orderDetail.shippingInfo.startAt')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="driverName"
            label={t('order.orderDetail.shippingInfo.driverName')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="driverPhone"
            label={t('order.orderDetail.shippingInfo.driverPhone')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="driverRemark"
            label={t('order.orderDetail.shippingInfo.driverRemark')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="createdAt"
            label={t('order.orderDetail.shippingInfo.createdAt')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="deliveryAt"
            label={t('order.orderDetail.shippingInfo.deliveryAt')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="completeAt"
            label={t('order.orderDetail.shippingInfo.completeAt')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
          <Form.Item
            name="updateAt"
            label={t('order.orderDetail.shippingInfo.updateAt')}
            style={{ marginBottom: 0 }}
          >
            <Input bordered={false} readOnly />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OrderShippingInfoModal;
