import { ReloadOutlined } from '@ant-design/icons';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import {
  DEFAULT_REGION_ID,
  ORDER_DISTRI_TYPE,
} from '../../../constants/analysisConstants';
import { BLUE1, ORANGE1 } from '../../../constants/color';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  GENERAL_TIMEOUT,
  MEDIUM_FONT_SIZE,
  SMALL_FONT_SIZE,
} from '../../../constants/systemConstants';
import { NivoPieChartData, OrderDistributionData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { addCommas, addCommasPrice } from '../../../utils/helperFunction';
import {
  getBarGraphBottomAxisLabel,
  nivoToolTip,
} from '../../../utils/nivoGraphTools';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import SellersDropdown from '../../sellers/SellersDropdown';
import RegionDropdown from '../../settings/common/RegionDropdown';
import TableToolbar from '../../table/TableToolbar';

type OrderDistributionProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const OrderDistribution = ({
  isSeller,
  isLoading,
  setIsLoading,
}: OrderDistributionProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const { RangePicker } = DatePicker;
  const [vt] = useVT(() => ({ scroll: { y: 500 } }), []);
  const isSubscribed = useRef(true);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [tabMode, setTabMode] = useState<string>('PAYMENT');
  const [viewMode, setViewMode] = useState<string>(
    t('analysis.orderDistribution.totalOrderNum')
  );
  const viewTypeButtons = ['totalOrderNum', 'totalAmount'];
  //Data Components
  const [tabsData, setTabsData] = useState<{
    [key: string]: {
      pieGraphs: NivoPieChartData[][];
      barGraph: {
        label: string;
        totalOrderNum?: number;
        totalAmount?: number;
      }[];
      tableData: OrderDistributionData[];
    };
  }>({});
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const columnKeys = [
    'label',
    'totalOrderNum',
    'totalAmount',
    'orderNumPercentage',
    'amountPercentage',
  ];

  const columns: ColumnsType<OrderDistributionData> = [
    {
      title: t('analysis.orderDistribution.label'),
      key: 'label',
      dataIndex: 'label',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('analysis.orderDistribution.totalOrderNum'),
      key: 'totalOrderNum',
      dataIndex: 'totalOrderNum',
      width: 90,
      sorter: (a: OrderDistributionData, b: OrderDistributionData) =>
        compare(
          Number(a.totalOrderNum) !== undefined
            ? Number(a.totalOrderNum)
            : null,
          Number(b.totalOrderNum) !== undefined ? Number(b.totalOrderNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.orderDistribution.totalAmount'),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 90,
      sorter: (a: OrderDistributionData, b: OrderDistributionData) =>
        compare(
          Number(a.totalAmount) !== undefined ? Number(a.totalAmount) : null,
          Number(b.totalAmount) !== undefined ? Number(b.totalAmount) : null
        ),
      render: (text: string) => addCommasPrice(text),
    },
    {
      title: t('analysis.orderDistribution.orderNumPercentage'),
      key: 'orderNumPercentage',
      dataIndex: 'orderNumPercentage',
      width: 90,
      render: (text: string) => text,
    },
    {
      title: t('analysis.orderDistribution.amountPercentage'),
      key: 'amountPercentage',
      dataIndex: 'amountPercentage',
      width: 90,
      render: (text: string) => text,
    },
  ];

  /**
   * Grabs all the needed data for Order Distribution
   */
  const getData = useCallback(() => {
    const getGraphData = (data: OrderDistributionData[]) => {
      let barGraphData: {
        label: string;
        totalOrderNum?: number;
        totalAmount?: number;
      }[] = [];
      let pieGraphs: NivoPieChartData[][] = [];
      let totalOrderNumPie: NivoPieChartData[] = [];
      let totalAmountPie: NivoPieChartData[] = [];
      let tableData: OrderDistributionData[] = [];
      if (data.length > 0) {
        data.forEach((point) => {
          if (
            tabMode === ORDER_DISTRI_TYPE.hours ||
            tabMode === ORDER_DISTRI_TYPE.days
          ) {
            barGraphData.push({
              label: point.label,
              totalOrderNum: point.totalOrderNum,
              totalAmount:
                point.totalAmount !== undefined
                  ? parseFloat(point.totalAmount)
                  : 0,
            });
          } else {
            point.totalOrderNum !== undefined &&
              point.orderNumPercentage !== undefined &&
              totalOrderNumPie.push({
                id: point.label,
                value: parseFloat(point.orderNumPercentage),
                label: point.totalOrderNum,
              });
            point.totalAmount !== undefined &&
              point.amountPercentage !== undefined &&
              totalAmountPie.push({
                id: point.label,
                value: parseFloat(point.amountPercentage),
                label: parseFloat(point.totalAmount),
              });
          }
          point.totalAmount &&
            point.totalOrderNum &&
            tableData.push({
              label: point.label,
              totalAmount: point.totalAmount,
              totalOrderNum: point.totalOrderNum,
              orderNumPercentage: point.orderNumPercentage,
              amountPercentage: point.amountPercentage,
            });
        });
        if (totalOrderNumPie.length > 0 && totalAmountPie.length > 0) {
          pieGraphs.push(totalOrderNumPie);
          pieGraphs.push(totalAmountPie);
        }
        if (barGraphData.length > 0) {
          setTabsData((prev) => ({
            ...prev,
            [tabMode]: {
              barGraph: barGraphData,
              tableData: tableData,
              pieGraphs: [],
            },
          }));
        } else if (
          pieGraphs.length > 0 &&
          totalOrderNumPie.length > 0 &&
          totalAmountPie.length > 0
        ) {
          setTabsData((prev) => ({
            ...prev,
            [tabMode]: {
              pieGraphs: pieGraphs,
              tableData: tableData,
              barGraph: [],
            },
          }));
        }
      }
    };
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('analysis/order/distr', {
      params: {
        isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
        sellerId:
          getDashboardStyle().isSellerSwitch &&
          isSeller &&
          formRef.current &&
          form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        distrType: tabMode,
        startDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
          : undefined,
        endDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
          : undefined,
        regionParentId: form.getFieldValue('regions')
          ? parseInt(form.getFieldValue('regions').at(-1))
          : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            getGraphData(response.data.list);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  }, [
    t,
    form,
    setFourZeroThree,
    setFiveHundred,
    tabMode,
    isSeller,
    setIsLoading,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={isLoading}>
          <Form /**Range picker, Regions, OK, Reset, Refresh Buttons */
            form={form}
            ref={formRef}
            initialValues={{
              regions: DEFAULT_REGION_ID,
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name={'date'}
                  label={t('analysis.orderDistribution.date')}
                  initialValue={[
                    moment().subtract(8, 'days'),
                    moment().subtract(1, 'days'),
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    placeholder={[
                      t('analysis.orderDistribution.startDate'),
                      t('analysis.orderDistribution.endDate'),
                    ]}
                    ranges={{
                      [`${t('nivo.week')}`]: [
                        moment().startOf('isoWeek'),
                        moment().endOf('isoWeek'),
                      ],
                      [`${t('nivo.month')}`]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [`${t('nivo.firstHalfYear')}`]: [
                        moment().startOf('year'),
                        moment().startOf('year').add(6, 'months'),
                      ],
                      [`${t('nivo.secondHalfYear')}`]: [
                        moment().startOf('year').add(6, 'months'),
                        moment().endOf('year'),
                      ],
                      [`${t('nivo.year')}`]: [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              {isSeller && (
                <Col span={24} md={24} lg={8} xl={8}>
                  <Form.Item
                    name="sellerId"
                    label={t('analysis.goodsSales.seller')}
                  >
                    <SellersDropdown />
                  </Form.Item>
                </Col>
              )}
              <Col span={24} md={24} lg={8} xl={8}>
                {tabMode === ORDER_DISTRI_TYPE.district && (
                  <Form.Item
                    name="regions"
                    label={t('analysis.orderDistribution.regions')}
                  >
                    <RegionDropdown initialValue={DEFAULT_REGION_ID} />
                  </Form.Item>
                )}
              </Col>
              <Col span={24}>
                <Space align="start">
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          setTabsData({});
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderDistribution.ok')}
                  </Button>
                  <Button
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          form.resetFields();
                          setTabsData({});
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                  >
                    {t('analysis.orderDistribution.reset')}
                  </Button>
                </Space>
                <Tooltip title={t('general.refresh')}>
                  <Button
                    type="text"
                    onClick={() => {
                      if (typingTimeout) clearTimeout(typingTimeout);
                      setTypingTimeout(
                        setTimeout(() => {
                          getData();
                        }, GENERAL_TIMEOUT)
                      );
                    }}
                    icon={<ReloadOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
          <Tabs
            defaultActiveKey={ORDER_DISTRI_TYPE.payment}
            onTabClick={(key) => {
              setTabMode(key);
            }}
          >
            {Object.keys(ORDER_DISTRI_TYPE).map((label) => {
              return (
                <Tabs.TabPane
                  tab={t(`analysis.orderDistribution.${label}`)}
                  key={
                    ORDER_DISTRI_TYPE[label as keyof typeof ORDER_DISTRI_TYPE]
                  }
                >
                  {tabMode === ORDER_DISTRI_TYPE.hours ||
                  tabMode === ORDER_DISTRI_TYPE.days ? (
                    <div /**Bar graph only when in Hours or Days tabs */>
                      <Space align="start">
                        {viewTypeButtons.map((buttonLabel, index) => {
                          /**ViewType Buttons */
                          return (
                            <Button
                              key={index}
                              type={
                                viewMode ===
                                t(`analysis.orderDistribution.${buttonLabel}`)
                                  ? 'primary'
                                  : 'default'
                              }
                              onClick={() =>
                                setViewMode(
                                  t(`analysis.orderDistribution.${buttonLabel}`)
                                )
                              }
                            >
                              {`${t(
                                `analysis.orderDistribution.${buttonLabel}`
                              )} (${t(
                                buttonLabel === 'totalOrderNum'
                                  ? `nivo.number`
                                  : `nivo.dollar`
                              )})`}
                            </Button>
                          );
                        })}
                      </Space>

                      <div /**Bar Graph Title */
                        style={{
                          textAlign: 'center',
                          fontSize: MEDIUM_FONT_SIZE,
                        }}
                      >{`${
                        viewMode ===
                        t('analysis.orderDistribution.totalOrderNum')
                          ? t('analysis.orderDistribution.totalOrderNum')
                          : t('analysis.orderDistribution.totalAmount')
                      }`}</div>
                      {/**Bar Legend */}
                      {viewMode ===
                      t('analysis.orderDistribution.totalOrderNum') ? (
                        <div
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div
                            style={{
                              height: 15,
                              width: 15,
                              paddingRight: 10,
                              backgroundColor: BLUE1,
                              fontSize: SMALL_FONT_SIZE,
                            }}
                          />
                          <div>
                            {t('analysis.orderDistribution.totalOrderNum')}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 10,
                              height: 15,
                              width: 15,
                              paddingRight: 10,
                              backgroundColor: ORANGE1,
                              fontSize: SMALL_FONT_SIZE,
                            }}
                          />
                          <div>
                            {t('analysis.orderDistribution.totalAmount')}
                          </div>
                        </div>
                      )}
                      <div style={{ height: 500 }}>
                        {tabsData[tabMode] &&
                          tabsData[tabMode].barGraph.length > 0 && (
                            <ResponsiveBar
                              tooltip={(props) =>
                                nivoToolTip({
                                  ...props,

                                  xAxisLength:
                                    tabsData[tabMode].barGraph.length,
                                  tooltipType: 'Standard',
                                  translationSection:
                                    'analysis.orderDistribution',
                                  specialForm: 'Time',
                                })
                              }
                              theme={{
                                fontSize: DEFAULT_FONT_SIZE,
                                axis: {
                                  legend: {
                                    text: { fontSize: DEFAULT_FONT_SIZE },
                                  },
                                },
                              }}
                              colors={
                                viewMode ===
                                t('analysis.orderDistribution.totalOrderNum')
                                  ? [BLUE1]
                                  : [ORANGE1]
                              }
                              data={tabsData[tabMode].barGraph}
                              indexBy="label"
                              keys={
                                viewMode ===
                                t('analysis.orderDistribution.totalOrderNum')
                                  ? ['totalOrderNum']
                                  : ['totalAmount']
                              }
                              margin={{ top: 10, bottom: 100, left: 60 }}
                              padding={0.3}
                              enableLabel={false}
                              axisBottom={getBarGraphBottomAxisLabel(
                                undefined,
                                ORDER_DISTRI_TYPE[
                                  label as keyof typeof ORDER_DISTRI_TYPE
                                ] === ORDER_DISTRI_TYPE.hours
                                  ? 'time'
                                  : 'week'
                              )}
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend:
                                  viewMode ===
                                  t('analysis.orderDistribution.totalOrderNum')
                                    ? t('nivo.number')
                                    : t('nivo.dollar'),
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          )}
                      </div>
                    </div>
                  ) : (
                    /**Pie Graphs */
                    tabsData[tabMode] &&
                    tabsData[tabMode].pieGraphs.length > 0 && (
                      <Row style={{ marginBottom: 40 }} gutter={[0, 24]}>
                        {tabsData[tabMode].pieGraphs.map((pieGraph, index) => {
                          return (
                            <Col
                              key={index}
                              span={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={12}
                              style={{ height: 300 }}
                            >
                              <div
                                style={{
                                  textAlign: 'center',
                                  fontSize: MEDIUM_FONT_SIZE,
                                  paddingBottom: 5,
                                }}
                              >
                                {t(
                                  `analysis.orderDistribution.${
                                    index === 0
                                      ? 'totalOrderNum'
                                      : 'totalAmount'
                                  }`
                                )}
                              </div>
                              <ResponsivePie
                                colors={{ scheme: 'paired' }}
                                tooltip={(datum) =>
                                  nivoToolTip({
                                    ...datum,
                                    tooltipType: 'Standard',
                                  })
                                }
                                arcLinkLabelsStraightLength={10}
                                arcLinkLabelsDiagonalLength={10}
                                arcLinkLabelsSkipAngle={
                                  pieGraph.length < 3 ? 0 : 5
                                }
                                arcLabelsSkipAngle={
                                  pieGraph.length < 3 ? 0 : 20
                                }
                                data={pieGraph}
                                arcLabel={(d) =>
                                  d.value !== 0 ? `${d.value}%` : ''
                                }
                                arcLinkLabel={(d) =>
                                  d.value !== 0 ? `${d.id}` : ''
                                }
                                theme={{
                                  fontSize: 12,
                                  axis: {
                                    legend: {
                                      text: { fontSize: DEFAULT_FONT_SIZE },
                                    },
                                  },
                                }}
                                margin={{
                                  top: 40,
                                  left: 40,
                                  right: 40,
                                  bottom: 40,
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    )
                  )}
                  {tabsData[tabMode] &&
                    tabsData[tabMode].tableData.length >
                      0 /**Export Table */ && (
                      <div style={{ width: '100%' }}>
                        <TableToolbar
                          columns={
                            tabMode === ORDER_DISTRI_TYPE.hours ||
                            tabMode === ORDER_DISTRI_TYPE.days
                              ? columns.filter(
                                  (x) =>
                                    x.key !== 'orderNumPercentage' &&
                                    x.key !== 'amountPercentage'
                                )
                              : columns
                          }
                          columnKeys={
                            tabMode === ORDER_DISTRI_TYPE.hours ||
                            tabMode === ORDER_DISTRI_TYPE.days
                              ? columnKeys.filter(
                                  (x) =>
                                    x !== 'orderNumPercentage' &&
                                    x !== 'amountPercentage'
                                )
                              : columnKeys
                          }
                          rows={tabsData[tabMode].tableData.map((data) => ({
                            ...data,
                          }))}
                          exportConfig={{
                            fileName: 'ORDER_DISTRIBUTION_ANALYSIS',
                          }}
                        />
                        <Table<OrderDistributionData>
                          dataSource={tabsData[tabMode].tableData}
                          rowKey={(obj) => obj.label}
                          columns={
                            tabMode === ORDER_DISTRI_TYPE.hours ||
                            tabMode === ORDER_DISTRI_TYPE.days
                              ? columns.filter(
                                  (x) =>
                                    x.key !== 'orderNumPercentage' &&
                                    x.key !== 'amountPercentage'
                                )
                              : columns
                          }
                          components={vt}
                          scroll={{
                            y: 500,
                            x: 500,
                          }}
                          loading={isLoading}
                          pagination={false}
                          rowSelection={undefined}
                          size="small"
                        />
                      </div>
                    )}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Spin>
      )}
    </>
  );
};

export default OrderDistribution;
