import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { GoodData, Promote, PurchaseLimit } from '../../../types';

type MainGoodsOverviewProps = {
  goodInfo: GoodData;
};

const MainGoodsOverview = ({ goodInfo }: MainGoodsOverviewProps) => {
  const { t } = useTranslation();
  const labels: Array<keyof GoodData> = [
    'goodsId',
    'goodsNumber',
    'goodsUnit',
    'gst',
    'pst',
    'isOnSale',
    'shopPrice',
  ];
  const promoteGoodsLabel: Array<keyof Promote> = ['isPromote', 'promotePrice'];
  const purchaseLimitGoodsLabel: Array<keyof PurchaseLimit> = [
    'isPurchaseLimit',
    'purchaseLimitNum',
  ];

  const renderDescriptionItems = (
    labelKeys: string[],
    data: any,
    translationKey: string
  ) => {
    return labelKeys.map((label) => (
      <Descriptions.Item
        key={label}
        labelStyle={{ fontWeight: 'bold' }}
        label={`${t(`${translationKey}.${label}`)} `}
      >
        {typeof data[label] === 'boolean'
          ? t(`general.booleanToStatus.${data[label].toString()}`)
          : data[label]?.toString() || 'N/A'}
      </Descriptions.Item>
    ));
  };

  const renderItemDate = (
    label: string,
    startTime: string,
    endTime: string
  ) => {
    return (
      <Descriptions.Item
        label={`${t(`${label}`)} `}
        labelStyle={{ fontWeight: 'bold' }}
        span={2}
      >
        {startTime ? `${startTime} - ${endTime}` : 'N/A'}
      </Descriptions.Item>
    );
  };

  return (
    <Descriptions
      title={`${t('goods.goodsListColumns.mainGoodsName')}: ${
        goodInfo.mainGoods.goodsName
      }`}
      layout="vertical"
      bordered
      // column={2}
      size="small"
    >
      {renderDescriptionItems(
        labels,
        goodInfo.mainGoods,
        'goods.goodsListColumns'
      )}

      {renderDescriptionItems(
        promoteGoodsLabel,
        goodInfo.mainGoods.promote,
        'goods.goodsListColumns.promote'
      )}

      {renderItemDate(
        `goods.goodsListColumns.promote.promoDate`,
        goodInfo.mainGoods.promote.promoteStartDate,
        goodInfo.mainGoods.promote.promoteEndDate
      )}

      {renderDescriptionItems(
        purchaseLimitGoodsLabel,
        goodInfo.mainGoods.purchaseLimit,
        'goods.goodsListColumns.purchaseLimit'
      )}

      {renderItemDate(
        `goods.goodsListColumns.purchaseLimit.purchaseLimitDate`,
        goodInfo.mainGoods.purchaseLimit.purchaseLimitStartDate,
        goodInfo.mainGoods.purchaseLimit.purchaseLimitEndDate
      )}
    </Descriptions>
  );
};

export default MainGoodsOverview;
