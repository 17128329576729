import { Modal, Form, Select, Input, Space, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicEnumInfoType, UserListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';

type AdjustAccountProps = {
  id: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  accountTypes: BasicEnumInfoType[];
  userInfo?: UserListData;
  refresh?: () => void;
};

const AdjustAccountModal = ({
  id,
  visible,
  setVisible,
  accountTypes,
  userInfo,
  refresh,
}: AdjustAccountProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [type, setType] = useState<string>('');
  const isSubscribed = useRef(true);
  let amountOnAccount: any = {
    USER_MONEY: userInfo?.userMoney,
    FROZEN_MONEY: userInfo?.frozenMoney,
    RANK_POINTS: userInfo?.rankPoints,
    PAY_POINTS: userInfo?.payPoints,
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields(['amount']);
    }
  }, [form, visible, type]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setLoading(true);
        postDataWithAuthToken('users/account_log/add', {
          accountType: values.accountType,
          amount: Number(values.amount).toString(),
          changeDesc: values.changeDesc,
          userId: id,
        }).then((response) => {
          if (response && response.goodStatus) {
            form.resetFields();
            if (isSubscribed.current) {
              setType('');
              setVisible(false);
            }
            if (refresh) refresh();
            alertMessage('success', t('users.alerts.accountLogAdjusted'));
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }

          if (isSubscribed.current) setLoading(false);
        });
      })
      .catch((err) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setType('');
        setVisible(false);
        form.resetFields();
      }}
      onOk={onSubmit}
      okText={t('users.adjustAccountLogs.ok')}
      cancelText={t('users.adjustAccountLogs.cancel')}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item label={t('users.adjustAccountLogs.username')}>
            {userInfo?.userName}
          </Form.Item>
          <Form.Item
            name="changeDesc"
            label={t('users.adjustAccountLogs.changeDesc')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
          <Form.Item
            name="accountType"
            label={t('users.adjustAccountLogs.accountType')}
            rules={[
              {
                required: true,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              style={{ width: 200 }}
              onChange={(value) => setType(value ? value.toString() : '')}
            >
              {accountTypes
                .filter((type) => type.code !== 'ALL')
                .map((account) => (
                  <Select.Option key={account.code} value={account.code}>
                    {account.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {type && (
            <Form.Item
              name="amount"
              label={t('users.adjustAccountLogs.amount')}
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseInputAmount'),
                },
                () => ({
                  validator(_, value) {
                    if (value) {
                      if (isNaN(value)) {
                        return Promise.reject(
                          new Error(t('general.inputError.pleaseInputAmount'))
                        );
                      }
                      if (type === 'PAY_POINTS' || type === 'RANK_POINTS') {
                        if (value > 9999 || value < -9999) {
                          return Promise.reject(
                            new Error(
                              t('users.inputError.integerAmountOutOfRange')
                            )
                          );
                        } else if (value && value.indexOf('.') !== -1) {
                          return Promise.reject(
                            t('users.inputError.integerOnly')
                          );
                        }
                      }

                      if (value > 9999.99 || value < -9999.99) {
                        return Promise.reject(
                          new Error(t('users.inputError.floatAmountOutOfRange'))
                        );
                      }

                      if (
                        value.toString().indexOf('.') !== -1 &&
                        value
                          .toString()
                          .substring(value.toString().indexOf('.') + 1).length >
                          2
                      ) {
                        return Promise.reject(
                          new Error(t('users.inputError.twoDecimalsOnly'))
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Space>
                <Input />
                <>{`(${t('users.adjustAccountLogs.currAmount')}: ${
                  amountOnAccount[type]
                })`}</>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default AdjustAccountModal;
