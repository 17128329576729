import React, { useEffect, useState, useCallback, useRef } from 'react';
import Container from '../../components/Container';
import { Card, Row, Col, Skeleton } from 'antd';
import { GoodData, GoodEnums } from '../../types';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import FourZeroThree from '../../components/FourZeroThree';
import { addPage } from '../../features/pageHistory/pageHistorySlice';
import FourZeroFour from '../../components/FourZeroFour';
import { alertMessage } from '../../utils/alertMessage';
import GoodPhoto from '../../components/goods/goodDetail/GoodPhoto';
import GoodDescription from '../../components/goods/goodDetail/GoodDescription';
import GoodGallery from '../../components/goods/goodDetail/GoodGallery';
import GoodPrice from '../../components/goods/goodDetail/GoodPrice';
import GoodSpecialInfo from '../../components/goods/goodDetail/GoodSpecialInfo';
import GoodOtherInfo from '../../components/goods/goodDetail/GoodOtherInfo';
import GoodClassification from '../../components/goods/goodDetail/GoodClassification';
import GoodDescriptionEditor from '../../components/goods/goodDetail/GoodDescriptionEditor';
import GoodSpecs from '../../components/goods/goodDetail/GoodSpecs';
import FiveHundred from '../../components/FiveHundred';
import { dashboardRoute } from '../../constants/pathname';
import getDashboardStyle from '../../utils/getDashboardStyle';

/**
 * Detail Page for an individual good
 * /dashboard/goods/detail?good_id=:goodId
 */
const GoodDetail = () => {
  const queryString = window.location.search;
  const [goodId] = useState(new URLSearchParams(queryString).get('good_id'));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [goodInfo, setGoodInfo] = useState<GoodData>();
  const [goodEnums, setGoodEnums] = useState<GoodEnums>();
  const [loading, setLoading] = useState(false);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const isSubscribed = useRef(true);
  const [fourZeroFour, setFourZeroFour] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const [isCurrentEditing, setIsCurrentEditing] = useState(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`goods/detail?goodsId=${goodId}`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            if (response.data) {
              setGoodInfo(response.data);
              document.title = `${t('goods.goodsDetail')} ${
                response.data.goodsName
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('goods.goodsDetail')} ${response.data.goodsName}`,
                  path: `${dashboardRoute.goods.detail}?good_id=${goodId}`,
                })
              );
            } else setFourZeroFour(true);
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          if (isSubscribed.current) setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [goodId, dispatch, t]);

  // Fetch good enums
  const getGoodEnums = useCallback(() => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodEnums(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getGoodEnums();
  }, [getGoodEnums]);

  return (
    <Container style={{ marginTop: 5 }}>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fourZeroFour ? (
        <Card>
          <FourZeroFour />
        </Card>
      ) : fiveHundred ? (
        <FiveHundred />
      ) : (
        <Row gutter={[5, 5]}>
          <Col span={24} lg={4}>
            <Card
              style={{ height: '100%' }}
              bodyStyle={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Skeleton active loading={loading}>
                <GoodPhoto
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24} lg={20}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodDescription
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodClassification
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodPrice
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodSpecs
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodSpecialInfo
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodOtherInfo
                  goodInfo={goodInfo}
                  goodEnums={goodEnums}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodGallery
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton active loading={loading}>
                <GoodDescriptionEditor
                  goodInfo={goodInfo}
                  callBack={getData}
                  isCurrentEditing={isCurrentEditing}
                  setIsCurrentEditing={setIsCurrentEditing}
                />
              </Skeleton>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default GoodDetail;
