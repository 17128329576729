import React, { useState, useEffect, useCallback } from 'react';
import { Button, Divider, Drawer, Grid, Layout, Menu } from 'antd';
import getDashboardStyle from '../utils/getDashboardStyle';
import { useTranslation } from 'react-i18next';
import { RouteConfig, matchRoutes } from 'react-router-config';
import { routes } from '../constants/routes';
import { useTab } from '../hooks/useTab';
import { dashboardRoute } from '../constants/pathname';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import { setSidebarCollapsed } from '../features/appConfig/appConfigSlice';
import { CloseOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import UserHeaderDropdown from './UserHeaderDropdown';
import LanguageSubMenu from './LanguageSubMenu';
import { setActivePage } from '../features/pageHistory/pageHistorySlice';
import { BLUE4, WHITE } from '../constants/color';

const { Sider } = Layout;
const { SubMenu } = Menu;

type SideBarProps = {
  route?: RouteConfig;
  isMobile?: boolean;
};

/**
 * Sidebar that appears in the dashboard
 * Should contain all links that user can go to
 *
 * TODO: Only show the links that user is authorized to access
 *
 * @param setIsCollapsed Change width of Sidebar
 * @param route          Route information from react-router-config
 */
const Sidebar = ({ route, isMobile }: SideBarProps) => {
  const { t } = useTranslation();
  const activePage = useAppSelector((state) => state.pageHistory.activePage);
  const collapsed = useAppSelector((state) => state.appConfig.sidebarCollapsed);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { addTab } = useTab();
  const dispatch = useDispatch();
  const [showDrawer, setShowDrawer] = useState(false);
  const [routeConfigs, setRouteConfigs] = useState<RouteConfig[]>([]);

  /**
   * Gets The RouteConfig for specific Sub Group
   *
   * @param   path         The path for the Sub Group of interest
   * @returns RouteConfig  The RouteConfig if it exists
   */
  const getSubGroup: (path: string) => RouteConfig = useCallback(
    (path: string) => {
      if (route && route.routes) {
        const matchingRoute = matchRoutes(route.routes, path);
        if (matchingRoute.length === 1 && matchingRoute[0].route) {
          return matchingRoute[0].route;
        }
      }
      return {};
    },
    [route]
  );

  useEffect(() => {
    let paths = [
      dashboardRoute.admins.path,
      dashboardRoute.users.path,
      dashboardRoute.salesperson.path,
      dashboardRoute.goods.path,
      dashboardRoute.order.path,
      dashboardRoute.activity.path,
      dashboardRoute.analysis.path,
      dashboardRoute.advertisement.path,
      dashboardRoute.sellers.path,
      dashboardRoute.sortationSystem.path,
      dashboardRoute.plugins.path,
      dashboardRoute.settings.path,
      dashboardRoute.profile,
    ];

    let configs = [];

    for (let path of paths) {
      if (
        path === dashboardRoute.profile &&
        getDashboardStyle().isSalesForceAppType
      ) {
        continue;
      }
      path !== undefined && configs.push(getSubGroup(path));
    }

    setRouteConfigs(configs);
  }, [getSubGroup]);

  /**
   * If link belongs to a parent it should be open by default
   *
   * @param  path           URL path
   * @return Array<string>  The parent that should be open by default
   * @return []             The path does not belong to a parent
   */
  const getOpenKey = (path: string): Array<string> => {
    if (route && route.routes) {
      const matchedRoutes = matchRoutes(route.routes, path).filter(
        (x) => x.match.isExact
      );
      if (matchedRoutes.length > 0) {
        // For example, to keep "plugins-> mail-> mail setting" open,
        // Name parent key as 'plugins-mail' in the route config file
        return matchedRoutes[0].route.parent
          ? matchedRoutes[0].route.parent.split('-')
          : [];
      }
    }
    return [];
  };

  /**
   * @param value Whether Sidebar should be open or collapsed
   */
  const onCollapse = (value: boolean) => {
    dispatch(setSidebarCollapsed(value));
  };

  /**
   * Get tab title based on URL
   * @param   path    URL path
   * @returns string  Tab title
   */
  const getTabTitle = (path: string) => {
    const matchedRoutes = matchRoutes(routes, path).filter(
      (x) => x.match.isExact
    );
    if (matchedRoutes.length > 0) {
      return matchedRoutes[0].route.title;
    }
    return '';
  };

  /**
   * Menu item to be shown based on the user profile
   * @param   actions  string[]  user permissions
   * @returns          boolean   whether the menu item should be displayed or not
   */
  const hasSideBarPermission = (actions?: string[]) => {
    let user: string = localStorage.getItem('profile') || '{}';
    let hideFromNav = getDashboardStyle().hideFromNav;
    if (user && JSON.parse(user).actionList) {
      if (JSON.parse(user).actionList === 'all') {
        if (
          actions &&
          actions.length &&
          hideFromNav.length &&
          actions.every((element) => hideFromNav.includes(element))
        ) {
          return false;
        }
        return true;
      }
      if (actions && actions.length) {
        for (let action of actions) {
          if (
            JSON.parse(user).actionList.split(',').includes(action) &&
            !hideFromNav.includes(action)
          ) {
            return true;
          }
        }
        return false;
      }
      return true;
    }
    return false;
  };

  const renderMenuItem = (configs?: RouteConfig[]) =>
    configs?.map((routeConfig) => {
      const Icon = routeConfig.icon;
      return routeConfig.routes
        ? hasSideBarPermission(routeConfig.actions) && (
            <SubMenu
              key={typeof routeConfig.path === 'string' ? routeConfig.path : ''}
              icon={Icon ? <Icon /> : <></>}
              title={t(routeConfig.title)}
            >
              {renderMenuItem(routeConfig.routes)}
            </SubMenu>
          )
        : hasSideBarPermission(routeConfig.actions) && !routeConfig.hide && (
            <Menu.Item
              key={typeof routeConfig.path === 'string' ? routeConfig.path : ''}
              icon={Icon ? <Icon /> : <></>}
            >
              {t(routeConfig.title)}
            </Menu.Item>
          );
    });

  return isMobile ? (
    showDrawer ? (
      <Drawer
        visible={showDrawer}
        closable={false}
        placement="left"
        onClose={() => setShowDrawer(false)}
        headerStyle={{ backgroundColor: BLUE4, padding: 0 }}
        bodyStyle={{ backgroundColor: BLUE4 }}
        width={200}
        title={
          <div>
            <Button
              type="link"
              block
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: WHITE,
                fontSize: 20,
                fontWeight: 500,
                padding: 0,
                margin: '16px 0',
              }}
              icon={
                <img
                  src={getDashboardStyle().icon}
                  width={32}
                  alt="logo"
                  style={{ marginRight: 10 }}
                />
              }
              onClick={() => {
                document.title = `${t('dashboard.title')} - ${t(
                  getDashboardStyle().title
                )}`;
                window.history.pushState({}, '', dashboardRoute.home);
                dispatch(setActivePage(dashboardRoute.home));
              }}
            >
              {t(getDashboardStyle().title)}
            </Button>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LanguageSubMenu />
              <Divider type="vertical" style={{ backgroundColor: WHITE }} />
              <UserHeaderDropdown />
            </div>
          </div>
        }
      >
        <Menu
          theme="dark"
          defaultOpenKeys={getOpenKey(activePage)}
          selectedKeys={[activePage]}
          mode="inline"
          onClick={({ key }) => {
            let title = getTabTitle(key);
            document.title = `${t(title)} - ${t(getDashboardStyle().title)}`;
            addTab(title, key);
            setShowDrawer(false);
          }}
        >
          {renderMenuItem(routeConfigs)}
        </Menu>
        <Button
          size="middle"
          icon={<CloseOutlined style={{ color: WHITE }} />}
          onClick={() => setShowDrawer(false)}
          style={{
            position: 'fixed',
            left: 200,
            top: 90,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 8,
            border: 'none',
            backgroundColor: BLUE4,
          }}
        />
      </Drawer>
    ) : (
      <Button
        size="middle"
        icon={<MenuUnfoldOutlined style={{ color: WHITE }} />}
        onClick={() => setShowDrawer(true)}
        style={{
          zIndex: 3,
          position: 'fixed',
          left: 0,
          top: 90,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 8,
          border: 'none',
          backgroundColor: BLUE4,
        }}
      />
    )
  ) : (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{
        position: 'fixed',
        maxHeight: '100%',
        minHeight: screens.sm ? window.innerHeight - 48 : '100%',
        overflow: 'overlay',
      }}
      collapsedWidth={55}
      breakpoint="sm"
      onBreakpoint={(broken) => {
        if (broken) {
          dispatch(setSidebarCollapsed(true));
        } else {
          dispatch(setSidebarCollapsed(false));
        }
      }}
    >
      <Button
        type="link"
        block
        style={{
          display: 'flex',
          justifyContent: 'center',
          color: WHITE,
          fontSize: 20,
          fontWeight: 500,
          padding: 0,
          margin: '16px 0',
        }}
        icon={
          <img
            src={getDashboardStyle().icon}
            width={32}
            alt="logo"
            style={{ marginRight: collapsed ? 0 : 10 }}
          />
        }
        onClick={() => {
          document.title = `${t('dashboard.title')} - ${t(
            getDashboardStyle().title
          )}`;
          window.history.pushState({}, '', dashboardRoute.home);
          dispatch(setActivePage(dashboardRoute.home));
        }}
      >
        {!collapsed && t(getDashboardStyle().title)}
      </Button>
      {!collapsed && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LanguageSubMenu />
          <Divider type="vertical" style={{ backgroundColor: WHITE }} />
          <UserHeaderDropdown />
        </div>
      )}
      <Menu
        theme="dark"
        defaultOpenKeys={getOpenKey(activePage)}
        selectedKeys={[activePage]}
        mode="inline"
        onClick={({ key }) => {
          let title = getTabTitle(key);
          document.title = `${t(title)} - ${t(getDashboardStyle().title)}`;
          addTab(title, key);
        }}
      >
        {renderMenuItem(routeConfigs)}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
