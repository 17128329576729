import { Form, Modal, Spin, Image, Rate, Switch, Space, Input } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodCommentData } from '../../types';

import 'react-quill/dist/quill.snow.css';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';
import { EyeOutlined } from '@ant-design/icons';
import { FALLBACK_IMG } from '../../constants/styles';

type GoodCommentsModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGoodComment?: GoodCommentData;
  refresh: Function;
};

const GoodCommentsModal = ({
  visible,
  setVisible,
  loading,
  setLoading,
  selectedGoodComment,
  refresh,
}: GoodCommentsModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  //Data components
  //Text Editor Components

  // Sets isSubscribed, quillRef to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        const { commentRank, ...rest } = values;
        postDataWithAuthToken('goods/comment/edit', {
          ...rest,
          commentId: selectedGoodComment
            ? selectedGoodComment.commentId
            : undefined,
          adminReplyContent: rest.adminReplyContent,
          isEnabled:
            selectedGoodComment?.isEnabled !== rest.isEnabled
              ? rest.isEnabled
              : undefined,
          isUseful:
            selectedGoodComment?.isUseful !== rest.isUseful
              ? rest.isUseful
              : undefined,
        }).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage('success', t('goods.alerts.goodsCommentEdited'));
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      onOk={onFinish}
      onCancel={onClose}
      bodyStyle={{ height: 'auto' }}
      title={`${t('goods.editGoodCommentsList.editTitle')} ${
        selectedGoodComment?.commentId
      }`}
      visible={visible}
    >
      <Spin spinning={loading}>
        {selectedGoodComment ? (
          <Form
            form={form}
            initialValues={{
              ...selectedGoodComment,
              adminReplyContent: selectedGoodComment.adminReply
                ? selectedGoodComment.adminReply.content
                : '',
            }}
            layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item label={`${t('goods.editGoodCommentsList.userId')}: `}>
              {selectedGoodComment.userId ? selectedGoodComment.userId : ''}
            </Form.Item>
            <Form.Item label={`${t('goods.editGoodCommentsList.orderId')}: `}>
              {selectedGoodComment.orderId ? selectedGoodComment.orderId : ''}
            </Form.Item>
            {selectedGoodComment.goods && (
              <Form.Item
                label={`${t('goods.editGoodCommentsList.goodInfo')}: `}
              >
                {`${selectedGoodComment.goods.fullGoodsName} (${selectedGoodComment.goods.goodsId})`}
              </Form.Item>
            )}
            {selectedGoodComment.goods && (
              <Form.Item label={`${t('goods.editGoodCommentsList.image')}: `}>
                {
                  <Image
                    id={selectedGoodComment.goods.goodsId.toString()}
                    width={80}
                    src={selectedGoodComment.goods.largePic}
                    preview={{ mask: <EyeOutlined /> }}
                    fallback={FALLBACK_IMG}
                  />
                }
              </Form.Item>
            )}
            {selectedGoodComment.commentRank && (
              <Form.Item
                label={`${t('goods.editGoodCommentsList.commentRank')}: `}
                name={'commentRank'}
              >
                <Rate allowHalf disabled />
              </Form.Item>
            )}
            <Form.Item label={`${t('goods.editGoodCommentsList.content')}: `}>
              {selectedGoodComment.content ? selectedGoodComment.content : ''}
            </Form.Item>
            <Form.Item label={`${t('goods.editGoodCommentsList.ipAddress')}: `}>
              {selectedGoodComment.ipAddress
                ? selectedGoodComment.ipAddress
                : ''}
            </Form.Item>
            <Form.Item label={`${t('goods.editGoodCommentsList.addTime')}: `}>
              {selectedGoodComment.addTime ? selectedGoodComment.addTime : ''}
            </Form.Item>
            <Form.Item
              name="isEnabled"
              valuePropName="checked"
              label={`${t('goods.editGoodCommentsList.isEnabled')}: `}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="isUseful"
              valuePropName="checked"
              label={`${t('goods.editGoodCommentsList.isUseful')}: `}
            >
              <Switch />
            </Form.Item>
            {selectedGoodComment.commentImgList.length > 0 && (
              <Form.Item
                label={`${t('goods.editGoodCommentsList.commentImgList')}: `}
              >
                <Space size={0} style={{ width: '100%', overflow: 'auto' }}>
                  {selectedGoodComment.commentImgList.map((commentImg) => {
                    return (
                      <Image
                        key={commentImg.commentImgId}
                        width={100}
                        src={commentImg.commentImgPath}
                        preview={{ mask: <EyeOutlined /> }}
                        fallback={FALLBACK_IMG}
                      />
                    );
                  })}
                </Space>
              </Form.Item>
            )}

            <Form.Item
              name="adminReplyContent"
              label={`${t('goods.editGoodCommentsList.adminReply')}: `}
            >
              <Input />
            </Form.Item>
          </Form>
        ) : (
          <></>
        )}
      </Spin>
    </Modal>
  );
};

export default GoodCommentsModal;
