import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import {
  Card,
  Grid,
  Typography,
  Table,
  Button,
  Space,
  Popover,
  Form,
  Select,
  Badge,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import FiveHundred from '../../../components/FiveHundred';
import FourZeroThree from '../../../components/FourZeroThree';
import ShippingModal from '../../../components/settings/shipping/ShippingModal';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../../constants/color';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { FontSizeType, ShippingData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import { hasPermission } from '../../../utils/hasPermission';
import { tableScrollToTop } from '../../../utils/helperFunction';

const ShippingList = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [shippingData, setShippingData] = useState<ShippingData[]>([]);
  const [editShipping, setEditShipping] = useState<ShippingData>();
  const [showModal, setShowModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'shippingId',
    'shippingCode',
    'shippingName',
    'shippingDesc',
    'insureFee',
    'supportCod',
    'isEnabled',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<ShippingData[]>([]);
  const [advance, setAdvance] = useState<{
    isEnabled?: string;
    isDeliveryOnly?: string;
  }>({});

  const columns: ColumnsType<ShippingData> = [
    {
      title: setFont(t('settings.shippingListColumns.shippingId'), fontSize),
      width: 120,
      key: 'shippingId',
      dataIndex: 'shippingId',
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string, record: ShippingData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.settingGroup.shipView)}
          onClick={() => {
            setEditShipping(record);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: ShippingData, b: ShippingData) =>
        compare(a.shippingId, b.shippingId),
    },
    {
      title: setFont(t('settings.shippingListColumns.shippingCode'), fontSize),
      key: 'shippingCode',
      dataIndex: 'shippingCode',
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: ShippingData, b: ShippingData) =>
        compare(a.shippingCode, b.shippingCode),
    },
    {
      title: setFont(t('settings.shippingListColumns.shippingName'), fontSize),
      key: 'shippingName',
      dataIndex: 'shippingName',
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: ShippingData, b: ShippingData) =>
        compare(a.shippingName, b.shippingName),
    },
    {
      title: setFont(t('settings.shippingListColumns.shippingDesc'), fontSize),
      key: 'shippingDesc',
      dataIndex: 'shippingDesc',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.shippingListColumns.insureFee'), fontSize),
      key: 'insureFee',
      dataIndex: 'insureFee',
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: ShippingData, b: ShippingData) =>
        compare(a.insureFee, b.insureFee),
    },
    {
      title: setFont(t('settings.shippingListColumns.supportCod'), fontSize),
      key: 'supportCod',
      dataIndex: 'supportCod',
      width: 140,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('settings.shippingListColumns.isEnabled'), fontSize),
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      width: 140,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 80,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: ShippingData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.settingGroup.shipManage)}
            onClick={() => {
              setEditShipping(record);
              setShowModal(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
        </Space>
      ),
    },
  ];

  /**
   * makes request call and loads the shippingData variable
   */
  const getShippingData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('shipping/list', {
      params: {
        keyword: keyword || undefined,
        isEnabled:
          formRef.current &&
          typeof form.getFieldValue('isEnabled') === 'boolean'
            ? form.getFieldValue('isEnabled')
            : undefined,
        isDeliveryOnly:
          formRef.current &&
          typeof form.getFieldValue('isDeliveryOnly') === 'boolean'
            ? form.getFieldValue('isDeliveryOnly')
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setShippingData(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, keyword, form]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getShippingData();
  }, [getShippingData]);

  /**
   * This popover displays, and handles the filters*/
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('settings.shippingListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
          initialValues={{ isEnabled: 'all', isDeliveryOnly: 'all' }}
        >
          <Form.Item
            name="isEnabled"
            label={t('settings.shippingListColumns.advancedSearch.isEnabled')}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onSelect={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  isEnabled: value === 'all' ? '' : value.toString(),
                }));
              }}
              filterOption={false}
              optionLabelProp="key"
              placeholder={`${t('general.pleaseSelect')}`}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="isDeliveryOnly"
            label={t(
              'settings.shippingListColumns.advancedSearch.isDeliveryOnly'
            )}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onSelect={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  isDeliveryOnly: value === 'all' ? '' : value.toString(),
                }));
              }}
              filterOption={false}
              optionLabelProp="key"
              placeholder={`${t('general.pleaseSelect')}`}
            >
              <Select.Option key={`${t('general.all')}`} value="all">
                {t('general.all')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.true')}`}
                value={true}
              >
                {t('general.booleanToStatus.true')}
              </Select.Option>
              <Select.Option
                key={`${t('general.booleanToStatus.false')}`}
                value={false}
              >
                {t('general.booleanToStatus.false')}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getShippingData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('settings.shippingListColumns.advancedSearch.search')}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getShippingData();
                }}
              >
                {t('settings.shippingListColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('settings.shippingListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('settings.shippingListColumns.title')}
          </Typography.Title>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getShippingData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
            }}
            searchPlaceholder={t('searchPlaceholders.searchShipping')}
            advancedSearch={advancedSearch}
            rows={shippingData}
            exportConfig={{ fileName: 'SHIPPING_LIST' }}
          />
          <Table<ShippingData>
            dataSource={shippingData}
            rowKey={(shipping) => shipping.shippingId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            scroll={{
              x: 'max-content',
              y: shippingData.length > 10 ? 500 : undefined,
            }}
            size={tableSize}
            loading={isLoading}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: ShippingData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            pagination={false}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'SHIPPING_LIST' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <ShippingModal
        visible={showModal}
        setVisible={setShowModal}
        shippingInfo={editShipping}
        refresh={() => getShippingData()}
      />
    </Container>
  );
};

export default ShippingList;
