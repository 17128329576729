import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Dropdown,
  Form,
  Grid,
  Menu,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import FourZeroThree from '../../../components/FourZeroThree';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GREEN1 } from '../../../constants/color';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import { FontSizeType, ValueCard, ValueCardEnum } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import FiveHundred from '../../FiveHundred';
import ValueCardModal from '../../activity/ValueCardModal';
import ValueCardRecordListModal from '../../activity/ValueCardRecordListModal';
import SellersDropdown from '../../sellers/SellersDropdown';
import UsersDropdown from '../common/UsersDropdown';
import {
  mergeParamsToString,
  tableScrollToTop,
} from '../../../utils/helperFunction';

type UserValueCardTableProps = {
  userId?: string;
};
const UserValueCardTable = ({ userId }: UserValueCardTableProps) => {
  //General Components
  const queryString = window.location.search;
  const [vctID] = useState(new URLSearchParams(queryString).get('vct_id'));
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { addTab } = useTab();
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const [isLoading, setIsLoading] = useState(true);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [modalVisible, setModalVisible] = useState<{
    visible: boolean;
    type: 'EDIT' | 'ASSIGN_USER' | 'ADD_MULTIPLE' | 'ADD_SINGLE';
  }>();
  const [valueCardRecordModalVisible, setValueCardRecordModalVisible] =
    useState<boolean>(false);
  const [keyword, setKeyword] = useState('');
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  //Data Components
  const [selectedValueCard, setSelectedValueCard] = useState<ValueCard>();
  const [valueCardData, setValueCardData] = useState<ValueCard[]>([]);
  const [valueCardEnum, setValueCardEnum] = useState<ValueCardEnum>();
  const [isSeller, setIsSeller] = useState(false);

  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  //Table Components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const columnKeys = [
    'vcId',
    'vcName|shopName',
    'userId',
    'valueCardSn',
    'valueCardPassword',
    'vcStatus',
    'cardMoney',
    'addTime|bindTime|endTime',
    'valueCardType',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    vctID ? columnKeys : columnKeys.filter((key) => key !== 'userId')
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<ValueCard[]>([]);

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('value_card/export/excel?', params);
  };

  const getFormParams = useCallback(() => {
    return {
      vctId: vctID ? vctID : undefined,
      userId:
        userId !== undefined
          ? userId
          : formRef.current && form.getFieldValue('userId')
          ? form.getFieldValue('userId')
          : undefined,
      keyword: keyword ? keyword : undefined,
      isSeller: isSeller,
      page: page,
      size: pageSize,
      vcStatus:
        formRef.current && form.getFieldValue('vcStatus')
          ? form.getFieldValue('vcStatus')
          : undefined,
      sellerId:
        formRef.current && form.getFieldValue('sellerId')
          ? form.getFieldValue('sellerId')
          : undefined,
    };
  }, [page, pageSize, form, vctID, userId, keyword, isSeller]);

  //Makes a request and set the order list data
  const getData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('value_card/list', {
      params: getFormParams(),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setValueCardData(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, getFormParams]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const columns: ColumnsType<ValueCard> = [
    {
      title: setFont(t('activity.valueCardColumns.vcId'), fontSize),
      dataIndex: 'vcId',
      key: 'vcId',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: ValueCard, b: ValueCard) => compare(a.vcId, b.vcId),
      render: (text: string, record: ValueCard) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.activityGroup.valueCard)}
          onClick={() => {
            setSelectedValueCard(record);
            setModalVisible({ visible: true, type: 'EDIT' });
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(
        t('activity.valueCardColumns.vctId'),
        fontSize,
        `${t('activity.valueCardColumns.vcName')}|${t(
          'activity.valueCardColumns.shopName'
        )}`
      ),
      key: 'vcName|shopName',
      width: 150,
      sorter: (a: ValueCard, b: ValueCard) => compare(a.vctId, b.vctId),
      render: (text: string, record: ValueCard) =>
        setFont(
          `${record.valueCardType.vcName}(${
            record.valueCardType.seller
              ? record.valueCardType.seller.shopName
              : ''
          })`,
          fontSize
        ),
    },
    {
      title: setFont(t('activity.valueCardColumns.userId'), fontSize),
      dataIndex: 'userId',
      key: 'userId',
      width: 80,
      render: (text: number) =>
        text === 0 ? (
          <Typography.Text style={{ fontSize: fontSize }} type="secondary">
            {text}
          </Typography.Text>
        ) : (
          <Button
            disabled={!hasPermission(actionPermissions.userGroup.userView)}
            style={{ padding: 0, fontSize: fontSize }}
            type="link"
            onClick={() =>
              addTab('', `${dashboardRoute.users.detail}?user_id=${text}`)
            }
          >
            {text}
          </Button>
        ),
    },
    {
      title: setFont(t('activity.valueCardColumns.valueCardSn'), fontSize),
      dataIndex: 'valueCardSn',
      key: 'valueCardSn',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('activity.valueCardColumns.valueCardPassword'),
        fontSize
      ),
      dataIndex: 'valueCardPassword',
      key: 'valueCardPassword',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardColumns.cardMoney'), fontSize),
      dataIndex: 'cardMoney',
      key: 'cardMoney',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.valueCardColumns.vcStatus'), fontSize),
      dataIndex: 'vcStatus',
      key: 'vcStatus',
      width: 120,
      render: (text: string) =>
        valueCardEnum &&
        valueCardEnum.vcStatus.length > 0 &&
        valueCardEnum.vcStatus.map((status, index) => {
          if (status.code === text) {
            return (
              <div key={index} style={{ fontSize: fontSize }}>
                {status.description}
              </div>
            );
          }
          return <div key={index}></div>;
        }),
    },
    {
      title: setFont(
        `${t('activity.valueCardColumns.time')}`,
        fontSize,
        `${t('activity.valueCardColumns.addTime')}|${t(
          'activity.valueCardColumns.bindTime'
        )}|${t('activity.valueCardColumns.endTime')}`
      ),
      key: 'addTime|bindTime|endTime',
      width: 250,
      render: (text: string, record: ValueCard) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {record.addTime && (
            <Typography.Text>{`${t('activity.valueCardColumns.addTime')}: ${
              record.addTime
            }`}</Typography.Text>
          )}
          {record.bindTime && (
            <Typography.Text>{`${t('activity.valueCardColumns.bindTime')}: ${
              record.bindTime
            }`}</Typography.Text>
          )}
          {record.userId > 0 && (
            <Typography.Text>{`${t('activity.valueCardColumns.endTime')}: ${
              record.endTime === null
                ? t('activity.valueCardColumns.noLimit')
                : record.endTime
            }`}</Typography.Text>
          )}
        </div>
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'action',
      width: 120,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: ValueCard) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.activityGroup.valueCard)}
            onClick={() => {
              setSelectedValueCard(record);
              setModalVisible({ visible: true, type: 'EDIT' });
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.vcId])
                setMorePopoverVis({ [record.vcId]: flag });
            }}
            visible={morePopoverVis[record.vcId]}
            overlay={
              <Menu>
                <Menu.Item
                  key="viewUsage"
                  style={{ fontSize: fontSize }}
                  onClick={() => {
                    setMorePopoverVis({ [record.vcId]: false });
                    setSelectedValueCard(record);
                    setValueCardRecordModalVisible(true);
                  }}
                >
                  {t('actionsColumn.viewRecord')}
                </Menu.Item>
                {record.userId === 0 && (
                  <Menu.Item
                    key="assignUser"
                    style={{ fontSize: fontSize, color: GREEN1 }}
                    onClick={() => {
                      setSelectedValueCard(record);
                      setModalVisible({ visible: true, type: 'ASSIGN_USER' });
                    }}
                  >
                    {t('actionsColumn.assignUser')}
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.vcId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  //Makes a request and set the value card enum list
  const getValueCardEnum = useCallback(() => {
    getDataWithAuthToken('value_card/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setValueCardEnum(response.data);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getValueCardEnum();
  }, [getValueCardEnum]);

  useEffect(() => {
    getData();
  }, [getData]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('activity.valueCardTypeColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          {userId && isSeller && (
            <Form.Item
              label={t('activity.couponColumnList.advancedSearch.sellerId')}
              name="sellerId"
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          {!userId && (
            <Form.Item
              name="userId"
              label={t('activity.valueCardColumns.advancedSearch.userId')}
            >
              <UsersDropdown
                onChange={(value: any) => {
                  setAdvance((prev: any) => ({
                    ...prev,
                    userId: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label={t('activity.valueCardColumns.advancedSearch.vcStatus')}
            name="vcStatus"
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  vcStatus: value,
                }))
              }
              // optionLabelProp="value"
              allowClear
            >
              {valueCardEnum &&
                valueCardEnum.vcStatus.length > 0 &&
                valueCardEnum.vcStatus.map((status) => (
                  <Select.Option key={status.code} value={status.code}>
                    <Typography>{status.description}</Typography>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('activity.valueCardTypeColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  getData();
                }}
              >
                {t('activity.valueCardTypeColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('activity.valueCardTypeColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setSelectedValueCard(undefined);
          setModalVisible({ visible: true, type: 'ADD_SINGLE' });
        }}
      >
        {t('activity.add/EditValueCardType.addUserVCTitle')}
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setSelectedValueCard(undefined);
          setModalVisible({ visible: true, type: 'ADD_MULTIPLE' });
        }}
      >
        {t('activity.add/EditValueCardType.addMultipleVCTitle')}
      </Menu.Item>
    </Menu>
  );

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Space>
        {userId ? (
          <Space>
            <Typography.Title level={4} style={{ marginBottom: 12 }}>
              {t('users.userDetailPage.valueCards')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={isLoading}
                checkedChildren={t('activity.valueCardTypeColumns.seller')}
                unCheckedChildren={t('activity.valueCardTypeColumns.self')}
                style={{ marginBottom: 12 }}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                      setPage(1);
                    }, EXTENDED_TIMEOUT)
                  );
                  if (!checked) {
                    formRef.current && form.resetFields(['sellerId']);
                    setAdvance((prev: any) => ({
                      ...prev,
                      sellerId: undefined,
                    }));
                  }
                }}
              />
            )}
          </Space>
        ) : (
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('activity.valueCardList')}
          </Typography.Title>
        )}
      </Space>
      <TableToolbar
        leftElement={
          userId === undefined && vctID ? (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button icon={<PlusOutlined />}>
                {t('activity.add/EditValueCardType.addVCTitle')}
              </Button>
            </Dropdown>
          ) : (
            <></>
          )
        }
        search={(keyword) => {
          setKeyword(keyword);
          setPage(1);
        }}
        searchPlaceholder={t('searchPlaceholders.searchValueCard')}
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        totalItems={total}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        rows={valueCardData.map((valueCard) => ({
          vcId: valueCard ? valueCard.vcId : undefined,
          vcName:
            valueCard && valueCard.valueCardType
              ? valueCard.valueCardType.vcName
              : '',
          shopName:
            valueCard &&
            valueCard.valueCardType &&
            valueCard.valueCardType.seller
              ? valueCard.valueCardType.seller.shopName
              : '',
          userId: valueCard ? valueCard.userId : '',
          valueCardSn: valueCard ? valueCard.valueCardSn : '',
          valueCardPassword: valueCard ? valueCard.valueCardPassword : '',
          cardMoney: valueCard ? valueCard.cardMoney : '',
          vcStatus: valueCard ? valueCard.vcStatus : '',
          addTime: valueCard ? valueCard.addTime : '',
          bindTime: valueCard ? valueCard.bindTime : '',
          endTime: valueCard ? valueCard.endTime : '',
        }))}
        exportConfig={{
          fileName: 'VALUE_CARD_LIST',
          url: getExportUrl(getFormParams()),
        }}
        advancedSearch={advancedSearch}
      />
      <Table<ValueCard>
        dataSource={valueCardData}
        rowKey={(valueCard) => valueCard.vcId}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        components={vt}
        scroll={{
          y: 600,
          x: 1200,
        }}
        loading={isLoading}
        size={tableSize}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
          },
          current: page,
          pageSizeOptions: ['10', '25', '50', '100', '150', '200'],
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ValueCard[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((valueCard) => ({
            vcId: valueCard ? valueCard.vcId : undefined,
            vcName:
              valueCard && valueCard.valueCardType
                ? valueCard.valueCardType.vcName
                : '',
            shopName:
              valueCard &&
              valueCard.valueCardType &&
              valueCard.valueCardType.seller
                ? valueCard.valueCardType.seller.shopName
                : '',
            userId: valueCard ? valueCard.userId : '',
            valueCardSn: valueCard ? valueCard.valueCardSn : '',
            valueCardPassword: valueCard ? valueCard.valueCardPassword : '',
            cardMoney: valueCard ? valueCard.cardMoney : '',
            vcStatus: valueCard ? valueCard.vcStatus : '',
            addTime: valueCard ? valueCard.addTime : '',
            bindTime: valueCard ? valueCard.bindTime : '',
            endTime: valueCard ? valueCard.endTime : '',
          }))}
          funct={{
            refresh: () => getData(),
            exportConfig: { fileName: 'VALUE_CARD_LIST' },
          }}
          // general={{ advance: advance, setAdvance: setAdvance }}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
        />
      )}
      <ValueCardModal
        vctID={vctID}
        visible={modalVisible}
        setVisible={setModalVisible}
        selectedValueCard={selectedValueCard}
        vcEnum={valueCardEnum}
        refresh={() => {
          getData();
        }}
      />
      <ValueCardRecordListModal
        selectedVCData={selectedValueCard}
        visible={valueCardRecordModalVisible}
        setVisible={setValueCardRecordModalVisible}
        refresh={getData}
      />
    </>
  );
};

export default UserValueCardTable;
