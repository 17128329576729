import { notification, Typography } from 'antd';

export const alertMessage = (
  messageType: string,
  title: string,
  content?: React.ReactNode
) => {
  switch (messageType) {
    case 'success': {
      return notification.success({message: title, description: content });
    }
    case 'info': {
      return notification.info({message: title, description: content });
    }
    case 'warning': {
      return notification.warning({message: title, description: content});
    }
    case 'error': {
      return notification.error({
        message: title,
        description: content ? (
          <Typography.Paragraph
            style={{ maxHeight: '50vh', overflow: 'auto' }}
            ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}
          >
            {content}
          </Typography.Paragraph>
        ) : undefined,
      });
    }
  }
};
