import { BorderlessTableOutlined } from '@ant-design/icons';
import { Divider, Form, FormInstance, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import SellersDropdown from '../../sellers/SellersDropdown';
import { SetStateAction } from 'react';
import { PaymentMethod } from '../../../types';

type SellerFormProps = {
  form: FormInstance<any>;
  isSeller: boolean;
  formData: { [key: string]: any };
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>;
  setPaymentMethods: React.Dispatch<SetStateAction<PaymentMethod[]>>;
};

const SellerForm = ({
  form,
  isSeller,
  formData,
  setFormData,
  setPaymentMethods,
}: SellerFormProps) => {
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ sellerId: isSeller ? undefined : 0 }}
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.add/editOrder.sellerInfo')}
        </Space>
      </Divider>

      {/** 选择店铺 */}
      <Form.Item
        label={`${t('goods.goodsListColumns.seller')}: `}
        name="sellerId"
        rules={[
          {
            required: true,
            message: t('general.inputError.pleaseSelectOne'),
          },
        ]}
      >
        <SellersDropdown
          disabled={!isSeller}
          initialValue={formData.sellerId ? formData.sellerId : undefined}
          onChange={(value, option: any) => {
            const seller = option.seller;
            setFormData((prev: any) => ({
              ...prev,
              sellerId: value,
              orderGoodsList: [],
              payId: undefined,
              paymentName: undefined,
              orderGoodsListTotal: '0.00',
              isRegionSale: seller ? seller.isRegionSale : undefined,
            }));
            setPaymentMethods([]);
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default SellerForm;
