import React, { useState } from 'react';
import { Card, Dropdown, Menu, Tabs, Typography } from 'antd';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import {
  removePageOnRefresh,
  insertPageOnRefresh,
  setActivePage,
  pinPage,
} from '../features/pageHistory/pageHistorySlice';
import { routes } from '../constants/routes';
import { matchRoutes, RouteConfigComponentProps } from 'react-router-config';
import { useTranslation } from 'react-i18next';
import { dashboardRoute } from '../constants/pathname';
import {
  CloseOutlined,
  EllipsisOutlined,
  PushpinOutlined,
  ReloadOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { GENERAL_TIMEOUT } from '../constants/systemConstants';
import FourZeroFour from './FourZeroFour';
import Container from './Container';
import getDashboardStyle from '../utils/getDashboardStyle';
import { useTab } from '../hooks/useTab';

const { TabPane } = Tabs;

/**
 * Keeps track of User Page history during session and keeps them in tabs for easy
 * access to jump from page to page
 *
 * @props RouteConfigComponentProps route Route information that includes info like title
 */
const PageHistoryTabs = (props: RouteConfigComponentProps) => {
  const pageHistory = useAppSelector((state) => state.pageHistory.value);
  const dispatch = useAppDispatch();
  const { removeTab } = useTab();
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const activePage = useAppSelector((state) => state.pageHistory.activePage);

  /**
   * @param targetKey The key that we are performing an action on
   * @param action    Whether we are adding a new tab or deleting an old one
   */
  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: 'add' | 'remove'
  ) => {
    if (typeof targetKey === 'string' && action === 'remove') {
      // targetKey is path
      removeTab(targetKey);
    }
  };

  const onRefresh = (page: { title: string; path: string }) => {
    let index = pageHistory.findIndex((p) => p.path === page.path);
    if (index > -1) {
      dispatch(
        removePageOnRefresh({
          page,
          refreshTitle: `${t('general.refresh')} - ${t(page.title)}`,
        })
      );
      setTimeout(() => {
        dispatch(insertPageOnRefresh({ page, index }));
      }, GENERAL_TIMEOUT);
    }
  };

  /**
   * Render component based on path name
   * @param path string       URL path
   * @returns    JSX.Element  React component
   */
  const getTabComponent = (path: string) => {
    const matchedRoutes = matchRoutes(routes, path).filter(
      (x) => x.match.isExact
    );
    if (matchedRoutes.length > 0) {
      let Component = matchedRoutes[0].route.component;

      return <Component {...props} />;
    }
    return <></>;
  };

  const contextMenu = (page: {
    title: string;
    path: string;
    pinned?: boolean;
  }) => (
    <Menu>
      <Menu.Item
        key="refresh"
        icon={<ReloadOutlined />}
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(setTimeout(() => onRefresh(page), GENERAL_TIMEOUT));
        }}
      >
        {t('general.refresh')}
      </Menu.Item>
      <Menu.Item
        key="close"
        icon={<CloseOutlined />}
        onClick={() => onEdit(page.path, 'remove')}
      >
        {t('general.close')}
      </Menu.Item>
      <Menu.Item
        key="openNewTab"
        icon={<SelectOutlined rotate={90} />}
        onClick={() => window.open(page.path)}
      >
        {t('general.openNewTab')}
      </Menu.Item>
      <Menu.Item
        key="pinTab"
        icon={
          page.pinned ? <PushpinOutlined rotate={-45} /> : <PushpinOutlined />
        }
        onClick={() => {
          dispatch(pinPage({ ...page, pinned: !page.pinned }));
        }}
      >
        {page.pinned ? t('general.unpin') : t('general.pin')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ paddingTop: 24 }}>
      <Tabs
        type="editable-card"
        // animated
        size="small"
        hideAdd
        onEdit={onEdit}
        renderTabBar={(props, TabNavList) => (
          <TabNavList {...props} mobile={false} />
        )}
        activeKey={activePage}
        moreIcon={
          <EllipsisOutlined style={{ paddingLeft: 0, paddingRight: 0 }} />
        }
        tabBarStyle={{
          marginBottom: -1,
          paddingLeft: 24,
          paddingRight: 10,
          zIndex: 3,
        }}
        onTabClick={(key, e) => {
          if (!e.currentTarget.innerHTML.includes('ant-dropdown-open')) {
            let page = pageHistory.find((page) => page.path === key);
            document.title = `${t(page ? page.title : '')} - ${t(
              getDashboardStyle().title
            )}`;
            window.history.pushState({}, '', key);
            dispatch(setActivePage(key));
          }
        }}
      >
        {!!pageHistory.length &&
          pageHistory.map(
            (page: { title: string; path: string; pinned?: boolean }) => (
              <TabPane
                key={page.path}
                tab={
                  <Dropdown
                    overlay={
                      page.path !== dashboardRoute.home ? (
                        contextMenu(page)
                      ) : (
                        <></>
                      )
                    }
                    trigger={['contextMenu']}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                      }}
                    >
                      {page.pinned && <PushpinOutlined rotate={-45} />}
                      <Typography.Text
                        style={{
                          width: 125,
                          color:
                            page.path === activePage
                              ? getDashboardStyle().mainColor
                              : undefined,
                        }}
                        ellipsis={{
                          tooltip:
                            page.path !== activePage
                              ? t(`${page.title}`)
                              : undefined,
                        }}
                      >
                        {t(`${page.title}`)}
                      </Typography.Text>
                    </div>
                  </Dropdown>
                }
                closable={page.path !== dashboardRoute.home && !page.pinned}
              >
                {page.path === dashboardRoute.settings.fileManager ? (
                  activePage === page.path ? (
                    getTabComponent(page.path)
                  ) : (
                    <></>
                  )
                ) : page.title === '404' ? (
                  <Container>
                    <Card>
                      <FourZeroFour />
                    </Card>
                  </Container>
                ) : (
                  getTabComponent(page.path)
                )}
              </TabPane>
            )
          )}
      </Tabs>
    </div>
  );
};

export default PageHistoryTabs;
