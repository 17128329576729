import { DollarOutlined, EditOutlined, GiftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Grid,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GREEN1, PRIMARY, RED1 } from '../../../constants/color';
import { OrderData, OrderEnum, OrderGood } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import OrderActionModal from './OrderActionModal';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import ValueCardDropdown from '../../activity/common/ValueCardDropdown';
import CouponUserDropdown from '../../activity/common/CouponUserDropdown';

type OrderFeeProps = {
  orderInfo?: OrderData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  enums?: OrderEnum;
};
const OrderFee = ({
  orderInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
  enums,
}: OrderFeeProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const screens = Grid.useBreakpoint();
  const [selectedValueCard, setSelectedValueCard] = useState<{
    [key: string]: any;
  }>({});

  useEffect(() => {
    if (orderInfo && orderInfo.orderAmount) {
      if (orderInfo.orderAmount.valueCard) {
        setSelectedValueCard({
          id: orderInfo.orderAmount.valueCard.vcId,
          value: orderInfo.orderAmount.valueCard.cardMoney,
        });
      }
    }
  }, [orderInfo, editing]);

  const calculateTotalFee = () => {
    setIsLoading(true);
    postDataWithAuthToken('order/preview', {
      orderId: orderInfo && orderInfo.orderId,
      shippingId:
        orderInfo && orderInfo.orderShipping
          ? orderInfo.orderShipping.shippingId
          : undefined,
      shippingCode:
        orderInfo && orderInfo.orderShipping
          ? orderInfo.orderShipping.shippingCode
          : undefined,
      payId: orderInfo && orderInfo.payId,
      postalCode:
        orderInfo && orderInfo.orderAddress
          ? orderInfo.orderAddress.postalCode
          : undefined,
      orderGoodsList:
        orderInfo &&
        orderInfo.orderGoodsList.map((good: OrderGood) => ({
          recId: good.recId || undefined,
          goodsId: good.goodsId,
          goodsNumber: good.goodsNumber,
          goodsName: good.goodsName,
          goodsPrice: good.goodsPrice,
          productId: good.productId || undefined,
        })),
      payFee:
        form.getFieldValue('payFee') !== undefined
          ? form.getFieldValue('payFee')
          : undefined,
      shippingFee:
        form.getFieldValue('shippingFee') !== undefined
          ? form.getFieldValue('shippingFee')
          : undefined,
      tipsFee:
        form.getFieldValue('tipsFee') !== undefined
          ? form.getFieldValue('tipsFee')
          : undefined,
      packFee:
        form.getFieldValue('packFee') !== undefined
          ? form.getFieldValue('packFee')
          : undefined,
      discountAmount:
        form.getFieldValue('discountAmount') !== undefined
          ? form.getFieldValue('discountAmount')
          : undefined,
      integral:
        form.getFieldValue('integral') !== undefined
          ? form.getFieldValue('integral')
          : undefined,
      surplusAmount:
        form.getFieldValue('surplusAmount') !== undefined
          ? form.getFieldValue('surplusAmount')
          : undefined,
      userCouponsId:
        form.getFieldValue('userCouponsId') !== undefined
          ? form.getFieldValue('userCouponsId').value
          : undefined,
      valueCardId:
        form.getFieldValue('valueCardId') !== undefined
          ? form.getFieldValue('valueCardId').value
          : undefined,
      valueCardAmount:
        form.getFieldValue('valueCardAmount') !== undefined
          ? form.getFieldValue('valueCardAmount')
          : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          form.setFieldsValue({
            ...response.data.orderAmount,
            userCouponsId: {
              label:
                form.getFieldValue('userCouponsId') !== undefined &&
                form.getFieldValue('userCouponsId').label,
              value: response.data.orderAmount.userCouponsId,
            },
            valueCardId: {
              label:
                form.getFieldValue('valueCardId') !== undefined &&
                form.getFieldValue('valueCardId').label,
              value: response.data.orderAmount.valueCardId,
            },
          });
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleOnEdit = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('order/edit', {
            orderId: orderInfo && orderInfo.orderId,
            shippingFee: values.shippingFee || 0,
            depositFee: values.depositFee || 0,
            recycleFee: values.recycleFee || 0,
            payFee: values.payFee || 0,
            tipsFee: values.tipsFee || 0,
            packFee: values.packFee || 0,
            discountAmount: values.discountAmount || 0,
            couponsAmount: values.couponsAmount || 0,
            bonusAmount: values.bonusAmount || 0,
            integral: values.integral || 0,
            surplusAmount: values.surplusAmount || 0,
            userCouponsId: values.userCouponsId.value || 0,
            valueCardId: values.valueCardId.value || 0,
            valueCardAmount: values.valueCardAmount || 0,
          })
            .then((response) => {
              if (response && response.goodStatus) {
                setIsLoading(false);
                alertMessage('success', t('order.alerts.orderEdited'));
                handleOnCancel();
                if (callBack) callBack();
              } else {
                setIsLoading(false);
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
            })
            .catch((err) => {
              setIsLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setEditing(true);
        if (setIsCurrentEditing) setIsCurrentEditing(true);
      }
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    setEditing(false);
    if (setIsCurrentEditing) setIsCurrentEditing(false);
  };

  return orderInfo && orderInfo.orderAmount ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...orderInfo.orderAmount,
          userCouponsId: orderInfo.orderAmount.userCoupons?.coupon
            ? {
                label: `${orderInfo.orderAmount.userCoupons.coupon.couponName} $${orderInfo.orderAmount.userCoupons.couponMoney}`,
                value: orderInfo.orderAmount.userCoupons.ucId,
              }
            : { label: t('order.orderDetail.noCoupon'), value: 0 },
          valueCardId: orderInfo.orderAmount.valueCard
            ? {
                label: `${orderInfo.orderAmount.valueCard.valueCardSn} $${orderInfo.orderAmount.valueCard.cardMoney}`,
                value: orderInfo.orderAmount.valueCard.vcId,
              }
            : { label: t('order.orderDetail.noValueCard'), value: 0 },
        }}
      >
        <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.orderDetail.orderFee')}
            </Typography.Text>
            {editing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button type="primary" onClick={handleOnEdit}>
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
                style={{
                  display: getDashboardStyle().isSalesForceAppType
                    ? 'none'
                    : undefined,
                }}
              />
            )}
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.goodsAmount')}: `}
              style={{ marginBottom: 0 }}
              name="goodsAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.gst')}: `}
              style={{ marginBottom: 0 }}
              name="gstFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.pst')}: `}
              style={{ marginBottom: 0 }}
              name="pstFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.depositFee')}: `}
              style={{ marginBottom: 0 }}
              name="depositFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                readOnly={true}
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.recycleFee')}: `}
              style={{ marginBottom: 0 }}
              name="recycleFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                readOnly={true}
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.shippingFee')}: `}
              style={{ marginBottom: 0 }}
              name="shippingFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                onBlur={() => {
                  if (editing) calculateTotalFee();
                }}
                readOnly={!editing}
                bordered={editing}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.payFee')}: `}
              style={{ marginBottom: 0 }}
              name="payFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.tipsFee')}: `}
              style={{ marginBottom: 0 }}
              name="tipsFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                onBlur={() => {
                  if (editing) calculateTotalFee();
                }}
                readOnly={!editing}
                bordered={editing}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.packFee')}: `}
              style={{ marginBottom: 0 }}
              name="packFee"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="+"
                onBlur={() => {
                  if (editing) calculateTotalFee();
                }}
                readOnly={!editing}
                bordered={editing}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.discountAmount')}: `}
              style={{ marginBottom: 0 }}
              name="discountAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="-"
                onBlur={() => {
                  if (editing) calculateTotalFee();
                }}
                readOnly={!editing}
                bordered={editing}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 24]} justify="end" style={{ marginBottom: 24 }}>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.totalAmount')}: `}
              style={{ marginBottom: 0 }}
              name="totalAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.bonusAmount')}: `}
              style={{ marginBottom: 0 }}
              name="bonusAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="-"
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.couponsAmount')}: `}
              style={{ marginBottom: 0 }}
              name="couponsAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="-"
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              name="userCouponsId"
              label={`${t('order.orderDetail.coupon')}: `}
            >
              <CouponUserDropdown
                userId={orderInfo?.user?.userId}
                sellerId={orderInfo?.seller?.sellerId}
                isEdit={editing}
                defaultOptions={true}
                labelInValue={true}
                initialValue={
                  orderInfo.orderAmount.userCoupons?.coupon
                    ? {
                        label: `${orderInfo.orderAmount.userCoupons.coupon.couponName} $${orderInfo.orderAmount.userCoupons.couponMoney}`,
                        value: orderInfo.orderAmount.userCoupons.ucId,
                      }
                    : { label: t('order.orderDetail.noCoupon'), value: 0 }
                }
                onChange={(value) => {
                  form.setFieldsValue({ userCouponsId: value });
                  calculateTotalFee();
                }}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.integralAmount')}: `}
              style={{ marginBottom: 0 }}
              name="integralAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="-"
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={
                <Space wrap>
                  {`${t('order.orderDetail.integral')}: `}
                  {editing &&
                    orderInfo.user &&
                    orderInfo.user.userIntegral !== undefined && (
                      <Space size={3}>
                        <GiftOutlined />
                        {orderInfo.user.userIntegral}
                      </Space>
                    )}
                </Space>
              }
              style={{ marginBottom: 0 }}
              name="integral"
            >
              <Select
                disabled={
                  editing && orderInfo.seller && orderInfo.seller.isRegionSale
                }
                onChange={(value) => {
                  form.setFieldsValue({ integral: value });
                  calculateTotalFee();
                }}
                showArrow={editing}
                bordered={editing}
                style={{ pointerEvents: editing ? undefined : 'none' }}
              >
                {enums &&
                  enums.integral &&
                  enums.integral.map((value) => (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.valueCardAmount')}: `}
              style={{ marginBottom: 0 }}
              name="valueCardAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="-"
                readOnly={!(editing && selectedValueCard.id > 0)}
                bordered={editing && selectedValueCard.id > 0}
                onBlur={() => {
                  if (editing && selectedValueCard.id > 0) calculateTotalFee();
                }}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              name="valueCardId"
              label={
                <Space wrap>
                  {`${t('order.orderDetail.valueCard')}: `}
                  {editing && selectedValueCard.id > 0 && (
                    <Space size={3}>
                      <DollarOutlined />
                      {selectedValueCard.value}
                    </Space>
                  )}
                </Space>
              }
            >
              <ValueCardDropdown
                userId={orderInfo?.user?.userId}
                sellerId={orderInfo?.seller?.sellerId}
                isEdit={editing}
                defaultOptions={true}
                labelInValue={true}
                initialValue={
                  orderInfo.orderAmount.valueCard
                    ? {
                        label: `${orderInfo.orderAmount.valueCard.valueCardSn} $${orderInfo.orderAmount.valueCard.cardMoney}`,
                        value: orderInfo.orderAmount.valueCard.vcId,
                      }
                    : { label: t('order.orderDetail.noValueCard'), value: 0 }
                }
                onChange={(value, option: any) => {
                  setSelectedValueCard({
                    id: Number(option.valueCard.vcId),
                    value: option.valueCard.cardMoney,
                  });
                  form.setFieldsValue({ valueCardId: value });
                  calculateTotalFee();
                }}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={
                <Space wrap>
                  {`${t('order.orderDetail.surplusAmount')}: `}
                  {editing &&
                    hasPermission(actionPermissions.userGroup.surplusManage) &&
                    orderInfo.user &&
                    orderInfo.user.userSurplusAmount !== undefined && (
                      <Space size={3}>
                        <DollarOutlined />
                        {orderInfo.user.userSurplusAmount}
                      </Space>
                    )}
                </Space>
              }
              style={{ marginBottom: 0 }}
              name="surplusAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                prefix="-"
                onBlur={() => {
                  if (
                    editing &&
                    !(orderInfo.seller && orderInfo.seller.isRegionSale)
                  )
                    calculateTotalFee();
                }}
                readOnly={
                  !editing ||
                  (orderInfo.seller && orderInfo.seller.isRegionSale)
                }
                bordered={
                  editing &&
                  !(orderInfo.seller && orderInfo.seller.isRegionSale)
                }
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.paidAmount')}: `}
              style={{ marginBottom: 0 }}
              name="paidAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                readOnly
                bordered={false}
                prefix="-"
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.returnAmount')}: `}
              style={{ marginBottom: 0 }}
              name="returnAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                readOnly
                bordered={false}
                style={{ color: GREEN1 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 24]} justify="end">
          <Col
            style={{
              width: screens.lg
                ? '10%'
                : screens.md
                ? '25%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Space wrap>
              <Form.Item
                label={`${t('order.orderDetail.orderAmount')}: `}
                name="orderAmount"
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  type="number"
                  min={0}
                  precision={2}
                  readOnly
                  bordered={false}
                  style={{ color: RED1, fontSize: 20 }}
                />
              </Form.Item>
              {editing &&
                orderInfo.buttonList &&
                orderInfo.buttonList.refundEnabled && (
                  <Form.Item label={' '} style={{ marginBottom: 0 }}>
                    <Button
                      size="small"
                      disabled={
                        !hasPermission(actionPermissions.orderGroup.orderRefund)
                      }
                      onClick={() => setShowModal(true)}
                      style={{ color: PRIMARY }}
                    >
                      {t('order.orderDetail.buttonList.refundEnabled')}
                    </Button>
                  </Form.Item>
                )}
            </Space>
          </Col>
        </Row>
      </Form>
      <OrderActionModal
        visible={showModal}
        setVisible={setShowModal}
        action="refundEnabled"
        orderInfo={orderInfo}
        callBack={() => {
          handleOnCancel();
          if (callBack) callBack();
        }}
        claimAmount={
          orderInfo &&
          orderInfo.orderAmount &&
          orderInfo.orderAmount.orderAmount &&
          orderInfo.orderAmount.orderAmount.replace('-', '')
        }
        enums={enums}
      />
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.orderFee')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderFee;
