import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Select, Spin, Tooltip, Space, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { S3Data, BasicEnumInfoType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { postDataWithAuthToken } from '../../utils/axiosRequest';

type S3ModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  bucketInfo?: S3Data;
  regionList?: BasicEnumInfoType[];
  refresh?: () => void;
};

const S3Modal = ({
  visible,
  setVisible,
  bucketInfo,
  regionList,
  refresh,
}: S3ModalProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCName, setIsCName] = useState(false);

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue(
        bucketInfo
          ? bucketInfo
          : {
              isCname: false,
              isUse: false,
              isDelImg: false,
              regional: 'us-west-2',
              path: '',
            }
      );
      if (isSubscribed.current)
        setIsCName(bucketInfo ? bucketInfo.isCname : false);
    }
  }, [visible, bucketInfo, form]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (isSubscribed.current) setIsLoading(true);

        postDataWithAuthToken(
          bucketInfo ? 'setting/s3/edit' : 'setting/s3/add',
          {
            id: bucketInfo ? bucketInfo.id : undefined,
            bucket: values.bucket,
            keyId: values.keyId,
            keySecret: values.keySecret,
            isCname: values.isCname,
            endpoint: values.endpoint || '',
            regional: values.regional,
            isUse: values.isUse,
            isDelImage: values.isDelImg,
          }
        )
          .then((response) => {
            if (response && response.goodStatus) {
              if (isSubscribed.current) setVisible(false);
              if (refresh) refresh();
              alertMessage(
                'success',
                bucketInfo
                  ? t('plugins.S3.alerts.bucketEdited')
                  : t('plugins.S3.alerts.bucketAdded')
              );
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
            if (isSubscribed.current) setIsLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    if (isSubscribed.current) setVisible(false);
  };

  return (
    <Modal
      title={
        bucketInfo
          ? `${t('plugins.S3.add/editBuckets.editTitle')} ${bucketInfo.bucket}`
          : t('plugins.S3.add/editBuckets.addTitle')
      }
      visible={visible}
      okText={t('plugins.S3.add/editBuckets.ok')}
      cancelText={t('plugins.S3.add/editBuckets.cancel')}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="bucket"
            label={t('plugins.S3.add/editBuckets.bucket')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="keyId"
            label={t('plugins.S3.add/editBuckets.s3AccessKeyId')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="keySecret"
            label={t('plugins.S3.add/editBuckets.s3AccessKeySecret')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="regional"
            label={t('plugins.S3.add/editBuckets.region')}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(value) => form.setFieldsValue({ regional: value })}
            >
              {regionList &&
                regionList.length &&
                regionList.map((region) => (
                  <Select.Option key={region.code} value={region.code}>
                    {region.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="isCname"
            label={
              <Space>
                {t('plugins.S3.add/editBuckets.isCName')}
                <Tooltip title={t('plugins.S3.add/editBuckets.isCNameTip')}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            }
            valuePropName="checked"
          >
            <Switch
              onChange={(checked) => {
                setIsCName(checked);
              }}
            />
          </Form.Item>
          {isCName && (
            <Form.Item
              name="endpoint"
              label={t('plugins.S3.add/editBuckets.path')}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="isUse"
            label={t('plugins.S3.add/editBuckets.inUse')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="isDelImg"
            label={t('plugins.S3.add/editBuckets.isDelImg')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default S3Modal;
