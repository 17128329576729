import { useState } from 'react';
import { REVIEW_STATUS_PASSED } from '../../../constants/generalConstants';
import { COUPON_TYPE_MANUAL } from '../../../constants/orderConstants';
import { CouponData } from '../../../types';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { useTranslation } from 'react-i18next';
import { Select, Typography } from 'antd';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { DefaultOptionType } from 'antd/lib/select';
type CouponDropdownProps = {
  sellerId?: number;
  initialValue?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
};
const CouponDropdown = ({
  sellerId,
  initialValue,
  onChange,
}: CouponDropdownProps) => {
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState<CouponData[]>([]);
  const [pageCoupon, setPageCoupon] = useState<number>(2);
  const [totalPageCoupon, setTotalPageCoupon] = useState<number>(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getCoupons = (value?: string) => {
    if (sellerId !== undefined) {
      getDataWithAuthToken('coupon/list', {
        params: {
          keyword: value || undefined,
          sellerId: sellerId,
          isExpired: false,
          couponType: COUPON_TYPE_MANUAL,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setCoupons(response.data.list);
            setPageCoupon(2);
            setTotalPageCoupon(response.data.totalPage);
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchOnScrollCoupon = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (pageCoupon > totalPageCoupon) return;
      getDataWithAuthToken('coupon/list', {
        params: {
          page: pageCoupon,
          keyword: searchText.coupon,
          sellerId: sellerId,
          isExpired: false,
          reviewStatus: REVIEW_STATUS_PASSED,
          couponType: COUPON_TYPE_MANUAL,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setCoupons((prev) => [...prev, ...response.data.list]);
          setPageCoupon((prev) => prev + 1);
          setTotalPageCoupon(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  return (
    <Select
      placeholder={t('searchPlaceholders.searchCoupon')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={fetchOnScrollCoupon}
      onFocus={() => {
        if (!coupons.length) getCoupons();
      }}
      showSearch
      allowClear
      filterOption={false}
      onSearch={(value) => {
        setSearchText((prev) => ({ ...prev, coupon: value }));
        if (typingTimeout) clearTimeout(typingTimeout);
        setTypingTimeout(setTimeout(() => getCoupons(value), EXTENDED_TIMEOUT));
      }}
      defaultValue={initialValue !== undefined ? initialValue : undefined}
      optionLabelProp="key"
      onChange={handleOnChange}
    >
      {coupons.map((coupon) => (
        <Select.Option
          key={`${coupon.couponName} (ID: ${coupon.couponId})`}
          value={coupon.couponId}
        >
          <div>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography.Text type="secondary">
                {t('activity.couponColumnList.couponId')}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t('activity.couponColumnList.couponName')}
              </Typography.Text>
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{coupon.couponId}</Typography>
              <Typography>{coupon.couponName}</Typography>
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CouponDropdown;
