// Refer to https://ant.design/docs/spec/colors for color ideas

import getDashboardStyle from '../utils/getDashboardStyle';

export const PRIMARY = getDashboardStyle().mainColor;
export const PRIMARY_LIGHT = '#e0eddf';
export const GRAY3 = '#f5f5f5';
export const GRAY4 = '#bfbfbf';
export const BLUE1 = '#0080FF';
export const BLUE2 = '#00CCCC';
export const BLUE3 = '#66B2FF';
export const BLUE4 = '#001529';
export const ORANGE1 = '#FF8000';
export const GREEN1 = '#00CC00';
export const PURPLE1 = '#FF33FF';
export const PURPLE2 = '#B266FF';
export const RED1 = '#FF0000';
export const RED2 = '#CC0066';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

export const HIGHLIGHT_ORANGE = '#f57a4c';
export const HIGHLIGHT_YELLOW = '#fcb103';
