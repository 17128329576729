import {
  BorderlessTableOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Grid,
  Modal,
  Result,
  Space,
  Spin,
  Steps,
  Tooltip,
  Upload,
} from 'antd';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import {
  FILES_ORDER_IMPORT_TEMPLATE,
  SHORTEST_TIMEOUT,
} from '../../constants/systemConstants';
import {
  OrderBatchData,
  OrderBatchExcelHeaderProps,
  OrderBatchGoodsData,
  OrderEnum,
  OrderGoodsInsertData,
  PaymentMethod,
  UserListData,
  Viewport,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { onNextStepClick, onOrderSubmit } from '../../utils/orderHelper';
import AddressForm from './addOrderSections/AddressForm';
import OrderGoodsForm from './addOrderSections/OrderGoodsForm';
import OtherInfoForm from './addOrderSections/OtherInfoForm';
import SellerForm from './addOrderSections/SellerForm';
import ShippingPaymentForm from './addOrderSections/ShippingPaymentForm';
import TotalFeeForm, {
  calculateTotalFeeComponent,
} from './addOrderSections/TotalFeeForm';
import ImportExcelPreview from './batchImportOrder/ImportExcelPreview';
import SelectExcelData from './batchImportOrder/SelectExcelData';
import UsersForm from './batchImportOrder/UsersForm';

type BatchOrderModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
  isSeller: boolean;
  enums?: OrderEnum;
  callBack?: () => void;
};

const BatchOrderModal = ({
  visible,
  setVisible,
  isSeller,
  enums,
  callBack,
}: BatchOrderModalProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const isSubscribed = useRef(true);
  const { confirm } = Modal;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  // Excel
  const [isUploadValid, setIsUploadValid] = useState<boolean>(false);
  const [excelHeader, setExcelHeader] = useState<string[]>([]);
  const [excelData, setExcelData] = useState<{ [key: string]: any }[]>([]);
  // convert to the format we needed
  const [batchOrderData, setBatchOrderData] = useState<OrderBatchData[]>([]);
  const [remainingBatchOrderData, setRemainingBatchOrderData] = useState<
    OrderBatchData[]
  >([]);
  const [selectedBatchOrderData, setSelectedBatchOrderData] =
    useState<OrderBatchData>();

  const [viewport, setViewport] = useState<Viewport>();
  const [selectedUser, setSelectedUser] = useState<UserListData>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  // 订单商品From Excel Data
  const [orderGoodsList, setOrderGoodsList] = useState<OrderGoodsInsertData[]>(
    []
  );
  const [previewStatus, setPreviewStatus] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      clearExcelData();
      form.resetFields();
    }
  }, [visible, form]);

  // 获取用户详情
  const getUserDetail = useCallback(
    (username: string) => {
      getDataWithAuthToken('users/detail', {
        params: { userName: username },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            const user: UserListData = response.data;
            if (user) {
              setSelectedUser(user);
              setFormData((prev: any) => ({
                ...prev,
                userId: user.userId,
                address: '',
                addressType: '',
                buzzerCode: '',
                consignee: '',
                email: '',
                latitude: 0,
                longitude: 0,
                mobile: '',
                postalCode: '',
                tel: '',
                unitNumber: '',
              }));
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => console.log(err));
    },
    [t]
  );

  // 获取Excel商品列表
  const batchGetGoodsInfo = useCallback(
    async (goodsData: OrderBatchGoodsData[]) => {
      try {
        const sellerId = formData.sellerId;
        const response = await postDataWithAuthToken('goods/preview', {
          goodsParamList: [...goodsData],
          sellerId: sellerId,
        });
        if (response && response.goodStatus) {
          // update order goods from selected excel
          setFormData((prev) => ({ ...prev, orderGoodsList: [] }));
          const orderGoodsList: OrderGoodsInsertData[] = response.data;
          setOrderGoodsList(orderGoodsList);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [formData?.sellerId, t]
  );

  // 把Excel的文件数据导入Form中，检查数据正确性
  const generateOrderDataForm = useCallback(() => {
    if (selectedBatchOrderData) {
      // get user detail
      if (selectedBatchOrderData.storeId) {
        getUserDetail(selectedBatchOrderData.storeId);
      }
      // get order goods data
      if (
        selectedBatchOrderData.goods &&
        selectedBatchOrderData.goods.length > 0
      ) {
        // insert value to formData
        batchGetGoodsInfo(selectedBatchOrderData.goods);
      }
      // set default delivery date
      if (selectedBatchOrderData.deliveryDate) {
        setFormData((prev) => ({
          ...prev,
          expectShippingTime: selectedBatchOrderData.deliveryDate,
        }));
      }
    }
  }, [selectedBatchOrderData, getUserDetail, batchGetGoodsInfo]);

  useEffect(() => {
    if (isSubscribed.current) {
      generateOrderDataForm();
    }
  }, [generateOrderDataForm]);

  useEffect(() => {
    // 设置剩余未导入的数据，初始阶段是FULL
    if (batchOrderData && batchOrderData.length > 0) {
      if (remainingBatchOrderData.length === 0)
        setRemainingBatchOrderData(batchOrderData);
    }
  }, [batchOrderData, remainingBatchOrderData]);

  const onClose = () => {
    clearExcelData();
    clearFormData();
    setVisible(false);
  };

  const clearFormData = () => {
    setCurrentStep(0);
    setFormData({});
    setSelectedUser(undefined);
    setPaymentMethods([]);
    setTimeout(() => {
      setViewport({
        longitude: 0,
        latitude: 0,
        zoom: 15,
      });
    }, SHORTEST_TIMEOUT);
  };

  // Clear excel data
  const clearExcelData = () => {
    setIsUploadValid(false);
    setExcelHeader([]);
    setExcelData([]);
    setBatchOrderData([]);
    setRemainingBatchOrderData([]);
    setSelectedBatchOrderData(undefined);
  };

  const showNextImportConfirm = () => {
    confirm({
      title: t('order.add/editOrder.batchOrder.nextBatchConfirmTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('order.add/editOrder.batchOrder.nextBatchConfirmContent'),
      onOk() {
        continueImportData();
      },
      onCancel() {
        onClose();
        callBack && callBack();
      },
    });
  };

  // 按顺序自动
  const createRemainingDataOrder = (
    data: OrderBatchData[],
    storeId?: string
  ) => {
    if (!storeId) {
      return [...data];
    }
    // Find lastIndex of storeId
    let lastIndex = -1;
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].storeId === storeId) {
        lastIndex = i;
        break;
      }
    }

    if (lastIndex === -1) {
      return [...data]; // Return a copy of the original array if storeId not found
    }

    const part1 = data.slice(lastIndex + 1); // Elements from lastIndex + 1 to end
    const part2 = data.slice(0, lastIndex); // Elements from beginning to lastIndex（except）

    return [...part1, ...part2]; // Concatenate both parts
  };

  const continueImportData = () => {
    // 店铺ID
    const sellerId = formData.sellerId;

    // 更新remaining excel data;
    const updatedRemainingBatchOrderData = createRemainingDataOrder(
      remainingBatchOrderData,
      selectedBatchOrderData?.storeId
    );
    setRemainingBatchOrderData(updatedRemainingBatchOrderData);

    // 自动绑定至下一个
    setSelectedBatchOrderData(updatedRemainingBatchOrderData[0]);

    // clear data
    clearFormData();

    // 设置店铺
    setFormData({ sellerId: sellerId });
    form.setFieldsValue({ sellerId: sellerId });
    setCurrentStep(2);
  };

  // Required Fields In Excel
  const requiredFieldsInExcel: (keyof OrderBatchExcelHeaderProps)[] = [
    'StoreID',
    'ItemID',
    'Order(Cs)',
    'Price/Cs',
  ];

  const convertExcelToJson = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming the first sheet is the one you want to read
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const excelDataJson: { [key: string]: any }[] = XLSX.utils.sheet_to_json(
        sheet,
        { raw: false }
      );

      // Get the header (column names)
      const excelHeader: string[] = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheetName],
        {
          header: 1,
        }
      )[0] as string[];

      setExcelData(excelDataJson);
      setExcelHeader(excelHeader);

      return {
        excelData: excelDataJson,
        excelHeader,
      };
    } catch (e) {
      if (isSubscribed.current) setIsLoading(false);
      console.error('Error fetching or parsing Excel data:', e);
    }
  };

  const calculateTotalFee = () => {
    calculateTotalFeeComponent(form, formData, t, setPreviewStatus);
  };

  const steps = [
    {
      // Seller info
      title: t('order.add/editOrder.stepOne'),
      content: (
        // 选择店铺
        <SellerForm
          form={form}
          isSeller={isSeller}
          formData={formData}
          setFormData={setFormData}
          setPaymentMethods={setPaymentMethods}
        />
      ),
    },
    {
      // Upload file
      title: t('order.add/editOrder.batchStepTwo'),
      content: (
        <Form form={form} layout="vertical">
          <Divider orientation="left" style={{ marginTop: 0 }}>
            <Space>
              <BorderlessTableOutlined />
              {t('general.importFiles')}
            </Space>
          </Divider>

          <Space>
            <Button
              type="link"
              style={{ padding: 0 }}
              href={FILES_ORDER_IMPORT_TEMPLATE}
              download={'order-import-template.xlx'}
            >
              {t('general.downloadTemplate')}
            </Button>
            <Tooltip
              title={`${t(
                'order.add/editOrder.batchOrder.batchUploadTooltip'
              )} ${requiredFieldsInExcel}`}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>

          <Upload.Dragger
            multiple={false}
            accept=".xlsx,.xls"
            maxCount={1}
            beforeUpload={async (file) => {
              if (file) {
                setIsUploadValid(false);
                const data = await convertExcelToJson(file);

                // validate if excel contains all columns we need
                if (
                  data &&
                  requiredFieldsInExcel.every((field) =>
                    data.excelHeader.includes(field)
                  )
                ) {
                  setIsUploadValid(true);
                } else {
                  alertMessage(
                    'warning',
                    t('order.inputError.batchUploadError')
                  );
                }
              }

              return false;
            }}
            onRemove={() => {
              clearExcelData();
            }}
          >
            <Space>
              <InboxOutlined />
              {t('general.dragToUpload')}
            </Space>
          </Upload.Dragger>

          {isUploadValid && excelHeader.length > 0 && excelData && (
            <ImportExcelPreview
              excelHeader={excelHeader}
              excelData={excelData}
            />
          )}
        </Form>
      ),
    },
    {
      // 选择订单数据，依次导入
      title: t('order.add/editOrder.batchStepThree'),
      content: (
        <Form form={form} layout="vertical">
          <Divider orientation="left" style={{ marginTop: 0 }}>
            <Space>
              <BorderlessTableOutlined />
              {t('order.add/editOrder.batchOrder.chooseData')}
            </Space>
          </Divider>

          <SelectExcelData
            excelData={excelData}
            batchOrderData={batchOrderData}
            setBatchOrderData={setBatchOrderData}
            selectedBatchOrderData={selectedBatchOrderData}
            setSelectedBatchOrderData={setSelectedBatchOrderData}
          />
        </Form>
      ),
    },
    {
      // 预览选中信息
      title: t('order.add/editOrder.stepTwo'),
      content: (
        <UsersForm
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setViewport={setViewport}
          form={form}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      // 商品选择（有默认数据）
      title: t('order.add/editOrder.stepThree'),
      content: (
        <OrderGoodsForm
          formData={formData}
          setFormData={setFormData}
          currentOrderGoodsList={orderGoodsList}
        />
      ),
    },
    {
      // Shipping address info
      title: t('order.add/editOrder.stepFour'),
      content: (
        <AddressForm
          form={form}
          enums={enums}
          viewport={viewport}
          setViewport={setViewport}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      // Shipping method and payment method
      title: t('order.add/editOrder.shipping/payment'),
      content: (
        <ShippingPaymentForm
          defaultSelected={true}
          form={form}
          enums={enums}
          formData={formData}
          setFormData={setFormData}
          setPaymentMethods={setPaymentMethods}
          paymentMethods={paymentMethods}
        />
      ),
    },
    {
      // Other info
      title: t('order.add/editOrder.otherInfo'),
      content: (
        <OtherInfoForm
          form={form}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      // Total fee
      title: t('order.add/editOrder.previewTotal'),
      content: (
        <TotalFeeForm
          form={form}
          enums={enums}
          formData={formData}
          isAnonymousUser={false}
          selectedUser={selectedUser}
          setPreviewStatus={setPreviewStatus}
        />
      ),
    },
    {
      // Final confirmation
      title: t('order.add/editOrder.finalStep'),
      content: (
        <Spin spinning={isLoading}>
          <Result
            status="success"
            title={t('order.add/editOrder.finish')}
            subTitle={t('order.add/editOrder.addOrderConfirmation')}
          />
        </Spin>
      ),
    },
  ];

  // 进去下一个步骤的条件
  const nextButtonCondition =
    // 上传步骤：必须获取数据，并且上传格式符合规定
    (currentStep === 1 &&
      (excelData.length === 0 || excelHeader.length === 0 || !isUploadValid)) ||
    // 选择数据 - 必须选择一条数据去添加
    (currentStep === 2 && !selectedBatchOrderData) ||
    // 选择用户
    (currentStep === 3 && !selectedUser) ||
    // 选择商品
    (currentStep === 4 &&
      (!formData.orderGoodsList ||
        (formData.orderGoodsList && !formData.orderGoodsList.length))) ||
    //Preview Amount
    (currentStep === 8 && !previewStatus);

  return (
    <Modal
      title={
        isSeller
          ? t('order.add/editOrder.addBatchSellerOrderTitle')
          : t('order.add/editOrder.addBatchTitle')
      }
      visible={visible}
      footer={null}
      maskClosable={false}
      width={screens.lg ? 1280 : screens.md ? 768 : undefined}
      onCancel={onClose}
      bodyStyle={{ padding: '24px 12px' }}
    >
      <Steps
        labelPlacement={screens.lg ? 'vertical' : undefined}
        responsive={false}
        size="small"
        current={currentStep}
        style={{ marginBottom: 24 }}
      >
        {steps.map((item) => (
          <Steps.Step key={item.title} title={screens.lg ? item.title : ''} />
        ))}
      </Steps>
      {/**** Content */}
      <div
        style={{
          height: '57vh',
          overflow: 'auto',
          padding: '0 12px',
        }}
      >
        {steps[currentStep].content}
      </div>

      {/**** Buttons */}
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}
      >
        {/**** Previous */}
        {currentStep > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              setCurrentStep((prev) => prev - 1);
              form.setFieldsValue({ ...formData });

              if (currentStep === 2 && selectedBatchOrderData) {
                setSelectedBatchOrderData(undefined);
              }
            }}
            disabled={isLoading}
          >
            {t('order.add/editOrder.previous')}
          </Button>
        )}

        {/**** Next */}
        {currentStep < steps.length - 1 && (
          <Button
            style={{ margin: '0 8px' }}
            type="primary"
            onClick={() => {
              onNextStepClick(
                form,
                formData,
                setFormData,
                currentStep,
                setCurrentStep,
                7,
                calculateTotalFee
              );
            }}
            disabled={nextButtonCondition}
          >
            {t('order.add/editOrder.next')}
          </Button>
        )}

        {/**** Finish */}
        {currentStep === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              if (
                remainingBatchOrderData &&
                remainingBatchOrderData.length - 1 > 0
              ) {
                // after finish, ask if want to continue. If yes, go to the next continuous data
                onOrderSubmit(
                  formData,
                  t,
                  setIsLoading,
                  showNextImportConfirm,
                  () => {}
                );
              } else {
                // callback here is to update order list
                onOrderSubmit(formData, t, setIsLoading, onClose, callBack);
              }
            }}
            loading={isLoading}
          >
            {t('order.add/editOrder.ok')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default BatchOrderModal;
