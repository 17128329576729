import { Col, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BLACK, GRAY4 } from '../../../../constants/color';
import {
  INVOICE_REMARK_FONTSIZE,
  PRINTOUT_TERMS_FONT_SIZE,
} from '../../../../constants/printoutConstants';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
} from '../../../../constants/systemConstants';
import {
  PrintoutOrderBodyData,
  PrintoutOrderHeaderData,
} from '../../../../types';
import getDashboardStyle from '../../../../utils/getDashboardStyle';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';

type InvoicePrintoutBodyProps = {
  header?: PrintoutOrderHeaderData;
  body: PrintoutOrderBodyData;
};

const InvoicePrintoutBody = ({ header, body }: InvoicePrintoutBodyProps) => {
  const { t } = useTranslation();
  const [field, setField] = useState<{ [key: string]: string }>({});
  const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const { consignee, fullAddress, mobile } = body;
    setField({ consignee, fullAddress, mobile });
  }, [body]);

  return (
    <div style={{ flexDirection: 'row' }}>
      {getDashboardStyle().isWholeSaleAppType && (
        <Row /**Rules, Buyer/Seller Remark*/>
          <Col /**Rules */
            span={15}
            style={{
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Typography.Text
              style={{ fontWeight: 'bold', fontSize: PRINTOUT_TERMS_FONT_SIZE }}
            >
              {t('order.printoutComponents.shippingTermsTitle') + ':'}
            </Typography.Text>
            {body.shippingTerms.map((term, index) => {
              return (
                <Typography.Paragraph
                  key={index}
                  style={{
                    margin: 0,
                    fontSize: PRINTOUT_TERMS_FONT_SIZE,
                  }}
                >{`${index + 1}. ${term}`}</Typography.Paragraph>
              );
            })}
          </Col>
          {getDashboardStyle().isWholeSaleAppType &&
            header &&
            header.taskQrCodeUrl && (
              <Col /**QR CODE - for sortation - only display in wholesale */
                span={9}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <QRCodeSVG
                  value={header.taskQrCodeUrl}
                  size={100}
                  imageSettings={{
                    src: header.logo,
                    height: 10,
                    width: 10,
                    excavate: true,
                  }}
                />
              </Col>
            )}
          {(body.buyerRemark || body.sellerRemark) && (
            <Col span={24} style={{ marginTop: 10 }}>
              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                }}
              >
                {body.buyerRemark && (
                  <Typography.Text
                    style={{
                      fontSize: INVOICE_REMARK_FONTSIZE,
                      color: BLACK,
                      fontWeight: 600,
                    }}
                  >
                    {`${t('order.printoutComponents.buyerRemarkTitle')}: ${
                      body.buyerRemark
                    }`}
                  </Typography.Text>
                )}
                {body.sellerRemark && (
                  <Typography.Text
                    style={{
                      fontSize: INVOICE_REMARK_FONTSIZE,
                      color: BLACK,
                      fontWeight: 600,
                    }}
                  >
                    {`${t('order.printoutComponents.sellerRemarkTitle')}: ${
                      body.sellerRemark
                    }`}
                  </Typography.Text>
                )}
              </div>
            </Col>
          )}
        </Row>
      )}
      <Row /**Bill To, Details, Payment */
        style={{
          marginTop: 50,
          justifyContent: 'space-between',
        }}
      >
        <Col /**Bill To */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              height: 2,
              backgroundColor: GRAY4,
            }}
          />
          <Typography.Paragraph
            strong
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.billTo')}
          </Typography.Paragraph>
          {field &&
            Object.keys(field).length > 0 &&
            Object.keys(field).map((key) => (
              <div key={key}>
                {isEditing[key] ? (
                  <Input
                    autoFocus
                    defaultValue={field[key]}
                    onChange={(e) =>
                      setField((prev) => ({
                        ...prev,
                        [key]:
                          e.target.value ||
                          body[key as keyof typeof InvoicePrintoutBody],
                      }))
                    }
                    onBlur={() => setIsEditing({ [key]: false })}
                  />
                ) : (
                  <Typography.Text
                    style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
                    onClick={() => setIsEditing({ [key]: true })}
                  >
                    {field[key]}
                  </Typography.Text>
                )}
              </div>
            ))}
        </Col>
        <Col /**Details */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              height: 2,
              backgroundColor: GRAY4,
            }}
          />
          <Typography.Paragraph
            strong
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.details')}
          </Typography.Paragraph>

          {body.user && body.user.nickName && (
            <Typography.Text
              style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
            >
              {`${t('order.print.name')} : ${body.user.nickName}`}
            </Typography.Text>
          )}
          <Typography.Text
            style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
          >
            {`${t('order.print.orderSn')}: ${body.orderSn}`}
          </Typography.Text>
          {body.orderShipping && body.orderShipping.shippingName && (
            <Typography.Text style={{ fontSize: DEFAULT_FONT_SIZE - 2 }}>
              {`${t('order.print.shippingName')} : ${
                body.orderShipping.shippingName
              }`}
            </Typography.Text>
          )}
          {body.orderShipping && body.orderShipping.expectShippingTime && (
            <Typography.Paragraph style={{ fontSize: DEFAULT_FONT_SIZE - 2 }}>
              {`${t('order.print.expectShippingTimeName')} : ${moment(
                body.orderShipping.expectShippingTime
              ).format(DATE_FORMAT)}`}
            </Typography.Paragraph>
          )}
        </Col>
        <Col /**Payment */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              height: 2,
              backgroundColor: GRAY4,
            }}
          />
          <Typography.Paragraph
            strong
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.payment')}
          </Typography.Paragraph>
          <Typography.Text
            style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
          >
            {body.payName}
          </Typography.Text>
          {body.payTime && (
            <Typography.Text
              style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
            >
              {body.payTime}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InvoicePrintoutBody;
