import {
  DownOutlined,
  EyeOutlined,
  PlusOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Card,
  Grid,
  Typography,
  Image,
  Switch,
  InputNumber,
  Space,
  Button,
  Popconfirm,
  Breadcrumb,
  Table,
  Dropdown,
  Menu,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import CategoryModal from '../../components/goods/CategoryModal';
import TransferGoodsModal from '../../components/goods/TransferGoodsModal';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { FALLBACK_IMG } from '../../constants/styles';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { CategoryData, BasicEnumInfoType, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { actionPermissions } from '../../constants/actionPermissions';
import { PRIMARY, RED1 } from '../../constants/color';
import {
  mergeParamsToString,
  tableScrollToTop,
} from '../../utils/helperFunction';

const CategoryList = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const isSubscribed = useRef(true);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [parentId, setParentId] = useState(0);
  const [parentCategory, setParentCategory] = useState();
  const [breadCrumbItems, setBreadCrumbItems] = useState<React.ReactNode[]>([]);
  const [breadCrumbs, setBreadCrumbs] = useState<number[]>([0]);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editCategory, setEditCategory] = useState<CategoryData>();
  const [categoryTopics, setCategoryTopics] = useState<BasicEnumInfoType[]>([]);
  const [showTransfer, setShowTransfer] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const columnKeys = [
    'catId',
    'parentId',
    'catName',
    'catNameEn',
    'desktopIcon',
    'mobileIcon',
    'categoryTopic',
    'catDesc',
    'showRegionId',
    'showInNav',
    'isShow',
    'sortOrder',
    'childCount',
    'goodsCount',
    'action',
  ];
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<CategoryData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  const [editValidator, setEditValidator] = useState<{
    data: CategoryData;
    rowIndex: any;
    colIndex: any;
  }>();

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('goods/category/export/excel?', params);
  };

  const handleOk = (param: string, value: any, record: CategoryData) => {
    postDataWithAuthToken('goods/category/edit', {
      ...record,
      sortOrder: param === 'sortOrder' && value ? parseInt(value) : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('goods.alerts.categoryEdited'));
          getCategoryData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setEditValidator(undefined);
  };

  const columns: ColumnsType<CategoryData> = [
    {
      title: setFont(t('goods.categoryListColumns.id'), fontSize),
      key: 'catId',
      dataIndex: 'catId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      render: (text: string, record: CategoryData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.categoryView)}
          onClick={() => handleViewSubCategories(record)}
          style={{ padding: 0, fontSize: fontSize }}
        >
          {text}
        </Button>
      ),
      sorter: (a: CategoryData, b: CategoryData) => compare(a.catId, b.catId),
    },
    {
      title: setFont(t('goods.categoryListColumns.catName'), fontSize),
      key: 'catName',
      dataIndex: 'catName',
      fixed: screens.lg ? 'left' : undefined,
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: CategoryData, b: CategoryData) =>
        compare(a.catName, b.catName),
    },
    {
      title: setFont(t('goods.categoryListColumns.catNameEn'), fontSize),
      key: 'catNameEn',
      dataIndex: 'catNameEn',
      width: 140,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: CategoryData, b: CategoryData) =>
        compare(a.catNameEn, b.catNameEn),
    },
    {
      title: setFont(t('goods.categoryListColumns.goodsCount'), fontSize),
      key: 'goodsCount',
      dataIndex: 'goodsCount',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: CategoryData, b: CategoryData) =>
        compare(a.goodsCount, b.goodsCount),
    },
    {
      title: setFont(t('goods.categoryListColumns.childCount'), fontSize),
      key: 'childCount',
      dataIndex: 'childCount',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: CategoryData, b: CategoryData) =>
        compare(a.childCount, b.childCount),
    },
    {
      title: setFont(t('goods.categoryListColumns.mobileIcon'), fontSize),
      key: 'mobileIcon',
      dataIndex: 'mobileIconPath',
      width: 120,
      render: (path: string) => (
        <Image
          width={50}
          src={path}
          preview={{ mask: <EyeOutlined /> }}
          fallback={FALLBACK_IMG}
        />
      ),
    },
    {
      title: setFont(t('goods.categoryListColumns.categoryTopic'), fontSize),
      key: 'categoryTopic',
      dataIndex: 'categoryTopic',
      width: 120,
      render: (value: string) =>
        value &&
        value.split(',').map((item, index) => {
          let topic = categoryTopics.find((topic) => topic.code === item);
          return (
            <Typography key={index}>
              {setFont(topic ? topic.description : '', fontSize)}
            </Typography>
          );
        }),
    },
    {
      title: setFont(t('goods.categoryListColumns.catDesc'), fontSize),
      key: 'catDesc',
      dataIndex: 'catDesc',
      width: 200,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.categoryListColumns.showInNav'), fontSize),
      key: 'showInNav',
      dataIndex: 'showInNav',
      width: 100,
      render: (value: boolean, record: CategoryData) => (
        <Switch
          checked={value}
          onChange={(checked) => updateCategory('showInNav', checked, record)}
        />
      ),
    },
    {
      title: setFont(t('goods.categoryListColumns.isShow'), fontSize),
      key: 'isShow',
      dataIndex: 'isShow',
      width: 100,
      render: (value: boolean, record: CategoryData) => (
        <Switch
          checked={value}
          onChange={(checked) => updateCategory('isShow', checked, record)}
        />
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      width: 100,
      defaultSortOrder: 'ascend',
      sorter: (a: CategoryData, b: CategoryData) =>
        compare(a.sortOrder, b.sortOrder),
      render: (record: CategoryData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'sortOrder' ? (
          <Space direction="vertical">
            <InputNumber
              autoFocus
              defaultValue={record.sortOrder}
              min={0}
              max={50000}
              placeholder="0-50000"
              style={{ width: 90, fontSize: fontSize }}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(input) =>
                !input ? setIsEmptyInput(true) : setIsEmptyInput(false)
              }
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                target.value && handleOk('sortOrder', target.value, record);
              }}
            ></InputNumber>
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'sortOrder',
              });
            }}
          >
            {record.sortOrder}
          </Button>
        ),
    },
    {
      title: t('actionsColumn.title'),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: CategoryData) => (
        <Space size="small">
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              setEditCategory(record);
              setShowModal(true);
            }}
            disabled={
              !hasPermission(actionPermissions.goodGroup.categoryManage)
            }
          >
            {t('actionsColumn.edit')}
          </Button>
          <Dropdown
            // getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.catId])
                setMorePopoverVis({ [record.catId]: flag });
            }}
            visible={morePopoverVis[record.catId]}
            overlay={
              <Menu>
                <Menu.Item
                  key="view"
                  onClick={() => handleViewSubCategories(record)}
                  disabled={
                    !hasPermission(actionPermissions.goodGroup.categoryView)
                  }
                >
                  {setFont(t('actionsColumn.view'), fontSize)}
                </Menu.Item>
                <Menu.Item
                  key="transfer"
                  onClick={() => {
                    setEditCategory(record);
                    setShowTransfer(true);
                  }}
                  disabled={!hasPermission(actionPermissions.goodGroup.catDrop)}
                >
                  {setFont(t('goods.actionsColumn.transfer'), fontSize)}
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  disabled={!hasPermission(actionPermissions.goodGroup.catDrop)}
                >
                  <Popconfirm
                    title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
                    onConfirm={() => {
                      handleDeleteCategory(record.catId);
                    }}
                    okText={t('actionsColumn.confirmation.yes')}
                    cancelText={t('actionsColumn.confirmation.no')}
                    disabled={
                      !hasPermission(actionPermissions.goodGroup.catDrop)
                    }
                    placement="leftBottom"
                  >
                    <Typography.Text
                      type={
                        hasPermission(actionPermissions.goodGroup.catDrop)
                          ? 'danger'
                          : 'secondary'
                      }
                      style={{ fontSize: fontSize }}
                    >
                      {t('actionsColumn.delete')}
                    </Typography.Text>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.catId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const updateCategory = async (
    key: string,
    value: string | number | boolean,
    category: CategoryData
  ) => {
    postDataWithAuthToken('goods/category/edit', {
      ...category,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getCategoryData();
            alertMessage('success', t('goods.alerts.categoryEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Delete category by ID
  const handleDeleteCategory = async (catId: number) => {
    try {
      const response = await postDataWithAuthToken('goods/category/delete', {
        catId: catId,
      });
      if (response && response.goodStatus) {
        getCategoryData();
        alertMessage('success', t('goods.alerts.categoryDeleted'));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  // Get Params from form
  const getFormParams = useCallback(() => {
    return {
      parentId: parentId,
      keyword: keyword || undefined,
    };
  }, [parentId, keyword]);

  const getCategoryData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/category/list', {
      params: getFormParams(),
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setCategoryData(response.data.list);
              setParentCategory(response.data.data);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, getFormParams]);

  const getTopicList = useCallback(() => {
    getDataWithAuthToken('goods/category/topic_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) setCategoryTopics(response.data.list);
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  // Click on breadcrumb
  const handleClickOnBreadcrumb = (e: React.MouseEvent) => {
    let target = e.target as HTMLAnchorElement;
    if (target.tabIndex !== breadCrumbItems.length - 1) {
      let temp = breadCrumbItems;
      temp.splice(target.tabIndex + 1);
      if (isSubscribed.current) {
        setBreadCrumbItems(temp);
        setParentId(parseInt(target.id));
        setBreadCrumbs((prev) => {
          return prev.slice(0, target.tabIndex + 2);
        });
      }
    }
  };

  // View sub categories
  const handleViewSubCategories = (category: CategoryData) => {
    let items = breadCrumbItems;
    items.push(
      <Breadcrumb.Item key={category.catId}>
        <span
          id={category.catId.toString()}
          tabIndex={breadCrumbItems.length}
          onClick={handleClickOnBreadcrumb}
          style={{ cursor: 'pointer', color: PRIMARY }}
        >
          {category.catName}
        </span>
      </Breadcrumb.Item>
    );
    if (isSubscribed.current) {
      setBreadCrumbItems(items);
      setParentId(category.catId);
      setBreadCrumbs((prev) => [...prev, category.catId]);
    }
  };

  // Go back to upper level category list
  const handleGoBack = (category: CategoryData) => {
    if (isSubscribed.current) {
      let temp = breadCrumbItems;
      temp.pop();
      setBreadCrumbItems(temp);
      setParentId(category.parentId || 0);
      setBreadCrumbs((prev) => prev.slice(0, prev.length - 1));
    }
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getCategoryData();
  }, [getCategoryData]);

  useEffect(() => {
    if (!categoryTopics.length) getTopicList();
  }, [getTopicList, categoryTopics]);

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.categoryList')}
          </Typography.Title>
          {!!(breadCrumbItems && breadCrumbItems.length) && (
            <Breadcrumb separator=">" style={{ marginBottom: 8 }}>
              <Breadcrumb.Item key="0">
                <span
                  onClick={() => {
                    if (
                      hasPermission(actionPermissions.goodGroup.categoryView)
                    ) {
                      setBreadCrumbItems([]);
                      setParentId(0);
                      setBreadCrumbs([0]);
                    }
                  }}
                  style={{ cursor: 'pointer', color: PRIMARY }}
                >
                  {t('goods.categoryListColumns.topCategory')}
                </span>
              </Breadcrumb.Item>
              {breadCrumbItems}
            </Breadcrumb>
          )}
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Space>
                {parentCategory && JSON.stringify(parentCategory) !== '{}' && (
                  <Button
                    icon={<RollbackOutlined />}
                    onClick={() => handleGoBack(parentCategory)}
                    disabled={
                      !hasPermission(actionPermissions.goodGroup.categoryManage)
                    }
                  >
                    {t('goods.categoryListColumns.back')}
                  </Button>
                )}

                <Button
                  icon={<PlusOutlined />}
                  disabled={
                    !hasPermission(actionPermissions.goodGroup.categoryManage)
                  }
                  onClick={() => {
                    setEditCategory(undefined);
                    setShowModal(true);
                  }}
                >
                  {t('goods.add/editCategory.addTitle')}
                </Button>
              </Space>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getCategoryData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
            }}
            searchPlaceholder={t('searchPlaceholders.searchCategoryName')}
            rows={categoryData}
            exportConfig={
              hasPermission(actionPermissions.goodGroup.categoryManage)
                ? {
                    fileName: 'CATEGORY_LIST',
                    url: hasPermission(
                      actionPermissions.goodGroup.categoryManage
                    )
                      ? getExportUrl(getFormParams())
                      : undefined,
                  }
                : undefined
            }
          />
          <Table<CategoryData>
            dataSource={categoryData}
            rowKey={(category) => category.catId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={isLoading}
            pagination={false}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: CategoryData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{
                exportConfig: hasPermission(
                  actionPermissions.goodGroup.categoryManage
                )
                  ? { fileName: 'CATEGORY_LIST' }
                  : undefined,
              }}
            />
          )}
        </Card>
      )}
      <CategoryModal
        visible={showModal}
        setVisible={setShowModal}
        category={editCategory}
        refresh={() => getCategoryData()}
        parent={parentCategory}
        categoryTopics={categoryTopics}
        breadCrumbs={breadCrumbs}
      />
      <TransferGoodsModal
        visible={showTransfer}
        setVisible={setShowTransfer}
        category={editCategory}
        refresh={() => getCategoryData()}
      />
    </Container>
  );
};

export default CategoryList;
