import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Image,
  Popover,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import BrandDropdown from '../../components/goods/common/BrandDropdown';
import CategoryDropdown from '../../components/goods/common/CategoryDropdown';
import DepartmentDropdown from '../../components/goods/common/DepartmentDropdown';
import SupplierDropdown from '../../components/goods/common/SupplierDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import { FALLBACK_IMG } from '../../constants/styles';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import {
  BasicEnumInfoType,
  FontSizeType,
  GoodData,
  GoodEnums,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import { getDataWithAuthToken } from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const MainGoodsList = () => {
  //General Components
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [navType, setNavType] = useState('NORMAL');
  const screens = useBreakpoint();
  const { addTab } = useTab();
  //Data Components
  const [mainGoodsData, setMainGoodsData] = useState<GoodData[]>([]);
  const [goodsEnum, setGoodsEnum] = useState<GoodEnums>();
  const [goodsTags, setGoodsTags] = useState<BasicEnumInfoType[]>([]);
  const [batchAdvance, setBatchAdvance] = useState<{
    catId?: number;
    departmentId?: number;
    brandId?: number;
    supplierId?: number;
    eventTag?: string;
    isOnSale?: string;
    goodsTag?: string;
  }>({});
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [keyword, setKeyword] = useState('');
  const ellipsis = useState(true);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  //Table Components
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const columnKeys = [
    'goodsId',
    'goodsName',
    'goodsUnit',
    'goodsSn',
    'shopPrice',
    'goodsNumber',
    'isWeightGoods|goodsWeight|estimateShopPrice',
    'eventTag',
    'salesVolume',
    'sortOrder',
    'expireDate',
    'sellerNote',
    'reviewStatus',
    'addTime',
    'lastUpdate',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter(
      (key) =>
        key !== 'reviewStatus' &&
        key !== 'goodsSn' &&
        key !== 'addTime' &&
        key !== 'lastUpdate' &&
        key !== 'expireDate'
    )
  );
  const [selectedRows, setSelectedRows] = useState<GoodData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchAdvance, setSearchAdvance] = useState<{ [key: string]: any }>(
    {}
  );

  const columns: ColumnsType<GoodData> = [
    {
      title: setFont(t('goods.goodsListColumns.goodsId'), fontSize),
      key: 'goodsId',
      dataIndex: 'goodsId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: true,
      render: (text: string, record: GoodData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
            )
          }
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsName'), fontSize),
      key: 'goodsName',
      dataIndex: 'goodsName',
      width: 350,
      render: (value: string, record: GoodData, index) => (
        <Space size="middle">
          <Image
            src={record.largePic}
            fallback={FALLBACK_IMG}
            width={90}
            preview={{ src: record.largePic }}
          />
          <Space direction="vertical">
            <Typography.Text
              style={{
                // width: 90,
                fontSize: fontSize,
                wordBreak: 'break-word',
              }}
            >
              {record.goodsName}
            </Typography.Text>

            <Space direction="vertical" wrap>
              {record.isRelatedGoods && (
                <Tag color="#708B74">
                  {t('goods.goodsListColumns.isRelatedGood')}
                </Tag>
              )}
              {record.activityTagList &&
                record.activityTagList.map((tag) => (
                  <Tag color={tag.tagColor} key={tag.code}>
                    {tag.description}
                  </Tag>
                ))}
            </Space>
          </Space>
        </Space>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsUnit'), fontSize),
      key: 'goodsUnit',
      dataIndex: 'goodsUnit',
      width: 90,
      render: (value: string) => (
        <Typography.Text
          style={{
            fontSize: fontSize,
          }}
        >
          {value}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsSn'), fontSize),
      key: 'goodsSn',
      dataIndex: 'goodsSn',
      width: 105,
      render: (text: string) => (
        <Typography.Text
          style={ellipsis ? { width: 100, fontSize: fontSize } : undefined}
          ellipsis={ellipsis ? { tooltip: text } : false}
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.shopPrice'), fontSize),
      key: 'shopPrice',
      dataIndex: 'shopPrice',
      width: 80,
      render: (value: number) => setFont(value.toString(), fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsNumber'), fontSize),
      key: 'goodsNumber',
      dataIndex: 'goodsNumber',
      width: 80,
      render: (value: string, record: GoodData) =>
        record.hasGoodsSkuAttr ? setFont(value, fontSize) : <></>,
    },
    {
      title: setFont(
        t('goods.goodsListColumns.weight/price'),
        fontSize,
        `${t('goods.goodsListColumns.isWeightGoods')}|${t(
          'goods.goodsListColumns.goodsWeight'
        )}|${t('goods.goodsListColumns.estimateShopPrice')}`
      ),
      key: 'isWeightGoods|goodsWeight|estimateShopPrice',
      width: 180,
      render: (text: string, record: GoodData, index) => (
        <Space style={{ width: '100%' }} direction="vertical" size={4}>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(
              `${t('goods.goodsListColumns.isWeightGoods')}: `,
              fontSize
            )}
            {record.isWeightGoods ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>

          {record.isWeightGoods ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {setFont(
                `${t('goods.goodsListColumns.goodsWeight')}: `,
                fontSize
              )}
              {setFont(record.goodsWeight, fontSize)}
            </div>
          ) : undefined}
          {record.isWeightGoods ? (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              {setFont(
                `${t('goods.goodsListColumns.estimateShopPrice')}: `,
                fontSize
              )}
              {record.goodsWeight !== '' &&
                setFont(record.estimateShopPrice || '', fontSize)}
            </Space>
          ) : undefined}
          {/* <Space
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {editValidator?.rowIndex === index &&
            editValidator.colIndex ===
              'isWeightGoods|goodsWeight|estimateShopPrice' ? (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    if (
                      weightValues &&
                      weightValues.isWeightGoods === true &&
                      weightValues.goodsWeight !== undefined &&
                      weightValues.goodsWeight === 0
                    ) {
                      alertMessage(
                        'warning',
                        t('goods.alerts.missingWeightWarning')
                      );
                    } else if (
                      hasPermission(actionPermissions.goodGroup.goodManage) &&
                      weightValues &&
                      weightValues.isWeightGoods !== undefined &&
                      weightValues.goodsWeight !== undefined
                    ) {
                      handleEditWeightGood(
                        record.goodsId,
                        weightValues?.isWeightGoods,
                        weightValues?.goodsWeight
                      );
                    }
                  }}
                >
                  {t('general.ok')}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setEditValidator(undefined);
                    setWeightValues(undefined);
                  }}
                >
                  {t('general.cancel')}
                </Button>
              </>
            ) : (
              <Button
                size="small"
                onClick={() => {
                  setWeightValues({
                    isWeightGoods: record.isWeightGoods,
                    goodsWeight: parseFloat(record.goodsWeight),
                  });
                  setEditValidator({
                    data: record,
                    rowIndex: index,
                    colIndex: 'isWeightGoods|goodsWeight|estimateShopPrice',
                  });
                }}
                icon={<EditOutlined />}
              />
            )}
          </Space> */}
        </Space>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.eventTag'), fontSize),
      key: 'eventTag',
      width: 110,
      render: (record: GoodData) => (
        <Space direction="vertical" size={4}>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isBest')}:`, fontSize)}
            {record.isBest ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isNew')}:`, fontSize)}
            {record.isNew ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isHot')}:`, fontSize)}
            {record.isHot ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isPresale')}:`, fontSize)}
            {record.isPresale ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isOnSale')}:`, fontSize)}
            {record.isOnSale ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>
        </Space>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.salesVolume'), fontSize),
      key: 'salesVolume',
      dataIndex: 'salesVolume',
      width: 80,
      sorter: true,
      render: (text) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 80,
      sorter: true,
      render: (value: number) => setFont(value.toString(), fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.expireDate'), fontSize),
      key: 'expireDate',
      dataIndex: 'expireDate',
      width: 160,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(t('goods.add/editGood.sellerNote'), fontSize),
      key: 'sellerNote',
      dataIndex: 'sellerNote',
      width: 120,
      render: (value: string) => setFont(value, fontSize),
    },
    {
      title: setFont(t('general.reviewStatus.title'), fontSize),
      key: 'reviewStatus',
      dataIndex: 'reviewStatus',
      width: 120,
      render: (text: string, record: GoodData) =>
        record.reviewStatus ? (
          <Tag color={record.reviewStatus.tagColor}>
            {setFont(record.reviewStatus.description, fontSize)}
          </Tag>
        ) : undefined,
    },
    {
      title: setFont(t('goods.goodsListColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.lastUpdate'), fontSize),
      key: 'lastUpdate',
      dataIndex: 'lastUpdate',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
  ];

  const getMainGoodsData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('goods/related_goods/main_goods_list', {
      params: {
        navType: navType || undefined,
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        catId: formRef.current
          ? form.getFieldValue('catId')
            ? form.getFieldValue('catId').at(-1)
            : undefined
          : undefined,
        eventTag: formRef.current
          ? form.getFieldValue('eventTag') !== 'all'
            ? form.getFieldValue('eventTag')
            : undefined
          : undefined,
        brandId: formRef.current ? form.getFieldValue('brandId') : undefined,
        supplierId:
          formRef.current && form.getFieldValue('supplierId')
            ? form.getFieldValue('supplierId')
            : undefined,
        departmentId: formRef.current
          ? form.getFieldValue('departmentId')
          : undefined,
        isOnSale: formRef.current
          ? form.getFieldValue('isOnSale') !== 'all'
            ? form.getFieldValue('isOnSale')
            : undefined
          : undefined,
        isWeightGoods: formRef.current
          ? form.getFieldValue('isWeightGoods') !== 'all'
            ? form.getFieldValue('isWeightGoods')
            : undefined
          : undefined,
        goodsTag: formRef.current
          ? form.getFieldValue('goodsTag') === 'all'
            ? undefined
            : form.getFieldValue('goodsTag')
          : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setMainGoodsData(response.data.list);
              setTotal(response.data.totalItem);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, keyword, page, pageSize, navType, form]);

  const getGoodEnums = useCallback(() => {
    getDataWithAuthToken('goods/related_goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodsEnum(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  useEffect(() => {
    getGoodEnums();
  }, [getGoodEnums]);

  useEffect(() => {
    getMainGoodsData();
  }, [getMainGoodsData]);

  const getGoodsTags = () => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodsTags(response.data.goodsTag);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('goods.goodsListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
          initialValues={{
            catId: [],
            eventTag: 'all',
            isOnSale: 'all',
            isWeightGoods: 'all',
            reviewStatus: 'all',
            goodsTag: 'all',
          }}
        >
          {hasPermission(actionPermissions.goodGroup.brandView) && (
            <Form.Item
              label={t('goods.goodsListColumns.advancedSearch.brandId')}
              name="brandId"
              style={{ marginBottom: 12 }}
            >
              <BrandDropdown
                onChange={(value) =>
                  setSearchAdvance((prev: any) => ({ ...prev, brandId: value }))
                }
              />
            </Form.Item>
          )}
          {hasPermission(actionPermissions.goodGroup.suppliersManage) && (
            <Form.Item
              label={t('goods.add/editSupplier.title')}
              name="supplierId"
            >
              <SupplierDropdown
                onChange={(value) => {
                  setSearchAdvance((prev: any) => ({
                    ...prev,
                    supplierId: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          {hasPermission(actionPermissions.goodGroup.departmentView) && (
            <Form.Item
              label={t('goods.goodsListColumns.advancedSearch.departmentId')}
              name="departmentId"
              style={{ marginBottom: 12 }}
            >
              <DepartmentDropdown
                onChange={(value) => {
                  setSearchAdvance((prev) => ({
                    ...prev,
                    departmentId: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label={t('goods.goodsListColumns.category')}
            name="catId"
            style={{ marginBottom: 12 }}
          >
            <CategoryDropdown
              onFocusFetch={true}
              onChange={(value) => {
                setSearchAdvance((prev) => ({
                  ...prev,
                  catId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.isOnSale')}
            name="isOnSale"
            style={{ marginBottom: 12 }}
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) => {
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  isOnSale:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }));
              }}
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.isWeightGoods')}
            name="isWeightGoods"
            style={{ marginBottom: 12 }}
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) => {
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  isWeightGoods:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }));
              }}
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('goods.add/editGood.goodsTag')}
            name="goodsTag"
            style={{ marginBottom: 12 }}
          >
            <Select
              defaultActiveFirstOption
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  goodsTag: value === 'all' ? '' : value,
                }))
              }
              onFocus={() => {
                if (!goodsTags.length) getGoodsTags();
              }}
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              {goodsTags.map((tag) => (
                <Select.Option key={tag.code} value={tag.code}>
                  {tag.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('goods.goodsListColumns.eventTag')}
            name="eventTag"
            style={{ marginBottom: 12 }}
          >
            <Select
              defaultActiveFirstOption
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  eventTag: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              {goodsEnum &&
                goodsEnum.goodsEventTag.map((tag) => (
                  <Select.Option key={tag.code} value={tag.code}>
                    {tag.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                setKeyword('');
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getMainGoodsData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(searchAdvance).every(
                (value) => typeof value !== 'number' && !value
              )}
              onClick={() => {
                form.resetFields();
                setSearchAdvance({});
                if (page !== 1) setPage(1);
                else getMainGoodsData();
              }}
            >
              {t('goods.goodsListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Badge
        count={Object.keys(searchAdvance).reduce((accumulator, obj) => {
          if (
            searchAdvance[obj as keyof typeof searchAdvance] &&
            searchAdvance[obj as keyof typeof searchAdvance] !== 'default'
          ) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('goods.goodsListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('goods.mainGoodsLib')}
          </Typography.Title>
          <Tabs
            onTabClick={(key) => {
              if (key !== navType) {
                setSelectedRowKeys([]);
                setSelectedRows([]);
                setNavType(key);
                setPage(1);
              }
            }}
          >
            {goodsEnum &&
              goodsEnum.goodsListNav.length &&
              goodsEnum.goodsListNav.map((type) => (
                <Tabs.TabPane key={type.code} tab={type.description} />
              ))}
          </Tabs>
          <TableToolbar
            tableSize={tableSize}
            setTableSize={setTableSize}
            fontSize={fontSize}
            setFontSize={setFontSize}
            refresh={() => getMainGoodsData()}
            totalItems={total}
            rows={mainGoodsData.map((good) => ({
              ...good,
              brandName: good.brand ? good.brand.brandName : '',
              shopName: good.seller ? good.seller.shopName : '',
              reviewStatus: good.reviewStatus ? good.reviewStatus.code : '',
              isWeightGoods: good.isWeightGoods,
              goodsWeight: good.goodsWeight ? good.goodsWeight : '',
              estimateShopPrice: good.estimateShopPrice
                ? good.estimateShopPrice
                : '',
            }))}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            searchPlaceholder={t(
              'searchPlaceholders.searchNameSnBarcodeKeywords'
            )}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            advancedSearch={advancedSearch}
            exportConfig={{ fileName: 'MAIN_GOODS_LIST' }}
          />
          <Table<GoodData>
            dataSource={mainGoodsData}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            loading={isLoading}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(mainGood) => mainGood.goodsId}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: GoodData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              total: total,
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              goodInfo={{ navType: navType }}
              selectedRowKeys={selectedRowKeys}
              selectedRows={selectedRows.map((good) => ({
                ...good,
                brandName: good.brand ? good.brand.brandName : '',
                shopName: good.seller ? good.seller.shopName : '',
                reviewStatus: good.reviewStatus ? good.reviewStatus.code : '',
                isWeightGoods: good.isWeightGoods,
                goodsWeight: good.goodsWeight ? good.goodsWeight : '',
                estimateShopPrice: good.estimateShopPrice
                  ? good.estimateShopPrice
                  : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              typeSpecificActions="relatedGoods"
              general={{ advance: batchAdvance, setAdvance: setBatchAdvance }}
              funct={{
                refresh: () => getMainGoodsData(),
                exportConfig: { fileName: 'MAIN_GOODS_LIST' },
              }}
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default MainGoodsList;
