import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Grid,
  Popover,
  Space,
  Table,
} from 'antd';

import { FilterOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import AdminDropdown from '../../components/admin/AdminDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { AdminListData, AdminLogData, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Admin Logs
 * /dashboard/admin/log
 *
 */
const AdminLog = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [logData, setLogData] = useState<Array<AdminLogData>>([]);
  const [totalLogs, setTotalLogs] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [advance, setAdvance] = useState<{ [key: string]: string }>({});
  const columnKeys = [
    'logId',
    'logTime',
    'userID',
    'username',
    'logInfo',
    'ipAddress',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRows, setSelectedRows] = useState<AdminLogData[]>([]);
  const [page, setPage] = useState(1);
  const isSubscribed = useRef(true);
  const [keyword, setKeyword] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Fetches Logs
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);

    getDataWithAuthToken('admin/log/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        adminId:
          formRef.current && form.getFieldValue('adminId')
            ? form.getFieldValue('adminId')
            : undefined,
        startDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
            : undefined,
        endDate:
          formRef.current && form.getFieldValue('date')
            ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setLogData(response.data.list);
            setTotalLogs(response.data.totalItem);
            setSelectedRowKeys([]);
            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<AdminLogData> = [
    {
      title: setFont(t('admin.adminLogsColumns.logId'), fontSize),
      dataIndex: 'logId',
      key: 'logId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminLogData, b: AdminLogData) => compare(a.logId, b.logId),
    },
    {
      title: setFont(t('admin.adminLogsColumns.username'), fontSize),
      dataIndex: 'adminUser',
      key: 'username',
      width: 160,
      render: (record: AdminListData) =>
        setFont(record ? record.adminUserName : '', fontSize),
      sorter: (a: AdminLogData, b: AdminLogData) =>
        compare(
          a.adminUser ? a.adminUser.adminUserName : '',
          b.adminUser ? b.adminUser.adminUserName : ''
        ),
    },
    {
      title: setFont(t('admin.adminLogsColumns.logTime'), fontSize),
      dataIndex: 'logTime',
      key: 'logTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminLogData, b: AdminLogData) =>
        compare(a.logTime, b.logTime),
    },
    {
      title: setFont(t('admin.adminLogsColumns.ipAddress'), fontSize),
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AdminLogData, b: AdminLogData) =>
        compare(a.ipAddress, b.ipAddress),
    },
    {
      title: setFont(t('admin.adminLogsColumns.logInfo'), fontSize),
      dataIndex: 'logInfo',
      key: 'logInfo',
      render: (text: string) => setFont(text, fontSize),
      width: 400,
    },
  ];

  /**
   * @param selectedRowKeys Array of keys that were selected
   */
  const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    if (isSubscribed.current) {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  // Row selection Handler
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDelete = () => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('admin/log/delete_batch', {
      logIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('admin.alerts.deletedLogs'));
          getData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setLoading(false);
      });
  };

  /**
   * The popover window which contains the "By Date" and "By Admin ID" filters, Search and Reset buttons
   * If the admin does not have the permission, the admin would not be able to search "By Admin ID"
   */
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('admin.adminLogsColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item
            name="date"
            label={t('admin.adminLogsColumns.advancedSearch.date')}
            style={{ width: 250 }}
          >
            <RangePicker
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      startDate: moment(values[0]).format(DATE_FORMAT),
                      endDate: moment(values[1]).format(DATE_FORMAT),
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      startDate: '',
                      endDate: '',
                    }));
              }}
              placeholder={[
                t('admin.adminLogsColumns.advancedSearch.startDate'),
                t('admin.adminLogsColumns.advancedSearch.endDate'),
              ]}
            />
          </Form.Item>
          <Form.Item
            name="adminId"
            label={t('admin.adminLogsColumns.advancedSearch.adminId')}
          >
            <AdminDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  adminId: value,
                }));
              }}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('admin.adminLogsColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getData();
                }}
              >
                {t('admin.adminLogsColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('admin.adminLogsColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <h1 style={{ fontSize: 24 }}>{t('admin.adminLogs')}</h1>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getData()}
            totalItems={totalLogs}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchAdminLogKeyword')}
            advancedSearch={advancedSearch}
            rows={logData.map((log) => ({
              ...log,
              adminUser: log.adminUser ? log.adminUser.adminUserName : '',
            }))}
            exportConfig={{ fileName: 'ADMIN_LOG' }}
            selectedRows={selectedRows}
            setSelectedRowKeys={setSelectedRowKeys}
          />
          <Table<AdminLogData>
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            dataSource={logData}
            size={tableSize}
            pagination={{
              total: totalLogs,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
            loading={loading}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowSelection={rowSelection}
            rowKey={(log) => log.logId}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={selectedRows.map((log) => ({
                ...log,
                adminUser: log.adminUser ? log.adminUser.adminUserName : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{
                deleteFunc: hasPermission(actionPermissions.adminGroup.logsDrop)
                  ? handleDelete
                  : undefined,
                exportConfig: { fileName: 'ADMIN_LOG' },
              }}
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default AdminLog;
