import {
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Menu,
  Popconfirm,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { PRIMARY } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import { AdData, AdEnum, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

const HomeAdList = () => {
  //General Component
  const formRef = useRef(null);
  const { addTab } = useTab();
  const isSubscribed = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const [isSeller, setIsSeller] = useState(false);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  //Table Component
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [form] = Form.useForm();
  const columnKeys = [
    'adPageId',
    'seller',
    'adPageType',
    'adPageName',
    'adPageTitle',
    'keyword',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((key) => key !== 'keyword')
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  //Data Component
  const [adData, setAdData] = useState<AdData[]>([]);
  const [adEnum, setAdEnum] = useState<AdEnum>();
  //Text Component
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const { t } = useTranslation();
  //Breadcrumbs Component
  const [parentId, setParentId] = useState(0);
  const [parentAd, setParentAd] = useState();
  const [breadCrumbItems, setBreadCrumbItems] = useState<React.ReactNode[]>([]);

  const columns: ColumnsType<AdData> = [
    {
      title: setFont(t('advertisement.adPageColumnList.adPageId'), fontSize),
      key: 'adPageId',
      dataIndex: 'adPageId',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string, record: AdData) => (
        <Button
          type="link"
          onClick={() => handleViewSubAds(record)}
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.advertisementGroup.homeAd)}
        >
          {text}
        </Button>
      ),
      sorter: (a: AdData, b: AdData) => compare(a.adPageId, b.adPageId),
    },
    {
      title: setFont(t('advertisement.adPageColumnList.seller'), fontSize),
      key: 'seller',
      dataIndex: 'seller',
      width: 120,
      sorter: (a: AdData, b: AdData) => compare(a.adPageId, b.adPageId),
      render: (text: string, record: AdData) => (
        <Button
          type="link"
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          onClick={() => {
            addTab(
              '',
              `${dashboardRoute.sellers.detail}?seller_id=${
                record.seller ? record.seller.sellerId : ''
              }`
            );
          }}
          style={{ padding: 0, fontSize: fontSize }}
        >
          {record.seller && record.seller.shopName
            ? record.seller.shopName
            : ''}
        </Button>
      ),
    },
    {
      title: setFont(t('advertisement.adPageColumnList.adPageType'), fontSize),
      key: 'adPageType',
      dataIndex: 'adPageType',
      width: 120,
      render: (text: string) =>
        adEnum?.adPageType.map((type, index) => {
          if (type.code === text) {
            return (
              <Typography.Text style={{ fontSize: fontSize }} key={index}>
                {type.description}
              </Typography.Text>
            );
          }
          return <Typography.Text key={index}>{''}</Typography.Text>;
        }),
    },
    {
      title: setFont(t('advertisement.adPageColumnList.adPageName'), fontSize),
      key: 'adPageName',
      dataIndex: 'adPageName',
      render: (text: string) => setFont(text === '' ? '' : text, fontSize),
    },
    {
      title: setFont(t('advertisement.adPageColumnList.adPageTitle'), fontSize),
      key: 'adPageTitle',
      dataIndex: 'adPageTitle',
      render: (text: string) => setFont(text === '' ? '' : text, fontSize),
    },
    {
      title: setFont(t('advertisement.adPageColumnList.keyword'), fontSize),
      key: 'keyword',
      width: 180,
      dataIndex: 'keyword',
      render: (text: string) => setFont(text === '' ? '' : text, fontSize),
    },
    {
      title: t('actionsColumn.title'),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: AdData) => (
        <Space size="small">
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.advertisementGroup.homeAd)
            }
            onClick={() => {
              addTab(
                '',
                `${dashboardRoute.advertisement.detail}?ad_page_id=${record.adPageId}`
              );
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Dropdown
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.adPageId])
                setMorePopoverVis({ [record.adPageId]: flag });
            }}
            visible={morePopoverVis[record.adPageId]}
            overlay={
              <Menu>
                <Menu.Item key="view" onClick={() => handleViewSubAds(record)}>
                  {setFont(t('actionsColumn.view'), fontSize)}
                </Menu.Item>
                <Menu.Item key="delete">
                  <Popconfirm
                    title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
                    onConfirm={() => {
                      deleteAd(record.adPageId);
                    }}
                    okText={t('actionsColumn.confirmation.yes')}
                    cancelText={t('actionsColumn.confirmation.no')}
                    placement="leftBottom"
                  >
                    <Typography.Text
                      type="danger"
                      style={{ fontSize: fontSize }}
                    >
                      {t('actionsColumn.delete')}
                    </Typography.Text>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.adPageId]: true })}
              disabled={
                !hasPermission(actionPermissions.advertisementGroup.homeAd)
              }
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  /**
   * @param id Id of Ad to delete
   */
  const deleteAd = (id: number) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('home_ad/delete', { adPageId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('advertisement.alerts.adDeleted'));
          getAdData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  //Get Ad data
  const getAdData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('home_ad/list', {
      params: {
        page: page,
        size: pageSize,
        parentId: parentId,
        keyword: keyword || undefined,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        isSeller: isSeller,
        adPageType:
          formRef.current && form.getFieldValue('adPageType')
            ? form.getFieldValue('adPageType')
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setAdData(response.data.list);
              setParentAd(response.data.data);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);

              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, parentId, keyword, isSeller, form, page, pageSize]);

  // Get Ad Enums
  const getAdEnum = useCallback(() => {
    getDataWithAuthToken('home_ad/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) setAdEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  // Click on breadcrumb
  const handleClickOnBreadcrumb = (e: React.MouseEvent) => {
    let target = e.target as HTMLAnchorElement;
    if (target.tabIndex !== breadCrumbItems.length - 1) {
      let temp = breadCrumbItems;
      temp.splice(target.tabIndex + 1);
      if (isSubscribed.current) {
        setBreadCrumbItems(temp);
        setParentId(parseInt(target.id));
      }
    }
  };

  // View sub Ads
  const handleViewSubAds = (ad: AdData) => {
    let items = breadCrumbItems;
    items.push(
      <Breadcrumb.Item key={ad.adPageId}>
        <span
          id={ad.adPageId.toString()}
          tabIndex={breadCrumbItems.length}
          onClick={handleClickOnBreadcrumb}
          style={{ cursor: 'pointer', color: PRIMARY }}
        >
          {ad.adPageName ? ad.adPageName : ad.adPageTitle ? ad.adPageTitle : ''}
        </span>
      </Breadcrumb.Item>
    );
    if (isSubscribed.current) {
      setBreadCrumbItems(items);
      setPage(1);
      setParentId(ad.adPageId);
    }
  };

  // Go back to upper level ad list
  const handleGoBack = (ad: AdData) => {
    if (isSubscribed.current) {
      let temp = breadCrumbItems;
      temp.pop();
      setBreadCrumbItems(temp);
      setParentId(ad.parentId || 0);
    }
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getAdData();
  }, [getAdData]);

  useEffect(() => {
    getAdEnum();
  }, [getAdEnum]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('advertisement.adPageColumnList.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          {isSeller && (
            <Form.Item
              label={t(
                'advertisement.adPageColumnList.advancedSearch.sellerId'
              )}
              name="sellerId"
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          <Form.Item
            label={t(
              'advertisement.adPageColumnList.advancedSearch.adPageType'
            )}
            name="adPageType"
          >
            <Select
              allowClear
              placeholder={t('general.inputError.pleaseSelectOne')}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  adPageType: value,
                }))
              }
            >
              {adEnum?.adPageType.map((adType) => (
                <Select.Option key={adType.description} value={adType.code}>
                  {adType.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getAdData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('advertisement.adPageColumnList.advancedSearch.search')}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getAdData();
                }}
              >
                {t('advertisement.adPageColumnList.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('advertisement.adPageColumnList.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('advertisement.homeAd')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={isLoading}
                checkedChildren={t('advertisement.adPageColumnList.seller')}
                unCheckedChildren={t('advertisement.adPageColumnList.self')}
                style={{ marginBottom: 12 }}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                      setPage(1);
                    }, EXTENDED_TIMEOUT)
                  );
                  if (!checked) {
                    formRef.current && form.resetFields(['sellerId']);
                    setAdvance((prev: any) => ({
                      ...prev,
                      sellerId: undefined,
                    }));
                  }
                }}
              />
            )}
          </Space>
          {!!(breadCrumbItems && breadCrumbItems.length) &&
            hasPermission(actionPermissions.advertisementGroup.homeAd) && (
              <Breadcrumb separator=">" style={{ marginBottom: 8 }}>
                <Breadcrumb.Item key="0">
                  <span
                    onClick={() => {
                      setBreadCrumbItems([]);
                      setParentId(0);
                    }}
                    style={{ cursor: 'pointer', color: PRIMARY }}
                  >
                    {t('advertisement.adPageColumnList.topAdPage')}
                  </span>
                </Breadcrumb.Item>
                {breadCrumbItems}
              </Breadcrumb>
            )}
          <TableToolbar
            advancedSearch={advancedSearch}
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Space>
                {parentAd && JSON.stringify(parentAd) !== '{}' && (
                  <Button
                    icon={<RollbackOutlined />}
                    disabled={
                      !hasPermission(
                        actionPermissions.advertisementGroup.homeAd
                      )
                    }
                    onClick={() => handleGoBack(parentAd)}
                  >
                    {t('advertisement.adPageColumnList.back')}
                  </Button>
                )}
                {
                  <Button
                    disabled={
                      !hasPermission(
                        actionPermissions.advertisementGroup.homeAd
                      )
                    }
                    icon={<PlusOutlined />}
                    onClick={() => {
                      addTab(
                        '',
                        `${dashboardRoute.advertisement.detail}?action=add&is_seller=${isSeller}`
                      );
                    }}
                  >
                    {isSeller
                      ? t('advertisement.add/EditAdPage.addTitleSeller')
                      : t('advertisement.add/EditAdPage.addTitle')}
                  </Button>
                }
              </Space>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getAdData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchAd')}
            rows={adData.map((ad) => ({
              ...ad,

              seller: ad.seller ? ad.seller.sellerId : '',
            }))}
            exportConfig={{
              fileName: 'AD_PAGE_LIST',
            }}
          />
          <Table<AdData>
            dataSource={adData}
            rowKey={(ad) => ad.adPageId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={isLoading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              current: page,
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              selectedRows={[]}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              funct={{ exportConfig: { fileName: 'AD_PAGE_LIST' } }}
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default HomeAdList;
