import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../../components/Container';
import FourZeroThree from '../../../components/FourZeroThree';
import SellersDropdown from '../../../components/sellers/SellersDropdown';
import TransModal from '../../../components/settings/shipping/TransModal';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { FontSizeType, SellerData, TransportData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import { tableScrollToTop } from '../../../utils/helperFunction';

const TransportList = () => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [isSeller, setIsSeller] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [viewType, setViewType] = useState(''); //Tell which modal to display View, Add or Edit
  const [showModal, setShowModal] = useState(false);
  //Data Components
  const [transportData, setTransportData] = useState<TransportData[]>([]);
  const [viewTrans, setViewTrans] = useState<TransportData>(); //for Trans Modal
  //Table Components
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'transportId',
    'transportName',
    'transportTitle',
    'updateTime',
    'seller',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<TransportData[]>([]);
  const [advance, setAdvance] = useState<{ sellerId: number }>({
    sellerId: -1,
  });
  //Text Components
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  const columns: ColumnsType<TransportData> = [
    {
      title: setFont(t('settings.transListColumns.transportId'), fontSize),
      key: 'transportId',
      dataIndex: 'transportId',
      fixed: screens.lg ? 'left' : undefined,
      width: 120,
      render: (text: string, record: TransportData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.settingGroup.shipManage)}
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            setViewType('view');
            setViewTrans(record);
            setShowModal(true);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: TransportData, b: TransportData) =>
        compare(a.transportId, b.transportId),
    },
    {
      title: setFont(t('settings.transListColumns.transportName'), fontSize),
      key: 'transportName',
      dataIndex: 'transportName',
      sorter: (a: TransportData, b: TransportData) =>
        compare(a.transportName, b.transportName),
      render: (text: string, record: TransportData) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.transListColumns.transportTitle'), fontSize),
      key: 'transportTitle',
      dataIndex: 'transportTitle',
      sorter: (a: TransportData, b: TransportData) =>
        compare(a.transportTitle, b.transportTitle),
      render: (text: string, record: TransportData) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.transListColumns.updateTime'), fontSize),
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.transListColumns.seller.title'), fontSize),
      key: 'seller',
      dataIndex: 'seller',
      width: 120,
      render: (record: SellerData) =>
        record &&
        setFont(record.shopName + ' (ID: ' + record.sellerId + ')', fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'action',
      width: 120,
      fixed: screens.lg ? 'right' : undefined,
      render: (record: TransportData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={!hasPermission(actionPermissions.settingGroup.shipManage)}
            onClick={() => {
              setViewType('view');
              setViewTrans(record);
              setShowModal(true);
            }}
          >
            {t('actionsColumn.view')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeleteTransport(record.transportId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.settingGroup.shipManage)}
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.settingGroup.shipManage)
              }
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  /**
   * @param transportId Id of admin to delete
   */
  const handleDeleteTransport = (transportId: number) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('shipping/transport/delete', {
      transportId: transportId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.transDeleted'));
          getTransportData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  /**
   * makes request call and loads the shippingData variable
   */
  const getTransportData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('shipping/transport/list', {
      params: {
        page: page,
        size: pageSize,
        isSeller: isSeller,
        keyword: keyword || undefined,
        sellerId:
          isSeller && formRef.current && form.getFieldValue('seller_Id')
            ? form.getFieldValue('seller_Id')
            : undefined,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setTransportData(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(true);
        console.log(err);
      });
  }, [t, page, pageSize, keyword, form, isSeller]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getTransportData();
  }, [getTransportData]);

  /**
   * This popover displays, and handles the filters*/
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('settings.transListColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          <Form.Item
            name="seller_Id"
            label={t('settings.transListColumns.advancedSearch.sellerId')}
          >
            <SellersDropdown
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getTransportData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('settings.transListColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={!advance.sellerId}
                onClick={() => {
                  form.resetFields();
                  setAdvance({
                    sellerId: -1,
                  });
                  if (page !== 1) setPage(1);
                  else getTransportData();
                }}
              >
                {t('settings.transListColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Button
        style={{ display: !isSeller ? 'none' : '' }}
        icon={<FilterOutlined />}
      >
        {t('settings.transListColumns.advancedSearch.title')}
      </Button>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <FourZeroThree />
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('settings.transListColumns.title')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={isLoading}
                onChange={(checked) => {
                  if (checked === false) {
                    form.resetFields();
                    setAdvance({
                      sellerId: -1,
                    });
                  }
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                    }, EXTENDED_TIMEOUT)
                  );
                }}
                style={{ marginBottom: 12 }}
                checkedChildren={t('goods.goodsListColumns.seller')}
                unCheckedChildren={t('goods.goodsListColumns.self')}
              />
            )}
          </Space>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Button
                icon={<PlusOutlined />}
                disabled={
                  !hasPermission(actionPermissions.settingGroup.shipManage)
                }
                onClick={() => {
                  setViewTrans(undefined);
                  setShowModal(true);
                  setViewType('addTrans');
                }}
              >
                {t('settings.add/edit/viewModal.addTransTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => {
              getTransportData();
            }}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchTransport')}
            advancedSearch={advancedSearch}
            rows={transportData}
            exportConfig={{ fileName: 'TRANSPORT_LIST' }}
          />
          <Table<TransportData>
            dataSource={transportData}
            rowKey={(transport) => transport.transportId}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            size={tableSize}
            loading={isLoading}
            pagination={{
              total: total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
              },
              current: page,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: TransportData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              funct={{ exportConfig: { fileName: 'TRANSPORT_LIST' } }}
              selectedRows={selectedRows}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
            />
          )}
        </Card>
      )}
      <TransModal
        isSeller={isSeller}
        viewType={viewType}
        setViewType={setViewType}
        visible={showModal}
        setVisible={setShowModal}
        transInfo={viewTrans}
        refresh={() => getTransportData()}
      />
    </Container>
  );
};

export default TransportList;
