import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Modal,
  Spin,
  Image,
  Radio,
  Input,
  Button,
  Tooltip,
  Upload,
  Select,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import { RED1 } from '../../constants/color';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_FAILED,
  REVIEW_STATUS_NO_NEED_REVIEW,
} from '../../constants/generalConstants';
import { IMAGE_TYPE } from '../../constants/mediaConstants';
import { dashboardRoute } from '../../constants/pathname';
import { useTab } from '../../hooks/useTab';
import { BasicEnumInfoType, UserIDListData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import UsersDropdown from './common/UsersDropdown';

type UserIDModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refresh?: Function;
  userID?: UserIDListData;
  identificationTypes?: BasicEnumInfoType[];
};

const UserIDModal = ({
  userID,
  visible,
  setVisible,
  refresh,
  identificationTypes,
}: UserIDModalProps) => {
  //General Components
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { addTab } = useTab();
  const [isOrderIDDetailLoading, setIsOrderIDDetailLoading] =
    useState<boolean>(false);
  const [userIDDetail, setUserIDDetail] = useState<UserIDListData>();
  const [reviewStatus, setReviewStatus] = useState<string>();
  const [firstPic, setFirstPic] = useState<any[]>([]);
  const [hasFirstPicAltered, setFirstPicHasAltered] = useState<boolean>(false);
  const [secondPic, setSecondPic] = useState<any[]>([]);
  const [hasSecondPicAltered, setSecondPicHasAltered] =
    useState<boolean>(false);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getUserIDDetail = useCallback(() => {
    if (isSubscribed.current) setIsOrderIDDetailLoading(true);
    getDataWithAuthToken('users/identification/detail', {
      params: {
        identificationId: userID?.identificationId,
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              form.setFieldsValue({
                ...response.data,
                reviewStatus: response.data.reviewStatus
                  ? response.data.reviewStatus.code
                  : undefined,
              });
              response.data.reviewStatus &&
              response.data.reviewStatus.code === REVIEW_STATUS_FAILED
                ? setReviewStatus(REVIEW_STATUS_FAILED)
                : setReviewStatus(undefined);
              if (response.data.firstIdCardImg !== '') {
                setFirstPic([
                  {
                    largePicPath: response.data.firstIdCardImg,
                  },
                ]);
              }
              if (response.data.secondIdCardImg !== '') {
                setSecondPic([
                  {
                    largePicPath: response.data.secondIdCardImg,
                  },
                ]);
              }

              setUserIDDetail(response.data);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
        if (isSubscribed.current) setIsOrderIDDetailLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsOrderIDDetailLoading(false);
        console.log(err);
      });
  }, [userID, t, form]);

  // Things to do on first render
  useEffect(() => {
    if (visible) {
      form.resetFields();
      userID && getUserIDDetail();
    }
  }, [form, visible, userID, getUserIDDetail]);

  const onClose = () => {
    if (isSubscribed.current) {
      setFirstPic([]);
      setFirstPicHasAltered(false);
      setSecondPic([]);
      setSecondPicHasAltered(false);
      setReviewStatus(undefined);
      setUserIDDetail(undefined);
      setVisible(false);
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const data = new FormData();
        userIDDetail &&
          data.append(
            'identificationId',
            userIDDetail.identificationId.toString()
          );
        Object.keys(values).forEach((label) => {
          if (label === 'identificationId' && userIDDetail) {
            data.append(
              label,
              userIDDetail[label as keyof typeof userIDDetail].toString()
            );
          } else if (label === 'firstPic' && hasFirstPicAltered) {
            if (
              firstPic &&
              firstPic.length === 1 &&
              firstPic[0].originFileObj
            ) {
              data.append('firstIdCardImg', firstPic[0].originFileObj);
              data.append('removeFirstIdCardImg', 'false');
            } else {
              data.append('removeFirstIdCardImg', 'true');
            }
          } else if (label === 'secondPic' && hasSecondPicAltered) {
            if (
              secondPic &&
              secondPic.length === 1 &&
              secondPic[0].originFileObj
            ) {
              data.append('secondIdCardImg', secondPic[0].originFileObj);
              data.append('removeSecondIdCardImg', 'false');
            } else {
              data.append('removeSecondIdCardImg', 'true');
            }
          } else if (values[label as keyof typeof values] !== undefined) {
            data.append(label, values[label as keyof typeof values]);
          }
        });
        if (isSubscribed.current) setIsOrderIDDetailLoading(true);
        postDataWithAuthToken(
          userIDDetail
            ? 'users/identification/edit'
            : 'users/identification/add',
          data
        )
          .then((response) => {
            if (response && response.goodStatus) {
              if (refresh) refresh();
              alertMessage(
                'success',
                userIDDetail
                  ? t('users.alerts.userIDEdited')
                  : t('users.alerts.userIDAdded')
              );
              onClose();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
            if (isSubscribed.current) setIsOrderIDDetailLoading(false);
          })
          .catch((err) => {
            if (isSubscribed.current) setIsOrderIDDetailLoading(false);

            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      title={
        userIDDetail
          ? `${t('users.viewUserIDModal.title')} ${userIDDetail?.realName}`
          : t('users.viewUserIDModal.addTitle')
      }
      onCancel={onClose}
      visible={visible}
      onOk={onFinish}
    >
      <Spin spinning={isOrderIDDetailLoading}>
        <Form
          form={form}
          ref={formRef}
          labelCol={{ span: 6 }}
          initialValues={
            userIDDetail
              ? {
                  realName: userIDDetail.realName ? userIDDetail.realName : '',
                  address: userIDDetail.address ? userIDDetail.address : '',
                  reviewStatus: userIDDetail.reviewStatus
                    ? userIDDetail.reviewStatus
                    : '',
                  reviewContent: userIDDetail.reviewContent
                    ? userIDDetail.reviewContent
                    : '',
                }
              : { reviewStatus: REVIEW_STATUS_NO_NEED_REVIEW }
          }
        >
          {!userIDDetail && (
            <Form.Item
              label={t('users.userIDColumns.identificationType')}
              name="identificationType"
              rules={[
                {
                  required: userIDDetail === undefined,
                  message: t('general.inputError.pleaseSelectOne'),
                },
              ]}
            >
              <Select>
                {identificationTypes &&
                  identificationTypes.length > 0 &&
                  identificationTypes
                    .filter(
                      (type) => type.code === 'USER' || type.code === 'BUSINESS'
                    )
                    .map((type) => (
                      <Select.Option key={type.code} value={type.code}>
                        {type.description}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          )}
          {userIDDetail && (
            <Form.Item label={t('users.viewUserIDModal.id')}>
              {userIDDetail.identificationId}
            </Form.Item>
          )}
          <Form.Item
            label={t('users.viewUserIDModal.userId')}
            name={userIDDetail ? undefined : 'userId'}
            rules={[
              {
                required: userIDDetail === undefined,
                message: t('general.inputError.pleaseSelectOne'),
              },
            ]}
          >
            {userIDDetail ? (
              <Button
                type="link"
                disabled={!hasPermission(actionPermissions.userGroup.userView)}
                style={{ padding: 0 }}
                onClick={() =>
                  addTab(
                    '',
                    `${dashboardRoute.users.detail}?user_id=${userIDDetail.userId}`
                  )
                }
              >
                {userIDDetail.userId}
              </Button>
            ) : (
              <UsersDropdown />
            )}
          </Form.Item>
          {userIDDetail && !!userIDDetail.orderId && (
            <Form.Item label={t('users.viewUserIDModal.orderId')}>
              <Button
                type="link"
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderView)
                }
                style={{ padding: 0 }}
                onClick={() =>
                  addTab(
                    '',
                    `${dashboardRoute.order.detail}?order_id=${userIDDetail.orderId}`
                  )
                }
              >
                {userIDDetail.orderId}
              </Button>
            </Form.Item>
          )}
          <Form.Item
            name="realName"
            label={t('users.viewUserIDModal.realName')}
          >
            <Input />
          </Form.Item>
          {userIDDetail && userIDDetail.bankCard && (
            <Form.Item label={t('users.viewUserIDModal.bankCard')}>
              {userIDDetail.bankCard}
            </Form.Item>
          )}
          <Form.Item name="address" label={t('users.viewUserIDModal.address')}>
            <Input />
          </Form.Item>
          <Form.Item
            name="firstPic"
            label={t('users.viewUserIDModal.firstPic')}
          >
            {firstPic && firstPic.length === 1 && firstPic[0].largePicPath ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Image height={100} src={firstPic[0].largePicPath} />
                <Tooltip
                  title={t('actionsColumn.delete')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    style={{ border: 0 }}
                    size="large"
                    icon={<CloseCircleOutlined style={{ color: RED1 }} />}
                    onClick={() => {
                      setFirstPicHasAltered(true);
                      setFirstPic([]);
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <Upload
                maxCount={1}
                listType="picture-card"
                accept={`${IMAGE_TYPE}`}
                showUploadList={{
                  showPreviewIcon: false,
                }}
                fileList={firstPic}
                onChange={({
                  file,
                  fileList,
                }: {
                  file: any;
                  fileList: any[];
                }) => {
                  setFirstPic(fileList);
                  setFirstPicHasAltered(true);
                }}
                onRemove={(file) => setFirstPic([])}
                beforeUpload={() => {
                  return false;
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {t('settings.photoGalleryActions.selectFile')}
                  </div>
                </div>
              </Upload>
            )}
          </Form.Item>
          <Form.Item
            name="secondPic"
            label={t('users.viewUserIDModal.secondPic')}
          >
            {secondPic &&
            secondPic.length === 1 &&
            secondPic[0].largePicPath ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Image height={100} src={secondPic[0].largePicPath} />
                <Tooltip
                  title={t('actionsColumn.delete')}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Button
                    style={{ border: 0 }}
                    size="large"
                    icon={<CloseCircleOutlined style={{ color: RED1 }} />}
                    onClick={() => {
                      setSecondPicHasAltered(true);
                      setSecondPic([]);
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <Upload
                maxCount={1}
                listType="picture-card"
                accept={`${IMAGE_TYPE}`}
                showUploadList={{
                  showPreviewIcon: false,
                }}
                fileList={secondPic}
                onChange={({
                  file,
                  fileList,
                }: {
                  file: any;
                  fileList: any[];
                }) => {
                  setSecondPic(fileList);
                  setSecondPicHasAltered(true);
                }}
                onRemove={(file) => setSecondPic([])}
                beforeUpload={() => {
                  return false;
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {t('settings.photoGalleryActions.selectFile')}
                  </div>
                </div>
              </Upload>
            )}
          </Form.Item>
          {getDashboardStyle().showReviewStatus && (
            <Form.Item
              name="reviewStatus"
              label={t('users.viewUserIDModal.reviewStatus')}
            >
              <Radio.Group
                onChange={(e) => {
                  form.resetFields(['reviewContent']);
                  setReviewStatus(e.target.value);
                }}
              >
                {Object.keys(REVIEW_STATUS).map((type) => {
                  return (
                    <Radio key={type} value={type}>
                      {t(REVIEW_STATUS[type])}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          )}
          {getDashboardStyle().showReviewStatus &&
            reviewStatus === REVIEW_STATUS_FAILED && (
              <Form.Item
                name="reviewContent"
                label={t('users.viewUserIDModal.reviewContent')}
                rules={[
                  {
                    required: reviewStatus ? true : false,
                    message: t('general.warning.empty'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default UserIDModal;
