import { ReloadOutlined } from '@ant-design/icons';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import {
  BLUE1,
  GREEN1,
  ORANGE1,
  PURPLE1,
  PURPLE2,
} from '../../../constants/color';
import { addCommas } from '../../../utils/helperFunction';
import {
  DATE_FORMAT,
  GENERAL_TIMEOUT,
  MEDIUM_FONT_SIZE,
  SMALL_FONT_SIZE,
  DEFAULT_FONT_SIZE,
} from '../../../constants/systemConstants';

import {
  NewUsersAnalysisDataPoint,
  NivoLineChartData,
  NivoPieChartData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { compare } from '../../../utils/colComponents';
import {
  getBarGraphBottomAxisLabel,
  getLineGraphBottomAxisLabel,
  nivoToolTip,
} from '../../../utils/nivoGraphTools';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import TableToolbar from '../../table/TableToolbar';
type NewUsersAnalysis = {
  date: string;
  newUserNum: number;
  totalUserNum: number;
  source: {
    regularNum: number;
    refererNum: number;
  };
};

const NewUsers = () => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [vt] = useVT(() => ({ scroll: { y: 300 } }), []);
  const screens = Grid.useBreakpoint();
  const { RangePicker } = DatePicker;
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [numOfDays, setNumOfDays] = useState<Number>(7);
  const [viewMode, setViewMode] = useState<string>(
    t('analysis.newUsers.totalUserNum')
  );
  const viewTypeButtons = ['totalUserNum', 'newUserNum'];
  //Data Components
  const [barGraphData, setBarGraphData] = useState<{}[]>([]);
  const [lineGraphData, setLineGraphData] = useState<NivoLineChartData[]>([]);
  const [pieGraphData, setPieGraphData] = useState<NivoPieChartData[]>([]);
  const [newUserData, setNewUserData] = useState<NewUsersAnalysis[]>([]);
  //Text/Visuals Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'date',
    'refererNum',
    'regularNum',
    'newUserNum',
    'totalUserNum',
  ];
  const columns: ColumnsType<NewUsersAnalysis> = [
    {
      title: t('analysis.newUsers.date'),
      key: 'date',
      dataIndex: 'date',
      fixed: screens.lg ? 'left' : undefined,
      width: 100,
      render: (text: string) => text,
    },
    {
      title: t('analysis.newUsers.refererNum'),
      key: 'refererNum',
      width: 90,
      render: (text: string, record: NewUsersAnalysis) =>
        addCommas(record.source.refererNum),
    },
    {
      title: t('analysis.newUsers.regularNum'),
      key: 'regularNum',
      width: 90,
      render: (text: string, record: NewUsersAnalysis) =>
        addCommas(record.source.regularNum),
    },
    {
      title: t('analysis.newUsers.newUserNum'),
      key: 'newUserNum',
      width: 90,
      dataIndex: 'newUserNum',
      sorter: (a: NewUsersAnalysis, b: NewUsersAnalysis) =>
        compare(
          Number(a.newUserNum) !== undefined ? Number(a.newUserNum) : null,
          Number(b.newUserNum) !== undefined ? Number(b.newUserNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
    {
      title: t('analysis.newUsers.totalUserNum'),
      key: 'totalUserNum',
      dataIndex: 'totalUserNum',
      sorter: (a: NewUsersAnalysis, b: NewUsersAnalysis) =>
        compare(
          Number(a.totalUserNum) !== undefined ? Number(a.totalUserNum) : null,
          Number(b.totalUserNum) !== undefined ? Number(b.totalUserNum) : null
        ),
      render: (text: string) => addCommas(text),
    },
  ];

  /**
   * Grabs all the needed data for New Users
   */
  const getData = useCallback(() => {
    const getLineBarGraphData = (data: NewUsersAnalysisDataPoint) => {
      let barGraphData: {}[] = [];
      let lineGraphData: NivoLineChartData[] = [];
      let newUserNumData: { x: string; y: string | number }[] = [];
      let totalUserNumData: { x: string; y: string | number }[] = [];

      data.userNumList.forEach((point) => {
        barGraphData.push({
          date: point.date,
          regularNum: point.source.regularNum,
          refererNum: point.source.refererNum,
        });
        newUserNumData.push({
          x: point.date,
          y: point.newUserNum,
        });
        totalUserNumData.push({
          x: point.date,
          y: point.totalUserNum,
        });
      });
      lineGraphData.push({
        id: `${t('analysis.newUsers.newUserNum')}`,
        color: GREEN1,
        data: newUserNumData,
      });
      lineGraphData.push({
        id: `${t('analysis.newUsers.totalUserNum')}`,
        color: PURPLE2,
        data: totalUserNumData,
      });
      return [barGraphData, lineGraphData];
    };
    const getPieGraphData = (data: NewUsersAnalysisDataPoint) => {
      let tempAnalysisData: NivoPieChartData[] = [];

      tempAnalysisData.push({
        id: `${t('analysis.newUsers.regularNum')}`,
        value: parseFloat(data.userSource.regularPercentage),
        label: data.userSource.regularNum,
        color: BLUE1,
      });
      tempAnalysisData.push({
        id: `${t('analysis.newUsers.refererNum')}`,
        value: parseFloat(data.userSource.refererPercentage),
        label: data.userSource.refererNum,
        color: ORANGE1,
      });
      return tempAnalysisData;
    };

    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken('analysis/user/new_user', {
      params: {
        startDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
          : undefined,
        endDate: form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
          : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            let barLineData = getLineBarGraphData(response.data);
            setNewUserData(response.data.userNumList);
            setBarGraphData(barLineData[0]);
            setLineGraphData(barLineData[1] as NivoLineChartData[]);
            setPieGraphData(getPieGraphData(response.data));
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, form, setFourZeroThree, setFiveHundred]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Spin style={{ width: '100%' }} spinning={loading}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 20,
              flexWrap: 'wrap',
            }} /**RangePicker and Refresh Button */
          >
            <Form form={form}>
              <Form.Item
                name={'date'}
                label={t('analysis.newUsers.date')}
                initialValue={[
                  moment().subtract(8, 'days'),
                  moment().subtract(1, 'days'),
                ]}
                style={{ marginBottom: 12, paddingRight: 10 }}
              >
                <RangePicker
                  format={DATE_FORMAT}
                  placeholder={[
                    t('analysis.newUsers.startDate'),
                    t('analysis.newUsers.endDate'),
                  ]}
                  ranges={{
                    [`${t('nivo.week')}`]: [
                      moment().startOf('isoWeek'),
                      moment().endOf('isoWeek'),
                    ],
                    [`${t('nivo.month')}`]: [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                    [`${t('nivo.firstHalfYear')}`]: [
                      moment().startOf('year'),
                      moment().startOf('year').add(6, 'months'),
                    ],
                    [`${t('nivo.secondHalfYear')}`]: [
                      moment().startOf('year').add(6, 'months'),
                      moment().endOf('year'),
                    ],
                    [`${t('nivo.year')}`]: [
                      moment().startOf('year'),
                      moment().endOf('year'),
                    ],
                  }}
                />
              </Form.Item>
            </Form>
            <Space align="start">
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      if (
                        form.getFieldValue('date') &&
                        form.getFieldValue('date').length === 2
                      ) {
                        setNumOfDays(
                          form
                            .getFieldValue('date')[1]
                            .diff(form.getFieldValue('date')[0], 'days') + 1
                        );
                        getData();
                      }
                    }, GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('analysis.orderDistribution.ok')}
              </Button>
              <Button
                onClick={() => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      form.setFieldsValue({
                        date: [
                          moment().startOf('isoWeek'),
                          moment().endOf('isoWeek'),
                        ],
                      });
                      getData();
                    }, GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('analysis.orderDistribution.reset')}
              </Button>
            </Space>
            <Tooltip title={t('general.refresh')}>
              <Button
                type="text"
                onClick={() => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
                icon={<ReloadOutlined />}
              />
            </Tooltip>
          </div>
          {barGraphData.length > 0 /**Title, Legend, Bar Graph */ && (
            <div
              style={{
                width: '100%',
                paddingBottom: 15,
              }}
            >
              <div /**Bar Graph Title */
                style={{ textAlign: 'center', fontSize: MEDIUM_FONT_SIZE }}
              >{`${t('analysis.newUsers.regularNum')}/${t(
                'analysis.newUsers.refererNum'
              )} ${t('analysis.newUsers.users')}`}</div>
              <div /**Bar Legend */
                style={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    height: 15,
                    width: 15,
                    paddingRight: 10,
                    backgroundColor: BLUE1,
                    fontSize: SMALL_FONT_SIZE,
                  }}
                />
                {`${t('analysis.newUsers.regularNum')}`}
                <div
                  style={{
                    marginLeft: 10,
                    height: 15,
                    width: 15,
                    paddingRight: 10,
                    backgroundColor: ORANGE1,
                    fontSize: SMALL_FONT_SIZE,
                  }}
                />
                {`${t('analysis.newUsers.refererNum')}`}
              </div>
              <div style={{ width: '100%', height: 550 }} /**Bar Graph */>
                <ResponsiveBar
                  tooltip={(props) =>
                    nivoToolTip({
                      ...props,
                      xAxisLength: barGraphData.length,
                      tooltipType: 'Standard',
                      translationSection: 'analysis.newUsers',
                    })
                  }
                  theme={{
                    fontSize: DEFAULT_FONT_SIZE,
                    axis: {
                      legend: { text: { fontSize: DEFAULT_FONT_SIZE } },
                    },
                  }}
                  colors={[BLUE1, ORANGE1]}
                  colorBy="id"
                  data={barGraphData}
                  indexBy="date"
                  keys={['regularNum', 'refererNum']}
                  margin={{ top: 10, bottom: 100, left: 60 }}
                  padding={0.3}
                  enableLabel={false}
                  axisBottom={getBarGraphBottomAxisLabel(numOfDays)}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: t('nivo.number'),
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                />
              </div>
            </div>
          )}
          {lineGraphData.length >
            0 /**ViewType buttons, Title, Legend, Line Graph */ && (
            <div style={{ width: '100%', paddingBottom: 15 }}>
              {viewTypeButtons.map((buttonLabel, index) => {
                /**ViewType Buttons */
                return (
                  <Button
                    key={index}
                    type={
                      viewMode === t(`analysis.newUsers.${buttonLabel}`)
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() =>
                      setViewMode(t(`analysis.newUsers.${buttonLabel}`))
                    }
                  >
                    {`${t(`analysis.newUsers.${buttonLabel}`)} (${t(
                      `nivo.number`
                    )})`}
                  </Button>
                );
              })}
              <div /**Line Graph Title */
                style={{ textAlign: 'center', fontSize: MEDIUM_FONT_SIZE }}
              >{`${
                viewMode === t('analysis.newUsers.totalUserNum')
                  ? t('analysis.newUsers.totalUserNum')
                  : t('analysis.newUsers.newUserNum')
              }`}</div>
              {/**Line Legend */}
              {viewMode === t('analysis.newUsers.totalUserNum') ? (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      paddingRight: 10,
                      backgroundColor: PURPLE1,
                      fontSize: SMALL_FONT_SIZE,
                    }}
                  />
                  <div>{`${t('analysis.newUsers.totalUserNum')}`}</div>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      marginLeft: 10,
                      height: 15,
                      width: 15,
                      paddingRight: 10,
                      backgroundColor: GREEN1,
                      fontSize: SMALL_FONT_SIZE,
                    }}
                  />
                  <div>{`${t('analysis.newUsers.newUserNum')}`}</div>
                </div>
              )}
              <div style={{ width: '100%', height: 550 }}>
                {
                  <ResponsiveLine
                    theme={{
                      fontSize: DEFAULT_FONT_SIZE,
                      axis: {
                        legend: { text: { fontSize: DEFAULT_FONT_SIZE } },
                      },
                    }}
                    colors={
                      viewMode === t('analysis.newUsers.totalUserNum')
                        ? PURPLE1
                        : GREEN1
                    }
                    tooltip={(point) =>
                      nivoToolTip({
                        ...point,
                        xAxisLength: lineGraphData[0].data.length,
                        tooltipType: 'Standard',
                      })
                    }
                    xScale={{
                      type: 'time',
                      format: '%Y-%m-%d',
                      useUTC: false,
                    }}
                    yScale={{
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: false,
                      reverse: false,
                    }}
                    useMesh
                    animate={true}
                    enableGridX={false}
                    data={lineGraphData.filter((data) => data.id === viewMode)}
                    axisBottom={getLineGraphBottomAxisLabel(numOfDays)}
                    axisLeft={{
                      legend: t('nivo.number'),
                      legendOffset: -60,
                      legendPosition: 'middle',
                    }}
                    margin={{
                      top: 10,
                      right: 20,
                      bottom: 100,
                      left: 70,
                    }}
                  />
                }
              </div>
            </div>
          )}
          <Row>
            {newUserData.length > 0 && (
              <Col
                style={{ maxHeight: 500, paddingRight: 25 }}
                span={24}
                sm={24}
                md={24}
                lg={10} /**New User Table */
              >
                <TableToolbar
                  columns={columns}
                  columnKeys={columnKeys}
                  rows={newUserData}
                  exportConfig={{
                    fileName: 'NEW_USER_ANALYSIS',
                  }}
                />
                <Table<NewUsersAnalysis>
                  components={vt}
                  size="small"
                  dataSource={newUserData}
                  columns={columns}
                  scroll={{ y: 300, x: 500 }}
                  rowKey={(record) => record.date}
                  pagination={{
                    pageSize: 50,
                    showTotal: (total, range) =>
                      t('general.paginationTotal', {
                        start: range[0],
                        end: range[1],
                        total: total,
                      }),
                    hideOnSinglePage: true,
                  }}
                />
              </Col>
            )}
            {pieGraphData.length > 0 /**Title, Legend, Pie Graph */ && (
              <Col
                span={24}
                sm={24}
                md={24}
                lg={14}
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div /**Pie Graph Title */
                  style={{ textAlign: 'center', fontSize: MEDIUM_FONT_SIZE }}
                >{`${t('analysis.newUsers.regularNum')}/${t(
                  'analysis.newUsers.refererNum'
                )} ${t('analysis.newUsers.users')}`}</div>
                <div style={{ width: '100%', height: 300 }} /**Pie Graph */>
                  {
                    <ResponsivePie
                      colors={{ scheme: 'paired' }}
                      tooltip={(datum) =>
                        nivoToolTip({
                          ...datum,
                          tooltipType: 'Standard',
                        })
                      }
                      data={pieGraphData}
                      arcLinkLabelsStraightLength={10}
                      arcLinkLabelsDiagonalLength={10}
                      arcLabel={(d) => (d.value !== 0 ? `${d.value}%` : '')}
                      arcLinkLabel={(d) => (d.value !== 0 ? `${d.id}` : '')}
                      margin={{
                        top: 40,
                        left: 40,
                        right: 40,
                        bottom: 40,
                      }}
                      arcLinkLabelsSkipAngle={pieGraphData.length < 2 ? 0 : 5}
                      arcLabelsSkipAngle={pieGraphData.length < 2 ? 0 : 20}
                      theme={{
                        fontSize: 12,
                        axis: {
                          legend: {
                            text: { fontSize: DEFAULT_FONT_SIZE },
                          },
                        },
                      }}
                    />
                  }
                </div>
              </Col>
            )}
          </Row>
        </Spin>
      )}
    </>
  );
};

export default NewUsers;
