import {
  AutoComplete,
  DatePicker,
  Form,
  Modal,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderData, TaskEnum } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { DATE_FORMAT } from '../../constants/systemConstants';
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';

type TaskModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  order: OrderData;
};

const TaskModal = ({ visible, setVisible, order }: TaskModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [taskEnum, setTaskEnum] = useState<TaskEnum>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taskLabelData, setTaskLabelData] = useState<{ value: string }[]>([]);

  const getTaskLabelList = () => {
    getDataWithAuthToken('task/task_label')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskLabelData(
            response.data
              ? response.data.map((taskLabel: string) => ({ value: taskLabel }))
              : []
          );
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskEnum = useCallback(() => {
    getDataWithAuthToken('task/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [t]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getTaskEnum();
    }
  }, [visible, form, getTaskEnum]);

  const onClose = () => {
    setVisible(false);
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken('task/add', {
          ...values,
          taskDate: form.getFieldValue('taskDate')
            ? moment(form.getFieldValue('taskDate')).format(DATE_FORMAT)
            : undefined,
          orderId: order.orderId ?? undefined,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              alertMessage('success', t('sortationSystem.alerts.taskAdded'));
              onClose();
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      visible={visible}
      title={t('sortationSystem.add/EditTask.addTitle')}
      onOk={onOk}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form form={form} labelCol={{ span: 6 }}>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskType')}
            name="taskType"
            initialValue="NORMAL"
            rules={[{ required: true }]}
          >
            <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
              {taskEnum &&
                taskEnum.taskType &&
                taskEnum.taskType.length > 0 &&
                taskEnum.taskType.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskStage')}
            name="taskStageGroup"
            initialValue="SORT"
            rules={[{ required: true }]}
          >
            <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
              {taskEnum &&
                taskEnum.taskStageGroup &&
                taskEnum.taskStageGroup.length > 0 &&
                taskEnum.taskStageGroup.map((item) => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.description}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('sortationSystem.taskListColumns.taskLabel')}
            name="taskLabel"
            // rules={[{ required: true }]}
          >
            <AutoComplete
              options={taskLabelData}
              allowClear
              filterOption
              onFocus={() => {
                getTaskLabelList();
              }}
            />
          </Form.Item>
          <Form.Item
            label={
              <Space>
                {t('sortationSystem.taskListColumns.taskTime')}
                <Tooltip title={t('sortationSystem.alerts.taskDateNotice')}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            }
            name="taskDate"
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default TaskModal;
