import {
  BorderlessTableOutlined,
  DollarOutlined,
  GiftOutlined,
} from '@ant-design/icons';
import {
  Col,
  Divider,
  Form,
  FormInstance,
  Grid,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import { SetStateAction, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { RED1 } from '../../../constants/color';
import { OrderEnum, UserListData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import CouponUserDropdown from '../../activity/common/CouponUserDropdown';
import ValueCardDropdown from '../../activity/common/ValueCardDropdown';

type TotalFeeFormProps = {
  form: FormInstance<any>;
  enums?: OrderEnum;
  formData: { [key: string]: any };
  isAnonymousUser: boolean;
  selectedUser?: UserListData;
  setPreviewStatus: React.Dispatch<SetStateAction<boolean>>;
};

const TotalFeeForm = ({
  form,
  enums,
  formData,
  isAnonymousUser,
  selectedUser,
  setPreviewStatus,
}: TotalFeeFormProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const [selectedValueCard, setSelectedValueCard] = useState<{
    [key: string]: any;
  }>({});

  const calculateTotalFee = () => {
    calculateTotalFeeComponent(form, formData, t, setPreviewStatus);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ integral: 0, userCouponsId: 0, valueCardId: 0 }}
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        <Space>
          <BorderlessTableOutlined />
          {t('order.orderDetail.orderFee')}
        </Space>
      </Divider>
      <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.goodsAmount')}: `}
            style={{ marginBottom: 0 }}
            name="goodsAmount"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.gst')}: `}
            style={{ marginBottom: 0 }}
            name="gstFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.pst')}: `}
            style={{ marginBottom: 0 }}
            name="pstFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.depositFee')}: `}
            style={{ marginBottom: 0 }}
            name="depositFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.recycleFee')}: `}
            style={{ marginBottom: 0 }}
            name="recycleFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.shippingFee')}: `}
            style={{ marginBottom: 0 }}
            name="shippingFee"
          >
            <InputNumber
              disabled={
                !hasPermission(actionPermissions.orderGroup.orderManage)
              }
              type="number"
              precision={2}
              min={0}
              prefix="+"
              onBlur={() => calculateTotalFee()}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.payFee')}: `}
            style={{ marginBottom: 0 }}
            name="payFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              readOnly
              bordered={false}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.tipsFee')}: `}
            style={{ marginBottom: 0 }}
            name="tipsFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              disabled={
                !hasPermission(actionPermissions.orderGroup.orderManage)
              }
              onBlur={() => calculateTotalFee()}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.packFee')}: `}
            style={{ marginBottom: 0 }}
            name="packFee"
          >
            <InputNumber
              type="number"
              precision={2}
              min={0}
              prefix="+"
              disabled={
                !hasPermission(actionPermissions.orderGroup.orderManage)
              }
              onBlur={() => calculateTotalFee()}
            />
          </Form.Item>
        </Col>
        {!isAnonymousUser && (
          <Col
            style={{
              width: screens.lg
                ? '20%'
                : screens.md
                ? '20%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.discountAmount')}: `}
              style={{ marginBottom: 0 }}
              name="discountAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                min={0}
                prefix="-"
                onBlur={() =>
                  !getDashboardStyle().isSalesForceAppType &&
                  calculateTotalFee()
                }
                readOnly={getDashboardStyle().isSalesForceAppType}
                bordered={!getDashboardStyle().isSalesForceAppType}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 24]} justify="end" style={{ marginBottom: 24 }}>
        {!isAnonymousUser && (
          <Col
            style={{
              width: screens.lg
                ? '20%'
                : screens.md
                ? '20%'
                : screens.sm
                ? '50%'
                : '100%',
            }}
          >
            <Form.Item
              label={`${t('order.orderDetail.totalAmount')}: `}
              style={{ marginBottom: 0 }}
              name="totalAmount"
            >
              <InputNumber
                type="number"
                precision={2}
                min={0}
                readOnly
                bordered={false}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 24]} style={{ marginBottom: 24 }}>
        {!isAnonymousUser && (
          <>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={`${t('order.orderDetail.bonusAmount')}: `}
                style={{ marginBottom: 0 }}
                name="bonusAmount"
              >
                <InputNumber
                  type="number"
                  precision={2}
                  min={0}
                  prefix="-"
                  readOnly
                  bordered={false}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={`${t('order.orderDetail.couponsAmount')}: `}
                style={{ marginBottom: 0 }}
                name="couponsAmount"
              >
                <InputNumber
                  type="number"
                  precision={2}
                  min={0}
                  prefix="-"
                  readOnly
                  bordered={false}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={`${t('order.orderDetail.coupon')}: `}
                style={{ marginBottom: 0 }}
                name="userCouponsId"
              >
                <CouponUserDropdown
                  userId={formData.userId}
                  sellerId={formData.sellerId}
                  defaultOptions={true}
                  onChange={(value) => {
                    form.setFieldsValue({ userCouponsId: value });
                    calculateTotalFee();
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={`${t('order.orderDetail.integralAmount')}: `}
                style={{ marginBottom: 0 }}
                name="integralAmount"
              >
                <InputNumber
                  type="number"
                  precision={2}
                  min={0}
                  prefix="-"
                  readOnly
                  bordered={false}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={
                  <Space wrap>
                    {`${t('order.orderDetail.integral')}: `}
                    {selectedUser && selectedUser.payPoints !== undefined && (
                      <Space size={3}>
                        <GiftOutlined />
                        {selectedUser.payPoints}
                      </Space>
                    )}
                  </Space>
                }
                style={{ marginBottom: 0 }}
                name="integral"
              >
                <Select
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage)
                  }
                  onChange={() => {
                    calculateTotalFee();
                  }}
                >
                  {enums &&
                    enums.integral &&
                    enums.integral.map((value) => (
                      <Select.Option
                        key={value}
                        value={value}
                        disabled={
                          selectedUser && selectedUser.payPoints < value
                        }
                      >
                        {value}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={`${t('order.orderDetail.valueCardAmount')}: `}
                style={{ marginBottom: 0 }}
                name="valueCardAmount"
              >
                <InputNumber
                  type="number"
                  precision={2}
                  min={0}
                  prefix="-"
                  readOnly={selectedValueCard.id <= 0}
                  bordered={selectedValueCard.id > 0}
                  onBlur={() => {
                    if (selectedValueCard.id > 0) {
                      calculateTotalFee();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '20%'
                  : screens.md
                  ? '20%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={
                  <Space wrap>
                    {`${t('order.orderDetail.valueCard')}: `}
                    {selectedValueCard.id > 0 && (
                      <Space size={3}>
                        <DollarOutlined />
                        {selectedValueCard.value}
                      </Space>
                    )}
                  </Space>
                }
                style={{ marginBottom: 0 }}
                name="valueCardId"
              >
                <ValueCardDropdown
                  userId={formData.userId}
                  sellerId={formData.sellerId}
                  defaultOptions={true}
                  onChange={(value, option: any) => {
                    setSelectedValueCard({
                      id: Number(option.valueCard.vcId),
                      value: option.valueCard.cardMoney,
                    });
                    form.setFieldsValue({ valueCardId: value });
                    calculateTotalFee();
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                width: screens.lg
                  ? '40%'
                  : screens.md
                  ? '40%'
                  : screens.sm
                  ? '50%'
                  : '100%',
              }}
            >
              <Form.Item
                label={
                  <Space wrap style={{ marginBottom: -16 }}>
                    {`${t('order.orderDetail.surplusAmount')}: `}
                    {selectedUser && selectedUser.userMoney !== undefined && (
                      <Space size={3}>
                        <DollarOutlined />
                        <div
                          style={
                            Number(selectedUser.userMoney)
                              ? {
                                  fontSize: 24,
                                  fontWeight: 500,
                                  color: RED1,
                                }
                              : undefined
                          }
                        >
                          {selectedUser.userMoney}
                        </div>
                      </Space>
                    )}
                  </Space>
                }
                style={{ marginBottom: 0, marginTop: -8 }}
                name="surplusAmount"
              >
                <InputNumber
                  type="number"
                  precision={2}
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage)
                  }
                  min={0}
                  prefix="-"
                  onBlur={() => calculateTotalFee()}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row gutter={[16, 24]} justify="end">
        <Col
          style={{
            width: screens.lg
              ? '20%'
              : screens.md
              ? '20%'
              : screens.sm
              ? '50%'
              : '100%',
          }}
        >
          <Form.Item
            label={`${t('order.orderDetail.orderAmount')}: `}
            style={{ marginBottom: 0 }}
            name="orderAmount"
          >
            <InputNumber
              type="number"
              precision={2}
              readOnly
              bordered={false}
              style={{ color: RED1 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const calculateTotalFeeComponent = (
  form: FormInstance<any>,
  formData: { [key: string]: any },
  t: TFunction<'translation'>,
  setPreviewStatus: React.Dispatch<SetStateAction<boolean>>
) => {
  setPreviewStatus(false);
  postDataWithAuthToken('order/preview', {
    shippingId: formData.shippingId,
    shippingCode: formData.shippingCode,
    payId: formData.payId,
    postalCode: formData.postalCode,
    orderGoodsList: formData.orderGoodsList.map((good: any) => ({
      goodsId: good.goodsId,
      goodsNumber: good.goodsNumber,
      goodsName: good.goodsName,
      goodsPrice: good.goodsPrice,
      goodsSkuAttrIds:
        (good.goodsAttrId && good.goodsAttrId.split(',')) || undefined,
      mainGoodsPrice: good.mainGoodsPrice,
      goodsWeight: good.goodsWeight || undefined,
    })),
    payFee:
      form.getFieldValue('payFee') !== undefined
        ? form.getFieldValue('payFee')
        : undefined,
    shippingFee:
      form.getFieldValue('shippingFee') !== undefined
        ? form.getFieldValue('shippingFee')
        : undefined,
    tipsFee:
      form.getFieldValue('tipsFee') !== undefined
        ? form.getFieldValue('tipsFee')
        : undefined,
    packFee:
      form.getFieldValue('packFee') !== undefined
        ? form.getFieldValue('packFee')
        : undefined,
    discountAmount:
      form.getFieldValue('discountAmount') !== undefined
        ? form.getFieldValue('discountAmount')
        : undefined,
    integral:
      form.getFieldValue('integral') !== undefined
        ? form.getFieldValue('integral')
        : undefined,
    surplusAmount:
      form.getFieldValue('surplusAmount') !== undefined
        ? form.getFieldValue('surplusAmount')
        : undefined,
    userCouponsId:
      form.getFieldValue('userCouponsId') !== undefined
        ? form.getFieldValue('userCouponsId')
        : undefined,
    sellerId: formData.sellerId,
    userId: formData.userId,
    valueCardId:
      form.getFieldValue('valueCardId') !== undefined
        ? form.getFieldValue('valueCardId')
        : undefined,
    valueCardAmount:
      form.getFieldValue('valueCardAmount') !== undefined
        ? form.getFieldValue('valueCardAmount')
        : undefined,
  })
    .then((response) => {
      if (response && response.goodStatus) {
        setPreviewStatus(true);
        form.setFieldsValue({ ...response.data.orderAmount });
      } else {
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export default TotalFeeForm;
