import {
  DownOutlined,
  PlusOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Card,
  Typography,
  Table,
  Grid,
  Space,
  Button,
  Popconfirm,
  InputNumber,
  Breadcrumb,
  Dropdown,
  Menu,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import FourZeroThree from '../../components/FourZeroThree';
import AlbumModal from '../../components/settings/photoGallery/AlbumModal';
import PhotoModal from '../../components/settings/photoGallery/PhotoModal';
import TableToolbar from '../../components/table/TableToolbar';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { GalleryAlbum, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { PRIMARY } from '../../constants/color';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

const PhotoGallery = () => {
  const { t } = useTranslation();
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [parentId, setParentId] = useState(0);
  const [parentAlbum, setParentAlbum] = useState<GalleryAlbum>();
  const [editAlbum, setEditAlbum] = useState<GalleryAlbum>();
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showAlbumModal, setShowAlbumModal] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [galleryData, setGalleryData] = useState<GalleryAlbum[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [breadCrumbItems, setBreadCrumbItems] = useState<React.ReactNode[]>([]);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const ellipsis = useState(true);
  const [keyword, setKeyword] = useState('');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys = [
    'id',
    'albumName',
    'photoCount',
    'childCount',
    'albumDesc',
    'sortOrder',
    'addTime',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<GalleryAlbum> = [
    {
      title: setFont(t('settings.photoGalleryColumns.id'), fontSize),
      key: 'id',
      dataIndex: 'albumId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      render: (text: string, record: GalleryAlbum) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => onViewSubAlbum(record)}
          disabled={!hasPermission(actionPermissions.settingGroup.galleryAlbum)}
        >
          {text}
        </Button>
      ),
      sorter: (a: GalleryAlbum, b: GalleryAlbum) =>
        compare(a.albumId, b.albumId),
    },
    {
      title: setFont(t('settings.photoGalleryColumns.albumName'), fontSize),
      key: 'albumName',
      dataIndex: 'albumName',
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string, record: GalleryAlbum) => (
        <Typography.Text
          style={ellipsis ? { width: 100, fontSize: fontSize } : undefined}
          ellipsis={ellipsis ? { tooltip: text } : false}
        >
          <Button
            disabled={
              !hasPermission(actionPermissions.settingGroup.galleryAlbum)
            }
            type="link"
            onClick={() => onViewSubAlbum(record)}
          >
            {text}
          </Button>
        </Typography.Text>
      ),
      sorter: (a: GalleryAlbum, b: GalleryAlbum) =>
        compare(a.albumName, b.albumName),
    },
    {
      title: setFont(t('settings.photoGalleryColumns.photoCount'), fontSize),
      key: 'photoCount',
      dataIndex: 'photoCount',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: GalleryAlbum, b: GalleryAlbum) =>
        compare(a.photoCount, b.photoCount),
    },
    {
      title: setFont(t('settings.photoGalleryColumns.albumCount'), fontSize),
      key: 'childCount',
      dataIndex: 'childCount',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: GalleryAlbum, b: GalleryAlbum) =>
        compare(a.childCount, b.childCount),
    },
    {
      title: setFont(t('settings.photoGalleryColumns.albumDesc'), fontSize),
      key: 'albumDesc',
      dataIndex: 'albumDesc',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.photoGalleryColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      defaultSortOrder: 'ascend',
      sorter: (a: GalleryAlbum, b: GalleryAlbum) =>
        compare(a.sortOrder, b.sortOrder),
      render: (value: number, record: GalleryAlbum) => (
        <InputNumber
          type="number"
          min={0}
          max={50000}
          style={{ fontSize: fontSize }}
          value={value}
          disabled={!hasPermission(actionPermissions.settingGroup.galleryAlbum)}
          onPressEnter={(e) => handleSortOrder(e, record)}
          placeholder="0-50000"
        />
      ),
    },
    {
      title: setFont(t('settings.photoGalleryColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: GalleryAlbum, b: GalleryAlbum) =>
        compare(a.addTime, b.addTime),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: GalleryAlbum) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => onEdit(record)}
            disabled={
              !hasPermission(actionPermissions.settingGroup.galleryAlbum)
            }
          >
            {t('actionsColumn.edit')}
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="viewPhoto" onClick={() => onViewPhoto(record)}>
                  {setFont(
                    t('settings.photoGalleryColumns.viewPhoto'),
                    fontSize
                  )}
                </Menu.Item>
                <Menu.Item
                  key="viewAlbum"
                  onClick={() => onViewSubAlbum(record)}
                >
                  {setFont(
                    t('settings.photoGalleryColumns.viewAlbum'),
                    fontSize
                  )}
                </Menu.Item>
                <Menu.Item key="delete">
                  <Popconfirm
                    title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
                    onConfirm={() => {
                      onDelete(record.albumId);
                    }}
                    okText={t('actionsColumn.confirmation.yes')}
                    cancelText={t('actionsColumn.confirmation.no')}
                  >
                    <Typography.Text
                      type="danger"
                      style={{ fontSize: fontSize }}
                    >
                      {t('actionsColumn.delete')}
                    </Typography.Text>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.settingGroup.galleryAlbum)
              }
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getGalleryData = useCallback(() => {
    if (isSubscribed.current) setIsLoading(true);
    getDataWithAuthToken('gallery/list', {
      params: {
        parentId: parentId,
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGalleryData(response.data.list);
            setTotalItems(response.data.totalItem);
            setParentAlbum(response.data.data);

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, parentId, page, pageSize, keyword]);

  useEffect(() => {
    getGalleryData();
  }, [getGalleryData]);

  const handleSortOrder = (e: React.KeyboardEvent, album: GalleryAlbum) => {
    let target = e.target as HTMLInputElement;
    if (!target.value) {
      alertMessage('error', t('general.inputError.empty'));
      return;
    }

    if (
      parseInt(target.value) < 0 ||
      parseInt(target.value) > 50000 ||
      isNaN(parseInt(target.value))
    ) {
      return alertMessage('error', t('settings.inputError.sortOrder'));
    }
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('gallery/edit', {
      ...album,
      sortOrder: parseInt(target.value),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getGalleryData();
          alertMessage('success', t('settings.alerts.albumEdited'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  };

  const onEdit = (album: GalleryAlbum) => {
    if (isSubscribed.current) {
      setEditAlbum(album);
      setShowAlbumModal(true);
    }
  };

  const onAdd = () => {
    if (isSubscribed.current) {
      setEditAlbum(undefined);
      setShowAlbumModal(true);
    }
  };

  const onDelete = (id: number) => {
    postDataWithAuthToken('gallery/delete', { albumId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.albumDeleted'));
          getGalleryData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onGoBack = (album: GalleryAlbum) => {
    if (isSubscribed.current) {
      let temp = breadCrumbItems;
      temp.pop();
      setBreadCrumbItems(temp);
      setParentId(album.parentId);
      setPage(1);
    }
  };

  const onClickOnBreadcrumb = (e: React.MouseEvent) => {
    let target = e.target as HTMLAnchorElement;
    if (target.tabIndex !== breadCrumbItems.length - 1) {
      let temp = breadCrumbItems;
      temp.splice(target.tabIndex + 1);
      if (isSubscribed.current) {
        setBreadCrumbItems(temp);
        setParentId(parseInt(target.id));
        setPage(1);
      }
    }
  };

  const onViewSubAlbum = (album: GalleryAlbum) => {
    let items = breadCrumbItems;
    items.push(
      <Breadcrumb.Item key={album.albumId}>
        <span
          id={album.albumId.toString()}
          tabIndex={breadCrumbItems.length}
          onClick={onClickOnBreadcrumb}
          style={{ cursor: 'pointer', color: PRIMARY }}
        >
          {album.albumName}
        </span>
      </Breadcrumb.Item>
    );
    if (isSubscribed.current) {
      setBreadCrumbItems(items);
      setPage(1);
      setParentId(album.albumId);
    }
  };

  const onViewPhoto = (album: GalleryAlbum) => {
    if (isSubscribed.current) {
      setEditAlbum(album);
      setShowPhotoModal(true);
    }
  };

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('settings.photoGallery')}
          </Typography.Title>
          {hasPermission(actionPermissions.settingGroup.galleryAlbum) &&
            !!(breadCrumbItems && breadCrumbItems.length) && (
              <Breadcrumb separator=">" style={{ marginBottom: 8 }}>
                <Breadcrumb.Item key="0">
                  <span
                    onClick={() => {
                      setBreadCrumbItems([]);
                      setParentId(0);
                    }}
                    style={{ cursor: 'pointer', color: PRIMARY }}
                  >
                    {t('settings.photoGalleryColumns.topAlbum')}
                  </span>
                </Breadcrumb.Item>
                {breadCrumbItems}
              </Breadcrumb>
            )}
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Space>
                {parentAlbum && JSON.stringify(parentAlbum) !== '{}' && (
                  <Button
                    disabled={
                      !hasPermission(
                        actionPermissions.settingGroup.galleryAlbum
                      )
                    }
                    icon={<RollbackOutlined />}
                    onClick={() => onGoBack(parentAlbum)}
                  >
                    {t('settings.photoGalleryActions.back')}
                  </Button>
                )}
                <Button
                  disabled={
                    !hasPermission(actionPermissions.settingGroup.galleryAlbum)
                  }
                  icon={<PlusOutlined />}
                  onClick={onAdd}
                >
                  {t('settings.add/editAlbum.addTitle')}
                </Button>
              </Space>
            }
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            tableSize={tableSize}
            setTableSize={setTableSize}
            totalItems={totalItems}
            refresh={() => getGalleryData()}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t(
              'searchPlaceholders.searchPhotoGalleryKeyword'
            )}
          />
          <Table<GalleryAlbum>
            sticky
            rowKey={(record) => record.albumId}
            loading={isLoading}
            size={tableSize}
            dataSource={galleryData}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            pagination={{
              total: totalItems,
              pageSize: pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
              },
              current: page,
            }}
          />
        </Card>
      )}
      <AlbumModal
        visible={showAlbumModal}
        setVisible={setShowAlbumModal}
        album={editAlbum}
        parent={parentAlbum}
        callBack={() => getGalleryData()}
      />
      <PhotoModal
        visible={showPhotoModal}
        setVisible={setShowPhotoModal}
        album={editAlbum}
        refresh={() => getGalleryData()}
      />
    </Container>
  );
};

export default PhotoGallery;
