import { Col, Empty, Form, Image, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FALLBACK_IMG } from '../../../constants/styles';
import { OrderData, OrderFormValue } from '../../../types';

type OrderCreditFormInfoProps = {
  orderInfo?: OrderData;
};

const OrderCreditFormInfo = ({ orderInfo }: OrderCreditFormInfoProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return orderInfo &&
    orderInfo.orderExtend &&
    orderInfo.orderExtend.creditFormValue ? (
    <Form form={form} layout="vertical">
      <Row gutter={[16, 24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Text strong style={{ fontSize: 16 }}>
            {t('order.orderDetail.creditFormInfo')}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 24]}>
            {orderInfo.orderExtend.creditFormValue.map(
              (item: OrderFormValue) => (
                <Col key={item.field_id} span={24}>
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <Typography.Text strong>
                        {item.field_name}
                      </Typography.Text>
                    </Col>
                    {Object.keys(item.child).map((key: any) => (
                      <Col key={key} span={24} md={12} lg={6}>
                        {
                          <Form.Item
                            label={`${item.child[key].field_name}: `}
                            style={{
                              marginBottom: 0,
                              display: item.child[key].isShow
                                ? undefined
                                : 'none',
                            }}
                            // rules={[{ required: item.child[key].isRequired }]}
                            // name={`${item.field_id}|${key}`}
                            // initialValue={item.child[key].value}
                          >
                            {item.child[key].fieldType === 'FILE' ||
                            item.child[key].fieldType === 'P_FILE' ? (
                              <Image
                                src={item.child[key].fullValue}
                                fallback={FALLBACK_IMG}
                                style={{ maxWidth: 200 }}
                              />
                            ) : (
                              <Typography.Text>
                                {item.child[key].value}
                              </Typography.Text>
                            )}
                          </Form.Item>
                        }
                      </Col>
                    ))}
                  </Row>
                </Col>
              )
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  ) : (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.creditFormInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderCreditFormInfo;
