import Container from '../../components/Container';

import { Card, Space, Switch, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import OrderOverview from '../../components/analysis/order/OrderOverview';
import OrderDistribution from '../../components/analysis/order/OrderDistribution';
import OrderDetail from '../../components/analysis/order/OrderDetail';
import { useState } from 'react';
import { EXTENDED_TIMEOUT } from '../../constants/systemConstants';
import getDashboardStyle from '../../utils/getDashboardStyle';

const OrderAnalysis = () => {
  //General Components
  const [isSeller, setIsSeller] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  //Text Components
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  return (
    <Container>
      <Card bodyStyle={{ minHeight: 250 }}>
        <Space>
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('analysis.orderTitle')}
          </Typography.Title>
          {getDashboardStyle().isSellerSwitch && (
            <Switch
              loading={isLoading}
              onChange={(checked) => {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => {
                    setIsSeller(checked);
                  }, EXTENDED_TIMEOUT)
                );
              }}
              style={{ marginBottom: 12 }}
              checkedChildren={t('goods.goodsListColumns.seller')}
              unCheckedChildren={t('goods.goodsListColumns.self')}
            />
          )}
        </Space>
        <Tabs destroyInactiveTabPane defaultActiveKey="1">
          <Tabs.TabPane tab={t(`analysis.orderOverview.tab1Title`)} key={'1'}>
            <OrderOverview
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(`analysis.orderDistribution.tab2Title`)}
            key={'2'}
          >
            <OrderDistribution
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t(`analysis.orderDetail.tab3Title`)} key={'3'}>
            <OrderDetail
              isSeller={isSeller}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Container>
  );
};

export default OrderAnalysis;
