import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Popconfirm,
  Popover,
  Space,
  Table,
  Form,
  Input,
  Tag,
  Grid,
  Typography,
  Badge,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FourZeroThree from '../../../components/FourZeroThree';
import TableToolbar from '../../../components/table/TableToolbar';
import UserAddressModal from '../../../components/users/UserAddressModal';
import { AddressData, FontSizeType } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import { dashboardRoute } from '../../../constants/pathname';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import { hasPermission } from '../../../utils/hasPermission';
import { compare, setFont } from '../../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../FiveHundred';
import { actionPermissions } from '../../../constants/actionPermissions';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import UsersDropdown from '../common/UsersDropdown';
import { tableScrollToTop } from '../../../utils/helperFunction';
import { RED1 } from '../../../constants/color';

type UserAddressTableProps = {
  id?: string;
};

/**
 * Displays all User Addresses
 * /dashboard/users/address
 */
const UserAddressTable = ({ id }: UserAddressTableProps) => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const isSubscribed = useRef(true);
  const [addressData, setAddressData] = useState<Array<AddressData>>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [editAddress, setEditAddress] = useState<AddressData | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<AddressData[]>([]);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const columnKeys = [
    'addressId',
    'userName',
    'addressType',
    'deliveryRegion',
    'consignee',
    'address',
    'mobile',
    'postalCode',
    'updateTime',
    'action',
    'unitNumber',
    'buzzerCode',
    'tel',
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((col: string) => {
      return col !== 'tel';
    })
  );
  const [form] = Form.useForm();
  const [advance, setAdvance] = useState<{
    mobile?: string;
    consignee?: string;
    user_name?: string;
  }>({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [keyword, setKeyword] = useState('');
  const { addTab } = useTab();
  const formRef = useRef(null);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Retrieves and sets AddressData, TotalLogs based on given params.
   * Alerts and console logs errors
   */
  const getAddressData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken('users/user_address/list', {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword || undefined,
        userId: id
          ? id
          : formRef.current && form.getFieldValue('search_user')
          ? form.getFieldValue('search_user')
          : undefined,
        mobilePhone:
          (formRef.current && form.getFieldValue('mobile')) || undefined,
        consignee:
          (formRef.current && form.getFieldValue('consignee')) || undefined,
        userName:
          (formRef.current && form.getFieldValue('username')) || undefined,
      },
    })
      .then((result) => {
        if (isSubscribed.current) {
          if (result && result.goodStatus && result.data) {
            if (isSubscribed.current) {
              setAddressData(result.data.list);
              setTotalItems(result.data.totalItem);
              setSelectedRowKeys([]);
              setLoading(false);
              // Scroll to top when data changes
              tableScrollToTop();
            }

            // setLoading(false);
          } else if (result && result.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            setFiveHundred(true);
            setLoading(false);
            alertMessage(
              'error',
              result?.msg || t('general.noResponse'),
              result?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  }, [t, page, pageSize, keyword, form, id]);

  useEffect(() => {
    getAddressData();
  }, [getAddressData]);

  /**
   * Edits user address by ID
   * Refreshes AddressData after action
   * Alerts and console logs errors
   */
  const onEdit = (address: any) => {
    if (isSubscribed.current) {
      setShowModal(true);
      setEditAddress(address);
    }
  };

  /**
   * Deletes batch user address by addressID
   * Refreshes AddressData after action
   * Alerts and console logs errors
   */
  const onDelete = (ids: number[]) => {
    postDataWithAuthToken('users/user_address/delete_batch', {
      addressIdList: ids,
    })
      .then((result) => {
        if (result && result.goodStatus) {
          getAddressData();
          alertMessage('success', t('users.alerts.addressDeleted'));
        } else
          alertMessage(
            'error',
            result?.msg || t('general.noResponse'),
            result?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: ColumnsType<AddressData> = [
    {
      title: setFont(t('users.userAddressColumns.id'), fontSize),
      dataIndex: 'addressId',
      key: 'addressId',
      width: 70,
      fixed: screens.lg ? 'left' : false,
      render: (text: string, address: AddressData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.userGroup.userAddress)}
          onClick={() => onEdit(address)}
        >
          {text}
        </Button>
      ),
      sorter: (a: AddressData, b: AddressData) =>
        compare(a.addressId, b.addressId),
    },
    {
      title: setFont(t('users.userAddressColumns.username'), fontSize),
      dataIndex: 'user',
      key: 'userName',
      width: 180,
      sorter: (a: AddressData, b: AddressData) =>
        compare(a.user ? a.user.userName : '', b.user ? b.user.userName : ''),
      render: (value: string, userAddress: AddressData) => (
        <Space direction="vertical">
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !userAddress.user ||
              !hasPermission(actionPermissions.userGroup.userView)
            }
            onClick={() =>
              addTab(
                '',
                `${dashboardRoute.users.detail}?user_id=${
                  userAddress.user ? userAddress.user.userId : ''
                }`
              )
            }
          >
            {userAddress.user ? userAddress.user.userName : ''}
          </Button>
          {userAddress.isDefaultAddress && (
            <Tag color={RED1} style={{ fontSize: fontSize }}>
              {t('users.userAddressColumns.default')}
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: setFont(t('users.userAddressColumns.type'), fontSize),
      dataIndex: 'addressType',
      key: 'addressType',
      width: 160,
      render: (value: string, data: AddressData) => (
        <Tag color={data.addressType.tagColor} style={{ fontSize: fontSize }}>
          {data.addressType.description}
        </Tag>
      ),
    },
    {
      title: setFont(t('users.userAddressColumns.deliveryRegion'), fontSize),
      dataIndex: 'deliveryRegion',
      key: 'deliveryRegion',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AddressData, b: AddressData) =>
        compare(a.deliveryRegion, b.deliveryRegion),
    },
    {
      title: setFont(t('users.userAddressColumns.consignee'), fontSize),
      dataIndex: 'consignee',
      key: 'consignee',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AddressData, b: AddressData) =>
        compare(a.consignee, b.consignee),
    },
    {
      title: setFont(t('users.userAddressColumns.address'), fontSize),
      dataIndex: 'address',
      key: 'address',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userAddressColumns.unit'), fontSize),
      dataIndex: 'unitNumber',
      key: 'unitNumber',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userAddressColumns.buzzer'), fontSize),
      dataIndex: 'buzzerCode',
      key: 'buzzerCode',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userAddressColumns.tel'), fontSize),
      dataIndex: 'tel',
      key: 'tel',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userAddressColumns.mobile'), fontSize),
      dataIndex: 'mobile',
      key: 'mobile',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userAddressColumns.postalCode'), fontSize),
      dataIndex: 'postalCode',
      key: 'postalCode',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('users.userAddressColumns.updateTime'), fontSize),
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
      sorter: (a: AddressData, b: AddressData) =>
        compare(a.updateTime, b.updateTime),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      key: 'action',
      fixed: screens.lg ? 'right' : false,
      width: 120,
      render: (address) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => onEdit(address)}
            disabled={!hasPermission(actionPermissions.userGroup.userAddress)}
          >
            {t('actionsColumn.edit')}
          </Button>
          {!getDashboardStyle().isSalesForceAppType && (
            <Popconfirm
              title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
              onConfirm={() => {
                onDelete([address.addressId]);
              }}
              okText={t('actionsColumn.confirmation.yes')}
              cancelText={t('actionsColumn.confirmation.no')}
              placement="topRight"
              disabled={!hasPermission(actionPermissions.userGroup.userAddress)}
            >
              <Button
                danger
                type="link"
                style={{ padding: 0, fontSize: fontSize }}
                disabled={
                  !hasPermission(actionPermissions.userGroup.userAddress)
                }
              >
                {t('actionsColumn.delete')}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  /**
   * The popover window which contains the "Mobile" "Consignee" "Username" "User ID" filters, Search and Reset buttons
   * If the admin does not have the permission, the admin would not be able to filter by "User ID"
   */
  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      title={t('users.userAddressColumns.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          style={{ width: 250 }}
        >
          <Form.Item
            name="mobile"
            label={t('users.userAddressColumns.advancedSearch.mobile')}
          >
            <Input
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  mobile: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item
            name="consignee"
            label={t('users.userAddressColumns.advancedSearch.consignee')}
          >
            <Input
              onChange={(e) =>
                setAdvance((prev) => ({
                  ...prev,
                  consignee: e.target.value,
                }))
              }
            />
          </Form.Item>
          {!id && (
            <Form.Item
              name="search_user"
              label={t('users.userAddressColumns.advancedSearch.userId')}
            >
              <UsersDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    user_name: value,
                  }));
                }}
              />
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Space wrap>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getAddressData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('users.userAddressColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={
                  !advance.mobile && !advance.consignee && !advance.user_name
                }
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else getAddressData();
                }}
              >
                {t('users.userAddressColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('users.userAddressColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );
  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {id ? (
        <Typography.Title level={4} style={{ marginBottom: 12 }}>
          {t('users.userAddress')}
        </Typography.Title>
      ) : (
        <h1 style={{ fontSize: 24 }}>{t('users.userAddress')}</h1>
      )}
      <TableToolbar
        leftElement={
          id ? (
            <Button
              disabled={!hasPermission(actionPermissions.userGroup.userAddress)}
              icon={<PlusOutlined />}
              onClick={() => {
                setEditAddress(undefined);
                setShowModal(true);
              }}
            >
              {t('users.add/editAddress.addTitle')}
            </Button>
          ) : undefined
        }
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getAddressData()}
        totalItems={totalItems}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        search={(keyword) => {
          setKeyword(keyword);
          setPage(1);
        }}
        searchPlaceholder={t('searchPlaceholders.searchAddressKeyword')}
        advancedSearch={advancedSearch}
        rows={addressData.map((address) => ({
          ...address,
          user: address.user ? address.user.userName : null,
        }))}
        exportConfig={{ fileName: 'USER_ADDRESS' }}
      />
      <Table<AddressData>
        loading={loading}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        dataSource={addressData}
        rowKey={(address) => address.addressId}
        size={tableSize}
        pagination={{
          total: totalItems,
          pageSize: pageSize,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          onChange: (page, size) => {
            setPage(page);
            setPageSize(size || pageSize);
            setSelectedRowKeys([]);
          },
          defaultPageSize: pageSize,
          current: page,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: AddressData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          selectedRows={selectedRows.map((address) => ({
            ...address,
            user: address.user.userName ? address.user.userName : '',
          }))}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          funct={{
            deleteFunc:
              hasPermission(actionPermissions.userGroup.userAddress) &&
              !getDashboardStyle().isSalesForceAppType
                ? () => onDelete(selectedRowKeys)
                : undefined,
            exportConfig: { fileName: 'USER_ADDRESS' },
          }}
        />
      )}
      <UserAddressModal
        visible={showModal}
        setVisible={setShowModal}
        editAddress={editAddress}
        refresh={() => getAddressData()}
        userId={id}
      />
    </>
  );
};

export default UserAddressTable;
