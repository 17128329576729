import { Dispatch, SetStateAction } from 'react';
import { OrderFormValue } from '../types';
import { postDataWithAuthToken } from './axiosRequest';
import { DATE_FORMAT } from '../constants/systemConstants';
import { alertMessage } from './alertMessage';
import { FormInstance } from 'antd';
import { hasPermission } from './hasPermission';
import { actionPermissions } from '../constants/actionPermissions';

export const onOrderSubmit = (
  formData: { [key: string]: any },
  t: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  onClose: Function,
  callBack?: () => void
) => {
  const { orderGoodsListTotal, shippingTransportId, isRegionSale, ...rest } =
    formData;
  setIsLoading(true);
  postDataWithAuthToken('order/add', {
    ...rest,
    orderGoodsList: formData.orderGoodsList.map((good: any) => ({
      goodsWeight: good.goodsWeight,
      goodsId: good.goodsId,
      goodsNumber: good.goodsNumber,
      goodsName: good.goodsName,
      goodsUnit: good.goodsUnit,
      goodsPrice: good.goodsPrice,
      goodsSkuAttrIds:
        (good.goodsAttrId && good.goodsAttrId.split(',')) || undefined,
      mainGoodsPrice: good.mainGoodsPrice,
    })),
    expectShippingTimeInterval:
      formData.expectShippingTimeInterval || undefined,
    expectShippingTime: formData.expectShippingTime
      ? formData.expectShippingTime.format(DATE_FORMAT)
      : '',
    payName: formData.payName || undefined,
    shippingName: formData.shippingName || undefined,
    shippingCode: formData.shippingCode || undefined,
    goodsAmount: formData.goodsAmount || undefined,
    gstFee: formData.gstFee || undefined,
    pstFee: formData.pstFee || undefined,
    shippingFee: formData.shippingFee || undefined,
    payFee: formData.payFee || undefined,
    tipsFee: formData.tipsFee || undefined,
    packFee: formData.packFee || undefined,
    discountAmount: formData.discountAmount || undefined,
    couponsAmount: formData.couponsAmount || undefined,
    bonusAmount: formData.bonusAmount || undefined,
    integral: formData.integral || undefined,
    surplusAmount: formData.surplusAmount || undefined,
    userCouponsId: formData.userCouponsId || undefined,
    formValue: formData.formValue.map((form: OrderFormValue) => {
      let modifiedChild: any = {};
      Object.keys(form.child).forEach((key) => {
        modifiedChild[key] = {
          field_name: form.child[key].field_name,
          value: form.child[key].value,
        };
      });

      return {
        field_id: form.field_id,
        field_code: form.field_code,
        field_name: form.field_name,
        child: modifiedChild,
      };
    }),
  })
    .then((response) => {
      if (response && response.goodStatus) {
        alertMessage('success', t('order.alerts.orderAdded'));
        callBack && callBack();
        onClose();
      } else {
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
};

export const onNextStepClick = (
  form: FormInstance<any>,
  formData: { [key: string]: any },
  setFormData: React.Dispatch<SetStateAction<{ [key: string]: any }>>,
  currentStep: number,
  setCurrentStep: React.Dispatch<SetStateAction<number>>,
  otherInfoStep: number,
  calculateTotalFee: () => void
) => {
  form
    .validateFields()
    .then((values) => {
      if (currentStep === otherInfoStep) {
        // step of buyer remark...
        const { buyerRemark, sellerRemark, deliveryRemark, ...rest } = values;

        let updatedFormValues: OrderFormValue[] = formData.formValue || [];

        Object.keys(rest).forEach((key) => {
          let index = updatedFormValues.findIndex(
            (item) => item.field_id.toString() === key.split('|')[0]
          );
          updatedFormValues[index] = {
            ...updatedFormValues[index],
            child: {
              ...updatedFormValues[index].child,
              [key.split('|')[1]]: {
                ...updatedFormValues[index].child[key.split('|')[1]],
                field_name:
                  updatedFormValues[index].child[key.split('|')[1]].field_name,
                value: rest[key],
              },
            },
          };
        });
        setFormData((prev: any) => ({
          ...prev,
          buyerRemark: buyerRemark,
          sellerRemark: sellerRemark,
          deliveryRemark: deliveryRemark,
          formValue: updatedFormValues,
        }));
      } else {
        setFormData((prev: any) => ({
          ...prev,
          ...values,
        }));
      }
      setCurrentStep((prev) => prev + 1);
      form.resetFields();
      form.setFieldsValue({ ...formData });

      if (
        currentStep === otherInfoStep &&
        hasPermission(actionPermissions.orderGroup.orderManage)
      )
        calculateTotalFee();
    })
    .catch((err) => console.log(err));
};
