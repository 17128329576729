import axios from 'axios';
import { getToken } from './createAuthProvider';
import { AdminListData } from '../types';
import { MAX_REQUEST_TIMEOUT } from '../constants/systemConstants';
import { alertMessage } from './alertMessage';
import moment from 'moment-timezone';
import getDashboardStyle from './getDashboardStyle';

const BASE_URL = getDashboardStyle().baseURL;

/**
 * Handles all HTTP requests for app
 *
 * If requests requires authentication createAuthProvider will handle the tokens
 */
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: MAX_REQUEST_TIMEOUT,
});

// Adding headers before each request
axiosInstance.interceptors.request.use(
  function (config) {
    // Chinese is the default language
    const language = localStorage.getItem('@storage_language');
    config.headers = {
      ...config.headers,
      'Accept-Language': language === 'en' ? 'en_us' : 'zh_cn',
      Timezone: moment.tz.guess(true),
      'app-type': getDashboardStyle().type,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * @param endPoint URL path
 * @param options  Custom options for axios request
 * @returns        validateAxiosResponse
 */
export const getData = async (endPoint: string, options?: {}) => {
  try {
    const response = await axiosInstance.get(endPoint, {
      ...options,
    });
    if (response.status === 200) {
      const validResponse = validateAxiosResponse(response.data);
      if (validResponse.returnCode === 403) {
        return;
      } else {
        return validResponse;
      }
    } else console.error(response);
  } catch (err: any) {
    if (axios.isCancel(err)) {
    } else {
      alertMessage('error', 'getData' + err + ' at endpoint: ' + endPoint);
      console.log('getData' + err + ' at endpoint: ' + endPoint);
    }
  }
};

/**
 * @param endPoint URL path
 * @param options  Custom options for axios request
 * @returns        validateAxiosResponse
 */
export const postData = async (
  endPoint: string,
  body: object,
  options?: {}
) => {
  try {
    const response = await axiosInstance.post(endPoint, body, options);
    if (response.status === 200) {
      return validateAxiosResponse(response.data);
    } else console.error(response);
  } catch (err) {
    alertMessage('error', 'postData' + err + ' at endpoint: ' + endPoint);
    console.log('postData' + err + ' at endpoint: ' + endPoint);
  }
};

/**
 * @param endPoint URL path
 * @param options  Custom options for axios request
 * @returns        validateAxiosResponse
 */
export const getDataWithAuthToken = async (endPoint: string, options?: {}) => {
  const authToken = await getToken();
  if (authToken) {
    try {
      const response = await axiosInstance.get(endPoint, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        ...options,
      });
      if (response.status === 200) {
        if (response.config.responseType === 'blob') {
          if (response.data.type === 'application/json') {
            let blob = await response.data.text();
            return validateAxiosResponse(JSON.parse(blob));
          }

          return validateAxiosResponse({
            data: response.data,
            returnCode: 200,
          });
        }
        return validateAxiosResponse(response.data);
      } else console.error(response);
    } catch (err: any) {
      if (axios.isCancel(err)) {
      } else {
        alertMessage('error', 'getData' + err + ' at endpoint: ' + endPoint);
        console.log('getData' + err + ' at endpoint: ' + endPoint);
      }
    }
  } else {
    window.history.pushState({ returnCode: 401 }, '', '/auth/login');
    window.location.assign('/auth/login');
  }
};

/**
 * @param endPoint URL path
 * @param options  Custom options for axios request
 * @returns        validateAxiosResponse
 */
export const postDataWithAuthToken = async (
  endPoint: string,
  body: object | Array<any>,
  options?: {}
) => {
  const authToken = await getToken();
  if (authToken) {
    try {
      const response = await axiosInstance.post(endPoint, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        ...options,
      });
      if (response.status === 200) {
        return validateAxiosResponse(response.data);
      } else console.error(response);
    } catch (err: any) {
      if (axios.isCancel(err)) {
      } else {
        alertMessage('error', 'postData' + err + ' at endpoint: ' + endPoint);
        console.log('postData' + err + ' at endpoint: ' + endPoint);
      }
    }
  } else {
    window.history.pushState({ returnCode: 401 }, '', '/auth/login');
    window.location.assign('/auth/login');
  }
};

/**
 * @param response returnCode  number   The return code number
 *                 data         JSON     The data received from the response
 *                 msg          string   The message received from the response
 *
 * @returns        goodStatus   boolean  If the return code is 200
 *                 returnCode  number   The return code number
 *                 data         JSON     The data received from the response
 *                 msg          string   The message received from the response
 */
const validateAxiosResponse = (response: {
  returnCode?: number;
  data: any;
  msg?: string;
}) => {
  if (response['returnCode']) {
    switch (response['returnCode']) {
      case 200:
        return {
          goodStatus: true,
          batchStatus: true,
          returnCode: response.returnCode,
          data: response.data,
          msg: response.msg,
        };
      case 401:
        localStorage.removeItem('AUTH_TOKEN');
        window.history.pushState({ returnCode: 401 }, '', '/auth/login');
        window.location.assign('/auth/login');
        return {
          goodStatus: false,
          batchStatus: true,
          returnCode: response.returnCode,
          data: response.data,
          msg: response.msg,
        };
      case 444: // Batch Fail
        return {
          goodStatus: false,
          batchStatus: false,
          returnCode: response.returnCode,
          data: response.data,
          msg: response.msg,
        };
      default:
        return {
          goodStatus: false,
          batchStatus: true,
          returnCode: response.returnCode,
          data: response.data,
          msg: response.msg,
        };
    }
  } else {
    return {
      goodStatus: false,
      batchStatus: true,
      returnCode: response.returnCode,
      data: response.data,
    };
  }
};

/**
 * @returns New token response from our refresh token
 */
export async function getNewToken() {
  const response = await postData('auth/refresh_token', {});
  return response;
}

/**
 * @returns MyProfile or null if MyProfile can't be found
 */
export const getMyProfile: () => Promise<AdminListData | null> = async () => {
  let profile = localStorage.getItem('profile');
  if (profile) {
    const profileObject = JSON.parse(profile);
    if (isAdminListData(profileObject)) {
      return profileObject;
    }
  }
  const response = await getDataWithAuthToken('admin/view_profile');
  if (response && response.goodStatus) {
    if (isAdminListData(response.data)) {
      localStorage.setItem('profile', JSON.stringify(response.data));
      return response.data;
    }
  }
  return null;
};

export async function getBusinessInfo() {
  try {
    let businessInfo = {
      logo: '',
      businessName: '',
      businessAddress: '',
      businessPhone: '',
    };
    const fetchData = async (code: string) => {
      const response = await getDataWithAuthToken(
        'setting/shop_config/detail',
        {
          params: {
            code: code,
          },
        }
      );
      if (response && response.goodStatus) {
        return response.data;
      }
      return '';
    };
    businessInfo.logo = await fetchData('order_print_logo');
    businessInfo.businessName = await fetchData('shop_name');
    businessInfo.businessAddress = await fetchData('shop_address');
    businessInfo.businessPhone = await fetchData('service_phone');
    return businessInfo;
  } catch (err) {
    console.error('Fail to fetch business info ', err);
    return null;
  }
}

/**
 * @param obj TypeScript Object
 * @returns   Whether obj fits the AdminListData definition
 */
const isAdminListData: (obj: any) => obj is AdminListData = (
  obj: any
): obj is AdminListData => {
  return (
    obj.adminId !== undefined &&
    obj.adminUserName !== undefined &&
    obj.parentAdmin !== undefined &&
    obj.sellerName !== undefined &&
    obj.email !== undefined &&
    obj.addTime !== undefined &&
    obj.lastLogin !== undefined &&
    obj.roleId !== undefined &&
    obj.actionList !== undefined
  );
};
