import { EditOutlined, EyeOutlined, PictureOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { ORDER_BUTTON } from '../../../constants/orderConstants';
import { dashboardRoute } from '../../../constants/pathname';
import { FALLBACK_IMG } from '../../../constants/styles';
import { TASK_STATUS } from '../../../constants/taskConstants';
import { useTab } from '../../../hooks/useTab';
import { OrderEnum, TaskData, TaskEnum, TaskGoodData } from '../../../types';
import ImagesViewer from '../../../utils/ImagesViewer';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import OrderActionModal from '../../order/orderDetail/OrderActionModal';

type TaskOrderGoodsTableProps = {
  title: string;
  taskInfo?: TaskData;
  taskGoodsList?: TaskGoodData[];
  isLoading?: boolean;
  callback?: () => void;
  isEditable?: boolean;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  enums?: TaskEnum;
};

const TaskOrderGoodsTable = ({
  title,
  taskInfo,
  taskGoodsList,
  isLoading,
  callback,
  isEditable = true,
  isCurrentEditing,
  setIsCurrentEditing,
  enums,
}: TaskOrderGoodsTableProps) => {
  const { t } = useTranslation();
  const { addTab } = useTab();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [imagesViewerVis, setImagesViewerVis] = useState<boolean>(false);
  const [taskGoodLoading, setTaskGoodLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  // Data component
  const [taskGoods, setTaskGoods] = useState<TaskGoodData[]>([]);
  const [selectedTaskGood, setSelectedTaskGood] = useState<TaskGoodData>();
  const [selectedRows, setSelectedRows] = useState<TaskGoodData[]>([]);
  const [claimAmount, setClaimAmount] = useState<string>('');
  const [orderEnum, setOrderEnum] = useState<OrderEnum>();
  const [action, setAction] = useState<string>('');
  // Pagination component
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    taskInfo && taskGoodsList && setTaskGoods(taskGoodsList);
  }, [taskInfo, taskGoodsList]);

  const getOrderEnum = async () => {
    getDataWithAuthToken('order/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setOrderEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAction = (action: string) => {
    setLoading(true);
    postDataWithAuthToken(
      action === ORDER_BUTTON.differenceEnabled
        ? 'order/preview_difference'
        : 'order/preview_out_of_stock',
      {
        remark:
          action === ORDER_BUTTON.differenceEnabled
            ? `System: ${t('order.orderDetail.buttonList.differenceEnabled')}`
            : `System: ${t('order.orderDetail.buttonList.outOfStockEnabled')}`,
        operate: action,
        orderId:
          taskInfo && taskInfo.order ? taskInfo.order.orderId : undefined,
        claimGoodsList:
          selectedRows && selectedRows.length
            ? selectedRows.map((good) =>
                action === ORDER_BUTTON.differenceEnabled
                  ? {
                      recId: good.ogId,
                      actualNumber: good.actualNumber || 0,
                      actualWeight: good.actualWeight || 0,
                      actualPrice: good.actualPrice,
                      claimReason: good.content,
                    }
                  : { recId: good.ogId }
              )
            : undefined,
      }
    )
      .then(async (response) => {
        if (response && response.goodStatus) {
          await getOrderEnum();
          setLoading(false);
          setClaimAmount(response.data);
          setAction(
            action === ORDER_BUTTON.differenceEnabled
              ? 'differenceEnabled'
              : 'outOfStockEnabled'
          );
          setShowModal(true);
        } else {
          setLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleOnCancel = () => {
    taskInfo && taskGoodsList && setTaskGoods(taskGoodsList);
    setLoading(false);
    setSelectedRows([]);
    setClaimAmount('');
    setIsEditing(false);
    setIsCurrentEditing && setIsCurrentEditing(false);
  };

  const updateTaskGoods = (key: string, value: any, index: number) => {
    setTaskGoods((prev) => {
      let newGoods: TaskGoodData[] = [...prev];
      newGoods[index] = {
        ...newGoods[index],
        [key]: value,
      };
      return newGoods;
    });
  };

  const handleOnEdit = () => {
    if (isEditing) {
      setLoading(true);
      postDataWithAuthToken(
        'task/task_goods/edit_batch',
        taskGoods.map((good) => ({
          tgId: good.tgId,
          taskId: taskInfo ? taskInfo.taskId : undefined,
          actualNumber: good.actualNumber,
          actualPrice: good.actualPrice,
          actualWeight: good.actualWeight,
          status: good.status ? good.status.code : undefined,
          content: good.content,
          reviewStatus: good.reviewStatus ? good.reviewStatus.code : undefined,
          reviewContent: good.reviewContent,
        }))
      )
        .then((response) => {
          if (response && response.goodStatus) {
            alertMessage('success', t('sortationSystem.alerts.taskEdited'));
            handleOnCancel();
            callback && callback();
          } else {
            setLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setIsEditing(true);
        setIsCurrentEditing && setIsCurrentEditing(true);
      }
    }
  };

  const getTaskGood = (tgId: number) => {
    setTaskGoodLoading(true);
    getDataWithAuthToken('task/task_goods/detail', { params: { tgId: tgId } })
      .then((response) => {
        if (response && response.goodStatus) {
          setSelectedTaskGood(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTaskGoodLoading(false);
      });
  };

  const columns: ColumnsType<TaskGoodData> = [
    {
      title: t('order.orderDetail.goodsImage'),
      width: 100,
      dataIndex: 'largePicPath',
      render: (value) =>
        value && (
          <Image
            src={value}
            fallback={FALLBACK_IMG}
            width={50}
            preview={{ mask: <EyeOutlined /> }}
          />
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsName'),
      width: 160,
      dataIndex: 'goodsName',
      render: (value, record) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
            )
          }
        >
          <Typography.Text ellipsis={{ tooltip: value }} style={{ width: 150 }}>
            {value}
          </Typography.Text>
        </Button>
      ),
    },
    {
      title: t('goods.goodsListColumns.goodsUnit'),
      width: 100,
      dataIndex: 'goodsUnit',
    },
    {
      title: t('goods.goodsListColumns.shopPrice'),
      width: 100,
      dataIndex: 'goodsPrice',
    },
    {
      title: t('goods.goodsListColumns.actualPrice'),
      width: 100,
      dataIndex: 'actualPrice',
      render: (value, record, index: number) =>
        isEditing ? (
          <InputNumber
            type="number"
            min={0}
            precision={2}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateTaskGoods('actualPrice', target.value, index);
            }}
          />
        ) : (
          <Typography.Text>{record.actualPrice}</Typography.Text>
        ),
    },
    {
      title: t('order.orderDetail.amount'),
      width: 100,
      dataIndex: 'goodsNumber',
    },
    {
      title: t('sortationSystem.taskDetail.actualNumber'),
      width: 100,
      dataIndex: 'actualNumber',
      render: (value, record, index: number) =>
        isEditing ? (
          <InputNumber
            type="number"
            min={0}
            precision={0}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateTaskGoods('actualNumber', target.value, index);
            }}
          />
        ) : (
          <Typography.Text>{record.actualNumber}</Typography.Text>
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsWeight'),
      width: 120,
      dataIndex: 'goodsWeight',
    },
    {
      title: t('sortationSystem.taskDetail.actualWeight'),
      width: 100,
      dataIndex: 'actualWeight',
      render: (value, record, index: number) =>
        isEditing ? (
          <InputNumber
            type="number"
            min={0}
            precision={3}
            defaultValue={value}
            onBlur={(e) => {
              let target = e.target as HTMLInputElement;
              updateTaskGoods('actualWeight', target.value, index);
            }}
          />
        ) : (
          <Typography.Text>{record.actualWeight}</Typography.Text>
        ),
    },
    {
      title: t('sortationSystem.taskDetail.taskGoodsStatus'),
      width: 100,
      dataIndex: 'status',
      render: (value, record, index) =>
        isEditing ? (
          <Select
            defaultValue={value ? value.code : undefined}
            style={{ width: '100%' }}
            onChange={(value) => {
              updateTaskGoods('status', { code: value }, index);
            }}
          >
            {enums &&
              enums.taskGoodsStatus &&
              enums.taskGoodsStatus.length > 0 &&
              enums.taskGoodsStatus.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.description}
                </Select.Option>
              ))}
          </Select>
        ) : (
          value && <Tag color={value.tagColor}>{value.description}</Tag>
        ),
    },
    {
      title: t('sortationSystem.taskDetail.content'),
      width: 160,
      dataIndex: 'content',
      render: (value, record, index) =>
        isEditing ? (
          <Input.TextArea
            defaultValue={value}
            autoSize={{ minRows: 1 }}
            onChange={(e) => {
              updateTaskGoods('content', e.target.value, index);
            }}
          />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text>{value}</Typography.Text>
            {record.hasImages && (
              <Button
                loading={taskGoodLoading}
                style={{ borderWidth: 0 }}
                onClick={() => {
                  getTaskGood(record.tgId);
                  setImagesViewerVis(true);
                }}
                icon={<PictureOutlined size={24} color="black" />}
              />
            )}
          </div>
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsSn'),
      width: 120,
      dataIndex: 'goodsSn',
    },
    {
      title: t('order.orderDetail.goodsAttrName'),
      width: 100,
      dataIndex: 'goodsAttrName',
    },
    {
      title: t('sortationSystem.taskListColumns.reviewStatus'),
      width: 100,
      dataIndex: 'reviewStatus',
      render: (value, record, index) =>
        isEditing ? (
          <Select
            defaultValue={value ? value.code : undefined}
            style={{ width: '100%' }}
            onChange={(value) => {
              updateTaskGoods('reviewStatus', value, index);
            }}
          >
            {enums &&
              enums.taskGoodsReviewStatus &&
              enums.taskGoodsReviewStatus.length > 0 &&
              enums.taskGoodsReviewStatus.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.description}
                </Select.Option>
              ))}
          </Select>
        ) : (
          value && <Tag color={value.tagColor}>{value.description}</Tag>
        ),
    },
    {
      title: t('sortationSystem.taskListColumns.reviewContent'),
      width: 160,
      dataIndex: 'reviewContent',
      render: (value, record, index) =>
        isEditing ? (
          <Input.TextArea
            defaultValue={value}
            autoSize={{ minRows: 1 }}
            onChange={(e) => {
              updateTaskGoods('reviewContent', e.target.value, index);
            }}
          />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        ),
    },
  ];

  return taskInfo && taskGoodsList ? (
    <Spin spinning={isLoading || loading}>
      <Row gutter={[16, 24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Text strong style={{ fontSize: 16 }}>
            {title}
          </Typography.Text>
          {/*** 指定条件下才能更新修改 */}
          {isEditable &&
            (isEditing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.sortationGroup.taskManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.sortationGroup.taskManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            ))}
        </Col>
        {isEditing && selectedRows && selectedRows.length > 0 && (
          <Col span={24}>
            <Space>
              <Button
                disabled={
                  !hasPermission(actionPermissions.sortationGroup.taskManage)
                }
                type="primary"
                size="small"
                onClick={() => handleAction(ORDER_BUTTON.outOfStockEnabled)}
              >
                {t('order.orderDetail.buttonList.outOfStockEnabled')}
              </Button>
              <Button
                disabled={
                  !hasPermission(actionPermissions.sortationGroup.taskManage)
                }
                type="primary"
                size="small"
                onClick={() => handleAction(ORDER_BUTTON.differenceEnabled)}
              >
                {t('order.orderDetail.buttonList.differenceEnabled')}
              </Button>
            </Space>
          </Col>
        )}
        <Col span={24}>
          <Table
            size="small"
            dataSource={taskGoods}
            columns={columns}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(record) => record.tgId}
            pagination={{
              pageSize: pageSize,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              showSizeChanger: true,
              onChange: (p, pSize) => {
                setPage(p);
                setPageSize(pSize);
              },
              current: page,
            }}
            loading={isLoading || loading}
            rowSelection={
              isEditing
                ? {
                    onChange: (
                      selectedRowKeys: React.Key[],
                      selectedRows: TaskGoodData[]
                    ) => {
                      setSelectedRows(selectedRows);
                    },
                    getCheckboxProps: (record: TaskGoodData) => ({
                      disabled:
                        taskInfo &&
                        taskInfo.taskStatus &&
                        taskInfo.taskStatus.code !== TASK_STATUS.finished,
                    }),
                  }
                : undefined
            }
          />
        </Col>
        <OrderActionModal
          visible={showModal}
          setVisible={setShowModal}
          action={action}
          claimAmount={claimAmount}
          orderGoods={selectedRows}
          orderInfo={taskInfo.order}
          taskAction={true}
          callBack={() => {
            handleOnCancel();
            callback && callback();
          }}
          enums={orderEnum}
        />
        {selectedTaskGood &&
          selectedTaskGood.taskGoodsImagesList.length > 0 && (
            <ImagesViewer
              loading={taskGoodLoading}
              images={selectedTaskGood.taskGoodsImagesList}
              setImages={setSelectedTaskGood}
              visible={imagesViewerVis}
              setVisible={setImagesViewerVis}
            />
          )}
      </Row>
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.orderGoods')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default TaskOrderGoodsTable;
