import React, { useEffect, useRef } from 'react';
import { Button, Col, Grid, Row, Space, Tag, Typography } from 'antd';
import { OrderClaimData, ClaimRefundLog } from '../../../types';
import { useTranslation } from 'react-i18next';

import Table, { ColumnsType } from 'antd/lib/table';
import {
  CLAIM_CODE_FINISH,
  CLAIM_CODE_SUCCESS,
} from '../../../constants/orderConstants';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import { alertMessage } from '../../../utils/alertMessage';
import { hasPermission } from '../../../utils/hasPermission';
import { actionPermissions } from '../../../constants/actionPermissions';

type ClaimRefundLogProps = {
  orderClaimDetail: OrderClaimData;
  loading: boolean;
  refresh: Function;
};

/**
 * Displays Claim Log List
 *
 */
const ClaimRefundLogList = ({
  orderClaimDetail,
  loading,
  refresh,
}: ClaimRefundLogProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const screens = Grid.useBreakpoint();
  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const retryRefund = (id: number) => {
    postDataWithAuthToken('claim/retry', { refundLogId: id })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            alertMessage('success', t('order.alerts.claimRetried'));
            if (refresh) refresh();
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: ColumnsType<ClaimRefundLog> = [
    {
      title: t('order.claimRefundLog.id'),
      width: 100,
      dataIndex: 'id',
      render: (text: string) => text,
    },
    {
      title: t('order.claimRefundLog.resultCode'),
      width: 150,
      dataIndex: 'resultCode',
      render: (text: string, record: ClaimRefundLog) => (
        <Tag color={record.resultCode ? record.resultCode.tagColor : undefined}>
          {record.resultCode ? record.resultCode.description : undefined}
        </Tag>
      ),
    },
    {
      title: t('order.claimRefundLog.refundAmount'),
      width: 120,
      dataIndex: 'refundAmount',
      render: (text: string, record: ClaimRefundLog) => record.refundAmount,
    },
    {
      title: t('order.claimRefundLog.payCode'),
      width: 100,
      dataIndex: 'payCode',
      render: (text: string, record: ClaimRefundLog) =>
        record.payCode ? record.payCode.description : '',
    },
    {
      title: t('order.claimRefundLog.payTransId'),
      width: 300,
      dataIndex: 'payTransId',
      render: (text: string) => text,
    },

    {
      title: t('order.claimRefundLog.payResponseTransId'),
      width: 300,
      dataIndex: 'payResponseTransId',
      render: (text: string) => text,
    },
    {
      title: t('order.claimRefundLog.refundTransId'),
      dataIndex: 'refundTransId',
      width: 300,
      render: (text: string) => text,
    },
    {
      title: t('order.claimRefundLog.relatedId'),
      dataIndex: 'relatedId',
      width: 100,
      render: (text: number) => text,
    },
    {
      title: t('order.claimRefundLog.createTime'),
      width: 160,
      dataIndex: 'createTime',
      render: (text: string) => text,
    },
    {
      title: t('order.claimRefundLog.finishTime'),
      width: 160,
      dataIndex: 'finishTime',
      render: (text: string) => text,
    },
    {
      title: t('order.claimRefundLog.remark'),
      dataIndex: 'remark',
      render: (text: string) => text,
    },
    {
      title: t('actionsColumn.title'),
      width: 80,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: ClaimRefundLog) => (
        <Space direction="vertical">
          {record.resultCode &&
            record.resultCode.code !== CLAIM_CODE_SUCCESS &&
            record.resultCode.code !== CLAIM_CODE_FINISH && (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.claimManage)
                }
                type="link"
                style={{ padding: 0 }}
                onClick={() => retryRefund(record.id)}
              >
                {t('actionsColumn.retry')}
              </Button>
            )}
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[16, 24]}>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Text
          strong
          style={{
            fontSize: 16,
            marginBottom: 10,
          }}
        >
          {t('order.claimRefundLog.title')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Table
          // rowClassName={(record) =>
          //   record.resultCode && record.resultCode.code === RESULT_CODE_FAILED
          //     ? 'redRow'
          //     : ''
          // }
          size="small"
          dataSource={orderClaimDetail.claimRefundLogList}
          columns={columns}
          scroll={{ y: 600, x: 1200 }}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: 50,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
            hideOnSinglePage: true,
          }}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default ClaimRefundLogList;
