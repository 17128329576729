import { useState } from 'react';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { CouponUserData } from '../../../types';
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../../utils/alertMessage';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { EXTENDED_TIMEOUT } from '../../../constants/systemConstants';
import { Space, Typography } from 'antd';

type CouponUserDropdownProps = {
  userId?: number;
  sellerId?: number;
  isEdit?: boolean;
  defaultOptions?: boolean;
  labelInValue?: boolean;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
  onClear?: Function;
  initialValue?: any;
};

const CouponUserDropdown = ({
  userId,
  sellerId,
  isEdit = true,
  defaultOptions = false,
  labelInValue = false,
  initialValue,
  onChange,
  onClear,
}: CouponUserDropdownProps) => {
  const { t } = useTranslation();
  const [couponUser, setCouponUser] = useState<CouponUserData[]>([]);
  const [couponUserPage, setCouponUserPage] = useState<number>(2);
  const [couponUserTotalPage, setCouponUserTotalPage] = useState<number>(2);
  const [searchText, setSearchText] = useState<{ [key: string]: string }>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const getCouponUser = (value?: string) => {
    if (userId && sellerId !== undefined) {
      getDataWithAuthToken('coupon/user/list', {
        params: {
          keyword: value || undefined,
          userId: userId,
          isUsed: false,
          isValid: true,
          sellerId: sellerId,
        },
      })
        .then((response) => {
          if (response && response.goodStatus) {
            setCouponUser(response.data.list);
            setCouponUserPage(2);
            setCouponUserTotalPage(response.data.totalPage);
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchOnScrollCouponUser = (e: React.UIEvent) => {
    e.persist();
    let target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (couponUserPage > couponUserTotalPage) return;
      getDataWithAuthToken('coupon/user/list', {
        params: {
          page: couponUserPage,
          keyword: searchText.couponUser,
          userId: userId || undefined,
          isUsed: false,
          isValid: true,
          sellerId: sellerId,
        },
      }).then((response) => {
        if (response && response.goodStatus) {
          setCouponUser((prev) => [...prev, ...response.data.list]);
          setCouponUserPage((prev) => prev + 1);
          setCouponUserTotalPage(response.data.totalPage);
        }
      });
    }
  };

  const handleOnChange = (value: any, options: any) => {
    if (onChange) {
      onChange(value, options);
    }
  };

  const handleOnClear = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <>
      <Select
        placeholder={t('searchPlaceholders.searchUserCoupon')}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onPopupScroll={fetchOnScrollCouponUser}
        onFocus={() => {
          if (!couponUser.length) getCouponUser();
        }}
        onSearch={(value) => {
          setSearchText((prev) => ({ ...prev, couponUser: value }));
          if (typingTimeout) clearTimeout(typingTimeout);
          setTypingTimeout(
            setTimeout(() => getCouponUser(value), EXTENDED_TIMEOUT)
          );
        }}
        onChange={handleOnChange}
        onClear={handleOnClear}
        allowClear={!defaultOptions}
        showSearch
        defaultValue={initialValue ? initialValue : undefined}
        showArrow={isEdit}
        bordered={isEdit}
        style={{ pointerEvents: isEdit ? undefined : 'none', width: '100%' }}
        filterOption={false}
        optionLabelProp="key"
        labelInValue={labelInValue}
      >
        {defaultOptions && (
          <Select.Option key={0} value={0}>
            {t('order.orderDetail.noCoupon')}
          </Select.Option>
        )}
        {couponUser.map((coupon) => (
          <Select.Option key={coupon.ucId} value={coupon.ucId}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Space wrap>
                <Typography.Text type="secondary">
                  {t('activity.userCouponColumnList.ucSn')}:
                </Typography.Text>
                <Typography>{coupon.ucSn}</Typography>
              </Space>
              {coupon.coupon && (
                <Space wrap>
                  <Typography.Text type="secondary">
                    {t('activity.userCouponColumnList.couponName')}:
                  </Typography.Text>
                  <Typography>{coupon.coupon?.couponName}</Typography>
                </Space>
              )}
              <Space wrap>
                <Typography.Text type="secondary">
                  {t('activity.valueCardColumns.cardMoney')}:
                </Typography.Text>
                <Typography>{coupon.couponMoney}</Typography>
              </Space>
            </div>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default CouponUserDropdown;
