import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Spin, Select } from 'antd';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { BasicEnumInfoType, SalespersonData } from '../../types';
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../utils/alertMessage';

const { Option } = Select;

type SalespersonModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  // If userProfile Exists then we are editing an already existing profile.
  salesperson?: SalespersonData;
  callBack?: () => void;
  salespersonEnum?: { [key: string]: BasicEnumInfoType[] };
};

/**
 * Modal to create and edit saleperson
 *
 * @prop visible      Whether Modal should be visible
 * @prop setVisible   Setting Modal to be visible or not
 * @prop salesperson  salesperson to edit
 * @prop callBack     Function to call after submitting form. Usually a refresh function
 */
const SalespersonModal = ({
  visible,
  setVisible,
  salesperson,
  callBack,
  salespersonEnum,
}: SalespersonModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');

  // Need to reset the form so that the default values are correct in Edit
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        if (salesperson) {
          // Editing salesperson
          postDataWithAuthToken('sales_person/edit', {
            ...values,
            salesPersonId: salesperson.salesPersonId,
            remark: values.remark || '',
          }).then((response) => {
            setLoading(false);
            if (response && response.goodStatus) {
              alertMessage(
                'success',
                t('salesperson.alerts.salespersonEdited')
              );
              if (callBack) {
                callBack();
              }
              handleCancel();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
        } else {
          // Creating new salesperson
          postDataWithAuthToken('sales_person/add', {
            ...values,
            remark: values.remark || '',
          }).then((response) => {
            setLoading(false);
            if (response && response.goodStatus) {
              alertMessage('success', t('salesperson.alerts.salespersonAdded'));
              if (callBack) {
                callBack();
              }
              handleCancel();
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setFirstPassword('');
    setVisible(false);
  };

  return (
    <Modal
      title={
        salesperson
          ? `${t('salesperson.editTitle')} ${salesperson.fullName}`
          : t('salesperson.addTitle')
      }
      visible={visible}
      onOk={submitForm}
      onCancel={handleCancel}
      okText={t('users.addUser.ok')}
      cancelText={t('users.addUser.cancel')}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={salesperson ? { ...salesperson } : {}}
        >
          <Form.Item
            name="fullName"
            label={t('salesperson.fullName')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={t('salesperson.mobile')}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('salesperson.password')}
            rules={[
              {
                required: salesperson ? false : true,
                message: t('general.inputError.empty'),
              },
            ]}
            hasFeedback={salesperson && firstPassword.length ? true : false}
          >
            <Input.Password
              onChange={(e) => setFirstPassword(e.target.value)}
            />
          </Form.Item>
          {firstPassword && (
            <Form.Item
              name="confirmPassword"
              label={t('salesperson.confirmPassword')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('general.inputError.pleaseConfirmPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(t('general.inputError.passwordsDontMatch'))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="status"
            label={t('salesperson.status')}
            rules={[{ required: true }]}
          >
            <Select>
              {salespersonEnum &&
                salespersonEnum.salesPersonStatus &&
                salespersonEnum.salesPersonStatus.length > 0 &&
                salespersonEnum.salesPersonStatus.map((status) => (
                  <Option key={status.code} value={status.code}>
                    {status.description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="remark" label={t('users.addUser.remark')}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SalespersonModal;
