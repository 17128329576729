import {
  Breadcrumb,
  Button,
  Card,
  Grid,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import Container from '../../components/Container';
import { useTranslation } from 'react-i18next';
import { RegionData, FontSizeType } from '../../types';
import { useEffect, useState, useRef, useCallback } from 'react';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import AddRegionModal from '../../components/settings/AddRegionModal';
import FourZeroThree from '../../components/FourZeroThree';
import { alertMessage } from '../../utils/alertMessage';
import TableToolbar from '../../components/table/TableToolbar';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { compare, setFont } from '../../utils/colComponents';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
} from '../../constants/systemConstants';
import { useVT } from 'virtualizedtableforantd4';
import FiveHundred from '../../components/FiveHundred';
import { PRIMARY, RED1 } from '../../constants/color';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import { tableScrollToTop } from '../../utils/helperFunction';

const Region = () => {
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [regionList, setRegionList] = useState<RegionData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [parentRegion, setParentRegion] = useState<RegionData>();
  const [totalItems, setTotalItems] = useState(0);
  const [breadCrumbItems, setBreadCrumbItems] = useState<React.ReactNode[]>([]);
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const ellipsis = useState(true);
  const screens = useBreakpoint();
  const columnKeys = [
    'id',
    'name',
    'type',
    'area',
    'postalCode',
    'isShow',
    'sortOrder',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  const [editValidator, setEditValidator] = useState<{
    data: RegionData;
    rowIndex: any;
    colIndex: any;
  }>();

  const handleOk = (param: string, value: any, record: RegionData) => {
    postDataWithAuthToken('setting/region/edit_info', {
      regionId: record.regionId,
      regionName: param === 'regionName' && value ? value : undefined,
      sortOrder: param === 'sortOrder' && value ? value : undefined,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('settings.alerts.regionEdited'));
          getRegionData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setEditValidator(undefined);
  };

  const handleShowAddModal = () => {
    if (isSubscribed.current) setShowAddModal(true);
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Update to display the region or not
  const updateIsShow = async (checked: boolean, region: RegionData) => {
    try {
      const result = await postDataWithAuthToken('setting/region/edit_info', {
        ...region,
        regionId: region.regionId,
        isShow: checked,
      });

      if (result && result.goodStatus) {
        if (isSubscribed.current) getRegionData();
        alertMessage('success', t('settings.alerts.regionEdited'));
      } else {
        alertMessage(
          'error',
          result?.msg || t('general.noResponse'),
          result?.data || undefined
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Update sort order
  const updateSortOrder = async (value: number, region: RegionData) => {
    let result = await postDataWithAuthToken('setting/region/edit_info', {
      ...region,
      regionId: region.regionId,
      sortOrder: value,
    });
    return result;
  };

  // Update region name
  const updateRegionName = async (value: string, region: RegionData) => {
    let result = await postDataWithAuthToken('setting/region/edit_info', {
      ...region,
      regionId: region.regionId,
      regionName: value,
    });
    return result;
  };

  // Update postal code
  const updatePostalCode = async (value: string, region: RegionData) => {
    let result = await postDataWithAuthToken('setting/region/edit_info', {
      ...region,
      regionId: region.regionId,
      postalCode: value,
    });
    return result;
  };

  // Trigger after pressing enter
  const handlePressEnter = async (
    e: React.KeyboardEvent,
    region: RegionData
  ) => {
    let target = e.target as HTMLInputElement;
    if (!target.value)
      return alertMessage('error', t('general.inputError.empty'));

    if (
      target.name === 'sortOrder' &&
      (parseInt(target.value) < 0 ||
        parseInt(target.value) > 50000 ||
        isNaN(parseInt(target.value)))
    )
      return alertMessage('error', t('settings.inputError.sortOrder'));

    if (target.name === 'postalCode' && target.value.length < 3)
      return alertMessage('error', t('settings.inputError.postalCodeLength'));

    try {
      let result: any;
      switch (target.name) {
        case 'sortOrder':
          result = await updateSortOrder(parseInt(target.value), region);
          break;
        case 'name':
          result = await updateRegionName(target.value, region);
          break;
        case 'postalCode':
          result = await updatePostalCode(target.value, region);
          break;
        default:
          result = null;
      }

      if (result && result.goodStatus) {
        getRegionData();
        alertMessage('success', t('settings.alerts.regionEdited'));
      } else
        alertMessage(
          'error',
          result?.msg || t('general.noResponse'),
          result?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  // Delete region
  const handleDeleteRegion = async (regionId: number) => {
    try {
      const result = await postDataWithAuthToken('setting/region/delete', {
        regionId: regionId,
      });
      if (result && result.goodStatus) {
        getRegionData();
        alertMessage('success', t('settings.alerts.regionDeleted'));
      } else
        alertMessage(
          'error',
          result?.msg || t('general.noResponse'),
          result?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  // Click on breadcrumb
  const handleClickOnBreadcrumb = (e: React.MouseEvent) => {
    let target = e.target as HTMLAnchorElement;
    if (target.tabIndex !== breadCrumbItems.length - 1) {
      let temp = breadCrumbItems;
      temp.splice(target.tabIndex + 1);
      if (isSubscribed.current) {
        setBreadCrumbItems(temp);
        setParentId(parseInt(target.id));
      }
    }
  };

  // View sub regions
  const handleViewSubRegion = (region: RegionData) => {
    let items = breadCrumbItems;
    items.push(
      <Breadcrumb.Item key={region.regionId}>
        <span
          id={region.regionId.toString()}
          tabIndex={breadCrumbItems.length}
          onClick={handleClickOnBreadcrumb}
          style={{ cursor: 'pointer', color: PRIMARY }}
        >
          {region.regionName}
        </span>
      </Breadcrumb.Item>
    );
    if (isSubscribed.current) {
      setBreadCrumbItems(items);
      setParentId(region.regionId);
    }
  };

  // Go back to upper level region list
  const handleGoBack = (region: RegionData) => {
    if (isSubscribed.current) {
      let temp = breadCrumbItems;
      temp.pop();
      setBreadCrumbItems(temp);
      setParentId(region.parentId);
    }
  };

  const getRegionData = useCallback(
    (sortOrder?: 'ASC' | 'DESC', sortValue?: 'region_id' | 'region_name') => {
      if (isSubscribed.current) setIsLoading(true);
      getDataWithAuthToken('setting/region/list', {
        params: {
          parentId: parentId,
          sortOrder: sortOrder || undefined,
          sortValue: sortValue || undefined,
        },
      })
        .then((regionListResult) => {
          if (
            regionListResult &&
            regionListResult.goodStatus &&
            regionListResult.data
          ) {
            if (isSubscribed.current) {
              setRegionList(regionListResult.data.list);
              setParentRegion(regionListResult.data.data);
              setTotalItems(regionListResult.data.totalItem);
              setIsLoading(false);

              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (regionListResult && regionListResult.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            if (isSubscribed.current) {
              setFiveHundred(true);
              setIsLoading(false);
            }
            alertMessage(
              'error',
              t('region.alerts.errorOccurred'),
              regionListResult?.msg || t('general.noResponse')
            );
          }
        })
        .catch((err) => {
          if (isSubscribed.current) setIsLoading(false);
          console.log(err);
        });
    },
    [parentId, t]
  );

  // Fetch region list, refresh the list if any parent region id gets updated
  useEffect(() => {
    getRegionData();
  }, [getRegionData]);

  const columns: ColumnsType<RegionData> = [
    {
      title: setFont(t('settings.regionColumns.id'), fontSize),
      dataIndex: 'regionId',
      key: 'id',
      width: 100,
      fixed: screens.lg ? 'left' : undefined,
      render: (text: string, record: RegionData) => (
        <Button
          type="link"
          onClick={() => handleViewSubRegion(record)}
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            !hasPermission(actionPermissions.settingGroup.regionSetting)
          }
        >
          {text}
        </Button>
      ),
      sorter: (a: RegionData, b: RegionData) => compare(a.regionId, b.regionId),
    },
    {
      title: setFont(t('settings.regionColumns.regionName'), fontSize),
      key: 'name',
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: RegionData, b: RegionData) =>
        compare(a.regionName, b.regionName),
      render: (record: RegionData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'regionName' ? (
          <Space direction="vertical">
            <Input
              autoFocus
              defaultValue={record.regionName}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(e) => {
                let target = e.target as HTMLInputElement;
                !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                target.value && handleOk('regionName', target.value, record);
              }}
            ></Input>
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Typography.Text
            style={ellipsis ? { fontSize: fontSize } : undefined}
            ellipsis={ellipsis ? { tooltip: record.regionName } : false}
          >
            <Button
              style={{ padding: 0 }}
              type="text"
              onClick={() => {
                setEditValidator({
                  data: record,
                  rowIndex: index,
                  colIndex: 'regionName',
                });
              }}
              disabled={
                !hasPermission(actionPermissions.settingGroup.regionSetting)
              }
            >
              {record.regionName}
            </Button>
          </Typography.Text>
        ),
    },
    {
      title: setFont(t('settings.regionColumns.regionType'), fontSize),
      dataIndex: 'regionType',
      key: 'type',
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('settings.regionColumns.postalCode'), fontSize),
      dataIndex: 'postalCode',
      key: 'postalCode',
      width: 100,
      render: (value: string, record: RegionData) =>
        record.regionTypeId === 4 ? (
          <Input
            disabled={
              !hasPermission(actionPermissions.settingGroup.regionSetting)
            }
            type="text"
            style={{ fontSize: fontSize }}
            name="postalCode"
            defaultValue={value}
            onPressEnter={(e) => handlePressEnter(e, record)}
            placeholder="Postal code"
            maxLength={3}
            minLength={3}
          />
        ) : (
          <>{setFont(value, fontSize)}</>
        ),
    },
    {
      title: setFont(t('settings.regionColumns.isShow'), fontSize),
      dataIndex: 'isShow',
      key: 'isShow',
      width: 100,
      render: (value: boolean, record: RegionData) => (
        <Switch
          disabled={
            !hasPermission(actionPermissions.settingGroup.regionSetting)
          }
          checked={Boolean(value)}
          onChange={(checked) => updateIsShow(checked, record)}
        />
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 100,
      sorter: true,
      render: (value: number, record: RegionData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'sortOrder' ? (
          <Space direction="vertical">
            <InputNumber
              autoFocus
              defaultValue={value}
              min={0}
              max={50000}
              placeholder="0-50000"
              style={{ width: 90, fontSize: fontSize }}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(input) =>
                !input ? setIsEmptyInput(true) : setIsEmptyInput(false)
              }
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                target.value && handleOk('sortOrder', target.value, record);
              }}
            ></InputNumber>
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Button
            style={{ padding: 0 }}
            type="text"
            disabled={
              !hasPermission(actionPermissions.settingGroup.regionSetting)
            }
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'sortOrder',
              });
            }}
          >
            {value}
          </Button>
        ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: RegionData) => (
        <Space>
          {record.regionTypeId !== 4 && (
            <Button
              onClick={() => handleViewSubRegion(record)}
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.settingGroup.regionSetting)
              }
            >
              {t('actionsColumn.view')}
            </Button>
          )}
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              handleDeleteRegion(record.regionId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            style={{ fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.settingGroup.regionSetting)
            }
          >
            <Button
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={
                !hasPermission(actionPermissions.settingGroup.regionSetting)
              }
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <h1 style={{ fontSize: 24 }}>{t('settings.regionSettings')}</h1>
          {hasPermission(actionPermissions.settingGroup.regionSetting) &&
            !!(breadCrumbItems && breadCrumbItems.length) && (
              <Breadcrumb separator=">" style={{ marginBottom: 8 }}>
                <Breadcrumb.Item key="0">
                  <span
                    onClick={() => {
                      setBreadCrumbItems([]);
                      setParentId(0);
                    }}
                    style={{ cursor: 'pointer', color: PRIMARY }}
                  >
                    Region
                  </span>
                </Breadcrumb.Item>
                {breadCrumbItems}
              </Breadcrumb>
            )}
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            leftElement={
              <Space>
                {parentRegion && (
                  <Button
                    icon={<RollbackOutlined />}
                    onClick={() => handleGoBack(parentRegion)}
                    disabled={
                      !hasPermission(
                        actionPermissions.settingGroup.regionSetting
                      )
                    }
                  >
                    {t('settings.add/editRegion.cancel')}
                  </Button>
                )}
                <Button icon={<PlusOutlined />} onClick={handleShowAddModal}>
                  {t('settings.add/editRegion.addTitle')}
                </Button>
              </Space>
            }
            totalItems={totalItems}
            refresh={() => getRegionData()}
            tableSize={tableSize}
            setTableSize={setTableSize}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            rows={regionList}
            exportConfig={{ fileName: 'REGION' }}
          />
          <Table
            dataSource={regionList}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(region) => region.regionId}
            loading={isLoading}
            pagination={false}
          />
        </Card>
      )}
      <AddRegionModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        refresh={getRegionData}
        parentId={parentId}
      />
    </Container>
  );
};

export default Region;
