import { Form, Modal, Spin, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplierData } from '../../types';

import 'react-quill/dist/quill.snow.css';
import { postDataWithAuthToken } from '../../utils/axiosRequest';
import { alertMessage } from '../../utils/alertMessage';

type SupplierModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSupplier?: SupplierData;
  refresh: Function;
};

const SupplierModal = ({
  visible,
  setVisible,
  selectedSupplier,
  refresh,
}: SupplierModalProps) => {
  //General components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  //Data components
  //Text Editor Components

  // Sets isSubscribed, quillRef to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Things to do on first render
  useEffect(() => {
    if (isSubscribed.current && visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        if (isSubscribed.current) setLoading(true);
        postDataWithAuthToken(
          selectedSupplier ? 'goods/supplier/edit' : 'goods/supplier/add',
          {
            ...values,
            supplierId: selectedSupplier
              ? selectedSupplier.supplierId
              : undefined,
          }
        ).then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) setVisible(false);
            alertMessage(
              'success',
              selectedSupplier
                ? t('goods.alerts.supplierEdited')
                : t('goods.alerts.supplierAdded')
            );
            if (refresh) refresh();
            onClose();
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        });
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err: any) => {
        if (isSubscribed.current) setLoading(false);
        console.log(err);
      });
  };

  return (
    <Modal
      onOk={onFinish}
      onCancel={onClose}
      bodyStyle={{ height: 'auto' }}
      title={
        selectedSupplier
          ? `${t('goods.add/editSupplier.editTitle')} ${
              selectedSupplier.supplierName
            }`
          : `${t('goods.add/editSupplier.addTitle')}`
      }
      visible={visible}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={{
            ...selectedSupplier,
          }}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item
            name={'supplierName'}
            label={`${t('goods.add/editSupplier.supplierName')}: `}
            rules={[
              {
                required: true,
                message: t('general.inputError.empty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'supplierDesc'}
            label={`${t('goods.add/editSupplier.supplierDesc')}: `}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SupplierModal;
